import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Popover as MUIPopover,
  PopoverOrigin as MUIPopoverOrigin,
  PopoverPosition as MUIPopoverPosition,
} from "@mui/material";
const PREFIX = "Popover";

const classes = {
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    border: `solid 1px ${theme.palette.secondary.main}`,
  },
}));

export interface Props {
  children?: React.ReactNode;
  popOverComponent?: React.ReactNode;
  anchorOrigin?: MUIPopoverOrigin;
  transformOrigin?: MUIPopoverOrigin;
  anchorPosition?: MUIPopoverPosition;
  anchorReference?: "none" | "anchorPosition" | "anchorEl" | undefined;
}

export function Popover({
  children,
  popOverComponent,
  anchorOrigin,
  transformOrigin,
  anchorPosition,
  anchorReference,
}: Props) {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Root>
      <div ref={anchorEl} onClick={handleToggleOpen}>
        {children}
      </div>
      <MUIPopover
        onClick={(e) => e.stopPropagation()}
        PaperProps={{ root: classes.root }}
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleToggleOpen}
        anchorOrigin={
          anchorOrigin || {
            vertical: "bottom",
            horizontal: "center",
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: "top",
            horizontal: "center",
          }
        }
        anchorPosition={
          anchorPosition || {
            top: 200,
            left: 200,
          }
        }
        anchorReference={anchorReference}
      >
        {popOverComponent}
      </MUIPopover>
    </Root>
  );
}
