import React from "react";
import { Chip, ChipProps } from "@mui/material";
import { SpotState } from "james/market/Spot";

export interface SpotStateChipProps {
  state: SpotState | "";
  chipProps?: ChipProps;
}

export function SpotStateChip(props: SpotStateChipProps) {
  switch (props.state) {
    case SpotState.Pending:
      return (
        <Chip
          {...props.chipProps}
          color="info"
          id={"spotStateChip-pending-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case SpotState.Settled:
      return (
        <Chip
          {...props.chipProps}
          id={"spotStateChip-settled-chip"}
          color="success"
          size={"small"}
          label={props.state}
        />
      );

    case SpotState.Failed:
      return (
        <Chip
          {...props.chipProps}
          color="error"
          id={"spotStateChip-failed-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case SpotState.UnderInvestigation:
      return (
        <Chip
          {...props.chipProps}
          color="warning"
          id={"spotStateChip-underInvestigation-chip"}
          size={"small"}
          label={props.state}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}
