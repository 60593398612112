import React, { useEffect, useMemo, useState } from "react";
import { SxProps, Theme, alpha, styled } from "@mui/material/styles";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Model as MarketSubscriptionUnderwritingViewModel,
  Updater as MarketSubscriptionUnderwritingViewUpdater,
  useMarketSubscriptionUnderwritingViewReaderRead,
} from "james/views/marketSubscriptionUnderwritingView";
import {
  AllSubscriptionUnderwritingStates,
  SubscriptionUnderwritingState,
} from "james/market/SubscriptionUnderwriting";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import { SubscriptionUnderwritingStateChip } from "./Chips";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  FaceOutlined,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { SubscriptionUnderwritingStateController } from "james/market/SubscriptionUnderwritingStateController";
import { Amount } from "../Ledger/Amount";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { TransactionTable } from "components/Ledger/Transaction";

const PREFIX = "SubscriptionUnderwritingTable";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  row: `${PREFIX}-row`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.textSearchField}`]: {
    width: 350,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },

  [`& .${classes.statusSelectFilterField}`]: {
    width: 230,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
});

export type SubscriptionUnderwritingTableProps = {
  system: boolean;
  height: number;
  subscriptionOrderBookID?: string;
  hideColumns?: string[];
  style?: SxProps<Theme>;
  showEmptyCard?: boolean;
};

export function SubscriptionUnderwritingTable(
  props: SubscriptionUnderwritingTableProps,
) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [fullUpdateLoading, setFullUpdateLoading] = useState(false);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const mcView =
    !!viewConfiguration?.["Subscription"]?.["Subscription Underwriting"]?.[
      "MC View"
    ];
  const [
    selectedSubscriptionUnderwritingViewModel,
    setSelectedSubscriptionUnderwritingViewModel,
  ] = useState<MarketSubscriptionUnderwritingViewModel | undefined>(undefined);

  const [resolutionInProgress, setResolutionInProgress] = useState(false);
  const handleResolveSubscriptionUnderwritingState = async () => {
    if (!selectedSubscriptionUnderwritingViewModel) {
      console.error(
        "selected market subscription Underwriting view model not set",
      );
      return;
    }

    setResolutionInProgress(true);
    try {
      if (!selectedSubscriptionUnderwritingViewModel) {
        console.error("Selected Subscription Underwriting Not Set");
        return;
      }
      await SubscriptionUnderwritingStateController.ResolveSubscriptionUnderwritingIDState(
        {
          context: authContext,
          subscriptionUnderwritingID:
            selectedSubscriptionUnderwritingViewModel.subscriptionUnderwritingID,
          ignoreResolutionCount: true,
        },
      );
      setSelectedSubscriptionUnderwritingViewModel(undefined);
      setReadRequest({ ...readRequest });
      enqueueSnackbar("Subscription Underwriting State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving subscription Underwriting state`, e);
      enqueueSnackbar(
        `error resolving subscription Underwriting state: ${err.message}`,
        { variant: "error" },
      );
    }
    setResolutionInProgress(false);
  };

  const [fundingInProgress, setFundingInProgress] = useState(false);
  const handleFundSubscriptionUnderwriting = async () => {
    if (!selectedSubscriptionUnderwritingViewModel) {
      console.error("selected subscription underwriting view model not set");
      return;
    }

    setFundingInProgress(true);
    try {
      await SubscriptionUnderwritingStateController.FundSubscriptionUnderwritingID(
        {
          context: authContext,
          subscriptionUnderwritingID:
            selectedSubscriptionUnderwritingViewModel.subscriptionUnderwritingID,
        },
      );
      setSelectedSubscriptionUnderwritingViewModel(undefined);
      setReadRequest({ ...readRequest });
      enqueueSnackbar("Funding in Progress", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error performing funding`, e);
      enqueueSnackbar(`error performing funding: ${err}`, { variant: "error" });
    }
    setFundingInProgress(false);
  };

  const startingCriteria = useMemo(
    () =>
      props.subscriptionOrderBookID
        ? {
            subscriptionOrderBookID: TextExactCriterion(
              props.subscriptionOrderBookID,
            ),
          }
        : {},
    [],
  );
  const { readResponse, loading, setReadRequest, readRequest } =
    useMarketSubscriptionUnderwritingViewReaderRead(
      {
        context: authContext,
        query: new Query(initialQuery),
        criteria: startingCriteria,
      },
      props.system,
    );

  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [
    subscriptionUnderwritingStatusesCriterion,
    setSubscriptionUnderwritingStatusesCriterion,
  ] = useState<SubscriptionUnderwritingState[]>([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { number: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            subscriptionOrderBookNumber: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
          {
            assetName: TextSubstringCriterion(textSearchCriterionTextField),
          },
          {
            "maxTakeUpTokens.token.code": TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...startingCriteria };

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (subscriptionUnderwritingStatusesCriterion.length) {
      criteria.subscriptionUnderwritingState = TextListCriterion(
        subscriptionUnderwritingStatusesCriterion,
      );
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    subscriptionUnderwritingStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  return (
    <Root>
      {readResponse.models.length === 0 &&
      props.showEmptyCard &&
      !loading &&
      !subscriptionUnderwritingStatusesCriterion.length &&
      textSearchCriterionTextField === "" ? (
        <Card>
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              height: 166,
              backgroundColor: theme.palette.custom.grape,
            })}
          >
            <Typography
              align="center"
              variant="h4"
              fontWeight={"bold"}
              color="textSecondary"
            >
              No Underwriter Obligations Listed?
            </Typography>
            <Typography
              align="center"
              fontSize={16}
              color="textSecondary"
              maxWidth={228}
            >
              You will see a list once you have a new underwriter.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <BPTable
          style={props.style}
          singleSelect
          loading={
            loading ||
            fullUpdateLoading ||
            resolutionInProgress ||
            fundingInProgress
          }
          height={props.height - noticeBannerHeight}
          title={"Subscription Underwriters"}
          data={readResponse.models}
          onSingleSelectChange={(data) =>
            setSelectedSubscriptionUnderwritingViewModel(
              data as MarketSubscriptionUnderwritingViewModel,
            )
          }
          columns={(() => {
            const columns = [
              {
                label: "Number",
                field: "number",
              },
              {
                label: "Book",
                field: "subscriptionOrderBookNumber",
              },
              {
                label: "Asset Name",
                field: "assetName",
              },
              {
                label: "Underwriter",
                field: "ownerClientName",
              },
              {
                label: "Percentage",
                field: "percentage",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  return `${
                    (data as MarketSubscriptionUnderwritingViewModel).percentage
                  }%`;
                },
              },
              {
                label: "Max Amount",
                field: "maxTakeUpAmount.value.float",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  return (
                    <Amount
                      amount={
                        (data as MarketSubscriptionUnderwritingViewModel)
                          .maxTakeUpAmount
                      }
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  );
                },
              },
              {
                field: "currentObligation.value.float",
                label: "Current Obligation",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => {
                  return (
                    <Box sx={{ mr: 1 }}>
                      {(data as MarketSubscriptionUnderwritingViewModel)
                        .currentObligationAmount && (
                        <Amount
                          amount={
                            (data as MarketSubscriptionUnderwritingViewModel)
                              .currentObligationAmount
                          }
                        />
                      )}
                    </Box>
                  );
                },
              },
              {
                field: "fundedAmount.value.float",
                label: "Funded Amount",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => {
                  const model = data as MarketSubscriptionUnderwritingViewModel;
                  if (model.fundedAmount.isUndefined()) {
                    return <Typography sx={{ mr: 1 }}>-</Typography>;
                  } else {
                    return (
                      <Box sx={{ mr: 1 }}>
                        {(data as MarketSubscriptionUnderwritingViewModel)
                          .fundedAmount && (
                          <Amount
                            amount={
                              (data as MarketSubscriptionUnderwritingViewModel)
                                .fundedAmount
                            }
                          />
                        )}
                      </Box>
                    );
                  }
                },
              },
              {
                label: "State",
                field: "state",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => {
                  return (
                    <SubscriptionUnderwritingStateChip
                      state={
                        (data as MarketSubscriptionUnderwritingViewModel).state
                      }
                    />
                  );
                },
              },
              {
                label: "Res. Count",
                field: "resolutionCount",
              },
            ];

            const filterCols = props.hideColumns ?? [];

            return columns.filter((c) => !filterCols.includes(c.label));
          })()}
          expandRowComponent={
            mcView
              ? {
                  maxHeight: 300,
                  component: (selectedRowData) => {
                    const marketSubscriptionUnderwritingViewModel =
                      selectedRowData as MarketSubscriptionUnderwritingViewModel;
                    return (
                      <TransactionTable
                        height={400}
                        title={`Transactions for Subscription Underwriting No ${marketSubscriptionUnderwritingViewModel.number}`}
                        constantCriteria={{
                          "metaData.subscriptionUnderwritingID":
                            TextExactCriterion(
                              marketSubscriptionUnderwritingViewModel.subscriptionUnderwritingID,
                            ),
                        }}
                      />
                    );
                  },
                }
              : undefined
          }
          query={readRequest.query}
          onQueryChange={(query: Query) =>
            setReadRequest({
              ...readRequest,
              query,
            })
          }
          totalNoRecords={readResponse.total}
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (
              selectedSubscriptionUnderwritingViewModel &&
              viewConfiguration?.["Underwriting"]?.["Fund"] &&
              [
                SubscriptionUnderwritingState.AwaitingFunding,
                SubscriptionUnderwritingState.FundingFailed,
              ].includes(
                selectedSubscriptionUnderwritingViewModel.state as SubscriptionUnderwritingState,
              )
            ) {
              controls.push(
                <Button
                  disabled={fundingInProgress}
                  variant={"contained"}
                  color={"primary"}
                  children={"Fund"}
                  onClick={handleFundSubscriptionUnderwriting}
                />,
              );
            }

            if (
              selectedSubscriptionUnderwritingViewModel &&
              viewConfiguration?.["Subscription"]?.["Subscription Underwriting"]
                ?.ResolveState
            ) {
              controls.push(
                <Button
                  disabled={resolutionInProgress}
                  variant={"contained"}
                  color={"primary"}
                  children={"Resolve State"}
                  onClick={handleResolveSubscriptionUnderwritingState}
                />,
              );
            }

            if (
              viewConfiguration?.["Subscription"]?.["Subscription Underwriting"]
                ?.ViewModel?.FullUpdate
            ) {
              controls.push(
                <Button
                  variant={"contained"}
                  color={"primary"}
                  children={"Full Update"}
                  onClick={async () => {
                    setFullUpdateLoading(true);
                    try {
                      await MarketSubscriptionUnderwritingViewUpdater.FullUpdate(
                        {
                          context: authContext,
                        },
                      );
                      setReadRequest({ ...readRequest });
                      enqueueSnackbar("Full Update Complete", {
                        variant: "success",
                      });
                    } catch (e) {
                      const err = errorContextErrorTranslator.translateError(e);
                      console.error(
                        `error performing full update: ${
                          err.message ? err.message : err.toString()
                        }`,
                      );
                      enqueueSnackbar(
                        `Error Performing Full Update: ${
                          err.message ? err.message : err.toString()
                        }`,
                        { variant: "error" },
                      );
                    }
                    setFullUpdateLoading(false);
                  }}
                />,
              );
            }

            controls.push(
              <Tooltip title={"Refresh"}>
                <span>
                  <IconButton
                    onClick={() =>
                      setReadRequest({
                        ...readRequest,
                        query: new Query(initialQuery),
                      })
                    }
                    id={
                      "marketSubscriptionUnderwritingsTable-refresh-iconButton"
                    }
                    disabled={loading}
                    size={"small"}
                  >
                    <ReloadIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            );

            return controls;
          })()}
          filters={[
            <TextField
              id={"marketSubscriptionUnderwritingsTable-textFilter-textField"}
              variant={"outlined"}
              margin={"dense"}
              className={classes.textSearchField}
              label={"Search Text Field"}
              placeholder={"Start Typing..."}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: textSearchCriterionTextField ? (
                  <InputAdornment
                    position={"end"}
                    children={
                      <IconButton
                        id={
                          "marketSubscriptionUnderwritingsTable-textFilterClearButton-iconButton"
                        }
                        size={"small"}
                        onClick={() => setTextSearchCriterionTextField("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  />
                ) : undefined,
              }}
              value={textSearchCriterionTextField}
              onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
            />,
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id={
                "marketSubscriptionUnderwritingsTable-stateFilter-autocomplete"
              }
              disabled={loading}
              multiple
              options={AllSubscriptionUnderwritingStates}
              filterSelectedOptions
              onChange={(_, value: SubscriptionUnderwritingState[]) =>
                setSubscriptionUnderwritingStatusesCriterion(value)
              }
              ChipProps={{
                color: "info",
                size: "small",
                deleteIcon: (
                  <CancelIcon
                    sx={(theme) => ({
                      color: `${theme.palette.text.secondary} !important`,
                      "&:hover": {
                        color: `${theme.palette.secondary.contrastText} !important`,
                      },
                    })}
                  />
                ),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={
                    "marketSubscriptionUnderwritingsTable-stateFilter-autocompleteTextField"
                  }
                  className={classes.statusSelectFilterField}
                  label={"State"}
                  variant={"outlined"}
                  margin={"dense"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={
                    subscriptionUnderwritingStatusesCriterion.length
                      ? undefined
                      : "Select..."
                  }
                />
              )}
            />,
          ]}
          noDataSplashComponent={
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaceOutlined
                sx={(theme) => ({
                  fontSize: 110,
                  color: alpha(theme.palette.background.default, 0.5),
                })}
              />
              <Typography
                variant="h4"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.tertiary,
                  margin: theme.spacing(2, 0),
                })}
                children={
                  !subscriptionUnderwritingStatusesCriterion.length &&
                  textSearchCriterionTextField === ""
                    ? "No Underwriter Obligations Listed?"
                    : "No Underwriter Obligations Found"
                }
              />
              <Typography
                variant="h5"
                sx={{
                  width: "226px",
                  color: (theme) => theme.palette.text.disabled,
                  textAlign: "center",
                }}
                children={
                  !subscriptionUnderwritingStatusesCriterion.length &&
                  textSearchCriterionTextField === ""
                    ? "You will see a list once a subscription has been placed."
                    : "Please try using different search criteria"
                }
              />
            </Box>
          }
        />
      )}
    </Root>
  );
}
