import { useEffect, useRef, useState } from "react";
import {
  alpha,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { FETable } from "components/Table/FETable";
import { Model as AssetHolderViewModel } from "james/views/ledgerAssetHolderView";

import { HolderDetailsDialog } from "./HolderDetailsDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TextField } from "components/FormFields";
import ClearIcon from "@mui/icons-material/Clear";
import { FaceOutlined, ContentCopy } from "@mui/icons-material";
import { NoticeBanner } from "components/NoticeBanner/NoticeBanner";
import { Amount } from "components/Ledger/Amount";
import { useIsMounted } from "hooks";

export const SecuritiesRegisterTable = ({
  assetHolders,
}: {
  assetHolders: AssetHolderViewModel[];
}) => {
  const [data, setData] = useState<AssetHolderViewModel[]>(assetHolders);
  const [randInt, setRandInt] = useState(0);
  const [loadCard, setLoadCard] = useState(true);
  const [loading, setLoading] = useState(true);
  const [textFilter, setTextFilter] = useState("");
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const isMounted = useIsMounted();

  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        if (isMounted()) {
          setData(assetHolders);
          if (assetHolders.length === 0) {
            setLoadCard(true);
          } else {
            setLoadCard(false);
          }
        }
      } catch (e) {
        console.error("error reading smart instrument", e);
      }
      setLoading(false);
    }, 600);
  }, [assetHolders, isMounted]);

  const filteredData = data.filter((v) => {
    if (textFilter === "") return true;

    const lowerTextFilter = textFilter.toLocaleLowerCase();

    const matchesAccountNumber = v.accountNumber
      .toLocaleLowerCase()
      .includes(lowerTextFilter);
    const matchesHolderEmail = v.holderEmailAddress
      .toLocaleLowerCase()
      .includes(lowerTextFilter);
    const matchesHolderName = v.holderName
      .toLocaleLowerCase()
      .includes(lowerTextFilter);
    const matchesAccountLedgerID = v.accountLedgerID
      .toLocaleLowerCase()
      .includes(lowerTextFilter);

    return (
      matchesAccountNumber ||
      matchesHolderEmail ||
      matchesHolderName ||
      matchesAccountLedgerID
    );
  });

  return (
    <Box>
      {loadCard ? (
        <NoDataCard
          title={"No Asset Holders"}
          subTitle={["There aren't any asset holders"]}
          refresh={() => {
            setLoadCard(true);
          }}
          loading={loading}
        />
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          <NoticeBanner
            title="Please note that this Securities Register is in flux due to ongoing trading and/or transfer activity"
            titleAlign="center"
            icon="warning"
            color="#F57C00"
          />

          <FETable
            filters={[
              <TextField
                label="Text Search"
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                InputProps={{
                  endAdornment: textFilter ? (
                    <InputAdornment
                      position={"end"}
                      children={
                        <IconButton
                          id={
                            "financialDigitalInstrumentsTable-textFilterClearButton-iconButton"
                          }
                          size={"small"}
                          onClick={() => setTextFilter("")}
                        >
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  ) : undefined,
                }}
              />,
            ]}
            loading={loading}
            height={506}
            disableSelect
            columns={[
              // Holder Name
              {
                label: "Asset Holder",
                field: "holderName",
              },
              // Mesh Account Number
              {
                label: "Mesh Account",
                field: "accountNumber",
              },
              // Ledger Account ID
              {
                label: "Ledger Account",
                field: "accountLedgerID",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  const viewModel = data as AssetHolderViewModel;
                  return (
                    <>
                      <Box display="flex" justifyContent="start">
                        <Tooltip title={viewModel.accountLedgerID}>
                          <Typography noWrap sx={{ maxWidth: 180 }}>
                            {viewModel.accountLedgerID}
                          </Typography>
                        </Tooltip>
                        {window.isSecureContext && (
                          <Button
                            onClick={() =>
                              navigator.clipboard.writeText(
                                viewModel.accountLedgerID,
                              )
                            }
                            size="small"
                            aria-label="copy"
                            sx={{
                              minWidth: "unset",
                              padding: 0,
                              mx: 2,
                              lineHeight: 1,
                            }}
                          >
                            <ContentCopy fontSize="small" />
                          </Button>
                        )}
                      </Box>
                    </>
                  );
                },
              },
              // Amount Held
              {
                label: "Units Held",
                field: "amountHeld",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  const viewModel = data as AssetHolderViewModel;
                  return (
                    <Amount
                      rootStyles={{
                        "& > .Amount-tokenAmountIssuer": {
                          display: "none",
                        },
                      }}
                      amount={viewModel.amountHeld}
                    />
                  );
                },
              },
              // Email Address
              {
                label: "Contact Email",
                field: "holderEmailAddress",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  const viewModel = data as AssetHolderViewModel;
                  return (
                    <Typography sx={{ maxWidth: 160 }} noWrap>
                      {viewModel.holderEmailAddress}
                    </Typography>
                  );
                },
              },
              // Address
              {
                label: "Details",
                field: "address.countryCode",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  const viewModel = data as AssetHolderViewModel;
                  return <AddressColumn viewModel={viewModel} />;
                },
              },
            ]}
            data={filteredData}
            title={"Securities Register"}
            toolBarControls={[
              <IconButton
                disabled={loading}
                onClick={() => {
                  setRandInt(randInt + 1);
                }}
              >
                <RefreshIcon />
              </IconButton>,
            ]}
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            noDataSplashComponent={
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaceOutlined
                  sx={(theme) => ({
                    fontSize: 110,
                    color: alpha(theme.palette.background.default, 0.5),
                  })}
                />
                <Typography
                  variant="h4"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    color: theme.palette.text.tertiary,
                    margin: theme.spacing(2, 0),
                  })}
                  children={"No Asset Holders Found"}
                />
                <Typography
                  variant="h5"
                  sx={{
                    width: "226px",
                    color: (theme) => theme.palette.text.disabled,
                    textAlign: "center",
                  }}
                  children={"Please try using different search criteria"}
                />
              </Box>
            }
          />
        </Box>
      )}
    </Box>
  );
};

interface AddressColumnProps {
  viewModel: AssetHolderViewModel;
}

const AddressColumn = ({ viewModel }: AddressColumnProps) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Box>
      <HolderDetailsDialog
        closeDialog={() => setShowDialog(false)}
        open={showDialog}
        assetHolderView={viewModel}
      />
      <Typography
        component={Link}
        underline="none"
        onClick={() => setShowDialog(!showDialog)}
      >
        View
      </Typography>
    </Box>
  );
};

interface NoDataCardProps {
  title: string;
  subTitle: string[];
  refresh?: () => void;
  loading?: boolean;
}
export const NoDataCard = ({
  title,
  subTitle,
  refresh,
  loading,
}: NoDataCardProps) => (
  <Card
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      textAlign: "center",
      backgroundColor: theme.palette.custom.grape,
      height: 166,
      position: "relative",
    })}
  >
    {refresh && (
      <IconButton
        sx={{ position: "absolute", top: 4, right: 8 }}
        onClick={refresh}
      >
        <RefreshIcon />
      </IconButton>
    )}
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <Typography
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          variant="h3"
          children={title}
          color="textSecondary"
        />
        {subTitle.map((text, idx) => {
          return (
            <Typography
              key={idx}
              variant="body1"
              children={text}
              color="textSecondary"
            />
          );
        })}
      </>
    )}
  </Card>
);
