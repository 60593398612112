import { Company } from "james/legal/company";
import { LuhnAlgorithm } from "utilities/validation";

export const FormValidator = (field: string, value: string): string[] => {
  const errorMessages: string[] = [];
  switch (field) {
    case "taxReferenceNumber":
      if (String(value) === "") {
        break;
      } else if (value.length !== 10) {
        errorMessages.push("Tax reference number must be 10 digits");
      } else if (value !== "" && !LuhnAlgorithm(value)) {
        errorMessages.push("Tax reference number is invalid");
      }
      break;
    case "vatRegistrationNumber":
      if (String(value) === "") {
        break;
      } else if (String(value).length !== 10) {
        errorMessages.push("Vat registration number must be 10 digits");
      } else if (String(value) !== "" && !LuhnAlgorithm(String(value))) {
        errorMessages.push("Vat registration number is invalid");
      }
      break;
    default:
  }
  return errorMessages;
};

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  values: Company,
  setFormFieldValid?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let InvalidFormFields = {};

  if (values.countryOfIncorporation === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      countryOfIncorporation: "Country of incorporation not provided",
    };
  }

  if (
    !(
      String(values.taxReferenceNumber).length === 10 ||
      String(values.taxReferenceNumber) === ""
    )
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      taxReferenceNumber: "Tax reference number must be 10 digits",
    };
  } else if (
    String(values.taxReferenceNumber).length !== 0 &&
    !LuhnAlgorithm(String(values.taxReferenceNumber))
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      taxReferenceNumber: "Tax Reference number is invalid",
    };
  }

  if (
    !(
      String(values.vatRegistrationNumber).length === 10 ||
      String(values.vatRegistrationNumber) === ""
    )
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      vatRegistrationNumber: "Vat registration number must be 10 digits",
    };
  } else if (
    String(values.vatRegistrationNumber).length !== 0 &&
    !LuhnAlgorithm(String(values.vatRegistrationNumber))
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      vatRegistrationNumber: "Vat registration number is invalid",
    };
  }

  if (setFormFieldValid) {
    setFormFieldValid(InvalidFormFields);
  }

  return !Object.keys(InvalidFormFields).length;
};
