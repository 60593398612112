import { IdentifierType } from ".";

export interface LedgerIDIdentifierType {
  type: IdentifierType;
  ledgerID: string;
}

function LedgerIDIdentifier(ledgerID: string): LedgerIDIdentifierType {
  return {
    type: IdentifierType.LedgerIDIdentifierTypeName,
    ledgerID,
  };
}

export { LedgerIDIdentifier };
