import React, { useMemo } from "react";
import {
  Box,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ParticipantProfile } from "james/ledger/AssetParticipants";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import cx from "classnames";

interface ParticpantSectionProps {
  logoUrl: string;
  participantProfile: ParticipantProfile;
  issuerSection?: boolean;
  rightAlignSocialMedia?: boolean;
}

export const ParticipantSection = ({
  participantProfile,
  logoUrl,
  issuerSection = false,
  rightAlignSocialMedia = false,
}: ParticpantSectionProps) => {
  const profile = participantProfile;
  const issuerWebsite = useMemo((): string => {
    if (!profile.webLink) return "";
    try {
      return new URL(profile.webLink).hostname.replace("www.", "");
    } catch {
      return profile.webLink;
    }
  }, [profile.webLink]);

  const hasSocialMedia =
    profile.twitterLink ||
    profile.linkedInLink ||
    profile.facebookLink ||
    profile.instagramLink ||
    profile.youtubeLink;

  const hasDetails = profile.contactNumber || profile.email || profile.webLink;

  const category = !(participantProfile.category === "Undefined")
    ? participantProfile.category + ":"
    : "Issuer:";

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const hasDescription = profile.description && profile.description[0] !== "";

  return (
    <Box sx={{ px: { sm: 5, xs: 3 }, py: 4 }}>
      <Box>
        <Typography
          sx={(theme) => ({
            fontSize: "12px",
            lineHeight: "20px",
            color: theme.palette.warning.light,
          })}
        >
          {category}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: 1,
          }}
        >
          {profile.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { sm: "flex-start" },
            width: "100%",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
          }}
        >
          {hasDescription &&
            profile.description.map((desc, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  className={cx({
                    issuerSection: issuerSection,
                  })}
                  key={index}
                  variant="body1"
                  sx={(theme) => ({
                    my: 2,
                    maxWidth: "466px",
                    color: theme.palette.text.secondary,
                    "&.issuerSection": {
                      maxWidth: "466px",
                    },
                  })}
                >
                  {desc}
                </Typography>
              </Box>
            ))}
          {(profile.logoUrl || logoUrl) && issuerSection && !smDown && (
            <Box
              minWidth={206}
              maxHeight={148}
              maxWidth={206}
              overflow={"hidden"}
              sx={{
                display: "block",
                my: 2,
                textAlign: hasDescription ? "right" : "left",
              }}
            >
              <Box
                component={"img"}
                maxHeight={148}
                maxWidth={206}
                src={logoUrl !== "" ? logoUrl : profile.logoUrl}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: { sm: 1, xs: 2 },
        }}
      >
        {hasDetails && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
              gap: 2,
            }}
          >
            {profile.webLink && (
              <Box className="infoBlock">
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                  })}
                >
                  Website
                </Typography>
                <Typography
                  component={Link}
                  target="_blank"
                  underline="none"
                  href={
                    profile.webLink.includes("http")
                      ? profile.webLink
                      : `https://${profile.webLink}`
                  }
                  sx={(theme) => ({
                    fontSize: 16,
                    color: theme.palette.secondary.main,
                  })}
                >
                  {issuerWebsite}
                </Typography>
              </Box>
            )}
            {profile.contactNumber && (
              <Box className="infoBlock">
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                  })}
                >
                  Contact Number
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  {profile.contactNumber}
                </Typography>
              </Box>
            )}
            {!(profile.address === "") && (
              <Box className="infoBlock">
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                  })}
                >
                  Address
                </Typography>
                <Typography
                  sx={{ fontSize: 16 }}
                >{`${profile.address.toString()} `}</Typography>
              </Box>
            )}
            {profile.email && (
              <Box className="infoBlock">
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                  })}
                >
                  Email
                </Typography>
                <Link
                  component={Link}
                  underline="hover"
                  href={`mailto:${profile.email}`}
                  sx={{ fontSize: 16 }}
                >
                  {profile.email}
                </Link>
              </Box>
            )}
            {issuerSection && !smDown && hasSocialMedia && (
              <SocialMediaLinks
                profile={profile}
                rightAlignSocialMedia={rightAlignSocialMedia}
              />
            )}
          </Box>
        )}
        {((!issuerSection && !smDown) || smDown) &&
        (profile.logoUrl || hasSocialMedia) ? (
          <Box sx={{ display: "flex", pt: 2, alignItems: "center" }}>
            {profile.logoUrl && (
              <Box
                overflow={"hidden"}
                marginRight={"auto"}
                sx={{
                  // FIXME: Weird distortion with this disabled
                  // minHeight: { sm: 110, xs: 42 },
                  // minWidth: { sm: 146 },
                  maxHeight: { sm: 110, xs: 42 },
                  maxWidth: { sm: 146 },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={profile.logoUrl}
                  sx={{
                    maxHeight: { sm: 110, xs: 42 },
                    maxWidth: { sm: 146, xs: 120 },
                  }}
                />
              </Box>
            )}
            {hasSocialMedia && (
              <SocialMediaLinks
                profile={profile}
                rightAlignSocialMedia={rightAlignSocialMedia}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

interface SocialMediaLinksProps {
  profile?: ParticipantProfile;
  rightAlignSocialMedia: boolean;
}

const isFullURL: (url: string) => boolean = (url: string) => {
  return url.includes(".com") || url.includes("http") || url.includes("https");
};

const SocialMediaLinks = ({
  profile,
  rightAlignSocialMedia,
}: SocialMediaLinksProps) => {
  if (!profile) return;
  const moreThenFourIcons =
    (profile.twitterLink ? 1 : 0) +
      (profile.linkedInLink ? 1 : 0) +
      (profile.facebookLink ? 1 : 0) +
      (profile.instagramLink ? 1 : 0) <
    4;

  return (
    <Box
      className={cx({
        rightAlignSocialMedia: rightAlignSocialMedia,
      })}
      sx={{
        "&.rightAlignSocialMedia": {
          ml: { sm: "auto", xs: 0 },
        },
      }}
    >
      {profile.twitterLink && (
        <IconButton
          target="_blank"
          href={
            isFullURL(profile.twitterLink)
              ? profile.twitterLink
              : `https://twitter.com/${profile.twitterLink}`
          }
        >
          <XIcon
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              height: 24,
              width: 24,
            })}
          />
        </IconButton>
      )}
      {profile.linkedInLink && (
        <IconButton
          target="_blank"
          href={
            isFullURL(profile.linkedInLink)
              ? profile.linkedInLink
              : `https://www.linkedin.com/company/${profile.linkedInLink}`
          }
        >
          <LinkedInIcon
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              height: 24,
              width: 24,
            })}
          />
        </IconButton>
      )}
      {profile.facebookLink && (
        <IconButton
          target="_blank"
          href={
            isFullURL(profile.facebookLink)
              ? profile.facebookLink
              : `https://www.facebook.com/${profile.facebookLink}`
          }
        >
          <FacebookRoundedIcon
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              height: 24,
              width: 24,
            })}
          />
        </IconButton>
      )}
      {profile.instagramLink && (
        <IconButton
          target="_blank"
          href={
            isFullURL(profile.instagramLink)
              ? profile.instagramLink
              : `https://www.instagram.com/${profile.instagramLink}`
          }
        >
          <InstagramIcon
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              height: 24,
              width: 24,
            })}
          />
        </IconButton>
      )}
      {profile.youtubeLink && moreThenFourIcons && (
        <IconButton
          target="_blank"
          href={
            isFullURL(profile.youtubeLink)
              ? profile.youtubeLink
              : `https://www.youtube.com/${profile.youtubeLink}`
          }
        >
          <YouTubeIcon
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              height: 24,
              width: 24,
            })}
          />
        </IconButton>
      )}
    </Box>
  );
};
