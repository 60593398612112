export const ModelTypeName = "mesh::stellarAccountStubView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  accountID = "";

  accountNumber = "";

  accountOwnerGroupName = "";

  accountOwnerClientName = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.accountID = model.accountID;
    this.accountNumber = model.accountNumber;
    this.accountOwnerGroupName = model.accountOwnerGroupName;
    this.accountOwnerClientName = model.accountOwnerClientName;
  }
}
