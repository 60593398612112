import { ValidationResult } from "../../common/validation";
import { FormData } from "./WaitingListPopUp";
import { ValidateEmail } from "../../utilities/validation";

export type FormDataUpdaterSpecsType = {
  email: (email: string, prevRequest?: FormData) => FormData;
  phoneNumber: (phoneNumber: string, prevRequest?: FormData) => FormData;
  name: (name: string, prevRequest?: FormData) => FormData;
  range: (range: string, prevRequest?: FormData) => FormData;
  isChecked: (isChecked: boolean, prevRequest?: FormData) => FormData;
  surname: (surname: string, prevRequest?: FormData) => FormData;
  isPublic: (isPublic: boolean, prevRequest?: FormData) => FormData;
};

export const formDataUpdaterSpecs: FormDataUpdaterSpecsType = {
  name(name: string, prevRequest: FormData | undefined): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      name: name,
    };
  },
  surname(surname: string, prevRequest: FormData | undefined): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      surname: surname,
    };
  },
  email(email: string, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      email: email,
    };
  },
  phoneNumber(phoneNumber: string, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      phoneNumber: phoneNumber,
    };
  },
  range(range: string, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      range: range,
    };
  },
  isChecked(isChecked: boolean, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      isChecked: isChecked,
    };
  },
  isPublic(isPublic: boolean, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      isPublic: isPublic,
    };
  },
};

// checkCellphoneNumberValid checks if the given cellphone number is valid according to the following rules:
// - the cellphone number should be either 10 or 9 digits long
// - if the cellphone number is 10 digits long:
//   - it must start with a zero - the next digit CANNOT be a zero
// - if the cellphone number is 9 digits long:
//   - it CANNOT start witha zero
// any other cellphone numbers are invalid.
// NOTE: the assumption is that the given cellphone number has been run through the processCellphoneNumber function.
const checkCellphoneNumberValid: (cellphoneNumber: string) => boolean = (
  cellphoneNumber: string,
) => {
  switch (cellphoneNumber.length) {
    case 9:
      // is valid if the first character is not a 0
      return cellphoneNumber.charAt(0) !== "0";
    case 10:
      // is valid if the first character is a 0 AND
      // the second numer is not a 0
      return (
        cellphoneNumber.charAt(0) === "0" && cellphoneNumber.charAt(1) !== "0"
      );
    default:
      // in all other situations the cellphone number is not valid
      return false;
  }
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  const validationResult: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  // if the given cellphone number is not valid
  if (!checkCellphoneNumberValid(formData.phoneNumber)) {
    // then validation has failed
    validationResult.valid = false;
    // and a form field error message should be shown
    validationResult.fieldValidations.phoneNumber =
      "Enter a valid mobile phone number";
  }

  if (formData.isPublic) {
    if (formData.email === "") {
      validationResult.fieldValidations.email = "Email cannot be empty";
      validationResult.valid = false;
    } else if (!ValidateEmail(formData.email)) {
      validationResult.fieldValidations.email = "Email is invalid";
      validationResult.valid = false;
    }
    if (formData.name === "") {
      validationResult.fieldValidations.name = "Name cannot be empty";
      validationResult.valid = false;
    }
    if (formData.surname === "") {
      validationResult.fieldValidations.surname = "Surname cannot be empty";
      validationResult.valid = false;
    }
  }
  if (!formData.isChecked) {
    validationResult.valid = false;
  }
  if (formData.range === "") {
    validationResult.fieldValidations.range = "Range cannot be empty";
    validationResult.valid = false;
  }
  return validationResult;
};
