/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { BPTable } from "components/Table";
import { Column } from "components/Table/BPTable/BPTable";
import { FaceOutlined, Cancel, Info } from "@mui/icons-material";
import {
  Model,
  useMarketSubscriptionUnderwritingViewReaderRead,
} from "james/views/marketSubscriptionUnderwritingView";
import { NewSorting, Query } from "james/search/query";
import { useApplicationContext } from "context/Application/Application";
import { Amount } from "components/Ledger/Amount";
import { useNavigate } from "react-router-dom";
import { SubscriptionUnderwritingStateChip } from "components/MarketSubscriptions/Chips";
import {
  AllSubscriptionUnderwritingStates,
  SubscriptionUnderwritingState,
} from "james/market/SubscriptionUnderwriting";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import { DateField } from "components/FormFields";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { dateIsValid } from "utilities/date";
import { DateRangeCriterion } from "pkgTemp/search/criterion";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import dayjs from "dayjs";
import { CommitFundsDialog } from "./CommitFundsDialog";
import RefreshIcon from "@mui/icons-material/Refresh";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export const Obligations = () => {
  const { authContext } = useApplicationContext();
  const [commitFundsDialog, setCommitFundsDialog] = useState(false);
  const [selectedUnderwritings, setSelectedUnderwritings] = useState<Model[]>();
  const navigate = useNavigate();
  const [criteria, setCriteria] = useState<Record<string, any>>({});
  const [criteriaValues, setCriteriaValues] = useState<Record<string, any>>({
    number: "",
    assetName: "",
    issuerName: "",
    state: [],
  });
  const [query, setQuery] = useState(new Query(initialQuery));
  const { NotificationBannerHeight } = useAppNoticeContext();

  const [startDate, setStartDate] = useState<DateRangeValue | undefined>(
    undefined,
  );
  const [endDate, setEndDate] = useState<DateRangeValue | undefined>(undefined);

  const { readResponse, setReadRequest, loading } =
    useMarketSubscriptionUnderwritingViewReaderRead(
      {
        context: authContext,
        query: query,
        criteria: {},
      },
      false,
    );

  useEffect(() => {
    setReadRequest({
      context: authContext,
      query: query,
      criteria: criteria,
    });
  }, [criteria, query]);

  useEffect(() => {
    if (startDate || endDate) {
      setCriteria({
        ...criteria,
        issueDate: DateRangeCriterion(startDate, endDate),
      });
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { issueDate, ...rest } = criteria;
    setCriteria(rest);
  }, [startDate, endDate]);

  const canFund = useMemo(() => {
    if (selectedUnderwritings?.length !== 1) return false;
    return ![
      SubscriptionUnderwritingState.AwaitingSettlement,
      SubscriptionUnderwritingState.Settled,
      SubscriptionUnderwritingState.RefundUnderInvestigation,
      SubscriptionUnderwritingState.FundingInProgress,
      SubscriptionUnderwritingState.FundingUnderInvestigation,
      SubscriptionUnderwritingState.ZeroObligation,
      "",
    ].includes(selectedUnderwritings[0].state);
  }, [selectedUnderwritings]);

  const filterFunc = (
    key: string,
    value: string | string[],
    criterion?: any,
  ) => {
    setCriteriaValues({
      ...criteriaValues,
      [key]: value,
    });
    if (!value || value.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: _, ...rest } = criteria;
      setCriteria(rest);
      return;
    }
    setCriteria({
      ...criteria,
      [key]: criterion,
    });
  };

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <BPTable
        columns={columns}
        query={query}
        loading={loading}
        onQueryChange={(q) => setQuery(new Query(q))}
        height={window.innerHeight - 56 - 48 - NotificationBannerHeight}
        onSelectedDataChange={(models) => {
          setSelectedUnderwritings(models as Model[]);
        }}
        toolBarControls={[
          ...(selectedUnderwritings?.length === 1
            ? [
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(
                      `/market/asset-overview?code=${selectedUnderwritings[0].maxTakeUpTokens.token.code}&issuer=${selectedUnderwritings[0].maxTakeUpTokens.token.issuer}&network=${selectedUnderwritings[0].maxTakeUpTokens.token.network}`,
                    );
                  }}
                >
                  View Asset
                </Button>,
                canFund && (
                  <Button
                    variant="outlined"
                    onClick={() => setCommitFundsDialog(!commitFundsDialog)}
                  >
                    Commit Funds
                  </Button>
                ),
              ]
            : []),
          <IconButton
            onClick={() => {
              setCriteria({});
              setCriteriaValues({
                number: "",
                assetName: "",
                issuerName: "",
                state: [],
              });
              setQuery(initialQuery);
            }}
          >
            <RefreshIcon />
          </IconButton>,
        ]}
        title={"Underwriting Obligations"}
        data={readResponse.models}
        totalNoRecords={readResponse.total}
        noDataSplashComponent={<NoDataSplash />}
        filters={[
          <TextField
            label="Number"
            value={criteriaValues.number}
            onChange={(e) => {
              filterFunc(
                "number",
                e.target.value,
                TextSubstringCriterion(e.target.value),
              );
            }}
            InputProps={{
              endAdornment: (() => (
                <>
                  {criteria.number && (
                    <Tooltip title="Clear" placement="top">
                      <IconButton
                        size="small"
                        onClick={() => filterFunc("number", "", undefined)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ))(),
            }}
          />,
          <TextField
            label="Issuer"
            value={criteriaValues.issuerName}
            onChange={(e) =>
              filterFunc(
                "issuerName",
                e.target.value,
                TextSubstringCriterion(e.target.value),
              )
            }
            InputProps={{
              endAdornment: (() => (
                <>
                  {criteria.issuerName && (
                    <Tooltip title="Clear" placement="top">
                      <IconButton
                        size="small"
                        onClick={() => filterFunc("issuerName", "", undefined)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ))(),
            }}
          />,
          <TextField
            label="Asset"
            value={criteriaValues.assetName}
            onChange={(e) =>
              filterFunc(
                "assetName",
                e.target.value,
                TextSubstringCriterion(e.target.value),
              )
            }
            InputProps={{
              endAdornment: (() => (
                <>
                  {criteria.assetName && (
                    <Tooltip title="Clear" placement="top">
                      <IconButton
                        size="small"
                        onClick={() => filterFunc("assetName", "", undefined)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ))(),
            }}
          />,

          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <DateField
              label="From Issue Date"
              nullable
              id="directOrdersTable-orderDateTimeFrom-dateField"
              value={startDate ? startDate?.date : null}
              onChange={(newValue) => {
                if (newValue?.isAfter(endDate?.date ?? dayjs())) {
                  setStartDate(undefined);
                } else if (!(newValue && dateIsValid(newValue))) {
                  setStartDate(undefined);
                } else {
                  setStartDate(
                    newValue
                      ? {
                          date: newValue.startOf("day").format(),
                          inclusive: true,
                          ignore: false,
                        }
                      : undefined,
                  );
                }
              }}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (() => (
                      <>
                        {startDate && (
                          <Tooltip title="Clear" placement="top">
                            <IconButton
                              size="small"
                              onClick={() => setStartDate(undefined)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {textFieldProps.InputProps &&
                        textFieldProps.InputProps.endAdornment
                          ? textFieldProps.InputProps.endAdornment
                          : null}
                      </>
                    ))(),
                  }}
                />
              )}
            />
            <DateField
              label="To Issue Date"
              id="directOrdersTable-orderDateTimeFrom-dateField"
              value={endDate ? endDate.date : null}
              nullable
              onChange={(newValue) => {
                if (newValue?.isBefore(startDate?.date ?? dayjs())) {
                  setEndDate(undefined);
                } else if (!(newValue && dateIsValid(newValue))) {
                  setEndDate(undefined);
                } else {
                  setEndDate(
                    newValue
                      ? {
                          date: newValue.startOf("day").format(),
                          inclusive: true,
                          ignore: false,
                        }
                      : undefined,
                  );
                }
              }}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (() => (
                      <>
                        {endDate && (
                          <Tooltip title="Clear" placement="top">
                            <IconButton
                              size="small"
                              onClick={() => setEndDate(undefined)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {textFieldProps.InputProps &&
                        textFieldProps.InputProps.endAdornment
                          ? textFieldProps.InputProps.endAdornment
                          : null}
                      </>
                    ))(),
                  }}
                />
              )}
            />
          </Box>,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            filterSelectedOptions
            value={criteriaValues.state}
            sx={{ width: 560 }}
            options={AllSubscriptionUnderwritingStates}
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              value: SubscriptionUnderwritingState[] | null,
            ) =>
              filterFunc("state", value ?? [], TextListCriterion(value ?? []))
            }
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <Cancel
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  criteria.state && criteria.state.length
                    ? undefined
                    : "Select..."
                }
              />
            )}
          />,
        ]}
      />
      {selectedUnderwritings?.length === 1 && commitFundsDialog && (
        <CommitFundsDialog
          open={commitFundsDialog}
          setCommitFundsDialog={setCommitFundsDialog}
          underWritingViewModel={selectedUnderwritings[0]}
        />
      )}
    </Box>
  );
};

const columns: Column[] = [
  {
    label: "Number",
    field: "number",
  },
  {
    field: "issuerName",
    label: "Issuer",
  },
  {
    field: "assetName",
    label: "Asset",
  },
  {
    field: "issueDate",
    label: "Issue Date",
    accessor: (data: { [key: string]: any }) => {
      return (
        <Typography sx={{ mr: 1 }}>
          {dayjs((data as Model).issueDate).format("YYYY-MM-DD")}
        </Typography>
      );
    },
  },
  {
    field: "maxTakeUpAmount.value.float",
    label: "Total Obligation",
    accessor: (data: { [key: string]: any }) => {
      return (
        <Box sx={{ mr: 1 }}>
          <Amount amount={(data as Model).maxTakeUpAmount} />
        </Box>
      );
    },
  },
  {
    field: "currentObligation.value.float",
    label: "Current Obligation",
    accessor: (data: { [key: string]: any }) => {
      return (
        <Box sx={{ mr: 1 }}>
          {(data as Model).currentObligationAmount && (
            <Amount amount={(data as Model).currentObligationAmount} />
          )}
        </Box>
      );
    },
  },
  {
    field: "fundedAmount.value.float",
    label: "Funded Amount",
    accessor: (data: { [key: string]: any }) => {
      const model = data as Model;
      if (model.fundedAmount.isUndefined()) {
        return <Typography sx={{ mr: 1 }}>-</Typography>;
      } else {
        return (
          <Box sx={{ mr: 1 }}>
            {(data as Model).fundedAmount && (
              <Amount amount={(data as Model).fundedAmount} />
            )}
          </Box>
        );
      }
    },
  },
  {
    field: "state",
    label: "State",
    accessor: (data: { [key: string]: any }) => {
      const model = data as Model;
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mr: 1 }}>
          <SubscriptionUnderwritingStateChip state={model.state} />
          {model.failureReasons && model.failureReasons.length > 0 && (
            <Tooltip
              title={
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                  }}
                >
                  {model.failureReasons.map((r, idx) => {
                    return <Typography key={idx}>- {r}</Typography>;
                  })}
                </Box>
              }
            >
              <Info />
            </Tooltip>
          )}
        </Box>
      );
    },
  },
];

const NoDataSplash = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
      }}
    >
      <FaceOutlined
        sx={(theme) => ({
          fontSize: 110,
          color: alpha(theme.palette.background.default, 0.5),
          mb: 2,
        })}
      />
      <Typography variant="h3">No Obligations Yet?</Typography>
      <Typography
        sx={(theme) => ({
          maxWidth: 400,
          mt: 2,
          color: theme.palette.text.secondary,
        })}
      >
        You will see a list once you are appointed as underwriter (to one or
        more instruments)
      </Typography>
    </Box>
  );
};
