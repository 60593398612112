import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

interface AssignRolesToUserRequest {
  context: Context;
  roleIDs: string[];
  userID: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssignRolesToUsersResponse {}

interface RemoveRolesFromUserRequest {
  context: Context;
  roleIDs: string[];
  userID: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveRolesFromUserResponse {}

interface InviteUserRequest {
  context: Context;
  email: string;
  roleIDs: string[];
  ownerID: string;
  clientID: string;
  firstName: string;
  lastName: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InviteUserResponse {}

export const ManagerWrite = {
  serviceProvider: "user-ManagerWrite",
  async RemoveRolesFromUser(
    request: RemoveRolesFromUserRequest,
    additionalHeaders?: Headers,
  ): Promise<RemoveRolesFromUserResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ManagerWrite.serviceProvider}.RemoveRolesFromUser`,
      request,
    });
  },
  async AssignRolesToUser(
    request: AssignRolesToUserRequest,
    additionalHeaders?: Headers,
  ): Promise<AssignRolesToUsersResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ManagerWrite.serviceProvider}.AssignRolesToUser`,
      request,
    });
  },
  async InviteUser(
    request: InviteUserRequest,
    additionalHeaders?: Headers,
  ): Promise<InviteUserResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ManagerWrite.serviceProvider}.InviteUser`,
      request,
    });
  },
};
