import { Notification } from "../notification/Notification";

export const AccountOpenedNotificationTypeName =
  "mesh::stellar/AccountOpenedNotification";

export class AccountOpenedNotification implements Notification {
  public ["@type"]: string = AccountOpenedNotificationTypeName;

  public accountLedgerID = "";

  constructor(n?: AccountOpenedNotification) {
    if (!n) {
      return;
    }
    this.accountLedgerID = n.accountLedgerID;
  }

  notificationType(): string {
    return AccountOpenedNotificationTypeName;
  }
}
