import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useWindowSize } from "utilities/general";
import { NewSorting, Query } from "james/search/query";
import {
  Model as DirectOrderModel,
  Reader,
} from "james/views/marketDirectOrderView";
import { TableMode } from "../../../Table";
import { LoadingRow } from "./LoadingRow";
import { NoDataSplash } from "./NoDataSplash";
import { OrderScrollCard } from "./OrderScrollCard";
import { InfiniteScrollList } from "components/Table/InfCardTable";
import { FancyTableModeButton } from "views/Marketplace/components/Orders/components/FancyTableModeButton";
import { tableModeInitialCriteriaIdx } from "../../../Orders";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

interface AwaitingConfirmationScrollListProps {
  setSelectedOrder: Dispatch<SetStateAction<DirectOrderModel | undefined>>;
  changeTableMode: (updatedTableMode: TableMode) => void;
}

export function AwaitingConfirmationScrollList({
  setSelectedOrder,
  changeTableMode,
}: AwaitingConfirmationScrollListProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, windowHeight] = useWindowSize();
  const { authContext } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [awaitingConfirmation, setAwaitingConfirmation] = useState<
    DirectOrderModel[]
  >([]);
  const [awaitingConfirmationTotal, setAwaitingConfirmationTotal] = useState(0);
  const { NotificationBannerHeight } = useAppNoticeContext();

  const loadMoreItems =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (criteria: any, query: Query, updateOffset: () => void) => async () => {
      setLoading(true);

      try {
        const response = await Reader.Read({
          context: authContext,
          query,
          criteria,
        });

        updateOffset();

        setAwaitingConfirmation([...awaitingConfirmation, ...response.models]);

        setAwaitingConfirmationTotal(response.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading market spot view models: ${
            err.message ? err.message : err.toString()
          }`,
          enqueueSnackbar(
            `Error reading market spot view models:: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          ),
        );
      }
      setLoading(false);
    };

  const handleChangeTableMode = () => {
    changeTableMode(TableMode.history);
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(0, 2, 0, 2),
        width: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          height: "64px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: theme.spacing(2),
        })}
      >
        <FancyTableModeButton
          tableMode={TableMode.awaitingConfirmation}
          onTableModeChange={handleChangeTableMode}
        />
      </Box>
      <InfiniteScrollList
        rowWidth={windowWidth - 32}
        rowHeight={220}
        rowGap={16}
        listHeight={
          // 75: mobile nav bar
          // 56: main app bar
          // 86: fancy filter button & co
          windowHeight - 75 - 56 - 86 - 48 - NotificationBannerHeight
        }
        disableMeshScroll={true}
        hideScrollbars={true}
        lastRowPadding={24}
        initialSorting={[NewSorting("id", "asc")]}
        initialCriteria={
          tableModeInitialCriteriaIdx[TableMode.awaitingConfirmation]
        }
        renderLoadingRow={<LoadingRow />}
        noDataSplashComponent={
          <NoDataSplash
            text="No Orders Yet?"
            subText="You will see a list once you have a new order."
          />
        }
        total={awaitingConfirmationTotal}
        loadMoreData={loadMoreItems}
        data={awaitingConfirmation}
        clearData={() => {
          setAwaitingConfirmation([]);
        }}
        isLoading={loading}
        renderData={function (data): ReactNode {
          return (
            <OrderScrollCard
              data={data}
              tableMode={TableMode.awaitingConfirmation}
              onClick={() => {
                setSelectedOrder(data);
              }}
            />
          );
        }}
        children={() => {
          return (
            <Box
              sx={(theme) => ({
                padding: theme.spacing(1, 0),
              })}
            />
          );
        }}
      />
    </Box>
  );
}
