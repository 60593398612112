import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { UnaryInterceptor, Request, UnaryResponse } from "grpc-web";

/**
 * `SimulatedTimeInterceptor` is a class responsible for intercepting requests and appending a simulated time metadata entry.
 */
export class SimulatedTimeInterceptor
  implements UnaryInterceptor<unknown, unknown>
{
  /** Whether simulated time is enabled and should be passed with request */
  private simulatedTimeEnabled: boolean;

  /** Holds the currentTime when simulated time is enabled */
  private currentTime: Timestamp;

  constructor(simulatedTimeEnabled: boolean, currentTime: Timestamp) {
    this.simulatedTimeEnabled = simulatedTimeEnabled;
    this.currentTime = currentTime;
  }

  /**
   * Intercepts a request to add the simulated time metadata entry to its metadata.
   *
   * @param {Object} request - The request object.
   * @param {Function} request.getMetadata - A function that returns the request's metadata.
   * @param {unknown} invoker - A function responsible for processing the request.
   *                            Expected to be of type `function`, but can be of any type.
   *
   * @returns {unknown} The result of the `invoker` function, if it is a function.
   *                    Otherwise, logs an error and doesn't return anything.
   */
  async intercept(
    request: Request<unknown, unknown>,
    invoker: (
      request: Request<unknown, unknown>,
    ) => Promise<UnaryResponse<unknown, unknown>>,
  ): Promise<UnaryResponse<unknown, unknown>> {
    // if not enabled don't do anything
    if (!this.simulatedTimeEnabled) {
      return await invoker(request);
    }

    // else pass metadata entry
    const metadata = request.getMetadata();
    metadata["mesh-simulated-time"] = this.currentTime.toDate().toISOString();
    console.debug(
      "intercepted simulated time: ",
      this.currentTime.toDate().toISOString(),
    );

    // hand over with modified request
    return await invoker(request);
  }
}
