import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";

const PREFIX = "Warning";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitle}`]: {
    padding: theme.spacing(1, 0),
  },
}));

interface WarningProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  onClose: () => void;
  open: boolean;
}

export function Warning(props: WarningProps) {
  const { onNext, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleProceed = () => {
    onNext(CompanyRegStep.confirmation)();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography
          className={classes.dialogTitle}
          variant="h5"
          children="Last Review Opportunity"
        />
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Please double check the data you’ve entered. If you’re happy to keep
          going, click Proceed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          Review
        </Button>
        <Button
          id="companyRegistration-reviewNotificationWarning-confirm"
          onClick={handleProceed}
          variant="contained"
          color="primary"
        >
          Proceed
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
