import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useIsMounted } from "hooks";
import { Reader } from "james/views/stellarClaimableBalanceView/Reader";
import { Query } from "james/search/query";
import { BooleanCriterion, TextExactCriterion } from "james/search/criterion";
import { Badge } from "@mui/material";
import { useNotificationContext } from "context/Notification";
import { GroupNotificationChannel } from "james/group";
import { ModelChangedNotificationTypeName as StellarModelChangedNotificationTypeName } from "james/views/stellarClaimableBalanceView/ModelChangedNotification";
import { StellarClaimableViewNotificationChannelName } from "james/views/stellarClaimableBalanceView";
import { useApplicationContext } from "context/Application/Application";

export interface ClaimableBalanceBadgeProps {
  children?: React.ReactNode;
  accountLedgerID: string;
  accountOwnerID: string;
  getBadgeCount?: Dispatch<SetStateAction<number>>;
}

export function ClaimableBalanceBadge(props: ClaimableBalanceBadgeProps) {
  const { authContext } = useApplicationContext();
  const { registerNotificationCallback } = useNotificationContext();
  const isMounted = useIsMounted();
  const [count, setCount] = useState(0);

  // read the claimable balance view
  const handleReadModels = useCallback(async () => {
    const readResponse = await Reader.Read({
      context: authContext,
      query: new Query(),
      criteria: {
        claimed: BooleanCriterion(false),
        archived: BooleanCriterion(false),
        claimantAccountLedgerID: TextExactCriterion(props.accountLedgerID),
      },
    });

    setCount(readResponse.total);
    props.getBadgeCount?.(readResponse.total);
  }, [props.accountLedgerID, authContext]);

  useEffect(() => {
    handleReadModels().finally();
  }, [handleReadModels]);

  useEffect(() => {
    let deregister: () => void = () => {
      return;
    };

    (async () => {
      try {
        deregister = await registerNotificationCallback(
          new GroupNotificationChannel({
            groupID: props.accountOwnerID,
            name: StellarClaimableViewNotificationChannelName,
            private: true,
          }),
          [StellarModelChangedNotificationTypeName],
          () => {
            if (isMounted()) {
              handleReadModels().finally();
            }
          },
        );
      } catch (e) {
        console.error(
          `error registering for notifications on group channel '${StellarClaimableViewNotificationChannelName}' for group ${props.accountOwnerID}`,
        );
      }
    })();
    if (isMounted()) {
      handleReadModels().finally();
    }
    // de-register when component unmounts
    return () => {
      deregister();
    };
  }, [
    handleReadModels,
    registerNotificationCallback,
    isMounted,
    props.accountOwnerID,
  ]);

  return (
    <Badge
      id="claimableBalanceTab-claimableBalanceCount-badge"
      overlap="rectangular"
      color="primary"
      badgeContent={count}
    >
      {props.children}
    </Badge>
  );
}
