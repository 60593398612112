import { DataSheetSection } from "./DataSheetSection";
import { Token } from "james/ledger";

export const StellarAssetPublicInfoDataSheetSectionTypeName =
  "mesh::marketData/StellarAssetPublicInfo";

export const StellarAssetPublicInfoDataSheetSectionName = "Information";

export class StellarAssetPublicInfoDataSheetSection
  implements DataSheetSection
{
  public ["@type"]: string = StellarAssetPublicInfoDataSheetSectionTypeName;

  public assetToken: Token = new Token();

  constructor(l?: StellarAssetPublicInfoDataSheetSection) {
    if (!l) {
      return;
    }
    this.assetToken = new Token(l.assetToken);
  }

  public DataSheetSectionName(): string {
    return StellarAssetPublicInfoDataSheetSectionName;
  }
}
