import React from "react";
import { ETNRollCorporateActionsDataSheetSection } from "james/marketData";
import { ETNRollCorporateActionCard } from "components/CorporateActions/ETNRollCorporateAction";
import Box from "@mui/material/Box";
import { Divider, useTheme } from "@mui/material";

export interface AssetDocumentsProps {
  dataSheetSection: ETNRollCorporateActionsDataSheetSection;
}

export function AssetCorporateActionsDataSheetSectionRenderer(
  props: AssetDocumentsProps,
) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(3.5),
        paddingBottom: theme.spacing(3.5),
      }}
    >
      {props.dataSheetSection.corporateActions.map((ca, idx) => (
        <React.Fragment key={`assetCorporateAction-${idx}`}>
          <ETNRollCorporateActionCard etnRollCorporateAction={ca} />
          {!!(
            props.dataSheetSection.corporateActions.length - 1 &&
            idx !== props.dataSheetSection.corporateActions.length - 1
          ) && (
            <Divider
              sx={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
