import { AuditEntry } from "pkgTemp/audit";
import {
  Account as LedgerAccount,
  LedgerAccountCategory,
} from "pkgTemp/ledger/Account";
import { LedgerNetwork } from "pkgTemp/ledger/Network";
import { StellarNetwork } from "./Network";
import { Amount, Token } from "pkgTemp/ledger";
import { LedgerAccountState } from "james/ledger";
import dayjs from "dayjs";

export const AccountTypeName = "mesh::stellar/Account";

export class Balance {
  public amount: Amount = new Amount();

  public limit: Amount = new Amount();

  public sellingLiabilities: Amount = new Amount();

  public buyingLiabilities: Amount = new Amount();

  constructor(balance?: Balance) {
    if (!balance) {
      return;
    }
    this.amount = new Amount(balance.amount);
    this.limit = new Amount(balance.limit);
    this.sellingLiabilities = new Amount(balance.sellingLiabilities);
    this.buyingLiabilities = new Amount(balance.buyingLiabilities);
  }
}

export class AuthFlags {
  public authRequired = false;

  public authRevocable = false;

  public authImmutable = false;

  constructor(authFlags?: AuthFlags) {
    if (!authFlags) {
      return;
    }
    this.authRequired = authFlags.authRequired;
    this.authRevocable = authFlags.authRevocable;
    this.authImmutable = authFlags.authImmutable;
  }
}

export enum SignatoryType {
  SHA256Hash = "SHA256Hash",
  ED25519 = "ED25519",
  PreAuthTx = "PreAuthTx",
}

export const AllAccountSignatoryTypes: SignatoryType[] = [
  SignatoryType.SHA256Hash,
  SignatoryType.ED25519,
  SignatoryType.PreAuthTx,
];

export class Signatory {
  public type: SignatoryType | "" = "";

  public key = "";

  public weight = 0;

  constructor(signatory?: Signatory) {
    if (!signatory) {
      return;
    }
    this.type = signatory.type;
    this.key = signatory.key;
    this.weight = signatory.weight;
  }
}

export class Account implements LedgerAccount {
  public ["@type"]: string = AccountTypeName;

  public id = "";

  public ownerID = "";

  public number = "";

  public category: LedgerAccountCategory | "" = "";

  public state: LedgerAccountState | "" = "";

  public network: StellarNetwork | "-" = "-";

  public ledgerID = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public balances: Balance[] = [];

  public signatories: Signatory[] = [];

  public authFlags: AuthFlags = new AuthFlags();

  public createdTime: dayjs.Dayjs = dayjs();

  constructor(account?: Account) {
    if (!account) {
      return;
    }
    this.id = account.id;
    this.ownerID = account.ownerID;
    this.number = account.number;
    this.category = account.category;
    this.network = account.network;
    this.ledgerID = account.ledgerID;
    this.state = account.state;
    this.auditEntry = new AuditEntry(account.auditEntry);
    if (account.balances) {
      this.balances = account.balances.map((b) => new Balance(b));
    }
    if (account.signatories) {
      this.signatories = account.signatories.map((s) => new Signatory(s));
    }
    this.authFlags = new AuthFlags(account.authFlags);
    this.createdTime = account.createdTime;
  }

  getTokenBalance(token: Token): Balance | undefined {
    for (const balance of this.balances) {
      if (balance.amount.token.isEqualTo(token)) {
        return balance;
      }
    }
  }

  accountID(): string {
    return this.id;
  }

  accountOwnerID(): string {
    return this.ownerID;
  }

  accountCategory(): "" | LedgerAccountCategory {
    return this.category;
  }

  accountLedgerNetwork(): LedgerNetwork {
    return this.network;
  }

  accountState(): "" | LedgerAccountState {
    return this.state;
  }

  createdAt(): dayjs.Dayjs {
    return this.createdTime;
  }
}
