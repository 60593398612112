import { useCallback, useState } from "react";
import { getRandomColor } from "utilities/color";
import { useTheme } from "@mui/material";

export const useAssetColorPicker = (
  noAssetsColor = false,
): ((key: string) => string) => {
  const [usedColors, setUsedColors] = useState<Map<string, string>>(new Map());
  const theme = useTheme();
  // default preffered collors that the picker would
  // use before randomly generating colors
  const [preferredColors] = useState([
    theme.palette.custom.pantone._3255,
    theme.palette.custom.pantone._513,
    theme.palette.custom.pantone._611,
    theme.palette.custom.pantone._632,
    theme.palette.custom.pantone._2587,
    theme.palette.custom.pantone._1235,
    theme.palette.custom.pantone._326,
    theme.palette.custom.pantone._246,
    theme.palette.custom.pantone._1375,
    theme.palette.custom.pantone._640,
    theme.palette.custom.pantone._1788,
    theme.palette.custom.pantone._1787,
    theme.palette.custom.pantone._1585,
    theme.palette.custom.pantone._383,
    theme.palette.custom.pantone._353,
  ]);

  // color picker based on key
  return useCallback(
    (key: string): string => {
      if (noAssetsColor) {
        return theme.palette.text.disabled;
      }

      // check if there is color associated with key already
      if (usedColors.has(key)) {
        return usedColors.get(key) || "#4AD99161";
      }

      //
      // no color is associated with key, attempt to get a new color for the key
      //
      let newColor = "";

      //
      // iterate through preferred colors, check if there are any prefered color available
      //
      for (const preferredColor of preferredColors) {
        let preferredColorAlreadyUsed = false;
        for (const colorKey of Array.from(usedColors.keys())) {
          if (usedColors.get(colorKey) === preferredColor) {
            preferredColorAlreadyUsed = true;
            break;
          }
        }
        if (!preferredColorAlreadyUsed) {
          newColor = preferredColor;
          break;
        }
      }

      //
      // if we reach here it means there are no preferred colors left and thus we select a random color
      //
      if (newColor === "") {
        const usedColorsArray: string[] = Array.from(usedColors.values());
        newColor = getRandomColor(usedColorsArray);
      }

      setUsedColors(usedColors.set(key, newColor));

      return newColor;
    },
    [usedColors, noAssetsColor],
  );
};
