import {
  Button,
  IconButton,
  Typography,
  Box,
  alpha,
  Tooltip,
  TextField,
} from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ReadManyModelRequest,
  ReadManyModelResponse,
} from "@mesh/common-js/dist/views/financialPaymentView/modelReader_meshproto_pb";
import { Model as FinancialPaymentViewModel } from "@mesh/common-js/dist/views/financialPaymentView/model_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as PastQuery } from "james/search/query";
import { BPTable } from "components/Table";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { assetflowCategoryToString } from "@mesh/common-js/dist/financial/assetFlowCategory";
import { enqueueSnackbar } from "notistack";
import { FullUpdateRequest } from "@mesh/common-js/dist/views/financialPaymentView/modelUpdater_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import { PaymentStateChip } from "../StateChip";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";
import { ResolvePaymentIDStateRequest } from "@mesh/common-js/dist/financial/paymentStateController_pb";
import { ReadManyPaymentRecipientRequest } from "@mesh/common-js/dist/financial/paymentRecipientReader_meshproto_pb";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import {
  newORCriterion,
  newTextSubstringCriterion,
} from "@mesh/common-js/dist/search";

export type TableProps = {
  system: boolean;
};

export const Table = (props: TableProps) => {
  const { viewConfiguration } = useApplicationContext();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    views: {
      financialPaymentViewModelReader,
      financialPaymentViewModelUNSCOPEDReader,
      financialPaymentViewModelUpdater,
    },
    financial: { paymentStateController },
  } = useAPIContext();
  const [selectedPayment, setSelectedPayment] = useState<
    FinancialPaymentViewModel | undefined
  >(undefined);
  const [readRequest, setReadRequest] = useState<ReadManyModelRequest>(
    new ReadManyModelRequest().setContext(authContext.toFuture()).setQuery(
      new Query()
        .setOffset(0)
        .setLimit(15)
        .setSortingList([
          new Sorting()
            .setField("date")
            .setOrder(SortingOrder.ASC_SORTING_ORDER),
        ]),
    ),
  );
  const updateReadRequestQuery = useCallback(
    (newreadRequestQuery: Query) =>
      setReadRequest(
        new ReadManyPaymentRecipientRequest()
          .setContext(readRequest.getContext())
          .setCriteriaList(readRequest.getCriteriaList())
          .setQuery(newreadRequestQuery),
      ),
    [readRequest],
  );
  const updateReadRequestCriteria = useCallback(
    (newReadRequestCriteria: Criterion[]) =>
      setReadRequest(
        new ReadManyPaymentRecipientRequest()
          .setContext(readRequest.getContext())
          .setCriteriaList(newReadRequestCriteria)
          .setQuery(readRequest.getQuery()),
      ),
    [readRequest],
  );
  const [readResponse, setReadResponse] = useState<ReadManyModelResponse>(
    new ReadManyModelResponse(),
  );
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManyModelResponse;
        if (props.system) {
          response =
            await financialPaymentViewModelUNSCOPEDReader.readManyModelUNSCOPED(
              readRequest,
            );
        } else {
          response =
            await financialPaymentViewModelReader.readManyModel(readRequest);
        }
        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error("error reading smart instrument", e);
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted]);
  const [apiLoading, setAPILoading] = useState(false);

  const [numberInstrumentNameCriterion, setNumberInstrumentNameCriterion] =
    useState("");
  useEffect(() => {
    const updatedCriteria: Criterion[] = [];
    if (numberInstrumentNameCriterion) {
      updatedCriteria.push(
        newORCriterion([
          newTextSubstringCriterion(
            "smartinstrumentname",
            numberInstrumentNameCriterion,
          ),
          newTextSubstringCriterion("number", numberInstrumentNameCriterion),
        ]),
      );
    }

    updateReadRequestCriteria(updatedCriteria);
  }, [numberInstrumentNameCriterion]);

  return (
    <BPTable
      loading={loading || apiLoading}
      height={window.innerHeight - 88 - noticeBannerHeight}
      singleSelect
      noDataSplashComponent={noDataSplashComponent()}
      onSingleSelectChange={(data) =>
        setSelectedPayment(data as FinancialPaymentViewModel | undefined)
      }
      title={"Payments"}
      query={PastQuery.fromFutureQuery(readRequest.getQuery())}
      onQueryChange={(query) => updateReadRequestQuery(query.toFutureQuery())}
      data={readResponse.getRecordsList()}
      totalNoRecords={readResponse.getTotal()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (viewConfiguration["Payments"]?.ViewModelFullUpdate) {
          controls.push(
            <Button
              onClick={async () => {
                setAPILoading(true);
                try {
                  await financialPaymentViewModelUpdater.fullUpdate(
                    new FullUpdateRequest().setContext(authContext.toFuture()),
                  );
                } catch (e) {
                  console.error(`error performing full update: ${e}`);
                  enqueueSnackbar(`error performing full update: ${e}`);
                }
                setAPILoading(false);
              }}
              variant="outlined"
            >
              Full Update
            </Button>,
          );
        }

        if (selectedPayment && viewConfiguration["Payments"]?.ResolveState) {
          controls.push(
            <Button
              onClick={async () => {
                setAPILoading(true);
                try {
                  await paymentStateController.resolvePaymentIDState(
                    new ResolvePaymentIDStateRequest()
                      .setContext(authContext.toFuture())
                      .setPaymentid(selectedPayment.getPaymentid())
                      .setManualinvocation(true),
                  );
                  enqueueSnackbar("Payment State Resolved", {
                    variant: "success",
                  });
                } catch (e) {
                  console.error(`error resolving payment state: ${e}`);
                  enqueueSnackbar(`error resolving payment state: ${e}`, {
                    variant: "error",
                  });
                }
                setAPILoading(false);
              }}
              variant="outlined"
            >
              Resolve Payment State
            </Button>,
          );
        }

        controls.push(
          <IconButton
            id={"paymentTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() =>
              setReadRequest(
                new ReadManyModelRequest()
                  .setContext(readRequest.getContext())
                  .setCriteriaList(readRequest.getCriteriaList())
                  .setQuery(readRequest.getQuery()),
              )
            }
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      filters={[
        <TextField
          id={"paymentTable-numberInstrumentNameFilter-textField"}
          sx={{ width: 160 }}
          variant={"outlined"}
          margin={"dense"}
          label={"Number / Instrument"}
          placeholder={"Start Typing..."}
          value={numberInstrumentNameCriterion}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setNumberInstrumentNameCriterion(e.target.value)}
        />,
      ]}
      columns={[
        {
          label: "Number",
          field: "number",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                {model.getNumber()}
              </Typography>
            );
          },
        },
        {
          label: "Instrument",
          field: "smartinstrumentname",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                {model.getSmartinstrumentname()}
              </Typography>
            );
          },
        },
        {
          label: "Payment Date",
          field: "date",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            return (
              <Typography
                // id={`paymentTable-spotNumber-text-${model.number}`}
                color={"inherit"}
                variant={"body1"}
              >
                {protobufTimestampToDayjs(model.getDate() ?? new Timestamp())
                  .tz(timezoneToString(model.getTimezone()))
                  .format(DateTimeFormatWithOffset)}
              </Typography>
            );
          },
        },
        {
          label: "Category",
          field: "category",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            return (
              <Typography
                // id={`paymentTable-spotNumber-text-${model.number}`}
                color={"inherit"}
                variant={"body1"}
              >
                {assetflowCategoryToString(model.getCategory())}
              </Typography>
            );
          },
        },
        {
          label: "Amount",
          field: "amount.value.float",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            const amount = model.getAmount();
            return (
              <Amount
                amount={
                  amount
                    ? LedgerAmount.fromFutureAmount(amount)
                    : new LedgerAmount()
                }
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  roundUp: true,
                }}
              />
            );
          },
        },
        {
          label: "Funded",
          field: "fundedamount.value.float",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            const amount = model.getFundedamount();
            return (
              <Amount
                amount={
                  amount
                    ? LedgerAmount.fromFutureAmount(amount)
                    : new LedgerAmount()
                }
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  roundUp: true,
                }}
              />
            );
          },
        },
        {
          label: "State",
          field: "state",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                <PaymentStateChip state={model.getState()} />
              </Typography>
            );
          },
        },
        {
          field: "",
          label: "",
          accessor: (data) => {
            const model = data as FinancialPaymentViewModel;
            return (
              <Tooltip placement="top" title="View payment detail in new tab">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `${window.location.href.replace(/\/table$/, "/view?id=")}${model.getPaymentid()}`,
                      "_blank",
                    );
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            );
          },
        },
      ]}
    />
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>payments</i> here once they have been generated.
            </span>
          }
        />
      </Box>
    </Box>
  );
};
