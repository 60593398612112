import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ValidationResult } from "common/validation";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { SmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLeg_pb";
import { SmartInstrumentLegType } from "@mesh/common-js/dist/financial/smartInstrumentLegType_pb";
import { BulletSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegBullet_pb";
import { FloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegFloatingRate_pb";
import { v4 as uuidV4 } from "uuid";
import { ScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfiguration_pb";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import { DateGenerationRule } from "@mesh/common-js/dist/financial/dateGenerationRule_pb";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { DayCountConvention } from "@mesh/common-js/dist/financial/dayCountConvention_pb";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { validateSmartInstrumentLeg } from "./components/LegsForm/validation";
import { bigNumberToDecimal } from "@mesh/common-js/dist/num";
import BigNumber from "bignumber.js";
import { FutureDocument } from "@mesh/common-js/dist/document/document_pb";
import { Deferrability } from "@mesh/common-js/dist/financial/deferrability_pb";
import { SmartInstrumentType } from "@mesh/common-js/dist/financial/smartInstrumentType_pb";
import { ShiftingPeriod } from "@mesh/common-js/dist/financial/shiftingPeriod_pb";
import { Period } from "@mesh/common-js/dist/financial/period_pb";
import { TimeUnit } from "@mesh/common-js/dist/financial/timeUnit_pb";
import {
  ScheduleConfigurationWrapper,
  SmartInstrumentLegWrapper,
} from "@mesh/common-js/dist/financial";
import { ScheduleConfigurationType } from "@mesh/common-js/dist/financial/scheduleConfigurationType_pb";
import { applyTimeFromDateToDate } from "@mesh/common-js/dist/timeTools";
import { validateAttribute } from "./components/InstrumentForm/components/AttributesForm/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { SmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttribute_pb";
import { SectorAllocationsSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeSectorAllocations_pb";
import { CountryAllocationsSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeCountryAllocations_pb";
import { HoldingAllocationsSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeHoldingAllocations_pb";
import { RiskAssessmentSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeRiskAssessment_pb";
import { ExternalParticipantsInformationSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeExternalParticipantsInformation_pb";
import { FiatCurrencyAllocationsSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeFiatCurrencyAllocations_pb";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import config from "react-global-configuration";
import { Environment } from "const";
import { Model as LedgerTokenViewModel } from "../../../../../james/views/ledgerTokenView";
import { Token } from "james/ledger";
import { futureNetworkToBEString } from "@mesh/common-js/dist/ledger";
import { StrKey } from "stellar-sdk";
import { AssetClassSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeAssetClass_pb";
import { AnnualPerformanceLogSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeAnnualPerformanceLog_pb";

export enum IssuerOption {
  ManualExisting = "Manually Set Existing",
  Network = "Network",
  New = "New",
}

export const allIssuerOptions: IssuerOption[] = [
  IssuerOption.ManualExisting,
  IssuerOption.Network,
  IssuerOption.New,
];

export type SmartInstrumentFormData = {
  assetTokenViewModels: LedgerTokenViewModel[];
  smartIntrumentCopy: SmartInstrument;
  smartInstrument: SmartInstrument;
  issuerOption: IssuerOption;
};

export type FormUpdaterSpecsType = {
  issuerOption: (
    issuerOption: IssuerOption,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  assetTokenViewModels: (
    assetTokenViewModels: LedgerTokenViewModel[],
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  smartInstrument: (
    smartInstrument: SmartInstrument,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  ownerID: (
    ownerID: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  issueDate: (
    issueDate?: Timestamp,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  name: (
    name: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  type: (
    smartInstrumentType: SmartInstrumentType,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  tokenNetwork: (
    network: FutureNetwork,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  tokenCode: (
    code: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  tokenIssuer: (
    issuer: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  directMintingAllowed: (
    directMintingAllowed: boolean,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  unit: (
    unit: Unit,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  timezone: (
    timezone: Timezone,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  linkedInstrumentID: (
    linkedInstrumentID: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  addLeg: (
    smartInstrumentLegType: SmartInstrumentLegType,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  removeLeg: (
    legIdx: number,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  updateLeg: (
    args: { smartInstrumentLeg: SmartInstrumentLeg; legIdx: number },
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  addAttribute: (
    smartInstrumentAttributeType: SmartInstrumentAttributeType,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  removeAttribute: (
    attributeIdx: number,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  updateAttribute: (
    args: {
      smartInstrumentAttribute: SmartInstrumentAttribute;
      attributeIdx: number;
    },
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  addDocuments: (
    documents: FutureDocument[],
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  removeDocument: (
    docdx: number,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  changeDocumentDescription: (
    args: { docIdx: number; newDescription: string },
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  issuerOption(
    issuerOption: IssuerOption,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const token = formData.smartInstrument.getToken() ?? new FutureToken();

    let newIssuer: string;
    switch (issuerOption) {
      case IssuerOption.ManualExisting:
        newIssuer = "";
        break;

      case IssuerOption.Network:
        newIssuer = futureNetworkToBEString(token.getNetwork());
        break;

      case IssuerOption.New:
      default:
        newIssuer = "";
    }

    return {
      ...formData,
      issuerOption,
      smartInstrument: formData.smartInstrument.setToken(
        token.setIssuer(newIssuer),
      ),
    };
  },
  assetTokenViewModels(
    assetTokenViewModels: LedgerTokenViewModel[],
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      assetTokenViewModels,
    };
  },
  smartInstrument(
    smartInstrument: SmartInstrument,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    // get a handle on the token issuer and network
    const tokenIssuer = smartInstrument.getToken()?.getIssuer() ?? "";
    const tokenNetwork =
      smartInstrument.getToken()?.getNetwork() ??
      FutureNetwork.UNDEFINED_NETWORK;

    // determine issuer option:
    // - default to "Manually Set Existing Issuer"
    let issuerOption: IssuerOption = IssuerOption.ManualExisting;
    // - if the issuer is blank then set to "New"
    if (tokenIssuer === "") {
      issuerOption = IssuerOption.New;
    } else if (
      // - if the issuer is set to the network then set to "Network"
      tokenIssuer === futureNetworkToBEString(tokenNetwork)
    ) {
      issuerOption = IssuerOption.Network;
    }

    return {
      ...formData,
      smartInstrument: smartInstrument,
      smartIntrumentCopy: SmartInstrument.deserializeBinary(
        smartInstrument.serializeBinary(),
      ),
      issuerOption,
    };
  },
  ownerID(
    ownerID: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setOwnerid(ownerID),
    };
  },
  issueDate(
    issueDate?: Timestamp,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    return {
      ...formData,
      smartInstrument: formData.smartInstrument
        .setIssuedate(issueDate)
        .setLegsList(
          formData.smartInstrument.getLegsList().map((leg) => {
            switch (new SmartInstrumentLegWrapper(leg).smartInstrumentLegType) {
              case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE: {
                const typedLeg = leg.getBulletsmartinstrumentleg();
                if (!typedLeg) {
                  throw new TypeError("bullet leg not set");
                }
                return new SmartInstrumentLeg().setBulletsmartinstrumentleg(
                  typedLeg.setDate(
                    applyTimeFromDateToDate(issueDate, typedLeg.getDate()),
                  ),
                );
              }

              case SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE: {
                const typedLeg = leg.getFloatingratesmartinstrumentleg();
                if (!typedLeg) {
                  throw new TypeError("floating rate leg not set");
                }

                switch (
                  new ScheduleConfigurationWrapper(
                    typedLeg.getScheduleconfiguration(),
                  ).scheduleConfigurationType
                ) {
                  case ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE: {
                    const typedScheduleConfig = typedLeg
                      .getScheduleconfiguration()
                      ?.getNonperpetualscheduleconfiguration();
                    if (!typedScheduleConfig) {
                      throw new TypeError(
                        "non-perpetual schedule configuration not set",
                      );
                    }

                    return new SmartInstrumentLeg().setFloatingratesmartinstrumentleg(
                      typedLeg.setScheduleconfiguration(
                        new ScheduleConfiguration().setNonperpetualscheduleconfiguration(
                          typedScheduleConfig
                            .setStartdate(
                              applyTimeFromDateToDate(
                                issueDate,
                                typedScheduleConfig.getStartdate(),
                              ),
                            )
                            .setFirstscheduleddate(
                              applyTimeFromDateToDate(
                                issueDate,
                                typedScheduleConfig.getFirstscheduleddate(),
                              ),
                            )
                            .setSecondtolastscheduleddate(
                              applyTimeFromDateToDate(
                                issueDate,
                                typedScheduleConfig.getSecondtolastscheduleddate(),
                              ),
                            )
                            .setEnddate(
                              applyTimeFromDateToDate(
                                issueDate,
                                typedScheduleConfig.getEnddate(),
                              ),
                            ),
                        ),
                      ),
                    );

                    break;
                  }

                  case ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE:
                    // do nothing - not yet supported
                    break;

                  case ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE:
                  default:
                    throw new TypeError(
                      `unexpected schedule configuration type: ${leg}`,
                    );
                }

                break;
              }

              case SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE:
              default:
                throw new TypeError(`unexpected leg type: ${leg}`);
            }

            return leg;
          }),
        ),
    };
  },
  name(
    name: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setName(
        name.length > 60 ? formData.smartInstrument.getName() : name,
      ),
    };
  },
  type(
    smartInstrumentType: SmartInstrumentType,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setType(smartInstrumentType),
    };
  },
  tokenNetwork(
    network: FutureNetwork,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const token = formData.smartInstrument.getToken() ?? new FutureToken();

    let issuer = token.getIssuer();
    if (formData.issuerOption === IssuerOption.Network) {
      issuer = futureNetworkToBEString(network);
    }

    return {
      ...formData,
      smartInstrument: formData.smartInstrument
        .setToken(token.setNetwork(network).setIssuer(issuer))
        // FIXME: token on the legs needs to be updated here - for now we remove the legs
        .setLegsList([]),
    };
  },
  tokenCode(
    code: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const token = formData.smartInstrument.getToken() ?? new FutureToken();

    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setToken(token.setCode(code)),
    };
  },
  tokenIssuer(
    issuer: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const token = formData.smartInstrument.getToken() ?? new FutureToken();

    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setToken(
        token.setIssuer(issuer),
      ),
    };
  },
  directMintingAllowed(
    directMintingAllowed: boolean,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    return {
      ...formData,
      smartInstrument:
        formData.smartInstrument.setDirectmintingallowed(directMintingAllowed),
    };
  },
  unit(
    unit: Unit,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setUnit(unit),
    };
  },
  timezone(
    timezone: Timezone,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setTimezone(timezone),
    };
  },
  linkedInstrumentID(
    linkedInstrumentID: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument:
        formData.smartInstrument.setLinkedsmartinstrumentid(linkedInstrumentID),
    };
  },
  addLeg(
    smartInstrumentLegType: SmartInstrumentLegType,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    // pick an asset to use to set the initial notional amounts on the legs
    // get view models of all of the tokens on the same network that the instrument is to be issued
    const assetTokenViewModels = formData.assetTokenViewModels
      // filter for tokens on the same network that the token is on
      .filter(
        (tokenViewModel) =>
          tokenViewModel.token.network ===
          Token.fromFutureToken(formData.smartInstrument.getToken()).network,
      );

    // try to pick mZAR - otherwise pick a random token
    const assetTokenViewModel =
      assetTokenViewModels.find((t) => t.token.code === "mZAR") ??
      assetTokenViewModels[0];
    if (!assetTokenViewModel) {
      console.error(
        "cannot find any tokens on the selected instrument network",
      );
      return formData;
    }

    switch (smartInstrumentLegType) {
      case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE:
        formData.smartInstrument.addLegs(
          new SmartInstrumentLeg().setBulletsmartinstrumentleg(
            new BulletSmartInstrumentLeg()
              .setId(uuidV4())
              .setName(
                `Leg ${formData.smartInstrument.getLegsList().length}: Bullet Leg`,
              )
              .setAssetflowcategory(
                AssetflowCategory.PRINCIPAL_ASSETFLOW_CATEGORY,
              )
              .setDate(
                dayjsToProtobufTimestamp(
                  protobufTimestampToDayjs(
                    formData.smartInstrument.getIssuedate() ?? new Timestamp(),
                  ).add(5, "y"),
                ),
              )
              .setAmount(
                assetTokenViewModel.token
                  .newAmountOf(BigNumber("1000"))
                  .toFutureAmount(),
              )
              .setBusinessdayconvention(
                BusinessDayConvention.PRECEDING_BUSINESS_DAY_CONVENTION,
              )
              .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR])
              .setRecordperiod(
                new ShiftingPeriod()
                  .setPeriod(
                    new Period()
                      .setCount(0)
                      .setTimeunit(TimeUnit.DAYS_TIME_UNIT),
                  )
                  .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR])
                  .setBusinessdayconvention(
                    BusinessDayConvention.UNADJUSTED_BUSINESS_DAY_CONVENTION,
                  ),
              ),
          ),
        );
        break;

      case SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE:
        formData.smartInstrument.addLegs(
          new SmartInstrumentLeg().setFloatingratesmartinstrumentleg(
            new FloatingRateSmartInstrumentLeg()
              .setId(uuidV4())
              .setName(
                `Leg ${formData.smartInstrument.getLegsList().length}: Floating Rate Leg`,
              )
              .setAssetflowcategory(
                AssetflowCategory.INTEREST_ASSETFLOW_CATEGORY,
              )
              .setDeferrability(Deferrability.NON_DEFERRABLE_DEFERRABILITY)
              .setDaycountconvention(
                DayCountConvention.ACTUAL_OVER_365_FIXED_DAY_COUNT_CONVENTION,
              )
              .setNotional(
                assetTokenViewModel.token
                  .newAmountOf(BigNumber("1000"))
                  .toFutureAmount(),
              )
              .setReferencerate(RateSource.SOUTH_AFRICA_PRIME_RATE_SOURCE)
              .setReferenceratefactor(bigNumberToDecimal(new BigNumber("100")))
              .setSpread(bigNumberToDecimal(new BigNumber("0")))
              .setFloor(bigNumberToDecimal(new BigNumber("0")))
              .setRateresetperiod(
                new ShiftingPeriod()
                  .setPeriod(
                    new Period()
                      .setCount(0)
                      .setTimeunit(TimeUnit.DAYS_TIME_UNIT),
                  )
                  .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR])
                  .setBusinessdayconvention(
                    BusinessDayConvention.UNADJUSTED_BUSINESS_DAY_CONVENTION,
                  ),
              )
              .setScheduleconfiguration(
                new ScheduleConfiguration().setNonperpetualscheduleconfiguration(
                  new NonPerpetualScheduleConfiguration()
                    .setStartdate(formData.smartInstrument.getIssuedate())
                    .setEnddate(
                      dayjsToProtobufTimestamp(
                        protobufTimestampToDayjs(
                          formData.smartInstrument.getIssuedate() ??
                            new Timestamp(),
                        ).add(5, "y"),
                      ),
                    )
                    .setFrequency(Frequency.QUARTERLY_FREQUENCY)
                    .setDategenerationrule(
                      DateGenerationRule.FORWARD_DATE_GENERATION_RULE,
                    )
                    .setBusinessdayconvention(
                      BusinessDayConvention.PRECEDING_BUSINESS_DAY_CONVENTION,
                    )
                    .setEnddatebusinessdayconvention(
                      BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION,
                    )
                    .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR]),
                ),
              )
              .setRecordperiod(
                new ShiftingPeriod()
                  .setPeriod(
                    new Period()
                      .setCount(0)
                      .setTimeunit(TimeUnit.DAYS_TIME_UNIT),
                  )
                  .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR])
                  .setBusinessdayconvention(
                    BusinessDayConvention.UNADJUSTED_BUSINESS_DAY_CONVENTION,
                  ),
              ),
          ),
        );
        break;
    }

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  removeLeg(
    legIdx: number,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    formData.smartInstrument.setLegsList(
      formData.smartInstrument.getLegsList().filter((_, idx) => idx !== legIdx),
    );

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  updateLeg(
    {
      smartInstrumentLeg,
      legIdx,
    }: { smartInstrumentLeg: SmartInstrumentLeg; legIdx: number },
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    const updatedLegsList = formData.smartInstrument.getLegsList();
    updatedLegsList[legIdx] = smartInstrumentLeg;

    formData.smartInstrument.setLegsList(updatedLegsList);

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  addAttribute(
    smartInstrumentAttributeType: SmartInstrumentAttributeType,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    switch (smartInstrumentAttributeType) {
      case SmartInstrumentAttributeType.SECTOR_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setSectorallocationssmartinstrumentattribute(
            new SectorAllocationsSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.COUNTRY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setCountryallocationssmartinstrumentattribute(
            new CountryAllocationsSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.FIAT_CURRENCY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setFiatcurrencyallocationssmartinstrumentattribute(
            new FiatCurrencyAllocationsSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setHoldingallocationssmartinstrumentattribute(
            new HoldingAllocationsSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setRiskassessmentsmartinstrumentattribute(
            new RiskAssessmentSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setExternalparticipantsinformationsmartinstrumentattribute(
            new ExternalParticipantsInformationSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setAssetclasssmartinstrumentattribute(
            new AssetClassSmartInstrumentAttribute(),
          ),
        );
        break;

      case SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
        formData.smartInstrument.addAttributes(
          new SmartInstrumentAttribute().setAnnualperformancelogsmartinstrumentattribute(
            new AnnualPerformanceLogSmartInstrumentAttribute(),
          ),
        );
        break;
    }

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  removeAttribute(
    attributeIdx: number,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    formData.smartInstrument.setAttributesList(
      formData.smartInstrument
        .getAttributesList()
        .filter((_, idx) => idx !== attributeIdx),
    );

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  updateAttribute(
    {
      smartInstrumentAttribute,
      attributeIdx,
    }: {
      smartInstrumentAttribute: SmartInstrumentAttribute;
      attributeIdx: number;
    },
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    const updatedAttributesList = formData.smartInstrument.getAttributesList();
    updatedAttributesList[attributeIdx] = smartInstrumentAttribute;

    formData.smartInstrument.setAttributesList(updatedAttributesList);

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  addDocuments(
    documents: FutureDocument[],
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    documents.forEach((d) => formData.smartInstrument.addDocuments(d));
    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  removeDocument(
    docdx: number,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const updatedDocsList = formData.smartInstrument
      .getDocumentsList()
      .filter((_, idx) => idx !== docdx);
    formData.smartInstrument.setDocumentsList(updatedDocsList);
    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  changeDocumentDescription(
    args: { docIdx: number; newDescription: string },
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const updatedDocsList = formData.smartInstrument.getDocumentsList();
    updatedDocsList[args.docIdx] = updatedDocsList[args.docIdx].setDescription(
      args.newDescription,
    );
    formData.smartInstrument.setDocumentsList(updatedDocsList);
    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
};

export const formDataValidationFunc = async (
  formData: SmartInstrumentFormData,
): Promise<ValidationResult> => {
  const environment = config.get("environment") as Environment;
  let supportedNetworks: FutureNetwork[];
  if (environment === Environment.Production) {
    supportedNetworks = [
      FutureNetwork.STELLAR_PUBLIC_NETWORK,
      FutureNetwork.BITCOIN_PUBLIC_NETWORK,
      FutureNetwork.ETHEREUM_PUBLIC_NETWORK,
      FutureNetwork.SA_STOCK_BROKERS_PRODUCTION_NETWORK,
      FutureNetwork.NULL_NETWORK,
    ];
  } else {
    supportedNetworks = [
      FutureNetwork.STELLAR_TEST_SDF_NETWORK,
      FutureNetwork.BITCOIN_TEST_NETWORK,
      FutureNetwork.ETHEREUM_TEST_NETWORK,
      FutureNetwork.SA_STOCK_BROKERS_TEST_NETWORK,
      FutureNetwork.NULL_NETWORK,
    ];
  }

  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  //perform validations
  if (formData.smartInstrument.getOwnerid() === "") {
    validationResult.valid = false;
    validationResult.fieldValidations.ownerID = "Must be set";
  }

  if (formData.smartInstrument.getName().length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Must be set";
  } else if (formData.smartInstrument.getName().length < 3) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Must be more than 3 characters";
  }

  const token = formData.smartInstrument.getToken() ?? new FutureToken();
  if (
    !(token.getCode() === "" || /^[A-Za-z0-9]{1,12}$/.test(token.getCode()))
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.tokenCode =
      "Must be blank or match ^[A-Za-z0-9]{1,12}$";
  }

  if (token.getNetwork() === FutureNetwork.UNDEFINED_NETWORK) {
    validationResult.valid = false;
    validationResult.fieldValidations.tokenNetwork = "Must be set";
  } else if (!supportedNetworks.includes(token.getNetwork())) {
    validationResult.valid = false;
    validationResult.fieldValidations.tokenNetwork = "Unsupported Network";
  }

  switch (formData.issuerOption) {
    case IssuerOption.ManualExisting:
      switch (token.getNetwork()) {
        // manual existing only supported for stellar networks for now
        case FutureNetwork.STELLAR_TEST_SDF_NETWORK:
        case FutureNetwork.STELLAR_PUBLIC_NETWORK:
          if (token.getIssuer() === "") {
            validationResult.valid = false;
            validationResult.fieldValidations.tokenIssuer = "Must be set";
          } else if (!StrKey.isValidEd25519PublicKey(token.getIssuer())) {
            validationResult.valid = false;
            validationResult.fieldValidations.tokenIssuer =
              "Not Valid Ed25519PublicKey";
          }
          break;

        default:
          validationResult.valid = false;
          validationResult.fieldValidations.tokenIssuer = "Not Supported";
      }
      break;

    case IssuerOption.Network:
      // network supported for all networks
      break;

    case IssuerOption.New:
    default:
      // new mint creation only supported for stellar networks for now
      switch (token.getNetwork()) {
        case FutureNetwork.STELLAR_TEST_SDF_NETWORK:
        case FutureNetwork.STELLAR_PUBLIC_NETWORK:
          break;

        default:
          validationResult.valid = false;
          validationResult.fieldValidations.tokenIssuer = "Not Supported";
      }
      break;
  }

  if (formData.smartInstrument.getTimezone() === Timezone.UNDEFINED_TIMEZONE) {
    validationResult.valid = false;
    validationResult.fieldValidations.timezone = "Must be set";
  }

  if (formData.smartInstrument.getUnit() === Unit.UNDEFINED_UNIT) {
    validationResult.valid = false;
    validationResult.fieldValidations.unit = "Must be set";
  }

  if (
    formData.smartInstrument.getType() ===
    SmartInstrumentType.UNDEFINED_SMART_INSTRUMENT_TYPE
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.type = "Must be set";
  }

  formData.smartInstrument.getLegsList().forEach((smartInstrumentLeg) => {
    const legValidationResult = validateSmartInstrumentLeg(smartInstrumentLeg, {
      smartInstrument: formData.smartInstrument,
    });
    validationResult.valid =
      legValidationResult.valid && validationResult.valid;
    for (const field in legValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        legValidationResult.fieldValidations[field];
    }
  });

  formData.smartInstrument.getAttributesList().forEach((attribute) => {
    const attributeValidationResult = validateAttribute(attribute);
    validationResult.valid =
      attributeValidationResult.valid && validationResult.valid;
    for (const field in attributeValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        attributeValidationResult.fieldValidations[field];
    }
  });

  return validationResult;
};
