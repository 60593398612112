import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { Context } from "../security";
import { Query } from "../search/query";
import { BankAccount } from "./bankAccount";

export interface ReadBankAccountsUnscopedRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadBankAccountsUnscopedResponse {
  bankAccounts: BankAccount[];
  total: number;
}

export const BankAccountReader = {
  serviceProvider: "banking-BankAccountReader",
  async ReadBankAccountsUnscoped(
    request: ReadBankAccountsUnscopedRequest,
  ): Promise<ReadBankAccountsUnscopedResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${BankAccountReader.serviceProvider}.ReadBankAccountsUnscoped`,
      request,
    });

    return {
      bankAccounts: response.bankAccounts.map(
        (b: BankAccount) => new BankAccount(b),
      ),
      total: response.total,
    };
  },
};
