import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Company } from "james/legal/company";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import React, { ChangeEvent, useState } from "react";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import {
  IndustryClassification,
  IndustryClassificationOptions,
} from "james/legal/IndustryClassification";
import { FormFieldsValidation, FormValidator } from "./Validation";
import { LegalForm } from "james/legal";
import { AllStockExchanges } from "james/financial/StockExchange";

interface CompanyDetailsProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  onChange: (name: string) => (value: unknown) => void;
  company: Company;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
}

export const CompanyDetails = (props: CompanyDetailsProps) => {
  const { company, onChange, onNext, invalidStep, inValid } = props;
  const [InvalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [ranValidation, setRanValidation] = useState(false);

  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...InvalidFormFields };
      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        delete InvalidFormField[field];
      }

      setInvalidFormFields(InvalidFormField);
    }, 600);

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    InlineFormValidation(name, event.target.value)();
    onChange(name)(event.target.value);
  };

  const nextStep = () => {
    if (
      FormFieldsValidation(InvalidFormFields, company, setInvalidFormFields)
    ) {
      onNext(CompanyRegStep.contactDetails)();
      invalidStep(CompanyRegStep.companyDetails, false);
    } else {
      onNext(CompanyRegStep.contactDetails)();
      invalidStep(CompanyRegStep.companyDetails, true);
    }
  };

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(InvalidFormFields, company, setInvalidFormFields);
  }

  return (
    <div>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography
            variant="h4"
            id="companyReg-companyDetails-title"
            color="secondary"
          >
            Company Details
          </Typography>
        </Box>
        <Button
          id="companyDetails-next"
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          Next
        </Button>
      </Box>

      <Box>
        <Typography
          style={{ marginTop: "8px", marginBottom: "32px" }}
          variant="subtitle2"
        >
          Add your company details below.
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 50%)",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gap: 2,
            }}
          >
            <TextField
              id="companyDetails-countryOfIncorporation"
              margin="dense"
              variant="outlined"
              select
              fullWidth
              label="Country of Incorporation"
              value={company.countryOfIncorporation}
              onChange={onUpdate("countryOfIncorporation")}
              helperText={InvalidFormFields.countryOfIncorporation}
              error={Boolean(InvalidFormFields.countryOfIncorporation)}
            >
              <MenuItem value="ZA">SOUTH AFRICA</MenuItem>
            </TextField>
            {company.formOfIncorporation !== LegalForm.PartnershipLegalForm && (
              <TextField
                id="companyDetails-taxReferenceNumber"
                margin="dense"
                fullWidth
                label="Tax Reference Number"
                variant="outlined"
                value={company.taxReferenceNumber}
                error={Boolean(InvalidFormFields.taxReferenceNumber)}
                helperText={InvalidFormFields.taxReferenceNumber}
                onChange={onUpdate("taxReferenceNumber")}
                placeholder="0000000000"
              />
            )}
            {[
              LegalForm.CloseCorporationLegalForm,
              LegalForm.SouthAfricanCompanyLegalForm,
              LegalForm.ForeignCompanyLegalForm,
              LegalForm.ListedCompanyLegalForm,
            ].includes(company.formOfIncorporation as LegalForm) && (
              <TextField
                id="companyDetails-vatRegistrationNumber"
                margin="dense"
                fullWidth
                variant="outlined"
                value={company.vatRegistrationNumber}
                label="Vat Registration Number"
                error={Boolean(InvalidFormFields.vatRegistrationNumber)}
                helperText={InvalidFormFields.vatRegistrationNumber}
                onChange={onUpdate("vatRegistrationNumber")}
                placeholder="0000000000"
              />
            )}
            {[
              LegalForm.ForeignCompanyLegalForm,
              LegalForm.ListedCompanyLegalForm,
            ].includes(company.formOfIncorporation as LegalForm) && (
              <Autocomplete
                id="companyDetails-industryClassification"
                options={IndustryClassificationOptions}
                getOptionLabel={(option) =>
                  !isEqual(
                    new IndustryClassification(option),
                    new IndustryClassification(),
                  )
                    ? `${option.industryName} - ${option.subIndustryName}`
                    : ""
                }
                isOptionEqualToValue={(option, value) => {
                  if (
                    (value.industryName as IndustryClassification | "") === ""
                  ) {
                    return true;
                  }
                  return option.industryName === value.industryName;
                }}
                value={company.industryClassification}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Industry Classification"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    error={Boolean(InvalidFormFields.industryClassification)}
                    fullWidth
                    helperText={InvalidFormFields.industryClassification}
                  />
                )}
              />
            )}
          </Box>
          {company.formOfIncorporation === LegalForm.ListedCompanyLegalForm && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="companyDetails-listedExchange"
                options={AllStockExchanges.sort((a, b) =>
                  a.name.localeCompare(b.name),
                )}
                renderOption={(props, option) => (
                  <Typography
                    {...props}
                    key={option.name}
                    children={`${option.code}: ${option.name}`}
                  />
                )}
                filterOptions={(options, { inputValue, getOptionLabel }) => {
                  const inputValues = inputValue
                    .trim()
                    .toLowerCase()
                    .split(" ");

                  return options.filter((option) => {
                    const label = getOptionLabel(option).toLowerCase();

                    // Check if all input values are present in either the name or code
                    const match = inputValues.every(
                      (inputValue) =>
                        label.includes(inputValue) ||
                        option.code.toLowerCase().includes(inputValue),
                    );

                    // Return the option if it matches
                    return match;
                  });
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Listed Exchange"
                    margin="dense"
                    variant="outlined"
                  />
                )}
              />
              <TextField
                id="companyDetails-listingReference"
                label="Listing Reference"
                onChange={onUpdate("listingReference")}
                margin="dense"
                fullWidth
                variant="outlined"
                value={company.listingReference}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
