import { FiatCurrency, FiatCurrencyTypeName } from "./FiatCurrency";
import { CryptoCurrency, CryptoCurrencyTypeName } from "./CryptoCurrency";

export interface Currency {
  ["@type"]: string;

  currencyID(): string;

  currencyName(): string;

  currencyCode(): string;

  firstCutOffAfter(reference: string): string;

  firstCutOffBefore(reference: string): string;

  firstStartOfDayBefore(reference: string): string;
}

export function NewCurrency(i: Currency): Currency {
  switch (i["@type"]) {
    case FiatCurrencyTypeName:
      return new FiatCurrency(i as FiatCurrency);

    case CryptoCurrencyTypeName:
      return new CryptoCurrency(i as CryptoCurrency);

    default:
      throw new TypeError(`unknown instrument type ${i["@type"]}`);
  }
}
