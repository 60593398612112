import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { Identifier } from "james/search/identifier/Identifier";
import { Transaction, NewTransaction } from "./Transaction";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveTransactionRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveTransactionResponse {
  transaction: Transaction;
}

export interface SearchTransactionsRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchTransactionsResponse {
  records: Transaction[];
  total: number;
}

export const TransactionCollection = {
  serviceProvider: "ledger-TransactionCollection",
  async RetrieveTransaction(
    request: RetrieveTransactionRequest,
  ): Promise<RetrieveTransactionResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${TransactionCollection.serviceProvider}.RetrieveTransaction`,
      request,
    });
    return {
      transaction: NewTransaction(response.transaction),
    };
  },
  async SearchTransactions(
    request: SearchTransactionsRequest,
  ): Promise<SearchTransactionsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${TransactionCollection.serviceProvider}.SearchTransactions`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map((m: Transaction) => NewTransaction(m)),
    };
  },
};

export function useSearchTransactions(
  initialSearchTransactionsRequest?: SearchTransactionsRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchTransactionsRequest, setSearchTransactionsRequest] =
    useState<SearchTransactionsRequest>(
      initialSearchTransactionsRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchTransactionsResponse, setSearchTransactionsResponse] =
    useState<SearchTransactionsResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchTransactionsResponse(
          await TransactionCollection.SearchTransactions(
            searchTransactionsRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for transactions: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for transactions: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchTransactionsRequest, shouldNotExecute]);

  return {
    searchTransactionsRequest,
    setSearchTransactionsRequest,
    searchTransactionsResponse,
    loading,
    error,
  };
}
