import { Context } from "james/security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

interface DeleteProfileRequest {
  context: Context;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeleteProfileResponse {}

interface GetFirebaseProfilePictureRequest {
  context: Context;
}

interface GetFirebaseProfilePictureResponse {
  uploadURL: string;
  mimeType: string;
  encodedProfilePicture: string;
}

export const ProfilePictureController = {
  serviceProvider: "user-ProfilePictureController",
  async DeleteProfilePicture(
    request: DeleteProfileRequest,
  ): Promise<DeleteProfileResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${ProfilePictureController.serviceProvider}.DeleteProfilePicture`,
      request,
    });
  },
  async GetFirebaseProfilePicture(
    request: GetFirebaseProfilePictureRequest,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalHeaders?: any,
  ): Promise<GetFirebaseProfilePictureResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${ProfilePictureController.serviceProvider}.GetFirebaseProfilePicture`,
        request,
      },
      {
        additionalHeaders,
      },
    );
  },
};
