import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { useEffect, useRef, useState } from "react";
import { AfterEffectPhase } from "./AfterEffectPhase";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface GetAfterEffectPhaseRequest {
  context: Context;
}

export interface GetAfterEffectPhaseResponse {
  afterEffectPhase: AfterEffectPhase;
}

export interface AfterEffectPhaseInspectorServiceProvider {
  serviceProvider: string;

  GetAfterEffectPhase(
    request: GetAfterEffectPhaseRequest,
  ): Promise<GetAfterEffectPhaseResponse>;
}

const AfterEffectPhaseInspectorServiceProviderName =
  "consistency-AfterEffectPhaseInspector";

export const AfterEffectPhaseInspector: AfterEffectPhaseInspectorServiceProvider =
  {
    serviceProvider: AfterEffectPhaseInspectorServiceProviderName,
    async GetAfterEffectPhase(
      request: GetAfterEffectPhaseRequest,
    ): Promise<GetAfterEffectPhaseResponse> {
      const response = await jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${AfterEffectPhaseInspector.serviceProvider}.GetAfterEffectPhase`,
        request,
      });
      return {
        afterEffectPhase: new AfterEffectPhase(response.afterEffectPhase),
      };
    },
  };

export function useGetAfterEffectPhase(
  initialGetAfterEffectPhaseRequest?: GetAfterEffectPhaseRequest,
  shouldNotExecute?: boolean,
) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [getAfterEffectPhaseRequest, setGetAfterEffectPhaseRequest] =
    useState<GetAfterEffectPhaseRequest>(
      initialGetAfterEffectPhaseRequest || { context: authContext },
    );
  const [getAfterEffectPhaseResponse, setGetAfterEffectPhaseResponse] =
    useState<GetAfterEffectPhaseResponse>({
      afterEffectPhase: new AfterEffectPhase(),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const reloadRef = useRef<() => void>(() =>
    setGetAfterEffectPhaseRequest({
      context: authContext,
    }),
  );
  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    (async () => {
      setLoading(true);
      setError(undefined);
      try {
        setGetAfterEffectPhaseResponse(
          await AfterEffectPhaseInspector.GetAfterEffectPhase(
            getAfterEffectPhaseRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting afterEffect phase: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error getting afterEffect phase: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    })();
  }, [getAfterEffectPhaseRequest, shouldNotExecute]);

  return {
    getAfterEffectPhaseRequest,
    setGetAfterEffectPhaseRequest,
    getAfterEffectPhaseResponse,
    loading,
    error,
    reload: reloadRef.current,
  };
}
