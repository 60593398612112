import { Amount } from "james/ledger";
import dayjs from "dayjs";
import { PaymentState } from "@mesh/common-js/dist/financial/payment_pb";

export const ModelTypeName = "mesh::financialInstrumentCashFlowView/Model";

export class Model {
  public ["@type"] = ModelTypeName;

  public id = "";
  public date: string = dayjs().format();
  public amount: Amount = new Amount();
  public paymentState: PaymentState = PaymentState.UNDEFINED_PAYMENT_STATE;
  public corporateActionID = "";
  public cashFlowType: CashFlowType | "" = "";
  public instrumentID = "";

  constructor(l?: Model) {
    if (!l) {
      return;
    }

    this.id = l.id;
    this.date = l.date;
    this.amount = new Amount(l.amount);
    this.paymentState = l.paymentState;
    this.corporateActionID = l.corporateActionID;
    this.cashFlowType = l.cashFlowType;
    this.instrumentID = l.instrumentID;
  }
}

export enum CashFlowType {
  CouponPaymentCashFlow = "Coupon Payment",
  MaturityCashFlow = "Maturity",
  DividendCashFlow = "Dividend",
  InterestCashFlow = "Interest",
  PrincipalCashFlow = "Principal",
}
