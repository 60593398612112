import React, { useCallback, useEffect, useRef, useState } from "react";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  RestartAlt as ReloadIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useValidatedForm } from "hooks/useForm";
import {
  FormState,
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  initialState,
  validationFunc,
} from "views/SetupClientProfile/components/companyClient/useFormState";
import { useSnackbar } from "notistack";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import { LegalForm } from "james/legal";
import { Company, Manager } from "james/legal/company";
import { Updater } from "james/legal/company/Updater";
import { CompanyRegisteredName } from "views/SignUp/const";
import { Reader } from "james/legal/company/Reader";
import { IDIdentifier } from "james/search/identifier";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export function SetupCompanyClientProfileCard() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureUploading, setProfilePictureUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profilePictureUploadPercentage, setProfilePictureUploadPercentage] =
    useState(0);
  const initialTouchedFields: Set<string> = new Set();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    authContext,
    loginClaims,
    logout,
    myClient,
    myClientRetrievalErr,
    refreshLoginClaims,
    refreshMyCompany,
  } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const companyName = localStorage.getItem(CompanyRegisteredName);

  useEffect(() => {
    if (!myClient && myClientRetrievalErr) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      return;
    }
  }, [myClient, myClientRetrievalErr]);

  useEffect(() => {
    getMyCompany().then((result) => {
      if (result) {
        formUpdater.company(result);
        if (!result.formOfIncorporation) {
          formUpdater.company({
            ...result,
            formOfIncorporation: LegalForm.SouthAfricanCompanyLegalForm,
          });
        }
      }
    });
  }, []);

  const getMyCompany = useCallback(async () => {
    if (!myClient) {
      return;
    }

    try {
      const retrieveMyCompanyResponse = await Reader.RetrieveMyCompany({
        context: authContext,
        identifier: IDIdentifier(myClient.legalEntityID),
      });

      return retrieveMyCompanyResponse.company;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(err);
    }
  }, []);

  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm<FormState, FormUpdaterSpecsType>(
      validationFunc,
      async (): Promise<FormState> => {
        return {
          companyName: companyName ? companyName : "",
          profilePictureData: "",
          companyType: LegalForm.OtherLegalForm,
          company: new Company(),
        };
      },
      formUpdaterSpecs,
      initialState,
      initialTouchedFields,
    );

  // retrieve company's Mesh profile picture or set the firebase profile picture
  // as the Mesh profile picture
  const getProfilePictureDownloadURL = useCallback(async () => {
    setProfilePictureUploading(true);
    try {
      const response = await Manager.RetrieveCompanyProfilePicture({
        clientIdentifier: IDIdentifier(loginClaims.clientID),
        context: authContext,
      });

      if (response.pictureData) {
        return response.pictureData;
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(err);
    }
  }, []);

  useEffect(() => {
    // update the formState with Mesh profile picture url
    getProfilePictureDownloadURL()
      .then((result) => {
        if (result) {
          formUpdater.profilePictureData(result);
        }
      })
      .finally(() => {
        setLoading(false);
        setProfilePictureUploading(false);
      });
  }, []);

  const { fieldValidations } = validationResult;

  //complete client profile
  const handleNextButtonClick = useCallback(async () => {
    setLoading(true);

    try {
      // setup client profile
      await Updater.UpdateMyCompany({
        context: authContext,
        company: formState.company,
      });
      await refreshLoginClaims();
      await refreshMyCompany();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Unable to Update Company Profile: ${err.message}`, {
        variant: "error",
      });
      console.error(`unable to setup client profile: ${e}`);
      setLoading(false);
    }
  }, [authContext, formState]);

  //upload user's profile picture
  const handleSelectPictureFile = async () => {
    let uploadInputRef;
    if (inputRef.current) {
      uploadInputRef = inputRef.current;
    }

    try {
      // confirm that a file has been selected
      if (
        !(uploadInputRef && uploadInputRef.files && uploadInputRef.files.length)
      ) {
        console.error("no upload input ref files");
        enqueueSnackbar("No upload file selected", { variant: "error" });
        setLoading(false);
        return;
      }

      // get a reference to the selected file and confirm it is not null
      const fileToUpload = uploadInputRef.files[0];
      if (!fileToUpload) {
        console.error("file to upload is null");
        enqueueSnackbar("File to upload is null", { variant: "error" });
        setLoading(false);
        return;
      }

      const fileSplit = fileToUpload.name.split(".");
      if (fileSplit.length < 2) {
        console.error("invalid file type");
        enqueueSnackbar("Invalid file type", { variant: "error" });
        setLoading(false);
        return;
      }

      const ext = fileSplit[fileSplit.length - 1];
      switch (ext.toLowerCase()) {
        case "png":
        case "jpg":
        case "jpeg":
          break;
        default:
          console.error("invalid file type");
          enqueueSnackbar("Invalid file type", { variant: "error" });
          setLoading(false);
          return;
      }

      // file size should not exceed 5 MB
      if (fileToUpload.size > 5000000) {
        console.error("file cannot be larger than 5 MB");
        enqueueSnackbar("File cannot be larger than 5 MB", {
          variant: "error",
        });
        setLoading(false);
        setProfilePictureUploading(false);
        return;
      }

      setProfilePictureUploading(true);
      setProfilePictureUploadPercentage(0);

      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const fileData: string | null = fileReader.result as string | null;
        if (fileData === null) {
          console.error("file data is null");
          enqueueSnackbar("File data is null", { variant: "error" });
          setLoading(false);
          return;
        }

        setLoading(true);
        try {
          const mimeType = fileData.slice(0, fileData.indexOf(","));
          await Manager.ChangeCompanyProfilePicture({
            context: authContext,
            clientIdentifier: IDIdentifier(loginClaims.clientID),
            pictureData: fileData.slice(fileData.indexOf(",") + 1),
            mimeType: mimeType.slice(
              mimeType.indexOf(":") + 1,
              mimeType.indexOf(";"),
            ),
          });
          const retrieveCompanyProfilePictureResponse =
            await Manager.RetrieveCompanyProfilePicture({
              clientIdentifier: IDIdentifier(loginClaims.clientID),
              context: authContext,
            });
          formUpdater.profilePictureData(
            retrieveCompanyProfilePictureResponse.pictureData
              ? `data:${retrieveCompanyProfilePictureResponse.mimeType};base64,${retrieveCompanyProfilePictureResponse.pictureData}`
              : "",
          );
          setLoading(false);
          setProfilePictureUploading(false);
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error changing profile picture: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `error changing profile picture: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
        }
        setLoading(false);
        setProfilePictureUploading(false);
      };
      fileReader.readAsDataURL(uploadInputRef.files[0]);
    } catch (e) {
      console.error("error trying to access picture file", e);
    }
    setLoading(false);
    setProfilePictureUploading(false);
  };

  //delete user's profile picture
  const handleDeleteProfilePicture = async () => {
    setLoading(true);
    try {
      await Manager.DeleteCompanyProfilePicture({
        context: authContext,
        clientIdentifier: IDIdentifier(loginClaims.clientID),
      });
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    //clear profile picture url from formState
    formUpdater.profilePictureData("");
    setLoading(false);
  };

  return (
    <OnboardingCard
      linksFooter={
        <Link
          id="setupIndividualClientCard-logout-link"
          sx={(theme) => ({
            fontWeight: "bold",
            paddingBottom: theme.spacing(5.5),
          })}
          onClick={async () => {
            await logout();
            navigate("/sign-in");
          }}
          fontSize={"16px"}
          component="button"
          underline="hover"
          color="secondary"
        >
          Logout
        </Link>
      }
    >
      <VerticalSlide transitionDuration={0.13}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 0, 0.5),
          })}
          alignSelf="center"
          fontSize="16px"
          fontWeight="600"
        >
          Company Information
        </Typography>
      </VerticalSlide>
      <VerticalSlide transitionDuration={0.33}>
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(3),
          })}
        >
          {loading || profilePictureUploading ? (
            <Box
              sx={{
                width: 70,
                height: 70,
                padding: 0,
                margin: 0,
              }}
            >
              <CircularProgress
                size={55}
                variant={
                  profilePictureUploadPercentage
                    ? "determinate"
                    : "indeterminate"
                }
                value={profilePictureUploadPercentage}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {formState.profilePictureData && (
                <IconButton disableRipple onClick={handleDeleteProfilePicture}>
                  <DeleteIcon />
                </IconButton>
              )}
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <IconButton
                    disableRipple
                    onClick={() => {
                      if (inputRef.current != null) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    <AddCircleIcon
                      sx={(theme) => ({
                        background: `radial-gradient(circle, #FFFFFF 0%, ${theme.palette.secondary.main} 70%, ${theme.palette.secondary.main} 100%)`,
                        borderRadius: "100%",
                        p: 0,
                      })}
                      color="secondary"
                    />
                  </IconButton>
                }
              >
                <Avatar
                  id="setupIndividualClientProfile-profilePic-button"
                  sx={(theme) => ({
                    width: 70,
                    height: 70,
                    padding: 0,
                    margin: 0,
                    backgroundColor: theme.palette.custom.grape,
                    color: theme.palette.text.primary,
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  })}
                  onClick={() => {
                    if (inputRef.current != null) {
                      inputRef.current.click();
                    }
                  }}
                >
                  {formState.profilePictureData ? (
                    <img
                      onLoad={() => {
                        if (profilePictureRef.current) {
                          setProfilePictureVertical(
                            profilePictureRef.current.naturalWidth <
                              profilePictureRef.current.naturalHeight,
                          );
                        }
                      }}
                      style={{
                        width: !profilePictureVertical ? 100 : 70,
                        height: profilePictureVertical ? 100 : 70,
                      }}
                      ref={profilePictureRef}
                      alt=""
                      src={formState.profilePictureData}
                    />
                  ) : (
                    <BusinessCenterIcon sx={{ width: 50, height: 50 }} />
                  )}
                </Avatar>
              </Badge>
              {formState.profilePictureData && (
                <IconButton
                  disableRipple
                  onClick={() => {
                    if (inputRef.current != null) {
                      inputRef.current.click();
                    }
                  }}
                >
                  <ReloadIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={0.5}>
        <Box
          sx={{
            width: "312px",
            height: "24px",
          }}
        >
          <input
            id={"mobile-hidden-input"}
            style={{
              visibility: "hidden",
              width: 0,
              height: 0,
              margin: 0,
              padding: 0,
            }}
            disabled={loading}
            onChange={handleSelectPictureFile}
            ref={inputRef}
            type="file"
            accept=".png,.jpg,.jpeg"
          />
        </Box>
        <TextField
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
          })}
          fullWidth
          id="setupIndividualClientCard-firstName-formField"
          disabled={loading}
          variant="outlined"
          label="Company Name"
          value={formState.company.registeredName}
          error={!!fieldValidations.companyName}
          helperText={fieldValidations.companyName}
          onChange={(e) => {
            formUpdater.company({
              ...formState.company,
              registeredName: e.target.value,
            });
          }}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={0.83}>
        <TextField
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
            margin: 0,
          })}
          fullWidth
          select
          id="setupIndividualClientCard-lastName-formField"
          disabled={loading}
          variant="outlined"
          label="Company type"
          placeholder={"Select"}
          value={
            formState.company.formOfIncorporation
              ? formState.company.formOfIncorporation
              : LegalForm.SouthAfricanCompanyLegalForm
          }
          onChange={(e) => {
            formUpdater.company({
              ...formState.company,
              formOfIncorporation: e.target.value as LegalForm,
            });
          }}
        >
          <MenuItem value={LegalForm.SoleProprietorShipLegalForm}>
            {LegalForm.SoleProprietorShipLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.CloseCorporationLegalForm}>
            {LegalForm.CloseCorporationLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.SouthAfricanCompanyLegalForm}>
            {LegalForm.SouthAfricanCompanyLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.ForeignCompanyLegalForm}>
            {LegalForm.ForeignCompanyLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.ListedCompanyLegalForm}>
            {LegalForm.ListedCompanyLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.PartnershipLegalForm}>
            {LegalForm.PartnershipLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.TrustLegalForm}>
            {LegalForm.TrustLegalForm}
          </MenuItem>
          <MenuItem value={LegalForm.OtherLegalForm}>
            {LegalForm.OtherLegalForm}
          </MenuItem>
        </TextField>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          sx={(theme) => ({
            height: theme.spacing(6),
            width: "100%",
            marginBottom: theme.spacing(8.5),
          })}
          id="setupIndividualClientCard-submit-button"
          disabled={
            !formState.company.registeredName || validationInProgress || loading
          }
          onClick={handleNextButtonClick}
          variant="contained"
          color="primary"
          size="large"
        >
          Next
        </Button>
      </VerticalSlide>
    </OnboardingCard>
  );
}
