import { JSONRPCMethodError } from "./errors";
import { TranslatedError } from "context/Error";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decodeError(e: any): any {
  if (e instanceof JSONRPCMethodError) {
    return new TranslatedError(e.rpcError.code, e.rpcError.message);
  }
  return e;
}

export { decodeError };
