export class Address {
  public addressLine1 = "";

  public addressLine2 = "";

  public suburb = "";

  public city = "";

  public province = "";

  public countryCode = "";

  public postalCode = "";

  constructor(address?: Omit<Address, "isEmpty">) {
    if (!address) {
      return;
    }
    this.addressLine1 = address.addressLine1;
    this.addressLine2 = address.addressLine2;
    this.suburb = address.suburb;
    this.city = address.city;
    this.province = address.province;
    this.postalCode = address.postalCode;
    this.countryCode = address.countryCode;
  }

  public toString(): string {
    return [
      this.addressLine1,
      this.addressLine2,
      this.suburb,
      this.city,
      this.province,
      this.postalCode,
    ]
      .filter((v) => !!v)
      .join(", ");
  }

  public isEmpty(): boolean {
    return (
      this.addressLine1 === "" &&
      this.addressLine2 === "" &&
      this.suburb === "" &&
      this.city === "" &&
      this.province === "" &&
      this.postalCode === "" &&
      this.countryCode === ""
    );
  }
}
