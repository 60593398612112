import config from "react-global-configuration";
import { Context } from "../security";
import { Amount } from "./Amount";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface MintAssetRequest {
  context: Context;
  assetID: string;
  destinationAccountID: string;
  amount: Amount;
}

export interface MintAssetResponse {
  transactionID: string;
}

export interface BurnAssetRequest {
  context: Context;
  assetID: string;
  accountID: string;
  amount: Amount;
}

export interface BurnAssetResponse {
  transactionID: string;
}

export const AssetTokeniserServiceProviderName = "ledger-AssetTokeniser";

export const AssetTokeniser = {
  async MintAsset(
    request: MintAssetRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<MintAssetResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetTokeniserServiceProviderName}.MintAsset`,
        request,
      },
      opts,
    );

    return {
      transactionID: response.transactionID,
    };
  },
  async BurnAsset(
    request: BurnAssetRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<BurnAssetResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetTokeniserServiceProviderName}.BurnAsset`,
        request,
      },
      opts,
    );

    return {
      transactionID: response.transactionID,
    };
  },
};
