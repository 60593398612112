export enum RatingAgency {
  Moodys = "Moody's Investor Services",
  StandardAndPoors = "Standard & Poor's",
  Fitch = "Fitch Ratings",
}

export const AllRatingAgencies: RatingAgency[] = [
  RatingAgency.Moodys,
  RatingAgency.StandardAndPoors,
  RatingAgency.Fitch,
];

export const Ratings: { [key: string]: string[] } = {
  "Moody's Investor Services": [
    "Aaa",
    "Aa1",
    "Aa2",
    "Aa3",
    "A1",
    "A2",
    "A3",
    "Baa1",
    "Baa2",
    "Baa3",
    "Ba1",
    "Ba2",
    "Ba3",
    "B1",
    "B2",
    "B3",
    "Caa1",
    "Caa2",
    "Caa3",
    "Ca",
    "D",
  ],
  "Standard & Poor's": [
    "AAA",
    "AA+",
    "AA",
    "AA-",
    "A+",
    "A",
    "A-",
    "BBB+",
    "BBB",
    "BBB-",
    "BB+",
    "BB",
    "BB-",
    "B+",
    "B",
    "B-",
    "CCC+",
    "CCC",
    "CCC-",
    "CC",
    "C",
    "D",
  ],
  "Fitch Ratings": [
    "AAA",
    "AA+",
    "AA",
    "AA-",
    "A+",
    "A",
    "A-",
    "BBB+",
    "BBB",
    "BBB-",
    "BB+",
    "BB",
    "BB-",
    "B+",
    "B",
    "B-",
    "CCC+",
    "CCC",
    "CCC-",
    "CC+",
    "CC",
    "CC-",
    "DDD",
  ],
};
