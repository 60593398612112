import { useBankingDetailsViewStore } from "../store";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { BankAccountReader } from "james/banking/bankAccountReader";
import { TextExactCriterion } from "james/search/criterion";
import { BankAccount, BankAccountUpdater } from "james/banking";

// Custom hook to save bank account details.
export const useSaveBankAccountDetails = () => {
  const state = useBankingDetailsViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const saveBankAccountDetails = async (client: Client) => {
    state.setLoading(true);
    try {
      const cachedBankAccount = new BankAccount(state.cachedBankAccount);
      await BankAccountUpdater.UpdateBankAccountUnscoped({
        context: authContext,
        bankAccountID: cachedBankAccount.id,
        bankAccount: new BankAccount({
          id: cachedBankAccount.id,
          ownerID: cachedBankAccount.ownerID,
          countryCode: state.countryCode,
          bankName: state.bankName,
          accountType: state.accountType,
          branchCode: state.branchCode,
          number: state.number,
          auditEntry: cachedBankAccount.auditEntry,
        }),
      });
      const response = await BankAccountReader.ReadBankAccountsUnscoped({
        context: authContext,
        criteria: {
          ownerID: TextExactCriterion(client.ownerID),
        },
      });

      if (response.total == 0) {
        state.setBankNotFound(true);
      } else {
        // Update local state with the fetched bank account details.
        state.updateStateWithBankAccount(response.bankAccounts[0]);
        state.setLastUpdateBy(response.bankAccounts[0].auditEntry.userID);
        state.setLastUpdateDate(response.bankAccounts[0].auditEntry.time);
        state.setCachedBankAccount(response.bankAccounts[0]);
      }

      // Display success message.
      enqueueSnackbar("Bank Account Details Saved", {
        variant: "success",
      });
    } catch (e) {
      // Handle errors.
      console.error(`error saving bank account details`, e);

      errorContextDefaultErrorFeedback(e, "error saving bank account details");
    }
    state.setLoading(false);
  };

  return {
    saveBankAccountDetails,
  };
};
