import React from "react";
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";

interface AddTextFieldProps {
  readOnly?: boolean;
}

export type TextFieldProps = MUITextFieldProps & AddTextFieldProps;

export function TextField({ readOnly, variant, ...rest }: TextFieldProps) {
  if (readOnly) {
    return (
      <MUITextField
        {...rest}
        SelectProps={{
          IconComponent: readOnly ? () => null : undefined,
        }}
        variant="standard"
        InputProps={{
          ...rest.InputProps,
          readOnly: true,
          disableUnderline: true,
        }}
      />
    );
  }

  return <MUITextField {...rest} variant={variant || "outlined"} />;
}
