import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { TextField } from "components/FormFields";
import { Close as CloseIcon } from "@mui/icons-material";
import { FundingOrder } from "@mesh/common-js/dist/banking/fundingOrder_pb";
import { FundingOrderStatusReason } from "james/banking/fundingOrder";
import { useApplicationContext } from "context/Application/Application";
import { useFundingContext } from "../../../Context";

interface ConfirmationProps {
  loading: boolean;
  confirmInfo: boolean;
  onClose: () => void;
  fundingOrder: FundingOrder;
}

export const Confirmation: React.FC<ConfirmationProps> = (
  props: ConfirmationProps,
) => {
  const { authContext } = useApplicationContext();
  const { setFundingOrder, cancelFundingOrder } = useFundingContext();
  const [cancelFundingReason, setCancelFundingReason] = useState("");
  return (
    <Dialog open={props.confirmInfo}>
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        })}
      >
        <Grid item>
          <Typography children="Confirm Funding Cancellation" />
        </Grid>
        <IconButton
          id="fundReceipt-close-button"
          onClick={props.onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "600px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "146px",
        }}
      >
        {!props.loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              fullWidth
              style={{ marginBottom: "16px", width: "400px" }}
              id="fundingCancel-confirmation-dialogTextField"
              label="Cancellation Reason."
              value={cancelFundingReason}
              onChange={(e) => {
                setCancelFundingReason(e.target.value);
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "end",
              }}
            >
              <Button
                id="fundingCancel-confirmation-finish"
                sx={{
                  width: "110px",
                }}
                variant="contained"
                color="primary"
                onClick={async () => {
                  props.fundingOrder.setReason(
                    `${cancelFundingReason};` +
                      `${FundingOrderStatusReason.FundingOrderCancelled} by ${authContext.userID}`,
                  );
                  setFundingOrder(props.fundingOrder);
                  await cancelFundingOrder();
                  props.onClose();
                }}
                disabled={props.loading}
              >
                confirm
              </Button>
            </Box>
          </Box>
        )}
        {props.loading && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};
