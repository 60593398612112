import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FormState } from "../../useFormState";

interface ConfirmationProps {
  formState: FormState;
  handleSubmitButton: () => Promise<void>;
  loading: boolean;
  confirmInfo: boolean;
  setConfirmInfo: Dispatch<SetStateAction<boolean>>;
}

export const Confirmation: React.FC<ConfirmationProps> = (
  props: ConfirmationProps,
) => {
  const [open, setOpen] = useState(true);
  return (
    <Dialog open={open || props.confirmInfo}>
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Typography children="Confirm Company Information" />
      </DialogTitle>
      <DialogContent
        sx={{
          width: "600px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "146px",
        }}
      >
        {!props.loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={(theme) => ({
                padding: theme.spacing(3, 0),
              })}
            >
              By clicking {"'Proceed,'"} you confirm that your company
              information is correct and would like to submit it for
              verification
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "end",
              }}
            >
              <Button
                id="companyRegistration-preview-information"
                sx={(theme) => ({
                  width: "110px",
                  marginRight: theme.spacing(2),
                })}
                onClick={() => {
                  setOpen(false);
                  props.setConfirmInfo(false);
                }}
              >
                Preview
              </Button>
              <Button
                id="companyRegistration-confirmation-finish"
                sx={{
                  width: "110px",
                }}
                variant="contained"
                color="primary"
                onClick={props.handleSubmitButton}
                disabled={props.loading}
              >
                submit
              </Button>
            </Box>
          </Box>
        )}
        {props.loading && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};
