import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { ViewConfiguration } from "james/configuration";
import { useNavigate } from "react-router-dom";
import { Router } from "routes";
import cx from "classnames";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";
import { useApplicationContext } from "context/Application/Application";
import { SubscriptionOrderTable } from "components/MarketSubscriptions/SubscriptionOrderTable";
import { SubscriptionUnderwritingTable } from "components/MarketSubscriptions/SubscriptionUnderwritingTable";

const PREFIX = "MCMarketSubscription";

const classes = {
  content: `${PREFIX}-content`,
  tabBar: `${PREFIX}-tabBar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.tabBar}`]: {
    paddingLeft: theme.spacing(4),
  },
}));

const tabRoutes: RouteType[] = [
  {
    name: "Subscription Order Book",
    id: "subscriptionOrderBook",
    path: "/market-management/subscriptions/subscription-order-book",
    component: <SubscriptionOrderBook />,
    allowSubPaths: true,
  },
  {
    name: "Subscription Order",
    id: "subscriptionOrder",
    path: "/market-management/subscriptions/subscription-order",
    component: (
      <SubscriptionOrderTable system height={window.innerHeight - 138} />
    ),
    allowSubPaths: true,
  },
  {
    name: "Subscription Underwriting",
    id: "subscriptionUnderwriting",
    path: "/market-management/subscriptions/subscription-underwriting",
    component: (
      <SubscriptionUnderwritingTable system height={window.innerHeight - 138} />
    ),
    allowSubPaths: true,
  },
];

function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  const viewConfigurationRules = viewConfig["Subscription"];
  if (!viewConfigurationRules) {
    console.error("no market subscription Books view configuration");
    return [];
  }

  return tabRoutes.filter((route) => viewConfigurationRules[route.name]);
}

export function MCMarketSubscription() {
  const navigate = useNavigate();
  const { viewConfiguration } = useApplicationContext();
  const availableTabRoutes = determineAvailableTabRoutes(viewConfiguration);

  // determine the active path each time the component renders
  const currentRoute = availableTabRoutes.find((r) =>
    window.location.pathname.includes(r.path),
  );

  // set only the active path if the route is not wallet
  let activePath = availableTabRoutes.length ? availableTabRoutes[0].path : "";
  if (currentRoute) {
    activePath = currentRoute.path;
  }

  return (
    <Root>
      <Paper square className={classes.tabBar}>
        <Grid container>
          <Grid item>
            <Tabs
              value={activePath}
              onChange={(_, value) => {
                if (activePath === value) {
                  return;
                }
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {availableTabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <div className={cx(classes.content, "meshScroll")}>
        <Router
          baseURL={"/market-management/subscriptions"}
          redirectPath={
            "/market-management/subscriptions/subscription-order-book"
          }
          routes={availableTabRoutes}
        />
      </div>
    </Root>
  );
}
