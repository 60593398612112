export enum ClaimsType {
  LoginClaimsType = "Login",
  InviteClientClaimsType = "InviteClient",
  InviteUserClaimsType = "InviteUser",
}

export const AllClaimsTypes = [
  ClaimsType.LoginClaimsType,
  ClaimsType.InviteClientClaimsType,
  ClaimsType.InviteUserClaimsType,
];
