import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

interface WarningProps {
  onClose: () => void;
  open: boolean;
}

export function ErrorDialog(props: WarningProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography variant="h6">Error</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          An internal system error has prevented us from determining whether
          this company exists. Please contact support.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="companyRegErrorDialog-dismiss-button"
          onClick={props.onClose}
          autoFocus
          variant="contained"
          color="primary"
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
