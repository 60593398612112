import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Typography,
  alpha,
} from "@mui/material";
import { Amount } from "components/Ledger/Amount";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import { useApplicationContext } from "context/Application/Application";
import { SubscriptionOrderStateChip } from "components/MarketSubscriptions/Chips";

interface SubscriptionOrderViewCardProps {
  selectedOrder: MarketSubscriptionOrderViewModel;
  actionNode?: React.ReactNode;
}

export const SubscriptionOrderViewCard = ({
  selectedOrder,
  actionNode,
}: SubscriptionOrderViewCardProps) => {
  const { myClient } = useApplicationContext();

  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        height: "100%",
        ".valueTypography": {
          color: theme.palette.warning.main,
          fontWeight: "bold",
        },
        ".codeTypography": {
          color: alpha(theme.palette.warning.main, 0.38),
          cursor: "pointer",
          fontWeight: "bold",
        },
        ".codeTypography:hover": {
          color: alpha(theme.palette.warning.main, 0.5),
        },
      })}
    >
      <CardHeader
        avatar={
          <TokenIconViewUpload size={64} token={selectedOrder.tokens.token} />
        }
        titleTypographyProps={{ variant: "h5", fontWeight: "bold" }}
        title={selectedOrder.assetName}
        action={actionNode}
        subheader={selectedOrder.assetShortName}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: theme.palette.custom.spaceblue,
          color: theme.palette.text.secondary,
          py: 0.5,
          px: 4,
        })}
      >
        <Typography>Order No. {selectedOrder.number}</Typography>
      </Box>

      {/* Investor info */}
      <Box sx={{ p: 4 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{ fontSize: "18px", fontWeight: "bold" }}
            >
              Subscription Order
            </Typography>
            <Typography
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              {selectedOrder.ownerID === myClient?.ownerID ? "To" : "From"}{" "}
              {selectedOrder.issuerClientName}
            </Typography>
          </Box>
          <SubscriptionOrderStateChip state={selectedOrder.state} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
          <Avatar src={selectedOrder.investorProfilePictureURL} />
          <Typography sx={{ ml: 2 }} variant="h6">
            {selectedOrder.investorClientName}
          </Typography>
        </Box>
        <Typography sx={(theme) => ({ color: theme.palette.text.tertiary })}>
          Amount
        </Typography>
        <Amount amount={selectedOrder.amount} />
      </Box>

      {/* Issuer info */}
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.spaceblue,
          p: 4,
          height: "100%",
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Avatar src={selectedOrder.issuerProfilePictureURL} />
          <Typography variant="h6" sx={{ ml: 2 }}>
            {selectedOrder.issuerClientName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Typography>Subscription values</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "auto auto",
            color: theme.palette.text.tertiary,
          })}
        >
          <Box>
            <Typography>Units</Typography>
            <Amount reverse amount={selectedOrder.tokens} />
          </Box>
          <Box>
            <Typography>Price Per Unit</Typography>
            <Amount amount={selectedOrder.unitPrice} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
