import React, { useState } from "react";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  DateFieldShowTime,
  DateTimeField,
} from "@mesh/common-js-react/dist/FormFields";
import dayjs from "dayjs";
import {
  allBusinessDayConventions,
  allCalendars,
  allDateGenerationRules,
  allFrequencys,
  businessDayConventionToString,
  calendarToString,
  dateGenerationRuleToString,
  frequencyToString,
} from "@mesh/common-js/dist/financial";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ValidationResult } from "common/validation";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { TextField } from "components/FormFields";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { applyTimeFromDateToDate } from "@mesh/common-js/dist/timeTools";
dayjs.extend(utc);
dayjs.extend(timezone);

export type NonPerpetualScheduleConfigurationFormProps = {
  smartInstrumentIssueDate?: Timestamp;
  timezone: Timezone;
  disabled: boolean;
  readOnly: boolean;
  nonPerpetualScheduleConfiguration: NonPerpetualScheduleConfiguration;
  onChange: (
    updatedScheduleConfiguration: NonPerpetualScheduleConfiguration,
  ) => void;
  formDataValidationResult: ValidationResult;
  validationResultFieldPrefix: string;
};

export const NonPerpetualScheduleConfigurationForm = (
  props: NonPerpetualScheduleConfigurationFormProps,
) => {
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${props.validationResultFieldPrefix}-${field}`
    ];
  };
  // bit of a pattern break to more reliably manage the fact that dates need to be a set
  const [, setUnadjustedDates] = useState(new Set<string>());

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexWrap: "wrap",
        columnGap: theme.spacing(1),
        padding: theme.spacing(0.5, 2),
        alignItems: "center",
      })}
    >
      <DateFieldShowTime
        id="smartInstrument-scheduleConfiguration-nonPerpetual-startDate-dateTimeField"
        sx={{ maxWidth: 245, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"Start Date"}
        value={props.nonPerpetualScheduleConfiguration.getStartdate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setStartdate(
              applyTimeFromDateToDate(props.smartInstrumentIssueDate, newValue),
            ),
          )
        }
        error={!!getFieldValidation("startDate")}
        helperText={getFieldValidation("startDate")}
        timezone={props.timezone}
      />
      <DateFieldShowTime
        id="smartInstrument-scheduleConfiguration-nonPerpetual-firstScheduledDate-dateTimeField"
        sx={{ maxWidth: 245, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"First Scheduled Date"}
        nullable
        value={props.nonPerpetualScheduleConfiguration.getFirstscheduleddate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setFirstscheduleddate(
              applyTimeFromDateToDate(props.smartInstrumentIssueDate, newValue),
            ),
          )
        }
        error={!!getFieldValidation("firstScheduledDate")}
        helperText={getFieldValidation("firstScheduledDate")}
        timezone={props.timezone}
      />
      <DateFieldShowTime
        id="smartInstrument-scheduleConfiguration-nonPerpetual-secondToLastScheduledDate-dateTimeField"
        sx={{ maxWidth: 245, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"Second to Last Scheduled Date"}
        nullable
        value={props.nonPerpetualScheduleConfiguration.getSecondtolastscheduleddate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setSecondtolastscheduleddate(
              applyTimeFromDateToDate(props.smartInstrumentIssueDate, newValue),
            ),
          )
        }
        error={!!getFieldValidation("secondToLastScheduledDate")}
        helperText={getFieldValidation("secondToLastScheduledDate")}
        timezone={props.timezone}
      />
      <DateFieldShowTime
        id="smartInstrument-scheduleConfiguration-nonPerpetual-endDate-dateTimeField"
        sx={{ maxWidth: 245, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"End Date"}
        value={props.nonPerpetualScheduleConfiguration.getEnddate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setEnddate(
              applyTimeFromDateToDate(props.smartInstrumentIssueDate, newValue),
            ),
          )
        }
        error={!!getFieldValidation("endDate")}
        helperText={getFieldValidation("endDate")}
        timezone={props.timezone}
      />
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-frequency-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="Frequency"
        select
        value={props.nonPerpetualScheduleConfiguration.getFrequency()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setFrequency(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("frequency")}
        helperText={getFieldValidation("frequency")}
      >
        {allFrequencys.map((v: Frequency) => {
          return (
            <MenuItem key={v} value={v}>
              {frequencyToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <Autocomplete
        multiple
        fullWidth
        disabled={props.disabled}
        readOnly={props.readOnly}
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-calendars-selectField"
        options={allCalendars.filter((c) => c !== Calendar.UNDEFINED_CALENDAR)}
        getOptionLabel={(option: Calendar) => calendarToString(option)}
        filterSelectedOptions
        ChipProps={{ size: "small" }}
        value={props.nonPerpetualScheduleConfiguration.getCalendarsList()}
        onChange={(_, value: Calendar[]) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setCalendarsList(value),
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={props.disabled}
            readOnly={props.readOnly}
            label="Calendars"
            placeholder="Select..."
            error={!!getFieldValidation("calendars")}
            helperText={getFieldValidation("calendars")}
          />
        )}
      />
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-businessDayConvention-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="Business Day Convention"
        select
        value={props.nonPerpetualScheduleConfiguration.getBusinessdayconvention()}
        onChange={(e) => {
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setBusinessdayconvention(
              Number(e.target.value),
            ),
          );
        }}
        error={!!getFieldValidation("businessDayConvention")}
        helperText={getFieldValidation("businessDayConvention")}
      >
        {allBusinessDayConventions.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {businessDayConventionToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-endDateBusinessDayConvention-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="End Date Business Day Convention"
        select
        value={props.nonPerpetualScheduleConfiguration.getEnddatebusinessdayconvention()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setEnddatebusinessdayconvention(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("endDateBusinessDayConvention")}
        helperText={getFieldValidation("endDateBusinessDayConvention")}
      >
        {allBusinessDayConventions.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {businessDayConventionToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-dateGenerationRule-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="Date Generation Rule"
        select
        value={props.nonPerpetualScheduleConfiguration.getDategenerationrule()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setDategenerationrule(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("dateGenerationRule")}
        helperText={getFieldValidation("dateGenerationRule")}
      >
        {allDateGenerationRules.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {dateGenerationRuleToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(2),
          alignItems: "center",
          width: "100%",
          maxWidth: "100%",
          border: `1px solid ${theme.palette.divider}`,
          padding: 1,
        })}
      >
        {props.readOnly ? (
          <Typography sx={{ minWidth: "150px" }}>Unadjusted Dates</Typography>
        ) : (
          <DateTimeField
            label="Unadjusted Dates"
            id="smartInstrument-scheduleConfiguration-nonPerpetual-unadjustedDates-dateTimeField"
            minDate={dayjs().add(1, "day")}
            disabled={props.disabled}
            readOnly={props.readOnly}
            sx={{ minWidth: "150px" }}
            onChange={(e) => {
              if (e && e.toDate().toString() !== "Invalid Date") {
                setUnadjustedDates((prevSet) => {
                  if (!prevSet.has(e.toDate().toDateString())) {
                    props.onChange(
                      props.nonPerpetualScheduleConfiguration.setUnadjusteddatesList(
                        [
                          e,
                          ...props.nonPerpetualScheduleConfiguration.getUnadjusteddatesList(),
                        ],
                      ),
                    );
                  }
                  return new Set(prevSet.add(e.toDate().toDateString()));
                });
              }
            }}
          />
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            padding: 1,
            width: "100%",
            minHeight: "32px",
            border: `1px solid ${theme.palette.divider}`,
            gap: theme.spacing(1),
          })}
        >
          {props.nonPerpetualScheduleConfiguration.getUnadjusteddatesList()
            .length === 0 ? (
            <Typography>Unadjusted Dates will be shown here</Typography>
          ) : (
            props.nonPerpetualScheduleConfiguration
              .getUnadjusteddatesList()
              .sort()
              .map((timestamp, idx) => {
                return (
                  <Chip
                    key={idx}
                    color="primary"
                    size="small"
                    onDelete={
                      props.readOnly
                        ? undefined
                        : (e) => {
                            setUnadjustedDates((prevState) => {
                              prevState.delete(
                                timestamp.toDate().toDateString(),
                              );
                              if (e) {
                                props.onChange(
                                  props.nonPerpetualScheduleConfiguration.setUnadjusteddatesList(
                                    props.nonPerpetualScheduleConfiguration
                                      .getUnadjusteddatesList()
                                      .filter(
                                        (listTimestamp) =>
                                          listTimestamp !== timestamp,
                                      ),
                                  ),
                                );
                              }
                              return new Set(prevState);
                            });
                          }
                    }
                    label={timestamp.toDate().toDateString()}
                  />
                );
              })
          )}
        </Box>
      </Box>
      <Box
        className="checkboxes"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          maxWidth: 322,
        }}
      >
        <FormControlLabel
          sx={{ width: "100%" }}
          id="smartInstrument-scheduleConfiguration-nonPerpetual-endOfMonthConvention-checkBox"
          disabled={props.disabled}
          control={
            <Checkbox
              disableFocusRipple={props.readOnly}
              disableTouchRipple={props.readOnly}
            />
          }
          label="End of Month Convention"
          checked={props.nonPerpetualScheduleConfiguration.getEndofmonthadjustment()}
          onChange={(_, checked) => {
            if (props.readOnly) {
              return;
            }
            props.onChange(
              props.nonPerpetualScheduleConfiguration.setEndofmonthadjustment(
                checked,
              ),
            );
          }}
        />
      </Box>
    </Box>
  );
};
