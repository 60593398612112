import { DataSheetSection } from "./DataSheetSection";

export const AssetManagingRiskDataSheetSectionTypeName =
  "mesh::marketData/AssetManagingRisk";

export const AssetManagingRiskDataSheetSectionName = "Managing Risk";

export class AssetManagingRiskDataSheetSection implements DataSheetSection {
  public ["@type"] = AssetManagingRiskDataSheetSectionTypeName;

  constructor(l?: AssetManagingRiskDataSheetSection) {
    if (!l) {
      return;
    }
  }

  public DataSheetSectionName(): string {
    return AssetManagingRiskDataSheetSectionName;
  }
}
