import { alpha, Typography } from "@mui/material";
import React from "react";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";

interface NoDataSplashProps {
  text: string;
  subText: string;
}

export const NoDataSplash = ({ text, subText }: NoDataSplashProps) => {
  return (
    <>
      <FaceIcon
        sx={(theme) => ({
          fontSize: 110,
          color: alpha(theme.palette.background.default, 0.5),
        })}
      />
      <Typography
        variant="h4"
        sx={(theme) => ({
          fontWeight: "bold",
          color: theme.palette.text.tertiary,
          margin: theme.spacing(2, 0),
        })}
        children={text}
      />
      <Typography
        variant="h5"
        sx={{
          width: "226px",
          color: (theme) => theme.palette.text.disabled,
          textAlign: "center",
        }}
        children={subText}
      />
    </>
  );
};
