import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IdentificationType } from "james/legal";
import { InviteUserClaims } from "james/security/claims";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import meshLogo from "assets/images/logo/meshLogo.svg";
import { TokenValidator } from "james/security/token";
import { useSnackbar } from "notistack";
import { useFirebaseContext } from "context/Firebase";
import * as Sentry from "@sentry/react";
import { Context } from "james/security";
import { LoadingScreen } from "./components/LoadingScreen";
import { PersonalDetailsStepFields } from "./components/PersonalDetails/PersonalDetails";
import { Finish } from "./components/Finish";
import { PersonalDetails, RegisterCredentials } from "./components";
import { CompanyUserRegistrar } from "james/onboarding/companyUserRegistrar";
import { Authenticator } from "../../james/security/authentication/Authenticator";
import { useErrorContext } from "context/Error";
const PREFIX = "CompanyUserRegistration";

const classes = {
  root: `${PREFIX}-root`,
  registerUserLayout: `${PREFIX}-registerUserLayout`,
};

const StyledNavigate = styled(Navigate)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
  },

  [`& .${classes.registerUserLayout}`]: {
    paddingTop: "90px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    gridRowGap: theme.spacing(1),
  },
}));

enum RegistrationSteps {
  initialisation,
  enterPersonalDetails,
  credentialRegistration,
  meshRegistrationInProgress,
  finishScreen,
}

export const CompanyUserRegistrationLocalStorageKey =
  "meshCompanyUserRegistrationState";

export function CompanyUserRegistration() {
  const { errorContextErrorTranslator } = useErrorContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [registrationStep, setRegistrationStep] = useState(
    RegistrationSteps.initialisation,
  );
  const { enqueueSnackbar } = useSnackbar();

  const { firebaseAuthenticated, firebaseLogout, firebaseUser } =
    useFirebaseContext();
  const clearFirebaseState = useCallback(async () => {
    // if logged into firebase then logout
    if (firebaseAuthenticated) {
      try {
        await firebaseLogout();
        await Authenticator.Logout();
      } catch (e) {
        console.error(`error logging out of firebase: ${e}`);
      }
    }
  }, [firebaseAuthenticated, firebaseLogout]);

  // This useEffect listens to firebase context changes to trigger
  // mesh user registration on successful firebase credential registration.
  const somethingWentWrongTimeout = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  useEffect(() => {
    // if mesh registration already in progress or completed then do nothing
    if (
      [
        RegistrationSteps.meshRegistrationInProgress,
        RegistrationSteps.finishScreen,
      ].includes(registrationStep)
    ) {
      return;
    }

    (async () => {
      // if firebase context is not yet authenticated...
      if (!(firebaseAuthenticated && firebaseUser)) {
        // and company user registration state is set...
        if (localStorage.getItem(CompanyUserRegistrationLocalStorageKey)) {
          // then credential registration has either:
          // - failed (cancelled by user, 3rd party cookies not allowed etc.)
          // - succeeded (and the state will still update)

          // Set a callback to fire in 3s if the state does not get resolved.
          clearTimeout(somethingWentWrongTimeout.current);
          somethingWentWrongTimeout.current = setTimeout(async () => {
            if (!localStorage.getItem(CompanyUserRegistrationLocalStorageKey)) {
              return;
            }
            // notify user
            enqueueSnackbar(
              "Something has gone wrong with your credential registration. Please confirm that your browser has 3rd party cookies enabled and try again.",
              { variant: "error" },
            );

            // notify us using sentry
            Sentry.captureException(
              `company user registration error - potential 3rd party cookie issue - state: ${localStorage.getItem(
                CompanyUserRegistrationLocalStorageKey,
              )}`,
            );
            console.error(
              `company user registration error - potential 3rd party cookie issue - state: ${localStorage.getItem(
                CompanyUserRegistrationLocalStorageKey,
              )}`,
            );

            // clear state and redirect to sign-in
            Sentry.setUser(null);
            await clearFirebaseState();
            localStorage.removeItem(CompanyUserRegistrationLocalStorageKey);
            navigate("/sign-in");
          }, 20000);
        } else {
          // nothing has happened yet - first time landing on screen - do nothing
        }
        return;
      }
      // if execution reaches here then firebase is authenticated

      // set sentry user considering firebase
      Sentry.setUser({
        email: firebaseUser.email ? firebaseUser.email : "",
        id: "",
        ip_address: "{{auto}}",
      });

      // look for stored company user registration state
      const serializedAppState = localStorage.getItem(
        CompanyUserRegistrationLocalStorageKey,
      );

      // confirm that stored company user registration state was found
      if (!serializedAppState) {
        // unexpected state - execution reaching here indicates a bug

        // notify user
        enqueueSnackbar("Unexpected Error Performing Registration", {
          variant: "error",
        });

        // notify us using sentry
        Sentry.captureException(
          "company user registration error - unexpected cleared local state",
        );
        console.error(
          "company user registration error - unexpected cleared local state",
        );

        // clear state and redirect to sign-in
        Sentry.setUser(null);
        clearTimeout(somethingWentWrongTimeout.current);
        await clearFirebaseState();
        navigate("/sign-in");
        return;
      }
      // if execution reaches here then company user registration state was set

      // clear something went wrong timeout and indicate mesh registration has started
      clearTimeout(somethingWentWrongTimeout.current);
      setRegistrationStep(RegistrationSteps.meshRegistrationInProgress);

      // parse application state
      let applicationState: {
        personalDetails: PersonalDetailsStepFields;
        inviteUserClaims: InviteUserClaims;
      };
      try {
        applicationState = JSON.parse(serializedAppState);
        applicationState.inviteUserClaims = new InviteUserClaims(
          applicationState.inviteUserClaims,
        );
        Sentry.setUser({
          email: applicationState.inviteUserClaims.invitedUserEmailAddress,
          id: "",
          ip_address: "{{auto}}",
        });
      } catch (e) {
        // notify user that something has gone wrong
        enqueueSnackbar(
          "Unexpected Error Performing Registration - Corrupt Application State",
          { variant: "error" },
        );

        // notify us using sentry
        Sentry.captureException(
          `company user registration error - error parsing company user registration state '${serializedAppState}': ${e}`,
        );
        console.error(
          `company user registration error - error parsing company user registration state '${serializedAppState}': ${e}`,
        );

        // clear state and redirect to sign-in
        Sentry.setUser(null);
        await clearFirebaseState();
        localStorage.removeItem(CompanyUserRegistrationLocalStorageKey);
        navigate("/sign-in");
        return;
      }

      // Confirm that email address used for credential registration matches
      // that which is within the claims.
      if (
        firebaseUser.email !==
        applicationState.inviteUserClaims.invitedUserEmailAddress
      ) {
        // notify user that something has gone wrong
        enqueueSnackbar(
          `Selected email address ${firebaseUser.email} different from invite ${applicationState.inviteUserClaims.invitedUserEmailAddress}`,
          { variant: "error" },
        );

        // notify us using sentry
        Sentry.captureException(
          `company user registration error: selected email address ${firebaseUser.email} different from invite ${applicationState.inviteUserClaims.invitedUserEmailAddress}`,
        );
        console.error(
          `company user registration error: selected email address ${firebaseUser.email} different from invite ${applicationState.inviteUserClaims.invitedUserEmailAddress}`,
        );

        // clear state and redirect to sign-in
        Sentry.setUser(null);
        await clearFirebaseState();
        localStorage.removeItem(CompanyUserRegistrationLocalStorageKey);
        navigate("/sign-in");
        return;
      }
      // if execution reaches here then everything is good to attempt mesh registration

      // prepare context for registration
      const contextForRegistration = new Context({
        userID: applicationState.inviteUserClaims.invitingUserID,
      });

      // perform registration
      try {
        await CompanyUserRegistrar.RegisterUser({
          context: contextForRegistration,
          ...applicationState.personalDetails,
        });
        enqueueSnackbar("User Registered", {
          variant: "success",
        });
        setRegistrationStep(RegistrationSteps.finishScreen);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        // notify user that something has gone wrong
        enqueueSnackbar(`Error Performing Registration: ${err.message}`, {
          variant: "error",
        });

        // notify us using sentry
        Sentry.captureException(
          `company user registration error: ${
            err.message ? err.message : err.toString()
          }`,
        );
        console.error(`company user registration error`, e);

        // clear state and redirect to sign-in
        Sentry.setUser(null);
        await clearFirebaseState();
        localStorage.removeItem(CompanyUserRegistrationLocalStorageKey);
        navigate("/sign-in");
      }
    })();
  }, [
    registrationStep,
    history,
    firebaseUser,
    firebaseAuthenticated,
    clearFirebaseState,
    enqueueSnackbar,
  ]);

  // useEffect for :
  // - validating token
  // - setting claims in state
  const [inviteUserClaims, setInviteUserClaims] = useState(
    new InviteUserClaims(),
  );
  const [personalDetails, setPersonalDetails] =
    useState<PersonalDetailsStepFields>({
      firstName: "",
      middleNames: "",
      lastName: "",
      nationality: "",
      identificationType: IdentificationType.SouthAfricanIDIdentificationType,
      identificationNumber: "",
    });
  useEffect(() => {
    if (localStorage.getItem(CompanyUserRegistrationLocalStorageKey)) {
      // Do nothing if registration state has previously been persisted.
      // This indicates that the user has returned to the app following
      // credential registration via redirect.
      // This will be dealt with by the useEffect triggered by:
      // [firebaseUser, firebaseAuthenticated]
      return;
    }

    // try and get token from url
    const jwt = searchParams.get("token");
    if (jwt === null) {
      enqueueSnackbar("Registration Token Not Present in URL", {
        variant: "error",
        // id: "validate-token-snackbar", // FIXME: add ID back if relevant
      });
      navigate("/sign-in");
      return;
    }

    // try and parse the token
    let claimsFromJWT: InviteUserClaims;
    try {
      claimsFromJWT = InviteUserClaims.NewFromJWT(jwt);
    } catch (e) {
      enqueueSnackbar("Token in URL Badly Formatted", {
        variant: "error",
        // id: "validate-token-snackbar", // FIXME: add ID back if relevant
      });
      navigate("/sign-in");
      return;
    }

    (async () => {
      try {
        // validate the token
        await TokenValidator.Validate({ token: jwt });
        setInviteUserClaims(claimsFromJWT);
      } catch (e) {
        enqueueSnackbar(
          "The sign-up link is invalid/expired. Please request a new one.",
          {
            variant: "error",
            // id: "validate-token-snackbar", // FIXME: add ID back if relevant
          },
        );
        navigate("/sign-in");
        return;
      }

      setRegistrationStep(RegistrationSteps.enterPersonalDetails);
    })();
  }, [enqueueSnackbar, history]);

  switch (registrationStep) {
    case RegistrationSteps.initialisation:
      return <LoadingScreen message="Getting things ready for you..." />;

    case RegistrationSteps.credentialRegistration:
      return (
        <RegisterCredentials
          inviteUserClaims={inviteUserClaims}
          onStartRegisterCredentials={() => {
            localStorage.setItem(
              CompanyUserRegistrationLocalStorageKey,
              JSON.stringify({
                personalDetails,
                inviteUserClaims,
              }),
            );
          }}
          onFailure={async () => {
            localStorage.removeItem(CompanyUserRegistrationLocalStorageKey);
            await clearFirebaseState();
          }}
        />
      );

    case RegistrationSteps.meshRegistrationInProgress:
      return <LoadingScreen message="Registration in progress..." />;

    case RegistrationSteps.enterPersonalDetails:
    case RegistrationSteps.finishScreen:
      return (
        <div className={classes.root}>
          <img alt="" width={230} src={meshLogo} />
          <div className={classes.registerUserLayout}>
            <Typography variant="h1">You&apos;re here!</Typography>
            <div>
              {(() => {
                switch (registrationStep) {
                  case RegistrationSteps.enterPersonalDetails:
                    return (
                      <PersonalDetails
                        inviteUserClaims={inviteUserClaims}
                        onNext={(
                          updatedPersonalDetails: PersonalDetailsStepFields,
                        ) => {
                          setPersonalDetails(updatedPersonalDetails);
                          setRegistrationStep(
                            RegistrationSteps.credentialRegistration,
                          );
                        }}
                      />
                    );
                  case RegistrationSteps.finishScreen:
                    return (
                      <Finish
                        onFinish={async () => {
                          // clear state and redirect to sign-in
                          Sentry.setUser(null);
                          await clearFirebaseState();
                          localStorage.removeItem(
                            CompanyUserRegistrationLocalStorageKey,
                          );
                          navigate("/sign-in");
                        }}
                      />
                    );
                }
              })()}
            </div>
          </div>
        </div>
      );

    default:
      return <StyledNavigate replace to="/" />;
  }
}
