import { Token } from "james/ledger";
import dayjs from "dayjs";
import { ListingState } from "james/market/Listing";

export const ModelTypeName = "mesh::financialCurrencyStablecoinView/Model";

export enum UnderlyingCurrencyCategory {
  Fiat = "Fiat",
  Crypto = "Crypto",
}

export class Model {
  ["@type"]: string = ModelTypeName;
  id = "";
  currencyStablecoinID = "";
  listingState: ListingState | "" = "";
  name = "";
  shortName = "";
  rate = 0;
  currencyID = "";
  token: Token = new Token();
  issueDate: string = dayjs().format();
  issuer = "";
  underlyingCurrencyCategory: UnderlyingCurrencyCategory | "" = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.currencyStablecoinID = model.currencyStablecoinID;
    this.listingState = model.listingState;
    this.name = model.name;
    this.shortName = model.shortName;
    this.rate = model.rate;
    this.currencyID = model.currencyID;
    this.token = new Token(model.token);
    this.issueDate = model.issueDate;
    this.issuer = model.issuer;
    this.underlyingCurrencyCategory = model.underlyingCurrencyCategory;
  }
}
