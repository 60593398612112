import { IdentifierType } from ".";

export interface ShortNameIdentifierType {
  type: IdentifierType;
  shortName: string;
}

function ShortNameIdentifier(shortName: string): ShortNameIdentifierType {
  return {
    type: IdentifierType.ShortNameIdentifierTypeName,
    shortName,
  };
}

export { ShortNameIdentifier };
