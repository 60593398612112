import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ETNRollCorporateActionState } from "james/financial/ETNRollCorporateAction";
import { Description, Info as InfoIcon, MoreVert } from "@mui/icons-material";
import { ETNRollCorporateActionStateChip } from "./Chips";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";
import { DownloadableDocument } from "james/document/DownloadableDocument";
import { download } from "utilities/network/download";
import { FinancialETNRollCorporateActionViewModel } from "james/views/financialETNRollCorporateActionView";

export type CorporateActionCardProps = {
  etnRollCorporateAction: FinancialETNRollCorporateActionViewModel;
};

export const ETNRollCorporateActionCard = (props: CorporateActionCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        }}
      >
        <Typography
          variant={"h4"}
          color={"primary"}
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
        >
          Roll-Over
        </Typography>
        <Tooltip
          title={
            "Instrument & related attributes renewed and maturity date set to a future date."
          }
          arrow
          placement={"top"}
        >
          <InfoIcon
            sx={{
              color: "secondary.light",
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <ETNRollCorporateActionStateChip
          state={props.etnRollCorporateAction.corporateAction.state}
        />
      </Box>
      {(() => {
        switch (props.etnRollCorporateAction.corporateAction.state) {
          case ETNRollCorporateActionState.Pending:
            return (
              <Typography
                variant={"body1"}
                sx={{
                  color: theme.palette.text.secondary,
                  maxWidth: 566,
                }}
              >
                The reference instrument for the{" "}
                <Typography component={"i"}>
                  {props.etnRollCorporateAction.instrumentName}
                </Typography>{" "}
                note will reach its maturity on{" "}
                <Typography
                  component={"b"}
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: "bold" }}
                >
                  {dayjs(
                    props.etnRollCorporateAction.corporateAction.executionDate,
                  ).format(DateFormat)}
                </Typography>{" "}
                at which point it will be rolled for an additional{" "}
                <Typography
                  component={"b"}
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: "bold" }}
                >
                  {dayjs(
                    props.etnRollCorporateAction.corporateAction
                      .newMaturityDate,
                  ).diff(
                    dayjs(
                      props.etnRollCorporateAction.corporateAction
                        .executionDate,
                    ),
                    "year",
                  )}
                  &nbsp;years.{" "}
                </Typography>{" "}
                The resultant instrument will be due to mature on{" "}
                <Typography
                  component={"b"}
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: "bold" }}
                >
                  {dayjs(
                    props.etnRollCorporateAction.corporateAction
                      .newMaturityDate,
                  ).format(DateFormat)}
                </Typography>
                .
              </Typography>
            );

          case ETNRollCorporateActionState.Executed:
            return (
              <Typography
                variant={"body1"}
                sx={{
                  color: theme.palette.text.secondary,
                  maxWidth: 566,
                }}
              >
                The reference instrument for the{" "}
                <Typography component={"i"}>
                  {props.etnRollCorporateAction.instrumentName}
                </Typography>{" "}
                note reached its maturity on{" "}
                <Typography
                  component={"b"}
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: "bold" }}
                >
                  {dayjs(
                    props.etnRollCorporateAction.corporateAction.executionDate,
                  ).format(DateFormat)}
                </Typography>{" "}
                at which point it was rolled for an additional{" "}
                <Typography
                  component={"b"}
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: "bold" }}
                >
                  {dayjs(
                    props.etnRollCorporateAction.corporateAction
                      .newMaturityDate,
                  ).diff(
                    dayjs(
                      props.etnRollCorporateAction.corporateAction
                        .executionDate,
                    ),
                    "year",
                  )}
                  &nbsp;years.{" "}
                </Typography>{" "}
                It is now due to mature on{" "}
                <Typography
                  component={"b"}
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: "bold" }}
                >
                  {dayjs(
                    props.etnRollCorporateAction.corporateAction
                      .newMaturityDate,
                  ).format(DateFormat)}
                </Typography>
                .
              </Typography>
            );
          default:
            return null;
        }
      })()}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          paddingTop: theme.spacing(2),
          gap: theme.spacing(3.5),
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: theme.spacing(1.5),
          },
        }}
      >
        <Box
          sx={{
            flex: 0.8,
          }}
        >
          <Card
            sx={(theme) => ({ backgroundColor: theme.palette.custom.midnight })}
          >
            <CardContent sx={{ paddingBottom: "8px !important" }}>
              <Typography
                sx={{
                  color: theme.palette.custom.lavender,
                  paddingBottom: theme.spacing(2),
                }}
                variant={"h6"}
              >
                Instrument Information
              </Typography>
              <Typography
                variant={"body2"}
                sx={(theme) => ({ color: theme.palette.warning.main })}
              >
                Pre-Rollover
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <TextField
                  sx={{ marginBottom: 0 }}
                  label={"ISIN"}
                  variant={"standard"}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  value={props.etnRollCorporateAction.corporateAction.oldISIN}
                />
                <TextField
                  sx={{ marginBottom: 0 }}
                  label={"Exchange Code"}
                  variant={"standard"}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  value={
                    props.etnRollCorporateAction.corporateAction.oldExchangeCode
                  }
                />
              </Box>
              <Divider
                sx={{
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                }}
              />
              <Typography
                variant={"body2"}
                sx={(theme) => ({ color: theme.palette.warning.main })}
              >
                Post-Rollover
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <TextField
                  sx={{ marginBottom: 0 }}
                  label={"ISIN"}
                  variant={"standard"}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  value={props.etnRollCorporateAction.corporateAction.newISIN}
                />
                <TextField
                  sx={{ marginBottom: 0 }}
                  label={"Exchange Code"}
                  variant={"standard"}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  value={
                    props.etnRollCorporateAction.corporateAction.newExchangeCode
                  }
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1.5),
          }}
        >
          <Typography
            sx={{
              paddingTop: theme.spacing(2),
            }}
            variant={"h6"}
          >
            Supporting Documentation
          </Typography>
          {props.etnRollCorporateAction.documents.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowX: "hide",
                overflowY: "auto",
                maxHeight: 156,
              }}
              className={"meshScroll"}
            >
              {props.etnRollCorporateAction.documents.map((document, idx) => (
                <React.Fragment key={idx}>
                  <DownloadableDocumentLine
                    documentIdx={idx}
                    document={document}
                  />
                  {!!(
                    props.etnRollCorporateAction.documents.length - 1 &&
                    idx !== props.etnRollCorporateAction.documents.length - 1
                  ) && (
                    <Divider
                      sx={{
                        marginTop: theme.spacing(1.5),
                        marginBottom: theme.spacing(1.5),
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Typography
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              No Documents to Show.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

type DownloadableDocumentLineProps = {
  documentIdx: number;
  document: DownloadableDocument;
};

const DownloadableDocumentLine = (props: DownloadableDocumentLineProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const vertOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const menuOnClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const { document } = props;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          columnGap: theme.spacing(2),
        }}
      >
        <Description color="disabled" sx={{ gridRow: "1/3" }} />
        <Typography color="textSecondary" variant="body2">
          {document.description}
        </Typography>
        <Typography color="secondary" variant="body2">
          {document.name}
        </Typography>
        <IconButton
          sx={{
            gridRow: "1/3",
            gridColumn: "3/4",
          }}
          id={`corporateActionCard-document-menuOpen-button-${props.documentIdx}`}
          onClick={vertOnClick}
          size={"small"}
        >
          <MoreVert />
        </IconButton>
      </Box>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        id={`corporateActionCard-document-menu-${props.documentIdx}`}
        onClose={menuOnClose}
        autoFocus={false}
      >
        <MenuItem
          id={`corporateActionCard-document-menuItem-view-${props.documentIdx}`}
          onClick={() => {
            window.open(document.downloadURL, document.name);
            menuOnClose();
          }}
        >
          View
        </MenuItem>
        <MenuItem
          id={`corporateActionCard-document-menuItem-download-${props.documentIdx}`}
          onClick={() => {
            download(document.downloadURL, document.name);
            menuOnClose();
          }}
        >
          Download
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
