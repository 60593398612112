import { TransactionStateChangedNotification } from "./TransactionStateChangedNotification";

export const TransactionRejectedNotificationTypeName =
  "consistency::horizon/TransactionRejectedNotification";

export class TransactionRejectedNotification
  implements TransactionStateChangedNotification
{
  public ["@type"]: string = TransactionRejectedNotificationTypeName;

  public transactionID = "";

  public reason = "";

  constructor(n?: TransactionRejectedNotification) {
    if (!n) {
      return;
    }
    this.transactionID = n.transactionID;
    this.reason = n.reason;
  }

  notificationType(): string {
    return TransactionRejectedNotificationTypeName;
  }

  transactionStateChangedID(): string {
    return this.transactionID;
  }
}
