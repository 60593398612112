import { Paper, Theme, styled } from "@mui/material";

const tableTitleRowHeight = 52;
const tableFilterPanelHeight = 80;

const PREFIX = "BPTable";

const classes = {
  root: `${PREFIX}-root`,
  tableHead: `${PREFIX}-tableHead`,
  tableTitleLayout: `${PREFIX}-tableTitleLayout`,
  tableTitleControlLayout: `${PREFIX}-tableTitleControlLayout`,
  filterLayout: `${PREFIX}-filterLayout`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellMinNonDensePadding: `${PREFIX}-tableCellMinNonDensePadding`,
  tableCellFirstColumnSingleSelectModeOn: `${PREFIX}-tableCellFirstColumnSingleSelectModeOn`,
  tableCellNoBorderBottom: `${PREFIX}-tableCellNoBorderBottom`,
  tableHeaderCell: `${PREFIX}-tableHeaderCell`,
  tableRow: `${PREFIX}-tableRow`,
  tableRowSelected: `${PREFIX}-tableRowSelected`,
  tableRowExpandRow: `${PREFIX}-tableRowExpandRow`,
  tableRowExpandCellOpen: `${PREFIX}-tableRowExpandCellOpen`,
  tableRowExpandCellClosed: `${PREFIX}-tableRowExpandCellClosed`,
  tableToolbarRowSelectedHighlight: `${PREFIX}-tableToolbarRowSelectedHighlight`,
  expandRowComponentWrapper: `${PREFIX}-expandRowComponentWrapper`,
  tableFooter: `${PREFIX}-tableFooter`,
  tableFooterCondenseSwitchLayout: `${PREFIX}-tableFooterCondenseSwitchLayout`,
  tablePaginationRoot: `${PREFIX}-tablePaginationRoot`,
  filterIcon: `${PREFIX}-filterIcon`,
  filterIconPanelOpen: `${PREFIX}-filterIconPanelOpen`,
};

const styling = ({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.tableHead}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.tableTitleLayout}`]: {
    height: tableTitleRowHeight,
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.custom.midnight,
    padding: theme.spacing(1, 3, 1, 3),
    alignItems: "center",
    justifyItems: "center",
    borderRadius: "inherit",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },

  [`& .${classes.tableTitleControlLayout}`]: {
    marginLeft: "auto",
    display: "grid",
    alignItems: "center",
  },

  [`& .${classes.filterLayout}`]: {
    height: tableFilterPanelHeight,
    overflowX: "hidden",
    overflowY: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    display: "grid",
    alignItems: "center",
  },

  [`& .${classes.tableWrapper}`]: {
    transition: "height 0.3s ease-out",
    overflow: "auto",
    flexGrow: 1,
  },

  [`& .${classes.tableCell}`]: {
    backgroundColor: "inherit",
    color: "inherit",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.tableCellMinNonDensePadding}`]: {
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableCellFirstColumnSingleSelectModeOn}`]: {
    paddingLeft: theme.spacing(3),
  },

  [`& .${classes.tableCellNoBorderBottom}`]: {
    borderBottom: "none",
  },

  [`& .${classes.tableHeaderCell}`]: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontWeight: "bold",
    fontSize: 14,
    height: 43,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.tableRowSelected}`]: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },

  [`& .${classes.tableRowExpandRow}`]: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.tertiary,
    border: "none",
  },

  [`& .${classes.tableRowExpandCellOpen}`]: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.tableRowExpandCellClosed}`]: {
    color: theme.palette.text.tertiary,
    border: "none",
  },

  [`& .${classes.tableToolbarRowSelectedHighlight}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
  },

  [`& .${classes.expandRowComponentWrapper}`]: {
    padding: theme.spacing(1, 1, 1, 12),
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.tableFooter}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.tableFooterCondenseSwitchLayout}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(0.5),
    alignItems: "center",
    height: 52,
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.tablePaginationRoot}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    overflow: "hidden",
    height: 52,
  },

  [`& .${classes.filterIcon}`]: {
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.filterIconPanelOpen}`]: {
    color: theme.palette.action.active,
  },
});

const StyledDiv = styled("div")(styling);

const StyledPaper = styled(Paper)(styling);

export {
  tableFilterPanelHeight,
  tableTitleRowHeight,
  StyledPaper,
  StyledDiv,
  classes,
};
