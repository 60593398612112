import React from "react";
import {
  Box,
  Button,
  Link,
  Skeleton,
  SvgIconTypeMap,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ClientKYCStatus } from "james/client";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import { useNavigate } from "react-router-dom";
import { SkeletonLoader } from "components/SkeletonLoader";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useApplicationContext } from "context/Application/Application";
import background from "assets/images/background/background.png";

interface KYCStatusCardProps {
  leave: (func: () => void) => void;
  disableViewButton: boolean;
  loading: boolean;
}

export const KYCStatusCard = ({
  leave,
  loading,
  disableViewButton,
}: KYCStatusCardProps) => {
  const theme = useTheme();
  const { KYCcolor, KYCtext, KYClogo } = useKYCInfo();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        [theme.breakpoints.up("lg")]: {
          maxWidth: "291px",
          maxHeight: "330px",
          padding: theme.spacing(5),
          margin: theme.spacing(1.5),
        },
        [theme.breakpoints.down("lg")]: {
          width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(3),
          my: theme.spacing(1),
          borderRadius: "10px",
        },
        [theme.breakpoints.down("sm")]: {
          backgroundImage: `url(${background})`,
          backgroundPosition: "75% 45%",
          boxShadow: `10px -7px 400px 200px rgba(23,20,51,0.60) inset`,
          width: "100%",
        },
        display: "flex",
        alignItems: "center",
        flexDirection: {
          lg: "column",
          sm: "row",
          xs: "column",
        },
        justifyContent: "center",
        padding: theme.spacing(3),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minWidth: "200px",
        }}
      >
        <SkeletonLoader
          loading={loading}
          variant="circular"
          width="40px"
          height="40px"
        >
          <KYClogo sx={{ width: "40px", height: "40px", color: KYCcolor }} />
        </SkeletonLoader>
        <Box
          sx={{
            mt: theme.spacing(1),
            display: "flex",
            flexWrap: "wrap",
            aignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkeletonLoader loading={loading} height="32px" width="80%">
            <Typography
              align="center"
              sx={{ color: KYCcolor, fontSize: "16px" }}
              children={`User Account ${KYCtext}`}
            />
          </SkeletonLoader>
        </Box>
      </Box>

      <Box
        sx={{
          margin: {
            sm: theme.spacing(0, 0, 0, 2),
            lg: theme.spacing(2, 0, 0, 0),
            xs: theme.spacing(2, 0, 0, 0),
          },
          display: "flex",
          flexDirection: "column",
          width: {
            sm: "60%",
            lg: "auto",
          },
        }}
      >
        {!loading ? (
          <Typography
            align={isMobile || isDesktop ? "center" : "left"}
            sx={{
              maxWidth: { lg: "360px" },
              color: theme.palette.text.secondary,
              fontSize: "12px",
            }}
          >
            Verification is a mandatory process required to confirm your
            identity.
          </Typography>
        ) : (
          <>
            <Skeleton width="100%" height="24px" />
            <Skeleton width="100%" height="24px" />
          </>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "column",
              sm: "row",
              xs: "column",
            },
            margin: {
              sm: theme.spacing(2, 2, 0, 1),
              lg: 0,
            },
            alignItems: "center",
            width: "100%",
          }}
        >
          <SkeletonLoader loading={loading}>
            <Button
              id="KycCard-View-Button"
              size="large"
              variant="outlined"
              sx={{
                border: 0.5,
                margin: {
                  lg: theme.spacing(2, 0),
                  sm: theme.spacing(0, 2, 0, 0),
                  xs: theme.spacing(2, 0),
                },
                width: "110px",
                height: {
                  sm: "36px",
                  xs: "48px",
                },
              }}
              disabled={disableViewButton}
              onClick={() => leave(() => navigate("/kyc"))}
            >
              View
            </Button>
          </SkeletonLoader>
          <SkeletonLoader loading={loading} sx={{ mx: 2 }}>
            <Link
              id="KycCard-LearnMore-Link"
              underline="none"
              onClick={() => {
                window.open(
                  "http://support.mesh.trade/support/solutions/80000417840",
                );
              }}
            >
              Learn more
            </Link>
          </SkeletonLoader>
        </Box>
      </Box>
    </Box>
  );
};

interface KYCInfo {
  KYClogo: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">>;
  KYCcolor: string;
  KYCtext: string;
}

const useKYCInfo = (): KYCInfo => {
  const { myClientKYCStatus } = useApplicationContext();
  const theme = useTheme();

  switch (myClientKYCStatus) {
    case ClientKYCStatus.Outstanding:
      return {
        KYClogo: GppMaybeOutlinedIcon,
        KYCcolor: theme.palette.warning.light,
        KYCtext: "Verification Outstanding",
      };
    case ClientKYCStatus.VerificationInProgressStatus:
      return {
        KYClogo: GppMaybeOutlinedIcon,
        KYCcolor: theme.palette.info.light,
        KYCtext: "Verification In Progress",
      };
    case ClientKYCStatus.VerifiedStatus:
      return {
        KYClogo: VerifiedUserOutlinedIcon,
        KYCcolor: theme.palette.success.light,
        KYCtext: "Verified",
      };
    default:
      return {
        KYClogo: GppMaybeOutlinedIcon,
        KYCcolor: theme.palette.info.light,
        KYCtext: "",
      };
  }
};
