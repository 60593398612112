import React, { useEffect } from "react";
import { RouteType } from "routes/Route";
import { useLocation, useNavigate } from "react-router-dom";
import { Router } from "routes";
import { Explore, Table } from "./components";

const groupRoutes: RouteType[] = [
  {
    name: "Table",
    id: "table",
    path: "/administration/manage/groups/table",
    component: <Table />,
  },
  {
    name: "Explore",
    id: "explore",
    path: "/administration/manage/groups/explore",
    component: <Explore />,
  },
];

function determineAvailableRoutes(): RouteType[] {
  return groupRoutes;
}

export function Groups() {
  const navigate = useNavigate();
  const location = useLocation();

  const availableRoutes = determineAvailableRoutes();

  // navigate to first tab
  useEffect(() => {
    // confirm that some tab routes are available
    if (!availableRoutes.length) {
      console.error("no routes for groups available");
      return;
    }

    // if some are available navigate to first available one
    // unless a path to one of them has already been set
    for (const route of availableRoutes) {
      if (location.pathname.includes(route.path)) {
        return;
      }
    }
    navigate(availableRoutes[0].path);
  }, [availableRoutes, location.pathname]);

  return (
    <Router
      baseURL={"/administration/manage/groups"}
      routes={availableRoutes}
    />
  );
}
