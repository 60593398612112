import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Query as PastQuery } from "james/search/query";
import { useAPIContext } from "../../../context/API";
import { useApplicationContext } from "context/Application/Application";
import { CapturePrimeRateRequest } from "@mesh/common-js/dist/financial/primeRateRecorder_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
  formatTextNum,
} from "@mesh/common-js/dist/num";
import BigNumber from "bignumber.js";
import {
  DateTimeField,
  TextNumField,
} from "@mesh/common-js-react/dist/FormFields";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Info as InfoIcon, Refresh as ReloadIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import {
  ReadManyPrimeRateRequest,
  ReadManyPrimeRateResponse,
} from "@mesh/common-js/dist/financial/primeRateReader_meshproto_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { useIsMounted } from "hooks";
import { PrimeRate } from "@mesh/common-js/dist/financial/primeRate_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { BPTable } from "components/Table";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
dayjs.extend(utc);

const initialQuery = new Query().setOffset(0).setLimit(15);

export const PrimeRateCapturer = () => {
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const isMounted = useIsMounted();
  const { authContext, viewConfiguration } = useApplicationContext();
  const [timezone, setTimezone] = useState<Timezone>(Timezone.SAST_TIMEZONE);
  const [showRateRecordDialog, setShowRateRecordDialog] = useState(false);
  const {
    financial: { primeRateReader },
  } = useAPIContext();
  const [loading, setLoading] = useState(false);

  const [readRequest, setReadRequest] = useState<ReadManyPrimeRateRequest>(
    new ReadManyPrimeRateRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  );
  const [readResponse, setReadResponse] = useState<ReadManyPrimeRateResponse>(
    new ReadManyPrimeRateResponse(),
  );
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        const response = await primeRateReader.readManyPrimeRate(readRequest);
        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error("error reading prime rates", e);
        setError(`error reading prime rates: ${e}`);
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted, error]);
  const reload = () =>
    setReadRequest(
      new ReadManyPrimeRateRequest()
        .setContext(readRequest.getContext())
        .setCriteriaList(readRequest.getCriteriaList())
        .setQuery(readRequest.getQuery()),
    );

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Builder"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while initialsing the builder.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setError(undefined)}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <>
      <BPTable
        disableSelect
        loading={loading}
        toolBarControls={(() => {
          const contols: React.ReactNode[] = [];

          if (viewConfiguration["Rate Sources"]?.["Prime Rate Capturer"]) {
            contols.push(
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowRateRecordDialog(true)}
              >
                Record a Rate
              </Button>,
            );
          }

          contols.push(
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Timezone"
                select
                value={timezone}
                onChange={(e) => setTimezone(Number(e.target.value))}
              >
                {allTimezones
                  .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
                  .map((tz, idx) => (
                    <MenuItem key={idx} value={tz}>
                      {timezoneToString(tz)}
                    </MenuItem>
                  ))}
              </TextField>
              <Tooltip title="The timezone in which times will be shown on the form. All timezones stored in UTC.">
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>,
          );

          contols.push(
            <IconButton size={"small"} disabled={loading} onClick={reload}>
              <ReloadIcon />
            </IconButton>,
          );

          return contols;
        })()}
        height={window.innerHeight - 138 - noticeBannerHeight}
        columns={[
          {
            label: "Date",
            field: "date",
            accessor: (data) => {
              data as PrimeRate;
              return protobufTimestampToDayjs(data.getDate() ?? new Timestamp())
                .tz(timezoneToString(timezone))
                .format(DateTimeFormatWithOffset);
            },
          },
          {
            label: "Rate",
            field: "rate.float",
            accessor: (data) => {
              data as PrimeRate;
              return formatTextNum(
                decimalToBigNumber(data.getRate() ?? new Decimal()),
              );
            },
          },
          {
            label: "Last Modified",
            field: "auditentry.date",
            accessor: (data) => {
              const rowData = data as PrimeRate;
              return protobufTimestampToDayjs(
                rowData.getAuditentry()?.getTime() ?? new Timestamp(),
              )
                .tz(timezoneToString(timezone))
                .format(DateTimeFormatWithOffset);
            },
          },
        ]}
        query={PastQuery.fromFutureQuery(readRequest.getQuery())}
        onQueryChange={(query) =>
          setReadRequest(readRequest.setQuery(query.toFutureQuery()))
        }
        title={"South African Prime Rates"}
        data={readResponse.getRecordsList()}
        totalNoRecords={readResponse.getTotal()}
      />
      {showRateRecordDialog && (
        <PrimeRateRecorderDialog
          closeDialog={() => {
            setShowRateRecordDialog(false);
            reload();
          }}
        />
      )}
    </>
  );
};

type PrimeRateRecorderDialogProps = {
  closeDialog: () => void;
};

const PrimeRateRecorderDialog = (props: PrimeRateRecorderDialogProps) => {
  const [timezone, setTimezone] = useState<Timezone>(Timezone.SAST_TIMEZONE);
  const { authContext, viewConfiguration } = useApplicationContext();
  const [formState, setFormState] = useState({
    captureRequest: new CapturePrimeRateRequest()
      .setContext(authContext.toFuture())
      .setDate(
        dayjsToProtobufTimestamp(dayjs().utc().startOf("day").set("h", -2)),
      )
      .setRate(bigNumberToDecimal(new BigNumber(10.12))),
  });
  const {
    financial: { primeRateRecorder },
  } = useAPIContext();
  const [loading, setLoading] = useState(false);

  const captureRate = async () => {
    setLoading(true);
    try {
      await primeRateRecorder.capturePrimeRate(formState.captureRequest);
      enqueueSnackbar({
        message: "Prime Rate Captured",
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar({
        message: `Error Capturing Prime Rate: ${e}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open onClose={props.closeDialog}>
      <DialogTitle>Record Prime Rate</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
          <TextField
            label="Timezone"
            select
            value={timezone}
            onChange={(e) => setTimezone(Number(e.target.value))}
          >
            {allTimezones
              .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
              .map((tz, idx) => (
                <MenuItem key={idx} value={tz}>
                  {timezoneToString(tz)}
                </MenuItem>
              ))}
          </TextField>
          <Tooltip title="The timezone in which times will be shown on the form. All timezones stored in UTC.">
            <InfoIcon sx={{ ml: 1 }} />
          </Tooltip>
        </Box>
        <DateTimeField
          timezone={timezone}
          disabled={loading}
          label="Date"
          value={formState.captureRequest.getDate()}
          onChange={(newValue) =>
            setFormState({
              captureRequest: formState.captureRequest.setDate(newValue),
            })
          }
        />
        <TextNumField
          disabled={loading}
          fullWidth
          label="Rate"
          value={formState.captureRequest.getRate()}
          onChange={(newValue: Decimal) =>
            setFormState({
              captureRequest: formState.captureRequest.setRate(newValue),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={captureRate}
          loading={loading}
          disabled={
            viewConfiguration["Rate Sources"]?.["Prime Rate Capturer"]
              ? false
              : true
          }
        >
          Capture Rate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
