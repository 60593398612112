import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Cancel as CancelIcon, Close as CloseIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { ManagerWrite as ClientManagerWrite } from "james/client/ManagerWrite";
import { AllClientTypes, ClientType } from "james/client";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";
import { useValidatedForm } from "hooks/useForm";
import {
  InviteCompanyFormType,
  InviteCompanyFormUpdateSpecs,
  InviteCompanyFormUpdater,
  performValidation,
} from "./InviteCompanyForm";

interface Props {
  closeDialog: () => void;
}

export function InviteCompanyDialog(props: Props) {
  const { authContext } = useApplicationContext();

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm<InviteCompanyFormType, InviteCompanyFormUpdateSpecs>(
    performValidation,
    undefined,
    InviteCompanyFormUpdater,
    {
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      clientShortName: "",
      clientTypes: [],
    },
    new Set<string>([]),
  );

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();

  const handleSendInvitation = async () => {
    if (!formDataValidationResult.valid) {
      return;
    }
    setLoading(true);
    try {
      await ClientManagerWrite.InviteCompanyClient({
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyName: formData.companyName,
        clientShortName: formData.clientShortName,
        clientTypes: formData.clientTypes,
        context: authContext,
      });
      enqueueSnackbar("Invite Sent", { variant: "success" });
      props.closeDialog();
    } catch (e) {
      errorContextDefaultErrorFeedback(e, "Error Inviting Company Client");
    }
    setLoading(false);
  };

  return (
    <Dialog open maxWidth="lg">
      <DialogTitle>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h5" children="Invite a Company" />
          </Grid>
          {loading && (
            <Grid item>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip
              title={
                formDataValidationResult.valid ? "" : "All fields are required"
              }
            >
              <span>
                <Button
                  id="inviteCompany-sendInvitation-button"
                  disabled={
                    loading ||
                    !formDataValidationResult.valid ||
                    formDataValidationInProgress
                  }
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSendInvitation}
                  children="send invitation"
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="inviteCompany-close-button"
                  size="small"
                  disabled={loading}
                  onClick={() => props.closeDialog()}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={(theme: Theme) => ({
          width: "488px",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridRowGap: theme.spacing(2),
        })}
      >
        <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
          Please fill in the information below to invite a company to sign up on
          Mesh.DCM.
        </Typography>

        <Box
          sx={(theme: Theme) => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridColumnGap: theme.spacing(1),
          })}
        >
          <Typography
            sx={{
              gridColumn: "1/3",
              pb: 1,
            }}
            variant="h6"
            children="Representative Personal Details"
          />
          <TextField
            label="First Name"
            id="inviteCompany-firstName-textfield"
            value={formData.firstName}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => {
              formDataUpdate.firstName(e.target.value);
            }}
            error={!!formDataValidationResult.fieldValidations.firstName}
            helperText={formDataValidationResult.fieldValidations.firstName}
            disabled={loading}
          />
          <TextField
            label="Last Name"
            id="inviteCompany-lastName-textfield"
            value={formData.lastName}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => {
              formDataUpdate.lastName(e.target.value);
            }}
            error={!!formDataValidationResult.fieldValidations.lastName}
            helperText={formDataValidationResult.fieldValidations.lastName}
            disabled={loading}
          />
          <TextField
            sx={{
              gridColumn: "1/3",
            }}
            label="Email Address"
            id="inviteCompany-emailAddress-textfield"
            value={formData.email}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => {
              formDataUpdate.email(e.target.value);
            }}
            error={!!formDataValidationResult.fieldValidations.email}
            helperText={formDataValidationResult.fieldValidations.email}
            disabled={loading}
          />
        </Box>

        <Box
          sx={(theme: Theme) => ({
            display: "grid",
            gridTemplateColumns: "2fr 1.5fr",
            gridColumnGap: theme.spacing(1),
          })}
        >
          <Typography
            sx={{
              gridColumn: "1/3",
              pb: 1,
            }}
            variant="h6"
            children="Company Details"
          />
          <TextField
            label="Company Name"
            id="inviteCompany-companyName-textfield"
            value={formData.companyName}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => {
              formDataUpdate.companyName(e.target.value);
            }}
            error={!!formDataValidationResult.fieldValidations.companyName}
            helperText={formDataValidationResult.fieldValidations.companyName}
            disabled={loading}
          />
          <TextField
            label="Company Short Name"
            id="inviteCompany-companyShortName-textfield"
            value={formData.clientShortName}
            inputProps={{ maxLength: 4 }}
            onChange={(e) => {
              formDataUpdate.clientShortName(e.target.value);
            }}
            error={!!formDataValidationResult.fieldValidations.clientShortName}
            helperText={
              formDataValidationResult.fieldValidations.clientShortName
            }
            disabled={loading}
          />
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            id="inviteCompany-clientTypes-autocomplete"
            options={AllClientTypes}
            filterSelectedOptions
            sx={{
              gridColumn: "1/3",
            }}
            value={formData.clientTypes}
            disabled={loading}
            onChange={(_, selected: ClientType[]) => {
              formDataUpdate.clientTypes(selected);
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={formData.clientTypes ? undefined : "select..."}
                error={!!formDataValidationResult.fieldValidations.clientTypes}
                helperText={
                  formDataValidationResult.fieldValidations.clientTypes
                }
              />
            )}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
