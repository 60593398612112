export enum AssetClassDEPRECATED {
  Equity = "Equity",
  Cash = "Cash",
  FixedIncome = "Fixed Income",
  Debt = "Debt",
  RealEstate = "Real Estate",
  Fund = "Fund",
  Commodities = "Commodities",
}

export const AllAssetClassesDEPRECATED: AssetClassDEPRECATED[] = [
  AssetClassDEPRECATED.Equity,
  AssetClassDEPRECATED.Cash,
  AssetClassDEPRECATED.FixedIncome,
  AssetClassDEPRECATED.Debt,
  AssetClassDEPRECATED.RealEstate,
  AssetClassDEPRECATED.Fund,
  AssetClassDEPRECATED.Commodities,
];
