import { Context } from "james/security";
import { Client } from "james/client";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { useEffect, useState } from "react";
import { useApplicationContext } from "context/Application/Application";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { useErrorContext } from "context/Error";

export interface RetrieveClientRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveClientResponse {
  client: Client;
}

export interface SearchClientsRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchClientsResponse {
  records: Client[];
  total: number;
}

export const Repository = {
  serviceProvider: "client-Repository",
  async RetrieveClient(
    request: RetrieveClientRequest,
  ): Promise<RetrieveClientResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.RetrieveClient`,
      request,
    });
    return { client: new Client(response.client) };
  },
  async SearchClients(
    request: SearchClientsRequest,
  ): Promise<SearchClientsResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.SearchClients`,
      request,
    });
    return {
      records: response.records.map((c: Client) => new Client(c)),
      total: response.total,
    };
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let searchClientsTimeout: any;

export function useClientRepositorySearchClients(
  initialSearchClientsRequest?: SearchClientsRequest,
) {
  const { authContext } = useApplicationContext();
  const [searchClientsRequest, setSearchClientsRequest] =
    useState<SearchClientsRequest>(
      initialSearchClientsRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchClientsResponse, setSearchClientsResponse] =
    useState<SearchClientsResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    clearTimeout(searchClientsTimeout);
    searchClientsTimeout = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchClientsResponse(
          await Repository.SearchClients(searchClientsRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for clients: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for clients: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchClientsRequest]);

  return {
    searchClientsRequest,
    setSearchClientsRequest,
    searchClientsResponse,
    loading,
    error,
  };
}
