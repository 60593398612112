import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface SendIndividualVerificationMailRequest {
  firebaseToken: string;
}

export interface SendIndividualVerificationMailResponse {
  expTime: string;
  expPeriod: number;
}

export interface SendCompanyVerificationMailRequest {
  firebaseToken: string;
}

export interface SendCompanyVerificationMailResponse {
  expTime: string;
  expPeriod: number;
}

export interface VerifyEmailTOTPRequest {
  firebaseToken: string;
  totp: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VerifyEmailTOTPResponse {}

export const VerificationEmailMailerServiceProviderName =
  "onboarding-VerificationEmailMailer";

export const UserEmailVerifier = {
  async SendIndividualVerificationMail(
    request: SendIndividualVerificationMailRequest,
  ): Promise<SendIndividualVerificationMailResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${VerificationEmailMailerServiceProviderName}.SendIndividualVerificationMail`,
      request,
    });
    return {
      expTime: response.expTime,
      expPeriod: response.expPeriod,
    };
  },
  async SendCompanyVerificationMail(
    request: SendCompanyVerificationMailRequest,
  ): Promise<SendCompanyVerificationMailResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${VerificationEmailMailerServiceProviderName}.SendCompanyVerificationMail`,
      request,
    });
    return {
      expTime: response.expTime,
      expPeriod: response.expPeriod,
    };
  },
  async VerifyEmailTOTP(
    request: VerifyEmailTOTPRequest,
  ): Promise<VerifyEmailTOTPResponse> {
    return await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${VerificationEmailMailerServiceProviderName}.VerifyEmailTOTP`,
      request,
    });
  },
};
