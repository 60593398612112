import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { DataSheetSectionsTab } from "../AssetOverview";
import { DataSheetSection } from "james/marketData/DataSheetSection";
import {
  ETNRollCorporateActionsDataSheetSection,
  AssetDocumentsDataSheetSection,
  AssetInvestmentObjectiveDataSheetSection,
  AssetPerformanceDataSheetSection,
  AssetPriceHistoryDataSheetSection,
  AssetSectorAllocationsAndHoldingsDataSheetSection,
  StellarAssetPublicInfoDataSheetSection,
} from "james/marketData";
import { AssetInvestmentObjectiveDataSheetSectionRenderer } from "./AssetInvestmentObjectiveDataSheetSectionRenderer";
import { AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer } from "./AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer";
import { StellarAssetPublicInfoDataSheetSectionRenderer } from "./StellarAssetPublicInfoDataSheetSectionRenderer";
import { AssetPerformanceDataSheetDataSheetSectionRenderer } from "./AssetPerformanceDataSheetDataSheetSectionRenderer";
import { AssetPriceHistoryDataSheetSectionRenderer } from "./AssetPriceHistoryDataSheetSectionRenderer";
import { AssetDocumentsDataSheetSectionRenderer } from "./AssetDocumentsDataSheetSectionRenderer";
import { AssetCorporateActionsDataSheetSectionRenderer } from "./AssetCorporateActionsSheetSectionRenderer";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { AssetSubscriptionOverviewRenderer } from "./AssetSubscriptionOverviewRenderer";
import { AssetIndependentReviewRenderer } from "./AssetIndependentReviewRenderer";
import { AssetManagingRiskSectionRenderer } from "./AssetManagingRiskSectionRenderer";
import { AssetSubscriptionOverviewDataSheetSection } from "james/marketData/AssetSubscriptionOverviewDataSheetSection";
import { AssetIndependentReviewDataSheetSection } from "james/marketData/AssetIndependentReviewDataSheetSection";
import { AssetManagingRiskDataSheetSection } from "james/marketData/AssetManagingRiskDataSheetSection";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { AssetMediaAndResourcesSectionRenderer } from "./AssetMediaAndResourcesSectionRenderer/AssetMediaAndResourcesSectionRenderer";
import { AssetMarketingMediaDataSheetSection } from "james/marketData/AssetMarketingMedia";
import { AssetRepaymentTermsDataSheetSection } from "james/marketData/AssetRepaymentTermsDataSheetSection";
import { AssetRepaymentTermsSectionRenderer } from "./AssetRepaymentTermsSectionRenderer/AssetRepaymentTermsSectionRenderer";

interface AssetDataSheetsProps {
  dataSheetSections: DataSheetSection[];
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
  setExpandedState: React.Dispatch<
    React.SetStateAction<{
      [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
    }>
  >;
  expandedState: {
    [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
  };

  functions: AssetOverviewFunctions;
}

interface AssetOverviewFunctions {
  goToIssuer: () => void;
}

export const AssetDataSheets = ({
  dataSheetSections,
  setExpandedState,
  marketListingViewModel,
  expandedState,
  functions,
}: AssetDataSheetsProps) => {
  return (
    <>
      {dataSheetSections.map((dataSheetSection, idx) => {
        return (
          <Accordion
            key={`accordion-dataSheetSection-${idx}`}
            sx={{
              "&.MuiAccordion-root:before": {
                backgroundColor: "unset",
              },
              "&.Mui-expanded": {
                margin: "0",
              },
            }}
            onClick={() =>
              setExpandedState((prevState) => ({
                ...prevState,
                [DataSheetSectionsTab.Asset]: {
                  ...prevState[DataSheetSectionsTab.Asset],
                  [idx]: !prevState[DataSheetSectionsTab.Asset][idx],
                },
              }))
            }
            expanded={
              // Default expanded state to true
              Object.keys(expandedState).includes(DataSheetSectionsTab.Asset) &&
              Object.keys(expandedState[DataSheetSectionsTab.Asset]).includes(
                idx.toString(),
              )
                ? expandedState[DataSheetSectionsTab.Asset][idx]
                : true
            }
            data-link-info={JSON.stringify({
              content_interaction_id: "info-accordion",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: `tab>asset>${dataSheetSection.DataSheetSectionName()}`,
              content_interaction_type: InteractionType.Accordion,
              content_interaction_driver: InteractionDriver.MoreInfo,
            } as DataLinkInfoType)}
          >
            <AccordionSummary
              data-link-info={JSON.stringify({
                content_interaction_id: "info-accordion",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: `tab>asset>${dataSheetSection.DataSheetSectionName()}`,
                content_interaction_type: InteractionType.Accordion,
                content_interaction_driver: InteractionDriver.MoreInfo,
              } as DataLinkInfoType)}
              sx={(theme) => ({
                flexDirection: "row-reverse",
                backgroundColor: theme.palette.custom.midnight,
                padding: theme.spacing(0, 4),
              })}
              expandIcon={
                <ExpandMoreIcon
                  id={`accordion-${idx}-expandToggle-icon`}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "expand accordions",
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color={"primary"}
                />
              }
            >
              <Typography
                sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-accordion",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: `tab>asset>${dataSheetSection.DataSheetSectionName()}`,
                  content_interaction_type: InteractionType.Accordion,
                  content_interaction_driver: InteractionDriver.MoreInfo,
                } as DataLinkInfoType)}
                variant={"h6"}
                children={dataSheetSection.DataSheetSectionName()}
              />
            </AccordionSummary>
            <AccordionDetails
              onClick={(e) => e.stopPropagation()}
              sx={(theme) => ({
                padding: {
                  sm: theme.spacing(0, 0, 0, 5),
                  xs: theme.spacing(0, 0, 0, 3),
                },
              })}
            >
              {(() => {
                if (
                  dataSheetSection instanceof
                  AssetSubscriptionOverviewDataSheetSection
                ) {
                  return (
                    <AssetSubscriptionOverviewRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  AssetMarketingMediaDataSheetSection
                ) {
                  return (
                    <AssetMediaAndResourcesSectionRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  AssetRepaymentTermsDataSheetSection
                ) {
                  return (
                    <AssetRepaymentTermsSectionRenderer
                      dataSheetSection={dataSheetSection}
                      marketListingViewModel={marketListingViewModel}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  AssetInvestmentObjectiveDataSheetSection
                ) {
                  return (
                    <AssetInvestmentObjectiveDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  AssetIndependentReviewDataSheetSection
                ) {
                  return (
                    <AssetIndependentReviewRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof AssetManagingRiskDataSheetSection
                ) {
                  return (
                    <AssetManagingRiskSectionRenderer
                      goToIssuer={functions.goToIssuer}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  AssetSectorAllocationsAndHoldingsDataSheetSection
                ) {
                  return (
                    <AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof AssetPerformanceDataSheetSection
                ) {
                  return (
                    <AssetPerformanceDataSheetDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof AssetPriceHistoryDataSheetSection
                ) {
                  return (
                    <AssetPriceHistoryDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                      marketListingViewModel={marketListingViewModel}
                    />
                  );
                } else if (
                  dataSheetSection instanceof AssetDocumentsDataSheetSection
                ) {
                  return (
                    <AssetDocumentsDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  StellarAssetPublicInfoDataSheetSection
                ) {
                  return (
                    <StellarAssetPublicInfoDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                      marketListingViewModel={marketListingViewModel}
                    />
                  );
                } else if (
                  dataSheetSection instanceof
                  ETNRollCorporateActionsDataSheetSection
                ) {
                  return (
                    <AssetCorporateActionsDataSheetSectionRenderer
                      dataSheetSection={dataSheetSection}
                    />
                  );
                }

                return null;
              })()}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
