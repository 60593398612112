import { Context } from "james/security";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import { Profile } from "./profile";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface RetrieveCompanyProfilePictureRequest {
  context: Context;
  clientIdentifier: Identifier;
}

export interface RetrieveCompanyProfilePictureResponse {
  pictureData: string;
  mimeType: string;
}

export interface ChangeCompanyProfilePictureRequest {
  context: Context;
  pictureData: string;
  mimeType: string;
  clientIdentifier: Identifier;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChangeCompanyProfilePictureResponse {}

export interface CheckCompanyExistenceRequest {
  context: Context;
  identifier: Identifier;
}

export interface CheckCompanyExistenceResponse {
  exists: boolean;
}

export interface RetrieveMyCompanyProfileRequest {
  context: Context;
}

export interface RetrieveMyCompanyProfileResponse {
  profile: Profile;
}

export interface RetrieveCompanyProfileRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveCompanyProfileResponse {
  profile: Profile;
}

export interface UpdateCompanyProfileRequest {
  context: Context;
  clientIdentifier: Identifier;
  profile: Profile;
}

export interface DeleteCompanyProfilePictureRequest {
  context: Context;
  clientIdentifier: Identifier;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeleteCompanyProfilePictureResponse {}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateCompanyProfileResponse {}

export const Manager = {
  serviceProvider: "company-Manager",
  async CheckCompanyExistence(
    request: CheckCompanyExistenceRequest,
  ): Promise<CheckCompanyExistenceResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Manager.serviceProvider}.CheckCompanyExistence`,
      request,
    });
  },
  async ChangeCompanyProfilePicture(
    request: ChangeCompanyProfilePictureRequest,
  ): Promise<ChangeCompanyProfilePictureResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Manager.serviceProvider}.ChangeCompanyProfilePicture`,
      request,
    });
  },
  async RetrieveCompanyProfilePicture(
    request: RetrieveCompanyProfilePictureRequest,
  ): Promise<RetrieveCompanyProfilePictureResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Manager.serviceProvider}.RetrieveCompanyProfilePicture`,
      request,
    });
  },
  async RetrieveMyCompanyProfile(
    request: RetrieveMyCompanyProfileRequest,
  ): Promise<RetrieveMyCompanyProfileResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Manager.serviceProvider}.RetrieveMyCompanyProfile`,
      request,
    });
    return { profile: new Profile(response.profile) };
  },
  async RetrieveCompanyProfile(
    request: RetrieveCompanyProfileRequest,
  ): Promise<RetrieveCompanyProfileResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Manager.serviceProvider}.RetrieveCompanyProfile`,
      request,
    });
    return { profile: new Profile(response.profile) };
  },
  async UpdateCompanyProfile(
    request: UpdateCompanyProfileRequest,
  ): Promise<UpdateCompanyProfileResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Manager.serviceProvider}.UpdateCompanyProfile`,
      request,
    });
  },
  async DeleteCompanyProfilePicture(
    request: DeleteCompanyProfilePictureRequest,
  ): Promise<DeleteCompanyProfilePictureResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Manager.serviceProvider}.DeleteCompanyProfilePicture`,
      request,
    });
  },
};
