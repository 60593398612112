import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { InstrumentForm } from "./components";
import { ViewMode, useBuilderContext } from "./Context";
import { DataSection } from "./components/DataSection/DataSection";

// top and bottom percentages must always add to 100%
const topPanelDefaultHeightPercentage = 60;

export type LayoutProps = {
  system: boolean;
};

export const Layout = (props: LayoutProps) => {
  const {
    initialisationError,
    clearInitialisationError,
    initialised,
    viewMode,
    formData,
  } = useBuilderContext();
  const theme = useTheme();
  const [topPanelHeightPercentage, setTopPanelPercentage] = useState<number>(
    topPanelDefaultHeightPercentage,
  );

  // calculate relevant heights whenever top panel height percentage has changed
  // TODO: workout how to trigger this when window.innerHeight changes
  const { layoutHeight, topPanelHeightPx, bottomPanelHeightPx } =
    useMemo(() => {
      const layoutHeight = window.innerHeight - 80;
      const topPanelHeightPx = (layoutHeight * topPanelHeightPercentage) / 100;
      const bottomPanelHeightPx = Math.abs(layoutHeight - topPanelHeightPx);
      return {
        layoutHeight,
        topPanelHeightPx,
        bottomPanelHeightPx,
      };
    }, [topPanelHeightPercentage]);

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: layoutHeight,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Builder"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while initialising the builder.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (!initialised || viewMode === ViewMode.Undefined) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <CircularProgress size={70} />
            <Typography
              variant="h5"
              color="textSecondary"
              children="Initialising Builder..."
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <>
      <Breadcrumbs
        sx={(theme) => ({ padding: theme.spacing(0.5) })}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="secondary" href="/builder">
          Issuance Hub
        </Link>
        <Link
          underline="hover"
          color="secondary"
          href="/builder/smart-instruments/builder/table"
        >
          Smart Instruments
        </Link>
        <Typography color="text.secondary">
          {formData.smartInstrument.getName() ?? "No Name"}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ height: layoutHeight, borderRadius: 0 }}>
        <PanelGroup direction="vertical">
          {/* ------------ Top Panel ------------- */}
          <Panel
            onResize={(percentage: React.SetStateAction<number>) =>
              setTopPanelPercentage(percentage)
            }
            defaultSize={topPanelDefaultHeightPercentage}
          >
            <PanelGroup direction="horizontal">
              <Panel
                style={{
                  borderRight: `1px solid ${theme.palette.background.default}`,
                }}
              >
                <InstrumentForm
                  system={props.system}
                  height={topPanelHeightPx}
                />
              </Panel>
              <PanelResizeHandle />
            </PanelGroup>
          </Panel>
          <PanelResizeHandle />
          {/* ------------ Bottom Panel ------------- */}
          <Panel
            style={{
              borderTop: `1px solid ${theme.palette.background.default}`,
            }}
          >
            <DataSection height={bottomPanelHeightPx} />
          </Panel>
        </PanelGroup>
      </Paper>
    </>
  );
};
