import { Chip, ChipProps, Theme } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { FundingOrderState } from "@mesh/common-js/dist/banking/fundingOrderState_pb";
import { fundingOrderStateToString } from "@mesh/common-js/dist/banking/fundingOrderState";

export interface FundingOrderStateChipProps {
  state: FundingOrderState | "";
  chipProps?: ChipProps;
}

const Root = styled("div")(({ theme }) => ({
  [`& .info`]: { backgroundColor: theme.palette.info.main },
  [`& .warning`]: { backgroundColor: theme.palette.warning.main },
  [`& .error`]: { backgroundColor: theme.palette.error.main },
  [`& .success`]: { backgroundColor: theme.palette.success.main },
}));

export function FundingOrderStateChip(props: FundingOrderStateChipProps) {
  switch (props.state) {
    case FundingOrderState.FAILED_FUNDING_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id="fundingOrderStateChip-failed-chip"
          size="small"
          label={fundingOrderStateToString(props.state)}
        />
      );

    case FundingOrderState.UNDER_INVESTIGATION_FUNDING_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id="fundingOrderStateChip-underInvestigation-chip"
          size="small"
          label={fundingOrderStateToString(props.state)}
        />
      );

    case FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.warning.main,
          })}
          id="fundingOrderStateChip-awaitingApproval-chip"
          size="small"
          label={fundingOrderStateToString(props.state)}
        />
      );

    case FundingOrderState.SETTLEMENT_IN_PROGRESS_FUNDING_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="fundingOrderStateChip-settlementInProgress-chip"
          size="small"
          label={fundingOrderStateToString(props.state)}
        />
      );

    case FundingOrderState.SETTLED_FUNDING_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.success.main,
          })}
          id="fundingOrderStateChip-settled-chip"
          size="small"
          label={fundingOrderStateToString(props.state)}
        />
      );

    case FundingOrderState.CANCELLED_FUNDING_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="fundingOrderStateChip-cancelled-chip"
          size="small"
          label={fundingOrderStateToString(props.state)}
        />
      );

    default:
      return <Root>{props.state}</Root>;
  }
}
