import React, { useEffect, useRef } from "react";
import {
  alpha,
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  SxProps,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import cx from "classnames";
import { BigNumber } from "bignumber.js";
import { useExchangeDashboardStore } from "../store";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";
import { ConnectionIcon } from "./ConnectionIcon";
import { LimitOrderType } from "@mesh/common-js/dist/market/limitOrder_pb";
import { Order, OrderBook } from "pkgTemp/stellar/Orders";
import { motion } from "framer-motion";
import { range } from "lodash";
import { getNoDecimals } from "utilities/number";
import { useExchangeStore } from "../../../store";
import { useFetchAndStreamOrderBook } from "../hooks/useFetchAndStreamOrderBook";
import { ScrollShadow } from "@mesh/common-js-react/dist/Utility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export interface OrderBookProps {
  spread: BigNumber;
  loading: boolean;
  orderBookState: OrderBook;
  highestPrice: BigNumber;
  serverConnectionStatus: ServerConnectionStatus;
}

export interface OrderBookActions {
  spread: (val: BigNumber) => void;
  precisionFromString: (val: string) => void;
  loading: (val: boolean) => void;
  orderBookState: (val: OrderBook) => void;
  highestPrice: (val: BigNumber) => void;
  serverConnectionStatus: (val: ServerConnectionStatus) => void;
}

export const OrderBookView = ({
  spread,
  orderBookState,
  highestPrice,
  serverConnectionStatus,
}: OrderBookProps) => {
  const theme = useTheme();
  const store = useExchangeDashboardStore();
  const exchangeStore = useExchangeStore();
  const { fetchAndStreamOrderBook } = useFetchAndStreamOrderBook();
  const endStream = useRef<() => void>();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const deb = setTimeout(async () => {
      endStream.current = await fetchAndStreamOrderBook();
    });

    return () => {
      clearTimeout(deb);
      if (endStream.current) {
        endStream.current();
      }
    };
  }, [exchangeStore.marketListingPair]);

  return (
    <Card
      sx={{
        height: {
          xs: "calc(100vh - 180px)",
          sm: "100%",
        },
        width: "100%",
        borderRadius: {
          sm: "10px",
          xs: 0,
        },
      }}
    >
      {!smDown && (
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <ConnectionIcon connectionStatus={serverConnectionStatus} />
                <Typography variant="h5">Order Book</Typography>
              </Box>
            </Box>
          }
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            py: 0,
            height: 52,
            alignItems: "center",
          }}
        />
      )}
      <CardContent
        sx={{
          pt: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {smDown ? (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <ConnectionIcon connectionStatus={serverConnectionStatus} />
              <Typography variant="h5">Order Book</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography>Spread</Typography>
              <Typography fontWeight={"bold"}>
                {spread.toFormat(getNoDecimals(spread, 7, 2))}
              </Typography>
            </Box>
          )}
          <TextField
            select
            onChange={(e) =>
              store.updateOrderBookState.precisionFromString(e.target.value)
            }
            sx={{
              ml: 1,
            }}
            value={orderBookState.precision.toString()}
            SelectProps={{
              IconComponent: KeyboardArrowDownIcon,
              sx: (theme) => ({
                height: "30px",
                width: 100,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.secondary.main,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.secondary.main,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.secondary.main,
                },
                ".MuiSvgIcon-root": {
                  color: theme.palette.secondary.main,
                },
              }),
            }}
          >
            <MenuItem value={"0.001"}>0.001</MenuItem>
            <MenuItem value={"0.01"}>0.01</MenuItem>
            <MenuItem value={"0.1"}>0.1</MenuItem>
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"10"}>10</MenuItem>
            <MenuItem value={"100"}>100</MenuItem>
            <MenuItem value={"1000"}>1000</MenuItem>
          </TextField>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplate: {
              xs: `
                      "a" 48px
                      "b" calc(50% - 48px)
                      "d" 48px
                      "c" calc(50% - 48px) / 1fr;
                    `,
              sm: `
                      "a a" 48px
                      "b c" auto / 1fr 1fr
                    `,
            },
          }}
        >
          <Box
            sx={{
              mb: 1,
              gridArea: "a",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
                gap: 2,
              }}
            >
              {range(smDown ? 1 : 2).map((v) => (
                <Box
                  key={v}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 1,
                    textAlign: "center",
                  }}
                >
                  <Box
                    textAlign="left"
                    sx={{
                      "& > p": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    <Typography>Price</Typography>
                    <Typography>
                      {exchangeStore.marketListingPair?.counter.code}
                    </Typography>
                  </Box>
                  <Box
                    textAlign="right"
                    sx={{
                      "& > p": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    <Typography>Amount</Typography>
                    <Typography>
                      {exchangeStore.marketListingPair?.base.code}
                    </Typography>
                  </Box>
                  <Box
                    textAlign="right"
                    sx={{
                      "& > p": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    <Typography>Total</Typography>
                    <Typography>
                      {exchangeStore.marketListingPair?.counter.code}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <ScrollShadow
            sx={{
              pr: 1,
              overflowY: "auto",
              gridArea: "b",
              maxHeight: 350,
            }}
          >
            {range(orderBookState.aggregatedBids.length).map((a, i) => {
              const ask =
                a <= orderBookState.aggregatedBids.length
                  ? orderBookState.aggregatedBids[a]
                  : undefined;
              return (
                <OrderBookBar
                  key={i}
                  index={i}
                  order={ask}
                  highestPrice={highestPrice}
                  direction="left"
                  cardOption={LimitOrderType.SELL}
                  sx={{
                    ".price": {
                      color: theme.palette.success.main,
                    },
                    ".orderbook-bar": {
                      background: `linear-gradient(to left, ${alpha(theme.palette.success.main, 0.1)}, ${alpha(theme.palette.success.main, 0.4)})`,
                      borderTopLeftRadius: "12px",
                      borderBottomLeftRadius: "12px",
                    },
                  }}
                />
              );
            })}
          </ScrollShadow>
          <ScrollShadow
            sx={{
              borderLeft: { sm: `1px solid ${alpha("#FFFFFF", 0.38)}` },
              pl: { sm: 1 },
              pr: 0.5,
              overflowY: "auto",
              gridArea: "c",
              maxHeight: 350,
            }}
          >
            {range(orderBookState.aggregatedAsks.length).map((a, i) => {
              const bid =
                a <= orderBookState.aggregatedAsks.length
                  ? orderBookState.aggregatedAsks[a]
                  : undefined;
              return (
                <OrderBookBar
                  key={i}
                  order={bid}
                  highestPrice={highestPrice}
                  direction="right"
                  cardOption={LimitOrderType.BUY}
                  index={i}
                  sx={{
                    ".price": {
                      color: theme.palette.error.main,
                    },
                    ".orderbook-bar": {
                      background: `linear-gradient(to right, ${alpha(theme.palette.error.light, 0.1)}, ${alpha(theme.palette.primary.main, 0.4)})`,
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    },
                  }}
                />
              );
            })}
          </ScrollShadow>
          {smDown && (
            <Box
              sx={(theme) => ({
                display: "flex",
                gridArea: "d",
                gap: 2,
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
                alignItems: "center",
                my: 0.5,
              })}
            >
              <Typography>Spread</Typography>
              <Typography fontWeight={"bold"}>
                {spread.toFormat(getNoDecimals(spread, 7, 2))}
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

interface OrderBookBarProps {
  order?: Order;
  highestPrice: BigNumber;
  direction: "left" | "right";
  sx: SxProps;
  cardOption: LimitOrderType;
  index: number;
}

const OrderBookBar = ({
  order,
  highestPrice,
  direction,
  sx,
  cardOption,
  index,
}: OrderBookBarProps) => {
  const exchangeDashboardStore = useExchangeDashboardStore();

  return (
    <Box
      component={ButtonBase}
      onClick={() => {
        if (!order) return;
        exchangeDashboardStore.selectLimitOrderFromBook(
          order.price.toNumber(),
          order.amount.toNumber(),
          cardOption,
        );
      }}
      sx={{
        ...sx,
        position: "relative",
        textAlign: "left",
        height: "28px",
        mb: 1,
        width: "100%",
        p: 0.5,
        "&:hover": {
          backgroundColor: alpha("#FFFFFF", 0.1),
          backgroundBlendMode: "multiply",
        },
      }}
    >
      {order && (
        <Box
          className={cx({
            bold: index === 0,
          })}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 1,
            width: "100%",
            zIndex: 1,
            "&.bold > *": {
              fontWeight: "bold",
            },
          }}
        >
          <Typography textAlign="left" className="price">
            {order.price.toFixed(2)}
          </Typography>
          <Typography textAlign="right">{order.amount.toFixed(2)}</Typography>
          <Typography textAlign="right">{order.total.toFixed(2)}</Typography>
        </Box>
      )}
      <Box
        component={motion.div}
        initial={{
          width: "0%",
          opacity: 0,
        }}
        exit={{
          width: "0%",
          opacity: 0,
        }}
        animate={{
          width: order
            ? `calc(${(order.amount.toNumber() / highestPrice.toNumber()) * 100}%)`
            : 0,
          opacity: 1,
        }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
          duration: 0.5,
        }}
        className={cx({
          [direction]: true,
          "orderbook-bar": true,
          order: !!order,
        })}
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          height: "28px",
          "&.left": {
            right: 0,
          },
          "&.right": {
            left: 0,
          },
          zIndex: 0,
        }}
      />
    </Box>
  );
};
