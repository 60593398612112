import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

export interface RemoveRoleFromGroupRequest {
  context: Context;
  roleID: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RemoveRoleFromGroupResponse {}

export const RoleRemover = {
  serviceProvider: "group-RoleRemover",
  async RemoveRoleFromGroup(
    request: RemoveRoleFromGroupRequest,
  ): Promise<RemoveRoleFromGroupResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${RoleRemover.serviceProvider}.RemoveRoleFromGroup`,
      request,
    });
  },
};
