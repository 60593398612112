import { useEffect, useRef, useState } from "react";

// useTimer returns the following values:
// - timerProgress: Number, The percentage of time elapsed between start and expiry (0 = just started, 50 half-way done, 100 finished)
// - timerValue: Number, The time left in seconds
// - timerCompleted: Boolean, True if the timer was set and has now run out
// - startTimer: func, The timer will start when this function is called with the exp time and the period
// - stopTimer: func, The timer is cleared when this is called

export const useTimer = () => {
  const timeDuration = useRef(0);
  const [timerValue, setTimerValue] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [timerProgress, setTimerProgress] = useState<number>(0);
  const [timerCompleted, setTimerCompleted] = useState<boolean>(false);

  const startTimer = (duration: number) => {
    setTimerValue(duration);
    setTimerCompleted(false);
    timeDuration.current = duration;
    setIntervalId(
      setInterval(() => {
        setTimerValue((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            setTimerCompleted(true);
            return 0;
          }
          setTimerProgress(
            ((timeDuration.current - (prevTime - 1)) / timeDuration.current) *
              100,
          );
          return prevTime - 1;
        });
      }, 1000),
    );
  };

  const stopTimer = () => {
    setTimerValue(0);
    setTimerCompleted(false);
    setTimerProgress(0);
    clearInterval(intervalId);
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  return { timerValue, timerProgress, timerCompleted, startTimer, stopTimer };
};
