import { Router } from "routes";
import React from "react";
import { MeshCoreLayout } from "layouts";
import { MarketManagementRouteBuilder } from "routes/private";
import { RouteType } from "routes/Route";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { Helmet } from "react-helmet-async";

export const MarketManagement = () => {
  const { viewConfiguration, myClient } = useApplicationContext();

  const marketManagementRoutes = MarketManagementRouteBuilder(
    viewConfiguration,
    new Client(myClient),
  );

  const allMarketManagementRoutes: RouteType[] = [
    ...marketManagementRoutes.otherRoutes,
  ];
  if (marketManagementRoutes.homeRoute) {
    allMarketManagementRoutes.push(marketManagementRoutes.homeRoute);
  }
  if (marketManagementRoutes.userProfileRoute) {
    allMarketManagementRoutes.push(marketManagementRoutes.userProfileRoute);
  }
  if (marketManagementRoutes.clientProfileRoute) {
    allMarketManagementRoutes.push(marketManagementRoutes.clientProfileRoute);
  }

  return (
    <MeshCoreLayout
      homeRoute={marketManagementRoutes.homeRoute}
      userProfileRoute={marketManagementRoutes.userProfileRoute}
      clientProfileRoute={marketManagementRoutes.clientProfileRoute}
      otherRoutes={marketManagementRoutes.otherRoutes}
    >
      <Helmet>
        <title>Mesh | Market Management</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Your Mesh Market Management Dashboard"
        />
      </Helmet>
      <Router
        baseURL={"/market-management"}
        redirectPath={"/market-management"}
        routes={allMarketManagementRoutes}
      />
    </MeshCoreLayout>
  );
};
