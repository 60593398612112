import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  MenuList,
  useTheme,
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

enum TableMode {
  awaitingConfirmation = "Awaiting Confirmation",
  history = "History",
}

interface FancyTableModeButtonProps {
  tableMode: TableMode;
  onTableModeChange: (newTableMode: TableMode) => void;
}

const allTableModes: TableMode[] = [
  TableMode.awaitingConfirmation,
  TableMode.history,
];

export const FancyTableModeButton = (props: FancyTableModeButtonProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        color="secondary"
        ref={anchorRef}
        aria-label="split button"
        sx={{
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "48px",
          },
        }}
      >
        <Button
          color="secondary"
          id="ordersTable-changeTableMode-selectedModeButton"
          children={props.tableMode}
          onClick={handleToggle}
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        />
        <Button
          id="ordersTable-changeTableMode-expandMenuButton"
          color="secondary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="Select Filter Option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        PaperProps={{
          sx: {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        }}
        onClose={handleClose}
        disablePortal
        autoFocus={false}
      >
        <MenuList id="ordersTable-changeTableMode-menuList" sx={{ padding: 0 }}>
          {allTableModes.map((tm, idx) => (
            <MenuItem
              key={idx}
              id={`ordersTable-changeTableMode-menuItem-${idx}`}
              data-link-info={JSON.stringify({
                content_interaction_id: "info-search",
                content_interaction_action: InteractionAction.SelectOption,
                content_interaction_type: InteractionType.Select,
                content_interaction_text: tm.toString().toLowerCase(),
                content_interaction_driver: InteractionDriver.SearchOrders,
              } as DataLinkInfoType)}
              selected={props.tableMode === tm}
              onClick={() => {
                // always close menu
                handleToggle();

                // only call on change if mode is changing
                if (props.tableMode !== tm) {
                  props.onTableModeChange(tm);
                }
              }}
            >
              {tm}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
