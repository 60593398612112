import { Role } from "james/role";
import { Group } from "./Group";

export const GroupWithRolesTypeName = "mesh::group/WithRoles";

export class GroupWithRoles extends Group {
  public ["@type"]: string = GroupWithRolesTypeName;

  public roles: Role[] = [];

  constructor(groupWithRoles?: GroupWithRoles) {
    super(groupWithRoles);
    if (!groupWithRoles) {
      return;
    }
    this.roles = groupWithRoles.roles.map((r) => new Role(r));
  }
}
