import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "../security";
import { SourceAccount } from "./SourceAccount";
import { StellarNetwork } from "./Network";

export interface OpenNewSourceAccountRequest {
  context: Context;
  network: StellarNetwork;
}

export interface OpenNewSourceAccountResponse {
  transactionID: string;
  sourceAccount: SourceAccount;
}

export interface OpenSourceAccountRequest {
  context: Context;
  sourceAccountID: string;
}

export interface OpenSourceAccountResponse {
  transactionID: string;
  sourceAccount: SourceAccount;
}

export const SourceAccountStateControllerServiceProviderName =
  "stellar-SourceAccountStateController";

export const SourceAccountStateController = {
  async OpenNewSourceAccount(
    request: OpenNewSourceAccountRequest,
    additionalHeaders?: Headers,
  ): Promise<OpenNewSourceAccountResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SourceAccountStateControllerServiceProviderName}.OpenNewSourceAccount`,
        request,
      },
      { additionalHeaders },
    );

    return {
      transactionID: response.transactionID,
      sourceAccount: new SourceAccount(response.sourceAccount),
    };
  },
  async OpenSourceAccount(
    request: OpenSourceAccountRequest,
    additionalHeaders?: Headers,
  ): Promise<OpenSourceAccountResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SourceAccountStateControllerServiceProviderName}.OpenSourceAccount`,
        request,
      },
      { additionalHeaders },
    );

    return {
      transactionID: response.transactionID,
      sourceAccount: new SourceAccount(response.sourceAccount),
    };
  },
};
