export class CompanyPublicContactInfo {
  public firstName = "";

  public lastName = "";

  public telephoneNumber = "";

  public cellphoneNumber = "";

  public emailAddress = "";

  public middleNames = "";

  constructor(c?: CompanyPublicContactInfo) {
    if (!c) {
      return;
    }
    this.firstName = c.firstName;
    this.middleNames = c.middleNames;
    this.lastName = c.lastName;
    this.telephoneNumber = c.telephoneNumber;
    this.cellphoneNumber = c.cellphoneNumber;
    this.emailAddress = c.emailAddress;
  }
}
