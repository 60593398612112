import { Context } from "james/security";
import { Person } from "james/legal/person";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

export interface RetrieveMyPersonRequest {
  context: Context;
}

export interface RetrieveMyPersonResponse {
  person: Person;
}

export const ManagerRead = {
  serviceProvider: "person-ManagerRead",
  async RetrieveMyPerson(
    request: RetrieveMyPersonRequest,
  ): Promise<RetrieveMyPersonResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${ManagerRead.serviceProvider}.RetrieveMyPerson`,
      request,
    });
    return { person: new Person(response.person) };
  },
};
