import React from "react";
import {
  Dashboard as DashboardIcon,
  People as AdministrationIcon,
} from "@mui/icons-material";
import { ViewConfiguration } from "james/configuration";
import { RouteType } from "routes/Route";
import { CompanyProfileView } from "views/CompanyProfile";
import { UserProfileView } from "views/UserProfile";
import isObject from "lodash/isObject";
import { AdminManagement } from "views/Administration/components/AdminManagement/AdminManagement";
import { AdministrationDashboard } from "views/Administration/components/AdministrationDashboard/AdministrationDashboard";

interface AdministrationRoutesToReturn {
  homeRoute?: RouteType;
  otherRoutes: RouteType[];
  userProfileRoute: RouteType;
  clientProfileRoute?: RouteType;
}

export const administrationSideBarRoutes: RouteType[] = [
  {
    name: "Administration",
    id: "administration",
    path: "/administration/manage",
    icon: AdministrationIcon,
    component: <AdminManagement />,
    allowSubPaths: true,
  },
];
export const AdminRouteBuilder: (
  viewConfiguration: ViewConfiguration,
) => AdministrationRoutesToReturn = (viewConfiguration: ViewConfiguration) => {
  const routesToReturn: AdministrationRoutesToReturn = {
    homeRoute: {
      name: "Dashboard",
      id: "builder",
      path: "/administration",
      icon: DashboardIcon,
      component: <AdministrationDashboard />,
    },

    userProfileRoute: {
      name: "User Profile",
      id: "user-profile",
      path: "/administration/user-profile",
      icon: DashboardIcon,
      component: <UserProfileView />,
    },

    // the rest of the routes will be built considering the viewConfiguration
    clientProfileRoute: undefined,
    otherRoutes: [],
  };

  // conditionally set company profile route
  if (viewConfiguration["Edit Client Profile"]) {
    routesToReturn.clientProfileRoute = {
      name: "Company profile",
      id: "company-profile",
      path: "/administration/company-profile",
      icon: DashboardIcon,
      component: <CompanyProfileView />,
    };
  }

  // for every sidebar route...
  administrationSideBarRoutes.forEach((route) => {
    // if the view configuration has an entry with the name of the route
    if (viewConfiguration[route.name]) {
      // and if the entry is an object
      if (isObject(viewConfiguration[route.name])) {
        // then check if at least one true is found with the embedded rules
        if (
          atLeastOneTrueFoundInViewConfigObject(viewConfiguration[route.name])
        ) {
          // then add the entry
          routesToReturn.otherRoutes.push(route);
        }
      } else {
        // if it is not an object then assume it to be a boolean
        if (viewConfiguration[route.name]) {
          routesToReturn.otherRoutes.push(route);
        }
      }
    }
  });

  return routesToReturn;
};

export function atLeastOneTrueFoundInViewConfigObject(viewConfig: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}): boolean {
  // for every key in given view config entry
  for (const key in viewConfig) {
    if (isObject(viewConfig[key])) {
      // if the entry is an object this indicates embedded rules
      // recursively determine if at least one true is found among
      // embedded rules
      if (atLeastOneTrueFoundInViewConfigObject(viewConfig[key])) {
        // if at least 1 true was found within the embedded rule,
        // return true since at least 1 true has been found
        return true;
      }
    } else {
      // if the entry is not an object then it is assumed to be a boolean
      // rule 'setting'.
      if (viewConfig[key]) {
        // if it is true then at least 1 true is found
        return true;
      }
    }
  }

  // if execution reaches here then no true was found
  return false;
}
