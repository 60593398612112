import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
import {
  RiskAssessmentInstrumentRiskProfile,
  RiskAssessmentInvestorRiskProfile,
  RiskAssessmentSmartInstrumentAttribute,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeRiskAssessment_pb";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { ValidationResult } from "common/validation";

export function validateRiskAssessmentAttribute(
  attribute: RiskAssessmentSmartInstrumentAttribute,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ] = message;
  };

  if (attribute.getAdvisorname() === "") {
    invalid("advisorName", "Must be set");
  }

  if (
    attribute.getInstrumentriskprofile() ===
    RiskAssessmentInstrumentRiskProfile.UNDEFINED_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE
  ) {
    invalid("instrumentRiskProfile", "Must be set");
  }

  if (
    attribute.getInvestorriskprofile() ===
    RiskAssessmentInvestorRiskProfile.UNDEFINED_RISK_ASSESSMENT_INVETOR_RISK_PROFILE
  ) {
    invalid("investorRiskProfile", "Must be set");
  }

  return validationResult;
}
