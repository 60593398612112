import dayjs from "dayjs";
import { Document } from "james/document";
import { AuditEntry } from "../audit";

export const ETNRollCorporateActionTypeName =
  "mesh::financial/ETNRollCorporateAction";

export enum ETNRollCorporateActionState {
  Pending = "Pending",
  Executed = "Executed",
}

export const AllETNRollCorporateActionStates: ETNRollCorporateActionState[] = [
  ETNRollCorporateActionState.Pending,
  ETNRollCorporateActionState.Executed,
];

export class ETNRollCorporateAction {
  public ["@type"]: string = ETNRollCorporateActionTypeName;

  public id = "";
  public ownerID = "";
  public instrumentID = "";
  public state: ETNRollCorporateActionState | "" = "";
  public executionDate: string = dayjs().format();
  public newMaturityDate: string = dayjs().format();
  public newExchangeCode = "";
  public newISIN = "";
  public documents: Document[] = [];
  public versionRolledAt = 0;
  public auditEntry: AuditEntry = new AuditEntry();
  public oldISIN = "";
  public oldExchangeCode = "";

  constructor(etnRollCorporateAction?: ETNRollCorporateAction) {
    if (!etnRollCorporateAction) {
      return;
    }
    this.id = etnRollCorporateAction.id;
    this.ownerID = etnRollCorporateAction.ownerID;
    this.instrumentID = etnRollCorporateAction.instrumentID;
    this.state = etnRollCorporateAction.state;
    this.executionDate = etnRollCorporateAction.executionDate;
    this.newMaturityDate = etnRollCorporateAction.newMaturityDate;
    this.newExchangeCode = etnRollCorporateAction.newExchangeCode;
    this.newISIN = etnRollCorporateAction.newISIN;
    this.documents = etnRollCorporateAction.documents.map(
      (d) => new Document(d),
    );
    this.versionRolledAt = etnRollCorporateAction.versionRolledAt;
    this.auditEntry = new AuditEntry(etnRollCorporateAction.auditEntry);
    this.oldISIN = etnRollCorporateAction.oldISIN;
    this.oldExchangeCode = etnRollCorporateAction.oldExchangeCode;
  }
}
