import React from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";
const PREFIX = "DownTriangleSplash";

const classes = {
  content: `${PREFIX}-content`,
};

const Root = styled("div")(() => ({
  [`& .${classes.content}`]: {
    zIndex: 1,
    position: "absolute",
    transform: "rotate(-45deg)",
  },
}));

interface TriangleSplashProps {
  scale?: number;
  backgroundColor?: string;
  contentPositioning?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  content?: React.ReactNode;
}

export function DownTriangleSplash(props: TriangleSplashProps) {
  const theme = useTheme();

  return (
    <Root
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        height: 50 * (props.scale ? props.scale : 1),
        width: 100 * (props.scale ? props.scale : 1),
        overflowY: "hidden",
        backgroundColor: "transparent",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-70%",
          borderBottomRightRadius: "90px",
          height: 70 * (props.scale ? props.scale : 1),
          width: 70 * (props.scale ? props.scale : 1),
          transform: "rotate(45deg)",
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : theme.palette.background.default,
        }}
      >
        <div
          className={classes.content}
          style={
            props.contentPositioning
              ? props.contentPositioning
              : { top: 160, left: 160 }
          }
        >
          {props.content}
        </div>
      </div>
    </Root>
  );
}
