import React, { useMemo } from "react";
import { Chip, ChipProps, SxProps, useTheme } from "@mui/material";
import { TransactionBatchState } from "james/ledger/TransactionBatch";
import camelCase from "lodash/camelCase";

export interface TransactionBatchStateChipProps {
  state: TransactionBatchState | "";
  chipProps?: ChipProps;
}

export const TransactionBatchStateChip = ({
  state,
  chipProps,
}: TransactionBatchStateChipProps) => {
  const theme = useTheme();

  const styleMap: { [x in TransactionBatchState | ""]: SxProps } = useMemo(
    () => ({
      [TransactionBatchState.Processing]: {
        backgroundColor: theme.palette.info.main,
      },
      [TransactionBatchState.Failed]: {
        backgroundColor: theme.palette.error.main,
      },
      [TransactionBatchState.Complete]: {
        backgroundColor: theme.palette.success.main,
      },
      "": {
        backgroundColor: theme.palette.info.main,
      },
    }),
    [],
  );

  return (
    <Chip
      {...chipProps}
      sx={styleMap[state]}
      id={`transactionBatch-${camelCase(state)}-chip`}
      size="small"
      label={state}
    />
  );
};
