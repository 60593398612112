import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { MarketListingViewAssetType } from "james/views/marketListingView";
import { Token } from "james/ledger";

const PREFIX = "InstrumentTypeC";

const classes = {
  root: `${PREFIX}-root`,
  captionText: `${PREFIX}-captionText`,
  iconSection: `${PREFIX}-iconSection`,
  icon: `${PREFIX}-icon`,
  iconText: `${PREFIX}-iconText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.captionText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.iconSection}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    fontSize: 24,
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },

  [`& .${classes.iconText}`]: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

interface InstrumentTypeCProps {
  token: Token;
  assetType: MarketListingViewAssetType | "";
}

export const InstrumentTypeC = (props: InstrumentTypeCProps) => {
  let captionText: string;
  let tooltipText: string;
  let instrumentTypeText: string;

  switch (props.assetType) {
    case MarketListingViewAssetType.Share:
      captionText = "";
      instrumentTypeText = "Share";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAShare:
      captionText = "Rights to a";
      instrumentTypeText = "Share";
      tooltipText = "This token is backed by a Share";
      break;

    case MarketListingViewAssetType.PreferenceShare:
      captionText = "";
      instrumentTypeText = "Preference Share";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAPreferenceShare:
      captionText = "Rights to a";
      instrumentTypeText = "Preference Share";
      tooltipText = "This token is backed by a Preference Share";
      break;

    case MarketListingViewAssetType.Bond:
      captionText = "";
      instrumentTypeText = "Bond";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToABond:
      captionText = "Rights to a";
      instrumentTypeText = "Bond";
      tooltipText = "This token is backed by a Bond";
      break;

    case MarketListingViewAssetType.ETF:
      captionText = "";
      instrumentTypeText = "ETF";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAnETF:
      captionText = "Rights to an ";
      instrumentTypeText = "ETF";
      tooltipText = "This token is backed by an ETF";
      break;

    case MarketListingViewAssetType.ETN:
      captionText = "";
      instrumentTypeText = "ETN";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAnETN:
      captionText = "Rights to an ";
      instrumentTypeText = "ETN";
      tooltipText = "This token is backed by an ETN";
      break;

    case MarketListingViewAssetType.AMC:
      captionText = "";
      instrumentTypeText = "AMC";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAnAMC:
      captionText = "Rights to an ";
      instrumentTypeText = "AMC";
      tooltipText = "This token is backed by an AMC";
      break;

    case MarketListingViewAssetType.UnitTrust:
      captionText = "";
      instrumentTypeText = "Unit Trust";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAUnitTrust:
      captionText = "Rights to a ";
      instrumentTypeText = "Unit Trust";
      tooltipText = "This token is backed by a Unit Trust";
      break;

    case MarketListingViewAssetType.CryptoCurrency:
      captionText = "";
      instrumentTypeText = "Crypto Currency";
      tooltipText =
        props.token.issuer === props.token.network
          ? `Native coin on the ${props.token.network} Network`
          : `A Crypto Currency issued on the ${props.token.network} Network`;
      break;
    case MarketListingViewAssetType.RightsToACryptoCurrency:
      captionText = "";
      instrumentTypeText = "Crypto Stablecoin";
      tooltipText =
        "The token is backed by Crypto Currency on another network.";
      break;

    case MarketListingViewAssetType.FiatCurrency:
      captionText = "";
      instrumentTypeText = "Fiat";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToAFiatCurrency:
      captionText = "";
      instrumentTypeText = "Fiat Stablecoin";
      tooltipText = "The token is backed by Fiat Currency.";
      break;

    case MarketListingViewAssetType.YieldBearingStablecoin:
      captionText = "";
      instrumentTypeText = "Crypto Stablecoin";
      tooltipText =
        "Yield bearing token that bears annual percentage yield, compounded daily";
      break;

    case MarketListingViewAssetType.Commodity:
      captionText = "";
      instrumentTypeText = "Commodity";
      tooltipText = "";
      break;
    case MarketListingViewAssetType.RightsToACommodity:
      captionText = "Rights to a ";
      instrumentTypeText = "Commodity";
      tooltipText = "This token is backed by a Commodity";
      break;

    default:
      captionText = "";
      tooltipText = "We aren't sure what type of instrument this is. Hmm";
      instrumentTypeText = "?";
  }

  return (
    <StyledBox className={classes.root}>
      <Typography
        className={classes.captionText}
        variant={"caption"}
        children={captionText}
      />
      <div className={classes.iconSection}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontWeight: "bold",
            fontSize: {
              md: "16px",
              xs: "14px",
            },
            mr: 1,
          })}
          className={classes.iconText}
          variant={"h4"}
          children={instrumentTypeText}
        />
        <Tooltip
          title={tooltipText}
          aria-label={"instrument type"}
          placement={"top"}
          arrow
        >
          <IconButton sx={{ p: 0 }}>
            <InfoIcon
              sx={(theme) => ({
                cursor: "pointer",
                fontSize: 24,
                height: 16,
                width: 16,
                [theme.breakpoints.up("md")]: {
                  fontSize: 16,
                },
                display: tooltipText === "" ? "none" : "block",
              })}
              className={classes.icon}
            />
          </IconButton>
        </Tooltip>
      </div>
    </StyledBox>
  );
};

interface infoType {
  captionText: string;
  tooltipText: string;
  instrumentTypeText: string;
}

export const InstrumentTypeCard = (props: InstrumentTypeCProps) => {
  const { captionText, tooltipText, instrumentTypeText }: infoType =
    useMemo((): infoType => {
      const info = {
        captionText: "",
        tooltipText: "",
        instrumentTypeText: "",
      };

      switch (props.assetType) {
        case MarketListingViewAssetType.Share:
          info.captionText = "";
          info.instrumentTypeText = "Share";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAShare:
          info.captionText = "Rights to a";
          info.instrumentTypeText = "Share";
          info.tooltipText = "This token is backed by a Share";
          break;

        case MarketListingViewAssetType.PreferenceShare:
          info.captionText = "";
          info.instrumentTypeText = "Preference Share";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAPreferenceShare:
          info.captionText = "Rights to a";
          info.instrumentTypeText = "Preference Share";
          info.tooltipText = "This token is backed by a Preference Share";
          break;

        case MarketListingViewAssetType.Bond:
          info.captionText = "";
          info.instrumentTypeText = "Bond";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToABond:
          info.captionText = "Rights to a";
          info.instrumentTypeText = "Bond";
          info.tooltipText = "This token is backed by a Bond";
          break;

        case MarketListingViewAssetType.ETF:
          info.captionText = "";
          info.instrumentTypeText = "ETF";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAnETF:
          info.captionText = "Rights to an ";
          info.instrumentTypeText = "ETF";
          info.tooltipText = "This token is backed by an ETF";
          break;

        case MarketListingViewAssetType.ETN:
          info.captionText = "";
          info.instrumentTypeText = "ETN";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAnETN:
          info.captionText = "Rights to an ";
          info.instrumentTypeText = "ETN";
          info.tooltipText = "This token is backed by an ETN";
          break;

        case MarketListingViewAssetType.AMC:
          info.captionText = "";
          info.instrumentTypeText = "AMC";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAnAMC:
          info.captionText = "Rights to an ";
          info.instrumentTypeText = "AMC";
          info.tooltipText = "This token is backed by an AMC";
          break;

        case MarketListingViewAssetType.UnitTrust:
          info.captionText = "";
          info.instrumentTypeText = "Unit Trust";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAUnitTrust:
          info.captionText = "Rights to a ";
          info.instrumentTypeText = "Unit Trust";
          info.tooltipText = "This token is backed by a Unit Trust";
          break;

        case MarketListingViewAssetType.CryptoCurrency:
          info.captionText = "";
          info.instrumentTypeText = "Crypto Currency";
          info.tooltipText =
            props.token.issuer === props.token.network
              ? `Native coin on the ${props.token.network} Network`
              : `A Crypto Currency issued on the ${props.token.network} Network`;
          break;
        case MarketListingViewAssetType.RightsToACryptoCurrency:
          info.captionText = "";
          info.instrumentTypeText = "Crypto Stablecoin";
          info.tooltipText =
            "The token is backed by Crypto Currency on another network.";
          break;

        case MarketListingViewAssetType.FiatCurrency:
          info.captionText = "";
          info.instrumentTypeText = "Fiat";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToAFiatCurrency:
          info.captionText = "";
          info.instrumentTypeText = "Fiat Stablecoin";
          info.tooltipText = "The token is backed by Fiat Currency.";
          break;

        case MarketListingViewAssetType.YieldBearingStablecoin:
          info.captionText = "";
          info.instrumentTypeText = "Crypto Stablecoin";
          info.tooltipText =
            "Yield bearing token that bears annual percentage yield, compounded daily";
          break;

        case MarketListingViewAssetType.Commodity:
          info.captionText = "";
          info.instrumentTypeText = "Commodity";
          info.tooltipText = "";
          break;
        case MarketListingViewAssetType.RightsToACommodity:
          info.captionText = "Rights to a ";
          info.instrumentTypeText = "Commodity";
          info.tooltipText = "This token is backed by a Commodity";
          break;

        default:
          info.captionText = "";
          info.tooltipText =
            "We aren't sure what type of instrument this is. Hmm";
          info.instrumentTypeText = "?";
      }
      return info;
    }, [props]);

  return (
    <StyledBox
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {captionText && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            mr: 1,
          })}
          variant={"caption"}
          children={captionText}
        />
      )}
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          fontWeight: "bold",
          fontSize: {
            md: "16px",
            xs: "14px",
          },
          mr: 1,
        })}
        children={instrumentTypeText}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {tooltipText && (
          <Tooltip
            title={tooltipText}
            aria-label={"instrument type"}
            placement={"top"}
            arrow
          >
            <IconButton
              sx={{
                p: 0,
              }}
            >
              <InfoIcon
                sx={(theme) => ({
                  cursor: "pointer",
                  fontSize: 24,
                  height: 16,
                  width: 16,
                  [theme.breakpoints.up("md")]: {
                    fontSize: 16,
                  },
                  display: tooltipText === "" ? "none" : "block",
                })}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </StyledBox>
  );
};
