import { Amount } from "james/ledger";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "../security";
import { Fee, NewFee } from "./Fee";

export interface GenerateTransferFeesRequest {
  context: Context;
  transferAmount: Amount;
  fromAccountOwnerID: string;
}

export interface GenerateTransferFeesResponse {
  fees: Fee[];
}

export interface GenerateOffPlatformTransferFeesRequest {
  context: Context;
  transferAmount: Amount;
  fromAccountOwnerID: string;
}

export interface GenerateOffPlatformTransferFeesResponse {
  fees: Fee[];
}

const TransferFeeGeneratorServiceProviderName =
  "remuneration-TransferFeeGenerator";

export const TransferFeeGenerator = {
  async GenerateTransferFees(
    request: GenerateTransferFeesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateTransferFeesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${TransferFeeGeneratorServiceProviderName}.GenerateTransferFees`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { fees: response.fees.map((f: Fee) => NewFee(f)) };
  },
  async GenerateOffPlatformTransferFees(
    request: GenerateOffPlatformTransferFeesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateOffPlatformTransferFeesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${TransferFeeGeneratorServiceProviderName}.GenerateOffPlatformTransferFees`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { fees: response.fees.map((f: Fee) => NewFee(f)) };
  },
};
