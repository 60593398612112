import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
import { ExternalParticipantsInformationSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeExternalParticipantsInformation_pb";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { ValidationResult } from "common/validation";

export function validateExternalParticipantsInformationAttribute(
  attribute: ExternalParticipantsInformationSmartInstrumentAttribute,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ] = message;
  };

  // at least 1 allocation should be added
  if (!attribute.getEntriesList().length) {
    invalid("entries", "Add at least 1");
  }

  attribute.getEntriesList().forEach((attribute) => {
    // attribute names cannot be blank
    if (attribute.getName().replaceAll(" ", "") === "") {
      invalid(
        `entries-category-${attribute.getCategory()}-name`,
        "Cannot be blank",
      );
    }
  });

  return validationResult;
}
