import { useTheme } from "@mui/material";
import React from "react";
import {
  DateFilter,
  useGenerateIndicativePriceHistory,
} from "james/marketData/IndicativePriceHistoryGenerator";
import { Area, AreaChart, ResponsiveContainer, YAxis } from "recharts";
import { TokenIdentifier } from "james/search/identifier";
import { IndicativePriceDataPoint } from "james/marketData/IndicativePriceDataPoint";
import { Token } from "james/ledger";

interface PerformanceChartSplashProps {
  assetToken: Token;
}

export function PerformanceChartSplash(props: PerformanceChartSplashProps) {
  const theme = useTheme();

  const { loading, generateIndicativePriceHistoryResponse } =
    useGenerateIndicativePriceHistory({
      assetIdentifier: TokenIdentifier(props.assetToken),
      dateFilter: DateFilter._1M,
    });

  if (loading) {
    return null;
  }

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart
        data={generateIndicativePriceHistoryResponse.priceHistory}
        margin={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <defs>
          <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.4}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <YAxis domain={["auto", "auto"]} hide />
        <Area
          dataKey={(val: IndicativePriceDataPoint) =>
            val.buyPrice.value.toNumber()
          }
          stroke="none"
          fill="url(#colorPrimary)"
          fillOpacity={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
