import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { Identifier } from "james/search/identifier/Identifier";
import { Listing } from "./Listing";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export const ErrListingNotFoundCode = 200318;

export interface RetrieveListingRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveListingResponse {
  listing: Listing;
}

export interface SearchListingRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchListingResponse {
  records: Listing[];
  total: number;
}

export const ListingRepository = {
  serviceProvider: "market-ListingRepository",
  async RetrieveListing(
    request: RetrieveListingRequest,
  ): Promise<RetrieveListingResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${ListingRepository.serviceProvider}.RetrieveListing`,
      request,
    });
    return {
      listing: new Listing(response.listing),
    };
  },
  async SearchListing(
    request: SearchListingRequest,
  ): Promise<SearchListingResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${ListingRepository.serviceProvider}.SearchListing`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map((m: Listing) => new Listing(m)),
    };
  },
};

export function useSearchListing(
  initialSearchListingRequest?: SearchListingRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchListingRequest, setSearchListingRequest] =
    useState<SearchListingRequest>(
      initialSearchListingRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchListingResponse, setSearchListingResponse] =
    useState<SearchListingResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchListingResponse(
          await ListingRepository.SearchListing(searchListingRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for listings: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for listings: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchListingRequest, shouldNotExecute]);

  return {
    searchListingRequest,
    setSearchListingRequest,
    searchListingResponse,
    loading,
    error,
  };
}
