import React from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { DefundOrderState } from "james/banking/defundOrder";

const PREFIX = "Chips";

const classes = {
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  error: `${PREFIX}-error`,
  success: `${PREFIX}-success`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.info}`]: { backgroundColor: theme.palette.info.main },
  [`& .${classes.warning}`]: { backgroundColor: theme.palette.warning.main },
  [`& .${classes.error}`]: { backgroundColor: theme.palette.error.main },
  [`& .${classes.success}`]: { backgroundColor: theme.palette.success.main },
}));

export interface DefundOrderStateChipProps {
  state: DefundOrderState | "";
}

export function DefundOrderStateChip(props: DefundOrderStateChipProps) {
  switch (props.state) {
    case DefundOrderState.Pending:
      return (
        <Chip
          className={classes.info}
          id="defundOrderStateChip-pending-chip"
          size="small"
          label={props.state}
        />
      );

    case DefundOrderState.UnderInvestigation:
      return (
        <Chip
          className={classes.error}
          id="defundOrderStateChip-awaitingConfirmation-chip"
          size="small"
          label={props.state}
        />
      );

    case DefundOrderState.AwaitingSettlement:
      return (
        <Chip
          className={classes.warning}
          id="defundOrderStateChip-accepted-chip"
          size="small"
          label={props.state}
        />
      );

    case DefundOrderState.SettlementInProgress:
      return (
        <Chip
          className={classes.info}
          id="defundOrderStateChip-settled-chip"
          size="small"
          label={props.state}
        />
      );

    case DefundOrderState.Settled:
      return (
        <Chip
          className={classes.success}
          id="defundOrderStateChip-cancelling-chip"
          size="small"
          label={props.state}
        />
      );

    case DefundOrderState.Cancelled:
      return (
        <Chip
          className={classes.info}
          id="defundOrderStateChip-cancelled-chip"
          size="small"
          label={props.state}
        />
      );

    default:
      return <Root>{props.state}</Root>;
  }
}
