// source: financial/rateSource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.RateSource', null, global);
/**
 * @enum {number}
 */
proto.financial.RateSource = {
  UNDEFINED_RATE_SOURCE: 0,
  LIBOR_RATE_SOURCE: 1,
  EURIBOR_RATE_SOURCE: 2,
  TIBOR_RATE_SOURCE: 3,
  SOFR_RATE_SOURCE: 4,
  FED_FUNDS_RATE_SOURCE: 5,
  SOUTH_AFRICA_PRIME_RATE_SOURCE: 6,
  OIS_RATE_SOURCE: 7,
  JIBAR1_RATE_SOURCE: 8,
  JIBAR3_RATE_SOURCE: 9,
  CDOR_RATE_SOURCE: 10,
  AONIA_RATE_SOURCE: 11,
  SARON_RATE_SOURCE: 12,
  STIBOR_RATE_SOURCE: 13,
  HIBOR_RATE_SOURCE: 14,
  BBSW_RATE_SOURCE: 15,
  SIBOR_RATE_SOURCE: 16,
  EONIA_RATE_SOURCE: 17,
  CIBOR_RATE_SOURCE: 18,
  NIBOR_RATE_SOURCE: 19,
  PRIBOR_RATE_SOURCE: 20,
  WIBOR_RATE_SOURCE: 21,
  MOSPRIME_RATE_SOURCE: 22,
  US_REPOS_RATE_SOURCE: 23,
  MUNICIPAL_BONDS_RATE_SOURCE: 24,
  MORTGAGE_BONDS_RATE_SOURCE: 25,
  CORPORATE_BONDS_RATE_SOURCE: 26,
  COMMERCIAL_PAPER_RATE_SOURCE: 27,
  TREASURY_BILLS_RATE_SOURCE: 28
};

goog.object.extend(exports, proto.financial);
