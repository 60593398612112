import React from "react";
import { Chip, ChipProps, SxProps, useTheme } from "@mui/material";
import { DirectOrderState } from "james/market/DirectOrder";
import camelCase from "lodash/camelCase";

export interface DirectOrderStateChipProps {
  state: DirectOrderState | "";
  chipProps?: ChipProps;
}

export const DirectOrderStateChip = ({
  state,
  chipProps,
}: DirectOrderStateChipProps) => {
  const theme = useTheme();

  const StyleMap: { [x: string]: SxProps } = {
    [DirectOrderState.Pending]: {
      backgroundColor: theme.palette.warning.main,
    },
    [DirectOrderState.AwaitingConfirmation]: {
      backgroundColor: theme.palette.info.main,
    },
    [DirectOrderState.Accepted]: {
      backgroundColor: theme.palette.info.main,
    },
    [DirectOrderState.Settled]: {
      backgroundColor: theme.palette.success.main,
    },
    [DirectOrderState.Cancelling]: {
      backgroundColor: theme.palette.info.main,
    },
    [DirectOrderState.Cancelled]: {
      backgroundColor: theme.palette.info.main,
    },
    [DirectOrderState.Declining]: {
      backgroundColor: theme.palette.warning.main,
    },
    [DirectOrderState.Declined]: {
      backgroundColor: theme.palette.warning.main,
    },
    [DirectOrderState.Failing]: { backgroundColor: theme.palette.error.main },
    [DirectOrderState.Failed]: { backgroundColor: theme.palette.error.main },
    [DirectOrderState.ClearanceFailed]: {
      backgroundColor: theme.palette.info.main,
    },
    [DirectOrderState.UnderInvestigation]: {
      backgroundColor: theme.palette.error.main,
    },
  };

  // NOTE: clearance failed is presented to user as "Processing"
  // in accordance with business requirements.
  if (state === DirectOrderState.ClearanceFailed) {
    return (
      <Chip
        {...chipProps}
        sx={{
          ...StyleMap[state],
        }}
        id={`directOrderStateChip-${camelCase(state)}-chip`}
        size="small"
        label={"Processing"}
      />
    );
  }

  return (
    <Chip
      {...chipProps}
      sx={{
        ...StyleMap[state],
      }}
      id={`directOrderStateChip-${camelCase(state)}-chip`}
      size="small"
      label={state}
    />
  );
};
