import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Link, TextField, Typography } from "@mui/material";
import { Close as ClearIcon } from "@mui/icons-material";
import { countries } from "james/country";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import { CountryOfResidenceButton } from "./components/CountryOfResidenceButton";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

interface CountryOfResidenceCardProps {
  onSubmit: (countryCode: string) => Promise<void>;
  onGoBack: () => void;
}

export const CountrySelectorCard = ({
  onSubmit,
  onGoBack,
}: CountryOfResidenceCardProps) => {
  const navigate = useNavigate();
  const [country, setCountry] = useState(countries[200]);

  return (
    <OnboardingCard
      linksFooter={
        <>
          <Typography>
            <Link
              sx={(theme) => ({
                marginBottom: theme.spacing(1),
                fontWeight: "bold",
              })}
              onClick={onGoBack}
              fontSize={"14px"}
              component="button"
              underline="hover"
              color="secondary"
            >
              Go Back
            </Link>
          </Typography>
          <Box
            sx={(theme) => ({
              display: "flex",
              paddingBottom: theme.spacing(5.5),
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: "14px",
              })}
              variant="body1"
            >
              Already Registered?
            </Typography>
            <Link
              sx={(theme) => ({
                paddingLeft: theme.spacing(1),
                fontWeight: "bold",
              })}
              onClick={() => {
                navigate("/sign-in");
              }}
              fontSize={"14px"}
              component="button"
              underline="hover"
              color="secondary"
              data-link-info={JSON.stringify({
                content_interaction_id: "sign-in-button",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "already registered - sign in",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.SignIn,
              } as DataLinkInfoType)}
            >
              Sign In
            </Link>
          </Box>
        </>
      }
    >
      <VerticalSlide transitionDuration={1 / 4}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 4, 0.5),
          })}
          fontSize="20px"
          fontWeight="600"
          children="Country of Residence"
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 4}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(4),
            color: theme.palette.text.tertiary,
          })}
          fontSize={"16px"}
          align={"center"}
          children={"To get started, please confirm your country of residence."}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={3 / 4}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          clearIcon={
            <ClearIcon
              id="signUp-countryOfResidence-countryOfResidence-autoComplete-clearIcon"
              fontSize={"small"}
            />
          }
          sx={(theme) => ({
            width: "100%",
            paddingBottom: theme.spacing(2),
            margin: 0,
          })}
          id="signUp-countryOfResidence-countryOfResidenceCard-autoComplete"
          options={countries}
          value={country}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                "& > img": {
                  mr: 2,
                  flexShrink: 0,
                },
              }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Country of Residence"
              placeholder="Select..."
              InputLabelProps={{ shrink: true }}
              id="signUp-countryOfResidence-countryOfResidenceCard-textField"
              variant="outlined"
              fullWidth
            />
          )}
          onChange={(_, value) => {
            if (value) {
              setCountry(value);
            }
          }}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <CountryOfResidenceButton onSubmit={onSubmit} country={country} />
      </VerticalSlide>
    </OnboardingCard>
  );
};
