import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TextField, TextNumField } from "components/FormFields";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTimer } from "hooks/useTimer";
import {
  Model as StellarAccountViewModel,
  UnscopedReader as UnscopedStellarAccountViewReader,
} from "james/views/stellarAccountView";
import { TextExactCriterion } from "james/search/criterion";
import { useStellarContext } from "context/Stellar";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { FundingOrder } from "@mesh/common-js/dist/banking/fundingOrder_pb";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import { useFundingContext } from "../../../Context";
import { FundingOrderStatusReason } from "../../../../../../../james/banking/fundingOrder";

interface ConfirmationProps {
  handleApprovalButton: (
    fundingOrderID: string,
    reason: string,
  ) => Promise<void>;
  loading: boolean;
  confirmApproval: boolean;
  onClose: () => void;
  fundingOrder: FundingOrder;
  classes: {
    assetToMintSection: string;
    detail: string;
    dialogTitleContent: string;
    dialogTitle: string;
    textSearchField: string;
    dateFilterField: string;
    iconButton: string;
  };
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  handleApprovalButton,
  onClose,
  confirmApproval,
  fundingOrder,
  classes,
}: ConfirmationProps) => {
  const theme = useTheme();
  const { apiCallInProgress } = useFundingContext();
  const { timerProgress, timerCompleted, timerValue, startTimer, stopTimer } =
    useTimer();
  const [approved, setApproved] = useState(false);
  const { stellarAccountContextPopulateModelWithLedgerDetails } =
    useStellarContext();
  const [targetStellarAccountViewModel, setTargetStellarAccountViewModel] =
    useState(new StellarAccountViewModel());
  const [loading, setLoading] = useState(false);
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      await getAccountDetails();
      return;
    })();
  }, []);

  const getAccountDetails = async () => {
    setLoading(true);
    try {
      // retrieve view model of account that is to be funded
      const ledgerAccViewModel =
        await stellarAccountContextPopulateModelWithLedgerDetails(
          (
            await UnscopedStellarAccountViewReader.UnscopedReadOne({
              context: authContext,
              criteria: {
                number: TextExactCriterion(fundingOrder.getAccountnumber()),
              },
            })
          ).model,
        );
      setTargetStellarAccountViewModel(ledgerAccViewModel);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving target stellar account view model : ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error retrieving account details: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!approved) {
        return;
      }

      if (timerCompleted) {
        await handleApprovalButton(
          fundingOrder.getId(),
          `${FundingOrderStatusReason.FundingOrderApproved} by ${authContext.userID}`,
        );
        onClose();
      }
    })();
  }, [timerCompleted]);
  return (
    <Dialog open={confirmApproval}>
      <DialogTitle>
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item alignSelf="center" className={classes.dialogTitle}>
            <Typography variant="h5">Review Funding</Typography>
          </Grid>
          {!approved && (
            <Grid item>
              <IconButton
                id="fundReceipt-close-button"
                onClick={onClose}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent
        style={{
          alignItems: "center",
          width: "590px",
          padding: 0,
        }}
      >
        {loading ? (
          <Box
            sx={(theme) => ({
              height: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              paddingTop: theme.spacing(2),
              gap: theme.spacing(2),
            })}
          >
            {" "}
            <CircularProgress size={70} />{" "}
            <Typography
              variant="h5"
              color="textSecondary"
              children="Getting latest funding order data..."
            />{" "}
          </Box>
        ) : apiCallInProgress ? (
          <Box
            sx={(theme) => ({
              height: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              paddingTop: theme.spacing(2),
              gap: theme.spacing(2),
            })}
          >
            {" "}
            <CircularProgress size={70} />{" "}
            <Typography
              variant="h5"
              color="textSecondary"
              children={`Settling funding order ${fundingOrder.getId()}`}
            />{" "}
          </Box>
        ) : (
          <>
            <Box sx={{ padding: theme.spacing(0, 3) }}>
              <Typography
                sx={{ padding: "16px 0px", fontSize: "20px" }}
                variant="h3"
              >
                Confirm Account Funding
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.hint,
                  padding: theme.spacing(0, 0, 2),
                }}
              >
                Please review the client information before confirming funding.
              </Typography>
              <div className={classes.assetToMintSection}>
                <TextField
                  id="fundReceipt-assetCode-textField"
                  label="Asset Code"
                  value={
                    LedgerAmount.fromFutureAmount(fundingOrder.getAmount())
                      .token.code
                  }
                  readOnly
                />
                <TextNumField
                  readOnly
                  id="fundReceipt-mintAmount-textNumField"
                  label="Units to Mint"
                  value={
                    LedgerAmount.fromFutureAmount(fundingOrder.getAmount())
                      .value
                  }
                  disallowNegative
                />
              </div>
              <div className={classes.assetToMintSection}>
                <TextField
                  readOnly
                  fullWidth
                  id="fundReceipt-reference-textField"
                  label="Client Reference (Account Nr.)"
                  value={fundingOrder.getAccountnumber()}
                />
              </div>
              <TextField
                id="fundReceipt-stablecoinledgerID-textfield"
                label="Ledger ID"
                fullWidth
                readOnly
                value={targetStellarAccountViewModel.ledgerID}
              />
              <TextField
                fullWidth
                style={{ marginBottom: theme.spacing(2) }}
                readOnly
                id="fundReceipt-paymentReference-balance"
                label="Bank Transaction Ref."
                value={fundingOrder.getBankreference()}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                gap: "16px",
                width: "100%",
                padding: theme.spacing(2, 3),
                backgroundColor: theme.palette.custom.midnight,
              }}
            >
              {!approved && (
                <Tooltip placement="top" title={"Close"}>
                  <span>
                    <Button
                      id="fundReceipt-updateFunding"
                      variant="outlined"
                      fullWidth
                      size="small"
                      onClick={onClose}
                      children="Close"
                    />
                  </span>
                </Tooltip>
              )}
              <Tooltip
                placement="top"
                title={!approved ? "Confirm Funding" : "Cancel"}
              >
                <span>
                  <LoadingButton
                    id="fundReceipt-fundAccount-balance"
                    variant={
                      timerCompleted
                        ? "text"
                        : approved
                          ? "outlined"
                          : "contained"
                    }
                    color={approved ? "secondary" : "primary"}
                    fullWidth
                    size="small"
                    onClick={() => {
                      if (approved) {
                        setApproved(false);
                        stopTimer();
                        return;
                      } else {
                        setApproved(true);
                        startTimer(10);
                        return;
                      }
                    }}
                  >
                    {timerCompleted ? (
                      <CircularProgress size={21} variant="indeterminate" />
                    ) : approved ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          lineHeight="0px"
                          color="text.primary"
                          children="Cancel"
                        />
                        <Box
                          sx={{ position: "relative", display: "inline-flex" }}
                        >
                          <CircularProgress
                            size={21}
                            variant="determinate"
                            value={timerProgress}
                          />
                          <Box
                            sx={{
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              position: "absolute",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                              }}
                              variant="caption"
                              component="div"
                              color="text.primary"
                            >
                              {timerValue === 0
                                ? ``
                                : `${Math.round(timerValue)}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      "Confirm"
                    )}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
