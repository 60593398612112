import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Amount } from "james/ledger/Amount";

export type DepositToLiquidityPoolRequest = {
  context: Context;
  fromAccountID: string;
  maxAmountA: Amount;
  maxAmountB: Amount;
  minPrice: Amount;
  maxPrice: Amount;
};

export type DepositToLiquidityPoolResponse = {
  transactionID: string;
};

export const LiquidityPoolOperator = {
  serviceProvider: "stellar-LiquidityPoolOperator",
  async DepositToLiquidityPool(
    request: DepositToLiquidityPoolRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DepositToLiquidityPoolResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${LiquidityPoolOperator.serviceProvider}.DepositToLiquidityPool`,
        request,
      },
      opts,
    );
  },
};
