import React, { useContext, useEffect, useState } from "react";
import config from "react-global-configuration";
import Pusher, { AuthorizerCallback, Channel } from "pusher-js";
import { useIsMounted } from "hooks";
import { Environment, UserAuthenticated } from "const";
import { PusherChannelAuthorizer } from "james/pusher";

interface ContextType {
  pusherConnection: Pusher | undefined;
}

const Context = React.createContext({} as ContextType);

export function PusherContext({ children }: { children?: React.ReactNode }) {
  const isMounted = useIsMounted();
  const [connection, setConnection] = useState<Pusher | undefined>();

  // connect pusher connection on first load
  // TODO: consider preventing each public session from getting a pusher connection
  //  This can be done by only allowing authenticated pusher connections.
  //  ref: https://pusher.com/docs/channels/using_channels/authorized-connections/
  useEffect(() => {
    //  if the component is not mounted yet return
    if (!isMounted()) {
      return;
    }

    // if a connection is already established return
    if (connection) {
      return;
    }

    if (config.get("environment") !== Environment.Production) {
      Pusher.logToConsole = true;
    }

    // initialise connection
    const pusherConfig = config.get("pusherConfig");
    const pusherConnection = new Pusher(pusherConfig.appKey, {
      cluster: pusherConfig.cluster,
      authorizer: (channel: Channel) => new PusherAuthorizer(channel.name),
    });
    setConnection(pusherConnection);

    return () => {
      // On useEffect clean attempt to disconnect the pusher connection
      try {
        pusherConnection.disconnect();
        setConnection(undefined);
      } catch (e) {
        console.error(`error disconnecting pusher connection: ${e}`);
      }
    };
  }, []);

  return (
    <Context.Provider
      value={{
        pusherConnection: connection,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const usePusherContext = () => useContext(Context);
export { usePusherContext };

class PusherAuthorizer {
  public channelName = "";

  constructor(channelName: string) {
    this.channelName = channelName;
  }

  authorize(socketID: string, callback: AuthorizerCallback): void {
    (async () => {
      try {
        //
        // Prevent channel authorization for unauthenticated users
        //
        if (!sessionStorage.getItem(UserAuthenticated)) {
          return;
        }

        const response = await PusherChannelAuthorizer.AuthorizeChannel({
          channelName: this.channelName,
          socketID,
        });
        callback(null, { auth: response.authorizationString });
      } catch (e) {
        const err = e as Error;
        console.error(
          `error authorizing pusher channel: ${
            err.message ? err.message : err.toString()
          }`,
        );
        callback(new Error("could not authorize"), { auth: "" });
      }
    })();
  }
}
