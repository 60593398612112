import React, { useEffect, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useExchangeStore } from "../../../store";
import dayjs from "dayjs";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";
import { ConnectionIcon } from "./ConnectionIcon";
import { StellarTrade } from "pkgTemp/stellar/Trades";
import { getNoDecimals } from "@mesh/common-js/dist/num";
import { useFetchAndStreanTrades } from "../hooks/useFetchAndStreamTrades";
import { ScrollShadow } from "@mesh/common-js-react/dist/Utility";

export interface TradesListProps {
  loading: boolean;
  allTrades: Record<string, StellarTrade[]>;
  tradesList: StellarTrade[];
  datesList: string[];
  tradeIds: Set<string>;
  serverConnectionStatus: ServerConnectionStatus;
}

export interface TradesListActions {
  loading: (val: boolean) => void;
  allTrades: (val: Record<string, StellarTrade[]>) => void;
  tradesList: (tradesList: StellarTrade[]) => void;
  datesList: (val: string[]) => void;
  pushTrade: (val: StellarTrade[]) => void;
  tradeIds: (val: Set<string>) => void;
  serverConnectionStatus: (val: ServerConnectionStatus) => void;
}

export const TradesList = ({
  loading,
  allTrades,
  datesList,
  serverConnectionStatus,
}: TradesListProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const exchangeStore = useExchangeStore();
  const { fetchAndStreamTrades } = useFetchAndStreanTrades();
  const endStream = useRef<() => void>();

  useEffect(() => {
    setTimeout(async () => {
      endStream.current = await fetchAndStreamTrades();
    });

    return () => {
      if (endStream.current) {
        endStream.current();
      }
    };
  }, [exchangeStore.marketListingPair]);

  return (
    <Card
      sx={{
        height: {
          sm: "100%",
          xs: "calc(100vh - 180px)",
        },
        borderRadius: {
          xs: 0,
          sm: "10px",
        },
        width: "100%",
      }}
    >
      {!smDown && (
        <CardHeader
          sx={{ height: 52 }}
          title={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <ConnectionIcon connectionStatus={serverConnectionStatus} />
              <Typography>Trade History</Typography>
              {loading && <CircularProgress size={18} />}
            </Box>
          }
        />
      )}
      <CardContent
        sx={{
          px: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            alignItems: "center",
            px: 2,
          }}
        >
          <Typography textAlign="left" fontSize={"12px"}>
            Price {exchangeStore.marketListingPair?.counter.code}
          </Typography>
          <Typography textAlign="right" fontSize={"12px"}>
            Amount {exchangeStore.marketListingPair?.base.code}
          </Typography>
          <Typography
            sx={{
              ml: "auto",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            Time
          </Typography>
        </Box>
        <ScrollShadow
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mb: 2,
            mt: 1,
            position: "relative",
            overflowY: "auto",
            height: {
              sm: 380,
              xs: "calc(100vh - 220px)",
            },
            pr: 0.5,
          }}
        >
          <>
            {datesList.map((date, idx) => {
              const trades = allTrades[date];
              return (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.palette.custom.midnight,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      py: 0.5,
                      pr: 1,
                    })}
                  >
                    <Typography align="right">{date}</Typography>
                  </Box>
                  {trades.map((tr, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: 1,
                        pl: 2,
                        pr: 0.5,
                        mr: 0.5,
                        my: 0.5,
                      }}
                    >
                      <Typography
                        className={tr.type}
                        sx={(theme) => ({
                          color: theme.palette.success.main,
                          "&.Buy": {
                            color: theme.palette.error.main,
                          },
                        })}
                        noWrap
                      >
                        {tr.price.value.toFixed(
                          getNoDecimals(tr.price.value, 6, 2),
                        )}
                      </Typography>

                      <Typography noWrap textAlign="right">
                        {tr.baseAmount.value.dp(2).toString()}
                      </Typography>
                      <Typography noWrap textAlign="right">
                        {dayjs(tr.ledgerCloseTime).format("HH:mm")}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            })}
          </>
        </ScrollShadow>
      </CardContent>
    </Card>
  );
};
