import { Amount } from "james/ledger";
import { Fee, FeeState } from "./Fee";
import { Entry } from "../audit/Entry";
import BigNumber from "bignumber.js";

export const InstrumentAssetsUnderManagementFeeTypeName =
  "mesh::remuneration/InstrumentAssetsUnderManagementFee";

export class InstrumentAssetsUnderManagementFee implements Fee {
  public ["@type"]: string = InstrumentAssetsUnderManagementFeeTypeName;

  public id = "";

  public responsibleGroupID = "";

  public instrumentID = "";

  public amount: Amount = new Amount();

  public transactionID = "";

  public auditEntry: Entry = new Entry();

  public state: FeeState = FeeState.Pending;

  public vatRate: BigNumber = BigNumber("0");

  constructor(fee?: InstrumentAssetsUnderManagementFee) {
    if (!fee) {
      return;
    }
    this.id = fee.id;
    this.responsibleGroupID = fee.responsibleGroupID;
    this.instrumentID = fee.instrumentID;
    this.amount = new Amount(fee.amount);
    this.transactionID = fee.transactionID;
    this.auditEntry = new Entry(fee.auditEntry);
    this.state = fee.state;
  }

  feeAmount(): Amount {
    return this.amount;
  }

  feeID(): string {
    return this.id;
  }

  feeName(): string {
    return "Instrument Asset Under Management Fee";
  }

  feeResponsibleGroupID(): string {
    return this.responsibleGroupID;
  }

  feeTransactionID(): string {
    return this.transactionID;
  }

  feeState(): FeeState {
    return this.state;
  }

  feeVATRate(): BigNumber {
    return this.vatRate;
  }

  feeTotal(): Amount {
    return this.amount.setValue(
      this.amount.value.plus(this.amount.value.multipliedBy(this.vatRate)),
    );
  }
}
