import { TextExactCriterionTypeName } from "james/search/criterion";

export interface TextExactCriterionType {
  type: string;
  text: string;
}

function TextExactCriterion(text: string): TextExactCriterionType {
  return {
    type: TextExactCriterionTypeName,
    text,
  };
}

export { TextExactCriterion };
