import { useEffect } from "react";
import { Client } from "james/client";
import { useDocumentsViewStore } from "../store";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { ClientDetailsInspector } from "james/kyc/ClientDetailsInspector";
import { KYCDocument, KYCDocumentClassification } from "james/kyc/KYCDocument";

export const useInitialDataFetch = (client: Client) => {
  const store = useDocumentsViewStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();

  useEffect(() => {
    // Guard clauses to early exit if no client or authContext is provided.
    setTimeout(async () => {
      store.setLoading(true);
      try {
        const clientKYCDocumentsResponse =
          await ClientDetailsInspector.GetClientKYCDocuments({
            context: authContext,
            clientID: client.id,
          });

        const IDClassDocs = clientKYCDocumentsResponse.documents.filter(
          (value) =>
            value.classification ===
            KYCDocumentClassification.IdentificationDocument,
        );

        const addressClassDocs = clientKYCDocumentsResponse.documents.filter(
          (value) =>
            value.classification === KYCDocumentClassification.ProofOfResidence,
        );

        store.setIdentificationClassDocument(
          IDClassDocs.map((v) => new KYCDocument(v)),
        );
        store.setProofOfResidenceClassDocument(
          addressClassDocs.map((v) => new KYCDocument(v)),
        );

        // setProofOfResidenceClassDocument(addressClassDocs);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(err);
      }
      // Reset loading state regardless of outcome.
      store.setLoading(false);
    });
  }, [client, authContext]);
};
