import React, { useMemo } from "react";
import { alpha, Box } from "@mui/material";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";

interface ConnectionIconProps {
  connectionStatus: ServerConnectionStatus;
}

export const ConnectionIcon = ({ connectionStatus }: ConnectionIconProps) => {
  const color = useMemo(() => {
    let color = "#FFFF00";
    switch (connectionStatus) {
      case ServerConnectionStatus.Connected:
        color = "#00FF00";
        break;
      case ServerConnectionStatus.Disconnected:
        color = "#FF0000";
        break;
    }
    return color;
  }, [connectionStatus]);

  return (
    <Box
      sx={{
        width: 12,
        height: 12,
        borderRadius: "50%",
        backgroundColor: alpha(color, 0.8),
        transition: "background 0.2s ease-in-out",
        boxShadow: `-2px -2px 2px 0px ${alpha("#444444", 0.68)} inset, 0 0 6px 0px ${alpha(color, 0.6)}`,
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          position: "absolute",
          width: "1px",
          height: "1px",
          top: 4,
          left: 4,
          backgroundColor: alpha("#FFFFFF", 0.68),
          boxShadow: `0 0 2px 2px ${alpha("#FFFFFF", 0.68)}`,
        }}
      />
    </Box>
  );
};
