import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CloseIcon from "@mui/icons-material/Close";

export interface InfoPopUpProps {
  dialogProps: DialogProps;
  closeDialog: () => void;
  title: string;
  heading: string;
  contactNumber: string;
  email: string;
  website: string;
  webLink: string;
  address: string;
  twitterLink: string;
  facebookLink: string;
  instagramLink: string;
  youtubeLink: string;
  linkedInLink: string;
}

export const InfoPopUp = ({
  dialogProps,
  closeDialog,
  title,
  heading,
  contactNumber,
  email,
  website,
  webLink,
  address,
  twitterLink,
  facebookLink,
  instagramLink,
  youtubeLink,
  linkedInLink,
}: InfoPopUpProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        ...dialogProps.PaperProps,
      }}
      sx={{
        top: { sm: "0%", xs: "auto" },
      }}
      maxWidth="md"
      fullScreen={smDown}
    >
      <InfoPopUpTitle closeDialog={closeDialog} title={title} />
      <DialogContent sx={{ px: { sm: 5, xs: 3 }, pb: 5, maxWidth: 718 }}>
        <Box sx={{ mt: 3 }}>
          <Box
            sx={(theme) => ({
              borderBottom: 1,
              borderColor: theme.palette.divider,
              mb: 3,
            })}
          >
            <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
              {heading}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
              columnGap: { sm: 5, xs: 2 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
                height: { sm: 64, xs: "auto" },
              }}
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Website
              </Typography>
              <Typography
                component={Link}
                href={webLink}
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
                variant="h5"
              >
                {website}
              </Typography>
            </Box>
            <Box sx={{ mb: 2, height: { sm: 64, xs: "auto" } }}>
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Contact Number
              </Typography>
              <Typography variant="h5">{contactNumber}</Typography>
            </Box>
            <Box
              sx={{
                mb: 2,
                height: { sm: 64, xs: "auto" },
              }}
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Address
              </Typography>
              <Typography variant="h5">{address}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: { sm: 64, xs: "auto" },
              }}
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Email
              </Typography>
              <Typography
                variant="h5"
                component={Link}
                underline="hover"
                href={`mailto:${email}`}
              >
                {email}
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              {linkedInLink && (
                <Box
                  component={Link}
                  underline="none"
                  href={linkedInLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      color: "inherit",
                      p: 0.5,
                    }}
                  >
                    <LinkedInIcon
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              {instagramLink && (
                <Box
                  component={Link}
                  underline="none"
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      color: "inherit",
                      p: 0.5,
                    }}
                  >
                    <InstagramIcon
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              {facebookLink && (
                <Box
                  component={Link}
                  underline="none"
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      color: "inherit",
                      pp: 0.5,
                    }}
                  >
                    <FacebookIcon
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              {twitterLink && (
                <Box
                  component={Link}
                  underline="none"
                  href={twitterLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      color: "inherit",
                      p: 0.5,
                    }}
                  >
                    <XIcon
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              {youtubeLink && (
                <Box
                  component={Link}
                  underline="none"
                  href={youtubeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      color: "inherit",
                      p: 0.5,
                    }}
                  >
                    <YouTubeIcon
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface InfoPopUpTitleProps {
  closeDialog: () => void;
  title: string;
}

const InfoPopUpTitle = ({ closeDialog, title }: InfoPopUpTitleProps) => {
  return (
    <DialogTitle
      sx={{
        boxShadow: {
          sm: 0,
          xs: 2,
        },
      }}
    >
      <Box>
        <Typography
          variant="h5"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
          children={title}
        />
      </Box>
      <IconButton
        sx={{
          width: 14, // Adjust width as needed
          height: 14, // Adjust height as needed
          alignSelf: `flex-start`,
          justifySelf: "flex-end",
          p: 2,
        }}
        disabled={false}
        onClick={() => {
          closeDialog();
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
