import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";

const PREFIX = "IssuePriceSection";

const classes = {
  issuePriceSectionRoot: `${PREFIX}-issuePriceSectionRoot`,
  issuePriceSectionRootSmall: `${PREFIX}-issuePriceSectionRootSmall`,
  section: `${PREFIX}-section`,
  sectionSmall: `${PREFIX}-sectionSmall`,
  launchingAtTextWrapper: `${PREFIX}-launchingAtTextWrapper`,
  launchingAtText: `${PREFIX}-launchingAtText`,
  priceUnit: `${PREFIX}-priceUnit`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.issuePriceSectionRoot}`]: {
    height: "71px",
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr",
    margin: theme.spacing(1, 0),
  },

  [`&.${classes.issuePriceSectionRootSmall}`]: {
    height: "71px",
    display: "grid",
    gridTemplateColumns: "1fr",
    margin: theme.spacing(1, 0),
  },

  [`& .${classes.section}`]: {
    width: "480px",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
    },
  },

  [`& .${classes.sectionSmall}`]: {
    width: "288px",
  },

  [`& .${classes.launchingAtTextWrapper}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.launchingAtText}`]: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },

  [`& .${classes.priceUnit}`]: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

interface IssuePriceSectionProps {
  issueDate: string;
}

export function IssuePriceSection(props: IssuePriceSectionProps) {
  return (
    <Root className={classes.issuePriceSectionRootSmall}>
      <div className={classes.sectionSmall}>
        <div className={classes.launchingAtTextWrapper}>
          <Typography
            display="inline"
            className={classes.launchingAtText}
            variant="subtitle1"
            children="Launching On"
          />
          <Typography
            display="inline"
            variant="button"
            children={dayjs(props.issueDate).format(DateFormat)}
          />
        </div>
      </div>
    </Root>
  );
}
