import dayjs, { Dayjs } from "dayjs";
import { Amount, Token } from "pkgTemp/ledger";

export enum Period {
  _1D = "1D",
  _1W = "1W",
  _1M = "1M",
  _3M = "3M",
  _6M = "6M",
  _1Y = "1Y",
  _ALL = "ALL",
}

export const AllPeriods: Period[] = [
  Period._1W,
  Period._1M,
  Period._3M,
  Period._6M,
  Period._1Y,
  Period._ALL,
];

export enum Resolution {
  Minute,
  Hour,
  Day,
  Week,
}

export type GetPriceForPeriodRequest = {
  baseToken: Token;
  quoteToken: Token;
  period: Period;
  resolution: Resolution;
  allFrom?: Dayjs;
};

export type GetPriceForPeriodResponse = {
  prices: HistoricalPrice[];
};

export type GetPriceForRangeRequest = {
  baseToken: Token;
  quoteToken: Token;
  from: Dayjs;
  to: Dayjs;
  resolution: Resolution;
};

export class HistoricalPrice {
  time: Dayjs = dayjs();
  avgPrice: Amount = new Amount();

  constructor(p?: HistoricalPrice) {
    if (!p) {
      return;
    }
    this.time = dayjs(p.time);
    this.avgPrice = new Amount(p.avgPrice);
  }
}

export type GetPriceForRangeResponse = {
  prices: HistoricalPrice[];
};

export interface PriceHistorian {
  GetPriceForPeriod(
    request: GetPriceForPeriodRequest,
  ): Promise<GetPriceForPeriodResponse>;

  GetPriceForRange(
    request: GetPriceForRangeRequest,
  ): Promise<GetPriceForRangeResponse>;
}
