import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import cx from "classnames";
import {
  InstrumentRiskProfile,
  RiskColorMap,
} from "james/financial/InstrumentRiskProfile";

const PREFIX = "RiskProfileText";

const classes = {
  riskProfileRoot: `${PREFIX}-riskProfileRoot`,
  title: `${PREFIX}-title`,
  bar: `${PREFIX}-bar`,
  riskBase: `${PREFIX}-riskBase`,
  low: `${PREFIX}-low`,
  lowModerate: `${PREFIX}-lowModerate`,
  moderate: `${PREFIX}-moderate`,
  moderateHigh: `${PREFIX}-moderateHigh`,
  high: `${PREFIX}-high`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.riskProfileRoot}`]: {
    height: "45px",
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.bar}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridAutoFlow: "columns",
    height: "4px",
    width: "100%",
  },

  [`& .${classes.riskBase}`]: {
    position: "relative",
  },

  [`& .${classes.low}`]: {
    background: "#acdcdd",
    color: "#acdcdd",
  },

  [`& .${classes.lowModerate}`]: {
    background: "#ACDDB2",
    color: "#ACDDB2",
  },

  [`& .${classes.moderate}`]: {
    background: "#FFCA83",
    color: "#FFCA83",
  },

  [`& .${classes.moderateHigh}`]: {
    background: "#F27049",
    color: "#F27049",
  },

  [`& .${classes.high}`]: {
    background: "#D94A4B",
    color: "#D94A4B",
  },
}));

const indicatorClasses = {
  indicatorRoot: `${PREFIX}-indicatorRoot`,
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
};

const IndicatorRoot = styled("div")(({ theme }) => ({
  [`&.${indicatorClasses.indicatorRoot}`]: {
    marginTop: theme.spacing(1),
    position: "absolute",
    left: 0,
    right: 0,
  },

  [`& .${indicatorClasses.container}`]: {
    display: "grid",
    justifyItems: "center",
  },

  [`& .${indicatorClasses.icon}`]: {
    transform: "rotate(-90deg)",
  },
}));

interface IndicatorProps {
  riskProfile: InstrumentRiskProfile | "";
  hide: boolean;
}

const Indicator = (props: IndicatorProps) => {
  if (props.hide) {
    return null;
  }

  return (
    <IndicatorRoot className={indicatorClasses.indicatorRoot}>
      <Root className={indicatorClasses.container}>
        <PlayArrowIcon className={indicatorClasses.icon} />
      </Root>
    </IndicatorRoot>
  );
};

interface RiskProfileProps {
  riskProfile: InstrumentRiskProfile | "";
}

export const RiskProfileText = ({ riskProfile }: RiskProfileProps) => {
  return (
    <Typography
      sx={{
        color: RiskColorMap[riskProfile],
        fontWeight: "bold",
        maxWidth: 120,
        textAlign: "end",
      }}
      children={`${riskProfile} Risk`}
    />
  );
};

export const RiskProfile = (props: RiskProfileProps) => {
  return (
    <Root className={classes.riskProfileRoot}>
      <Typography
        className={classes.title}
        variant={"caption"}
        children={"Risk Profile"}
      />
      <div className={classes.bar}>
        <div className={cx(classes.riskBase, classes.low)}>
          <Indicator
            riskProfile={props.riskProfile}
            hide={props.riskProfile !== InstrumentRiskProfile.Low}
          />
        </div>
        <div className={cx(classes.riskBase, classes.lowModerate)}>
          <Indicator
            riskProfile={props.riskProfile}
            hide={props.riskProfile !== InstrumentRiskProfile.LowToModerate}
          />
        </div>
        <div className={cx(classes.riskBase, classes.moderate)}>
          <Indicator
            riskProfile={props.riskProfile}
            hide={props.riskProfile !== InstrumentRiskProfile.Moderate}
          />
        </div>
        <div className={cx(classes.riskBase, classes.moderateHigh)}>
          <Indicator
            riskProfile={props.riskProfile}
            hide={props.riskProfile !== InstrumentRiskProfile.ModerateToHigh}
          />
        </div>
        <div className={cx(classes.riskBase, classes.high)}>
          <Indicator
            riskProfile={props.riskProfile}
            hide={props.riskProfile !== InstrumentRiskProfile.High}
          />
        </div>
      </div>
    </Root>
  );
};
