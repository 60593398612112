import React from "react";
import { Box, SxProps, Typography, useTheme } from "@mui/material";
import yieldIcon from "assets/images/yieldIcon.png";

interface YieldCoinLabelProps {
  sx?: SxProps;
}

export const YieldCoinLabel = ({ sx }: YieldCoinLabelProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        width: "170px",
        backgroundColor: theme.palette.custom.midnight,
        pl: 3,
        my: 1,
        height: theme.spacing(3),
        alignItems: "center",
        ...sx,
      }}
    >
      <Box
        component="img"
        sx={{
          position: "relative",
          height: "32px",
          width: "32px",
        }}
        src={yieldIcon}
      />
      <Typography
        sx={{
          color: theme.palette.warning.light,
          ml: 1,
          fontSize: "12px",
          fontWeight: 500,
        }}
      >
        Yield Bearing
      </Typography>
    </Box>
  );
};
