export class Profile {
  public clientName = "";

  public description = "";

  public website = "";

  public linkedIn = "";

  public twitter = "";

  public instagram = "";

  public facebook = "";

  public youtube = "";

  public otherSite = "";

  constructor(profile?: Profile) {
    if (!profile) {
      return;
    }
    this.clientName = profile.clientName;
    this.description = profile.description;
    this.website = profile.website;
    this.linkedIn = profile.linkedIn;
    this.twitter = profile.twitter;
    this.instagram = profile.instagram;
    this.facebook = profile.facebook;
    this.youtube = profile.youtube;
    this.otherSite = profile.otherSite;
  }
}
