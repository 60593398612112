import { Amount } from "james/ledger";
import { SpotState, SpotType } from "james/market";
import dayjs from "dayjs";

export const ModelTypeName = "mesh::marketSpotView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;
  public id = "";
  public ownerID = "";
  public spotID = "";
  public number = "";
  public type: SpotType | "" = "";
  public receivedAmount: Amount = new Amount();
  public paidAmount: Amount = new Amount();
  public price: Amount = new Amount();
  public feeAmount: Amount = new Amount();
  public vatRate: string = "0";
  public fromAccountDescription = "";
  public state: SpotState | "" = "";
  public submittedAt: string = dayjs().format();
  public failureReasons: string[] = [];
  public clientName: string = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.ownerID = model.ownerID;
    this.spotID = model.spotID;
    this.number = model.number;
    this.type = model.type;
    this.receivedAmount = new Amount(model.receivedAmount);
    this.paidAmount = new Amount(model.paidAmount);
    this.price = new Amount(model.price);
    this.feeAmount = new Amount(model.feeAmount);
    this.vatRate = model.vatRate;
    this.fromAccountDescription = model.fromAccountDescription;
    this.state = model.state;
    this.submittedAt = model.submittedAt;
    this.failureReasons = [...model.failureReasons];
    this.clientName = model.clientName;
  }
}
