import { RouteType } from "routes/Route";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Obligations } from "views/UnderwriterHub/components/Obligations";
import { Client } from "james/client";
import { ViewConfiguration } from "james/configuration";
import { UserProfileView } from "views/UserProfile";

interface UnderwriterHubRoutesToReturn {
  homeRoute: RouteType;
  otherRoutes: RouteType[];
  userProfileRoute: RouteType;
  clientProfileRoute?: RouteType;
}

export const UnderwriterHubRouteBuilder: (
  viewConfiguration: ViewConfiguration,
  client?: Client,
) => UnderwriterHubRoutesToReturn = () => {
  const routesToReturn = {
    userProfileRoute: {
      name: "User Profile",
      id: "user-profile",
      path: "/underwriter-hub/user-profile",
      icon: DashboardIcon,
      component: <UserProfileView />,
    },
    homeRoute: {
      name: "Obligations",
      id: "",
      path: "/underwriter-hub/obligations",
      icon: DashboardIcon,
      component: <Obligations />,
      allowSubPaths: true,
    },
    otherRoutes: [],
    ClientProfileRoute: undefined,
  };

  return routesToReturn;
};
