import { OrchestrateOracleDataSynchronisationsRequest } from "@mesh/common-js/dist/financial/oracleDataSynchronisationOrchestrator_pb";
import { OrchestratePaymentsRequest } from "@mesh/common-js/dist/financial/paymentOrchestrator_pb";
import { OrchestrateSmartInstrumentsRequest } from "@mesh/common-js/dist/financial/smartInstrumentOrchestrator_pb";
import { OrchestrateAssetHolderLookupsRequest } from "@mesh/common-js/dist/ledger/assetHolderLookupOrchestrator_pb";
import { OrchestrateSubscriptionOrderBooksRequest } from "@mesh/common-js/dist/market/subscriptionOrderBookOrchestrator_pb";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

export const Orchestration = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const {
    financial: {
      smartInstrumentOrchestrator,
      oracleDataSynchronisationOrchestrator,
      paymentOrchestrator,
    },
    ledger: { ledgerAssetHolderLookupOrchestrator },
    market: { subscriptionOrderBookOrchestrator },
  } = useAPIContext();
  const [apiLoading, setAPILoading] = useState(false);

  const renderButton = (title: string, action: () => Promise<void>) => {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={action}
        disabled={apiLoading}
      >
        {title}
      </Button>
    );
  };

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(3),
      })}
    >
      <Card>
        <CardHeader
          title={
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(2),
              })}
            >
              <Typography variant="h5">Orchestrate System Actions</Typography>
              {apiLoading && <CircularProgress size={10} />}
            </Box>
          }
        />
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
          })}
        >
          {renderButton("Subscription Order Books", async () => {
            setAPILoading(true);
            try {
              await subscriptionOrderBookOrchestrator.orchestrateSubscriptionOrderBooks(
                new OrchestrateSubscriptionOrderBooksRequest().setContext(
                  authContext.toFuture(),
                ),
              );
            } catch (e) {
              console.error("error orchestrating subscription order books", e);
              enqueueSnackbar(
                `error orchestrating subscription order books: ${e}`,
                { variant: "error" },
              );
            }
            setAPILoading(false);
          })}
          {renderButton("Smart Instruments", async () => {
            setAPILoading(true);
            try {
              await smartInstrumentOrchestrator.orchestrateSmartInstruments(
                new OrchestrateSmartInstrumentsRequest().setContext(
                  authContext.toFuture(),
                ),
              );
            } catch (e) {
              console.error("error orchestrating smart instruments", e);
              enqueueSnackbar(`error orchestrating smart instruments: ${e}`, {
                variant: "error",
              });
            }
            setAPILoading(false);
          })}
          {renderButton("Oracle Data Synchronisation", async () => {
            setAPILoading(true);
            try {
              await oracleDataSynchronisationOrchestrator.orchestrateOracleDataSynchronisations(
                new OrchestrateOracleDataSynchronisationsRequest().setContext(
                  authContext.toFuture(),
                ),
              );
            } catch (e) {
              console.error(
                "error orchestrating oracle data synchronisation",
                e,
              );
              enqueueSnackbar(
                `error orchestrating oracle data synchronisation: ${e}`,
                {
                  variant: "error",
                },
              );
            }
            setAPILoading(false);
          })}
          {renderButton("Asset Holder Lookups", async () => {
            setAPILoading(true);
            try {
              await ledgerAssetHolderLookupOrchestrator.orchestrateAssetHolderLookups(
                new OrchestrateAssetHolderLookupsRequest().setContext(
                  authContext.toFuture(),
                ),
              );
            } catch (e) {
              console.error("error orchestrating asset holder lookups", e);
              enqueueSnackbar(
                `error orchestrating asset holder lookups: ${e}`,
                { variant: "error" },
              );
            }
            setAPILoading(false);
          })}
          {renderButton("Payments", async () => {
            setAPILoading(true);
            try {
              await paymentOrchestrator.orchestratePayments(
                new OrchestratePaymentsRequest().setContext(
                  authContext.toFuture(),
                ),
              );
            } catch (e) {
              console.error("error orchestrating payments", e);
              enqueueSnackbar(`error orchestrating payments: ${e}`, {
                variant: "error",
              });
            }
            setAPILoading(false);
          })}
        </CardContent>
      </Card>
    </Box>
  );
};
