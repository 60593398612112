import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Amount } from "james/ledger";

export interface CalculateLiquidityPoolDepositRequest {
  context: Context;
  liquidityPoolID: string;
  maxAmountA: Amount;
  maxAmountB: Amount;
  slippage: string;
}

export interface CalculateLiquidityPoolDepositResponse {
  maxAmountA: Amount;
  maxAmountB: Amount;
  minPrice: Amount;
  maxPrice: Amount;
}

export const LiquidityPoolCalculator = {
  serviceProvider: "stellar-LiquidityPoolCalculator",
  async CalculateLiquidityPoolDeposit(
    request: CalculateLiquidityPoolDepositRequest,
  ): Promise<CalculateLiquidityPoolDepositResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${LiquidityPoolCalculator.serviceProvider}.CalculateLiquidityPoolDeposit`,
      request,
    });
    return {
      maxAmountA: new Amount(response.maxAmountA),
      maxAmountB: new Amount(response.maxAmountB),
      minPrice: new Amount(response.minPrice),
      maxPrice: new Amount(response.maxPrice),
    };
  },
};
