import {
  SmartInstrumentAttributeWrapper,
  allSmartInstrumentAttributeTypes,
  smartInstrumentAttributeTypeToString,
} from "@mesh/common-js/dist/financial";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { SmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttribute_pb";
import {
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, alpha } from "@mui/system";
import React, { useState } from "react";
import {
  Close as DeleteIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import {
  SectorAllocationsAttributeForm,
  CountryAllocationsAttributeForm,
  FiatCurrencyAllocationsAttributeForm,
  HoldingAllocationsAttributeForm,
  ExternalParticipantAttributeForm,
  RiskAssessmentAttributeForm,
  AssetClassAttributeForm,
  AnnualPerformanceLogAttributeForm,
} from "./components";
import { ViewMode, useBuilderContext } from "../../../../Context";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { useApplicationContext } from "context/Application/Application";

export const AttributesForm = () => {
  const {
    viewMode,
    apiCallInProgress,
    formData,
    formDataUpdater,
    formDataValidationResult,
    changeAttributesAction,
  } = useBuilderContext();
  const { viewConfiguration } = useApplicationContext();

  // set a local view mode initialised to view
  const [localViewMode, setLocalViewMode] = useState(ViewMode.View);

  // read only determined by global builder view mode if instrument is in draft, other wise local
  const readOnly =
    formData.smartInstrument.getState() ===
    SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE
      ? viewMode === ViewMode.View
      : localViewMode === ViewMode.View;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Instrument Attributes</Typography>
        {(() => {
          // if the instrument is in draft then view mode is determined by global view mode
          // so do not show local save/edit button
          if (
            formData.smartInstrument.getState() ===
              SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE ||
            !viewConfiguration["Smart Instruments"]?.Write?.ChangeAttributes
          ) {
            return null;
          }

          return localViewMode === ViewMode.Edit ? (
            <Tooltip
              placement="top"
              title={(() => {
                if (!formDataValidationResult.valid) {
                  const problems: string[] = [];
                  Object.keys(
                    formDataValidationResult.fieldValidations,
                  ).forEach((k) => {
                    if (formDataValidationResult.fieldValidations[k]) {
                      problems.push(
                        `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                      );
                    }
                  });
                  return (
                    <Box>
                      <Typography>
                        All problems need to be resolved before calculations can
                        run:
                      </Typography>
                      <ul>
                        {problems.map((p, idx) => (
                          <li key={idx}>{p}</li>
                        ))}
                      </ul>
                    </Box>
                  );
                }
                return "";
              })()}
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await changeAttributesAction();
                    setLocalViewMode(ViewMode.View);
                  }}
                  disabled={
                    !formDataValidationResult.valid || apiCallInProgress
                  }
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setLocalViewMode(ViewMode.Edit)}
            >
              Edit
            </Button>
          );
        })()}
      </Box>
      {!readOnly && (
        <TextField
          size="small"
          sx={(theme) => ({
            width: 150,
            marginBottom: theme.spacing(2),
          })}
          id={"smartInstrumentForm-potentialAttributeType-selectField"}
          disabled={apiCallInProgress}
          label="Add Attribute"
          select
          placeholder="Select..."
          value={
            SmartInstrumentAttributeType.UNDEFINED_SMART_INSTRUMENT_ATTRIBUTE_TYPE
          }
          onChange={(e) => {
            formDataUpdater.addAttribute(
              Number(e.target.value) as SmartInstrumentAttributeType,
            );
          }}
        >
          {allSmartInstrumentAttributeTypes
            .filter(
              // only show non-used attributes as possible new attributes
              (attributeType) =>
                !formData.smartInstrument
                  .getAttributesList()
                  .find(
                    (existingAttribute) =>
                      new SmartInstrumentAttributeWrapper(existingAttribute)
                        .smartInstrumentAttributeType === attributeType,
                  ),
            )
            .map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {smartInstrumentAttributeTypeToString(v)}
                </MenuItem>
              );
            })}
        </TextField>
      )}
      {(() => {
        const attributes = formData.smartInstrument.getAttributesList();

        if (!attributes.length) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: theme.spacing(0.5),
                })}
              >
                <FaceIcon
                  sx={(theme) => ({
                    fontSize: 110,
                    color: alpha(theme.palette.background.default, 0.5),
                  })}
                />
                <Typography
                  color="secondary"
                  variant="h4"
                  children="No Attributes Added Yet!"
                />
              </Box>
            </Box>
          );
        }

        const attributeWrapper = (
          attributeType: SmartInstrumentAttributeType,
          attributeIdx: number,
          component: React.ReactNode,
        ) => {
          return (
            <Box
              sx={(theme) => ({
                padding: theme.spacing(1),
                border: `solid 1px ${theme.palette.divider}`,
              })}
              key={`attribute-${attributeType}`}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                >
                  {smartInstrumentAttributeTypeToString(attributeType)}
                </Typography>
                {!readOnly && (
                  <Tooltip
                    placement="top"
                    title={`Remove '${smartInstrumentAttributeTypeToString(attributeType)}' Attribute`}
                  >
                    <IconButton
                      id={`smartInstrumentForm-attribute-${attributeIdx}-delete-iconButton`}
                      onClick={() =>
                        formDataUpdater.removeAttribute(attributeIdx)
                      }
                      sx={{
                        width: "24px",
                        height: "24px",
                        padding: "4px",
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              {component}
            </Box>
          );
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {attributes.map((rawAttribute, idx) => {
              const attribute = new SmartInstrumentAttributeWrapper(
                rawAttribute,
              );

              switch (attribute.smartInstrumentAttributeType) {
                case SmartInstrumentAttributeType.SECTOR_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const sectorAllocationsAttribute =
                    rawAttribute.getSectorallocationssmartinstrumentattribute();
                  return sectorAllocationsAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <SectorAllocationsAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          sectorAllocationsAttribute={
                            sectorAllocationsAttribute
                          }
                          onChange={(updatedSectorAllocationsAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setSectorallocationssmartinstrumentattribute(
                                  updatedSectorAllocationsAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.COUNTRY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const countryAllocationsAttribute =
                    rawAttribute.getCountryallocationssmartinstrumentattribute();
                  return countryAllocationsAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <CountryAllocationsAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          countryAllocationsAttribute={
                            countryAllocationsAttribute
                          }
                          onChange={(updatedCountryAllocationsAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setCountryallocationssmartinstrumentattribute(
                                  updatedCountryAllocationsAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.FIAT_CURRENCY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const fiatCurrencyAllocationsAttribute =
                    rawAttribute.getFiatcurrencyallocationssmartinstrumentattribute();
                  return fiatCurrencyAllocationsAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <FiatCurrencyAllocationsAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          fiatCurrencyAllocationsAttribute={
                            fiatCurrencyAllocationsAttribute
                          }
                          onChange={(updatedFiatCurrencyAllocationsAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setFiatcurrencyallocationssmartinstrumentattribute(
                                  updatedFiatCurrencyAllocationsAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const holdingAllocationsAttribute =
                    rawAttribute.getHoldingallocationssmartinstrumentattribute();
                  return holdingAllocationsAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <HoldingAllocationsAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          holdingAllocationsAttribute={
                            holdingAllocationsAttribute
                          }
                          onChange={(updatedHoldingAllocationsAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setHoldingallocationssmartinstrumentattribute(
                                  updatedHoldingAllocationsAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const riskAssessmentAttribute =
                    rawAttribute.getRiskassessmentsmartinstrumentattribute();
                  return riskAssessmentAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <RiskAssessmentAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          riskAssessmentAttribute={riskAssessmentAttribute}
                          onChange={(updatedRiskAssessmentAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setRiskassessmentsmartinstrumentattribute(
                                  updatedRiskAssessmentAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const externalParticipantsInformationAttribute =
                    rawAttribute.getExternalparticipantsinformationsmartinstrumentattribute();
                  return externalParticipantsInformationAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <ExternalParticipantAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          externalParticipantsInformationAttribute={
                            externalParticipantsInformationAttribute
                          }
                          onChange={(
                            updatedExternalParticipantsInformationAttribute,
                          ) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setExternalparticipantsinformationsmartinstrumentattribute(
                                  updatedExternalParticipantsInformationAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const assetClassAttribute =
                    rawAttribute.getAssetclasssmartinstrumentattribute();
                  return assetClassAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <AssetClassAttributeForm
                          formDataValidationResult={formDataValidationResult}
                          assetClassAttribute={assetClassAttribute}
                          onChange={(updatedAssetClassAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setAssetclasssmartinstrumentattribute(
                                  updatedAssetClassAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                case SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
                  const annualPerformanceLogAttribute =
                    rawAttribute.getAnnualperformancelogsmartinstrumentattribute();
                  return annualPerformanceLogAttribute
                    ? attributeWrapper(
                        attribute.smartInstrumentAttributeType,
                        idx,
                        <AnnualPerformanceLogAttributeForm
                          startDate={
                            formData.smartInstrument.getIssuedate() ??
                            new Timestamp()
                          }
                          formDataValidationResult={formDataValidationResult}
                          annualPerformanceLogAttribute={
                            annualPerformanceLogAttribute
                          }
                          onChange={(updatedAnnualPerformanceLogAttribute) =>
                            formDataUpdater.updateAttribute({
                              smartInstrumentAttribute:
                                new SmartInstrumentAttribute().setAnnualperformancelogsmartinstrumentattribute(
                                  updatedAnnualPerformanceLogAttribute,
                                ),
                              attributeIdx: idx,
                            })
                          }
                          disabled={apiCallInProgress}
                          readOnly={readOnly}
                        />,
                      )
                    : "-";
                }

                default:
                  return null;
              }
            })}
          </Box>
        );
      })()}
    </Box>
  );
};
