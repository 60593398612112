import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import cx from "classnames";
import { Client, ClientKYCStatus } from "james/client";
import {
  Close as CloseIcon,
  Close as ClearIcon,
  Info,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField } from "components/FormFields/TextField";
import { DateField } from "components/FormFields";
import { Autocomplete } from "@mui/lab";
import { countries } from "james/country";
import { IdentificationType } from "james/legal";
import { useValidatedForm } from "hooks/useForm";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import {
  Person,
  PersonManagerRead,
  PersonManagerWrite,
  SourceOfIncomeAndWealthType,
  ValidSourceOfIncomeTypes,
  ValidSourceOfWealthTypes,
} from "james/legal/person";
import {
  checkCompletedSteps,
  FormState,
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  initialState,
  validationFunc,
} from "views/KYC/useFormState";
import { Address } from "james/location";
import { KYCDocumentClassification } from "james/kyc/KYCDocument";
import { HelpWidget } from "./components/HelpWidget/HelpWidget";
import { ClientDetailsInspector } from "james/kyc/ClientDetailsInspector";
import { KYCCaption } from "./components/KYCCaption/KYCCaption";
import { NonSAKYCCaption } from "views/KYC/components/KYCCaption/NonSAKYCCaption";
import { KYCVerificationInProgressPlaceHolder } from "components/PlaceHolderScreens/KYCVerificationInProgressPlaceHolder";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import Close from "@mui/icons-material/Close";
import { InfluentialPersonStatus } from "james/legal/person/Person";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { SumsubDialog } from "./components/SumsubDialog/SumsubDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  ApplicantInspector,
  docTypeFullName,
  RetrieveApplicantVerificationStepsStatusResponse,
  Status,
} from "james/sumsub/ApplicantInspector";

const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const saveByteArray = (
  fileName: string,
  base64File: string,
  mimeType: string,
): void => {
  const blob = new Blob([base64ToArrayBuffer(base64File)], { type: mimeType });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.target = "_blank";
  link.rel = "noreferrer";
  link.click();
};

function ApplicantVerificationStepsStatusTable() {
  const [
    applicantVerificationStepsStatus,
    setApplicantVerificationStepsStatus,
  ] = useState<Array<RetrieveApplicantVerificationStepsStatusResponse>>([]);
  const { authContext, myClient } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();

  const getApplicantVerificationStepsStatus = useCallback(async () => {
    if (myClient && myClient?.id !== "") {
      try {
        const verificationStepsStatusResponse =
          await ApplicantInspector.RetrieveApplicantVerificationStepsStatus({
            context: authContext,
            clientID: myClient.id,
          });

        setApplicantVerificationStepsStatus(verificationStepsStatusResponse);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `Error getting applicant verification statuses: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error getting applicant verification statuses", {
          variant: "error",
        });
      }
    }
  }, [myClient]);

  const handleDownloadDocument = useCallback(
    async (name: string, imageID: number) => {
      if (myClient && myClient?.id !== "") {
        try {
          const res = await ApplicantInspector.RetrieveApplicantDocument({
            context: authContext,
            clientID: myClient.id,
            imageID,
          });
          saveByteArray(name, res.File, res.MimeType);
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }
      }
    },
    [myClient],
  );

  useEffect(() => {
    getApplicantVerificationStepsStatus();
  }, [getApplicantVerificationStepsStatus]);

  const status = useCallback((reviewAnswer: Status | undefined): string => {
    switch (reviewAnswer) {
      case "GREEN":
        return "Completed";
      case "RED":
        return "Declined";
      default:
        return "Pending";
    }
  }, []);

  if (applicantVerificationStepsStatus.length === 0) {
    return null;
  }

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Required</TableCell>
            <TableCell align="center">Document</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applicantVerificationStepsStatus.map((doc) => (
            <TableRow
              key={doc.idDocType}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {docTypeFullName(doc.idDocType)}
              </TableCell>
              <TableCell
                align="center"
                sx={(theme) => ({ color: theme.palette.success.light })}
              >
                {doc.imageIds.map((imageId: number) => (
                  <Button
                    key={imageId}
                    onClick={() =>
                      handleDownloadDocument(
                        docTypeFullName(doc.idDocType),
                        imageId,
                      )
                    }
                  >
                    View
                  </Button>
                ))}
              </TableCell>
              <TableCell
                align="left"
                sx={(theme) =>
                  doc.reviewResult.reviewAnswer === "GREEN"
                    ? { color: theme.palette.success.light }
                    : doc.reviewResult.reviewAnswer === "RED"
                      ? { color: theme.palette.error.light }
                      : { color: theme.palette.info.light }
                }
              >
                {status(doc.reviewResult.reviewAnswer)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const PREFIX = "KYCDialog";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  mainHeading: `${PREFIX}-mainHeading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  outStandingChipColor: `${PREFIX}-outStandingChipColor`,
  verifiedChipColor: `${PREFIX}-verifiedChipColor`,
  inProgressChipColor: `${PREFIX}-inProgressChipColor`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  successColor: `${PREFIX}-successColor`,
  warningColor: `${PREFIX}-warningColor`,
  accordionDetails: `${PREFIX}-accordionDetails`,
  documentUploadSection: `${PREFIX}-documentUploadSection`,
  supportingDocsTypography: `${PREFIX}-supportingDocsTypography`,
  dialogContent: `${PREFIX}-dialogContent`,
  rightBorder: `${PREFIX}-rightBorder`,
  leftBorder: `${PREFIX}-leftBorder`,
  fullHeight: `${PREFIX}-fullHeight`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.mainHeading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 32,
    width: 32,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  [`& .${classes.outStandingChipColor}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.info.main,
    fontWeight: 400,
  },

  [`& .${classes.verifiedChipColor}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.success.main,
    fontWeight: 400,
  },

  [`& .${classes.inProgressChipColor}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.info.main,
    fontWeight: 400,
  },

  [`& .${classes.accordionSummary}`]: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },

  [`& .${classes.heading}`]: {
    flexBasis: "50%",
    flexShrink: 0,
  },

  [`& .${classes.subHeading}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2,auto)",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.successColor}`]: {
    color: theme.palette.success.light,
  },

  [`& .${classes.warningColor}`]: {
    color: theme.palette.warning.light,
  },

  [`& .${classes.accordionDetails}`]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },

  [`& .${classes.documentUploadSection}`]: {
    padding: "32px",
  },

  [`& .${classes.supportingDocsTypography}`]: {
    paddingBottom: "32px",
  },

  [`& .${classes.dialogContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.fullHeight}`]: {
    height: "100%",
  },
}));

export function KYCDialog() {
  const {
    authContext,
    loginClaims,
    myClientKYCStatus,
    myClient,
    myClientRetrievalErr,
    refreshMyClient,
    refreshMyPerson,
  } = useApplicationContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isTabletLandScape = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("md", "lg"),
  );
  const navigate = useNavigate();
  const { errorContextErrorTranslator } = useErrorContext();
  const [apiLoading, setApiLoading] = useState(false);
  const [showSumsubDialog, setShowSumsubDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [showMobilePEPInfo, setShowMobilePEPInfo] = useState(false);
  const initialTouchedFields: Set<string> = new Set();

  useEffect(() => {
    handleRefreshPersonEntity();
  }, []);

  useEffect(() => {
    if (!myClient && myClientRetrievalErr) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      handleClose();
      return;
    }
  }, [myClient, myClientRetrievalErr]);

  useEffect(() => {
    handleRefreshPersonEntity();
    // keep the sumsub dialog open while verification is in progress
    if (myClientKYCStatus === ClientKYCStatus.VerificationInProgressStatus) {
      setShowSumsubDialog(false);
    }
    return () => setShowSumsubDialog(false); // remove dialog upon unmount
  }, [myClientKYCStatus]);

  const asyncInitState = async (): Promise<FormState> => {
    try {
      setApiLoading(true);
      const retrievedPerson = await PersonManagerRead.RetrieveMyPerson({
        context: authContext,
      });

      // add fields with existing values to initial touched fields for validation
      Object.keys(retrievedPerson.person).forEach(
        (key) =>
          retrievedPerson.person[key as keyof Person] !== "" &&
          initialTouchedFields.add(key),
      );

      Object.keys(retrievedPerson.person.residentialAddress).forEach(
        (key) =>
          retrievedPerson.person.residentialAddress[key as keyof Address] !==
            "" && initialTouchedFields.add(key),
      );

      if (!retrievedPerson.person.nationality) {
        retrievedPerson.person.nationality = countries[200].value;
        initialTouchedFields.add("nationality");
      }

      const getClientKYCDocsResponse =
        await ClientDetailsInspector.GetClientKYCDocuments({
          context: authContext,
          clientID: loginClaims.clientID,
        });

      // filter out all identification classification documents
      const IDClassDocs = getClientKYCDocsResponse.documents.filter(
        (value) =>
          value.classification ===
          KYCDocumentClassification.IdentificationDocument,
      );

      // filter out all proof of residence classification documents
      const addressClassDocs = getClientKYCDocsResponse.documents.filter(
        (value) =>
          value.classification === KYCDocumentClassification.ProofOfResidence,
      );

      setApiLoading(false);
      return {
        person: {
          ...retrievedPerson.person,
        },
        clientKYCDocuments: {
          identificationClassDocument: IDClassDocs,
          proofOfResidenceClassDocument: addressClassDocs,
        },
      };
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
      handleClose();
    }

    setApiLoading(false);
    return {
      person: new Person(),
      clientKYCDocuments: {
        identificationClassDocument: [],
        proofOfResidenceClassDocument: [],
      },
    };
  };

  const [
    formState,
    validationResult,
    formUpdater,
    validationInProgress,
    touchedFields,
  ] = useValidatedForm<FormState, FormUpdaterSpecsType>(
    validationFunc,
    asyncInitState,
    formUpdaterSpecs,
    initialState,
    initialTouchedFields,
  );

  //
  // handlers
  //

  const handleRefreshPersonEntity = async () => {
    try {
      const retrievedPerson = await PersonManagerRead.RetrieveMyPerson({
        context: authContext,
      });
      formUpdater.person(retrievedPerson.person);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to retrieve person:  ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `unable to retrieve person:  ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
  };

  const handleSave = async () => {
    // update the person entity
    try {
      setApiLoading(true);
      await PersonManagerWrite.UpdateMyPerson({
        context: authContext,
        person: formState.person,
      });
      await handleRefreshPersonEntity();
      await refreshMyPerson();
      enqueueSnackbar("Identity Verification Info Saved", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to update person: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        "Failed to save your personal information. Please refresh the page and try again.",
        {
          variant: "warning",
        },
      );
    } finally {
      setApiLoading(false);
    }
  };

  const handleNext = async () => {
    try {
      setApiLoading(true);
      try {
        // try update the person
        // this may fail if the local state is outdated)
        await PersonManagerWrite.UpdateMyPerson({
          context: authContext,
          person: formState.person,
        });
      } catch (e) {
        // if the update fails, try retrieving the latest person entity
        // so that we have the latest state and retry the update
        // note this may cause any changes made by the user to be overwritten
        // fail silently so that the user can still proceed with uploading their docs
        await handleRefreshPersonEntity();
        try {
          await PersonManagerWrite.UpdateMyPerson({
            context: authContext,
            person: formState.person,
          });
        } catch (e) {
          console.error("unable to start verification", e);
        }
      }
      await handleRefreshPersonEntity();
      await refreshMyPerson();
      refreshMyClient();
      enqueueSnackbar("Identity Verification Info Saved", {
        variant: "success",
      });
      setShowSumsubDialog(true);
    } catch (e) {
      console.error("unable to start verification", e);
      enqueueSnackbar(
        "Error! Your information could not be captured, please try again",
        {
          variant: "error",
        },
      );
    } finally {
      setApiLoading(false);
    }
  };

  // variables
  const { stepOneComplete, stepTwoComplete } = checkCompletedSteps({
    touchedFields,
    validationResult,
    formState,
  });
  const allStepsComplete = useMemo(
    () => stepOneComplete && stepTwoComplete,
    [stepOneComplete, stepTwoComplete],
  );
  const formDisabled = useMemo(
    () =>
      apiLoading ||
      myClientKYCStatus === ClientKYCStatus.VerifiedStatus ||
      myClientKYCStatus === ClientKYCStatus.VerificationInProgressStatus,
    [apiLoading, myClientKYCStatus],
  );

  const formReadonly = useMemo(
    () =>
      myClientKYCStatus === ClientKYCStatus.VerifiedStatus ||
      myClientKYCStatus === ClientKYCStatus.VerificationInProgressStatus,
    [myClientKYCStatus],
  );

  const {
    firstName,
    lastName,
    middleNames,
    dateOfBirth,
    identificationType,
    identificationNumber,
    sourceOfIncome,
    sourceOfWealth,
    influentialPerson,
  } = formState.person;

  const loadingInProgress = useMemo(
    () => !myClient || apiLoading,
    [myClient, apiLoading],
  );

  const {
    addressLine1,
    addressLine2,
    suburb,
    postalCode,
    countryCode,
    province,
    city,
  } = formState.person.residentialAddress;

  const { fieldValidations } = validationResult;
  //
  // render
  //

  const handleClose = () => {
    navigate("/market");
  };

  return (
    <>
      <Helmet>
        <title>Mesh | Verification</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Verify your identity so you can trade on Mesh"
        />
      </Helmet>
      <SumsubDialog
        open={showSumsubDialog}
        onClose={() => setShowSumsubDialog(false)}
        onComplete={() => navigate("/market")}
      />
      {
        <StyledDialog
          open
          fullScreen
          PaperProps={{
            "data-component-info": JSON.stringify({
              component_id: "kyc_verification",
              component_business_name: "kyc verification",
              component_title: "Let's get you verified",
              component_driver: InteractionDriver.kycSubmit,
            } as DataComponentInfo),
          }}
        >
          <KYCDialogTitle
            loading={apiLoading}
            validating={validationInProgress}
            onClose={handleClose}
            onSave={handleSave}
          />
          <DialogContent
            className={cx(classes.dialogContent, "meshScroll")}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {myClientKYCStatus ===
            ClientKYCStatus.VerificationInProgressStatus ? (
              <Stack
                direction={"column"}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  p: 3,
                  display: "flex",
                }}
              >
                <KYCVerificationInProgressPlaceHolder
                  isKYBVerificationInProgress={false}
                />
                <ApplicantVerificationStepsStatusTable />
              </Stack>
            ) : (
              <Grid
                container
                className={classes.fullHeight}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: isMobile ? "100%" : "80%",
                  p: isMobile ? 1 : 3,
                }}
              >
                <Grid
                  item
                  xs={12}
                  direction="column"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <KYCCaption client={new Client(myClient)} />
                  <HelpWidget />
                  <Stack
                    direction={"column"}
                    spacing={2}
                    justifyContent={"center"}
                  >
                    {/* Step 1 */}
                    <Accordion
                      sx={{
                        boxShadow: "none",
                      }}
                      disableGutters
                      defaultExpanded
                    >
                      <KYCDialogAccordianSummary
                        complete={stepOneComplete}
                        title={"Personal Details"}
                        step={1}
                        // dialogContentRef={accordionRef1}
                      />
                      <AccordionDetails className={classes.accordionDetails}>
                        <NonSAKYCCaption />
                        <Grid
                          container
                          spacing={isDesktop || isTabletLandScape ? 8 : 0}
                        >
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              id="kycDialog-firstName-textField"
                              label="First Name"
                              variant="outlined"
                              readOnly={formReadonly}
                              value={firstName}
                              onChange={(e) =>
                                formUpdater.firstName(e.target.value)
                              }
                              error={!!fieldValidations.firstName}
                              helperText={fieldValidations.firstName}
                            />
                            {!(
                              myClientKYCStatus ===
                                ClientKYCStatus.VerifiedStatus &&
                              middleNames === ""
                            ) && (
                              <TextField
                                id="kycDialog-middleName-textfield"
                                fullWidth
                                label="Middle Name(s)"
                                readOnly={formReadonly}
                                variant="outlined"
                                placeholder="Optional"
                                value={middleNames}
                                onChange={(e) =>
                                  formUpdater.middleNames(e.target.value)
                                }
                              />
                            )}
                            <TextField
                              fullWidth
                              id="kycDialog-lastName-textField"
                              label="Last Name"
                              variant="outlined"
                              readOnly={formReadonly}
                              value={lastName}
                              error={!!fieldValidations.lastName}
                              helperText={fieldValidations.lastName}
                              onChange={(e) =>
                                formUpdater.lastName(e.target.value)
                              }
                            />
                            <DateField
                              label="Date of Birth"
                              readOnly={formReadonly}
                              value={dateOfBirth}
                              onChange={(newValue) =>
                                formUpdater.dateOfBirth(
                                  newValue ? dayjs(newValue).format() : "",
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="kycDialog-dateOfBirth-dateOfBirth"
                                  sx={(theme) => ({
                                    paddingBottom: theme.spacing(1),
                                  })}
                                  fullWidth
                                  readOnly={formReadonly}
                                  helperText={fieldValidations.dateOfBirth}
                                  error={!!fieldValidations.dateOfBirth}
                                />
                              )}
                            />
                            <Autocomplete
                              id="kycDialog-sourceOfIncome-autocomplete"
                              options={ValidSourceOfIncomeTypes}
                              readOnly={formReadonly}
                              value={sourceOfIncome || ""}
                              isOptionEqualToValue={(option, value) => {
                                if (value === "") {
                                  return true;
                                }
                                return option === value;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  label="Source of Income"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Select..."
                                  readOnly={formReadonly}
                                  variant="outlined"
                                  fullWidth
                                  error={!!fieldValidations.sourceOfIncome}
                                  helperText={fieldValidations.sourceOfIncome}
                                />
                              )}
                              onChange={(_, value) => {
                                formUpdater.sourceOfIncome(
                                  value === null || value === undefined
                                    ? ""
                                    : (value as SourceOfIncomeAndWealthType),
                                );
                              }}
                            />
                            <Autocomplete
                              id="kycDialog-sourceOfWealth-autocomplete"
                              options={ValidSourceOfWealthTypes}
                              readOnly={formReadonly}
                              value={sourceOfWealth || ""}
                              isOptionEqualToValue={(option, value) => {
                                if (value === "") {
                                  return true;
                                }
                                return option === value;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  label="Source of Wealth"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Select..."
                                  readOnly={formReadonly}
                                  variant="outlined"
                                  fullWidth
                                  error={!!fieldValidations.sourceOfWealth}
                                  helperText={fieldValidations.sourceOfWealth}
                                />
                              )}
                              onChange={(_, value) => {
                                formUpdater.sourceOfWealth(
                                  value === null || value === undefined
                                    ? ""
                                    : (value as SourceOfIncomeAndWealthType),
                                );
                              }}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              id={"kycDialog-nationality-textfield"}
                              sx={{
                                marginTop: "3px",
                              }}
                              fullWidth
                              readOnly
                              label="Nationality"
                              error={!!fieldValidations.nationality}
                              value={countries[200].label}
                            />
                            <Autocomplete
                              id="kycDialog-identificationType-autocomplete"
                              options={[
                                IdentificationType.SouthAfricanIDIdentificationType,
                              ]}
                              readOnly={formReadonly}
                              value={identificationType || ""}
                              isOptionEqualToValue={(option, value) => {
                                if (value === "") {
                                  return true;
                                }
                                return option === value;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  label="Identification Type"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Select..."
                                  readOnly={formReadonly}
                                  variant="outlined"
                                  fullWidth
                                  error={!!fieldValidations.identificationType}
                                  helperText={
                                    fieldValidations.identificationType
                                  }
                                />
                              )}
                              onChange={(_, value) => {
                                formUpdater.identificationType(
                                  value === null
                                    ? ""
                                    : (value as IdentificationType),
                                );
                              }}
                            />
                            <TextField
                              id="kycDialog-identificationNumber-textfield"
                              fullWidth
                              label={"Identification Number"}
                              variant="outlined"
                              value={identificationNumber}
                              readOnly={formReadonly}
                              error={!!fieldValidations.identificationNumber}
                              helperText={fieldValidations.identificationNumber}
                              onChange={(e) => {
                                formUpdater.identificationNumber(
                                  e.target.value,
                                );
                              }}
                            />
                            {/* Pep Declaration */}
                            <Box
                              sx={(theme) => ({
                                color: theme.palette.text.disabled,
                                pt: 3,
                                fontSize: "12px",
                                lineHeight: "18px",
                                "& > p": {
                                  display: "flex",
                                  alignItems: "center",
                                },
                              })}
                            >
                              <Typography
                                sx={(theme) => ({
                                  mb: 1,
                                  color: theme.palette.text.primary,
                                })}
                              >
                                Are you a:
                              </Typography>
                              <Typography>
                                - Domestic Prominent Influential Person (DPIP)
                                <Tooltip
                                  placement="top"
                                  title="Domestic Prominent Influential Person, an individual who holds a prominent public function in the Republic."
                                >
                                  <Info sx={{ width: 20, ml: 1 }} />
                                </Tooltip>
                              </Typography>
                              <Typography>
                                - Foreign Prominent Public Official (FPPO)
                                <Tooltip
                                  placement="top"
                                  title="Foreign Prominent Public Official, an individual who holds a prominent public function outside the borders of South Africa, which includes: Head of State, head of a country or government or member of a royal family."
                                >
                                  <Info sx={{ width: 20, ml: 1 }} />
                                </Tooltip>
                              </Typography>
                              <Typography>
                                or related to one of the above
                                <Tooltip
                                  placement="top"
                                  title="An individual who is an immediate family member or known close associate of a Domestic Prominent Influential Person or a Foreign Prominent Public Official."
                                >
                                  <Info sx={{ width: 20, ml: 1 }} />
                                </Tooltip>
                              </Typography>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto",
                                  gap: 2,
                                  alignItems: "center",
                                  width: "100%",
                                  mt: 2,
                                }}
                              >
                                <Autocomplete
                                  options={[
                                    InfluentialPersonStatus.InfluentialPersonStatus,
                                    InfluentialPersonStatus.NotInfluentialPersonStatus,
                                  ]}
                                  readOnly={formReadonly}
                                  value={influentialPerson || ""}
                                  onChange={(_, val) =>
                                    formUpdater.influentialPerson(
                                      val
                                        ? (val as InfluentialPersonStatus)
                                        : "",
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status Declaration"
                                      fullWidth
                                      margin="dense"
                                      placeholder="Select..."
                                      InputLabelProps={{ shrink: true }}
                                      variant="outlined"
                                      error={
                                        !!fieldValidations.influentialPerson
                                      }
                                      helperText={
                                        !!fieldValidations.pepDeclarationStatus
                                      }
                                    />
                                  )}
                                  isOptionEqualToValue={(option, value) => {
                                    if (value === "") {
                                      return true;
                                    }
                                    return (
                                      option ===
                                      (value as InfluentialPersonStatus)
                                    );
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "12px" }}>
                                    Need more clarification?
                                  </Typography>
                                  <Link
                                    underline="none"
                                    target="_blank"
                                    href="https://support.mesh.trade/support/solutions/articles/80001089809-what-is-a-prominent-influential-person-"
                                  >
                                    View our FAQ article
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {/* Step 2 */}
                    <Accordion
                      sx={{
                        boxShadow: "none",
                      }}
                      disableGutters
                      defaultExpanded
                    >
                      <KYCDialogAccordianSummary
                        complete={stepTwoComplete}
                        title={"Residential Address"}
                        step={2}
                        // dialogContentRef={accordionRef2}
                      />
                      <AccordionDetails className={classes.accordionDetails}>
                        <Grid
                          sx={(theme) => ({
                            paddingTop: theme.spacing(2),
                          })}
                          container
                          spacing={isDesktop || isTabletLandScape ? 8 : 0}
                        >
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              id="kycDialog-addressLine1-textfield"
                              label="Address 1"
                              variant="outlined"
                              readOnly={formReadonly}
                              value={addressLine1}
                              error={!!fieldValidations.addressLine1}
                              helperText={fieldValidations.addressLine1}
                              onChange={(e) => {
                                formUpdater.addressLine1(e.target.value);
                              }}
                            />
                            {!(
                              myClientKYCStatus ===
                                ClientKYCStatus.VerifiedStatus &&
                              addressLine2 === ""
                            ) && (
                              <TextField
                                fullWidth
                                label="Address 2"
                                id="kycDialog-addressline2-textfield"
                                variant="outlined"
                                placeholder="Optional"
                                readOnly={formReadonly}
                                value={addressLine2}
                                onChange={(e) => {
                                  formUpdater.addressLine2(e.target.value);
                                }}
                              />
                            )}
                            {!(
                              myClientKYCStatus ===
                                ClientKYCStatus.VerifiedStatus && suburb === ""
                            ) && (
                              <TextField
                                fullWidth
                                label="Suburb"
                                id="kycDialog-suburbs-textfield"
                                variant="outlined"
                                placeholder="Optional"
                                readOnly={formReadonly}
                                value={suburb}
                                onChange={(e) => {
                                  formUpdater.suburb(e.target.value);
                                }}
                              />
                            )}
                            <TextField
                              fullWidth
                              label="Postal Code"
                              id="kycDialog-postalCode-textfield"
                              variant="outlined"
                              readOnly={formReadonly}
                              value={postalCode}
                              error={!!fieldValidations.postalCode}
                              helperText={fieldValidations.postalCode}
                              onChange={(e) => {
                                formUpdater.postalCode(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="City"
                              id="kycDialog-city-textfield"
                              variant="outlined"
                              readOnly={formReadonly}
                              value={city}
                              error={!!fieldValidations.city}
                              helperText={fieldValidations.city}
                              onChange={(e) => {
                                formUpdater.city(e.target.value);
                              }}
                            />
                            <TextField
                              fullWidth
                              label="Province"
                              id="kycDialog-province-textfield"
                              variant="outlined"
                              readOnly={formReadonly}
                              value={province}
                              error={!!fieldValidations.province}
                              helperText={fieldValidations.province}
                              onChange={(e) => {
                                formUpdater.province(e.target.value);
                              }}
                            />
                            <Autocomplete
                              clearIcon={
                                <ClearIcon
                                  id="kycDialog-country-autocomplete-clearIcon"
                                  fontSize={"small"}
                                />
                              }
                              id="kycDialog-country-autocomplete"
                              options={countries}
                              readOnly={formReadonly}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => {
                                if (value.value === "") {
                                  return true;
                                }
                                return option === value;
                              }}
                              value={
                                countryCode === ""
                                  ? {
                                      value: "",
                                      label: "",
                                    }
                                  : {
                                      value: countryCode,
                                      label: countries.filter(
                                        (c) => c.value === countryCode,
                                      )[0].label,
                                    }
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": {
                                      mr: 2,
                                      flexShrink: 0,
                                    },
                                  }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  label="Country"
                                  placeholder="Select..."
                                  InputLabelProps={{ shrink: true }}
                                  id="kycDialog-country-textfield"
                                  variant="outlined"
                                  readOnly={formReadonly}
                                  error={!!fieldValidations.countryCode}
                                  helperText={fieldValidations.countryCode}
                                  fullWidth
                                />
                              )}
                              onChange={(_, value) => {
                                formUpdater.countryCode(value?.value || "");
                              }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {/* Step 3 */}

                    <Accordion
                      sx={{
                        boxShadow: "none",
                      }}
                      disableGutters
                      defaultExpanded
                    >
                      <KYCDialogAccordianSummary
                        title={"Documentation & Liveness Check"}
                        step={3}
                      />
                      <AccordionDetails
                        className={classes.accordionDetails}
                        sx={{ mb: 20 }}
                      >
                        <Box
                          sx={(theme) => ({
                            padding: theme.spacing(2, 0, 2, 0),
                          })}
                        >
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.text.secondary,
                              paddingTop: theme.spacing(1),
                            })}
                            variant="body1"
                          >
                            Before continuing, please ensure that you have your
                            ID or residence permit, as well as a{" "}
                            <Typography
                              component={Link}
                              target="_blank"
                              underline={"none"}
                              sx={(theme) => ({
                                color: theme.palette.secondary.main,
                                display: "inline-block",
                              })}
                              href={
                                "https://support.mesh.trade/support/solutions/articles/80000862513-what-documents-can-be-used-for-physical-address-verification-"
                              }
                            >
                              valid proof of address
                            </Typography>{" "}
                            handy.
                          </Typography>
                        </Box>
                        {!isMobile && (
                          <Tooltip
                            placement={"right-end"}
                            title={
                              stepOneComplete && stepTwoComplete
                                ? ""
                                : "Please complete the previous steps before submitting your documents"
                            }
                          >
                            <Box sx={{ width: "200px" }}>
                              <Button
                                sx={{
                                  width: "250px",
                                }}
                                onClick={handleNext}
                                data-link-info={JSON.stringify({
                                  content_interaction_id:
                                    "kyc-verification-link",
                                  content_interaction_action:
                                    InteractionAction.Click,
                                  content_interaction_text: "submit",
                                  content_interaction_type:
                                    InteractionType.Button,
                                  content_interaction_driver:
                                    InteractionDriver.kycComplete,
                                } as DataLinkInfoType)}
                                id="KYCDialog-submit-button"
                                variant="contained"
                                disabled={
                                  validationInProgress ||
                                  formDisabled ||
                                  !allStepsComplete ||
                                  loadingInProgress
                                }
                                color="primary"
                              >
                                Submit Docs & Liveliness Checks
                              </Button>
                            </Box>
                          </Tooltip>
                        )}

                        {stepOneComplete && stepTwoComplete && (
                          <ApplicantVerificationStepsStatusTable />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          {isMobile && (
            <>
              <Box
                sx={(theme) => ({
                  zIndex: 1,
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  border: "none",
                  boxShadow: "-5px 1px 10px #000000",
                  borderRadius: "8px 8px 0 0",
                  backgroundColor: theme.palette.custom.cardInner,
                })}
              >
                <Grid
                  sx={(theme) => ({
                    padding: theme.spacing(3, 0, 4, 0),
                    justifyContent: "center",
                  })}
                  container
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      sx={{
                        height: "55px",
                        width: "170px",
                      }}
                      data-link-info={JSON.stringify({
                        content_interaction_id: "kyc-verification-link",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: "save",
                        content_interaction_type: InteractionType.Button,
                        content_interaction_driver: InteractionDriver.kycSave,
                      } as DataLinkInfoType)}
                      disabled={validationInProgress || formDisabled}
                      onClick={() => handleSave()}
                      id="KYCDialog-save-button"
                      variant="outlined"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        height: "55px",
                        width: "170px",
                      }}
                      onClick={handleNext}
                      data-link-info={JSON.stringify({
                        content_interaction_id: "kyc-verification-link",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: "submit",
                        content_interaction_type: InteractionType.Button,
                        content_interaction_driver:
                          InteractionDriver.kycComplete,
                      } as DataLinkInfoType)}
                      id="KYCDialog-submit-button"
                      variant="contained"
                      disabled={
                        validationInProgress ||
                        formDisabled ||
                        !allStepsComplete ||
                        loadingInProgress
                      }
                      color="primary"
                    >
                      Submit Docs & Liveliness Checks
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <PEPInfoDrawer
                open={showMobilePEPInfo}
                setShowMobilePEPInfo={setShowMobilePEPInfo}
              />
            </>
          )}
        </StyledDialog>
      }
    </>
  );
}

interface KYCDialogTitleProps {
  loading?: boolean;
  validating?: boolean;
  onSave?: VoidFunction;
  onClose: VoidFunction;
}

function KYCDialogTitle({
  loading,
  validating,
  onSave,
  onClose,
}: KYCDialogTitleProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { myClientKYCStatus, myClient } = useApplicationContext();

  const loadingInProgress = useMemo(
    () => !myClient || loading,
    [myClient, loading],
  );

  const formDisabled = useMemo(
    () =>
      loading ||
      myClientKYCStatus === ClientKYCStatus.VerifiedStatus ||
      myClientKYCStatus === ClientKYCStatus.VerificationInProgressStatus,
    [loading, myClientKYCStatus],
  );

  return (
    <DialogTitle
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item className={classes.mainHeading}>
          <div className={classes.miniLogoWrapper}>
            <img alt="" width="100%" src={meshMiniLogo} />
          </div>
          <Typography
            sx={(theme) => ({
              padding: theme.spacing(0, 1),
            })}
            variant="h5"
            children="Verification"
          />
        </Grid>
        <Grid item>
          <Chip
            size="small"
            className={cx(
              {
                [classes.verifiedChipColor]:
                  myClientKYCStatus === ClientKYCStatus.VerifiedStatus,
              },
              {
                [classes.inProgressChipColor]:
                  myClientKYCStatus ===
                  ClientKYCStatus.VerificationInProgressStatus,
              },
              {
                [classes.outStandingChipColor]:
                  myClientKYCStatus === ClientKYCStatus.Outstanding &&
                  new Client(myClient).kycStatusReason === "",
              },
              {
                [classes.inProgressChipColor]:
                  myClientKYCStatus === ClientKYCStatus.Outstanding &&
                  new Client(myClient).kycStatusReason !== "",
              },
            )}
            label={myClientKYCStatus}
          />
        </Grid>
        {loading && (
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" spacing={2} alignItems="center">
        {onSave && !isMobile && (
          <Grid item>
            <Button
              disabled={validating || formDisabled}
              data-link-info={JSON.stringify({
                content_interaction_id: "kyc-verification-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "save",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.kycSave,
              } as DataLinkInfoType)}
              onClick={onSave}
              id="KYCDialog-save-button"
              variant="outlined"
            >
              Save
            </Button>
          </Grid>
        )}
        <Grid item>
          <Tooltip title="Close" placement="top">
            <span>
              <IconButton
                id="KYCDialog-close-button"
                size="small"
                onClick={onClose}
                disabled={loadingInProgress}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}

interface KYCDialogAccordianSummaryProps {
  complete?: boolean;
  title: string;
  step: number;
  // dialogContentRef: RefObject<HTMLDivElement>;
}

function KYCDialogAccordianSummary({
  complete,
  title,
  step,
  // dialogContentRef,
}: KYCDialogAccordianSummaryProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  // const handleExpandMobileAccordion =
  //   (ref: MutableRefObject<HTMLDivElement | null>) => () => {
  //     if (dialogContentRef.current && ref.current) {
  //       const top = ref.current.offsetTop - dialogContentRef.current.offsetTop;
  //       setTimeout(() => {
  //         dialogContentRef.current?.scrollTo({ top, behavior: "smooth" });
  //       }, 250);
  //     }
  //   };

  return (
    <AccordionSummary
      sx={(theme) => ({
        flexDirection: "row-reverse",
        backgroundColor: theme.palette.custom.midnight,
        padding: theme.spacing(0, 3, 0, 3),
        "&.MuiAccordionSummary-expandIconWrapper:before": {
          placement: "flex-start",
        },
        borderRadius: 2,
      })}
      expandIcon={<ExpandMoreIcon color="primary" />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      // onClick={handleExpandMobileAccordion(dialogContentRef)}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          paddingLeft: theme.spacing(3),
          justifyContent: "space-between",
          width: "100%",
        })}
      >
        <Typography>{title}</Typography>
        <Box
          sx={{
            display: "flex",
            direction: "row",
          }}
        >
          <Typography
            color="textSecondary"
            sx={{ pr: 1 }}
          >{`Step ${step}`}</Typography>
          {complete !== undefined && (
            <Typography
              className={cx(
                { [classes.successColor]: complete },
                { [classes.warningColor]: !complete },
              )}
            >
              {complete ? "Complete" : "Not Complete"}
            </Typography>
          )}
        </Box>
      </Box>
    </AccordionSummary>
  );
}

interface PEPInfoDrawerProps {
  setShowMobilePEPInfo: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

const PEPInfoDrawer = ({ setShowMobilePEPInfo, open }: PEPInfoDrawerProps) => {
  return (
    <Drawer
      sx={(theme) => ({
        zIndex: 1301,
        position: "absolute",
        bottom: 0,
        width: "100%",
        border: "none",
        boxShadow: "-5px 1px 10px #000000",
        borderRadius: "10px 10px 0 0",
        backgroundColor: theme.palette.custom.cardInner,
      })}
      open={open}
      anchor="bottom"
    >
      <Card
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.cardInner,
        })}
      >
        <CardHeader
          sx={{ pl: 3 }}
          title="Status Declaration"
          action={
            <IconButton onClick={() => setShowMobilePEPInfo(false)}>
              <Close />
            </IconButton>
          }
        />
        <CardContent
          sx={(theme) => ({
            p: 3,
            "& > h4": {
              color: theme.palette.text.primary,
              mb: 0.5,
              fontSize: 14,
              fontWeight: theme.typography.fontWeightMedium,
              letterSpacing: 0,
            },
            "& > p": {
              color: theme.palette.text.secondary,
              fontSize: 12,
              mb: 2,
            },
          })}
        >
          <Typography variant="h4">
            Domestic Prominent Influential Person (DPIP).
          </Typography>
          <Typography variant="body1">
            Domestic Prominent Influential Person, an individual who holds a
            prominent public function in South Africa.
          </Typography>

          <Typography variant="h4">
            Foreign Prominent Public Official (FPPO).
          </Typography>
          <Typography variant="body1">
            Foreign Prominent Public Official, an individual who holds a
            prominent public function outside the borders of South Africa, which
            includes: Head of State, head of a country or government or member
            of a royal family.
          </Typography>

          <Typography variant="h4">or related to one of the above.</Typography>
          <Typography variant="body1">
            An individual who is an immediate family member or known close
            associate of a Domestic Prominent Influential Person or a Foreign
            Prominent Public Official.
          </Typography>
        </CardContent>
      </Card>
    </Drawer>
  );
};
