import { DataSheetSection } from "james/marketData/DataSheetSection";
import { Amount } from "james/ledger";

export const AssetSubscriptionOverviewDataSheetSectionTypeName =
  "mesh::marketData/AssetSubscriptionOverview";

export const AssetSubscriptionOverviewDataSheetSectionName =
  "Subscription Overview";

export class AssetSubscriptionOverviewDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = AssetSubscriptionOverviewDataSheetSectionTypeName;

  /**
   * SubscriptionAmount is the target subscription amount.
   * i.e. the amount that the issuer would like to raise.
   * @type {Amount}
   */
  public subscriptionAmount: Amount = new Amount();

  /**
   * OverSubscriptionAmount is the total possible amount that can be subscribed.
   * i.e. it is >= SubscriptionAmount.
   * @type {Amount}
   */
  public overSubscriptionAmount: Amount = new Amount();

  /**
   * SubscriptionAmount is the target subscription amount.
   * i.e. the amount that the issuer would like to raise.
   * @type {Amount}
   */
  public committedAmount: Amount = new Amount();

  /**
   * UnderwriterCommittmentAmount is the amount of the SubscriptionAmount that is committed to be
   * fullfilled by underwriters.
   * @type {Amount}
   */
  public underwriterCommittmentAmount: Amount = new Amount();

  /**
   * SubscribedAmount is the actual amount that has been subscribed so far.
   * i.e. the sum of all pending subscription orders.
   * @type {Amount}
   */
  public subscribedAmount: Amount = new Amount();

  constructor(l?: AssetSubscriptionOverviewDataSheetSection) {
    if (!l) {
      return;
    }
    this.subscriptionAmount = new Amount(l.subscriptionAmount);
    this.overSubscriptionAmount = new Amount(l.overSubscriptionAmount);
    this.committedAmount = new Amount(l.committedAmount);
    this.underwriterCommittmentAmount = new Amount(
      l.underwriterCommittmentAmount,
    );
    this.subscribedAmount = new Amount(l.subscribedAmount);
  }

  public DataSheetSectionName(): string {
    return AssetSubscriptionOverviewDataSheetSectionName;
  }
}
