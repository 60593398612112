import React, { useContext, useRef, useState } from "react";
import { Permission } from "james/security";
import {
  clientRequestOpts,
  JSONRPCRequestProps,
  MiddleWare,
} from "utilities/network/jsonRPCRequest/jsonRPCRequestWithMiddleware";
import { useApplicationContext } from "context/Application/Application";
import { MUADialog } from "context/MUA/MUADialog";
import { Authorizer } from "james/authorization/authorizer";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

interface ContextType {
  middleWare: MiddleWare;
}

// MUA context state
const MUAState = {
  None: 0,
  Verification: 1,
} as const;

const Context = React.createContext({} as ContextType);

export function MUAContext({ children }: { children?: React.ReactNode }) {
  const { myClient, authContext } = useApplicationContext();

  const [muaState, setMUAState] = useState<number>();

  const onVerifiedRef = useRef<(code: string) => void>(() => null);
  const onCancelRef = useRef<() => void>(() => null);
  const { enqueueSnackbar } = useSnackbar();

  const isMUARequired = (permissions: Permission[]): boolean => {
    const missingPermissions = myClient?.settings.muaSettings.enabledFor.filter(
      (x) => !permissions.includes(x),
    );

    return (missingPermissions || []).length !== 0;
  };

  const performMUAIfRequired = async (
    request: JSONRPCRequestProps,
    permissions: Permission[],
    requestOpts?: clientRequestOpts,
  ): Promise<string> => {
    // check if required
    if (!isMUARequired(permissions)) {
      return Promise.resolve("");
    }

    enqueueSnackbar(
      "multi user authorisation required, sending request to authorised user",
      {
        variant: "info",
      },
    );
    if (requestOpts?.onMiddlewarePreStarted) {
      requestOpts?.onMiddlewarePreStarted();
    }

    try {
      // request MUA from authorising user
      await Authorizer.RequestMUA({
        context: authContext,
        requestRaw: JSON.stringify(request),
        serviceName: permissions[0].serviceName,
        serviceProvider: permissions[0].serviceProvider,
        requestId: request.request.MUAID,
      });
    } catch (e) {
      console.error("failed to request MUA", e);
      Sentry.captureException(`failed to request MUA: ${e}`);
      enqueueSnackbar("failed to request MUA", {
        variant: "error",
        persist: true,
      });

      setMUAState(MUAState.None);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new Promise<string>((resolve: any, reject: any) => {
        reject("mfa failed");
      });
    }

    enqueueSnackbar(
      "multi user authorisation requested, please fill in the code from the authorised user",
      {
        variant: "info",
      },
    );
    if (requestOpts?.onMiddlewarePreFinished) {
      requestOpts?.onMiddlewarePreFinished();
    }

    setMUAState(MUAState.Verification);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<string>((resolve: any, reject: any) => {
      onVerifiedRef.current = (code: string) => {
        setMUAState(MUAState.None);
        resolve(code);
      };

      onCancelRef.current = () => {
        setMUAState(MUAState.None);
        reject("mfa failed");
      };
    });
  };

  const MFAMiddleware: MiddleWare = {
    pre: async (request, opts?, clientRequestOpts?) => {
      const methodSplit = request.method.split(".");
      if (methodSplit.length !== 2) {
        throw new Error("invalid method");
      }

      // add MUAID to request
      request.request.MUAID = Math.random().toString(36).substring(7);
      request.request["@type"] = "MUARequest";

      // perform MUA if required (this will request the MUA and show the MUA dialog)
      const code = await performMUAIfRequired(request, [
        {
          serviceName: methodSplit[1],
          serviceProvider: methodSplit[0],
          description: "-",
        },
      ]);

      if (opts) {
        // add code to the header and send with request
        if (opts?.additionalHeaders) {
          opts.additionalHeaders.set("MESH-X-MUA", code);
        } else {
          opts.additionalHeaders = new Headers({
            "MESH-X-MUA": code,
          });
        }
      } else {
        opts = {
          additionalHeaders: new Headers({
            "MESH-X-MUA": code,
          }),
        };
      }

      if (clientRequestOpts?.onMiddlewarePreFinished) {
        clientRequestOpts?.onMiddlewarePreFinished();
      }

      return {
        JSONRPCRequestProps: request,
        JSONRPCRequestOpts: opts,
      };
    },

    post: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response: any,
      headers,
    ): Promise<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response: any;
      headers?: Headers;
    }> => {
      return Promise.resolve({ response, headers });
    },

    onError: async () => {
      return Promise.resolve();
    },
  };

  return (
    <Context.Provider
      value={{
        middleWare: MFAMiddleware,
      }}
    >
      <MUADialog
        open={muaState === MUAState.Verification}
        // onClose={() => setMUAState(MUAState.None)}
        onSubmit={onVerifiedRef.current}
        onCancel={onCancelRef.current}
      />
      {children}
    </Context.Provider>
  );
}

const useMUAContext = () => useContext(Context);
export { useMUAContext };
