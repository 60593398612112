import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { VerifyEmailCard } from "views/SignUp/components/VerifyEmailOTP/VerifyEmailCard";
import {
  UserEmailVerifier,
  VerificationEmailMailerServiceProviderName,
} from "james/onboarding/UserEmailVerifier";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTimer } from "hooks/useTimer";
import { useFirebaseContext } from "context/Firebase";
import { useNavigate } from "react-router-dom";
import { useErrorContext } from "context/Error";
import {
  CompanyRegisteredName,
  InitiateCompanyAdminRegistration,
  InitiateUserRegistration,
} from "../../const";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import { BuildQueryKey } from "utilities/reactQuery";
import { UserRegisteringLoader } from "components/Loaders";
import { useUserRegistrationHelper } from "hooks/helperAPI/useUserRegistrationHelper";
import background from "assets/images/background/background.png";

export function VerifyEmailOTP() {
  const width = useWidth();
  const { enqueueSnackbar } = useSnackbar();
  const { firebaseUserIDToken } = useFirebaseContext();
  const {
    registerIndividualUser,
    individualUserRegistrationInProgress,
    companyUserRegistrationInProgress,
    registerCompanyUser,
  } = useUserRegistrationHelper();
  const [confirmationCode, setConfirmationCode] = useState("");
  const isMobile = isWidthDown("sm", width);
  const { timerValue, timerProgress, timerCompleted, startTimer, stopTimer } =
    useTimer();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { firebaseUser, firebaseLogout } = useFirebaseContext();
  const [totpResendLoading, setTotpResendLoading] = useState(false);
  const reactQueryClient = useQueryClient();
  const [enableSendOTPQuery, setEnableOTPQuery] = useState(true);
  const navigate = useNavigate();

  const handleOnOTPResend = useCallback(async () => {
    setTotpResendLoading(true);
    try {
      enqueueSnackbar("Sending OTP Email", {
        variant: "info",
      });
      if (localStorage.getItem(InitiateCompanyAdminRegistration)) {
        await UserEmailVerifier.SendCompanyVerificationMail({
          firebaseToken: firebaseUserIDToken,
        });
        startTimer(120);
        enqueueSnackbar("Email Sent", {
          variant: "success",
        });
      } else {
        await UserEmailVerifier.SendIndividualVerificationMail({
          firebaseToken: firebaseUserIDToken,
        });
        startTimer(120);
        enqueueSnackbar("Email Sent", {
          variant: "success",
        });
      }
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
      // logout the user from firebase and redirect to /sign-in
      firebaseLogout().then(() => navigate("/sign-in"));
    } finally {
      setTotpResendLoading(false);
    }
  }, [firebaseUserIDToken]);

  useQuery(
    BuildQueryKey(
      localStorage.getItem(InitiateCompanyAdminRegistration)
        ? `${VerificationEmailMailerServiceProviderName}":"SendCompanyVerificationMail"`
        : `${VerificationEmailMailerServiceProviderName}":"SendIndividualVerificationMail"`,
    ),
    async () => {
      setTotpResendLoading(true);
      enqueueSnackbar("Sending OTP Email", {
        variant: "info",
      });

      if (localStorage.getItem(InitiateCompanyAdminRegistration)) {
        return await UserEmailVerifier.SendCompanyVerificationMail({
          firebaseToken: firebaseUserIDToken,
        });
      } else {
        return await UserEmailVerifier.SendIndividualVerificationMail({
          firebaseToken: firebaseUserIDToken,
        });
      }
    },
    {
      enabled: !!firebaseUserIDToken && enableSendOTPQuery,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      onError: (e) => {
        errorContextDefaultErrorFeedback(e);

        setTotpResendLoading(false);

        // logout the user from firebase and redirect to /sign-in
        firebaseLogout().then(() => navigate("/sign-in"));
      },
      onSuccess: () => {
        enqueueSnackbar("Email Sent", {
          variant: "success",
        });
        startTimer(120);
        setTotpResendLoading(false);
        // disable query and remove it from cache
        setEnableOTPQuery(false);

        if (localStorage.getItem(InitiateCompanyAdminRegistration)) {
          reactQueryClient.removeQueries(
            BuildQueryKey(
              `${VerificationEmailMailerServiceProviderName}":"SendCompanyVerificationMail"`,
            ),
          );
        } else {
          reactQueryClient.removeQueries(
            BuildQueryKey(
              `${VerificationEmailMailerServiceProviderName}":"SendIndividualVerificationMail"`,
            ),
          );
        }
      },
    },
  );

  const handleOnVerifyEmail = useCallback(async () => {
    enqueueSnackbar("Verifying OTP", {
      variant: "info",
    });
    try {
      await UserEmailVerifier.VerifyEmailTOTP({
        firebaseToken: firebaseUserIDToken,
        totp: confirmationCode,
      });
      enqueueSnackbar("Email Verified", {
        variant: "success",
      });
      stopTimer();
      await firebaseUser?.reload();
      if (localStorage.getItem(InitiateCompanyAdminRegistration)) {
        const companyName = localStorage.getItem(CompanyRegisteredName);
        if (!companyName) {
          return;
        }
        await registerCompanyUser(firebaseUserIDToken, companyName);
        localStorage.removeItem(InitiateCompanyAdminRegistration);
      } else {
        await registerIndividualUser(firebaseUserIDToken);
        localStorage.removeItem(InitiateUserRegistration);
      }
      navigate("/");
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
    }
  }, [firebaseUserIDToken, confirmationCode]);

  const setOTPValues = (): [number, number, boolean] => {
    return [timerProgress, timerValue, timerCompleted];
  };

  if (
    individualUserRegistrationInProgress ||
    companyUserRegistrationInProgress
  ) {
    return <UserRegisteringLoader />;
  }

  if (isMobile) {
    return (
      <VerifyEmailCard
        handleOnOTPResend={handleOnOTPResend}
        handleOnVerifyEmail={handleOnVerifyEmail}
        totpResendLoading={totpResendLoading}
        confirmationCode={confirmationCode}
        setConfirmationCode={setConfirmationCode}
        setOTPValues={setOTPValues}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        backgroundImage: `url(${background})`,
        gridTemplateRows: "auto 1fr",
        backgroundSize: "cover",
        height: "100vh",
        overflowY: isWidthUp("md", width) ? "hidden" : "auto",
        width: "100%",
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: 230,
        }}
        alt=""
        src={meshMiniLogo}
      />
      <Box
        sx={{
          minWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "24px",
        }}
      >
        <VerifyEmailCard
          handleOnOTPResend={handleOnOTPResend}
          handleOnVerifyEmail={handleOnVerifyEmail}
          totpResendLoading={totpResendLoading}
          confirmationCode={confirmationCode}
          setConfirmationCode={setConfirmationCode}
          setOTPValues={setOTPValues}
        />
      </Box>
    </Box>
  );
}
