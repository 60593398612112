import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Badge,
  Button,
  CircularProgress,
  Hidden,
  Theme,
  Link,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteType } from "routes/Route";
import { formatTextNum } from "utilities/number";
import Tooltip from "@mui/material/Tooltip";
import { ClientKYCStatus } from "james/client";
import { useNotificationContext } from "context/Notification";
import { useIsMounted } from "hooks";
import {
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
  Reader as MarketDirectOrderViewReader,
  useRead,
} from "james/views/marketDirectOrderView";
import {
  Determiner,
  DetermineScopeCriteriaByRolesResponse,
  ScopeFields,
} from "james/search/scope/Determiner";
import { Permission } from "james/security";
import {
  TextListCriterion,
  TextListCriterionTypeName,
} from "james/search/criterion";
import { TextListCriterionType } from "james/search/criterion/text/List";
import { GroupNotificationChannel } from "james/group";
import { Query } from "james/search/query";
import { DirectOrderState } from "james/market/DirectOrder";
import { useApplicationContext } from "context/Application/Application";
import { useAppTourContext } from "context/AppTour/AppTour";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { RibbonContainer } from "./components/RibbonContainer";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";
import { useErrorContext } from "context/Error";
import { ClientKind } from "james/client/Client";

const PREFIX = "Ribbon";

const classes = {
  appBarRoot: `${PREFIX}-appBarRoot`,
  toolbarRoot: `${PREFIX}-toolbarRoot`,
  toolbarRegular: `${PREFIX}-toolbarRegular`,
  leftSection: `${PREFIX}-leftSection`,
  rightSection: `${PREFIX}-rightSection`,
  fundButton: `${PREFIX}-fundButton`,
  tabIndicator: `${PREFIX}-tabIndicator`,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`& .${classes.leftSection}`]: {
    display: "grid",
    gridAutoFlow: "column",
    columnGap: theme.spacing(2),
  },

  [`& .${classes.rightSection}`]: {
    display: "grid",
    gridAutoFlow: "column",
    alignItems: "center",
  },

  [`& .${classes.fundButton}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.tabIndicator}`]: {
    backgroundColor: theme.palette.text.primary,
  },
}));

type RibbonProps = {
  currencyPrefix?: string;
  balance: string;
  routes: RouteType[];
  modelID: string;
};

export const Ribbon = (props: RibbonProps) => {
  const hideFundButton = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  const navigate = useNavigate();
  const [displayFundDialog, setDisplayFundDialog] = useState<boolean>(false);
  const { viewConfiguration, myClientKYCStatus, myClient } =
    useApplicationContext();
  const { currencyPrefix = "mZAR", balance, routes } = props;
  const location = useLocation();
  const { registerElement } = useAppTourContext();

  // determine the active path each time the component renders
  const currentRoute = routes.find((r) => location.pathname.includes(r.path));

  // set only the active path if the route is not wallet
  let activePath = props.routes.length ? props.routes[0].path : "";
  if (currentRoute && currentRoute.id !== "marketplace-wallet") {
    activePath = currentRoute.path;
  }
  return (
    <RibbonContainer>
      <StyledToolbar
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.breakpoints.values.lg - 32,
          display: "grid",
          gridTemplateColumns: "1fr auto",
          paddingRight: theme.spacing(2),
        })}
        disableGutters
      >
        <div className={classes.leftSection}>
          <Tabs
            ref={registerElement("marketPlaceRibbon-tabs")}
            value={activePath}
            textColor={"inherit"}
            onChange={(_, value) => {
              if (activePath === value) {
                return;
              }
              navigate(value);
            }}
            TabIndicatorProps={{
              className: classes.tabIndicator,
            }}
          >
            {(() => {
              const tabs: JSX.Element[] = [];
              props.routes.forEach((route, i) => {
                // add badge to orders tab
                if (route.id === "marketplace-orders") {
                  tabs.push(
                    <Tab
                      ref={registerElement(
                        `marketPlaceRibbon-${route.name}-tab`,
                      )}
                      id={`marketPlaceRibbon-${route.name}-tab`}
                      key={i}
                      value={route.path}
                      onClick={() => navigate(route.path)}
                      data-link-info={JSON.stringify({
                        content_interaction_id: "nav-link",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: "orders",
                        content_interaction_type: InteractionType.Link,
                        content_interaction_driver:
                          InteractionDriver.Navigation,
                      } as DataLinkInfoType)}
                      label={
                        <AwaitingConfirmationCountBadge
                          data-link-info={JSON.stringify({
                            content_interaction_id: "nav-link",
                            content_interaction_action: InteractionAction.Click,
                            content_interaction_text: "orders",
                            content_interaction_type: InteractionType.Link,
                            content_interaction_driver:
                              InteractionDriver.Navigation,
                          } as DataLinkInfoType)}
                        >
                          <Typography
                            data-link-info={JSON.stringify({
                              content_interaction_id: "nav-link",
                              content_interaction_action:
                                InteractionAction.Click,
                              content_interaction_text: "orders",
                              content_interaction_type: InteractionType.Link,
                              content_interaction_driver:
                                InteractionDriver.Navigation,
                            } as DataLinkInfoType)}
                            sx={{
                              fontWeight: 600,
                            }}
                          >
                            {route.name}
                          </Typography>
                        </AwaitingConfirmationCountBadge>
                      }
                    />,
                  );
                } else {
                  tabs.push(
                    <Tab
                      ref={registerElement(
                        `marketPlaceRibbon-${route.name}-tab`,
                      )}
                      id={`marketPlaceRibbon-${route.name}-tab`}
                      key={i}
                      value={route.path}
                      onClick={() => navigate(route.path)}
                      sx={{
                        fontWeight: 600,
                      }}
                      label={route.name}
                      data-link-info={JSON.stringify({
                        content_interaction_id: "nav-link",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: route.name.toLowerCase(),
                        content_interaction_type: InteractionType.Link,
                        content_interaction_driver:
                          InteractionDriver.Navigation,
                      } as DataLinkInfoType)}
                    />,
                  );
                }
              });
              return tabs;
            })()}
          </Tabs>
        </div>
        {viewConfiguration.Wallet && !hideFundButton && (
          <div className={classes.rightSection}>
            <Hidden smDown>
              {myClientKYCStatus === ClientKYCStatus.VerifiedStatus && (
                <Typography variant="body1" color="textSecondary">
                  {`Available ${currencyPrefix}:`}&nbsp;
                </Typography>
              )}
              {props.modelID &&
                myClientKYCStatus === ClientKYCStatus.VerifiedStatus && (
                  <Typography
                    id="marketPlaceRibbon-AvailableBalance-Typography"
                    variant="body1"
                    children={formatTextNum(balance, {
                      addDecimalPadding: true,
                    })}
                  />
                )}
              {!props.modelID &&
                myClientKYCStatus === ClientKYCStatus.VerifiedStatus && (
                  <CircularProgress size={20} />
                )}
              <Tooltip
                disableInteractive={false}
                title={
                  myClientKYCStatus !== ClientKYCStatus.VerifiedStatus ? (
                    <Typography fontWeight={"medium"}>
                      Identity Verification Required
                      <Link
                        sx={(theme) => ({
                          paddingLeft: theme.spacing(0.5),
                          color: theme.palette.info.main,
                          "&:hover": {
                            color: theme.palette.info.main,
                          },
                        })}
                        onClick={() => {
                          if (myClient) {
                            if (
                              myClient.clientKind === ClientKind.IndividualType
                            ) {
                              navigate("/kyc");
                            } else {
                              navigate("/kyb");
                            }
                          }
                        }}
                        component="a"
                        underline="always"
                        color="info.main"
                      >
                        Start Now
                      </Link>
                    </Typography>
                  ) : (
                    ""
                  )
                }
                placement="top"
              >
                <span
                  ref={registerElement("marketPlaceRibbon-funcAccount-button")}
                >
                  <Button
                    id="marketPlaceRibbon-funcAccount-button"
                    data-link-info={JSON.stringify({
                      content_interaction_id: "nav-link",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_text: "fund account",
                      content_interaction_type: InteractionType.Button,
                      content_interaction_driver: InteractionDriver.Navigation,
                    } as DataLinkInfoType)}
                    disabled={!props.modelID}
                    onClick={() => setDisplayFundDialog(!displayFundDialog)}
                    variant="contained"
                    color="primary"
                    children="fund account"
                    className={classes.fundButton}
                  />
                </span>
              </Tooltip>
            </Hidden>
          </div>
        )}
      </StyledToolbar>

      {displayFundDialog && props.modelID && (
        <FundAccountDialog
          accountID={props.modelID}
          open={displayFundDialog}
          onClose={() => setDisplayFundDialog(!displayFundDialog)}
        />
      )}
    </RibbonContainer>
  );
};

const awaitingConfirmationCountQuery = new Query({
  limit: 1,
  offset: 0,
  sorting: [],
});

const awaitingConfirmationCountCriteria = {
  state: TextListCriterion([
    DirectOrderState.AwaitingConfirmation,
    DirectOrderState.Pending,
  ]),
};

function AwaitingConfirmationCountBadge({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { viewConfiguration, authContext } = useApplicationContext();

  const { errorContextErrorTranslator } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();
  const isMounted = useIsMounted();

  // confirm that logged in user can access the orders view
  const canReadDirectOrders = viewConfiguration.Marketplace
    ? viewConfiguration.Marketplace.Orders
      ? !!viewConfiguration.Marketplace.Orders.Read
      : false
    : false;

  // keep track of the number of orders that match awaiting confirmation state
  const [count, setCount] = useState(0);

  // read the direct orders view
  const { readResponse, setReadRequest } = useRead(
    {
      context: authContext,
      criteria: awaitingConfirmationCountCriteria,
      query: awaitingConfirmationCountQuery,
    },
    // but only if the logged in user has permission
    // and this component is mounted
    !(canReadDirectOrders && isMounted()),
  );

  // each time the read response total changes update the count
  useEffect(() => {
    if (isMounted()) {
      setCount(readResponse.total);
    }
  }, [readResponse.total, isMounted]);

  // on first load register notification callbacks to keep count up to date
  useEffect(() => {
    const deregisterCallbacks: (() => void)[] = [];
    (async () => {
      // get the ID of all groups in which the executing user has permission to call
      // the MarketDirectOrderViewReader.Read method
      let determineScopeCriteriaByRolesResponse: DetermineScopeCriteriaByRolesResponse;
      try {
        determineScopeCriteriaByRolesResponse =
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName: "Read",
              serviceProvider: MarketDirectOrderViewReader.serviceProvider,
              description: "?",
            }),
            criteria: {},
            scopeFields: [ScopeFields.IDField],
            buildScopeTree: false,
          });
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining scope of ${
            MarketDirectOrderViewReader.serviceProvider
          }.Read: ${err.message ? err.message : err.toString()}`,
        );
        return;
      }

      // assume type returned to get list of group IDs
      if (!determineScopeCriteriaByRolesResponse.criteria.id) {
        console.error(
          "unexpected determineScopeCriteriaByRolesResponse.criteria - does not contain id field",
        );
        return;
      }
      if (
        determineScopeCriteriaByRolesResponse.criteria.id.type !==
        TextListCriterionTypeName
      ) {
        console.error(
          `unexpected determineScopeCriteriaByRolesResponse.criteria.id.type - expected type to be ${TextListCriterionTypeName}`,
        );
        return;
      }
      const textListCriterion = determineScopeCriteriaByRolesResponse.criteria
        .id as TextListCriterionType;

      // register a notification callback for each of these groups when a notification
      // is received on any of the group market direct order view notification channels
      for (const groupID of textListCriterion.list) {
        try {
          deregisterCallbacks.push(
            await registerNotificationCallback(
              new GroupNotificationChannel({
                groupID,
                name: MarketDirectOrderViewNotificationChannelName,
                private: true,
              } as GroupNotificationChannel),
              [MarketDirectOrderViewModelChangedNotificationTypeName],
              () => {
                if (isMounted()) {
                  setReadRequest({
                    context: authContext,
                    criteria: awaitingConfirmationCountCriteria,
                    query: awaitingConfirmationCountQuery,
                  });
                }
              },
            ),
          );
        } catch (e) {
          console.error(
            `error registering for notifications on group channel '${MarketDirectOrderViewNotificationChannelName}' for group ${groupID}`,
          );
        }
      }
    })();

    // de-register when component unmounts
    return () => deregisterCallbacks.forEach((deregister) => deregister());
  }, [authContext, registerNotificationCallback, isMounted, setReadRequest]);

  return (
    <Badge
      id={"marketplaceRibbonOrdersTab-awaitingOrdersCount-badge"}
      badgeContent={count}
      color={"primary"}
    >
      {children}
    </Badge>
  );
}
