import React from "react";
import { Box } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import { SetupIndividualClientProfileCard } from "./components/individualClient/SetupIndividualClientProfileCard";
import background from "assets/images/background/background.png";
import { SetupCompanyClientProfile } from "./components/companyClient/SetupCompanyClientProfile";
import { ClientKind } from "james/client/Client";
import { useApplicationContext } from "context/Application/Application";

export function SetupClientProfile() {
  const width = useWidth();
  const { loginClaims } = useApplicationContext();

  return (
    <>
      {isWidthUp("sm", width) ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: "100vh",
            overflowY: isWidthUp("md", width) ? "hidden" : "auto",
            width: "100%",
            paddingBottom: "24px",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              minHeight: "100%",
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loginClaims.clientKind === ClientKind.IndividualType ? (
              <SetupIndividualClientProfileCard />
            ) : (
              <SetupCompanyClientProfile />
            )}
          </Box>
        </Box>
      ) : loginClaims.clientKind === ClientKind.IndividualType ? (
        <SetupIndividualClientProfileCard />
      ) : (
        <SetupCompanyClientProfile />
      )}
    </>
  );
}
