import React from "react";
import { MeshCoreLayout } from "layouts";
import { Router } from "routes";
import { UnderwriterHubRouteBuilder } from "routes/private/underwriterHubRoutes";
import { useApplicationContext } from "context/Application/Application";

export const UnderwriterHub = () => {
  const { myClient, viewConfiguration } = useApplicationContext();
  const { homeRoute, userProfileRoute, otherRoutes } =
    UnderwriterHubRouteBuilder(viewConfiguration, myClient);

  return (
    <MeshCoreLayout
      userProfileRoute={userProfileRoute}
      otherRoutes={[]}
      homeRoute={homeRoute}
    >
      <Router
        baseURL={"/underwriter-hub"}
        redirectPath={"/underwriter-hub/obligations"}
        routes={[homeRoute, ...otherRoutes]}
      />
    </MeshCoreLayout>
  );
};
