import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FadeProps,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import React from "react";

const PREFIX = "WarningDialog";

const classes = {
  warningDialogTitle: `${PREFIX}-warningDialogTitle`,
  btn: `${PREFIX}-btn`,
};

const StyledFade = styled(Fade)(({ theme }) => ({
  [`& .${classes.warningDialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.btn}`]: {
    minWidth: 101,
  },
}));

export interface WarningDialogProps {
  showDialog: boolean;
  onCloseClick: () => void;
  onAwayClick?: () => void;
  onYesClick: () => void;
  onNoClick: () => void;
  title?: string;
  messageParagraphs: string[];
  disableControls?: boolean;
  showProgressIndicator?: boolean;
}

export function WarningDialog(props: WarningDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={props.showDialog}
      onClose={props.onAwayClick ? props.onAwayClick : undefined}
      maxWidth="lg"
      PaperProps={{
        sx: {
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            width: "100%",
            bottom: 0,
            borderRadius: "10px 10px 0 0",
            margin: 0,
          },
        },
      }}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
    >
      <DialogTitle classes={{ root: classes.warningDialogTitle }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography
              variant="h5"
              children={props.title ? props.title : "Confirm Action"}
            />
          </Grid>
          {props.showProgressIndicator && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  size="small"
                  disabled={props.showProgressIndicator}
                  id="warningDialog-close-button"
                  onClick={props.onCloseClick}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: `${theme.spacing(3)} !important`,
          [theme.breakpoints.up("sm")]: {
            width: 499,
          },
        }}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              {props.messageParagraphs.map((p, idx) => (
                <Grid item key={idx}>
                  <Typography color="textSecondary">{p}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                paddingBottom: theme.spacing(3),
              },
            }}
          >
            <Grid
              container
              direction={!isMobile ? "row-reverse" : "column-reverse"}
              spacing={2}
            >
              <Grid item>
                <Button
                  sx={{
                    minWidth: 101,
                    [theme.breakpoints.down("sm")]: {
                      height: "40px",
                    },
                  }}
                  fullWidth={isMobile}
                  variant="contained"
                  color="primary"
                  children="yes"
                  size="large"
                  disabled={
                    props.showProgressIndicator || props.disableControls
                  }
                  id="warningDialog-yes-button"
                  onClick={props.onYesClick}
                />
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    minWidth: 101,
                    [theme.breakpoints.down("sm")]: {
                      height: "40px",
                    },
                  }}
                  fullWidth={isMobile}
                  variant="outlined"
                  children="no"
                  size="large"
                  disabled={
                    props.showProgressIndicator || props.disableControls
                  }
                  id="warningDialog-no-button"
                  onClick={props.onNoClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" {...tprops} />;
};

const fadeTransition = (tprops: FadeProps) => {
  return <StyledFade in {...tprops} />;
};
