import { TextNEExactCriterionTypeName } from "james/search/criterion";

export interface TextNEExactCriterionType {
  type: string;
  text: string;
}

function TextNEExactCriterion(text: string): TextNEExactCriterionType {
  return {
    type: TextNEExactCriterionTypeName,
    text,
  };
}

export { TextNEExactCriterion };
