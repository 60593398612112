import {
  GetPriceForPeriodRequest,
  GetPriceForPeriodResponse,
  GetPriceForRangeRequest,
  GetPriceForRangeResponse,
  HistoricalPrice,
  InvalidDateTimeError,
  InvalidPeriodError,
  InvalidResolutionError,
  Period,
  PriceHistorian,
  Resolution,
  UnexpectedTokenError,
} from "pkgTemp/market";
import { Token } from "pkgTemp/ledger";
import { Client, TradeAggregationRecord } from "./Client";
import { NativeAssetTokenCode, tokenToAsset } from "./token";
import dayjs, { Dayjs } from "dayjs";
import { Asset, NotFoundError, Horizon } from "stellar-sdk";
import { StellarNetwork } from "./Network";

export type NewStellarPriceHistorianProps = {
  client: Client;
};

export class StellarPriceHistorian implements PriceHistorian {
  private readonly stellarClient: Client;

  constructor(props: NewStellarPriceHistorianProps) {
    this.stellarClient = props.client;
  }

  async GetPriceForPeriod(
    request: GetPriceForPeriodRequest,
  ): Promise<GetPriceForPeriodResponse> {
    // convert given period to a range
    const [from, to] = periodToRange(request.period, request.allFrom);

    // invoke and return price for range
    return this.GetPriceForRange({
      baseToken: request.baseToken,
      quoteToken: request.quoteToken,
      resolution: request.resolution,
      from,
      to,
    });
  }

  async GetPriceForRange(
    request: GetPriceForRangeRequest,
  ): Promise<GetPriceForRangeResponse> {
    // confirm that given base and quote tokens differ
    if (request.baseToken.isEqualTo(request.quoteToken)) {
      throw new UnexpectedTokenError(
        `expected base and quote tokens to differ, got '${request.baseToken}' for both base and quote`,
      );
    }

    // confirm that given from date is before to
    if (request.to.isSame(request.from) || request.to.isBefore(request.from)) {
      throw new InvalidDateTimeError(
        `given from '${request.from}' is not before to '${request.to}'`,
      );
    }

    // convert given base and quote tokens to stellar asset types
    const baseAsset = tokenToAsset(request.baseToken);
    const quoteAsset = tokenToAsset(request.quoteToken);

    // get resolution in MS
    const resolutionMS = resolutionToMS(request.resolution);

    if (baseAsset.isNative() || quoteAsset.isNative()) {
      // if either the base or quote token is XLM then get trade aggregations directly
      const tradeAggregations = await this.getAllTradeAggregations(
        baseAsset,
        quoteAsset,
        request.from,
        request.to,
        resolutionMS,
      );

      // map to prices
      const prices = tradeAggregations.map(
        (tradeAggregation) =>
          new HistoricalPrice({
            time: dayjs(+tradeAggregation.timestamp),
            avgPrice: request.quoteToken.newAmountOf(tradeAggregation.avg),
          }),
      );

      // fill and return
      return {
        prices: fillMissingPrices(request.to, resolutionMS, prices),
      };
    } else {
      // neither asset is XLM
      // get base and quote to XLM and triangulate

      // prepare xlm
      const xlmToken = new Token({
        code: NativeAssetTokenCode,
        issuer: this.stellarClient.network,
        network: this.stellarClient.network as StellarNetwork.TestSDFNetwork,
      });
      const xlmAsset = tokenToAsset(xlmToken);

      // get base and quote to XLM filled price arrays
      let baseToXLMFilledPrices: HistoricalPrice[] = [];
      let xlmToQuoteFilledPrices: HistoricalPrice[] = [];
      await Promise.all([
        (async () => {
          // get base asset to XLM trade aggregations
          const baseToXLMTradeAggregations = await this.getAllTradeAggregations(
            baseAsset,
            xlmAsset,
            request.from,
            request.to,
            resolutionMS,
          );

          // map to prices
          const baseToXLMPrices = baseToXLMTradeAggregations.map(
            (tradeAggregation) =>
              new HistoricalPrice({
                time: dayjs(+tradeAggregation.timestamp),
                avgPrice: xlmToken.newAmountOf(tradeAggregation.avg),
              }),
          );

          // fill prices
          baseToXLMFilledPrices = fillMissingPrices(
            request.to,
            resolutionMS,
            baseToXLMPrices,
          );
        })(),
        (async () => {
          // get quote asset to XLM trade aggregations
          const xlmToQuoteTradeAggregations =
            await this.getAllTradeAggregations(
              xlmAsset,
              quoteAsset,
              request.from,
              request.to,
              resolutionMS,
            );

          // map to prices
          const xlmToQuotePrices = xlmToQuoteTradeAggregations.map(
            (tradeAggregation) =>
              new HistoricalPrice({
                time: dayjs(+tradeAggregation.timestamp),
                avgPrice: request.quoteToken.newAmountOf(tradeAggregation.avg),
              }),
          );

          // fill prices
          xlmToQuoteFilledPrices = fillMissingPrices(
            request.to,
            resolutionMS,
            xlmToQuotePrices,
          );
        })(),
      ]);

      // triangulate and return
      return {
        prices: triangulate(baseToXLMFilledPrices, xlmToQuoteFilledPrices),
      };
    }
  }

  async getAllTradeAggregations(
    baseAsset: Asset,
    quoteAsset: Asset,
    from: Dayjs,
    to: Dayjs,
    resolutionMS: number,
  ): Promise<TradeAggregationRecord[]> {
    // prepare list of trade aggregation records
    let tradeAggregationRecords: TradeAggregationRecord[] = [];

    // get first trade aggregations page
    let tradeAggregationsPage: Horizon.ServerApi.CollectionPage<TradeAggregationRecord>;
    try {
      tradeAggregationsPage = await this.stellarClient.tradeAggregations(
        baseAsset,
        quoteAsset,
        from.valueOf(),
        to.valueOf(),
        resolutionMS,
        0, // default segmentation sufficient
        {
          limit: 100,
          order: "asc",
        },
      );
    } catch (e) {
      // not found means no trades exist for this pair yet
      if (e instanceof NotFoundError) {
        return [];
      }
      throw e;
    }

    // iterate until no more trade aggregations are retrieved
    while (tradeAggregationsPage.records.length) {
      tradeAggregationRecords = [
        ...tradeAggregationRecords,
        ...tradeAggregationsPage.records,
      ];
      tradeAggregationsPage = await tradeAggregationsPage.next();
    }

    // return retrieved trade aggregations
    return tradeAggregationRecords;
  }
}

/**
 * Converts given resolution to milliseconds.
 * @param {Resolution} resolution resolution to convert to milliseconds
 */
export function resolutionToMS(resolution: Resolution): number {
  switch (resolution) {
    case Resolution.Minute:
      return 60000;

    case Resolution.Hour:
      return 3600000;

    case Resolution.Day:
      return 86400000;

    case Resolution.Week:
      return 604800000;

    default:
      throw new InvalidResolutionError(resolution);
  }
}

/**
 * Converts given period to a time range.
 * @param {Period} period period to convert to a time range
 * @param {Dayjs} [allFrom] starting point of ALL range
 */
export function periodToRange(period: Period, allFrom?: Dayjs): [Dayjs, Dayjs] {
  const to = dayjs();
  switch (period) {
    case Period._1D:
      return [dayjs(to).subtract(1, "day"), to];

    case Period._1W:
      return [dayjs(to).subtract(1, "week"), to];

    case Period._1M:
      return [dayjs(to).subtract(1, "month"), to];

    case Period._3M:
      return [dayjs(to).subtract(3, "month"), to];

    case Period._6M:
      return [dayjs(to).subtract(6, "month"), to];

    case Period._1Y:
      return [dayjs(to).subtract(1, "year"), to];

    case Period._ALL:
      if (!allFrom) {
        throw new InvalidPeriodError(
          period,
          "allFrom value required for ALL period",
        );
      }
      // if (allFrom.isSameOrAfter(to)) {
      if (allFrom.isSame(to) || allFrom.isAfter(to)) {
        throw new InvalidDateTimeError(
          "allFrom value for ALL period should be in the past",
        );
      }
      return [allFrom, to];

    default:
      throw new InvalidPeriodError(period);
  }
}

/**
 * Fills missing historical prices from the earliest given price
 * to given to value.
 * @param {Dayjs} fillTo end of range to which filling should be done
 * @param {number} resolutionMS 'tick' resolution at which prices should exist
 * @param {HistoricalPrice[]} prices price range to process and fill in missing values.
 * Given prices assumed to be sorted in ascending time order.
 */
export function fillMissingPrices(
  fillTo: Dayjs,
  resolutionMS: number,
  prices: HistoricalPrice[],
): HistoricalPrice[] {
  // do nothing if no prices given
  if (!prices.length) {
    return prices;
  }

  // prepare filled list of prices with the first price given
  const filledPrices: HistoricalPrice[] = [new HistoricalPrice(prices[0])];

  // Initialise a time cursor at the time of the first price given.
  // the fill range is from this cursorTime to the given fillTo time
  // i.e. [firstPriceTime, fillTo]
  let cursorTime = dayjs(filledPrices[0].time);

  // confirm that given 'fillTo' value is not before the first given price's time
  if (fillTo.isBefore(cursorTime)) {
    throw new InvalidDateTimeError(
      "given fillTo value is before time of first price",
    );
  }

  // Initialise index from which to slice given list of prices before looking
  // for next price in the fill range [firstPriceTime, fillTo].
  let lookForPriceFromIdx = 1;
  while (
    // continue iterating while adding another measure of resolutionMS
    // will not surpass the given fillTo
    dayjs(cursorTime).add(resolutionMS, "millisecond").isSame(fillTo) ||
    dayjs(cursorTime).add(resolutionMS, "millisecond").isBefore(fillTo)
  ) {
    // get reduced list of prices in which to look for next price
    const reducedPrices = prices.slice(lookForPriceFromIdx);

    // increment cursor time by the given resolution
    cursorTime = cursorTime.add(resolutionMS, "millisecond");

    // prepare next price as the last filled price but at the current
    // cursor time
    const lastPrice = filledPrices[filledPrices.length - 1];
    let nextPrice = new HistoricalPrice({
      time: cursorTime,
      avgPrice: lastPrice.avgPrice,
    });

    // find first price in the reduced list that is after the last price's time
    // and before or equal to the current cursor time
    for (let priceIdx = 0; priceIdx < reducedPrices.length; priceIdx++) {
      if (
        reducedPrices[priceIdx].time.isAfter(lastPrice.time) &&
        (reducedPrices[priceIdx].time.isSame(cursorTime) ||
          reducedPrices[priceIdx].time.isBefore(cursorTime))
      ) {
        lookForPriceFromIdx = priceIdx;
        nextPrice = new HistoricalPrice(reducedPrices[priceIdx]);
        break;
      }
    }

    // add to list of prices
    filledPrices.push(nextPrice);
  }

  return filledPrices;
}

/**
 * Triangulates given base to xlm and xlm to quote prices to create base to quote prices
 * Given parameters are assumed to be sorted in ascending time order and to have aligning
 * time values.
 * @param {HistoricalPrice[]} baseToXLMPrices base/xlm prices e.g. BTC/XLM
 * @param {HistoricalPrice[]} xlmToQuotePrices xlm/quote prices e.g. XLM/mZAR
 */
export function triangulate(
  baseToXLMPrices: HistoricalPrice[],
  xlmToQuotePrices: HistoricalPrice[],
): HistoricalPrice[] {
  // trim either base or quote to xlm such that both are of the same length for triangulation
  if (baseToXLMPrices.length > xlmToQuotePrices.length) {
    baseToXLMPrices = baseToXLMPrices.slice(
      baseToXLMPrices.length - xlmToQuotePrices.length,
    );
  } else if (baseToXLMPrices.length < xlmToQuotePrices.length) {
    xlmToQuotePrices = xlmToQuotePrices.slice(
      xlmToQuotePrices.length - baseToXLMPrices.length,
    );
  }

  // triangulate trimmed array
  const triangulatedPrices: HistoricalPrice[] = [];
  baseToXLMPrices.forEach((price, idx) => {
    // confirm that times align
    if (!price.time.isSame(xlmToQuotePrices[idx].time)) {
      throw new InvalidDateTimeError(
        "given base/XLM and XLM/quote prices do not align",
      );
    }

    // add triangulated price
    triangulatedPrices.push(
      new HistoricalPrice({
        time: price.time,
        avgPrice: xlmToQuotePrices[idx].avgPrice.setValue(
          price.avgPrice.value.multipliedBy(
            xlmToQuotePrices[idx].avgPrice.value,
          ),
        ),
      }),
    );
  });

  return triangulatedPrices;
}
