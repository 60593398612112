import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { useEffect, useRef, useState } from "react";
import { CurrencyStablecoin } from ".";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveCurrencyStablecoinRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveCurrencyStablecoinResponse {
  currencyStablecoin: CurrencyStablecoin;
}

export interface SearchCurrencyStablecoinRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchCurrencyStablecoinResponse {
  records: CurrencyStablecoin[];
  total: number;
}

export const CurrencyStablecoinRepository = {
  serviceProvider: "financial-CurrencyStablecoinRepository",
  async RetrieveCurrencyStablecoin(
    request: RetrieveCurrencyStablecoinRequest,
  ): Promise<RetrieveCurrencyStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${CurrencyStablecoinRepository.serviceProvider}.RetrieveCurrencyStablecoin`,
      request,
    });
    return {
      currencyStablecoin: new CurrencyStablecoin(response.currencyStablecoin),
    };
  },
  async SearchCurrencyStablecoin(
    request: SearchCurrencyStablecoinRequest,
  ): Promise<SearchCurrencyStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${CurrencyStablecoinRepository.serviceProvider}.SearchCurrencyStablecoin`,
      request,
    });
    return {
      records: response.records.map(
        (c: CurrencyStablecoin) => new CurrencyStablecoin(c),
      ),
      total: response.total,
    };
  },
};

export function useSearchCurrencyStablecoin(
  initialSearchCurrencyStablecoinRequest?: SearchCurrencyStablecoinRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchCurrencyStablecoinRequest, setSearchCurrencyStablecoinRequest] =
    useState<SearchCurrencyStablecoinRequest>(
      initialSearchCurrencyStablecoinRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [
    searchCurrencyStablecoinResponse,
    setSearchCurrencyStablecoinResponse,
  ] = useState<SearchCurrencyStablecoinResponse>({
    total: 0,
    records: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchCurrencyStablecoinResponse(
          await CurrencyStablecoinRepository.SearchCurrencyStablecoin(
            searchCurrencyStablecoinRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for currencyStablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for currencyStablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchCurrencyStablecoinRequest, shouldNotExecute]);

  return {
    searchCurrencyStablecoinRequest,
    setSearchCurrencyStablecoinRequest,
    searchCurrencyStablecoinResponse,
    loading,
    error,
  };
}
