import { Context } from "james/security";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { useEffect, useState } from "react";
import { GroupUser } from "./GroupUser";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface SearchGroupUsersInGroupRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  groupID: string;
  query?: Query;
}

export interface SearchGroupUsersInGroupResponse {
  records: GroupUser[];
  total: number;
}

export interface SearchGroupUsersRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchGroupUsersResponse {
  records: GroupUser[];
  total: number;
}

export const Compository = {
  serviceProvider: "user-Compository",
  async SearchGroupUsersInGroup(
    request: SearchGroupUsersInGroupRequest,
  ): Promise<SearchGroupUsersInGroupResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Compository.serviceProvider}.SearchGroupUsersInGroup`,
      request,
    });
    return {
      records: response.records.map((c: GroupUser) => new GroupUser(c)),
      total: response.total,
    };
  },
  async SearchGroupUsers(
    request: SearchGroupUsersRequest,
  ): Promise<SearchGroupUsersResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Compository.serviceProvider}.SearchGroupUsers`,
      request,
    });
    return {
      records: response.records.map((c: GroupUser) => new GroupUser(c)),
      total: response.total,
    };
  },
};
let searchGroupUsersTimeout: NodeJS.Timeout;

export function useUserCompositorySearchGroupUsers(
  initialSearchGroupUsers?: SearchGroupUsersRequest,
) {
  const { authContext } = useApplicationContext();
  const [searchGroupUsersRequest, setSearchGroupUsersRequest] =
    useState<SearchGroupUsersRequest>(
      initialSearchGroupUsers || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchGroupUsersResponse, setSearchGroupUsersResponse] =
    useState<SearchGroupUsersResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    clearTimeout(searchGroupUsersTimeout);
    searchGroupUsersTimeout = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchGroupUsersResponse(
          await Compository.SearchGroupUsers(searchGroupUsersRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for group users: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for group users: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchGroupUsersRequest]);

  return {
    searchGroupUsersRequest,
    setSearchGroupUsersRequest,
    searchGroupUsersResponse,
    loading,
    error,
  };
}
