import { Balance as StellarAccountBalance } from "james/stellar/Account";
import { Model as LedgerTokenViewModel } from "../ledgerTokenView";

export class Balance extends StellarAccountBalance {
  // all fields of LedgerBalance...

  // plus a tokenViewModel for LedgerBalance.Token
  public tokenViewModel: LedgerTokenViewModel = new LedgerTokenViewModel();

  constructor(balance?: Balance) {
    super(balance);
    if (!balance) {
      return;
    }
    this.tokenViewModel = new LedgerTokenViewModel(balance.tokenViewModel);
  }
}
