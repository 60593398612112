"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allPaymentStatees = void 0;
exports.paymentStateToString = paymentStateToString;
exports.stringToPaymentState = stringToPaymentState;
const payment_pb_1 = require("./payment_pb");
// Get all asset class as enum values
exports.allPaymentStatees = Object.values(payment_pb_1.PaymentState).filter((value) => typeof value === "number");
// Define explicit mappings between PaymentState enums and custom string representations
const paymentStateToStringMapping = {
    [payment_pb_1.PaymentState.UNDEFINED_PAYMENT_STATE]: "-",
    [payment_pb_1.PaymentState.PENDING_PAYMENT_STATE]: "Pending",
    [payment_pb_1.PaymentState.PAYMENT_IN_PROGRESS_PAYMENT_STATE]: "Payment in Progress",
    [payment_pb_1.PaymentState.PAYMENT_FAILED_PAYMENT_STATE]: "Payment Failed",
    [payment_pb_1.PaymentState.PAID_PAYMENT_STATE]: "Paid",
    [payment_pb_1.PaymentState.CLEAN_UP_IN_PROGRESS_PAYMENT_STATE]: "Cleanup in Progress",
    [payment_pb_1.PaymentState.CLEAN_UP_UNDER_INVESTIGATION_PAYMENT_STATE]: "Cleanup Under Investigation",
    [payment_pb_1.PaymentState.CLEAN_UP_FAILED_PAYMENT_STATE]: "Cleanup Failed",
    [payment_pb_1.PaymentState.COMPLETE_PAYMENT_STATE]: "Complete",
};
// Reverse mapping from string to PaymentState enum
const stringToPaymentStateMapping = {};
for (const [key, value] of Object.entries(paymentStateToStringMapping)) {
    stringToPaymentStateMapping[value] = Number(key);
}
class UnsupportedPaymentStateError extends Error {
    constructor(paymentState) {
        const message = `Unsupported PaymentState: ${paymentState}`;
        super(message);
        this.paymentState = paymentState;
    }
}
/**
 * Converts a PaymentState enum instance to a custom string representation.
 * @param {PaymentState} paymentState - The asset class to convert.
 * @returns {string} The custom string representation of the asset class.
 */
function paymentStateToString(paymentState) {
    if (paymentState in paymentStateToStringMapping) {
        return paymentStateToStringMapping[paymentState];
    }
    else {
        throw new UnsupportedPaymentStateError(paymentState);
    }
}
class UnsupportedPaymentStateStringError extends Error {
    constructor(paymentStateStr) {
        const message = `Unsupported asset class string: ${paymentStateStr}`;
        super(message);
        this.paymentStateStr = paymentStateStr;
    }
}
/**
 * Converts a custom string representation to a PaymentState enum instance.
 * @param {string} paymentStateStr - The custom string representation of the asset class.
 * @returns {PaymentState} The corresponding PaymentState enum instance.
 */
function stringToPaymentState(paymentStateStr) {
    if (paymentStateStr in stringToPaymentStateMapping) {
        return stringToPaymentStateMapping[paymentStateStr];
    }
    else {
        throw new UnsupportedPaymentStateStringError(paymentStateStr);
    }
}
