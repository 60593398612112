import React from "react";
import { Router } from "routes";
import { Table } from "./Table";

export function AfterEffects() {
  return (
    <Router
      baseURL={"/consistency/afterEffects"}
      redirectPath={"/consistency/afterEffects/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/consistency/afterEffects/table",
          component: <Table />,
        },
      ]}
    />
  );
}
