import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Add from "@mui/icons-material/Add";
import { ConnectedIndividual as ConnectedIndividualClass } from "james/legal";
import { Company } from "james/legal/company/Company";
import React, { useState } from "react";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import { FETable } from "components/Table/FETable";
import { EditOutlined as EditIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConnectedIndividualDialog } from "./components/Dialog/ConnectedIndividual";
const PREFIX = "ConnectedIndividual";

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  helperTextLayout: `${PREFIX}-helperTextLayout`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  [`& .${classes.icon}`]: {
    marginRight: "14px",
    marginBottom: "1px",
  },

  [`& .${classes.helperTextLayout}`]: {
    width: 400,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "45px 1fr",
    paddingBottom: theme.spacing(4),
  },
}));

interface ConnectedIndividualProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  company: Company;
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
}

export const ConnectedIndividual = (props: ConnectedIndividualProps) => {
  const { company, onChange, onNext, invalidStep } = props;

  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [showConnectedIndividualDialog, setShowConnectedIndividualDialog] =
    useState(false);

  const nextStep = () => {
    onNext(CompanyRegStep.connectedNonIndividuals)();
    invalidStep(CompanyRegStep.connectedIndividuals, false);
  };

  return (
    <Root className={classes.root}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary">
            Connected Individuals
          </Typography>
        </Box>
        <Button
          id="connectedIndividual-next"
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          Next
        </Button>
      </Box>

      <div className={classes.helperTextLayout}>
        <Typography variant="subtitle2">
          Key individuals connected to the company:
        </Typography>
        <div>
          <Typography variant="subtitle2">
            - The manager of the company
          </Typography>
          <Typography variant="subtitle2">
            - Shareholders holding 25% or more of the voting rights
          </Typography>
          <Typography variant="subtitle2">
            - Any individuals that are company agents
          </Typography>
        </div>
      </div>

      {!company.connectedIndividuals.length && (
        <div>
          <Button
            onClick={() => {
              setSelectedIdx(-1);
              setShowConnectedIndividualDialog(true);
            }}
            variant="contained"
            color="secondary"
            id="connectedIndividual-newIndividual"
          >
            <Add className={classes.icon} />
            New Individual
          </Button>
        </div>
      )}

      {company.connectedIndividuals.length > 0 && (
        <FETable
          singleSelect
          height={326}
          title="List of Individuals"
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (selectedIdx >= 0) {
              controls.push(
                <Tooltip title="Remove" placement="top">
                  <span>
                    <IconButton
                      id="connectedIndividualTable-remove-iconButton"
                      size="small"
                      onClick={() =>
                        onChange("connectedIndividuals")(
                          company.connectedIndividuals.filter(
                            (_: ConnectedIndividualClass, idx: number) =>
                              idx !== selectedIdx,
                          ),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              controls.push(
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconButton
                      id="connectedIndividualTable-edit-iconButton"
                      size="small"
                      onClick={() => {
                        setSelectedIdx(selectedIdx);
                        setShowConnectedIndividualDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );
            } else {
              controls.push(
                <Button
                  onClick={() => {
                    setSelectedIdx(-1);
                    setShowConnectedIndividualDialog(true);
                  }}
                  variant="contained"
                  color="secondary"
                  id="connectedIndividual-newIndividual"
                >
                  <Add className={classes.icon} />
                  New Individual
                </Button>,
              );
            }

            return controls;
          })()}
          onSingleSelectChange={(selectedRowData, idx) => {
            if (selectedRowData) {
              setSelectedIdx(idx);
            } else {
              setSelectedIdx(-1);
            }
          }}
          columns={[
            {
              label: "First Name",
              field: "firstName",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  children={(data as ConnectedIndividualClass).firstName}
                />
              ),
            },
            {
              label: "Last Name",
              field: "lastName",
            },
            {
              label: "Email",
              field: "emailAddress",
            },
            {
              label: "Mobile",
              field: "cellphoneNumber",
            },
          ]}
          data={company.connectedIndividuals}
        />
      )}

      {showConnectedIndividualDialog && (
        <ConnectedIndividualDialog
          connectedIndividual={
            // if index not negative
            selectedIdx >= 0
              ? // then use selected individuals list
                new ConnectedIndividualClass(
                  company.connectedIndividuals[selectedIdx],
                )
              : // otherwise provide nothing
                undefined
          }
          onChange={(individual: ConnectedIndividualClass) => {
            // if index not negative
            if (selectedIdx >= 0) {
              // then update selected individuals list
              const updatedList = [...company.connectedIndividuals];
              updatedList[selectedIdx] = new ConnectedIndividualClass(
                individual,
              );
              onChange("connectedIndividuals")(updatedList);
            } else {
              // otherwise add
              onChange("connectedIndividuals")([
                ...company.connectedIndividuals,
                individual,
              ]);
            }
          }}
          open
          onClose={() => {
            setShowConnectedIndividualDialog(false);
          }}
        />
      )}
    </Root>
  );
};
