import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";

export interface ArchiveClaimableBalanceRequest {
  context: Context;
  claimableBalanceID: string;
  claimantAccountLedgerID: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ArchiveClaimableBalanceResponse {}

export const ClaimableBalanceArchiver = {
  serviceProvider: "stellar-ClaimableBalanceArchiver",
  async ArchiveClaimableBalance(
    request: ArchiveClaimableBalanceRequest,
    additionalHeaders?: Headers,
  ): Promise<ArchiveClaimableBalanceResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ClaimableBalanceArchiver.serviceProvider}.ArchiveClaimableBalance`,
      request,
    });
  },
};
