import { Button } from "@mui/material";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import React from "react";
import { CountryOption } from "james/country/countries";

export const CountryOfResidenceButton = (props: {
  country: CountryOption;
  onSubmit: (countryCode: string) => Promise<void>;
}) => {
  return (
    <Button
      sx={(theme) => ({
        height: theme.spacing(6),
        boxShadow: 5,
        width: "100%",
        marginBottom: theme.spacing(25),
      })}
      data-link-info={JSON.stringify({
        content_interaction_id: "sign-up-button",
        content_interaction_action: InteractionAction.Click,
        content_interaction_text: "country of residence>register",
        content_interaction_type: InteractionType.Button,
        content_interaction_driver: InteractionDriver.SignUp,
      } as DataLinkInfoType)}
      color="primary"
      variant="contained"
      size="large"
      disabled={!props.country}
      onClick={() => props.onSubmit(props.country.value)}
      children="Submit"
    />
  );
};
