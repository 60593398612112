import { RunningTransaction } from "./RunningTransaction";

export class CommitPhase {
  public runningTransactions: RunningTransaction[] = [];

  constructor(c?: CommitPhase) {
    if (!c) {
      return;
    }
    this.runningTransactions = c.runningTransactions.map(
      (rt) => new RunningTransaction(rt),
    );
  }
}
