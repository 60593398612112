import { Notification } from "james/notification/Notification";

export const TransactionSucceededNotificationTypeName =
  "mesh::ledger/TransactionSucceededNotification";

export class TransactionSucceededNotification implements Notification {
  public ["@type"]: string = TransactionSucceededNotificationTypeName;

  public transactionID = "";

  constructor(n?: TransactionSucceededNotification) {
    if (!n) {
      return;
    }

    this.transactionID = n.transactionID;
  }

  notificationType(): string {
    return TransactionSucceededNotificationTypeName;
  }
}

export const TransactionFailedNotificationTypeName =
  "mesh::ledger/TransactionFailedNotification";

export class TransactionFailedNotification implements Notification {
  public ["@type"]: string = TransactionFailedNotificationTypeName;

  public transactionID = "";
  public codes: string[] = [];

  constructor(n?: TransactionFailedNotification) {
    if (!n) {
      return;
    }

    this.transactionID = n.transactionID;
    this.codes = n.codes;
  }

  notificationType(): string {
    return TransactionFailedNotificationTypeName;
  }
}

export const TransactionSubmissionResolutionFailedNotificationTypeName =
  "mesh::ledger/TransactionSubmissionResolutionFailedNotification";

export class TransactionSubmissionResolutionFailedNotification
  implements Notification
{
  public ["@type"]: string =
    TransactionSubmissionResolutionFailedNotificationTypeName;

  public transactionID = "";

  constructor(n?: TransactionSubmissionResolutionFailedNotification) {
    if (!n) {
      return;
    }

    this.transactionID = n.transactionID;
  }

  notificationType(): string {
    return TransactionSubmissionResolutionFailedNotificationTypeName;
  }
}
