import { ValidationResult } from "common/validation";
import { Amount } from "james/ledger";
import { Model as AccountViewModel } from "james/views/stellarAccountView";
import { CalculateLiquidityPoolDepositResponse } from "james/stellar/LiquidityPoolCalculator";
import { NumFieldHlpTxt } from "validationHelperText";
import { formatTextNum } from "utilities/number";
import { FormData } from "./DepositDialog";

export type FormUpdaterSpecsType = {
  selectedTradingAccViewModel: (
    selectedTradingAccViewModel: AccountViewModel | null,
    prevFormData?: FormData,
  ) => FormData;
  maxAmountA: (maxAmountA: Amount, prevFormData?: FormData) => FormData;
  maxAmountB: (maxAmountB: Amount, prevFormData?: FormData) => FormData;
  calculateLiquidityPoolDepositResponse: (
    calculateLiquidityPoolDepositResponse: CalculateLiquidityPoolDepositResponse,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  selectedTradingAccViewModel(
    selectedTradingAccViewModel: AccountViewModel | null,
    prevFormData?: FormData,
  ): FormData {
    return {
      ...(prevFormData as FormData),
      selectedTradingAccViewModel,
    };
  },
  maxAmountA(maxAmountA: Amount, prevFormData?: FormData): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      maxAmountA,
      calculateLiquidityPoolDepositRequest: {
        ...prevFormData.calculateLiquidityPoolDepositRequest,
        maxAmountA,
        maxAmountB:
          prevFormData.registeredLiquidityPoolViewModel.liquidityPool.totalShares.value.toNumber()
            ? new Amount()
            : prevFormData.maxAmountB,
      },
    };
  },
  maxAmountB(maxAmountB: Amount, prevFormData?: FormData): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      maxAmountB,
      calculateLiquidityPoolDepositRequest: {
        ...prevFormData.calculateLiquidityPoolDepositRequest,
        maxAmountA:
          prevFormData.registeredLiquidityPoolViewModel.liquidityPool.totalShares.value.toNumber()
            ? new Amount()
            : prevFormData.maxAmountA,
        maxAmountB,
      },
    };
  },
  calculateLiquidityPoolDepositResponse(
    calculateLiquidityPoolDepositResponse: CalculateLiquidityPoolDepositResponse,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      maxAmountA: calculateLiquidityPoolDepositResponse.maxAmountA,
      maxAmountB: calculateLiquidityPoolDepositResponse.maxAmountB,
      minPrice: calculateLiquidityPoolDepositResponse.minPrice,
      maxPrice: calculateLiquidityPoolDepositResponse.maxPrice,
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // confirm given value for maxAmountA is > 0
  if (!formData.maxAmountA.value.toNumber()) {
    validationResult.valid = false;
    validationResult.fieldValidations.maxAmountA =
      NumFieldHlpTxt.MustBeGreaterThan0;

    // confirm balance A is sufficient to make deposit
  } else if (formData.maxAmountA.value.gt(formData.balanceA.value)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maxAmountA = `Insufficient balance ${
      formData.maxAmountA.token.code
    } ${formatTextNum(formData.balanceA.value, { noDecimalPlaces: 7 })}`;
  }

  // confirm given value for maxAmountB is > 0
  if (!formData.maxAmountB.value.toNumber()) {
    validationResult.valid = false;
    validationResult.fieldValidations.maxAmountB =
      NumFieldHlpTxt.MustBeGreaterThan0;

    // confirm balance A is sufficient to make deposit
  } else if (formData.maxAmountB.value.gt(formData.balanceB.value)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maxAmountB = `Insufficient balance ${
      formData.maxAmountB.token.code
    } ${formatTextNum(formData.balanceB.value, { noDecimalPlaces: 7 })}`;
  }

  // confirm that user is a signatory on the selected trading account
  let userIsSignatory = false;
  for (const k of formData.userKeys) {
    if (
      formData.selectedTradingAccViewModel &&
      formData.selectedTradingAccViewModel.signatories.find(
        (s) => s.key === k.publicKey,
      )
    ) {
      userIsSignatory = true;
      break;
    }
  }

  if (!userIsSignatory) {
    validationResult.valid = false;
    validationResult.fieldValidations.selectedTradingAccViewModel =
      "You are not a signatory on this account";
  }

  return validationResult;
};
