import {
  TransactionCompleteNotification,
  TransactionCompleteNotificationTypeName,
  TransactionRejectedNotification,
  TransactionRejectedNotificationTypeName,
  TransactionRolledBackNotification,
  TransactionRolledBackNotificationTypeName,
} from "consistency/horizon";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
} from "james/views/marketDirectOrderView";
import {
  MarketSpotViewModelChangedNotification,
  MarketSpotViewModelChangedNotificationTypeName,
} from "james/views/marketSpotView";
import {
  ModelChangedNotification as StellarClaimableBalanceViewModelChangedNotification,
  ModelChangedNotificationTypeName as StellarModelChangedNotificationTypeName,
} from "james/views/stellarClaimableBalanceView/ModelChangedNotification";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "../ledger/TransactionNotifications";
import {
  AccountChangedNotification,
  AccountChangedNotificationTypeName,
} from "../stellar/AccountChangedNotification";
import { AccountOpenedNotification } from "../stellar";
import { AccountOpenedNotificationTypeName } from "../stellar/AccountOpenedNotification";
import {
  ModelChangedNotification as MarketSubscriptionViewModelChangedNotification,
  ModelChangedNotificationTypeName as SubscriptionOrderViewModelChangedNotificationTypeName,
} from "james/views/marketSubscriptionOrderView";

export interface Notification {
  ["@type"]: string;

  notificationType(): string;
}

export function NewNotification(n: Notification): Notification {
  switch (n["@type"]) {
    case TransactionCompleteNotificationTypeName:
      return new TransactionCompleteNotification(
        n as TransactionCompleteNotification,
      );

    case TransactionRolledBackNotificationTypeName:
      return new TransactionRolledBackNotification(
        n as TransactionRolledBackNotification,
      );

    case TransactionRejectedNotificationTypeName:
      return new TransactionRejectedNotification(
        n as TransactionRejectedNotification,
      );

    case MarketDirectOrderViewModelChangedNotificationTypeName:
      return new MarketDirectOrderViewModelChangedNotification(
        n as MarketDirectOrderViewModelChangedNotification,
      );

    case SubscriptionOrderViewModelChangedNotificationTypeName:
      return new MarketSubscriptionViewModelChangedNotification(
        n as MarketSubscriptionViewModelChangedNotification,
      );

    case MarketSpotViewModelChangedNotificationTypeName:
      return new MarketSpotViewModelChangedNotification(
        n as MarketSpotViewModelChangedNotification,
      );

    case TransactionSucceededNotificationTypeName:
      return new TransactionSucceededNotification(
        n as TransactionSucceededNotification,
      );

    case TransactionFailedNotificationTypeName:
      return new TransactionFailedNotification(
        n as TransactionFailedNotification,
      );

    case TransactionSubmissionResolutionFailedNotificationTypeName:
      return new TransactionSubmissionResolutionFailedNotification(
        n as TransactionSubmissionResolutionFailedNotification,
      );

    case StellarModelChangedNotificationTypeName:
      return new StellarClaimableBalanceViewModelChangedNotification(
        n as StellarClaimableBalanceViewModelChangedNotification,
      );

    case AccountChangedNotificationTypeName:
      return new AccountChangedNotification(n as AccountChangedNotification);

    case AccountOpenedNotificationTypeName:
      return new AccountOpenedNotification(n as AccountOpenedNotification);

    default:
      throw new TypeError(`unknown notification type ${n["@type"]}`);
  }
}
