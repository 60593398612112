export class Format {
  public code = "";

  public fraction = 2;

  public grapheme = "$";

  public template = "$1";

  public decimal = ".";

  public thousand = ",";

  constructor(format?: Format) {
    if (!format) {
      return;
    }
    this.code = format.code;
    this.fraction = format.fraction;
    this.grapheme = format.grapheme;
    this.template = format.template;
    this.decimal = format.decimal;
    this.thousand = format.thousand;
  }
}

export const getFormat = (code: string): Format =>
  formats.find((f) => f.code === code) || new Format();

// TODO: Load these from server side so that we have a single source of truth
const formats: Format[] = [
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AED",
    fraction: 2,
    grapheme: ".\u062f.\u0625",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AFN",
    fraction: 2,
    grapheme: "\u060b",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ALL",
    fraction: 2,
    grapheme: "L",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AMD",
    fraction: 2,
    grapheme: "\u0564\u0580.",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "ANG",
    fraction: 2,
    grapheme: "\u0192",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AOA",
    fraction: 2,
    grapheme: "Kz",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ARS",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AUD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AWG",
    fraction: 2,
    grapheme: "\u0192",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "AZN",
    fraction: 2,
    grapheme: "\u20bc",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BAM",
    fraction: 2,
    grapheme: "KM",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BBD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BDT",
    fraction: 2,
    grapheme: "\u09f3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BGN",
    fraction: 2,
    grapheme: "\u043b\u0432",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BHD",
    fraction: 3,
    grapheme: ".\u062f.\u0628",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BIF",
    fraction: 0,
    grapheme: "Fr",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BMD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BND",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BOB",
    fraction: 2,
    grapheme: "Bs.",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BRL",
    fraction: 2,
    grapheme: "R$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BSD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BTN",
    fraction: 2,
    grapheme: "Nu.",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BWP",
    fraction: 2,
    grapheme: "P",
    template: "$1",
  }),
  new Format({
    decimal: ",",
    thousand: " ",
    code: "BYN",
    fraction: 2,
    grapheme: "p.",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: " ",
    code: "BYR",
    fraction: 0,
    grapheme: "p.",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BZD",
    fraction: 2,
    grapheme: "BZ$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CAD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CDF",
    fraction: 2,
    grapheme: "FC",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CHF",
    fraction: 2,
    grapheme: "CHF",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "CLF",
    fraction: 4,
    grapheme: "UF",
    template: "$1",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "CLP",
    fraction: 0,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CNY",
    fraction: 2,
    grapheme: "\u5143",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "COP",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CRC",
    fraction: 2,
    grapheme: "\u20a1",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CUC",
    fraction: 2,
    grapheme: "$",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CUP",
    fraction: 2,
    grapheme: "$MN",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CVE",
    fraction: 2,
    grapheme: "$",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "CZK",
    fraction: 2,
    grapheme: "K\u010d",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "DJF",
    fraction: 0,
    grapheme: "Fdj",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "DKK",
    fraction: 2,
    grapheme: "kr",
    template: "$ 1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "DOP",
    fraction: 2,
    grapheme: "RD$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "DZD",
    fraction: 2,
    grapheme: ".\u062f.\u062c",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "EEK",
    fraction: 2,
    grapheme: "kr",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "EGP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ERN",
    fraction: 2,
    grapheme: "Nfk",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ETB",
    fraction: 2,
    grapheme: "Br",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "EUR",
    fraction: 2,
    grapheme: "\u20ac",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "FJD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "FKP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GBP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GEL",
    fraction: 2,
    grapheme: "\u10da",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GGP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GHC",
    fraction: 2,
    grapheme: "\u00a2",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GHS",
    fraction: 2,
    grapheme: "\u20b5",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GIP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GMD",
    fraction: 2,
    grapheme: "D",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GNF",
    fraction: 0,
    grapheme: "FG",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GTQ",
    fraction: 2,
    grapheme: "Q",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "GYD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "HKD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "HNL",
    fraction: 2,
    grapheme: "L",
    template: "$1",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "HRK",
    fraction: 2,
    grapheme: "kn",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "HTG",
    fraction: 2,
    grapheme: "G",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "HUF",
    fraction: 0,
    grapheme: "Ft",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "IDR",
    fraction: 2,
    grapheme: "Rp",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ILS",
    fraction: 2,
    grapheme: "\u20aa",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "IMP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "INR",
    fraction: 2,
    grapheme: "\u20b9",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "IQD",
    fraction: 3,
    grapheme: ".\u062f.\u0639",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "IRR",
    fraction: 2,
    grapheme: "\ufdfc",
    template: "1 $",
  }),
  new Format({
    decimal: ",",
    thousand: ".",
    code: "ISK",
    fraction: 0,
    grapheme: "kr",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "JEP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "JMD",
    fraction: 2,
    grapheme: "J$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "JOD",
    fraction: 3,
    grapheme: ".\u062f.\u0625",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "JPY",
    fraction: 0,
    grapheme: "\u00a5",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KES",
    fraction: 2,
    grapheme: "KSh",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KGS",
    fraction: 2,
    grapheme: "\u0441\u043e\u043c",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KHR",
    fraction: 2,
    grapheme: "\u17db",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KMF",
    fraction: 0,
    grapheme: "CF",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KPW",
    fraction: 0,
    grapheme: "\u20a9",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KRW",
    fraction: 0,
    grapheme: "\u20a9",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KWD",
    fraction: 3,
    grapheme: ".\u062f.\u0643",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KYD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "KZT",
    fraction: 2,
    grapheme: "\u20b8",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LAK",
    fraction: 2,
    grapheme: "\u20ad",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LBP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LKR",
    fraction: 2,
    grapheme: "\u20a8",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LRD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LSL",
    fraction: 2,
    grapheme: "L",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LTL",
    fraction: 2,
    grapheme: "Lt",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LVL",
    fraction: 2,
    grapheme: "Ls",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "LYD",
    fraction: 3,
    grapheme: ".\u062f.\u0644",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MAD",
    fraction: 2,
    grapheme: ".\u062f.\u0645",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MDL",
    fraction: 2,
    grapheme: "lei",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MKD",
    fraction: 2,
    grapheme: "\u0434\u0435\u043d",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MMK",
    fraction: 2,
    grapheme: "K",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MNT",
    fraction: 2,
    grapheme: "\u20ae",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MOP",
    fraction: 2,
    grapheme: "P",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MUR",
    fraction: 2,
    grapheme: "\u20a8",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MVR",
    fraction: 2,
    grapheme: "MVR",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MWK",
    fraction: 2,
    grapheme: "MK",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MXN",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MYR",
    fraction: 2,
    grapheme: "RM",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "MZN",
    fraction: 2,
    grapheme: "MT",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "NAD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "NGN",
    fraction: 2,
    grapheme: "\u20a6",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "NIO",
    fraction: 2,
    grapheme: "C$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "NOK",
    fraction: 2,
    grapheme: "kr",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "NPR",
    fraction: 2,
    grapheme: "\u20a8",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "NZD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "OMR",
    fraction: 3,
    grapheme: "\ufdfc",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PAB",
    fraction: 2,
    grapheme: "B/.",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PEN",
    fraction: 2,
    grapheme: "S/",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PGK",
    fraction: 2,
    grapheme: "K",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PHP",
    fraction: 2,
    grapheme: "\u20b1",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PKR",
    fraction: 2,
    grapheme: "\u20a8",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PLN",
    fraction: 2,
    grapheme: "z\u0142",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "PYG",
    fraction: 0,
    grapheme: "Gs",
    template: "1$",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "QAR",
    fraction: 2,
    grapheme: "\ufdfc",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "RON",
    fraction: 2,
    grapheme: "lei",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "RSD",
    fraction: 2,
    grapheme: "\u0414\u0438\u043d.",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "RUB",
    fraction: 2,
    grapheme: "\u20bd",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "RUR",
    fraction: 2,
    grapheme: "\u20bd",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "RWF",
    fraction: 0,
    grapheme: "FRw",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SAR",
    fraction: 2,
    grapheme: "\ufdfc",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SBD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SCR",
    fraction: 2,
    grapheme: "\u20a8",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SDG",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SEK",
    fraction: 2,
    grapheme: "kr",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SGD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SHP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SKK",
    fraction: 2,
    grapheme: "Sk",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SLL",
    fraction: 2,
    grapheme: "Le",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SOS",
    fraction: 2,
    grapheme: "Sh",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SRD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SSP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "STD",
    fraction: 2,
    grapheme: "Db",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SVC",
    fraction: 2,
    grapheme: "\u20a1",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SYP",
    fraction: 2,
    grapheme: "\u00a3",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "SZL",
    fraction: 2,
    grapheme: "\u00a3",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "THB",
    fraction: 2,
    grapheme: "\u0e3f",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TJS",
    fraction: 2,
    grapheme: "SM",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TMT",
    fraction: 2,
    grapheme: "T",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TND",
    fraction: 3,
    grapheme: ".\u062f.\u062a",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TOP",
    fraction: 2,
    grapheme: "T$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TRL",
    fraction: 2,
    grapheme: "\u20a4",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TRY",
    fraction: 2,
    grapheme: "\u20ba",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TTD",
    fraction: 2,
    grapheme: "TT$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TWD",
    fraction: 0,
    grapheme: "NT$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "TZS",
    fraction: 0,
    grapheme: "TSh",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "UAH",
    fraction: 2,
    grapheme: "\u20b4",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "UGX",
    fraction: 0,
    grapheme: "USh",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "USD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "UYU",
    fraction: 2,
    grapheme: "$U",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "UZS",
    fraction: 2,
    grapheme: "so\u2019m",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "VEF",
    fraction: 2,
    grapheme: "Bs",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "VND",
    fraction: 0,
    grapheme: "\u20ab",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "VUV",
    fraction: 0,
    grapheme: "Vt",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "WST",
    fraction: 2,
    grapheme: "T",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "XAF",
    fraction: 0,
    grapheme: "Fr",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "XAG",
    fraction: 0,
    grapheme: "oz t",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "XAU",
    fraction: 0,
    grapheme: "oz t",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "XCD",
    fraction: 2,
    grapheme: "$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "XDR",
    fraction: 0,
    grapheme: "SDR",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "YER",
    fraction: 2,
    grapheme: "\ufdfc",
    template: "1 $",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ZAR",
    fraction: 2,
    grapheme: "R",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ZMW",
    fraction: 2,
    grapheme: "ZK",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "ZWD",
    fraction: 2,
    grapheme: "Z$",
    template: "$1",
  }),
  new Format({
    decimal: ".",
    thousand: ",",
    code: "BTC",
    fraction: 8,
    grapheme: "\u20bf",
    template: "$1",
  }),
];

export { formats };
