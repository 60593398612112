import { Holding, SectorAllocation } from "james/financial";
import { DataSheetSection } from "./DataSheetSection";

export const AssetSectorAllocationsAndHoldingsDataSheetSectionTypeName =
  "mesh::marketData/AssetSectorAllocationsAndHoldings";

export const AssetSectorAllocationsAndHoldingsDataSheetSectionName =
  "Sector Allocations & Holdings";

export class AssetSectorAllocationsAndHoldingsDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = AssetSectorAllocationsAndHoldingsDataSheetSectionTypeName;

  public sectorAllocations: SectorAllocation[] = [];

  public holdings: Holding[] = [];

  constructor(l?: AssetSectorAllocationsAndHoldingsDataSheetSection) {
    if (!l) {
      return;
    }

    this.holdings = l.holdings.map((v) => new Holding(v));
    this.sectorAllocations = l.sectorAllocations.map(
      (v) => new SectorAllocation(v),
    );
  }

  public DataSheetSectionName(): string {
    return AssetSectorAllocationsAndHoldingsDataSheetSectionName;
  }
}
