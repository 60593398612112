export enum SourceOfIncomeAndWealthType {
  Allowances = "Allowances",
  Bursary = "Bursary",
  CourtOrder = "Court Order",
  LoanFinancialInstitution = "Loan (Financial Institution)",
  LoanOther = "Loan (Other)",
  Maintenance = "Maintenance",
  MaturingInvestments = "Maturing Investments",
  Pension = "Pension",
  RentalIncome = "Rental Income",
  CompanyProfits = "Company Profits",
  CompanySale = "Company Sale",
  DeceasedEstate = "Deceased Estate",
  DivorceSettlement = "Divorce Settlement",
  GiftOrDonation = "Gift / Donation",
  IncomeFromEmployment = "Income from Employment",
  IncomeFromPreviousEmployment = "Income from Previous Employment",
  Inheritance = "Inheritance",
  LotteryWinningsOrGambling = "Lottery / Winnings / Gambling",
  SaleOfAsset = "Sale of Asset",
  SaleOfShares = "Sale of Shares",
  SavingsInvestmentOrDividend = "Savings  / Investment / Dividend",
  TrustDistributions = "Trust Distributions",
}

export const ValidSourceOfIncomeTypes = [
  SourceOfIncomeAndWealthType.Allowances,
  SourceOfIncomeAndWealthType.Bursary,
  SourceOfIncomeAndWealthType.CourtOrder,
  SourceOfIncomeAndWealthType.LoanFinancialInstitution,
  SourceOfIncomeAndWealthType.LoanOther,
  SourceOfIncomeAndWealthType.Maintenance,
  SourceOfIncomeAndWealthType.MaturingInvestments,
  SourceOfIncomeAndWealthType.Pension,
  SourceOfIncomeAndWealthType.RentalIncome,
  SourceOfIncomeAndWealthType.CompanyProfits,
  SourceOfIncomeAndWealthType.CompanySale,
  SourceOfIncomeAndWealthType.DeceasedEstate,
  SourceOfIncomeAndWealthType.DivorceSettlement,
  SourceOfIncomeAndWealthType.GiftOrDonation,
  SourceOfIncomeAndWealthType.IncomeFromEmployment,
  SourceOfIncomeAndWealthType.IncomeFromPreviousEmployment,
  SourceOfIncomeAndWealthType.Inheritance,
  SourceOfIncomeAndWealthType.LotteryWinningsOrGambling,
  SourceOfIncomeAndWealthType.SaleOfAsset,
  SourceOfIncomeAndWealthType.SaleOfShares,
  SourceOfIncomeAndWealthType.SavingsInvestmentOrDividend,
  SourceOfIncomeAndWealthType.TrustDistributions,
];

export const ValidSourceOfWealthTypes = [
  SourceOfIncomeAndWealthType.CompanyProfits,
  SourceOfIncomeAndWealthType.CompanySale,
  SourceOfIncomeAndWealthType.DeceasedEstate,
  SourceOfIncomeAndWealthType.DivorceSettlement,
  SourceOfIncomeAndWealthType.GiftOrDonation,
  SourceOfIncomeAndWealthType.IncomeFromEmployment,
  SourceOfIncomeAndWealthType.IncomeFromPreviousEmployment,
  SourceOfIncomeAndWealthType.Inheritance,
  SourceOfIncomeAndWealthType.LotteryWinningsOrGambling,
  SourceOfIncomeAndWealthType.SaleOfAsset,
  SourceOfIncomeAndWealthType.SaleOfShares,
  SourceOfIncomeAndWealthType.SavingsInvestmentOrDividend,
  SourceOfIncomeAndWealthType.TrustDistributions,
];
