import { TextListCriterionTypeName } from "james/search/criterion";

export interface TextListCriterionType {
  type: string;
  list: string[];
}

function TextListCriterion(list: string[]): TextListCriterionType {
  return {
    type: TextListCriterionTypeName,
    list,
  };
}

export { TextListCriterion };
