import React, { useMemo, useState } from "react";
import { Amount } from "james/ledger";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { TextNumField } from "components/FormFields";
import { formatTextNum, getNoDecimals } from "utilities/number";
import { ValidationResult } from "common/validation";
import ReportProblemSharpIcon from "@mui/icons-material/ReportProblemSharp";
import BigNumber from "bignumber.js";

type BurnAssertCardProps = {
  onSubmitButtonClick: () => void;
  tokensToBurnBalance?: Amount;
  tokensToBurn: Amount;
  tokensToBurnIssuerName: string;
  onTokensToBurnChange: (tokensToBurn: Amount) => void;
  fee?: Amount;
  vat?: Amount;
  feeCalcInProgress: boolean;
  feePaymentTokenBalance?: Amount;
  feePaymentTokenIssuerName: string;
  feeAccountName: string;
  feeCalculationInProgress?: boolean;
  onCloseButtonClick: () => void;
  onMaxButtonClick: () => void;
  userIsSignatoryOnAccount: boolean;
  validationResult: ValidationResult;
};

export function BurnAssetCard(props: BurnAssertCardProps) {
  const [expandFeeSection, setExpandFeeSection] = useState(false);

  const tokensToBurnDecimals = useMemo(
    (): number => getNoDecimals(props.tokensToBurn.value),
    [props.tokensToBurn],
  );

  return (
    <Card>
      <CardHeader
        sx={{
          padding: (theme) => theme.spacing(2, 3, 2, 3),
        }}
        title={
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
            }}
          >
            <Typography component={"div"} variant="h5">
              Burn Tokens
            </Typography>
            <Box>
              <IconButton
                id="BurnAssetCard-close-button"
                onClick={props.onCloseButtonClick}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        }
      />
      <CardContent
        sx={{
          padding: "40px !important",
        }}
      >
        <Typography
          children={"How many tokens do you want to burn?"}
          variant={"h6"}
          component={"div"}
          sx={{
            marginBottom: "16px",
          }}
        />
        {/* No. of tokens to burn form field*/}
        <TextNumField
          fullWidth
          id={"burnAssetDialog-NoTokens-FormField"}
          noDecimalPlaces={7}
          helperText={(() => {
            if (props.validationResult.fieldValidations.tokensToBurn) {
              return props.validationResult.fieldValidations.tokensToBurn;
            }

            return `Available Balance: ${
              props.tokensToBurnBalance?.token.code
            } ${formatTextNum(
              props.tokensToBurnBalance ? props.tokensToBurnBalance.value : "",
              {
                noDecimalPlaces: tokensToBurnDecimals,
              },
            )}`;
          })()}
          error={!!props.validationResult.fieldValidations.tokensToBurn}
          label="Tokens"
          disallowNegative
          InputProps={{
            startAdornment: (
              <Tooltip
                title={`Issued by ${props.tokensToBurnIssuerName}`}
                placement="top"
              >
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    marginRight: 2,
                    color: theme.palette.text.secondary,
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.light,
                    },
                  })}
                  children={props.tokensToBurn.token.code}
                />
              </Tooltip>
            ),
            endAdornment: (
              <Box
                sx={{
                  padding: 0,
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <Typography
                  id="BurnAssetCard-max-typography"
                  color={"secondary"}
                  onClick={props.onMaxButtonClick}
                >
                  MAX
                </Typography>
              </Box>
            ),
          }}
          sx={(theme) => ({
            paddingRight: { sm: theme.spacing(1) },
            marginBottom: "16px",
          })}
          onChange={(e) => {
            props.onTokensToBurnChange(
              props.tokensToBurn.setValue(e.target.value),
            );
          }}
          value={props.tokensToBurn.value}
        />

        {/* Burn fee collapse section */}

        {!props.fee?.isUndefined() && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "24px",
                height: "16px",
              }}
            >
              <IconButton
                id="BurnAssetCard-expand-button"
                onClick={() => setExpandFeeSection(!expandFeeSection)}
                size="small"
              >
                {!expandFeeSection && <KeyboardArrowDown color="primary" />}
                {expandFeeSection && <KeyboardArrowUp color="primary" />}
              </IconButton>
              <Typography
                sx={{
                  marginLeft: (theme) => theme.spacing(0.5),
                  marginRight: (theme) => theme.spacing(1),
                }}
                color="textSecondary"
                variant="body1"
                children="Total Fee:"
              />
              {props.feeCalcInProgress ? (
                <Skeleton width={"100px"} height={"24px"} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ marginRight: "4px" }}
                    variant="body1"
                    component={"p"}
                  >
                    {props.fee && (
                      <>
                        <Tooltip
                          title={`Issued by ${props.feePaymentTokenIssuerName}`}
                          placement="top"
                        >
                          <Typography
                            component={"span"}
                            color={
                              props.validationResult.fieldValidations.fee
                                ? "error"
                                : undefined
                            }
                            sx={(theme) => ({
                              cursor: "pointer",
                              "&:hover": {
                                color: theme.palette.primary.light,
                              },
                            })}
                          >
                            {props.fee.token.code}&nbsp;
                          </Typography>
                        </Tooltip>
                        <Typography
                          color={
                            props.validationResult.fieldValidations.fee
                              ? "error"
                              : undefined
                          }
                          component={"span"}
                        >
                          {formatTextNum(
                            props.fee.value.plus(
                              props.vat?.value || BigNumber("0"),
                            ),
                          )}
                        </Typography>
                      </>
                    )}
                  </Typography>
                  {props.validationResult.fieldValidations.fee && (
                    <ReportProblemSharpIcon
                      fontSize={"small"}
                      color={"error"}
                    />
                  )}
                </Box>
              )}
            </Box>
            <Collapse timeout={200} in={expandFeeSection}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: (theme) => theme.spacing(0, 0, 1, 5),
                }}
              >
                <Link
                  sx={{
                    cursor: "pointer",
                    marginBottom: (theme) => theme.spacing(2),
                    marginTop: (theme) => theme.spacing(2),
                  }}
                  variant={"body1"}
                  color="secondary"
                  target="_blank"
                  underline={"hover"}
                  href="https://mesh.trade/fees"
                  children={"Why these fees?"}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginRight: (theme) => theme.spacing(2),
                    }}
                  >
                    Fee Acc:
                  </Typography>
                  <Typography noWrap variant="body2" component="span">
                    {props.feeAccountName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginRight: (theme) => theme.spacing(2),
                    }}
                  >
                    Burn Fee:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={`Issued by ${props.feePaymentTokenIssuerName}`}
                      placement="top"
                    >
                      <Typography
                        color={
                          props.validationResult.fieldValidations.fee
                            ? "error"
                            : undefined
                        }
                        sx={(theme) => ({
                          margin: theme.spacing(0, 0.5, 0, 1),
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.primary.light,
                          },
                        })}
                        variant="body2"
                        children={props.fee ? props.fee.token.code : ""}
                      />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      color={
                        props.validationResult.fieldValidations.fee
                          ? "error"
                          : undefined
                      }
                      children={
                        props.fee
                          ? formatTextNum(props.fee.value, {
                              noDecimalPlaces: 2,
                            })
                          : ""
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginRight: (theme) => theme.spacing(2),
                    }}
                  >
                    VAT on Fee:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={`Issued by ${props.feePaymentTokenIssuerName}`}
                      placement="top"
                    >
                      <Typography
                        color={
                          props.validationResult.fieldValidations.vat
                            ? "error"
                            : undefined
                        }
                        sx={(theme) => ({
                          margin: theme.spacing(0, 0.5, 0, 1),
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.primary.light,
                          },
                        })}
                        variant="body2"
                        children={props.vat ? props.vat.token.code : ""}
                      />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      color={
                        props.validationResult.fieldValidations.vat
                          ? "error"
                          : undefined
                      }
                      children={
                        props.vat
                          ? formatTextNum(props.vat.value, {
                              noDecimalPlaces: 2,
                            })
                          : ""
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "60px 1fr",
                  }}
                >
                  <Typography
                    component={"div"}
                    color="textSecondary"
                    variant="body2"
                    children="Available"
                  />
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={`Issued by ${props.feePaymentTokenIssuerName}`}
                      placement="top"
                    >
                      <Typography
                        color={
                          props.validationResult.fieldValidations.fee
                            ? "error"
                            : undefined
                        }
                        sx={(theme) => ({
                          margin: theme.spacing(0, 0.5, 0, 1),
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.primary.light,
                          },
                        })}
                        variant="body2"
                        children={
                          props.feePaymentTokenBalance
                            ? props.feePaymentTokenBalance.token.code
                            : ""
                        }
                      />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      color={
                        props.validationResult.fieldValidations.fee
                          ? "error"
                          : undefined
                      }
                      children={
                        props.feePaymentTokenBalance
                          ? formatTextNum(props.feePaymentTokenBalance.value, {
                              noDecimalPlaces: 2,
                            })
                          : ""
                      }
                    />
                  </Box>
                  <Box />
                  {props.validationResult.fieldValidations.fee && (
                    <Typography
                      component={"div"}
                      variant="body2"
                      color={"error"}
                      sx={{ marginLeft: "8px" }}
                      children={"Insufficient Funds"}
                    />
                  )}
                </Box>
              </Box>
            </Collapse>
          </>
        )}

        {/* Submit Button*/}
        <Tooltip
          title={
            !props.userIsSignatoryOnAccount
              ? "You are not a signatory on this account"
              : ""
          }
          placement="top"
        >
          <Box
            sx={{
              marginTop: "24px",
              display: "flex",
            }}
            component={"span"}
          >
            <Button
              id="BurnAssetCard-Submit-Button"
              sx={{
                height: {
                  xs: "48px",
                  sm: "36px",
                },
              }}
              onClick={props.onSubmitButtonClick}
              fullWidth
              color="primary"
              variant="contained"
              disabled={
                !props.userIsSignatoryOnAccount ||
                props.tokensToBurn.value.isZero() ||
                !props.validationResult.valid
              }
            >
              Submit
            </Button>
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
