import React from "react";
import { styled } from "@mui/material/styles";
import { Model as MarketSpotViewModel } from "james/views/marketSpotView";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
const PREFIX = "SpotTradeFailedDialog";

const classes = {
  list: `${PREFIX}-list`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.list}`]: {
    paddingLeft: theme.spacing(1.8),
  },
}));

export type Props = {
  marketSpotViewModel: MarketSpotViewModel;
  closeDialog: () => void;
};

export const SpotTradeFailedDialog = (props: Props) => {
  return (
    <StyledDialog
      open
      PaperProps={{
        sx: {
          margin: 0,
          width: {
            xs: "100%",
            sm: "510px",
          },
          position: {
            xs: "fixed",
            sm: "relative",
          },
          bottom: {
            xs: 0,
          },
          borderBottomLeftRadius: {
            xs: 0,
            sm: "10px",
          },
          borderBottomRightRadius: {
            xs: 0,
            sm: "10px",
          },
        },
      }}
    >
      <DialogTitle
        id={"marketSpotTradeFailedDialog-title"}
        sx={(theme) => ({
          padding: {
            sm: theme.spacing(1, 1, 1, 3),
            xs: theme.spacing(1, 1, 1, 2),
          },
        })}
      >
        <Grid container direction={"row"} spacing={2} alignItems={"center"}>
          <Grid item>
            <Typography
              color={"error"}
              variant={"subtitle1"}
              children={`Trade #${props.marketSpotViewModel.number} has failed`}
            />
          </Grid>
        </Grid>
        <Tooltip title={"Close"} placement={"top"}>
          <IconButton
            id={"marketSpotTradeFailedDialog-close-iconButton"}
            size={"small"}
            onClick={props.closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          padding: `${theme.spacing(3)} !important`,
        })}
      >
        <Typography variant={"h6"} sx={{ marginBottom: 1 }}>
          Unfortunately your trade could not be processed due to the following
          reason(s):
        </Typography>
        <ul className={classes.list}>
          {props.marketSpotViewModel.failureReasons.map((reason, idx) => (
            <Typography
              key={idx}
              variant={"body1"}
              component={"li"}
              sx={{ color: "text.disabled" }}
              children={reason}
            />
          ))}
        </ul>
        <Typography
          variant={"body1"}
          sx={{
            color: "text.disabled",
            marginTop: 2,
            marginBottom: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          Please Rebook
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};
