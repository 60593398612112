import { ConnectedCompany } from "james/legal/ConnectedCompany";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import { ValidateEmail } from "utilities/validation";

export const FormValidator = (field: string, value: string): string[] => {
  const errorMessages: string[] = [];
  if (field === "companyRepresentativeEmailAddress") {
    if (String(value) !== "" && !ValidateEmail(String(value))) {
      errorMessages.push("Email is invalid");
    }
  }
  return errorMessages;
};

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  nonIndividual: ConnectedCompany,
  setFormFieldValid?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let InvalidFormFields = {};

  if (nonIndividual.businessName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessName: "Business name is not provided",
    };
  }

  if (nonIndividual.registeredName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      registeredName: "Registered name is not provided",
    };
  }

  if (nonIndividual.registrationNumber === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      registrationNumber: "Registration number is not provided",
    };
  }

  if (nonIndividual.legalForm === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      legalForm: "Legal form is not provided",
    };
  }

  if (nonIndividual.companyRepresentative.firstName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      companyRepresentativeFirstName:
        "Company representative name not provided",
    };
  }

  if (nonIndividual.companyRepresentative.lastName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      companyRepresentativeLastName:
        "Company representative last name not provided",
    };
  }

  if (
    nonIndividual.companyRepresentative.emailAddress === "" &&
    nonIndividual.companyRepresentative.cellphoneNumber === "" &&
    nonIndividual.companyRepresentative.telephoneNumber === ""
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      companyRepresentativeEmailAddress: "Enter at least 1",
      companyRepresentativeCellphoneNumber: "Enter at least 1",
      companyRepresentativeTelephoneNumber: "Enter at least 1",
    };
  }

  if (
    nonIndividual.companyRepresentative.emailAddress !== "" &&
    !ValidateEmail(String(nonIndividual.companyRepresentative.emailAddress))
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      companyRepresentativeEmailAddress: "Email is invalid",
    };
  }

  if (nonIndividual.businessAddress) {
    if (nonIndividual.businessAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressAddressLine1: "Address line is missing",
      };
    }

    if (nonIndividual.businessAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressCity: "City is missing",
      };
    }

    if (nonIndividual.businessAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressCountryCode: "Country code is missing",
      };
    }

    if (nonIndividual.businessAddress.postalCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressPostalCode: "Postal code is required",
      };
    }

    if (nonIndividual.businessAddress.suburb === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressSuburb: "Suburb is required",
      };
    }

    if (nonIndividual.businessAddress.province === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressProvince: "Province is required",
      };
    }
  }
  if (
    nonIndividual.headOfficeAddress &&
    !isEqual(new Address(nonIndividual.headOfficeAddress), new Address())
  ) {
    if (nonIndividual.headOfficeAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        headOfficeAddressAddressLine1: "Address line is missing",
      };
    }

    if (nonIndividual.headOfficeAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        headOfficeAddressCity: "City is missing",
      };
    }

    if (nonIndividual.headOfficeAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        headOfficeAddressCountryCode: "Country code is missing",
      };
    }
  }

  if (setFormFieldValid) {
    setFormFieldValid({ ...InvalidFormFields });
  }

  return !Object.keys(InvalidFormFields).length;
};

export const FormValidationState: { [key: string]: string | undefined } = {};
