import { Entry } from "james/audit/Entry";
import { Transaction } from "./Transaction";

export class ValidationPhase {
  public id = "";

  public running = false;

  public lastActionAnnotation = "";

  public acceptedTransactions: Transaction[] = [];

  public auditEntry: Entry = new Entry();

  constructor(validationPhase?: ValidationPhase) {
    if (!validationPhase) {
      return;
    }
    this.id = validationPhase.id;
    this.running = validationPhase.running;
    this.lastActionAnnotation = validationPhase.lastActionAnnotation;
    this.auditEntry = new Entry(validationPhase.auditEntry);
    this.acceptedTransactions = validationPhase.acceptedTransactions.map(
      (t) => new Transaction(t),
    );
  }
}
