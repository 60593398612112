import { SmartInstrumentAttributeWrapper } from "@mesh/common-js/dist/financial";
import { SmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttribute_pb";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { ValidationResult } from "common/validation";
import {
  validateSectorAllocationsAttribute,
  validateCountryAllocationsAttribute,
  validateFiatCurrencyAllocationsAttribute,
  validateHoldingAllocationsAttribute,
  validateExternalParticipantsInformationAttribute,
  validateAssetClassAttribute,
  validateAnnualPerformanceLogAttribute,
} from "./components";
import { validateRiskAssessmentAttribute } from "./components/RiskAssessmentAttributeForm";

export function validateAttribute(
  attribute: SmartInstrumentAttribute,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // validate each attribute
  const attributeType = new SmartInstrumentAttributeWrapper(attribute)
    .smartInstrumentAttributeType;
  switch (attributeType) {
    case SmartInstrumentAttributeType.SECTOR_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const sectorAllocationsAttribute =
        attribute.getSectorallocationssmartinstrumentattribute();
      if (!sectorAllocationsAttribute) {
        throw new Error("sector allocations attribute is not set");
      }
      return validateSectorAllocationsAttribute(sectorAllocationsAttribute);
    }

    case SmartInstrumentAttributeType.COUNTRY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const countryAllocationsAttribute =
        attribute.getCountryallocationssmartinstrumentattribute();
      if (!countryAllocationsAttribute) {
        throw new Error("country allocations attribute is not set");
      }
      return validateCountryAllocationsAttribute(countryAllocationsAttribute);
    }

    case SmartInstrumentAttributeType.FIAT_CURRENCY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const fiatCurrencyAllocationsAttribute =
        attribute.getFiatcurrencyallocationssmartinstrumentattribute();
      if (!fiatCurrencyAllocationsAttribute) {
        throw new Error("fiat currency allocations attribute is not set");
      }
      return validateFiatCurrencyAllocationsAttribute(
        fiatCurrencyAllocationsAttribute,
      );
    }

    case SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const holdingsAllocationsAttribute =
        attribute.getHoldingallocationssmartinstrumentattribute();
      if (!holdingsAllocationsAttribute) {
        throw new Error("holding allocations attribute is not set");
      }
      return validateHoldingAllocationsAttribute(holdingsAllocationsAttribute);
    }

    case SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const externalParticipantsInformationAttribute =
        attribute.getExternalparticipantsinformationsmartinstrumentattribute();
      if (!externalParticipantsInformationAttribute) {
        throw new Error(
          "external participants information attribute is not set",
        );
      }
      return validateExternalParticipantsInformationAttribute(
        externalParticipantsInformationAttribute,
      );
    }

    case SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const riskAssessmentAttribute =
        attribute.getRiskassessmentsmartinstrumentattribute();
      if (!riskAssessmentAttribute) {
        throw new Error("risk assessment attribute is not set");
      }
      return validateRiskAssessmentAttribute(riskAssessmentAttribute);
    }

    case SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      const assetClassAttrbiute =
        attribute.getAssetclasssmartinstrumentattribute();
      if (!assetClassAttrbiute) {
        throw new Error("asset class attribute is not set");
      }
      return validateAssetClassAttribute(assetClassAttrbiute);
    }

    case SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE: {
      // uncomment the following if validation is required - atm. no validation required
      // const annualPerformanceLogAttribute =
      //   attribute.getAnnualperformancelogsmartinstrumentattribute();
      // if (!annualPerformanceLogAttribute) {
      //   throw new Error("annual performance log attribute is not set");
      // }
      return validateAnnualPerformanceLogAttribute();
      // uncomment the following if validation is required - atm. no validation required
      // annualPerformanceLogAttribute,
    }

    case SmartInstrumentAttributeType.UNDEFINED_SMART_INSTRUMENT_ATTRIBUTE_TYPE:
    default:
      console.error("unknown SmartInstrumentAttributeType: ", attributeType);
      validationResult.valid = false;
  }

  return validationResult;
}
