import { FinancialETNRollCorporateActionViewModel } from "james/views/financialETNRollCorporateActionView";
import { DataSheetSection } from "./DataSheetSection";

export const ETNRollCorporateActionsDataSheetSectionTypeName =
  "mesh::marketData/ETNRollCorporateActions";

export const ETNRollCorporateActionsDataSheetSectionName = "Corporate Actions";

export class ETNRollCorporateActionsDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = ETNRollCorporateActionsDataSheetSectionTypeName;

  public corporateActions: FinancialETNRollCorporateActionViewModel[] = [];

  public DataSheetSectionName(): string {
    return ETNRollCorporateActionsDataSheetSectionName;
  }

  constructor(a?: ETNRollCorporateActionsDataSheetSection) {
    if (!a) {
      return;
    }
    this.corporateActions = a.corporateActions.map(
      (v) => new FinancialETNRollCorporateActionViewModel(v),
    );
  }
}
