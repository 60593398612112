import { ValidationResult } from "common/validation";
import { ClientType } from "james/client";
import { InviteCompanyClientRequest } from "james/client/ManagerWrite";
import { ValidateClientShortName, ValidateEmail } from "utilities/validation";

export type InviteCompanyFormType = Omit<InviteCompanyClientRequest, "context">;

export type InviteCompanyFormUpdateSpecs = {
  email: (
    value: string,
    prevData?: InviteCompanyFormType,
  ) => InviteCompanyFormType;
  firstName: (
    value: string,
    prevData?: InviteCompanyFormType,
  ) => InviteCompanyFormType;
  lastName: (
    value: string,
    prevData?: InviteCompanyFormType,
  ) => InviteCompanyFormType;
  companyName: (
    value: string,
    prevData?: InviteCompanyFormType,
  ) => InviteCompanyFormType;
  clientShortName: (
    value: string,
    prevData?: InviteCompanyFormType,
  ) => InviteCompanyFormType;
  clientTypes: (
    value: ClientType[],
    prevData?: InviteCompanyFormType,
  ) => InviteCompanyFormType;
};

export const InviteCompanyFormUpdater: InviteCompanyFormUpdateSpecs = {
  email: (value: string, prevData?: InviteCompanyFormType) => {
    prevData = prevData as InviteCompanyFormType;
    return { ...prevData, email: value };
  },
  firstName: (value: string, prevData?: InviteCompanyFormType) => {
    prevData = prevData as InviteCompanyFormType;
    return { ...prevData, firstName: value };
  },
  lastName: (value: string, prevData?: InviteCompanyFormType) => {
    prevData = prevData as InviteCompanyFormType;
    return { ...prevData, lastName: value };
  },
  companyName: (value: string, prevData?: InviteCompanyFormType) => {
    prevData = prevData as InviteCompanyFormType;
    return { ...prevData, companyName: value };
  },
  clientShortName: (value: string, prevData?: InviteCompanyFormType) => {
    prevData = prevData as InviteCompanyFormType;

    return { ...prevData, clientShortName: value };
  },
  clientTypes: (value: ClientType[], prevData?: InviteCompanyFormType) => {
    prevData = prevData as InviteCompanyFormType;
    return { ...prevData, clientTypes: value };
  },
};

export const performValidation = async (
  inviteClientRequest: InviteCompanyFormType,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!inviteClientRequest.firstName) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.firstName = "blank";
  }

  if (!inviteClientRequest.lastName) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.lastName = "blank";
  }

  if (!inviteClientRequest.email) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.email = "blank";
  } else if (!ValidateEmail(inviteClientRequest.email)) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.email = "invalid";
  }

  if (!inviteClientRequest.companyName) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.companyName = "blank";
  }

  if (!inviteClientRequest.clientShortName) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.clientShortName = "blank";
  } else if (!ValidateClientShortName(inviteClientRequest.clientShortName)) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.clientShortName = "invalid";
  }

  if (inviteClientRequest.clientTypes.length === 0) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.clientTypes = "choose at least 1";
  }

  return newValidationState;
};
