import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth, useFirebaseApp } from "reactfire";
import { redirectURLKey } from "routes/Route";
import { useErrorContext } from "context/Error";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  User,
  verifyPasswordResetCode,
  OAuthCredential,
  ProviderId,
  deleteUser,
} from "firebase/auth";
import { FirebaseUserCancelledError } from "context/Firebase/errors";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";

interface ContextType {
  firebaseContextUpdating: boolean; // indicates that firebase context is updating
  firebaseAuthenticated: boolean;
  firebaseUserIDToken: string;
  firebaseLoginWithGoogle: () => Promise<void>;
  firebaseLoginWithFacebook: () => Promise<void>;
  firebaseSignUpWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  firebaseLoginWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  firebaseLogout: () => Promise<void>;
  firebaseUserCleanup: () => Promise<void>;
  firebaseUser: User | null;
  firebaseResetPasswordEmail: (email: string) => Promise<void>;
  firebaseResetPassword: (password: string, code: string) => Promise<void>;
  firebaseVerifyPasswordResetCode: (code: string) => Promise<void>;
  firebaseRemoteConfig?: FirebaseRemoteConfig;
}

const credentialToLinkKey = "credentialToLink";
const selectedProviderKey = "selectedProvider";

export type FirebaseRemoteConfig = {
  showBanner?: boolean;
  showFullScreenNotice?: boolean;
  bannerConfig?: BannerRemoteConfig;
  marketplaceBannersConfig?: MarketplaceBannersRemoteConfig;
  fullScreenNoticeConfig?: FullScreenNoticeRemoteConfig;
};

export type BannerRemoteConfig = {
  title?: string;
  icon?: RibbonIconKeys;
  color?: string;
  disableClose?: boolean;
};

export type FullScreenNoticeRemoteConfig = {
  title?: string;
  text?: string[];
  buttonText?: string;
  buttonHref?: string;
};

export type MarketplaceBannersRemoteConfig = Record<
  string,
  { startAt: Date; expireAt: Date }
>;

type RibbonIconKeys = "warning" | "info";

const Context = React.createContext({} as ContextType);

export function FirebaseContext({ children }: { children?: React.ReactNode }) {
  const [updating, setUpdating] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [userIDToken, setUserIDToken] = useState("");
  const firebaseAuth = useAuth();
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    //
    // keeps auth state up to date
    //
    firebaseAuth.onAuthStateChanged(async (user: User | null) => {
      if (mounted) {
        setUpdating(true);
      }

      if (user) {
        if (mounted) {
          const providerDataEmail =
            user.providerData.length > 0 && user.providerData[0]?.email;
          const providerDataId =
            user.providerData.length > 0 && user.providerData[0]?.providerId;

          const userEmail = user.email || providerDataEmail;

          if (!userEmail) {
            if (providerDataId === "facebook.com") {
              enqueueSnackbar(
                `${
                  providerDataId ? providerDataId : "Your identity provider"
                } did not supply an email address please try another sign in method, or
                please ensure your primary email address is set on your facebook account and third party
                access is allowed
                `,
                // There might also be a slight time delay for new facebook accounts
                {
                  variant: "info",
                  persist: true,
                },
              );
            } else {
              enqueueSnackbar(
                `${
                  providerDataId ? providerDataId : "Your identity provider"
                } did not supply an email address please try another sign in method`,
                {
                  variant: "error",
                  persist: true,
                },
              );
            }

            setAuthenticated(false);
            setUserIDToken("");
            setFirebaseUser(null);
            setUpdating(false);
            return;
          }

          // if redirect URL has been set, navigate there
          const firebaseRedirectURL = sessionStorage.getItem(redirectURLKey);
          if (firebaseRedirectURL) {
            navigate(firebaseRedirectURL, { replace: true });
            sessionStorage.removeItem(redirectURLKey);
          }

          setUserIDToken(await user.getIdToken());
          setAuthenticated(true);
          setFirebaseUser(user);
        }
      } else if (mounted) {
        setAuthenticated(false);
        setUserIDToken("");
        setFirebaseUser(null);
      }
      setUpdating(false);
    });

    return () => {
      mounted = false;
    };
  }, [firebaseAuth]);

  // NOTE: to only be used on email verification screen!!!!
  const firebaseUserCleanup = async () => {
    if (!firebaseUser) {
      return;
    }

    if (!firebaseUser.emailVerified) {
      await deleteUser(firebaseUser);
    }
  };

  //
  // method which can be used to sign in with google
  //
  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    sessionStorage.setItem(selectedProviderKey, provider.providerId);
    await signInWithRedirect(firebaseAuth, provider);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAccountExistWithDifferentCredentials = (redirectError: any) => {
    try {
      let cred: OAuthCredential | null;

      const selectedProvider = sessionStorage.getItem(selectedProviderKey);
      sessionStorage.removeItem(selectedProviderKey);

      switch (selectedProvider) {
        case ProviderId.GOOGLE:
          cred = GoogleAuthProvider.credentialFromError(redirectError);
          break;
        case ProviderId.FACEBOOK:
          cred = FacebookAuthProvider.credentialFromError(redirectError);
          break;
        case ProviderId.PASSWORD:
        default:
          enqueueSnackbar("please use another login method");
          navigate("/sign-in");
      }
      const email = redirectError.email || redirectError.customData.email;

      // attempt to link login providers
      fetchSignInMethodsForEmail(firebaseAuth, email).then((providers) => {
        if (providers.length === 0) {
          throw "no existing providers";
        }

        // set credentials to link to
        sessionStorage.setItem(
          credentialToLinkKey,
          JSON.stringify(cred?.toJSON()),
        );

        // instantiate provider from available providers
        let provider;

        // TODO add case for:
        // else if (providers.includes(ProviderId.PASSWORD))
        // TODO need to prompt user for password - create task (need design)
        // then use signInWithEmailAndPassword, this will then trigger the linkWithCredential
        // For now error message only is returned

        if (providers.includes(ProviderId.GOOGLE)) {
          provider = new GoogleAuthProvider();
          provider.setCustomParameters({ login_hint: email });
        } else if (providers.includes(ProviderId.FACEBOOK)) {
          provider = new FacebookAuthProvider();
          provider.addScope("email");
        } else {
          enqueueSnackbar(
            "Please sign in using email or password, or using Google. Facebook login can be used once Google has been linked.",
            {
              variant: "info",
              persist: true,
            },
          );
          navigate("/sign-in");
        }

        if (provider) {
          signInWithRedirect(firebaseAuth, provider).finally();
        }
      });
    } catch (e) {
      console.error(
        "failed to link Facebook login to existing login providers",
        e,
      );
      Sentry.captureException(
        `failed to link Facebook login to existing login providers: ${e}`,
      );
      enqueueSnackbar(
        "Failed to link Facebook login to existing login providers, please use existing login provider",
        {
          variant: "error",
          persist: true,
        },
      );
    }
  };

  getRedirectResult(firebaseAuth)
    .then((redirectResult) => {
      const cred = sessionStorage.getItem(credentialToLinkKey);

      if (cred && redirectResult?.user) {
        const credObj = OAuthCredential.fromJSON(cred);

        if (!credObj) {
          return;
        }

        linkWithCredential(redirectResult.user, credObj).catch((e) => {
          errorContextDefaultErrorFeedback(e);
        });

        sessionStorage.removeItem(credentialToLinkKey);
      }
    })
    .catch((e) => {
      switch (e.code) {
        case "auth/account-exists-with-different-credential":
          handleAccountExistWithDifferentCredentials(e);
          return;
        case "auth/user-cancelled":
          errorContextDefaultErrorFeedback(new FirebaseUserCancelledError());
          return;
        default:
          Sentry.captureException(`unexpected firebase auth error: ${e}`);
          errorContextDefaultErrorFeedback(e);
      }
      console.error(e);
    });

  //
  // method which can be used to sign in with facebook
  //
  const loginWithFacebook = async () => {
    const fbP = new FacebookAuthProvider();
    fbP.addScope("email");
    sessionStorage.setItem(selectedProviderKey, fbP.providerId);
    await signInWithRedirect(firebaseAuth, fbP);
  };

  //
  // method which can be used to sign in with email address and password
  //
  const loginWithEmailAddressAndPassword = async (
    email: string,
    password: string,
  ) => {
    sessionStorage.setItem(selectedProviderKey, ProviderId.PASSWORD);
    await signInWithEmailAndPassword(firebaseAuth, email, password);
  };

  //
  // method which can be used to sign up with email address and password
  //
  const signUpWithEmailAndPassword = async (
    email: string,
    password: string,
  ) => {
    sessionStorage.setItem(selectedProviderKey, ProviderId.PASSWORD);
    await createUserWithEmailAndPassword(firebaseAuth, email, password);
  };

  //
  // method which can be used to trigger a logout
  //
  const logout = async () => {
    await firebaseAuth.signOut();
    sessionStorage.removeItem(redirectURLKey);
  };

  //
  // method which can be used to send password reset email
  //
  const resetPasswordEmail = async (email: string) => {
    await sendPasswordResetEmail(firebaseAuth, email);
  };

  //
  // method which can be used to reset password
  //
  const resetPassword = async (password: string, code: string) => {
    await confirmPasswordReset(firebaseAuth, code, password);
  };

  //
  // method which can be used to verify the oobCode provided by firebase, to ensure code is valid and not yet expired
  //
  const validatePasswordResetCode = async (code: string) => {
    await verifyPasswordResetCode(firebaseAuth, code);
  };

  const app = useFirebaseApp();
  const [remoteConfig] = useState(() => getRemoteConfig(app));

  const [firebaseRemoteConfig, setFirebaseRemoteConfig] =
    useState<FirebaseRemoteConfig>({});

  const fetchInterval = 900000; // 15 minutes
  const fetchTimeoutMillis = 120000; // 2 minutes

  remoteConfig.settings.minimumFetchIntervalMillis = fetchInterval;
  remoteConfig.settings.fetchTimeoutMillis = fetchTimeoutMillis;

  const fetchAndParseRemoteConf = useCallback(() => {
    fetchAndActivate(remoteConfig).then(() => {
      const bannerRemoteConf = getValue(
        remoteConfig,
        "Banner_Config",
      ).asString();

      let bannerConf = {};
      if (bannerRemoteConf) {
        bannerConf = JSON.parse(bannerRemoteConf) as BannerRemoteConfig;
      }

      const fullScreenNoticeRemoteConfig = getValue(
        remoteConfig,
        "Full_Screen_Notice_Config",
      ).asString();

      let fullScreenNoticeConfig = {};
      if (fullScreenNoticeRemoteConfig) {
        fullScreenNoticeConfig = JSON.parse(
          fullScreenNoticeRemoteConfig,
        ) as BannerRemoteConfig;
      }

      let showBanner = false;

      showBanner = getValue(remoteConfig, "Show_Banner").asBoolean();

      let showFullScreenNotice = false;
      showFullScreenNotice = getValue(
        remoteConfig,
        "Show_Full_Screen_Notice",
      ).asBoolean();

      const marketplaceBannersRemoteConfig = getValue(
        remoteConfig,
        "Marketplace_Banners_Config",
      ).asString();

      let marketplaceBannersConfig = {};
      if (marketplaceBannersRemoteConfig) {
        marketplaceBannersConfig = JSON.parse(
          marketplaceBannersRemoteConfig,
        ) as MarketplaceBannersRemoteConfig;
      }

      setFirebaseRemoteConfig({
        showBanner: showBanner,
        showFullScreenNotice: showFullScreenNotice,
        bannerConfig: bannerConf,
        fullScreenNoticeConfig: fullScreenNoticeConfig,
        marketplaceBannersConfig: marketplaceBannersConfig,
      });
    });
  }, []);

  useEffect(() => {
    fetchAndParseRemoteConf();
    setInterval(() => {
      fetchAndParseRemoteConf();
    }, fetchInterval);
  }, []);

  return (
    <Context.Provider
      value={{
        firebaseContextUpdating: updating,
        firebaseAuthenticated: authenticated,
        firebaseLogout: logout,
        firebaseSignUpWithEmailAndPassword: signUpWithEmailAndPassword,
        firebaseUserIDToken: userIDToken,
        firebaseLoginWithGoogle: loginWithGoogle,
        firebaseLoginWithFacebook: loginWithFacebook,
        firebaseLoginWithEmailAndPassword: loginWithEmailAddressAndPassword,
        firebaseUser,
        firebaseUserCleanup,
        firebaseResetPasswordEmail: resetPasswordEmail,
        firebaseResetPassword: resetPassword,
        firebaseVerifyPasswordResetCode: validatePasswordResetCode,
        firebaseRemoteConfig: firebaseRemoteConfig,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useFirebaseContext = () => useContext(Context);
export { useFirebaseContext };
