import config from "react-global-configuration";
import { ETNStablecoin } from "./ETNStablecoin";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Amount } from "../ledger/Amount";

export interface DraftUpdateETNStablecoinRequest {
  context: Context;
  etnStablecoin: ETNStablecoin;
}

export interface DraftUpdateETNStablecoinResponse {
  etnStablecoin: ETNStablecoin;
}

export interface IncreaseETNStablecoinMaximumUnitsRequest {
  context: Context;
  etnStablecoinID: string;
  amount: Amount;
}

export interface IncreaseETNStablecoinMaximumUnitsResponse {
  etnStablecoin: ETNStablecoin;
}

export interface DecreaseETNStablecoinMaximumUnitsRequest {
  context: Context;
  etnStablecoinID: string;
  amount: Amount;
}

export interface DecreaseETNStablecoinMaximumUnitsResponse {
  etnStablecoin: ETNStablecoin;
}

export interface ChangeETNStablecoinMaturityDateRequest {
  context: Context;
  etnStablecoinID: string;
  maturityDate: string;
}

export interface ChangeETNStablecoinMaturityDateResponse {
  etnStablecoin: ETNStablecoin;
}

export const ETNStablecoinUpdater = {
  serviceProvider: "financial-ETNStablecoinUpdater",
  async DraftUpdateETNStablecoin(
    request: DraftUpdateETNStablecoinRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateETNStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNStablecoinUpdater.serviceProvider}.DraftUpdateETNStablecoin`,
      request,
    });
    return { etnStablecoin: new ETNStablecoin(response.etnStablecoin) };
  },
  async IncreaseETNStablecoinMaximumUnits(
    request: IncreaseETNStablecoinMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<IncreaseETNStablecoinMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNStablecoinUpdater.serviceProvider}.IncreaseETNStablecoinMaximumUnits`,
      request,
    });
    return { etnStablecoin: new ETNStablecoin(response.etnStablecoin) };
  },
  async DecreaseETNStablecoinMaximumUnits(
    request: DecreaseETNStablecoinMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<DecreaseETNStablecoinMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNStablecoinUpdater.serviceProvider}.DecreaseETNStablecoinMaximumUnits`,
      request,
    });
    return { etnStablecoin: new ETNStablecoin(response.etnStablecoin) };
  },
  async ChangeETNStablecoinMaturityDate(
    request: ChangeETNStablecoinMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNStablecoinMaturityDateResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNStablecoinUpdater.serviceProvider}.ChangeETNStablecoinMaturityDate`,
      request,
    });
    return { etnStablecoin: new ETNStablecoin(response.etnStablecoin) };
  },
};
