import React, { useEffect, useState } from "react";
import {
  Model as DirectOrderModel,
  useRead as useMarketDirectOrderViewReaderRead,
} from "james/views/marketDirectOrderView";
import { Box, Dialog } from "@mui/material";
import { tableModeInitialCriteriaIdx } from "views/Marketplace/components/Orders/Orders";
import {
  TableMode,
  tableModeInitialQueryIdx,
} from "views/Marketplace/components/Orders/Table";
import { MarketDirectOrderViewCard } from "components/Cards/MarketDirectOrderViewCard";
import { DirectOrderState } from "james/market/DirectOrder";
import {
  DateRangeCriterionType,
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { AwaitingConfirmationScrollList } from "./components/AwaitingConfirmationScrollList";
import { HistoryScrollList } from "./components/HistoryScrollList";
import { useApplicationContext } from "context/Application/Application";
import { LoadingRow } from "./components/LoadingRow";

export interface DirectOrderSearchCriteria {
  state: {
    type: string;
    list: string[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $or?: any[];
  orderDateTime?: DateRangeCriterionType;
}

export interface FilterValues {
  textSearch: string;
  orderStatuses: DirectOrderState[];
  dateStart?: DateRangeValue;
  dateEnd?: DateRangeValue;
}

export const OrdersScrollList = (): JSX.Element => {
  const [tableMode, setTableMode] = useState<TableMode>(
    TableMode.awaitingConfirmation,
  );
  const [selectedOrder, setSelectedOrder] = useState<
    DirectOrderModel | undefined
  >(undefined);
  const [initialApiCall, setInitialApiCall] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const { loginClaims, authContext } = useApplicationContext();

  const { loading, readResponse } = useMarketDirectOrderViewReaderRead({
    context: authContext,
    criteria: tableModeInitialCriteriaIdx[tableMode],
    query: tableModeInitialQueryIdx[tableMode],
  });

  useEffect(() => {
    if (loading) {
      setInitialApiCall(true);
    }

    if (readResponse.total !== 0 && initialApiCall && initialLoading) {
      setInitialLoading(false);
      setInitialApiCall(false);
    } else if (
      readResponse.total === 0 &&
      initialApiCall &&
      initialLoading &&
      !loading
    ) {
      setTableMode(TableMode.history);
      setInitialLoading(false);
      setInitialApiCall(false);
    }
  }, [readResponse.total, loading]);

  const handleChangeTableMode = (updatedTableMode: TableMode) => {
    setTableMode(updatedTableMode);
  };

  return (
    <>
      {!initialLoading ? (
        <>
          {tableMode === TableMode.awaitingConfirmation && (
            <AwaitingConfirmationScrollList
              setSelectedOrder={setSelectedOrder}
              changeTableMode={handleChangeTableMode}
            />
          )}
          {tableMode === TableMode.history && (
            <HistoryScrollList
              changeTableMode={handleChangeTableMode}
              setSelectedOrder={setSelectedOrder}
            />
          )}

          {/* Dialog for selected order */}
          {selectedOrder && (
            <Dialog fullScreen open={selectedOrder !== undefined}>
              <MarketDirectOrderViewCard
                onActionComplete={() => {
                  setSelectedOrder(undefined);
                }}
                clientIDPerspective={loginClaims.clientID}
                marketDirectOrderViewModel={selectedOrder}
                close={() => {
                  setSelectedOrder(undefined);
                }}
              />
            </Dialog>
          )}
        </>
      ) : (
        <Box sx={{ padding: "8px 16px 0px 16px" }}>
          <Box
            sx={(theme) => ({
              height: "64px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: theme.spacing(2),
            })}
          />
          <LoadingRow />
        </Box>
      )}
    </>
  );
};

export const GetDirectOrderSearchCriteria = ({
  textSearch,
  orderStatuses,
  dateEnd,
  dateStart,
  tableMode,
}: FilterValues & { tableMode: TableMode }): DirectOrderSearchCriteria => {
  let criteria: DirectOrderSearchCriteria = {
    ...tableModeInitialCriteriaIdx[tableMode],
  };

  if (textSearch) {
    criteria = {
      ...criteria,
      $or: [
        { number: TextSubstringCriterion(textSearch) },
        { assetName: TextSubstringCriterion(textSearch) },
        {
          initiatingPartyClientName: TextSubstringCriterion(textSearch),
        },
        {
          counterpartyClientName: TextSubstringCriterion(textSearch),
        },
      ],
    };
  }

  if (orderStatuses.length) {
    criteria.state = TextListCriterion(orderStatuses);
  }

  if (dateStart || dateEnd) {
    criteria.orderDateTime = DateRangeCriterion(dateStart, dateEnd);
  }

  return criteria;
};
