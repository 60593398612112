import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDownward as DepositIcon,
  Close as CloseIcon,
  Waves as LiquidityPoolsIcon,
} from "@mui/icons-material";
import { Model as TokenViewModel } from "james/views/ledgerTokenView";
import {
  LiquidityPoolCalculator,
  LiquidityPoolOperator,
  LiquidityPoolTokenToReserveTokens,
} from "james/stellar";
import { TextExactCriterion } from "james/search/criterion";
import { useSnackbar } from "notistack";
import { useValidatedForm } from "hooks/useForm";
import { Model as AccountViewModel } from "james/views/stellarAccountView";
import {
  Amount as LedgerAmount,
  LedgerAccountCategory,
  Token,
} from "james/ledger";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Context, Permission } from "james/security";
import { TextNumField } from "components/FormFields";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Amount } from "components/Ledger/Amount";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { CalculateLiquidityPoolDepositRequest } from "james/stellar/LiquidityPoolCalculator";
import {
  Model as RegisteredLiquidityPoolViewModel,
  Reader,
} from "james/views/stellarRegisteredLiquidityPoolView";
import {
  formDataValidationFunc,
  formUpdaterSpecs,
  FormUpdaterSpecsType,
} from "./useValidatedForm";
import { Key } from "james/key/Key";
import { useAccountContext } from "context/Account/Account";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { DepositToLiquidityPoolResponse } from "james/stellar/LiquidityPoolOperator";

const PREFIX = "DepositDialog";

const classes = {
  dialogTitleRootOverride: `${PREFIX}-dialogTitleRootOverride`,
  dialogContent: `${PREFIX}-dialogContent`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  row: `${PREFIX}-row`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: theme.spacing(2, 1, 2, 2),
    alignItems: "center",
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(3, 4, 4, 4),
    [theme.breakpoints.up("sm")]: {
      width: 420,
    },
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.row}`]: {
    paddingLeft: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export type DepositDialogProps = {
  closeDialog: () => void;
  liquidityPoolSharesToken: Token;
};

export type FormData = {
  userID: string;
  userKeys: Key[];
  maxAmountA: LedgerAmount;
  balanceA: LedgerAmount;
  maxAmountB: LedgerAmount;
  balanceB: LedgerAmount;
  minPrice: LedgerAmount;
  maxPrice: LedgerAmount;
  selectedTradingAccViewModel: AccountViewModel | null;
  calculateLiquidityPoolDepositRequest: CalculateLiquidityPoolDepositRequest;
  registeredLiquidityPoolViewModel: RegisteredLiquidityPoolViewModel;
};

export function DepositDialog(props: DepositDialogProps) {
  const { closeDialog } = props;

  const { errorContextErrorTranslator } = useErrorContext();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { registerNotificationCallback } = useNotificationContext();
  const { stellarAccountContext } = useAccountContext();
  const [potentialTradingAccounts, setPotentialTradingAccounts] = useState<
    AccountViewModel[]
  >([]);
  const [tokenAViewModel, setTokenAViewModel] = useState<TokenViewModel>(
    new TokenViewModel(),
  );
  const [tokenBViewModel, setTokenBViewModel] = useState<TokenViewModel>(
    new TokenViewModel(),
  );
  const [initialising, setInitialising] = useState(true);
  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm<FormData, FormUpdaterSpecsType>(
    formDataValidationFunc,
    async () => {
      // get tokens A and B from given liquidity pool token
      const [tokenA, tokenB] = LiquidityPoolTokenToReserveTokens(
        props.liquidityPoolSharesToken,
      );

      // prepare initial dialog form state
      const initialState: FormData = {
        userID: authContext.userID,
        userKeys: stellarAccountContext.keys,
        maxAmountA: tokenA.newAmountOf("0"),
        balanceA: tokenA.newAmountOf("0"),
        maxAmountB: tokenB.newAmountOf("0"),
        balanceB: tokenB.newAmountOf("0"),
        minPrice: new LedgerAmount(),
        maxPrice: new LedgerAmount(),
        selectedTradingAccViewModel: new AccountViewModel(),
        calculateLiquidityPoolDepositRequest: {
          context: authContext,
          liquidityPoolID: "",
          maxAmountA: new LedgerAmount(),
          maxAmountB: new LedgerAmount(),
          slippage: "10",
        },
        registeredLiquidityPoolViewModel:
          new RegisteredLiquidityPoolViewModel(),
      };

      // retrieve any required data
      try {
        await Promise.all([
          // retrieve associated registered liquidity pool view model
          (async () => {
            initialState.registeredLiquidityPoolViewModel = (
              await Reader.ReadOne({
                context: authContext,
                criteria: props.liquidityPoolSharesToken.toFilter(),
              })
            ).model;
            initialState.calculateLiquidityPoolDepositRequest.liquidityPoolID =
              initialState.registeredLiquidityPoolViewModel.liquidityPool.id;
          })(),

          // get all potential trading accounts and set trading account
          (async () => {
            const determineScopeCriteriaByRolesResponse =
              await Determiner.DetermineScopeCriteriaByRoles({
                context: authContext,
                service: new Permission({
                  serviceName: "DepositToLiquidityPool",
                  serviceProvider: LiquidityPoolOperator.serviceProvider,
                  description: "-",
                }),
                criteria: {
                  network: TextExactCriterion(
                    props.liquidityPoolSharesToken.network,
                  ),
                  category: TextExactCriterion(LedgerAccountCategory.Trading),
                },
                scopeFields: [ScopeFields.OwnerIDField],
                buildScopeTree: true,
              });

            const ownerIDList: string[] =
              determineScopeCriteriaByRolesResponse.criteria.ownerID.list;

            const retrievedPotentialTradingAccounts: AccountViewModel[] =
              stellarAccountContext.accounts.filter(
                (val) =>
                  ownerIDList.includes(val.ownerID) &&
                  val.category === LedgerAccountCategory.Trading,
              );

            if (!retrievedPotentialTradingAccounts.length) {
              console.error(
                "expected at least 1 potential trading account, got 0",
              );
              return;
            }
            setPotentialTradingAccounts(retrievedPotentialTradingAccounts);
            initialState.selectedTradingAccViewModel =
              retrievedPotentialTradingAccounts[0];

            // set balances A and B
            const balanceA =
              initialState.selectedTradingAccViewModel.getTokenBalance(tokenA);
            initialState.balanceA = balanceA
              ? balanceA.amount
              : tokenA.newAmountOf("0");
            const balanceB =
              initialState.selectedTradingAccViewModel.getTokenBalance(tokenB);
            initialState.balanceB = balanceB
              ? balanceB.amount
              : tokenB.newAmountOf("0");
          })(),

          // get reserve token view models
          (async () => {
            setTokenAViewModel(await getLedgerTokenViewModel(tokenA));
          })(),
          (async () => {
            setTokenBViewModel(await getLedgerTokenViewModel(tokenB));
          })(),
        ]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error initialising form: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error initialising form: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        return initialState;
      }

      setInitialising(false);
      return initialState;
    },
    formUpdaterSpecs,
    {
      userID: "",
      userKeys: [],
      maxAmountA: new LedgerAmount(),
      balanceA: new LedgerAmount(),
      maxAmountB: new LedgerAmount(),
      balanceB: new LedgerAmount(),
      minPrice: new LedgerAmount(),
      maxPrice: new LedgerAmount(),
      selectedTradingAccViewModel: new AccountViewModel(),
      calculateLiquidityPoolDepositRequest: {
        context: new Context(),
        liquidityPoolID: "",
        maxAmountA: new LedgerAmount(),
        maxAmountB: new LedgerAmount(),
        slippage: "10",
      },
      registeredLiquidityPoolViewModel: new RegisteredLiquidityPoolViewModel(),
    },
    new Set<string>(["selectedTradingAccViewModel"]),
  );

  const [calculationInProgress, setCalculationInProgress] = useState(false);
  const calculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (
      formData.registeredLiquidityPoolViewModel.liquidityPool.totalShares.value.toNumber()
    ) {
      // if pool contains shares then perform calculation if Either maxAmount A or B
      // have an amount > 0 set (it is assumed 1 of the 2 is 0 and undefined)
      if (
        !(
          formData.calculateLiquidityPoolDepositRequest.maxAmountA.value.toNumber() ||
          formData.calculateLiquidityPoolDepositRequest.maxAmountB.value.toNumber()
        )
      ) {
        // if neither amounts are > 0 then do nothing
        return;
      }
    } else {
      // if the pool is empty then perform calculation only if BOTH maxAmounts A and B
      // have amounts > 0 (it is assumed neither are undefined)
      if (
        !(
          formData.calculateLiquidityPoolDepositRequest.maxAmountA.value.toNumber() &&
          formData.calculateLiquidityPoolDepositRequest.maxAmountB.value.toNumber()
        )
      ) {
        // if either amount is 0 then do nothing
        return;
      }
    }

    setCalculationInProgress(true);
    clearTimeout(calculationTimeoutRef.current);
    calculationTimeoutRef.current = setTimeout(async () => {
      try {
        formDataUpdate.calculateLiquidityPoolDepositResponse(
          await LiquidityPoolCalculator.CalculateLiquidityPoolDeposit(
            formData.calculateLiquidityPoolDepositRequest,
          ),
          formData,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error calculating liquidity pool deposit: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error calculating liquidity pool deposit: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setCalculationInProgress(false);
    }, 500);
  }, [
    enqueueSnackbar,
    formDataUpdate,
    formData.calculateLiquidityPoolDepositRequest,
    formData.registeredLiquidityPoolViewModel.liquidityPool.totalShares.value,
  ]);

  const [depositInProgress, setDepositInProgress] = useState(false);
  const handleDeposit = useCallback(async () => {
    setDepositInProgress(true);
    let depositToLiquidityPoolResponse: DepositToLiquidityPoolResponse;
    try {
      if (!formData.selectedTradingAccViewModel) {
        throw new Error("expected selected trading account to be set");
      }

      // perform deposit
      depositToLiquidityPoolResponse =
        await LiquidityPoolOperator.DepositToLiquidityPool({
          context: authContext,
          fromAccountID: formData.selectedTradingAccViewModel.id,
          maxAmountA: formData.maxAmountA,
          maxAmountB: formData.maxAmountB,
          minPrice: formData.minPrice,
          maxPrice: formData.maxPrice,
        });

      // notify the user deposit is in progress
      enqueueSnackbar("Deposit in Progress", { variant: "info" });

      // close the dialog
      closeDialog();
    } catch (e) {
      console.error("error performing deposit", e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Performing Deposit: ${err.message}`, {
        variant: "warning",
      });
      setDepositInProgress(true);
      return;
    }

    try {
      // register callback to fire once the deposit has been submitted
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: depositToLiquidityPoolResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (n instanceof TransactionSucceededNotification) {
            enqueueSnackbar("Success! Deposit Complete", {
              variant: "success",
            });
          }

          if (n instanceof TransactionFailedNotification) {
            enqueueSnackbar("Error! Deposit Failed", { variant: "error" });
          }

          if (n instanceof TransactionSubmissionResolutionFailedNotification) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the deposit and its status is being investigated",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error(
        "error registring for deposit transaction notifications",
        e,
      );
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Deposit Transaction - Please Check Account and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  }, [
    registerNotificationCallback,
    enqueueSnackbar,
    closeDialog,
    authContext,
    formData.maxAmountA,
    formData.maxAmountB,
    formData.minPrice,
    formData.maxPrice,
    formData.selectedTradingAccViewModel,
  ]);

  const loading =
    formDataValidationInProgress || calculationInProgress || depositInProgress;

  const dialogContent =
    initialising || !potentialTradingAccounts.length ? (
      <Skeleton width="100%" height={200} />
    ) : (
      <>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Select the account that will pay for the deposit
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                and receive liquidity pool share tokens.
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="stellarLiquidityPoolDepositDialog-fromAccountID-autoComplete"
                fullWidth
                disabled={potentialTradingAccounts.length <= 1 || loading}
                options={potentialTradingAccounts}
                getOptionLabel={(acc: AccountViewModel) =>
                  `${acc.accountOwnerGroupName} ${acc.category} Account`
                }
                onChange={(_, selectedAcc) =>
                  formDataUpdate.selectedTradingAccViewModel(selectedAcc)
                }
                value={(() => {
                  const selected = potentialTradingAccounts.find(
                    (pta) =>
                      pta.id === formData.selectedTradingAccViewModel?.id,
                  );
                  return selected || null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="stellarLiquidityPoolDepositDialog-fromAccountID-autoCompleteTextField"
                    label="From Account"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      formDataValidationResult.fieldValidations
                        .selectedTradingAccViewModel
                    }
                    error={
                      !!formDataValidationResult.fieldValidations
                        .selectedTradingAccViewModel
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Enter deposit parameters
              </Typography>
            </Grid>
            <Grid item>
              <TextNumField
                id="stellarLiquidityPoolDepositDialog-maxAmountA-textNumField"
                noDecimalPlaces={7}
                label="Max Amount A"
                disallowNegative
                fullWidth
                value={formData.maxAmountA.value}
                onChange={(e) =>
                  formDataUpdate.maxAmountA(
                    formData.maxAmountA.setValue(e.target.value),
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TokenIconViewUpload
                        disableChangeIcon
                        token={tokenAViewModel.token}
                        size={23}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Issued by ${tokenAViewModel.issuer}`}
                        placement="top"
                      >
                        <Typography
                          variant="body1"
                          className={classes.textNumFieldCode}
                          children={tokenAViewModel.token.code}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={!!formDataValidationResult.fieldValidations.maxAmountA}
              />
              {formDataValidationResult.fieldValidations.maxAmountA ? (
                <div className={classes.row}>
                  <Typography
                    children={
                      formDataValidationResult.fieldValidations.maxAmountA
                    }
                    variant="caption"
                    color="error"
                  />
                </div>
              ) : (
                <div className={classes.row}>
                  <Typography
                    children="Available:"
                    variant="caption"
                    color="textSecondary"
                  />
                  <Amount
                    id="stellarLiquidityPoolDepositDialog-maxAmountA-availableBalance-amountField"
                    amount={formData.balanceA}
                    formatTextNumOpts={{ noDecimalPlaces: 7 }}
                    codeTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                    valueTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item>
              <TextNumField
                noDecimalPlaces={7}
                id="stellarLiquidityPoolDepositDialog-maxAmountB-textNumField"
                label="Max Amount B"
                disallowNegative
                fullWidth
                value={formData.maxAmountB.value}
                onChange={(e) =>
                  formDataUpdate.maxAmountB(
                    formData.maxAmountB.setValue(e.target.value),
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TokenIconViewUpload
                        disableChangeIcon
                        token={tokenBViewModel.token}
                        size={23}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Issued by ${tokenBViewModel.issuer}`}
                        placement="top"
                      >
                        <Typography
                          variant="body1"
                          className={classes.textNumFieldCode}
                          children={tokenBViewModel.token.code}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={!!formDataValidationResult.fieldValidations.maxAmountB}
              />
              {formDataValidationResult.fieldValidations.maxAmountB ? (
                <div className={classes.row}>
                  <Typography
                    children={
                      formDataValidationResult.fieldValidations.maxAmountB
                    }
                    variant="caption"
                    color="error"
                  />
                </div>
              ) : (
                <div className={classes.row}>
                  <Typography
                    children="Available:"
                    variant="caption"
                    color="textSecondary"
                  />
                  <Amount
                    id="stellarLiquidityPoolDepositDialog-maxAmountB-availableBalance-amountField"
                    amount={formData.balanceB}
                    formatTextNumOpts={{ noDecimalPlaces: 7 }}
                    codeTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                    valueTypographyProps={{
                      variant: "caption",
                      color: "textSecondary",
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item>
              <TextNumField
                id="stellarLiquidityPoolDepositDialog-slippage-textNumField"
                noDecimalPlaces={7}
                label="Slippage"
                disabled
                fullWidth
                value="10"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        children="%"
                        variant="body1"
                        color="textSecondary"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Tooltip
            placement="top"
            title={(() => {
              switch (true) {
                case calculationInProgress:
                  return "Calculation in progress";
                case formDataValidationInProgress:
                  return "Validation in progress";
                case !formDataValidationResult.valid:
                  return "Please ensure that all fields have been completed correctly";
                default:
                  return "";
              }
            })()}
          >
            <span>
              <Button
                id="stellarLiquidityPoolDepositDialog-deposit-button"
                fullWidth
                variant="contained"
                color="primary"
                children="deposit"
                disabled={
                  loading ||
                  formDataValidationInProgress ||
                  !formDataValidationResult.valid
                }
                onClick={handleDeposit}
                endIcon={<DepositIcon />}
              />
            </span>
          </Tooltip>
        </DialogContent>
      </>
    );

  return (
    <StyledDialog open maxWidth="lg">
      <DialogTitle classes={{ root: classes.dialogTitleRootOverride }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <LiquidityPoolsIcon />
          </Grid>
          <Grid item>
            <Typography variant="h5" children="Deposit Liquidity" />
          </Grid>
          {(calculationInProgress || depositInProgress) && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <IconButton
                id="stellarLiquidityPoolDepositDialog-close-iconButton"
                size="small"
                onClick={props.closeDialog}
                disabled={loading}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      {dialogContent}
    </StyledDialog>
  );
}
