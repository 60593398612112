"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartInstrumentAttributeWrapper = void 0;
const smartInstrumentAttribute_pb_1 = require("./smartInstrumentAttribute_pb");
const smartInstrumentAttributeType_pb_1 = require("./smartInstrumentAttributeType_pb");
/**
 * Wrapper class for SmartInstrumentAttribute.
 */
class SmartInstrumentAttributeWrapper {
    /**
     * Constructs a new SmartInstrumentAttributeWrapper instance.
     * @param {SmartInstrumentAttribute} smartInstrumentAttribute - The smart instrument attribute instance to wrap.
     * @throws {TypeError} If the wrapped smart instrument attribute does not have a valid attribute set.
     */
    constructor(smartInstrumentAttribute) {
        this._smartInstrumentAttribute =
            smartInstrumentAttribute !== null && smartInstrumentAttribute !== void 0 ? smartInstrumentAttribute : new smartInstrumentAttribute_pb_1.SmartInstrumentAttribute();
    }
    /**
     * Gets the type of the wrapped smart instrument attribute.
     * @returns {SmartInstrumentAttributeType} The type of the wrapped smart instrument attribute.
     * @throws {TypeError} If the wrapped smart instrument attribute does not have a valid attribute set.
     */
    get smartInstrumentAttributeType() {
        switch (true) {
            case this._smartInstrumentAttribute.hasSectorallocationssmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.SECTOR_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasCountryallocationssmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.COUNTRY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasFiatcurrencyallocationssmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.FIAT_CURRENCY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasHoldingallocationssmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasRiskassessmentsmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasExternalparticipantsinformationsmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasAssetclasssmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            case this._smartInstrumentAttribute.hasAnnualperformancelogsmartinstrumentattribute():
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
            default:
                return smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.UNDEFINED_SMART_INSTRUMENT_ATTRIBUTE_TYPE;
        }
    }
    /**
     * Gets the wrapped smart instrument attribute instance.
     * @returns {SmartInstrumentAttribute} The wrapped smart instrument attribute.
     */
    get smartInstrumentAttribute() {
        return this._smartInstrumentAttribute;
    }
}
exports.SmartInstrumentAttributeWrapper = SmartInstrumentAttributeWrapper;
