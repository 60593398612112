import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { Token } from "james/ledger";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface DownloadAnalyticsReportRequest {
  context: Context;
  fromDate: string;
  toDate: string;
  valuationToken: Token;
}

export interface DownloadAnalyticsReportResponse {
  excelFileBase64: string;
}

export const ReportDownloaderServiceProviderName = "analytics-ReportDownloader";

export const ReportDownloader = {
  async DownloadAnalyticsReport(
    request: DownloadAnalyticsReportRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DownloadAnalyticsReportResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${ReportDownloaderServiceProviderName}.DownloadAnalyticsReport`,
        request,
      },
      opts,
    );
  },
};
