import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

const serviceProvider = "media-PublicMediaController";

export interface GenerateImageUploadUrlRequest {
  context: Context;
}

export interface GenerateImageUploadUrlResponse {
  imageID: string;
  url: string;
}

export interface GenerateImageDownloadUrlRequest {
  context: Context;
  mediaAssetID: string;
}

export interface GenerateImageDownloadUrlResponse {
  generatedURL: boolean;
  imageID: string;
  url: string;
}

export interface GenerateVideoUploadUrlRequest {
  context: Context;
}

export interface GenerateVideoUploadUrlResponse {
  videoID: string;
  url: string;
}

export interface GenerateVideoDownloadUrlRequest {
  context: Context;
  videoID: string;
}

export interface GenerateVideoDownloadUrlResponse {
  videoID: string;
  url: string;
}

export const PublicMediaController = {
  serviceProvider: serviceProvider,
  async GenerateImageUploadUrl(
    request: GenerateImageUploadUrlRequest,
  ): Promise<GenerateImageUploadUrlResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${PublicMediaController.serviceProvider}.GenerateImageUploadUrl`,
      request,
    });
    return {
      imageID: response.imageID,
      url: response.url,
    };
  },
  async GenerateImageDownloadUrl(
    request: GenerateImageDownloadUrlRequest,
  ): Promise<GenerateImageDownloadUrlResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${PublicMediaController.serviceProvider}.GenerateImageDownloadUrl`,
      request,
    });
    return {
      generatedURL: response.generatedURL,
      imageID: response.imageID,
      url: response.url,
    };
  },
  async GenerateVideoUploadUrl(
    request: GenerateVideoUploadUrlRequest,
  ): Promise<GenerateVideoUploadUrlResponse> {
    const response: GenerateVideoUploadUrlResponse = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${PublicMediaController.serviceProvider}.GenerateVideoUploadUrl`,
      request,
    });
    return {
      videoID: response.videoID,
      url: response.url,
    };
  },
  async GenerateVideoDownloadUrl(
    request: GenerateVideoDownloadUrlRequest,
  ): Promise<GenerateVideoDownloadUrlResponse> {
    const response: GenerateVideoDownloadUrlResponse = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${PublicMediaController.serviceProvider}.GenerateVideoDownloadUrl`,
      request,
    });
    return {
      videoID: response.videoID,
      url: response.url,
    };
  },
};
