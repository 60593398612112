import { Context } from "james/security";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { useEffect, useRef, useState } from "react";
import { GroupWithRoles } from "./GroupWithRoles";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface SearchGroupsWithRolesRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchGroupsWithRolesResponse {
  records: GroupWithRoles[];
  total: number;
}

export const Compository = {
  serviceProvider: "group-Compository",
  async SearchGroupsWithRoles(
    request: SearchGroupsWithRolesRequest,
  ): Promise<SearchGroupsWithRolesResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Compository.serviceProvider}.SearchGroupsWithRoles`,
      request,
    });
    return {
      records: response.records.map(
        (c: GroupWithRoles) => new GroupWithRoles(c),
      ),
      total: response.total,
    };
  },
};

export function useSearchGroupsWithRoles(
  initialSearchGroupsWithRolesRequest?: SearchGroupsWithRolesRequest,
) {
  const { authContext } = useApplicationContext();
  const [searchGroupsWithRolesRequest, setSearchGroupsWithRolesRequest] =
    useState<SearchGroupsWithRolesRequest>(
      initialSearchGroupsWithRolesRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchGroupsWithRolesResponse, setSearchGroupsWithRolesResponse] =
    useState<SearchGroupsWithRolesResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchGroupsWithRolesResponse(
          await Compository.SearchGroupsWithRoles(searchGroupsWithRolesRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching groups with roles: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching groups with roles: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchGroupsWithRolesRequest]);

  return {
    searchGroupsWithRolesRequest,
    setSearchGroupsWithRolesRequest,
    searchGroupsWithRolesResponse,
    loading,
    error,
  };
}
