"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allCountryCodes = void 0;
exports.countryCodeToString = countryCodeToString;
exports.stringToCountryCode = stringToCountryCode;
const countryCode_pb_1 = require("./countryCode_pb");
// Get all countryCodes as enum values
exports.allCountryCodes = Object.values(countryCode_pb_1.CountryCode).filter((value) => typeof value === "number");
// Define explicit mappings between CountryCode enums and custom string representations
const countryCodeToStringMapping = {
    [countryCode_pb_1.CountryCode.UNDEFINED_COUNTRY_CODE]: "-",
    [countryCode_pb_1.CountryCode.AFGHANISTAN_AF_COUNTRY_CODE]: "Afghanistan",
    [countryCode_pb_1.CountryCode.ALAND_ISLANDS_AX_COUNTRY_CODE]: "Åland Islands",
    [countryCode_pb_1.CountryCode.ALBANIA_AL_COUNTRY_CODE]: "Albania",
    [countryCode_pb_1.CountryCode.ALGERIA_DZ_COUNTRY_CODE]: "Algeria",
    [countryCode_pb_1.CountryCode.AMERICAN_SAMOA_AS_COUNTRY_CODE]: "American Samoa",
    [countryCode_pb_1.CountryCode.ANDORRA_AD_COUNTRY_CODE]: "Andorra",
    [countryCode_pb_1.CountryCode.ANGOLA_AO_COUNTRY_CODE]: "Angola",
    [countryCode_pb_1.CountryCode.ANGUILLA_AI_COUNTRY_CODE]: "Anguilla",
    [countryCode_pb_1.CountryCode.ANTARCTICA_AQ_COUNTRY_CODE]: "Antarctica",
    [countryCode_pb_1.CountryCode.ANTIGUA_AND_BARBUDA_AG_COUNTRY_CODE]: "Antigua and Barbuda",
    [countryCode_pb_1.CountryCode.ARGENTINA_AR_COUNTRY_CODE]: "Argentina",
    [countryCode_pb_1.CountryCode.ARMENIA_AM_COUNTRY_CODE]: "Armenia",
    [countryCode_pb_1.CountryCode.ARUBA_AW_COUNTRY_CODE]: "Aruba",
    [countryCode_pb_1.CountryCode.AUSTRALIA_AU_COUNTRY_CODE]: "Australia",
    [countryCode_pb_1.CountryCode.AUSTRIA_AT_COUNTRY_CODE]: "Austria",
    [countryCode_pb_1.CountryCode.AZERBAIJAN_AZ_COUNTRY_CODE]: "Azerbaijan",
    [countryCode_pb_1.CountryCode.BAHAMAS_BS_COUNTRY_CODE]: "Bahamas",
    [countryCode_pb_1.CountryCode.BAHRAIN_BH_COUNTRY_CODE]: "Bahrain",
    [countryCode_pb_1.CountryCode.BANGLADESH_BD_COUNTRY_CODE]: "Bangladesh",
    [countryCode_pb_1.CountryCode.BARBADOS_BB_COUNTRY_CODE]: "Barbados",
    [countryCode_pb_1.CountryCode.BELARUS_BY_COUNTRY_CODE]: "Belarus",
    [countryCode_pb_1.CountryCode.BELGIUM_BE_COUNTRY_CODE]: "Belgium",
    [countryCode_pb_1.CountryCode.BELIZE_BZ_COUNTRY_CODE]: "Belize",
    [countryCode_pb_1.CountryCode.BENIN_BJ_COUNTRY_CODE]: "Benin",
    [countryCode_pb_1.CountryCode.BERMUDA_BM_COUNTRY_CODE]: "Bermuda",
    [countryCode_pb_1.CountryCode.BHUTAN_BT_COUNTRY_CODE]: "Bhutan",
    [countryCode_pb_1.CountryCode.BOLIVIA_PLURINATIONAL_STATE_OF_BO_COUNTRY_CODE]: "Bolivia, Plurinational State of",
    [countryCode_pb_1.CountryCode.BONAIRE_SINT_EUSTATIUS_AND_SABA_BQ_COUNTRY_CODE]: "Bonaire, Sint Eustatius and Saba",
    [countryCode_pb_1.CountryCode.BOSNIA_AND_HERZEGOVINA_BA_COUNTRY_CODE]: "Bosnia and Herzegovina",
    [countryCode_pb_1.CountryCode.BOTSWANA_BW_COUNTRY_CODE]: "Botswana",
    [countryCode_pb_1.CountryCode.BOUVET_ISLAND_BV_COUNTRY_CODE]: "Bouvet Island",
    [countryCode_pb_1.CountryCode.BRAZIL_BR_COUNTRY_CODE]: "Brazil",
    [countryCode_pb_1.CountryCode.BRITISH_INDIAN_OCEAN_TERRITORY_IO_COUNTRY_CODE]: "British Indian Ocean Territory",
    [countryCode_pb_1.CountryCode.BRUNEI_DARUSSALAM_BN_COUNTRY_CODE]: "Brunei Darussalam",
    [countryCode_pb_1.CountryCode.BULGARIA_BG_COUNTRY_CODE]: "Bulgaria",
    [countryCode_pb_1.CountryCode.BURKINA_FASO_BF_COUNTRY_CODE]: "Burkina Faso",
    [countryCode_pb_1.CountryCode.BURUNDI_BI_COUNTRY_CODE]: "Burundi",
    [countryCode_pb_1.CountryCode.CABO_VERDE_CV_COUNTRY_CODE]: "Cabo Verde",
    [countryCode_pb_1.CountryCode.CAMBODIA_KH_COUNTRY_CODE]: "Cambodia",
    [countryCode_pb_1.CountryCode.CAMEROON_CM_COUNTRY_CODE]: "Cameroon",
    [countryCode_pb_1.CountryCode.CANADA_CA_COUNTRY_CODE]: "Canada",
    [countryCode_pb_1.CountryCode.CAYMAN_ISLANDS_KY_COUNTRY_CODE]: "Cayman Islands",
    [countryCode_pb_1.CountryCode.CENTRAL_AFRICAN_REPUBLIC_CF_COUNTRY_CODE]: "Central African Republic",
    [countryCode_pb_1.CountryCode.CHAD_TD_COUNTRY_CODE]: "Chad",
    [countryCode_pb_1.CountryCode.CHILE_CL_COUNTRY_CODE]: "Chile",
    [countryCode_pb_1.CountryCode.CHINA_CN_COUNTRY_CODE]: "China",
    [countryCode_pb_1.CountryCode.CHRISTMAS_ISLAND_CX_COUNTRY_CODE]: "Christmas Island",
    [countryCode_pb_1.CountryCode.COCOS_KEELING_ISLANDS_CC_COUNTRY_CODE]: "Cocos (Keeling) Islands",
    [countryCode_pb_1.CountryCode.COLOMBIA_CO_COUNTRY_CODE]: "Colombia",
    [countryCode_pb_1.CountryCode.COMOROS_KM_COUNTRY_CODE]: "Comoros",
    [countryCode_pb_1.CountryCode.CONGO_CG_COUNTRY_CODE]: "Congo",
    [countryCode_pb_1.CountryCode.CONGO_DEMOCRATIC_REPUBLIC_OF_THE_CD_COUNTRY_CODE]: "Congo, Democratic Republic of the",
    [countryCode_pb_1.CountryCode.COOK_ISLANDS_CK_COUNTRY_CODE]: "Cook Islands",
    [countryCode_pb_1.CountryCode.COSTA_RICA_CR_COUNTRY_CODE]: "Costa Rica",
    [countryCode_pb_1.CountryCode.COTE_DIVOIRE_CI_COUNTRY_CODE]: "Côte d'Ivoire",
    [countryCode_pb_1.CountryCode.CROATIA_HR_COUNTRY_CODE]: "Croatia",
    [countryCode_pb_1.CountryCode.CUBA_CU_COUNTRY_CODE]: "Cuba",
    [countryCode_pb_1.CountryCode.CURACAO_CW_COUNTRY_CODE]: "Curaçao",
    [countryCode_pb_1.CountryCode.CYPRUS_CY_COUNTRY_CODE]: "Cyprus",
    [countryCode_pb_1.CountryCode.CZECHIA_CZ_COUNTRY_CODE]: "Czechia",
    [countryCode_pb_1.CountryCode.DENMARK_DK_COUNTRY_CODE]: "Denmark",
    [countryCode_pb_1.CountryCode.DJIBOUTI_DJ_COUNTRY_CODE]: "Djibouti",
    [countryCode_pb_1.CountryCode.DOMINICA_DM_COUNTRY_CODE]: "Dominica",
    [countryCode_pb_1.CountryCode.DOMINICAN_REPUBLIC_DO_COUNTRY_CODE]: "Dominican Republic",
    [countryCode_pb_1.CountryCode.ECUADOR_EC_COUNTRY_CODE]: "Ecuador",
    [countryCode_pb_1.CountryCode.EGYPT_EG_COUNTRY_CODE]: "Egypt",
    [countryCode_pb_1.CountryCode.EL_SALVADOR_SV_COUNTRY_CODE]: "El Salvador",
    [countryCode_pb_1.CountryCode.EQUATORIAL_GUINEA_GQ_COUNTRY_CODE]: "Equatorial Guinea",
    [countryCode_pb_1.CountryCode.ERITREA_ER_COUNTRY_CODE]: "Eritrea",
    [countryCode_pb_1.CountryCode.ESTONIA_EE_COUNTRY_CODE]: "Estonia",
    [countryCode_pb_1.CountryCode.ESWATINI_SZ_COUNTRY_CODE]: "Eswatini",
    [countryCode_pb_1.CountryCode.ETHIOPIA_ET_COUNTRY_CODE]: "Ethiopia",
    [countryCode_pb_1.CountryCode.FALKLAND_ISLANDS_MALVINAS_FK_COUNTRY_CODE]: "Falkland Islands (Malvinas)",
    [countryCode_pb_1.CountryCode.FAROE_ISLANDS_FO_COUNTRY_CODE]: "Faroe Islands",
    [countryCode_pb_1.CountryCode.FIJI_FJ_COUNTRY_CODE]: "Fiji",
    [countryCode_pb_1.CountryCode.FINLAND_FI_COUNTRY_CODE]: "Finland",
    [countryCode_pb_1.CountryCode.FRANCE_FR_COUNTRY_CODE]: "France",
    [countryCode_pb_1.CountryCode.FRENCH_GUIANA_GF_COUNTRY_CODE]: "French Guiana",
    [countryCode_pb_1.CountryCode.FRENCH_POLYNESIA_PF_COUNTRY_CODE]: "French Polynesia",
    [countryCode_pb_1.CountryCode.FRENCH_SOUTHERN_TERRITORIES_TF_COUNTRY_CODE]: "French Southern Territories",
    [countryCode_pb_1.CountryCode.GABON_GA_COUNTRY_CODE]: "Gabon",
    [countryCode_pb_1.CountryCode.GAMBIA_GM_COUNTRY_CODE]: "Gambia",
    [countryCode_pb_1.CountryCode.GEORGIA_GE_COUNTRY_CODE]: "Georgia",
    [countryCode_pb_1.CountryCode.GERMANY_DE_COUNTRY_CODE]: "Germany",
    [countryCode_pb_1.CountryCode.GHANA_GH_COUNTRY_CODE]: "Ghana",
    [countryCode_pb_1.CountryCode.GIBRALTAR_GI_COUNTRY_CODE]: "Gibraltar",
    [countryCode_pb_1.CountryCode.GREECE_GR_COUNTRY_CODE]: "Greece",
    [countryCode_pb_1.CountryCode.GREENLAND_GL_COUNTRY_CODE]: "Greenland",
    [countryCode_pb_1.CountryCode.GRENADA_GD_COUNTRY_CODE]: "Grenada",
    [countryCode_pb_1.CountryCode.GUADELOUPE_GP_COUNTRY_CODE]: "Guadeloupe",
    [countryCode_pb_1.CountryCode.GUAM_GU_COUNTRY_CODE]: "Guam",
    [countryCode_pb_1.CountryCode.GUATEMALA_GT_COUNTRY_CODE]: "Guatemala",
    [countryCode_pb_1.CountryCode.GUERNSEY_GG_COUNTRY_CODE]: "Guernsey",
    [countryCode_pb_1.CountryCode.GUINEA_GN_COUNTRY_CODE]: "Guinea",
    [countryCode_pb_1.CountryCode.GUINEABISSAU_GW_COUNTRY_CODE]: "Guinea-Bissau",
    [countryCode_pb_1.CountryCode.GUYANA_GY_COUNTRY_CODE]: "Guyana",
    [countryCode_pb_1.CountryCode.HAITI_HT_COUNTRY_CODE]: "Haiti",
    [countryCode_pb_1.CountryCode.HEARD_ISLAND_AND_MCDONALD_ISLANDS_HM_COUNTRY_CODE]: "Heard Island and McDonald Islands",
    [countryCode_pb_1.CountryCode.HOLY_SEE_VA_COUNTRY_CODE]: "Holy See",
    [countryCode_pb_1.CountryCode.HONDURAS_HN_COUNTRY_CODE]: "Honduras",
    [countryCode_pb_1.CountryCode.HONG_KONG_HK_COUNTRY_CODE]: "Hong Kong",
    [countryCode_pb_1.CountryCode.HUNGARY_HU_COUNTRY_CODE]: "Hungary",
    [countryCode_pb_1.CountryCode.ICELAND_IS_COUNTRY_CODE]: "Iceland",
    [countryCode_pb_1.CountryCode.INDIA_IN_COUNTRY_CODE]: "India",
    [countryCode_pb_1.CountryCode.INDONESIA_ID_COUNTRY_CODE]: "Indonesia",
    [countryCode_pb_1.CountryCode.IRAN_ISLAMIC_REPUBLIC_OF_IR_COUNTRY_CODE]: "Iran, Islamic Republic of",
    [countryCode_pb_1.CountryCode.IRAQ_IQ_COUNTRY_CODE]: "Iraq",
    [countryCode_pb_1.CountryCode.IRELAND_IE_COUNTRY_CODE]: "Ireland",
    [countryCode_pb_1.CountryCode.ISLE_OF_MAN_IM_COUNTRY_CODE]: "Isle of Man",
    [countryCode_pb_1.CountryCode.ISRAEL_IL_COUNTRY_CODE]: "Israel",
    [countryCode_pb_1.CountryCode.ITALY_IT_COUNTRY_CODE]: "Italy",
    [countryCode_pb_1.CountryCode.JAMAICA_JM_COUNTRY_CODE]: "Jamaica",
    [countryCode_pb_1.CountryCode.JAPAN_JP_COUNTRY_CODE]: "Japan",
    [countryCode_pb_1.CountryCode.JERSEY_JE_COUNTRY_CODE]: "Jersey",
    [countryCode_pb_1.CountryCode.JORDAN_JO_COUNTRY_CODE]: "Jordan",
    [countryCode_pb_1.CountryCode.KAZAKHSTAN_KZ_COUNTRY_CODE]: "Kazakhstan",
    [countryCode_pb_1.CountryCode.KENYA_KE_COUNTRY_CODE]: "Kenya",
    [countryCode_pb_1.CountryCode.KIRIBATI_KI_COUNTRY_CODE]: "Kiribati",
    [countryCode_pb_1.CountryCode.KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KP_COUNTRY_CODE]: "Korea, Democratic People's Republic of",
    [countryCode_pb_1.CountryCode.KOREA_REPUBLIC_OF_KR_COUNTRY_CODE]: "Korea, Republic of",
    [countryCode_pb_1.CountryCode.KUWAIT_KW_COUNTRY_CODE]: "Kuwait",
    [countryCode_pb_1.CountryCode.KYRGYZSTAN_KG_COUNTRY_CODE]: "Kyrgyzstan",
    [countryCode_pb_1.CountryCode.LAO_PEOPLES_DEMOCRATIC_REPUBLIC_LA_COUNTRY_CODE]: "Lao People's Democratic Republic",
    [countryCode_pb_1.CountryCode.LATVIA_LV_COUNTRY_CODE]: "Latvia",
    [countryCode_pb_1.CountryCode.LEBANON_LB_COUNTRY_CODE]: "Lebanon",
    [countryCode_pb_1.CountryCode.LESOTHO_LS_COUNTRY_CODE]: "Lesotho",
    [countryCode_pb_1.CountryCode.LIBERIA_LR_COUNTRY_CODE]: "Liberia",
    [countryCode_pb_1.CountryCode.LIBYA_LY_COUNTRY_CODE]: "Libya",
    [countryCode_pb_1.CountryCode.LIECHTENSTEIN_LI_COUNTRY_CODE]: "Liechtenstein",
    [countryCode_pb_1.CountryCode.LITHUANIA_LT_COUNTRY_CODE]: "Lithuania",
    [countryCode_pb_1.CountryCode.LUXEMBOURG_LU_COUNTRY_CODE]: "Luxembourg",
    [countryCode_pb_1.CountryCode.MACAO_MO_COUNTRY_CODE]: "Macao",
    [countryCode_pb_1.CountryCode.MADAGASCAR_MG_COUNTRY_CODE]: "Madagascar",
    [countryCode_pb_1.CountryCode.MALAWI_MW_COUNTRY_CODE]: "Malawi",
    [countryCode_pb_1.CountryCode.MALAYSIA_MY_COUNTRY_CODE]: "Malaysia",
    [countryCode_pb_1.CountryCode.MALDIVES_MV_COUNTRY_CODE]: "Maldives",
    [countryCode_pb_1.CountryCode.MALI_ML_COUNTRY_CODE]: "Mali",
    [countryCode_pb_1.CountryCode.MALTA_MT_COUNTRY_CODE]: "Malta",
    [countryCode_pb_1.CountryCode.MARSHALL_ISLANDS_MH_COUNTRY_CODE]: "Marshall Islands",
    [countryCode_pb_1.CountryCode.MARTINIQUE_MQ_COUNTRY_CODE]: "Martinique",
    [countryCode_pb_1.CountryCode.MAURITANIA_MR_COUNTRY_CODE]: "Mauritania",
    [countryCode_pb_1.CountryCode.MAURITIUS_MU_COUNTRY_CODE]: "Mauritius",
    [countryCode_pb_1.CountryCode.MAYOTTE_YT_COUNTRY_CODE]: "Mayotte",
    [countryCode_pb_1.CountryCode.MEXICO_MX_COUNTRY_CODE]: "Mexico",
    [countryCode_pb_1.CountryCode.MICRONESIA_FEDERATED_STATES_OF_FM_COUNTRY_CODE]: "Micronesia, Federated States of",
    [countryCode_pb_1.CountryCode.MOLDOVA_REPUBLIC_OF_MD_COUNTRY_CODE]: "Moldova, Republic of",
    [countryCode_pb_1.CountryCode.MONACO_MC_COUNTRY_CODE]: "Monaco",
    [countryCode_pb_1.CountryCode.MONGOLIA_MN_COUNTRY_CODE]: "Mongolia",
    [countryCode_pb_1.CountryCode.MONTENEGRO_ME_COUNTRY_CODE]: "Montenegro",
    [countryCode_pb_1.CountryCode.MONTSERRAT_MS_COUNTRY_CODE]: "Montserrat",
    [countryCode_pb_1.CountryCode.MOROCCO_MA_COUNTRY_CODE]: "Morocco",
    [countryCode_pb_1.CountryCode.MOZAMBIQUE_MZ_COUNTRY_CODE]: "Mozambique",
    [countryCode_pb_1.CountryCode.MYANMAR_MM_COUNTRY_CODE]: "Myanmar",
    [countryCode_pb_1.CountryCode.NAMIBIA_NA_COUNTRY_CODE]: "Namibia",
    [countryCode_pb_1.CountryCode.NAURU_NR_COUNTRY_CODE]: "Nauru",
    [countryCode_pb_1.CountryCode.NEPAL_NP_COUNTRY_CODE]: "Nepal",
    [countryCode_pb_1.CountryCode.NETHERLANDS_KINGDOM_OF_THE_NL_COUNTRY_CODE]: "Netherlands, Kingdom of the",
    [countryCode_pb_1.CountryCode.NEW_CALEDONIA_NC_COUNTRY_CODE]: "New Caledonia",
    [countryCode_pb_1.CountryCode.NEW_ZEALAND_NZ_COUNTRY_CODE]: "New Zealand",
    [countryCode_pb_1.CountryCode.NICARAGUA_NI_COUNTRY_CODE]: "Nicaragua",
    [countryCode_pb_1.CountryCode.NIGER_NE_COUNTRY_CODE]: "Niger",
    [countryCode_pb_1.CountryCode.NIGERIA_NG_COUNTRY_CODE]: "Nigeria",
    [countryCode_pb_1.CountryCode.NIUE_NU_COUNTRY_CODE]: "Niue",
    [countryCode_pb_1.CountryCode.NORFOLK_ISLAND_NF_COUNTRY_CODE]: "Norfolk Island",
    [countryCode_pb_1.CountryCode.NORTH_MACEDONIA_MK_COUNTRY_CODE]: "North Macedonia",
    [countryCode_pb_1.CountryCode.NORTHERN_MARIANA_ISLANDS_MP_COUNTRY_CODE]: "Northern Mariana Islands",
    [countryCode_pb_1.CountryCode.NORWAY_NO_COUNTRY_CODE]: "Norway",
    [countryCode_pb_1.CountryCode.OMAN_OM_COUNTRY_CODE]: "Oman",
    [countryCode_pb_1.CountryCode.PAKISTAN_PK_COUNTRY_CODE]: "Pakistan",
    [countryCode_pb_1.CountryCode.PALAU_PW_COUNTRY_CODE]: "Palau",
    [countryCode_pb_1.CountryCode.PALESTINE_STATE_OF_PS_COUNTRY_CODE]: "Palestine, State of",
    [countryCode_pb_1.CountryCode.PANAMA_PA_COUNTRY_CODE]: "Panama",
    [countryCode_pb_1.CountryCode.PAPUA_NEW_GUINEA_PG_COUNTRY_CODE]: "Papua New Guinea",
    [countryCode_pb_1.CountryCode.PARAGUAY_PY_COUNTRY_CODE]: "Paraguay",
    [countryCode_pb_1.CountryCode.PERU_PE_COUNTRY_CODE]: "Peru",
    [countryCode_pb_1.CountryCode.PHILIPPINES_PH_COUNTRY_CODE]: "Philippines",
    [countryCode_pb_1.CountryCode.PITCAIRN_PN_COUNTRY_CODE]: "Pitcairn",
    [countryCode_pb_1.CountryCode.POLAND_PL_COUNTRY_CODE]: "Poland",
    [countryCode_pb_1.CountryCode.PORTUGAL_PT_COUNTRY_CODE]: "Portugal",
    [countryCode_pb_1.CountryCode.PUERTO_RICO_PR_COUNTRY_CODE]: "Puerto Rico",
    [countryCode_pb_1.CountryCode.QATAR_QA_COUNTRY_CODE]: "Qatar",
    [countryCode_pb_1.CountryCode.REUNION_RE_COUNTRY_CODE]: "Réunion",
    [countryCode_pb_1.CountryCode.ROMANIA_RO_COUNTRY_CODE]: "Romania",
    [countryCode_pb_1.CountryCode.RUSSIAN_FEDERATION_RU_COUNTRY_CODE]: "Russian Federation",
    [countryCode_pb_1.CountryCode.RWANDA_RW_COUNTRY_CODE]: "Rwanda",
    [countryCode_pb_1.CountryCode.SAINT_BARTHELEMY_BL_COUNTRY_CODE]: "Saint Barthélemy",
    [countryCode_pb_1.CountryCode.SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA_SH_COUNTRY_CODE]: "Saint Helena, Ascension and Tristan da Cunha",
    [countryCode_pb_1.CountryCode.SAINT_KITTS_AND_NEVIS_KN_COUNTRY_CODE]: "Saint Kitts and Nevis",
    [countryCode_pb_1.CountryCode.SAINT_LUCIA_LC_COUNTRY_CODE]: "Saint Lucia",
    [countryCode_pb_1.CountryCode.SAINT_MARTIN_FRENCH_PART_MF_COUNTRY_CODE]: "Saint Martin (French part)",
    [countryCode_pb_1.CountryCode.SAINT_PIERRE_AND_MIQUELON_PM_COUNTRY_CODE]: "Saint Pierre and Miquelon",
    [countryCode_pb_1.CountryCode.SAINT_VINCENT_AND_THE_GRENADINES_VC_COUNTRY_CODE]: "Saint Vincent and the Grenadines",
    [countryCode_pb_1.CountryCode.SAMOA_WS_COUNTRY_CODE]: "Samoa",
    [countryCode_pb_1.CountryCode.SAN_MARINO_SM_COUNTRY_CODE]: "San Marino",
    [countryCode_pb_1.CountryCode.SAO_TOME_AND_PRINCIPE_ST_COUNTRY_CODE]: "Sao Tome and Principe",
    [countryCode_pb_1.CountryCode.SAUDI_ARABIA_SA_COUNTRY_CODE]: "Saudi Arabia",
    [countryCode_pb_1.CountryCode.SENEGAL_SN_COUNTRY_CODE]: "Senegal",
    [countryCode_pb_1.CountryCode.SERBIA_RS_COUNTRY_CODE]: "Serbia",
    [countryCode_pb_1.CountryCode.SEYCHELLES_SC_COUNTRY_CODE]: "Seychelles",
    [countryCode_pb_1.CountryCode.SIERRA_LEONE_SL_COUNTRY_CODE]: "Sierra Leone",
    [countryCode_pb_1.CountryCode.SINGAPORE_SG_COUNTRY_CODE]: "Singapore",
    [countryCode_pb_1.CountryCode.SINT_MAARTEN_DUTCH_PART_SX_COUNTRY_CODE]: "Sint Maarten (Dutch part)",
    [countryCode_pb_1.CountryCode.SLOVAKIA_SK_COUNTRY_CODE]: "Slovakia",
    [countryCode_pb_1.CountryCode.SLOVENIA_SI_COUNTRY_CODE]: "Slovenia",
    [countryCode_pb_1.CountryCode.SOLOMON_ISLANDS_SB_COUNTRY_CODE]: "Solomon Islands",
    [countryCode_pb_1.CountryCode.SOMALIA_SO_COUNTRY_CODE]: "Somalia",
    [countryCode_pb_1.CountryCode.SOUTH_AFRICA_ZA_COUNTRY_CODE]: "South Africa",
    [countryCode_pb_1.CountryCode.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS_GS_COUNTRY_CODE]: "South Georgia and the South Sandwich Islands",
    [countryCode_pb_1.CountryCode.SOUTH_SUDAN_SS_COUNTRY_CODE]: "South Sudan",
    [countryCode_pb_1.CountryCode.SPAIN_ES_COUNTRY_CODE]: "Spain",
    [countryCode_pb_1.CountryCode.SRI_LANKA_LK_COUNTRY_CODE]: "Sri Lanka",
    [countryCode_pb_1.CountryCode.SUDAN_SD_COUNTRY_CODE]: "Sudan",
    [countryCode_pb_1.CountryCode.SURINAME_SR_COUNTRY_CODE]: "Suriname",
    [countryCode_pb_1.CountryCode.SVALBARD_AND_JAN_MAYEN_SJ_COUNTRY_CODE]: "Svalbard and Jan Mayen",
    [countryCode_pb_1.CountryCode.SWEDEN_SE_COUNTRY_CODE]: "Sweden",
    [countryCode_pb_1.CountryCode.SWITZERLAND_CH_COUNTRY_CODE]: "Switzerland",
    [countryCode_pb_1.CountryCode.SYRIAN_ARAB_REPUBLIC_SY_COUNTRY_CODE]: "Syrian Arab Republic",
    [countryCode_pb_1.CountryCode.TAIWAN_PROVINCE_OF_CHINA_TW_COUNTRY_CODE]: "Taiwan, Province of China",
    [countryCode_pb_1.CountryCode.TAJIKISTAN_TJ_COUNTRY_CODE]: "Tajikistan",
    [countryCode_pb_1.CountryCode.TANZANIA_UNITED_REPUBLIC_OF_TZ_COUNTRY_CODE]: "Tanzania, United Republic of",
    [countryCode_pb_1.CountryCode.THAILAND_TH_COUNTRY_CODE]: "Thailand",
    [countryCode_pb_1.CountryCode.TIMORLESTE_TL_COUNTRY_CODE]: "Timor-Leste",
    [countryCode_pb_1.CountryCode.TOGO_TG_COUNTRY_CODE]: "Togo",
    [countryCode_pb_1.CountryCode.TOKELAU_TK_COUNTRY_CODE]: "Tokelau",
    [countryCode_pb_1.CountryCode.TONGA_TO_COUNTRY_CODE]: "Tonga",
    [countryCode_pb_1.CountryCode.TRINIDAD_AND_TOBAGO_TT_COUNTRY_CODE]: "Trinidad and Tobago",
    [countryCode_pb_1.CountryCode.TUNISIA_TN_COUNTRY_CODE]: "Tunisia",
    [countryCode_pb_1.CountryCode.TURKIYE_TR_COUNTRY_CODE]: "Türkiye",
    [countryCode_pb_1.CountryCode.TURKMENISTAN_TM_COUNTRY_CODE]: "Turkmenistan",
    [countryCode_pb_1.CountryCode.TURKS_AND_CAICOS_ISLANDS_TC_COUNTRY_CODE]: "Turks and Caicos Islands",
    [countryCode_pb_1.CountryCode.TUVALU_TV_COUNTRY_CODE]: "Tuvalu",
    [countryCode_pb_1.CountryCode.UGANDA_UG_COUNTRY_CODE]: "Uganda",
    [countryCode_pb_1.CountryCode.UKRAINE_UA_COUNTRY_CODE]: "Ukraine",
    [countryCode_pb_1.CountryCode.UNITED_ARAB_EMIRATES_AE_COUNTRY_CODE]: "United Arab Emirates",
    [countryCode_pb_1.CountryCode.UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND_GB_COUNTRY_CODE]: "United Kingdom of Great Britain and Northern Ireland",
    [countryCode_pb_1.CountryCode.UNITED_STATES_OF_AMERICA_US_COUNTRY_CODE]: "United States of America",
    [countryCode_pb_1.CountryCode.UNITED_STATES_MINOR_OUTLYING_ISLANDS_UM_COUNTRY_CODE]: "United States Minor Outlying Islands",
    [countryCode_pb_1.CountryCode.URUGUAY_UY_COUNTRY_CODE]: "Uruguay",
    [countryCode_pb_1.CountryCode.UZBEKISTAN_UZ_COUNTRY_CODE]: "Uzbekistan",
    [countryCode_pb_1.CountryCode.VANUATU_VU_COUNTRY_CODE]: "Vanuatu",
    [countryCode_pb_1.CountryCode.VENEZUELA_BOLIVARIAN_REPUBLIC_OF_VE_COUNTRY_CODE]: "Venezuela, Bolivarian Republic of",
    [countryCode_pb_1.CountryCode.VIET_NAM_VN_COUNTRY_CODE]: "Viet Nam",
    [countryCode_pb_1.CountryCode.VIRGIN_ISLANDS_BRITISH_VG_COUNTRY_CODE]: "Virgin Islands (British)",
    [countryCode_pb_1.CountryCode.VIRGIN_ISLANDS_US_VI_COUNTRY_CODE]: "Virgin Islands (U.S.)",
    [countryCode_pb_1.CountryCode.WALLIS_AND_FUTUNA_WF_COUNTRY_CODE]: "Wallis and Futuna",
    [countryCode_pb_1.CountryCode.WESTERN_SAHARA_EH_COUNTRY_CODE]: "Western Sahara",
    [countryCode_pb_1.CountryCode.YEMEN_YE_COUNTRY_CODE]: "Yemen",
    [countryCode_pb_1.CountryCode.ZAMBIA_ZM_COUNTRY_CODE]: "Zambia",
    [countryCode_pb_1.CountryCode.ZIMBABWE_ZW_COUNTRY_CODE]: "Zimbabwe",
};
// Reverse mapping from string to CountryCode enum
const stringToCountryCodeMapping = {};
for (const [key, value] of Object.entries(countryCodeToStringMapping)) {
    stringToCountryCodeMapping[value] = Number(key);
}
class UnsupportedCountryCodeError extends Error {
    constructor(countryCode) {
        const message = `Unsupported CountryCode: ${countryCode}`;
        super(message);
        this.countryCode = countryCode;
    }
}
/**
 * Converts a CountryCode enum instance to a custom string representation.
 * @param {CountryCode} countryCode - The countryCode to convert.
 * @returns {string} The custom string representation of the countryCode.
 */
function countryCodeToString(countryCode) {
    if (countryCode in countryCodeToStringMapping) {
        return countryCodeToStringMapping[countryCode];
    }
    else {
        throw new UnsupportedCountryCodeError(countryCode);
    }
}
class UnsupportedCountryCodeStringError extends Error {
    constructor(countryCodeStr) {
        const message = `Unsupported countryCode string: ${countryCodeStr}`;
        super(message);
        this.countryCodeStr = countryCodeStr;
    }
}
/**
 * Converts a custom string representation to a CountryCode enum instance.
 * @param {string} countryCodeStr - The custom string representation of the countryCode.
 * @returns {CountryCode} The corresponding CountryCode enum instance.
 */
function stringToCountryCode(countryCodeStr) {
    if (countryCodeStr in stringToCountryCodeMapping) {
        return stringToCountryCodeMapping[countryCodeStr];
    }
    else {
        throw new UnsupportedCountryCodeStringError(countryCodeStr);
    }
}
