// text types
export const TextSubstringCriterionTypeName = "TextSubstring";
export const TextExactCriterionTypeName = "TextExact";
export const TextListCriterionTypeName = "TextList";
export const TextNEExactCriterionTypeName = "TextNEExact";
export const TextNINListCriterionTypeName = "TextNINList";

// number types
export const NumberRangeCriterionTypeName = "NumberRange";
export const NumberExactCriterionTypeName = "NumberExact";

// date types
export const DateRangeCriterionTypeName = "DateRange";

// boolean type
export const BooleanCriterionTypeName = "Boolean";
