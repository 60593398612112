import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  MenuOpen as DrawerToggleIcon,
  QuestionAnswer as UnknownIcon,
} from "@mui/icons-material";
import cx from "classnames";
import { RouteType } from "routes/Route";
import { useLocation, useNavigate } from "react-router-dom";
import { MainAppBarHeight } from "components/MainAppBar/MainAppBar";
import config from "react-global-configuration";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "Sidebar";

const classes = {
  list: `${PREFIX}-list`,
  listItemRoot: `${PREFIX}-listItemRoot`,
  listItemButton: `${PREFIX}-listItemButton`,
  listItemSelected: `${PREFIX}-listItemSelected`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperMini: `${PREFIX}-drawerPaperMini`,
  versionAndDrawerToggleWrapper: `${PREFIX}-versionAndDrawerToggleWrapper`,
  drawerToggleLayout: `${PREFIX}-drawerToggleLayout`,
  drawerOpenToggleIconButton: `${PREFIX}-drawerOpenToggleIconButton`,
  drawerClosedToggleIconButton: `${PREFIX}-drawerClosedToggleIconButton`,
  sidebarToggleMessage: `${PREFIX}-sidebarToggleMessage`,
  versionWrapper: `${PREFIX}-versionWrapper`,
  version: `${PREFIX}-version`,
};

const StyledDiv = styled(Box)(({ theme }) => ({
  [`& .${classes.drawerPaper}`]: {
    marginRight: -10,
    overflow: "hidden",
    backgroundColor: "#171433",
    border: "none",
    position: "fixed",
    bottom: "0",
    left: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    boxShadow: "none",
  },

  [`& .${classes.drawerPaperMini}`]: {
    boxShadow:
      "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    width: `${drawerMiniWidth}px !important`,
  },

  [`& .${classes.versionAndDrawerToggleWrapper}`]: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column-reverse",
  },

  [`& .${classes.drawerToggleLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    columnGap: theme.spacing(2),
    marginLeft: theme.spacing(1.25),
  },

  [`& .${classes.drawerOpenToggleIconButton}`]: {
    transition: "0.50s",
    "-webkit-transition": "0.50s",
    "-moz-transition": "0.50s",
    "-ms-transition": "0.50s",
    "-o-transition": "0.50s",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.drawerClosedToggleIconButton}`]: {
    transition: "0.50s",
    "-webkit-transition": "0.50s",
    "-moz-transition": "0.50s",
    "-ms-transition": "0.50s",
    "-o-transition": "0.50s",
    "-webkit-transform": "rotate(180deg)",
    "-moz-transform": "rotate(180deg)",
    "-o-transform": "rotate(180deg)",
    "-ms-transform": "rotate(180deg)",
    transform: "rotate(180deg)",
  },

  [`& .${classes.sidebarToggleMessage}`]: {
    cursor: "pointer",
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.versionWrapper}`]: {
    display: "flex",
    width: drawerMiniWidth,
    flexDirection: "column-reverse",
  },

  [`& .${classes.version}`]: {
    paddingBottom: theme.spacing(1),
    fontSize: 10,
    alignSelf: "center",
    color: theme.palette.text.secondary,
  },
  [`&.${classes.list}`]: {
    padding: 0,
    zIndex: 3000,
    cursor: "pointer",
  },

  [`& .${classes.listItemRoot}`]: {
    opacity: "50%",
  },

  [`& .${classes.listItemButton}`]: {
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.action.focus,
        theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity,
      ),
      opacity: "100%",
    },
  },

  [`& .${classes.listItemSelected}`]: {
    backgroundColor: `${alpha(
      theme.palette.action.focus,
      theme.palette.action.selectedOpacity,
    )} !important`,
    opacity: "100%",
  },
}));

export const drawerWidth = 260;
export const drawerMiniWidth = 55;

interface SidebarProps {
  sidebarClosed: boolean;
  handleSidebarOpenToggle: () => void;
  homeRoute?: RouteType;
  otherRoutes: RouteType[];
}

export function Sidebar(props: SidebarProps) {
  const [mouseOverSidebar, setMouseOverSidebar] = useState(false);
  const { NotificationBannerHeight } = useAppNoticeContext();

  const sidebarMinimized = props.sidebarClosed && !mouseOverSidebar;

  const drawerPaper = cx(classes.drawerPaper, {
    [classes.drawerPaperMini]: sidebarMinimized,
  });

  return (
    <StyledDiv
      onMouseEnter={() => setMouseOverSidebar(true)}
      onMouseLeave={() => setMouseOverSidebar(false)}
    >
      <Drawer
        classes={{ paper: drawerPaper }}
        PaperProps={{
          sx: {
            height: `calc(100vh - ${
              MainAppBarHeight + NotificationBannerHeight
            }px)`,
            top: MainAppBarHeight + NotificationBannerHeight,
          },
        }}
        anchor="left"
        variant="permanent"
        open
      >
        {/* Sidebar menu items */}
        <SidebarItems
          appRoutes={props.otherRoutes}
          homeRoute={props.homeRoute}
        />

        {/* Version and drawer toggle */}
        <div className={classes.versionAndDrawerToggleWrapper}>
          <div className={classes.versionWrapper}>
            <Typography
              id="sidebar-appVersion-text"
              className={classes.version}
              children={config.get("applicationVersion")}
            />
          </div>

          <div
            className={classes.drawerToggleLayout}
            onClick={props.handleSidebarOpenToggle}
          >
            <IconButton
              className={cx(classes.drawerOpenToggleIconButton, {
                [classes.drawerClosedToggleIconButton]: props.sidebarClosed,
              })}
              size="small"
              id="sidebar-drawerToggle-button"
            >
              <DrawerToggleIcon />
            </IconButton>
            {mouseOverSidebar && (
              <Typography
                id="sidebar-drawerToggle-text"
                variant="body1"
                color="textSecondary"
                className={classes.sidebarToggleMessage}
                noWrap
                children={props.sidebarClosed ? "Open Menu" : "Close Menu"}
              />
            )}
          </div>
        </div>
      </Drawer>
    </StyledDiv>
  );
}

interface SidebarItemsProps {
  appRoutes: RouteType[];
  homeRoute?: RouteType;
}

function SidebarItems({ appRoutes, homeRoute }: SidebarItemsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <List className={classes.list}>
      {homeRoute && (
        <ListItem
          classes={{
            root: classes.listItemRoot,
            selected: classes.listItemSelected,
            button: classes.listItemButton,
          }}
          id={homeRoute.id}
          onClick={() => navigate(homeRoute.path)}
          selected={location.pathname === homeRoute.path}
        >
          <ListItemIcon>
            {homeRoute.icon ? <homeRoute.icon /> : <DashboardIcon />}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={homeRoute.name}
          />
        </ListItem>
      )}
      {appRoutes.map((route, idx) => (
        <ListItem
          key={idx}
          classes={{
            root: classes.listItemRoot,
            selected: classes.listItemSelected,
            button: classes.listItemButton,
          }}
          id={route.id}
          onClick={() => navigate(route.path)}
          selected={location.pathname.includes(route.path)}
        >
          <ListItemIcon>
            {route.icon ? <route.icon /> : <UnknownIcon />}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={route.name}
          />
        </ListItem>
      ))}
    </List>
  );
}
