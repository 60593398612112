import React, { useMemo } from "react";
import { Model } from "james/views/financialCashFlowView/Model";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { PaymentState } from "@mesh/common-js/dist/financial/payment_pb";

interface RepaymentPieChartProps {
  size: number;
  cashFlowViewModels: Model[];
}

export const RepaymentPieChart = ({
  size,
  cashFlowViewModels,
}: RepaymentPieChartProps) => {
  const dataSet = useMemo(() => {
    let paymentsMade = 0;
    for (const m of cashFlowViewModels) {
      if ([PaymentState.PAID_PAYMENT_STATE].includes(m.paymentState)) {
        paymentsMade++;
      }
    }

    return [
      {
        key: "repayed",
        value: paymentsMade,
      },
      {
        key: "outstanding",
        value: cashFlowViewModels.length - paymentsMade,
      },
    ];
  }, [cashFlowViewModels]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <defs>
          <linearGradient id="colorSecondary" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={"#56D9FD"} stopOpacity={0.4} />
            <stop offset="95%" stopColor={"#56D9FD"} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPurple" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={"#8E258D"} stopOpacity={0.4} />
            <stop offset="95%" stopColor={"#8E258D"} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Pie
          data={dataSet}
          dataKey={"value"}
          height={size}
          innerRadius={size / 4 - 4}
          outerRadius={size / 2 - 2}
        >
          <Cell stroke="#56D9FD" fill="url(#colorSecondary)" />
          <Cell stroke="#8E258D" fill="url(#colorPurple)" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
