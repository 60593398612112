import { ValidationResult } from "common/validation";
import { ValidateEmail } from "utilities/validation";

export type FormState = {
  email: string;
};

export type formUpdaterSpecsType = {
  email: (value: string, prevState?: FormState) => FormState;
};

export const formUpdaterSpecs: formUpdaterSpecsType = {
  email: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    email: value,
  }),
};

export const validationFunc = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (formState.email === "") {
    newValidationState.fieldValidations.email = "Email cannot be empty";
    newValidationState.valid = false;
  } else if (!ValidateEmail(formState.email)) {
    newValidationState.fieldValidations.email = "Email is invalid";
    newValidationState.valid = false;
  }

  return newValidationState;
};

const initializeState = (): FormState => {
  return {
    email: "",
  };
};

export const initialState = initializeState();
