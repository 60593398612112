import React from "react";
import { FinancialInstrumentState } from "james/financial/InstrumentState";
import { ETFStablecoinState } from "james/financial";
import { Chip, useTheme } from "@mui/material";
import { FinancialInstrumentStablecoinState } from "james/financial/InstrumentStablecoinState";
import { ListingState } from "james/market/Listing";

export interface InstrumentStateChipProps {
  state: FinancialInstrumentState | FinancialInstrumentStablecoinState | "";
}

export function InstrumentStateChip(props: InstrumentStateChipProps) {
  const theme = useTheme();
  const chipStyles = {
    chip: {
      fontWeight: 400,
    },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    success: { backgroundColor: theme.palette.success.main },
    secondary: { backgroundColor: theme.palette.info.main },
  };

  switch (props.state) {
    case ETFStablecoinState.Draft:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          id="instrumentStateChip-draft-chip"
          size="small"
          label={props.state}
        />
      );

    case ETFStablecoinState.PreIssued:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          id="instrumentStateChip-preIssued-chip"
          size="small"
          label={props.state}
        />
      );

    case ETFStablecoinState.Issued:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          id="instrumentStateChip-issued-chip"
          size="small"
          label={props.state}
        />
      );

    case ETFStablecoinState.Matured:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          id="instrumentStateChip-matured-chip"
          size="small"
          label={props.state}
        />
      );

    case ETFStablecoinState.Cancelled:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.error,
          }}
          id="instrumentStateChip-cancelled-chip"
          size="small"
          label={props.state}
        />
      );

    case ETFStablecoinState.Deleted:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.error,
          }}
          id="instrumentStateChip-deleted-chip"
          size="small"
          label={props.state}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}

export interface PlacementStateChipProps {
  listingState: ListingState | "";
}

export function PlacementStateChip(props: PlacementStateChipProps) {
  const theme = useTheme();
  const chipStyles = {
    chip: {
      fontWeight: 400,
    },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    success: { backgroundColor: theme.palette.success.main },
    secondary: { backgroundColor: theme.palette.info.main },
  };
  switch (props.listingState) {
    case ListingState.Active:
      return (
        <Chip
          id="placementStateChip-placed-chip"
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          size="small"
          label="Yes"
        />
      );

    case ListingState.ListingInProgress:
      return (
        <Chip
          id="placementStateChip-inProgress-chip"
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          size="small"
          label="In Progress"
        />
      );

    case ListingState.ListingFailed:
      return (
        <Chip
          id="placementStateChip-failed-chip"
          sx={{
            ...chipStyles.chip,
            ...chipStyles.error,
          }}
          size="small"
          label="Failed"
        />
      );

    case ListingState.ListingUnderInvestigation:
      return (
        <Chip
          id="placementStateChip-underInvestigation-chip"
          sx={{
            ...chipStyles.chip,
            ...chipStyles.error,
          }}
          size="small"
          label="Under Investigation"
        />
      );

    case "":
      return (
        <Chip
          id="placementStateChip-notPlaced-chip"
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          size="small"
          label="No"
        />
      );

    default:
      return <div>{"-"}</div>;
  }
}

export interface PlacementStateWordsChipProps {
  placed: boolean;
}

export function PlacementStateWordsChip(props: PlacementStateWordsChipProps) {
  const theme = useTheme();
  const chipStyles = {
    chip: {
      fontWeight: 400,
    },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    success: { backgroundColor: theme.palette.success.main },
    secondary: { backgroundColor: theme.palette.info.main },
  };
  if (props.placed) {
    return (
      <Chip
        id="placementStateWordsChip-placed-chip"
        sx={{
          ...chipStyles.chip,
          ...chipStyles.success,
        }}
        size="small"
        label="On Marketplace"
      />
    );
  }
  return (
    <Chip
      id="placementStateWordsChip-notPlaced-chip"
      sx={{
        ...chipStyles.chip,
        ...chipStyles.warning,
      }}
      size="small"
      label="No Marketplace Listing"
    />
  );
}
