import React from "react";
import { PaymentContextProvider } from "./Context";
import { PaymentDetail } from "./PaymentDetail";
import { useApplicationContext } from "context/Application/Application";

export const Payment = () => {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Payments"]?.ReadUNSCOPED;

  return (
    <PaymentContextProvider system={system}>
      <PaymentDetail system={system} />
    </PaymentContextProvider>
  );
};
