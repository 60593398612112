import dayjs, { Dayjs } from "dayjs";

export class NewsArticle {
  public date: Dayjs = dayjs();
  public excerpt: string = "";
  public url: string = "";

  constructor(n?: NewsArticle) {
    if (!n) return;

    this.date = dayjs(n.date);
    this.excerpt = n.excerpt;
    this.url = n.url;
  }
}
