import React, { useContext } from "react";
import { Client } from "utilities/network/jsonRPCRequest/jsonRPCRequestWithMiddleware";
import { useMUAContext } from "context/MUA/MUA";
import { AccountOperatorWithMiddleware } from "james/stellar/AccountOperator";

interface ContextType {
  ServiceProviders: {
    accountOperator: AccountOperatorWithMiddleware;
  };
}

const Context = React.createContext({} as ContextType);

export function RequestContext({ children }: { children?: React.ReactNode }) {
  const { middleWare: mfaMiddleware } = useMUAContext();

  const middleware = [mfaMiddleware];

  const clientWithMiddleware = new Client(middleware);

  // service providers with MFA
  const accountOperator = new AccountOperatorWithMiddleware(
    clientWithMiddleware,
  );

  return (
    <Context.Provider
      value={{
        ServiceProviders: {
          accountOperator: accountOperator,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useRequestContext = () => useContext(Context);
export { useRequestContext };
