import { IDIdentifier } from "./ID";
import { LedgerIDIdentifier } from "./LedgerID";
import { NameIdentifier } from "./Name";
import { NumberIdentifier } from "./Number";
import { ShortNameIdentifier } from "./ShortName";
import { CategoryOwnerIDNetworkIdentifier } from "./CategoryOwnerIDNetwork";
import { RegisteredNameIdentifier } from "./RegisteredName";
import { RegistrationNumberIdentifier } from "./RegistrationNumber";
import { TokenIdentifier } from "./Token";

export enum IdentifierType {
  IDIdentifierTypeName = "ID",
  LedgerIDIdentifierTypeName = "LedgerID",
  NameIdentifierTypeName = "Name",
  ShortNameIdentifierTypeName = "ShortName",
  RegistrationNumberIdentifierTypeName = "RegistrationNumber",
  RegisteredNameIdentifierTypeName = "RegisteredName",
  NumberIdentifierTypeName = "Number",
  CategoryOwnerIDNetworkTypeName = "CategoryOwnerIDNetwork",
  TokenTypeName = "Token",
}

export {
  IDIdentifier,
  ShortNameIdentifier,
  LedgerIDIdentifier,
  NameIdentifier,
  CategoryOwnerIDNetworkIdentifier,
  RegisteredNameIdentifier,
  NumberIdentifier,
  RegistrationNumberIdentifier,
  TokenIdentifier,
};
