import { AuditEntry } from "james/audit";

export enum UserState {
  Registered = "Registered",
  Disabled = "Disabled",
}

export enum UserGuide {
  FirstTimeLoginSteps = "First Time Login Steps",
  PlatformGuidedJourney = "Platform Guided Journey",
  ScrollAssetDetailView = "Scroll Asset Detail View",
}

export const AllUserRegistrationStatus = [
  UserState.Registered,
  UserState.Disabled,
];

export const UserTypeName = "mesh::user/User";

export class User {
  public ["@type"]: string = UserTypeName;

  public id = "";

  public ownerID = "";

  public clientID = "";

  public personID = "";

  public email = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public roleIDs: string[] = [];

  public ausAgreementVersion = "";

  public state: UserState = UserState.Disabled;

  public completedGuides: UserGuide[] = [];

  constructor(user?: User) {
    if (!user) {
      return;
    }
    this.id = user.id;
    this.ownerID = user.ownerID;
    this.clientID = user.clientID;
    this.personID = user.personID;
    this.email = user.email;
    this.auditEntry = new AuditEntry(user.auditEntry);
    this.roleIDs = user.roleIDs.map((id) => id);
    this.ausAgreementVersion = user.ausAgreementVersion;
    this.state = user.state;
    this.completedGuides = user.completedGuides;
  }
}
