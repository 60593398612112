import React, { Dispatch, SetStateAction, useState } from "react";
import {
  alpha,
  Box,
  IconButton,
  Skeleton,
  Theme,
  Typography,
} from "@mui/material";
import { Model } from "james/views/stellarAccountView";
import { SkeletonLoader } from "components/SkeletonLoader";
import Tooltip from "@mui/material/Tooltip";
import { FileCopy as CopyPasteIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { LedgerAccountCategory } from "james/ledger";
import { FundDeFundCard } from "../FundDeFund/FundDeFundCard";
import { CashBalancesCard } from "./components/CashBalancesCard";
import { formatTextNum } from "utilities/number";
import { AccountOverview } from "james/views/stellarPortfolioView";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

interface CashBalancesProps {
  accountModel?: Model;
  setShowDeFundDialog: Dispatch<SetStateAction<boolean>>;
  setShowFundAccDialog: Dispatch<SetStateAction<boolean>>;
  accountOverview?: AccountOverview;
  showDeFundDialog: boolean;
  singleAccount?: boolean;
  showFundAccDialog: boolean;
}

export function CashBalances(props: CashBalancesProps) {
  const [meshAccountCopied, setMeshAccountCopied] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const fundDeFundOnBalanceCard =
    props.accountModel &&
    props.accountModel.balances.filter(
      (v) =>
        v.tokenViewModel.tokenCategory === TokenCategory.RightsToFiatCurrency,
    ).length === 1;

  const [scrollEnd, setScrollEnd] = useState(false);
  const [scrollStart, setScrollStart] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollLeft, scrollWidth, clientWidth } = target;
    const isFullyScrolled = scrollLeft + clientWidth >= scrollWidth;

    setScrollStart(scrollLeft === 0);
    setScrollEnd(isFullyScrolled);
  };

  return (
    <Box>
      {!isMobile && (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: theme.spacing(4),
          })}
        >
          {!props.singleAccount && (
            <Box>
              {(() => {
                if (!props.singleAccount) {
                  return (
                    <Box>
                      <SkeletonLoader loading={!props.accountOverview}>
                        <Typography
                          sx={{
                            textTransform: "none",
                            alignSelf: "center",
                            color: (theme) => theme.palette.text.disabled,
                          }}
                          variant="overline"
                          children="Account Value"
                        />
                      </SkeletonLoader>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          alignSelf: "center",
                        }}
                      >
                        {props.accountOverview && (
                          <Box
                            component={"span"}
                            sx={(theme) => ({
                              color: alpha(theme.palette.secondary.dark, 0.6),
                            })}
                          >
                            {props.accountOverview.currentValue.token.code}
                            &nbsp;
                          </Box>
                        )}
                        {props.accountOverview && (
                          <Box
                            component={"span"}
                            sx={(theme) => ({
                              color: theme.palette.secondary.dark,
                              marginTop: "4px",
                            })}
                          >
                            {formatTextNum(
                              props.accountOverview.currentValue.value,
                              {
                                noDecimalPlaces: 2,
                                addDecimalPadding: true,
                              },
                            )}
                          </Box>
                        )}
                      </Typography>
                    </Box>
                  );
                }
              })()}
            </Box>
          )}
          {props.singleAccount && (
            <Typography
              sx={(theme) => ({
                fontWeight: "bold",
                fontSize: theme.spacing(2),
                color: theme.palette.custom.lavenderLight,
              })}
            >
              Cash Balances
            </Typography>
          )}
          <Box>
            <SkeletonLoader loading={!props.accountModel}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  padding: "0px, 16px",
                }}
              >
                {!isMobile &&
                  props.accountModel &&
                  !fundDeFundOnBalanceCard && (
                    <FundDeFundCard
                      accountModel={props.accountModel}
                      setShowDeFundDialog={props.setShowDeFundDialog}
                      setShowFundAccDialog={props.setShowFundAccDialog}
                      showDeFundDialog={props.showDeFundDialog}
                      showFundAccDialog={props.showFundAccDialog}
                    />
                  )}
                {props.accountModel ? (
                  <Tooltip
                    sx={{
                      paddingRight: fundDeFundOnBalanceCard ? 0 : "27px",
                    }}
                    PopperProps={{ disablePortal: true }}
                    onClose={() => setMeshAccountCopied(false)}
                    open={meshAccountCopied}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied"
                  >
                    <IconButton
                      id={`accountDetails-cashBalances-meshAccountCopied-iconButton-${props.accountModel.number}`}
                      data-testid={`accountDetails-meshAccountCopied-iconButton-${props.accountModel.number}`}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            props.accountModel ? props.accountModel.number : "",
                          )
                          .then(() => setMeshAccountCopied(true));
                      }}
                      onMouseLeave={() => setMeshAccountCopied(false)}
                      sx={{ padding: "8px", marginRight: "16px" }}
                      size="small"
                      data-link-info={JSON.stringify({
                        content_interaction_id: "copy-info",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: "copy",
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_driver:
                          InteractionDriver.FundAccount,
                      } as DataLinkInfoType)}
                    >
                      <CopyPasteIcon
                        sx={{
                          fill: "text.secondary",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Skeleton width={"24px"} height={"24px"} />
                )}
                {props.accountModel ? (
                  <Typography
                    id={`accountDetails-cashBalances-accountNumber-typography`}
                    variant={isMobile ? "subtitle1" : "caption"}
                    component={"div"}
                  >
                    {props.accountModel.number}
                  </Typography>
                ) : (
                  <Skeleton width={"80px"} height={"24px"} />
                )}
                <Typography
                  variant={isMobile ? "subtitle1" : "caption"}
                  component={"div"}
                  sx={(theme) => ({
                    color: theme.palette.text.disabled,
                    mr: 1,
                  })}
                >
                  Mesh Acc Nr:
                </Typography>
              </Box>
            </SkeletonLoader>
          </Box>
        </Box>
      )}
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginBottom: !props.singleAccount ? "16px" : "32px" }}>
            <SkeletonLoader loading={!props.accountModel}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                }}
              >
                {props.accountModel ? (
                  <Typography
                    id={`accountDetails-cashBalancesCard-accountNumber-typography`}
                    sx={(theme) => ({
                      padding: theme.spacing(0, 1),
                      fontSize: theme.spacing(1.5),
                    })}
                    variant={"subtitle1"}
                  >
                    {props.accountModel.number}
                  </Typography>
                ) : (
                  <Skeleton width={"80px"} height={"24px"} />
                )}
                <Typography
                  variant={"subtitle1"}
                  sx={(theme) => ({
                    color: theme.palette.text.disabled,
                    fontSize: theme.spacing(1.5),
                    mr: 1,
                  })}
                >
                  Mesh Acc Nr:
                </Typography>
              </Box>
            </SkeletonLoader>
          </Box>
          {(() => {
            if (!props.singleAccount) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    margin: theme.spacing(0, 0, 4),
                    flexDirection: "column",
                    alignItems: "center",
                  })}
                >
                  <SkeletonLoader loading={!props.accountOverview}>
                    <Typography
                      sx={{
                        opacity: "0.38",
                        textTransform: "none",
                      }}
                      variant="overline"
                      children="Account Value"
                    />
                  </SkeletonLoader>
                  {props.accountOverview ? (
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        alignSelf: "center",
                      }}
                    >
                      {props.accountOverview && (
                        <Box
                          component={"span"}
                          sx={(theme) => ({
                            color: alpha(theme.palette.secondary.dark, 0.6),
                          })}
                        >
                          {props.accountOverview.currentValue.token.code}&nbsp;
                        </Box>
                      )}
                      {props.accountOverview && (
                        <Box
                          component={"span"}
                          sx={(theme) => ({
                            color: theme.palette.secondary.dark,
                          })}
                        >
                          {formatTextNum(
                            props.accountOverview.currentValue.value,
                            {
                              noDecimalPlaces: 2,
                              addDecimalPadding: true,
                            },
                          )}
                        </Box>
                      )}
                    </Typography>
                  ) : (
                    <Skeleton width={"100px"} height={"24px"} />
                  )}
                </Box>
              );
            }
          })()}
          {
            // conditionally render fund and defund below cash balances when on mobile
            (() => {
              if (
                props.accountModel &&
                isMobile &&
                props.accountModel.category !==
                  LedgerAccountCategory.Clearance &&
                props.accountModel.category !== LedgerAccountCategory.Issuance
              ) {
                return (
                  <FundDeFundCard
                    accountModel={props.accountModel}
                    setShowDeFundDialog={props.setShowDeFundDialog}
                    setShowFundAccDialog={props.setShowFundAccDialog}
                    showDeFundDialog={props.showDeFundDialog}
                    showFundAccDialog={props.showFundAccDialog}
                  />
                );
              }
              return <></>;
            })()
          }
          {props.accountModel?.category !== LedgerAccountCategory.Issuance && (
            <Typography
              sx={(theme) => ({
                color: theme.palette.custom.lavenderLight,
                paddingTop: theme.spacing(5),
                fontSize: theme.spacing(2),
              })}
              fontWeight={"500"}
            >
              Cash Balances
            </Typography>
          )}
        </Box>
      )}
      {!props.singleAccount &&
        !isMobile &&
        props.accountModel?.category !== LedgerAccountCategory.Issuance &&
        props.accountModel &&
        props.accountModel.balances.filter(
          (v) =>
            v.tokenViewModel.tokenCategory ===
            TokenCategory.RightsToFiatCurrency,
        ).length > 1 && (
          <Typography
            sx={(theme) => ({
              fontWeight: "bold",
              fontSize: theme.spacing(2),
              color: theme.palette.custom.lavenderLight,
              marginBottom: theme.spacing(2),
            })}
          >
            Cash Balances
          </Typography>
        )}
      {props.accountModel?.category !== LedgerAccountCategory.Issuance && (
        <Box
          sx={(theme) => ({
            width: "100%",
            [theme.breakpoints.down("sm")]: {
              marginTop: isMobile ? theme.spacing(2) : theme.spacing(5),
            },
            position: "relative",
          })}
        >
          {props.accountModel ? (
            props.accountModel.balances.filter(
              (v) =>
                v.tokenViewModel.tokenCategory ===
                TokenCategory.RightsToFiatCurrency,
            ).length > 0 && (
              <Box
                onScroll={handleScroll}
                sx={(theme) => ({
                  display: "flex",
                  gap: theme.spacing(2),
                  gridGap: (theme) => theme.spacing(2),
                  alignItems: "center",
                  marginBottom: theme.spacing(1),
                  justifyContent:
                    props.accountModel &&
                    props.accountModel.balances.filter(
                      (v) =>
                        v.tokenViewModel.tokenCategory ===
                        TokenCategory.RightsToFiatCurrency,
                    ).length === 1 &&
                    isMobile
                      ? "center"
                      : "flex-start",
                  overflowX: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  maxWidth: "800px",
                  [theme.breakpoints.down("sm")]: {
                    px: 2,
                    "&::after": {
                      content: '""',
                      opacity: scrollEnd ? 0 : 1,
                      transition:
                        "opacity 400ms ease-in, opacity 200ms ease-out",
                      position: "absolute",
                      right: 0,
                      width: 64,
                      height: "100%",
                      background: `linear-gradient(to left, ${theme.palette.custom.midnight} 4px,transparent)`,
                    },
                    "&::before": {
                      content: '""',
                      opacity: scrollStart ? 0 : 1,
                      transition:
                        "opacity 200ms ease-in, opacity 400ms ease-out",
                      position: "absolute",
                      left: 0,
                      height: "100%",
                      width: 40,
                      background: `linear-gradient(to right, ${theme.palette.custom.midnight} 4px,transparent)`,
                    },
                  },
                })}
              >
                {props.accountModel &&
                  props.accountModel.balances
                    .filter(
                      (v) =>
                        v.tokenViewModel.tokenCategory ===
                        TokenCategory.RightsToFiatCurrency,
                    )
                    .map((v, idx) => (
                      <CashBalancesCard
                        key={`cashBalanceCard-${idx}`}
                        fundDeFundOnBalanceCard={fundDeFundOnBalanceCard}
                        balance={v}
                        accountModel={props.accountModel}
                        setShowDeFundDialog={props.setShowDeFundDialog}
                        setShowFundAccDialog={props.setShowFundAccDialog}
                        showDeFundDialog={props.showDeFundDialog}
                        showFundAccDialog={props.showFundAccDialog}
                      />
                    ))}
              </Box>
            )
          ) : (
            <Skeleton width={"100%"} height={"72px"} />
          )}
        </Box>
      )}
    </Box>
  );
}
