"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyTimeFromDateToDate = applyTimeFromDateToDate;
const googleProtobufConverters_1 = require("../googleProtobufConverters");
/**
 * Applies the time (hours, minutes, seconds) from one timestamp to another.
 *
 * @param {Timestamp} [dateForTime] - The timestamp from which to take the time.
 * @param {Timestamp} [dateToApplyTimeTo] - The timestamp to which the time should be applied.
 * @returns {Timestamp | undefined} The new timestamp with the applied time or undefined if any of the input timestamps are not provided.
 */
function applyTimeFromDateToDate(dateForTime, dateToApplyTimeTo) {
    // if either given value is not set then return undefined
    if (!(dateForTime && dateToApplyTimeTo)) {
        return undefined;
    }
    // convert given date for time to day js
    const dateForTimeDayJs = (0, googleProtobufConverters_1.protobufTimestampToDayjs)(dateForTime);
    // apply hours minutes and seconds and return after converting back
    return (0, googleProtobufConverters_1.dayjsToProtobufTimestamp)((0, googleProtobufConverters_1.protobufTimestampToDayjs)(dateToApplyTimeTo)
        .set("hour", dateForTimeDayJs.hour())
        .set("minute", dateForTimeDayJs.minute())
        .set("second", dateForTimeDayJs.second()));
}
