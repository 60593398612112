import React from "react";
import {
  AccountBalance as BankingIcon,
  AccountBalanceWallet as AccountsIcon,
  Cake as FeeIcon,
  Dashboard as DashboardIcon,
  LibraryBooks as ComplianceIcon,
  List as MarketListingIcon,
  Money as CurrencyIcon,
  ReceiptLong as LedgerIcon,
  Settings as SystemIcon,
  SwapHoriz as TradingIcon,
  Toll as TokenIcon,
  Waves as LiquidityPoolsIcon,
  Assignment as MarketSubscriptionIcon,
  TrendingUp,
  AutoAwesome as OrchestrationIcon,
} from "@mui/icons-material";
import { Token } from "views/Token";
import { RouteType } from "routes/Route";
import { AccountManagement } from "views/AccountManagement";
import { Banking } from "views/Banking/Banking";
import { System } from "views/System";
import { Compliance } from "views/Compliance/Compliance";
import { ViewConfiguration } from "james/configuration";
import { Client, ClientType } from "james/client";
import { MarketManagementDashboard } from "views/Dashboard/MarketManagementDashboard";
import { UserProfileView } from "views/UserProfile";
import { CompanyProfileView } from "views/CompanyProfile";
import isObject from "lodash/isObject";
import { atLeastOneTrueFoundInViewConfigObject } from "routes/private/administrationRoutes";
import { Trading } from "views/Trading";
import { Ledger } from "views/Ledger";
import { Assets } from "views/Assets";
import { Liquidity } from "views/Liquidity";
import { MarketListing } from "views/MarketListing";
import { MCMarketSubscription } from "views/MCMarketSubscription";
import { Fees } from "views/Fee/Fees";
import { Orchestration } from "views/MarketManagement";
import { RateSources } from "views/RateSources/RateSources";

interface MarketManagementRoutesToReturn {
  homeRoute?: RouteType;
  otherRoutes: RouteType[];
  userProfileRoute: RouteType;
  clientProfileRoute?: RouteType;
}

export const mcOnlyRoutes = [
  {
    name: "Tokens",
    id: "tokens",
    path: "/market-management/tokens",
    icon: TokenIcon,
    component: <Token />,
  },
];

export const marketManagementRoutes: RouteType[] = [
  {
    name: "Fees",
    id: "fees",
    path: "/market-management/fees",
    icon: FeeIcon,
    component: <Fees />,
    allowSubPaths: true,
  },
  {
    name: "Accounts",
    id: "accounts",
    path: "/market-management/accounts",
    icon: AccountsIcon,
    component: <AccountManagement />,
    allowSubPaths: true,
  },
  {
    name: "Banking",
    id: "banking",
    path: "/market-management/banking",
    icon: BankingIcon,
    component: <Banking />,
    allowSubPaths: true,
  },
  {
    name: "System",
    id: "system",
    path: "/market-management/system",
    icon: SystemIcon,
    component: <System />,
  },
  {
    name: "Compliance",
    id: "compliance",
    path: "/market-management/compliance",
    icon: ComplianceIcon,
    component: <Compliance />,
    allowSubPaths: true,
  },
  {
    name: "Support",
    id: "support",
    path: "/market-management/support",
    icon: ComplianceIcon,
    component: <Compliance />,
    allowSubPaths: true,
  },
  {
    name: "Trading",
    id: "trading",
    path: "/market-management/trading",
    icon: TradingIcon,
    component: <Trading />,
    allowSubPaths: true,
  },
  {
    name: "Ledger",
    id: "ledger",
    path: "/market-management/ledger",
    icon: LedgerIcon,
    component: <Ledger />,
    allowSubPaths: true,
  },
  {
    name: "Assets",
    id: "assets",
    path: "/market-management/assets",
    icon: CurrencyIcon,
    component: <Assets />,
    allowSubPaths: true,
  },
  {
    name: "Liquidity",
    id: "liquidity",
    path: "/market-management/liquidity",
    icon: LiquidityPoolsIcon,
    component: <Liquidity />,
    allowSubPaths: true,
  },
  {
    name: "MC Market Listing",
    id: "marketListing",
    path: "/market-management/market-listing",
    icon: MarketListingIcon,
    component: <MarketListing />,
    allowSubPaths: true,
  },
  {
    name: "Subscription",
    id: "subscriptions",
    path: "/market-management/subscriptions",
    icon: MarketSubscriptionIcon,
    component: <MCMarketSubscription />,
    allowSubPaths: true,
  },
  {
    name: "Orchestration",
    id: "orchestration",
    path: "/market-management/orchestration",
    icon: OrchestrationIcon,
    component: <Orchestration />,
    allowSubPaths: true,
  },
  {
    name: "Rate Sources",
    id: "rateSources",
    path: "/market-management/rate-sources",
    icon: TrendingUp,
    component: <RateSources />,
    allowSubPaths: true,
  },
];
export const MarketManagementRouteBuilder: (
  viewConfiguration: ViewConfiguration,
  client: Client,
) => MarketManagementRoutesToReturn = (
  viewConfiguration: ViewConfiguration,
  client: Client,
) => {
  const routesToReturn: MarketManagementRoutesToReturn = {
    homeRoute: {
      name: "Dashboard",
      id: "market-management",
      path: "/market-management",
      icon: DashboardIcon,
      component: <MarketManagementDashboard />,
    },

    userProfileRoute: {
      name: "User Profile",
      id: "user-profile",
      path: "/market-management/user-profile",
      icon: DashboardIcon,
      component: <UserProfileView />,
    },

    // the rest of the routes will be built considering the viewConfiguration
    clientProfileRoute: undefined,
    otherRoutes: [],
  };

  // conditionally set company profile route
  if (viewConfiguration["Edit Client Profile"]) {
    routesToReturn.clientProfileRoute = {
      name: "Company profile",
      id: "company-profile",
      path: "/market-management/company-profile",
      icon: DashboardIcon,
      component: <CompanyProfileView />,
    };
  }

  // for every sidebar route...
  marketManagementRoutes.forEach((route) => {
    // if the view configuration has an entry with the name of the route
    if (viewConfiguration[route.name]) {
      // and if the entry is an object
      if (isObject(viewConfiguration[route.name])) {
        // then check if at least one true is found with the embedded rules
        if (
          atLeastOneTrueFoundInViewConfigObject(viewConfiguration[route.name])
        ) {
          // then add the entry
          routesToReturn.otherRoutes.push(route);
        }
      } else {
        // if it is not an object then assume it to be a boolean
        if (viewConfiguration[route.name]) {
          routesToReturn.otherRoutes.push(route);
        }
      }
    }
  });

  // routes anyone in MC should see
  if (client.clientTypes.includes(ClientType.ManagingCompanyType)) {
    mcOnlyRoutes.forEach((r) => routesToReturn.otherRoutes.push(r));
  }

  return routesToReturn;
};
