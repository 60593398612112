"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allFrequencys = void 0;
exports.frequencyToString = frequencyToString;
exports.stringToFrequency = stringToFrequency;
const frequency_pb_1 = require("./frequency_pb");
// Get all frequencies as enum values
exports.allFrequencys = Object.values(frequency_pb_1.Frequency).filter((value) => typeof value === "number");
// Define explicit mappings between Frequency enums and custom string representations
const frequencyToStringMapping = {
    [frequency_pb_1.Frequency.UNDEFINED_FREQUENCY]: "-",
    [frequency_pb_1.Frequency.MONTHLY_FREQUENCY]: "Monthly",
    [frequency_pb_1.Frequency.QUARTERLY_FREQUENCY]: "Quarterly",
    [frequency_pb_1.Frequency.SEMI_ANNUALLY_FREQUENCY]: "Semi-Annually",
    [frequency_pb_1.Frequency.ANNUALLY_FREQUENCY]: "Annually",
};
// Reverse mapping from string to Frequency enum
const stringToFrequencyMapping = {};
for (const [key, value] of Object.entries(frequencyToStringMapping)) {
    stringToFrequencyMapping[value] = Number(key);
}
class UnsupportedFrequencyError extends Error {
    constructor(frequency) {
        const message = `Unsupported Frequency: ${frequency}`;
        super(message);
        this.frequency = frequency;
    }
}
/**
 * Converts a Frequency enum instance to a custom string representation.
 * @param {Frequency} frequency - The frequency to convert.
 * @returns {string} The custom string representation of the frequency.
 */
function frequencyToString(frequency) {
    if (frequency in frequencyToStringMapping) {
        return frequencyToStringMapping[frequency];
    }
    else {
        throw new UnsupportedFrequencyError(frequency);
    }
}
class UnsupportedFrequencyStringError extends Error {
    constructor(frequencyStr) {
        const message = `Unsupported frequency string: ${frequencyStr}`;
        super(message);
        this.frequencyStr = frequencyStr;
    }
}
/**
 * Converts a custom string representation to a Frequency enum instance.
 * @param {string} frequencyStr - The custom string representation of the frequency.
 * @returns {Frequency} The corresponding Frequency enum instance.
 */
function stringToFrequency(frequencyStr) {
    if (frequencyStr in stringToFrequencyMapping) {
        return stringToFrequencyMapping[frequencyStr];
    }
    else {
        throw new UnsupportedFrequencyStringError(frequencyStr);
    }
}
