import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TimelineItem } from "./TimelineItem";
import { DateTimeFormatFancy } from "const/dateformats";
import { MarketListingViewModel } from "james/views/marketListingView";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate } from "react-router-dom";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface SubscriptionOrderSubmittedDialogProps {
  closeDialog: () => void;
  marketListingViewModel: MarketListingViewModel;
  ledgerTokenViewModel: LedgerTokenViewModel;
}

const getAssetflowCategoryLabel = (category: AssetflowCategory) => {
  switch (category) {
    case AssetflowCategory.INTEREST_ASSETFLOW_CATEGORY:
      return "coupon";
    case AssetflowCategory.DIVIDEND_ASSETFLOW_CATEGORY:
      return "dividend";
    case AssetflowCategory.PRINCIPAL_ASSETFLOW_CATEGORY:
      return "principal";
    default:
      return "distribution";
  }
};

const getUnitLabel = (unit: Unit) => {
  switch (unit) {
    case Unit.BOND_UNIT:
      return "bond";
    case Unit.PREFERENCE_SHARE_UNIT:
      return "preference share";
    case Unit.NOTE_UNIT:
      return "note";
    case Unit.SHARE_UNIT:
      return "share";
    default:
      return "token";
  }
};

export const SubscriptionOrderSubmittedDialog: React.FC<
  SubscriptionOrderSubmittedDialogProps
> = ({ closeDialog, marketListingViewModel, ledgerTokenViewModel }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const handleNavigate = (navToUrl: string) => {
    // prevents unnecessary navigation and duplicate history entries when already on the target page
    const decodedNavToUrl = decodeURIComponent(navToUrl);
    const decodedCurrentUrl = decodeURIComponent(
      `${location.pathname}${location.search}`,
    );
    if (decodedCurrentUrl !== decodedNavToUrl) {
      navigate(navToUrl);
    }
  };

  const formatDate = (date: string | undefined): string => {
    const formattedDate = dayjs(date).format(DateTimeFormatFancy);
    return formattedDate.includes("GMT+2")
      ? formattedDate.replace("GMT+2", "SAST")
      : formattedDate;
  };

  // FIXME: how to get SAST instead of GMT+2? Timezone ignored for now
  const closeDate = formatDate(
    marketListingViewModel.marketSubscriptionOrderBookViewModel?.closeDate,
  );
  const settleDate = formatDate(
    marketListingViewModel.marketSubscriptionOrderBookViewModel?.settlementDate,
  );
  const paymentDate = formatDate(marketListingViewModel.firstAssetflowDate);
  const hasPayment =
    marketListingViewModel.firstAssetflowCategory !=
    AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY;
  const assetflowCategoryLabel = getAssetflowCategoryLabel(
    marketListingViewModel.firstAssetflowCategory,
  );
  const unitType = getUnitLabel(marketListingViewModel.assetUnit);

  return (
    <Dialog open fullScreen={isMobile}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.custom.midnight,
          padding: {
            sm: theme.spacing(2, 2, 2, 3),
            xs: theme.spacing(2, 2, 2, 2),
          },
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Typography
            variant={"h5"}
            children={"Order Submitted Successfully!"}
          />
        </Grid>
        <Box sx={{ alignSelf: "center" }}>
          <IconButton
            id={"subscriptionSuccessDialog-close-iconButton"}
            size={"small"}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        style={{
          alignItems: "center",
          padding: theme.spacing(3),
          maxWidth: "640px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3),
          }}
        >
          <Typography>
            <b>What happens next?</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TimelineItem
              hasConnector={true}
              title="Subscription closes"
              subtitle={closeDate}
              body={
                <Typography variant="body2">
                  Until this date, you can continue to place more orders for{" "}
                  {marketListingViewModel.assetName}. After the close, no
                  further subscriptions will be accepted. You can view your open
                  orders on the{" "}
                  <Link
                    underline="none"
                    onClick={() => {
                      handleNavigate("/market/subscriptions");
                      closeDialog();
                    }}
                  >
                    Subscriptions page
                  </Link>
                  .
                </Typography>
              }
            />
            <TimelineItem
              hasConnector={hasPayment}
              title="Settlement"
              subtitle={settleDate}
              body={
                <Typography variant="body2">
                  Your funds will be transferred to{" "}
                  {ledgerTokenViewModel.issuer}, and your {unitType}s will be
                  issued and automatically added to your account.
                </Typography>
              }
            />
            {hasPayment && (
              <TimelineItem
                hasConnector={false}
                title={`First ${assetflowCategoryLabel} payment`}
                subtitle={paymentDate}
                body={
                  <Typography variant="body2">
                    You'll receive your first {assetflowCategoryLabel} payment
                    on this date, which will be credited directly to your
                    account. You can calculate your expected{" "}
                    {assetflowCategoryLabel} receivable using the payment
                    calculator on the{" "}
                    <Link
                      underline="none"
                      onClick={() => {
                        const t = ledgerTokenViewModel.token;
                        handleNavigate(
                          `/market/asset-overview?code=${t.code}&issuer=${t.issuer}&network=${t.network}`,
                        );
                        closeDialog();
                      }}
                    >
                      Asset page
                    </Link>
                    .
                  </Typography>
                }
              />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: {
            sm: theme.spacing(2, 3, 3, 3),
            xs: theme.spacing(2, 3, 4, 3),
          },
          ...(isMobile && {
            justifyContent: "center",
          }),
        }}
      >
        <Box sx={{ width: isMobile ? "100%" : "auto" }}>
          <Button
            id="subscriptionSuccessDialog-close-button"
            variant="outlined"
            fullWidth={isMobile}
            size="medium"
            onClick={closeDialog}
          >
            Close
          </Button>
        </Box>

        <Box sx={{ width: isMobile ? "100%" : "auto" }}>
          <Button
            id="subscriptionSuccessDialog-viewAsset-button"
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            size="medium"
            onClick={() => {
              const t = ledgerTokenViewModel.token;
              handleNavigate(
                `/market/asset-overview?code=${t.code}&issuer=${t.issuer}&network=${t.network}`,
              );
              closeDialog();
            }}
          >
            View Asset
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
