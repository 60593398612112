import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Brandz from "./Brandz.jpg";
import Compound from "./Compound.jpg";
import GameChanger from "./Game-Changer.png";
import Unicorn from "./Unicorn.jpg";

export const AnbroEquityBanner = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Unicorn})`,
        display: "flex",
        justifyContent: "center",
        height: "200px",
        backgroundSize: "1280px",
        alignItems: "center",
        backgroundPosition: {
          lg: "100%",
          md: "80%",
          sm: "50%",
        },
      }}
      data-component-info={JSON.stringify({
        component_id: "AnBro Unicorn Banner",
        component_business_name: "AnBro Unicorn Banner",
        component_title: "AnBro Unicorn Banner",
        component_driver: "showcase",
      })}
      data-testid="anbro_unicorn_banner"
    >
      <Content
        text="The AnBro Unicorn Global Equity AMC is "
        link="https://app.mesh.trade/market/asset-overview?code=ZAE000266417&issuer=GAZ35XFNL3XDDX6MTAUXRIEKFHMSSNAWK3CIWTDDYGYEOOFWSMM5BV3V&network=Stellar%20Public&utm_source=mesh-app&utm_medium=organic&utm_campaign=mtg_get_ttl_anbro-amc_aoc-72_oct-24__za_pfmza_none_mesh-app_cro_none_none_none&utm_marketing_tactic=cro&utm_creative_format=image&utm_id=mtg_get_ttl_anbro-amc_aoc-72&utm_content=unicorn-amc-invest-now&utm_term=organic_unicorn-amc-invest-now&utm_source_platform=carousel-banners"
      />
    </Box>
  );
};

export const AnbroBrandsBanner = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Brandz})`,
        display: "flex",
        justifyContent: "center",
        height: "200px",
        backgroundSize: "1280px",
        alignItems: "flex-end",
        backgroundPosition: {
          lg: "100%",
          sm: "20%",
        },
      }}
      data-component-info={JSON.stringify({
        component_id: "AnBro Brandz Banner",
        component_business_name: "AnBro Brandz Banner",
        component_title: "AnBro Brandz Banner",
        component_driver: "showcase",
      })}
      data-testid="anbro_brandz_banner"
    >
      <Content
        text="The AnBro World’s Biggest BRNDZ AMC is "
        link="https://app.mesh.trade/market/asset-overview?code=ZAE000326641&issuer=GBDHRJIYMWG4IKABJ4HI4TSGGFFNQKELPM2463BCVV2NSEQZ75THYU7B&network=Stellar%20Public&utm_source=mesh-app&utm_medium=organic&utm_campaign=mtg_get_ttl_anbro-amc_aoc-72_oct-24__za_pfmza_none_mesh-app_cro_none_none_none&utm_marketing_tactic=cro&utm_creative_format=image&utm_id=mtg_get_ttl_anbro-amc_aoc-72&utm_content=brndz-amc-invest-now&utm_term=organic_brndz-amc-invest-now&utm_source_platform=carousel-banners"
      />
    </Box>
  );
};

export const AnbroAMCBanner = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Compound})`,
        display: "flex",
        justifyContent: "center",
        height: "200px",
        backgroundSize: "1280px",
        backgroundPosition: {
          lg: "100%",
          md: "80%",
          sm: "55%",
        },
        alignItems: "center",
      }}
      data-component-info={JSON.stringify({
        component_id: "AnBro AMC Banner",
        component_business_name: "AnBro AMC Banner",
        component_title: "AnBro AMC Banner",
        component_driver: "showcase",
      })}
      data-testid="anbro_amc_banner"
    >
      <Content
        text="The AnBro Dynamic Compound AMC is "
        link="https://app.mesh.trade/market/asset-overview?code=ZAE000316311&issuer=GBRAGR6ZDVYHBTNBGE7QJM43Z5QXFM473Z6E2OZ4ZQQG32ONC2SQXMBP&network=Stellar%20Public&utm_source=mesh-app&utm_medium=organic&utm_campaign=mtg_get_ttl_anbro-amc_aoc-72_oct-24__za_pfmza_none_mesh-app_cro_none_none_none&utm_marketing_tactic=cro&utm_creative_format=image&utm_id=mtg_get_ttl_anbro-amc_aoc-72&utm_content=dynamic-compound-amc-invest-now&utm_term=organic_dynamic-compound-amc-invest-now&utm_source_platform=carousel-banners"
      />
    </Box>
  );
};

interface ContentProps {
  text: string;
  link: string;
}

const Content = ({ text, link }: ContentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        justifyContent: "center",
        width: 600,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          width: 420,
          height: 200,
        }}
      >
        <Typography
          sx={{
            maxWidth: 420,
            mt: 3,
            "& > .highlight": {
              // TODO: remove hard coded colour
              color: "#DC0551",
              ml: "auto",
            },
          }}
          fontWeight={"bold"}
          fontSize={27}
          letterSpacing={0}
          lineHeight={"32px"}
        >
          {text} <span className="highlight">open&nbsp;to&nbsp;all.</span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <Button
            sx={{
              height: 36,
              width: 120,
            }}
            variant="contained"
            color="primary"
            href={link}
          >
            Invest Now
          </Button>
          <Box
            sx={{
              mt: -1,
              ml: 1,
              backgroundImage: `url(${GameChanger})`,
              backgroundSize: "260px 48px",
              backgroundRepeat: "no-repeat",
              height: "60px",
              width: "calc(100% - 120px)",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
