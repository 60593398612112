"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadMore = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const ReadMore = ({ children, variant = "body1" }) => {
    if (children === undefined) {
        return null;
    }
    const text = children;
    const [isExpanded, setIsExpanded] = (0, react_1.useState)(false);
    const [shouldTruncate, setShouldTruncate] = (0, react_1.useState)(false);
    const textRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (textRef.current) {
            const { scrollWidth, clientWidth } = textRef.current;
            setShouldTruncate(scrollWidth > clientWidth);
        }
    }, [text]);
    const toggleReadMore = () => setIsExpanded((prev) => !prev);
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
            display: "inline-block",
            maxWidth: "100%",
            overflow: "hidden",
        }, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: variant, ref: textRef, sx: Object.assign({ mt: 1 }, (isExpanded
                    ? {
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                    }
                    : {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    })), children: text }), shouldTruncate && ((0, jsx_runtime_1.jsx)(material_1.Typography, { sx: { cursor: "pointer", my: 1 }, color: "secondary", fontSize: "13px", lineHeight: "21px", fontWeight: 600, width: "20%", onClick: toggleReadMore, children: isExpanded ? "Read Less" : "Read More" }))] }));
};
exports.ReadMore = ReadMore;
