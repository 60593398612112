import { MeshCoreLayout } from "layouts";
import { Router } from "routes";
import React from "react";
import { AdminRouteBuilder } from "routes/private";
import { RouteType } from "routes/Route";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";

export const Administration = () => {
  const { viewConfiguration } = useApplicationContext();

  const adminRoutes = AdminRouteBuilder(viewConfiguration);

  const allAdminRoutes: RouteType[] = [...adminRoutes.otherRoutes];
  if (adminRoutes.homeRoute) {
    allAdminRoutes.push(adminRoutes.homeRoute);
  }
  if (adminRoutes.userProfileRoute) {
    allAdminRoutes.push(adminRoutes.userProfileRoute);
  }
  if (adminRoutes.clientProfileRoute) {
    allAdminRoutes.push(adminRoutes.clientProfileRoute);
  }

  return (
    <MeshCoreLayout
      homeRoute={adminRoutes.homeRoute}
      userProfileRoute={adminRoutes.userProfileRoute}
      clientProfileRoute={adminRoutes.clientProfileRoute}
      otherRoutes={adminRoutes.otherRoutes}
    >
      <Helmet>
        <title>Mesh | Administration</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Your Mesh Admin Dashboard" />
      </Helmet>
      <Router
        baseURL={"/administration"}
        redirectPath={"/administration"}
        routes={allAdminRoutes}
      />
    </MeshCoreLayout>
  );
};
