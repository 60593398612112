import React, { useState, useEffect } from "react";
import { MainAppBar } from "components/MainAppBar";
import { MobileNavigator } from "components/BottomNavigation/MobileNavigator";
import { Box, Tab, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppTourContext } from "context/AppTour/AppTour";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useLocation, useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { useWindowSize } from "utilities/general";
import cx from "classnames";
import { Footer } from "./components/Footer";

interface Props {
  children?: React.ReactNode;
}

export function MarketplaceLayout(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { viewConfiguration } = useApplicationContext();
  const { registerElement } = useAppTourContext();
  const { NotificationBannerHeight } = useAppNoticeContext();
  const navigate = useNavigate();
  const [, windowHeight] = useWindowSize();
  const location = useLocation();
  const tabsRef = React.useRef<HTMLDivElement>();
  const [path, setPath] = useState(() => {
    const pathList = location.pathname.split("/");
    return pathList[pathList.length - 1];
  });

  useEffect(() => {
    const pathList = location.pathname.split("/");
    setPath(pathList[pathList.length - 1]);
  }, [location]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(18, 16, 39, 1)",
        position: "relative",
        overflow: "hidden",
      }}
      ref={registerElement("marketplaceLayoutMobile")}
    >
      <Box
        ref={registerElement("marketplaceLayoutMobileTitle")}
        sx={{
          p: 0,
          minHeight: "56px",
          height: NotificationBannerHeight + 56,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <MainAppBar />
      </Box>
      {["orders", "trades", "subscriptions"].includes(path) && isMobile && (
        <Box
          ref={tabsRef}
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.palette.background.default,
            maxWidth: "100%",
            py: 0,
            ".active": {
              color: theme.palette.primary.main,
              borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
          })}
        >
          {viewConfiguration.Marketplace.Orders && (
            <Tab
              className={cx({
                active: path === "orders",
              })}
              onClick={() => navigate("orders")}
              label="Orders"
            />
          )}
          {viewConfiguration.Marketplace.Trades && (
            <Tab
              className={cx({
                active: path === "trades",
              })}
              onClick={() => navigate("trades")}
              label="Trades"
            />
          )}
          {viewConfiguration.Marketplace.Subscriptions && (
            <Tab
              className={cx({
                active: path === "subscriptions",
              })}
              onClick={() => navigate("subscriptions")}
              label="Subscriptions"
            />
          )}
        </Box>
      )}
      <Box
        sx={{
          height: {
            sm: windowHeight - 56 - NotificationBannerHeight - 48,
            xs:
              windowHeight -
              56 -
              NotificationBannerHeight -
              75 -
              (path !== "markets" ? 48 : 0),
          },
        }}
        ref={registerElement("marketplaceLayoutMobileContent")}
      >
        {props.children}
      </Box>
      {isMobile && (
        <Box
          ref={registerElement("marketplaceLayoutMobileNav")}
          sx={{
            p: 0,
            zIndex: 1300,
            minHeight: "75px",
          }}
        >
          <MobileNavigator />
        </Box>
      )}
      {!isMobile && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Footer />
        </Box>
      )}
    </Box>
  );
}
