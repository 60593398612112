import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import banner from "assets/images/kycOutstandingDialog/banner.png";
import { ClientKYCStatus } from "james/client";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirebaseContext } from "context/Firebase";
import { Client, ClientKind } from "james/client/Client";
import { useApplicationContext } from "context/Application/Application";
import { UserState } from "james/user/User";
import { useAppTourContext } from "context/AppTour/AppTour";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import bannerMini from "assets/images/kycOutstandingDialog/banner_mini.png";
import { useWidth } from "utilities/general";

const PREFIX = "KYBOutstandingDialog";

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  kycRejectedDialogContent: `${PREFIX}-kycRejectedDialogContent`,
  banner: `${PREFIX}-banner`,
  boldFont: `${PREFIX}-boldFont`,
  addedMargin: `${PREFIX}-addedMargin`,
  fixedHeight52: `${PREFIX}-fixedHeight52`,
  contentSection: `${PREFIX}-contentSection`,
  warningTextColor: `${PREFIX}-warningTextColor`,
  warningMessageLayout: `${PREFIX}-warningMessageLayout`,
  errorLogoSize: `${PREFIX}-errorLogoSize`,
  buttonSize: `${PREFIX}-buttonSize`,
  linkCursor: `${PREFIX}-linkCursor`,
  mobileDialogContent: `${PREFIX}-mobileDialogContent`,
  mobileDialogTitle: `${PREFIX}-mobileDialogTitle`,
  mobileBanner: `${PREFIX}-mobileBanner`,
  mobileWarningMessageLayout: `${PREFIX}-mobileWarningMessageLayout`,
  mobileKYCRejectedDialogContent: `${PREFIX}-mobileKYCRejectedDialogContent`,
  mobileCloseButton: `${PREFIX}-mobileCloseButton`,
  mobileContentSection: `${PREFIX}-mobileContentSection`,
  drawer: `${PREFIX}-drawer`,
  mobileButtonHeight: `${PREFIX}-mobileButtonHeight`,
  mobileButtonBottomMargin: `${PREFIX}-mobileButtonBottomMargin`,
  removePadding: `${PREFIX}-removePadding`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "200px auto",
    gridRowGap: theme.spacing(1),
    padding: "0px",
  },

  [`& .${classes.kycRejectedDialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "repeat(4, auto)",
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(4, 4, 4, 3),
  },

  [`& .${classes.banner}`]: {
    width: "100%",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    paddingTop: "47px",
    paddingLeft: "52px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  [`& .${classes.boldFont}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.addedMargin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.fixedHeight52}`]: {
    height: "52px",
  },

  [`& .${classes.contentSection}`]: {
    display: "grid",
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(3),
  },

  [`& .${classes.warningTextColor}`]: {
    color: theme.palette.warning.light,
  },

  [`& .${classes.warningMessageLayout}`]: {
    display: "grid",
    gridTemplateColumns: "24px 0.7fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.errorLogoSize}`]: {
    fontSize: "20px",
  },

  [`& .${classes.buttonSize}`]: {
    maxWidth: "229px",
  },

  [`& .${classes.linkCursor}`]: {
    cursor: "pointer",
  },

  [`& .${classes.drawer}`]: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },

  [`& .${classes.removePadding}`]: {
    padding: 0,
  },

  [`& .${classes.mobileDialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "200px auto",
    backgroundColor: theme.palette.custom.midnight,
    gridRowGap: theme.spacing(1),
    padding: "0px",
  },

  [`& .${classes.mobileDialogTitle}`]: {
    padding: 0,
  },

  [`& .${classes.mobileBanner}`]: {
    width: "100%",
    backgroundImage: `url(${bannerMini})`,
    backgroundSize: "cover",
    paddingTop: "70px",
    paddingLeft: "24px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  [`& .${classes.mobileCloseButton}`]: {
    color: theme.palette.text.tertiary,
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.mobileKYCRejectedDialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "repeat(4, auto)",
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(2, 3, 5, 3),
  },
}));

export function KYBOutstandingDialog() {
  const { tourInProgress } = useAppTourContext();
  const { firebaseAuthenticated } = useFirebaseContext();
  const { userAuthenticated, loginClaims, myClient } = useApplicationContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);

  const navigate = useNavigate();
  const location = useLocation();
  const [dialogAlreadyVisited, setDialogAlreadyVisited] = useState(false);

  const showDialog = useMemo(
    () =>
      !tourInProgress &&
      loggedIn &&
      loginClaims.userState === UserState.Registered &&
      !dialogAlreadyVisited &&
      !location.pathname.includes("/kyb") &&
      new Client(myClient).kycStatus === ClientKYCStatus.Outstanding &&
      new Client(myClient).kycStatusReason === "" &&
      new Client(myClient).clientKind === ClientKind.CompanyType,
    [dialogAlreadyVisited, loggedIn, myClient, tourInProgress],
  );

  const handleDoLaterClick = () => {
    setDialogAlreadyVisited(true);
  };

  const handleStartKYBProcessClick = () => {
    navigate("/kyb");
    setDialogAlreadyVisited(true);
  };

  // do not render if the client entity is still being retrieved
  if (!myClient) {
    return null;
  }

  if (isMobile) {
    return (
      <StyledDialog fullScreen open={showDialog}>
        <DialogTitle className={classes.fixedHeight52}>
          <Typography>What&apos;s next?</Typography>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="KYBDialog-close-button"
                  size="small"
                  onClick={handleDoLaterClick}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            width: "100%",
            display: "grid",
            gridTemplateRows: "200px auto",
            backgroundColor: theme.palette.custom.midnight,
            padding: "0px",
          })}
        >
          <Box
            sx={(theme) => ({
              width: "100%",
              backgroundImage: `url(${bannerMini})`,
              backgroundSize: "cover",
              paddingTop: "70px",
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            })}
          >
            <Typography
              className={classes.boldFont}
              variant="h3"
              children={`Hello ${loginClaims.firstName}, let's get your company verified`}
            />
          </Box>
          <Box
            sx={(theme) => ({
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(2, 3, 5, 3),
              gap: 2,
            })}
          >
            <Typography
              className={classes.boldFont}
              variant="h4"
              children="Verification Required"
            />
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="body1"
              children={`Before you can invest using Mesh, we'll need to collect and verify a few important
             details about your company, including the information of shareholders, representatives and agents. 
             Feel free to explore the Mesh platform or continue to the verification process.`}
            />
            <Button
              id="kybOutstandingDialog-startTheKYCProcess-button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                marginTop: 11,
              }}
              onClick={handleStartKYBProcessClick}
            >
              Verify Now
            </Button>
            <Button variant="text" sx={{ padding: "8px" }}>
              <Link
                id="kybOutstandingDialog-doItLater-link"
                onClick={handleDoLaterClick}
                className={classes.linkCursor}
                underline="none"
                component="span"
                color="secondary"
              >
                I&apos;ll do this later
              </Link>
            </Button>
          </Box>
        </DialogContent>
      </StyledDialog>
    );
  }

  return (
    <StyledDialog
      open={showDialog}
      sx={{
        width: "652px",
        alignSelf: "center",
        justifySelf: "center",
      }}
    >
      <DialogTitle className={classes.fixedHeight52}>
        <Typography>What&apos;s next?</Typography>
        <Grid item>
          <Tooltip title="Close" placement="top">
            <span>
              <IconButton
                id="KYBDialog-close-button"
                size="small"
                onClick={handleDoLaterClick}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </DialogTitle>
      <DialogContent
        className={"meshScroll"}
        classes={{ root: classes.dialogContent }}
      >
        <div className={classes.banner}>
          <Typography
            variant="h1"
            children={`Hello ${loginClaims.firstName}, let's get your company verified`}
          />
        </div>
        <div className={classes.contentSection}>
          <Typography
            className={classes.boldFont}
            variant="h4"
            children="Verification Required"
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
            children={`Before you can invest using Mesh, we'll need to collect and verify a few important
             details about your company, including the information of shareholders, representatives and agents. 
             Feel free to explore the Mesh platform or continue to the verification process.`}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              paddingTop: "29px",
            }}
          >
            <Button
              className={classes.buttonSize}
              id="kybOutstandingDialog-startTheKYCProcess-button"
              variant="contained"
              color="primary"
              onClick={handleStartKYBProcessClick}
              sx={{
                marginLeft: "16px",
              }}
            >
              Verify Now
            </Button>
            <Button variant="text" sx={{ padding: "6px 16px" }}>
              <Link
                id="kybOutstandingDialog-doItLater-link"
                onClick={handleDoLaterClick}
                className={classes.linkCursor}
                underline="none"
                component="span"
                color="secondary"
              >
                I&apos;ll do this later
              </Link>
            </Button>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
}
