import React, { ReactNode, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSearchAfterEffects } from "consistency/consistency/AfterEffectRepository";
import { BPTable } from "components/Table";
import { NewSorting, Query } from "james/search/query";
import dayjs from "dayjs";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { ConsistencyAfterEffect } from "consistency/consistency";
import {
  DateRangeCriterion,
  TextListCriterion,
  TextNINListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  AfterEffectState,
  AllAfterEffectStates,
} from "consistency/consistency/AfterEffect";
import { useSnackbar } from "notistack";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { DateField } from "components/FormFields";
import { dateIsValid } from "utilities/date/dateIsValid";
import MultiRowInfoIcon from "../Dashboard/MultiRowInfoIcon";
import { useApplicationContext } from "context/Application/Application";

const PREFIX = "Table";

const classes = {
  autoRefreshSwitchLayout: `${PREFIX}-autoRefreshSwitchLayout`,
  autoRefreshCircularLoaderWrapper: `${PREFIX}-autoRefreshCircularLoaderWrapper`,
  clickableText: `${PREFIX}-clickableText`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  row: `${PREFIX}-row`,
  copyPasteIcon: `${PREFIX}-copyPasteIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.autoRefreshSwitchLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    border: `solid 1px ${theme.palette.divider}`,
    paddingRight: theme.spacing(1),
  },
  [`& .${classes.autoRefreshCircularLoaderWrapper}`]: {
    width: 30,
  },
  [`& .${classes.clickableText}`]: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.light,
    },
    cursor: "pointer",
  },
  [`& .${classes.textSearchField}`]: {
    width: 400,
  },

  [`& .${classes.dateFilterField}`]: {
    width: 180,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
  },

  [`& .${classes.copyPasteIcon}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("auditEntry.time", "desc")],
});

export function Table() {
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const {
    searchAfterEffectsResponse,
    searchAfterEffectsRequest,
    setSearchAfterEffectsRequest,
    loading: searchAfterEffectsLoading,
  } = useSearchAfterEffects({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });

  const [autoRefresh, setAutoRefresh] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { id: TextSubstringCriterion(textSearchCriterionTextField) },
          { serviceURI: TextSubstringCriterion(textSearchCriterionTextField) },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  const [stateInclCriterion, setStateInclCriterion] = useState<
    AfterEffectState[]
  >([]);
  const [stateNInCriterion, setStateNInCriterion] = useState<
    AfterEffectState[]
  >([]);
  const [dateTimeCriterionFrom, setDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [dateTimeCriterionTo, setDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (stateInclCriterion.length) {
      criteria = {
        ...criteria,
        state: TextListCriterion(stateInclCriterion),
      };
    }

    if (stateNInCriterion.length) {
      criteria = {
        ...criteria,
        state: TextNINListCriterion(stateNInCriterion),
      };
    }

    if (dateTimeCriterionFrom || dateTimeCriterionTo) {
      criteria["auditEntry.time"] = DateRangeCriterion(
        dateTimeCriterionFrom,
        dateTimeCriterionTo,
      );
    }

    setSearchAfterEffectsRequest({
      context: searchAfterEffectsRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    stateInclCriterion,
    stateNInCriterion,
    textSearchCriterion,
    searchAfterEffectsRequest.context,
    setSearchAfterEffectsRequest,
    authContext,
    dateTimeCriterionFrom,
    dateTimeCriterionTo,
  ]);

  useEffect(() => {
    if (autoRefresh) {
      const timeOut = setTimeout(
        () =>
          setSearchAfterEffectsRequest({
            ...searchAfterEffectsRequest,
            query: new Query(initialQuery),
          }),
        1000,
      );
      return () => clearTimeout(timeOut);
    }
  }, [autoRefresh, searchAfterEffectsRequest, setSearchAfterEffectsRequest]);

  return (
    <Root>
      <BPTable
        disableSelect
        height={window.innerHeight - 138}
        title="After Effects"
        loading={autoRefresh ? undefined : searchAfterEffectsLoading}
        data={searchAfterEffectsResponse.records}
        totalNoRecords={searchAfterEffectsResponse.total}
        query={searchAfterEffectsRequest.query}
        onQueryChange={(query) =>
          setSearchAfterEffectsRequest({
            ...searchAfterEffectsRequest,
            query,
          })
        }
        toolBarControls={(() => {
          const controlsToRender: ReactNode[] = [];

          controlsToRender.push(
            <div className={classes.autoRefreshSwitchLayout}>
              <Switch
                color="primary"
                checked={autoRefresh}
                onChange={(e) => setAutoRefresh(e.target.checked)}
              />
              <Typography children="Stream" variant="body2" />
              {autoRefresh && (
                <div className={classes.autoRefreshCircularLoaderWrapper}>
                  {searchAfterEffectsLoading && autoRefresh && (
                    <CircularProgress size={20} />
                  )}
                </div>
              )}
            </div>,
          );

          if (!autoRefresh) {
            controlsToRender.push(
              <Tooltip title="Reload">
                <span>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setSearchAfterEffectsRequest({
                        ...searchAfterEffectsRequest,
                        query: new Query(initialQuery),
                      })
                    }
                  >
                    <ReloadIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            );
          }

          return controlsToRender;
        })()}
        filters={[
          <TextField
            variant="outlined"
            margin="dense"
            className={classes.textSearchField}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            options={AllAfterEffectStates}
            filterSelectedOptions
            onChange={(_: React.SyntheticEvent, value: AfterEffectState[]) =>
              setStateInclCriterion(value)
            }
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textSearchField}
                label="In State"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  stateInclCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            options={AllAfterEffectStates}
            filterSelectedOptions
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              value: AfterEffectState[],
            ) => setStateNInCriterion(value)}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textSearchField}
                label="NOT In State"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={stateNInCriterion.length ? undefined : "Select..."}
              />
            )}
          />,
          <DateField
            label="From"
            id="consistencyAfterEffectTable-orderDateTimeFrom-dateField"
            className={classes.dateFilterField}
            value={dateTimeCriterionFrom ? dateTimeCriterionFrom.date : null}
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionFrom(undefined);
              } else {
                setDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionFrom && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.copyPasteIcon}
                            size="small"
                            onClick={() => setDateTimeCriterionFrom(undefined)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <DateField
            label="To"
            id="consistencyAfterEffectTable-orderDateTimeTo-dateField"
            className={classes.dateFilterField}
            value={dateTimeCriterionTo ? dateTimeCriterionTo.date : null}
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionTo(undefined);
              } else {
                setDateTimeCriterionTo(
                  newValue
                    ? {
                        date: newValue.endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionTo && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.copyPasteIcon}
                            size="small"
                            onClick={() => {
                              setDateTimeCriterionTo(undefined);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
        ]}
        columns={[
          {
            field: "",
            label: "",
            minWidth: 40,
            sortable: false,
            accessor: (data) => (
              <Tooltip
                placement="top"
                title="View after effect in containing transaction detail in new tab"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(
                      `${
                        window.location.origin
                      }/consistency/transactions/view?id=${
                        (data as ConsistencyAfterEffect).transactionID
                      }`,
                      "_blank",
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "id",
            label: "ID",
            minWidth: 280,
            accessor: (data) => {
              const txn = data as ConsistencyAfterEffect;
              return (
                <div className={classes.row}>
                  <Typography variant="body1" children={txn.id} />
                  <CopyPasteIcon
                    className={classes.copyPasteIcon}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(txn.id)
                        .then(() => enqueueSnackbar("After Effect ID copied"))
                    }
                  />
                </div>
              );
            },
          },
          {
            field: "state",
            label: "State",
            minWidth: 140,
            accessor: (data) => {
              const txn = data as ConsistencyAfterEffect;
              return (
                <div className={classes.row}>
                  <Typography variant="body1" children={txn.state} />
                  <MultiRowInfoIcon
                    invertColors
                    title={txn.lastActionAnnotation}
                  />
                </div>
              );
            },
          },
          {
            field: "auditEntry.time",
            label: "Last Modified",
            minWidth: 160,
            accessor: (data) =>
              dayjs((data as ConsistencyAfterEffect).auditEntry.time).format(
                "DD/MM/YYYY HH:mm:ss",
              ),
          },
          {
            field: "serviceURI",
            label: "Service",
          },
        ]}
      />
    </Root>
  );
}
