import { AllETFStates, ETFState } from "./ETF";
import { AllETNStates, ETNState } from "./ETN";

export const DeletedFinancialInstrumentState = "Deleted";

export type FinancialInstrumentState = "" | ETFState | ETNState;

export const AllFinancialInstrumentStates: FinancialInstrumentState[] = [
  ...AllETFStates,
  ...AllETNStates,
].filter((value, index, self) => self.indexOf(value) === index);
