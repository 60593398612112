import { NewsArticle } from "james/marketing/NewsArticle";
import { DataSheetSection } from "./DataSheetSection";
import { MarketingMedia } from "james/marketing/MarketingMedia";

export const AssetMarketingMediaDataSheetSectionTypeName =
  "mesh::marketData/AssetMarketingMedia";

export const AssetMarketingMediaDataSheetSectionName = "Media & Resources";

export class AssetMarketingMediaDataSheetSection implements DataSheetSection {
  public ["@type"] = AssetMarketingMediaDataSheetSectionTypeName;

  public newsArticles: NewsArticle[] = [];
  public marketingMedia: MarketingMedia[] = [];

  constructor(m?: AssetMarketingMediaDataSheetSection) {
    if (!m) return;

    if (m.newsArticles) {
      this.newsArticles = m.newsArticles.map((v) => new NewsArticle(v));
    }
    if (m.marketingMedia) {
      this.marketingMedia = m.marketingMedia.map((v) => new MarketingMedia(v));
    }
  }

  public DataSheetSectionName(): string {
    return AssetMarketingMediaDataSheetSectionName;
  }
}
