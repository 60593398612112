export enum TextFieldHlpTxt {
  CannotBeBlank = "Cannot be blank",
  AlreadySelected = "Value already selected",
}

export enum NumFieldHlpTxt {
  MustBeGreaterThan0 = "Must be greater than 0",
}

export enum DateFieldHlpTxt {
  NotInTheFuture = "Cannot be in the future",
  NotInThePast = "Cannot be in the past",
}
