"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allAssetHolderLookupStatees = void 0;
exports.assetHolderLookupStateToString = assetHolderLookupStateToString;
exports.stringToAssetHolderLookupState = stringToAssetHolderLookupState;
const assetHolderLookup_pb_1 = require("./assetHolderLookup_pb");
// Get all asset class as enum values
exports.allAssetHolderLookupStatees = Object.values(assetHolderLookup_pb_1.AssetHolderLookupState).filter((value) => typeof value === "number");
// Define explicit mappings between AssetHolderLookupState enums and custom string representations
const assetHolderLookupStateToStringMapping = {
    [assetHolderLookup_pb_1.AssetHolderLookupState.UNDEFINED_ASSET_HOLDER_LOOKUP_STATE]: "-",
    [assetHolderLookup_pb_1.AssetHolderLookupState.PENING_ASSET_HOLDER_LOOKUP_STATE]: "Pending",
    [assetHolderLookup_pb_1.AssetHolderLookupState.IN_PROGRESS_ASSET_HOLDER_LOOKUP_STATE]: "In Progress",
    [assetHolderLookup_pb_1.AssetHolderLookupState.COMPLETE_ASSET_HOLDER_LOOKUP_STATE]: "Complete",
};
// Reverse mapping from string to AssetHolderLookupState enum
const stringToAssetHolderLookupStateMapping = {};
for (const [key, value] of Object.entries(assetHolderLookupStateToStringMapping)) {
    stringToAssetHolderLookupStateMapping[value] = Number(key);
}
class UnsupportedAssetHolderLookupStateError extends Error {
    constructor(assetHolderLookupState) {
        const message = `Unsupported AssetHolderLookupState: ${assetHolderLookupState}`;
        super(message);
        this.assetHolderLookupState = assetHolderLookupState;
    }
}
/**
 * Converts a AssetHolderLookupState enum instance to a custom string representation.
 * @param {AssetHolderLookupState} assetHolderLookupState - The asset class to convert.
 * @returns {string} The custom string representation of the asset class.
 */
function assetHolderLookupStateToString(assetHolderLookupState) {
    if (assetHolderLookupState in assetHolderLookupStateToStringMapping) {
        return assetHolderLookupStateToStringMapping[assetHolderLookupState];
    }
    else {
        throw new UnsupportedAssetHolderLookupStateError(assetHolderLookupState);
    }
}
class UnsupportedAssetHolderLookupStateStringError extends Error {
    constructor(assetHolderLookupStateStr) {
        const message = `Unsupported asset class string: ${assetHolderLookupStateStr}`;
        super(message);
        this.assetHolderLookupStateStr = assetHolderLookupStateStr;
    }
}
/**
 * Converts a custom string representation to a AssetHolderLookupState enum instance.
 * @param {string} assetHolderLookupStateStr - The custom string representation of the asset class.
 * @returns {AssetHolderLookupState} The corresponding AssetHolderLookupState enum instance.
 */
function stringToAssetHolderLookupState(assetHolderLookupStateStr) {
    if (assetHolderLookupStateStr in stringToAssetHolderLookupStateMapping) {
        return stringToAssetHolderLookupStateMapping[assetHolderLookupStateStr];
    }
    else {
        throw new UnsupportedAssetHolderLookupStateStringError(assetHolderLookupStateStr);
    }
}
