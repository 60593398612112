import { Sector } from "./Sector";
import BigNumber from "bignumber.js";

export class SectorAllocation {
  public sector: Sector | "" = "";

  public percentage: BigNumber = new BigNumber("0");

  constructor(sectorAllocation?: SectorAllocation) {
    if (!sectorAllocation) {
      return;
    }
    this.sector = sectorAllocation.sector;
    this.percentage = new BigNumber(sectorAllocation.percentage);
  }
}
