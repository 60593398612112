import React, { useEffect } from "react";
import config from "react-global-configuration";

const Context = React.createContext({});

export const ClarityContext = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      c[a] =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        c[a] ||
        function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          // eslint-disable-next-line prefer-rest-params
          (c[a].q = c[a].q || []).push(arguments);
        };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      t = l.createElement(r);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      t.async = 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      t.src = "https://www.clarity.ms/tag/" + i;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      y = l.getElementsByTagName(r)[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", config.get("clarityProjectId"));
  }, [config.get("clarityProjectId")]);

  return <Context.Provider value={{}}>{children}</Context.Provider>;
};
