import { Context } from "james/security";
import {
  JSONRPCRequestOpts,
  jsonRPCRequest,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Client } from "./Client";

export interface UpdateClientUnscopedRequest {
  context: Context;
  client: Client;
}

export interface UpdateClientUnscopedResponse {
  client: Client;
}

export const Updater = {
  serviceProvider: "client-Updater",
  async UpdateClientUnscoped(
    request: UpdateClientUnscopedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<UpdateClientUnscopedResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${Updater.serviceProvider}.UpdateClientUnscoped`,
        request,
      },
      opts,
    );
  },
};
