import { RunningAfterEffect } from "./RunningAfterEffect";

export class AfterEffectPhase {
  public runningAfterEffects: RunningAfterEffect[] = [];

  constructor(c?: AfterEffectPhase) {
    if (!c) {
      return;
    }
    this.runningAfterEffects = c.runningAfterEffects.map(
      (rt) => new RunningAfterEffect(rt),
    );
  }
}
