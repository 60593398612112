import { Amount, Token } from "james/ledger";

export class PortfolioAssetAllocation {
  public asset: Token = new Token();
  public allocation: Amount = new Amount();
  public allocationPercentage = "";

  constructor(portfolioAssetAllocation?: PortfolioAssetAllocation) {
    if (!portfolioAssetAllocation) {
      return;
    }

    this.asset = portfolioAssetAllocation.asset;
    this.allocation = new Amount(portfolioAssetAllocation.allocation);
    this.allocationPercentage = portfolioAssetAllocation.allocationPercentage;
  }
}

export class PortfolioValuation {
  public date = "";
  public totalPortfolioValuation: Amount = new Amount();

  constructor(portfolioValuation?: PortfolioValuation) {
    if (!portfolioValuation) {
      return;
    }

    this.date = portfolioValuation?.date;
    this.totalPortfolioValuation = new Amount(
      portfolioValuation.totalPortfolioValuation,
    );
  }
}

export class AccountValuation {
  public date = "";
  public accountValuation: Amount = new Amount();
  public accountValuationBreakdown: AssetAllocation[] = [];
  public valuationFromClearanceAccounts: Amount = new Amount();

  constructor(accountValuation?: AccountValuation) {
    if (!accountValuation) {
      return;
    }

    this.date = accountValuation?.date;
    this.accountValuation = new Amount(accountValuation.accountValuation);

    this.accountValuationBreakdown =
      accountValuation.accountValuationBreakdown.map(
        (breakdown) => new AssetAllocation(breakdown),
      );

    this.valuationFromClearanceAccounts = new Amount(
      accountValuation.valuationFromClearanceAccounts,
    );
  }
}

export class AssetAllocation {
  public asset: Token = new Token();
  public accountAssetBalance: Amount = new Amount();
  public assetAllocationPercentage = "0";
  public assetValuation: Amount = new Amount();
  public assetIndicativePrice: Amount = new Amount();
  public buyPriceUsed = false;
  public valuationFromClearanceAccounts: Amount = new Amount();

  constructor(assetAllocation?: AssetAllocation) {
    if (!assetAllocation) {
      return;
    }

    this.asset = new Token(assetAllocation.asset);
    this.accountAssetBalance = new Amount(assetAllocation.accountAssetBalance);
    this.assetAllocationPercentage = assetAllocation.assetAllocationPercentage;
    this.assetValuation = new Amount(assetAllocation.assetValuation);
    this.buyPriceUsed = assetAllocation.buyPriceUsed;
    this.assetIndicativePrice = new Amount(
      assetAllocation.assetIndicativePrice,
    );
    this.valuationFromClearanceAccounts = new Amount(
      assetAllocation.valuationFromClearanceAccounts,
    );
  }
}

export class AccountAssetPnl {
  public token: Token = new Token();
  public pnLAmount: Amount = new Amount();
  public currentMarketValuationUsed: Amount = new Amount();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transfersInUsed: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transfersOutUsed: any[] = [];
  public pnlFromLinkedClearanceAccounts: Amount = new Amount();

  constructor(accountAssetPnl?: AccountAssetPnl) {
    if (!accountAssetPnl) {
      return;
    }
    this.token = new Token(accountAssetPnl.token);
    this.pnLAmount = new Amount(accountAssetPnl.pnLAmount);
    this.currentMarketValuationUsed = new Amount(
      accountAssetPnl.currentMarketValuationUsed,
    );
    this.transfersInUsed = accountAssetPnl.transfersInUsed;
    this.transfersOutUsed = accountAssetPnl.transfersOutUsed;
    this.pnlFromLinkedClearanceAccounts = new Amount(
      accountAssetPnl.pnlFromLinkedClearanceAccounts,
    );
  }
}

export class AccountOverview {
  public accountID = "";
  public accountNumber = "";
  public accountLedgerID = "";
  public pnlAmount: Amount = new Amount();
  public pnlBreakdownPerAsset: AccountAssetPnl[] = [];
  public valuationHistory: AccountValuation[] = [];
  public currentValue: Amount = new Amount();
  public currentAssetAllocation: AssetAllocation[] = [];
  // NotEnoughData is a way to tell the user weather there's sufficient data to show the UI charts
  public notEnoughValuationHistoryData = false;
  public notEnoughPnlData = false;
  public isLinkedClearanceAccount = false;

  public pnlFromLinkedClearanceAccounts: Amount = new Amount();

  constructor(accountOverview?: AccountOverview) {
    if (!accountOverview) {
      return;
    }

    this.accountID = accountOverview.accountID;
    this.accountNumber = accountOverview.accountNumber;
    this.accountLedgerID = accountOverview.accountLedgerID;

    this.pnlAmount = new Amount(accountOverview.pnlAmount);

    this.pnlBreakdownPerAsset = accountOverview.pnlBreakdownPerAsset.map(
      (breakdown) => new AccountAssetPnl(breakdown),
    );

    this.valuationHistory = accountOverview.valuationHistory.map(
      (valuationHistory: AccountValuation) =>
        new AccountValuation(valuationHistory),
    );
    this.currentValue = new Amount(accountOverview.currentValue);
    this.currentAssetAllocation = accountOverview.currentAssetAllocation.map(
      (assetAllocation: AssetAllocation) =>
        new AssetAllocation(assetAllocation),
    );
    this.notEnoughValuationHistoryData =
      accountOverview.notEnoughValuationHistoryData;
    this.notEnoughPnlData = accountOverview.notEnoughPnlData;
    this.isLinkedClearanceAccount = accountOverview.isLinkedClearanceAccount;

    this.pnlFromLinkedClearanceAccounts = new Amount(
      accountOverview.pnlFromLinkedClearanceAccounts,
    );
  }
}
