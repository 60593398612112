import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { useEffect, useRef, useState } from "react";
import { CommitPhase } from "./CommitPhase";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface GetCommitPhaseRequest {
  context: Context;
}

export interface GetCommitPhaseResponse {
  commitPhase: CommitPhase;
}

export interface CommitPhaseInspectorServiceProvider {
  serviceProvider: string;

  GetCommitPhase(
    request: GetCommitPhaseRequest,
  ): Promise<GetCommitPhaseResponse>;
}

const CommitPhaseInspectorServiceProviderName =
  "consistency-CommitPhaseInspector";

export const CommitPhaseInspector: CommitPhaseInspectorServiceProvider = {
  serviceProvider: CommitPhaseInspectorServiceProviderName,
  async GetCommitPhase(
    request: GetCommitPhaseRequest,
  ): Promise<GetCommitPhaseResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("consistencyHorizonAuthorizedURL"),
      method: `${CommitPhaseInspector.serviceProvider}.GetCommitPhase`,
      request,
    });
    return { commitPhase: new CommitPhase(response.commitPhase) };
  },
};

export function useGetCommitPhase(
  initialGetCommitPhaseRequest?: GetCommitPhaseRequest,
  shouldNotExecute?: boolean,
) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [getCommitPhaseRequest, setGetCommitPhaseRequest] =
    useState<GetCommitPhaseRequest>(
      initialGetCommitPhaseRequest || { context: authContext },
    );
  const [getCommitPhaseResponse, setGetCommitPhaseResponse] =
    useState<GetCommitPhaseResponse>({
      commitPhase: new CommitPhase(),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const reloadRef = useRef<() => void>(() =>
    setGetCommitPhaseRequest({
      context: authContext,
    }),
  );
  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    (async () => {
      setLoading(true);
      setError(undefined);
      try {
        setGetCommitPhaseResponse(
          await CommitPhaseInspector.GetCommitPhase(getCommitPhaseRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting commit phase: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error getting commit phase: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    })();
  }, [getCommitPhaseRequest, shouldNotExecute]);

  return {
    getCommitPhaseRequest,
    setGetCommitPhaseRequest,
    getCommitPhaseResponse,
    loading,
    error,
    reload: reloadRef.current,
  };
}
