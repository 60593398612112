import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface SendCompanyOnboardingMailRequest {
  companyName: string;
  adminEmail: string;
  adminName: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SendCompanyOnboardingMailResponse {}

export const SendCompanyOnboardRequestMailServiceProviderName =
  "onboarding-CompanyOnboardingRequestMailer";

export const CompanyOnboardingRequestMailer = {
  async SendCompanyOnboardingRequestMail(
    request: SendCompanyOnboardingMailRequest,
  ): Promise<SendCompanyOnboardingMailResponse> {
    return jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${SendCompanyOnboardRequestMailServiceProviderName}.SendCompanyOnboardingRequestMail`,
      request,
    });
  },
};
