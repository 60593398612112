import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Sidebar, drawerMiniWidth, drawerWidth } from "components/Sidebar";
import cx from "classnames";
import { MainAppBar } from "components/MainAppBar";
import { RouteType } from "routes/Route";
import { Box } from "@mui/material";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "MeshCore";

const classes = {
  root: `${PREFIX}-root`,
  mainPanel: `${PREFIX}-mainPanel`,
  mainPanelSidebarMini: `${PREFIX}-mainPanelSidebarMini`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: "calc(100vh)",
    overflow: "hidden",
  },

  [`& .${classes.mainPanel}`]: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "hidden",
    position: "relative",
    float: "right",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },

  [`& .${classes.mainPanelSidebarMini}`]: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },

  [`& .${classes.content}`]: {
    marginTop: 56,
    height: `calc(100vh - ${56}px)`,
    overflowY: "hidden",
  },
}));

export interface Props {
  children?: React.ReactNode;
  homeRoute?: RouteType;
  userProfileRoute: RouteType;
  clientProfileRoute?: RouteType;
  otherRoutes: RouteType[];
}

export function MeshCoreLayout(props: Props) {
  const [sideBarClosed, setSidebarClosed] = useState(false);
  const { NotificationBannerHeight } = useAppNoticeContext();

  return (
    <Root className={classes.root}>
      <Sidebar
        sidebarClosed={sideBarClosed}
        handleSidebarOpenToggle={() => setSidebarClosed(!sideBarClosed)}
        homeRoute={props.homeRoute}
        otherRoutes={props.otherRoutes}
      />
      <div
        className={cx(classes.mainPanel, {
          [classes.mainPanelSidebarMini]: sideBarClosed,
        })}
      >
        <MainAppBar
          userProfileRoute={props.userProfileRoute}
          clientProfileRoute={props.clientProfileRoute}
        />
        <Box
          // TODO The SX property does not want to work here
          style={{
            marginTop: 56 + NotificationBannerHeight,
            height: `calc(100vh - ${56 + NotificationBannerHeight}px)`,
            overflowY: "hidden",
          }}
        >
          {props.children}
        </Box>
      </div>
    </Root>
  );
}
