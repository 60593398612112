import {
  Box,
  Button,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Avatar,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { doUpload } from "utilities/network/upload";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useValidatedForm } from "hooks/useForm";
import { Asset } from "james/ledger/Asset";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForm";
import {
  MarketingMedia,
  NewsArticle,
  SocialMediaLinks,
  InstrumentMarketingContentRepository,
  InstrumentMarketingContent,
  MediaType,
  IndependentReview,
} from "james/marketing";
import { DateField, TextField } from "components/FormFields";
import dayjs from "dayjs";
import { MediaImage } from "james/media/Image";
import { TokenIdentifier } from "james/search/identifier";
import { Token } from "james/ledger";
import { Listing } from "james/market";
import {
  InstrumentMarketingContentUpdater,
  UpdateIndependentReviewRequest,
  UpdateInvestmentObjectiveRequest,
  UpdateNewsArticlesRequest,
} from "james/marketing/InstrumentMarketingContentUpdater";
import { useSearchParams } from "react-router-dom";
import {
  GenerateImageDownloadUrlResponse,
  GenerateImageUploadUrlResponse,
  PublicMediaController,
} from "james/media/publicMediaController";
import { LoadingButton } from "@mui/lab";
import { isEqual } from "lodash";
import { ListingState } from "james/market/Listing";
import { extractVideoLinks, objectsAreEqual } from "./marketingMedia_utils";
import { ReactSortable } from "react-sortablejs";

export enum FormMode {
  Edit = "edit",
  View = "view",
}

export type Props = {
  asset: Asset;
  listing: Listing;
};

export const MarketingMediaForm = (props: Props) => {
  // Component Setup
  const { authContext } = useApplicationContext();
  useErrorContext();

  const reviewerImageInputRef = useRef<HTMLInputElement>(null);
  const mediaThumbnailImageInputRef = useRef<HTMLInputElement>(null);
  const marketingMediaInputRef = useRef<HTMLInputElement>(null);

  const [apiLoading, setAPILoading] = useState(false);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [uploadingInProgress, setUploadingInProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState<string | boolean>(true);
  const theme = useTheme();

  // Keep track of the current marketing content as on the db
  const [currentMarketingContent, setCurrentMarketingContent] = useState(
    new InstrumentMarketingContent(),
  );
  const [marketingContentId, setMarketingContentId] = useState<string>("");

  // Create reference type of the Estimated Annual Return string
  const [estimatedAnnualReturnHasChanges, setEstimatedAnnualReturnHasChanges] =
    useState(false);

  // Create reference type of the Investment Objective string
  const [investmentObjectiveHasChanges, setInvestmentObjectiveHasChanges] =
    useState(false);

  // Create reference type of the SocialMediaLinks class
  const [socialMediaLinksHasChanges, setSocialMediaLinksHasChanges] =
    useState(false);

  // Create reference type of the NewsArticles class
  const [currentNewsArticle, setCurrentNewsArticle] = useState(
    new NewsArticle(),
  );

  // The rendered list of the news articles
  const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([]);

  const [newsArticlesHasChanges, setNewsArticlesHasChanges] = useState(false);

  // Create reference type of the IndependentReviews class
  const [currentIndependentReview, setCurrentIndependentReview] = useState(
    new IndependentReview(),
  );
  const [independentReviewsHasChanges, setIndependentReviewsHasChanges] =
    useState(false);
  const [
    reviewerProfilePictureUploadPercentage,
    setReviewerProfilePictureUploadPercentage,
  ] = useState(0);

  // Create reference type of the Marketing Media class
  const [currentMarketingMedia, setCurrentMarketingMedia] = useState(
    new MarketingMedia({
      type: MediaType.Image,
      url: "",
      thumbnailImage: new MediaImage(),
    }),
  );

  // The rendered list of the marketing media
  const [marketingMediaList, setMarketingMediaList] = useState<
    MarketingMediaWithId[]
  >([]);

  const [marketingMediaHasChanges, setMarketingMediaHasChanges] =
    useState(false);
  const [marketingMediaUploadPercentage, setMarketingMediaUploadPercentage] =
    useState(0);
  const [
    mediaThumbnailImageUploadPercentage,
    setMediaThumbnailImageUploadPercentage,
  ] = useState(0);

  interface MarketingMediaWithId extends MarketingMedia {
    id: number;
  }

  const retrieveInstrumentMarketingContent =
    async (): Promise<InstrumentMarketingContent> => {
      let existingMarketingContent: InstrumentMarketingContent =
        new InstrumentMarketingContent();

      try {
        const response =
          await InstrumentMarketingContentRepository.RetrieveInstrumentMarketingContent(
            {
              context: authContext,
              identifier: TokenIdentifier(new Token(props.asset.assetToken())),
            },
          );
        existingMarketingContent = response.instrumentMarketingContent;
        setCurrentMarketingContent(existingMarketingContent);
        setMarketingContentId(response.instrumentMarketingContent.id);
      } catch (error) {
        console.error("failed to retrieve marketing content: ", error);
        if (props.listing.state != ListingState.Active) {
          enqueueSnackbar("no marketing content on unlisted instrument", {
            variant: "warning",
          });
        } else {
          enqueueSnackbar("failed to retrieve marketing content", {
            variant: "error",
          });
        }
      }

      return existingMarketingContent;
    };

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      const marketingContent = await retrieveInstrumentMarketingContent();
      setMarketingMediaList(
        marketingContent.instrumentMarketingMedia()?.map((v, k) => ({
          id: k,
          ...v,
        })) ?? [],
      );
      setNewsArticles(
        marketingContent.instrumentNewsArticles()?.map((v, k) => ({
          id: k,
          ...v,
        })) ?? [],
      );
      return {
        asset: props.asset,
        instrumentMarketingContent: new InstrumentMarketingContent(
          marketingContent,
        ),
      };
    },
    formDataUpdaterSpecs,
    {
      asset: props.asset,
      instrumentMarketingContent: new InstrumentMarketingContent(),
    },
    new Set<string>(Object.keys(new InstrumentMarketingContent())),
  );

  const handleUpdateEstimatedAnnualReturn = async () => {
    try {
      const request = {
        context: authContext,
        ID: marketingContentId,
        EstimatedAnnualReturn:
          formData.instrumentMarketingContent.estimatedAnnualReturn,
      };
      const response =
        await InstrumentMarketingContentUpdater.UpdateEstimatedAnnualReturn(
          request,
        );
      setCurrentMarketingContent(
        new InstrumentMarketingContent(response.instrumentmarketingcontent),
      );
      setEstimatedAnnualReturnHasChanges(false);
      enqueueSnackbar("Estimated annual return updated", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update estimated annual return", {
        variant: "error",
      });
    }
  };

  const handleUpdateSocialMediaLinks = async () => {
    try {
      const request = {
        context: authContext,
        ID: marketingContentId,
        SocialMediaLinks: formData.instrumentMarketingContent.socialMediaLinks,
      };
      const response =
        await InstrumentMarketingContentUpdater.UpdateSocialMediaLinks(request);
      setCurrentMarketingContent(
        new InstrumentMarketingContent(response.instrumentmarketingcontent),
      );
      setSocialMediaLinksHasChanges(false);
      enqueueSnackbar("Social media links updated", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update social media links", {
        variant: "error",
      });
    }
  };

  const handleUpdateInvestmentObjective = async () => {
    try {
      const request: UpdateInvestmentObjectiveRequest = {
        context: authContext,
        ID: marketingContentId,
        InvestmentObjective:
          formData.instrumentMarketingContent.investmentObjective,
      };
      const response =
        await InstrumentMarketingContentUpdater.UpdateInvestmentObjective(
          request,
        );
      setCurrentMarketingContent(
        new InstrumentMarketingContent(response.instrumentmarketingcontent),
      );
      setInvestmentObjectiveHasChanges(false);
      enqueueSnackbar("Investment objective updated", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update investment objective", {
        variant: "error",
      });
    }
  };

  const handleUpdateNewsArticles = async () => {
    if (newsArticles === undefined) {
      return;
    } else if (newsArticles.length == 0) {
      formDataUpdate.newsArticles([currentNewsArticle]);
    }

    const newsArticlesTEMP = newsArticles.map((v) => ({
      ...v,
    }));

    formDataUpdate.newsArticles(newsArticlesTEMP);
    try {
      const request: UpdateNewsArticlesRequest = {
        context: authContext,
        ID: marketingContentId,
        NewsArticles: newsArticlesTEMP,
      };
      const response =
        await InstrumentMarketingContentUpdater.UpdateNewsArticles(request);
      setCurrentMarketingContent(
        new InstrumentMarketingContent(response.instrumentmarketingcontent),
      );
      setNewsArticlesHasChanges(false);
      enqueueSnackbar("News articles updated", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update news articles", {
        variant: "error",
      });
    }
  };

  const handleUpdateIndependentReviews = async () => {
    try {
      const request: UpdateIndependentReviewRequest = {
        context: authContext,
        ID: marketingContentId,
        IndependentReviews: [
          ...formData.instrumentMarketingContent.independentReviews,
        ],
      };
      const reponse =
        await InstrumentMarketingContentUpdater.UpdateIndependentReview(
          request,
        );
      setCurrentMarketingContent(
        new InstrumentMarketingContent(reponse.instrumentmarketingcontent),
      );
      setIndependentReviewsHasChanges(false);
      setCurrentIndependentReview(new IndependentReview());
      enqueueSnackbar("Independent reviews updated", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update independent reviews", {
        variant: "error",
      });
    }

    setReviewerProfilePictureUploadPercentage(0);
  };

  const handleUpdateMarketingMedia = async () => {
    if (marketingMediaList === undefined) {
      return;
    } else if (marketingMediaList.length == 0) {
      formDataUpdate.marketingMedia([new MarketingMedia()]);
    }

    const mMedia = marketingMediaList.map((v) => ({
      ...v,
    }));

    // Keep form up to date with changes
    formDataUpdate.marketingMedia(mMedia);
    try {
      const request = {
        context: authContext,
        ID: marketingContentId,
        MarketingMedia: mMedia,
      };
      const response =
        await InstrumentMarketingContentUpdater.UpdateMarketingMedia(request);
      setCurrentMarketingContent(
        new InstrumentMarketingContent(response.instrumentmarketingcontent),
      );
      setMarketingMediaHasChanges(false);
      enqueueSnackbar("Marketing media updated", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update marketing media", {
        variant: "error",
      });
    }
  };

  const handleSaveAll = async () => {
    const response =
      await InstrumentMarketingContentRepository.RetrieveInstrumentMarketingContent(
        {
          context: authContext,
          identifier: TokenIdentifier(new Token(props.asset.assetToken())),
        },
      );

    let count = 0;

    setAPILoading(true);

    const newsArticlesAreEqual = objectsAreEqual(
      formData.instrumentMarketingContent.newsArticles,
      response.instrumentMarketingContent.newsArticles,
    );

    if (!newsArticlesAreEqual) {
      count++;
      await handleUpdateNewsArticles();
    }

    const independentReviewsAreEqual = objectsAreEqual(
      formData.instrumentMarketingContent.independentReviews,
      response.instrumentMarketingContent.independentReviews,
    );

    if (!independentReviewsAreEqual) {
      count++;
      await handleUpdateIndependentReviews();
    }

    const marketingMediaAreEqual = objectsAreEqual(
      formData.instrumentMarketingContent.marketMedia,
      response.instrumentMarketingContent.marketMedia,
    );
    if (!marketingMediaAreEqual) {
      count++;
      await handleUpdateMarketingMedia();
    }

    const estimatedAnnualReturnAreEqual =
      formData.instrumentMarketingContent.estimatedAnnualReturn ===
      response.instrumentMarketingContent.estimatedAnnualReturn;
    if (!estimatedAnnualReturnAreEqual) {
      count++;
      await handleUpdateEstimatedAnnualReturn();
    }

    const investmentObjectiveAreEqual =
      formData.instrumentMarketingContent.investmentObjective ===
      response.instrumentMarketingContent.investmentObjective;
    if (!investmentObjectiveAreEqual) {
      count++;
      await handleUpdateInvestmentObjective();
    }

    const socialMediaLinksAreEqual = isEqual(
      formData.instrumentMarketingContent.socialMediaLinks,
      response.instrumentMarketingContent.socialMediaLinks,
    );
    if (!socialMediaLinksAreEqual) {
      count++;
      await handleUpdateSocialMediaLinks();
    }

    setFormHasChanges(false);
    setAPILoading(false);
    if (count == 0) {
      enqueueSnackbar("No changes to save", {
        variant: "info",
      });
      return;
    } else if (count == 6) {
      enqueueSnackbar("All changes saved", {
        variant: "success",
      });
    }
  };

  const handleReviewerImageUpload = async () => {
    reviewerImageInputRef.current?.click();
  };

  const handleImageChange = async <T,>(
    event: React.ChangeEvent<HTMLInputElement>,
    setStateAction: React.Dispatch<React.SetStateAction<T>>,
    setUploadPercentage: React.Dispatch<React.SetStateAction<number>>,
    updateStateFn: (prevState: T, newImage: MediaImage) => T,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        enqueueSnackbar("Invalid file type. Should be one of: jpeg, png", {
          variant: "warning",
        });
        return;
      }

      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        enqueueSnackbar("File is Too Large for Upload (Max 10 MB)", {
          variant: "warning",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await PublicMediaController.GenerateImageUploadUrl({
          context: authContext,
        });

        await (() =>
          new Promise((resolve, reject) => {
            file.arrayBuffer().then((buffer) => {
              doUpload({
                url: response.url,
                documentData: buffer,
                documentType: file.type,
                onProgress: (progress) => {
                  setUploadPercentage(progress);
                },
                onComplete: async () => {
                  const downloadResponse =
                    await PublicMediaController.GenerateImageDownloadUrl({
                      context: authContext,
                      mediaAssetID: response.imageID,
                    });
                  const newImage = new MediaImage({
                    name: file.name,
                    id: response.imageID,
                    url: downloadResponse.url,
                    type: "image",
                    metaData: {},
                  });
                  setStateAction((prevState: T) =>
                    updateStateFn(prevState, newImage),
                  );

                  resolve(true);
                },
                onError: reject,
              });
            });
          }))();
      } catch (error) {
        enqueueSnackbar("Failed to upload image", { variant: "error" });
        return;
      }
    }
  };

  const handleReviewerImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleImageChange<typeof currentIndependentReview>(
      event,
      setCurrentIndependentReview,
      setReviewerProfilePictureUploadPercentage,
      (prevState, newImage) => ({
        ...prevState,
        reviewerDetails: {
          ...prevState.reviewerDetails,
          profilePicture: newImage,
        },
      }),
    );
  };

  const handleMediaThumbnailImageUpload = async () => {
    mediaThumbnailImageInputRef.current?.click();
  };

  const handleMediaThumbnailImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleImageChange<typeof currentMarketingMedia>(
      event,
      setCurrentMarketingMedia,
      setMediaThumbnailImageUploadPercentage,
      (prevState, newImage) => ({
        ...prevState,
        thumbnailImage: newImage,
      }),
    );
  };

  const handleMarketingMediaUpload = async () => {
    if (currentMarketingMedia.thumbnailImage.url.length == 0) {
      setCurrentMarketingMedia({
        ...currentMarketingMedia,
        thumbnailImage: new MediaImage({
          ...currentMarketingMedia.thumbnailImage,
          url: currentMarketingMedia.url,
        }),
      });
    }
    marketingMediaInputRef.current?.click();
  };

  const handleMarketingMediaChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUploadingInProgress(true);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let fileType: MediaType;
      // Check if file type is image
      if (file.type.startsWith("image/")) {
        fileType = MediaType.Image;
      } else if (file.type.startsWith("video/")) {
        fileType = MediaType.Video;
      } else {
        formDataUpdate.marketingMedia([new MarketingMedia()]);
        setMarketingMediaUploadPercentage(0);
        enqueueSnackbar("Invalid file type. Should be one of: jpeg, png", {
          variant: "warning",
        });
        return;
      }

      const maxSizeInBytes = 100 * 1024 * 1024; // 100MB
      if (file.size > maxSizeInBytes) {
        enqueueSnackbar("File is Too Large for Upload (Max 10 MB)", {
          variant: "warning",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        let response: GenerateImageUploadUrlResponse;
        let downloadResponse: GenerateImageDownloadUrlResponse;

        if (currentMarketingMedia.type === MediaType.Image) {
          response = await PublicMediaController.GenerateImageUploadUrl({
            context: authContext,
          });
        }

        await (() =>
          new Promise((resolve, reject) => {
            file.arrayBuffer().then((buffer) => {
              doUpload({
                url: response.url,
                documentData: buffer,
                documentType: file.type,
                onProgress: (progress) => {
                  setMarketingMediaUploadPercentage(progress);
                },
                onComplete: async () => {
                  if ("imageID" in response) {
                    downloadResponse =
                      await PublicMediaController.GenerateImageDownloadUrl({
                        context: authContext,
                        mediaAssetID: response.imageID,
                      });
                  }
                  let thumbnailImageUrl = downloadResponse.url;
                  if (mediaThumbnailImageUploadPercentage == 100) {
                    thumbnailImageUrl =
                      currentMarketingMedia.thumbnailImage.url;
                  }
                  setCurrentMarketingMedia({
                    ...currentMarketingMedia,
                    type: fileType,
                    url: thumbnailImageUrl,
                    thumbnailImage: new MediaImage({
                      ...currentMarketingMedia.thumbnailImage,
                      url: thumbnailImageUrl,
                    }),
                  });
                  setMarketingMediaHasChanges(true);
                  resolve(true);
                },
                onError: () => {
                  setMarketingMediaHasChanges(false);
                  reject;
                },
              });
            });
          }))();
      } catch (error) {
        enqueueSnackbar("Failed to upload media", { variant: "error" });
        return;
      }
    }
    setUploadingInProgress(false);
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, expanded: boolean) => {
      if ((event.target as HTMLElement).innerText != "Save") {
        setExpanded(expanded ? panel : false);
      }
    };

  useEffect(() => {
    const formMode = searchParams.get("mode");
    if (!formMode) {
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set(
        "mode",
        formData.instrumentMarketingContent.instrumentInvestmentObjective() ===
          ""
          ? FormMode.View
          : FormMode.Edit,
      );
      setSearchParams(updatedSearchParams);
    }

    if (newsArticlesHasChanges) {
      if (!(formData.instrumentMarketingContent.newsArticles.length == 0)) {
        setNewsArticles(
          formData.instrumentMarketingContent.newsArticles.map((v, k) => ({
            id: k,
            ...v,
          })),
        );
      } else if (
        objectsAreEqual(
          formData.instrumentMarketingContent.newsArticles,
          newsArticles,
        )
      ) {
        return;
      }
    }

    if (marketingMediaHasChanges) {
      if (!(formData.instrumentMarketingContent.marketMedia.length == 0)) {
        setMarketingMediaList(
          formData.instrumentMarketingContent.marketMedia.map((v, k) => ({
            id: k,
            ...v,
          })),
        );
      } else if (
        objectsAreEqual(
          formData.instrumentMarketingContent.marketMedia,
          marketingMediaList,
        )
      ) {
        return;
      }
    }

    setFormHasChanges(
      socialMediaLinksHasChanges ||
        estimatedAnnualReturnHasChanges ||
        investmentObjectiveHasChanges ||
        newsArticlesHasChanges ||
        independentReviewsHasChanges ||
        marketingMediaHasChanges,
    );
  }, [formData.instrumentMarketingContent, searchParams]);

  const readOnly = (searchParams.get("mode") as FormMode) === FormMode.View;

  return (
    <>
      <Box sx={{ mb: expanded ? 6 : 0 }}>
        <CardContent sx={{}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              m: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={(theme) => ({
                fontSize: 20, // h4 font size
                fontWeight: theme.typography.fontWeightBold, // Bold font
                letterSpacing: 0,
              })}
            >
              Marketing Media
            </Typography>
            <Tooltip
              title={"Upload marketing media for this asset"}
              disableFocusListener
              disableTouchListener
            >
              <span>
                <LoadingButton
                  loading={apiLoading}
                  color="primary"
                  variant="contained"
                  disabled={
                    (!formDataValidationResult.valid ||
                      formDataValidationInProgress ||
                      !formHasChanges) &&
                    !readOnly
                  }
                  endIcon={
                    searchParams.get("mode") === FormMode.Edit ? null : (
                      <EditIcon />
                    )
                  }
                  onClick={() => {
                    const formMode = searchParams.get("mode");
                    const newSearchParams = new URLSearchParams(searchParams);
                    if (formMode === FormMode.View) {
                      newSearchParams.set("mode", FormMode.Edit);
                    } else if (formMode === FormMode.Edit) {
                      try {
                        handleSaveAll();
                      } catch (error) {
                        enqueueSnackbar("Failed to save changes", {
                          variant: "error",
                        });
                      }
                      newSearchParams.set("mode", FormMode.View);
                    }

                    setSearchParams(newSearchParams);
                  }}
                >
                  {searchParams.get("mode") == FormMode.Edit ? "Save" : "Edit"}
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>

          <Box margin={theme.spacing(5, 3.5, 2, 3.5)}>
            {/* TODO check view mode && Viewconfig.Update && docsReadOnly ? : No docs */}
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    id={"instrumentMarketing-socialMediaLink-textField"}
                    value={
                      formData.instrumentMarketingContent.socialMediaLinks.web
                    }
                    label={"Website link"}
                    placeholder={"Enter the link to the website"}
                    disabled={uploadingInProgress}
                    readOnly={readOnly}
                    onChange={(e) => {
                      formDataUpdate.socialMediaLinks({
                        facebook:
                          formData.instrumentMarketingContent.socialMediaLinks
                            .facebook,
                        web: e.target.value.toString(),
                        linkedin:
                          formData.instrumentMarketingContent.socialMediaLinks
                            .linkedin,
                        youtube:
                          formData.instrumentMarketingContent.socialMediaLinks
                            .youtube,
                      });
                      setSocialMediaLinksHasChanges(true);
                    }}
                    sx={{
                      fontSize: 15,
                      letterSpacing: 0,
                      "&::placeholder": {
                        color: "rgba(0, 0, 0, 0.54)", // Placeholder color
                        fontWeight: 600,
                        fontSize: "1.2rem", // Placeholder font size to match h4
                      },
                    }}
                    error={!!formDataValidationResult.fieldValidations.web}
                    helperText={formDataValidationResult.fieldValidations.web}
                  />
                  <TextField
                    id={"instrumentMarketing-socialMediaLink-textField"}
                    value={
                      formData.instrumentMarketingContent.socialMediaLinks
                        .linkedin
                    }
                    label={"Linkedin link"}
                    placeholder={"Enter the link to the linkedin page"}
                    disabled={uploadingInProgress}
                    readOnly={readOnly}
                    onChange={(e) => {
                      formDataUpdate.socialMediaLinks(
                        new SocialMediaLinks({
                          facebook:
                            formData.instrumentMarketingContent.socialMediaLinks
                              .facebook,
                          web: formData.instrumentMarketingContent
                            .socialMediaLinks.web,
                          linkedin: e.target.value.toString(),
                          youtube:
                            formData.instrumentMarketingContent.socialMediaLinks
                              .youtube,
                        }),
                      );
                      setSocialMediaLinksHasChanges(true);
                    }}
                    sx={{
                      fontSize: 15,
                      letterSpacing: 0,
                      "&::placeholder": {
                        color: "rgba(0, 0, 0, 0.54)", // Placeholder color
                        fontWeight: 600,
                        fontSize: "1.2rem", // Placeholder font size to match h4
                      },
                    }}
                    error={!!formDataValidationResult.fieldValidations.linkedin}
                    helperText={
                      formDataValidationResult.fieldValidations.linkedin
                    }
                  />
                  <TextField
                    id={"instrumentMarketing-socialMediaLink-textField"}
                    value={
                      formData.instrumentMarketingContent.socialMediaLinks
                        .youtube
                    }
                    label={"Youtube link"}
                    placeholder={"Enter the link to the youtube page"}
                    disabled={uploadingInProgress}
                    readOnly={readOnly}
                    onChange={(e) => {
                      formDataUpdate.socialMediaLinks(
                        new SocialMediaLinks({
                          facebook:
                            formData.instrumentMarketingContent.socialMediaLinks
                              .facebook,
                          web: formData.instrumentMarketingContent
                            .socialMediaLinks.web,
                          linkedin:
                            formData.instrumentMarketingContent.socialMediaLinks
                              .linkedin,
                          youtube: e.target.value.toString(),
                        }),
                      );
                      setSocialMediaLinksHasChanges(true);
                    }}
                    sx={{
                      fontSize: 15,
                      letterSpacing: 0,
                      "&::placeholder": {
                        color: "rgba(0, 0, 0, 0.54)", // Placeholder color
                        fontWeight: 600,
                        fontSize: "1.2rem", // Placeholder font size to match h4
                      },
                    }}
                    error={!!formDataValidationResult.fieldValidations.youtube}
                    helperText={
                      formDataValidationResult.fieldValidations.youtube
                    }
                  />
                  <TextField
                    id={"instrumentMarketing-socialMediaLink-textField"}
                    value={
                      formData.instrumentMarketingContent.socialMediaLinks
                        .facebook
                    }
                    label={"Facebook link"}
                    placeholder={"Enter the link to the facebook page"}
                    disabled={uploadingInProgress}
                    readOnly={readOnly}
                    onChange={(e) => {
                      formDataUpdate.socialMediaLinks(
                        new SocialMediaLinks({
                          facebook: e.target.value.toString(),
                          web: formData.instrumentMarketingContent
                            .socialMediaLinks.web,
                          linkedin:
                            formData.instrumentMarketingContent.socialMediaLinks
                              .linkedin,
                          youtube:
                            formData.instrumentMarketingContent.socialMediaLinks
                              .youtube,
                        }),
                      );
                      setSocialMediaLinksHasChanges(true);
                    }}
                    sx={{
                      fontSize: 15,
                      letterSpacing: 0,
                      "&::placeholder": {
                        color: "rgba(0, 0, 0, 0.54)", // Placeholder color
                        fontWeight: 600,
                        fontSize: "1.2rem", // Placeholder font size to match h4
                      },
                    }}
                    error={!!formDataValidationResult.fieldValidations.facebook}
                    helperText={
                      formDataValidationResult.fieldValidations.facebook
                    }
                  />
                </Box>
                <TextField
                  id="instrumentMarketingContent-investmentObjective-textField"
                  label="Investment Objective"
                  readOnly={readOnly}
                  multiline
                  rows={8.4}
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                  }}
                  value={
                    formData.instrumentMarketingContent.investmentObjective
                  }
                  onChange={(e) => {
                    formDataUpdate.investmentObjective(e.target.value);
                    setInvestmentObjectiveHasChanges(true);
                  }}
                  error={
                    !!formDataValidationResult.fieldValidations
                      .investmentObjective
                  }
                  helperText={
                    formDataValidationResult.fieldValidations
                      .investmentObjective
                  }
                />
              </Box>
            </>
          </Box>
        </CardContent>

        {/* ************************** NEWS ARTICLES SECTION UI ************************** */}
        <Accordion
          expanded={expanded === "newsArticles" || expanded === true}
          onChange={handleAccordionChange("newsArticles")}
        >
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="marketingContent-newsArticles-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Typography
                sx={{
                  ml: 4,
                }}
                variant={"h6"}
                alignContent={"center"}
              >
                News Articles
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpdateNewsArticles}
                disabled={!newsArticlesHasChanges}
              >
                Save
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              columnGap: theme.spacing(3),
              p: 2,
              m: theme.spacing(0, 3.5, 2, 3.5),
            })}
          >
            <Typography variant={"h6"} sx={{ gridColumn: "1/4", my: 2 }}>
              Existing News Articles
            </Typography>
            {(() => {
              const newsArticlesWithId = newsArticles.map((article, idx) => ({
                id: idx,
                ...article,
              }));

              if (
                newsArticlesWithId == undefined ||
                newsArticlesWithId.length == 0
              ) {
                <Typography variant="h6" sx={{ my: 4 }} textAlign={"center"}>
                  No News Articles
                </Typography>;
              }

              return (
                <ReactSortable
                  animation={200}
                  easing="ease-out"
                  disabled={readOnly}
                  list={newsArticlesWithId ?? []}
                  setList={setNewsArticles}
                  chosenClass={"meshDragChosen"}
                  ghostClass={"meshDragGhost"}
                  onChange={() => {
                    // Get rid of the id field required by ReactSortable
                    formDataUpdate.newsArticles(
                      newsArticlesWithId?.map((v) => ({
                        date: v.date,
                        url: v.url,
                        excerpt: v.excerpt,
                      })),
                    );
                    setNewsArticlesHasChanges(true);
                  }}
                >
                  {newsArticles?.map((article, idx) => (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        my: 1,
                      }}
                      boxShadow={1}
                      key={idx}
                    >
                      <Box
                        sx={{
                          width: "95%",
                          m: 1,
                        }}
                      >
                        <DateField
                          sx={{
                            width: "40%",
                          }}
                          label={"Article Date"}
                          readOnly
                          value={article.date}
                        />
                        <TextField
                          sx={{
                            width: "40%",
                            input: {
                              cursor: "pointer",
                            },
                          }}
                          label={"Article Url"}
                          readOnly
                          value={article.url}
                        />
                      </Box>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={article.excerpt}
                      >
                        <span style={{ width: "100%" }}>
                          <TextField
                            sx={{
                              width: "100%",
                              "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                            label={"Article Excerpt"}
                            readOnly
                            value={article.excerpt}
                          />
                        </span>
                      </Tooltip>
                      <IconButton
                        disabled={readOnly}
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          const articles =
                            formData.instrumentMarketingContent.newsArticles ??
                            [];
                          articles.splice(idx, 1);
                          formDataUpdate.newsArticles([...articles]);
                          setNewsArticlesHasChanges(
                            formData.instrumentMarketingContent.newsArticles !==
                              currentMarketingContent.newsArticles,
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </ReactSortable>
              );
            })()}

            <Divider />
            {!readOnly ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                    my: 2,
                  }}
                >
                  <Typography variant={"h6"} sx={{ my: 2 }}>
                    News Articles
                  </Typography>
                  <Button
                    disabled={currentNewsArticle.url.length == 0}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      if (
                        formData.instrumentMarketingContent.newsArticles ==
                        undefined
                      ) {
                        formDataUpdate.newsArticles([currentNewsArticle]);
                      } else {
                        formDataUpdate.newsArticles([
                          ...formData.instrumentMarketingContent.newsArticles,
                          currentNewsArticle,
                        ]);
                      }

                      setNewsArticlesHasChanges(true);
                      setCurrentNewsArticle(new NewsArticle());
                    }}
                  >
                    Add Article
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <DateField
                    label="Date"
                    id="instrumentMarketingContent-currentNewsArticle-dateField"
                    onChange={(newDate) => {
                      setCurrentNewsArticle({
                        ...currentNewsArticle,
                        date: dayjs(newDate),
                      });
                    }}
                    value={currentNewsArticle.date.format("YYYY-MM-DD")}
                    maxDate={dayjs()}
                  />
                  <TextField
                    fullWidth
                    label={"Article Url"}
                    value={currentNewsArticle.url}
                    onChange={(e) => {
                      setCurrentNewsArticle({
                        ...currentNewsArticle,
                        url: e.target.value,
                      });
                    }}
                  />
                </Box>
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  label={"Article Excerpt"}
                  value={currentNewsArticle.excerpt}
                  onChange={(e) => {
                    setCurrentNewsArticle({
                      ...currentNewsArticle,
                      excerpt: e.target.value,
                    });
                  }}
                />
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>

        {/* ************************** INDEPENDENT REVIEWS SECTION UI ************************** */}
        <Accordion
          expanded={expanded === "independentReviews" || expanded === true}
          onChange={handleAccordionChange("independentReviews")}
        >
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="marketingContent-independentReviews-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Typography
                sx={{
                  ml: 4,
                }}
                alignContent={"center"}
              >
                Independent Reviews
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpdateIndependentReviews}
                disabled={!independentReviewsHasChanges}
              >
                Save
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              columnGap: theme.spacing(3),
              p: 2,
              pb: 5,
              m: theme.spacing(0, 3.5, 2, 3.5),
            })}
          >
            <Typography variant={"h6"} sx={{ gridColumn: "1/4", my: 2 }}>
              Existing Reviews
            </Typography>
            {(() => {
              const rows =
                formData.instrumentMarketingContent.independentReviews?.map(
                  (review, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          my: 1,
                        }}
                        boxShadow={1}
                        key={idx}
                      >
                        <Box
                          sx={{
                            width: "95%",
                            my: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: 5,
                            }}
                          >
                            <TextField
                              sx={{ width: "30%" }}
                              label={"Title"}
                              readOnly
                              value={review.title}
                            />
                            <TextField
                              sx={{ width: "20%" }}
                              label={"Date"}
                              readOnly
                              value={review.date}
                            />
                            <TextField
                              sx={{
                                input: {
                                  cursor: "pointer",
                                },
                              }}
                              fullWidth
                              label={"Review Link"}
                              readOnly
                              value={review.url}
                              onClick={() => {
                                window.open(review.url);
                              }}
                            />
                            <Tooltip
                              disableFocusListener
                              disableTouchListener
                              title={review.summary}
                            >
                              <span style={{ width: "100%" }}>
                                <TextField
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    },
                                  }}
                                  fullWidth
                                  label={"Review Summary"}
                                  readOnly
                                  value={review.summary}
                                />
                              </span>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: 5,
                            }}
                          >
                            <TextField
                              sx={{
                                width: "20%",
                              }}
                              label={"Reviewer Name"}
                              readOnly
                              value={review.reviewerDetails.name}
                            />
                            <TextField
                              sx={{
                                width: "70%",
                              }}
                              label={"Reviewer Bio"}
                              readOnly
                              value={review.reviewerDetails.bio}
                            />
                            <Avatar
                              sx={{
                                width: "50px",
                                height: "50px",
                              }}
                            >
                              <img
                                alt=""
                                src={review.reviewerDetails.profilePicture.url}
                              />
                            </Avatar>
                          </Box>
                        </Box>
                        <Box alignContent={"start"} justifyContent={"center"}>
                          <IconButton
                            disabled={readOnly}
                            aria-label="delete"
                            size="large"
                            onClick={() => {
                              const reviews =
                                formData.instrumentMarketingContent
                                  .independentReviews ?? [];
                              reviews.splice(idx, 1);
                              formDataUpdate.independentReviews([...reviews]);
                              setIndependentReviewsHasChanges(
                                formData.instrumentMarketingContent
                                  .independentReviews !==
                                  currentMarketingContent.independentReviews,
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  },
                );

              return rows?.length != 0 && rows != undefined ? (
                rows
              ) : (
                <Typography variant="h6" sx={{ my: 4 }} textAlign={"center"}>
                  No Independent Reviews{" "}
                </Typography>
              );
            })()}

            <Divider />
            {readOnly ? null : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                    my: 2,
                  }}
                >
                  <Typography variant={"h6"} sx={{ my: 2 }}>
                    Independent Reviews
                  </Typography>
                  <Button
                    disabled={
                      currentIndependentReview.title.length == 0 ||
                      (reviewerProfilePictureUploadPercentage > 0 &&
                        reviewerProfilePictureUploadPercentage < 100)
                    }
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      formDataUpdate.independentReviews([
                        ...formData.instrumentMarketingContent
                          .independentReviews,
                        currentIndependentReview,
                      ]);
                      setIndependentReviewsHasChanges(true);
                      setCurrentIndependentReview(new IndependentReview());
                      setReviewerProfilePictureUploadPercentage(0);
                    }}
                  >
                    Add Review
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      fullWidth
                      label={"Title"}
                      value={currentIndependentReview.title}
                      onChange={(e) => {
                        setCurrentIndependentReview({
                          ...currentIndependentReview,
                          title: e.target.value,
                        });
                      }}
                    />
                    <DateField
                      label="Date"
                      id="instrumentMarketingContent-currentIndependentReview-dateField"
                      onChange={(newDate) => {
                        setCurrentIndependentReview({
                          ...currentIndependentReview,
                          date: dayjs(newDate),
                        });
                      }}
                      value={currentIndependentReview.date.format("YYYY-MM-DD")}
                      maxDate={dayjs().add(1, "day")}
                    />
                    <TextField
                      fullWidth
                      label={"Url"}
                      value={currentIndependentReview.url}
                      onChange={(e) => {
                        setCurrentIndependentReview({
                          ...currentIndependentReview,
                          url: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    label="Summary"
                    multiline
                    rows={6}
                    sx={{
                      display: "flex",
                      height: "100%",
                    }}
                    value={currentIndependentReview.summary}
                    onChange={(e) => {
                      setCurrentIndependentReview({
                        ...currentIndependentReview,
                        summary: e.target.value,
                      });
                    }}
                    error={
                      !!formDataValidationResult.fieldValidations
                        .independentReview
                    }
                    helperText={
                      formDataValidationResult.fieldValidations
                        .independentReview
                    }
                  />
                </Box>
                <Box
                  sx={{ width: "100%" }}
                  display={"flex"}
                  gap={2}
                  alignContent={"center"}
                >
                  <TextField
                    sx={{
                      width: "30%",
                    }}
                    label={"Reviewer Name"}
                    value={currentIndependentReview.reviewerDetails.name}
                    onChange={(e) => {
                      setCurrentIndependentReview({
                        ...currentIndependentReview,
                        reviewerDetails: {
                          ...currentIndependentReview.reviewerDetails,
                          name: e.target.value,
                        },
                      });
                    }}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    label={"Reviewer Bio"}
                    value={currentIndependentReview.reviewerDetails.bio}
                    onChange={(e) => {
                      setCurrentIndependentReview({
                        ...currentIndependentReview,
                        reviewerDetails: {
                          ...currentIndependentReview.reviewerDetails,
                          bio: e.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="file"
                    ref={reviewerImageInputRef}
                    onChange={handleReviewerImageChange}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <LoadingButton
                    loading={
                      reviewerProfilePictureUploadPercentage < 100 &&
                      reviewerProfilePictureUploadPercentage > 0
                    }
                    disabled={reviewerProfilePictureUploadPercentage == 100}
                    id="instrumentMarketingContent-upload-button"
                    variant="contained"
                    size="small"
                    sx={{
                      my: 1,
                      height: "36px",
                      backgroundColor: theme.palette.primary.main,
                    }}
                    onClick={handleReviewerImageUpload}
                  >
                    upload photo
                  </LoadingButton>
                </Box>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {/* ************************** MARKETING MEDIA SECTION UI ************************** */}
        <Accordion
          expanded={expanded === "marketingMedia" || expanded === true}
          onChange={handleAccordionChange("marketingMedia")}
        >
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="marketingContent-marketingMedia-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Typography
                sx={{
                  ml: 4,
                }}
                variant={"h6"}
                alignContent={"center"}
              >
                Marketing Media
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpdateMarketingMedia}
                disabled={!marketingMediaHasChanges}
              >
                Save
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              columnGap: theme.spacing(3),
              p: 2,
              pb: 5,
              m: theme.spacing(0, 3.5, 2, 3.5),
            })}
          >
            <Typography variant={"h6"} sx={{ gridColumn: "1/4", my: 2 }}>
              Existing Media
            </Typography>
            {(() => {
              const marketMediaWithId =
                formData.instrumentMarketingContent.marketMedia?.map(
                  (media, idx) => ({
                    id: idx,
                    chosen: true,
                    ...media,
                  }),
                );
              if (
                marketMediaWithId == undefined ||
                marketMediaWithId.length == 0
              ) {
                <Typography variant="h6" sx={{ my: 4 }} textAlign={"center"}>
                  No Marketing Media
                </Typography>;
              }

              return (
                <ReactSortable
                  animation={200}
                  easing="ease-out"
                  disabled={readOnly}
                  list={marketingMediaList ?? []}
                  setList={setMarketingMediaList}
                  chosenClass={"meshDragChosen"}
                  ghostClass={"meshDragGhost"}
                  onChange={() => {
                    // Get rid of the id field required by ReactSortable
                    formDataUpdate.marketingMedia(
                      marketingMediaList?.map((v) => ({
                        type: v.type,
                        url: v.url,
                        thumbnailImage: v.thumbnailImage,
                      })),
                    );
                    setMarketingMediaHasChanges(true);
                  }}
                >
                  {marketingMediaList?.map((media, idx) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: 1,
                        my: 1,
                        cursor: "grab",
                      }}
                      key={idx}
                      boxShadow={1}
                    >
                      <Box
                        sx={{
                          width: "95%",
                          alignItems: "center",
                          alignContent: "center",
                          my: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <TextField
                            label={"Media Type"}
                            readOnly
                            value={media.type}
                          />
                          <TextField
                            sx={{
                              width: "60%",
                            }}
                            label={"Media Url"}
                            readOnly
                            value={media.url}
                          />
                          <Avatar
                            sx={{
                              width: "50px",
                              height: "50px",
                            }}
                          >
                            <img
                              width={"100%"}
                              src={media.thumbnailImage.url}
                            />
                          </Avatar>
                        </Box>
                      </Box>
                      <Box alignContent={"center"} justifyContent={"center"}>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          disabled={
                            readOnly ||
                            formData.instrumentMarketingContent.marketMedia ==
                              undefined ||
                            formData.instrumentMarketingContent.marketMedia
                              ?.length == 0
                          }
                          onClick={() => {
                            const media =
                              formData.instrumentMarketingContent.marketMedia ??
                              [];
                            media.splice(idx, 1);
                            if (media.length != 0) {
                              formDataUpdate.marketingMedia([...media]);
                              setMarketingMediaList(
                                media.map((v, k) => ({
                                  id: k,
                                  ...v,
                                })),
                              );
                            } else {
                              formDataUpdate.marketingMedia([]);
                              setMarketingMediaList([]);
                            }

                            setMarketingMediaHasChanges(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </ReactSortable>
              );
            })()}

            <Divider />
            {readOnly ? null : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                    my: 2,
                  }}
                >
                  <Typography variant={"h6"} sx={{ my: 2 }}>
                    Marketing Media
                  </Typography>
                  <Button
                    disabled={currentMarketingMedia.url.length == 0}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      if (
                        formData.instrumentMarketingContent.marketMedia ==
                        undefined
                      ) {
                        formDataUpdate.marketingMedia([currentMarketingMedia]);
                      } else {
                        formDataUpdate.marketingMedia([
                          ...formData.instrumentMarketingContent.marketMedia,
                          currentMarketingMedia,
                        ]);
                      }
                      setMarketingMediaHasChanges(true);
                      setCurrentMarketingMedia(
                        new MarketingMedia({
                          type: MediaType.Image,
                          url: "",
                          thumbnailImage: new MediaImage(),
                        }),
                      );
                      setMarketingMediaUploadPercentage(0);
                      setMediaThumbnailImageUploadPercentage(0);
                    }}
                  >
                    Add Media
                  </Button>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    gap={2}
                    sx={{
                      alignitems: "center",
                    }}
                  >
                    <input
                      type="file"
                      ref={marketingMediaInputRef}
                      onChange={handleMarketingMediaChange}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    <LoadingButton
                      loading={
                        marketingMediaUploadPercentage < 100 &&
                        marketingMediaUploadPercentage > 0
                      }
                      disabled={
                        marketingMediaUploadPercentage == 100 ||
                        currentMarketingMedia.type != MediaType.Image
                      }
                      id="instrumentMarketingContent-marketingMedia-button"
                      variant="contained"
                      size="medium"
                      sx={{
                        my: 1,
                        height: "36px",
                        minWidth: "200px",
                        backgroundColor: theme.palette.primary.main,
                      }}
                      onClick={handleMarketingMediaUpload}
                    >
                      Upload Image
                    </LoadingButton>
                    <Typography sx={{ alignContent: "center" }}>OR</Typography>
                    <TextField
                      disabled={marketingMediaUploadPercentage > 0}
                      sx={{
                        width: "50%",
                      }}
                      label={"Add video link"}
                      value={
                        currentMarketingMedia.type == MediaType.EmbeddedVideo
                          ? currentMarketingMedia.url
                          : ""
                      }
                      onChange={(e) => {
                        const { embedUrl, thumbnailUrl } = extractVideoLinks(
                          e.target.value,
                        );
                        let thumbnailImageUrl = thumbnailUrl;

                        if (embedUrl.length != 0) {
                          if (currentMarketingMedia.thumbnailImage.url != "") {
                            thumbnailImageUrl =
                              currentMarketingMedia.thumbnailImage.url;
                          }
                          setCurrentMarketingMedia({
                            ...currentMarketingMedia,
                            type: MediaType.EmbeddedVideo,
                            url: embedUrl,
                            thumbnailImage: new MediaImage({
                              id: "",
                              name: "",
                              type: MediaType.Image,
                              url: thumbnailImageUrl,
                              metaData: {} as Record<string, string>,
                            }),
                          });
                        } else {
                          setCurrentMarketingMedia({
                            ...currentMarketingMedia,
                            type: MediaType.Image,
                            url: embedUrl,
                            thumbnailImage: new MediaImage({
                              id: "",
                              name: "",
                              type: MediaType.Image,
                              url: "",
                              metaData: {} as Record<string, string>,
                            }),
                          });
                        }
                      }}
                    />
                    <Tooltip
                      sx={{
                        input: {
                          cursor: "pointer",
                        },
                      }}
                      disableFocusListener
                      disableTouchListener
                      title={currentMarketingMedia.url ?? ""}
                    >
                      <span style={{ width: "100%" }}>
                        <TextField
                          sx={{
                            width: "100%",
                            input: {
                              cursor: "pointer",
                            },
                            "& .MuiInputBase-input": {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          }}
                          readOnly
                          value={currentMarketingMedia.url ?? ""}
                          label="Embedded Url"
                          onClick={() => {
                            currentMarketingMedia.url
                              ? window.open(currentMarketingMedia.url)
                              : null;
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={2}
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      ref={mediaThumbnailImageInputRef}
                      onChange={handleMediaThumbnailImageChange}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    <LoadingButton
                      loading={
                        mediaThumbnailImageUploadPercentage < 100 &&
                        mediaThumbnailImageUploadPercentage > 0
                      }
                      disabled={mediaThumbnailImageUploadPercentage == 100}
                      id="instrumentMarketingContent-mediaThumbnailImage-button"
                      variant="contained"
                      size="medium"
                      sx={{
                        my: 1,
                        height: "36px",
                        minWidth: "200px",
                        backgroundColor: theme.palette.primary.main,
                      }}
                      onClick={handleMediaThumbnailImageUpload}
                    >
                      upload thumbnail
                    </LoadingButton>
                    <Tooltip
                      sx={{
                        input: {
                          cursor: "pointer",
                        },
                      }}
                      disableFocusListener
                      disableTouchListener
                      title={currentMarketingMedia.thumbnailImage?.url ?? ""}
                    >
                      <span style={{ width: "100%" }}>
                        <TextField
                          sx={{
                            width: "50%",
                            input: {
                              cursor: "pointer",
                            },
                            "& .MuiInputBase-input": {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          }}
                          readOnly
                          value={
                            currentMarketingMedia.thumbnailImage?.url ?? ""
                          }
                          onClick={() => {
                            currentMarketingMedia.thumbnailImage?.url
                              ? window.open(
                                  currentMarketingMedia.thumbnailImage.url,
                                )
                              : null;
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
