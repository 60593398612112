export enum Sector {
  AutomobilesAndParts = "Automobiles and Parts",
  Banks = "Banks",
  CashAndCashEquivalents = "Cash and Cash Equivalents",
  Chemicals = "Chemicals",
  CommunicationSector = "Communication",
  ConsumerDiscretionary = "Consumer Discretionary",
  ConsumerProductsAndServices = "Consumer Products and Services",
  ConsumerStaples = "Consumer Staples",
  Debt = "Debt",
  Energy = "Energy",
  FinancialServices = "Financial Services",
  Financials = "Financials",
  FoodBeverageAndTobacco = "Food, Beverage and Tobacco",
  HealthCare = "Health Care",
  IndustrialGoodsAndServices = "Industrial Goods and Services",
  Industrials = "Industrials",
  InformationTechnology = "Information Technology",
  Insurance = "Insurance",
  Materials = "Materials",
  Media = "Media",
  PersonalCareDrugAndGroceryStores = "Personal Care, Drug and Grocery Stores",
  RealEstate = "Real Estate",
  Retail = "Retail",
  Technology = "Technology",
  TelecommunicationServices = "Telecommunication Services",
  TravelAndLeisure = "Travel and Leisure",
  Utilities = "Utilities",
}

export const AllSectors: Sector[] = [
  Sector.AutomobilesAndParts,
  Sector.Banks,
  Sector.CashAndCashEquivalents,
  Sector.Chemicals,
  Sector.CommunicationSector,
  Sector.ConsumerDiscretionary,
  Sector.ConsumerProductsAndServices,
  Sector.ConsumerStaples,
  Sector.Debt,
  Sector.Energy,
  Sector.FinancialServices,
  Sector.Financials,
  Sector.FoodBeverageAndTobacco,
  Sector.HealthCare,
  Sector.IndustrialGoodsAndServices,
  Sector.Industrials,
  Sector.InformationTechnology,
  Sector.Insurance,
  Sector.Materials,
  Sector.Media,
  Sector.PersonalCareDrugAndGroceryStores,
  Sector.RealEstate,
  Sector.Retail,
  Sector.Technology,
  Sector.TelecommunicationServices,
  Sector.TravelAndLeisure,
  Sector.Utilities,
];
