import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Add as AddUserIcon,
  CancelRounded,
  HighlightOffOutlined as RemoveIcon,
} from "@mui/icons-material";
import { BPTable } from "components/Table";
import { Role } from "james/role";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import { NewSorting, Query } from "james/search/query";
import { UserManagerWrite } from "james/user";
import {
  Compository,
  SearchGroupUsersInGroupResponse,
} from "james/user/Compository";
import { GroupUser } from "james/user/GroupUser";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";
import { AddOrInviteUsersDialog } from "./AddOrInviteUsersDialog";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "UsersTable";

const classes = {
  groupUserAvatarLayout: `${PREFIX}-groupUserAvatarLayout`,
  groupUserAvatar: `${PREFIX}-groupUserAvatar`,
  usersGroupRolesSelect: `${PREFIX}-usersGroupRolesSelect`,
  groupUserRoleChip: `${PREFIX}-groupUserRoleChip`,
  progressWrapper: `${PREFIX}-progressWrapper`,
  warningDialogTitleLayout: `${PREFIX}-warningDialogTitleLayout`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.groupUserAvatarLayout}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.groupUserAvatar}`]: {
    marginRight: "6px",
    width: "23px",
    height: "23px",
    fontSize: "0.90rem",
  },

  [`& .${classes.usersGroupRolesSelect}`]: {
    width: 500,
  },

  [`& .${classes.groupUserRoleChip}`]: {
    margin: theme.spacing(0.5),
  },

  [`& .${classes.progressWrapper}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.warningDialogTitleLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(2),
    alignItems: "center",
  },
}));

export interface TableProps {
  groupID: string;
  groupRoles: Role[];
  refreshToggle?: boolean;
}

let fetchDataTimeout: NodeJS.Timeout;

const initialQuery = new Query({
  limit: 5,
  offset: 0,
  sorting: [NewSorting("fullName", "asc")],
});

export function UsersTable(props: TableProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { refreshToggle } = props;

  const [refreshTable, setRefreshTable] = useState<boolean>(!!refreshToggle);
  const [searchGroupUsersResponse, setSearchGroupUsersResponse] =
    useState<SearchGroupUsersInGroupResponse>({
      records: [],
      total: 0,
    });
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const [rolesForSearchCriteria, setRolesForSearchCriteria] = useState<Role[]>(
    [],
  );
  const [fullNameSearchCriteria, setFullNameSearchCriteria] = useState("");
  const [selectedGroupUsers, setSelectedGroupUsers] = useState<GroupUser[]>([]);
  const [addUsersToGroupDialogOpen, setAddUsersToGroupDialogOpen] =
    useState(false);
  const [removeSelectedConfirmDialogOpen, setRemoveSelectedConfirmDialogOpen] =
    useState(false);
  const [lastRoleRemovalPair, setLastRoleRemovalPair] = useState<
    { user: GroupUser; role: Role } | undefined
  >(undefined);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [criteria, setCriteria] = React.useState<any>({});

  const [usersBeingModified, setUsersBeingModified] = useState<{
    [key: string]: boolean;
  }>({});
  const handleRemoveRoleFromUser =
    (user: GroupUser, role: Role) => async () => {
      // if this is the last role that this group user has
      if (user.roles.length === 1) {
        // and if a lastRoleRemovalPair is not yet set
        if (!lastRoleRemovalPair) {
          // set one and return
          setLastRoleRemovalPair({
            user,
            role,
          });
          return;
        }
      }
      // otherwise remove the role regardless
      setLastRoleRemovalPair(undefined);

      // perform role removal
      setUsersBeingModified((cur) => ({
        ...cur,
        [user.id]: true,
      }));
      try {
        await UserManagerWrite.RemoveRolesFromUser({
          context: authContext,
          roleIDs: [role.id],
          userID: user.id,
        });
        enqueueSnackbar(`${role.name} removed from ${user.fullName}`, {
          variant: "success",
        });
        setRefreshTable(!refreshTable);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error("error removing role from user:", e);
        enqueueSnackbar(
          `error removing role from ${user.fullName}: ${err.message}`,
          { variant: "error" },
        );
      }
      setUsersBeingModified((cur) => {
        delete cur[user.id];
        return { ...cur };
      });
    };

  const handleAddRoleToUser =
    (user: GroupUser) =>
    async (
      _: React.SyntheticEvent<Element, Event>,
      usersUpdatedRoles: Role[],
    ) => {
      try {
        const rolesToAddToUser = usersUpdatedRoles.filter(
          (potentialNewRole) =>
            !user.roles.find(
              (existingRole) => existingRole.id === potentialNewRole.id,
            ),
        );
        if (!rolesToAddToUser.length) {
          // no roles to add to user
          return;
        }

        setUsersBeingModified((cur) => ({
          ...cur,
          [user.id]: true,
        }));

        await UserManagerWrite.AssignRolesToUser({
          context: authContext,
          roleIDs: rolesToAddToUser.map((r) => r.id),
          userID: user.id,
        });
        enqueueSnackbar(
          `${rolesToAddToUser.map((r) => r.name).join(", ")} added to ${
            user.fullName
          }`,
          { variant: "success" },
        );
        setRefreshTable(!refreshTable);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error("error adding role to user:", e);
        enqueueSnackbar(
          `error adding role/s to ${user.fullName}: ${err.message}`,
          { variant: "error" },
        );
      }

      setUsersBeingModified((cur) => {
        delete cur[user.id];
        return { ...cur };
      });
    };

  const [removingUsersFromGroup, setRemovingUsersFromGroup] = useState(false);
  const handleRemoveSelectedUsersFromGroup = async () => {
    setRemovingUsersFromGroup(true);
    try {
      // perform removals
      await Promise.all(
        selectedGroupUsers.map((gu) =>
          (async () => {
            await UserManagerWrite.RemoveRolesFromUser({
              context: authContext,
              roleIDs: gu.roles.map((r) => r.id),
              userID: gu.id,
            });
          })(),
        ),
      );

      enqueueSnackbar(
        `removed ${selectedGroupUsers.length} user/s from group`,
        { variant: "success" },
      );
      setRefreshTable(!refreshTable);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error("error removing user/s from group:", e);
      enqueueSnackbar(`error removing user/s from group: ${err.message}`, {
        variant: "error",
      });
    }
    setRemoveSelectedConfirmDialogOpen(false);
    setRemovingUsersFromGroup(false);
  };

  const handleFullNameSearchCriteriaChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (Object.keys(usersBeingModified).length) {
      // disable while role add/remove in progress
      return;
    }

    setFullNameSearchCriteria(event.target.value);

    if (event.target.value === "") {
      // if value is clear fullName criteria should be removed
      delete criteria.fullName;
      setCriteria({ ...criteria });
      setQuery(new Query(initialQuery));
      return;
    }

    // otherwise set criteria
    setCriteria({
      ...criteria,
      fullName: TextSubstringCriterion(event.target.value),
    });
    setQuery(new Query(initialQuery));
  };
  const handleChangeRolesForSearchCriteria = (
    _: React.SyntheticEvent<Element, Event>,
    selectedRoles: Role[],
  ) => {
    if (Object.keys(usersBeingModified).length) {
      // disable while role add/remove in progress
      return;
    }

    setRolesForSearchCriteria(selectedRoles);
    if (selectedRoles.length) {
      // if there are any selected roles to filter on
      // update the criteria to search with these roles
      setCriteria({
        ...criteria,
        "roles.id": TextListCriterion(selectedRoles.map((r) => r.id)),
      });
      setQuery(new Query(initialQuery));
    } else if (criteria["roles.id"]) {
      // otherwise remove the roles.id key if there is one
      delete criteria["roles.id"];
      setCriteria({ ...criteria });
      setQuery(new Query(initialQuery));
    }
  };
  const handleRemoveRoleForSearchCriteria = (roleToRemove: Role) => () => {
    if (Object.keys(usersBeingModified).length) {
      // disable while role add/remove in progress
      return;
    }

    // filter out the role that needs to be removed from the search criteria
    const updatedRolesForSearchCriteria = rolesForSearchCriteria.filter(
      (r) => r.id !== roleToRemove.id,
    );
    setRolesForSearchCriteria(updatedRolesForSearchCriteria);

    // update the search criteria
    if (updatedRolesForSearchCriteria.length) {
      // if there are any selected roles to filter on
      // update the criteria to search with these roles
      setCriteria({
        ...criteria,
        "roles.id": TextListCriterion(
          updatedRolesForSearchCriteria.map((r) => r.id),
        ),
      });
      setQuery(new Query(initialQuery));
    } else if (criteria["roles.id"]) {
      // otherwise remove the roles.id key if there is one
      delete criteria["roles.id"];
      setCriteria({ ...criteria });
      setQuery(new Query(initialQuery));
    }
  };
  const handleClearCriteria = () => {
    if (Object.keys(usersBeingModified).length) {
      // disable while role add/remove in progress
      return;
    }

    if (!Object.keys(criteria).length) {
      return;
    }
    setCriteria({});
    setQuery(new Query(initialQuery));
    setRolesForSearchCriteria([]);
    setFullNameSearchCriteria("");
  };

  const [fetchLoading, setFetchLoading] = React.useState(false);
  const [query, setQuery] = useState(new Query(initialQuery));
  useEffect(() => {
    setFetchLoading(true);
    const fetchData = async () => {
      try {
        setSelectedGroupUsers([]);
        setSearchGroupUsersResponse(
          await Compository.SearchGroupUsersInGroup({
            context: authContext,
            criteria,
            query,
            groupID: props.groupID,
          }),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error("error searching for group users: ", e);
        enqueueSnackbar(
          `error searching for group users: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setFetchLoading(false);
    };
    clearTimeout(fetchDataTimeout);
    fetchDataTimeout = setTimeout(fetchData, 400);
  }, [
    props.groupID,
    authContext,
    criteria,
    query,
    refreshTable,
    enqueueSnackbar,
  ]);

  return (
    <Root>
      <BPTable
        loading={fetchLoading}
        query={query}
        onQueryChange={(newQuery) => setQuery(newQuery)}
        height={window.innerHeight - 370 - noticeBannerHeight}
        onFilterPanelClose={handleClearCriteria}
        onSelectedDataChange={(selectedData) =>
          setSelectedGroupUsers(selectedData as GroupUser[])
        }
        toolBarControls={(() => {
          if (Object.keys(usersBeingModified).length) {
            return [];
          }

          if (selectedGroupUsers.length === 0) {
            return [
              <Button
                id="groupUsersTable-addUser-button"
                variant="outlined"
                startIcon={<AddUserIcon />}
                onClick={() => setAddUsersToGroupDialogOpen(true)}
              >
                add users
              </Button>,
            ];
          }
          return [
            <Tooltip title="Remove Users From Group">
              <IconButton
                id="groupUsersTable-removeUser-button"
                size="small"
                onClick={() => setRemoveSelectedConfirmDialogOpen(true)}
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>,
          ];
        })()}
        columns={[
          {
            field: "fullName",
            label: "Name",
            /* eslint-disable @typescript-eslint/no-explicit-any */
            accessor: (data: { [key: string]: any }) => {
              const groupUser = data as GroupUser;
              return (
                <div className={classes.groupUserAvatarLayout}>
                  <Avatar
                    sizes="small"
                    color="primary"
                    className={classes.groupUserAvatar}
                  >
                    {groupUser.fullName[0]}
                  </Avatar>
                  <Typography variant="body1" color="inherit">
                    {groupUser.fullName}
                  </Typography>
                  {usersBeingModified[groupUser.id] && (
                    <div className={classes.progressWrapper}>
                      <CircularProgress size={15} />
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            field: "roles",
            label: "Roles",
            sortable: false,
            accessor: (data: { [key: string]: any }) => {
              const groupUser = data as GroupUser;
              return (
                <Autocomplete
                  id={`groupUsersTable-addRole-autocompleteFormfield-${groupUser.email}`}
                  onClick={(e) => e.stopPropagation()}
                  multiple
                  disabled={usersBeingModified[groupUser.id]}
                  options={props.groupRoles}
                  value={groupUser.roles}
                  getOptionLabel={(groupRole: Role) => groupRole.name}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) =>
                    value.id === option.id
                  }
                  renderOption={(ilProps, option) => (
                    <li id={option.id} {...ilProps}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      id={`groupUsersTable-addRole-textfield-${groupUser.email}`}
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      className={classes.usersGroupRolesSelect}
                      margin="dense"
                    />
                  )}
                  onChange={handleAddRoleToUser(groupUser)}
                  renderTags={(usersRoles: Role[]) =>
                    usersRoles.map((selectedRole, roleIdx) => (
                      <Chip
                        disabled={usersBeingModified[groupUser.id]}
                        id={`chip-${groupUser.email}-${selectedRole.name}`}
                        className={classes.groupUserRoleChip}
                        size="small"
                        key={roleIdx}
                        color="info"
                        label={selectedRole.name}
                        deleteIcon={
                          <CancelRounded
                            id={`removeIcon-${groupUser.email}-${selectedRole.name}`}
                            sx={(theme) => ({
                              color: `${theme.palette.text.secondary} !important`,
                              "&:hover": {
                                color: `${theme.palette.secondary.contrastText} !important`,
                              },
                            })}
                          />
                        }
                        onDelete={handleRemoveRoleFromUser(
                          groupUser,
                          selectedRole,
                        )}
                      />
                    ))
                  }
                />
              );
            },
          },
          {
            field: "email",
            label: "Email",
          },
          {
            field: "state",
            label: "Status",
          },
        ]}
        data={searchGroupUsersResponse.records}
        title="Group Users"
        totalNoRecords={searchGroupUsersResponse.total}
        filters={(() => {
          if (Object.keys(usersBeingModified).length) {
            return [];
          }

          return [
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Name"
              id="groupUsersTable-filterByName-formfield"
              margin="dense"
              variant="outlined"
              placeholder="Filter By Name"
              value={fullNameSearchCriteria}
              onChange={handleFullNameSearchCriteriaChange}
            />,
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              // prevent click event propagation to prevent row becoming selected
              // when roles are changed
              onClick={(e) => e.stopPropagation()}
              multiple
              id="groupUsersTable-filterByRoles-autocompleteFormfield"
              options={props.groupRoles}
              value={rolesForSearchCriteria}
              getOptionLabel={(groupRole: Role) => groupRole.name}
              filterSelectedOptions
              renderOption={(ilProps, option) => (
                <li id={option.name} {...props}>
                  {option.name}
                </li>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  id="groupUsersTable-filterByRoles-formField"
                  placeholder={
                    rolesForSearchCriteria.length
                      ? undefined
                      : "Filter By Roles"
                  }
                  label="Roles"
                  InputLabelProps={{ shrink: true }}
                  className={classes.usersGroupRolesSelect}
                  margin="dense"
                />
              )}
              onChange={handleChangeRolesForSearchCriteria}
              renderTags={(usersRoles: Role[]) =>
                usersRoles.map((selectedRole, roleIdx) => (
                  <Chip
                    size="small"
                    key={roleIdx}
                    color="secondary"
                    label={selectedRole.name}
                    onDelete={handleRemoveRoleForSearchCriteria(selectedRole)}
                  />
                ))
              }
            />,
          ];
        })()}
      />
      {addUsersToGroupDialogOpen && (
        <AddOrInviteUsersDialog
          groupID={props.groupID}
          closeDialog={() => setAddUsersToGroupDialogOpen(false)}
          onExistingUsersAddedToGroup={() => setRefreshTable(!refreshTable)}
        />
      )}
      <Dialog open={removeSelectedConfirmDialogOpen}>
        <DialogTitle>
          <div className={classes.warningDialogTitleLayout}>
            <Typography>Confirm user removal</Typography>
            {removingUsersFromGroup && <CircularProgress size={15} />}
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please confirm the removal of the selected user/users from this
            group. You will be able to add them back to the group by selecting
            add to group.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            id="groupUsersTable-userRemovalGoBack-button"
            variant="outlined"
            color="primary"
            onClick={() => setRemoveSelectedConfirmDialogOpen(false)}
            disabled={removingUsersFromGroup}
          >
            go back
          </Button>
          <Button
            id="groupUsersTable-userRemovalProceed-button"
            variant="contained"
            color="primary"
            disabled={removingUsersFromGroup}
            onClick={handleRemoveSelectedUsersFromGroup}
          >
            proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!lastRoleRemovalPair}>
        <DialogTitle>
          <Typography>Confirm user removal</Typography>
        </DialogTitle>
        <DialogContent>
          {lastRoleRemovalPair && (
            <Typography variant="body1">
              This is {lastRoleRemovalPair.user.fullName}
              &apos;s last role in this group. Removing it will remove them from
              the group. Please confirm.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id="groupUsersTable-lastUserRemovalGoBack-button"
            variant="outlined"
            color="primary"
            onClick={() => setLastRoleRemovalPair(undefined)}
          >
            go back
          </Button>
          {lastRoleRemovalPair && (
            <Button
              id="groupUsersTable-lastUserRemovalProceed-button"
              variant="contained"
              color="primary"
              onClick={handleRemoveRoleFromUser(
                lastRoleRemovalPair.user,
                lastRoleRemovalPair.role,
              )}
            >
              proceed
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Root>
  );
}
