import React, { forwardRef, useState } from "react";
import { Theme, styled } from "@mui/material/styles";
import { AssetDocumentsDataSheetSection } from "james/marketData";
import {
  Box,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Description, MoreVert } from "@mui/icons-material";
import { download } from "utilities/network/download";
import cx from "classnames";

const PREFIX = "AssetDocumentsDataSheetSectionRenderer";

const classes = {
  documentLine: `${PREFIX}-documentLine`,
  documentLineBottomBorder: `${PREFIX}-documentLineBottomBorder`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  documentNameDescription: `${PREFIX}-documentNameDescription`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.documentLine}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    gridColumnGap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  [`& .${classes.documentLineBottomBorder}`]: {
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },

  [`& .${classes.iconWrapper}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.documentNameDescription}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export interface AssetDocumentsProps {
  dataSheetSection: AssetDocumentsDataSheetSection;
}

export const AssetDocumentsDataSheetSectionRenderer = forwardRef(
  (props: AssetDocumentsProps, ref) => {
    const [anchorEls, setAnchorEls] = useState<Array<HTMLElement | null>>(
      Array.from(
        { length: props.dataSheetSection.documents.length },
        () => null,
      ),
    );

    const vertOnClick = (
      e: React.MouseEvent<HTMLButtonElement>,
      idx: number,
    ) => {
      const newAnchorEls = [...anchorEls];
      newAnchorEls[idx] = e.currentTarget;
      setAnchorEls(newAnchorEls);
    };

    const menuOnClose = (idx: number) => {
      const newAnchorEls = [...anchorEls];
      newAnchorEls[idx] = null;
      setAnchorEls(newAnchorEls);
    };

    const smDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm"),
    );

    return (
      <StyledBox
        ref={ref}
        sx={(theme) => ({
          margin: theme.spacing(3, 3, 3, 0),
        })}
      >
        {" "}
        {!smDown && (
          <Box>
            <Typography sx={{ ml: 5, mb: 2 }}>Description</Typography>
            <Divider></Divider>
          </Box>
        )}
        {props.dataSheetSection.documents.map((v, idx) => (
          <div
            key={idx}
            className={cx(classes.documentLine, {
              [classes.documentLineBottomBorder]:
                idx !== props.dataSheetSection.documents.length - 1,
            })}
          >
            <div className={classes.iconWrapper}>
              <Description color="disabled" />
            </div>
            <div className={classes.documentNameDescription}>
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  wordBreak: "break-word",
                  mt: 2,
                }}
                color="textPrimary"
                variant="body2"
              >
                {v.description}
              </Typography>
              <Link
                onClick={() => {
                  window.open(v.downloadURL, v.name);
                }}
                underline="hover"
              >
                <Typography
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    wordBreak: "break-word",
                    mb: 2,
                  }}
                  variant="body2"
                >
                  {v.name}
                </Typography>
              </Link>
            </div>
            <div className={classes.iconWrapper}>
              <IconButton
                id={`AssetDocument-DocOption-button-${idx}`}
                onClick={(e) => vertOnClick(e, idx)}
                size={"small"}
              >
                <MoreVert />
              </IconButton>
              <Menu
                open={!!anchorEls[idx]}
                anchorEl={anchorEls[idx]}
                id={`AsssetDocuments-Menu-${idx}`}
                onClose={() => menuOnClose(idx)}
                autoFocus={false}
              >
                <MenuItem
                  id={`AsssetDocuments-view-MenuItem-${idx}`}
                  onClick={() => {
                    window.open(v.downloadURL, v.name);
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  id={`AsssetDocuments-download-MenuItem-${idx}`}
                  onClick={() => {
                    download(v.downloadURL, v.name);
                  }}
                >
                  Download
                </MenuItem>
              </Menu>
            </div>
          </div>
        ))}
      </StyledBox>
    );
  },
);
