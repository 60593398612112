import { PageDetails, PageType } from "types/gtm";
import React, { useEffect } from "react";
import { pageDevice } from "const/gtm";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";

type GtmPageDetailsContainerProps = {
  pageType: PageType;
  children?: React.ReactNode;
};

export const GTMPageLevelTaggingContainer = (
  props: GtmPageDetailsContainerProps,
) => {
  const { pushPageViewEvent } = useGTMTriggersPusher();

  useEffect(() => {
    // Check the user agent string for 'Mobile' to determine the device type
    const pageDeviceType = /Mobile/i.test(navigator.userAgent)
      ? pageDevice.mobile
      : pageDevice.desktop;

    //
    // The below code constructs the pagename and pagesection for GTM. By deconstructing
    // the URL's path into segment.
    //
    let pagename = "";
    let pagesection = "";
    const pathArray = window.location.pathname.split("/");
    for (let i = 0; i < pathArray.length; i++) {
      if (i == 0 || i == pathArray.length) {
        pagename = pathArray[i];
      }
      if (i > 0 && i < pathArray.length) {
        if (i == 2) {
          pagesection = pathArray[i];
        }
        if (pathArray[i] !== "") {
          pagename += ":" + pathArray[i];
        }
      }
    }

    pagename = pagename.substring(1, pagename.length);
    if (pagename == "") {
      // set to home
      pagename = "home";
    }
    if (pagesection == "") {
      // set to home
      pagesection = "home";
    }

    pushPageViewEvent({
      page_name: pagename,
      page_section: pagesection,
      page_type: props.pageType,
      page_device_type: pageDeviceType,
      page_url: window.location.href,
      page_title: document.title,
    } as PageDetails);
  }, []);
  return <React.Fragment>{props.children}</React.Fragment>;
};
