import React, { useEffect, useState } from "react";
import {
  Model as MarketSpotViewModel,
  Reader,
  useRead as useMarketSpotViewReaderRead,
} from "james/views/marketSpotView";
import { NewSorting, Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Collapse,
  IconButton,
  Skeleton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";
import { Amount } from "components/Ledger/Amount";
import {
  Cancel as CancelIcon,
  DeleteForever as ClearFiltersIcon,
  Error as ErrorIcon,
  FaceOutlined as FaceIcon,
  FilterList as FilterIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { SpotStateChip } from "./Chips";
import { AllSpotStates, SpotState } from "james/market";
import {
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { useIsMounted } from "hooks";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DateField } from "components/FormFields";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import { AllStellarNetworks } from "james/stellar";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { InfiniteScrollList } from "components/Table/InfCardTable";
import { useWindowSize } from "utilities/general";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

const defaultTokenCriteria = {
  "token.network": TextListCriterion(AllStellarNetworks),
  tokenCategory: TextListCriterion([
    TokenCategory.CryptoCurrency,
    TokenCategory.RightsToFiatCurrency,
    TokenCategory.RightsToCryptoCurrency,
  ]),
};

export const Trades = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Helmet>
        <title>Mesh | Trades</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="View all your spot trades in one place, a list of your successful and your unsuccessful trades"
        />
      </Helmet>
      {isMobile ? <MobileTradeTable /> : <DesktopTradeTable />}
    </>
  );
};

const MobileTradeTable = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const [models, setModels] = useState<MarketSpotViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { authContext } = useApplicationContext();
  const [, windowHeight] = useWindowSize();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [tradeNumber, setTradeNumber] = useState("");
  const [dateTo, setDateTo] = useState<string | undefined>(undefined);
  const [dateFrom, setDateFrom] = useState<string | undefined>(undefined);
  const [state, setState] = useState<SpotState[]>([]);
  const [assetToken, setAssetToken] = useState<TokenViewModel | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { NotificationBannerHeight } = useAppNoticeContext();
  const {
    readResponse: ledgerTokenViewReadResponse,
    readRequest: ledgerTokenViewReadRequest,
    setReadRequest: setLedgerTokenViewReadRequest,
    loading: ledgerTokenViewReadLoading,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 100,
    }),
    criteria: defaultTokenCriteria,
  });

  const loadMoreItems =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (criteria: any, query: Query, updateOffset: () => void) => async () => {
      setLoading(true);

      try {
        const response = await Reader.Read({
          context: authContext,
          query,
          criteria,
        });

        updateOffset();

        setModels([...models, ...response.models]);

        setTotal(response.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading market spot view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error reading market spot view models:: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      setLoading(false);
    };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(1, 2, 0, 2),
        width: "100%",
      }}
    >
      <InfiniteScrollList
        total={total}
        data={models}
        isLoading={loading}
        clearData={() => setModels([])}
        loadMoreData={loadMoreItems}
        rowWidth={"auto"}
        initialSorting={initialQuery.sorting}
        listHeight={
          // 75: mobile nav bar
          // 56: main app bar
          // 16: padding
          // 260.5: filter panel & co
          openCollapse
            ? windowHeight -
              75 -
              56 -
              45 -
              16 -
              260.5 -
              48 -
              NotificationBannerHeight
            : windowHeight - 75 - 56 - 45 - 16 - 48 - NotificationBannerHeight
        }
        rowHeight={232}
        batchSize={4}
        threshold={0}
        rowGap={16}
        disableMeshScroll={true}
        hideScrollbars={true}
        lastRowPadding={24}
        renderLoadingRow={
          <Box
            sx={{
              height: "100%",
              borderRadius: "10px",
              backgroundColor: (theme) => theme.palette.custom.midnight,
              gridTemplateRows: "auto 0px",
              alignContent: "center",
              width: "100%",
              display: "grid",
              padding: (theme) => theme.spacing(1.8, 2, 1.8, 2),
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: (theme) => theme.spacing(1.8),
                gridGap: (theme) => theme.spacing(1.8),
                flexDirection: "column",
              }}
            >
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={115}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={91}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={79}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={119}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={91}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={83}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={104}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={139}
                height={14}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: (theme) => theme.spacing(1.8),
                gridGap: (theme) => theme.spacing(1.8),
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={86}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={56}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={64}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={86}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={100}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={56}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={59}
                height={14}
              />
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={116}
                height={14}
              />
            </Box>
          </Box>
        }
        renderData={(data) => {
          return (
            <Box
              sx={{
                height: "100%",
                maxHeight: "232px",
                borderRadius: "10px",
                backgroundColor: (theme) => theme.palette.custom.midnight,
                gridTemplateRows: "auto 0px",
                alignContent: "center",
                width: "100%",
                display: "grid",
                padding: (theme) => theme.spacing(1.8, 2, 1.8, 2),
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: (theme) => theme.spacing(1),
                  gridGap: (theme) => theme.spacing(1),
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "75px",
                }}
              >
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  Trade No.
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  Date
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  Received
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  Paid
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  Price
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  Fees
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  From Acc.
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={() => ({
                    color: (theme) => theme.palette.text.disabled,
                  })}
                >
                  State
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: (theme) => theme.spacing(1),
                  gridGap: (theme) => theme.spacing(1),
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  width: "calc(100% - 75px -16px)",
                }}
              >
                <Typography align={"right"} variant={"body1"}>
                  {data.number}
                </Typography>

                <Typography align={"right"} variant={"body1"}>
                  {dayjs(data.submittedAt).format("YYYY-MM-DD")}
                </Typography>

                {data.receivedAmount.isUndefined() ? (
                  <Typography align={"right"} variant={"body1"}>
                    -
                  </Typography>
                ) : (
                  <Amount
                    amount={data.receivedAmount}
                    formatTextNumOpts={{
                      noDecimalPlaces: 7,
                      addDecimalPadding: false,
                    }}
                  />
                )}
                {data.paidAmount.isUndefined() ? (
                  <Typography
                    align={"right"}
                    sx={{ textAlign: "end" }}
                    variant={"body1"}
                  >
                    -
                  </Typography>
                ) : (
                  <Amount
                    amount={data.paidAmount}
                    formatTextNumOpts={{
                      noDecimalPlaces: 7,
                      addDecimalPadding: false,
                    }}
                  />
                )}

                {data.price.isUndefined() ? (
                  <Typography
                    align={"right"}
                    sx={{ textAlign: "end" }}
                    variant={"body1"}
                  >
                    -
                  </Typography>
                ) : (
                  <Amount
                    amount={data.price}
                    formatTextNumOpts={{
                      noDecimalPlaces: 7,
                      addDecimalPadding: false,
                    }}
                  />
                )}

                {data.feeAmount.isUndefined() ? (
                  <Typography align={"right"} variant={"body1"}>
                    -
                  </Typography>
                ) : (
                  <Amount
                    amount={data.feeAmount}
                    formatTextNumOpts={{
                      noDecimalPlaces: 7,
                      addDecimalPadding: false,
                    }}
                  />
                )}
                <Typography align={"right"} variant={"body1"}>
                  {data.fromAccountDescription}
                </Typography>

                {(() => {
                  if (data.state === SpotState.Failed) {
                    return (
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          flexDirection: "row-reverse",
                          gap: theme.spacing(1),
                          gridGap: (theme) => theme.spacing(1),
                          alignItems: "center",
                        })}
                      >
                        <Tooltip
                          title={data.failureReasons.join(", ")}
                          placement={"top"}
                        >
                          <ErrorIcon
                            sx={{
                              color: "secondary.light",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                        <SpotStateChip
                          chipProps={{
                            sx: {
                              height: "19px",
                              width: "fit-content",
                              justifySelf: "end",
                            },
                          }}
                          state={data.state}
                        />
                      </Box>
                    );
                  }
                  return (
                    <SpotStateChip
                      chipProps={{
                        sx: {
                          height: "19px",
                          width: "fit-content",
                          justifySelf: "end",
                        },
                      }}
                      state={data.state}
                    />
                  );
                })()}
              </Box>
            </Box>
          );
        }}
        noDataSplashComponent={
          <>
            <FaceIcon
              sx={(theme) => ({
                fontSize: 110,
                color: alpha(theme.palette.background.default, 0.5),
              })}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
                marginBottom: (theme) => theme.spacing(2),
              }}
              children={"No Trades Yet?"}
            />
            <Typography
              variant="h5"
              sx={{
                width: "226px",
                color: (theme) => theme.palette.text.disabled,
                textAlign: "center",
              }}
              children="You will see a list once you have placed a trade."
            />
          </>
        }
      >
        {({ setCriteria }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              marginBottom: (theme) => theme.spacing(1),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant={"h5"}>Trade History</Typography>
              {total > 0 && (
                <IconButton
                  sx={[
                    !openCollapse && {
                      color: (theme) => theme.palette.action.disabled,
                    },
                    openCollapse && {
                      color: (theme) => theme.palette.action.active,
                    },
                  ]}
                  onClick={() => setOpenCollapse(!openCollapse)}
                >
                  <FilterIcon />
                </IconButton>
              )}
            </Box>
            <Collapse in={openCollapse}>
              <TextField
                fullWidth={true}
                label="Trade No."
                disabled={loading}
                id="tradesTable-tradeNumber-formfield"
                value={tradeNumber}
                onChange={(e) => {
                  setTradeNumber(e.target.value);
                  if (e.target.value) {
                    setCriteria(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (prevCriteria: any) =>
                        ({
                          ...prevCriteria,
                          number: TextSubstringCriterion(e.target.value),
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        }) as any,
                    );
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    setCriteria((prevCriteria: any) => {
                      const updatedCriteria = prevCriteria;
                      delete updatedCriteria.number;
                      return { ...updatedCriteria };
                    });
                  }
                }}
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gap: (theme) => theme.spacing(2),
                  gridGap: (theme) => theme.spacing(2),
                }}
              >
                <DateField
                  label="Date From"
                  id={"tradesTable-dateFromFilter-formfield"}
                  disabled={loading}
                  value={dateFrom ? dayjs(dateFrom) : null}
                  renderInput={(textFieldProps: TextFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      fullWidth={true}
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(newValue) => {
                    if (!(newValue && dateIsValid(newValue))) {
                      setDateFrom(undefined);
                    } else {
                      setDateFrom(newValue ? newValue.format() : undefined);
                      setCriteria(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (prevCriteria: any) =>
                          ({
                            ...prevCriteria,
                            submittedAt: DateRangeCriterion(
                              newValue
                                ? {
                                    date: newValue.startOf("day").format(),
                                    inclusive: true,
                                    ignore: false,
                                  }
                                : undefined,
                              dateTo
                                ? {
                                    date: dayjs(dateTo as string)
                                      .startOf("day")
                                      .format(),
                                    inclusive: true,
                                    ignore: false,
                                  }
                                : undefined,
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          }) as any,
                      );
                    }
                  }}
                />
                <DateField
                  label="Date To"
                  id={"tradesTable-dateToFilter-formfield"}
                  value={dateTo ? dayjs(dateTo) : null}
                  disabled={loading}
                  renderInput={(textFieldProps: TextFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      fullWidth={true}
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(newValue) => {
                    if (!(newValue && dateIsValid(newValue))) {
                      setDateTo(undefined);
                    } else {
                      setDateTo(newValue ? newValue.format() : undefined);
                      setCriteria(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (prevCriteria: any) =>
                          ({
                            ...prevCriteria,
                            submittedAt: DateRangeCriterion(
                              dateFrom
                                ? {
                                    date: dayjs(dateFrom as string)
                                      .startOf("day")
                                      .format(),
                                    inclusive: true,
                                    ignore: false,
                                  }
                                : undefined,
                              newValue
                                ? {
                                    date: newValue.startOf("day").format(),
                                    inclusive: true,
                                    ignore: false,
                                  }
                                : undefined,
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          }) as any,
                      );
                    }
                  }}
                />
              </Box>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="tradesTable-state-filter"
                disabled={loading}
                multiple
                options={AllSpotStates}
                filterSelectedOptions
                onChange={(_, value: SpotState[]) => {
                  setState(value);
                  if (value.length > 0) {
                    setCriteria(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (prevCriteria: any) =>
                        ({
                          ...prevCriteria,
                          state: TextListCriterion(value),
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        }) as any,
                    );
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    setCriteria((prevCriteria: any) => {
                      const updatedCriteria = prevCriteria;
                      delete updatedCriteria.state;
                      return { ...updatedCriteria };
                    });
                  }
                }}
                ChipProps={{
                  color: "info",
                  size: "small",
                }}
                value={state}
                renderTags={(spotStates: SpotState[]) =>
                  spotStates.map((s, idx) => (
                    <Box sx={{ padding: "4px" }}>
                      <SpotStateChip
                        key={idx}
                        chipProps={{
                          onDelete: () => {
                            const filteredState = state.filter((v) => v !== s);
                            setState(filteredState);
                            if (filteredState.length !== 0) {
                              setCriteria(
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (prevCriteria: any) =>
                                  ({
                                    ...prevCriteria,
                                    state: TextListCriterion(filteredState),
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  }) as any,
                              );
                            } else {
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              setCriteria((prevCriteria: any) => {
                                const updatedCriteria = prevCriteria;
                                delete updatedCriteria.state;
                                return { ...updatedCriteria };
                              });
                            }
                          },
                          deleteIcon: (
                            <CancelIcon
                              sx={(theme) => ({
                                color: `${theme.palette.text.secondary} !important`,
                                "&:hover": {
                                  color: `${theme.palette.secondary.contrastText} !important`,
                                },
                              })}
                            />
                          ),
                        }}
                        state={s}
                      />
                    </Box>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={"tradesTable-stateFilter-autocompleteTextField"}
                    fullWidth={true}
                    label={"State"}
                    variant={"outlined"}
                    margin={"dense"}
                    InputLabelProps={{ shrink: true }}
                    placeholder={state.length ? undefined : "Select..."}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                  />
                )}
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: (theme) => theme.spacing(2),
                  gridGap: (theme) => theme.spacing(2),
                }}
              >
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option === value}
                  id="tradesTable-asset-filter"
                  getOptionLabel={(option: TokenViewModel) =>
                    `${option.token.code} - ${option.issuer}`
                  }
                  options={ledgerTokenViewReadResponse.models}
                  loading={ledgerTokenViewReadLoading}
                  onChange={(_, selected: TokenViewModel | null) => {
                    setAssetToken(selected);
                    if (selected) {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      setCriteria((prevCriteria: any) => ({
                        ...prevCriteria,
                        $or: [
                          {
                            "receivedAmount.token.code": TextExactCriterion(
                              selected.token.code,
                            ),
                          },
                          {
                            "paidAmount.token.code": TextExactCriterion(
                              selected.token.code,
                            ),
                          },
                        ],
                      }));
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      setCriteria((prevCriteria: any) => {
                        const updatedCriteria = prevCriteria;
                        delete updatedCriteria.$or;
                        return { ...updatedCriteria };
                      });
                    }
                  }}
                  value={assetToken}
                  onInputChange={(e, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    }
                    if (newInputValue === "") {
                      setLedgerTokenViewReadRequest({
                        ...ledgerTokenViewReadRequest,
                        criteria: defaultTokenCriteria,
                      });
                    } else {
                      setLedgerTokenViewReadRequest({
                        ...ledgerTokenViewReadRequest,
                        criteria: {
                          ...defaultTokenCriteria,
                          "token.code": TextSubstringCriterion(newInputValue),
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Asset"}
                      id={"tradesTable-assetFilter-autocompleteTextField"}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        placeholder: "Select...",
                      }}
                    />
                  )}
                />
              </Box>
              <Button
                id="tradesTable-clearAll-button"
                disabled={
                  loading ||
                  !(
                    tradeNumber ||
                    dateTo ||
                    dateFrom ||
                    state.length ||
                    assetToken
                  )
                }
                sx={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  height: "48px",
                }}
                variant="contained"
                color={"secondary"}
                fullWidth={true}
                onClick={() => {
                  setCriteria({});
                  setTradeNumber("");
                  setDateFrom(undefined);
                  setDateFrom(undefined);
                  setAssetToken(null);
                  setState([]);
                }}
              >
                <ClearFiltersIcon
                  sx={{
                    marginRight: "16px",
                  }}
                />
                Clear All
              </Button>
            </Collapse>
          </Box>
        )}
      </InfiniteScrollList>
    </Box>
  );
};

const DesktopTradeTable = () => {
  const theme = useTheme();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    readResponse: ledgerTokenViewReadResponse,
    readRequest: ledgerTokenViewReadRequest,
    setReadRequest: setLedgerTokenViewReadRequest,
    loading: ledgerTokenViewReadLoading,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 100,
    }),
    criteria: defaultTokenCriteria,
  });
  const { setReadRequest, readRequest, readResponse, loading } =
    useMarketSpotViewReaderRead({
      context: authContext,
      criteria: {},
      query: initialQuery,
    });

  // table criteria
  const [numberSearchCriteria, setNumberSearchCriteria] = useState("");
  const [spotStatusesForCriterion, setSpotStatusesForCriterion] = useState<
    SpotState[]
  >([]);
  const [spotDateTimeCriterionFrom, setSpotDateTimeCriterionFrom] =
    useState<DateRangeValue | null>(null);
  const [spotDateTimeCriterionTo, setSpotDateTimeCriterionTo] =
    useState<DateRangeValue | null>(null);
  const [assetToken, setAssetToken] = useState<TokenViewModel | null>(null);
  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined,
  );
  useEffect(() => {
    // prepare new initial criteria
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (numberSearchCriteria) {
      criteria.number = TextSubstringCriterion(numberSearchCriteria);
    }

    if (spotStatusesForCriterion.length) {
      criteria.state = TextListCriterion(spotStatusesForCriterion);
    }

    if (spotDateTimeCriterionFrom || spotDateTimeCriterionTo) {
      criteria.submittedAt = DateRangeCriterion(
        spotDateTimeCriterionFrom ? spotDateTimeCriterionFrom : undefined,
        spotDateTimeCriterionTo ? spotDateTimeCriterionTo : undefined,
      );
    }

    // search by asset
    if (assetToken) {
      criteria = {
        ...criteria,
        $or: [
          {
            "receivedAmount.token.code": TextExactCriterion(
              assetToken.token.code,
            ),
          },
          {
            "paidAmount.token.code": TextExactCriterion(assetToken.token.code),
          },
        ],
      };
    }

    if (isMounted()) {
      setReadRequest((prev) => ({
        ...prev,
        criteria,
      }));
    }
  }, [
    numberSearchCriteria,
    spotStatusesForCriterion,
    spotDateTimeCriterionFrom,
    spotDateTimeCriterionTo,
    assetToken,
    setReadRequest,
  ]);

  const validateDate = (date: string, field: string) => {
    setErrors({
      ...errors,
      [field]: "",
    });
    if (date.length >= 8 && !dateIsValid(dayjs(date).startOf("day").format())) {
      setErrors({
        ...errors,
        [field]: "Invalid date",
      });
    }
  };

  // reset readRequest's query offset when criteria changes
  useEffect(() => {
    if (isMounted()) {
      setReadRequest((prev) => ({
        ...prev,
        query: prev.query
          ? new Query({
              ...prev.query,
              offset: 0,
            })
          : new Query(),
      }));
    }
  }, [readRequest.criteria]);

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.breakpoints.values.lg,
          padding: theme.spacing(3, 3, 3, 3),
        })}
        data-component-info={JSON.stringify({
          component_id: "trades_history_list",
          component_business_name: "trades history",
          component_title: "trades history",
          component_driver: InteractionDriver.listTradeHistory,
        } as DataComponentInfo)}
      >
        <BPTable
          loading={loading}
          filterRowStyling={{ maxWidth: "800px" }}
          height={window.innerHeight - 201 - noticeBannerHeight}
          disableSelect
          title={"Trade History"}
          query={readRequest.query}
          interactionDriver={InteractionDriver.FindOrders}
          onQueryChange={(query) =>
            setReadRequest({
              ...readRequest,
              query,
            })
          }
          data={readResponse.models}
          noDataSplashComponent={
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaceIcon
                sx={(theme) => ({
                  fontSize: 110,
                  color: alpha(theme.palette.background.default, 0.5),
                })}
              />
              <Typography
                variant="h4"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.tertiary,
                  margin: theme.spacing(2, 0),
                })}
                children={
                  spotDateTimeCriterionTo
                    ? "No Results Found"
                    : "No Trades Yet?"
                }
              />
              <Typography
                variant="h5"
                sx={{
                  width: "226px",
                  color: (theme) => theme.palette.text.disabled,
                  textAlign: "center",
                }}
                children={
                  spotDateTimeCriterionTo
                    ? "Please try using different search criteria"
                    : "You will see an entry once you have placed a trade."
                }
              />
            </Box>
          }
          totalNoRecords={readResponse.total}
          filters={(() => {
            const filters: React.ReactNode[] = [
              <TextField
                id={"marketSpotTable-numberFilter-textField"}
                sx={{ width: 160 }}
                variant={"outlined"}
                margin={"dense"}
                label={"Number"}
                placeholder={"Start Typing..."}
                value={numberSearchCriteria}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setNumberSearchCriteria(e.target.value)}
              />,
              <DateField
                label={"From"}
                disabled={loading}
                id={"marketSpotTable-spotDateTimeFromFilter-dateField"}
                value={
                  spotDateTimeCriterionFrom
                    ? spotDateTimeCriterionFrom.date
                    : null
                }
                onChange={(newValue) => {
                  if (
                    newValue?.isAfter(spotDateTimeCriterionTo?.date ?? dayjs())
                  ) {
                    setSpotDateTimeCriterionFrom(null);
                  } else if (!(newValue && dateIsValid(newValue))) {
                    setErrors({
                      ...errors,
                      fromDate: "Invalid date",
                    });
                    setSpotDateTimeCriterionFrom(null);
                  } else {
                    setErrors(undefined);
                    setSpotDateTimeCriterionFrom(
                      newValue
                        ? {
                            date: newValue.startOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : null,
                    );
                  }
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    id={
                      "marketSpotTable-spotDateTimeFromFilter-dateFieldTextField"
                    }
                    sx={{ width: 160 }}
                    variant={"outlined"}
                    margin={"dense"}
                    helperText={errors?.fromDate ?? ""}
                    FormHelperTextProps={{
                      sx: { color: theme.palette.error.main },
                    }}
                    onChange={(e) => validateDate(e.target.value, "fromDate")}
                  />
                )}
              />,
              <DateField
                label={"To"}
                disabled={loading}
                id={"marketSpotTable-spotDateTimeToFilter-dateField"}
                value={
                  spotDateTimeCriterionTo ? spotDateTimeCriterionTo.date : null
                }
                onChange={(newValue) => {
                  if (
                    spotDateTimeCriterionFrom &&
                    newValue?.isBefore(spotDateTimeCriterionFrom.date)
                  ) {
                    setSpotDateTimeCriterionTo(null);
                  } else if (!(newValue && dateIsValid(newValue))) {
                    setErrors({
                      ...errors,
                      toDate: "Invalid date",
                    });
                    setSpotDateTimeCriterionTo(null);
                  } else {
                    setErrors(undefined);
                    setSpotDateTimeCriterionTo(
                      newValue
                        ? {
                            date: newValue.endOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : null,
                    );
                  }
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    id={
                      "marketSpotTable-spotDateTimeToFilter-dateFieldTextField"
                    }
                    sx={{ width: 160 }}
                    variant={"outlined"}
                    margin={"dense"}
                    helperText={errors?.toDate ?? ""}
                    FormHelperTextProps={{
                      sx: { color: theme.palette.error.main },
                    }}
                    onChange={(e) => validateDate(e.target.value, "toDate")}
                  />
                )}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id={"marketSpotTable-assetFilter-autocomplete"}
                getOptionLabel={(option: TokenViewModel) =>
                  `${option.token.code} - ${option.issuer}`
                }
                options={ledgerTokenViewReadResponse.models}
                loading={ledgerTokenViewReadLoading}
                onChange={(_, selected: TokenViewModel | null) =>
                  setAssetToken(selected)
                }
                value={assetToken}
                onInputChange={(e, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  }
                  if (newInputValue === "") {
                    setLedgerTokenViewReadRequest({
                      ...ledgerTokenViewReadRequest,
                      criteria: defaultTokenCriteria,
                    });
                  } else {
                    setLedgerTokenViewReadRequest({
                      ...ledgerTokenViewReadRequest,
                      criteria: {
                        ...defaultTokenCriteria,
                        "token.code": TextSubstringCriterion(newInputValue),
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Asset"}
                    id={"marketSpotTable-assetFilter-autocompleteTextField"}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                      sx: { width: 200 },
                    }}
                  />
                )}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id={"marketSpotTable-stateFilter-autocomplete"}
                disabled={loading}
                multiple
                options={AllSpotStates}
                filterSelectedOptions
                onChange={(_, value: SpotState[]) =>
                  setSpotStatusesForCriterion(value)
                }
                ChipProps={{
                  color: "info",
                  size: "small",
                }}
                value={spotStatusesForCriterion}
                renderTags={(spotStates: SpotState[]) =>
                  spotStates.map((s, idx) => (
                    <Box sx={{ padding: "4px" }}>
                      <SpotStateChip
                        key={idx}
                        chipProps={{
                          onDelete: () =>
                            setSpotStatusesForCriterion((prev) =>
                              prev.filter((prevState) => prevState !== s),
                            ),
                          deleteIcon: (
                            <CancelIcon
                              sx={(theme) => ({
                                color: `${theme.palette.text.secondary} !important`,
                                "&:hover": {
                                  color: `${theme.palette.secondary.contrastText} !important`,
                                },
                              })}
                            />
                          ),
                        }}
                        state={s}
                      />
                    </Box>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={"marketSpotTable-stateFilter-autocompleteTextField"}
                    sx={{ width: 336 }}
                    label={"State"}
                    variant={"outlined"}
                    margin={"dense"}
                    InputLabelProps={{ shrink: true }}
                    placeholder={
                      spotStatusesForCriterion.length ? undefined : "Select..."
                    }
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                  />
                )}
              />,
            ];

            // if any criteria is set then show a clear all filters button
            if (
              numberSearchCriteria ||
              spotStatusesForCriterion.length ||
              spotDateTimeCriterionFrom ||
              spotDateTimeCriterionTo ||
              assetToken
            ) {
              filters.push(
                <Button
                  sx={{ marginTop: "10px" }}
                  id={"marketSpotTable-clearAllFilters-button"}
                  variant={"contained"}
                  color={"secondary"}
                  children={"clear all"}
                  onClick={() => {
                    setNumberSearchCriteria("");
                    setSpotStatusesForCriterion([]);
                    setSpotDateTimeCriterionFrom(null);
                    setSpotDateTimeCriterionTo(null);
                    setAssetToken(null);
                    setErrors({});
                  }}
                  startIcon={<ClearFiltersIcon />}
                />,
              );
            }

            return filters;
          })()}
          toolBarControls={[
            <IconButton
              id={"marketSpotTable-reload-iconButton"}
              size={"small"}
              disabled={loading}
              onClick={() => {
                setReadRequest({ ...readRequest });
              }}
              data-link-info={JSON.stringify({
                content_interaction_id: "info-search",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Icon,
                content_interaction_text: "refresh",
                content_interaction_driver: InteractionDriver.FindOrders,
              } as DataLinkInfoType)}
            >
              <ReloadIcon
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-search",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Icon,
                  content_interaction_text: "refresh",
                  content_interaction_driver: InteractionDriver.FindOrders,
                } as DataLinkInfoType)}
              />
            </IconButton>,
          ]}
          columns={[
            {
              label: "Trade No.",
              field: "number",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                return (
                  <Typography
                    sx={(theme) => ({ marginRight: theme.spacing(5) })}
                    id={`marketSpotTable-spotNumber-text-${model.number}`}
                    color={"inherit"}
                    variant={"body1"}
                  >
                    {model.number}
                  </Typography>
                );
              },
            },
            {
              label: "Date",
              field: "date",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => (
                <Typography
                  sx={{
                    marginRight: "40px",
                  }}
                  id={`marketSpotTable-data-text-${data.number}`}
                  color={"inherit"}
                  variant={"body1"}
                >
                  {dayjs((data as MarketSpotViewModel).submittedAt).format(
                    DateFormat,
                  )}
                </Typography>
              ),
            },
            {
              label: "Received",
              field: "receivedAmount.value.float",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                return model.receivedAmount.isUndefined() ? (
                  <Typography
                    sx={{
                      marginRight: "40px",
                    }}
                    id={`marketSpotTable-spotNumber-text-${data.number}`}
                    color={"inherit"}
                    variant={"body1"}
                  >
                    -
                  </Typography>
                ) : (
                  <Box
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(5),
                    })}
                  >
                    <Amount
                      amount={model.receivedAmount}
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  </Box>
                );
              },
            },
            {
              label: "Paid",
              field: "paidAmount.value.float",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                return model.paidAmount.isUndefined() ? (
                  <Typography
                    sx={{
                      marginRight: "40px",
                    }}
                    id={`marketSpotTable-spotNumber-text-${data.number}`}
                    color={"inherit"}
                    variant={"body1"}
                  >
                    -
                  </Typography>
                ) : (
                  <Box
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(5),
                    })}
                  >
                    <Amount
                      amount={model.paidAmount}
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  </Box>
                );
              },
            },
            {
              label: "Price",
              field: "price.value.float",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                return model.price.isUndefined() ? (
                  <Typography
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(5),
                    })}
                    children={"-"}
                  />
                ) : (
                  <Box
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(5),
                    })}
                  >
                    <Amount
                      amount={model.price}
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  </Box>
                );
              },
            },
            {
              label: "Fees",
              field: "feeAmount.value.float",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                return model.feeAmount.isUndefined() ? (
                  <Typography
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(5),
                    })}
                    children={"-"}
                  />
                ) : (
                  <Box
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(5),
                    })}
                  >
                    <Amount
                      amount={model.feeAmount}
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  </Box>
                );
              },
            },
            {
              label: "From Account",
              field: "fromAccountDescription",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                return (
                  <Typography
                    id={`marketSpotTable-fromAccountDescription-text-${model.number}`}
                    color={"inherit"}
                    variant={"body1"}
                    sx={{
                      width: "128px",
                      marginRight: (theme) => theme.spacing(5),
                    }}
                  >
                    {model.fromAccountDescription}
                  </Typography>
                );
              },
            },
            {
              label: "State",
              field: "state",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const model = data as MarketSpotViewModel;
                if (model.state === SpotState.Failed) {
                  return (
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        gap: theme.spacing(1),
                        gridGap: (theme) => theme.spacing(1),
                        alignItems: "center",
                        marginRight: theme.spacing(5),
                      })}
                    >
                      <SpotStateChip state={model.state} />
                      <Tooltip
                        title={model.failureReasons.join(", ")}
                        placement={"top"}
                      >
                        <ErrorIcon
                          sx={{
                            color: "secondary.light",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  );
                }
                return (
                  <Box
                    sx={() => ({
                      marginRight: (theme) => theme.spacing(2),
                    })}
                  >
                    <SpotStateChip state={model.state} />
                  </Box>
                );
              },
            },
          ]}
        />
      </Box>
    </>
  );
};
