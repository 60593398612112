import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldValidations, ValidationResult } from "common/validation";

// Types
export type ValidationFuncType<FormStateType> = (
  req: FormStateType,
) => Promise<ValidationResult>;
export type StateAsyncInitFuncType<FormStateType> =
  () => Promise<FormStateType>;

export type AllowedFormUpdaterSpecsFuncType<FormStateType> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  r?: FormStateType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => FormStateType;
export type AllowedFormUpdaterSpecsType<FormStateType> = {
  [key: string]: AllowedFormUpdaterSpecsFuncType<FormStateType>;
};

const defaultInitialValidation: ValidationResult = {
  valid: false,
  fieldValidations: {},
};

// Additional props
type UseValidatedFormPropsType = {
  initialValidateState?: ValidationResult;
  skipTouchedFieldsOnValidation?: boolean;
};

// useValidatedForm is a hook to abstract the following common patterns:
// - manage form state, while keeping track of which fields are touched by the user
// - perform form validation only on touched fields when the form state changed, async included
// - set an initial state, async included
// The user is required to supply the following:
// - The FormStateType, for type safety
// - UserFormUpdaterSpecsType, for type safety. This type is restricted to have the same 'shape' as the AllowedFormUpdaterSpecsType.
// - A validationFunc, which is a user defined func that takes the formState as a parameter, and returns the validation result (
// the user does not have to take touched fields into account )
// - An optional async initialize state func
// - formUpdaterSpecs is a user defined object, where each key represent a unique field identifier, which will get added to the touched fields
// and the value of each key is a function that defines how that form state should change, given the supplied value. (prevState, value) => newState
// - initial state object, this needs to be supplied even if a async state init func is also supploed, this is
//   because the returned state can never be undefined
// - initial touched fields
// - optional useValidatedFormProps
// Then the following is returned to be used by the parent component
// - FormState
// - Validation Result
// - Form Updater
// - Validation in Progress flag
// - A set of touched fields
export const useValidatedForm = <
  FormStateType,
  UserFormUpdaterSpecsType extends AllowedFormUpdaterSpecsType<FormStateType>,
>(
  validationFunc: ValidationFuncType<FormStateType>,
  initializeState: StateAsyncInitFuncType<FormStateType> | undefined,
  formUpdaterSpecs: UserFormUpdaterSpecsType,
  initialState: FormStateType,
  initialTouchedFields: Set<string>,
  useValidatedFormProps: UseValidatedFormPropsType = {},
): [
  FormStateType,
  ValidationResult,
  UserFormUpdaterSpecsType,
  boolean,
  Set<string>,
] => {
  // memoize hook deps in refs
  const formUpdaterSpecsRef =
    useRef<UserFormUpdaterSpecsType>(formUpdaterSpecs);
  const validationFRef = useRef(validationFunc);
  const initializeStateFRef = useRef(initializeState);
  const validatedFormPropsRef = useRef(useValidatedFormProps);

  // untouched fields will be excluded from the validation result by default
  const touchedFields = useRef<Set<string>>(initialTouchedFields);

  // form state
  const [formState, setFormState] = useState<FormStateType>(initialState);

  // validation result state
  const [validationResultState, setValidationResultState] =
    useState<ValidationResult>(
      validatedFormPropsRef.current.initialValidateState ||
        defaultInitialValidation,
    );
  const [validationInProgress, setValidationInProgress] =
    useState<boolean>(false);
  // timeout used to debounce validation
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  // memoizedValidatorFunc is a memoized func that calls the user-defined validation
  // and returns the validation result. The untouched fields are excluded by default.
  const memoizedValidatorFunc = useCallback(async (state: FormStateType) => {
    if (!state) {
      return;
    }
    setValidationInProgress(true);
    const validateResponse = await validationFRef.current(state);

    // skip touched fields
    if (!validatedFormPropsRef.current.skipTouchedFieldsOnValidation) {
      const filteredFieldValidations: FieldValidations = Object.keys(
        validateResponse.fieldValidations,
      )
        .filter((key) => touchedFields.current.has(key))
        .reduce((obj: FieldValidations, key: string) => {
          obj[key] = validateResponse.fieldValidations[key];
          return obj;
        }, {});

      const filteredValidationResult: ValidationResult = {
        valid: validateResponse.valid,
        fieldValidations: filteredFieldValidations,
      };
      setValidationResultState(filteredValidationResult);
      setValidationInProgress(false);
    } else {
      setValidationResultState(validateResponse);
      setValidationInProgress(false);
    }
  }, []);

  // The memoizedValidatorFunc is called after the state has been initialized using the user defined async state init function
  // This will only run once.
  useEffect(() => {
    (async () => {
      if (initializeStateFRef.current) {
        const initState = await initializeStateFRef.current();
        memoizedValidatorFunc(initState).finally();
        setFormState(initState);
      }
    })();
  }, [setFormState, memoizedValidatorFunc]);

  // a memoized formUpdater is built and returned to the user. The formUpdater is based on the
  // formUpdaterSpecs defined by the user. The formUpdater will be responsible for updating the state using the user defined
  // specs, and then adding the unique key to the touched fields array. Validation is done every time a method on the formUpdater
  // is called. This will only run once.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formUpdater: { [p: string]: (value: any, ...args: any[]) => void } =
    useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const returnDPFO1: { [p: string]: (value: any, ...args: any[]) => void } =
        {};
      for (const key in formUpdaterSpecsRef.current) {
        if (!key) {
          continue;
        }
        returnDPFO1[key] = (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: any,
          otherTouchedFields?: string[],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...args: any[]
        ) => {
          setFormState((prevState) => {
            if (!prevState) {
              return prevState;
            }
            touchedFields.current = touchedFields.current.add(key);
            if (otherTouchedFields && otherTouchedFields?.forEach) {
              otherTouchedFields?.forEach(
                (v) => (touchedFields.current = touchedFields.current.add(v)),
              );
            }

            const newState = formUpdaterSpecsRef.current[
              key as keyof UserFormUpdaterSpecsType
            ](value, prevState, args);
            clearTimeout(validationTimeoutRef.current);
            validationTimeoutRef.current = setTimeout(
              () => memoizedValidatorFunc(newState).finally(),
              200,
            );
            return newState;
          });
        };
      }
      return returnDPFO1;
    }, [memoizedValidatorFunc, setFormState]);

  return [
    formState,
    validationResultState,
    formUpdater as unknown as UserFormUpdaterSpecsType,
    validationInProgress,
    touchedFields.current,
  ];
};
