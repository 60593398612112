export class DownloadableDocument {
  public name = "";

  public mimeType = "";

  public description = "";

  public downloadURL = "";

  constructor(downloadableDocument?: DownloadableDocument) {
    if (!downloadableDocument) {
      return;
    }
    this.downloadURL = downloadableDocument.downloadURL;
    this.description = downloadableDocument.description;
    this.name = downloadableDocument.name;
    this.mimeType = downloadableDocument.mimeType;
  }
}
