import { Token } from "james/ledger";
import { Model } from "james/views/marketListingView/Model";
import { create } from "zustand";
import * as StellarSDK from "stellar-sdk";

interface MarketListingPair {
  base: Token;
  counter: Token;
}

export interface ExchangeStore {
  /** Each child component will increment this number on initial load
   * When it finishes it will decrement this number:
   * [loading <= 0] means none of the child components are in initial loading state
   */
  loading: number;
  marketListings: Model[];
  selectedMarketListing: Model | undefined;
  marketListingPair: MarketListingPair | undefined;
  total: number;
  trades: StellarSDK.Horizon.ServerApi.TradeRecord[];
}

export interface ExchangeActions {
  incrementLoading: () => void;
  decrementLoading: () => void;
  setMarketListings(marketListings: Model[]): void;
  setMarketListingPair(marketListingPair?: MarketListingPair): void;
  setSelectedMarketListing(marketListing: Model): void;
  setTotal(total: number): void;
}

const initialExchangeStoreState: ExchangeStore = {
  loading: 0,
  marketListings: [],
  selectedMarketListing: undefined,
  marketListingPair: undefined,
  total: 0,
  trades: [],
};

export const useExchangeStore = create<ExchangeStore & ExchangeActions>(
  (set, get) => ({
    ...initialExchangeStoreState,
    incrementLoading: () => set({ loading: get().loading + 1 }),
    decrementLoading: () => set({ loading: get().loading - 1 }),
    setMarketListings: (val: Model[]) => set({ marketListings: val }),
    setMarketListingPair: (val: MarketListingPair) =>
      set({ marketListingPair: val }),
    setTotal: (val: number) => set({ total: val }),
    setSelectedMarketListing: (val: Model) =>
      set({ selectedMarketListing: val }),
  }),
);
