import dayjs, { Dayjs } from "dayjs";
import { MediaImage } from "james/media/Image";

export class IndependentReview {
  public title: string = "";
  public summary: string = "";
  public date: Dayjs = dayjs();
  public url: string = "";
  public reviewerDetails: ReviewerDetails = new ReviewerDetails();

  constructor(i?: IndependentReview) {
    if (!i) return;

    this.title = i.title;
    this.summary = i.summary;
    this.date = i.date;
    this.url = i.url;
    this.reviewerDetails = new ReviewerDetails(i.reviewerDetails);
  }
}

export class ReviewerDetails {
  public name: string = "";
  public bio: string = "";
  public profilePicture: MediaImage = new MediaImage();

  constructor(r?: ReviewerDetails) {
    if (!r) return;

    this.name = r.name;
    this.bio = r.bio;
    this.profilePicture = new MediaImage(r.profilePicture);
  }
}
