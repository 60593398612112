import React, { ReactNode, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { FiatCurrency } from "james/financial";
import { useSearchFiatCurrency } from "james/financial/FiatCurrencyRepository";
import { BPTable } from "components/Table";
import { NewSorting, Query } from "james/search/query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Clear as ClearIcon, Refresh as ReloadIcon } from "@mui/icons-material";
import { TextSubstringCriterion } from "james/search/criterion";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "Table";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
};

const StyledIconButton = styled(IconButton)(() => ({
  [`& .${classes.textSearchField}`]: {
    width: 400,
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "asc")],
});

export function Table() {
  const { authContext } = useApplicationContext();
  dayjs.extend(utc);
  const {
    searchFiatCurrencyRequest,
    searchFiatCurrencyResponse,
    setSearchFiatCurrencyRequest,
    loading: readLoading,
  } = useSearchFiatCurrency({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { code: TextSubstringCriterion(textSearchCriterionTextField) },
          { name: TextSubstringCriterion(textSearchCriterionTextField) },
          { country: TextSubstringCriterion(textSearchCriterionTextField) },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    setSearchFiatCurrencyRequest({
      context: searchFiatCurrencyRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    textSearchCriterion,
    searchFiatCurrencyRequest.context,
    setSearchFiatCurrencyRequest,
    authContext,
  ]);

  return (
    <BPTable
      singleSelect
      height={window.innerHeight - 138 - noticeBannerHeight}
      title={"Currency Fiat"}
      loading={readLoading}
      data={searchFiatCurrencyResponse.records}
      totalNoRecords={searchFiatCurrencyResponse.total}
      query={searchFiatCurrencyRequest.query}
      onQueryChange={(query) =>
        setSearchFiatCurrencyRequest({
          ...searchFiatCurrencyRequest,
          query,
        })
      }
      toolBarControls={(() => {
        const controlsToRender: ReactNode[] = [];

        controlsToRender.push(
          <Tooltip title={"Reload"}>
            <span>
              <StyledIconButton
                size={"small"}
                onClick={() =>
                  setSearchFiatCurrencyRequest({
                    ...searchFiatCurrencyRequest,
                    query: new Query(initialQuery),
                  })
                }
              >
                <ReloadIcon />
              </StyledIconButton>
            </span>
          </Tooltip>,
        );

        return controlsToRender;
      })()}
      filters={[
        <TextField
          variant={"outlined"}
          margin={"dense"}
          className={classes.textSearchField}
          label={"Search Text Fields"}
          placeholder={"Start Typing..."}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterionTextField ? (
              <InputAdornment
                position={"end"}
                children={
                  <IconButton
                    size={"small"}
                    onClick={() => setTextSearchCriterionTextField("")}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterionTextField}
          onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
        />,
      ]}
      columns={[
        {
          field: "name",
          label: "Name",
        },
        {
          field: "code",
          label: "Code",
        },
        {
          field: "country",
          label: "Country",
        },
        {
          field: "timeZoneOffset",
          label: "Time Zone Offset",
          accessor: (data) => {
            const offset = +(data as FiatCurrency).timeZoneOffset / (60 * 60);
            return offset >= 0 ? `GMT+${offset}` : `GMT${offset}`;
          },
        },
        {
          field: "settlementCutoffTime",
          label: "Cutoff Time",
          accessor: (data) =>
            dayjs((data as FiatCurrency).settlementCutoffTime)
              .utcOffset(+(data as FiatCurrency).timeZoneOffset / (60 * 60))
              .format("HH:mm:ss"),
        },
      ]}
    />
  );
}
