import { Context } from "james/security";
import { Query } from "james/search/query";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Model } from "./Model";

export interface ReadRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadResponse {
  models: Model[];
  total: number;
}

export const Reader = {
  serviceProvider: "stellarRecipientView-Reader",
  async Read(
    request: ReadRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<ReadResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${Reader.serviceProvider}.Read`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      models: response.models.map((m: Model) => new Model(m)),
      total: response.total,
    };
  },
};
