import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Token } from "james/ledger";
import { LiquidityPoolRegistration } from "./LiquidityPoolRegistration";

export interface RegisterLiquidityPoolForTokensRequest {
  context: Context;
  ownerID: string;
  token1: Token;
  token2: Token;
}

export interface RegisterLiquidityPoolForTokensResponse {
  liquidityPoolRegistration: LiquidityPoolRegistration;
}

export const LiquidityPoolRegistrar = {
  serviceProvider: "stellar-LiquidityPoolRegistrar",
  async RegisterLiquidityPoolForTokens(
    request: RegisterLiquidityPoolForTokensRequest,
    additionalHeaders?: Headers,
  ): Promise<RegisterLiquidityPoolForTokensResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${LiquidityPoolRegistrar.serviceProvider}.RegisterLiquidityPoolForTokens`,
      request,
    });
    return {
      liquidityPoolRegistration: new LiquidityPoolRegistration(
        response.liquidityPoolRegistration,
      ),
    };
  },
};
