import React from "react";
import { PublicMainAppBar } from "./components/PublicMainAppBar";
import { Ribbon } from "./components/Ribbon";
import { Box } from "@mui/material";
import { useWindowSize } from "utilities/general";
import { useAppTourContext } from "context/AppTour/AppTour";

export interface PublicViewWithFrameProps {
  children?: React.ReactNode;
}

export function PublicViewWithFrame(props: PublicViewWithFrameProps) {
  const [, windowHeight] = useWindowSize();
  const { registerElement } = useAppTourContext();

  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        overflow: "hidden",
        gridTemplateRows: "56px 48px calc(100% - 56px - 48px)",
      }}
    >
      <PublicMainAppBar />
      <Ribbon />
      <Box
        ref={registerElement("assetDetailViewScrollBox")}
        sx={{
          width: "100%",
          maxHeight: windowHeight - 104,
          overflowY: {
            lg: "scroll",
            xs: "hidden",
          },
          overflowX: "hidden",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
        className={"meshScroll"}
      >
        {props.children}
      </Box>
    </Box>
  );
}
