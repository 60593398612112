import React from "react";
import { Button, IconButton } from "@mui/material";
import {
  InteractionAction,
  InteractionType,
  InteractionDriver,
  DataLinkInfoType,
} from "const/gtm";
import CloseIcon from "@mui/icons-material/Close";

interface AppTourButtonProps {
  onClick: () => void;
}

export const CloseButton = ({ onClick }: AppTourButtonProps) => {
  return (
    <IconButton
      data-testid="close-button"
      onClick={onClick}
      data-link-info={JSON.stringify({
        content_interaction_id: "onboarding-tour-navlink",
        content_interaction_action: InteractionAction.Click,
        content_interaction_text: "skip",
        content_interaction_type: InteractionType.Icon,
        content_interaction_driver: InteractionDriver.Navigation,
      } as DataLinkInfoType)}
    >
      <CloseIcon />
    </IconButton>
  );
};

export const NextButton = ({ onClick }: AppTourButtonProps) => {
  return (
    <Button
      id={"platform-guided-journey-next-button"}
      variant="contained"
      color="primary"
      onClick={onClick}
      data-link-info={JSON.stringify({
        content_interaction_id: "onboarding-tour-navlink",
        content_interaction_action: InteractionAction.Click,
        content_interaction_text: "next",
        content_interaction_type: InteractionType.Button,
        content_interaction_driver: InteractionDriver.Navigation,
      } as DataLinkInfoType)}
    >
      Next
    </Button>
  );
};

export const BackButton = ({ onClick }: AppTourButtonProps) => {
  return (
    <Button
      id={"platform-guided-journey-back-button"}
      color="secondary"
      onClick={onClick}
      data-link-info={JSON.stringify({
        content_interaction_id: "onboarding-tour-navlink",
        content_interaction_action: InteractionAction.Click,
        content_interaction_text: "back",
        content_interaction_type: InteractionType.Button,
        content_interaction_driver: InteractionDriver.Navigation,
      } as DataLinkInfoType)}
    >
      Back
    </Button>
  );
};

export const CompleteButton = ({ onClick }: AppTourButtonProps) => {
  return (
    <Button
      id={"platform-guided-journey-back-button"}
      variant="contained"
      color="secondary"
      onClick={onClick}
      data-link-info={JSON.stringify({
        content_interaction_id: "onboarding-tour-navlink",
        content_interaction_action: InteractionAction.Click,
        content_interaction_text: "complete",
        content_interaction_type: InteractionType.Button,
        content_interaction_driver: InteractionDriver.CompleteTutorial,
      } as DataLinkInfoType)}
    >
      Complete
    </Button>
  );
};
