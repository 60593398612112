import React, { ReactNode, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FinancialCryptoCurrencyViewModel,
  FinancialCryptoCurrencyViewUpdater,
  useFinancialCryptoCurrencyViewReaderRead,
} from "james/views/financialCryptoCurrencyView";
import { BPTable } from "components/Table";
import { NewSorting, Query } from "james/search/query";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear as ClearIcon, Refresh as ReloadIcon } from "@mui/icons-material";
import { TextSubstringCriterion } from "james/search/criterion";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { Description } from "views/Assets/Description";
import { Token } from "james/ledger";
import { ListingManagementDialog } from "components/ListingManagementDialog";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { ListingStateChip } from "../../MarketListing/MarketListings/Chips";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "Table";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  row: `${PREFIX}-row`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.textSearchField}`]: {
    width: 400,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "asc")],
});

export function Table() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { viewConfiguration, authContext } = useApplicationContext();
  const {
    readRequest,
    readResponse,
    setReadRequest,
    loading: readLoading,
  } = useFinancialCryptoCurrencyViewReaderRead({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { name: TextSubstringCriterion(textSearchCriterionTextField) },
          { shortName: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            "token.code": TextSubstringCriterion(textSearchCriterionTextField),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState<
    FinancialCryptoCurrencyViewModel | undefined
  >(undefined);
  const [fullUpdateLoading, setFullUpdateLoading] = useState(false);
  const marketListingsViewConfiguration =
    viewConfiguration["MC Market Listing"]?.["Market Listings"];
  const [tokenToManageListing, setTokenToManageListing] = useState<
    Token | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [textSearchCriterion, readRequest.context, setReadRequest, authContext]);

  return (
    <Root>
      <BPTable
        singleSelect
        height={window.innerHeight - 138 - noticeBannerHeight}
        title={"Currency Crypto"}
        loading={readLoading || fullUpdateLoading}
        data={readResponse.models}
        totalNoRecords={readResponse.total}
        onSingleSelectChange={(data) =>
          setSelectedCryptoCurrency(data as FinancialCryptoCurrencyViewModel)
        }
        query={readRequest.query}
        onQueryChange={(query) =>
          setReadRequest({
            ...readRequest,
            query,
          })
        }
        toolBarControls={(() => {
          const controlsToRender: ReactNode[] = [];

          if (
            selectedCryptoCurrency &&
            marketListingsViewConfiguration.Operate
          ) {
            const noListing = !selectedCryptoCurrency.listingState;
            controlsToRender.push(
              <Button
                id={`cryptoCurrencyTable-${
                  noListing ? "editListing" : "newListing"
                }-button`}
                variant={"contained"}
                color={"primary"}
                onClick={() =>
                  setTokenToManageListing(selectedCryptoCurrency.token)
                }
                children={noListing ? "Place" : "Edit Listing"}
              />,
            );
          }

          if (viewConfiguration?.Currency?.Crypto?.ViewModel?.FullUpdate) {
            controlsToRender.push(
              <Button
                variant={"contained"}
                color={"primary"}
                children={"Full Update"}
                onClick={async () => {
                  setFullUpdateLoading(true);
                  try {
                    await FinancialCryptoCurrencyViewUpdater.FullUpdate({
                      context: authContext,
                    });
                    setReadRequest({ ...readRequest });
                    enqueueSnackbar("Full Update Complete", {
                      variant: "success",
                    });
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    console.error(
                      `error performing full update: ${
                        err.message ? err.message : err.toString()
                      }`,
                    );
                    enqueueSnackbar(
                      `Error Performing Full Update: ${
                        err.message ? err.message : err.toString()
                      }`,
                      { variant: "error" },
                    );
                  }
                  setFullUpdateLoading(false);
                }}
              />,
            );
          }

          controlsToRender.push(
            <Tooltip title={"Reload"}>
              <span>
                <IconButton
                  id={"cryptoCurrencyTable-refresh-button"}
                  size={"small"}
                  onClick={() =>
                    setReadRequest({
                      ...readRequest,
                      query: new Query(initialQuery),
                    })
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controlsToRender;
        })()}
        filters={[
          <TextField
            id={"cryptoCurrencyTable-textFilter-textField"}
            variant={"outlined"}
            margin={"dense"}
            className={classes.textSearchField}
            label={"Search Text Fields"}
            placeholder={"Start Typing..."}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position={"end"}
                  children={
                    <IconButton
                      id={"cryptoCurrencyTable-textFilterClear-iconButton"}
                      size={"small"}
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
        ]}
        columns={[
          {
            field: "tokenIcon",
            sortable: false,
            label: "",
            accessor: (data) => (
              <IconViewUpload
                token={(data as FinancialCryptoCurrencyViewModel).token}
              />
            ),
          },
          {
            field: "token.code",
            label: "Code",
            accessor: (data) =>
              (data as FinancialCryptoCurrencyViewModel).token.code,
          },
          {
            field: "name",
            label: "Name",
            accessor: (data) => (
              <div className={classes.row}>
                <Typography
                  color={"inherit"}
                  children={(data as FinancialCryptoCurrencyViewModel).name}
                />
                <Description
                  token={(data as FinancialCryptoCurrencyViewModel).token}
                />
              </div>
            ),
          },
          {
            field: "issuer",
            label: "Issuer",
          },
          {
            field: "token.network",
            label: "Network",
            accessor: (data) =>
              (data as FinancialCryptoCurrencyViewModel).token.network,
          },
          {
            field: "listingState",
            label: "Listing",
            accessor: (data) => (
              <ListingStateChip
                state={(data as FinancialCryptoCurrencyViewModel).listingState}
              />
            ),
          },
        ]}
      />
      {tokenToManageListing && (
        <ListingManagementDialog
          closeDialog={() => setTokenToManageListing(undefined)}
          baseToken={tokenToManageListing}
        />
      )}
    </Root>
  );
}
