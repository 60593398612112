export class TranslatedError extends Error {
  /** error type */
  public name = "Translated Error";

  /** error message */
  public message = "";

  /** error code */
  public code = 0;

  constructor(code: number, message: string) {
    super();
    this.message = message;
    this.code = code;
  }
}

export const UnexpectedTranslatedError = new TranslatedError(
  100101,
  "Something went wrong. Please try again.",
);
