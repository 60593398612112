export class Permission {
  public serviceName = "";

  public serviceProvider = "";

  public description = "";

  constructor(permission?: Permission) {
    if (!permission) {
      return;
    }
    this.serviceName = permission.serviceName;
    this.serviceProvider = permission.serviceProvider;
    this.description = permission.description;
  }
}
