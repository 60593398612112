import { Button, IconButton, Typography, alpha } from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import { BPTable } from "components/Table";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useApplicationContext } from "context/Application/Application";
import { Query } from "james/search/query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  InstrumentMarketingContent,
  useReadManyMarketingContent,
} from "james/marketing";

const initialQuery = new Query({
  offset: 0,
  limit: 15,
  sorting: [],
});

export type TableProps = {
  system: boolean;
};

export const Table = (props: TableProps) => {
  const navigate = useNavigate();
  const { authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { readRequest, setReadRequest, readResponse, loading } =
    useReadManyMarketingContent(
      {
        context: authContext,
        criteria: {},
        query: initialQuery,
      },
      props.system,
    );

  const [selectedMarketingContent, setSelectedMarketingContent] = useState<
    InstrumentMarketingContent | undefined
  >(undefined);

  return (
    <BPTable
      loading={loading}
      height={window.innerHeight - 88 - noticeBannerHeight}
      singleSelect
      onSingleSelectChange={(data) =>
        setSelectedMarketingContent(data as InstrumentMarketingContent)
      }
      title={"Instrument Marketing Content"}
      noDataSplashComponent={noDataSplashComponent()}
      query={readRequest?.query}
      onQueryChange={(query) =>
        setReadRequest({
          query: new Query(query),
          context: readRequest?.context || authContext,
          criteria: readRequest?.criteria,
        })
      }
      data={readResponse.records}
      totalNoRecords={readResponse.total}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (selectedMarketingContent) {
          controls.push(
            <Button
              variant={"contained"}
              id={"instrumentMarketingContent-view-button"}
              children={"View"}
              color={"primary"}
              onClick={() => {
                if (!selectedMarketingContent) {
                  return;
                }
                const token = selectedMarketingContent.token;
                if (!token) {
                  return;
                }

                const query = new URLSearchParams();
                query.set("token-code", token.code);
                query.set("token-issuer", token.issuer);
                query.set("token-network", token.network);

                navigate({
                  pathname: "/builder/marketing/marketing-info",
                  search: query.toString(),
                });
              }}
            />,
          );
        }

        controls.push(
          <IconButton
            id={"instrumentMarketingContent-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() =>
              setReadRequest({
                context: authContext,
                criteria: readRequest?.criteria,
                query: readRequest?.query,
              })
            }
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "Name",
          field: "name",
          accessor: (data) => {
            const model = data as InstrumentMarketingContent;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                {model.token.code}
              </Typography>
            );
          },
        },
      ]}
    />
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>instrument marketing content</i> once it has been
              created.
            </span>
          }
        />
      </Box>
    </Box>
  );
};
