import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "Ribbon";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 48,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface RibbonProps {
  children?: React.ReactNode;
}

export function Ribbon(props: RibbonProps) {
  return <Root className={classes.root}>{props.children}</Root>;
}
