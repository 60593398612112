"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToFundingOrderState = stringToFundingOrderState;
exports.fundingOrderStateToString = fundingOrderStateToString;
const fundingOrderState_pb_1 = require("./fundingOrderState_pb");
const fundingOrderStateToStringMapping = {
    [fundingOrderState_pb_1.FundingOrderState.FAILED_FUNDING_ORDER_STATE]: "Failed",
    [fundingOrderState_pb_1.FundingOrderState.UNDER_INVESTIGATION_FUNDING_ORDER_STATE]: "Under Investigation",
    [fundingOrderState_pb_1.FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE]: "Awaiting Approval",
    [fundingOrderState_pb_1.FundingOrderState.SETTLEMENT_IN_PROGRESS_FUNDING_ORDER_STATE]: "Settlement In Progress",
    [fundingOrderState_pb_1.FundingOrderState.SETTLED_FUNDING_ORDER_STATE]: "Settled",
    [fundingOrderState_pb_1.FundingOrderState.CANCELLED_FUNDING_ORDER_STATE]: "Cancelled",
};
const fundingOrderStateStringToState = {
    Failed: fundingOrderState_pb_1.FundingOrderState.FAILED_FUNDING_ORDER_STATE,
    "Under Investigation": fundingOrderState_pb_1.FundingOrderState.UNDER_INVESTIGATION_FUNDING_ORDER_STATE,
    "Awaiting Approval": fundingOrderState_pb_1.FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE,
    "Settlement In Progress": fundingOrderState_pb_1.FundingOrderState.SETTLEMENT_IN_PROGRESS_FUNDING_ORDER_STATE,
    Settled: fundingOrderState_pb_1.FundingOrderState.SETTLED_FUNDING_ORDER_STATE,
    Cancelled: fundingOrderState_pb_1.FundingOrderState.CANCELLED_FUNDING_ORDER_STATE,
};
class UnsupportedFundingOrderStringError extends Error {
    constructor(fundingOrderStateString) {
        const message = `Unsupported FundingOrderState string: ${fundingOrderStateString}`;
        super(message);
        this.fundingOrderStateString = fundingOrderStateString;
    }
}
/**
 * Converts a custom string representation of a FundingOrderState to a FundingOrderState enum instance.
 * @param {string} fundingOrderStateString - The custom string representation of the funding order state to convert.
 * @returns {FundingOrderState} The funding order state.
 */
function stringToFundingOrderState(fundingOrderStateString) {
    if (fundingOrderStateString in fundingOrderStateStringToState) {
        return fundingOrderStateStringToState[fundingOrderStateString];
    }
    else {
        throw new UnsupportedFundingOrderStringError(fundingOrderStateString);
    }
}
class UnsupportedFundingOrderStateError extends Error {
    constructor(fundingOrderState) {
        const message = `Unsupported FundingOrderState: ${fundingOrderState}`;
        super(message);
        this.fundingOrderState = fundingOrderState;
    }
}
/**
 * Converts a FundingOrderState enum instance to a custom string representation.
 * @param {FundingOrderState} fundingOrderState - The funding order state to convert.
 * @returns {string} The custom string representation of the funding order state.
 */
function fundingOrderStateToString(fundingOrderState) {
    if (fundingOrderState in fundingOrderStateToStringMapping) {
        return fundingOrderStateToStringMapping[fundingOrderState];
    }
    else {
        throw new UnsupportedFundingOrderStateError(fundingOrderState);
    }
}
