import { useCallback } from "react";
import {
  GenerateRequest,
  GenerateResponse,
  IndicativePriceHistoryGenerator,
} from "james/marketData/IndicativePriceHistoryGenerator";
import {
  BuildQueryKey,
  GenerateAPIRequestUniqueKey,
} from "utilities/reactQuery";
import { useQueryCacheControllerContext } from "context/QueryCacheController/QueryCacheController";
import { ReactQueryOpt } from "./type";

export type IndicativePriceHistoryGenerator = {
  Generate: (
    request: GenerateRequest,
    reactQueryOpts?: ReactQueryOpt,
  ) => Promise<GenerateResponse>;
};

export function useRQIndicativePriceHistoryGenerator(): IndicativePriceHistoryGenerator {
  const { registerQuery, executeQuery } = useQueryCacheControllerContext();

  const Generate = useCallback(
    async (request: GenerateRequest): Promise<GenerateResponse> => {
      // create unique Key for the API request
      const uniqueKey = GenerateAPIRequestUniqueKey(
        IndicativePriceHistoryGenerator.serviceProvider,
        "Generate",
        request,
      );

      // build query Key and use it as an ID to register the query
      const ID = BuildQueryKey(uniqueKey, "PriceHistorian");

      registerQuery(
        ID,
        () => IndicativePriceHistoryGenerator.Generate(request),
        {
          staleTime: 0, // 0 min
          cacheTime: 10 * 60 * 1000, // 10 min
        },
      );

      return (await executeQuery(ID)) as Promise<GenerateResponse>;
    },
    [registerQuery, executeQuery],
  );

  return {
    Generate,
  };
}
