import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { useState } from "react";
import { useIsMounted } from "hooks";
import { useSnackbar } from "notistack";
import { PaaSAgreement } from "./PaaSAgreement";
import { AUSAgreement } from "./AUSAgreement";
import { ComplianceManagerRead } from "./index";
import { useErrorContext } from "context/Error";

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetLatestPaaSAgreementRequest {}

export interface GetLatestPaaSAgreementResponse {
  paasAgreement: PaaSAgreement;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetLatestAUSAgreementRequest {}

export interface GetLatestAUSAgreementResponse {
  ausAgreement: AUSAgreement;
}

export const ManagerRead = {
  serviceProvider: "compliance-ManagerRead",
  async GetLatestPaaSAgreement(
    request: GetLatestPaaSAgreementRequest,
  ): Promise<GetLatestPaaSAgreementResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshPublicURL"),
      method: `${ManagerRead.serviceProvider}.GetLatestPaaSAgreement`,
      request,
    });

    return { paasAgreement: new PaaSAgreement(response.paasAgreement) };
  },
  async GetLatestAUSAgreement(
    request: GetLatestAUSAgreementRequest,
  ): Promise<GetLatestAUSAgreementResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshPublicURL"),
      method: `${ManagerRead.serviceProvider}.GetLatestAUSAgreement`,
      request,
    });

    return { ausAgreement: new PaaSAgreement(response.ausAgreement) };
  },
};

export function useOpenLatestAUSAgreement() {
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();

  const [loadingAUSAgreement, setLoadingAUSAgreement] = useState(false);
  const openLatestAUSAgreement = () => {
    // prevent double load
    if (loadingAUSAgreement) {
      return;
    }
    if (isMounted()) {
      setLoadingAUSAgreement(true);
    }
    try {
      const newWindow = window.open();
      (async () => {
        if (newWindow) {
          newWindow.location = (
            await ComplianceManagerRead.GetLatestAUSAgreement({})
          ).ausAgreement.pdfURL;
        }
      })();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error getting latest AUS agreement: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Getting Latest Terms & Conditions: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    if (isMounted()) {
      setLoadingAUSAgreement(false);
    }
  };

  return {
    openLatestAUSAgreement,
    loadingAUSAgreement,
  };
}

export function useOpenLatestPaaSAgreement() {
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();

  const [loadingPaaSAgreement, setLoadingPaaSAgreement] = useState(false);
  const openLatestPaaSAgreement = () => {
    // prevent double load
    if (loadingPaaSAgreement) {
      return;
    }
    if (isMounted()) {
      setLoadingPaaSAgreement(true);
    }
    try {
      const newWindow = window.open();
      (async () => {
        if (newWindow) {
          newWindow.location = (
            await ComplianceManagerRead.GetLatestPaaSAgreement({})
          ).paasAgreement.pdfURL;
        }
      })();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error getting latest PaaS agreement: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Getting Latest Terms & Conditions: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    if (isMounted()) {
      setLoadingPaaSAgreement(false);
    }
  };

  return {
    openLatestPaaSAgreement,
    loadingPaaSAgreement,
  };
}
