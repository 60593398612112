import { Amount as LedgerAmount } from "james/ledger/Amount";
import { BankAccount, DefundOrderStateController } from "james/banking";
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FadeProps,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { useApplicationContext } from "context/Application/Application";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { TransactionTypes } from "types/gtm";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { SubmitDefundOrderResponse } from "james/banking/defundOrderStateController";
import { useErrorContext } from "context/Error";

interface DefundOrderConfimationDialogProps {
  open: boolean;
  onClose: () => void;
  defundAmount: LedgerAmount;
  bankAccount: BankAccount;
  expressDefund: boolean;
  accountNumber: string;
  onDefundOrderSubmissionComplete: () => void;
  accountID: string;
}

export function DefundOrderConfimationDialog(
  props: DefundOrderConfimationDialogProps,
) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const { registerNotificationCallback } = useNotificationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [disableDefundOrderButton, setDisableDefundOrderButton] =
    useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { pushDefundAccountComplete } = useGTMTriggersPusher();

  const handleSubmitDefundOrder = async () => {
    setDisableDefundOrderButton(true);
    let submitDefundOrderResponse: SubmitDefundOrderResponse;
    try {
      submitDefundOrderResponse =
        await DefundOrderStateController.SubmitDefundOrder({
          context: authContext,
          amount: props.defundAmount,
          bankAccountID: props.bankAccount.id,
          accountID: props.accountID,
          express: props.expressDefund,
        });

      // close the card
      props.onDefundOrderSubmissionComplete();

      // notify the user a transfer is in progress
      enqueueSnackbar("Defund Order Submission In Progress", {
        variant: "info",
      });
    } catch (e) {
      console.error(`error executing defund order`, e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Processing Defund Order: ${err.message}`, {
        variant: "warning",
      });
      setDisableDefundOrderButton(false);
      return;
    }

    try {
      // register callback to fire once the transfer has been submitted
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: submitDefundOrderResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === submitDefundOrderResponse.transactionID
          ) {
            // push the account defunded analytics event
            pushDefundAccountComplete({
              account_transaction_type: TransactionTypes.Defund,
              account_no: props.accountNumber,
              account_transaction_amount: props.defundAmount.value.toString(),
            });

            enqueueSnackbar(
              `Success! Defund Order: ${submitDefundOrderResponse.number} Awaiting Settlement`,
              {
                variant: "success",
              },
            );
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === submitDefundOrderResponse.transactionID
          ) {
            enqueueSnackbar("Error! Defund Order Submission Failed", {
              variant: "error",
            });
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === submitDefundOrderResponse.transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the defund order and its status is being investigated",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error(
        "error registering for defund order transaction notifications",
        e,
      );
      enqueueSnackbar(
        "Warning! Error Registering for Defund Order Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": isMobile
            ? {
                width: "100%",
                padding: "0px",
                margin: "0px",
                position: "absolute",
                bottom: 0,
              }
            : {
                maxWidth: "392px",
                width: "100%",
              },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (Paper props typescript type does not allow for data- attributes)
        "data-component-info": JSON.stringify({
          component_id: "defund_account_confirmation",
          component_business_name: "Defund your account",
          component_title: "defund confirmation",
          component_driver: InteractionDriver.DefundAccount,
        } as DataComponentInfo),
      }}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
      open={props.open}
    >
      <DialogTitle
        sx={(theme) => ({
          height: 52,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
            })}
          >
            <Typography variant="h5">Defund Confirmation</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="defundOrderConfirmation-close-button"
              size="small"
              onClick={props.onClose}
              data-link-info={JSON.stringify({
                content_interaction_id: "defund-confirm",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Button,
                content_interaction_text: "close",
                content_interaction_driver: InteractionDriver.DefundAccount,
              } as DataLinkInfoType)}
              disabled={false}
            >
              <CloseIcon
                data-link-info={JSON.stringify({
                  content_interaction_id: "defund-confirm",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Button,
                  content_interaction_text: "close",
                  content_interaction_driver: InteractionDriver.DefundAccount,
                } as DataLinkInfoType)}
              />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateRows: "repeat(3,auto)",
            gridRowGap: theme.spacing(2),
            padding: `${theme.spacing(4)}`,
          })}
        >
          <Typography variant="body1">
            Please confirm your defund details.
          </Typography>
          <div>
            <Typography color="textSecondary" variant="caption">
              Bank Name
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              {props.bankAccount.bankName}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Bank Account Number
            </Typography>
            <Typography
              sx={{
                wordBreak: "break-word",
              }}
              color="textSecondary"
              variant="subtitle1"
            >
              {props.bankAccount.number}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Defunded Type
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              {props.expressDefund ? "Express Defund" : "Standard Defund"}
            </Typography>
          </div>
        </Box>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(4, 4, 5, 4),
            backgroundColor: theme.palette.custom.midnight,
          })}
        >
          <Typography color="textSecondary" variant="subtitle1">
            Amount
          </Typography>
          <Amount
            valueTypographyProps={{
              variant: "h4",
              sx: (theme) => ({
                color: theme.palette.warning.main,
                fontWeight: theme.typography.fontWeightBold,
              }),
            }}
            codeTypographyProps={{
              variant: "h4",
              sx: (theme) => ({
                color: alpha(theme.palette.warning.main, 0.5),
                fontWeight: theme.typography.fontWeightBold,
              }),
            }}
            amount={props.defundAmount}
          />
          <Button
            id="defundOrderConfirmationDialog-confirm-button"
            data-link-info={JSON.stringify({
              content_interaction_id: "defund-confirm",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "confirm",
              content_interaction_type: InteractionType.Button,
              content_interaction_driver:
                InteractionDriver.DefundAccountComplete,
            } as DataLinkInfoType)}
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              height: isMobile ? 48 : 36,
            })}
            variant="contained"
            disabled={disableDefundOrderButton}
            fullWidth
            color="primary"
            onClick={handleSubmitDefundOrder}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" {...tprops} />;
};

const fadeTransition = (tprops: FadeProps) => {
  return <Fade in {...tprops} />;
};
