import React, { useRef } from "react";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";
import {
  Model as MarketDirectOrderViewModel,
  Model as DirectOrderModel,
} from "james/views/marketDirectOrderView";
import {
  Backdrop,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { Error as ErrorIcon, HowToReg } from "@mui/icons-material";
import { DirectOrderState, DirectOrderType } from "james/market/DirectOrder";
import { Column } from "components/Table/BPTable/BPTable";
import { TableMode } from "../../../Table";
import { Amount } from "components/Ledger/Amount";
import { Client } from "james/client";
import { DirectOrderStateChip } from "../../Chips";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { useApplicationContext } from "context/Application/Application";

interface OrderScrollCardProps {
  data: DirectOrderModel;
  tableMode: TableMode;
  onClick: () => void;
}

export const OrderScrollCard = ({
  data,
  tableMode,
  onClick,
}: OrderScrollCardProps): JSX.Element => {
  const { myClient } = useApplicationContext();

  const theme = useTheme();

  if (!myClient) {
    return (
      <Backdrop
        sx={() => ({ zIndex: (theme) => theme.zIndex.drawer + 1 })}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent sx={(theme) => ({ padding: theme.spacing(1, 2) })}>
        {getColumns(tableMode, myClient).map((col, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: theme.spacing(0.5, 0),
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                width: "75px",
              })}
            >
              {col.label}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "calc(100% - 75px - 16px)",
              }}
            >
              {renderCellData(data, false, col.field, col.accessor)}
            </Box>
          </Box>
        ))}
      </CardContent>
      <CardActionArea
        sx={{
          backgroundColor: theme.palette.custom.grape,
          padding: theme.spacing(2),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "42px",
        }}
        onClick={onClick}
      >
        <Typography>VIEW ORDER</Typography>
      </CardActionArea>
    </Card>
  );
};

const InitiatingUserIcon = ({ id }: { id: string }): JSX.Element => {
  const theme = useTheme();

  return (
    <HowToReg
      sx={{
        color: theme.palette.custom.lavender,
        fontSize: 16,
      }}
      id={id}
    />
  );
};

const getColumns = (
  tableMode: TableMode,
  appContextMyClient: Client,
): Column[] => {
  // prepare index of the columns that should be in the table the active table mode
  const { current: commonColumns } = useRef<Column[]>([
    {
      label: "Order No.",
      field: "number",
      maxWidth: 160,
      minWidth: 100,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as DirectOrderModel;
        return (
          <Typography
            noWrap
            id={`orderNumber-${marketDirectOrderViewModel.number}`}
            color="inherit"
            variant="body1"
          >
            {marketDirectOrderViewModel.number}
          </Typography>
        );
      },
    },
    {
      label: "Buyer",
      field: "",
      maxWidth: 160,
      minWidth: 100,
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as DirectOrderModel;

        // if order is of type BUY
        if (marketDirectOrderViewModel.orderType === DirectOrderType.Buy) {
          // then the initiating party is the buyer
          return (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: "8px",
                alignItems: "center",
              }}
            >
              <Typography
                noWrap
                children={marketDirectOrderViewModel.initiatingPartyClientName}
              />
              <InitiatingUserIcon
                id={`table-buyer-initiatingUserIcon-${marketDirectOrderViewModel.number}-${marketDirectOrderViewModel.initiatingPartyClientName}`}
              />
            </Box>
          );
        }
        // otherwise this is a sell order, the counterparty is the seller
        return (
          <Typography
            noWrap
            children={marketDirectOrderViewModel.counterpartyClientName}
          />
        );
      },
    },
    {
      label: "Seller",
      field: "",
      minWidth: 100,
      maxWidth: 160,
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as DirectOrderModel;

        // if order is of type SELL
        if (marketDirectOrderViewModel.orderType === DirectOrderType.Sell) {
          // then the initiating party is the seller
          return (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: "8px",
                alignItems: "center",
              }}
            >
              <Typography
                noWrap
                children={marketDirectOrderViewModel.initiatingPartyClientName}
              />
              <InitiatingUserIcon
                id={`table-seller-initiatingUserIcon-${marketDirectOrderViewModel.number}-${marketDirectOrderViewModel.initiatingPartyClientName}`}
              />
            </Box>
          );
        }
        // otherwise this is a buy order, the counterparty is the buyer
        return (
          <Typography
            noWrap
            children={marketDirectOrderViewModel.counterpartyClientName}
          />
        );
      },
    },
    {
      label: "Instr.",
      field: "",
      minWidth: 100,
      maxWidth: 160,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as DirectOrderModel;
        return (
          <Typography
            noWrap
            id={`table-assetName-typography-${marketDirectOrderViewModel.assetName}-${marketDirectOrderViewModel.directOrderID}`}
          >
            {marketDirectOrderViewModel.assetName}
          </Typography>
        );
      },
    },
  ]);

  // prepare columns required for different table modes
  const { current: tableModeColumnIndex } = useRef<{ [key: string]: Column[] }>(
    {
      [TableMode.awaitingConfirmation]: [
        ...commonColumns,
        {
          label: "Date",
          field: "orderDateTime",
          minWidth: 100,
          maxWidth: 160,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const theme = useTheme();
            const marketDirectOrderViewModel = data as DirectOrderModel;
            const orderDateTimeDayjs = dayjs(
              marketDirectOrderViewModel.orderDateTime,
            );
            const submittedMinutesAgo = dayjs().diff(
              orderDateTimeDayjs,
              "minutes",
              true,
            );

            return (
              <Typography
                noWrap
                children={orderDateTimeDayjs.format(DateFormat)}
                sx={[
                  {
                    fontWeight: "bold",
                  },
                  submittedMinutesAgo < 5 && {
                    color: theme.palette.success.main,
                  },
                  submittedMinutesAgo >= 5 &&
                    submittedMinutesAgo < 10 && {
                      color: theme.palette.warning.main,
                    },
                  submittedMinutesAgo >= 10 && {
                    color: theme.palette.error.main,
                  },
                ]}
              />
            );
          },
        },
        {
          field: "amountIncl.value.float",
          label: "Amount",
          minWidth: 100,
          maxWidth: 160,

          accessor: (data) => {
            const marketDirectOrderViewModel = data as DirectOrderModel;

            const viewingAsInitiatingParty =
              appContextMyClient.id ===
              marketDirectOrderViewModel.initiatingPartyClientID;
            const isBuy =
              marketDirectOrderViewModel.orderType === DirectOrderType.Buy;

            return (
              <Amount
                amount={
                  viewingAsInitiatingParty
                    ? isBuy // View as initiating party to a...
                      ? // Buy:
                        // Show how much stablecoin they pay
                        marketDirectOrderViewModel.amountIncl
                      : // Sell:
                        // Show how much stablecoin they receive
                        marketDirectOrderViewModel.amountIncl
                    : isBuy // View as counterparty to a...
                      ? // Buy:
                        // Show how much stablecoin they receive
                        marketDirectOrderViewModel.amountIncl.setValue(
                          marketDirectOrderViewModel.amountIncl.value.minus(
                            marketDirectOrderViewModel.feeAmount.value,
                          ),
                        )
                      : // Sell:
                        // Show how much stablecoin they pay
                        marketDirectOrderViewModel.amountIncl.setValue(
                          marketDirectOrderViewModel.amountIncl.value.plus(
                            marketDirectOrderViewModel.feeAmount.value,
                          ),
                        )
                }
              />
            );
          },
        },
      ],
      [TableMode.history]: [
        ...commonColumns,
        {
          label: "Date",
          field: "orderDateTime",
          minWidth: 100,
          maxWidth: 160,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const marketDirectOrderViewModel = data as DirectOrderModel;

            return dayjs(marketDirectOrderViewModel.orderDateTime).format(
              DateFormat,
            );
          },
        },
        {
          label: "State",
          field: "state",
          maxWidth: 160,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const model = data as MarketDirectOrderViewModel;
            if (model.state === DirectOrderState.Failed) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    gap: theme.spacing(1),
                    alignItems: "center",
                  })}
                >
                  <DirectOrderStateChip
                    state={(data as MarketDirectOrderViewModel).state}
                  />
                  <Tooltip
                    title={model.failureReasons.join(", ")}
                    placement={"top"}
                  >
                    <ErrorIcon
                      sx={{
                        color: "secondary.light",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            }
            return (
              <DirectOrderStateChip
                state={(data as MarketDirectOrderViewModel).state}
              />
            );
          },
        },
      ],
    },
  );

  return tableModeColumnIndex[tableMode];
};

const renderCellData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any },
  rowIsSelected: boolean,
  field?: string,
  accessor?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { [key: string]: any },
    rowIsSelected: boolean,
  ) => string | number | React.ReactNode,
): string | number | React.ReactNode => {
  try {
    // if an accessor function was provided, call it with the data
    let accessedData: string | number | React.ReactNode = "";
    if (accessor) {
      accessedData = accessor(data, rowIsSelected);
    } else if (field) {
      // otherwise use provided field to get data
      accessedData = data[field];
    } else {
      console.error("neither field nor accessor provided to column");
      return "-";
    }
    if (isString(accessedData) || isNumber(accessedData)) {
      return (
        <Typography variant="body1" children={accessedData} color="inherit" />
      );
    }
    return accessedData;
  } catch (e) {
    console.error("error rendering cell data", e);
    return "-";
  }
};
