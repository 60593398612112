import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useCompanyDetailViewStore } from "../store";
import { Updater } from "james/legal/company/Updater";

export const useSaveCompanyDetails = () => {
  const store = useCompanyDetailViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const saveCompanyDetails = async () => {
    store.setLoading(true);
    try {
      // save company updates
      const response = await Updater.UpdateCompanyUnscoped({
        context: authContext,
        company: store.getCompanyObject(),
      });

      // then update the state
      store.setCachedCompany(response.company);
      store.setStateFrom(response.company);
      store.setLastUpdateBy(response.company.auditEntry.userID);
      store.setLastUpdateDate(response.company.auditEntry.time);

      // display success message.
      enqueueSnackbar("Company Details Saved", {
        variant: "success",
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(e, "error saving company details");
    }
    store.setLoading(false);
  };

  return {
    saveCompanyDetails,
  };
};
