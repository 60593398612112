import React from "react";
import { Header } from "components/Cards/MarketDirectOrderViewCard/Components/Header";
import { OrderDirectionAndState } from "components/Cards/MarketDirectOrderViewCard/Components/OrderDirectionAndState";
import { AvatarAndDirection } from "components/Cards/MarketDirectOrderViewCard/Components/AvatarAndDirection";
import { HeaderProps } from "components/Cards/MarketDirectOrderViewCard/Components/Header/Header";
import { Box, Typography, useTheme } from "@mui/material";
import cx from "classnames";
import { Amount } from "components/Ledger/Amount";
import { DirectOrderState, DirectOrderType } from "james/market/DirectOrder";
import { StyledCard, classes } from "../cardStyle";

interface DefaultCardProps extends HeaderProps {
  viewingAsInitiatingParty: boolean;
  close?: () => void;
}

const presentTenseCases: (DirectOrderState | "")[] = [
  DirectOrderState.Pending,
  DirectOrderState.AwaitingConfirmation,
  DirectOrderState.Cancelling,
  DirectOrderState.Cancelled,
  DirectOrderState.Declining,
  DirectOrderState.Declined,
  DirectOrderState.Failing,
  DirectOrderState.Failed,
];

export function DefaultCard(props: DefaultCardProps) {
  const theme = useTheme();

  const isBuy =
    props.marketDirectOrderViewModel.orderType === DirectOrderType.Buy;

  return (
    <StyledCard
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        [theme.breakpoints.down("sm")]: {
          borderRadius: 0,
          height: "100%",
        },
      }}
    >
      <Header {...props} />
      <Box
        className={cx(
          classes.sectionWithPaperBackground,
          classes.sectionWithPaddingLR,
          classes.sectionWithRows2Gap,
        )}
      >
        <OrderDirectionAndState
          viewingAsInitiatingParty={props.viewingAsInitiatingParty}
          marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        />
        <AvatarAndDirection
          name={
            props.viewingAsInitiatingParty
              ? props.marketDirectOrderViewModel.initiatingPartyClientName
              : props.marketDirectOrderViewModel.counterpartyClientName
          }
          pictureDLURL={
            props.viewingAsInitiatingParty
              ? props.marketDirectOrderViewModel
                  .initiatingPartyProfilePictureDLURL
              : props.marketDirectOrderViewModel.counterpartyProfilePictureDLURL
          }
          pastTense={
            !presentTenseCases.includes(props.marketDirectOrderViewModel.state)
          }
          receives={false} // Viewer pays...
        />
        <Box className={classes.sectionWithRows1Gap}>
          <Box className={classes.sectionWith2EqualColumns}>
            <Typography
              color="textSecondary"
              variant="caption"
              children={
                props.viewingAsInitiatingParty
                  ? isBuy
                    ? "Amount"
                    : "Tokens"
                  : isBuy
                    ? "Tokens"
                    : "Amount"
              }
            />
          </Box>
          <Box className={classes.sectionWith2EqualColumns}>
            <Amount
              reverse={props.viewingAsInitiatingParty ? !isBuy : isBuy}
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: props.viewingAsInitiatingParty
                  ? isBuy
                    ? 2
                    : 7
                  : isBuy
                    ? 7
                    : 2,
                addDecimalPadding: props.viewingAsInitiatingParty
                  ? isBuy
                  : !isBuy,
              }}
              amount={
                props.viewingAsInitiatingParty
                  ? isBuy
                    ? props.marketDirectOrderViewModel.amountIncl
                    : props.marketDirectOrderViewModel.tokens
                  : isBuy
                    ? props.marketDirectOrderViewModel.tokens
                    : props.marketDirectOrderViewModel.amountIncl.setValue(
                        props.marketDirectOrderViewModel.amountIncl.value.plus(
                          props.marketDirectOrderViewModel.feeAmount.value.plus(
                            props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                              props.marketDirectOrderViewModel.vatRate,
                            ),
                          ),
                        ),
                      )
              }
            />
          </Box>
        </Box>
        {props.viewingAsInitiatingParty && (
          <>
            <Box className={classes.tradeFeeLayout}>
              <Typography
                variant="caption"
                className={classes.disabledText}
                children="Direct Order Fee:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                valueTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketDirectOrderViewModel.feeAmount}
              />
            </Box>
            <Box className={classes.tradeFeeLayout}>
              <Typography
                variant="caption"
                className={classes.disabledText}
                children="VAT on Fee:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                valueTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketDirectOrderViewModel.feeAmount.setValue(
                  props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                    props.marketDirectOrderViewModel.vatRate,
                  ),
                )}
              />
            </Box>
          </>
        )}
      </Box>
      <Box
        className={cx(
          classes.sectionWithPaddingLR,
          classes.sectionWithRows2Gap,
        )}
      >
        <AvatarAndDirection
          pastTense={
            !presentTenseCases.includes(props.marketDirectOrderViewModel.state)
          }
          name={
            props.viewingAsInitiatingParty
              ? props.marketDirectOrderViewModel.initiatingPartyClientName
              : props.marketDirectOrderViewModel.counterpartyClientName
          }
          pictureDLURL={
            props.viewingAsInitiatingParty
              ? props.marketDirectOrderViewModel
                  .initiatingPartyProfilePictureDLURL
              : props.marketDirectOrderViewModel.counterpartyProfilePictureDLURL
          }
          receives // Viewer receives...
        />
        <Box className={classes.sectionWith2EqualColumns}>
          <Typography
            color="textSecondary"
            variant="caption"
            children={
              props.viewingAsInitiatingParty
                ? isBuy
                  ? "Tokens"
                  : "Amount"
                : isBuy
                  ? "Amount"
                  : "Tokens"
            }
          />
          <Typography
            color="textSecondary"
            variant="caption"
            children="Price per Token"
          />
        </Box>
        <Box className={classes.sectionWith2EqualColumns}>
          <Amount
            reverse={props.viewingAsInitiatingParty ? isBuy : !isBuy}
            codeTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountCode,
            }}
            valueTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountValue,
            }}
            formatTextNumOpts={{
              noDecimalPlaces: props.viewingAsInitiatingParty
                ? isBuy
                  ? 7
                  : 2
                : isBuy
                  ? 2
                  : 7,
              addDecimalPadding: props.viewingAsInitiatingParty
                ? !isBuy
                : isBuy,
            }}
            amount={
              props.viewingAsInitiatingParty
                ? isBuy
                  ? props.marketDirectOrderViewModel.tokens
                  : props.marketDirectOrderViewModel.amountIncl
                : isBuy
                  ? props.marketDirectOrderViewModel.amountIncl.setValue(
                      props.marketDirectOrderViewModel.amountIncl.value
                        .minus(props.marketDirectOrderViewModel.feeAmount.value)
                        .minus(
                          props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                            props.marketDirectOrderViewModel.vatRate,
                          ),
                        ),
                    )
                  : props.marketDirectOrderViewModel.tokens
            }
          />
          <Amount
            codeTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountCode,
            }}
            valueTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountValue,
            }}
            formatTextNumOpts={{
              noDecimalPlaces: 2,
              addDecimalPadding: true,
            }}
            amount={props.marketDirectOrderViewModel.price}
          />
        </Box>
      </Box>
    </StyledCard>
  );
}
