import { InviteClientClaims } from "james/security/claims";
import { styled } from "@mui/material/styles";
import { TokenValidator } from "james/security/token";
import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { CompanyRepresentative } from "james/legal";
import { Company } from "james/legal/company/Company";
import { Address } from "james/location";

import meshLogo from "assets/images/logo/meshLogo.svg";
import { Confirmation, Framework, Landing } from "./components";

const PREFIX = "CompanyRegistrationView";

const classes = {
  root: `${PREFIX}-root`,
  meshLogo: `${PREFIX}-meshLogo`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.meshLogo}`]: {
    width: 230,
  },

  [`& .${classes.content}`]: {
    paddingTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: 800,
  },
}));

export enum CompanyRegStep {
  landing = "landing",
  companyProfile = "companyProfile",
  contactDetails = "contactDetails",
  companyDetails = "companyDetails",
  companyRepresentative = "companyRepresentative",
  connectedIndividuals = "connectedIndividuals",
  connectedNonIndividuals = "connectedNonIndividuals",
  clientAdministratorData = "clientAdministratorData",
  confirmation = "confirmation",
}

const tokenValidationSteps = {
  notStarted: 0,
  inProgress: 1,
  fail: 2,
  success: 3,
};

export const CompanyRegistrationView = () => {
  const [tokenValidationStep, setTokenValidationStep] = useState(
    tokenValidationSteps.notStarted,
  );
  const [activeStep, setActiveStep] = useState<CompanyRegStep>(
    CompanyRegStep.landing,
  );
  const [inviteClientClaims, setInviteClientClaims] = useState(
    new InviteClientClaims(),
  );

  const [searchParams] = useSearchParams();

  const [company, setCompany] = React.useState(
    new Company({
      ...new Company(),
      registeredAddress: new Address(),
      businessAddress: new Address(),
      headOfficeAddress: new Address(),
      companyRepresentative: new CompanyRepresentative({
        ...new CompanyRepresentative(),
        physicalAddress: new Address(),
        postalAddress: new Address(),
      } as CompanyRepresentative),
    } as Company),
  );
  const [admin, setAdmin] = React.useState({
    name: "",
    surname: "",
    email: "",
  });
  const onNext = (step: CompanyRegStep) => {
    setActiveStep(step);
  };

  // parse and validate token on load
  useEffect(() => {
    const parseAndValidateToken = async () => {
      try {
        setTokenValidationStep(tokenValidationSteps.inProgress);

        // get token from url
        const jwt = searchParams.get("token");
        if (jwt === null) {
          setTokenValidationStep(tokenValidationSteps.fail);
          console.error("no token in url");
          return;
        }

        // validate token
        await TokenValidator.Validate({ token: jwt });

        // parse token to claims
        setInviteClientClaims(InviteClientClaims.NewFromJWT(jwt));
        setTokenValidationStep(tokenValidationSteps.success);
      } catch (e) {
        console.error("error parsing/validating token from url", e);
        setTokenValidationStep(tokenValidationSteps.fail);
      }
    };
    parseAndValidateToken().finally();
  }, []);

  useEffect(() => {
    const setCompanyFromInviteClaims = () => {
      setCompany(
        (c) =>
          new Company({
            ...c,
            registeredName: inviteClientClaims.companyName,
          }),
      );
    };
    setCompanyFromInviteClaims();
  }, [inviteClientClaims]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (name: string) => (value: any) => {
    const newState = new Company({
      ...company,
      [name]: value,
    });
    setCompany(newState);
  };

  const renderStep = (step: CompanyRegStep) => {
    switch (step) {
      case CompanyRegStep.landing:
        return <Landing onNext={onNext} />;

      case CompanyRegStep.confirmation:
        return (
          <Confirmation
            inviteClientClaims={inviteClientClaims}
            company={company}
            admin={admin}
          />
        );

      default:
        return (
          <Framework
            inviteClientClaims={inviteClientClaims}
            admin={admin}
            company={company}
            activeStep={step}
            setActiveStep={setActiveStep}
            onChange={onChange}
            setAdmin={setAdmin}
          />
        );
    }
  };

  return (
    <Root id="company-registration-root">
      <img className={classes.meshLogo} alt="" src={meshLogo} />
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h1" children="Company Registration" />
          {(() => {
            switch (tokenValidationStep) {
              case tokenValidationSteps.notStarted:
              case tokenValidationSteps.inProgress:
                return <CircularProgress />;

              case tokenValidationSteps.success:
                return renderStep(activeStep);

              case tokenValidationSteps.fail:
              default:
                return <Navigate replace to="/" />;
            }
          })()}
        </div>
      </div>
    </Root>
  );
};
