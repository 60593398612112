import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { useEffect, useRef, useState } from "react";
import { ValidationPhase } from "./ValidationPhase";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface GetValidationPhaseRequest {
  context: Context;
}

export interface GetValidationPhaseResponse {
  validationPhase: ValidationPhase;
}

export interface ValidationPhaseInspectorServiceProvider {
  serviceProvider: string;

  GetValidationPhase(
    request: GetValidationPhaseRequest,
  ): Promise<GetValidationPhaseResponse>;
}

const ValidationPhaseInspectorServiceProviderName =
  "consistency-ValidationPhaseInspector";

export const ValidationPhaseInspector: ValidationPhaseInspectorServiceProvider =
  {
    serviceProvider: ValidationPhaseInspectorServiceProviderName,
    async GetValidationPhase(
      request: GetValidationPhaseRequest,
    ): Promise<GetValidationPhaseResponse> {
      const response = await jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${ValidationPhaseInspector.serviceProvider}.GetValidationPhase`,
        request,
      });
      return { validationPhase: new ValidationPhase(response.validationPhase) };
    },
  };

export function useGetValidationPhase(
  initialGetValidationPhaseRequest?: GetValidationPhaseRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const [getValidationPhaseRequest, setGetValidationPhaseRequest] =
    useState<GetValidationPhaseRequest>(
      initialGetValidationPhaseRequest || { context: authContext },
    );
  const [getValidationPhaseResponse, setGetValidationPhaseResponse] =
    useState<GetValidationPhaseResponse>({
      validationPhase: new ValidationPhase(),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const reloadRef = useRef<() => void>(() =>
    setGetValidationPhaseRequest({
      context: authContext,
    }),
  );
  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    (async () => {
      setLoading(true);
      setError(undefined);
      try {
        setGetValidationPhaseResponse(
          await ValidationPhaseInspector.GetValidationPhase(
            getValidationPhaseRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting validation phase: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error getting validation phase: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    })();
  }, [getValidationPhaseRequest, shouldNotExecute]);

  return {
    getValidationPhaseRequest,
    setGetValidationPhaseRequest,
    getValidationPhaseResponse,
    loading,
    error,
    reload: reloadRef.current,
  };
}
