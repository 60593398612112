import React from "react";
import { Chip, ChipProps, Theme } from "@mui/material";
import { LedgerAccountState } from "james/ledger";

export interface SourceAccountStateChipProps {
  state: LedgerAccountState | "";
  chipProps?: ChipProps;
}

export function SourceAccountStateChip(props: SourceAccountStateChipProps) {
  switch (props.state) {
    case LedgerAccountState.OpeningAccountState:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.warning.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case LedgerAccountState.Open:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case LedgerAccountState.Closed:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case LedgerAccountState.UnderInvestigationAccountState:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}
