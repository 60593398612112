import React, { useEffect, useState } from "react";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import { GroupUser } from "james/user/GroupUser";
import { Autocomplete, Chip, TextField, styled } from "@mui/material";
import { useUserCompositorySearchGroupUsers } from "james/user/Compository";
import { TextListCriterionType } from "james/search/criterion/text/List";
import { TextSubstringCriterionType } from "james/search/criterion/text/Substring";
import { RoleType } from "james/role";
import { AllRoleTypes } from "james/role/Role";
import { AllUserRegistrationStatus, UserState } from "james/user/User";
import { Query } from "james/search/query";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { BPTable } from "components/Table/BPTable/BPTable";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "Users";

const classes = {
  filterField: `${PREFIX}-filterField`,
  clearIcon: `${PREFIX}-clearIcon`,
  chip: `${PREFIX}-chip`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.filterField}`]: {
    minWidth: 150,
  },

  [`& .${classes.clearIcon}`]: {
    marginRight: -6,
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.chip}`]: {
    margin: "2px",
  },
}));

const initialQuery = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

export function Users() {
  const { authContext } = useApplicationContext();
  const {
    searchGroupUsersResponse,
    setSearchGroupUsersRequest,
    searchGroupUsersRequest,
    loading,
  } = useUserCompositorySearchGroupUsers({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const [userNameCriterion, setUserNameCriterion] = useState<
    TextSubstringCriterionType | undefined
  >(undefined);
  const [groupNameCriterion, setGroupNameCriterion] = useState<
    TextSubstringCriterionType | undefined
  >(undefined);
  const [userStatusCriterion, setUserStatusCriterion] = useState<
    TextListCriterionType | undefined
  >(undefined);
  const [roleCriterion, setRoleCriterion] = useState<
    TextListCriterionType | undefined
  >(undefined);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};
    if (userNameCriterion) {
      criteria = {
        ...criteria,
        fullName: userNameCriterion,
      };
    }
    if (groupNameCriterion) {
      criteria = {
        ...criteria,
        "groups.name": groupNameCriterion,
      };
    }
    if (roleCriterion) {
      criteria = {
        ...criteria,
        "roles.name": roleCriterion,
      };
    }
    if (userStatusCriterion) {
      criteria = {
        ...criteria,
        registrationStatus: userStatusCriterion,
      };
    }
    setSearchGroupUsersRequest({
      context: searchGroupUsersRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    setSearchGroupUsersRequest,
    userNameCriterion,
    groupNameCriterion,
    roleCriterion,
    userStatusCriterion,
    searchGroupUsersRequest.context,
  ]);

  return (
    <Root>
      <BPTable
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Users"
        loading={loading}
        columns={[
          {
            label: "Full Name",
            field: "fullName",
          },
          {
            label: "Groups",
            field: "groups",
            maxWidth: 300,
            accessor: (data) =>
              (data as GroupUser).groups.map((g) => (
                <Chip
                  className={classes.chip}
                  key={g.name}
                  size="small"
                  label={g.name}
                  color="info"
                />
              )),
          },
          {
            label: "Roles",
            field: "roles",
            maxWidth: 400,
            accessor: (data) =>
              (data as GroupUser).roles.map((r) => (
                <Chip
                  className={classes.chip}
                  key={r.id}
                  size="small"
                  label={r.name}
                  color="info"
                />
              )),
          },
          {
            label: "Status",
            field: "state",
          },
        ]}
        filters={[
          <TextField
            variant="outlined"
            margin="dense"
            label="Search"
            placeholder="start typing..."
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              if (e.target.value === "") {
                setUserNameCriterion(undefined);
              } else {
                setUserNameCriterion(TextSubstringCriterion(e.target.value));
              }
            }}
          />,
          <TextField
            variant="outlined"
            margin="dense"
            label="Group Name"
            placeholder="start typing..."
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              if (e.target.value === "") {
                setGroupNameCriterion(undefined);
              } else {
                setGroupNameCriterion(TextSubstringCriterion(e.target.value));
              }
            }}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            options={AllRoleTypes}
            filterSelectedOptions
            onChange={(_, selected) => {
              if (selected.length) {
                setRoleCriterion(TextListCriterion(selected as RoleType[]));
              } else {
                setRoleCriterion(undefined);
              }
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.filterField}
                label="Role"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  searchGroupUsersRequest.criteria["roles.name"]
                    ? undefined
                    : "select..."
                }
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            options={AllUserRegistrationStatus}
            filterSelectedOptions
            onChange={(_, selected) => {
              if (selected.length) {
                setUserStatusCriterion(
                  TextListCriterion(selected as UserState[]),
                );
              } else {
                setUserStatusCriterion(undefined);
              }
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.filterField}
                label="Status"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  searchGroupUsersRequest.criteria.registrationStatus
                    ? undefined
                    : "select..."
                }
              />
            )}
          />,
        ]}
        data={searchGroupUsersResponse.records}
        query={searchGroupUsersRequest.query}
        onQueryChange={(query) =>
          setSearchGroupUsersRequest({
            ...searchGroupUsersRequest,
            query,
          })
        }
        totalNoRecords={searchGroupUsersResponse.total}
      />
    </Root>
  );
}
