/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import {
  FilterList as FilterIcon,
  KeyboardArrowDown as ExpandRowIcon,
  KeyboardArrowUp as CollapseRowIcon,
  UnfoldLess as CollapseAllRowsIcon,
} from "@mui/icons-material";
import {
  StyledPaper,
  classes,
  tableFilterPanelHeight,
  tableTitleRowHeight,
} from "components/Table/style";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { usePrevious } from "hooks";
import { ExpandRowComponentProps } from "../BPTable/BPTable";

interface FETableProps {
  columns: Column[];
  noDataSplashComponent?: ReactNode;
  data: { [key: string]: any }[];
  title: string | ReactNode;
  height?: number;
  initialRowsPerPage?: number;
  initialDenseTable?: boolean;
  filters?: React.ReactNode[];
  toolBarControls?: React.ReactNode[];
  onSelectedDataChange?: (allSelectedData: { [key: string]: any }[]) => void;
  disableSelect?: boolean;
  onSingleSelectChange?: (
    selectedRowData: { [key: string]: any } | undefined,
    idx: number,
  ) => void;
  onRowClick?: (rowClickProps: {
    clickedRowData: { [key: string]: any };
    clickedRowIdx: number;
  }) => void;
  singleSelect?: boolean;
  expandRowComponent?: ExpandRowComponentProps;
  selectedRowCheck?: (selectedRowData: { [key: string]: any }) => boolean;
  onFilterPanelClose?: () => void;
  backgroundColor?: string;
  loading?: boolean;
  style?: SxProps<Theme>;
}

interface Column {
  field: string;
  label: React.ReactNode;
  minWidth?: number;
  align?: "right";
  accessor?: (
    data: { [key: string]: any },
    rowIsSelected: boolean,
  ) => string | number | React.ReactNode;
}

const renderCellData = (
  data: { [key: string]: any },
  rowIsSelected: boolean,
  field?: string,
  accessor?: (
    data: { [key: string]: any },
    rowIsSelected: boolean,
  ) => string | number | React.ReactNode,
): string | number | React.ReactNode => {
  try {
    // if an accessor function was provided, call it with the data
    let accessedData: string | number | React.ReactNode = "";
    if (accessor) {
      accessedData = accessor(data, rowIsSelected);
    } else if (field) {
      // otherwise use provided field to get data
      accessedData = data[field];
    } else {
      console.error("neither field nor accessor provided to column");
      return "-";
    }
    if (isString(accessedData) || isNumber(accessedData)) {
      return (
        <Typography variant="body1" children={accessedData} color="inherit" />
      );
    }
    return accessedData;
  } catch (e: any) {
    console.error("error rendering cell data", e);
    return "-";
  }
};

export function FETable(props: FETableProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.initialRowsPerPage ? props.initialRowsPerPage : 10,
  );
  const [paginationComponentHeight, setPaginationComponentHeight] =
    useState(56);
  const [tableHeadHeight, setTableHeadHeight] = useState(53);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const prevData = usePrevious(props.data);
  const [rowExpandedStates, setRowExpandedStates] = useState<{
    [key: number]: boolean;
  }>({});
  const [denseTable, setDenseTable] = useState(!!props.initialDenseTable);
  const tableHeight = props.height === undefined ? 600 : props.height;

  const tableWrapperHeight = filterPanelOpen
    ? tableHeight -
      tableTitleRowHeight -
      tableFilterPanelHeight -
      paginationComponentHeight
    : tableHeight - tableTitleRowHeight - paginationComponentHeight;

  useEffect(() => {
    // this checks for a change in data to remove row selection
    if (!isEqual(props.data, prevData)) {
      setRowExpandedStates({});
      setSelectedRowIndices([]);
      setSingleSelectRowIndex(-1);

      if (props.onSelectedDataChange) {
        props.onSelectedDataChange([]);
      }
      if (props.singleSelect && props.onSingleSelectChange) {
        props.onSingleSelectChange(undefined, -1);
      }

      return;
    }

    // if the data itself has not changed (i.e. contents of objects)
    // this checks to see if new objects have been constructed
    if (props.data.length > 0 && prevData !== undefined) {
      const prevDataTyped: { [key: string]: any }[] = prevData as unknown as {
        [key: string]: any;
      }[];
      if (props.data[0] !== prevDataTyped[0]) {
        setRowExpandedStates({});
        setSelectedRowIndices([]);
        setSingleSelectRowIndex(-1);

        if (props.onSelectedDataChange) {
          props.onSelectedDataChange([]);
        }
        if (props.singleSelect && props.onSingleSelectChange) {
          props.onSingleSelectChange(undefined, -1);
        }
      }
    }
  }, [props, props.data, prevData]);

  // check for change in filters prop so that if filters are removed the panel
  // is closed
  useEffect(() => {
    if (!(props.filters && props.filters.length) && filterPanelOpen) {
      setFilterPanelOpen(false);
    }
  }, [props.filters, filterPanelOpen]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [singleSelectRowIndex, setSingleSelectRowIndex] = useState(-1);
  const [selectedRowIndices, setSelectedRowIndices] = useState<number[]>([]);
  const handleRowSelect =
    (clickedRowIdx: number, clickedRowData: { [key: string]: any }) => () => {
      // run row click callback
      if (props.onRowClick) {
        props.onRowClick({
          clickedRowData,
          clickedRowIdx,
        });
      }

      if (props.disableSelect) {
        return;
      }

      if (props.singleSelect) {
        if (singleSelectRowIndex === clickedRowIdx) {
          // row already selected --> deselect
          setSingleSelectRowIndex(-1);
          setSelectedRowIndices([]);
          if (props.onSingleSelectChange) {
            props.onSingleSelectChange(undefined, -1);
          }
        } else {
          // different or no row selected --> select
          setSingleSelectRowIndex(clickedRowIdx);
          setSelectedRowIndices([clickedRowIdx]);
          if (props.onSingleSelectChange) {
            props.onSingleSelectChange(clickedRowData, clickedRowIdx);
          }
        }
      } else {
        // the rest of this deals with multi-select functionality
        let updatedSelectedRowIndices: number[] = [];
        // check if this row is already selected
        if (selectedRowIndices.includes(clickedRowIdx)) {
          // remove the selected row idx
          updatedSelectedRowIndices = selectedRowIndices.filter(
            (selectedRowIdx) => selectedRowIdx !== clickedRowIdx,
          );
        } else {
          // add it this row idx as a selected one
          updatedSelectedRowIndices = [...selectedRowIndices, clickedRowIdx];
        }
        setSelectedRowIndices(updatedSelectedRowIndices);
        if (props.onSelectedDataChange) {
          props.onSelectedDataChange(
            props.data.filter((data, idx) =>
              updatedSelectedRowIndices.includes(idx),
            ),
          );
        }
      }
    };
  const handleSelectAll = () => {
    if (props.disableSelect) {
      return;
    }

    if (selectedRowIndices.length === props.data.length) {
      // all rows already selected, clear rows selection
      setSelectedRowIndices([]);
      if (props.onSelectedDataChange) {
        props.onSelectedDataChange([]);
      }
    } else {
      // not all rows are selected, set all rows to selected
      setSelectedRowIndices(props.data.map((data, idx) => idx));
      if (props.onSelectedDataChange) {
        props.onSelectedDataChange(props.data);
      }
    }
  };

  const handleToggleFilterPanel = () => {
    if (filterPanelOpen) {
      // if the filter panel is open close it
      setFilterPanelOpen(false);
      // clear selected rows
      setSelectedRowIndices([]);
      // collapse all rows
      setRowExpandedStates({});
      // call optional callback to notify that selection has changed
      if (props.onSelectedDataChange) {
        props.onSelectedDataChange([]);
      }
      // call optional callback to notify that panel closed
      if (props.onFilterPanelClose) {
        props.onFilterPanelClose();
      }
    } else {
      // otherwise just open it
      setFilterPanelOpen(true);
    }
  };

  return (
    <StyledPaper className={classes.root} sx={props.style}>
      <div
        id="feTableToolbar"
        className={cx(classes.tableTitleLayout, {
          [classes.tableToolbarRowSelectedHighlight]:
            !props.singleSelect && selectedRowIndices.length > 0,
        })}
      >
        {!props.singleSelect && selectedRowIndices.length > 0 ? (
          // only show number selected in multi-select mode when there are 1 or more selected
          <Typography
            variant="subtitle1"
            color="inherit"
            id="tableToolbar-selectedItemsText"
          >
            {`${selectedRowIndices.length} Selected`}
          </Typography>
        ) : isString(props.title) ? (
          <Typography variant="subtitle1" id="tableToolbar-title">
            {props.title}
          </Typography>
        ) : (
          props.title
        )}
        <div className={classes.tableTitleControlLayout}>
          <Grid container spacing={1} alignItems="center">
            {props.toolBarControls
              ? props.toolBarControls.map((f, idx) => (
                  <Grid item key={idx}>
                    {f}
                  </Grid>
                ))
              : null}
            {props.filters &&
              !!props.filters.length && ( // only show filter button if filters given
                <Grid item>
                  <IconButton
                    size="small"
                    id="bpTable-toggleFilterPanelOpened-button"
                    onClick={handleToggleFilterPanel}
                    className={cx(classes.filterIcon, {
                      [classes.filterIconPanelOpen]: filterPanelOpen,
                    })}
                  >
                    <FilterIcon />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        </div>
      </div>
      <Collapse in={filterPanelOpen}>
        <div className={cx(classes.filterLayout, "meshScroll")}>
          <Grid container spacing={1} alignItems="flex-start">
            {props.filters
              ? props.filters.map((f, idx) => (
                  <Grid item key={idx}>
                    {f}
                  </Grid>
                ))
              : null}
          </Grid>
        </div>
      </Collapse>
      {props.loading ? (
        <Box
          className={cx(classes.tableWrapper, "meshScroll")}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: tableHeight - paginationComponentHeight,
            width: "100%",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : !props.data.length && props.noDataSplashComponent ? (
        <div
          className={cx(classes.tableWrapper, "meshScroll")}
          style={{ height: tableHeight - paginationComponentHeight }}
        >
          {props.noDataSplashComponent}
        </div>
      ) : (
        <>
          <div
            className={cx(classes.tableWrapper, "meshScroll")}
            style={{
              height: tableWrapperHeight,
            }}
          >
            <Table
              stickyHeader
              padding="none"
              size={denseTable ? "small" : "medium"}
            >
              <TableHead
                id="feTable-head"
                ref={(tableHeadRef: HTMLTableSectionElement) => {
                  if (!tableHeadRef) {
                    return;
                  }
                  if (
                    tableHeadRef.clientHeight &&
                    tableHeadRef.clientHeight !== tableHeadHeight
                  ) {
                    setTableHeadHeight(tableHeadRef.clientHeight);
                  }
                }}
                classes={{ root: classes.tableHead }}
              >
                <TableRow id="feTable-head-row">
                  {
                    // only show 'check all' check box if neither disableSelect nor singleSelect are set
                    !(props.disableSelect || props.singleSelect) && (
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        style={{
                          backgroundColor: props.backgroundColor
                            ? props.backgroundColor
                            : "inherit",
                        }}
                        padding="checkbox"
                      >
                        <Checkbox
                          id="tableHead-checkbox"
                          color="primary"
                          indeterminate={
                            !(
                              selectedRowIndices.length === props.data.length ||
                              selectedRowIndices.length === 0
                            )
                          }
                          checked={
                            selectedRowIndices.length !== 0 &&
                            props.data.length === selectedRowIndices.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )
                  }
                  {props.expandRowComponent && (
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      padding="checkbox"
                    >
                      <IconButton
                        size="small"
                        onClick={() => setRowExpandedStates({})}
                      >
                        <CollapseAllRowsIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  {props.columns.map((col, idx) => (
                    <TableCell
                      key={idx}
                      align={col.align}
                      style={{
                        minWidth: col.minWidth,
                        backgroundColor: props.backgroundColor
                          ? props.backgroundColor
                          : "inherit",
                      }}
                      className={cx(classes.tableHeaderCell, {
                        [classes.tableCellFirstColumnSingleSelectModeOn]:
                          (props.singleSelect || props.disableSelect) && !idx,
                      })}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, rowIdx) => {
                    // determine if row is selected
                    const rowIsSelected = props.selectedRowCheck
                      ? props.selectedRowCheck(data)
                      : props.singleSelect
                        ? rowIdx === singleSelectRowIndex
                        : selectedRowIndices.includes(rowIdx);

                    return (
                      <TableRow
                        key={rowIdx}
                        id={`feTable-Row-${rowIdx}`}
                        onClick={handleRowSelect(rowIdx, data)}
                        className={cx(classes.tableRow, {
                          [classes.tableRowSelected]:
                            rowIsSelected ||
                            (props.expandRowComponent &&
                              rowExpandedStates[rowIdx]),
                        })}
                      >
                        {
                          // only show check box on row if neither disableSelect nor singleSelect are set
                          !(props.disableSelect || props.singleSelect) && (
                            <TableCell
                              align="center"
                              className={cx(classes.tableCell, {
                                [classes.tableCellNoBorderBottom]:
                                  props.expandRowComponent &&
                                  rowExpandedStates[rowIdx],
                              })}
                              padding="checkbox"
                            >
                              <Checkbox
                                id={`checkbox-${String(rowIdx)}`}
                                color="primary"
                                checked={rowIsSelected}
                              />
                            </TableCell>
                          )
                        }
                        {props.expandRowComponent && (
                          <TableCell
                            align="center"
                            className={cx(classes.tableCell, {
                              [classes.tableCellNoBorderBottom]:
                                props.expandRowComponent &&
                                rowExpandedStates[rowIdx],
                            })}
                            padding="checkbox"
                          >
                            <IconButton
                              size="small"
                              color={
                                rowExpandedStates[rowIdx]
                                  ? "primary"
                                  : undefined
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                setRowExpandedStates({
                                  ...rowExpandedStates,
                                  [rowIdx]: !rowExpandedStates[rowIdx],
                                });
                              }}
                            >
                              {rowExpandedStates[rowIdx] ? (
                                <CollapseRowIcon />
                              ) : (
                                <ExpandRowIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        )}
                        {props.columns.map((col, colIdx) => (
                          <TableCell
                            key={colIdx}
                            className={cx(classes.tableCell, {
                              [classes.tableCellMinNonDensePadding]:
                                !denseTable,
                              [classes.tableCellFirstColumnSingleSelectModeOn]:
                                (props.singleSelect || props.disableSelect) &&
                                !colIdx,
                            })}
                          >
                            {renderCellData(
                              data,
                              rowIsSelected,
                              col.field,
                              col.accessor,
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <div
            className={classes.tableFooter}
            style={{
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "inherit",
            }}
          >
            <div className={classes.tableFooterCondenseSwitchLayout}>
              <Switch
                color="primary"
                checked={denseTable}
                onChange={() => setDenseTable(!denseTable)}
              />
              <Typography children="Condensed Table" variant="body2" />
            </div>
            <TablePagination
              classes={{ root: classes.tablePaginationRoot }}
              ref={(paginationRef: HTMLDivElement) => {
                if (!paginationRef) {
                  return;
                }
                if (
                  paginationRef.clientHeight &&
                  paginationComponentHeight !== paginationRef.clientHeight
                ) {
                  setPaginationComponentHeight(paginationRef.clientHeight);
                }
              }}
              rowsPerPageOptions={[
                10, 20, 25, 100, 150, 200, 250, 300, 350, 400,
              ]}
              component="div"
              count={props.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{ "aria-label": "previous page" }}
              nextIconButtonProps={{ "aria-label": "next page" }}
            />
          </div>
        </>
      )}
    </StyledPaper>
  );
}
