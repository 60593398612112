import { Client } from "james/client";
import { usePersonViewStore } from "../store";
import { useEffect } from "react";
import { ClientDetailsInspector } from "james/kyc";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { Person } from "james/legal/person";

export const useInitialDataFetch = (client: Client) => {
  const store = usePersonViewStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();

  useEffect(() => {
    setTimeout(async () => {
      try {
        store.setLoading(true);
        const response =
          await ClientDetailsInspector.GetIndividualClientPersonalInfo({
            context: authContext,
            clientID: client.id,
          });

        store.setLastUpdateBy(response.person.auditEntry.userID);
        store.setLastUpdateDate(response.person.auditEntry.time);
        store.setFromState(new Person(response.person));
        store.setCachedPerson(new Person(response.person));
      } catch (e) {
        store.setLoading(false);
        const err = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(err);
      }
      store.setLoading(false);
    });
  }, []);

  return {};
};
