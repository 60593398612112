// Log Rocket
export enum AssetEvent {
  explore = "asset-explore",
  openDirectOrder = "asset-openDirectOrder",
  placeDirectOrder = "asset-placeDirectOrder",
  openSpotTrade = "asset-openSpotTrade",
  doSpotTrade = "asset-doSpotTrade",
  placeSubscription = "asset-placeSubscription",
  openSubscriptionTicket = "asset-openSubscriptionTicket",
}
