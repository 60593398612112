import {
  Box,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
  Tooltip,
  Dialog,
  Grid,
  IconButton,
  DialogTitle,
  Drawer,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { CircularProgressWithLabel } from "views/SignUp/components/VerifyEmailOTP/CircularProgressWithLabel";
import { useApplicationContext } from "context/Application/Application";
import { useNavigate } from "react-router-dom";
import {
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from "@mui/icons-material";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { useFirebaseContext } from "context/Firebase";
import { OnboardingCard } from "../OnboardingCard/OnboardingCard";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import DialogContent from "@mui/material/DialogContent";

interface VerifyEmailCardProps {
  handleOnVerifyEmail(): Promise<void>;

  handleOnOTPResend(): Promise<void>;

  totpResendLoading: boolean;
  confirmationCode: string;
  setConfirmationCode: Dispatch<SetStateAction<string>>;
  setOTPValues: () => [number, number, boolean];
}

export const VerifyEmailCard = ({
  handleOnVerifyEmail,
  handleOnOTPResend,
  totpResendLoading,
  setConfirmationCode,
  confirmationCode,
  setOTPValues,
}: VerifyEmailCardProps) => {
  const navigate = useNavigate();
  const { logout } = useApplicationContext();
  const { firebaseUser, firebaseUserCleanup } = useFirebaseContext();
  const [timerProgress, timerValue, timerCompleted] = setOTPValues();
  const [emailVerificationInProgress, setEmailVerificationInProgress] =
    useState(false);
  const canResend = timerCompleted && !totpResendLoading;
  const disableVerifyButton =
    confirmationCode.length !== 6 || emailVerificationInProgress;
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);

  const onClickVerifyEmail = useCallback(async () => {
    setEmailVerificationInProgress(true);
    await handleOnVerifyEmail();
    setEmailVerificationInProgress(false);
  }, [handleOnVerifyEmail]);

  const onConfirm = useCallback(async () => {
    setRemovingUser(true);
    try {
      // clean up firebase user in a try catch as the clean up can fail
      await firebaseUserCleanup();
    } catch (e) {
      console.error("error performing firebase user cleanup", e);
    }
    await logout();
    setShowConfirmation(false);
    setRemovingUser(false);
    navigate("/sign-up/register");
  }, [showConfirmation]);

  return (
    <>
      <OnboardingCard
        linksFooter={
          <Link
            id="verifyEmailCard-logout-link"
            sx={{
              fontWeight: "bold",
              padding: "0px 0px 44px 0px",
            }}
            onClick={() => setShowConfirmation(true)}
            component="a"
            underline="hover"
            color="secondary"
          >
            Go Back
          </Link>
        }
      >
        <VerticalSlide transitionDuration={1 / 5}>
          <Typography
            sx={{
              fontWeight: 600,
              padding: "44px 0 24px 0",
              textAlign: "center",
              lineHeight: "29px",
            }}
            variant="h4"
            children="Please verify your email address"
          />
        </VerticalSlide>
        <VerticalSlide transitionDuration={2 / 5}>
          <Typography
            sx={{
              textAlign: "center",
              margin: "0px 0 32px 0",
            }}
            color="textSecondary"
            variant="subtitle1"
          >
            A unique 6 digit code has been sent to &nbsp;
            <Typography
              sx={{
                textAlign: "inherit",
                lineBreak: "anywhere",
                color: "inherit",
              }}
              variant="subtitle1"
              component={"span"}
            >
              {firebaseUser?.email || "your email"}.
            </Typography>
          </Typography>
        </VerticalSlide>
        <VerticalSlide width="100%" transitionDuration={3 / 5}>
          <TextField
            sx={{
              marginBottom: "16px",
            }}
            fullWidth
            label="Confirmation Code"
            id="desktopVerifyOTP-confirmationCode-textField"
            InputLabelProps={{ shrink: undefined }}
            value={confirmationCode}
            inputProps={{
              autoComplete: "new-password",
            }}
            onChange={(e) => {
              setConfirmationCode(e.target.value);
            }}
            InputProps={{
              endAdornment: timerCompleted ? (
                <InputAdornment position="end">
                  <Tooltip
                    title="To resend your confirmation code click on the link below."
                    placement={"top"}
                  >
                    <ErrorIcon />
                  </Tooltip>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <CircularProgressWithLabel
                    value={timerValue}
                    progress={timerProgress}
                    hideZeroValue
                  />
                </InputAdornment>
              ),
            }}
          />
        </VerticalSlide>
        <VerticalSlide width="100%" transitionDuration={1}>
          <Tooltip
            title={(() => {
              if (disableVerifyButton && isMobile && timerCompleted) {
                return "To resend your confirmation code click on the link below.";
              }

              if (disableVerifyButton && isMobile && !timerCompleted) {
                return (
                  "You can only resend confirmation code email every 120s. " +
                  "Confirmation code will expire in 5 min"
                );
              }

              return "";
            })()}
            placement={"top"}
          >
            <span>
              <Button
                sx={{
                  marginBottom: "32px",
                  height: "48px",
                  width: "100%",
                }}
                fullWidth
                size={"large"}
                type="submit"
                id="desktopVerifyOTP-submit-button"
                onClick={onClickVerifyEmail}
                color="primary"
                variant="contained"
                children="verify your email"
                disabled={disableVerifyButton}
              />
            </span>
          </Tooltip>
        </VerticalSlide>
        <VerticalSlide transitionDuration={4 / 5}>
          <MemoizedResendLink
            canResend={canResend}
            handleOnOTPResend={handleOnOTPResend}
          />
        </VerticalSlide>
      </OnboardingCard>

      {showConfirmation && !isMobile && (
        <Dialog
          PaperProps={{
            sx: {
              width: "629px",
              height: "195px",
              alignSelf: "center",
              justifySelf: "center",
            },
          }}
          open={showConfirmation}
        >
          <DialogTitle sx={{ height: "52px" }}>
            <Typography>Please Note</Typography>
            <Grid item>
              <Tooltip title="Close" placement="top">
                <span>
                  <IconButton
                    id="Verify-email-dialog-close-button"
                    size="small"
                    onClick={() => setShowConfirmation(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </DialogTitle>
          <DialogContent
            sx={(theme) => ({
              width: "100%",
              display: "grid",
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(3),
            })}
          >
            <Typography
              fontSize="14px"
              sx={(theme) => ({
                paddingTop: 3,
                color: theme.palette.text.hint,
              })}
            >
              If you proceed, you&apos;ll need to restart the registration
              process.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                height: "36px",
                marginTop: "40px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "110px", marginLeft: "16px" }}
                onClick={onConfirm}
                disabled={removingUser}
              >
                Proceed
              </Button>
              <Button
                id="kybOutstandingDialog-startTheKYCProcess-button"
                fullWidth
                variant="outlined"
                sx={{
                  width: "110px",
                }}
                onClick={() => setShowConfirmation(false)}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {isMobile && showConfirmation && (
        <Drawer open={showConfirmation} anchor="bottom">
          <DialogTitle sx={{ height: "52px" }}>
            <Typography>Please Note</Typography>
            <Grid item>
              <Tooltip title="Close" placement="top">
                <span>
                  <IconButton
                    id="Verify-email-dialog-close-button"
                    size="small"
                    onClick={() => setShowConfirmation(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </DialogTitle>
          <DialogContent
            sx={(theme) => ({
              width: "100%",
              display: "grid",
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(3),
            })}
          >
            <Typography
              fontSize="14px"
              sx={(theme) => ({
                paddingTop: 3,
                color: theme.palette.text.hint,
              })}
            >
              If you proceed, you&apos;ll need to restart the registration
              process.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: "24px 0px" }}
              onClick={onConfirm}
              disabled={removingUser}
            >
              Proceed
            </Button>
          </DialogContent>
        </Drawer>
      )}
    </>
  );
};

const ResendLink = (props: {
  canResend: boolean;
  handleOnOTPResend: () => Promise<void>;
}) => {
  const theme = useTheme();

  return (
    <Typography
      variant="subtitle2"
      sx={(theme) => ({
        color: theme.palette.text.secondary,
        width: "100%",
      })}
      children={
        <Box
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            padding: "0px 45px 62px 45px",
            textAlign: "center",
          })}
        >
          If you have not received an email or if you need a new confirmation
          code
          {props.canResend ? (
            <Link
              id="verifyEmail-moreInfo-link"
              onClick={props.handleOnOTPResend}
              sx={{
                cursor: "pointer",
                marginLeft: "3px",
                fontWeight: "bold",
              }}
              component="a"
              underline="hover"
              color="secondary"
            >
              click here
            </Link>
          ) : (
            <Tooltip
              arrow
              placement={"top"}
              title={"Can only resend every 120s"}
            >
              <Box
                sx={{
                  color: theme.palette.text.secondary,
                  marginLeft: "3px",
                  fontWeight: "bold",
                }}
                component={"span"}
              >
                click here
              </Box>
            </Tooltip>
          )}
          .
        </Box>
      }
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function resendLinkPropsComparison(prevProps: any, nextProps: any) {
  return prevProps.canResend === nextProps.canResend;
}

const MemoizedResendLink = React.memo(ResendLink, resendLinkPropsComparison);
