import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import meshLogo from "assets/images/logo/meshLogo.svg";
import {
  ComplianceManagerWrite,
  useOpenLatestAUSAgreement,
  useOpenLatestPaaSAgreement,
} from "james/compliance";
import { useSnackbar } from "notistack";
import backgroundCroppedDark from "assets/images/background/backgroundCropped.jpeg";
import { useWidth } from "utilities/general";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import { useErrorContext } from "context/Error";

export function AcceptAUSANDPaas() {
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const isDesktop = isWidthUp("sm", width);
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { openLatestPaaSAgreement } = useOpenLatestPaaSAgreement();
  const { openLatestAUSAgreement } = useOpenLatestAUSAgreement();
  const { errorContextErrorTranslator } = useErrorContext();

  const handleAcceptTermsAndConditions = async () => {
    setLoading(true);

    try {
      await ComplianceManagerWrite.AcceptAUSAndPaaSAgreement({
        context: authContext,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error accepting agreements: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Accepting Agreements: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Mesh | Terms</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Accept the Mesh Terms and Conditions"
        />
      </Helmet>
      {isDesktop && (
        <Box
          sx={{
            height: "inherit",
            display: "grid",
            backgroundImage: `url(${backgroundCroppedDark})`,
            backgroundSize: "cover",
            position: "relative",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <img alt="" width="300" src={meshLogo} />
          </Box>
          <Container
            disableGutters
            maxWidth={"sm"}
            sx={{
              zIndex: 3,
            }}
          >
            <Box
              sx={{
                display: "grid",
                justifyItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h1">A responsible revolution</Typography>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  marginBottom: 3,
                })}
                component="p"
                variant="subtitle1"
                align="center"
              >
                The safety and security of your Mesh experience is our priority.
                Our Terms and Conditions protect our platform and our users, so
                please read and accept them.
              </Typography>

              <Typography
                component="p"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  marginBottom: 3,
                })}
                variant="subtitle1"
                align="center"
              >
                Please read our&nbsp;
                <Link
                  noWrap
                  onClick={openLatestPaaSAgreement}
                  component="span"
                  underline="hover"
                  color="secondary"
                >
                  Platform as a Service Agreement
                </Link>
                &nbsp;and our&nbsp;
                <Link
                  noWrap
                  onClick={openLatestAUSAgreement}
                  component="span"
                  underline="hover"
                  variant="subtitle1"
                  color="secondary"
                >
                  Authorised User Service Agreement
                </Link>
                .
              </Typography>
              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <>
                  <FormControlLabel
                    sx={{
                      marginBottom: 2,
                    }}
                    control={
                      <Checkbox
                        checked={accept}
                        onChange={() => setAccept(!accept)}
                        name="checkedB"
                        color="primary"
                        id="kycDialog-sarsDeclaration-checkbox"
                      />
                    }
                    label="I have read and accept the Mesh Terms and Conditions."
                  />
                  <Button
                    sx={{
                      width: "106px",
                    }}
                    id="acceptAUSandPaaS-accept-button"
                    size="large"
                    disabled={!accept || loading}
                    onClick={handleAcceptTermsAndConditions}
                    variant="contained"
                    color="primary"
                    children="accept"
                  />
                </>
              )}
            </Box>
          </Container>
        </Box>
      )}
      {isMobile && (
        <Box
          sx={{
            justifySelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <img alt="" width="220px" height="100px" src={meshLogo} />
            </Box>
            <Box
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                }}
                variant="h1"
              >
                A responsible
              </Typography>
            </Box>
            <Box
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  lineHeight: "24px",
                }}
                variant="h1"
              >
                revolution
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                padding: theme.spacing(0, 3, 0, 3),
                marginTop: theme.spacing(3),
              })}
            >
              <Typography
                sx={(theme) => ({
                  fontSize: "14px",
                  color: theme.palette.text.secondary,
                })}
                variant="subtitle1"
                align="center"
              >
                The safety and security of your Mesh experience is our priority.
                Our Terms and Conditions protect our platform and our users, so
                please read and accept them.
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                padding: theme.spacing(0, 3, 0, 3),
                marginTop: theme.spacing(2),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              })}
            >
              <Typography
                component="p"
                sx={(theme) => ({
                  marginBottom: theme.spacing(2),
                  color: theme.palette.text.secondary,
                  fontSize: "14px",
                })}
                variant="subtitle1"
              >
                Please read our&nbsp;
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <Link
                  onClick={openLatestAUSAgreement}
                  target="_blank"
                  sx={{
                    cursor: "pointer",
                  }}
                  underline="none"
                  color="secondary"
                >
                  Platform as a Service Agreement
                </Link>
              </Typography>
              <Typography
                component="p"
                sx={(theme) => ({
                  marginTop: theme.spacing(2),
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(2),
                  fontSize: "14px",
                })}
                variant="subtitle1"
              >
                and
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <Link
                  onClick={openLatestAUSAgreement}
                  target="_blank"
                  underline="none"
                  sx={{
                    cursor: "pointer",
                  }}
                  color="secondary"
                >
                  Authorised User Service Agreement
                </Link>
              </Typography>
            </Box>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <Box
                  sx={(theme) => ({
                    alignItems: "center",
                    marginBottom: theme.spacing(2),
                  })}
                >
                  <FormControlLabel
                    sx={(theme) => ({
                      paddingTop: theme.spacing(5),
                      paddingBottom: theme.spacing(2),
                    })}
                    control={
                      <Checkbox
                        checked={accept}
                        onChange={() => setAccept(!accept)}
                        name="checkedB"
                        color="primary"
                        id="kycDialog-sarsDeclaration-checkbox"
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        I have read and accept the
                        <br />
                        Mesh Terms and Conditions.
                      </Typography>
                    }
                  />
                </Box>
                <Box
                  sx={(theme) => ({
                    width: "312px",
                    marginBottom: theme.spacing(3),
                  })}
                >
                  <Button
                    sx={{
                      width: "100%",
                      height: 48,
                    }}
                    id="acceptAUSandPaaS-accept-button"
                    size="large"
                    disabled={!accept || loading}
                    onClick={handleAcceptTermsAndConditions}
                    variant="contained"
                    color="primary"
                    children="submit"
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
