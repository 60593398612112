import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import React from "react";
const PREFIX = "MarketMakerResponsibilitiesDialog";

const classes = {
  MarketMakerResponsibilitiesDialogTitle: `${PREFIX}-MarketMakerResponsibilitiesDialogTitle`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  btn: `${PREFIX}-btn`,
  responsibilitiesList: `${PREFIX}-responsibilitiesList`,
  responsibilitiesListItem: `${PREFIX}-responsibilitiesListItem`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.MarketMakerResponsibilitiesDialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 541,
    padding: `${theme.spacing(2, 3)} !important`,
  },

  [`& .${classes.btn}`]: {
    minWidth: 101,
  },

  [`& .${classes.responsibilitiesList}`]: {
    paddingLeft: theme.spacing(1.8),
  },

  [`& .${classes.responsibilitiesListItem}`]: {},
}));

export interface MarketMakerResponsibilitiesDialogProps {
  showDialog: boolean;
  onCloseClick: () => void;
  tokenClass: string;
  responsibilities: string[];
}

export function MarketMakerResponsibilitiesDialog(
  props: MarketMakerResponsibilitiesDialogProps,
) {
  return (
    <StyledDialog
      open={props.showDialog}
      onClose={props.onCloseClick}
      maxWidth="lg"
    >
      <DialogTitle
        classes={{ root: classes.MarketMakerResponsibilitiesDialogTitle }}
      >
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h5" children="Market Maker Responsibilities" />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  size="small"
                  id="marketMakerResponsibilitiesDialog-close-button"
                  onClick={props.onCloseClick}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContentRootOverride}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6" children={props.tokenClass} />
          </Grid>
          <Grid item>
            <ul className={classes.responsibilitiesList}>
              {props.responsibilities.map((r, idx) => (
                <Typography
                  key={idx}
                  variant="body1"
                  color="textSecondary"
                  component="li"
                  children={r}
                />
              ))}
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
