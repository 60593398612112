import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "../security";
import { Amount } from "../ledger/Amount";
import { DirectOrder } from "./DirectOrder";
import { Identifier } from "../search/identifier/Identifier";

export interface AcceptDirectOrderIDRequest {
  context: Context;
  directOrderID: string;
  price: Amount;
}

export interface AcceptDirectOrderIDResponse {
  directOrder: DirectOrder;
}

export interface CancelDirectOrderIDRequest {
  context: Context;
  directOrderID: string;
}

export interface CancelDirectOrderIDResponse {
  directOrder: DirectOrder;
}

export interface DeclineDirectOrderIDRequest {
  context: Context;
  directOrderID: string;
}

export interface DeclineDirectOrderIDResponse {
  directOrder: DirectOrder;
}

export interface ResolveDirectOrderStateRequest {
  context: Context;
  directOrderIdentifier: Identifier;
  ignoreFailureCount: boolean;
}

export interface ResolveDirectOrderStateResponse {
  directOrder: DirectOrder;
}

export const DirectOrderStateControllerServiceProviderName =
  "market-DirectOrderStateController";

export const DirectOrderStateController = {
  async AcceptDirectOrderID(
    request: AcceptDirectOrderIDRequest,
    additionalHeaders?: Headers,
  ): Promise<AcceptDirectOrderIDResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DirectOrderStateControllerServiceProviderName}.AcceptDirectOrderID`,
        request,
      },
      { additionalHeaders },
    );

    return { directOrder: new DirectOrder(response.directOrder) };
  },
  async CancelDirectOrderID(
    request: CancelDirectOrderIDRequest,
    additionalHeaders?: Headers,
  ): Promise<CancelDirectOrderIDResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DirectOrderStateControllerServiceProviderName}.CancelDirectOrderID`,
        request,
      },
      { additionalHeaders },
    );

    return { directOrder: new DirectOrder(response.directOrder) };
  },
  async DeclineDirectOrderID(
    request: DeclineDirectOrderIDRequest,
    additionalHeaders?: Headers,
  ): Promise<DeclineDirectOrderIDResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DirectOrderStateControllerServiceProviderName}.DeclineDirectOrderID`,
        request,
      },
      { additionalHeaders },
    );

    return { directOrder: new DirectOrder(response.directOrder) };
  },
  async ResolveDirectOrderState(
    request: ResolveDirectOrderStateRequest,
    additionalHeaders?: Headers,
  ): Promise<ResolveDirectOrderStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DirectOrderStateControllerServiceProviderName}.ResolveDirectOrderState`,
        request,
      },
      { additionalHeaders },
    );

    return { directOrder: new DirectOrder(response.directOrder) };
  },
};
