"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSmartInstrumentLegTypes = void 0;
exports.smartInstrumentLegTypeToString = smartInstrumentLegTypeToString;
exports.stringToSmartInstrumentLegType = stringToSmartInstrumentLegType;
const smartInstrumentLegType_pb_1 = require("./smartInstrumentLegType_pb");
// Get all smart instrument leg types as enum values
exports.allSmartInstrumentLegTypes = Object.values(smartInstrumentLegType_pb_1.SmartInstrumentLegType).filter((value) => typeof value === "number");
// Define explicit mappings between SmartInstrumentLegType enums and custom string representations
const smartInstrumentLegTypeToStringMapping = {
    [smartInstrumentLegType_pb_1.SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE]: "-",
    [smartInstrumentLegType_pb_1.SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE]: "Bullet",
    [smartInstrumentLegType_pb_1.SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE]: "Floating Rate",
};
// Reverse mapping from string to SmartInstrumentLegType enum
const stringToSmartInstrumentLegTypeMapping = {};
for (const [key, value] of Object.entries(smartInstrumentLegTypeToStringMapping)) {
    stringToSmartInstrumentLegTypeMapping[value] = Number(key);
}
class UnsupportedSmartInstrumentLegTypeError extends Error {
    constructor(smartInstrumentLegType) {
        const message = `Unsupported SmartInstrumentLegType: ${smartInstrumentLegType}`;
        super(message);
        this.smartInstrumentLegType = smartInstrumentLegType;
    }
}
/**
 * Converts a SmartInstrumentLegType enum instance to a custom string representation.
 * @param {SmartInstrumentLegType} smartInstrumentLegType - The smart instrument leg type to convert.
 * @returns {string} The custom string representation of the smart instrument leg type.
 */
function smartInstrumentLegTypeToString(smartInstrumentLegType) {
    if (smartInstrumentLegType in smartInstrumentLegTypeToStringMapping) {
        return smartInstrumentLegTypeToStringMapping[smartInstrumentLegType];
    }
    else {
        throw new UnsupportedSmartInstrumentLegTypeError(smartInstrumentLegType);
    }
}
class UnsupportedSmartInstrumentLegTypeStringError extends Error {
    constructor(smartInstrumentLegTypeStr) {
        const message = `Unsupported smart instrument leg type string: ${smartInstrumentLegTypeStr}`;
        super(message);
        this.smartInstrumentLegTypeStr = smartInstrumentLegTypeStr;
    }
}
/**
 * Converts a custom string representation to a SmartInstrumentLegType enum instance.
 * @param {string} smartInstrumentLegTypeStr - The custom string representation of the smart instrument leg type.
 * @returns {SmartInstrumentLegType} The corresponding SmartInstrumentLegType enum instance.
 */
function stringToSmartInstrumentLegType(smartInstrumentLegTypeStr) {
    if (smartInstrumentLegTypeStr in stringToSmartInstrumentLegTypeMapping) {
        return stringToSmartInstrumentLegTypeMapping[smartInstrumentLegTypeStr];
    }
    else {
        throw new UnsupportedSmartInstrumentLegTypeStringError(smartInstrumentLegTypeStr);
    }
}
