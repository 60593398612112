import dayjs from "dayjs";
import { Amount } from "james/ledger";

export enum CreditOrDebit {
  AccountCredited = "account_credited",
  AccountDebited = "account_debited",
}

export class Model {
  public pagingToken = "";
  public ownerID = "";
  public accountLedgerID = "";
  public accountID = "";
  public transactionDateTime: string = dayjs().format();
  public creditOrDebit: CreditOrDebit | "" = "";
  public amount: Amount = new Amount();

  public category = "";

  public balance: Amount = new Amount();
  public description = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.accountID = model.accountID;
    this.pagingToken = model.pagingToken;
    this.ownerID = model.ownerID;
    this.accountLedgerID = model.accountLedgerID;
    this.transactionDateTime = model.transactionDateTime;
    this.creditOrDebit = model.creditOrDebit;
    this.amount = new Amount(model.amount);
    this.balance = new Amount(model.balance);
    this.category = model.category;
    this.description = model.description;
  }
}
