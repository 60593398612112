import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { Client } from "james/client";
import { useApplicationContext } from "context/Application/Application";
import { useBankingDetailsViewStore } from "../store";
import { BankAccountCreator, BankName } from "james/banking";
import { BankAccountReader } from "james/banking/bankAccountReader";
import { TextExactCriterion } from "james/search/criterion";

// custom hook to capture/create bank account for an client.
export const useCaptureBankAccountDetails = () => {
  const state = useBankingDetailsViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();

  const captureClientBankAccountDetails = async (client: Client) => {
    // validation to ensure all the necessary field are populated
    if (!state.isBankDetailsPopulated()) {
      enqueueSnackbar("Please fill all fields before submitting", {
        variant: "warning",
      });
      return;
    }

    state.setLoading(true);
    try {
      // Attempt to save bank account details
      await BankAccountCreator.CreateNewBankAccountUnscoped({
        context: authContext,
        ownerID: client.ownerID,
        countryCode: state.countryCode,
        bankName: state.bankName as BankName,
        accountType: state.accountType,
        branchCode: state.branchCode,
        bankAccountNumber: state.number,
      });
      const response = await BankAccountReader.ReadBankAccountsUnscoped({
        context: authContext,
        criteria: {
          ownerID: TextExactCriterion(client.ownerID),
        },
      });
      // Update local state with the fetched bank account details.
      state.updateStateWithBankAccount(response.bankAccounts[0]);
      state.setLastUpdateBy(response.bankAccounts[0].auditEntry.userID);
      state.setLastUpdateDate(response.bankAccounts[0].auditEntry.time);
      state.setCachedBankAccount(response.bankAccounts[0]);
      state.setBankNotFound(false);
      enqueueSnackbar("Bank Account Details Captured", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error capturing bank account details: ${
          err.message ? err.message : err.toString()
        }`,
      );
      errorContextDefaultErrorFeedback(e);
    }
    state.setLoading(false);
  };

  return {
    captureClientBankAccountDetails,
  };
};
