import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
import {
  CountryAllocationsSmartInstrumentAttribute,
  SmartInstrumentCountryAllocationEntry,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeCountryAllocations_pb";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import React from "react";
import { Close as DeleteIcon } from "@mui/icons-material";
import { TextField, TextNumField } from "@mesh/common-js-react/dist/FormFields";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { ValidationResult } from "common/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { allCountryCodes, countryCodeToString } from "@mesh/common-js/dist/i8n";
import { CountryCode } from "@mesh/common-js/dist/i8n/countryCode_pb";

export type CountryAllocationsAttributeFormProps = {
  countryAllocationsAttribute: CountryAllocationsSmartInstrumentAttribute;
  onChange: (
    updatedCountryAllocationsAttribute: CountryAllocationsSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const CountryAllocationsAttributeForm = (
  props: CountryAllocationsAttributeFormProps,
) => {
  const entries = props.countryAllocationsAttribute.getEntriesList();

  // sum total allocation
  const totalAllocation = entries.reduce(
    (sum, entry) =>
      sum.plus(decimalToBigNumber(entry.getAmount() ?? new Decimal())),
    BigNumber("0"),
  );

  const fieldValidationResult = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.COUNTRY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ];
  };

  return (
    <Box sx={{ width: 400 }}>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing(1),
        })}
      >
        <Typography
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          variant="caption"
        >
          Total Allocated:
        </Typography>
        <Typography variant="caption">{totalAllocation.toString()}%</Typography>
      </Box>
      {!!fieldValidationResult("total") && (
        <Typography color={"error"} variant="body2">
          {fieldValidationResult("total")}
        </Typography>
      )}
      {props.readOnly ? (
        <ul>
          {entries.map((entry, idx) => (
            <Typography
              variant="body2"
              component={"li"}
              key={`${idx}-${entry.getCountry()}`}
            >
              {`${countryCodeToString(entry.getCountry())} - ${decimalToBigNumber(
                entry.getAmount() ?? new Decimal(),
              ).toString()}%`}
            </Typography>
          ))}
        </ul>
      ) : (
        <Box>
          {entries.map((thisEntry, idx) => (
            <Box
              key={`country-${thisEntry.getCountry()}-${idx}`}
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr 80px auto",
                alignItems: "center",
                gap: theme.spacing(0.5),
              })}
            >
              <TextField
                size="small"
                fullWidth
                id={"countryAllocationsAttributeForm-country-selectField"}
                disabled={props.disabled}
                select
                placeholder="Select..."
                error={!!fieldValidationResult(`${thisEntry.getCountry()}`)}
                value={thisEntry.getCountry()}
                onChange={(e) => {
                  entries[idx] = thisEntry.setCountry(
                    Number(e.target.value) as CountryCode,
                  );
                  props.onChange(
                    props.countryAllocationsAttribute.setEntriesList(entries),
                  );
                }}
              >
                {[
                  // only show non-used, non-undefined values and the current country
                  thisEntry.getCountry(),
                  ...allCountryCodes.filter(
                    (country) =>
                      !entries.find(
                        (existingEntry) =>
                          existingEntry.getCountry() === country,
                      ) && country !== CountryCode.UNDEFINED_COUNTRY_CODE,
                  ),
                ].map((v) => {
                  return (
                    <MenuItem key={v} value={v}>
                      {countryCodeToString(v)}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextNumField
                fullWidth
                sx={{ width: 80 }}
                id={`${thisEntry.getCountry()}-${idx}-countryAllocationsAttributeForm-country-percentage-textNumField`}
                disabled={props.disabled}
                readOnly={props.readOnly}
                disallowNegative
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
                error={!!fieldValidationResult(`${thisEntry.getCountry()}`)}
                value={thisEntry.getAmount()}
                onChange={(newValue) => {
                  entries[idx] = thisEntry.setAmount(newValue);
                  props.onChange(
                    props.countryAllocationsAttribute.setEntriesList(entries),
                  );
                }}
              />
              <Tooltip placement="top" title={"Remove"}>
                <IconButton
                  size={"small"}
                  id={`${thisEntry.getCountry()}-${idx}-countryAllocationsAttributeForm-country-remove-iconButton`}
                  onClick={() =>
                    props.onChange(
                      props.countryAllocationsAttribute.setEntriesList(
                        entries.filter(
                          (entry) =>
                            entry.getCountry() !== thisEntry.getCountry(),
                        ),
                      ),
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {!!fieldValidationResult(`${thisEntry.getCountry()}`) && (
                <Typography
                  sx={{ marginTop: -1 }}
                  color={"error"}
                  variant="body2"
                >
                  {fieldValidationResult(`${thisEntry.getCountry()}`)}
                </Typography>
              )}
            </Box>
          ))}

          {/* Render the "add new" control row at the end if not in read only mode */}
          {!props.readOnly && (
            <Box sx={{ marginTop: 1 }}>
              <TextField
                size="small"
                fullWidth
                id={"countryAllocationsAttributeForm-addACountry-selectField"}
                disabled={props.disabled}
                select
                label="Add a Country"
                value={CountryCode.UNDEFINED_COUNTRY_CODE}
                onChange={(e) => {
                  props.onChange(
                    props.countryAllocationsAttribute.setEntriesList([
                      ...entries,
                      new SmartInstrumentCountryAllocationEntry()
                        .setCountry(Number(e.target.value) as CountryCode)
                        .setAmount(bigNumberToDecimal(BigNumber("0"))),
                    ]),
                  );
                }}
              >
                {allCountryCodes
                  .filter(
                    // only show non-used values
                    (country) =>
                      !entries.find(
                        (existingEntry) =>
                          existingEntry.getCountry() === country,
                      ),
                  )
                  .map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        {countryCodeToString(v)}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
