import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

export interface GenerateAPIKeyForMyUserRequest {
  context: Context;
}

export interface GenerateAPIKeyForMyUserResponse {
  apiKey: string;
}

export interface IsAPIKeySetForMyUserRequest {
  context: Context;
}

export interface IsAPIKeySetForMyUserResponse {
  result: boolean;
}

export const Manager = {
  serviceProvider: "apiKey-Manager",
  async GenerateAPIKeyForMyUser(
    request: GenerateAPIKeyForMyUserRequest,
  ): Promise<GenerateAPIKeyForMyUserResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Manager.serviceProvider}.GenerateAPIKeyForMyUser`,
      request,
    });
  },
  async IsAPIKeySetForMyUser(
    request: IsAPIKeySetForMyUserRequest,
  ): Promise<IsAPIKeySetForMyUserResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Manager.serviceProvider}.IsAPIKeySetForMyUser`,
      request,
    });
  },
};
