import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import backgroundDark from "assets/images/background/background.png";
import meshLogoNoWords from "assets/images/logo/meshLogoNoWords.svg";
import React from "react";
const PREFIX = "LoadingScreen";

const classes = {
  loadingRoot: `${PREFIX}-loadingRoot`,
  loadingContent: `${PREFIX}-loadingContent`,
  backgroundImage: `${PREFIX}-backgroundImage`,
};

const Root = styled("div")(() => ({
  [`&.${classes.loadingRoot}`]: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${backgroundDark})`,
    backgroundSize: "cover",
    width: "100%",
  },

  [`& .${classes.loadingContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.backgroundImage}`]: {
    minHeight: "100%",
    minWidth: "1024px",
    width: "100%",
    height: "auto",
    position: "fixed",
    top: 0,
    left: 0,
  },
}));

interface LoadingScreenProps {
  message: string;
}

export function LoadingScreen(props: LoadingScreenProps) {
  return (
    <Root className={classes.loadingRoot}>
      <div className={classes.loadingContent}>
        <img
          alt=""
          width={100}
          style={{ alignSelf: "center" }}
          src={meshLogoNoWords}
        />
        <Typography
          variant="h6"
          style={{ alignSelf: "center", marginTop: 8 }}
          children={props.message}
        />
      </div>
    </Root>
  );
}
