import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import {
  InstrumentStablecoin,
  NewInstrumentStablecoin,
} from "./InstrumentStablecoin";

export interface RetrieveInstrumentStablecoinRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveInstrumentStablecoinResponse {
  instrumentStablecoin: InstrumentStablecoin;
}

export interface SearchInstrumentStablecoinRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchInstrumentStablecoinResponse {
  records: InstrumentStablecoin[];
  total: number;
}

export const InstrumentStablecoinCollection = {
  serviceProvider: "financial-InstrumentStablecoinCollection",
  async RetrieveInstrumentStablecoin(
    request: RetrieveInstrumentStablecoinRequest,
  ): Promise<RetrieveInstrumentStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${InstrumentStablecoinCollection.serviceProvider}.RetrieveInstrumentStablecoin`,
      request,
    });
    return {
      instrumentStablecoin: NewInstrumentStablecoin(
        response.instrumentStablecoin,
      ),
    };
  },
  async SearchInstrumentStablecoin(
    request: SearchInstrumentStablecoinRequest,
  ): Promise<SearchInstrumentStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${InstrumentStablecoinCollection.serviceProvider}.SearchInstrumentStablecoin`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map((m: InstrumentStablecoin) =>
        NewInstrumentStablecoin(m),
      ),
    };
  },
};
