import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import React, { useState } from "react";
import { ProfilePicture } from "views/UserProfileDialog/util";
import { doUpload } from "utilities/network/upload";
import { UserManagerRead } from "james/user";
import { SkeletonLoader } from "components/SkeletonLoader";
import { ProfilePictureController } from "james/user/ProfilePictureController";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";

interface ProfilePhotoSectionProps {
  initialLoad: boolean;
  fileTooLarge: boolean;
  setFileTooLarge: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfilePhotoSection = ({
  initialLoad,
  fileTooLarge,
  setFileTooLarge,
}: ProfilePhotoSectionProps) => {
  const { authContext, myProfilePictureURL, refreshProfilePictureURL } =
    useApplicationContext();
  const [uploadInputRef, setUploadInputRef] = useState<HTMLInputElement | null>(
    null,
  );
  const [, setProfilePictureUploadInProgress] = useState(false);
  const [, setProfilePictureUploadPercentage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadClick, setUploadClick] = useState<(() => void) | null>(null);
  const theme = useTheme();
  const width = useWidth();
  const [loading, setLoading] = useState(false);

  const handleSelectPictureFile = async () => {
    setLoading(true);
    if (uploadInputRef === null) {
      console.error("upload input ref is null");
      setLoading(false);
      return;
    }

    try {
      if (
        !(uploadInputRef && uploadInputRef.files && uploadInputRef.files.length)
      ) {
        console.error("no upload input ref files");
        enqueueSnackbar("No upload file selected", { variant: "error" });
        setLoading(false);
        return;
      }

      // get a reference to the selected file and confirm it is not null
      const fileToUpload = uploadInputRef.files[0];
      if (!fileToUpload) {
        console.error("file to upload is null");
        enqueueSnackbar("File to upload is null", { variant: "error" });
        setLoading(false);
        return;
      }

      const fileSplit = fileToUpload.name.split(".");
      if (fileSplit.length < 2) {
        console.error("invalid file type");
        enqueueSnackbar("Invalid file type", { variant: "error" });
        setLoading(false);
        return;
      }

      const ext = fileSplit[fileSplit.length - 1];
      switch (ext.toLowerCase()) {
        case "png":
        case "jpg":
        case "jpeg":
          break;
        default:
          console.error("invalid file type");
          enqueueSnackbar("Invalid file type", { variant: "error" });
          setLoading(false);
          return;
      }

      if (fileToUpload.size > 5000000) {
        setFileTooLarge(true);
        setTimeout(() => setFileTooLarge(false), 10000);
        setLoading(false);
        enqueueSnackbar("File too large, max file size is 5MB", {
          variant: "error",
        });
        return;
      }

      setProfilePictureUploadPercentage(0);
      setProfilePictureUploadInProgress(true);

      // construct a file reader
      // read file
      // perform upload
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const fileData: ArrayBuffer | null =
          fileReader.result as ArrayBuffer | null;
        if (fileData === null) {
          console.error("file data is null");
          return;
        }

        const uploadURL = (
          await UserManagerRead.GetMyProfilePictureUploadURL({
            context: authContext,
          })
        ).url;
        doUpload({
          url: uploadURL,
          documentType: fileToUpload.type,
          documentData: fileData,
          onComplete: refreshProfilePictureURL,
          onProgress: (percentageComplete: number) =>
            setProfilePictureUploadPercentage(percentageComplete),
        });
      };

      fileReader.readAsArrayBuffer(uploadInputRef.files[0]);
    } catch (e) {
      console.error("error trying to access picture file", e);
      setProfilePictureUploadInProgress(false);
      setLoading(false);
    }
    setLoading(false);
  };

  const attachInputClick = (inputRef: HTMLInputElement) => {
    if (uploadInputRef !== null) {
      return;
    }
    if (!inputRef) {
      return;
    }
    if (uploadClick !== null) {
      return;
    }
    setUploadInputRef(inputRef);
    setUploadClick(() => () => {
      inputRef.click();
    });
  };

  return (
    <>
      <input
        ref={attachInputClick}
        onChange={() => {
          setLoading(true);
          handleSelectPictureFile().finally();
          setLoading(false);
        }}
        type="file"
        accept=".png,.jpg,.jpeg"
        style={{
          width: 0,
          height: 0,
        }}
      />
      <SkeletonLoader loading={initialLoad && isWidthUp("sm", width)}>
        <Box
          sx={{
            display: "flex",
            borderRadius: "10px",
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(3),
              backgroundColor: theme.palette.custom.cardInner,
              m: theme.spacing(3, 0, 4, 0),
            },
            [theme.breakpoints.down("sm")]: {
              padding: theme.spacing(2, 3, 2, 0),
              height: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <SkeletonLoader loading={initialLoad} variant="circular">
              <ProfilePicture width="80px" height="80px" />
            </SkeletonLoader>
            {isWidthDown("sm", width) && (
              <Box
                sx={{
                  my: 2,
                  mr: 1,
                  ml: 1.5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <SkeletonLoader loading={initialLoad}>
                  <Link
                    id="ProfilePhotoSection-Change-Link"
                    align="center"
                    underline="none"
                    sx={{ maxWidth: "60px" }}
                    onClick={() => {
                      if (uploadClick != null) {
                        uploadClick();
                      }
                    }}
                  >
                    Change
                  </Link>
                </SkeletonLoader>
                <SkeletonLoader loading={initialLoad}>
                  <Link
                    id="ProfilePhotoSection-Photo-Link"
                    align="center"
                    underline="none"
                    sx={{ maxWidth: "60px" }}
                    onClick={() => {
                      if (uploadClick != null) {
                        uploadClick();
                      }
                    }}
                  >
                    Photo
                  </Link>
                </SkeletonLoader>
              </Box>
            )}
          </Box>
          {isWidthUp("sm", width) && (
            <Box
              sx={{
                mr: theme.spacing(2),
                ml: theme.spacing(3),
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Button
                  id="ProfilePhotoSection-Upload-Button"
                  variant="contained"
                  size="large"
                  sx={{
                    height: "36px",
                    backgroundColor: theme.palette.primary.main,
                  }}
                  disabled={loading}
                  onClick={() => {
                    if (uploadClick != null) {
                      uploadClick();
                    }
                  }}
                >
                  upload photo
                </Button>
                <Button
                  id="ProfilePhotoSection-Remove-Button"
                  variant="contained"
                  size="large"
                  disabled={!myProfilePictureURL || loading}
                  sx={{
                    height: "36px",
                    backgroundColor: theme.palette.secondary.main,
                    mx: theme.spacing(2),
                  }}
                  onClick={async () => {
                    setLoading(true);
                    try {
                      await ProfilePictureController.DeleteProfilePicture({
                        context: authContext,
                      });
                    } catch (e) {
                      setLoading(false);
                    }
                    refreshProfilePictureURL();
                  }}
                >
                  remove
                </Button>
                {loading && <CircularProgress />}
              </Box>
              <Typography
                sx={{
                  color: fileTooLarge
                    ? theme.palette.error.light
                    : theme.palette.text.secondary,
                }}
              >
                You can upload jpg, gif or png image files.
                <br /> Max size of 5mb.
              </Typography>
            </Box>
          )}
        </Box>
      </SkeletonLoader>
    </>
  );
};
