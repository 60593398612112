import config from "react-global-configuration";
import { Context } from "../security";
import { Identifier } from "../search/identifier/Identifier";
import { DefundOrder } from "./defundOrder";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

export interface RetrieveDefundOrderRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveDefundOrderResponse {
  defundOrder: DefundOrder;
}

const DefundOrderRepositoryServiceProviderName =
  "banking-DefundOrderRepository";

export const DefundOrderRepository = {
  async RetrieveDefundOrder(
    request: RetrieveDefundOrderRequest,
  ): Promise<RetrieveDefundOrderResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${DefundOrderRepositoryServiceProviderName}.RetrieveDefundOrder`,
      request,
    });
    return {
      defundOrder: new DefundOrder(response.defundOrder),
    };
  },
};
