import React from "react";
import { styled } from "@mui/material/styles";
import cx from "classnames";
import { MainAppBar } from "components/MainAppBar";
import { Box } from "@mui/material";
import { MainAppBarHeight } from "components/MainAppBar/MainAppBar";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "MainAppBarOnlyLayout";

const classes = {
  layoutRoot: `${PREFIX}-layoutRoot`,
  scrollArea: `${PREFIX}-scrollArea`,
};

const Root = styled("div")(() => ({
  [`&.${classes.layoutRoot}`]: {
    display: "grid",
    gridTemplateRows: "56px 1fr",
    height: "100%",
  },

  [`& .${classes.scrollArea}`]: {
    height: "calc(100vh - 56px)",
    overflowY: "auto",
  },
}));

interface Props {
  children?: React.ReactNode;
}

export function MainAppBarOnlyLayout(props: Props) {
  const { NotificationBannerHeight } = useAppNoticeContext();
  return (
    <Root className={classes.layoutRoot}>
      <Box sx={{ height: MainAppBarHeight + NotificationBannerHeight }}>
        <MainAppBar />
      </Box>
      <div className={cx(classes.scrollArea, "meshScroll")}>
        {props.children}
      </div>
    </Root>
  );
}
