import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "../security";

export interface InitiateUserRegistrationRequest {
  context: Context;
  firebaseUserIDToken: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InitiateUserRegistrationResponse {}

export const IndividualClientUserRegistrarServiceProviderName =
  "user-IndividualClientUserRegistrar";

export const IndividualClientUserRegistrar = {
  async InitiateUserRegistration(
    request: InitiateUserRegistrationRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<InitiateUserRegistrationResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${IndividualClientUserRegistrarServiceProviderName}.InitiateUserRegistration`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { token: response.token };
  },
};
