import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CompanyRegistrationView, CompanyRegStep } from "./CompanyRegistration";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { ClientKYCStatus } from "james/client";
import { useErrorContext } from "context/Error";
import { useValidatedForm } from "hooks/useForm";
import {
  FormState,
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  initialState,
  validationFunc,
} from "./useFormState";
import { Company } from "james/legal/company";
import { Updater } from "james/legal/company/Updater";
import { useSnackbar } from "notistack";
import { ClientKYBStateController } from "james/kyb/clientKYBStateController";
import { KYCVerificationInProgressPlaceHolder } from "components/PlaceHolderScreens/KYCVerificationInProgressPlaceHolder";
import { countries } from "james/country";

export function KYBDialog() {
  const navigate = useNavigate();
  const {
    authContext,
    myClientKYCStatus,
    myClient,
    refreshMyClient,
    myClientRetrievalErr,
    myCompany,
  } = useApplicationContext();
  const theme = useTheme();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const initialTouchedFields: Set<string> = new Set();
  const [apiLoading, setApiLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nextPressed, setNextPressed] = useState(false);
  const [activeStep, setActiveStep] = useState<CompanyRegStep>(
    CompanyRegStep.companyProfile,
  );
  const [confirmInfo, setConfirmInfo] = useState(false);

  useEffect(() => {
    if (!myClient && myClientRetrievalErr) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      return;
    }
  }, [myClient, myClientRetrievalErr]);

  useEffect(() => {
    if (myCompany) {
      if (!myCompany.countryOfIncorporation) {
        myCompany.countryOfIncorporation = countries.filter(
          (c) => c.value === "ZA",
        )[0].value;
      }

      if (myCompany.companyRepresentative.physicalAddress) {
        if (!myCompany.companyRepresentative.physicalAddress.countryCode) {
          myCompany.companyRepresentative.physicalAddress.countryCode =
            countries.filter((c) => c.value === "ZA")[0].value;
        }
      }

      formUpdater.company(myCompany);
    }
  }, []);

  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm<FormState, FormUpdaterSpecsType>(
      validationFunc,
      async (): Promise<FormState> => {
        const company = new Company(myCompany);
        company.connectedIndividuals = [];
        company.connectedCompanies = [];
        return {
          company: company,
        };
      },
      formUpdaterSpecs,
      initialState,
      initialTouchedFields,
    );

  const handleClose = () => {
    navigate("/market");
  };

  const handleNextButtonClick = async () => {
    setApiLoading(true);
    setNextPressed(true);
    try {
      // setup individual client profile
      await Updater.UpdateMyCompany({
        context: authContext,
        company: formState.company,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Updating Company Profile: ${err.message}`, {
        variant: "error",
      });
      console.error(`unable to update company profile: ${e}`);
    }
    setApiLoading(false);
  };

  const handleSubmitButton = async () => {
    if (!myClient) {
      return;
    }
    setApiLoading(true);
    try {
      await Updater.UpdateMyCompany({
        context: authContext,
        company: formState.company,
      });

      await ClientKYBStateController.StartVerification({
        context: authContext,
        clientID: myClient.id,
      });

      refreshMyClient();
      setTimeout(() => navigate("/market"), 200);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Application: ${err.message}`, {
        variant: "error",
      });
      console.error(`unable to submit kyb application: ${e}`);
    }
    setApiLoading(false);
  };

  const handleBackButtonClick = () => {
    switch (activeStep) {
      case CompanyRegStep.companyDetails:
        setActiveStep(CompanyRegStep.companyProfile);
        break;
      case CompanyRegStep.contactDetails:
        setActiveStep(CompanyRegStep.companyDetails);
        break;
      case CompanyRegStep.companyRepresentative:
        setActiveStep(CompanyRegStep.contactDetails);
        break;
      case CompanyRegStep.connectedIndividuals:
        setActiveStep(CompanyRegStep.companyRepresentative);
        break;
      case CompanyRegStep.connectedNonIndividuals:
        setActiveStep(CompanyRegStep.connectedIndividuals);
        break;
    }
  };

  return (
    <Dialog fullScreen open>
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid
            item
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
            })}
          >
            <div
              style={{
                height: 32,
                width: 32,
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <img alt="" width="100%" src={meshMiniLogo} />
            </div>
            <Typography
              sx={(theme) => ({
                padding: theme.spacing(0, 1),
              })}
              variant="h5"
              children="Company Verification"
            />
          </Grid>
          <Grid item>
            <Chip
              id="KYBDialog-status-chip"
              size="small"
              style={{
                color: theme.palette.text.primary,
                backgroundColor:
                  ClientKYCStatus.VerificationInProgressStatus ===
                  myClientKYCStatus
                    ? theme.palette.info.main
                    : ClientKYCStatus.VerifiedStatus === myClientKYCStatus
                      ? theme.palette.success.main
                      : ClientKYCStatus.Outstanding === myClientKYCStatus
                        ? theme.palette.warning.main
                        : theme.palette.info.main,
                fontWeight: 400,
              }}
              label={
                ClientKYCStatus.VerificationInProgressStatus ===
                myClientKYCStatus
                  ? "Under review"
                  : ClientKYCStatus.VerifiedStatus === myClientKYCStatus
                    ? "Completed"
                    : ClientKYCStatus.Outstanding === myClientKYCStatus
                      ? "Outstanding"
                      : "information required"
              }
            />
          </Grid>
          {apiLoading && (
            <Grid item>
              <CircularProgress size={30} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={2} alignItems="center">
          {activeStep !== CompanyRegStep.companyProfile && (
            <Grid item>
              <Button
                disabled={apiLoading}
                onClick={() => handleBackButtonClick()}
                id="KYBDialog-save-button"
                variant="outlined"
              >
                Back
              </Button>
            </Grid>
          )}
          {activeStep !== CompanyRegStep.connectedNonIndividuals && (
            <Grid item>
              <Button
                onClick={handleNextButtonClick}
                id="KYBDialog-next-button"
                variant="contained"
                disabled={apiLoading}
                color="primary"
              >
                Next
              </Button>
            </Grid>
          )}
          {activeStep === CompanyRegStep.connectedNonIndividuals && (
            <Grid item>
              <Button
                onClick={() => setConfirmInfo(true)}
                id="KYBDialog-submit-button"
                variant="contained"
                disabled={apiLoading}
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          )}
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="KYBDialog-close-button"
                  size="small"
                  onClick={handleClose}
                  disabled={validationInProgress}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {myClientKYCStatus === ClientKYCStatus.VerificationInProgressStatus ? (
          <KYCVerificationInProgressPlaceHolder
            isKYBVerificationInProgress={true}
          />
        ) : (
          <CompanyRegistrationView
            formState={formState}
            formUpdater={formUpdater}
            validationResult={validationResult}
            nextPressed={nextPressed}
            setNextPressed={setNextPressed}
            savingInProgress={apiLoading}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleSubmitButton={handleSubmitButton}
            confirmInfo={confirmInfo}
            setConfirmInfo={setConfirmInfo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
