import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import googleIcon from "assets/images/googleIcon.jpg";
import { useSnackbar } from "notistack";
import { useFirebaseContext } from "context/Firebase";
import { InviteUserClaims } from "james/security/claims";
import {
  Close as CloseIcon,
  Error as ResolveIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import debounce from "lodash/debounce";
import { ValidatePassword } from "utilities/validation";
import { LoadingScreen } from "views/CompanyUserRegistration/components/LoadingScreen";
import { useOpenLatestAUSAgreement } from "james/compliance";
import { useErrorContext } from "context/Error";

const PREFIX = "RegisterCredentials";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  signUpCardLayout: `${PREFIX}-signUpCardLayout`,
  signUpCardLeftSection: `${PREFIX}-signUpCardLeftSection`,
  signUpCardRightSection: `${PREFIX}-signUpCardRightSection`,
  bottomPadding16: `${PREFIX}-bottomPadding16`,
  leftSectionInfoLayout: `${PREFIX}-leftSectionInfoLayout`,
  disabledTextColor: `${PREFIX}-disabledTextColor`,
  individualSignUpLayout: `${PREFIX}-individualSignUpLayout`,
  signUpForm: `${PREFIX}-signUpForm`,
  signUpText: `${PREFIX}-signUpText`,
  googleImage: `${PREFIX}-googleImage`,
  individualSignUpButtonsLayout: `${PREFIX}-individualSignUpButtonsLayout`,
  googleSignUpButtonColor: `${PREFIX}-googleSignUpButtonColor`,
  signUpWithGoogleTypographyText: `${PREFIX}-signUpWithGoogleTypographyText`,
  bottomControlText: `${PREFIX}-bottomControlText`,
  h6TypographyFontSize: `${PREFIX}-h6TypographyFontSize`,
  loginInformationText: `${PREFIX}-loginInformationText`,
  dividerPadding: `${PREFIX}-dividerPadding`,
  orTypographyText: `${PREFIX}-orTypographyText`,
  popperCard: `${PREFIX}-popperCard`,
  popperHeaderSection: `${PREFIX}-popperHeaderSection`,
  popperResolveText: `${PREFIX}-popperResolveText`,
  popperIcon: `${PREFIX}-popperIcon`,
  linkCursor: `${PREFIX}-linkCursor`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.content}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.signUpCardLayout}`]: {
    width: "900px",
    height: "450px",
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },

  [`& .${classes.signUpCardLeftSection}`]: {
    backgroundColor: theme.palette.custom.midnight,
    height: "inherit",
    padding: theme.spacing(5),
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },

  [`& .${classes.signUpCardRightSection}`]: {
    height: "inherit",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },

  [`& .${classes.bottomPadding16}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.leftSectionInfoLayout}`]: {
    display: "grid",
    gridTemplateRows: "auto",
    gridRowGap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.disabledTextColor}`]: {
    color: theme.palette.text.disabled,
  },

  [`& .${classes.individualSignUpLayout}`]: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },

  [`& .${classes.signUpForm}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.signUpText}`]: {
    alignSelf: "center",
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.googleImage}`]: {
    width: "28px",
    height: "28px",
  },

  [`& .${classes.individualSignUpButtonsLayout}`]: {
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(6),
  },

  [`& .${classes.googleSignUpButtonColor}`]: {
    backgroundColor: "white",
  },

  [`& .${classes.signUpWithGoogleTypographyText}`]: {
    color: "black",
    opacity: "0.5",
  },

  [`& .${classes.bottomControlText}`]: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(0.5),
  },

  [`& .${classes.h6TypographyFontSize}`]: {
    fontSize: "10px",
  },

  [`& .${classes.loginInformationText}`]: {
    color: theme.palette.primary.main,
    fontSize: "inherit",
    fontWeight: "bold",
  },

  [`& .${classes.dividerPadding}`]: {
    paddingLeft: "2px",
    paddingRight: "2px",
  },

  [`& .${classes.orTypographyText}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignSelf: "center",
  },

  [`& .${classes.popperCard}`]: {
    width: "200px",
    backgroundColor: theme.palette.secondary.light,
    padding: "16px",
    display: "grid",
    gridTemplateRows: "auto",
    color: "black",
    marginTop: "8px",
  },

  [`& .${classes.popperHeaderSection}`]: {
    display: "grid",
    gridTemplateColumns: "20px 1fr 20px",
    gridColumnGap: "4px",
    paddingBottom: "8px",
  },

  [`& .${classes.popperResolveText}`]: {
    fontSize: 12,
    fontWeight: "bold",
    alignSelf: "center",
  },

  [`& .${classes.popperIcon}`]: {
    width: "20px",
    height: "20px",
  },

  [`& .${classes.linkCursor}`]: {
    color: theme.palette.text.tertiary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

interface RegisterCredentialsProps {
  inviteUserClaims: InviteUserClaims;
  onStartRegisterCredentials: () => void;
  onFailure: () => void;
}

export function RegisterCredentials(props: RegisterCredentialsProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { openLatestAUSAgreement } = useOpenLatestAUSAgreement();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { firebaseSignUpWithEmailAndPassword, firebaseLoginWithGoogle } =
    useFirebaseContext();
  const [openPasswordPopper, setOpenPasswordPopper] = useState<boolean>(false);
  const passwordTextFieldEl = useRef<HTMLImageElement>(null);
  const [validationState, setValidationState] = useState<{
    [key: string]: string | undefined | string[];
  }>({});

  const performInlineValidation = (field: string, value: string) =>
    debounce(() => {
      const newValidationState: { individualPassword: string[] } = {
        individualPassword: [],
      };
      if (field === "password") {
        const passwordValidationState = ValidatePassword(value);
        if (passwordValidationState.length !== 0 && openPasswordPopper) {
          newValidationState.individualPassword = passwordValidationState;
          setValidationState(newValidationState);
        } else if (openPasswordPopper) {
          // close the popper
          setOpenPasswordPopper(false);
        }
      }
    });

  const performValidation = () => {
    const newValidationState: { [key: string]: string | undefined | string[] } =
      {};

    const passwordValidationState = ValidatePassword(password);
    if (passwordValidationState.length !== 0 && !openPasswordPopper) {
      newValidationState.individualPassword = passwordValidationState;
      setOpenPasswordPopper(true);
      setValidationState(newValidationState);
      return !Object.keys(newValidationState).length;
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const [signingUpWithUsernamePassword, setSigningUpWithUsernamePassword] =
    useState(false);
  const handleSignUpWithEmailAndPassword = async () => {
    if (!performValidation()) {
      return;
    }
    props.onStartRegisterCredentials();
    try {
      setSigningUpWithUsernamePassword(true);
      await firebaseSignUpWithEmailAndPassword(
        props.inviteUserClaims.invitedUserEmailAddress,
        password,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setSigningUpWithUsernamePassword(false);
      switch (e.code) {
        case "auth/invalid-email":
          enqueueSnackbar(e.message ? e.message : e.toString(), {
            variant: "error",
          });
          break;
        case "auth/invalid-password":
          enqueueSnackbar(e.message ? e.message : e.toString(), {
            variant: "error",
          });
          break;
        case "auth/email-already-exist":
          enqueueSnackbar(e.message ? e.message : e.toString(), {
            variant: "error",
          });
          break;
        default:
          enqueueSnackbar(
            `unexpected error signing up user: ${
              e.message ? e.message : e.toString()
            }`,
            { variant: "error" },
          );
          break;
      }
      props.onFailure();
    }
  };

  const handleSignUpWithGoogle = async () => {
    props.onStartRegisterCredentials();
    try {
      await firebaseLoginWithGoogle();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `unexpected error signing up user: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      props.onFailure();
    }
  };

  if (signingUpWithUsernamePassword) {
    return <LoadingScreen message="Registering Credentials..." />;
  }

  return (
    <Root className={classes.root}>
      <div className={classes.content}>
        <div className={classes.signUpCardLayout}>
          <div className={classes.signUpCardLeftSection}>
            <img
              alt=""
              width={230}
              src={meshMiniLogo}
              className={classes.bottomPadding16}
            />
            <div className={classes.leftSectionInfoLayout}>
              <Typography component="div" variant="h4">
                <p>
                  Create your
                  <span className={classes.loginInformationText}>
                    {" "}
                    Login Information
                  </span>
                </p>
              </Typography>
              <Typography
                variant="body1"
                className={classes.disabledTextColor}
                children="Please create a user for us to grant you access. You can also make use of your Google account to login."
              />
            </div>
          </div>
          <div className={classes.signUpCardRightSection}>
            <div className={classes.individualSignUpLayout}>
              <Typography
                className={classes.signUpText}
                variant="h5"
                children="Sign Up"
              />
              <form
                className={classes.signUpForm}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <TextField
                  label="Email"
                  autoComplete="email"
                  id="registerCredentials-email-textfield"
                  disabled
                  value={props.inviteUserClaims.invitedUserEmailAddress}
                />
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="registerCredentials-password-textfield"
                  autoComplete="current-password"
                  ref={passwordTextFieldEl}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    performInlineValidation("password", e.target.value)();
                  }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Popper
                  anchorEl={passwordTextFieldEl.current}
                  open={openPasswordPopper}
                  placement="bottom-start"
                >
                  <Paper className={classes.popperCard}>
                    <div className={classes.popperHeaderSection}>
                      <ResolveIcon className={classes.popperIcon} />
                      <Typography
                        variant="body2"
                        className={classes.popperResolveText}
                      >
                        Please Resolve
                      </Typography>
                      <CloseIcon
                        className={classes.popperIcon}
                        onClick={() => setOpenPasswordPopper(false)}
                      />
                    </div>
                    {(() => (
                      <>
                        {validationState.individualPassword &&
                          (validationState.individualPassword as string[]).map(
                            (v, idx) => (
                              <Typography key={idx} variant="body2">
                                {v}
                              </Typography>
                            ),
                          )}
                      </>
                    ))()}
                  </Paper>
                </Popper>
              </form>
              <div className={classes.individualSignUpButtonsLayout}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  id="registerCredentials-emailAndPasswordSignUp-button"
                  children="sign up"
                  onClick={handleSignUpWithEmailAndPassword}
                />
                <Typography
                  color="textSecondary"
                  className={classes.orTypographyText}
                  variant="body1"
                  children="or"
                />
                <Button
                  startIcon={
                    <img
                      alt=""
                      className={classes.googleImage}
                      src={googleIcon}
                    />
                  }
                  className={classes.googleSignUpButtonColor}
                  variant="contained"
                  size="large"
                  id="registerCredentials-signUpWithGoogle-button"
                  onClick={handleSignUpWithGoogle}
                  children={
                    <Typography
                      className={classes.signUpWithGoogleTypographyText}
                      variant="body2"
                    >
                      Sign up with Google
                    </Typography>
                  }
                />
              </div>
              <div className={classes.bottomControlText}>
                <Typography
                  className={classes.h6TypographyFontSize}
                  color="textSecondary"
                  variant="h6"
                >
                  <Link
                    id="signUp-termsAndConditions-link"
                    color="textSecondary"
                    onClick={openLatestAUSAgreement}
                    className={classes.linkCursor}
                  >
                    Terms & Conditions
                  </Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
