import { Address } from "james/location";

export enum ConnectionType {
  ManagerConnectionType = "Manager",
  ShareholderConnectionType = "Shareholder",
  AgentConnectionType = "Agent",
  SoleProprietorConnectionType = "Sole Proprietor",
  PartnerConnectionType = "Partner",
}

export enum IdentificationType {
  SouthAfricanIDIdentificationType = "South African ID",
  PassportIdentificationType = "Passport",
}

export const AllIdentificationTypes = [
  IdentificationType.SouthAfricanIDIdentificationType,
  IdentificationType.PassportIdentificationType,
];

export class ConnectedIndividual {
  public connectionType: ConnectionType | "" = "";

  public firstName = "";

  public middleNames = "";

  public lastName = "";

  public dateOfBirth = ""; // format: 2006-01-02

  public nationality = "";

  public identificationType: IdentificationType | "" = "";

  public identificationNumber = ""; // format depends on IdentificationType

  public physicalAddress: Address = new Address();

  public postalAddress: Address = new Address();

  public telephoneNumber = ""; // At least one of Telephone, Cellphone or Email must be provided

  public cellphoneNumber = ""; // At least one of Telephone, Cellphone or Email must be provided

  public emailAddress = ""; // At least one of Telephone, Cellphone or Email must be provided

  constructor(connectedIndividual?: ConnectedIndividual) {
    if (!connectedIndividual) {
      return;
    }
    this.connectionType = connectedIndividual.connectionType;
    this.firstName = connectedIndividual.firstName;
    this.middleNames = connectedIndividual.middleNames;
    this.lastName = connectedIndividual.lastName;
    this.dateOfBirth = connectedIndividual.dateOfBirth;
    this.nationality = connectedIndividual.nationality;
    this.identificationType = connectedIndividual.identificationType;
    this.identificationNumber = connectedIndividual.identificationNumber;
    this.physicalAddress = new Address(connectedIndividual.physicalAddress);
    this.postalAddress = new Address(connectedIndividual.postalAddress);
    this.telephoneNumber = connectedIndividual.telephoneNumber;
    this.cellphoneNumber = connectedIndividual.cellphoneNumber;
    this.emailAddress = connectedIndividual.emailAddress;
  }
}
