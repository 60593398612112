import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { Reader } from "james/views/stellarClaimableBalanceView/Reader";
import { Model as StellarClaimableBalanceModel } from "james/views/stellarClaimableBalanceView/Model";
import { BooleanCriterion, TextExactCriterion } from "james/search/criterion";
import { Query } from "james/search/query";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useIsMounted } from "hooks";
import { useSnackbar } from "notistack";
import { Refresh as ReloadIcon } from "@mui/icons-material";
import { Updater } from "james/views/stellarClaimableBalanceView/Updater";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { Model } from "james/views/stellarAccountView";
import { useAccountContext } from "context/Account/Account";
import { useApplicationContext } from "context/Application/Application";
import { ClaimableBalanceCard } from "./components/ClaimableBalanceCard";
import { useErrorContext } from "context/Error";

interface ClaimableBalanceListProps {
  accountViewModel: Model;
}

export function ClaimableBalanceList(props: ClaimableBalanceListProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [models, setModels] = useState<StellarClaimableBalanceModel[]>([]);
  const [userSignatoryOnAccount, setUserSignatoryOnAccount] = useState(false);
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [reloadClickCount, setReloadClickCount] = useState(0);
  const { registerNotificationCallback } = useNotificationContext();
  const { stellarAccountContext } = useAccountContext();

  // read the claimable balance view
  const handleReadModels = useCallback(async () => {
    if (isMounted()) {
      setLoading(true);
    }

    try {
      const readResponse = await Reader.Read({
        context: authContext,
        query: new Query(),
        criteria: {
          claimed: BooleanCriterion(false),
          archived: BooleanCriterion(false),
          claimantAccountLedgerID: TextExactCriterion(
            props.accountViewModel.ledgerID,
          ),
        },
      });
      setModels(readResponse.models);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error reading view models: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar("Error Retrieving Claimable balances", {
        variant: "error",
      });
    }

    if (isMounted()) {
      setLoading(false);
    }
  }, [props.accountViewModel.ledgerID, authContext, enqueueSnackbar]);

  const handleUpdateModelForAccount = useCallback(async () => {
    setLoading(true);
    try {
      await Updater.UpdateForAccount({
        context: authContext,
        accountIdentifier: LedgerIDIdentifier(props.accountViewModel.ledgerID),
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error updating view models for account: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar("Error Retrieving Claimable balances", {
        variant: "error",
      });
    }
    setLoading(false);
  }, [enqueueSnackbar, authContext, props.accountViewModel.ledgerID]);

  const handleReloadClaimableBalance = useCallback(async () => {
    setReloadClickCount(reloadClickCount + 1);
    if (reloadClickCount < 2) {
      await handleReadModels();
    } else {
      await handleUpdateModelForAccount();
      await handleReadModels();
      setReloadClickCount(0);
    }
  }, [reloadClickCount, handleReadModels, handleUpdateModelForAccount]);

  useEffect(() => {
    // determine if there is transaction ID stored in local Storage
    const txnIDs: string[] = [];
    const modelIDs: string[] = [];
    const deregisterCallbacks: (() => void)[] = [];

    models.forEach((m) => {
      const txnID = localStorage.getItem(
        `claimableBalanceViewModelID-${m.id}-claimingTransactionID`,
      );
      if (txnID !== null) {
        txnIDs.push(txnID);
        modelIDs.push(m.id);
      }
    });

    (async () => {
      for (let i = 0; i < txnIDs.length; i++) {
        try {
          deregisterCallbacks.push(
            await registerNotificationCallback(
              new TransactionNotificationChannel({
                transactionID: txnIDs[i],
                private: true,
              }),
              [TransactionSucceededNotificationTypeName],
              (n: Notification) => {
                if (n instanceof TransactionSucceededNotification) {
                  // on success filter out card
                  setModels(models.filter((value) => value.id !== modelIDs[i]));
                }

                localStorage.removeItem(
                  `claimableBalanceViewModelID-${modelIDs[i]}-claimingTransactionID`,
                );
              },
            ),
          );
        } catch (e) {
          console.error(
            "error registring for claim claimable balance transaction notifications",
            e,
          );
        }
      }
    })();

    return () => deregisterCallbacks.forEach((deregister) => deregister());
  }, [registerNotificationCallback, models]);

  useEffect(() => {
    (async () => {
      // if account context is still loading return
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        return;
      }

      // check if the user is a signatory on the account
      try {
        if (isMounted()) {
          setUserSignatoryOnAccount(
            await stellarAccountContext.checkUserSignatoryOnAccount(
              LedgerIDIdentifier(props.accountViewModel.ledgerID),
            ),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      await handleReadModels();
    })();
  }, [
    handleReadModels,
    stellarAccountContext.error,
    stellarAccountContext.loading,
    props.accountViewModel.ledgerID,
    enqueueSnackbar,
    isMounted,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Claimable balance title header text */}
      {!loading && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <Typography
            variant="caption"
            component="p"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          >
            <Typography color="textPrimary" variant="caption" component="span">
              Please Note:&nbsp;
            </Typography>
            Selecting to claim the Claimable Balance will also set the limit of
            the asset.
          </Typography>
          <IconButton size="small" onClick={handleReloadClaimableBalance}>
            <ReloadIcon />
          </IconButton>
        </Box>
      )}
      {loading && (
        <Skeleton
          sx={(theme) => ({
            transform: "unset",
            marginBottom: theme.spacing(2),
          })}
          width={"100%"}
          height={17}
        />
      )}
      {loading && (
        <Skeleton
          sx={{ transform: "unset" }}
          component="div"
          width={"100%"}
          height={216}
        />
      )}
      {!loading && (
        <Box
          sx={(theme) => ({
            height: "100%",
            marginTop: theme.spacing(2),
          })}
          className={"meshScroll"}
        >
          {models.map((v, i) => (
            <React.Fragment key={i}>
              <ClaimableBalanceCard
                claimaintAccountID={props.accountViewModel.id}
                isUserSignatoryOnAccount={userSignatoryOnAccount}
                removeFromList={(id) =>
                  setModels(models.filter((value) => value.id !== id))
                }
                model={v}
              />
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}
