import { TextNINListCriterionTypeName } from "james/search/criterion";

export interface TextNINListCriterionType {
  type: string;
  list: string[];
}

function TextNINListCriterion(list: string[]): TextNINListCriterionType {
  return {
    type: TextNINListCriterionTypeName,
    list,
  };
}

export { TextNINListCriterion };
