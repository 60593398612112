import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ParticipantSection } from "./components/ParticipantSection";
import { UnderwritersSection } from "./components/UnderwritersSection";
import { Token } from "james/ledger";
import { ParticipantsLoader } from "./components/ParticipantsLoader";
import { Model as MarketListingViewModel } from "james/views/marketListingView/Model";
import { PublicModel as PublicMarketListingViewModel } from "james/views/marketListingView";
import cx from "classnames";
import { ExpandMore } from "@mui/icons-material";
import { GuarantorSection } from "./components/GuarantorSection";
import { useAssetOverviewContext } from "../Context";

interface ParticipantsProps {
  token: Token;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

export const Participants = ({ marketListingViewModel }: ParticipantsProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { assetParticipants, assetParticipantsLoading } =
    useAssetOverviewContext();

  const numberOfLinkedParticipants =
    assetParticipants.linkedParticipants?.otherLinkedParticipants?.length ?? 0;
  const hasOddNumberOfLinkedParticipants = numberOfLinkedParticipants % 2 !== 0;

  if (assetParticipantsLoading) {
    return <ParticipantsLoader />;
  }

  return (
    <Box>
      <Box
        className={cx({
          "no-underwriters": [
            assetParticipants.instrumentParticipants.underwriters.length > 0,
          ],
        })}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          "&.no-underwriters": {
            height: "100%",
            bottom: 0,
          },
        })}
      >
        <ParticipantSection
          logoUrl={
            assetParticipants.instrumentParticipants.issuer.logoUrl ??
            marketListingViewModel.issuerLogo?.url ??
            ""
          }
          participantProfile={assetParticipants.instrumentParticipants.issuer}
          issuerSection={true}
        />
        {(assetParticipants.instrumentParticipants.underwriters.length > 0 ||
          assetParticipants.instrumentParticipants.guarantor) && (
          <Divider sx={{ mx: { sm: 5, xs: 3 } }} />
        )}
        <UnderwritersSection
          underwriters={assetParticipants.instrumentParticipants.underwriters}
        />
        {assetParticipants.instrumentParticipants.guarantor && (
          <GuarantorSection
            guarantor={assetParticipants.instrumentParticipants.guarantor}
          />
        )}
        {(assetParticipants.instrumentParticipants.underwriters.length > 0 ||
          assetParticipants.instrumentParticipants.guarantor) && (
          <Box sx={{ pb: 5 }} />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grape,
          display: "flex",
          flexWrap: "wrap",
          // gap: 2, // Adjust the space between items
        })}
      >
        {assetParticipants.instrumentParticipants.externalParticipants.map(
          (participant, index) => (
            <Box
              key={index}
              sx={{
                flexBasis: {
                  xs: "100%", // On mobile, all items take full width
                  sm:
                    index === 0 &&
                    assetParticipants.instrumentParticipants
                      .externalParticipants.length %
                      2 !==
                      0
                      ? "100%" // On larger screens, if odd number, first item takes full width
                      : "calc(50%)", // Otherwise, half width for the rest
                },
                boxSizing: "border-box",
              }}
            >
              <ParticipantSection
                logoUrl={participant.logoUrl}
                participantProfile={participant}
                issuerSection={
                  !smDown &&
                  index === 0 &&
                  assetParticipants.instrumentParticipants.externalParticipants
                    .length %
                    2 !==
                    0
                }
                rightAlignSocialMedia={true}
              />
              {smDown && <Divider sx={{ mx: 3 }} />}
            </Box>
          ),
        )}
      </Box>
      {assetParticipants.linkedParticipants && (
        <Accordion disableGutters>
          <AccordionSummary
            sx={(theme) => ({
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
              padding: theme.spacing(0, 4),
            })}
            expandIcon={
              <ExpandMore
                id={`accordion-expandToggle-icon`}
                color={"primary"}
              />
            }
          >
            <Typography
              sx={{ pl: 2 }}
              variant={"h6"}
              children={"Underlying Asset"}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Box
              className={cx({})}
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
              })}
            >
              {assetParticipants.linkedParticipants?.issuer && (
                <ParticipantSection
                  logoUrl={
                    marketListingViewModel.issuerLogo?.url ??
                    assetParticipants.linkedParticipants?.issuer.logoUrl ??
                    ""
                  }
                  participantProfile={
                    assetParticipants.linkedParticipants.issuer
                  }
                  issuerSection={true}
                  rightAlignSocialMedia={true}
                />
              )}
            </Box>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.custom.grape,
                display: "flex",
                flexWrap: "wrap",
                //gap: 2, // Adjust the space between items
              })}
            >
              {assetParticipants.linkedParticipants?.otherLinkedParticipants.map(
                (participant, index) => (
                  <Box
                    key={index}
                    sx={{
                      flexBasis: {
                        xs: "100%", // On mobile, all items take full width
                        sm:
                          index === 0 && hasOddNumberOfLinkedParticipants
                            ? "100%" // On larger screens, if odd number, first item takes full width
                            : "calc(50%)", // Otherwise, half width for the rest
                      },
                      boxSizing: "border-box",
                    }}
                  >
                    <ParticipantSection
                      logoUrl={participant.logoUrl}
                      participantProfile={participant}
                      issuerSection={
                        !smDown &&
                        index === 0 &&
                        hasOddNumberOfLinkedParticipants
                      }
                      rightAlignSocialMedia={true}
                    />
                    {smDown && <Divider sx={{ mx: 3 }} />}
                  </Box>
                ),
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
