import { Context } from "james/security";
import { Group } from "james/group";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

export interface RetrieveGroupRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveGroupResponse {
  group: Group;
}

export interface SearchGroupsRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchGroupsResponse {
  records: Group[];
  total: number;
}

export interface UpdateGroupRequest {
  context: Context;
  identifier: Identifier;
  group: Group;
}

export interface UpdateGroupResponse {
  group: Group;
}

export const Repository = {
  serviceProvider: "group-Repository",
  async RetrieveGroup(
    request: RetrieveGroupRequest,
  ): Promise<RetrieveGroupResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.RetrieveGroup`,
      request,
    });
    return { group: new Group(response.group) };
  },
  async SearchGroups(
    request: SearchGroupsRequest,
  ): Promise<SearchGroupsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.SearchGroups`,
      request,
    });
    return {
      records: response.records.map((g: Group) => new Group(g)),
      total: response.total,
    };
  },
  async UpdateGroup(request: UpdateGroupRequest): Promise<UpdateGroupResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.UpdateGroup`,
      request,
    });
    return {
      group: response.group,
    };
  },
};
