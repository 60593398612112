import React, { useCallback, useEffect, useState } from "react";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  MarketListingViewModel,
  useMarketListingViewReaderRead,
} from "james/views/marketListingView";
import { AllListingStates, ListingState } from "james/market/Listing";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import { ListingStateChip } from "views/MarketListing/MarketListings/Chips";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { ListingStateController } from "james/market";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useNavigate } from "react-router-dom";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import { Token } from "james/ledger";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { SubscriptionOrderBookStateChip } from "components/MarketSubscriptions/Chips";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
});

export function Table() {
  const navigate = useNavigate();
  const { authContext, viewConfiguration } = useApplicationContext();
  const [selectedListingViewModel, setSelectedListingViewModel] = useState<
    MarketListingViewModel | undefined
  >(undefined);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const system = !!viewConfiguration["Smart Instruments"]?.ReadUNSCOPED;

  // on initial load construct a scope that should be applied to filtering of listings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [criteriaForRead, setCriteriaForRead] = useState<any | null>(null);
  useEffect(() => {
    // if system user then no scope
    if (system) {
      setCriteriaForRead({});
      return;
    }

    // otherwise build a scope
    (async () => {
      try {
        setCriteriaForRead(
          (
            await Determiner.DetermineScopeCriteriaByRoles({
              context: authContext,
              // only allow seeing listings in the groups in which the user can read smart instruments
              service: new Permission({
                serviceName: "ReadOneSmartInstrument",
                serviceProvider: "financial-SmartInstrumentReader",
                description: "-",
              }),
              criteria: {},
              scopeFields: [ScopeFields.OwnerIDField],
              buildScopeTree: false,
            })
          ).criteria,
        );
      } catch (e) {
        console.error(`error loading scoped criteria: ${e}`);
      }
    })();
  }, [system, authContext]);

  // use hook to read the market listing view - but do not read data on first load
  const {
    readResponse,
    loading,
    setReadRequest,
    readRequest,
    setShouldNotExecute,
  } = useMarketListingViewReaderRead(
    {
      context: authContext,
      query: new Query(initialQuery),
      criteria: {},
    },
    true, // disable on initial run
  );

  // once criteria for read has loaded then allow marketlisting view reader to execute
  useEffect(() => {
    if (criteriaForRead) {
      setReadRequest((readRequest) => ({
        ...readRequest,
        criteria: {
          ...criteriaForRead,
          ...readRequest.criteria,
        },
      }));
      setShouldNotExecute(false);
    }
  }, [criteriaForRead]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [listingStatusesCriterion, setListingStatusesCriterion] = useState<
    ListingState[]
  >([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { assetName: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            assetShortName: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
          { assetType: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            listingLastActionAnnotation: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (listingStatusesCriterion.length) {
      criteria.listingState = TextListCriterion(listingStatusesCriterion);
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria: {
        ...criteriaForRead,
        ...criteria,
      },
    });
  }, [
    listingStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  // function to return a placement retry button
  const [apiLoading, setAPILoading] = useState(false);
  const retryPlacementButton = useCallback(
    (assetToken: Token) => (
      <Button
        id="smartInstrumentForm-marketListingRetry-button"
        variant="outlined"
        children="Retry Listing"
        disabled={apiLoading}
        onClick={async () => {
          setAPILoading(true);
          try {
            // invoke call to retry asset listing
            await ListingStateController.RetryListAsset({
              context: authContext,
              assetToken,
            });

            // notify that listing is in progress
            enqueueSnackbar(
              "Listing is in Progress - Refresh Table to Monitor Progress",
              { variant: "info" },
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(`error retrying`, e);
            enqueueSnackbar(`Error Retrying Listing: ${err.message}`, {
              variant: "error",
            });
          }
          setAPILoading(false);
        }}
      />
    ),
    [authContext, apiLoading],
  );

  return (
    <>
      <BPTable
        singleSelect
        loading={loading || !criteriaForRead || apiLoading}
        height={window.innerHeight - 90 - noticeBannerHeight}
        title={"Market Listings"}
        data={readResponse.models}
        onSingleSelectChange={(data) =>
          setSelectedListingViewModel(data as MarketListingViewModel)
        }
        columns={[
          {
            field: "tokenIcon",
            sortable: false,
            label: "",
            accessor: (data) => (
              <IconViewUpload token={(data as MarketListingViewModel).token} />
            ),
          },
          {
            label: "Name",
            field: "assetName",
          },
          {
            label: "Short Name",
            field: "assetShortName",
          },
          {
            label: "Asset Type",
            field: "assetType",
          },
          {
            label: "Mechanism(s)",
            field: "listingMarketMechanisms",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) =>
              (data as MarketListingViewModel).listingMarketMechanisms
                .map((m) => m.type)
                .join(","),
          },
          {
            label: "Listing State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              return (
                <ListingStateChip
                  state={(data as MarketListingViewModel).listingState}
                />
              );
            },
          },
          {
            label: "Primary Market State",
            field: "state",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const bookViewModel = (data as MarketListingViewModel)
                .marketSubscriptionOrderBookViewModel;

              return bookViewModel ? (
                <SubscriptionOrderBookStateChip state={bookViewModel.state} />
              ) : (
                "-"
              );
            },
          },
        ]}
        query={readRequest.query}
        onQueryChange={(query: Query) =>
          setReadRequest({
            ...readRequest,
            query,
          })
        }
        totalNoRecords={readResponse.total}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (
            selectedListingViewModel &&
            selectedListingViewModel.newInstrumentModel
          ) {
            controls.push(
              <Button
                id={"smartInstrumentForm-marketListing-button"}
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  const token = selectedListingViewModel.token.toFutureToken();

                  const query = new URLSearchParams();

                  query.set("token-code", token.getCode());
                  query.set("token-issuer", token.getIssuer());
                  query.set("token-network", token.getNetwork().toString());

                  navigate({
                    pathname: "/builder/market-listing/listing",
                    search: query.toString(),
                  });
                }}
              >
                View
              </Button>,
            );

            if (
              viewConfiguration["MC Market Listing"]?.["Market Listings"]?.[
                "Operate"
              ] &&
              selectedListingViewModel.listingState ===
                ListingState.ListingFailed
            ) {
              controls.push(
                retryPlacementButton(selectedListingViewModel.token),
              );
            }
          }

          controls.push(
            <Tooltip title={"Refresh"}>
              <span>
                <IconButton
                  onClick={() =>
                    setReadRequest({
                      ...readRequest,
                      query: new Query(initialQuery),
                    })
                  }
                  id={"marketListingsTable-refresh-iconButton"}
                  disabled={loading}
                  size={"small"}
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controls;
        })()}
        filters={[
          <TextField
            id={"marketListingsTable-textFilter-textField"}
            variant={"outlined"}
            margin={"dense"}
            label={"Search Text Field"}
            placeholder={"Start Typing..."}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position={"end"}
                  children={
                    <IconButton
                      id={
                        "marketListingsTable-textFilterClearButton-iconButton"
                      }
                      size={"small"}
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"marketListingsTable-stateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={AllListingStates}
            filterSelectedOptions
            onChange={(_, value: ListingState[]) =>
              setListingStatusesCriterion(value)
            }
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={"marketListingsTable-stateFilter-autocompleteTextField"}
                label={"Listing State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  listingStatusesCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
        ]}
      />
    </>
  );
}
