import React, { useEffect, useState } from "react";
import {
  Model as MarketSpotViewModel,
  Updater,
} from "james/views/marketSpotView";
import { useUnscopedRead as useMarketSpotViewReaderUnscopedRead } from "james/views/marketSpotView/UnscopedReader";
import { NewSorting, Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";
import { Amount } from "components/Ledger/Amount";
import {
  Cancel as CancelIcon,
  DeleteForever as ClearFiltersIcon,
  Error as ErrorIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { SpotStateChip } from "views/Marketplace/components/Trades/Chips";
import { AllSpotStates, SpotState, SpotStateController } from "james/market";
import {
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { useIsMounted } from "hooks";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DateField } from "components/FormFields";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import { AllStellarNetworks } from "james/stellar";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { TextSubstringCriterionType } from "james/search/criterion/text/Substring";
import { TextListCriterionType } from "james/search/criterion/text/List";
import { DateRangeCriterionType } from "pkgTemp/search/criterion/date/Range";
import { TextExactCriterionType } from "james/search/criterion/text/Exact";
import { TransactionTable } from "components/Ledger/Transaction";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

const defaultTokenCriteria = {
  "token.network": TextListCriterion(AllStellarNetworks),
  tokenCategory: TextListCriterion([
    TokenCategory.CryptoCurrency,
    TokenCategory.RightsToFiatCurrency,
    TokenCategory.RightsToCryptoCurrency,
  ]),
};

export const Spot = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    readResponse: ledgerTokenViewReadResponse,
    readRequest: ledgerTokenViewReadRequest,
    setReadRequest: setLedgerTokenViewReadRequest,
    loading: ledgerTokenViewReadLoading,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: defaultTokenCriteria,
  });
  const {
    setUnscopedReadRequest,
    unscopedReadRequest,
    unscopedReadResponse,
    loading,
  } = useMarketSpotViewReaderUnscopedRead({
    context: authContext,
    criteria: {},
    query: initialQuery,
  });

  interface Criteria {
    number: TextSubstringCriterionType | undefined;
    state: TextListCriterionType | undefined;
    submittedAt: DateRangeCriterionType | undefined;
    $or: Array<Record<string, TextExactCriterionType>> | undefined;
  }

  // table criteria
  const [numberSearchCriteria, setNumberSearchCriteria] = useState("");
  const [spotStatusesForCriterion, setSpotStatusesForCriterion] = useState<
    SpotState[]
  >([]);
  const [spotDateTimeCriterionFrom, setSpotDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [spotDateTimeCriterionTo, setSpotDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [assetToken, setAssetToken] = useState<TokenViewModel | null>(null);
  useEffect(() => {
    // prepare new initial criteria
    let criteria: Criteria = {
      number: undefined,
      state: undefined,
      submittedAt: undefined,
      $or: undefined,
    };

    if (numberSearchCriteria) {
      criteria.number = TextSubstringCriterion(numberSearchCriteria);
    }

    if (spotStatusesForCriterion.length) {
      criteria.state = TextListCriterion(spotStatusesForCriterion);
    }

    if (spotDateTimeCriterionFrom || spotDateTimeCriterionTo) {
      criteria.submittedAt = DateRangeCriterion(
        spotDateTimeCriterionFrom,
        spotDateTimeCriterionTo,
      );
    }

    // search by asset
    if (assetToken) {
      criteria = {
        ...criteria,
        $or: [
          {
            "receivedAmount.token.code": TextExactCriterion(
              assetToken.token.code,
            ),
          },
          {
            "paidAmount.token.code": TextExactCriterion(assetToken.token.code),
          },
        ],
      };
    }

    if (isMounted()) {
      setUnscopedReadRequest({
        context: unscopedReadRequest.context,
        query: unscopedReadRequest.query,
        criteria,
      });
    }
  }, [
    numberSearchCriteria,
    spotStatusesForCriterion,
    spotDateTimeCriterionFrom,
    spotDateTimeCriterionTo,
    assetToken,
  ]);

  const [apiLoading, setAPILoading] = useState(false);
  const handleFullUpdate = async () => {
    setAPILoading(true);
    try {
      await Updater.FullUpdate({
        context: authContext,
      });
      enqueueSnackbar("Full Update Complete", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error performing full update: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Performing Full Update: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setAPILoading(false);
  };

  const [selectedSpotViewModel, setSelectedSpotViewModel] = useState<
    MarketSpotViewModel | undefined
  >(undefined);
  const handleResolveState = async () => {
    if (!selectedSpotViewModel) {
      console.error("selected market spot view model not set");
      return;
    }

    setAPILoading(true);
    try {
      await SpotStateController.ResolveSpotIDState({
        context: authContext,
        spotID: selectedSpotViewModel.spotID,
      });
      setSelectedSpotViewModel(undefined);
      setUnscopedReadRequest({ ...unscopedReadRequest });
      enqueueSnackbar("Spot State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving spot state`, e);
      enqueueSnackbar(`error resolving spot state: ${err.message}`, {
        variant: "error",
      });
    }
    setAPILoading(false);
  };

  return (
    <BPTable
      loading={loading || apiLoading}
      height={window.innerHeight - 140 - noticeBannerHeight}
      singleSelect
      onSingleSelectChange={(data) =>
        setSelectedSpotViewModel(data as MarketSpotViewModel)
      }
      title={"Spot Trades"}
      query={unscopedReadRequest.query}
      onQueryChange={(query) =>
        setUnscopedReadRequest({
          ...unscopedReadRequest,
          query,
        })
      }
      data={unscopedReadResponse.models}
      totalNoRecords={unscopedReadResponse.total}
      filters={(() => {
        const filters: React.ReactNode[] = [
          <TextField
            id={"marketSpotTable-numberFilter-textField"}
            sx={{ width: 160 }}
            variant={"outlined"}
            margin={"dense"}
            label={"Number"}
            placeholder={"Start Typing..."}
            value={numberSearchCriteria}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setNumberSearchCriteria(e.target.value)}
          />,
          <DateField
            label={"From"}
            disabled={loading}
            id={"marketSpotTable-spotDateTimeFromFilter-dateField"}
            value={
              spotDateTimeCriterionFrom ? spotDateTimeCriterionFrom.date : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue.date()))) {
                setSpotDateTimeCriterionFrom(undefined);
              } else {
                setSpotDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                id={"marketSpotTable-spotDateTimeFromFilter-dateFieldTextField"}
                sx={{ width: 160 }}
                variant={"outlined"}
                margin={"dense"}
              />
            )}
          />,
          <DateField
            label={"To"}
            disabled={loading}
            id={"marketSpotTable-spotDateTimeToFilter-dateField"}
            value={
              spotDateTimeCriterionTo ? spotDateTimeCriterionTo.date : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setSpotDateTimeCriterionTo(undefined);
              } else {
                setSpotDateTimeCriterionTo(
                  newValue
                    ? {
                        date: newValue.endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                id={"marketSpotTable-spotDateTimeToFilter-dateFieldTextField"}
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"marketSpotTable-assetFilter-autocomplete"}
            getOptionLabel={(option: TokenViewModel) =>
              `${option.token.code} - ${option.issuer}`
            }
            options={ledgerTokenViewReadResponse.models}
            loading={ledgerTokenViewReadLoading}
            onChange={(_, selected: TokenViewModel | null) =>
              setAssetToken(selected)
            }
            value={assetToken}
            onInputChange={(e, newInputValue, reason) => {
              if (reason === "reset") {
                return;
              }
              if (newInputValue === "") {
                setLedgerTokenViewReadRequest({
                  ...ledgerTokenViewReadRequest,
                  criteria: defaultTokenCriteria,
                });
              } else {
                setLedgerTokenViewReadRequest({
                  ...ledgerTokenViewReadRequest,
                  criteria: {
                    ...defaultTokenCriteria,
                    "token.code": TextSubstringCriterion(newInputValue),
                  },
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Asset"}
                id={"marketSpotTable-assetFilter-autocompleteTextField"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select...",
                  sx: { width: 200 },
                }}
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"marketSpotTable-stateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={AllSpotStates}
            filterSelectedOptions
            onChange={(_, value: SpotState[]) =>
              setSpotStatusesForCriterion(value)
            }
            ChipProps={{
              color: "info",
              size: "small",
            }}
            value={spotStatusesForCriterion}
            renderTags={(spotStates: SpotState[]) =>
              spotStates.map((s, idx) => (
                <SpotStateChip
                  key={idx}
                  chipProps={{
                    onDelete: () =>
                      setSpotStatusesForCriterion((prev) =>
                        prev.filter((prevState) => prevState !== s),
                      ),
                    deleteIcon: (
                      <CancelIcon
                        sx={(theme) => ({
                          color: `${theme.palette.text.secondary} !important`,
                          "&:hover": {
                            color: `${theme.palette.secondary.contrastText} !important`,
                          },
                        })}
                      />
                    ),
                  }}
                  state={s}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={"marketSpotTable-stateFilter-autocompleteTextField"}
                sx={{ width: 317 }}
                label={"State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  spotStatusesForCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
        ];

        // if any criteria is set then show a clear all filters button
        if (
          numberSearchCriteria ||
          spotStatusesForCriterion.length ||
          spotDateTimeCriterionFrom ||
          spotDateTimeCriterionTo ||
          assetToken
        ) {
          filters.push(
            <Button
              sx={{ marginTop: "10px" }}
              id={"marketSpotTable-clearAllFilters-button"}
              variant={"contained"}
              color={"secondary"}
              children={"clear all"}
              onClick={() => {
                setNumberSearchCriteria("");
                setSpotStatusesForCriterion([]);
                setSpotDateTimeCriterionFrom(undefined);
                setSpotDateTimeCriterionTo(undefined);
                setAssetToken(null);
              }}
              startIcon={<ClearFiltersIcon />}
            />,
          );
        }

        return filters;
      })()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (viewConfiguration.Trading?.["Spot"]?.ViewModel?.FullUpdate) {
          controls.push(
            <Button
              variant={"outlined"}
              id={"marketSpotTable-fullUpdate-button"}
              children={"full view update"}
              onClick={handleFullUpdate}
            />,
          );
        }

        if (
          viewConfiguration.Trading?.["Spot"]?.Resolve &&
          selectedSpotViewModel &&
          ![SpotState.Failed, SpotState.Settled].includes(
            selectedSpotViewModel.state as SpotState,
          )
        ) {
          controls.push(
            <Button
              variant={"outlined"}
              id={"marketSpotTable-resolveState-button"}
              children={"Resolve State"}
              onClick={handleResolveState}
            />,
          );
        }

        controls.push(
          <IconButton
            id={"marketSpotTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() => {
              setUnscopedReadRequest({ ...unscopedReadRequest });
            }}
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        // Trade No.
        {
          label: "Trade No.",
          field: "number",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return (
              <Typography
                id={`marketSpotTable-spotNumber-text-${model.number}`}
                color={"inherit"}
                variant={"body1"}
              >
                {model.number}
              </Typography>
            );
          },
        },
        // Legal Entity Name
        {
          label: "Legal Entity Name",
          field: "clientName",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return (
              <Typography
                id={`marketSpotTable-clientName-text-${model.clientName}`}
                color={"inherit"}
                variant={"body1"}
              >
                {model.clientName}
              </Typography>
            );
          },
        },
        // Date
        {
          label: "Date",
          field: "date",
          accessor: (data) =>
            dayjs((data as MarketSpotViewModel).submittedAt).format(DateFormat),
        },
        // From Account
        {
          label: "From Account",
          field: "fromAccountDescription",
        },
        // Received
        {
          label: "Received",
          field: "receivedAmount.value.float",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return model.receivedAmount.isUndefined() ? (
              "-"
            ) : (
              <Amount
                amount={model.receivedAmount}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            );
          },
        },
        // Paid
        {
          label: "Paid",
          field: "paidAmount.value.float",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return model.paidAmount.isUndefined() ? (
              "-"
            ) : (
              <Amount
                amount={model.paidAmount}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            );
          },
        },
        // Price
        {
          label: "Price",
          field: "price.value.float",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return model.price.isUndefined() ? (
              "-"
            ) : (
              <Amount
                amount={model.price.setValue(
                  model.price.value.decimalPlaces(2),
                )}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            );
          },
        },
        // Fee
        {
          label: "Fee",
          field: "feeAmount.value.float",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return model.feeAmount.isUndefined() ? (
              "-"
            ) : (
              <Amount
                amount={model.feeAmount}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            );
          },
        },
        // VAT
        {
          label: "VAT Amount",
          field: "vatRate",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            return model.feeAmount.isUndefined() ? (
              "-"
            ) : (
              <Amount
                amount={model.feeAmount.setValue(
                  model.feeAmount.value.multipliedBy(model.vatRate),
                )}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            );
          },
        },
        // State
        {
          label: "State",
          field: "state",
          accessor: (data) => {
            const model = data as MarketSpotViewModel;
            if (model.state === SpotState.Failed) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    gap: theme.spacing(1),
                    alignItems: "center",
                  })}
                >
                  <SpotStateChip state={model.state} />
                  <Tooltip
                    title={model.failureReasons.join(", ")}
                    placement={"top"}
                  >
                    <ErrorIcon
                      sx={{
                        color: "secondary.light",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            }
            return <SpotStateChip state={model.state} />;
          },
        },
      ]}
      expandRowComponent={{
        maxHeight: 300,
        component: (selectedRowData) => {
          const spotViewModel = selectedRowData as MarketSpotViewModel;
          return (
            <TransactionTable
              height={400}
              title={`Transactions for Spot No ${spotViewModel.number}`}
              constantCriteria={{
                "metaData.spotNumber": TextExactCriterion(spotViewModel.number),
              }}
            />
          );
        },
      }}
    />
  );
};
