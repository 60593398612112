import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import { GroupRepository } from "james/group";
import { Group } from "james/group/Group";
import {
  TextExactCriterion,
  TextNEExactCriterion,
} from "james/search/criterion/text";
import { TextExactCriterionType } from "james/search/criterion/text/Exact";
import {
  Reader as StellarAccountViewReader,
  useRead,
} from "james/views/stellarAccountView";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import { AccountCard } from "./AccountCard";
import { Query } from "james/search/query";
import { LedgerAccountCategory } from "james/ledger";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

const PREFIX = "Table";

const classes = {
  root: `${PREFIX}-root`,
  searchAccountsTextField: `${PREFIX}-searchAccountsTextField`,
  searchGroupOwnerField: `${PREFIX}-searchGroupOwnerField`,
  accountCardsWrapper: `${PREFIX}-accountCardsWrapper`,
  spacer: `${PREFIX}-spacer`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridRowGap: theme.spacing(1),
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(1)}`,
    overflow: "hidden",
  },

  [`& .${classes.searchAccountsTextField}`]: {
    width: 400,
  },

  [`& .${classes.searchGroupOwnerField}`]: {
    width: 224,
  },

  [`& .${classes.accountCardsWrapper}`]: {
    height: "calc(100vh - 250px)",
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.spacer}`]: {
    height: theme.spacing(1),
  },
}));

export function Table() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [loadingPotentialGroupOwners, setLoadingPotentialGroupOwners] =
    useState(false);
  const [potentialGroupOwners, setPotentialGroupOwners] = useState<Group[]>([]);
  const {
    readResponse,
    readRequest,
    setReadRequest,
    loading: searchAccountsLoading,
    error: searchAccountsError,
  } = useRead({
    context: authContext,
    query: new Query(),
    criteria: {
      category: TextNEExactCriterion(LedgerAccountCategory.Clearance),
    },
  });
  const navigate = useNavigate();

  // fetch owner groups for display on account and for searching
  useEffect(() => {
    const initialLoad = async () => {
      setLoadingPotentialGroupOwners(true);
      // get all groups in which the user has the list accounts permission
      try {
        const groupOwnerCriteria =
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName: "Read",
              serviceProvider: StellarAccountViewReader.serviceProvider,
              description: "?",
            }),
            criteria: {},
            scopeFields: [ScopeFields.IDField],
            buildScopeTree: true,
          });
        setPotentialGroupOwners(
          (
            await GroupRepository.SearchGroups({
              context: authContext,
              criteria: groupOwnerCriteria.criteria,
            })
          ).records,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `unable to search for account group owners: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoadingPotentialGroupOwners(false);
    };
    initialLoad().finally();
  }, [authContext]);

  const loading = loadingPotentialGroupOwners || searchAccountsLoading;

  if (
    searchAccountsError &&
    searchAccountsError ===
      "error searching for accounts: ledger account does not exist"
  ) {
    return (
      <Root>
        <Typography>
          An Account does not have a ledger account open yet.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          children="retry"
          onClick={() => setReadRequest({ ...readRequest })}
        />
      </Root>
    );
  }

  return (
    <Root className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            disabled={loading}
            label="Team"
            variant="outlined"
            margin="dense"
            select
            className={classes.searchGroupOwnerField}
            onChange={(e) =>
              setReadRequest({
                ...readRequest,
                criteria: {
                  ownerID: TextExactCriterion(e.target.value),
                },
              })
            }
            value={
              readRequest.criteria.ownerID
                ? (readRequest.criteria.ownerID as TextExactCriterionType).text
                : ""
            }
            InputProps={{
              endAdornment: readRequest.criteria.ownerID ? (
                <InputAdornment position="end">
                  <Tooltip title="Clear" placement="right-end">
                    <IconButton
                      onClick={() => {
                        delete readRequest.criteria.ownerID;
                        setReadRequest({
                          ...readRequest,
                        });
                      }}
                      size="large"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : undefined,
            }}
          >
            {potentialGroupOwners.map((g) => (
              <MenuItem key={g.id} value={g.id}>
                {g.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {loading && (
          <Grid item>
            <CircularProgress size={25} />
          </Grid>
        )}
      </Grid>
      <div className={cx(classes.accountCardsWrapper, "meshScroll")}>
        {(() => {
          if (readResponse.models.length) {
            return readResponse.models.map((acc, idx) => (
              <React.Fragment key={idx}>
                <div className={classes.spacer} />
                <AccountCard
                  stellarAccountViewModel={acc}
                  cardHeaderControls={[
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        navigate(
                          `/market-management/accounts/detail?number=${acc.number}`,
                        )
                      }
                    >
                      View
                    </Button>,
                  ]}
                  groupOwner={(() => {
                    const groupOwner = potentialGroupOwners.find(
                      (g) => g.id === acc.ownerID,
                    );
                    return groupOwner || new Group();
                  })()}
                />
              </React.Fragment>
            ));
          }
          return <Typography>No Accounts</Typography>;
        })()}
      </div>
    </Root>
  );
}
