import React, { useMemo, useState } from "react";
import {
  alpha,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
  Theme,
} from "@mui/material";
import banner from "assets/images/portfolio/banner.jpeg";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { formatTextNum } from "utilities/number";
import { ValuationChart } from "components/Charts/Portfolio/Valuation/ValuationChart";
import { AssetAllocationChart } from "components/Charts/Portfolio/AssetAllocation/AssetAllocationChart";
import { usePortfolioContext } from "context/Portfolio";
import { SkeletonLoader } from "components/SkeletonLoader";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export function PortfolioBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "lg"),
  );
  const portfolioContext = usePortfolioContext();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const displayCurrency = useMemo<
    { value: string; label: string } | undefined
  >(() => {
    if (!portfolioContext.valuationCurrency) {
      return undefined;
    }

    return {
      value: portfolioContext.valuationCurrency.code,
      label: portfolioContext.valuationCurrency.code,
    };
  }, [portfolioContext.valuationCurrency]);

  const pnlChangeIsPositive: boolean | undefined = portfolioContext.pnlAmount
    ? +portfolioContext.pnlAmount.value >= 0
    : undefined;

  if (collapsed && !isMobile) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto auto auto 1fr",
          backgroundSize: "cover",
          backgroundImage: `url(${banner})`,
          padding: theme.spacing(1, 1, 1, 2),
          gap: theme.spacing(5),
          width: "100%",
          backgroundPositionY: "87px",
        }}
      >
        <Box />
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: theme.spacing(),
          })}
        >
          <SkeletonLoader loading={!portfolioContext.currentValue}>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
              children="Portfolio Performance Overview"
            />
          </SkeletonLoader>
          <Tooltip
            arrow
            placement={"top"}
            title={
              "This portfolio bar gives you an overview of how the assets you hold in your Mesh account are performing."
            }
          >
            <Box component={"span"}>
              <InfoIcon
                sx={(theme) => ({
                  color: theme.palette.text.tertiary,
                })}
              />
            </Box>
          </Tooltip>
          <IconButton
            size="small"
            data-link-info={JSON.stringify({
              content_interaction_id: "show-hide-content",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Icon,
              content_interaction_text: "portfolio hide or show",
              content_interaction_driver: InteractionDriver.Show_hide_content,
            } as DataLinkInfoType)}
            id="accountManagementDialog-expand-button"
            onClick={() => setCollapsed(!collapsed)}
          >
            <ExpandMoreIcon
              data-link-info={JSON.stringify({
                content_interaction_id: "show-hide-content",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Icon,
                content_interaction_text: "portfolio hide or show",
                content_interaction_driver: InteractionDriver.Show_hide_content,
              } as DataLinkInfoType)}
              color="primary"
            />
          </IconButton>
        </Box>
        <SkeletonLoader
          sx={{ height: "48px" }}
          loading={!portfolioContext.currentValue}
        >
          <>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                textTransform: "none",
              })}
              variant="overline"
              children="Total Portfolio Value"
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
              }}
            >
              {displayCurrency && (
                <Box
                  component={"span"}
                  sx={(theme) => ({
                    color: alpha(theme.palette.custom.lavenderLight, 0.6),
                  })}
                >
                  {`${displayCurrency.value} `}
                </Box>
              )}
              <Box
                component={"span"}
                sx={(theme) => ({
                  color: theme.palette.custom.lavenderLight,
                })}
              >
                {portfolioContext.currentValue &&
                  formatTextNum(portfolioContext.currentValue.value, {
                    noDecimalPlaces: 2,
                    addDecimalPadding: true,
                  })}
              </Box>
            </Typography>
          </>
        </SkeletonLoader>
        <div>
          <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textTransform: "none",
              }}
              variant="overline"
              children="Profit & Loss Value"
            />
          </SkeletonLoader>
          <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
              }}
              children={
                <>
                  <Box
                    component={"span"}
                    sx={(theme) => ({
                      color: pnlChangeIsPositive
                        ? theme.palette.custom.green
                        : theme.palette.custom.red,
                    })}
                  >
                    {`${displayCurrency?.value} `}
                  </Box>
                  <Box
                    component={"span"}
                    sx={(theme) => ({
                      color: pnlChangeIsPositive
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    })}
                  >
                    {portfolioContext.pnlAmount &&
                      `${formatTextNum(portfolioContext.pnlAmount.value, {
                        noDecimalPlaces: 2,
                        addDecimalPadding: true,
                      })} `}
                  </Box>
                </>
              }
            />
          </SkeletonLoader>
        </div>
        <div />
      </Box>
    );
  }

  //
  //  on mobile
  //
  if (isMobile) {
    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          height: "250px",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(2),
          padding: theme.spacing(4, 0, 4, 0),
        })}
        data-component-info={JSON.stringify({
          component_id: "portfolio_overview",
          component_business_name: "Portfolio performance overview",
          component_title: "portfolio overview",
          component_driver: InteractionDriver.ViewPortfolio,
        } as DataComponentInfo)}
      >
        <SkeletonLoader loading={!portfolioContext.currentValue}>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
            }}
            variant="h4"
            component={"div"}
            children="Portfolio Performance Overview"
          />
        </SkeletonLoader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SkeletonLoader loading={!portfolioContext.currentValue}>
            <Typography
              sx={{
                opacity: "0.38",
                textTransform: "none",
                alignSelf: "center",
              }}
              variant="overline"
              children="Total Portfolio Value"
            />
          </SkeletonLoader>
          <SkeletonLoader loading={!portfolioContext.currentValue}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                alignSelf: "center",
              }}
              children={
                <>
                  <Box
                    component={"span"}
                    sx={(theme) => ({
                      color: alpha(theme.palette.custom.lavenderLight, 0.6),
                    })}
                  >
                    {`${displayCurrency?.value} `}
                  </Box>
                  <Box
                    component={"span"}
                    sx={(theme) => ({
                      color: theme.palette.custom.lavenderLight,
                    })}
                  >
                    {portfolioContext.currentValue &&
                      formatTextNum(portfolioContext.currentValue.value, {
                        noDecimalPlaces: 2,
                        addDecimalPadding: true,
                      })}
                  </Box>
                </>
              }
            />
          </SkeletonLoader>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textTransform: "none",
                alignSelf: "center",
              }}
              variant="overline"
              children="Profit & Loss Value"
            />
          </SkeletonLoader>
          <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                alignSelf: "center",
              }}
            >
              <Box
                component={"span"}
                sx={(theme) => ({
                  color: pnlChangeIsPositive
                    ? theme.palette.custom.green
                    : theme.palette.custom.red,
                })}
              >
                {`${displayCurrency?.value} `}
              </Box>
              <Box
                component={"span"}
                sx={(theme) => ({
                  color: pnlChangeIsPositive
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                })}
              >
                {portfolioContext.pnlAmount &&
                  `${formatTextNum(portfolioContext.pnlAmount.value, {
                    noDecimalPlaces: 2,
                    addDecimalPadding: true,
                  })} `}
              </Box>
            </Typography>
          </SkeletonLoader>
        </Box>
      </Box>
    );
  }

  //
  //  on tablet
  //
  if (isTablet) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto calc(100vw * 0.40) 1fr",
          gridColumnGap: theme.spacing(3),
          backgroundImage: `url(${banner})`,
          height: "auto",
          backgroundPosition: "bottom",
          width: "100vw",
        }}
        data-component-info={JSON.stringify({
          component_id: "portfolio_overview",
          component_business_name: "Portfolio performance overview",
          component_title: "portfolio overview",
          component_driver: InteractionDriver.ViewPortfolio,
        } as DataComponentInfo)}
      >
        <Box sx={(theme) => ({ padding: theme.spacing(2, 2, 2, 4) })}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: theme.spacing(),
              marginBottom: theme.spacing(2),
            }}
          >
            <SkeletonLoader loading={!portfolioContext.currentValue}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  maxWidth: "229px",
                }}
                variant="h4"
                children="Portfolio Performance Overview"
              />
            </SkeletonLoader>
            <SkeletonLoader loading={!portfolioContext.currentValue}>
              <Tooltip
                arrow
                placement={"top"}
                title={
                  "This portfolio bar gives you an overview of how the assets you hold in your Mesh account are performing."
                }
              >
                <Box component={"span"}>
                  <InfoIcon
                    sx={(theme) => ({
                      color: theme.palette.text.tertiary,
                    })}
                  />
                </Box>
              </Tooltip>
            </SkeletonLoader>
            <IconButton
              size="small"
              id="accountManagementDialog-expand-button"
              data-link-info={JSON.stringify({
                content_interaction_id: "show-hide-content",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Icon,
                content_interaction_text: "portfolio hide or show",
                content_interaction_driver: InteractionDriver.Show_hide_content,
              } as DataLinkInfoType)}
              onClick={() => setCollapsed(!collapsed)}
            >
              <SkeletonLoader loading={!portfolioContext.currentValue}>
                <ExpandLessIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "show-hide-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "portfolio hide or show",
                    content_interaction_driver:
                      InteractionDriver.Show_hide_content,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              </SkeletonLoader>
            </IconButton>
          </Box>
          <Box
            sx={(theme) => ({
              display: "grid",
              gap: "32px",
              [theme.breakpoints.down(710)]: {
                gap: "0px",
                gridTemplateColumns: "unset",
                gridTemplateRows: "1fr 1fr",
              },
            })}
          >
            <div>
              <SkeletonLoader
                loading={!portfolioContext.currentValue}
                sx={{
                  height: "48px",
                }}
              >
                <>
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                      textTransform: "none",
                    }}
                    variant="overline"
                    children="Total Portfolio Value"
                  />
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    <Box
                      component={"span"}
                      sx={(theme) => ({
                        color: alpha(theme.palette.custom.lavenderLight, 0.6),
                      })}
                    >
                      {`${displayCurrency?.value} `}
                    </Box>
                    <Box
                      component={"span"}
                      sx={(theme) => ({
                        color: theme.palette.custom.lavenderLight,
                      })}
                    >
                      {portfolioContext.currentValue &&
                        formatTextNum(portfolioContext.currentValue.value, {
                          noDecimalPlaces: 2,
                          addDecimalPadding: true,
                        })}
                    </Box>
                  </Typography>
                </>
              </SkeletonLoader>
            </div>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.custom.opaqueBackground,
            padding: theme.spacing(2, 3, 2, 3),
            marginTop: "16px",
            marginBottom: "16px",
            borderRadius: theme.spacing(1),
            height: "160px",
          })}
        >
          <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textTransform: "none",
              }}
              variant="overline"
              children="Profit & Loss Value"
            />
          </SkeletonLoader>
          <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
              }}
              children={
                <>
                  <Box
                    component={"span"}
                    sx={(theme) => ({
                      color:
                        portfolioContext.notEnoughPortfolioValuationHistoryData
                          ? theme.palette.text.secondary
                          : pnlChangeIsPositive
                            ? theme.palette.custom.green
                            : theme.palette.custom.red,
                    })}
                  >
                    {`${displayCurrency?.value} `}
                  </Box>
                  <Box
                    component={"span"}
                    sx={(theme) => ({
                      color:
                        portfolioContext.notEnoughPortfolioValuationHistoryData
                          ? theme.palette.common.white
                          : pnlChangeIsPositive
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                    })}
                  >
                    {portfolioContext.pnlAmount &&
                      `${formatTextNum(portfolioContext.pnlAmount.value, {
                        noDecimalPlaces: 2,
                        addDecimalPadding: true,
                      })} `}
                  </Box>
                </>
              }
            />
          </SkeletonLoader>
          <ValuationChart
            valuations={portfolioContext.portfolioValuationHistory?.map(
              (val) => ({
                date: val.date.toString(),
                valuation: val.valuation,
                tokenCode: val.valuation.token.code,
              }),
            )}
            noInfoDisplay={
              portfolioContext.notEnoughPortfolioValuationHistoryData
            }
          />
          <SkeletonLoader
            sx={{ transform: "none" }}
            loading={!portfolioContext.portfolioValuationHistory}
          >
            <Typography
              sx={{
                color: (theme) =>
                  portfolioContext.notEnoughPortfolioValuationHistoryData
                    ? theme.palette.text.secondary
                    : theme.palette.text.tertiary,
                textTransform: "none",
                marginTop: (theme) => theme.spacing(2),
              }}
              component={"div"}
              variant={"overline"}
              children="Market Valuation"
            />
          </SkeletonLoader>
        </Box>
        <div />
      </Box>
    );
  }

  //
  //  on desktop
  //
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 468px 305px",
        gridColumnGap: "24px",
        backgroundImage: `url(${banner})`,
        height: "auto",
        paddingRight: "16px",
        backgroundPosition: "bottom",
        width: "100vw",
        justifyContent: "center",
      }}
      data-component-info={JSON.stringify({
        component_id: "portfolio_overview",
        component_business_name: "Portfolio performance overview",
        component_title: "portfolio overview",
        component_driver: InteractionDriver.ViewPortfolio,
      } as DataComponentInfo)}
    >
      <Box sx={{ padding: "16px 32px 16px 32px" }}>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: theme.spacing(),
            marginBottom: theme.spacing(2),
          })}
        >
          <SkeletonLoader loading={!portfolioContext.currentValue}>
            <Typography
              sx={{
                fontWeight: "bold",
                maxWidth: "229px",
              }}
              variant="h4"
              children="Portfolio Performance Overview"
            />
          </SkeletonLoader>
          <SkeletonLoader loading={!portfolioContext.currentValue}>
            <Tooltip
              arrow
              placement={"top"}
              title={
                "This portfolio bar gives you an overview of how the assets you hold in your Mesh account are performing."
              }
            >
              <Box component={"span"}>
                <InfoIcon
                  sx={(theme) => ({
                    color: theme.palette.text.tertiary,
                  })}
                />
              </Box>
            </Tooltip>
          </SkeletonLoader>
          <SkeletonLoader loading={!portfolioContext.currentValue}>
            <IconButton
              size="small"
              id="accountManagementDialog-expand-button"
              data-link-info={JSON.stringify({
                content_interaction_id: "show-hide-content",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Icon,
                content_interaction_text: "portfolio hide or show",
                content_interaction_driver: InteractionDriver.Show_hide_content,
              } as DataLinkInfoType)}
              onClick={() => setCollapsed(!collapsed)}
            >
              <ExpandLessIcon
                data-link-info={JSON.stringify({
                  content_interaction_id: "show-hide-content",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Icon,
                  content_interaction_text: "portfolio hide or show",
                  content_interaction_driver:
                    InteractionDriver.Show_hide_content,
                } as DataLinkInfoType)}
                color="primary"
              />
            </IconButton>
          </SkeletonLoader>
        </Box>
        <Box>
          <SkeletonLoader
            sx={{ height: "48px" }}
            loading={!portfolioContext.currentValue}
          >
            <>
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  textTransform: "none",
                }}
                variant="overline"
                children="Total Portfolio Value"
              />
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                <Box
                  component={"span"}
                  sx={(theme) => ({
                    color: alpha(theme.palette.custom.lavenderLight, 0.6),
                  })}
                >
                  {`${displayCurrency?.value} `}
                </Box>
                <Box
                  component={"span"}
                  sx={(theme) => ({
                    color: theme.palette.custom.lavenderLight,
                  })}
                >
                  {portfolioContext.currentValue &&
                    formatTextNum(portfolioContext.currentValue.value, {
                      noDecimalPlaces: 2,
                      addDecimalPadding: true,
                    })}
                </Box>
              </Typography>
            </>
          </SkeletonLoader>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.opaqueBackground,
          marginTop: "16px",
          marginBottom: "16px",
          padding: "16px 24px 16px 24px",
          borderRadius: theme.spacing(1),
          height: "160px",
        })}
      >
        <SkeletonLoader
          sx={{ transform: "none" }}
          loading={!portfolioContext.portfolioValuationHistory}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textTransform: "none",
            }}
            variant="overline"
            children="Profit & Loss Value"
          />
        </SkeletonLoader>
        <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
            children={
              <>
                <Box
                  component={"span"}
                  sx={(theme) => ({
                    color:
                      portfolioContext.notEnoughPortfolioValuationHistoryData
                        ? theme.palette.text.secondary
                        : pnlChangeIsPositive
                          ? theme.palette.custom.green
                          : theme.palette.custom.red,
                  })}
                >
                  {`${displayCurrency?.value} `}
                </Box>
                <Box
                  component={"span"}
                  sx={(theme) => ({
                    color:
                      portfolioContext.notEnoughPortfolioValuationHistoryData
                        ? theme.palette.common.white
                        : pnlChangeIsPositive
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                  })}
                >
                  {portfolioContext.pnlAmount &&
                    `${formatTextNum(portfolioContext.pnlAmount.value, {
                      noDecimalPlaces: 2,
                      addDecimalPadding: true,
                    })}`}
                </Box>
              </>
            }
          />
        </SkeletonLoader>
        <ValuationChart
          valuations={portfolioContext.portfolioValuationHistory?.map(
            (val) => ({
              date: val.date.toString(),
              valuation: val.valuation,
              tokenCode: val.valuation.token.code,
            }),
          )}
          noInfoDisplay={
            portfolioContext.notEnoughPortfolioValuationHistoryData
          }
        />
        <SkeletonLoader loading={!portfolioContext.portfolioValuationHistory}>
          <Typography
            sx={{
              color: (theme) =>
                portfolioContext.notEnoughPortfolioValuationHistoryData
                  ? theme.palette.text.secondary
                  : theme.palette.text.secondary,
              textTransform: "none",
              marginTop: (theme) => theme.spacing(2),
            }}
            component={"div"}
            color={"disabled"}
            variant={"overline"}
            children="Market Valuation"
          />
        </SkeletonLoader>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.opaqueBackground,
          padding: "16px 24px 16px 24px",
          marginTop: "16px",
          marginBottom: "16px",
          borderRadius: theme.spacing(1),
          height: "160px",
        })}
      >
        <SkeletonLoader loading={!portfolioContext.currentAssetAllocation}>
          <Typography
            sx={{
              opacity: "0.38",
              textTransform: "none",
            }}
            variant="overline"
            children="Portfolio Allocation"
          />
        </SkeletonLoader>
        <AssetAllocationChart
          loading={portfolioContext.currentAssetAllocation === undefined}
          assetAllocations={
            portfolioContext.currentAssetAllocation
              ? portfolioContext.currentAssetAllocation.map((v) => ({
                  asset: v.asset,
                  percentage: v.percentage,
                }))
              : []
          }
          noDataTextLeftPos={80}
        />
      </Box>
    </Box>
  );
}
