import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { IndicativePrice } from ".";

export interface RetrieveIndicativePriceRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveIndicativePriceResponse {
  indicativePrice: IndicativePrice;
}

export interface SearchIndicativePricesRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchIndicativePricesResponse {
  records: IndicativePrice[];
  total: number;
}

export const IndicativePriceRepository = {
  serviceProvider: "market-IndicativePriceRepository",
  async RetrieveIndicativePrice(
    request: RetrieveIndicativePriceRequest,
  ): Promise<RetrieveIndicativePriceResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${IndicativePriceRepository.serviceProvider}.RetrieveIndicativePrice`,
      request,
    });
    return { indicativePrice: new IndicativePrice(response.indicativePrice) };
  },
  async SearchIndicativePrice(
    request: SearchIndicativePricesRequest,
  ): Promise<SearchIndicativePricesResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${IndicativePriceRepository.serviceProvider}.SearchIndicativePrice`,
      request,
    });
    return {
      records: response.records.map(
        (c: IndicativePrice) => new IndicativePrice(c),
      ),
      total: response.total,
    };
  },
};
