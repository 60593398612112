import {
  useTheme,
  Typography,
  Popper,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  alpha,
} from "@mui/material";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useApplicationContext } from "context/Application/Application";
import { useWidth } from "utilities/general";
import { getBreakpointValue, virtualElement } from "./util";
import { GuidesController } from "james/user/GuidesController";
import { UserGuide } from "james/user/User";
import { CallbackType, ElementType, TourStep, VirtualElement } from "./types";
import { ClientKind } from "james/client/Client";
import { TutorialNames, TutorialTypes } from "types/gtm";
import {
  BackButton,
  CloseButton,
  CompleteButton,
  NextButton,
} from "./components/AppTourButtons";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { DataComponentInfo, InteractionDriver } from "const/gtm";

interface ContextType {
  startTour: (tour: TourStep[], name: UserGuide) => void;
  registerElement: (
    name: string,
    backup?: boolean,
  ) => (elem: ElementType) => void;
  tourInProgress: boolean;
}

const Context = React.createContext({} as ContextType);

export const AppTour = ({ children }: { children: React.ReactNode }) => {
  const { pushTutorialStartedEvent, pushTutorialCompletedEvent } =
    useGTMTriggersPusher();
  const [tutorialStartedTriggered, setTutorialStartedTriggered] =
    useState(false);
  const breakpoint = useWidth();
  const { loginClaims, authContext, applicationContextUpdating } =
    useApplicationContext();
  const theme = useTheme();
  const elements = useRef<Record<string, ElementType>>({
    "": null,
  });
  const [tour, setTour] = useState<TourStep[]>();
  const [step, setStep] = useState(0);
  const [dialogAnchorRef, setDialogAnchorRef] = useState<
    ElementType | VirtualElement
  >(virtualElement);
  const [backdrop, setBackdrop] = useState(false);
  const [currentTour, setCurrentTour] = useState<UserGuide>();
  const [hiddenElements, setHiddenElements] = useState<string[]>([]);

  useEffect(() => {
    if (!tour || applicationContextUpdating) return;
    const currentStep = tour[step];

    // run before
    currentStep.beforeCallbacks.forEach((callback: CallbackType) => {
      callback({
        elements: elements.current,
        highlightElements,
        removeHighlight,
        hideElements,
        unhideElements,
        breakpoint,
      });
    });

    if (currentStep.skipDialog) {
      complete();
    }
    const id = getBreakpointValue(currentStep.dialog.refElementID, breakpoint);

    const dialogAnchorElem =
      elements.current[id ?? ""] ?? elements.current[`backup-${id}`];

    setDialogAnchorRef(dialogAnchorElem ?? virtualElement);
  }, [tour, step, applicationContextUpdating]);

  const hideElements = useCallback(
    (elementsToHide: string[]) => {
      elementsToHide.forEach((name) => {
        const elem = elements.current[name];
        if (elem) {
          elem.style.visibility = "hidden";
        }
      });
      setHiddenElements(elementsToHide);
    },
    [hiddenElements],
  );

  const unhideElements = useCallback(
    (elementsToUnhide?: string[]) => {
      if (elementsToUnhide) {
        elementsToUnhide.forEach((name) => {
          const elem = elements.current[name];
          if (elem) {
            elem.style.visibility = "visible";
          }
        });
        setHiddenElements(
          hiddenElements.filter((v) => !elementsToUnhide.includes(v)),
        );
        return;
      }
      hiddenElements.forEach((name) => {
        const elem = elements.current[name];
        if (elem) {
          elem.style.visibility = "visible";
        }
      });
      setHiddenElements([]);
    },
    [hiddenElements],
  );

  const highlightElements = useCallback(
    (elementsToHighlight: string[]) => {
      elementsToHighlight.forEach((name) => {
        const elem = elements.current[name];
        if (elem) {
          elem.style.zIndex = "1102";
        }
      });
      setBackdrop(true);
    },
    [elements],
  );

  const removeHighlight = useCallback(() => {
    setBackdrop(false);
  }, []);

  const startTour = useCallback(
    (tour: TourStep[], name: UserGuide) => {
      setTimeout(() => {
        if (
          loginClaims.clientKind !== ClientKind.IndividualType &&
          name !== "Scroll Asset Detail View"
        )
          return;
        if (loginClaims.completedGuides.includes(name)) {
          localStorage.removeItem(name);
          return;
        }
        if (localStorage.getItem(name) === "complete") return;
        setCurrentTour(name);
        setTour(tour);
      });
    },
    [loginClaims],
  );

  const next = useCallback(() => {
    if (!tour) return;
    const currentStep = tour[step];
    currentStep.afterCallbacks.forEach((callback: CallbackType) => {
      callback({
        elements: elements.current,
        highlightElements,
        removeHighlight,
        hideElements,
        unhideElements,
        breakpoint,
      });
    });

    // only fire the GTM tag when the tutorial has started
    if (step === 0 && !tutorialStartedTriggered) {
      pushTutorialStartedEvent({
        tutorial_name: TutorialNames.Platform_Guided_Journey,
        tutorial_type: TutorialTypes.Onboarding,
        tutorial_start: true,
        tutorial_complete: false,
      });
      setTutorialStartedTriggered(true);
    }
    setStep(step + 1);
  }, [tour, step]);

  const previous = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const complete = useCallback(async () => {
    if (!tour) return;
    const currentStep = tour[step];
    currentStep.afterCallbacks.forEach((callback: CallbackType) => {
      callback({
        elements: elements.current,
        highlightElements,
        removeHighlight,
        hideElements,
        unhideElements,
        breakpoint,
      });
    });
    if (currentTour === UserGuide.ScrollAssetDetailView) {
      return;
    }
    if (currentTour) {
      localStorage.setItem(currentTour, "complete");
    }
    try {
      // invoke complete platform guided journey
      await GuidesController.CompletePlatformGuidedJourney({
        context: authContext,
      });

      // push tutorial completed event
      pushTutorialCompletedEvent({
        tutorial_name: TutorialNames.Platform_Guided_Journey,
        tutorial_type: TutorialTypes.Onboarding,
        tutorial_start: false,
        tutorial_complete: true,
      });
    } catch (e) {
      // do nothing except log an error if this fails.
      // they will just see the journey the next time they log in again
      console.error(e);
    }
    setStep(0);
    setTour(undefined);
    setBackdrop(false);
    unhideElements();
  }, [currentTour, hiddenElements, tour, step]);

  const registerElement = useCallback(
    (name: string, backup?: boolean) => (elem: ElementType) => {
      elements.current[!backup ? name : `backup-${name}`] = elem;
    },
    [elements],
  );

  const arrowPosition = useMemo(
    () =>
      tour
        ? getBreakpointValue(tour[step].dialog.arrow, breakpoint)
        : { top: -2, left: "50%", position: "top" },
    [tour, step],
  );

  return (
    <Context.Provider
      value={{
        startTour,
        registerElement,
        tourInProgress: tour !== undefined,
      }}
    >
      <Popper
        id="popper"
        key="popper"
        open={tour !== undefined}
        data-component-info={JSON.stringify({
          component_id: "platform-guided-journey",
          component_business_name: "platform guided journey",
          component_title: `Step ${step + 1} of 3: ${
            tour ? tour[step].dialog.header : "-"
          }`,
          component_driver: InteractionDriver.Tutorial,
        } as DataComponentInfo)}
        style={{
          zIndex: 99999,
          border: "3px solid #FFFFFF",
          borderRadius: "10px",
          backgroundColor: theme.palette.background.paper,
          maxWidth: "280px",
        }}
        anchorEl={dialogAnchorRef}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
              padding: 16,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              tether: true,
              rootBoundary: "viewport",
              padding: 16,
            },
          },
          {
            name: "offset",
            options: {
              offset: [
                tour
                  ? getBreakpointValue(tour[step].dialog.offset, breakpoint)?.x
                  : 0,
                tour
                  ? getBreakpointValue(tour[step].dialog.offset, breakpoint)?.y
                  : 0,
              ],
            },
          },
        ]}
      >
        {tour && !tour[step].skipDialog && (
          <Paper
            elevation={10}
            sx={{
              borderRadius: "10px",
              "&::before": {
                content: '""',
                width: "16px",
                height: "16px",
                backgroundColor: theme.palette.background.paper,
                transform: "translate(-50%, -50%) rotate(45deg)",
                top: arrowPosition?.top,
                left: arrowPosition?.left,

                borderLeft:
                  arrowPosition?.position === "top" ||
                  arrowPosition?.position === "left"
                    ? "2px solid #FFFFFF"
                    : "",
                borderRight:
                  arrowPosition?.position === "right" ||
                  arrowPosition?.position === "bottom"
                    ? "2px solid #FFFFFF"
                    : "",
                borderTop:
                  arrowPosition?.position === "top" ||
                  arrowPosition?.position === "top"
                    ? "2px solid #FFFFFF"
                    : "",
                borderBottom:
                  arrowPosition?.position === "bottom" ||
                  arrowPosition?.position === "left"
                    ? "2px solid #FFFFFF"
                    : "",
                position: "absolute",
              },
            }}
          >
            <DialogTitle
              sx={{
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Typography>
                Step {step + 1} of {tour.length}
              </Typography>
              <CloseButton onClick={complete} />
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold", mb: 1 }}>
                {tour[step].dialog.header}
              </Typography>
              <Typography
                variant="body1"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              >
                {tour[step].dialog.content}
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pl: 10,
              }}
            >
              {step > 0 && <BackButton onClick={previous} />}
              {step < tour.length - 1 && <NextButton onClick={next} />}
              {step === tour.length - 1 && (
                <CompleteButton onClick={complete} />
              )}
            </DialogActions>
          </Paper>
        )}
      </Popper>
      {children}
      {backdrop && (
        <Box
          ref={registerElement("appTour-backdrop")}
          sx={{
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1101,
            backgroundColor: alpha("#000000", 0.6),
            position: "absolute",
          }}
        />
      )}
      {tour && (
        <Box
          sx={{
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1405,
            position: "absolute",
          }}
        />
      )}
    </Context.Provider>
  );
};

export const useAppTourContext = () => React.useContext(Context);
