import { Context } from "james/security";
import { Query } from "james/search/query";
import { useEffect, useRef, useState } from "react";
import config from "react-global-configuration";
import { PublicModel } from "james/views/marketListingView/PublicModel";
import { Model } from "./Model";
import { useApplicationContext } from "context/Application/Application";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { useErrorContext } from "context/Error";

export interface ReadRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadResponse {
  models: Model[];
  total: number;
}

export interface ReadOneRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface ReadOneResponse {
  model: Model;
}

export interface PublicReadRequest {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface PublicReadResponse {
  publicModels: PublicModel[];
  total: number;
}

export interface PublicReadOneRequest {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface PublicReadOneResponse {
  publicModel: PublicModel;
}

export const Reader = {
  serviceProvider: "marketListingView-Reader",
  async Read(request: ReadRequest): Promise<ReadResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Reader.serviceProvider}.Read`,
      request,
    });
    return {
      models: response.models.map((m: Model) => new Model(m)),
      total: response.total,
    };
  },
  async ReadOne(request: ReadOneRequest): Promise<ReadOneResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Reader.serviceProvider}.ReadOne`,
      request,
    });
    return { model: new Model(response.model) };
  },
  async PublicReadOne(
    request: PublicReadOneRequest,
  ): Promise<PublicReadOneResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${Reader.serviceProvider}.PublicReadOne`,
      request,
    });
    return { publicModel: new PublicModel(response.publicModel) };
  },
};

export function useRead(
  initialReadRequest?: ReadRequest,
  initialShouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [readRequest, setReadRequest] = useState<ReadRequest>(
    initialReadRequest || {
      context: authContext,
      query: new Query(),
      criteria: {},
    },
  );
  const [readResponse, setReadResponse] = useState<ReadResponse>({
    total: 0,
    models: [],
  });
  const [loading, setLoading] = useState(false);
  const [shouldNotExecute, setShouldNotExecute] = useState(
    !!initialShouldNotExecute,
  );
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        setReadResponse(await Reader.Read(readRequest));
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading instrument view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error reading instrument view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [readRequest, shouldNotExecute]);

  return {
    readRequest,
    setReadRequest,
    setShouldNotExecute,
    readResponse,
    loading,
    error,
  };
}
