import { Company } from "james/legal/company";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  values: Company,
  setFormFieldValid?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let InvalidFormFields = {};
  if (values.registeredAddress) {
    if (values.registeredAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        registeredAddressAddressLine1: "Address line is required",
      };
    }
    if (values.registeredAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        registeredAddressCity: "City is required",
      };
    }

    if (values.registeredAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        registeredAddressCountryCode: "Country code is required",
      };
    }
  }

  if (
    values.businessAddress &&
    !isEqual(new Address(values.businessAddress), new Address())
  ) {
    if (values.businessAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressAddressLine1: "Address line is missing",
      };
    }

    if (values.businessAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressCity: "City is missing",
      };
    }

    if (values.businessAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        businessAddressCountryCode: "Country Code is missing",
      };
    }
  }

  if (
    values.headOfficeAddress &&
    !isEqual(new Address(values.headOfficeAddress), new Address())
  ) {
    if (values.headOfficeAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        headOfficeAddressAddressLine1: "Address line is missing",
      };
    }

    if (values.headOfficeAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        headOfficeAddressCity: "City is missing",
      };
    }

    if (values.headOfficeAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        headOfficeAddressCountryCode: "Country Code is missing",
      };
    }
  }

  if (setFormFieldValid) {
    setFormFieldValid(InvalidFormFields);
  }
  return !Object.keys(InvalidFormFields).length;
};

export const ContactDetailFormFieldValidationState: {
  [key: string]: string | undefined;
} = {};
