"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSmartInstrumentAttributeTypes = void 0;
exports.smartInstrumentAttributeTypeToString = smartInstrumentAttributeTypeToString;
exports.stringToSmartInstrumentAttributeType = stringToSmartInstrumentAttributeType;
const smartInstrumentAttributeType_pb_1 = require("./smartInstrumentAttributeType_pb");
// Get all smart instrument attribute types as enum values
exports.allSmartInstrumentAttributeTypes = Object.values(smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType).filter((value) => typeof value === "number");
// Define explicit mappings between SmartInstrumentAttributeType enums and custom string representations
const smartInstrumentAttributeTypeToStringMapping = {
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.UNDEFINED_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "-",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.SECTOR_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Sector Allocations",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.COUNTRY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Country Allocations",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.FIAT_CURRENCY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Fiat Currency Allocations",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Holding Allocations",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Risk Assessment",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "External Participant Information",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Asset Class",
    [smartInstrumentAttributeType_pb_1.SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE]: "Annual Performance Log",
};
// Reverse mapping from string to SmartInstrumentAttributeType enum
const stringToSmartInstrumentAttributeTypeMapping = {};
for (const [key, value] of Object.entries(smartInstrumentAttributeTypeToStringMapping)) {
    stringToSmartInstrumentAttributeTypeMapping[value] = Number(key);
}
class UnsupportedSmartInstrumentAttributeTypeError extends Error {
    constructor(smartInstrumentAttributeType) {
        const message = `Unsupported SmartInstrumentAttributeType: ${smartInstrumentAttributeType}`;
        super(message);
        this.smartInstrumentAttributeType = smartInstrumentAttributeType;
    }
}
/**
 * Converts a SmartInstrumentAttributeType enum instance to a custom string representation.
 * @param {SmartInstrumentAttributeType} smartInstrumentAttributeType - The smart instrument attribute type to convert.
 * @returns {string} The custom string representation of the smart instrument attribute type.
 */
function smartInstrumentAttributeTypeToString(smartInstrumentAttributeType) {
    if (smartInstrumentAttributeType in smartInstrumentAttributeTypeToStringMapping) {
        return smartInstrumentAttributeTypeToStringMapping[smartInstrumentAttributeType];
    }
    else {
        throw new UnsupportedSmartInstrumentAttributeTypeError(smartInstrumentAttributeType);
    }
}
class UnsupportedSmartInstrumentAttributeTypeStringError extends Error {
    constructor(smartInstrumentAttributeTypeStr) {
        const message = `Unsupported smart instrument attribute type string: ${smartInstrumentAttributeTypeStr}`;
        super(message);
        this.smartInstrumentAttributeTypeStr = smartInstrumentAttributeTypeStr;
    }
}
/**
 * Converts a custom string representation to a SmartInstrumentAttributeType enum instance.
 * @param {string} smartInstrumentAttributeTypeStr - The custom string representation of the smart instrument attribute type.
 * @returns {SmartInstrumentAttributeType} The corresponding SmartInstrumentAttributeType enum instance.
 */
function stringToSmartInstrumentAttributeType(smartInstrumentAttributeTypeStr) {
    if (smartInstrumentAttributeTypeStr in
        stringToSmartInstrumentAttributeTypeMapping) {
        return stringToSmartInstrumentAttributeTypeMapping[smartInstrumentAttributeTypeStr];
    }
    else {
        throw new UnsupportedSmartInstrumentAttributeTypeStringError(smartInstrumentAttributeTypeStr);
    }
}
