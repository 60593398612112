import { PopoverProps } from "@mui/material/Popover";
import { useLocation, useNavigate } from "react-router-dom";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { getViewConfigurationApps } from "utilities/configuration/viewConfiguration";
import { MeshApp } from "App";
import InstrumentBuilderIcon from "@mui/icons-material/Build";
import AdministrationIcon from "@mui/icons-material/Settings";
import MarketManagementIcon from "@mui/icons-material/SettingsSystemDaydream";
import MarketPlaceIcon from "@mui/icons-material/Store";
import ConsistencyIcon from "@mui/icons-material/Storage";
import React from "react";
import { useApplicationContext } from "context/Application/Application";
import BorderColorIcon from "@mui/icons-material/BorderColor";

interface AppSwitcherMenuProps {
  anchorElApps: PopoverProps["anchorEl"];
  clearAnchorElApps: () => void;
}

export function AppSwitcherMenu(props: AppSwitcherMenuProps) {
  const { viewConfiguration } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();

  const options = [
    {
      icon: InstrumentBuilderIcon,
      title: "Issuance Hub",
      condition: MeshApp.InstrumentBuilder,
      pushTo: "/builder/instruments",
      id: "mainAppBar-appSwitcherBuilder-menuItem",
    },
    {
      icon: AdministrationIcon,
      title: "Administration",
      condition: MeshApp.Administration,
      pushTo: "/administration/manage",
      id: "mainAppBar-appSwitcherAdministrator-menuItem",
    },
    {
      icon: MarketManagementIcon,
      title: "Market Management",
      condition: MeshApp.MarketManagement,
      pushTo: "/market-management",
      id: "mainAppBar-appSwitcherMarketManagement-menuItem",
    },
    {
      icon: MarketPlaceIcon,
      title: "Marketplace",
      condition: MeshApp.Marketplace,
      pushTo: "/market/markets",
      id: "mainAppBar-appSwitcherMarketplace-menuItem",
    },
    {
      icon: ConsistencyIcon,
      title: "Consistency",
      condition: MeshApp.Consistency,
      pushTo: "/consistency",
      id: "mainAppBar-appSwitcherConsistency-menuItem",
    },
    {
      icon: BorderColorIcon,
      title: "Underwriter Hub",
      condition: MeshApp.Underwriting,
      pushTo: "/underwriter-hub/obligations",
      id: "mainAppBar-appSwitcherConsistency-menuItem",
    },
  ];

  // Menu used by the app switcher
  return (
    <>
      <Menu
        id="apps-menu"
        anchorEl={props.anchorElApps}
        keepMounted
        open={Boolean(props.anchorElApps)}
        onClose={props.clearAnchorElApps}
        autoFocus={false}
      >
        {options.map((v, i) => {
          if (
            getViewConfigurationApps(viewConfiguration).includes(v.condition)
          ) {
            return (
              <MenuItem
                key={i}
                id={v.id}
                onClick={() => {
                  navigate(v.pushTo);
                  props.clearAnchorElApps();
                }}
                selected={location.pathname.includes(v.pushTo)}
              >
                <ListItemIcon>
                  <v.icon />
                </ListItemIcon>
                <Typography>{v.title}</Typography>
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
}
