import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@mui/material";
import { SecuritiesRegisterOverview } from "./components/SecuritiesRegisterOverview";
import { SecuritiesRegisterTable } from "./components/SecuritiesRegisterTable";
import { useSecuritiesRegisterContext } from "./Context";
import { ChevronLeft as BackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const Layout = () => {
  const {
    apiCallInProgress,
    initialised,
    initialisationError,
    clearInitialisationError,
    marketListingViewModel,
    assetInformation,
    assetHolderViewModels,
  } = useSecuritiesRegisterContext();

  const navigate = useNavigate();

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Securites Register"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while initialising the securities register.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const isAnyDataUndefined =
    marketListingViewModel === undefined ||
    assetInformation === undefined ||
    assetHolderViewModels === undefined;

  if (isAnyDataUndefined) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        sx={(theme) => ({
          position: "sticky",
          top: 0,
          height: "55px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingRight: {
            lg: 0,
            sm: theme.spacing(3.5),
          },
          gap: theme.spacing(0.5),
          backgroundColor: theme.palette.background.default,
          zIndex: 200,
          pr: 2,
        })}
      >
        <Button
          id={"securitiesRegister-back-button"}
          sx={{
            textTransform: "none",
            minWidth: "80px",
            width: "80px",
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
          }}
          variant={"text"}
          color={"secondary"}
          startIcon={<BackIcon />}
          children={"Back"}
          onClick={() => navigate(-1)}
        />
        <Breadcrumbs
          sx={(theme) => ({ ml: "auto", padding: theme.spacing(0.5) })}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="secondary" href="/builder">
            Issuance Hub
          </Link>
          <Link
            underline="hover"
            color="secondary"
            href="/builder/smart-instruments/builder/table"
          >
            Smart Instruments
          </Link>
          <Typography color="text.secondary">
            {assetInformation.assetName ?? "No Name"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <SecuritiesRegisterOverview
        marketListingViewModel={marketListingViewModel}
        assetInformation={assetInformation}
        loading={apiCallInProgress || !initialised}
      />
      <SecuritiesRegisterTable assetHolders={assetHolderViewModels} />
    </Box>
  );
};
