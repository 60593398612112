import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Link, useMediaQuery, useTheme } from "@mui/material";
import { LiveHelp as HelpIcon } from "@mui/icons-material";
import cx from "classnames";
import { useWindowSize } from "utilities/general";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

const PREFIX = "Footer";

const classes = {
  footerRoot: `${PREFIX}-footerRoot`,
  footerContent: `${PREFIX}-footerContent`,
  linksWrapper: `${PREFIX}-linksWrapper`,
  linksWrapperTemporaryOverride: `${PREFIX}-linksWrapperTemporaryOverride`,
  footerLinkStyle: `${PREFIX}-footerLinkStyle`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.footerContent}`]: {
    padding: theme.spacing(0, 4),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
      display: "grid",
      gridTemplateColumns: "1fr",
      justifyContent: "none",
    },
    width: "100%",
    maxWidth: theme.breakpoints.values.lg - 8,
  },

  [`& .${classes.linksWrapper}`]: {
    minHeight: "48px",
    display: "grid",
    alignItems: "center",
    gridAutoFlow: "column",
    columnGap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  // TODO: remove when chat comes back to space buttons apart
  [`& .${classes.linksWrapperTemporaryOverride}`]: {
    justifyContent: "center",
  },

  [`& .${classes.footerLinkStyle}`]: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.body1,
    },
    textDecoration: "none",
  },
}));

export const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const windowSize = useWindowSize();

  return (
    <StyledBox
      sx={{
        width: windowSize[0],
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
      data-component-info={JSON.stringify({
        component_id: "footer_nav",
        component_business_name: "Footer",
        component_title: "App footer",
        component_driver: "navigation",
      } as DataComponentInfo)}
    >
      <div className={classes.footerContent}>
        <div className={classes.linksWrapper}>
          <Link
            id={"marketplaceFooter-fees-link"}
            className={classes.footerLinkStyle}
            href={"https://mesh.trade/services/fees"}
            target={"_blank"}
            children={"Fees"}
            data-link-info={JSON.stringify({
              content_interaction_id: "footer-nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "fees",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
          />
          <Link
            id={"marketplaceFooter-tradeAndSettlementRules-link"}
            className={classes.footerLinkStyle}
            href={"https://mesh.trade/services/trading-and-settlement-rules"}
            target={"_blank"}
            children={"Trade And Settlement Rules"}
            data-link-info={JSON.stringify({
              content_interaction_id: "footer-nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "trade and settlement rules",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
          />
          <Link
            id={"marketplaceFooter-services-link"}
            className={classes.footerLinkStyle}
            href={"https://mesh.trade/services"}
            target={"_blank"}
            children={"Services"}
            data-link-info={JSON.stringify({
              content_interaction_id: "footer-nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "services",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
          />
        </div>
        {!isMobile && (
          <div
            className={cx(
              classes.linksWrapper,
              classes.linksWrapperTemporaryOverride,
            )}
          >
            <Button
              id={"marketplaceFooter-needhelp-button"}
              variant={"text"}
              target="_blank"
              href={"https://support.mesh.trade/support/home"}
              startIcon={<HelpIcon />}
              children={"need help?"}
              data-link-info={JSON.stringify({
                content_interaction_id: "footer-nav-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "need help?",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            />
          </div>
        )}
      </div>
    </StyledBox>
  );
};
