import React from "react";
import {
  Typography,
  Button,
  Box,
  Avatar,
  Theme,
  useMediaQuery,
  Link,
} from "@mui/material";
import dayjs from "dayjs";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { AssetIndependentReviewDataSheetSection } from "james/marketData/AssetIndependentReviewDataSheetSection";

interface AssetIndependentReviewRendererProps {
  dataSheetSection: AssetIndependentReviewDataSheetSection;
}

export const AssetIndependentReviewRenderer = ({
  dataSheetSection,
}: AssetIndependentReviewRendererProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ pb: 5, pt: 4, pr: { sm: 4, xs: 3 } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            sm: "column",
            xs: "column-reverse",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            alignItems: {
              sm: "flex-start",
              xs: "center",
            },
            mb: 3,
          }}
        >
          {/* Avatar */}
          <Box
            sx={{ position: "relative", width: "80px", height: "80px", mb: 1 }}
          >
            <Avatar
              src={
                dataSheetSection.independentReview?.reviewerDetails
                  .profilePicture.url
              }
              sx={{ width: "80px", height: "80px" }}
            />
            <VerifiedUserIcon
              sx={(theme) => ({
                position: "absolute",
                zIndex: 1,
                bottom: 0,
                right: 0,
                color: theme.palette.success.main,
              })}
            />
            <VerifiedUserOutlinedIcon
              sx={(theme) => ({
                position: "absolute",
                bottom: 0,
                right: 0,
                color: theme.palette.text.primary,
              })}
            />
          </Box>
          {/* Description */}
          <Box sx={{ maxWidth: 560, ml: { sm: 2.5 } }}>
            <Typography
              variant="h6"
              align={smDown ? "center" : "left"}
              sx={{ mb: { xs: 2, sm: 0.5 }, fontSize: "16px" }}
            >
              {dataSheetSection.independentReview?.reviewerDetails.name}
            </Typography>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              {dataSheetSection.independentReview?.reviewerDetails.bio}
            </Typography>
          </Box>
        </Box>
        <Box>
          {/* Title */}
          <Box sx={{ display: "flex", alignItems: "flex-end", mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Executive summary
            </Typography>
            <Typography sx={{ mx: 1, fontWeight: 500 }}>|</Typography>
            <Typography sx={(theme) => ({ color: theme.palette.warning.main })}>
              {dayjs(dataSheetSection.independentReview?.date).format()}
            </Typography>
          </Box>
          {/* Content */}
          <Typography
            sx={(theme) => ({ color: theme.palette.text.secondary, mb: 2 })}
          >
            {dataSheetSection.independentReview?.summary}
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        variant="contained"
        color="secondary"
        href={dataSheetSection.independentReview?.url}
        target="_blank"
      >
        Read Report
      </Button>
    </Box>
  );
};
