"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allExternalParticipantInformationCategorys = void 0;
exports.smartInstrumentAllocationExternalParticipantInformationCategoryToString = smartInstrumentAllocationExternalParticipantInformationCategoryToString;
exports.stringToExternalParticipantInformationCategory = stringToExternalParticipantInformationCategory;
const smartInstrumentAttributeExternalParticipantsInformation_pb_1 = require("./smartInstrumentAttributeExternalParticipantsInformation_pb");
// Get all external participant information categorys as enum values
exports.allExternalParticipantInformationCategorys = Object.values(smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory).filter((value) => typeof value === "number");
// Define explicit mappings between ExternalParticipantInformationCategory enums and custom string representations
const smartInstrumentAllocationExternalParticipantInformationCategoryToStringMapping = {
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.UNDEFINED_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "-",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.GUARANTOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Guarantor",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Advisor",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.ISSUER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Issuer",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Agent",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.PAYING_AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Paying Agent",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.TRANSFER_AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Transfer Agent",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.CALCULATION_AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Calculation Agent",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.CUSTODIAN_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Custodian",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.MARKET_MAKER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Market Maker",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.EXCHANGE_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Exchange",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.BROKER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Broker",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.MANAGER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Manager",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.INVESTMENT_MANAGER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Investment Manager",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.PORTFOLIO_MANAGER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Portfolio Manager",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.PORTFOLIO_ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Portfolio Advisor",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.REFERENCE_PORTFOLIO_ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Reference Portfolio Advisor",
    [smartInstrumentAttributeExternalParticipantsInformation_pb_1.ExternalParticipantInformationCategory.DELEGATED_REFERENCE_PORTFOLIO_ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY]: "Delegated Reference Portfolio Advisor",
};
// Reverse mapping from string to ExternalParticipantInformationCategory enum
const stringToExternalParticipantInformationCategoryMapping = {};
for (const [key, value] of Object.entries(smartInstrumentAllocationExternalParticipantInformationCategoryToStringMapping)) {
    stringToExternalParticipantInformationCategoryMapping[value] = Number(key);
}
class UnsupportedExternalParticipantInformationCategoryError extends Error {
    constructor(smartInstrumentAllocationExternalParticipantInformationCategory) {
        const message = `Unsupported ExternalParticipantInformationCategory: ${smartInstrumentAllocationExternalParticipantInformationCategory}`;
        super(message);
        this.smartInstrumentAllocationExternalParticipantInformationCategory =
            smartInstrumentAllocationExternalParticipantInformationCategory;
    }
}
/**
 * Converts a ExternalParticipantInformationCategory enum instance to a custom string representation.
 * @param {ExternalParticipantInformationCategory} smartInstrumentAllocationExternalParticipantInformationCategory - The external participant information category to convert.
 * @returns {string} The custom string representation of the external participant information category.
 */
function smartInstrumentAllocationExternalParticipantInformationCategoryToString(smartInstrumentAllocationExternalParticipantInformationCategory) {
    if (smartInstrumentAllocationExternalParticipantInformationCategory in
        smartInstrumentAllocationExternalParticipantInformationCategoryToStringMapping) {
        return smartInstrumentAllocationExternalParticipantInformationCategoryToStringMapping[smartInstrumentAllocationExternalParticipantInformationCategory];
    }
    else {
        throw new UnsupportedExternalParticipantInformationCategoryError(smartInstrumentAllocationExternalParticipantInformationCategory);
    }
}
class UnsupportedExternalParticipantInformationCategoryStringError extends Error {
    constructor(smartInstrumentAllocationExternalParticipantInformationCategoryStr) {
        const message = `Unsupported external participant information category string: ${smartInstrumentAllocationExternalParticipantInformationCategoryStr}`;
        super(message);
        this.smartInstrumentAllocationExternalParticipantInformationCategoryStr =
            smartInstrumentAllocationExternalParticipantInformationCategoryStr;
    }
}
/**
 * Converts a custom string representation to a ExternalParticipantInformationCategory enum instance.
 * @param {string} smartInstrumentAllocationExternalParticipantInformationCategoryStr - The custom string representation of the external participant information category.
 * @returns {ExternalParticipantInformationCategory} The corresponding ExternalParticipantInformationCategory enum instance.
 */
function stringToExternalParticipantInformationCategory(smartInstrumentAllocationExternalParticipantInformationCategoryStr) {
    if (smartInstrumentAllocationExternalParticipantInformationCategoryStr in
        stringToExternalParticipantInformationCategoryMapping) {
        return stringToExternalParticipantInformationCategoryMapping[smartInstrumentAllocationExternalParticipantInformationCategoryStr];
    }
    else {
        throw new UnsupportedExternalParticipantInformationCategoryStringError(smartInstrumentAllocationExternalParticipantInformationCategoryStr);
    }
}
