import { Skeleton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { BondSection } from "components/Cards/MarketplaceCard/components/BondSection/BondSection";
import { DirectOrderSection } from "components/Cards/MarketplaceCard/components/DirectOrderSection";
import { InstrumentTypeC } from "components/Cards/MarketplaceCard/components/InstrumentType/InstrumentType";
import { IssuePriceSection } from "components/Cards/MarketplaceCard/components/IssuePriceSection";
import { IssuerIconSection } from "components/Cards/MarketplaceCard/components/IssuerIcon/IssuerIcon";
import {
  IssuerNameCard,
  IssuerName,
} from "components/Cards/MarketplaceCard/components/IssuerName/IssuerName";
import { NetworkLogo } from "components/Cards/MarketplaceCard/components/NetworkLogo";
import {
  CryptoPerformanceChartSplash,
  PerformanceChartSplash,
} from "components/Cards/MarketplaceCard/components/PerformanceChart";
import { ReturnIndicator } from "components/Cards/MarketplaceCard/components/ReturnIndicator";
import { SpotSection } from "components/Cards/MarketplaceCard/components/SpotSection";
import { YieldCoinLabel } from "components/Labels/YieldCoinLabel";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { DownTriangleSplash } from "components/TriangleSplash/DownTriangleSplash";
import { DataComponentTransaction } from "const/gtm";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useMarketContext } from "context/Market";
import dayjs from "dayjs";
import {
  MechanismType,
  SpotType,
  IndicativePriceRepository,
  Mechanism,
} from "james/market";
import { IndicativePriceFetcher } from "james/market/IndicativePriceFetcher";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { TextExactCriterion } from "james/search/criterion";
import { TokenIdentifier } from "james/search/identifier";
import { NewSorting, Query } from "james/search/query";
import { MarketListingViewModel } from "james/views/marketListingView";
import { AssetType } from "james/views/marketListingView/Model";
import { CouldNotGetPrice } from "pkgTemp/market";
import { useState, useEffect } from "react";
import { Token } from "james/ledger";
import {
  AssetOverviewSectionProps,
  bondAssetTypes,
  cryptoCardAssetTypes,
} from "../AssetOverview";

export const DesktopAssetCard = (props: AssetOverviewSectionProps) => {
  const theme = useTheme();
  const isPublic = location.pathname.startsWith("/public");

  const [initialised, setInitialised] = useState(false);
  const { authContext } = useApplicationContext();

  const { errorContextErrorTranslator } = useErrorContext();

  const [defaultQuoteTokenForSpot, setDefaultQuoteTokenForSpot] = useState<
    Token | undefined
  >(undefined);

  const { marketContextSpotPricer } = useMarketContext();
  const [isCryptoCard, setIsCryptoCard] = useState(false);
  const [isBond, setIsBond] = useState(false);
  const [isSmartInstrument, setIsSmartInstrument] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [assetIsIssued, setAssetIsIssued] = useState(false);

  const [price, setPrice] = useState<{
    buy: string;
    sell: string;
  }>({
    buy: "",
    sell: "",
  });

  useEffect(() => {
    // do nothing until given market listing view model is set
    if (!props.marketListingViewModel) {
      return;
    }
    if (!props.marketListingViewModel.assetType) {
      return;
    }

    setIsSubscription(
      [AssetType.PreferenceShare, AssetType.Bond, AssetType.Share].includes(
        props.marketListingViewModel.assetType,
      ),
    );

    setIsSmartInstrument(
      [AssetType.Bond, AssetType.Share, AssetType.PreferenceShare].includes(
        props.marketListingViewModel.assetType,
      ),
    );

    // compute required parameters
    setIsCryptoCard(
      cryptoCardAssetTypes.includes(props.marketListingViewModel?.assetType),
    );
    setIsBond(bondAssetTypes.includes(props.marketListingViewModel?.assetType));
    setAssetIsIssued(
      dayjs(props.marketListingViewModel.assetIssueDate).isBefore(dayjs()),
    );

    // find spot market mechanism to get default quote parameter for pricing
    const spotMarketMechanism =
      props.marketListingViewModel.listingMarketMechanisms.find(
        (mm) => mm.type === MechanismType.Spot,
      );
    if (spotMarketMechanism) {
      // get default quote parameter
      let defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
        (qp) => qp.quoteToken.code === "mZAR",
      );
      if (!defaultQuoteParameter) {
        defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
          (qp) => qp.quoteToken.code === "USDC",
        );
      }
      if (
        !defaultQuoteParameter &&
        spotMarketMechanism.quoteParameters.length
      ) {
        defaultQuoteParameter = spotMarketMechanism.quoteParameters[0];
      }

      // return if no quote parameter was found
      if (!defaultQuoteParameter) {
        console.error("default quote parameter not found");
        return;
      }
      setDefaultQuoteTokenForSpot(defaultQuoteParameter.quoteToken);
    }

    setInitialised(true);
  }, [props.marketListingViewModel]);

  useEffect(() => {
    setTimeout(async () => {
      if (props.marketListingViewModel?.marketSubscriptionOrderBookViewModel) {
        const unitPrice =
          props.marketListingViewModel?.marketSubscriptionOrderBookViewModel
            .unitPrice;
        setPrice({
          buy: unitPrice.value.toString(),
          sell: unitPrice.value.toString(),
        });
        return;
      }

      if (
        cryptoCardAssetTypes.includes(
          props.marketListingViewModel?.assetType,
        ) &&
        props.marketListingViewModel
      ) {
        const spotMarketMechanism =
          props.marketListingViewModel.listingMarketMechanisms.find(
            (mm) => mm.type === MechanismType.Spot,
          );
        if (!spotMarketMechanism) {
          console.error("expected to find spot market mechanism");
          return;
        }
        if (!spotMarketMechanism.quoteParameters.length) {
          console.error("expected at least 1 quote parameter");
          return;
        }

        // get default quote parameter
        let defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
          (qp) => qp.quoteToken.code === "mZAR",
        );
        if (!defaultQuoteParameter) {
          defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
            (qp) => qp.quoteToken.code === "USDC",
          );
        }

        // return if no quote parameter was found
        try {
          if (!defaultQuoteParameter) {
            return;
          }

          if (
            !defaultQuoteParameter &&
            spotMarketMechanism.quoteParameters.length
          ) {
            defaultQuoteParameter = spotMarketMechanism.quoteParameters[0];
          }
          (
            await marketContextSpotPricer.PriceSpot({
              spotType: SpotType.Buy,
              baseAmount: defaultQuoteParameter.minimumDealSize,
              quoteAmount: defaultQuoteParameter.quoteToken.newAmountOf("0"),
            })
          ).quoteAmount.value.div(defaultQuoteParameter.minimumDealSize.value);
        } catch (e) {
          // log all errors
          console.error(`error pricing spot: ${e}`);

          if (!(e instanceof CouldNotGetPrice)) {
            // return without stopping price loading for unexpected error
            return;
          }
        }
      }

      if (props.marketListingViewModel) {
        if (
          props.marketListingViewModel.listingMarketMechanisms.find(
            (mm) => mm.type === MechanismType.Spot,
          )
        ) {
          return;
        }
        try {
          let latestIndicativePrice;
          if (isPublic) {
            latestIndicativePrice = (
              await IndicativePriceFetcher.PublicFetchLatestIndicativePrice({
                assetIdentifier: TokenIdentifier(
                  props.marketListingViewModel.token,
                ),
              })
            ).publicIndicativePrice;
          } else {
            latestIndicativePrice = (
              await IndicativePriceRepository.SearchIndicativePrice({
                context: authContext,
                criteria: {
                  "token.code": TextExactCriterion(
                    props.marketListingViewModel.token.code,
                  ),
                  "token.issuer": TextExactCriterion(
                    props.marketListingViewModel.token.issuer,
                  ),
                  "token.network": TextExactCriterion(
                    props.marketListingViewModel.token.network,
                  ),
                },
                query: new Query({
                  limit: 1,
                  offset: 0,
                  sorting: [NewSorting("timeOfPrice", "desc")],
                }),
              })
            ).records[0];
          }

          // confirm at least 1 record was retrieved
          if (!latestIndicativePrice) {
            console.error(
              "expected at least 1 indicative price to exist for asset",
            );
            return;
          }

          setPrice({
            buy: latestIndicativePrice.buyPrice.value.toString(),
            sell: latestIndicativePrice.sellPrice.value.toString(),
          });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error loading indicative price information: ${
              err.message ? err.message : err.toString()
            }`,
          );
          // intentionally not stopping loading - errors will result in card seeming to load forever
        }
      }
    });
  }, [props.marketListingViewModel]);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 55,
        width: 384,
        height: isBond ? 572 : 605,
        borderRadius: "0 0 0 10px",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        backgroundColor: theme.palette.background.paper,
      }}
      data-component-transaction={JSON.stringify({
        transaction_asset_buy_price: price.buy,
        transaction_asset_sell_price: price.sell,
        transaction_asset_id: props.marketListingViewModel
          ? `${props.marketListingViewModel.token.code}:${props.marketListingViewModel.token.issuer}:${props.marketListingViewModel.token.network}`
          : "-",
        transaction_asset_name: props.marketListingViewModel
          ? props.marketListingViewModel.assetName
          : "-",
        transaction_asset_issuer:
          props.marketListingViewModel?.assetOwnerClientShortName,
        transaction_asset_risk_rating: props.marketListingViewModel
          ? props.marketListingViewModel.instrumentRiskProfile
          : "-",
        transaction_asset_investor_profile: props.marketListingViewModel
          ? props.marketListingViewModel.instrumentRiskProfile
          : "-",
        transaction_asset_type: props.marketListingViewModel
          ? props.marketListingViewModel.assetType
          : "-",
        transaction_asset_currency: props.marketListingViewModel
          ? props.marketListingViewModel.listingMarketMechanisms[0]
              .quoteParameters[0].quoteToken.code
          : "-",
      } as DataComponentTransaction)}
    >
      <Box>
        <DownTriangleSplash
          backgroundColor={theme.palette.custom.midnight}
          scale={3.825}
          contentPositioning={{
            top: 133,
            left: 133,
          }}
          content={
            props.marketListingViewModel ? (
              <IconViewUpload
                size={113}
                disableChangeIcon
                tokenIconDownloadURL={props.marketListingViewModel.tokenIconURL}
                token={props.marketListingViewModel.token}
              />
            ) : (
              <Skeleton variant={"circular"} width={113} height={113} />
            )
          }
        />
      </Box>
      <>
        {props.marketListingViewModel && initialised ? (
          <Box
            sx={{
              marginTop: "-10px",
              padding: theme.spacing(0, 3),
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              children={props.marketListingViewModel.assetName}
              variant={"h4"}
              sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
              align={"center"}
            />
            <Typography
              children={props.marketListingViewModel.token.code}
              variant={"subtitle2"}
              sx={{
                color: "text.secondary",
                marginTop: theme.spacing(1.5),
              }}
            />

            {isBond && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  mt: 2,
                  px: 1,
                }}
              >
                <ReturnIndicator
                  returnDescription={
                    isBond
                      ? "Indicative Returns p.a."
                      : `Return (${props.marketListingViewModel.returnDescription})`
                  }
                  returnDisclaimer={
                    isSubscription
                      ? props.marketListingViewModel.returnDescription
                      : undefined
                  }
                  subtitle={
                    isSubscription
                      ? props.marketListingViewModel.returnDescription
                      : ""
                  }
                  returnValue={props.marketListingViewModel.returnValue}
                  reverseAlign
                  isSmartInstrument={isSmartInstrument}
                />

                <IssuerNameCard
                  token={props.marketListingViewModel.token}
                  typographyProps={{
                    sx: {
                      color:
                        props.marketListingViewModel.assetType ===
                        AssetType.YieldBearingStablecoin
                          ? theme.palette.text.disabled
                          : theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            )}

            {/* Action Sectioin */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: isBond ? 3 : 6.5,
              }}
            >
              {(() => {
                // if there are no market mechanisms then no action can be returned
                if (
                  !props.marketListingViewModel.listingMarketMechanisms.length
                ) {
                  return null;
                }

                // assume that the first listing market mechanism is the relevant one
                let relevantMarketMechanism: Mechanism =
                  props.marketListingViewModel.listingMarketMechanisms[0];

                // There is only 1 supported use case for 2 market mechanisms at the moment.
                // This is when 1 market mechanism is subscription order book and the other is
                // direct order. This is to deal with that use case after the book has settled.
                if (
                  props.marketListingViewModel.listingMarketMechanisms.length ==
                    2 &&
                  props.marketListingViewModel.listingMarketMechanisms[0]
                    .type === MechanismType.Subscription &&
                  props.marketListingViewModel.listingMarketMechanisms[1]
                    .type === MechanismType.DirectOrder &&
                  props.marketListingViewModel
                    .marketSubscriptionOrderBookViewModel &&
                  props.marketListingViewModel
                    .marketSubscriptionOrderBookViewModel.state ===
                    SubscriptionOrderBookState.Settled
                ) {
                  relevantMarketMechanism =
                    props.marketListingViewModel.listingMarketMechanisms[1];
                }

                switch (relevantMarketMechanism.type) {
                  case MechanismType.DirectOrder:
                    return assetIsIssued ? (
                      <DirectOrderSection
                        marketListingViewModel={props.marketListingViewModel}
                      />
                    ) : (
                      <IssuePriceSection
                        issueDate={props.marketListingViewModel.assetIssueDate}
                      />
                    );

                  case MechanismType.Spot:
                    return assetIsIssued ? (
                      <SpotSection
                        marketListingViewModel={props.marketListingViewModel}
                      />
                    ) : (
                      <IssuePriceSection
                        issueDate={props.marketListingViewModel.assetIssueDate}
                      />
                    );

                  case MechanismType.Subscription:
                    return (
                      <BondSection
                        marketListingViewModel={
                          props.marketListingViewModel as MarketListingViewModel
                        }
                      />
                    );

                  default:
                    return null;
                }
              })()}
            </Box>

            {/*  Risk Profile or Issuer */}
            {isCryptoCard && (
              <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                <IssuerName
                  typographyProps={{ color: "textSecondary" }}
                  token={props.marketListingViewModel.token}
                />
              </Box>
            )}

            {/*  Instrument type and returns section (tradFi instrument) OR */}
            {/*  Instrument type and network logo (crypto) */}
            <Box
              sx={{
                zIndex: 1,
                mt: isBond ? "20px" : "auto",
                mb: isBond ? "20px" : "96px",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr auto",
              }}
            >
              <InstrumentTypeC
                token={props.marketListingViewModel.token}
                assetType={props.marketListingViewModel.assetType}
              />
              {isCryptoCard ? (
                <Box sx={{ alignSelf: "flex-end" }}>
                  <NetworkLogo
                    breakPoint={"sm"}
                    network={props.marketListingViewModel.token.network}
                  />
                </Box>
              ) : !isBond ? (
                <ReturnIndicator
                  returnDescription={
                    isBond
                      ? "Indicative Returns p.a."
                      : `Return (${props.marketListingViewModel.returnDescription})
                    `
                  }
                  returnDisclaimer={
                    isBond
                      ? `*${props.marketListingViewModel.returnDescription}${
                          props.marketListingViewModel.assetType ===
                            AssetType.Bond &&
                          /\d$/.test(
                            props.marketListingViewModel.returnDescription,
                          )
                            ? "%"
                            : ""
                        }`
                      : undefined
                  }
                  returnValue={props.marketListingViewModel.returnValue}
                  isSmartInstrument={isSmartInstrument}
                />
              ) : null}
            </Box>
            {/* Absolute positioned label for YieldBearing assets */}
            {props.marketListingViewModel.assetType ===
              AssetType.YieldBearingStablecoin && (
              <YieldCoinLabel
                sx={{
                  position: "absolute",
                  left: 0,
                  top: "220px",
                  borderRadius: "0 5px 5px 0",
                }}
              />
            )}
            {/*  Performance Chart Splash */}
            {!isBond && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  marginLeft: "-2px",
                  width: 384,
                  height: "113px",
                }}
              >
                {isCryptoCard ? (
                  defaultQuoteTokenForSpot ? (
                    <CryptoPerformanceChartSplash
                      baseToken={props.marketListingViewModel.token}
                      quoteToken={defaultQuoteTokenForSpot}
                    />
                  ) : null
                ) : (
                  <PerformanceChartSplash
                    assetToken={props.marketListingViewModel.token}
                  />
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              marginTop: "-10px",
              padding: theme.spacing(0, 3),
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}
          >
            <Skeleton width={200} height={25} />
            <Skeleton
              sx={(theme) => ({ marginTop: theme.spacing(1.5) })}
              width={150}
              height={15}
            />
            <Skeleton
              sx={(theme) => ({ marginTop: theme.spacing(6.5) })}
              width={"100%"}
              height={72}
            />
            <Skeleton
              sx={(theme) => ({ marginTop: theme.spacing(2) })}
              width={100}
              height={15}
            />
            <Box
              sx={(theme) => ({
                marginTop: "auto",
                marginBottom: "96px",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr auto",
                columnGap: theme.spacing(5),
              })}
            >
              <Skeleton width={"100%"} height={72} />
              <Skeleton width={87} height={72} />
            </Box>
          </Box>
        )}
        {isBond && (
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              backgroundColor: theme.palette.custom.midnight,
              py: 1,
              pr: 2,
              borderBottomLeftRadius: "10px",
              height: "58px",
            })}
          >
            <IssuerIconSection
              marketListingViewModel={props.marketListingViewModel}
            />
          </Box>
        )}
      </>
    </Box>
  );
};
