import { Channel } from "james/notification/Channel";

export const NotificationChannelTypeName =
  "mesh::ledger/TransactionNotificationChannel";

export type NewNotificationChannelOpts = {
  transactionID: string;
  private: boolean;
};

export class TransactionNotificationChannel implements Channel {
  public ["@type"]: string = NotificationChannelTypeName;

  public transactionID = "";

  public private = false;

  constructor(n?: NewNotificationChannelOpts) {
    if (!n) {
      return;
    }
    this.transactionID = n.transactionID;
    this.private = n.private;
  }

  id(): string {
    return `${this.transactionID}-LedgerTransaction`;
  }

  isPrivate(): boolean {
    return this.private;
  }

  channelType(): string {
    return NotificationChannelTypeName;
  }
}
