import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
import {
  HoldingAllocationsSmartInstrumentAttribute,
  SmartInstrumentHoldingAllocationEntry,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeHoldingAllocations_pb";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import {
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import React from "react";
import { Close as DeleteIcon } from "@mui/icons-material";
import { TextField, TextNumField } from "@mesh/common-js-react/dist/FormFields";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { ValidationResult } from "common/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";

export type HoldingAllocationsAttributeFormProps = {
  holdingAllocationsAttribute: HoldingAllocationsSmartInstrumentAttribute;
  onChange: (
    updatedHoldingAllocationsAttribute: HoldingAllocationsSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const HoldingAllocationsAttributeForm = (
  props: HoldingAllocationsAttributeFormProps,
) => {
  const entries = props.holdingAllocationsAttribute.getEntriesList();

  // sum total allocation
  const totalAllocation = entries.reduce(
    (sum, entry) =>
      sum.plus(decimalToBigNumber(entry.getAmount() ?? new Decimal())),
    BigNumber("0"),
  );

  const fieldValidationResult = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ];
  };

  return (
    <Box sx={{ width: 400 }}>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing(1),
        })}
      >
        <Typography
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          variant="caption"
        >
          Total Allocated:
        </Typography>
        <Typography variant="caption">{totalAllocation.toString()}%</Typography>
      </Box>
      {!!fieldValidationResult("total") && (
        <Typography color={"error"} variant="body2">
          {fieldValidationResult("total")}
        </Typography>
      )}
      {props.readOnly ? (
        <ul>
          {entries.map((entry, idx) => (
            <Typography
              variant="body2"
              component={"li"}
              key={`${idx}-${entry.getHolding()}`}
            >
              {`${entry.getHolding()} - ${decimalToBigNumber(
                entry.getAmount() ?? new Decimal(),
              ).toString()}%`}
            </Typography>
          ))}
        </ul>
      ) : (
        <Box>
          {entries.map((thisEntry, idx) => (
            <Box
              key={`holding-${idx}`}
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr 80px auto",
                alignItems: "center",
                gap: theme.spacing(0.5),
              })}
            >
              <TextField
                size="small"
                fullWidth
                id={"holdingAllocationsAttributeForm-holding-selectField"}
                disabled={props.disabled}
                error={!!fieldValidationResult(`${thisEntry.getHolding()}`)}
                value={thisEntry.getHolding()}
                onChange={(e) => {
                  entries[idx] = thisEntry.setHolding(e.target.value);
                  props.onChange(
                    props.holdingAllocationsAttribute.setEntriesList(entries),
                  );
                }}
              />
              <TextNumField
                fullWidth
                sx={{ width: 80 }}
                id={`${idx}-holdingAllocationsAttributeForm-holding-percentage-textNumField`}
                disabled={props.disabled}
                readOnly={props.readOnly}
                disallowNegative
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
                error={!!fieldValidationResult(`${thisEntry.getHolding()}`)}
                value={thisEntry.getAmount()}
                onChange={(newValue) => {
                  entries[idx] = thisEntry.setAmount(newValue);
                  props.onChange(
                    props.holdingAllocationsAttribute.setEntriesList(entries),
                  );
                }}
              />
              <Tooltip placement="top" title={"Remove"}>
                <IconButton
                  size={"small"}
                  id={`${idx}-holdingAllocationsAttributeForm-holding-remove-iconButton`}
                  onClick={() =>
                    props.onChange(
                      props.holdingAllocationsAttribute.setEntriesList(
                        entries.filter(
                          (entry) =>
                            entry.getHolding() !== thisEntry.getHolding(),
                        ),
                      ),
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {!!fieldValidationResult(`${thisEntry.getHolding()}`) && (
                <Typography
                  sx={{ marginTop: -1 }}
                  color={"error"}
                  variant="body2"
                >
                  {fieldValidationResult(`${thisEntry.getHolding()}`)}
                </Typography>
              )}
            </Box>
          ))}

          {/* Render the "add new" control row at the end if not in read only mode */}
          {!props.readOnly && (
            <Box sx={{ marginTop: 1 }}>
              <Button
                onClick={() =>
                  props.onChange(
                    props.holdingAllocationsAttribute.setEntriesList([
                      ...entries,
                      new SmartInstrumentHoldingAllocationEntry()
                        .setHolding("")
                        .setAmount(bigNumberToDecimal(BigNumber("0"))),
                    ]),
                  )
                }
                variant="outlined"
              >
                Add Holding
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
