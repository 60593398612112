import { ETN, ETNStablecoin } from "james/financial";
import dayjs from "dayjs";
import { NumFieldHlpTxt, TextFieldHlpTxt } from "validationHelperText";
import { StepValidationResult, TouchedFields } from "./common";
import { dateIsValid } from "utilities/date/dateIsValid";
import BigNumber from "bignumber.js";
import { PercentageInstrumentManagementFee } from "james/financial/InstrumentManagementFeePercentage";

const big100 = new BigNumber("100");
const big0 = new BigNumber("0");

export function validateRights2ETNStep1(
  etn: ETN,
  etnStablecoin: ETNStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  // determine the validity of dates
  const etnStablecoinIssueDateValid = dateIsValid(etnStablecoin.issueDate);
  const etnStablecoinMaturityDateValid = dateIsValid(
    etnStablecoin.maturityDate,
  );
  const etnIssueDateValid = dateIsValid(etn.issueDate);
  const etnMaturityDateValid = dateIsValid(etn.maturityDate);

  // if all of the dates are valid
  if (
    etnStablecoinIssueDateValid &&
    etnStablecoinMaturityDateValid &&
    etnIssueDateValid &&
    etnMaturityDateValid
  ) {
    // then date validation can be done

    // parse dates required for validation
    const etnStablecoinIssueDateDayjs = dayjs(etnStablecoin.issueDate);
    const etnStablecoinMaturityDateDayjs = dayjs(etnStablecoin.maturityDate);
    const etnIssueDateDayjs = dayjs(etn.issueDate);
    const etnMaturityDateDayjs = dayjs(etn.maturityDate);
    const now = dayjs();

    //
    // etnStablecoin.issueDate
    //
    // if the issueDate is after the maturityDate
    if (etnStablecoinIssueDateDayjs.isAfter(etnStablecoinMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-issueDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-issueDate"] =
          "Cannot be after maturity";
      }
    }
    // if the issueDate is before that of the reference etn issueDate
    if (etnStablecoinIssueDateDayjs.isBefore(etnIssueDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-issueDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-issueDate"] =
          "Cannot be before underlying ETN issuance";
      }
    }

    //
    // etnStablecoin.maturityDate
    //
    // if the maturity date is in the past
    if (etnStablecoinMaturityDateDayjs.isBefore(now)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-maturityDate"] =
          "Cannot be in the past";
      }
    }
    // if the issueDate is after the maturityDate
    if (etnStablecoinIssueDateDayjs.isAfter(etnStablecoinMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-maturityDate"] =
          "Cannot be before issuance";
      }
    }
    // if the maturityDate is after that of the reference etn maturityDate
    if (etnStablecoinMaturityDateDayjs.isAfter(etnMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-maturityDate"] =
          "Cannot be after underlying ETN maturity";
      }
    }
  } else {
    // otherwise 1 or more are not valid - no further date validation can be done

    // show errors on fields with invalid dates

    // if the etnStablecoin issue date is not valid
    if (!etnStablecoinIssueDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-issueDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-issueDate"] =
          "Must be a valid date";
      }
    }

    // if the etnStablecoin maturityDate is not valid
    if (!etnStablecoinMaturityDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etnStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etnStablecoin-maturityDate"] =
          "Must be a valid date";
      }
    }
  }

  //
  // etnStablecoin.ownerID
  //
  // if ownerID is not set
  if (etnStablecoin.ownerID === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etnStablecoin-ownerID"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etnStablecoin-ownerID"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etnStablecoin.maximumUnits
  //
  // if maximumUnits is zero
  if (etnStablecoin.maximumUnits.value.lte(big0)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etnStablecoin-maximumUnits"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etnStablecoin-maximumUnits"] =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  //
  // etnStablecoin.valuationToken
  //
  // if valuationToken token is not set
  if (etnStablecoin.valuationToken.isUndefined()) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etnStablecoin-valuationToken"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etnStablecoin-valuationToken"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  return validationResult;
}

export function validateRights2ETNStep2(
  etn: ETN,
  etnStablecoin: ETNStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  // determine the validity of dates
  const etnStablecoinIssueDateValid = dateIsValid(etnStablecoin.issueDate);
  const etnIssueDateValid = dateIsValid(etn.issueDate);
  const etnStablecoinMaturityDateValid = dateIsValid(
    etnStablecoin.maturityDate,
  );
  const etnMaturityDateValid = dateIsValid(etn.maturityDate);

  // if all of the dates are valid
  if (
    etnStablecoinIssueDateValid &&
    etnIssueDateValid &&
    etnStablecoinMaturityDateValid &&
    etnMaturityDateValid
  ) {
    // parse dates required for validation
    const etnStablecoinIssueDateDayjs = dayjs(etnStablecoin.issueDate);
    const etnIssueDateDayjs = dayjs(etn.issueDate);
    const etnStablecoinMaturityDateDayjs = dayjs(etnStablecoin.maturityDate);
    const etnMaturityDateDayjs = dayjs(etn.maturityDate);
    const now = dayjs();

    //
    // etn.issueDate
    //
    // if the issueDate is after the maturityDate
    if (etnIssueDateDayjs.isAfter(etnMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-issueDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-issueDate"] =
          "Cannot be after maturity";
      }
    }
    // if the etnStablecoin.issueDate is before that of the etn issueDate
    if (etnStablecoinIssueDateDayjs.isBefore(etnIssueDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-issueDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-issueDate"] =
          "Cannot be after digital instrument issuance";
      }
    }

    //
    // etn.maturityDate
    //
    // if the maturity date is in the past
    if (etnMaturityDateDayjs.isBefore(now)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-maturityDate"] =
          "Cannot be in the past";
      }
    }
    // if the issueDate is after the maturityDate
    if (etnIssueDateDayjs.isAfter(etnMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-maturityDate"] =
          "Cannot be before issuance";
      }
    }
    // if the etnStablecoin.maturityDate is after that of the etn maturityDate
    if (etnStablecoinMaturityDateDayjs.isAfter(etnMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-maturityDate"] =
          "Cannot be before digital instrument maturity";
      }
    }
  } else {
    // otherwise 1 or more are not valid - no further date validation can be done

    // show errors on fields with invalid dates

    // if the etn issueDate is not valid
    if (!etnIssueDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-issueDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-issueDate"] =
          "Must be a valid date";
      }
    }

    // if the etn maturityDate is not valid
    if (!etnMaturityDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etn-maturityDate"] =
          "Must be a valid date";
      }
    }
  }

  //
  // etn.name
  //
  // if name is not set
  if (etn.name === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-name"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-name"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  } else if (etn.name.length < 3) {
    // otherwise if name is less than 3 characters long

    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-name"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-name"] =
        "Cannot be less than 3 characters";
    }
  }

  //
  // etn.assetClass
  //
  if (etn.assetClass === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-assetClass"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-assetClass"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etn.issuerName
  //
  if (etn.issuerName === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-issuerName"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-issuerName"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // unitTrust.issuerManagementFee.percentage
  //
  if (
    etn.issuerManagementFee instanceof PercentageInstrumentManagementFee &&
    etn.issuerManagementFee.percentage.isGreaterThan(big100)
  ) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etn-issuerManagementFee"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-issuerManagementFeePercentage"] =
        "Cannot be more than 100";
    }
  }

  return validationResult;
}

export function validateRights2ETNStep3(
  etn: ETN,
  etnStablecoin: ETNStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  //
  // etn.investorProfile
  //
  // if investorProfile is not set
  if (etn.investorProfile === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-investorProfile"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-investorProfile"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etn.instrumentRiskProfile
  //
  // if instrumentRiskProfile is not set
  if (etn.riskProfile === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-riskProfile"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etn-riskProfile"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etn.countryAllocations
  //
  // prepare variables for validation
  const usedCountryAllocations: { [key: string]: boolean } = {};
  let totalCountryAllocation = new BigNumber("0");

  // for every countryAllocation...
  etn.countryAllocations.forEach((c, i) => {
    // if the name is not set
    if (c.name === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the countryAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etn-countryAllocations"]
      ) {
        // then an error message should be shown on the specific allocation where the problem is
        validationResult.fieldValidations[`etn-countryAllocations-${i}-name`] =
          "Country code cannot be blank";
        validationResult.fieldValidations["etn-countryAllocations"] =
          "Error in list";
      }
    } else {
      // otherwise, if the name is set...

      // and it is not unique
      if (usedCountryAllocations[c.name.toLowerCase()]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the countryAllocations section has been touched
        if (
          addAllFieldValidationsErrors ||
          touchedFields["etn-countryAllocations"]
        ) {
          // then an error message should be shown on the specific countryAllocation where the problem is
          validationResult.fieldValidations[
            `etn-countryAllocations-${i}-name`
          ] = "Entry with this name already exists";
          validationResult.fieldValidations["etn-countryAllocations"] =
            "Error in list";
        }
      } else {
        // name is unique - record used
        usedCountryAllocations[c.name.toLowerCase()] = true;
      }
    }

    // if the percentage of any of the countryAllocations <= 0
    if (c.percentage.lte(big0)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the countryAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etn-countryAllocations"]
      ) {
        // then an error message should be shown on the specific countryAllocation where the problem is
        validationResult.fieldValidations[
          `etn-countryAllocations-${i}-percentage`
        ] = "Percentage must be greater than 0";
        validationResult.fieldValidations["etn-countryAllocations"] =
          "Error in list";
      }
    }

    // add to total amount for total checks
    totalCountryAllocation = totalCountryAllocation.plus(c.percentage);
  });

  // if the sum of all countryAllocation percentages exceeds 100
  if (totalCountryAllocation.isGreaterThan(big100)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the countryAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etn-countryAllocations"]
    ) {
      // then an error message should be shown on the countryAllocation section
      validationResult.fieldValidations["etn-countryAllocations"] =
        "Sum cannot exceed 100%";
    }
  }

  // if no countryAllocations are set
  if (etn.countryAllocations.length === 0) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the countryAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etn-countryAllocations"]
    ) {
      // then an error message should be shown on the countryAllocation section
      validationResult.fieldValidations["etn-countryAllocations"] =
        "At least 1 is required";
    }
  }

  //
  // etn.holdings
  //
  // prepare variables for validation
  const usedHoldingNames: { [key: string]: boolean } = {};
  let totalHoldingAllocation = new BigNumber("0");

  // for every holding...
  etn.holdings.forEach((c, i) => {
    // if the name is not set
    if (c.name === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the holdings section has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-holdings"]) {
        // then an error message should be shown on the specific allocation where the problem is
        validationResult.fieldValidations[`etn-holdings-${i}-name`] =
          "Holding name cannot be blank";
        validationResult.fieldValidations["etn-holdings"] = "Error in list";
      }
    } else {
      // otherwise, if the name is set...

      // and it is not unique
      if (usedHoldingNames[c.name.toLowerCase()]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the holdings section has been touched
        if (addAllFieldValidationsErrors || touchedFields["etn-holdings"]) {
          // then an error message should be shown on the specific holding where the problem is
          validationResult.fieldValidations[`etn-holdings-${i}-name`] =
            "Entry with this name already exists";
          validationResult.fieldValidations["etn-holdings"] = "Error in list";
        }
      } else {
        // name is unique - record used
        usedHoldingNames[c.name.toLowerCase()] = true;
      }
    }

    // if the percentage of any of the holdings is <= 0
    if (c.percentage.lte(big0)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the holdings section has been touched
      if (addAllFieldValidationsErrors || touchedFields["etn-holdings"]) {
        // then an error message should be shown on the specific holding where the problem is
        validationResult.fieldValidations[`etn-holdings-${i}-percentage`] =
          "Percentage must be greater than 0";
        validationResult.fieldValidations["etn-holdings"] = "Error in list";
      }
    }

    // add to total amount for total checks
    totalHoldingAllocation = totalHoldingAllocation.plus(c.percentage);
  });

  // if the sum of all holding percentages exceeds 100
  if (totalHoldingAllocation.isGreaterThan(big100)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the holdings section has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-holdings"]) {
      // then an error message should be shown on the holding section
      validationResult.fieldValidations["etn-holdings"] =
        "Sum cannot exceed 100%";
    }
  }

  // if no holdings are set
  if (etn.holdings.length === 0) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the holdings section has been touched
    if (addAllFieldValidationsErrors || touchedFields["etn-holdings"]) {
      // then an error message should be shown on the holding section
      validationResult.fieldValidations["etn-holdings"] =
        "At least 1 is required";
    }
  }

  //
  // etn.sectorAllocations
  //
  // prepare variables for validation
  const usedSectorAllocations: { [key: string]: boolean } = {};
  let totalSectorAllocation = new BigNumber("0");

  // for every sectorAllocation...
  etn.sectorAllocations.forEach((c, i) => {
    // if the name is not set
    if (c.sector === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the sectorAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etn-sectorAllocations"]
      ) {
        // then an error message should be shown on the specific allocation where the problem is
        validationResult.fieldValidations[`etn-sectorAllocations-${i}-name`] =
          "Sector name cannot be blank";
        validationResult.fieldValidations["etn-sectorAllocations"] =
          "Error in list";
      }
    } else {
      // otherwise, if the name is set...

      // and it is not unique
      if (usedSectorAllocations[c.sector.toLowerCase()]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the sectorAllocations section has been touched
        if (
          addAllFieldValidationsErrors ||
          touchedFields["etn-sectorAllocations"]
        ) {
          // then an error message should be shown on the specific sectorAllocation where the problem is
          validationResult.fieldValidations[`etn-sectorAllocations-${i}-name`] =
            "Entry with this name already exists";
          validationResult.fieldValidations["etn-sectorAllocations"] =
            "Error in list";
        }
      } else {
        // name is unique - record used
        usedSectorAllocations[c.sector.toLowerCase()] = true;
      }
    }

    // if the percentage of any of the sectorAllocations is <= 0
    if (c.percentage.lte(big0)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the sectorAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etn-sectorAllocations"]
      ) {
        // then an error message should be shown on the specific sectorAllocation where the problem is
        validationResult.fieldValidations[
          `etn-sectorAllocations-${i}-percentage`
        ] = "Percentage must be greater than 0";
        validationResult.fieldValidations["etn-sectorAllocations"] =
          "Error in list";
      }
    }

    // add to total amount for total checks
    totalSectorAllocation = totalSectorAllocation.plus(c.percentage);
  });

  // if the sum of all sectorAllocation percentages exceeds 100
  if (totalSectorAllocation.isGreaterThan(big100)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the sectorAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etn-sectorAllocations"]
    ) {
      // then an error message should be shown on the sectorAllocation section
      validationResult.fieldValidations["etn-sectorAllocations"] =
        "Sum cannot exceed 100%";
    }
  }

  // if no sectorAllocations are set
  if (etn.sectorAllocations.length === 0) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the sectorAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etn-sectorAllocations"]
    ) {
      // then an error message should be shown on the sectorAllocation section
      validationResult.fieldValidations["etn-sectorAllocations"] =
        "At least 1 is required";
    }
  }

  //
  // etn.annualPerformanceLog
  //
  // prepare variables for validation
  const usedYears: { [key: number]: boolean } = {};

  // for every performance log entry
  etn.annualPerformanceLog.forEach((pl, i) => {
    // if the year is not appropriately set
    if (pl.year <= 0) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the annualPerformanceLog section has been touched
      if (addAllFieldValidationsErrors || touchedFields.annualPerformanceLog) {
        // then an error message should be shown on the specific entry where the problem is
        validationResult.fieldValidations[`annualPerformanceLog-${i}-year`] =
          "Year cannot be <= 0";
        validationResult.fieldValidations.annualPerformanceLog =
          "Error in list";
      }
    } else {
      // otherwise, if the year is set appropriately

      // and it is not unique
      if (usedYears[pl.year]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the annualPerformanceLog section has been touched
        if (
          addAllFieldValidationsErrors ||
          touchedFields.annualPerformanceLog
        ) {
          // then an error message should be shown on the specific sectorAllocation where the problem is
          validationResult.fieldValidations[`annualPerformanceLog-${i}-year`] =
            "Year already used";
          validationResult.fieldValidations.annualPerformanceLog =
            "Error in list";
        }
      } else {
        // year is unique - record used
        usedYears[pl.year] = true;

        for (let idx = 0; idx < 12; idx++) {
          if (pl.monthlyPerformance[idx + 1]) {
            if (pl.monthlyPerformance[idx + 1] === "NaN") {
              validationResult.stepComplete = false;
              validationResult.fieldValidations[
                `annualPerformanceLog-${i}-year-${idx + 1}-month`
              ] = "invalid";
              validationResult.fieldValidations[
                `annualPerformanceLog-${i}-year`
              ] = "All fields must be numeric";
            }
          }
        }
      }
    }
  });

  return validationResult;
}

export function validateRightsToETNSupportingDocuments(
  etn: ETN,
  etnStablecoin: ETNStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  //
  // etn.supportingDocuments
  //
  // for every supporting document
  etn.supportingDocuments.forEach((doc, i) => {
    // if description is not set
    if (doc.description === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // indicate that some descriptions are missing
      validationResult.fieldValidations["etn-supportingDocuments"] =
        "Names cannot be blank";

      // and if the supportingDocuments section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields[`etn-supportingDocuments-${i}-description`]
      ) {
        // then an error message should be shown on the specific document
        // where the problem is
        validationResult.fieldValidations[
          `etn-supportingDocuments-${i}-description`
        ] = "Name cannot be blank";
      }
    }
  });

  return validationResult;
}
