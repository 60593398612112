import { TokenIconViewUpload } from "components/Ledger/Token";
import { styled } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import { Model as MarketDirectOrderViewModel } from "james/views/marketDirectOrderView";
import dayjs from "dayjs";
import { DateTimeFormat } from "const/dateformats";
import { Close } from "@mui/icons-material";

const PREFIX = "Header";

const classes = {
  headerRow1InstrumentDetailsLayout: `${PREFIX}-headerRow1InstrumentDetailsLayout`,
  headerRow1InstrumentDetailsInstrumentName: `${PREFIX}-headerRow1InstrumentDetailsInstrumentName`,
  headerRow1InstrumentDetailsInstrumentShortName: `${PREFIX}-headerRow1InstrumentDetailsInstrumentShortName`,
  headerRow2OrderDetailsText: `${PREFIX}-headerRow2OrderDetailsText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  // header
  [`& .${classes.headerRow1InstrumentDetailsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
  },

  [`& .${classes.headerRow1InstrumentDetailsInstrumentName}`]: {
    alignSelf: "end",
    fontWeight: "bold",
  },

  [`& .${classes.headerRow1InstrumentDetailsInstrumentShortName}`]: {
    alignSelf: "start",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.headerRow2OrderDetailsText}`]: {
    color: theme.palette.text.secondary,
  },
}));

export interface HeaderProps {
  marketDirectOrderViewModel: MarketDirectOrderViewModel;
  close?: () => void;
  showLoading?: boolean;
}

export function Header({ close, showLoading, ...props }: HeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const orderDateTimeFormatted = dayjs(
    props.marketDirectOrderViewModel.orderDateTime,
  ).format(DateTimeFormat);

  return (
    <Root>
      {/* Header */}
      <Box
        sx={{
          backgroundColor: theme.palette.custom.midnight,
          padding: {
            sm: `${theme.spacing(1, 3, 1, 4)} !important`,
            xs: `${theme.spacing(1, 3)} !important`,
          },
          display: "grid",
          gridTemplateColumns: "auto auto 1fr",
          columnGap: theme.spacing(2),
          alignItems: "center",
        }}
      >
        <TokenIconViewUpload
          size={64}
          token={props.marketDirectOrderViewModel.tokens.token}
        />
        <Box>
          <Typography
            id="header-assetName-typography"
            variant="h4"
            className={classes.headerRow1InstrumentDetailsInstrumentName}
            children={props.marketDirectOrderViewModel.assetName}
          />
          <Typography
            id="header-assetShortName-typography"
            variant="caption"
            className={classes.headerRow1InstrumentDetailsInstrumentShortName}
            children={props.marketDirectOrderViewModel.assetShortName}
          />
        </Box>
        <Collapse in={showLoading}>
          <CircularProgress />
        </Collapse>
        {isMobile && (
          <IconButton
            disabled={showLoading}
            onClick={close}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.custom.spaceblue,
          display: "grid",
          gridTemplateColumns: "1fr auto",
          padding: theme.spacing(1, 4),
        }}
      >
        <Typography
          variant="caption"
          className={classes.headerRow2OrderDetailsText}
          children={`Order No. ${props.marketDirectOrderViewModel.number}`}
        />
        <Typography
          variant="caption"
          className={classes.headerRow2OrderDetailsText}
          children={orderDateTimeFormatted}
        />
      </Box>
    </Root>
  );
}
