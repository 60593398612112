import React, { useMemo } from "react";
import { Chip, ChipProps, SxProps, useTheme } from "@mui/material";
import { ETNRollCorporateActionState } from "james/financial/ETNRollCorporateAction";
import camelCase from "lodash/camelCase";

export interface ETNRollCorporateActionStateChipProps {
  state: ETNRollCorporateActionState | "";
  chipProps?: ChipProps;
}

export const ETNRollCorporateActionStateChip = ({
  state,
  chipProps,
}: ETNRollCorporateActionStateChipProps) => {
  const theme = useTheme();

  const styleMap: { [x in ETNRollCorporateActionState | ""]: SxProps } =
    useMemo(
      () => ({
        [ETNRollCorporateActionState.Pending]: {
          backgroundColor: theme.palette.info.main,
        },
        [ETNRollCorporateActionState.Executed]: {
          backgroundColor: theme.palette.success.main,
        },
        "": {
          backgroundColor: theme.palette.info.main,
        },
      }),
      [],
    );

  return (
    <Chip
      {...chipProps}
      sx={styleMap[state]}
      id={`etnRollCorporateActionState-${camelCase(state)}-chip`}
      size="small"
      label={state}
    />
  );
};
