"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allTimeUnits = void 0;
exports.timeUnitToString = timeUnitToString;
exports.stringToTimeUnit = stringToTimeUnit;
const timeUnit_pb_1 = require("./timeUnit_pb");
// Get all timeUnits as enum values
exports.allTimeUnits = Object.values(timeUnit_pb_1.TimeUnit).filter((value) => typeof value === "number");
// Define explicit mappings between TimeUnit enums and custom string representations
const timeUnitToStringMapping = {
    [timeUnit_pb_1.TimeUnit.UNDEFINED_TIME_UNIT]: "-",
    [timeUnit_pb_1.TimeUnit.HOURS_TIME_UNIT]: "Hours",
    [timeUnit_pb_1.TimeUnit.DAYS_TIME_UNIT]: "Days",
    [timeUnit_pb_1.TimeUnit.MONTHS_TIME_UNIT]: "Months",
    [timeUnit_pb_1.TimeUnit.YEARS_TIME_UNIT]: "Years",
};
// Reverse mapping from string to TimeUnit enum
const stringToTimeUnitMapping = {};
for (const [key, value] of Object.entries(timeUnitToStringMapping)) {
    stringToTimeUnitMapping[value] = Number(key);
}
class UnsupportedTimeUnitError extends Error {
    constructor(timeUnit) {
        const message = `Unsupported TimeUnit: ${timeUnit}`;
        super(message);
        this.timeUnit = timeUnit;
    }
}
/**
 * Converts a TimeUnit enum instance to a custom string representation.
 * @param {TimeUnit} timeUnit - The timeUnit to convert.
 * @returns {string} The custom string representation of the timeUnit.
 */
function timeUnitToString(timeUnit) {
    if (timeUnit in timeUnitToStringMapping) {
        return timeUnitToStringMapping[timeUnit];
    }
    else {
        throw new UnsupportedTimeUnitError(timeUnit);
    }
}
class UnsupportedTimeUnitStringError extends Error {
    constructor(timeUnitStr) {
        const message = `Unsupported timeUnit string: ${timeUnitStr}`;
        super(message);
        this.timeUnitStr = timeUnitStr;
    }
}
/**
 * Converts a custom string representation to a TimeUnit enum instance.
 * @param {string} timeUnitStr - The custom string representation of the timeUnit.
 * @returns {TimeUnit} The corresponding TimeUnit enum instance.
 */
function stringToTimeUnit(timeUnitStr) {
    if (timeUnitStr in stringToTimeUnitMapping) {
        return stringToTimeUnitMapping[timeUnitStr];
    }
    else {
        throw new UnsupportedTimeUnitStringError(timeUnitStr);
    }
}
