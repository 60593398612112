/* eslint-disable import/no-default-export */
import React from "react";
import { Box } from "@mui/material";
import { useFirebaseContext } from "context/Firebase";
import { MarketplaceLayout, PublicLayout } from "layouts";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Router } from "routes";
import { publicRoutes } from "routes/public";

// Views
import { Marketplace as MarketPlaceHomeView } from "views/Marketplace/";
import { CompanyRegistrationView } from "views/CompanyRegistration";
import { ForgotPassword } from "views/ForgotPassword";
import { SignIn } from "views/SignIn";
import { CompanyUserRegistration } from "views/CompanyUserRegistration";
import { redirectURLKey } from "routes/Route";
import { ClientKind, ClientKYCStatus, ClientStatus } from "james/client/Client";
import { getViewConfigurationApps } from "utilities/configuration/viewConfiguration";
import { Splash } from "views/Splash/Splash";
import { MainAppBarOnlyLayout } from "layouts/MainAppBarOnly";
import { Consistency } from "views/Consistency";
import { ResetPassword } from "views/ResetPassword/ResetPassword";
import { CompanyUserRegistrationLocalStorageKey } from "views/CompanyUserRegistration/CompanyUserRegistration";
import { PublicViewWithFrame } from "layouts/Public/PublicViewWithFrame";
import { AssetOverview } from "views/Marketplace/components/AssetOverview";
import { UserProfileDialog } from "views/UserProfileDialog/UserProfileDialog";
import { SignUp } from "views/SignUp/SignUp";
import { useApplicationContext } from "context/Application/Application";
import { InstrumentBuilder } from "views/InstrumentBuilder/InstrumentBuilder";
import { Administration } from "views/Administration";
import { MarketManagement } from "views/MarketManagement/MarketManagement";
import { UserGuide, UserState } from "james/user/User";
import { LoginClaimsChecker } from "components/LoginClaimsChecker";
import { AcceptAUSANDPaas } from "views/TermsAndConditions/AcceptAUSAndPaaS";
import { AcceptAUS } from "views/TermsAndConditions/AcceptAUS";
import { useWindowSize } from "utilities/general";
import { KYCOutstandingDialog } from "components/KYCOutstandingDialog/KYCOutstandingDialog";
import {
  CompanyClientUserFirstTimeLoginSteps,
  IndividualClientUserFirstTimeLoginSteps,
} from "./views/FirstLoginSteps";
import { ErrUserNotVerifiedErrorCode } from "james/security/authentication";
import { UserLoginLoader } from "components/Loaders";
import { CompanyFirstSteps } from "views/CompanyFirstSteps";
import {
  InitiateCompanyAdminRegistration,
  InitiateUserRegistration,
} from "./views/SignUp/const";
import { SetupClientProfile } from "views/SetupClientProfile/SetupClientProfile";
import { Helmet } from "react-helmet-async";
import { GTMPageLevelTaggingContainer } from "./components/GTM/GTMPageLevelTaggingContainer";
import { PageType } from "types/gtm";
import { UnderwriterHub } from "views/UnderwriterHub/UnderwriterHub";
import { Accounts } from "views/Accounts";
import { KYBOutstandingDialog } from "components/KYBOutstandingDialog/KYBOutstandingDialog";
import { KYBDialog } from "views/KYB/KYBDialog";
import { KYCDialog } from "./views/KYC/KYCDialog";

export enum MeshApp {
  InstrumentBuilder = "Instrument Builder",
  Marketplace = "Market Place",
  Administration = "Administration",
  MarketManagement = "Market Management",
  Consistency = "Consistency",
  Underwriting = "Underwriting",
}

const Redirects = () => {
  const { firebaseAuthenticated } = useFirebaseContext();
  const { userAuthenticated, viewConfiguration } = useApplicationContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;

  if (!firebaseAuthenticated && !loggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  if (firebaseAuthenticated && !loggedIn) {
    return <Navigate replace to="/sign-up" />;
  }

  const viewConfigurationApps = getViewConfigurationApps(viewConfiguration);

  if (viewConfigurationApps.includes(MeshApp.Marketplace)) {
    return <Navigate replace to="/market" />;
  }

  if (viewConfigurationApps.includes(MeshApp.InstrumentBuilder)) {
    return <Navigate replace to="/builder" />;
  }

  if (viewConfigurationApps.includes(MeshApp.Administration)) {
    return <Navigate replace to="/administration" />;
  }

  if (viewConfigurationApps.includes(MeshApp.MarketManagement)) {
    return <Navigate replace to="/market-management" />;
  }

  // if the code reaches here show a splash this indicates some sort of bug

  return <Splash />;
};

interface RequireAuthProps {
  /** notLoggedIn is an optional prop for inverting the loggedIn requirement.
   * Setting notLoggedIn=true will ensure the user is NOT logged in
   * The default value is false and will ensure the user is logged in
   * If the condition is not met the user is redirected based on the value:
   * redirect to "/sign-in" if notLoggedIn == false
   * redirect to "/" if notLoggedIn == true
   * */
  notLoggedIn?: boolean;

  /** requiredViewConfig is optional.
   * If specified the appContextViewConfiguration must contain the specified value
   * otherwise the <Splash /> component is returned.
   * NOTE: If the user does not have the required view config the <Splash />
   * component is rendered but the user is not redirected */
  requiredViewConfig?: MeshApp;

  /** requiredClientKind is optional.
   * If specified the appContextMyClient.clientKind must be equal to the specified
   * value otherwise the user is redirected to "/" */
  requiredClientKind?: ClientKind;

  /** redirectURL is an optional override to where the user is redirected */
  redirectURL?: string;

  firebaseSignInAccess?: boolean;

  children: JSX.Element;
}

const RequireAuth = (props: RequireAuthProps) => {
  const { firebaseAuthenticated, firebaseLogout } = useFirebaseContext();
  const location = useLocation();
  const { viewConfiguration, userAuthenticated, loginClaims, userLoginErr } =
    useApplicationContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;

  if (props.firebaseSignInAccess) {
    if (loggedIn) {
      return <Navigate to={props.redirectURL ?? "/"} replace />;
    }
    // we know the user is not logged in so we can't check anything else
    return props.children;
  }

  // required to NOT be logged in
  if (props.notLoggedIn) {
    if (firebaseAuthenticated && !loggedIn) {
      if (userLoginErr && userLoginErr.code !== ErrUserNotVerifiedErrorCode) {
        firebaseLogout().finally();
        return <Navigate replace to="/sign-in" />;
      }

      // if the user is just authenticated with firebase and not logged in
      // then in that scenario the user will need to redirected to sign-up
      // this is required for the user to complete their sign-up
      if (!localStorage.getItem(CompanyUserRegistrationLocalStorageKey)) {
        return <Navigate replace to="/sign-up" />;
      }
    }

    if (loggedIn) {
      return <Navigate to={props.redirectURL ?? "/"} replace />;
    }
    // we know the user is not logged in so we can't check anything else
    return props.children;
  }

  // if code reached here the user IS required to be logged in
  if (!loggedIn) {
    // Redirect the user to the "/sign-in" page, but save the
    // current location they were trying to go to when they were redirected.
    // This allows us to send them along to that page after they sign in.
    const redirectPath = `${location.pathname}${location.search}${location.hash}`;
    if (redirectPath !== "/") {
      sessionStorage.setItem(redirectURLKey, redirectPath);
    }

    return <Navigate to={props.redirectURL ?? "/sign-in"} replace />;
  }

  // if the user doesn't have the required view configuration, show
  // the Splash screen
  if (
    props.requiredViewConfig &&
    !getViewConfigurationApps(viewConfiguration).includes(
      props.requiredViewConfig,
    )
  ) {
    return <Splash />;
  }

  // if the user is not the required client kind, redirect the user to "/"
  if (
    props.requiredClientKind &&
    loginClaims.clientKind !== props.requiredClientKind
  ) {
    return <Navigate to={props.redirectURL ?? "/"} replace />;
  }

  // the user is authorised to view the route
  return props.children;
};

const App: React.FC = () => {
  const { firebaseContextUpdating, firebaseAuthenticated } =
    useFirebaseContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    applicationContextUpdating,
    userAuthenticated,
    userLoginErr,
    loginClaims,
    viewConfiguration,
    myCompany,
  } = useApplicationContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;
  const [, windowHeight] = useWindowSize();

  if (!userLoginErr) {
    if (firebaseContextUpdating || applicationContextUpdating) {
      return <UserLoginLoader />;
    }

    if (
      // this case ensures that the browser router does not render until
      // the user has logged into james, prevents navigations to home on refresh
      firebaseAuthenticated &&
      !localStorage.getItem(InitiateUserRegistration) &&
      !localStorage.getItem(CompanyUserRegistrationLocalStorageKey) &&
      !localStorage.getItem(InitiateCompanyAdminRegistration) &&
      !loggedIn
    ) {
      return <UserLoginLoader />;
    }

    if (loginClaims.clientKind === ClientKind.CompanyType && !myCompany) {
      return <UserLoginLoader />;
    }
  }

  if (
    // if user is logged in
    loggedIn && // AND
    // they are not trying to access a public route
    !publicRoutes.find((pr) => window.location.pathname.includes(pr.path))
  ) {
    if (
      loginClaims.firstName === "" ||
      loginClaims.lastName === "" ||
      loginClaims.ausAgreementAcceptanceRequired ||
      (loginClaims.clientKind == ClientKind.CompanyType &&
        myCompany?.formOfIncorporation === "")
    ) {
      if (
        loginClaims.clientKind === ClientKind.CompanyType &&
        viewConfiguration.UpdateMyCompany &&
        loginClaims.kycStatus !== ClientKYCStatus.VerifiedStatus
      ) {
        return <SetupClientProfile />;
      }

      if (loginClaims.clientKind === ClientKind.IndividualType) {
        return <SetupClientProfile />;
      }
    }
  }

  if (
    // if user is logged in
    loggedIn && // AND
    // they are not trying to access a public route
    !publicRoutes.find((pr) => window.location.pathname.includes(pr.path)) &&
    loginClaims.userState === UserState.Registered
  ) {
    // then a number of checks need to be done before it is
    // possible for them to navigate freely within the app

    // if the user is of an individual client and has not yet accepted BOTH
    // the aus AND paas agreement - then show them the 'accept paas and aus page'
    if (
      loginClaims.clientKind === ClientKind.IndividualType &&
      loginClaims.ausAgreementAcceptanceRequired &&
      loginClaims.paasAgreementAcceptanceRequired
    ) {
      return (
        <Box sx={{ height: windowHeight }}>
          <AcceptAUSANDPaas />
        </Box>
      );
    }

    // if the user is of an individual client and acceptance of the
    // aus agreement is required - then show them the 'accept aus page'
    if (loginClaims.ausAgreementAcceptanceRequired) {
      return (
        <Box sx={{ height: windowHeight }}>
          <AcceptAUS />
        </Box>
      );
    }

    if (
      loginClaims.clientKind === ClientKind.IndividualType &&
      !loginClaims.completedGuides.includes(UserGuide.FirstTimeLoginSteps)
    ) {
      return (
        <Box sx={{ height: windowHeight }}>
          <IndividualClientUserFirstTimeLoginSteps />
        </Box>
      );
    }

    if (
      loginClaims.clientKind === ClientKind.CompanyType &&
      myCompany?.formOfIncorporation !== "" &&
      !loginClaims.completedGuides.includes(UserGuide.FirstTimeLoginSteps)
    ) {
      return (
        <Box sx={{ height: windowHeight }}>
          <CompanyClientUserFirstTimeLoginSteps />
        </Box>
      );
    }

    // check if this user should be shown the company first steps
    // i.e. check if the user is an administrator at their client AND
    // if the client status is complete profile
    if (
      viewConfiguration["Company First Steps"] &&
      loginClaims.clientStatus === ClientStatus.CompleteProfile
    ) {
      return <CompanyFirstSteps />;
    }
  }

  return (
    <>
      <Helmet>
        <title>Mesh</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Mesh is a multi-sided, end-to-end, cross-asset platform designed to fundamentally disrupt the way global Financial and Capital Markets operate and are accessed."
        />
      </Helmet>
      <LoginClaimsChecker />
      <KYCOutstandingDialog />
      <KYBOutstandingDialog />
      <Routes>
        <Route
          path="/sign-up/*"
          element={
            <RequireAuth firebaseSignInAccess={true}>
              <GTMPageLevelTaggingContainer pageType={PageType.Generic}>
                <SignUp />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/accounts/*"
          element={
            <RequireAuth>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <Accounts />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/kyc/*"
          element={
            <RequireAuth requiredClientKind={ClientKind.IndividualType}>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <KYCDialog />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/kyb/*"
          element={
            <RequireAuth requiredClientKind={ClientKind.CompanyType}>
              <KYBDialog />
            </RequireAuth>
          }
        />
        <Route
          path="/user-profile"
          element={
            <RequireAuth>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <UserProfileDialog open />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/builder/*"
          element={
            <RequireAuth requiredViewConfig={MeshApp.InstrumentBuilder}>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <InstrumentBuilder />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path={"/administration/*"}
          element={
            <RequireAuth requiredViewConfig={MeshApp.Administration}>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <Administration />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path={"/market-management/*"}
          element={
            <RequireAuth requiredViewConfig={MeshApp.MarketManagement}>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <MarketManagement />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route path="/market/*">
          <Route
            path="asset-overview/*"
            element={
              // If the user is trying to trade, redirect to the sign-in page,
              // otherwise the public page
              <RequireAuth
                requiredViewConfig={MeshApp.Marketplace}
                redirectURL={
                  searchParams.get("spot") || searchParams.get("order")
                    ? "/sign-in"
                    : `/public${location.pathname}${location.search}`
                }
              >
                <MarketplaceLayout>
                  <GTMPageLevelTaggingContainer
                    pageType={PageType.informational}
                  >
                    <MarketPlaceHomeView baseURL={"/market/asset-overview"} />
                  </GTMPageLevelTaggingContainer>
                </MarketplaceLayout>
              </RequireAuth>
            }
          />
          <Route
            path={"*"}
            element={
              <RequireAuth requiredViewConfig={MeshApp.Marketplace}>
                <div>
                  <MarketplaceLayout>
                    <GTMPageLevelTaggingContainer
                      pageType={PageType.Transactional}
                    >
                      <MarketPlaceHomeView baseURL={"/market"} />
                    </GTMPageLevelTaggingContainer>
                  </MarketplaceLayout>
                </div>
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/consistency/*"
          element={
            <RequireAuth requiredViewConfig={MeshApp.Consistency}>
              <MainAppBarOnlyLayout>
                <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                  <Consistency />
                </GTMPageLevelTaggingContainer>
              </MainAppBarOnlyLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/underwriter-hub/*"
          element={
            <RequireAuth requiredViewConfig={MeshApp.Underwriting}>
              <UnderwriterHub />
            </RequireAuth>
          }
        />
        <Route
          path="/sign-in"
          element={
            <RequireAuth notLoggedIn>
              <PublicLayout>
                <GTMPageLevelTaggingContainer pageType={PageType.Generic}>
                  <SignIn />
                </GTMPageLevelTaggingContainer>
              </PublicLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <RequireAuth notLoggedIn>
              <GTMPageLevelTaggingContainer pageType={PageType.Generic}>
                <ForgotPassword />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/reset-password"
          element={
            <RequireAuth notLoggedIn>
              <GTMPageLevelTaggingContainer pageType={PageType.Generic}>
                <ResetPassword />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/company-registration"
          element={
            <RequireAuth notLoggedIn>
              <GTMPageLevelTaggingContainer pageType={PageType.Generic}>
                <CompanyRegistrationView />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route
          path="/user-registration"
          element={
            <RequireAuth notLoggedIn>
              <GTMPageLevelTaggingContainer pageType={PageType.Management}>
                <CompanyUserRegistration />
              </GTMPageLevelTaggingContainer>
            </RequireAuth>
          }
        />
        <Route path="/public/*">
          <Route
            path="market/asset-overview/*"
            element={
              <RequireAuth
                notLoggedIn
                redirectURL={`/market/asset-overview${location.search}`}
              >
                <PublicViewWithFrame>
                  <GTMPageLevelTaggingContainer
                    pageType={PageType.informational}
                  >
                    <AssetOverview />
                  </GTMPageLevelTaggingContainer>
                </PublicViewWithFrame>
              </RequireAuth>
            }
          />
          <Route
            path={"*"}
            element={
              <RequireAuth notLoggedIn>
                <PublicViewWithFrame>
                  <Router
                    baseURL={"/public"}
                    redirectPath={"/"}
                    routes={publicRoutes}
                  />
                </PublicViewWithFrame>
              </RequireAuth>
            }
          />
        </Route>
        <Route
          // This route catches all other paths
          path="*"
          element={<Redirects />}
        />
      </Routes>
    </>
  );
};

export default App;
