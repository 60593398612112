"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSmartInstrumentAllocationSectors = void 0;
exports.smartInstrumentAllocationSectorToString = smartInstrumentAllocationSectorToString;
exports.stringToSmartInstrumentAllocationSector = stringToSmartInstrumentAllocationSector;
const smartInstrumentAttributeSectorAllocations_pb_1 = require("./smartInstrumentAttributeSectorAllocations_pb");
// Get all day count conventions as enum values
exports.allSmartInstrumentAllocationSectors = Object.values(smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector).filter((value) => typeof value === "number");
// Define explicit mappings between SmartInstrumentAllocationSector enums and custom string representations
const smartInstrumentAllocationSectorToStringMapping = {
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.UNDEFINED_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "-",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.AUTOMOBILES_AND_PARTS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Automobiles and Parts",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.BANKS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Banks",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.CASH_AND_CASH_EQUIVALENTS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Cash and Cash Equivalents",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.CHEMICALS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Chemicals",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.COMMUNICATION_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Communication",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.CONSUMER_DISCRETIONARY_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Consumer Discretionary",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.CONSUMER_PRODUCTS_AND_SERVICES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Consumer Products and Services",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.CONSUMER_STAPLES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Consumer Staples",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.DEBT_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Debt",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.ENERGY_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Energy",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.FINANCIAL_SERVICES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Financial Services",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.FINANCIALS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Financials",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.FOOD_BEVERAGE_AND_TOBACCO_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Food, Beverage and Tobacco",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.HEALTHCARE_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Health Care",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.INDUSTRIAL_GOODS_AND_SERVICES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Industrial Goods and Services",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.INDUSTRIALS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Industrials",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.INFORMATION_TECHNOLOGY_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Information Technology",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.INSURANCE_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Insurance",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.MATERIALS_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Materials",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.MEDIA_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Media",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.PERSONAL_CARE_DRUG_AND_GROCERY_STORES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Personal Care, Drug and Grocery Stores",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.REAL_ESTATE_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Real Estate",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.RETAIL_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Retail",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.TECHNOLOGY_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Technology",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.TELECOMMUNICATION_SERVICES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Telecommunication Services",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.TRAVEL_AND_LEISURE_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Travel and Leisure",
    [smartInstrumentAttributeSectorAllocations_pb_1.SmartInstrumentAllocationSector.UTILITIES_SMART_INSTRUMENT_ALLOCATION_SECTOR]: "Utilities",
};
// Reverse mapping from string to SmartInstrumentAllocationSector enum
const stringToSmartInstrumentAllocationSectorMapping = {};
for (const [key, value] of Object.entries(smartInstrumentAllocationSectorToStringMapping)) {
    stringToSmartInstrumentAllocationSectorMapping[value] = Number(key);
}
class UnsupportedSmartInstrumentAllocationSectorError extends Error {
    constructor(smartInstrumentAllocationSector) {
        const message = `Unsupported SmartInstrumentAllocationSector: ${smartInstrumentAllocationSector}`;
        super(message);
        this.smartInstrumentAllocationSector = smartInstrumentAllocationSector;
    }
}
/**
 * Converts a SmartInstrumentAllocationSector enum instance to a custom string representation.
 * @param {SmartInstrumentAllocationSector} smartInstrumentAllocationSector - The day count convention to convert.
 * @returns {string} The custom string representation of the day count convention.
 */
function smartInstrumentAllocationSectorToString(smartInstrumentAllocationSector) {
    if (smartInstrumentAllocationSector in
        smartInstrumentAllocationSectorToStringMapping) {
        return smartInstrumentAllocationSectorToStringMapping[smartInstrumentAllocationSector];
    }
    else {
        throw new UnsupportedSmartInstrumentAllocationSectorError(smartInstrumentAllocationSector);
    }
}
class UnsupportedSmartInstrumentAllocationSectorStringError extends Error {
    constructor(smartInstrumentAllocationSectorStr) {
        const message = `Unsupported day count convention string: ${smartInstrumentAllocationSectorStr}`;
        super(message);
        this.smartInstrumentAllocationSectorStr =
            smartInstrumentAllocationSectorStr;
    }
}
/**
 * Converts a custom string representation to a SmartInstrumentAllocationSector enum instance.
 * @param {string} smartInstrumentAllocationSectorStr - The custom string representation of the day count convention.
 * @returns {SmartInstrumentAllocationSector} The corresponding SmartInstrumentAllocationSector enum instance.
 */
function stringToSmartInstrumentAllocationSector(smartInstrumentAllocationSectorStr) {
    if (smartInstrumentAllocationSectorStr in
        stringToSmartInstrumentAllocationSectorMapping) {
        return stringToSmartInstrumentAllocationSectorMapping[smartInstrumentAllocationSectorStr];
    }
    else {
        throw new UnsupportedSmartInstrumentAllocationSectorStringError(smartInstrumentAllocationSectorStr);
    }
}
