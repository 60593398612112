import { InstrumentAnnualPerformanceLogEntry } from "james/financial";
import { DataSheetSection } from "./DataSheetSection";

export const AssetPerformanceDataSheetSectionTypeName =
  "mesh::marketData/AssetPerformance";

export const AssetPerformanceDataSheetSectionName = "Performance";

export class AssetPerformanceDataSheetSection implements DataSheetSection {
  public ["@type"] = AssetPerformanceDataSheetSectionTypeName;

  public performanceLog: InstrumentAnnualPerformanceLogEntry[] = [];

  constructor(l?: AssetPerformanceDataSheetSection) {
    if (!l) {
      return;
    }

    this.performanceLog = l.performanceLog.map(
      (v) => new InstrumentAnnualPerformanceLogEntry(v),
    );
  }

  public DataSheetSectionName(): string {
    return AssetPerformanceDataSheetSectionName;
  }
}
