import React, { useEffect, useMemo, useState } from "react";
import { Dialog, useTheme } from "@mui/material";
import { DirectOrderType } from "james/market/DirectOrder";
import {
  MarketListingViewModel,
  Reader as MarketListingViewReader,
} from "james/views/marketListingView";
import { useIsMounted } from "hooks";
import { ScopeDeterminer } from "james/search/scope";
import { Permission } from "james/security/Permission";
import { DirectOrderStateControllerServiceProviderName } from "james/market/DirectOrderStateController";
import {
  IndicativePrice,
  IndicativePriceRepository,
  Listing,
  ListingRepository,
  MechanismType,
  QuoteParameter,
} from "james/market";
import { TextExactCriterion } from "james/search/criterion";
import { IDIdentifier } from "james/search/identifier/ID";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useSnackbar } from "notistack";
import { NewSorting, Query } from "james/search/query";
import { Header } from "./components/Header";
import { IssuerDirectOrderCard } from "./components/IssuerDirectOrderCard";
import { InvestorDirectOrderCard } from "./components/InvestorDirectOrderCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingDialogContent } from "./components/LoadingDialogContent";
import { useApplicationContext } from "context/Application/Application";
import {
  DataComponentInfo,
  DataComponentTransaction,
  InteractionDriver,
} from "const/gtm";
import { useErrorContext } from "context/Error";

interface PlaceOrdersDialogProps {
  closeDialog: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  marketListingViewModelCriteria?: any;
  directOrderType: DirectOrderType;
  marketListingViewModel?: MarketListingViewModel;
  indicativePrice?: IndicativePrice;
}

enum DialogMode {
  Determining,
  Issuer,
  Investor,
}

export function PlaceOrdersDialog(props: PlaceOrdersDialogProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const isMounted = useIsMounted();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // on dialog open, load view model if required
  const [marketListingViewModel, setMarketListingViewModel] = useState<
    MarketListingViewModel | undefined
  >(props.marketListingViewModel);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // if a market listing view model is provided then return immediately
    if (props.marketListingViewModel) {
      return;
    }

    // if market listing view model already set then return immediately
    if (marketListingViewModel) {
      return;
    }

    // otherwise retrieve with given criteria
    (async () => {
      if (!props.marketListingViewModelCriteria) {
        return;
      }
      try {
        const retrievedModel = await MarketListingViewReader.ReadOne({
          context: authContext,
          criteria: props.marketListingViewModelCriteria,
        });
        if (isMounted()) {
          setMarketListingViewModel(retrievedModel.model);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving market listing view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Retrieving Placement", { variant: "error" });
        props.closeDialog();
      }
    })();
  }, [marketListingViewModel, props, isMounted, enqueueSnackbar, authContext]);

  // On load determine dialog mode by determining if the executing user has
  // permission to submit an order in the group that owns the asset
  const [dialogMode, setDialogMode] = useState(DialogMode.Determining);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    if (!marketListingViewModel) {
      return;
    }
    (async () => {
      try {
        if (
          (
            await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
              context: authContext,
              groupID: marketListingViewModel.assetOwnerID,
              buildScopeTree: false,
              service: new Permission({
                serviceName: "SubmitDirectOrder",
                serviceProvider: DirectOrderStateControllerServiceProviderName,
                description: "?",
              }),
            })
          ).authorized
        ) {
          if (isMounted()) {
            setDialogMode(DialogMode.Issuer);
          }
        } else if (isMounted()) {
          setDialogMode(DialogMode.Investor);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining dialog mode: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [isMounted, authContext, marketListingViewModel]);

  // load data required for issuer/investor for either buy/sell
  const [fetchingRequiredData, setFetchingRequiredData] = useState(true);
  const [indicativePrice, setIndicativePrice] = useState<
    IndicativePrice | undefined
  >(props.indicativePrice);
  const [listing, setListing] = useState<Listing | undefined>(undefined);
  const [assetIssuanceTokenViewModel, setAssetIssuanceTokenViewModel] =
    useState<LedgerTokenViewModel | undefined>(undefined);
  const [assetValuationTokenViewModel, setAssetValuationTokenViewModel] =
    useState<LedgerTokenViewModel | undefined>(undefined);
  const [marketMechanismQuoteParameter, setMarketMechanismQuoteParameter] =
    useState<QuoteParameter | undefined>(undefined);

  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    if (dialogMode === DialogMode.Determining) {
      return;
    }
    (async () => {
      if (isMounted()) {
        setFetchingRequiredData(true);
      }
      if (!marketListingViewModel) {
        return;
      }

      try {
        // prepare required data variables
        let retrievedIndicativePrice!: IndicativePrice;
        let retrievedListing!: Listing;
        let retrievedMarketMechanismQuoteParameter!: QuoteParameter;
        let retrievedAssetIssuanceTokenViewModel!: LedgerTokenViewModel;
        let retrievedAssetValuationTokenViewModel!: LedgerTokenViewModel;

        await Promise.all([
          // retrieve latest indicative price
          (async () => {
            const response =
              await IndicativePriceRepository.SearchIndicativePrice({
                context: authContext,
                criteria: {
                  "token.code": TextExactCriterion(
                    marketListingViewModel.token.code,
                  ),
                  "token.issuer": TextExactCriterion(
                    marketListingViewModel.token.issuer,
                  ),
                  "token.network": TextExactCriterion(
                    marketListingViewModel.token.network,
                  ),
                },
                query: new Query({
                  limit: 1,
                  offset: 0,
                  sorting: [NewSorting("timeOfPrice", "desc")],
                }),
              });

            // confirm  1 record was retrieved
            if (response.records.length !== 1) {
              console.error("expected 1 indicative price");
              return;
            }

            retrievedIndicativePrice = response.records[0];
          })(),

          // retrieve listing
          (async () => {
            retrievedListing = (
              await ListingRepository.RetrieveListing({
                context: authContext,
                identifier: IDIdentifier(marketListingViewModel.listingID),
              })
            ).listing;
          })(),

          // retrieve asset issuance and valuation tokens
          (async () => {
            retrievedAssetIssuanceTokenViewModel =
              await getLedgerTokenViewModel(marketListingViewModel.token);
          })(),
          (async () => {
            // set the marketmechanism quote parameter
            for (const m of marketListingViewModel.listingMarketMechanisms) {
              if (
                m.type === MechanismType.DirectOrder &&
                m.quoteParameters.length === 1
              ) {
                retrievedMarketMechanismQuoteParameter = m.quoteParameters[0];
              }
            }
            if (!retrievedMarketMechanismQuoteParameter) {
              return;
            }

            retrievedAssetValuationTokenViewModel =
              await getLedgerTokenViewModel(
                retrievedMarketMechanismQuoteParameter.quoteToken,
              );
          })(),
        ]);

        // confirm all data set as required
        if (
          !(
            retrievedIndicativePrice &&
            retrievedListing &&
            retrievedAssetIssuanceTokenViewModel &&
            retrievedAssetValuationTokenViewModel &&
            retrievedMarketMechanismQuoteParameter
          )
        ) {
          console.error("something has gone wrong");
          if (isMounted()) {
            props.closeDialog();
          }
          return;
        }

        // set required values
        if (isMounted()) {
          setIndicativePrice(retrievedIndicativePrice);
          setListing(retrievedListing);
          setAssetIssuanceTokenViewModel(retrievedAssetIssuanceTokenViewModel);
          setAssetValuationTokenViewModel(
            retrievedAssetValuationTokenViewModel,
          );
          setMarketMechanismQuoteParameter(
            retrievedMarketMechanismQuoteParameter,
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error initialising: ${err.message ? err.message : err.toString()}`,
        );
        if (isMounted()) {
          props.closeDialog();
          return;
        }
      }
      if (isMounted()) {
        setFetchingRequiredData(false);
      }
    })();
  }, [
    isMounted,
    dialogMode,
    marketListingViewModel,
    authContext,
    getLedgerTokenViewModel,
  ]);

  const showLoadingSplash = useMemo(
    () => dialogMode === DialogMode.Determining || fetchingRequiredData,
    [dialogMode, fetchingRequiredData],
  );

  return (
    <Dialog
      PaperProps={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (Paper props typescript type does not allow for data- attributes)
        "data-component-info": JSON.stringify({
          component_id: "asset_card",
          component_business_name: "asset_card",
          component_title: props.marketListingViewModel?.assetName,
          component_driver: InteractionDriver.DriveTransaction,
        } as DataComponentInfo),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (Paper props typescript type does not allow for data- attributes)
        "data-component-transaction": JSON.stringify({
          transaction_asset_buy_price: props.indicativePrice
            ? props.indicativePrice.buyPrice.value.toString()
            : "-",
          transaction_asset_sell_price: props.indicativePrice
            ? props.indicativePrice.sellPrice.value.toString()
            : "-",
          transaction_asset_id: props.marketListingViewModel
            ? `${props.marketListingViewModel.token.code}:${props.marketListingViewModel.token.issuer}:${props.marketListingViewModel.token.network}`
            : "-",
          transaction_asset_name: props.marketListingViewModel
            ? props.marketListingViewModel.assetName
            : "-",
          transaction_asset_issuer: props.marketListingViewModel
            ? props.marketListingViewModel.assetOwnerClientShortName
            : "-",
          transaction_asset_type: props.marketListingViewModel
            ? props.marketListingViewModel.assetType
            : "-",
          transaction_asset_investor_profile: props.marketListingViewModel
            ? props.marketListingViewModel.instrumentRiskProfile
            : "-",
          transaction_asset_currency: props.indicativePrice
            ? props.indicativePrice.token.code.toString()
            : "-",
        } as DataComponentTransaction),
      }}
      open
      fullScreen={isMobile}
    >
      {showLoadingSplash ? (
        <>
          <Header
            {...{
              ...props,
              marketListingViewModel,
            }}
          />
          <LoadingDialogContent />
        </>
      ) : dialogMode === DialogMode.Investor ? (
        marketListingViewModel &&
        indicativePrice &&
        listing &&
        assetIssuanceTokenViewModel &&
        assetValuationTokenViewModel &&
        marketMechanismQuoteParameter && (
          <InvestorDirectOrderCard
            {...{
              ...props,
              marketListingViewModel,
              indicativePrice,
            }}
            listing={listing}
            assetIssuanceTokenViewModel={assetIssuanceTokenViewModel}
            assetValuationTokenViewModel={assetValuationTokenViewModel}
            marketMechanismQuoteParameter={marketMechanismQuoteParameter}
          />
        )
      ) : (
        marketListingViewModel &&
        indicativePrice &&
        listing &&
        assetIssuanceTokenViewModel &&
        assetValuationTokenViewModel &&
        marketMechanismQuoteParameter && (
          <IssuerDirectOrderCard
            {...{
              ...props,
              marketListingViewModel,
              indicativePrice,
            }}
            listing={listing}
            assetIssuanceTokenViewModel={assetIssuanceTokenViewModel}
            assetValuationTokenViewModel={assetValuationTokenViewModel}
            marketMechanismQuoteParameter={marketMechanismQuoteParameter}
          />
        )
      )}
    </Dialog>
  );
}
