import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Asset, NewAsset } from "./Asset";

export interface FetchAssetRequest {
  context: Context;
  identifier: Identifier;
}

export interface FetchAssetResponse {
  asset: Asset;
}

export const AssetFetcherServiceProviderName = "ledger-AssetFetcher";

export const AssetFetcher = {
  async FetchAsset(request: FetchAssetRequest): Promise<FetchAssetResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${AssetFetcherServiceProviderName}.FetchAsset`,
      request,
    });
    return { asset: NewAsset(response.asset) };
  },
};
