import { IndustryClassification } from "./index";
import { CompanyRepresentative } from "../index";
import { CompanyPublicContactInfo } from "../CompanyPublicContactInfo";

export class Profile {
  public companyName = "";

  public industry: IndustryClassification | undefined = undefined;

  public profilePictureData = "";

  public profilePictureMIMEType = "";

  public companyRepresentative: CompanyRepresentative | undefined = undefined;
  public publicContactInfo: CompanyPublicContactInfo =
    new CompanyPublicContactInfo();

  constructor(profile?: Profile) {
    if (!profile) {
      return;
    }
    this.companyName = profile.companyName;
    if (profile.industry) {
      this.industry = new IndustryClassification(profile.industry);
    }
    this.profilePictureData = profile.profilePictureData;
    this.profilePictureMIMEType = profile.profilePictureMIMEType;
    if (profile.companyRepresentative) {
      this.companyRepresentative = new CompanyRepresentative(
        profile.companyRepresentative,
      );
    }
    this.publicContactInfo = new CompanyPublicContactInfo(
      profile.publicContactInfo,
    );
  }
}
