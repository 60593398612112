"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allScheduleConfigurationTypes = void 0;
exports.scheduleConfigurationTypeToString = scheduleConfigurationTypeToString;
exports.stringToScheduleConfigurationType = stringToScheduleConfigurationType;
const scheduleConfigurationType_pb_1 = require("./scheduleConfigurationType_pb");
// Get all schedule configuration types as enum values
exports.allScheduleConfigurationTypes = Object.values(scheduleConfigurationType_pb_1.ScheduleConfigurationType).filter((value) => typeof value === "number");
// Define explicit mappings between ScheduleConfigurationType enums and custom string representations
const scheduleConfigurationTypeToStringMapping = {
    [scheduleConfigurationType_pb_1.ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE]: "-",
    [scheduleConfigurationType_pb_1.ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE]: "Perpetual",
    [scheduleConfigurationType_pb_1.ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE]: "Non-Perpetual",
};
// Reverse mapping from string to ScheduleConfigurationType enum
const stringToScheduleConfigurationTypeMapping = {};
for (const [key, value] of Object.entries(scheduleConfigurationTypeToStringMapping)) {
    stringToScheduleConfigurationTypeMapping[value] = Number(key);
}
class UnsupportedScheduleConfigurationTypeError extends Error {
    constructor(scheduleConfigurationType) {
        const message = `Unsupported ScheduleConfigurationType: ${scheduleConfigurationType}`;
        super(message);
        this.scheduleConfigurationType = scheduleConfigurationType;
    }
}
/**
 * Converts a ScheduleConfigurationType enum instance to a custom string representation.
 * @param {ScheduleConfigurationType} scheduleConfigurationType - The schedule configuration type to convert.
 * @returns {string} The custom string representation of the schedule configuration type.
 */
function scheduleConfigurationTypeToString(scheduleConfigurationType) {
    if (scheduleConfigurationType in scheduleConfigurationTypeToStringMapping) {
        return scheduleConfigurationTypeToStringMapping[scheduleConfigurationType];
    }
    else {
        throw new UnsupportedScheduleConfigurationTypeError(scheduleConfigurationType);
    }
}
class UnsupportedScheduleConfigurationTypeStringError extends Error {
    constructor(scheduleConfigurationTypeStr) {
        const message = `Unsupported schedule configuration type string: ${scheduleConfigurationTypeStr}`;
        super(message);
        this.scheduleConfigurationTypeStr = scheduleConfigurationTypeStr;
    }
}
/**
 * Converts a custom string representation to a ScheduleConfigurationType enum instance.
 * @param {string} scheduleConfigurationTypeStr - The custom string representation of the schedule configuration type.
 * @returns {ScheduleConfigurationType} The corresponding ScheduleConfigurationType enum instance.
 */
function stringToScheduleConfigurationType(scheduleConfigurationTypeStr) {
    if (scheduleConfigurationTypeStr in stringToScheduleConfigurationTypeMapping) {
        return stringToScheduleConfigurationTypeMapping[scheduleConfigurationTypeStr];
    }
    else {
        throw new UnsupportedScheduleConfigurationTypeStringError(scheduleConfigurationTypeStr);
    }
}
