import { useCallback, useRef } from "react";
import { v4 as uuidV4 } from "uuid";

/**
 * useCurrentAPICall can be used to monitor and abort the execution of an API.
 * Use as follows:
 *
 * 1. Invoke hook in body of functional component
 *    (replacing Service with the name of API method being called):
 *
 *       const [
 *         isCurrentServiceAPICall,
 *         initServiceAPICall,
 *       ] = useCurrentAPICall();
 *
 * 2. Before calling API initialise API call:
 *
 *     const { apiCallID, abortController } = initServiceAPICall();
 *
 *    Repeated calls to the above will call abort on the abortController returned
 *    by a prior invocation of initServiceAPICall()
 *
 * 3.  Pass abortController signal to underlying fetch:
 *
 *       const response = await ServiceProvider.Service(
 *         request,
 *         { signal: abortController.signal }
 *       );
 *
 *     Where ServiceProvider.Service is written as:
 *
 *       async Service(
 *         request: Request,
 *         jsonRPCRequestOpts?: JSONRPCRequestOpts
 *       ): Promise<Response> { ..... }
 *
 * 4. After API call, use isCurrentServiceAPICall and apiCallID to check if execution
 *    is still current (typically paired with isMounted() check):
 *
 *       if (isMounted() && isCurrentServiceAPICall(apiCallID)) {
 *         setState(response);
 *       }
 *
 *     Check for abortions in catch that surrounds API call:
 *
 *      try {
 *        ...
 *      } catch (e: any) {
 *         if (e instanceof CallAbortedError) {
 *           return;
 *         }
 *         // handle other errors
 *      }
 *
 * Examples:
 * - useCalculateDirectOrderFee in src/james/remuneration/DirectOrderFeeCalculator.ts
 * - use of DirectOrderFeeCalculator in src/views/Marketplace/components/PlaceOrderDialog/components/InvestorDirectOrderCard/BuyCard.tsx
 */
export const useCurrentAPICall: () => [
  (apiCallID: string) => boolean,
  () => { apiCallID: string; abortController: AbortController },
] = () => {
  const currentAPICallRef = useRef<{
    abortController: AbortController;
    apiCallID: string;
  }>({
    abortController: new AbortController(),
    apiCallID: "",
  });
  const isCurrentAPICall = useCallback(
    (apiCallID: string) => currentAPICallRef.current.apiCallID === apiCallID,
    [],
  );
  const initAPICall = useCallback(() => {
    // abort any ongoing API call
    currentAPICallRef.current.abortController.abort();

    // prepare an ID and abort controller for this API
    const abortController = new AbortController();
    const apiCallID = uuidV4();

    // register api
    currentAPICallRef.current = {
      abortController,
      apiCallID,
    };

    // return api ID and abort controller
    return {
      apiCallID,
      abortController,
    };
  }, []);

  return [isCurrentAPICall, initAPICall];
};
