import { PopoverProps } from "@mui/material/Popover";
import { RouteType } from "routes/Route";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Divider,
  Link,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ClientKind } from "james/client/Client";
import { useApplicationContext } from "context/Application/Application";
import { useAppTourContext } from "context/AppTour/AppTour";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

interface ProfileMenuProps {
  anchorElProfile: PopoverProps["anchorEl"];
  onClose: () => void;
  userProfileRoute?: RouteType;
  clientProfileRoute?: RouteType;
}

export function ProfileMenu(props: ProfileMenuProps) {
  const { loginClaims, logout } = useApplicationContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { registerElement, tourInProgress } = useAppTourContext();
  const { viewConfiguration } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Menu
        id="mainAppBar-profile-menu"
        anchorEl={props.anchorElProfile}
        keepMounted
        open={Boolean(props.anchorElProfile)}
        onClose={props.onClose}
        transitionDuration={tourInProgress ? 0 : "auto"}
        autoFocus={false}
        sx={{
          ".MuiMenuItem-root": {
            py: 0,
            height: 36,
          },
        }}
      >
        {
          //  Todo hide show client profile option when you are on the market place. Because of design update needed
          props.clientProfileRoute &&
            (location.pathname.includes("/market-management") ||
              location.pathname.includes("/builder") ||
              location.pathname.includes("/administration")) && (
              <MenuItem
                id="mainAppBar-userProfile-menuItem"
                data-link-info={JSON.stringify({
                  content_interaction_id: "nav-link",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "profile:user profile",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.Navigation,
                } as DataLinkInfoType)}
                onClick={() => {
                  if (props.userProfileRoute) {
                    navigate(props.userProfileRoute.path);
                  }
                  props.onClose();
                }}
                selected={
                  props.userProfileRoute &&
                  location.pathname.includes(props.userProfileRoute.path)
                }
              >
                <Typography
                  data-link-info={JSON.stringify({
                    content_interaction_id: "nav-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "profile:user profile",
                    content_interaction_type: InteractionType.Link,
                    content_interaction_driver: InteractionDriver.Navigation,
                  } as DataLinkInfoType)}
                >
                  My profile
                </Typography>
              </MenuItem>
            )
        }
        {isMobile && (
          <MenuItem
            component={Link}
            id="mainAppBar-freshWorkd-menuItem"
            target="_blank"
            href={"https://support.mesh.trade/support/home"}
          >
            Need Help?
          </MenuItem>
        )}
        {
          // Todo hide show client profile option when you are on the market place. Because of Design update needed
          props.clientProfileRoute &&
            (location.pathname.includes("/market-management") ||
              location.pathname.includes("/builder") ||
              location.pathname.includes("/administration")) && (
              <MenuItem
                id="mainAppBar-companyProfile-menuItem"
                onClick={() => {
                  if (props.clientProfileRoute) {
                    navigate(props.clientProfileRoute.path);
                  }
                  props.onClose();
                }}
                selected={location.pathname.includes(
                  props.clientProfileRoute.path,
                )}
              >
                Company Profile
              </MenuItem>
            )
        }
        {loginClaims.clientKind === ClientKind.IndividualType && (
          <MenuItem
            id="mainAppBar-kyc-menuItem"
            onClick={() => {
              navigate("/kyc");
            }}
            data-link-info={JSON.stringify({
              content_interaction_id: "nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "profile: identity verification",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
            selected={location.pathname.includes("/kyc")}
          >
            <Typography
              ref={registerElement("mainAppBar-identityVerification-menuItem")}
              data-link-info={JSON.stringify({
                content_interaction_id: "nav-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "profile: identity verification",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Identity Verification
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          id="mainAppBar-userProfile-menuItem"
          data-link-info={JSON.stringify({
            content_interaction_id: "nav-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "profile: user profile",
            content_interaction_type: InteractionType.Link,
            content_interaction_driver: InteractionDriver.Navigation,
          } as DataLinkInfoType)}
          onClick={() => navigate("/user-profile")}
          sx={{ height: "31px" }}
        >
          <Typography
            data-link-info={JSON.stringify({
              content_interaction_id: "nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "profile: user profile",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
          >
            User Profile
          </Typography>
        </MenuItem>
        {viewConfiguration.Accounts["DownloadAccountStatement"] && (
          <MenuItem
            id="mainAppBar-account-statements-menuItem"
            data-link-info={JSON.stringify({
              content_interaction_id: "nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
            onClick={() => {
              navigate("/accounts?focusStatement=true");
            }}
            selected={location.pathname.includes("/accounts")}
          >
            <Typography
              data-link-info={JSON.stringify({
                content_interaction_id: "nav-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Statements
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          id="mainAppBar-logout-menuItem"
          data-link-info={JSON.stringify({
            content_interaction_id: "nav-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "profile:logout",
            content_interaction_type: InteractionType.Link,
            content_interaction_driver: InteractionDriver.Navigation,
          } as DataLinkInfoType)}
          onClick={async () => {
            await logout();
            navigate("/sign-in");
          }}
          selected={location.pathname.includes("/sign-in")}
        >
          <Typography
            data-link-info={JSON.stringify({
              content_interaction_id: "nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "profile:logout",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Navigation,
            } as DataLinkInfoType)}
          >
            Logout
          </Typography>
        </MenuItem>
        {isMobile && (
          <>
            <Divider />
            <Typography sx={{ fontWeight: "bold", px: 2, mt: 2 }}>
              Web Resources
            </Typography>
            <MenuItem
              target="_blank"
              component={Link}
              href={"https://mesh.trade/services/fees"}
            >
              Fees
            </MenuItem>
            <MenuItem
              target="_blank"
              component={Link}
              href={"https://mesh.trade/services/trading-and-settlement-rules"}
            >
              Trading & Settlement Rules
            </MenuItem>
            <MenuItem
              target="_blank"
              component={Link}
              href={"https://mesh.trade/services"}
            >
              Mesh Services
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}
