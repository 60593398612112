"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withGRPCBackend = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable @typescript-eslint/no-explicit-any */
const react_1 = require("react");
const query_pb_1 = require("@mesh/common-js/dist/search/query_pb");
const Criteria_1 = require("@mesh/common-js/dist/search/Criteria");
const withGRPCBackend = (Table) => (_a) => {
    var { requestFunction, columns, interval, title, filters, height, criteria, query, sx, data, total, loading } = _a, props = __rest(_a, ["requestFunction", "columns", "interval", "title", "filters", "height", "criteria", "query", "sx", "data", "total", "loading"]);
    const [_data, setData] = (0, react_1.useState)(data !== null && data !== void 0 ? data : []);
    const [_total, setTotal] = (0, react_1.useState)(total !== null && total !== void 0 ? total : 0);
    const [pages, setPages] = (0, react_1.useState)([0, 0]);
    const [currentCriteria, setCriteria] = (0, react_1.useState)(new Criteria_1.Criteria(criteria.criteriaMap));
    const [currentQuery, setQuery] = (0, react_1.useState)(query !== null && query !== void 0 ? query : new query_pb_1.Query());
    const [currentInterval, setInterval] = (0, react_1.useState)(interval);
    const [_loading, setLoading] = (0, react_1.useState)(true);
    const [fetchingData, setFetchingData] = (0, react_1.useState)(false);
    const fetchData = (criteria, query, page) => __awaiter(void 0, void 0, void 0, function* () {
        setFetchingData(true);
        try {
            const response = yield requestFunction({
                criteria: criteria,
                query: query
                    .setOffset(page ? currentInterval * pages[1] : 0)
                    .setLimit(currentInterval),
            });
            setFetchingData(false);
            setLoading(false);
            return response;
        }
        catch (error) {
            console.error(error);
            setLoading(false);
            setFetchingData(false);
        }
        return {
            models: [],
            total: 0,
        };
    });
    (0, react_1.useEffect)(() => setInterval(interval), [interval]);
    // page
    (0, react_1.useEffect)(() => {
        if (!currentCriteria ||
            pages[0] >= pages[1] ||
            _data.length >= _total ||
            loading)
            return;
        const timeOutRef = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield fetchData(currentCriteria, currentQuery, true);
            setData(_data.concat(response.models));
            setTotal(response.total);
        }));
        return () => clearTimeout(timeOutRef);
    }, [pages]);
    // criteria
    (0, react_1.useEffect)(() => {
        if (!currentCriteria)
            return;
        const timeOutRef = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            setData([]);
            setPages([0, 0]);
            const response = yield fetchData(currentCriteria, query !== null && query !== void 0 ? query : currentQuery);
            setData(response.models);
            setTotal(response.total);
        }));
        return () => clearTimeout(timeOutRef);
    }, [currentCriteria]);
    const refresh = () => __awaiter(void 0, void 0, void 0, function* () {
        if (loading || _loading)
            return;
        setData([]);
        setPages([0, 0]);
        const response = yield fetchData(currentCriteria, query !== null && query !== void 0 ? query : new query_pb_1.Query());
        setData(response.models);
        setTotal(response.total);
        setQuery(query !== null && query !== void 0 ? query : new query_pb_1.Query());
    });
    return ((0, jsx_runtime_1.jsx)(Table, Object.assign({}, props, { tableContainerClassName: "meshScroll", fetchingData: fetchingData, loading: loading || _loading || fetchingData, sx: sx, data: _data, columns: columns, title: title, filters: filters ? filters(setCriteria, setQuery) : undefined, height: height, total: _total, refresh: refresh, _page: pages[1], onPageChange: (prevPage, page) => {
            setPages([prevPage, page]);
        } })));
};
exports.withGRPCBackend = withGRPCBackend;
