"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allDateGenerationRules = void 0;
exports.dateGenerationRuleToString = dateGenerationRuleToString;
exports.stringToDateGenerationRule = stringToDateGenerationRule;
const dateGenerationRule_pb_1 = require("./dateGenerationRule_pb");
// Get all date generation rule as enum values
exports.allDateGenerationRules = Object.values(dateGenerationRule_pb_1.DateGenerationRule).filter((value) => typeof value === "number");
// Define explicit mappings between DateGenerationRule enums and custom string representations
const dateGenerationRuleToStringMapping = {
    [dateGenerationRule_pb_1.DateGenerationRule.UNDEFINED_DATE_GENERATION_RULE]: "-",
    [dateGenerationRule_pb_1.DateGenerationRule.BACKWARD_DATE_GENERATION_RULE]: "Backward",
    [dateGenerationRule_pb_1.DateGenerationRule.FORWARD_DATE_GENERATION_RULE]: "Forward",
    [dateGenerationRule_pb_1.DateGenerationRule.ZERO_DATE_GENERATION_RULE]: "Zero",
    [dateGenerationRule_pb_1.DateGenerationRule.THIRD_WEDNESDAY_DATE_GENERATION_RULE]: "3rd Wednesday",
    [dateGenerationRule_pb_1.DateGenerationRule.THIRD_WEDNESDAY_INCLUSIVE_DATE_GENERATION_RULE]: "3rd Wednesday Incl.",
    [dateGenerationRule_pb_1.DateGenerationRule.TWENTIETH_DATE_GENERATION_RULE]: "20th",
    [dateGenerationRule_pb_1.DateGenerationRule.TWENTIETH_IMM_DATE_GENERATION_RULE]: "20th IMM",
    [dateGenerationRule_pb_1.DateGenerationRule.OLD_CDS_DATE_GENERATION_RULE]: "Old CDS",
    [dateGenerationRule_pb_1.DateGenerationRule.CDS_DATE_GENERATION_RULE]: "CDS",
    [dateGenerationRule_pb_1.DateGenerationRule.CDS2015_DATE_GENERATION_RULE]: "CDS 2015",
};
// Reverse mapping from string to DateGenerationRule enum
const stringToDateGenerationRuleMapping = {};
for (const [key, value] of Object.entries(dateGenerationRuleToStringMapping)) {
    stringToDateGenerationRuleMapping[value] = Number(key);
}
class UnsupportedDateGenerationRuleError extends Error {
    constructor(dateGenerationRule) {
        const message = `Unsupported DateGenerationRule: ${dateGenerationRule}`;
        super(message);
        this.dateGenerationRule = dateGenerationRule;
    }
}
/**
 * Converts a DateGenerationRule enum instance to a custom string representation.
 * @param {DateGenerationRule} dateGenerationRule - The date generation rule to convert.
 * @returns {string} The custom string representation of the date generation rule.
 */
function dateGenerationRuleToString(dateGenerationRule) {
    if (dateGenerationRule in dateGenerationRuleToStringMapping) {
        return dateGenerationRuleToStringMapping[dateGenerationRule];
    }
    else {
        throw new UnsupportedDateGenerationRuleError(dateGenerationRule);
    }
}
class UnsupportedDateGenerationRuleStringError extends Error {
    constructor(dateGenerationRuleStr) {
        const message = `Unsupported date generation rule string: ${dateGenerationRuleStr}`;
        super(message);
        this.dateGenerationRuleStr = dateGenerationRuleStr;
    }
}
/**
 * Converts a custom string representation to a DateGenerationRule enum instance.
 * @param {string} dateGenerationRuleStr - The custom string representation of the date generation rule.
 * @returns {DateGenerationRule} The corresponding DateGenerationRule enum instance.
 */
function stringToDateGenerationRule(dateGenerationRuleStr) {
    if (dateGenerationRuleStr in stringToDateGenerationRuleMapping) {
        return stringToDateGenerationRuleMapping[dateGenerationRuleStr];
    }
    else {
        throw new UnsupportedDateGenerationRuleStringError(dateGenerationRuleStr);
    }
}
