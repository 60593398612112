import React from "react";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";

export type DateFieldProps = {
  id?: string;
  value: Dayjs | string | null;

  /**
   * @deprecated
   * use {@link DatePickerProps.slotProps} instead
   * @example
   *  slotProps={{
        textField: {
          sx: { width: "100%" },
          id: "ID",
          error: !!formDataValidationResult.fieldValidations.fieldOne,
          helperText: formDataValidationResult.fieldValidations.fieldOne,
        },
      }}
   */
  renderInput?: (textFieldProps: MUITextFieldProps) => JSX.Element | Element;
  nullable?: boolean;
} & Omit<DatePickerProps<Dayjs> & React.RefAttributes<HTMLDivElement>, "value">;

export function DateField(props: DateFieldProps) {
  // delete props.renderInput;

  if (props.readOnly) {
    return (
      <MUITextField
        label={props.label}
        value={props.value ? dayjs(props.value).format("MM/DD/YYYY") : "-"}
        variant="standard"
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    );
  }

  return (
    <DatePicker
      {...props}
      value={props.value ? dayjs(props.value) : null}
      slotProps={{
        ...props.slotProps,
        actionBar: {
          actions: props.nullable
            ? ["clear", "cancel", "accept"]
            : ["cancel", "accept"],
        },
      }}
    />
  );
}
