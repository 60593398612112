import { AssetHolderLookup } from "@mesh/common-js/dist/ledger/assetHolderLookup_pb";
import { Refresh, FileCopy as CopyPasteIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { BPTable } from "components/Table";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { Query } from "james/search/query";
import { ReactNode } from "react";
import { useOwnershipContext } from "views/Ledger/Ownership/components/Context";
import { AssetHolderLookupStateChip } from "./AssetHolderLookupStateChip";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { useSnackbar } from "notistack";

export type OwnershipTableProps = {
  title?: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constantCriteria?: any;
  height?: number;
  collapsible?: boolean;
  initialCollapsedState?: boolean;
  divTable?: boolean;
  initialDenseTable?: boolean;
};

export const OwnershipTable = (props: OwnershipTableProps) => {
  const {
    loading,
    assetHolderLookups,
    numAssetHolderLookups,
    query,
    setQuery,
    reload,
  } = useOwnershipContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <BPTable
      collapsible={props.collapsible}
      initialDenseTable={props.initialDenseTable}
      initialCollapsedState={props.initialCollapsedState}
      divTable={props.divTable}
      title={props.title ?? "Ownership"}
      loading={loading}
      height={
        props.height
          ? props.height - noticeBannerHeight
          : 100 - noticeBannerHeight
      }
      query={Query.fromFutureQuery(query)}
      onQueryChange={(query) => {
        setQuery(query.toFutureQuery());
      }}
      data={assetHolderLookups ?? []}
      totalNoRecords={numAssetHolderLookups}
      disableSelect
      columns={[
        {
          label: "ID",
          field: "id",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={(data as AssetHolderLookup).getId()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText((data as AssetHolderLookup).getId())
                      .then(() => enqueueSnackbar("ID copied"))
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Date",
          field: "date",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return protobufTimestampToDayjs(
              (data as AssetHolderLookup).getDate() ?? new Timestamp(),
            ).format(DateTimeFormatWithOffset);
          },
        },
        {
          label: "Token",
          field: "token",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (data as AssetHolderLookup).getToken()?.getCode();
          },
        },
        {
          label: "Lookup Status",
          field: "state",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const state = (data as AssetHolderLookup).getState();
            return <AssetHolderLookupStateChip state={state} />;
          },
        },
        {
          label: "Lookup Reference",
          field: "lookupreference",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (data as AssetHolderLookup).getLookupreference();
          },
        },
        {
          label: "Ledger Reference",
          field: "ledgerreference",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (data as AssetHolderLookup).getLedgerreference();
          },
        },
      ]}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        controls.push(
          <IconButton onClick={reload}>
            <Refresh />
          </IconButton>,
        );

        return controls;
      })()}
    />
  );
};
