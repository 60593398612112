import React from "react";
import { Box, Card, Typography, useTheme, useMediaQuery } from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import meshArtBlockChain from "assets/images/howDoesItWork/meshArtBlockChain.png";
import key from "assets/images/howDoesItWork/key.png";
import { ContentType } from "./HowDoesItWorkContentProvider";

type BlockChainSectionType = {
  benefits: ContentType[];
  account: ContentType[];
  description: ContentType[];
  benefitList: ContentType[];
};

export const BlockChainSection = ({ section }: HowDoesItWorkSectionProps) => {
  const theme = useTheme();
  const content = section.content as BlockChainSectionType;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          sm: "row",
          xs: "column",
        },
        m: {
          sm: 5,
        },
        mb: {
          sm: 6,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          maxWidth: {
            sm: "540px",
          },
          p: {
            xs: 3,
            sm: 0,
          },
        }}
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
          {content.description[0].title}
        </Typography>
        {content.description[0].info?.map((v: string, i: number) => {
          return (
            <Typography
              key={i}
              children={v}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          );
        })}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 2,
            mb: 4,
          }}
        >
          <Box
            component={"img"}
            sx={{
              width: {
                sm: "384px",
                xs: "274px",
              },
            }}
            src={meshArtBlockChain}
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: "16px",
          }}
        >
          {content.benefits[0].title}
        </Typography>
        <Box
          sx={{
            pt: 1,
            pb: 2,
          }}
        >
          {content.benefits[0].info?.map((v, i) => {
            return (
              <Typography
                key={i}
                sx={{
                  mt: 1,
                  color: theme.palette.custom.lavender,
                }}
                children={v}
              />
            );
          })}
        </Box>

        <StyledList
          items={content.benefitList.map((v) => {
            return v.info?.join(" ") ?? "";
          })}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            px: 3,
            pb: 3,
          },
        }}
      >
        <Box
          sx={{
            height: {
              sm: "467px",
            },
            ml: {
              sm: 5,
            },
            position: "relative",
          }}
        >
          {/* funky border handle */}
          <Box
            sx={{
              position: "absolute",
              borderTopLeftRadius: "4px",
              [theme.breakpoints.up("sm")]: {
                borderBottomLeftRadius: "4px",
                top: "67px",
                bottom: "67px",
                left: "-4px",
              },
              [theme.breakpoints.down("sm")]: {
                borderTopRightRadius: "4px",
                top: "-4px",
                right: "24px",
                left: "24px",
              },
              border: `2px solid ${theme.palette.secondary.main}`,
            }}
          />
          <Card
            sx={{
              width: {
                sm: "228px",
                xs: "100%",
              },
              height: { sm: "467px" },
              backgroundColor: theme.palette.custom.grapeLight,
              px: 3,
              pt: 2,
              pb: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  sm: "flex-start",
                },
              }}
            >
              <Box
                component="img"
                src={key}
                sx={{
                  height: "110px",
                  width: "110px",
                }}
              />
            </Box>
            <Typography
              sx={{
                mb: 2,
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {content.account[0].title}
            </Typography>
            {content.account[0].info?.map((v, i) => {
              return (
                <Typography
                  key={i}
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "14px",
                    mb: 1,
                  }}
                  children={v}
                />
              );
            })}
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

interface StyledListProps {
  items: string[];
}

const StyledList = ({ items }: StyledListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {items.map((item, i) => {
        return item !== "" ? (
          <Box
            key={i}
            sx={{
              display: "flex",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "30px",
                height: "100%",
              }}
            >
              {isMobile ? (
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "8px",
                    backgroundColor: theme.palette.secondary.main,
                    mt: 1,
                    mr: 2,
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                    fontSize: "20px",
                    mt: 0.5,
                    mr: 2,
                  }}
                >
                  {i + 1}.
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                maxWidth: {
                  sm: "500px",
                  xs: "272px",
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                {item}
              </Typography>
            </Box>
          </Box>
        ) : (
          <></>
        );
      })}
    </>
  );
};
