import { LedgerNetwork } from "pkgTemp/ledger/Network";
import { StellarAccount, StellarAccountTypeName } from "pkgTemp/stellar";

export enum LedgerAccountCategory {
  Issuance = "Issuance",
  Trading = "Trading",
  Operational = "Operational",
  MarketInfrastructure = "Market Infrastructure",
  Fee = "Fee",
  Clearance = "Clearance",
  System = "System",
}

export enum LedgerAccountState {
  OpeningAccountState = "Opening",
  Open = "Open",
  Closed = "Closed",
  UnderInvestigationAccountState = "Under Investigation",
}

export interface Account {
  ["@type"]: string;

  accountID(): string;

  accountOwnerID(): string;

  accountCategory(): LedgerAccountCategory | "";

  accountState(): LedgerAccountState | "";

  accountLedgerNetwork(): LedgerNetwork | "";
}

export function NewAccount(account: Account): Account {
  switch (account["@type"]) {
    case StellarAccountTypeName:
      return new StellarAccount(account as StellarAccount);
    default:
      throw new TypeError(`unknown account type: ${account["@type"]}`);
  }
}
