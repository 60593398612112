import dayjs from "dayjs";
import { Document } from "james/document";
import { Instrument } from "./Instrument";
import { AuditEntry } from "../audit";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { RatingAgency } from "./RatingAgency";
import { FinancialInstrumentState } from "./InstrumentState";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import * as InstrumentManagementFee from "./InstrumentManagementFee";
import { CurrencyAmountInstrumentManagementFee } from "./InstrumentManagementFeeCurrencyAmount";

export const ETNTypeName = "mesh::financial/ETN";

export enum ETNState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllETNStates: ETNState[] = [
  ETNState.Draft,
  ETNState.PreIssued,
  ETNState.Issued,
  ETNState.Matured,
  ETNState.Cancelled,
  ETNState.Deleted,
];

export enum ETNAction {
  //
  // ETNCreator Actions
  //
  New,

  //
  // ETNStateChanger Actions
  //
  MarkDeleted,
  MarkPreIssued,
  MarkIssued,
  MarkCancelled,
  MarkMatured,

  //
  // ETNUpdater Actions
  //
  DraftUpdate,
  ChangeMaturityDate,
  ChangeHoldings,
  ChangeSectorAllocations,
  ChangeCountryAllocations,
  ChangeAnnualPerformanceLog,
  ChangeSupportingDocuments,
}

export class ETN implements Instrument {
  public ["@type"]: string = ETNTypeName;

  public id = "";

  public ownerID = "";

  public state: ETNState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public name = "";

  public isin = "";

  public exchange = "";

  public exchangeCode = "";

  public assetClass: AssetClassDEPRECATED | "" = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public issuerName = "";

  public issuerRatingAgency: RatingAgency | "" = "";

  public issuerCreditRating = "";

  public issuerManagementFee: InstrumentManagementFee.InstrumentManagementFee =
    new CurrencyAmountInstrumentManagementFee();

  public issuerManagementFeeFrequency: InstrumentManagementFee.InstrumentManagementFeeFrequency =
    InstrumentManagementFee.InstrumentManagementFeeFrequency.Yearly;

  public supportingDocuments: Document[] = [];

  public investorProfile: InvestorProfile | "" = "";

  public investorProfileDescription = "";

  public riskProfile: InstrumentRiskProfile | "" = "";

  public riskProfileDescription = "";

  public holdings: Holding[] = [];

  public sectorAllocations: SectorAllocation[] = [];

  public countryAllocations: CountryAllocation[] = [];

  public annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[] = [];

  constructor(etn?: ETN) {
    if (!etn) {
      return;
    }
    this.id = etn.id;
    this.ownerID = etn.ownerID;
    this.state = etn.state;
    this.auditEntry = new AuditEntry(etn.auditEntry);
    this.name = etn.name;
    this.isin = etn.isin;
    this.exchange = etn.exchange;
    this.exchangeCode = etn.exchangeCode;
    this.assetClass = etn.assetClass;
    this.issueDate = etn.issueDate;
    this.maturityDate = etn.maturityDate;
    this.issuerName = etn.issuerName;
    this.issuerRatingAgency = etn.issuerRatingAgency;
    this.issuerCreditRating = etn.issuerCreditRating;
    this.issuerManagementFee =
      InstrumentManagementFee.NewInstrumentManagementFee(
        etn.issuerManagementFee,
      );
    this.issuerManagementFeeFrequency = etn.issuerManagementFeeFrequency;
    this.supportingDocuments = etn.supportingDocuments.map(
      (d) => new Document(d),
    );
    this.investorProfile = etn.investorProfile;
    this.investorProfileDescription = etn.investorProfileDescription;
    this.riskProfile = etn.riskProfile;
    this.riskProfileDescription = etn.riskProfileDescription;
    this.holdings = etn.holdings.map((h) => new Holding(h));
    this.sectorAllocations = etn.sectorAllocations.map(
      (s) => new SectorAllocation(s),
    );
    this.countryAllocations = etn.countryAllocations.map(
      (c) => new CountryAllocation(c),
    );
    this.annualPerformanceLog = etn.annualPerformanceLog.map(
      (ple) => new InstrumentAnnualPerformanceLogEntry(ple),
    );
  }

  instrumentID(): string {
    return this.id;
  }

  instrumentState(): FinancialInstrumentState {
    return this.state;
  }

  instrumentOwnerID(): string {
    return this.ownerID;
  }

  instrumentIssueDate(): string {
    return this.issueDate;
  }

  instrumentMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentName(): string {
    return this.name;
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    return this.assetClass;
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    return this.riskProfile;
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    return this.annualPerformanceLog;
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    return this.investorProfile;
  }

  instrumentSupportingDocuments(): Document[] {
    return this.supportingDocuments;
  }
}

export function getPotentialNextETNActions(
  etnState: ETNState | "",
): ETNAction[] {
  switch (etnState) {
    case ETNState.Draft: {
      return [
        ETNAction.MarkDeleted,
        ETNAction.DraftUpdate,
        ETNAction.MarkPreIssued,
        ETNAction.MarkIssued,
      ];
    }

    case ETNState.PreIssued: {
      return [
        ETNAction.MarkIssued,
        ETNAction.MarkCancelled,
        ETNAction.ChangeMaturityDate,
        ETNAction.ChangeHoldings,
        ETNAction.ChangeSectorAllocations,
        ETNAction.ChangeCountryAllocations,
        ETNAction.ChangeAnnualPerformanceLog,
        ETNAction.ChangeSupportingDocuments,
      ];
    }

    case ETNState.Issued:
      return [
        ETNAction.MarkCancelled,
        ETNAction.ChangeHoldings,
        ETNAction.MarkMatured,
        ETNAction.ChangeSectorAllocations,
        ETNAction.ChangeCountryAllocations,
        ETNAction.ChangeAnnualPerformanceLog,
        ETNAction.ChangeSupportingDocuments,
      ];

    case ETNState.Matured:
      return [];

    case ETNState.Cancelled:
      return [];

    case ETNState.Deleted:
      return [];

    default:
      throw new TypeError(`invalid ETNState: '${etnState}'`);
  }
}
