import { ETNRollCorporateAction } from "james/financial/ETNRollCorporateAction";
import { DownloadableDocument } from "james/document/DownloadableDocument";

export const ModelTypeName =
  "mesh::financialETNRollETNRollCorporateActionView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  public instrumentName = "";
  public corporateAction = new ETNRollCorporateAction();
  public documents: DownloadableDocument[] = [];

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.corporateAction = new ETNRollCorporateAction(model.corporateAction);
    this.documents = model.documents.map((d) => new DownloadableDocument(d));
    this.instrumentName = model.instrumentName;
  }
}
