import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";

export interface FullUpdateRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetCriteria: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FullUpdateResponse {}

export const Updater = {
  serviceProvider: "ledgerTokenView-Updater",
  async FullUpdate(request: FullUpdateRequest): Promise<FullUpdateResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.FullUpdate`,
      request,
    });
  },
};
