export class PaaSAgreement {
  public version = "";

  public pdfURL = "";

  public latest = "";

  public acceptanceRequired = false;

  constructor(obj?: PaaSAgreement) {
    if (!obj) {
      return;
    }

    this.version = obj.version;
    this.acceptanceRequired = obj.acceptanceRequired;
    this.latest = obj.latest;
    this.pdfURL = obj.pdfURL;
  }
}
