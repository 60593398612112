import { CompanyRepresentative } from "james/legal";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import { ValidateEmail } from "utilities/validation";

export const FormValidator = (field: string, value: string): string[] => {
  const errorMessages: string[] = [];
  if (field === "emailAddress") {
    if (String(value) !== "" && !ValidateEmail(String(value))) {
      errorMessages.push("Email is invalid");
    }
  }
  return errorMessages;
};

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  values: CompanyRepresentative,
  setFormFieldValid?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let InvalidFormFields = {};
  if (values.firstName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      firstName: "Name not provided",
    };
  }

  if (values.lastName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      lastName: "Last name not provided",
    };
  }

  if (values.emailAddress === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      emailAddress: "Email Address is required",
    };
  }

  if (values.physicalAddress) {
    if (values.physicalAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        physicalAddressAddressLine1: "Address line required",
      };
    }

    if (values.physicalAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        physicalAddressCity: "City required",
      };
    }

    if (values.physicalAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        physicalAddressCountryCode: "Country code is required",
      };
    }
  }

  if (values.emailAddress !== "" && !ValidateEmail(values.emailAddress)) {
    InvalidFormFields = {
      ...InvalidFormFields,
      emailAddress: "Email Address is invalid",
    };
  }

  if (
    values.postalAddress &&
    !isEqual(new Address(values.postalAddress), new Address())
  ) {
    if (values.postalAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        postalAddressAddressLine1: "Address line missing",
      };
    }

    if (values.postalAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        postalAddressCity: "City is missing",
      };
    }

    if (values.postalAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        postalAddressCountryCode: "Country code is missing",
      };
    }
  }

  if (setFormFieldValid) {
    setFormFieldValid(InvalidFormFields);
  }
  return !Object.keys(InvalidFormFields).length;
};
