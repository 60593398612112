import { Context } from "james/security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export interface SetupIndividualClientProfileRequest {
  context: Context;
  firstName: string;
  lastName: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetupIndividualClientProfileResponse {}

export interface SetupCompanyClientProfileRequest {
  context: Context;
  firstName: string;
  lastName: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetupCompanyClientProfileResponse {}

export const ClientProfileAdministratorServiceProviderName =
  "onboarding-ClientProfileAdministrator";

export const ClientProfileAdministrator = {
  async SetupIndividualClientProfile(
    request: SetupIndividualClientProfileRequest,
    additionalHeaders?: Headers,
  ): Promise<SetupIndividualClientProfileResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${ClientProfileAdministratorServiceProviderName}.SetupIndividualClientProfile`,
        request,
      },
      {
        additionalHeaders,
      },
    );
  },
  async SetupCompanyClientProfile(
    request: SetupCompanyClientProfileRequest,
    additionalHeaders?: Headers,
  ): Promise<SetupCompanyClientProfileResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${ClientProfileAdministratorServiceProviderName}.SetupCompanyClientProfile`,
        request,
      },
      {
        additionalHeaders,
      },
    );
  },
};
