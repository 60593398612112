// source: financial/fiatCurrencyCode.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.FiatCurrencyCode', null, global);
/**
 * @enum {number}
 */
proto.financial.FiatCurrencyCode = {
  UNDEFINED_FIAT_CURRENCY_CODE: 0,
  BARBADOS_DOLLAR_BBD_FIAT_CURRENCY_CODE: 1,
  CFA_FRANC_BEAC_XAF_FIAT_CURRENCY_CODE: 2,
  AUSTRALIAN_DOLLAR_AUD_FIAT_CURRENCY_CODE: 3,
  US_DOLLAR_USD_FIAT_CURRENCY_CODE: 4,
  EURO_EUR_FIAT_CURRENCY_CODE: 5,
  ZLOTY_PLN_FIAT_CURRENCY_CODE: 6,
  DOBRA_STN_FIAT_CURRENCY_CODE: 8,
  NORWEGIAN_KRONE_NOK_FIAT_CURRENCY_CODE: 9,
  BOND_MARKETS_UNIT_EUROPEAN_COMPOSITE_UNIT_EURCO_XBA_FIAT_CURRENCY_CODE: 10,
  BOND_MARKETS_UNIT_EUROPEAN_MONETARY_UNIT_EMU6_XBB_FIAT_CURRENCY_CODE: 11,
  ANDORRAN_PESETA_ADP_FIAT_CURRENCY_CODE: 12,
  GUINEABISSAU_PESO_GWP_FIAT_CURRENCY_CODE: 13,
  ITALIAN_LIRA_ITL_FIAT_CURRENCY_CODE: 14,
  IRISH_POUND_IEP_FIAT_CURRENCY_CODE: 15,
  OLD_SHEKEL_ILR_FIAT_CURRENCY_CODE: 16,
  LUXEMBOURG_CONVERTIBLE_FRANC_LUC_FIAT_CURRENCY_CODE: 17,
  LUXEMBOURG_FINANCIAL_FRANC_LUL_FIAT_CURRENCY_CODE: 18,
  OLD_LEU_ROL_FIAT_CURRENCY_CODE: 19,
  RUSSIAN_RUBLE_RUR_FIAT_CURRENCY_CODE: 20,
  BOLIVAR_FUERTE_VEF_FIAT_CURRENCY_CODE: 21,
  ZIMBABWE_DOLLAR_NEW_ZWN_FIAT_CURRENCY_CODE: 22,
  DANISH_KRONE_DKK_FIAT_CURRENCY_CODE: 24,
  SOL_PEN_FIAT_CURRENCY_CODE: 26,
  EAST_CARIBBEAN_DOLLAR_XCD_FIAT_CURRENCY_CODE: 28,
  SUCRE_XSU_FIAT_CURRENCY_CODE: 29,
  TUNISIAN_DINAR_TND_FIAT_CURRENCY_CODE: 30,
  VATU_VUV_FIAT_CURRENCY_CODE: 32,
  INTI_PEI_FIAT_CURRENCY_CODE: 33,
  ZLOTY_PLZ_FIAT_CURRENCY_CODE: 34,
  SPANISH_PESETA_ESA_FIAT_CURRENCY_CODE: 35,
  BOLIVIANO_BOB_FIAT_CURRENCY_CODE: 38,
  POUND_STERLING_GBP_FIAT_CURRENCY_CODE: 40,
  NORTH_KOREAN_WON_KPW_FIAT_CURRENCY_CODE: 41,
  MALAGASY_ARIARY_MGA_FIAT_CURRENCY_CODE: 43,
  RIAL_OMANI_OMR_FIAT_CURRENCY_CODE: 44,
  RWANDA_FRANC_RWF_FIAT_CURRENCY_CODE: 45,
  LEONE_SLL_FIAT_CURRENCY_CODE: 47,
  SINGAPORE_DOLLAR_SGD_FIAT_CURRENCY_CODE: 48,
  CFP_FRANC_XPF_FIAT_CURRENCY_CODE: 50,
  NEW_ROMANIAN_LEU_RON_FIAT_CURRENCY_CODE: 51,
  FINANCIAL_RAND_ZAL_FIAT_CURRENCY_CODE: 52,
  SPANISH_PESETA_ESP_FIAT_CURRENCY_CODE: 53,
  BOLIVAR_VEB_FIAT_CURRENCY_CODE: 54,
  OLD_DONG_VNC_FIAT_CURRENCY_CODE: 55,
  NGULTRUM_BTN_FIAT_CURRENCY_CODE: 56,
  YUAN_RENMINBI_CNY_FIAT_CURRENCY_CODE: 57,
  EL_SALVADOR_COLON_SVC_FIAT_CURRENCY_CODE: 58,
  HONG_KONG_DOLLAR_HKD_FIAT_CURRENCY_CODE: 60,
  LOTI_LSL_FIAT_CURRENCY_CODE: 61,
  MAURITIUS_RUPEE_MUR_FIAT_CURRENCY_CODE: 62,
  RAND_ZAR_FIAT_CURRENCY_CODE: 63,
  NEW_ZEALAND_DOLLAR_NZD_FIAT_CURRENCY_CODE: 64,
  GOLD_XAU_FIAT_CURRENCY_CODE: 65,
  CROATIAN_KUNA_HRK_FIAT_CURRENCY_CODE: 66,
  PATHET_LAO_KIP_LAJ_FIAT_CURRENCY_CODE: 67,
  BULGARIAN_LEV_BGN_FIAT_CURRENCY_CODE: 69,
  LARI_GEL_FIAT_CURRENCY_CODE: 72,
  KENYAN_SHILLING_KES_FIAT_CURRENCY_CODE: 74,
  TUGRIK_MNT_FIAT_CURRENCY_CODE: 75,
  SAINT_HELENA_POUND_SHP_FIAT_CURRENCY_CODE: 76,
  HRYVNIA_UAH_FIAT_CURRENCY_CODE: 77,
  NEW_YUGOSLAVIAN_DINAR_YUD_FIAT_CURRENCY_CODE: 79,
  DJIBOUTI_FRANC_DJF_FIAT_CURRENCY_CODE: 82,
  QUETZAL_GTQ_FIAT_CURRENCY_CODE: 83,
  NETHERLANDS_ANTILLEAN_GUILDER_ANG_FIAT_CURRENCY_CODE: 88,
  KYAT_BUK_FIAT_CURRENCY_CODE: 91,
  FRENCH_FRANC_FRF_FIAT_CURRENCY_CODE: 92,
  MEXICAN_PESO_MXP_FIAT_CURRENCY_CODE: 93,
  NETHERLANDS_GUILDER_NLG_FIAT_CURRENCY_CODE: 94,
  OLD_SHILLING_UGW_FIAT_CURRENCY_CODE: 95,
  YUGOSLAVIAN_DINAR_YUN_FIAT_CURRENCY_CODE: 96,
  BAHRAINI_DINAR_BHD_FIAT_CURRENCY_CODE: 97,
  UNIDAD_DE_FOMENTO_CLF_FIAT_CURRENCY_CODE: 98,
  NAKFA_ERN_FIAT_CURRENCY_CODE: 99,
  GUYANA_DOLLAR_GYD_FIAT_CURRENCY_CODE: 101,
  BALBOA_PAB_FIAT_CURRENCY_CODE: 105,
  CFA_FRANC_BCEAO_XOF_FIAT_CURRENCY_CODE: 106,
  CODES_SPECIFICALLY_RESERVED_FOR_TESTING_PURPOSES_XTS_FIAT_CURRENCY_CODE: 107,
  PESO_ARGENTINO_ARP_FIAT_CURRENCY_CODE: 108,
  TALONAS_LTT_FIAT_CURRENCY_CODE: 110,
  MOZAMBIQUE_METICAL_MZM_FIAT_CURRENCY_CODE: 111,
  PORTUGUESE_ESCUDO_PTE_FIAT_CURRENCY_CODE: 112,
  SERBIAN_DINAR_CSD_FIAT_CURRENCY_CODE: 113,
  ZAMBIAN_KWACHA_ZMK_FIAT_CURRENCY_CODE: 114,
  BELIZE_DOLLAR_BZD_FIAT_CURRENCY_CODE: 115,
  FALKLAND_ISLANDS_POUND_FKP_FIAT_CURRENCY_CODE: 117,
  MEXICAN_UNIDAD_DE_INVERSION_UDI_MXV_FIAT_CURRENCY_CODE: 118,
  SOLOMON_ISLANDS_DOLLAR_SBD_FIAT_CURRENCY_CODE: 120,
  WIR_FRANC_CHW_FIAT_CURRENCY_CODE: 121,
  NEW_TAIWAN_DOLLAR_TWD_FIAT_CURRENCY_CODE: 122,
  TRINIDAD_AND_TOBAGO_DOLLAR_TTD_FIAT_CURRENCY_CODE: 123,
  TURKISH_LIRA_TRY_FIAT_CURRENCY_CODE: 124,
  PESO_URUGUAYO_UYU_FIAT_CURRENCY_CODE: 125,
  CONVERTIBLE_FRANC_BEC_FIAT_CURRENCY_CODE: 127,
  FINANCIAL_FRANC_BEL_FIAT_CURRENCY_CODE: 128,
  DRACHMA_GRD_FIAT_CURRENCY_CODE: 130,
  RUPIAH_IDR_FIAT_CURRENCY_CODE: 132,
  ZAIRE_ZRZ_FIAT_CURRENCY_CODE: 133,
  BELARUSIAN_RUBLE_BYN_FIAT_CURRENCY_CODE: 135,
  ICELAND_KRONA_ISK_FIAT_CURRENCY_CODE: 136,
  LIBERIAN_DOLLAR_LRD_FIAT_CURRENCY_CODE: 137,
  AUSTRAL_ARA_FIAT_CURRENCY_CODE: 141,
  MARK_DER_DDR_DDM_FIAT_CURRENCY_CODE: 142,
  CEDI_GHC_FIAT_CURRENCY_CODE: 143,
  SUDANESE_POUND_SDP_FIAT_CURRENCY_CODE: 146,
  KARBOVANET_UAK_FIAT_CURRENCY_CODE: 147,
  GOURDE_HTG_FIAT_CURRENCY_CODE: 148,
  WON_KRW_FIAT_CURRENCY_CODE: 149,
  QATARI_RIAL_QAR_FIAT_CURRENCY_CODE: 152,
  CORDOBA_NIC_FIAT_CURRENCY_CODE: 155,
  TOLAR_SIT_FIAT_CURRENCY_CODE: 156,
  SUDANESE_POUND_SDG_FIAT_CURRENCY_CODE: 157,
  WIR_FRANC_FOR_ELECTRONIC_CHC_FIAT_CURRENCY_CODE: 158,
  BAHAMIAN_DOLLAR_BSD_FIAT_CURRENCY_CODE: 159,
  LAO_KIP_LAK_FIAT_CURRENCY_CODE: 164,
  MOLDOVAN_LEU_MDL_FIAT_CURRENCY_CODE: 166,
  MOZAMBIQUE_METICAL_MZN_FIAT_CURRENCY_CODE: 167,
  SOUTH_SUDANESE_POUND_SSP_FIAT_CURRENCY_CODE: 168,
  URUGUAY_PESO_EN_UNIDADES_INDEXADAS_UI_UYI_FIAT_CURRENCY_CODE: 169,
  BELARUSIAN_RUBLE_BYR_FIAT_CURRENCY_CODE: 171,
  KRONA_A53_CSJ_FIAT_CURRENCY_CODE: 172,
  GUINEA_ESCUDO_GWE_FIAT_CURRENCY_CODE: 173,
  LUXEMBOURG_FRANC_LUF_FIAT_CURRENCY_CODE: 174,
  KWANZA_AOA_FIAT_CURRENCY_CODE: 175,
  PHILIPPINE_PESO_PHP_FIAT_CURRENCY_CODE: 177,
  TALA_WST_FIAT_CURRENCY_CODE: 178,
  DINAR_BAD_FIAT_CURRENCY_CODE: 179,
  NEW_CRUZADO_BRN_FIAT_CURRENCY_CODE: 180,
  CRUZEIRO_REAL_BRR_FIAT_CURRENCY_CODE: 181,
  CYPRUS_POUND_CYP_FIAT_CURRENCY_CODE: 182,
  SYLI_GNS_FIAT_CURRENCY_CODE: 183,
  LITHUANIAN_LITAS_LTL_FIAT_CURRENCY_CODE: 185,
  MALTESE_POUND_MTP_FIAT_CURRENCY_CODE: 186,
  OLD_URUGUAY_PESO_UYN_FIAT_CURRENCY_CODE: 188,
  ALGERIAN_DINAR_DZD_FIAT_CURRENCY_CODE: 189,
  ARUBAN_FLORIN_AWG_FIAT_CURRENCY_CODE: 190,
  CANADIAN_DOLLAR_CAD_FIAT_CURRENCY_CODE: 191,
  CAYMAN_ISLANDS_DOLLAR_KYD_FIAT_CURRENCY_CODE: 192,
  SYRIAN_POUND_SYP_FIAT_CURRENCY_CODE: 196,
  TANZANIAN_SHILLING_TZS_FIAT_CURRENCY_CODE: 197,
  MARKKA_FIM_FIAT_CURRENCY_CODE: 198,
  MALDIVE_RUPEE_MVQ_FIAT_CURRENCY_CODE: 200,
  ROUBLE_SUR_FIAT_CURRENCY_CODE: 201,
  US_DOLLAR_SAME_DAY_USS_FIAT_CURRENCY_CODE: 202,
  NEW_ZAIRE_ZRN_FIAT_CURRENCY_CODE: 203,
  RIEL_KHR_FIAT_CURRENCY_CODE: 205,
  COSTA_RICAN_COLON_CRC_FIAT_CURRENCY_CODE: 207,
  INDIAN_RUPEE_INR_FIAT_CURRENCY_CODE: 210,
  CORDOBA_ORO_NIO_FIAT_CURRENCY_CODE: 213,
  SRI_LANKA_RUPEE_LKR_FIAT_CURRENCY_CODE: 215,
  SURINAM_DOLLAR_SRD_FIAT_CURRENCY_CODE: 216,
  UNIDAD_PREVISIONAL_UYW_FIAT_CURRENCY_CODE: 218,
  SILVER_XAG_FIAT_CURRENCY_CODE: 220,
  DEUTSCHE_MARK_DEM_FIAT_CURRENCY_CODE: 221,
  UICFRANC_XFU_FIAT_CURRENCY_CODE: 223,
  CABO_VERDE_ESCUDO_CVE_FIAT_CURRENCY_CODE: 225,
  CHILEAN_PESO_CLP_FIAT_CURRENCY_CODE: 227,
  CONGOLESE_FRANC_CDF_FIAT_CURRENCY_CODE: 228,
  PESO_CONVERTIBLE_CUC_FIAT_CURRENCY_CODE: 230,
  WIR_EURO_CHE_FIAT_CURRENCY_CODE: 231,
  BOND_MARKETS_UNIT_EUROPEAN_UNIT_OF_ACCOUNT_17_EUA17_XBD_FIAT_CURRENCY_CODE: 232,
  OLD_LEK_ALK_FIAT_CURRENCY_CODE: 233,
  LEV_A62_BGK_FIAT_CURRENCY_CODE: 234,
  KROON_EEK_FIAT_CURRENCY_CODE: 235,
  GEORGIAN_COUPON_GEK_FIAT_CURRENCY_CODE: 236,
  LATVIAN_RUBLE_LVR_FIAT_CURRENCY_CODE: 237,
  OLD_TURKISH_LIRA_TRL_FIAT_CURRENCY_CODE: 238,
  AZERBAIJAN_MANAT_AZN_FIAT_CURRENCY_CODE: 239,
  CZECH_KORUNA_CZK_FIAT_CURRENCY_CODE: 240,
  UGANDA_SHILLING_UGX_FIAT_CURRENCY_CODE: 241,
  US_DOLLAR_NEXT_DAY_USN_FIAT_CURRENCY_CODE: 242,
  PLATINUM_XPT_FIAT_CURRENCY_CODE: 243,
  CRUZEIRO_BRB_FIAT_CURRENCY_CODE: 244,
  LEV_BGL_FIAT_CURRENCY_CODE: 245,
  LOTI_LSM_FIAT_CURRENCY_CODE: 246,
  SOL_PEH_FIAT_CURRENCY_CODE: 247,
  URUGUAYAN_PESO_UYP_FIAT_CURRENCY_CODE: 248,
  RINET_FUNDS_CODE_XRE_FIAT_CURRENCY_CODE: 249,
  BERMUDIAN_DOLLAR_BMD_FIAT_CURRENCY_CODE: 250,
  MVDOL_BOV_FIAT_CURRENCY_CODE: 251,
  PULA_BWP_FIAT_CURRENCY_CODE: 252,
  BURUNDI_FRANC_BIF_FIAT_CURRENCY_CODE: 253,
  DOMINICAN_PESO_DOP_FIAT_CURRENCY_CODE: 254,
  TENGE_KZT_FIAT_CURRENCY_CODE: 257,
  SERBIAN_DINAR_RSD_FIAT_CURRENCY_CODE: 258,
  ZAMBIAN_KWACHA_ZMW_FIAT_CURRENCY_CODE: 259,
  BELGIAN_FRANC_BEF_FIAT_CURRENCY_CODE: 260,
  CRUZADO_BRC_FIAT_CURRENCY_CODE: 261,
  KORUNA_CSK_FIAT_CURRENCY_CODE: 262,
  EKWELE_GQE_FIAT_CURRENCY_CODE: 263,
  GHANA_CEDI_GHP_FIAT_CURRENCY_CODE: 264,
  OLD_KRONA_ISJ_FIAT_CURRENCY_CODE: 265,
  LATVIAN_LATS_LVL_FIAT_CURRENCY_CODE: 266,
  TIMOR_ESCUDO_TPE_FIAT_CURRENCY_CODE: 269,
  UGANDA_SHILLING_UGS_FIAT_CURRENCY_CODE: 270,
  GOLDFRANC_XFO_FIAT_CURRENCY_CODE: 271,
  AFGHANI_AFN_FIAT_CURRENCY_CODE: 272,
  COMORIAN_FRANC_KMF_FIAT_CURRENCY_CODE: 273,
  DALASI_GMD_FIAT_CURRENCY_CODE: 274,
  IRANIAN_RIAL_IRR_FIAT_CURRENCY_CODE: 275,
  NEW_ISRAELI_SHEQEL_ILS_FIAT_CURRENCY_CODE: 276,
  SOM_KGS_FIAT_CURRENCY_CODE: 277,
  NEPALESE_RUPEE_NPR_FIAT_CURRENCY_CODE: 278,
  SAUDI_RIYAL_SAR_FIAT_CURRENCY_CODE: 279,
  BOLIVAR_SOBERANO_VES_FIAT_CURRENCY_CODE: 280,
  THE_CODES_ASSIGNED_FOR_TRANSACTIONS_WHERE_NO_CURRENCY_IS_INVOLVED_XXX_FIAT_CURRENCY_CODE: 281,
  CROATIAN_DINAR_HRD_FIAT_CURRENCY_CODE: 283,
  LEU_A52_ROK_FIAT_CURRENCY_CODE: 285,
  LEK_ALL_FIAT_CURRENCY_CODE: 286,
  GUINEAN_FRANC_GNF_FIAT_CURRENCY_CODE: 291,
  JORDANIAN_DINAR_JOD_FIAT_CURRENCY_CODE: 292,
  MALAWI_KWACHA_MWK_FIAT_CURRENCY_CODE: 294,
  SWEDISH_KRONA_SEK_FIAT_CURRENCY_CODE: 296,
  PALLADIUM_XPD_FIAT_CURRENCY_CODE: 298,
  CRUZEIRO_BRE_FIAT_CURRENCY_CODE: 299,
  UNIDAD_DE_VALOR_CONSTANTE_UVC_ECV_FIAT_CURRENCY_CODE: 300,
  DOBRA_STD_FIAT_CURRENCY_CODE: 302,
  TURKMENISTAN_MANAT_TMM_FIAT_CURRENCY_CODE: 303,
  RUFIYAA_MVR_FIAT_CURRENCY_CODE: 305,
  KINA_PGK_FIAT_CURRENCY_CODE: 307,
  UAE_DIRHAM_AED_FIAT_CURRENCY_CODE: 309,
  UZBEKISTAN_SUM_UZS_FIAT_CURRENCY_CODE: 310,
  AZERBAIJAN_MANAT_AYM_FIAT_CURRENCY_CODE: 311,
  PESO_BOLIVIANO_BOP_FIAT_CURRENCY_CODE: 313,
  LEV_A52_BGJ_FIAT_CURRENCY_CODE: 314,
  SLOVAK_KORUNA_SKK_FIAT_CURRENCY_CODE: 316,
  CUBAN_PESO_CUP_FIAT_CURRENCY_CODE: 319,
  MOROCCAN_DIRHAM_MAD_FIAT_CURRENCY_CODE: 321,
  NAIRA_NGN_FIAT_CURRENCY_CODE: 322,
  AFGHANI_AFA_FIAT_CURRENCY_CODE: 323,
  SUCRE_ECS_FIAT_CURRENCY_CODE: 324,
  SOL_PES_FIAT_CURRENCY_CODE: 326,
  UNIDAD_DE_VALOR_REAL_COU_FIAT_CURRENCY_CODE: 328,
  KUWAITI_DINAR_KWD_FIAT_CURRENCY_CODE: 331,
  LEBANESE_POUND_LBP_FIAT_CURRENCY_CODE: 332,
  SCHILLING_ATS_FIAT_CURRENCY_CODE: 334,
  BELARUSIAN_RUBLE_BYB_FIAT_CURRENCY_CODE: 336,
  ZIMBABWE_DOLLAR_ZWD_FIAT_CURRENCY_CODE: 338,
  ARMENIAN_DRAM_AMD_FIAT_CURRENCY_CODE: 339,
  GIBRALTAR_POUND_GIP_FIAT_CURRENCY_CODE: 341,
  DENAR_MKD_FIAT_CURRENCY_CODE: 342,
  MALAYSIAN_RINGGIT_MYR_FIAT_CURRENCY_CODE: 343,
  MEXICAN_PESO_MXN_FIAT_CURRENCY_CODE: 344,
  RUSSIAN_RUBLE_RUB_FIAT_CURRENCY_CODE: 347,
  SWISS_FRANC_CHF_FIAT_CURRENCY_CODE: 349,
  BAHT_THB_FIAT_CURRENCY_CODE: 350,
  PAANGA_TOP_FIAT_CURRENCY_CODE: 352,
  TURKMENISTAN_NEW_MANAT_TMT_FIAT_CURRENCY_CODE: 353,
  PESO_ARY_FIAT_CURRENCY_CODE: 354,
  EUROPEAN_CURRENCY_UNIT_ECU_XEU_FIAT_CURRENCY_CODE: 355,
  ARGENTINE_PESO_ARS_FIAT_CURRENCY_CODE: 357,
  ETHIOPIAN_BIRR_ETB_FIAT_CURRENCY_CODE: 358,
  SDR_SPECIAL_DRAWING_RIGHT_XDR_FIAT_CURRENCY_CODE: 360,
  IRAQI_DINAR_IQD_FIAT_CURRENCY_CODE: 361,
  ADB_UNIT_OF_ACCOUNT_XUA_FIAT_CURRENCY_CODE: 364,
  NAMIBIA_DOLLAR_NAD_FIAT_CURRENCY_CODE: 366,
  PAKISTAN_RUPEE_PKR_FIAT_CURRENCY_CODE: 367,
  SOMONI_TJS_FIAT_CURRENCY_CODE: 369,
  YEMENI_RIAL_YER_FIAT_CURRENCY_CODE: 370,
  ZIMBABWE_DOLLAR_ZWL_FIAT_CURRENCY_CODE: 371,
  KWANZA_AOK_FIAT_CURRENCY_CODE: 372,
  RHODESIAN_DOLLAR_RHD_FIAT_CURRENCY_CODE: 373,
  NEW_DINAR_YUM_FIAT_CURRENCY_CODE: 375,
  BRUNEI_DOLLAR_BND_FIAT_CURRENCY_CODE: 376,
  GHANA_CEDI_GHS_FIAT_CURRENCY_CODE: 378,
  SOMALI_SHILLING_SOS_FIAT_CURRENCY_CODE: 381,
  ZIMBABWE_DOLLAR_ZWR_FIAT_CURRENCY_CODE: 388,
  CONVERTIBLE_MARK_BAM_FIAT_CURRENCY_CODE: 390,
  COLOMBIAN_PESO_COP_FIAT_CURRENCY_CODE: 391,
  FORINT_HUF_FIAT_CURRENCY_CODE: 394,
  JAMAICAN_DOLLAR_JMD_FIAT_CURRENCY_CODE: 395,
  LIBYAN_DINAR_LYD_FIAT_CURRENCY_CODE: 396,
  BOND_MARKETS_UNIT_EUROPEAN_UNIT_OF_ACCOUNT_9_EUA9_XBC_FIAT_CURRENCY_CODE: 399,
  MALI_FRANC_MLF_FIAT_CURRENCY_CODE: 400,
  SUDANESE_DINAR_SDD_FIAT_CURRENCY_CODE: 401,
  RHODESIAN_DOLLAR_ZWC_FIAT_CURRENCY_CODE: 402,
  TAKA_BDT_FIAT_CURRENCY_CODE: 403,
  BRAZILIAN_REAL_BRL_FIAT_CURRENCY_CODE: 404,
  FIJI_DOLLAR_FJD_FIAT_CURRENCY_CODE: 405,
  LEMPIRA_HNL_FIAT_CURRENCY_CODE: 406,
  OUGUIYA_MRU_FIAT_CURRENCY_CODE: 407,
  GUARANI_PYG_FIAT_CURRENCY_CODE: 410,
  SEYCHELLES_RUPEE_SCR_FIAT_CURRENCY_CODE: 411,
  KWANZA_REAJUSTADO_AOR_FIAT_CURRENCY_CODE: 412,
  AZERBAIJANIAN_MANAT_AZM_FIAT_CURRENCY_CODE: 413,
  SYLI_GNE_FIAT_CURRENCY_CODE: 414,
  POUND_ILP_FIAT_CURRENCY_CODE: 415,
  MALAGASY_FRANC_MGF_FIAT_CURRENCY_CODE: 416,
  A_ACCOUNT_CONVERTIBLE_PESETA_ACCOUNT_ESB_FIAT_CURRENCY_CODE: 418,
  LILANGENI_SZL_FIAT_CURRENCY_CODE: 419,
  TAJIK_RUBLE_TJR_FIAT_CURRENCY_CODE: 420,
  EGYPTIAN_POUND_EGP_FIAT_CURRENCY_CODE: 423,
  YEN_JPY_FIAT_CURRENCY_CODE: 426,
  PATACA_MOP_FIAT_CURRENCY_CODE: 428,
  KYAT_MMK_FIAT_CURRENCY_CODE: 429,
  DONG_VND_FIAT_CURRENCY_CODE: 433,
  NEW_KWANZA_AON_FIAT_CURRENCY_CODE: 434,
  MALTESE_LIRA_MTL_FIAT_CURRENCY_CODE: 435,
  OUGUIYA_MRO_FIAT_CURRENCY_CODE: 436,
  MOZAMBIQUE_ESCUDO_MZE_FIAT_CURRENCY_CODE: 438,
  SURINAM_GUILDER_SRG_FIAT_CURRENCY_CODE: 439,
  YEMENI_DINAR_YDD_FIAT_CURRENCY_CODE: 441
};

goog.object.extend(exports, proto.financial);
