"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allLimitOrderStates = void 0;
exports.limitOrderStateToString = limitOrderStateToString;
exports.stringToLimitOrderState = stringToLimitOrderState;
const limitOrder_pb_1 = require("./limitOrder_pb");
exports.allLimitOrderStates = Object.values(limitOrder_pb_1.LimitOrderState).filter((value) => typeof value === "number");
const limitOrderStateToStringMapping = {
    [limitOrder_pb_1.LimitOrderState.SUBMISSION_IN_PROGRESS]: "Submission In Progress",
    [limitOrder_pb_1.LimitOrderState.SUBMISSION_FAILED]: "Submission Failed",
    [limitOrder_pb_1.LimitOrderState.CANCELLATION_IN_PROGRESS]: "Cancellation In Progress",
    [limitOrder_pb_1.LimitOrderState.CANCELLED]: "Cancelled",
    [limitOrder_pb_1.LimitOrderState.OPEN]: "Open",
    [limitOrder_pb_1.LimitOrderState.COMPLETE]: "Complete",
    [limitOrder_pb_1.LimitOrderState.COMPLETE_IN_PROGRESS]: "Complete In Progress",
};
const stringToLimitOrderStateMapping = {};
for (const [key, value] of Object.entries(limitOrderStateToStringMapping)) {
    stringToLimitOrderStateMapping[value] = Number(key);
}
class UnsupportedLimitOrderStateError extends Error {
    constructor(limitOrderState) {
        const message = `Unsupported LimitOrderState: ${limitOrderState}`;
        super(message);
        this.limitOrderState = limitOrderState;
    }
}
function limitOrderStateToString(limitOrderState) {
    if (limitOrderState in limitOrderStateToStringMapping) {
        return limitOrderStateToStringMapping[limitOrderState];
    }
    else {
        throw new UnsupportedLimitOrderStateError(limitOrderState);
    }
}
class UnsupportedLimitOrderStateStringError extends Error {
    constructor(limitOrderStateStr) {
        const message = `Unsupported limit order type string: ${limitOrderStateStr}`;
        super(message);
        this.limitOrderStateStr = limitOrderStateStr;
    }
}
function stringToLimitOrderState(limitOrderStateStr) {
    if (limitOrderStateStr in stringToLimitOrderStateMapping) {
        return stringToLimitOrderStateMapping[limitOrderStateStr];
    }
    else {
        throw new UnsupportedLimitOrderStateStringError(limitOrderStateStr);
    }
}
