import {
  CryptoCurrency,
  CryptoCurrencyTypeName,
  CurrencyStablecoin,
  CurrencyStablecoinTypeName,
  YieldBearingStablecoin,
  YieldBearingStablecoinTypeName,
  ETFStablecoin,
  ETFStablecoinTypeName,
  ETNStablecoin,
  ETNStablecoinTypeName,
} from "james/financial";
import { Token } from "./Token";

export interface Asset {
  ["@type"]: string;

  assetID(): string;

  assetOwnerID(): string;

  assetToken(): Token;

  assetIssueDate(): string;

  assetName(): string;

  assetShortName(): string;
}

export function NewAsset(i: Asset): Asset {
  switch (i["@type"]) {
    case CryptoCurrencyTypeName:
      return new CryptoCurrency(i as CryptoCurrency);

    case CurrencyStablecoinTypeName:
      return new CurrencyStablecoin(i as CurrencyStablecoin);

    case YieldBearingStablecoinTypeName:
      return new YieldBearingStablecoin(i as YieldBearingStablecoin);

    case ETFStablecoinTypeName:
      return new ETFStablecoin(i as ETFStablecoin);

    case ETNStablecoinTypeName:
      return new ETNStablecoin(i as ETNStablecoin);

    default:
      throw new TypeError(`unknown asset type ${i["@type"]}`);
  }
}
