import React from "react";
import { RouteType } from "routes/Route";
import { AssetOverview } from "views/Marketplace/components/AssetOverview";

//
// NOTE: these are public routes that can be accessed while logged in
// for routes that should not be accessible while logged in see company-registration
// in src/App.tsx
//

export const routes: RouteType[] = [
  {
    name: "Asset Overview",
    id: "public-asset-overview",
    path: "/public/market/asset-overview",
    component: <AssetOverview />,
  },
];
