import {
  allRiskAssessmentInstrumentRiskProfiles,
  allRiskAssessmentInvestorRiskProfiles,
  riskAssessmentInstrumentRiskProfileToString,
  riskAssessmentInvestorRiskProfileToString,
  smartInstrumentAttributeTypeToString,
} from "@mesh/common-js/dist/financial";
import {
  RiskAssessmentSmartInstrumentAttribute,
  RiskAssessmentInstrumentRiskProfile,
  RiskAssessmentInvestorRiskProfile,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeRiskAssessment_pb";
import { Divider, MenuItem, TextareaAutosize, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import { ValidationResult } from "common/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";

export type RiskAssessmentAttributeFormProps = {
  riskAssessmentAttribute: RiskAssessmentSmartInstrumentAttribute;
  onChange: (
    updatedRiskAssessmentAttribute: RiskAssessmentSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const RiskAssessmentAttributeForm = (
  props: RiskAssessmentAttributeFormProps,
) => {
  const theme = useTheme();

  const fieldValidationResult = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.RISK_ASSESSMENT_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ];
  };

  return (
    <Box
      sx={(theme) => ({
        width: 400,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      })}
    >
      <TextField
        id={"riskAssessmentAttributeForm-advisorName-textField"}
        disabled={props.disabled}
        readOnly={props.readOnly}
        fullWidth
        label={"Risk Advisor"}
        value={
          props.readOnly
            ? props.riskAssessmentAttribute.getAdvisorname()
              ? props.riskAssessmentAttribute.getAdvisorname()
              : "-"
            : props.riskAssessmentAttribute.getAdvisorname()
        }
        onChange={(e) =>
          props.onChange(
            props.riskAssessmentAttribute.setAdvisorname(e.target.value),
          )
        }
        error={!!fieldValidationResult("advisorName")}
        helperText={fieldValidationResult("advisorName")}
      />

      <Divider />

      <Box>
        <TextField
          size="small"
          fullWidth
          id={"riskAssessmentAttributeForm-instrumentRiskProfile-selectField"}
          label={"Instrument Risk Profile"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          select
          placeholder="Select..."
          error={!!fieldValidationResult("instrumentRiskProfile")}
          helperText={fieldValidationResult("instrumentRiskProfile")}
          value={props.riskAssessmentAttribute.getInstrumentriskprofile()}
          onChange={(e) => {
            props.onChange(
              props.riskAssessmentAttribute.setInstrumentriskprofile(
                Number(e.target.value) as RiskAssessmentInstrumentRiskProfile,
              ),
            );
          }}
        >
          {allRiskAssessmentInstrumentRiskProfiles.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {riskAssessmentInstrumentRiskProfileToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <Typography variant="body2" color="textSecondary">
          Instrument Risk Profile Description
        </Typography>
        {props.readOnly ? (
          <Typography sx={{ width: 400 }} variant="body1" color="textSecondary">
            {props.readOnly
              ? props.riskAssessmentAttribute.getInstrumentriskprofiledescription()
                ? props.riskAssessmentAttribute.getInstrumentriskprofiledescription()
                : "-"
              : props.riskAssessmentAttribute.getInstrumentriskprofiledescription()}
          </Typography>
        ) : (
          <>
            <TextareaAutosize
              minRows={5}
              maxRows={5}
              value={props.riskAssessmentAttribute.getInstrumentriskprofiledescription()}
              onChange={(e) => {
                let newValue: string = e.target.value;
                if (newValue.length >= 250) {
                  newValue = newValue.slice(0, 250);
                }
                props.onChange(
                  props.riskAssessmentAttribute.setInstrumentriskprofiledescription(
                    newValue,
                  ),
                );
              }}
              placeholder="Add an Instrument Risk Profile Description"
              style={{
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                width: 400,
                maxWidth: 400,
              }}
            />
            {fieldValidationResult("instrumentRiskProfileDescription") ? (
              <Typography variant="body2" color="textSecondary">
                {fieldValidationResult("instrumentRiskProfileDescription")}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                {250 -
                  props.riskAssessmentAttribute.getInstrumentriskprofiledescription()
                    .length}{" "}
                Characters Left
              </Typography>
            )}
          </>
        )}
      </Box>

      <Divider />

      <Box>
        <TextField
          size="small"
          fullWidth
          id={"riskAssessmentAttributeForm-investorRiskProfile-selectField"}
          label={"Investor Risk Profile"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          select
          placeholder="Select..."
          error={!!fieldValidationResult("investorRiskProfile")}
          helperText={fieldValidationResult("investorRiskProfile")}
          value={props.riskAssessmentAttribute.getInvestorriskprofile()}
          onChange={(e) => {
            props.onChange(
              props.riskAssessmentAttribute.setInvestorriskprofile(
                Number(e.target.value) as RiskAssessmentInvestorRiskProfile,
              ),
            );
          }}
        >
          {allRiskAssessmentInvestorRiskProfiles.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {riskAssessmentInvestorRiskProfileToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <Typography variant="body2" color="textSecondary">
          Investor Risk Profile Description
        </Typography>
        {props.readOnly ? (
          <Typography sx={{ width: 400 }} variant="body1" color="textSecondary">
            {props.readOnly
              ? props.riskAssessmentAttribute.getInvestorriskprofiledescription()
                ? props.riskAssessmentAttribute.getInvestorriskprofiledescription()
                : "-"
              : props.riskAssessmentAttribute.getInvestorriskprofiledescription()}
          </Typography>
        ) : (
          <>
            <TextareaAutosize
              minRows={5}
              maxRows={5}
              value={props.riskAssessmentAttribute.getInvestorriskprofiledescription()}
              onChange={(e) => {
                let newValue: string = e.target.value;
                if (newValue.length >= 250) {
                  newValue = newValue.slice(0, 250);
                }
                props.onChange(
                  props.riskAssessmentAttribute.setInvestorriskprofiledescription(
                    newValue,
                  ),
                );
              }}
              placeholder="Add an Investor Risk Profile Description"
              style={{
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                width: 400,
                maxWidth: 400,
              }}
            />
            {fieldValidationResult("investorRiskProfileDescription") ? (
              <Typography variant="body2" color="textSecondary">
                {fieldValidationResult("investorRiskProfileDescription")}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                {250 -
                  props.riskAssessmentAttribute.getInvestorriskprofiledescription()
                    .length}{" "}
                Characters Left
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
