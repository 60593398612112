import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Token } from "james/ledger";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage";

interface RedirectPopUpProps {
  dialogProps: DialogProps;
  redirectURL: string;
  redirectName: string;
  redirectShortName: string;
  redirectToken: Token;
  closeDialog: () => void;
}

export const RedirectPopUp = ({
  dialogProps,
  closeDialog,
  redirectName,
  redirectURL,
  redirectShortName,
  redirectToken,
}: RedirectPopUpProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { clearStore } = useLocalStorage();
  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        ...dialogProps.PaperProps,
      }}
      fullScreen={smDown}
      sx={{
        top: { sm: "0%", xs: "auto" },
      }}
    >
      <DialogTitle
        sx={{
          boxShadow: {
            sm: 0,
            xs: 2,
          },
          height: 52,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography children={"Linked Asset Notice"} />
          <IconButton
            sx={{
              width: 14, // Adjust width as needed
              height: 14, // Adjust height as needed
              alignSelf: `flex-start`,
              justifySelf: "flex-end",
              p: 2,
            }}
            disabled={false}
            onClick={() => {
              closeDialog();
              clearStore();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          px: { sm: 3, xs: 3 },
          pb: { sm: 3, xs: 5 },
          height: "auto",
        }}
      >
        <Typography
          sx={(theme) => ({
            mt: 3,
            mr: { sm: 9, xs: 0 },
            color: theme.palette.text.secondary,
          })}
          variant="body1"
        >
          Before signing up, you received a link to the following asset.
          You&nbsp;can view this asset or, alternatively, explore
          our&nbsp;Marketplace.
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 4,
            alignItems: "center",
          }}
        >
          <IconViewUpload size={48} token={redirectToken} />
          <Box>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: "bold",
              })}
              variant="body1"
            >
              {redirectName}
            </Typography>
            <Typography
              sx={(theme) => ({ color: theme.palette.text.secondary })}
              variant="body2"
            >
              {redirectShortName}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: { sm: 3, xs: 4 },
            display: "flex",
            justifyContent: "flex-end",
            alignItems: { sm: "center", xs: "flex-start" },
            flexDirection: { sm: "row", xs: "column" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: { sm: "50%", xs: "100%" },
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                height: {
                  sm: 36,
                  xs: 48,
                },
              }}
              fullWidth={smDown}
              variant={"outlined"}
              children={"Close"}
              onClick={() => {
                closeDialog();
                clearStore();
              }}
            />
            <Button
              sx={{
                height: {
                  sm: 36,
                  xs: 48,
                },
              }}
              fullWidth={smDown}
              variant="contained"
              color="primary"
              children="View Asset"
              onClick={() => {
                closeDialog();
                navigate(redirectURL);
                clearStore();
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
