"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LedgerAmountField = LedgerAmountField;
const jsx_runtime_1 = require("react/jsx-runtime");
const TextNumField_1 = require("./TextNumField");
const decimal_pb_1 = require("@mesh/common-js/dist/num/decimal_pb");
const material_1 = require("@mui/material");
const ledger_1 = require("@mesh/common-js/dist/ledger");
const token_pb_1 = require("@mesh/common-js/dist/ledger/token_pb");
function LedgerAmountField(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var { reverse, startAdornment, endAdornment, adornmentProps } = _a, props = __rest(_a, ["reverse", "startAdornment", "endAdornment", "adornmentProps"]);
    return ((0, jsx_runtime_1.jsx)(TextNumField_1.TextNumField, Object.assign({}, props, { value: (_c = (_b = props === null || props === void 0 ? void 0 : props.value) === null || _b === void 0 ? void 0 : _b.getValue()) !== null && _c !== void 0 ? _c : new decimal_pb_1.Decimal(), onChange: (newValue) => {
            var _a, _b;
            if (!props.onChange) {
                return;
            }
            props.onChange((0, ledger_1.newAmountFromDecimal)(newValue, (_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.getToken()) !== null && _b !== void 0 ? _b : new token_pb_1.FutureToken()));
        }, InputProps: reverse
            ? {
                endAdornment: endAdornment !== null && endAdornment !== void 0 ? endAdornment : ((0, jsx_runtime_1.jsx)(material_1.InputAdornment, Object.assign({}, adornmentProps, { position: "end", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { children: (_f = (_e = (_d = props === null || props === void 0 ? void 0 : props.value) === null || _d === void 0 ? void 0 : _d.getToken()) === null || _e === void 0 ? void 0 : _e.getCode()) !== null && _f !== void 0 ? _f : "-" }) }))),
            }
            : {
                startAdornment: startAdornment !== null && startAdornment !== void 0 ? startAdornment : ((0, jsx_runtime_1.jsx)(material_1.InputAdornment, Object.assign({}, adornmentProps, { position: "start", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { children: (_j = (_h = (_g = props === null || props === void 0 ? void 0 : props.value) === null || _g === void 0 ? void 0 : _g.getToken()) === null || _h === void 0 ? void 0 : _h.getCode()) !== null && _j !== void 0 ? _j : "-" }) }))),
            } })));
}
