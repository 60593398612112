import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { Identifier } from "james/search/identifier/Identifier";
import { Instrument, NewInstrument } from "./Instrument";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveInstrumentRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveInstrumentResponse {
  instrument: Instrument;
}

export interface SearchInstrumentRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchInstrumentResponse {
  records: Instrument[];
  total: number;
}

export const InstrumentCollection = {
  serviceProvider: "financial-InstrumentCollection",
  async RetrieveInstrument(
    request: RetrieveInstrumentRequest,
  ): Promise<RetrieveInstrumentResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${InstrumentCollection.serviceProvider}.RetrieveInstrument`,
      request,
    });
    return {
      instrument: NewInstrument(response.instrument),
    };
  },
  async SearchInstrument(
    request: SearchInstrumentRequest,
  ): Promise<SearchInstrumentResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${InstrumentCollection.serviceProvider}.SearchInstrument`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map((m: Instrument) => NewInstrument(m)),
    };
  },
};

export function useSearchInstrument(
  initialSearchInstrumentRequest?: SearchInstrumentRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchInstrumentRequest, setSearchInstrumentRequest] =
    useState<SearchInstrumentRequest>(
      initialSearchInstrumentRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchInstrumentResponse, setSearchInstrumentResponse] =
    useState<SearchInstrumentResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchInstrumentResponse(
          await InstrumentCollection.SearchInstrument(searchInstrumentRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for instrument: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for instrument: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchInstrumentRequest, shouldNotExecute]);

  return {
    searchInstrumentRequest,
    setSearchInstrumentRequest,
    searchInstrumentResponse,
    loading,
    error,
  };
}
