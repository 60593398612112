import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export type GetJamesVersionRequest = Record<string, never>;

export type GetJamesVersionResponse = {
  version: string;
};

export const Inspector = {
  serviceProvider: "system-Inspector",
  async GetJamesVersion(
    request: GetJamesVersionRequest,
  ): Promise<GetJamesVersionResponse> {
    return jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${Inspector.serviceProvider}.GetJamesVersion`,
      request,
    });
  },
};
