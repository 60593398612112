"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartInstrumentLegWrapper = void 0;
const smartInstrumentLeg_pb_1 = require("./smartInstrumentLeg_pb");
const smartInstrumentLegType_pb_1 = require("./smartInstrumentLegType_pb");
/**
 * Wrapper class for SmartInstrumentLeg.
 */
class SmartInstrumentLegWrapper {
    /**
     * Constructs a new SmartInstrumentLegWrapper instance.
     * @param {SmartInstrumentLeg} smartInstrumentLeg - The smart instrument leg instance to wrap.
     * @throws {TypeError} If the wrapped smart instrument leg does not have a valid leg set.
     */
    constructor(smartInstrumentLeg) {
        this._smartInstrumentLeg = smartInstrumentLeg !== null && smartInstrumentLeg !== void 0 ? smartInstrumentLeg : new smartInstrumentLeg_pb_1.SmartInstrumentLeg();
    }
    /**
     * Gets the ID of the wrapped smart instrument leg.
     * @returns {string} The ID of the wrapped smart instrument leg.
     * @throws {TypeError} If the wrapped smart instrument leg does not have a valid leg set.
     */
    get id() {
        var _a, _b, _c, _d;
        switch (true) {
            case this._smartInstrumentLeg.hasBulletsmartinstrumentleg():
                return ((_b = (_a = this._smartInstrumentLeg.getBulletsmartinstrumentleg()) === null || _a === void 0 ? void 0 : _a.getId()) !== null && _b !== void 0 ? _b : "");
            case this._smartInstrumentLeg.hasFloatingratesmartinstrumentleg():
                return ((_d = (_c = this._smartInstrumentLeg
                    .getFloatingratesmartinstrumentleg()) === null || _c === void 0 ? void 0 : _c.getId()) !== null && _d !== void 0 ? _d : "");
            default:
                throw new TypeError("The wrapped smart instrument leg does not have a valid leg set");
        }
    }
    /**
     * Gets the type of the wrapped smart instrument leg.
     * @returns {SmartInstrumentLegType} The type of the wrapped smart instrument leg.
     * @throws {TypeError} If the wrapped smart instrument leg does not have a valid leg set.
     */
    get smartInstrumentLegType() {
        switch (true) {
            case this._smartInstrumentLeg.hasBulletsmartinstrumentleg():
                return smartInstrumentLegType_pb_1.SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE;
            case this._smartInstrumentLeg.hasFloatingratesmartinstrumentleg():
                return smartInstrumentLegType_pb_1.SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE;
            default:
                return smartInstrumentLegType_pb_1.SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE;
        }
    }
    /**
     * Gets the name of the wrapped smart instrument leg.
     * @returns {string} The name of the wrapped smart instrument leg.
     * @throws {TypeError} If the wrapped smart instrument leg does not have a valid leg set.
     */
    get name() {
        var _a, _b, _c, _d;
        switch (true) {
            case this._smartInstrumentLeg.hasBulletsmartinstrumentleg():
                return ((_b = (_a = this._smartInstrumentLeg.getBulletsmartinstrumentleg()) === null || _a === void 0 ? void 0 : _a.getName()) !== null && _b !== void 0 ? _b : "");
            case this._smartInstrumentLeg.hasFloatingratesmartinstrumentleg():
                return ((_d = (_c = this._smartInstrumentLeg
                    .getFloatingratesmartinstrumentleg()) === null || _c === void 0 ? void 0 : _c.getName()) !== null && _d !== void 0 ? _d : "");
            default:
                throw new TypeError("The wrapped smart instrument leg does not have a valid leg set");
        }
    }
    /**
     * Sets the name of the wrapped smart instrument leg.
     * @param {string} name - The new name for the wrapped smart instrument leg.
     * @throws {TypeError} If the wrapped smart instrument leg does not have a valid leg set.
     */
    set name(name) {
        var _a, _b;
        switch (true) {
            case this._smartInstrumentLeg.hasBulletsmartinstrumentleg():
                this._smartInstrumentLeg.setBulletsmartinstrumentleg((_a = this._smartInstrumentLeg.getBulletsmartinstrumentleg()) === null || _a === void 0 ? void 0 : _a.setName(name));
                break;
            case this._smartInstrumentLeg.hasFloatingratesmartinstrumentleg():
                this._smartInstrumentLeg.setFloatingratesmartinstrumentleg((_b = this._smartInstrumentLeg
                    .getFloatingratesmartinstrumentleg()) === null || _b === void 0 ? void 0 : _b.setName(name));
                break;
            default:
                throw new TypeError("The wrapped smart instrument leg does not have a valid leg set");
        }
    }
    /**
     * Gets the wrapped smart instrument leg instance.
     * @returns {SmartInstrumentLeg} The wrapped smart instrument leg.
     */
    get smartInstrumentLeg() {
        return this._smartInstrumentLeg;
    }
}
exports.SmartInstrumentLegWrapper = SmartInstrumentLegWrapper;
