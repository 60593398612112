import {
  alpha,
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "../../context/Application/Application";
import { ClientKind } from "../../james/client/Client";

interface WalletDialogKYCPlaceHolderProps {
  fromLocation: string;
}

export function WalletDialogKYCPlaceHolder({
  fromLocation,
}: WalletDialogKYCPlaceHolderProps) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { myClient } = useApplicationContext();

  const handleReviewInfo = () => {
    if (myClient) {
      if (myClient.clientKind === ClientKind.IndividualType) {
        navigate("/kyc");
      } else {
        navigate("/kyb");
      }
    }
  };

  return (
    <>
      {!isMobile && (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: theme.spacing(3, 0, 1, 0),
            width: "100%",
          })}
        >
          <Box
            sx={{
              width: {
                sm: "80%",
                md: "60%",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600 }} variant="h4">
              My Mesh Account
            </Typography>
            <Box
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                padding: theme.spacing(1, 0, 2, 0),
              })}
            >
              <div>
                <Link
                  target={"_blank"}
                  children={"< Back"}
                  underline={"hover"}
                  onClick={() =>
                    navigate(fromLocation, {
                      replace: true,
                    })
                  }
                />
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Link
                  sx={{
                    justifySelf: "end",
                    textDecorationColor: "unset",
                  }}
                  target="_blank"
                  href={"https://support.mesh.trade/support/home"}
                  children={"Need help?"}
                  underline={"hover"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={(theme) => ({
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 2, 9.5, 2),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: theme.spacing(1),
            height: {
              xs: "100%",
              sm: "80%",
              md: "70%",
            },
            width: {
              xs: "100%",
              sm: "80%",
              md: "60%",
            },
            margin: {
              xs: theme.spacing(2, 2, 2, 2),
              sm: theme.spacing(2, 2, 0, 2),
              md: 0,
            },
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            variant="h4"
            sx={(theme) => ({
              fontWeight: "bold",
              color: theme.palette.text.tertiary,
              margin: theme.spacing(2, 0),
            })}
            children={"No Account!"}
          />
          <Typography
            variant="h5"
            sx={(theme) => ({
              width: "236px",
              color: theme.palette.text.secondary,
              textAlign: "center",
            })}
            children={
              "Let's change that. Once your information is verified your account will be ready."
            }
          />
          <Button
            id="accounts-reviewKYCVerification-button"
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              [theme.breakpoints.down("sm")]: {
                height: theme.spacing(6),
                width: theme.spacing(36.5),
                fontSize: "17px",
              },
            })}
            onClick={handleReviewInfo}
          >
            review verification info
          </Button>
        </Box>
      </Box>
    </>
  );
}
