/* eslint-disable @typescript-eslint/no-empty-interface */
import config from "react-global-configuration";
import { Context } from "../security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export type AcceptAUSAgreementRequest = {
  context: Context;
};

export type AcceptAUSAgreementResponse = Record<string, never>;

export type AcceptAUSAndPaaSAgreementRequest = {
  context: Context;
};

export type AcceptAUSAndPaaSAgreementResponse = Record<string, never>;

export const ManagerWrite = {
  serviceProvider: "compliance-ManagerWrite",
  async AcceptAUSAgreement(
    request: AcceptAUSAgreementRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<AcceptAUSAgreementResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${ManagerWrite.serviceProvider}.AcceptAUSAgreement`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async AcceptAUSAndPaaSAgreement(
    request: AcceptAUSAndPaaSAgreementRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<AcceptAUSAndPaaSAgreementResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${ManagerWrite.serviceProvider}.AcceptAUSAndPaaSAgreement`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
};
