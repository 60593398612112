import { AuditEntry } from "james/audit";
import { LedgerNetwork } from "james/ledger/Network";
import { TransactionState } from "james/ledger";
import { Transaction as LedgerTransaction } from "james/ledger/Transaction";

export const TransactionTypeName = "mesh::stellar/Transaction";

export class Transaction implements LedgerTransaction {
  public ["@type"]: string = TransactionTypeName;

  public id = "";

  public network: LedgerNetwork | "" = "";

  public ledgerID = "";

  public data = "";

  public metaData: { [key: string]: string } = {};

  public state: TransactionState | "" = "";

  public stateResolutionCount = 0;

  public innerTransactionSigningKeys: string[] = [];

  public feeBumpEnvelopeSigningKeys: string[] = [];

  public auditEntry: AuditEntry = new AuditEntry();

  public conflictKey = "";

  public sourceAccountBufferOverride = false;

  constructor(transaction?: Transaction) {
    if (!transaction) {
      return;
    }
    this.id = transaction.id;
    this.network = transaction.network;
    this.ledgerID = transaction.ledgerID;
    this.data = transaction.data;
    this.metaData = { ...transaction.metaData };
    this.state = transaction.state;
    this.stateResolutionCount = transaction.stateResolutionCount;
    this.innerTransactionSigningKeys = [
      ...transaction.innerTransactionSigningKeys,
    ];
    this.feeBumpEnvelopeSigningKeys = [
      ...transaction.feeBumpEnvelopeSigningKeys,
    ];
    this.auditEntry = transaction.auditEntry;
    this.conflictKey = transaction.conflictKey;
    this.sourceAccountBufferOverride = transaction.sourceAccountBufferOverride;
  }

  transactionID(): string {
    return this.id;
  }

  transactionAuditEntry(): AuditEntry {
    return this.auditEntry;
  }

  transactionStateResolutionCount(): number {
    return this.stateResolutionCount;
  }

  transactionDescription(): string {
    return this.metaData.description ?? this.metaData.type ?? "Unknown";
  }

  transactionMetaData(): { [p: string]: string } {
    return this.metaData;
  }

  transactionNetwork(): LedgerNetwork | "" {
    return this.network;
  }

  transactionState(): TransactionState | "" {
    return this.state;
  }
}
