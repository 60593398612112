import { AuditEntry } from "../audit";

export const TransactionBatchTypeName = "mesh::ledger/TransactionBatch";

export enum TransactionBatchState {
  Processing = "Processing",
  Failed = "Failed",
  Complete = "Complete",
}

export const AllTransactionBatchStates: TransactionBatchState[] = [
  TransactionBatchState.Processing,
  TransactionBatchState.Failed,
  TransactionBatchState.Complete,
];

export class TransactionBatch {
  public ["@type"]: string = TransactionBatchTypeName;

  public id = "";
  public state: TransactionBatchState | "" = "";
  public transactionQueueIDs: string[] = [];
  public metaData: { [key: string]: string } = {};
  public stateResolutionCount = 0;
  public auditEntry: AuditEntry = new AuditEntry();

  constructor(transactionBatch?: TransactionBatch) {
    if (!transactionBatch) {
      return;
    }
    this.id = transactionBatch.id;
    this.state = transactionBatch.state;
    this.transactionQueueIDs = [...transactionBatch.transactionQueueIDs];
    this.metaData = { ...transactionBatch.metaData };
    this.stateResolutionCount = transactionBatch.stateResolutionCount;
    this.auditEntry = new AuditEntry(transactionBatch.auditEntry);
  }
}
