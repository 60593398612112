import { useEffect } from "react";
import { useExchangeStore } from "../store";
import { Reader } from "james/views/marketListingView";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { TextListCriterion } from "james/search/criterion";
import { MechanismType } from "james/market";

export const useInitialDataFetch = () => {
  const store = useExchangeStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        const response = await Reader.Read({
          context: authContext,
          criteria: {
            // TODO: Change to whatever the exchange mechanism will be
            "listingMarketMechanisms.type": TextListCriterion([
              MechanismType.LimitOrder,
            ]),
          },
        });

        store.setMarketListings(response.models);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving marketlisting details: ${
            err.message ? err.message : err.toString()
          }`,
        );
        errorContextDefaultErrorFeedback(e);
      }
    });

    return () => clearTimeout(timeout);
  }, []);
};
