"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allRiskAssessmentInvestorRiskProfiles = exports.allRiskAssessmentInstrumentRiskProfiles = void 0;
exports.riskAssessmentInstrumentRiskProfileToString = riskAssessmentInstrumentRiskProfileToString;
exports.stringToRiskAssessmentInstrumentRiskProfile = stringToRiskAssessmentInstrumentRiskProfile;
exports.riskAssessmentInvestorRiskProfileToString = riskAssessmentInvestorRiskProfileToString;
exports.stringToRiskAssessmentInvestorRiskProfile = stringToRiskAssessmentInvestorRiskProfile;
const smartInstrumentAttributeRiskAssessment_pb_1 = require("./smartInstrumentAttributeRiskAssessment_pb");
// Get all RiskAssessmentInstrumentRiskProfiles as enum values
exports.allRiskAssessmentInstrumentRiskProfiles = Object.values(smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile).filter((value) => typeof value === "number");
// Define explicit mappings between RiskAssessmentInstrumentRiskProfile enums and custom string representations
const riskAssessmentInstrumentRiskProfileToStringMapping = {
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile.UNDEFINED_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE]: "-",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile.LOW_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE]: "Low",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile.LOW_TO_MEDIUM_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE]: "Low to Medium",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile.MEDIUM_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE]: "Medium",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile.MEDIUM_TO_HIGH_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE]: "Medium to High",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInstrumentRiskProfile.HIGH_RISK_ASSESSMENT_INSTRUMENT_RISK_PROFILE]: "High",
};
// Reverse mapping from string to RiskAssessmentInstrumentRiskProfile enum
const stringToRiskAssessmentInstrumentRiskProfileMapping = {};
for (const [key, value] of Object.entries(riskAssessmentInstrumentRiskProfileToStringMapping)) {
    stringToRiskAssessmentInstrumentRiskProfileMapping[value] = Number(key);
}
class UnsupportedRiskAssessmentInstrumentRiskProfileError extends Error {
    constructor(riskAssessmentInstrumentRiskProfile) {
        const message = `Unsupported RiskAssessmentInstrumentRiskProfile: ${riskAssessmentInstrumentRiskProfile}`;
        super(message);
        this.riskAssessmentInstrumentRiskProfile =
            riskAssessmentInstrumentRiskProfile;
    }
}
/**
 * Converts a RiskAssessmentInstrumentRiskProfile enum instance to a custom string representation.
 * @param {RiskAssessmentInstrumentRiskProfile} riskAssessmentInstrumentRiskProfile - The RiskAssessmentInstrumentRiskProfile to convert.
 * @returns {string} The custom string representation of the RiskAssessmentInstrumentRiskProfile.
 */
function riskAssessmentInstrumentRiskProfileToString(riskAssessmentInstrumentRiskProfile) {
    if (riskAssessmentInstrumentRiskProfile in
        riskAssessmentInstrumentRiskProfileToStringMapping) {
        return riskAssessmentInstrumentRiskProfileToStringMapping[riskAssessmentInstrumentRiskProfile];
    }
    else {
        throw new UnsupportedRiskAssessmentInstrumentRiskProfileError(riskAssessmentInstrumentRiskProfile);
    }
}
class UnsupportedRiskAssessmentInstrumentRiskProfileStringError extends Error {
    constructor(riskAssessmentInstrumentRiskProfileStr) {
        const message = `Unsupported RiskAssessmentInstrumentRiskProfile string: ${riskAssessmentInstrumentRiskProfileStr}`;
        super(message);
        this.riskAssessmentInstrumentRiskProfileStr =
            riskAssessmentInstrumentRiskProfileStr;
    }
}
/**
 * Converts a custom string representation to a RiskAssessmentInstrumentRiskProfile enum instance.
 * @param {string} riskAssessmentInstrumentRiskProfileStr - The custom string representation of the RiskAssessmentInstrumentRiskProfile.
 * @returns {RiskAssessmentInstrumentRiskProfile} The corresponding RiskAssessmentInstrumentRiskProfile enum instance.
 */
function stringToRiskAssessmentInstrumentRiskProfile(riskAssessmentInstrumentRiskProfileStr) {
    if (riskAssessmentInstrumentRiskProfileStr in
        stringToRiskAssessmentInstrumentRiskProfileMapping) {
        return stringToRiskAssessmentInstrumentRiskProfileMapping[riskAssessmentInstrumentRiskProfileStr];
    }
    else {
        throw new UnsupportedRiskAssessmentInstrumentRiskProfileStringError(riskAssessmentInstrumentRiskProfileStr);
    }
}
// Get all RiskAssessmentInvestorRiskProfiles as enum values
exports.allRiskAssessmentInvestorRiskProfiles = Object.values(smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInvestorRiskProfile).filter((value) => typeof value === "number");
// Define explicit mappings between RiskAssessmentInvestorRiskProfile enums and custom string representations
const riskAssessmentInvestorRiskProfileToStringMapping = {
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInvestorRiskProfile.UNDEFINED_RISK_ASSESSMENT_INVETOR_RISK_PROFILE]: "-",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInvestorRiskProfile.CONSERVATIVE_RISK_ASSESSMENT_INVETOR_RISK_PROFILE]: "Conservative",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInvestorRiskProfile.BALANCED_RISK_ASSESSMENT_INVETOR_RISK_PROFILE]: "Balanced",
    [smartInstrumentAttributeRiskAssessment_pb_1.RiskAssessmentInvestorRiskProfile.AGGRESSIVE_RISK_ASSESSMENT_INVETOR_RISK_PROFILE]: "Aggressive",
};
// Reverse mapping from string to RiskAssessmentInvestorRiskProfile enum
const stringToRiskAssessmentInvestorRiskProfileMapping = {};
for (const [key, value] of Object.entries(riskAssessmentInvestorRiskProfileToStringMapping)) {
    stringToRiskAssessmentInvestorRiskProfileMapping[value] = Number(key);
}
class UnsupportedRiskAssessmentInvestorRiskProfileError extends Error {
    constructor(riskAssessmentInvestorRiskProfile) {
        const message = `Unsupported RiskAssessmentInvestorRiskProfile: ${riskAssessmentInvestorRiskProfile}`;
        super(message);
        this.riskAssessmentInvestorRiskProfile = riskAssessmentInvestorRiskProfile;
    }
}
/**
 * Converts a RiskAssessmentInvestorRiskProfile enum instance to a custom string representation.
 * @param {RiskAssessmentInvestorRiskProfile} riskAssessmentInvestorRiskProfile - The RiskAssessmentInvestorRiskProfile to convert.
 * @returns {string} The custom string representation of the RiskAssessmentInvestorRiskProfile.
 */
function riskAssessmentInvestorRiskProfileToString(riskAssessmentInvestorRiskProfile) {
    if (riskAssessmentInvestorRiskProfile in
        riskAssessmentInvestorRiskProfileToStringMapping) {
        return riskAssessmentInvestorRiskProfileToStringMapping[riskAssessmentInvestorRiskProfile];
    }
    else {
        throw new UnsupportedRiskAssessmentInvestorRiskProfileError(riskAssessmentInvestorRiskProfile);
    }
}
class UnsupportedRiskAssessmentInvestorRiskProfileStringError extends Error {
    constructor(riskAssessmentInvestorRiskProfileStr) {
        const message = `Unsupported RiskAssessmentInvestorRiskProfile string: ${riskAssessmentInvestorRiskProfileStr}`;
        super(message);
        this.riskAssessmentInvestorRiskProfileStr =
            riskAssessmentInvestorRiskProfileStr;
    }
}
/**
 * Converts a custom string representation to a RiskAssessmentInvestorRiskProfile enum instance.
 * @param {string} riskAssessmentInvestorRiskProfileStr - The custom string representation of the RiskAssessmentInvestorRiskProfile.
 * @returns {RiskAssessmentInvestorRiskProfile} The corresponding RiskAssessmentInvestorRiskProfile enum instance.
 */
function stringToRiskAssessmentInvestorRiskProfile(riskAssessmentInvestorRiskProfileStr) {
    if (riskAssessmentInvestorRiskProfileStr in
        stringToRiskAssessmentInvestorRiskProfileMapping) {
        return stringToRiskAssessmentInvestorRiskProfileMapping[riskAssessmentInvestorRiskProfileStr];
    }
    else {
        throw new UnsupportedRiskAssessmentInvestorRiskProfileStringError(riskAssessmentInvestorRiskProfileStr);
    }
}
