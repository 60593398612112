import { Card, CardHeader, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import cx from "classnames";
import CardContent from "@mui/material/CardContent";

const PREFIX = "InstrumentBuilderDashboard";

const classes = {
  root: `${PREFIX}-root`,
  textBox: `${PREFIX}-textBox`,
  boldText: `${PREFIX}-boldText`,
  cardRoot: `${PREFIX}-cardRoot`,
  cardBox: `${PREFIX}-cardBox`,
  cardRightMargin: `${PREFIX}-cardRightMargin`,
  cardLeftMargin: `${PREFIX}-cardLeftMargin`,
  instructionRow: `${PREFIX}-instructionRow`,
  typographyCenterText: `${PREFIX}-typographyCenterText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    width: "800",
    flexDirection: "column",
    padding: theme.spacing(6, 16, 6, 16),
  },

  [`& .${classes.textBox}`]: {
    display: "grid",
    gridTemplateRows: "repeat(3,auto)",
    gridTemplateColumns: "585px",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.cardRoot}`]: {
    width: "800px",
    height: "320px",
  },

  [`& .${classes.cardBox}`]: {
    display: "flex",
    width: "800px",
    marginTop: theme.spacing(4),
  },

  [`& .${classes.cardRightMargin}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.cardLeftMargin}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.instructionRow}`]: {
    display: "grid",
    gridTemplateColumns: "24px 693px",
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.typographyCenterText}`]: {
    display: "flex",
    alignItems: "center",
  },
}));

export function InstrumentBuilderDashboard() {
  return (
    <Root className={classes.root}>
      <div className={classes.textBox}>
        <Typography component="p" variant="h1">
          Welcome to the Mesh
          <Typography
            className={classes.boldText}
            component="span"
            color="primary"
            variant="h1"
          >
            {" "}
            Instrument Builder
          </Typography>
        </Typography>
        <Typography variant="body1">
          There’s always something new happening on Mesh. Keep an eye on this
          page for regular updates and news.
        </Typography>
        <Typography variant="body1">
          Create, issue, tokenise and place any financial asset with our
          Instrument Builder. Find out more here
        </Typography>
      </div>
      <div className={classes.cardBox}>
        <Card className={cx(classes.cardRoot, classes.cardRightMargin)}>
          <CardHeader title="How to issue an instrument:" />
          <CardContent>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                1.
              </Typography>
              <Typography
                className={classes.typographyCenterText}
                color="textSecondary"
                variant="body1"
              >
                Select Instruments from the side panel.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={cx(classes.boldText, classes.typographyCenterText)}
                variant="h2"
                color="textSecondary"
              >
                2.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Select Build New and prompts will lead you to complete all the
                fields required in order to build up an instrument. Take note of
                the fields that are mandatory - without them you won’t be able
                to continue.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={cx(classes.boldText, classes.typographyCenterText)}
                variant="h2"
                color="textSecondary"
              >
                3.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                You can Save your work at any time and return to complete the
                process at a later stage. When you are satisfied with all the
                information you have entered, click on the Issue button.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={cx(classes.boldText, classes.typographyCenterText)}
                variant="h2"
                color="textSecondary"
              >
                4.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                You can send your instrument to the Marketplace (by checking the
                Placement checkbox), at the time of issuing it. The system will
                prompt you to make a decision on this when you issue your
                instrument. If you do not want to place the instrument at the
                time of issuing, leave the Placement checkbox unchecked.
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </Root>
  );
}
