import { Context } from "james/security";
import { Token } from "./Token";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { IDIdentifierType } from "james/search/identifier/ID";

export interface GenerateOwnershipCertificateRequest {
  context: Context;
  assetToken: Token;
  holdingAccountID: IDIdentifierType;
}

export interface GenerateOwnershipCertificateResponse {
  certificate: Blob;
}

export const OwnershipCertificateGeneratorCoordinatorServiceProviderName =
  "ledger-OwnershipCertificateGeneratorCoordinator";

export const OwnershipCertificateGeneratorCoordinator = {
  async GenerateOwnershipCertificate(
    request: GenerateOwnershipCertificateRequest,
  ): Promise<GenerateOwnershipCertificateResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${OwnershipCertificateGeneratorCoordinatorServiceProviderName}.CoordinateOwnershipCertificateGeneration`,
      request,
    });
    const raw = atob(response.ownershipCertificate);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i += 1) {
      array[i] = raw.charCodeAt(i);
    }
    const blob = new Blob([array], {
      type: "application/pdf",
    });
    return { certificate: blob };
  },
};
