import { Context } from "james/security";
import { Query } from "james/search/query";
import { useEffect, useRef, useState } from "react";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Model } from "./Model";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface ReadRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadResponse {
  models: Model[];
  total: number;
}

export const Reader = {
  serviceProvider: "marketDirectOrderView-Reader",
  async Read(request: ReadRequest): Promise<ReadResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${Reader.serviceProvider}.Read`,
      request,
    });
    return {
      models: response.models.map((m: Model) => new Model(m)),
      total: response.total,
    };
  },
};

export function useRead(
  initialReadRequest?: ReadRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [readRequest, setReadRequest] = useState<ReadRequest>(
    initialReadRequest || {
      context: authContext,
      query: new Query(),
      criteria: {},
    },
  );
  const [readResponse, setReadResponse] = useState<ReadResponse>({
    total: 0,
    models: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        setReadResponse(await Reader.Read(readRequest));
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading market direct order view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error reading market direct order view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [readRequest, shouldNotExecute]);

  return {
    readRequest,
    setReadRequest,
    readResponse,
    loading,
    error,
  };
}
