import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { BPTable } from "components/Table";
import { useClientRepositorySearchClients } from "james/client/Repository";
import {
  Autocomplete,
  Chip,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import {
  AllClientStatuses,
  AllClientTypes,
  Client,
  ClientStatus,
  ClientType,
} from "james/client";
import dayjs from "dayjs";
import {
  DateRangeCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { TextSubstringCriterionType } from "james/search/criterion/text/Substring";
import { TextListCriterionType } from "james/search/criterion/text/List";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { Cancel as CancelIcon, Close as ClearIcon } from "@mui/icons-material";
import { Query } from "james/search/query";
import { DateField } from "components/FormFields/DateField";
import { useApplicationContext } from "context/Application/Application";
import { ClientKind } from "james/client/Client";
import { TextExactCriterion } from "james/search/criterion/text/Exact";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "Companies";

const classes = {
  filterField: `${PREFIX}-filterField`,
  dateFilterLayout: `${PREFIX}-dateFilterLayout`,
  clearIcon: `${PREFIX}-clearIcon`,
  chip: `${PREFIX}-chip`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.filterField}`]: {
    minWidth: 150,
  },

  [`&.${classes.dateFilterLayout}`]: {
    width: 410,
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.clearIcon}`]: {
    marginRight: -6,
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.chip}`]: {
    margin: "2px",
  },
}));

const initialQuery = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

export function Companies() {
  const { authContext } = useApplicationContext();
  const {
    searchClientsResponse,
    setSearchClientsRequest,
    searchClientsRequest,
    loading,
  } = useClientRepositorySearchClients({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });

  const [nameShortNameCriterion, setNameShortNameCriterion] = useState<
    TextSubstringCriterionType | undefined
  >(undefined);
  const [clientTypesCriterion, setClientTypesCriterion] = useState<
    TextListCriterionType | undefined
  >(undefined);
  const [clientStatusCriterion, setClientStatusCriterion] = useState<
    TextListCriterionType | undefined
  >(undefined);
  const [registeredKYCCriterionFrom, setRegisteredKYCCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [registeredKYCCriterionTo, setRegisteredKYCCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const criteria: any = {};
    if (
      nameShortNameCriterion &&
      (registeredKYCCriterionFrom || registeredKYCCriterionTo)
    ) {
      criteria.$and = [
        {
          $or: [
            { name: nameShortNameCriterion },
            { shortName: nameShortNameCriterion },
          ],
        },
        {
          $or: [
            {
              kycVerificationDate: DateRangeCriterion(
                registeredKYCCriterionFrom,
                registeredKYCCriterionTo,
              ),
            },
            {
              registrationDate: DateRangeCriterion(
                registeredKYCCriterionFrom,
                registeredKYCCriterionTo,
              ),
            },
          ],
        },
      ];
    } else if (nameShortNameCriterion) {
      criteria.$or = [
        { name: nameShortNameCriterion },
        { shortName: nameShortNameCriterion },
      ];
    } else if (registeredKYCCriterionFrom || registeredKYCCriterionTo) {
      criteria.$or = [
        {
          kycVerificationDate: DateRangeCriterion(
            registeredKYCCriterionFrom,
            registeredKYCCriterionTo,
          ),
        },
        {
          registrationDate: DateRangeCriterion(
            registeredKYCCriterionFrom,
            registeredKYCCriterionTo,
          ),
        },
      ];
    }
    if (clientTypesCriterion) {
      criteria.clientTypes = clientTypesCriterion;
    }
    if (clientStatusCriterion) {
      criteria.status = clientStatusCriterion;
    }

    criteria.clientKind = TextExactCriterion(ClientKind.CompanyType);

    setSearchClientsRequest({
      context: searchClientsRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    setSearchClientsRequest,
    searchClientsRequest.context,
    nameShortNameCriterion,
    clientTypesCriterion,
    registeredKYCCriterionFrom,
    registeredKYCCriterionTo,
    clientStatusCriterion,
  ]);

  return (
    <BPTable
      height={window.innerHeight - 138 - noticeBannerHeight}
      title="Companies"
      loading={loading}
      columns={[
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Short Name",
          field: "shortName",
        },
        {
          label: "Client Types",
          field: "clientTypes",
          accessor: (data) =>
            (data as Client).clientTypes.map((c) => (
              <Chip
                key={c}
                className={classes.chip}
                size="small"
                label={c}
                color="info"
              />
            )),
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Registration Date",
          field: "registrationDate",
          accessor: (c) =>
            dayjs((c as Client).registrationDate).year() === 1
              ? "-"
              : dayjs((c as Client).registrationDate).format("YYYY-MM-DD"),
        },
        {
          label: "KYC Date",
          field: "kycVerificationDate",
          accessor: (c) =>
            dayjs((c as Client).kycVerificationDate).year() === 1
              ? "-"
              : dayjs((c as Client).kycVerificationDate).format("YYYY-MM-DD"),
        },
      ]}
      filters={[
        <TextField
          variant="outlined"
          margin="dense"
          label="Search"
          placeholder="start typing..."
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            if (e.target.value === "") {
              setNameShortNameCriterion(undefined);
            } else {
              setNameShortNameCriterion(TextSubstringCriterion(e.target.value));
            }
          }}
        />,
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          multiple
          options={AllClientTypes}
          filterSelectedOptions
          onChange={(_, selected) => {
            if (selected.length) {
              setClientTypesCriterion(
                TextListCriterion(selected as ClientType[]),
              );
            } else {
              setClientTypesCriterion(undefined);
            }
          }}
          ChipProps={{
            color: "info",
            size: "small",
            deleteIcon: (
              <CancelIcon
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.filterField}
              label="Type"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              placeholder={
                searchClientsRequest.criteria.clientTypes
                  ? undefined
                  : "select..."
              }
            />
          )}
        />,
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          multiple
          options={AllClientStatuses}
          filterSelectedOptions
          onChange={(_, selected) => {
            if (selected.length) {
              setClientStatusCriterion(
                TextListCriterion(selected as ClientStatus[]),
              );
            } else {
              setClientStatusCriterion(undefined);
            }
            setSearchClientsRequest({
              ...searchClientsRequest,
              query: new Query(initialQuery),
            });
          }}
          ChipProps={{
            color: "info",
            size: "small",
            deleteIcon: (
              <CancelIcon
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.filterField}
              label="Status"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              placeholder={
                searchClientsRequest.criteria.status ? undefined : "select..."
              }
            />
          )}
        />,
        <Root className={classes.dateFilterLayout}>
          <DateField
            label="Date From"
            value={
              registeredKYCCriterionFrom
                ? registeredKYCCriterionFrom.date
                : null
            }
            onChange={(newValue) =>
              setRegisteredKYCCriterionFrom(
                newValue
                  ? {
                      date: newValue.format(),
                      inclusive: true,
                      ignore: false,
                    }
                  : undefined,
              )
            }
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {registeredKYCCriterionFrom && (
                        <Tooltip title="clear" placement="top">
                          <IconButton
                            className={classes.clearIcon}
                            size="small"
                            onClick={() =>
                              setRegisteredKYCCriterionFrom(undefined)
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />
          <DateField
            label="Date To"
            value={
              registeredKYCCriterionTo ? registeredKYCCriterionTo.date : null
            }
            onChange={(newValue) =>
              setRegisteredKYCCriterionTo(
                newValue
                  ? {
                      date: newValue.format(),
                      inclusive: true,
                      ignore: false,
                    }
                  : undefined,
              )
            }
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {registeredKYCCriterionTo && (
                        <Tooltip title="clear" placement="top">
                          <IconButton
                            className={classes.clearIcon}
                            size="small"
                            onClick={() =>
                              setRegisteredKYCCriterionTo(undefined)
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />
        </Root>,
      ]}
      data={searchClientsResponse.records}
      query={searchClientsRequest.query}
      onQueryChange={(query) =>
        setSearchClientsRequest({
          ...searchClientsRequest,
          query,
        })
      }
      totalNoRecords={searchClientsResponse.total}
    />
  );
}
