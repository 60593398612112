import { IdentifierType } from ".";

export interface RegistrationNumberIdentifierType {
  type: IdentifierType;
  registrationNumber: string;
}

export function RegistrationNumberIdentifier(
  registrationNumber: string,
): RegistrationNumberIdentifierType {
  return {
    type: IdentifierType.RegistrationNumberIdentifierTypeName,
    registrationNumber,
  };
}
