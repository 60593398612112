import { TransactionStateChangedNotification } from "./TransactionStateChangedNotification";

export const TransactionRolledBackNotificationTypeName =
  "consistency::horizon/TransactionRolledBackNotification";

export class TransactionRolledBackNotification
  implements TransactionStateChangedNotification
{
  public ["@type"]: string = TransactionRolledBackNotificationTypeName;

  public transactionID = "";

  constructor(n?: TransactionRolledBackNotification) {
    if (!n) {
      return;
    }
    this.transactionID = n.transactionID;
  }

  notificationType(): string {
    return TransactionRolledBackNotificationTypeName;
  }

  transactionStateChangedID(): string {
    return this.transactionID;
  }
}
