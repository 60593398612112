import { TransactionStateChangedNotification } from "./TransactionStateChangedNotification";

export const TransactionCompleteNotificationTypeName =
  "consistency::horizon/TransactionCompleteNotification";

export class TransactionCompleteNotification
  implements TransactionStateChangedNotification
{
  public ["@type"]: string = TransactionCompleteNotificationTypeName;

  public transactionID = "";

  constructor(n?: TransactionCompleteNotification) {
    if (!n) {
      return;
    }
    this.transactionID = n.transactionID;
  }

  notificationType(): string {
    return TransactionCompleteNotificationTypeName;
  }

  transactionStateChangedID(): string {
    return this.transactionID;
  }
}
