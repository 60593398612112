import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { alpha, InputAdornment, TextField, Typography } from "@mui/material";

import { InstrumentAnnualPerformanceLogEntry } from "james/financial";
import range from "lodash/range";
import cx from "classnames";
import dayjs from "dayjs";
import { TextNumField } from "components/FormFields";
import { dateIsValid } from "utilities/date/dateIsValid";
import { FieldValidations } from "./common";

const PREFIX = "ViewEditInstrumentAnnualPerformanceLog";

const classes = {
  root: `${PREFIX}-root`,
  tableLayout: `${PREFIX}-tableLayout`,
  rowDivider: `${PREFIX}-rowDivider`,
  headerRowDivider: `${PREFIX}-headerRowDivider`,
  headerCell: `${PREFIX}-headerCell`,
  bodyWrapper: `${PREFIX}-bodyWrapper`,
  bodyRowDivider: `${PREFIX}-bodyRowDivider`,
  bodyCell: `${PREFIX}-bodyCell`,
  percentageEndAdornment: `${PREFIX}-percentageEndAdornment`,
  percentageTextField: `${PREFIX}-percentageTextField`,
  errorHelperText: `${PREFIX}-errorHelperText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: theme.spacing(1),
  },

  [`& .${classes.tableLayout}`]: {
    display: "grid",
    gridTemplateColumns: "60px repeat(13, auto)",
    alignItems: "center",
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(0.2),
  },

  // row
  [`& .${classes.rowDivider}`]: {
    gridColumn: "1/15",
  },

  // Header
  [`& .${classes.headerRowDivider}`]: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },

  [`& .${classes.headerCell}`]: {
    justifySelf: "center",
  },

  // Body
  [`& .${classes.bodyWrapper}`]: {
    maxHeight: 565,
    overflowX: "hidden",
    overflowY: "auto",
  },

  [`& .${classes.bodyRowDivider}`]: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },

  [`& .${classes.bodyCell}`]: {
    justifySelf: "center",
  },

  [`& .${classes.percentageEndAdornment}`]: {
    marginTop: -4,
    marginLeft: -3,
  },

  [`& .${classes.percentageTextField}`]: {
    padding: theme.spacing(0.4, 0.5, 0, 0.5),
    marginTop: -4,
    borderRadius: 8,
    width: 60,
    color: theme.palette.text.tertiary,
    backgroundColor: alpha(theme.palette.background.default, 0.5),
  },

  // other
  [`& .${classes.errorHelperText}`]: {
    gridColumn: "1/15",
  },
}));

export interface Props {
  disabled: boolean;
  viewMode: boolean;
  annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
  onChange: (updated: InstrumentAnnualPerformanceLogEntry[]) => void;
  fieldValidations: FieldValidations;
  issueDate: string;
  maturityDate: string;
}

const shortMonths = dayjs.monthsShort();

export function ViewEditInstrumentAnnualPerformanceLog(props: Props) {
  // parse given issue and maturity date
  const issueDate = useMemo(() => dayjs(props.issueDate), [props.issueDate]);
  const maturityDate = useMemo(
    () => dayjs(props.maturityDate),
    [props.maturityDate],
  );

  // prepare a year range
  const years = useMemo(
    () => range(issueDate.year(), dayjs().year() + 1),
    [issueDate],
  );

  // initialise incoming performance log
  const annualPerformanceLog = useMemo(() => {
    // prepare a new performance log
    let newAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[] = [];

    // process given performance log
    years.forEach((year) => {
      // for every required year
      // look for an associated performance log entry
      const ple = props.annualPerformanceLog.find((p) => p.year === year);
      if (ple) {
        // if found then add in
        newAnnualPerformanceLog.push(ple);
      } else {
        // if not then add one for the required year
        newAnnualPerformanceLog.push(new InstrumentAnnualPerformanceLogEntry());
        newAnnualPerformanceLog[newAnnualPerformanceLog.length - 1].year = year;
      }
    });

    // sort in descending order
    newAnnualPerformanceLog = newAnnualPerformanceLog.sort((x, y) => {
      if (x.year > y.year) {
        return -1;
      }
      if (x.year < y.year) {
        return 1;
      }
      return 0;
    });

    return newAnnualPerformanceLog;
  }, [years]);

  if (!(dateIsValid(props.issueDate) && dateIsValid(props.maturityDate))) {
    return (
      <Typography
        color="error"
        variant="body2"
        children="Invalid dates in form need to be corrected before returns log can be filled in."
      />
    );
  }

  return (
    <Root className={classes.root}>
      <div className={classes.tableLayout}>
        {/* Header */}
        <div />
        {range(0, 12).map((monthIdx) => (
          <Typography
            key={monthIdx}
            variant="body1"
            className={classes.headerCell}
            children={shortMonths[monthIdx]}
          />
        ))}
        <Typography
          variant="body1"
          className={classes.headerCell}
          children="Annual"
        />
        <div className={cx(classes.rowDivider, classes.headerRowDivider)} />
      </div>

      <div
        className={cx(classes.tableLayout, classes.bodyWrapper, "meshScroll")}
      >
        {/* For every performance log entry add a row ... */}
        {annualPerformanceLog.map((pl, idx) => {
          // get now
          const now = dayjs();

          // the year cannot have annual performance entered if
          const yearHasNoAnnualPerformance =
            // it is this year (not over yet)
            pl.year >= now.year() || // OR
            // it is after the year that the related instrument matures
            pl.year > maturityDate.year() || // OR
            // it is before the year that the related instrument was issued
            pl.year < issueDate.year() || // OR
            // it is the year that the instrument was issued AND
            // the instrument was not issued in the first month
            (pl.year === issueDate.year() && issueDate.month() !== 0);

          return (
            <React.Fragment key={idx}>
              <Typography
                color="textSecondary"
                variant="body1"
                id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${idx}`}
                className={classes.bodyCell}
                children={+pl.year ? pl.year : "-"}
              />
              {range(0, 12).map((monthIdx) => {
                // get the first day of this month idx
                const firstDayOfMonth = dayjs()
                  .year(pl.year)
                  .month(monthIdx)
                  .day(1);

                // no returns can be submitted for this month if
                const noReturnForMonth =
                  // the first day of this month is in the future
                  firstDayOfMonth.isAfter(now) || // OR
                  // the first day of this month is in a month before the
                  // issue date of the related instrument
                  firstDayOfMonth.isBefore(issueDate, "month") || // OR
                  // the first day of this month is in a month after the
                  // maturity date of the related instrument
                  firstDayOfMonth.isAfter(maturityDate, "month");

                return props.viewMode ? (
                  // View Mode
                  <Typography
                    key={monthIdx}
                    variant="body1"
                    color="textSecondary"
                    id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${monthIdx}-${idx}`}
                    className={classes.bodyCell}
                    children={
                      +pl.monthlyPerformance[monthIdx + 1]
                        ? `${pl.monthlyPerformance[monthIdx + 1]}%`
                        : "-"
                    }
                  />
                ) : // NOT viewMode - i.e. Edit mode
                noReturnForMonth ? (
                  <TextField
                    key={monthIdx}
                    className={classes.bodyCell}
                    disabled
                    variant="standard"
                    id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${monthIdx}-${idx}`}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      className: classes.percentageTextField,
                    }}
                    value=""
                  />
                ) : (
                  <TextNumField
                    key={monthIdx}
                    className={classes.bodyCell}
                    disabled={props.disabled}
                    variant="standard"
                    id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${monthIdx}-${idx}`}
                    noDecimalPlaces={2}
                    InputProps={{
                      sx: (theme) => ({
                        border: props.fieldValidations[
                          `annualPerformanceLog-${idx}-year-${
                            monthIdx + 1
                          }-month`
                        ]
                          ? `1px solid ${theme.palette.error.main}`
                          : "",
                      }),
                      disableUnderline: true,
                      className: classes.percentageTextField,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.percentageEndAdornment}
                          children="%"
                        />
                      ),
                    }}
                    value={
                      annualPerformanceLog[idx].monthlyPerformance[
                        monthIdx + 1
                      ] === undefined
                        ? "0"
                        : annualPerformanceLog[idx].monthlyPerformance[
                            monthIdx + 1
                          ]
                    }
                    onChange={(e) => {
                      annualPerformanceLog[idx].monthlyPerformance[
                        monthIdx + 1
                      ] = e.target.value;
                      props.onChange([...annualPerformanceLog]);
                    }}
                  />
                );
              })}
              {props.viewMode ? (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-textField-${idx}`}
                  className={classes.bodyCell}
                  children={
                    +pl.annualPerformance ? `${pl.annualPerformance}%` : "-"
                  }
                />
              ) : yearHasNoAnnualPerformance ? (
                <TextField
                  variant="standard"
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-textField-${idx}`}
                  className={classes.bodyCell}
                  disabled
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    className: classes.percentageTextField,
                  }}
                  value=""
                />
              ) : (
                <TextNumField
                  variant="standard"
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-textField-${idx}`}
                  className={classes.bodyCell}
                  disabled={props.disabled}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.percentageTextField,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.percentageEndAdornment}
                        children="%"
                      />
                    ),
                  }}
                  value={annualPerformanceLog[idx].annualPerformance}
                  onChange={(e) => {
                    annualPerformanceLog[idx].annualPerformance =
                      e.target.value;
                    props.onChange([...annualPerformanceLog]);
                  }}
                />
              )}
              {!!props.fieldValidations[`annualPerformanceLog-${idx}-year`] && (
                <Typography
                  color="error"
                  variant="body2"
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-helperText-${idx}`}
                  className={classes.errorHelperText}
                  children={
                    props.fieldValidations[`annualPerformanceLog-${idx}-year`]
                  }
                />
              )}
              {idx === annualPerformanceLog.length - 1 ? (
                <div
                  className={cx(classes.rowDivider, classes.headerRowDivider)}
                />
              ) : (
                <div
                  className={cx(classes.rowDivider, classes.bodyRowDivider)}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Root>
  );
}
