import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { IndependentReview } from "./IndependentReviews";
import { MarketingMedia } from "./MarketingMedia";
import { NewsArticle } from "./NewsArticle";
import { Token } from "james/ledger";
import { SocialMediaLinks } from "./SocialMediaLinks";
import { InstrumentMarketingContent } from "./InstrumentMarketingContent";
import { Query } from "james/search/query";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { Identifier } from "james/search/identifier/Identifier";

export type CreateInstrumentMarketingContentRequest = {
  context: Context;
  ownerID: string;
  token: Token;
  estimatedAnnualReturn: Decimal;
  socialMediaLinks: SocialMediaLinks;
  investmentObjective: string;
  IndependentReviews: IndependentReview[];
  MarketingMedia: MarketingMedia[];
  NewsArticles: NewsArticle[];
};

export type RetrieveInstrumentMarketingContentRequest = {
  context: Context;
  identifier: Identifier;
};

export type RetrieveInstrumentMarketingContentResponse = {
  instrumentMarketingContent: InstrumentMarketingContent;
};

export type SearchInstrumentMarketingContentsRequest = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  context: Context;
  query?: Query;
};

export type SearchInstrumentMarketingContentsResponse = {
  instrumentsMarketingContent: InstrumentMarketingContent[];
  total: number;
};

export const InstrumentMarketingMediaContentRepositoryServiceProviderName =
  "marketing-InstrumentMarketingContentRepository";

export const InstrumentMarketingContentRepository = {
  async RetrieveInstrumentMarketingContent(
    request: RetrieveInstrumentMarketingContentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<{ instrumentMarketingContent: InstrumentMarketingContent }> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${InstrumentMarketingMediaContentRepositoryServiceProviderName}.RetrieveInstrumentMarketingContent`,
        request,
      },
      opts,
    );

    return {
      instrumentMarketingContent: new InstrumentMarketingContent(
        response.instrumentMarketingContent,
      ),
    };
  },
  async SearchInstrumentMarketingContents(
    request: SearchInstrumentMarketingContentsRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SearchInstrumentMarketingContentsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${InstrumentMarketingMediaContentRepositoryServiceProviderName}.SearchInstrumentMarketingContents`,
        request,
      },
      opts,
    );

    return {
      instrumentsMarketingContent: response.instrumentMarketingContent.map(
        (item: InstrumentMarketingContent) =>
          new InstrumentMarketingContent(item),
      ),
      total: response.total,
    };
  },
};
