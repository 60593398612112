import React, { Dispatch, SetStateAction, useCallback } from "react";

import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { Company } from "james/legal/company/Company";
import { CompanyRegStep } from "views/KYB/CompanyRegistration";
import { CompanyDetails, CompanyProfile, ContactDetails } from "./components";
import { CompanyRepresentative } from "./components/CompanyRepresentative";
import { ConnectedIndividual } from "./components/ConnectedIndividual";
import { NonIndividual } from "./components/NonIndividual";
import { CompanyStepsValidator } from "./Validation";
import { FieldValidations } from "common/validation";
import { FormState } from "views/KYB/useFormState";

interface FrameworkProps {
  activeStep: CompanyRegStep;
  setActiveStep: (step: CompanyRegStep) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  fieldValidations: FieldValidations;
  formState: FormState;
  nextPressed: boolean;
  setNextPressed: Dispatch<SetStateAction<boolean>>;
  savingInProgress: boolean;
  confirmInfo: boolean;
  handleSubmitButton: () => Promise<void>;
  setConfirmInfo: Dispatch<SetStateAction<boolean>>;
}

interface StepType {
  companyRegStep: CompanyRegStep;
  component: React.ReactNode;
  label: React.ReactNode | string;
  CompanyStepsValidator?: (
    companyRegStep: CompanyRegStep,
    company: Company,
  ) => boolean;
  ClientAdminStepValidator?: (admin: {
    name: string;
    surname: string;
    email: string;
  }) => boolean;
}

export function Framework(props: FrameworkProps) {
  const {
    activeStep,
    setActiveStep,
    onChange,
    formState,
    fieldValidations,
    nextPressed,
    setNextPressed,
    savingInProgress,
    confirmInfo,
    handleSubmitButton,
    setConfirmInfo,
  } = props;

  const [businessAddressSame, setBusinessAddressSame] =
    React.useState<boolean>(false);
  const [headOfficeAddressSame, setHeadOfficeAddressSame] =
    React.useState<boolean>(false);
  const [postalOfficeAddressSame, setPostalOfficeAddressSame] =
    React.useState<boolean>(false);

  const [stepValidationStatus, setStepValidationStatus] =
    React.useState<StepValidStatusType>({
      ...StepValidStatus,
    });

  const onNext = (step: CompanyRegStep) => () => {
    setActiveStep(step);
  };

  const invalidateStep = (step: CompanyRegStep, validStatus: boolean) => {
    setStepValidationStatus({
      ...stepValidationStatus,
      [`${step}`]: validStatus,
    });
  };

  const steps: StepType[] = [
    {
      companyRegStep: CompanyRegStep.companyProfile,
      component: (
        <CompanyProfile
          onNext={onNext}
          onChange={onChange}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.companyProfile}
          formState={formState}
          fieldValidations={fieldValidations}
          nextPressed={nextPressed}
          setNextPressed={setNextPressed}
          savingInProgress={savingInProgress}
        />
      ),
      label: (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            "& > .secondary": {
              color: theme.palette.text.secondary,
            },
          })}
        >
          {" "}
          Your
          <br />
          <span className="secondary">{"Company"}</span>
        </Typography>
      ),
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.companyDetails,
      component: (
        <CompanyDetails
          onNext={onNext}
          onChange={onChange}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.companyDetails}
          formState={formState}
          fieldValidations={fieldValidations}
          nextPressed={nextPressed}
          setNextPressed={setNextPressed}
          savingInProgress={savingInProgress}
        />
      ),
      label: (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            "& > .secondary": {
              color: theme.palette.text.secondary,
            },
          })}
        >
          Company
          <br />
          <span className="secondary">{"Details"}</span>
        </Typography>
      ),
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.contactDetails,
      component: (
        <ContactDetails
          onNext={onNext}
          onChange={onChange}
          setBusinessAddressSame={setBusinessAddressSame}
          businessAddressSame={businessAddressSame}
          headOfficeAddressSame={headOfficeAddressSame}
          setHeadOfficeAddressSame={setHeadOfficeAddressSame}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.contactDetails}
          formState={formState}
          nextPressed={nextPressed}
          setNextPressed={setNextPressed}
          savingInProgress={savingInProgress}
        />
      ),
      label: (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            "& > .secondary": {
              color: theme.palette.text.secondary,
            },
          })}
        >
          Contact
          <br />
          <span className="secondary">{"Details"}</span>
        </Typography>
      ),
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.companyRepresentative,
      component: (
        <CompanyRepresentative
          onNext={onNext}
          onChange={onChange}
          postalOfficeAddressSame={postalOfficeAddressSame}
          setPostalOfficeAddressSame={setPostalOfficeAddressSame}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.companyRepresentative}
          formState={formState}
          nextPressed={nextPressed}
          setNextPressed={setNextPressed}
          savingInProgress={savingInProgress}
        />
      ),
      label: (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            "& > .secondary": {
              color: theme.palette.text.secondary,
            },
          })}
        >
          Company
          <br />
          <span className="secondary">{"Representative"}</span>
        </Typography>
      ),
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.connectedIndividuals,
      component: (
        <ConnectedIndividual
          onNext={onNext}
          onChange={onChange}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.connectedIndividuals}
          formState={formState}
          nextPressed={nextPressed}
          setNextPressed={setNextPressed}
          savingInProgress={savingInProgress}
        />
      ),
      label: (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            "& > .secondary": {
              color: theme.palette.text.secondary,
            },
          })}
        >
          Connected
          <br />
          <span className="secondary">{"Individuals"}</span>
        </Typography>
      ),
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.connectedNonIndividuals,
      component: (
        <NonIndividual
          onNext={onNext}
          onChange={onChange}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.connectedNonIndividuals}
          formState={formState}
          nextPressed={nextPressed}
          setNextPressed={setNextPressed}
          savingInProgress={savingInProgress}
          confirmInfo={confirmInfo}
          handleSubmitButton={handleSubmitButton}
          setConfirmInfo={setConfirmInfo}
        />
      ),
      label: (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            "& > .secondary": {
              color: theme.palette.text.secondary,
            },
          })}
        >
          Connected <br /> Entity <br />
          <span className="secondary">{" (Optional)"}</span>
        </Typography>
      ),
      CompanyStepsValidator,
    },
  ];

  const getActiveStep = useCallback(
    () => steps.findIndex((step) => step.companyRegStep === activeStep),
    [steps],
  );

  const handleStep = (idx: number) => () => {
    const currentStep = steps[getActiveStep()];
    if (
      currentStep.CompanyStepsValidator &&
      currentStep.CompanyStepsValidator(
        currentStep.companyRegStep,
        formState.company,
      )
    ) {
      invalidateStep(currentStep.companyRegStep, false);
      setActiveStep(steps[idx].companyRegStep);
      return;
    }
    invalidateStep(currentStep.companyRegStep, true);
    setActiveStep(steps[idx].companyRegStep);
  };

  const renderComponent = () => {
    const step = steps.find((s) => s.companyRegStep === activeStep);
    if (step) {
      return step.component;
    }
    return null;
  };

  return (
    <>
      <Stepper
        activeStep={getActiveStep()}
        alternativeLabel
        nonLinear={false}
        sx={{
          background: "transparent",
        }}
      >
        {steps.map((step, idx) => (
          <Step
            id={`companyRegistration-${step.companyRegStep}-stepper`}
            key={idx}
            completed={stepValidationStatus[`${step.companyRegStep}`] === false}
          >
            <StepButton
              id={`${step.companyRegStep}-stepperButton`}
              disabled
              onClick={handleStep(idx)}
            >
              <StepLabel
                id={`companyRegistration-${step.companyRegStep}-stepperLabel`}
                error={stepValidationStatus[`${step.companyRegStep}`]}
              >
                <Typography variant="caption">{step.label}</Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {renderComponent()}
    </>
  );
}

type StatusType = boolean | undefined;

// Ensure the step valid types match up with the company register steps
type StepValidStatusType = {
  [K in CompanyRegStep]: StatusType;
};

const StepValidStatus: StepValidStatusType = {
  companyProfile: undefined,
  companyDetails: undefined,
  contactDetails: undefined,
  companyRepresentative: undefined,
  connectedIndividuals: undefined,
  connectedNonIndividuals: undefined,
  confirmation: undefined,
};
