import { Context } from "james/security";
import { Company } from "james/legal/company/Company";
import {
  JSONRPCRequestOpts,
  jsonRPCRequest,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";

export interface RetrieveMyCompanyRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveMyCompanyResponse {
  company: Company;
}

export interface RetrieveCompanyUnscopedRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveCompanyUnscopedResponse {
  company: Company;
}

export const Reader = {
  serviceProvider: "company-Reader",
  async RetrieveMyCompany(
    request: RetrieveMyCompanyRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveMyCompanyResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${Reader.serviceProvider}.RetrieveMyCompany`,
        request,
      },
      opts,
    );
  },
  async RetrieveCompanyUnscoped(
    request: RetrieveCompanyUnscopedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveCompanyUnscopedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${Reader.serviceProvider}.RetrieveCompanyUnscoped`,
        request,
      },
      opts,
    );

    return {
      company: new Company(response.company),
    };
  },
};
