import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ComplianceManagerWrite,
  useOpenLatestAUSAgreement,
} from "james/compliance";
import backgroundCroppedDark from "assets/images/background/backgroundCropped.jpeg";
import meshLogo from "assets/images/logo/meshLogo.svg";
import { useWidth } from "utilities/general";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import { useErrorContext } from "context/Error";

export function AcceptAUS() {
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { authContext, refreshLoginClaims } = useApplicationContext();
  const { openLatestAUSAgreement } = useOpenLatestAUSAgreement();
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const isDesktop = isWidthUp("sm", width);
  const { errorContextErrorTranslator } = useErrorContext();

  const handleAcceptTermsAndConditions = async () => {
    setLoading(true);

    try {
      await ComplianceManagerWrite.AcceptAUSAgreement({ context: authContext });
      await refreshLoginClaims();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error accepting Authorised User Service Agreement: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error accepting Authorised User Service Agreement: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Mesh | Terms</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Accept the Mesh Terms and Conditions"
        />
      </Helmet>
      {isDesktop && (
        <Box
          sx={(theme) => ({
            height: "100%",
            backgroundColor: theme.palette.background.paper,
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "30vh 1fr",
            backgroundImage: `url(${backgroundCroppedDark})`,
            backgroundSize: "cover",
          })}
        >
          <Box
            sx={{
              zIndex: 3,
            }}
          >
            <img alt="" width="300" src={meshLogo} />
          </Box>
          <Box
            sx={(theme) => ({
              justifySelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "625px",
              zIndex: 2,
              gap: theme.spacing(4),
            })}
          >
            <Typography variant="h1">A responsible revolution</Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="subtitle1"
              align="center"
            >
              The safety and security of your Mesh experience is our priority.
              Our Terms and Conditions protect our platform and our users, so
              please read and accept them.
            </Typography>
            <Typography
              component="p"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="subtitle1"
            >
              Please read our&nbsp;
              <Link
                onClick={openLatestAUSAgreement}
                target="_blank"
                sx={{
                  cursor: "pointer",
                }}
                underline="hover"
                color="secondary"
              >
                Authorised User Service Agreement
              </Link>
              .
            </Typography>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={accept}
                      onChange={() => setAccept(!accept)}
                      name="checkedB"
                      color="primary"
                      id="kycDialog-sarsDeclaration-checkbox"
                    />
                  }
                  label="I have read and accept the Mesh Terms and Conditions."
                />
                <Button
                  id="acceptAUS-accept-button"
                  disabled={!accept || loading}
                  size="large"
                  onClick={handleAcceptTermsAndConditions}
                  variant="contained"
                  color="primary"
                  children="accept"
                />
              </>
            )}
          </Box>
        </Box>
      )}
      {isMobile && (
        <Box
          sx={{
            justifySelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <img alt="" width="220px" height="100px" src={meshLogo} />
          </Box>
          <Box
            sx={{
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
              }}
              variant="h1"
            >
              A responsible
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                lineHeight: "24px",
              }}
              variant="h1"
            >
              revolution
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(0, 2, 0, 2),
              marginTop: theme.spacing(4),
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="subtitle1"
              align="center"
            >
              The safety and security of your Mesh experience is our priority.
              Our Terms and Conditions protect our platform and our users, so
              please read and accept them.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(0, 3, 0, 3),
              marginTop: theme.spacing(3),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}
          >
            <Typography
              component="p"
              sx={(theme) => ({
                marginBottom: theme.spacing(3),
                color: theme.palette.text.secondary,
              })}
              variant="subtitle1"
            >
              Please read our&nbsp;
            </Typography>
            <Typography>
              <Link
                onClick={openLatestAUSAgreement}
                target="_blank"
                sx={{
                  cursor: "pointer",
                }}
                underline="hover"
                color="secondary"
              >
                Authorised User Service Agreement
              </Link>
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(0, 3, 0, 3),
              marginTop: theme.spacing(6),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: theme.spacing(3),
            })}
          >
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <Box
                  sx={(theme) => ({
                    padding: theme.spacing(0, 1.5, 0, 3),
                    width: "300px",
                    marginBottom: theme.spacing(3),
                  })}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accept}
                        onChange={() => setAccept(!accept)}
                        name="checkedB"
                        color="primary"
                        id="kycDialog-sarsDeclaration-checkbox"
                      />
                    }
                    label="I have read and accept
                     the Mesh Terms and Conditions."
                  />
                </Box>
                <Box
                  sx={{
                    width: "320px",
                  }}
                >
                  <Button
                    sx={{
                      width: "100%",
                      height: 52,
                    }}
                    id="acceptAUSandPaaS-accept-button"
                    size="large"
                    disabled={!accept || loading}
                    onClick={handleAcceptTermsAndConditions}
                    variant="contained"
                    color="primary"
                    children="submit"
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
