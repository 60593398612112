import { CallbackProps, TourStep } from "./types";

export const MarketplaceTour: TourStep[] = [
  // step 1
  {
    dialog: {
      header: "Identity Verification",
      content: "We need to verify who you are, you will need a proof of id",
      refElementID: {
        xs: "mainAppBar-identityVerification-menuItem",
      },
      arrow: {
        xs: {
          top: -2,
          left: "90%",
          position: "top",
        },
      },
      offset: {
        xs: {
          x: -80,
          y: 16,
        },
        sm: {
          x: -140,
          y: 18,
        },
      },
    },
    beforeCallbacks: [
      ({ elements, hideElements }: CallbackProps) => {
        const icon = elements["mainAppBar-profileMenu-iconButton"];
        icon?.click();
        hideElements([
          "marketPlaceContent",
          "marketPlaceRibbon",
          "marketplaceLayoutMobileNav",
          "marketplaceLayoutMobileContent",
        ]);
      },
    ],
    afterCallbacks: [
      ({ elements, unhideElements }: CallbackProps) => {
        const icon = elements["mainAppBar-profileMenu-iconButton"];
        icon?.click();
        unhideElements(["marketPlaceRibbon", "marketplaceLayoutMobileNav"]);
      },
    ],
  },
  // step 2
  {
    dialog: {
      header: "Fund your account",
      content: "Find your unique ref. number and make an EFT Payment",
      refElementID: {
        xs: "mobileNavigator-walletNavigation-button",
        sm: "marketPlaceRibbon-funcAccount-button",
      },
      arrow: {
        xs: {
          top: "calc(100% + 2px)",
          left: "90%",
          position: "bottom",
        },
        sm: {
          top: -2,
          left: "90%",
          position: "top",
        },
      },
      offset: {
        xs: {
          x: 0,
          y: 10,
        },
        sm: {
          x: -150,
          y: 16,
        },
      },
    },
    beforeCallbacks: [
      ({ hideElements }) =>
        hideElements(["marketPlaceContent", "marketplaceLayoutMobileContent"]),
      ({ removeHighlight }) => removeHighlight(),
    ],
    afterCallbacks: [
      ({ unhideElements }) =>
        unhideElements([
          "marketPlaceContent",
          "marketplaceLayoutMobileContent",
        ]),
    ],
  },
  // step 3
  {
    dialog: {
      header: "Mesh Market",
      content: "Discover and invest in the full asset universe on Mesh",
      refElementID: {
        xs: "mobileNavigator-marketsNavigation-button",
        sm: "marketPlaceRibbon-Markets-tab",
      },
      arrow: {
        xs: {
          top: "calc(100% + 2px)",
          left: "10%",
          position: "bottom",
        },
        sm: {
          top: -2,
          left: "10%",
          position: "top",
        },
      },
      offset: {
        xs: {
          x: 0,
          y: 10,
        },
        sm: {
          x: 120,
          y: 12,
        },
      },
    },
    beforeCallbacks: [
      ({ highlightElements, breakpoint }: CallbackProps) => {
        if (breakpoint !== "xs") {
          highlightElements([
            "marketPlaceRibbon",
            `marketplaceCard-0`,
            `marketplaceCard-1`,
            `marketplaceCard-2`,
          ]);
        } else {
          highlightElements([
            `marketplaceCard-0`,
            "marketplaceLayoutMobileNav",
            "marketplaceLayoutMobileTitle",
          ]);
        }
      },
    ],
    afterCallbacks: [({ removeHighlight }: CallbackProps) => removeHighlight()],
  },
];
