import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AddAPhotoRounded as AddAPhotoIcon } from "@mui/icons-material";
import cx from "classnames";
import { IDIdentifier } from "james/search/identifier";
import React, { useEffect, useRef, useState } from "react";
import { useApplicationContext } from "context/Application/Application";
import { Manager, Profile } from "james/legal/company";

const PREFIX = "InfoProfilePicture";

const classes = {
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  skipButton: `${PREFIX}-skipButton`,
  avatar: `${PREFIX}-avatar`,
  avatarNoImage: `${PREFIX}-avatarNoImage`,
  hiddenInput: `${PREFIX}-hiddenInput`,
  circularProgressWrapper: `${PREFIX}-circularProgressWrapper`,
  profilePictureVertical: `${PREFIX}-profilePictureVertical`,
  profilePictureHorizontal: `${PREFIX}-profilePictureHorizontal`,
  companyInfoTextArea: `${PREFIX}-companyInfoTextArea`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.subHeading}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
    cursor: "pointer",
    alignItems: "start",
  },

  [`& .${classes.avatarNoImage}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  [`& .${classes.hiddenInput}`]: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },

  [`& .${classes.circularProgressWrapper}`]: {
    width: 60,
    height: 60,
    padding: 0,
    margin: 0,
  },

  [`& .${classes.profilePictureVertical}`]: {
    width: 60,
  },

  [`& .${classes.profilePictureHorizontal}`]: {
    height: 60,
  },

  [`& .${classes.companyInfoTextArea}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.default,
    width: 368,
  },
}));

interface InfoProfilePicturesProps {
  onNext: (companyDescription: string) => void;
  companyDescription: string;
}

export const InfoProfilePicture: React.FC<InfoProfilePicturesProps> = (
  props: InfoProfilePicturesProps,
) => {
  const { loginClaims, authContext } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [uploadClick, setUploadClick] = useState<(() => void) | null>(null);
  const [companyProfile, setCompanyProfile] = useState<Profile | undefined>(
    undefined,
  );
  const [uploadInputRef, setUploadInputRef] = useState<HTMLInputElement | null>(
    null,
  );
  const [profilePictureData, setProfilePictureData] = useState<string | null>();
  const [companyDescription, setCompanyDescription] = useState(
    props.companyDescription,
  );
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);

  useEffect(() => {
    Manager.RetrieveMyCompanyProfile({
      context: authContext,
    })
      .then((response) => {
        setProfilePictureData(
          response.profile.profilePictureData === ""
            ? null
            : `data:${response.profile.profilePictureMIMEType};base64,${response.profile.profilePictureData}`,
        );
        setCompanyProfile(response.profile);
      })
      .catch((e) => {
        console.error(
          `error retrieving company profile: ${
            e.message ? e.message : e.toString()
          }`,
        );
      });
  }, []);

  const retrieveCompanyProfilePicture = async () => {
    setLoading(true);
    try {
      const retrieveCompanyProfilePictureResponse =
        await Manager.RetrieveCompanyProfilePicture({
          clientIdentifier: IDIdentifier(loginClaims.clientID),
          context: authContext,
        });
      setProfilePictureData(
        retrieveCompanyProfilePictureResponse.pictureData
          ? `data:${retrieveCompanyProfilePictureResponse.mimeType};base64,${retrieveCompanyProfilePictureResponse.pictureData}`
          : "",
      );
    } catch (e) {
      console.error("error retrieving company profile picture", e);
    }
    setLoading(false);
  };

  const attachInputClick = (inputRef: HTMLInputElement) => {
    if (uploadInputRef !== null) {
      return;
    }
    if (!inputRef) {
      return;
    }
    if (uploadClick !== null) {
      return;
    }
    setUploadInputRef(inputRef);
    setUploadClick(() => () => {
      inputRef.click();
    });
  };

  const handleSelectPictureFile = async () => {
    if (uploadInputRef === null) {
      console.error("upload input ref is null");
      return;
    }
    try {
      if (uploadInputRef.files && uploadInputRef.files.length > 0) {
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          const fileData: string | null = fileReader.result as string | null;
          if (fileData === null) {
            console.error("file data is null");
            return;
          }
          setLoading(true);
          try {
            const pictureData = fileData.slice(fileData.indexOf(",") + 1);
            const mimeType = fileData.slice(0, fileData.indexOf(","));
            await Manager.ChangeCompanyProfilePicture({
              context: authContext,
              clientIdentifier: IDIdentifier(loginClaims.clientID),
              pictureData,
              mimeType: mimeType.slice(
                mimeType.indexOf(":") + 1,
                mimeType.indexOf(";"),
              ),
            });
            await retrieveCompanyProfilePicture();
          } catch (e) {
            console.error("error changing profile picture", e);
          }
          setLoading(false);
        };
        fileReader.readAsDataURL(uploadInputRef.files[0]);
      } else {
        console.error("no upload input ref files");
      }
    } catch (e) {
      console.error("error trying to access picture file", e);
    }
    setLoading(false);
  };

  return (
    <StyledBox
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {companyProfile ? (
        <Typography
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
          variant="h4"
        >
          Welcome to {companyProfile.companyName}
          &apos;s first steps on Mesh
        </Typography>
      ) : (
        <Skeleton width={"100px"} height={"24px"} />
      )}
      {companyProfile ? (
        <Typography className={classes.subHeading} variant="h6">
          Remember to add a profile picture and a short description of{" "}
          {companyProfile.companyName} for the benefit of anyone seeking to know
          more about {companyProfile.companyName}.
        </Typography>
      ) : (
        <Skeleton width={"100px"} height={"50px"} />
      )}
      <Grid container direction="row" alignItems="center" spacing={2}>
        {loading ? (
          <Grid item>
            <div className={classes.circularProgressWrapper}>
              <CircularProgress size={55} />
            </div>
          </Grid>
        ) : (
          <Grid item>
            <Avatar
              id="infoProfilePicture-profilePicture-avatar"
              className={cx(classes.avatar, {
                [classes.avatarNoImage]: !profilePictureData,
              })}
              onClick={() => {
                if (uploadClick != null) {
                  uploadClick();
                }
              }}
            >
              {profilePictureData ? (
                <img
                  id="infoProfilePicture-profilePictureAvatar-img"
                  onLoad={() => {
                    if (profilePictureRef.current) {
                      setProfilePictureVertical(
                        profilePictureRef.current.naturalWidth <
                          profilePictureRef.current.naturalHeight,
                      );
                    }
                  }}
                  ref={profilePictureRef}
                  alt=""
                  src={profilePictureData}
                  className={cx({
                    [classes.profilePictureVertical]: profilePictureVertical,
                    [classes.profilePictureHorizontal]: !profilePictureVertical,
                  })}
                />
              ) : (
                <AddAPhotoIcon id="infoProfilePicture-profilePicture-addAPhotoIcon" />
              )}
            </Avatar>
          </Grid>
        )}
        <Grid item>
          {companyProfile ? (
            <Typography variant="h5">{companyProfile.companyName}</Typography>
          ) : (
            <Skeleton width={"80px"} height={"24px"} />
          )}
          {companyProfile && companyProfile.industry ? (
            <Typography variant="h6">
              {companyProfile.industry.industryName} -
              {companyProfile.industry.subIndustryName}
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          <input
            disabled={loading}
            ref={attachInputClick}
            onChange={handleSelectPictureFile}
            type="file"
            accept=".png,.jpg,.jpeg"
            className={classes.hiddenInput}
          />
        </Grid>
      </Grid>
      <Button
        id="infoProfilePicture-addOrChangePhoto-button"
        disabled={loading}
        color="primary"
        variant="contained"
        sx={{ width: 160 }}
        onClick={() => {
          if (uploadClick != null) {
            uploadClick();
          }
        }}
      >
        {profilePictureData ? "change photo" : "add photo"}
      </Button>
      <TextareaAutosize
        id="companyFirstSteps-infoProfilePicture-description-textAreaAutoSize"
        minRows={7}
        maxRows={7}
        value={companyDescription}
        onChange={(e) => setCompanyDescription(e.target.value)}
        placeholder="Description"
        className={classes.companyInfoTextArea}
      />
      <Button
        id="companyFirstSteps-infoProfilePicture-skipOrNext-button"
        disabled={loading}
        variant={
          profilePictureData || companyDescription !== ""
            ? "contained"
            : "outlined"
        }
        color={
          profilePictureData || companyDescription !== ""
            ? "primary"
            : undefined
        }
        onClick={() => props.onNext(companyDescription)}
        sx={{
          mt: 7,
          width: 120,
        }}
      >
        {profilePictureData || companyDescription !== "" ? "next" : "skip"}
      </Button>
    </StyledBox>
  );
};
