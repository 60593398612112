const LockTypeName = "mesh::locking/Lock";

export class Lock {
  public ["@type"]: string = LockTypeName;

  public id = "";

  public description = "";

  public signature = "";

  public lockedAt = "";

  public lockedBy = "";

  constructor(lock?: Lock) {
    if (!lock) {
      return;
    }
    this.id = lock.id;
    this.description = lock.description;
    this.signature = lock.signature;
    this.lockedAt = lock.lockedAt;
    this.lockedBy = lock.lockedBy;
  }
}
