import { Company } from "james/legal/company";
import { ValidationResult } from "common/validation";
import { Address } from "james/location";

export type FormState = {
  company: Company;
};

export type FormUpdaterSpecsType = {
  company: (value: Company, prevState?: FormState) => FormState;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  company: (value: Company, prevState?: FormState): FormState => {
    const f = prevState as FormState;
    return {
      ...f,
      company: value,
    };
  },
};

export const validationFunc = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!formState.company.registeredName) {
    newValidationState.fieldValidations.companyName =
      "Company registered name not provided";
  }

  return newValidationState;
};

const initializeState = (): FormState => {
  const company = new Company();
  company.connectedCompanies = [];
  company.connectedCompanies = [];
  company.businessAddress = new Address();
  return {
    company,
  };
};

export const initialState = initializeState();
