// source: financial/dayCountConvention.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.DayCountConvention', null, global);
/**
 * @enum {number}
 */
proto.financial.DayCountConvention = {
  UNDEFINED_DAY_COUNT_CONVENTION: 0,
  ACTUAL_OVER_365_FIXED_DAY_COUNT_CONVENTION: 1,
  ACTUAL_OVER_360_DAY_COUNT_CONVENTION: 2,
  ACTUAL_OVER_364_DAY_COUNT_CONVENTION: 3,
  ACTUAL_OVER_366_DAY_COUNT_CONVENTION: 4,
  ACTUAL_OVER_ACTUAL_ISMA_DAY_COUNT_CONVENTION: 5,
  THIRTY_OVER_360_ISMA_DAY_COUNT_CONVENTION: 6,
  THIRTY_OVER_365_DAY_COUNT_CONVENTION: 7,
  ACTUAL_OVER_365_25_DAY_COUNT_CONVENTION: 8
};

goog.object.extend(exports, proto.financial);
