import { Context } from "james/security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Model } from "./Model";

export interface ReadOneRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface ReadOneResponse {
  model: Model;
}

export const Reader = {
  serviceProvider: "marketSubscriptionOrderBookSettlementView-Reader",
  async ReadOne(request: ReadOneRequest): Promise<ReadOneResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Reader.serviceProvider}.ReadOne`,
      request,
    });
    return { model: new Model(response.model) };
  },
};

export const UnscopedReader = {
  serviceProvider: "marketSubscriptionOrderBookSettlementView-UnscopedReader",
  async UnscopedReadOne(request: ReadOneRequest): Promise<ReadOneResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${UnscopedReader.serviceProvider}.UnscopedReadOne`,
      request,
    });
    return {
      model: new Model(response.model),
    };
  },
};
