import { Notification } from "james/notification/Notification";
import { Model } from "./Model";

export const ModelChangedNotificationTypeName =
  "mesh::marketDirectOrderView/ModelChangedNotification";

export class ModelChangedNotification implements Notification {
  public ["@type"]: string = ModelChangedNotificationTypeName;

  public modelID = "";

  public model: Model = new Model();

  constructor(n?: ModelChangedNotification) {
    if (!n) {
      return;
    }
    this.modelID = n.modelID;
    this.model = new Model(n.model);
  }

  notificationType(): string {
    return ModelChangedNotificationTypeName;
  }
}
