import { Channel } from "james/notification/Channel";

export const NotificationChannelTypeName = "mesh::group/NotificationChannel";

export type NewNotificationChannelOpts = {
  groupID: string;
  name: string;
  private: boolean;
};

export class NotificationChannel implements Channel {
  public ["@type"]: string = NotificationChannelTypeName;

  public groupID = "";

  public name = "";

  public private = false;

  constructor(n?: NewNotificationChannelOpts) {
    if (!n) {
      return;
    }
    this.groupID = n.groupID;
    this.name = n.name;
    this.private = n.private;
  }

  id(): string {
    return `${this.groupID}-${this.name}`;
  }

  isPrivate(): boolean {
    return this.private;
  }

  channelType(): string {
    return NotificationChannelTypeName;
  }
}
