import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import {
  GetPriceForPeriodRequest,
  GetPriceForPeriodResponse,
  GetPriceForRangeRequest,
  GetPriceForRangeResponse,
  HistoricalPrice,
  PriceHistorian,
  Resolution,
  InvalidResolutionError,
} from "pkgTemp/market";
import { periodToRange } from "pkgTemp/stellar";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export const PriceHistorianServiceProviderName = "market-PriceHistorian";

export class MeshPriceHistorian implements PriceHistorian {
  // convert given period to a range
  async GetPriceForPeriod(
    request: GetPriceForPeriodRequest,
  ): Promise<GetPriceForPeriodResponse> {
    const [from, to] = periodToRange(request.period, request.allFrom);
    const historicalPrices: HistoricalPrice[] = [];

    // get resolution in NS
    const resolutionNS = resolutionToNS(request.resolution);

    const response = await this.GetPriceForRange({
      baseToken: request.baseToken,
      quoteToken: request.quoteToken,
      resolution: resolutionNS,
      from,
      to,
    });

    // convert the response into HistoricalPrice object
    // Note: this fails if the object is set this way
    for (const historicalPrice of response.prices) {
      historicalPrices.push(
        new HistoricalPrice({
          time: historicalPrice.time,
          avgPrice: request.quoteToken.newAmountOf(
            historicalPrice.avgPrice.value,
          ),
        }),
      );
    }
    // invoke and return price for range
    return {
      prices: historicalPrices,
    };
  }

  async GetPriceForRange(
    request: GetPriceForRangeRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<GetPriceForRangeResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshPublicURL"),
        method: `${PriceHistorianServiceProviderName}.GetPriceForRange`,
        request,
      },
      opts,
    );
  }
}

/**
 * Converts given resolution to nanoseconds.
 * @param {Resolution} resolution resolution to convert to nanoseconds
 */
export function resolutionToNS(resolution: Resolution): number {
  switch (resolution) {
    case Resolution.Minute:
      return 60000000000;

    case Resolution.Hour:
      return 3600000000000;

    case Resolution.Day:
      return 86400000000000;

    case Resolution.Week:
      return 604800000000000;

    default:
      throw new InvalidResolutionError(resolution);
  }
}
