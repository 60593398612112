import { AuditEntry } from "../audit";

export const TransactionQueueEntryTypeName =
  "mesh::ledger/TransactionQueueEntry";

export class TransactionQueueEntry {
  public ["@type"]: string = TransactionQueueEntryTypeName;

  public id = "";
  public transactionBatchID = "";
  public queueID = "";
  public key = "";
  public transactionIDs: string[] = [];
  public auditEntry: AuditEntry = new AuditEntry();

  constructor(transactionQueueEntry?: TransactionQueueEntry) {
    if (!transactionQueueEntry) {
      return;
    }
    this.id = transactionQueueEntry.id;
    this.transactionBatchID = transactionQueueEntry.transactionBatchID;
    this.queueID = transactionQueueEntry.queueID;
    this.key = transactionQueueEntry.key;
    this.transactionIDs = [...transactionQueueEntry.transactionIDs];
    this.auditEntry = new AuditEntry(transactionQueueEntry.auditEntry);
  }
}
