import { UserGroupRoles, UserRoleGroups } from "james/user";

export class Profile {
  public profilePictureURL = "";

  public firstName = "";

  public lastName = "";

  public cellphoneNumber = "";

  public telephoneNumber = "";

  public emailAddress = "";

  public clientName = "";

  public roleGroups: UserRoleGroups[] = [];

  public groupRoles: UserGroupRoles[] = [];

  constructor(profile?: Profile) {
    if (!profile) {
      return;
    }
    this.profilePictureURL = profile.profilePictureURL;
    this.firstName = profile.firstName;
    this.lastName = profile.lastName;
    this.cellphoneNumber = profile.cellphoneNumber;
    this.telephoneNumber = profile.telephoneNumber;
    this.emailAddress = profile.emailAddress;
    this.clientName = profile.clientName;
    this.roleGroups = profile.roleGroups.map((rg) => new UserRoleGroups(rg));
    this.groupRoles = profile.groupRoles.map((gr) => new UserGroupRoles(gr));
  }
}
