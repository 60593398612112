//  MediaImage class represent the media.Image type on our backends.
export class MediaImage {
  public id = ""; // Unique identifier for the media image.
  public name = ""; // Name of the media image.
  public type = ""; // Type of the media image, e.g., 'jpeg', 'png'.
  public url = ""; // URL where the media image is located.
  public metaData: Record<string, string> = {}; // Metadata associated with the image, stored as key-value pairs.

  // Constructor for the MediaImage class.
  constructor(mediaImage?: MediaImage) {
    // If an instance of MediaImage is not provided, return immediately.
    if (!mediaImage) {
      return;
    }

    this.id = mediaImage.id;
    this.name = mediaImage.name;
    this.type = mediaImage.type;
    this.url = mediaImage.url;
    this.metaData = mediaImage.metaData;
  }
}
