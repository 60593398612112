import { Context } from "../security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export interface CompleteFirstTimeLoginStepsRequest {
  context: Context;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CompleteFirstTimeLoginStepsResponse {}

export interface CompletePlatformGuidedJourneyRequest {
  context: Context;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CompletePlatformGuidedJourneyResponse {}

const CompletedGuidesUpdaterServiceProviderName = "user-GuidesController";

export const GuidesController = {
  async CompleteFirstTimeLoginSteps(
    request: CompleteFirstTimeLoginStepsRequest,
    additionalHeaders?: Headers,
  ): Promise<CompleteFirstTimeLoginStepsResponse> {
    return await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${CompletedGuidesUpdaterServiceProviderName}.CompleteFirstTimeLoginSteps`,
        request,
      },
      { additionalHeaders },
    );
  },
  async CompletePlatformGuidedJourney(
    request: CompletePlatformGuidedJourneyRequest,
    additionalHeaders?: Headers,
  ): Promise<CompletePlatformGuidedJourneyResponse> {
    return await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${CompletedGuidesUpdaterServiceProviderName}.CompletePlatformGuidedJourney`,
        request,
      },
      { additionalHeaders },
    );
  },
};
