import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequestWithMiddleware";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface RequestDocumentUploadForInstrumentRequest {
  context: Context;
}

export interface RequestDocumentUploadForInstrumentResponse {
  documentID: string;
  uploadURL: string;
}

export interface RequestDocumentDownloadForInstrumentRequest {
  context: Context;
  documentID: string;
}

export interface RequestDocumentDownloadForInstrumentResponse {
  downloadURL: string;
}

export interface DeleteDocumentForInstrumentRequest {
  context: Context;
  documentID: string;
}

export const InstrumentDocumentController = {
  serviceProvider: "financial-InstrumentDocumentController",
  async RequestDocumentUploadForInstrument(
    request: RequestDocumentUploadForInstrumentRequest,
    additionalHeaders?: Headers,
  ): Promise<RequestDocumentUploadForInstrumentResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshAuthorizedURL"),
      method: `${InstrumentDocumentController.serviceProvider}.RequestDocumentUploadForInstrument`,
      request,
    });
  },
  async RequestDocumentDownloadForInstrument(
    request: RequestDocumentDownloadForInstrumentRequest,
    additionalHeaders?: Headers,
  ): Promise<RequestDocumentDownloadForInstrumentResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshAuthorizedURL"),
      method: `${InstrumentDocumentController.serviceProvider}.RequestDocumentDownloadForInstrument`,
      request,
    });
  },
  async DeleteDocumentForInstrument(
    request: DeleteDocumentForInstrumentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<void> {
    await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${InstrumentDocumentController.serviceProvider}.DeleteDocumentForInstrument`,
        request,
      },
      opts,
    );
  },
};
