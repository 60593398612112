import React from "react";
import { Box, Link, Typography } from "@mui/material";

interface KYCVerificationInProgressPlaceHolderProps {
  isKYBVerificationInProgress: boolean;
}

export function KYCVerificationInProgressPlaceHolder({
  isKYBVerificationInProgress,
}: KYCVerificationInProgressPlaceHolderProps) {
  const verificationInProgressText = (
    <>
      {isKYBVerificationInProgress ? (
        <Typography
          variant="h5"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
          fontSize={"14px"}
        >
          We are busy verifying your submitted information and documents, and
          will be in touch as soon as we complete the process.
        </Typography>
      ) : (
        <Typography
          variant="h5"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
          fontSize={"14px"}
        >
          We are busy verifying your submitted information, and will be in touch
          as soon as we complete the process.
        </Typography>
      )}
    </>
  );

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: theme.spacing(1),
      })}
    >
      <Box>
        <Typography
          variant="h4"
          sx={(theme) => ({
            fontWeight: "bold",
            color: theme.palette.text.primary,
            margin: theme.spacing(2, 0),
          })}
          children={"Verification in progress"}
        />
        {verificationInProgressText}
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            paddingTop: theme.spacing(2),
          })}
          variant="body1"
        >
          If you have any questions, please email us at
          <Link
            sx={(theme) => ({
              paddingLeft: theme.spacing(0.5),
            })}
            href="mailto:compliance@meshtrade.co"
            component="a"
            underline="hover"
            color="secondary"
          >
            compliance@meshtrade.co
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
}
