/* eslint-disable @typescript-eslint/no-empty-interface */
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Amount, Token } from "james/ledger";

const picassoURL = "https://mesh-dev-tokens.mesh.trade/api";

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InitialiseTapsRequest {}

export interface InitialiseTapsResponse {
  transactionID: string;
}

// tslint:disable-next-line:no-empty-interface
export interface ListTokensRequest {}

export interface ListTokensResponse {
  tokens: Token[];
}

// tslint:disable-next-line:no-empty-interface
export interface PourRequest {
  amount: Amount;
  destinationAccountAddress: string;
}

export interface PourResponse {
  transactionID: string;
}

export interface TokenTapServiceProvider {
  serviceProvider: string;

  InitialiseTaps(
    request: InitialiseTapsRequest,
  ): Promise<InitialiseTapsResponse>;

  ListTokens(request: ListTokensRequest): Promise<ListTokensResponse>;

  Pour(request: PourRequest): Promise<PourResponse>;
}

const TokenTapServiceProviderName = "stellar-TokenTap";

export const TokenTap: TokenTapServiceProvider = {
  serviceProvider: TokenTapServiceProviderName,
  async InitialiseTaps(
    request: InitialiseTapsRequest,
  ): Promise<InitialiseTapsResponse> {
    return jsonRPCRequestDEPRECATED({
      url: picassoURL,
      method: `${TokenTap.serviceProvider}.InitialiseTaps`,
      request,
      excludeCredentials: true,
    });
  },
  async ListTokens(request: ListTokensRequest): Promise<ListTokensResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: picassoURL,
      method: `${TokenTap.serviceProvider}.ListTokens`,
      request,
      excludeCredentials: true,
    });
    return { tokens: response.tokens.map((t: Token) => new Token(t)) };
  },
  async Pour(request: PourRequest): Promise<PourResponse> {
    return jsonRPCRequestDEPRECATED({
      url: picassoURL,
      method: `${TokenTap.serviceProvider}.Pour`,
      request,
      excludeCredentials: true,
    });
  },
};
