// source: financial/businessDayConvention.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.BusinessDayConvention', null, global);
/**
 * @enum {number}
 */
proto.financial.BusinessDayConvention = {
  UNDEFINED_BUSINESS_DAY_CONVENTION: 0,
  FOLLOWING_BUSINESS_DAY_CONVENTION: 1,
  MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION: 2,
  PRECEDING_BUSINESS_DAY_CONVENTION: 3,
  MODIFIED_PRECEDING_BUSINESS_DAY_CONVENTION: 4,
  UNADJUSTED_BUSINESS_DAY_CONVENTION: 5,
  HALF_MONTH_MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION: 6,
  NEAREST_BUSINESS_DAY_CONVENTION: 7
};

goog.object.extend(exports, proto.financial);
