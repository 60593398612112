import React, { useState } from "react";
import { Typography, Card, IconButton, Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { NewsArticle } from "james/marketing/NewsArticle";
import cx from "classnames";
import dayjs, { Dayjs } from "dayjs";

interface NewSectionProps {
  showScrollbar?: boolean;
  newsArticles: NewsArticle[];
  horizontal?: boolean;
}

export const NewsSection = ({
  showScrollbar,
  newsArticles,
  horizontal,
}: NewSectionProps) => {
  const [scrollEnd, setScrollEnd] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const isFullyScrolled = scrollTop + clientHeight >= scrollHeight;

    setScrollEnd(isFullyScrolled);
  };

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        News Articles
      </Typography>
      <Box sx={{ position: "relative" }}>
        <Box
          onScroll={handleScroll}
          className={cx({
            horizontal: horizontal,
            meshScroll: showScrollbar || horizontal,
          })}
          sx={(theme) => ({
            overflow: "auto",
            maxHeight: { xs: "347px", md: "427px", lg: "347px" },
            pr: { sm: 3 },
            "&.horizontal": {
              display: "flex",
              gap: 2,
              overflow: "auto",
              height: "110px",
              width: { lg: "800px", sm: "100%" },
            },
            [theme.breakpoints.down("sm")]: {
              "&::after": {
                content: '""',
                opacity: scrollEnd ? 0 : 1,
                transition: "opacity 200ms ease-in, opacity 400ms ease-out",
                position: "absolute",
                bottom: -1,
                width: "100%",
                height: 40,
                background: `linear-gradient(to top, ${theme.palette.custom.cardInner} 40%, transparent)`,
              },
            },
          })}
        >
          {newsArticles.map((v, i) => {
            return (
              <Box key={i}>
                <NewsCard
                  excerpt={v.excerpt}
                  url={v.url}
                  date={dayjs(v.date)}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

interface NewsCardProps {
  excerpt: string;
  url: string;
  date: Dayjs;
}

const NewsCard = ({ excerpt, url, date }: NewsCardProps) => {
  return (
    <Card
      sx={(theme) => ({
        position: "relative",
        mb: 2,
        backgroundColor: theme.palette.custom.midnight,
        p: 2,
        width: { sm: "232px" },
      })}
    >
      <Typography sx={(theme) => ({ color: theme.palette.warning.main })}>
        {date.format("DD MMM, YYYY")}
      </Typography>
      <Typography
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          WebkitLineClamp: 2,
          my: 1,
          mr: 1,
        }}
      >
        {excerpt}
      </Typography>
      <IconButton
        sx={(theme) => ({
          position: "absolute",
          top: 0,
          right: 0,
          color: theme.palette.text.secondary,
        })}
        href={url}
        target="_blank"
      >
        <LaunchIcon />
      </IconButton>
    </Card>
  );
};
