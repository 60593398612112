import { Box, Skeleton } from "@mui/material";
import range from "lodash/range";
import React from "react";

export const ParticipantsLoader = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Skeleton width={250} height={32} />
      <Box sx={{ mr: 2, display: "flex", justifyContent: "space-between" }}>
        <Skeleton height={150} width={500} />
        <Skeleton height={150} width={155} />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 3,
          mt: 1,
        }}
      >
        {range(6).map((i) => (
          <Box key={i}>
            <Skeleton height="12" width="160px" />
            <Skeleton height="18" width="180px" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
