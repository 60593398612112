import { Context } from "james/security";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { Model } from "./Model";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface UnscopedReadRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface UnscopedReadResponse {
  models: Model[];
  total: number;
}

export const UnscopedReader = {
  serviceProvider: "stellarTransactionHistoryView-UnscopedReader",
  async Read(request: UnscopedReadRequest): Promise<UnscopedReadResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${UnscopedReader.serviceProvider}.UnscopedRead`,
      request,
    });
    return {
      models: response.models.map((m: Model) => new Model(m)),
      total: response.total,
    };
  },
};
