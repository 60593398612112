import React from "react";
import { Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";

export function LoadingEllipses() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        gap: theme.spacing(1),
        padding: theme.spacing(2, 0, 0),
      }}
    >
      <motion.div
        style={{
          borderRadius: "100%",
          backgroundColor: theme.palette.text.primary,
          width: theme.spacing(0.5),
          height: theme.spacing(0.5),
          fontSize: theme.spacing(2),
        }}
        initial={{ opacity: -1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, repeat: Infinity }}
      />
      <motion.div
        style={{
          borderRadius: "100%",
          backgroundColor: theme.palette.text.primary,
          width: theme.spacing(0.5),
          height: theme.spacing(0.5),
          fontSize: theme.spacing(2),
        }}
        initial={{ opacity: -1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, repeat: Infinity, delay: 0.25 }}
      />
      <motion.div
        style={{
          borderRadius: "100%",
          backgroundColor: theme.palette.text.primary,
          width: theme.spacing(0.5),
          height: theme.spacing(0.5),
          fontSize: theme.spacing(2),
        }}
        initial={{ opacity: -1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, repeat: Infinity, delay: 0.5 }}
      />
    </Box>
  );
}
