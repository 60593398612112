import { BooleanCriterionTypeName } from "james/search/criterion";

export interface BooleanCriterionType {
  type: string;
  value: boolean;
}

function BooleanCriterion(value: boolean): BooleanCriterionType {
  return {
    type: BooleanCriterionTypeName,
    value,
  };
}

export { BooleanCriterion };
