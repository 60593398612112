import { useRef } from "react";
import { useExchangeDashboardStore } from "../store";
import * as StellarSDK from "stellar-sdk";
import { BigNumber } from "bignumber.js";
import { useExchangeStore } from "../../../store";
import { Amount, Token } from "james/ledger";
import { useStellarContext } from "context/Stellar";
import { StellarNetwork } from "james/stellar";
import { StellarTrade } from "pkgTemp/stellar/Trades";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";
import dayjs from "dayjs";

export const useFetchAndStreanTrades = () => {
  const store = useExchangeDashboardStore();
  const { stellarTradesViewer } = useStellarContext();
  const exchangeStore = useExchangeStore();
  const endTradeStreamRef = useRef<() => void>();

  // Fetch and stream trades
  const fetchAndStreamTrades = async () => {
    if (!exchangeStore.marketListingPair) return;

    const pair = exchangeStore.marketListingPair;

    let baseAsset = StellarSDK.Asset.native();
    let counterAsset = StellarSDK.Asset.native();

    // Change Asset Pairs if either asset is not XLM;
    if (
      pair.base.code !== baseAsset.code &&
      pair.base.issuer !== baseAsset.issuer
    ) {
      baseAsset = new StellarSDK.Asset(pair.base.code, pair.base.issuer);
    }
    if (
      pair.counter.code !== counterAsset.code &&
      pair.counter.issuer !== counterAsset.issuer
    ) {
      counterAsset = new StellarSDK.Asset(
        pair.counter.code,
        pair.counter.issuer,
      );
    }

    const handleNewTrades = (
      newTrades: StellarSDK.Horizon.ServerApi.TradeRecord[],
    ) => {
      store.updateTradesListState.serverConnectionStatus(
        ServerConnectionStatus.Connected,
      );
      const pair = exchangeStore.marketListingPair;

      const newData = newTrades.map(
        (tr): StellarTrade => ({
          type: tr.base_is_seller ? "Sell" : "Buy",
          tradeType: tr.trade_type,
          id: tr.id,
          ledgerCloseTime: tr.ledger_close_time,
          counterAmount: new Amount({
            value: new BigNumber(tr.counter_amount),
            token: new Token({
              code: pair?.counter.code ?? "-",
              issuer: pair?.counter.issuer ?? "-",
              network: pair?.counter.network ?? StellarNetwork.TestSDFNetwork,
            }),
          }),
          baseAmount: new Amount({
            value: new BigNumber(tr.base_amount),
            token: new Token({
              code: pair?.base.code ?? "-",
              issuer: pair?.base.issuer ?? "-",
              network: pair?.base.network ?? StellarNetwork.TestSDFNetwork,
            }),
          }),
          price: new Amount({
            value: new BigNumber(tr.price?.n ?? "").dividedBy(
              tr.price?.d ?? "",
            ),
            token: new Token({
              code: pair?.counter.code ?? "-",
              issuer: pair?.counter.issuer ?? "-",
              network: pair?.counter.network ?? StellarNetwork.TestSDFNetwork,
            }),
          }),
        }),
      );

      store.updateTradesListState.pushTrade(newData);
    };

    const onError = () => {
      store.updateTradesListState.serverConnectionStatus(
        ServerConnectionStatus.Disconnected,
      );
    };

    if (endTradeStreamRef.current) {
      endTradeStreamRef.current();
    }
    try {
      store.updateTradesListState.loading(true);
      store.updateTradesListState.serverConnectionStatus(
        ServerConnectionStatus.Connecting,
      );

      const response = await stellarTradesViewer.FetchTradesForPair({
        baseAsset: baseAsset,
        counterAsset: counterAsset,
        cursor: "now",
        order: "desc",
        limit: 20,
      });

      const allTrades: Record<string, StellarTrade[]> = {};
      const dates: string[] = [];
      for (const trade of response.trades) {
        const date = dayjs(trade.ledgerCloseTime).format("YYYY/MM/DD");
        if (!date) continue;
        if (allTrades[date]) {
          allTrades[date].push(trade);
        } else {
          allTrades[date] = [trade];
          dates.push(date);
        }
      }

      store.updateTradesListState.tradesList(response.trades);
      store.updateTradesListState.allTrades(allTrades);
      store.updateTradesListState.datesList(dates);
      store.updateTradesListState.loading(false);
      store.updateTradesListState.serverConnectionStatus(
        ServerConnectionStatus.Connected,
      );

      endTradeStreamRef.current = await stellarTradesViewer.StreamTradesForPair(
        {
          baseAsset: baseAsset,
          counterAsset: counterAsset,
          onTrades: (newTrades) => handleNewTrades(newTrades),
          onError: onError,
        },
      );
    } catch (err) {
      console.error(err);
    }

    return () => {
      if (endTradeStreamRef.current) {
        endTradeStreamRef.current();
      }
    };
  };

  return {
    fetchAndStreamTrades,
  };
};
