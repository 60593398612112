import { Context } from "james/security";
import { Person } from "james/legal/person";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface UpdateMyPersonRequest {
  context: Context;
  person: Person;
}

export interface UpdateMyPersonResponse {
  person: Person;
}

export const ManagerWrite = {
  serviceProvider: "person-ManagerWrite",
  async UpdateMyPerson(
    request: UpdateMyPersonRequest,
    additionalHeaders?: Headers,
  ): Promise<UpdateMyPersonResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${ManagerWrite.serviceProvider}.UpdateMyPerson`,
        request,
      },
      {
        additionalHeaders: additionalHeaders,
      },
    );
    return { person: new Person(response.person) };
  },
};
