import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { useEffect, useRef, useState } from "react";
import { FiatCurrency } from ".";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveFiatCurrencyRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveFiatCurrencyResponse {
  fiatCurrency: FiatCurrency;
}

export interface SearchFiatCurrencyRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchFiatCurrencyResponse {
  records: FiatCurrency[];
  total: number;
}

export const FiatCurrencyRepository = {
  serviceProvider: "financial-FiatCurrencyRepository",
  async RetrieveFiatCurrency(
    request: RetrieveFiatCurrencyRequest,
  ): Promise<RetrieveFiatCurrencyResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${FiatCurrencyRepository.serviceProvider}.RetrieveFiatCurrency`,
      request,
    });
    return { fiatCurrency: new FiatCurrency(response.fiatCurrency) };
  },
  async SearchFiatCurrency(
    request: SearchFiatCurrencyRequest,
  ): Promise<SearchFiatCurrencyResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${FiatCurrencyRepository.serviceProvider}.SearchFiatCurrency`,
      request,
    });
    return {
      records: response.records.map((c: FiatCurrency) => new FiatCurrency(c)),
      total: response.total,
    };
  },
};

export function useSearchFiatCurrency(
  initialSearchFiatCurrencyRequest?: SearchFiatCurrencyRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchFiatCurrencyRequest, setSearchFiatCurrencyRequest] =
    useState<SearchFiatCurrencyRequest>(
      initialSearchFiatCurrencyRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchFiatCurrencyResponse, setSearchFiatCurrencyResponse] =
    useState<SearchFiatCurrencyResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchFiatCurrencyResponse(
          await FiatCurrencyRepository.SearchFiatCurrency(
            searchFiatCurrencyRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for fiat currency: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for fiat currency: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchFiatCurrencyRequest, shouldNotExecute]);

  return {
    searchFiatCurrencyRequest,
    setSearchFiatCurrencyRequest,
    searchFiatCurrencyResponse,
    loading,
    error,
  };
}
