"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.criteriaFromToken = criteriaFromToken;
const search_1 = require("../search");
function criteriaFromToken(token) {
    return [
        (0, search_1.newTextExactCriterion)("token.code", token.getCode()),
        (0, search_1.newTextExactCriterion)("token.issuer", token.getIssuer()),
        (0, search_1.newUint32ExactCriterion)("token.network", token.getNetwork()),
    ];
}
