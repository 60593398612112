import React from "react";
import { HeaderProps } from "components/Cards/MarketDirectOrderViewCard/Components/Header/Header";
import { Listing, QuoteParameter } from "james/market";
import { Balance } from "james/stellar/Account";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { Model as MarketDirectOrderViewModel } from "james/views/marketDirectOrderView";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import { CounterpartyCard as CounterpartyView } from "./CounterpartyCard";
import { InitiatingPartyCard } from "./InitiatingPartyCard";
import { MarketListingViewModel } from "james/views/marketListingView";

export interface IssuerAwaitingConfirmationCardProps extends HeaderProps {
  onActionComplete: () => void;
  refreshCard: () => void;
  tradingAccViewModel: StellarAccountViewModel;
  viewingAsInitiatingParty: boolean;
  listing: Listing;
  marketListingViewModel: MarketListingViewModel;
  marketDirectOrderViewModel: MarketDirectOrderViewModel;
  tradingAccountID: string;
  userIsSignatoryOnTradingAcc: boolean;
  assetIssuanceTokenBalance: Balance;
  assetIssuanceTokenViewModel: LedgerTokenViewModel;
  assetValuationTokenBalance: Balance;
  assetValuationTokenViewModel: LedgerTokenViewModel;
  marketMechanismQuoteParameter: QuoteParameter;
  close?: () => void;
}

export function IssuerAwaitingConfirmationCard(
  props: IssuerAwaitingConfirmationCardProps,
) {
  return props.viewingAsInitiatingParty ? (
    <InitiatingPartyCard {...props} />
  ) : (
    <CounterpartyView {...props} />
  );
}
