import BigNumber from "bignumber.js";
import { Token } from "../ledger/Token";
import { IndependentReview } from "./IndependentReviews";
import { MarketingMedia } from "./MarketingMedia";
import { NewsArticle } from "./NewsArticle";
import { SocialMediaLinks } from "./SocialMediaLinks";

export const InstrumentMarketingContentName =
  "mesh::marketing/InstrumentMarketingContent";

export class InstrumentMarketingContent {
  public ["@type"]: string = InstrumentMarketingContentName;

  public id = "";
  public ownerID = "";
  public token: Token = new Token();
  public estimatedAnnualReturn: BigNumber = new BigNumber(0);
  public socialMediaLinks: SocialMediaLinks = new SocialMediaLinks();
  public investmentObjective = "";
  public independentReviews: IndependentReview[] = [];
  public marketMedia: MarketingMedia[] = [];
  public newsArticles: NewsArticle[] = [];

  constructor(instrumentMarketingContent?: InstrumentMarketingContent) {
    if (!instrumentMarketingContent) {
      return;
    }
    this.id = instrumentMarketingContent.id;
    this.ownerID = instrumentMarketingContent.ownerID;
    this.token = new Token(instrumentMarketingContent.token);
    this.estimatedAnnualReturn =
      instrumentMarketingContent.estimatedAnnualReturn;
    this.socialMediaLinks = instrumentMarketingContent.socialMediaLinks;
    this.investmentObjective = instrumentMarketingContent.investmentObjective;
    this.independentReviews = instrumentMarketingContent.independentReviews;
    this.marketMedia = instrumentMarketingContent.marketMedia;
    this.newsArticles = instrumentMarketingContent.newsArticles;
  }

  marketingMediaId(): string {
    return this.id;
  }

  assetOwnerId(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  instrumentEstimatedAnnualReturn(): BigNumber {
    return this.estimatedAnnualReturn;
  }

  instrumentSocialMediaLinks(): SocialMediaLinks {
    return this.socialMediaLinks;
  }

  instrumentInvestmentObjective(): string {
    return this.investmentObjective;
  }

  instrumentIndependentReviews(): IndependentReview[] {
    return this.independentReviews;
  }

  instrumentMarketingMedia(): MarketingMedia[] {
    return this.marketMedia;
  }

  instrumentNewsArticles(): NewsArticle[] {
    return this.newsArticles;
  }
}
