import { Card, CardHeader, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import cx from "classnames";
import CardContent from "@mui/material/CardContent";

const PREFIX = "AdministrationDashboard";

const classes = {
  root: `${PREFIX}-root`,
  textBox: `${PREFIX}-textBox`,
  boldText: `${PREFIX}-boldText`,
  cardRoot: `${PREFIX}-cardRoot`,
  cardBox: `${PREFIX}-cardBox`,
  cardRightMargin: `${PREFIX}-cardRightMargin`,
  cardLeftMargin: `${PREFIX}-cardLeftMargin`,
  instructionRow: `${PREFIX}-instructionRow`,
  typographyCenterText: `${PREFIX}-typographyCenterText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    width: "800",
    flexDirection: "column",
    padding: theme.spacing(6, 16, 6, 16),
  },

  [`& .${classes.textBox}`]: {
    display: "grid",
    gridTemplateRows: "repeat(3,auto)",
    gridTemplateColumns: "585px",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.cardRoot}`]: {
    width: "368px",
    height: "400px",
  },

  [`& .${classes.cardBox}`]: {
    display: "flex",
    width: "800",
    marginTop: theme.spacing(4),
  },

  [`& .${classes.cardRightMargin}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.cardLeftMargin}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.instructionRow}`]: {
    display: "grid",
    gridTemplateRows: "330x",
    gridTemplateColumns: "24px 281px",
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.typographyCenterText}`]: {
    display: "flex",
    alignItems: "center",
  },
}));

export function AdministrationDashboard() {
  return (
    <Root className={classes.root}>
      <div className={classes.textBox}>
        <Typography component="p" variant="h1">
          Welcome to
          <Typography
            className={classes.boldText}
            component="span"
            color="primary"
            variant="h1"
          >
            {" "}
            Administration
          </Typography>
        </Typography>
        <Typography variant="body1">
          There’s always something new happening on Mesh. Keep an eye on this
          page for regular updates and news.
        </Typography>
        <Typography variant="body1">
          Manage your teams, users and access rights. Find out more here
        </Typography>
      </div>
      <div className={classes.cardBox}>
        <Card className={cx(classes.cardRoot, classes.cardRightMargin)}>
          <CardHeader title="How to setup groups:" />
          <CardContent>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                1.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Navigate to the Administration Menu item.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                2.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Select Add Group in the top right corner.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                3.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Complete the details and select Create Group.
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card className={cx(classes.cardRoot, classes.cardLeftMargin)}>
          <CardHeader title="How to invite users:" />
          <CardContent>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                1.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Navigate to the Administration Menu item.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                2.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Select Invite User in the top right corner.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                3.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                You can either add a user who is already registered on Mesh to
                your group OR you can invite a new user to join Mesh.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                4.
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Complete the necessary information and select the User’s Roles.
              </Typography>
            </div>
            <div className={classes.instructionRow}>
              <Typography
                className={classes.boldText}
                variant="h2"
                color="textSecondary"
              >
                5.
              </Typography>
              <Typography
                className={classes.typographyCenterText}
                color="textSecondary"
                variant="body1"
              >
                Select Add.
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </Root>
  );
}
