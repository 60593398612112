import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Token } from "james/ledger";

export interface DoesListingForTokenExistRequest {
  context: Context;
  token: Token;
}

export interface DoesListingForTokenExistResponse {
  exists: boolean;
}

const ListingInspectorServiceProviderName = "market-ListingInspector";

export const ListingInspector = {
  async DoesListingForTokenExist(
    request: DoesListingForTokenExistRequest,
  ): Promise<DoesListingForTokenExistResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${ListingInspectorServiceProviderName}.DoesListingForTokenExist`,
      request,
    });
  },
};
