import { Context } from "james/security";
import { Query } from "james/search/query";
import { useEffect, useRef, useState } from "react";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Model } from "./Model";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface UnscopedReadRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface UnscopedReadResponse {
  models: Model[];
  total: number;
}

export interface UnscopedReadOneRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface UnscopedReadOneResponse {
  model: Model;
}

const UnscopedReader = {
  serviceProvider: "marketSpotView-UnscopedReader",
  async UnscopedRead(
    request: UnscopedReadRequest,
  ): Promise<UnscopedReadResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${UnscopedReader.serviceProvider}.UnscopedRead`,
      request,
    });
    return {
      models: response.models.map((m: Model) => new Model(m)),
      total: response.total,
    };
  },
  async UnscopedReadOne(
    request: UnscopedReadOneRequest,
  ): Promise<UnscopedReadOneResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${UnscopedReader.serviceProvider}.UnscopedReadOne`,
      request,
    });
    return {
      model: new Model(response.model),
    };
  },
};

export function useUnscopedRead(
  initialUnscopedReadRequest?: UnscopedReadRequest,
) {
  const { authContext } = useApplicationContext();
  const [unscopedReadRequest, setUnscopedReadRequest] =
    useState<UnscopedReadRequest>(
      initialUnscopedReadRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [unscopedReadResponse, setUnscopedReadResponse] =
    useState<UnscopedReadResponse>({
      total: 0,
      models: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    const unscopedRead = async () => {
      setLoading(true);
      setError(undefined);
      try {
        setUnscopedReadResponse(
          await UnscopedReader.UnscopedRead(unscopedReadRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading market direct order view: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error reading market direct order view: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    };
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(unscopedRead, 400);
  }, [unscopedReadRequest]);

  return {
    unscopedReadRequest,
    setUnscopedReadRequest,
    unscopedReadResponse,
    loading,
    error,
  };
}
