import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { Box, Button, Typography } from "@mui/material";

import { Company } from "james/legal/company";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import {
  ContactDetailFormFieldValidationState,
  FormFieldsValidation,
} from "./Validation";

import {
  HeadOfficeAddress,
  BusinessAddress,
  RegisteredAddress,
} from "./components";

const PREFIX = "ContactDetails";

const classes = {
  addressLayout: `${PREFIX}-addressLayout`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.addressLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}));

interface ContactDetailsProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue: (name: string) => (value: any) => void;
  company: Company;
  businessAddressSame: boolean;
  setBusinessAddressSame: (value: boolean) => void;
  headOfficeAddressSame: boolean;
  setHeadOfficeAddressSame: (value: boolean) => void;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
}

export const ContactDetails: React.FC<ContactDetailsProps> = (
  props: ContactDetailsProps,
) => {
  const {
    company,
    onChangeValue,
    onNext,
    businessAddressSame,
    setBusinessAddressSame,
    headOfficeAddressSame,
    setHeadOfficeAddressSame,
    invalidStep,
    inValid,
  } = props;

  const [invalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>({ ...ContactDetailFormFieldValidationState });

  const [ranValidation, setRanValidation] = useState(false);

  const nextStep = () => {
    if (
      FormFieldsValidation(invalidFormFields, company, setInvalidFormFields)
    ) {
      onNext(CompanyRegStep.companyRepresentative)();
      invalidStep(CompanyRegStep.contactDetails, false);
    } else {
      onNext(CompanyRegStep.companyRepresentative)();
      invalidStep(CompanyRegStep.contactDetails, true);
    }
  };

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(invalidFormFields, company, setInvalidFormFields);
  }

  useEffect(() => {
    (() => {
      if (businessAddressSame) {
        setInvalidFormFields((value) => {
          const InvalidFormFields = { ...value };
          delete InvalidFormFields.businessAddressAddressLine1;
          delete InvalidFormFields.businessAddressCity;
          delete InvalidFormFields.businessAddressCountryCode;
          return InvalidFormFields;
        });
      }
    })();
  }, [businessAddressSame]);

  useEffect(() => {
    (() => {
      if (headOfficeAddressSame) {
        setInvalidFormFields((value) => {
          const InvalidFormFields = { ...value };
          delete InvalidFormFields.headOfficeAddressCity;
          delete InvalidFormFields.headOfficeAddressCountryCode;
          delete InvalidFormFields.headOfficeAddressAddressLine1;
          return InvalidFormFields;
        });
      }
    })();
  }, [headOfficeAddressSame]);

  return (
    <Root>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary">
            Company Contact Information
          </Typography>
        </Box>
        <Button
          id="contactDetails-next"
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          Next
        </Button>
      </Box>

      <Typography
        variant="subtitle2"
        style={{ marginTop: "8px", marginBottom: "32px" }}
      >
        Add your Registered-, Business- and Head Office address. They can all be
        the same.
      </Typography>

      <div className={classes.addressLayout}>
        <RegisteredAddress
          onChangeValue={onChangeValue}
          regAddress={company.registeredAddress}
          FormFieldValid={invalidFormFields}
          setFormFieldValid={setInvalidFormFields}
        />

        <HeadOfficeAddress
          headOfficeAddressSame={headOfficeAddressSame}
          setHeadOfficeAddressSame={setHeadOfficeAddressSame}
          onChangeValue={onChangeValue}
          company={company}
          FormFieldValid={invalidFormFields}
          setFormFieldValid={setInvalidFormFields}
        />

        <BusinessAddress
          businessAddressSame={businessAddressSame}
          setBusinessAddressSame={setBusinessAddressSame}
          onChangeValue={onChangeValue}
          company={company}
          FormFieldValid={invalidFormFields}
          setFormFieldValid={setInvalidFormFields}
        />
      </div>
    </Root>
  );
};
