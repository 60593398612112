import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";

export const ErrSubscriptionOrderBookNotFoundCode = 200316;

export interface RetrieveSubscriptionOrderBookRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveSubscriptionOrderBookResponse {
  subscriptionOrderBook: SubscriptionOrderBook;
}

export interface SearchSubscriptionOrderBooksRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchSubscriptionOrderBooksResponse {
  records: SubscriptionOrderBook[];
  total: number;
}

export const SubscriptionOrderBookRepository = {
  serviceProvider: "market-SubscriptionOrderBookRepository",
  async RetrieveSubscriptionOrderBook(
    request: RetrieveSubscriptionOrderBookRequest,
  ): Promise<RetrieveSubscriptionOrderBookResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${SubscriptionOrderBookRepository.serviceProvider}.RetrieveSubscriptionOrderBook`,
      request,
    });
    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
  async SearchSubscriptionOrderBooks(
    request: SearchSubscriptionOrderBooksRequest,
  ): Promise<SearchSubscriptionOrderBooksResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${SubscriptionOrderBookRepository.serviceProvider}.SearchSubscriptionOrderBooks`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map(
        (m: SubscriptionOrderBook) => new SubscriptionOrderBook(m),
      ),
    };
  },
};
