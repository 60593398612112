import { Box, Button, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Context } from "james/security";
import { Company } from "james/legal/company";
import { Address } from "james/location";
import { InviteClientClaims } from "james/security/claims";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CompanyClientRegistrar } from "james/onboarding/companyClientRegistrar";
import { useErrorContext } from "context/Error";

interface ConfirmationProps {
  inviteClientClaims: InviteClientClaims;
  company: Company;
  admin: { name: string; surname: string; email: string };
}

function setAddressesUndefined(company: Company): Company {
  if (isEqual(new Address(company.businessAddress), new Address())) {
    company.businessAddress = new Address();
  }

  if (isEqual(new Address(company.headOfficeAddress), new Address())) {
    company.headOfficeAddress = new Address();
  }

  company.connectedIndividuals = company.connectedIndividuals.map(
    (connectedIndividual) => {
      if (
        isEqual(new Address(connectedIndividual.postalAddress), new Address())
      ) {
        connectedIndividual.postalAddress = new Address();
      }

      return connectedIndividual;
    },
  );

  company.connectedCompanies = company.connectedCompanies.map(
    (connectedCompany) => {
      if (
        isEqual(new Address(connectedCompany.businessAddress), new Address())
      ) {
        connectedCompany.businessAddress = new Address();
      }

      if (
        isEqual(new Address(connectedCompany.headOfficeAddress), new Address())
      ) {
        connectedCompany.headOfficeAddress = new Address();
      }

      return connectedCompany;
    },
  );

  if (
    isEqual(
      new Address(company.companyRepresentative.postalAddress),
      new Address(),
    )
  ) {
    company.companyRepresentative.postalAddress = undefined;
  }

  return company;
}

export const Confirmation: React.FC<ConfirmationProps> = (
  props: ConfirmationProps,
) => {
  const { errorContextErrorTranslator } = useErrorContext();
  const [loading, setLoading] = useState(false);
  const [registrationDone, setRegistrationDone] = useState(false);
  const [navigateHome, setNavigateHome] = useState(false);
  const { company, admin } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleRegistration = async () => {
    setLoading(true);
    try {
      const authContext = new Context({
        userID: props.inviteClientClaims.invitingUserID,
      });
      await CompanyClientRegistrar.RegisterCompanyClient({
        context: authContext,
        company: setAddressesUndefined(company),
        administratorFirstName: admin.name,
        administratorLastName: admin.surname,
        administratorEmail: admin.email,
      });
      enqueueSnackbar("Company Registered", {
        variant: "success",
      });
      setRegistrationDone(true);
      setTimeout(() => setNavigateHome(true), 3000);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error("error registering company", e);
      enqueueSnackbar(`error registering company: ${err.message}`, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  if (navigateHome) {
    return <Navigate replace to="/" />;
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      })}
    >
      <Typography variant="h4" color="secondary">
        The company registration is just about complete.
      </Typography>
      <Typography variant="subtitle1">
        Click the button below to submit your company registration.
      </Typography>
      {!registrationDone && (
        <div>
          <Button
            id="companyRegistration-confirmation-finish"
            variant="contained"
            color="primary"
            onClick={handleRegistration}
            disabled={loading}
          >
            submit
          </Button>
        </div>
      )}
      {loading && <CircularProgress />}
    </Box>
  );
};
