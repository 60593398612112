import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Token } from "james/ledger";

export interface CheckForLiquidityPoolRegistrationByTokensRequest {
  context: Context;
  token1: Token;
  token2: Token;
}

export interface CheckForLiquidityPoolRegistrationByTokensResponse {
  exists: boolean;
}

const LiquidityPoolRegistrationInspectorServiceProviderName =
  "stellar-LiquidityPoolRegistrationInspector";

export const LiquidityPoolRegistrationInspector = {
  async CheckForLiquidityPoolRegistrationByTokens(
    request: CheckForLiquidityPoolRegistrationByTokensRequest,
  ): Promise<CheckForLiquidityPoolRegistrationByTokensResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${LiquidityPoolRegistrationInspectorServiceProviderName}.CheckForLiquidityPoolRegistrationByTokens`,
      request,
    });
  },
};
