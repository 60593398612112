import { Chip, ChipProps } from "@mui/material";
import { DirectOrderState } from "james/market/DirectOrder";

export interface DirectOrderStateChipProps {
  state: DirectOrderState | "";
  chipProps?: ChipProps;
}

export function DirectOrderStateChip(props: DirectOrderStateChipProps) {
  switch (props.state) {
    case DirectOrderState.Pending:
      return (
        <Chip
          {...props.chipProps}
          color="warning"
          id="directOrderStateChip-pending-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.AwaitingConfirmation:
      return (
        <Chip
          {...props.chipProps}
          color="secondary"
          id="directOrderStateChip-awaitingConfirmation-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Accepted:
      return (
        <Chip
          {...props.chipProps}
          color="secondary"
          id="directOrderStateChip-accepted-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Settled:
      return (
        <Chip
          {...props.chipProps}
          color="success"
          id="directOrderStateChip-settled-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Cancelling:
      return (
        <Chip
          {...props.chipProps}
          color="secondary"
          id="directOrderStateChip-cancelling-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Cancelled:
      return (
        <Chip
          {...props.chipProps}
          color="secondary"
          id="directOrderStateChip-cancelled-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Declining:
      return (
        <Chip
          {...props.chipProps}
          color="warning"
          id="directOrderStateChip-declining-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Declined:
      return (
        <Chip
          {...props.chipProps}
          color="warning"
          id="directOrderStateChip-declined-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Failing:
      return (
        <Chip
          {...props.chipProps}
          color="error"
          id="directOrderStateChip-failing-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.Failed:
      return (
        <Chip
          {...props.chipProps}
          color="error"
          id="directOrderStateChip-failed-chip"
          size="small"
          label={props.state}
        />
      );

    case DirectOrderState.ClearanceFailed:
      return (
        <Chip
          {...props.chipProps}
          color="secondary"
          id="directOrderStateChip-processing-chip"
          size="small"
          label={"Clearance Failed (Processing)"}
        />
      );

    case DirectOrderState.UnderInvestigation:
      return (
        <Chip
          {...props.chipProps}
          color="error"
          id="directOrderStateChip-underInvestigation-chip"
          size="small"
          label={props.state}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}
