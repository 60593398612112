import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Box, Collapse, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useWindowSize } from "utilities/general";
import { NewSorting, Query } from "james/search/query";
import {
  Model as DirectOrderModel,
  Reader,
} from "james/views/marketDirectOrderView";
import { TableMode } from "../../../Table";
import { LoadingRow } from "./LoadingRow";
import { NoDataSplash } from "./NoDataSplash";
import { OrderScrollCard } from "./OrderScrollCard";
import { InfiniteScrollList } from "components/Table/InfCardTable";
import {
  DirectOrderSearchCriteria,
  FilterValues,
  GetDirectOrderSearchCriteria,
} from "../OrdersScrollList";
import { OrderScrollFilters } from "./OrderScrollFilters";
import { FancyTableModeButton } from "views/Marketplace/components/Orders/components/FancyTableModeButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

interface HistoryScrollListProps {
  setSelectedOrder: Dispatch<SetStateAction<DirectOrderModel | undefined>>;
  changeTableMode: (updatedTableMode: TableMode) => void;
}

export function HistoryScrollList({
  setSelectedOrder,
  changeTableMode,
}: HistoryScrollListProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, windowHeight] = useWindowSize();
  const { authContext } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [history, setHistory] = useState<DirectOrderModel[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const { NotificationBannerHeight } = useAppNoticeContext();
  const [filterValues, setFilterValues] = useState<FilterValues>({
    textSearch: "",
    orderStatuses: [],
  });

  const loadMoreItems =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (criteria: any, query: Query, updateOffset: () => void) => async () => {
      setLoading(true);

      try {
        const response = await Reader.Read({
          context: authContext,
          query,
          criteria,
        });

        updateOffset();

        setHistory([...history, ...response.models]);

        setHistoryTotal(response.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading market spot view models: ${
            err.message ? err.message : err.toString()
          }`,
          enqueueSnackbar(
            `Error reading market spot view models:: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          ),
        );
      }
      setLoading(false);
    };

  const handleChangeTableMode = () => {
    changeTableMode(TableMode.awaitingConfirmation);
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(0, 2, 0, 2),
        width: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          height: "64px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: theme.spacing(2),
        })}
      >
        <FancyTableModeButton
          tableMode={TableMode.history}
          onTableModeChange={handleChangeTableMode}
        />
        {historyTotal > 0 && (
          <IconButton
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            <FilterListIcon />
          </IconButton>
        )}
      </Box>
      <InfiniteScrollList
        total={historyTotal}
        loadMoreData={loadMoreItems}
        data={history}
        rowWidth={windowWidth - 32}
        rowHeight={220}
        clearData={() => {
          setHistory([]);
        }}
        rowGap={16}
        listHeight={
          // 75: mobile nav bar
          // 56: main app bar
          // 86: fancy filter button & co
          showFilters
            ? windowHeight - 75 - 56 - 86 - 256 - 48 - NotificationBannerHeight
            : windowHeight - 75 - 56 - 86 - 48 - NotificationBannerHeight
        }
        disableMeshScroll={true}
        hideScrollbars={true}
        lastRowPadding={24}
        initialSorting={[NewSorting("lastModifiedTime", "desc")]}
        initialCriteria={GetDirectOrderSearchCriteria({
          ...filterValues,
          tableMode: TableMode.history,
        })}
        renderLoadingRow={<LoadingRow />}
        noDataSplashComponent={
          <NoDataSplash
            text="No Orders Yet?"
            subText="You will see a list once you have actioned an order."
          />
        }
        isLoading={loading}
        renderData={function (data): ReactNode {
          return (
            <OrderScrollCard
              data={data}
              tableMode={TableMode.history}
              onClick={() => {
                setSelectedOrder(data);
              }}
            />
          );
        }}
        children={({ setCriteria }) => {
          return (
            <>
              <Collapse
                in={showFilters}
                sx={(theme) => ({
                  padding: theme.spacing(showFilters ? 2 : 1, 0),
                })}
              >
                <OrderScrollFilters
                  setFilter={setFilterValues}
                  filterValues={filterValues}
                  setCriteria={
                    setCriteria as React.Dispatch<
                      React.SetStateAction<DirectOrderSearchCriteria>
                    >
                  }
                  loading={loading}
                />
              </Collapse>
            </>
          );
        }}
      />
    </Box>
  );
}
