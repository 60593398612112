import React, { useContext, useEffect, useRef, useState } from "react";
import config from "react-global-configuration";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { Box, Button, Theme, Typography } from "@mui/material";
import { SystemInspector } from "james/system";
import { Environment } from "const";
import background from "assets/images/background/background.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContextType {}

const Context = React.createContext({} as ContextType);

const expectedMeshCoreVersion = "v1";

export function VersionCheckContext({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { current: doCheck } = useRef(
    [Environment.Production, Environment.Staging].includes(
      config.get("environment"),
    ),
  );
  const [loading, setLoading] = useState(true);
  const [matching, setMatching] = useState(!doCheck);

  useEffect(() => {
    // only perform check if required
    if (!doCheck) {
      setLoading(false);
      return;
    }

    // perform check
    (async () => {
      try {
        // get current mesh core version
        const meshCoreVersion = (await SystemInspector.GetJamesVersion({}))
          .version;

        // extract major version
        const majorVersion = meshCoreVersion.slice(
          0,
          meshCoreVersion.indexOf("."),
        );

        // compute and set result
        const result =
          majorVersion.toUpperCase() === expectedMeshCoreVersion.toUpperCase();
        if (!result) {
          console.warn(
            `meshCore major version '${majorVersion}' (from version '${meshCoreVersion}') doesn't match expected version '${expectedMeshCoreVersion}'`,
          );
        }
        setMatching(result);
      } catch (e) {
        const err = e as Error;
        console.error(
          `error getting mesh core version: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }

      setLoading(false);
    })();
  }, []);

  return (
    <Context.Provider value={{}}>
      {matching ? (
        children
      ) : (
        <Box
          sx={(theme: Theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: "100vh",
            width: "100%",
            gap: theme.spacing(3),
          })}
        >
          <img
            onClick={() => location.reload()}
            alt=""
            width={100}
            src={meshMiniLogo}
          />
          {!loading && (
            <>
              <Typography
                variant="h5"
                sx={(theme) => ({
                  textAlign: "center",
                  color: theme.palette.custom.lavender,
                })}
                children={"A newer version of the application is available!"}
              />
              <Button
                onClick={() => location.reload()}
                color={"primary"}
                variant={"contained"}
              >
                Please Refresh
              </Button>
            </>
          )}
        </Box>
      )}
    </Context.Provider>
  );
}

const useVersionCheckContext = () => useContext(Context);
export { useVersionCheckContext };
