/* eslint-disable import/no-default-export */
import React from "react";

import { alpha, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import cx from "classnames";

const PREFIX = "MultiRowInfoIcon";

const classes = {
  infoInfoIcon: `${PREFIX}-infoInfoIcon`,
  infoInfoIconInverted: `${PREFIX}-infoInfoIconInverted`,
  infoTooltipText: `${PREFIX}-infoTooltipText`,
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${classes.infoInfoIcon}`]: {
    color: theme.palette.background.default,
    cursor: "pointer",
    "&:hover": {
      color: alpha(theme.palette.background.default, 0.5),
    },
  },

  [`& .${classes.infoInfoIconInverted}`]: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: alpha(theme.palette.text.primary, 0.5),
    },
  },

  [`& .${classes.infoTooltipText}`]: {
    display: "block",
  },
}));

interface MultiRowInfoIconProps {
  title: string;
  listRows?: string[];
  invertColors?: boolean;
}

function MultiRowInfoIcon(props: MultiRowInfoIconProps) {
  return (
    <StyledTooltip
      title={
        <div>
          <Typography
            className={classes.infoTooltipText}
            variant="caption"
            children={props.title}
          />
          {props.listRows
            ? props.listRows.map((reason, idx) => (
                <Typography
                  key={idx}
                  className={classes.infoTooltipText}
                  variant="caption"
                  children={`- ${reason}`}
                />
              ))
            : null}
        </div>
      }
      placement="top"
    >
      <InfoIcon
        className={cx({
          [classes.infoInfoIcon]: !props.invertColors,
          [classes.infoInfoIconInverted]: !!props.invertColors,
        })}
      />
    </StyledTooltip>
  );
}

export default MultiRowInfoIcon;
