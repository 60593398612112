import React, { useEffect, useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import {
  Box,
  ButtonBase,
  FormControl,
  InputBase,
  InputBaseProps,
  alpha,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Amount } from "james/ledger";

export type NumberFieldProps = {
  onChange?: (value: string, number: BigNumber) => void;
  disabled?: boolean;
  disallowNegative?: boolean;
  noDecimals?: boolean;
  value?: number;
  variant?: "left-input" | "middle-input";
  width?: string | number;
  color?: string;
  id?: string;
  minValue?: number;
  maxValue?: number;
  inputBaseProps?: InputBaseProps;
  fullWidth?: boolean;
};

/**
 *
 * @component
 * @prop {(value: string, number: BigNumber) => void} onChange
 * - is a function that runs when the value changes
 * - the value is passed to the function as a string and BigNumber so it can be used outside of the component
 * @prop {boolean} disallowNegative
 * @prop {boolean} noDecimals
 * @prop {"left-input" | "middle-input"} [variant="left-input"] decides the layout of the input
 * @prop {string | number } [width="200px"]
 * @prop {string} [color=secondary.main]
 * @prop {number} [value=0] sets the value inside the component
 */
export const NumberIncrementField = ({
  onChange,
  disallowNegative = false,
  noDecimals,
  disabled = false,
  variant = "left-input",
  width = "200px",
  color = "#30b0b0",
  value = 0,
  id = "",
  minValue,
  maxValue,
  fullWidth,
}: NumberFieldProps) => {
  const [stringValue, setStringValue] = useState(
    minValue ? `${minValue}` : `${value}`,
  );
  const [bigNumber, setBigNumber] = useState(new BigNumber(minValue ?? value));
  const [_color, setColor] = useState(color);

  useEffect(() => {
    if (!color) return;
    setColor(color);
  }, [color]);

  const regexExp = useMemo(() => {
    const negative = disallowNegative ? "" : "[-]?";
    const number = noDecimals ? "\\d+" : "(\\d+(\\.\\d*)?|\\.\\d+)";
    return new RegExp(`^${negative}${number}$`);
  }, [noDecimals, disallowNegative]);

  const getValue = (): string => {
    if (new BigNumber(stringValue).isNaN()) {
      return stringValue;
    }
    return bigNumber.toFormat();
  };

  const increment = () => {
    if (maxValue && bigNumber.eq(maxValue)) return;
    const newVal = bigNumber.plus(1);
    setStringValue(newVal.toString());
    setBigNumber(newVal);
    if (onChange) {
      onChange(newVal.toString(), newVal);
    }
  };
  const decrement = () => {
    if (disallowNegative && bigNumber.eq(0)) return;
    if (minValue && bigNumber.eq(minValue)) return;
    const newVal = bigNumber.minus(1);
    setStringValue(newVal.toString());
    setBigNumber(newVal);
    if (onChange) {
      onChange(newVal.toString(), newVal);
    }
  };

  const onInputChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (e) => {
    const v: string = e.target.value.replaceAll(",", "");
    if (v === "") {
      if (minValue) {
        setStringValue(`${minValue}`);
        setBigNumber(new BigNumber(minValue));
        if (onChange) {
          onChange(`${minValue}`, new BigNumber(minValue));
        }
        return;
      }
      setStringValue("");
      setBigNumber(new BigNumber(0));
      if (onChange) {
        onChange(v, new BigNumber(v));
      }
    } else if (!disallowNegative && v === "-") {
      setStringValue(v);
      setBigNumber(new BigNumber(0));
      if (onChange) {
        onChange(v, new BigNumber(v));
      }
    } else if (regexExp.test(v)) {
      if (minValue && +v < minValue) {
        setStringValue(`${minValue}`);
        setBigNumber(new BigNumber(minValue));
        if (onChange) {
          onChange(`${minValue}`, new BigNumber(minValue));
        }
        return;
      } else if (maxValue && +v > maxValue) {
        setStringValue(`${maxValue}`);
        setBigNumber(new BigNumber(maxValue).decimalPlaces(0));
        if (onChange) {
          onChange(`${maxValue}`, new BigNumber(maxValue).decimalPlaces(0));
        }
        return;
      }
      setStringValue(v);
      setBigNumber(new BigNumber(v));
      if (onChange) {
        onChange(v, new BigNumber(v));
      }
    }
  };

  switch (variant) {
    case "left-input":
      return (
        <FormControl
          fullWidth={fullWidth}
          disabled={disabled}
          sx={(theme) => ({
            width: width,
            height: "40px",
            minWidth: width,
            display: "grid",
            gridTemplateColumns: "auto 34px",
            border: `solid 1px ${_color}`,
            borderRadius: "4px",
            transition: "background-color 200ms ease-in-out",
            "&:disabled": {
              border: `solid 1px ${theme.palette.text.disabled}`,
            },
            "&:focus-within": {
              backgroundColor: alpha(_color, 0.1),
            },
          })}
          onKeyDown={(e) => {
            if (e.key === "ArrowUp") {
              increment();
            } else if (e.key === "ArrowDown") {
              decrement();
            }
          }}
        >
          <InputBase
            id={id}
            inputProps={{
              pattern: "\\d*",
              style: { textAlign: "center" },
            }}
            placeholder="0"
            value={getValue()}
            className={"input"}
            margin="none"
            onChange={onInputChange}
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateRows: "auto auto",
            }}
          >
            <Box
              component={ButtonBase}
              disabled={disabled}
              sx={(theme) => ({
                backgroundColor: alpha(_color, 0.3),
                borderLeft: `solid 0.5px ${_color}`,
                "&:disabled": {
                  borderLeft: `solid 0.5px ${theme.palette.text.disabled}`,
                  backgroundColor: alpha("#FFFFFF", 0.1),
                },
                "&:hover": {
                  backgroundColor: alpha(_color, 0.4),
                },
              })}
              onClick={increment}
            >
              <ArrowDropUpIcon sx={{ color: _color, height: "18px" }} />
            </Box>
            <Box
              component={ButtonBase}
              disabled={disabled}
              sx={(theme) => ({
                backgroundColor: alpha(_color, 0.3),
                borderLeft: `solid 0.5px ${_color}`,
                borderTop: `solid 0.5px ${_color}`,
                "&:disabled": {
                  borderLeft: `solid 0.5px ${theme.palette.text.disabled}`,
                  borderTop: `solid 0.5px ${theme.palette.text.disabled}`,
                  backgroundColor: alpha("#FFFFFF", 0.1),
                },
                "&:hover": {
                  backgroundColor: alpha(_color, 0.4),
                },
              })}
              onClick={decrement}
            >
              <ArrowDropDownIcon sx={{ color: _color, height: "18px" }} />
            </Box>
          </Box>
        </FormControl>
      );
    case "middle-input":
      return (
        <FormControl
          fullWidth={fullWidth}
          disabled={disabled}
          sx={(theme) => ({
            width: width,
            display: "grid",
            height: "40px",
            minWidth: width,
            gridTemplateColumns: "34px auto 34px",
            border: `solid 1px ${_color}`,
            borderRadius: "4px",
            transition: "background-color 200ms ease-in-out",
            "&:disabled": {
              border: `solid 1px ${theme.palette.text.disabled}`,
            },
            "&:focus-within": {
              backgroundColor: alpha(_color, 0.1),
            },
          })}
        >
          <Box
            component={ButtonBase}
            sx={{
              backgroundColor: alpha(_color, 0.3),
              borderRight: `solid 0.5px ${_color}`,
              "&:hover": {
                backgroundColor: alpha(_color, 0.4),
              },
            }}
            onClick={() => {
              if (disallowNegative && bigNumber.eq(0)) return;
              const newVal = bigNumber.minus(1);
              setStringValue(newVal.toString());
              setBigNumber(newVal);
              if (onChange) {
                onChange(newVal.toString(), newVal);
              }
            }}
          >
            <ArrowDropDownIcon sx={{ color: _color }} />
          </Box>
          <InputBase
            inputProps={{
              pattern: "\\d*",
              style: { textAlign: "center" },
            }}
            placeholder="0"
            value={getValue()}
            className={"input"}
            margin="none"
            onChange={onInputChange}
          />
          <Box
            component={ButtonBase}
            sx={{
              backgroundColor: alpha(_color, 0.3),
              borderLeft: `solid 0.5px ${_color}`,
              "&:hover": {
                backgroundColor: alpha(_color, 0.4),
              },
            }}
            onClick={() => {
              const newVal = bigNumber.plus(1);
              setStringValue(newVal.toString());
              setBigNumber(newVal);
              if (onChange) {
                onChange(newVal.toString(), newVal);
              }
            }}
          >
            <ArrowDropUpIcon sx={{ color: _color }} />
          </Box>
        </FormControl>
      );
  }
};

/**
 *
 * @component
 * @prop {func(value: string, number: Amount): void} onChange
 * - is a function that runs when the value changes
 * - the value is passed to the function as a string and BigNumber so it can be used outside of the component
 * @prop {boolean} disallowNegative
 * @prop {boolean} noDecimals
 * @prop {"left-input" | "middle-input"} [variant="left-input"] decides the layout of the input
 * @prop {string | number } [width="200px"]
 * @prop {string} [color=secondary.main]
 * @prop {Amount} [value] sets the value inside the component
 * @returns
 */
export type AmountIncrementProps = {
  value: Amount;
  onChange?: (value: string, amount: Amount) => void;
} & Omit<NumberFieldProps, "value" | "onChange">;

export const AmountIncrementField = ({
  onChange,
  value,
  noDecimals,
  disallowNegative = false,
  variant = "left-input",
  width = "200px",
  color = "#30b0b0",
  id = "",
  disabled,
  inputBaseProps,
}: AmountIncrementProps) => {
  const [stringValue, setStringValue] = useState(value ? `${value.value}` : "");
  const [amount, setAmount] = useState<Amount>(new Amount(value));
  const [_color, setColor] = useState(color);

  useEffect(() => {
    if (!color) return;
    setColor(color);
  }, [color]);

  useEffect(() => {
    setAmount(value);
  }, [value]);

  const regexExp = useMemo(() => {
    const negative = disallowNegative ? "" : "[-]?";
    const number = noDecimals ? "\\d+" : "(\\d+(\\.\\d*)?|\\.\\d+)";
    return new RegExp(`^${negative}${number}$`);
  }, [noDecimals, disallowNegative]);

  const getValue = (): string => {
    if (new BigNumber(stringValue).isNaN()) {
      return stringValue;
    }
    return amount.value.toFormat();
  };

  const increment = () => {
    const newVal = amount.value.plus(1);
    setStringValue(newVal.toString());
    setAmount(amount.setValue(newVal));
    if (onChange) {
      onChange(newVal.toString(), amount.setValue(newVal));
    }
  };
  const decrement = () => {
    if (disallowNegative && amount.value.eq(0)) return;
    const newVal = amount.value.minus(1);
    setStringValue(newVal.toString());
    setAmount(amount.setValue(newVal));
    if (onChange) {
      onChange(newVal.toString(), amount.setValue(newVal));
    }
  };

  const onInputChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (e) => {
    const v: string = e.target.value.replaceAll(",", "");
    const Zero = new Amount(amount.setValue(new BigNumber(0)));
    if (v === "") {
      setStringValue("");
      setAmount(Zero);
    } else if (!disallowNegative && v === "-") {
      setStringValue(v);
      setAmount(Zero);
    } else if (regexExp.test(v)) {
      setStringValue(v);
      setAmount(new Amount(amount.setValue(v)));
    }
    if (onChange) {
      onChange(v, new Amount(amount.setValue(v)));
    }
  };

  return (
    <FormControl
      className={variant}
      disabled={disabled}
      sx={(theme) => ({
        width: width,
        height: "40px",
        minWidth: width,
        border: disabled
          ? `solid 1px ${theme.palette.text.disabled}`
          : `solid 1px ${_color}`,
        borderRadius: "4px",
        transition: "background-color 200ms ease-in-out",
        "&:focus-within": {
          backgroundColor: alpha(_color, 0.1),
        },
        display: "grid",
        gridTemplate: `
        "b c" auto
        "b a" 2ch / auto 34px;
        `,
        "&.middle-input": {
          gridTemplate: `
          "a b c" auto / 34px auto 34px;
        `,
        },
      })}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp") {
          increment();
        } else if (e.key === "ArrowDown") {
          decrement();
        }
      }}
    >
      <Box
        component={ButtonBase}
        disabled={disabled}
        sx={(theme) => ({
          gridArea: "a",
          backgroundColor: alpha(_color, 0.3),
          borderLeft: `solid 0.5px ${_color}`,
          borderTop: `solid 0.5px ${_color}`,
          "&:disabled": {
            backgroundColor: alpha("#FFFFFF", 0.1),
            borderLeft: `solid 1px ${theme.palette.text.disabled}`,
            borderTop: `solid 1px ${theme.palette.text.disabled}`,
          },
          "&:hover": {
            backgroundColor: alpha(_color, 0.4),
          },
        })}
        onClick={decrement}
      >
        <ArrowDropDownIcon
          sx={{
            color: disabled ? alpha("#FFFFFF", 0.3) : _color,
            height: "18px",
          }}
        />
      </Box>
      <InputBase
        {...inputBaseProps}
        autoComplete="off"
        id={id}
        inputProps={{
          pattern: "\\d*",
          sx: { textAlign: "center" },
          ...inputBaseProps?.inputProps,
        }}
        sx={{
          gridArea: "b",
        }}
        placeholder="0"
        value={getValue()}
        className={"input"}
        margin="none"
        onChange={onInputChange}
      />
      <Box
        component={ButtonBase}
        disabled={disabled}
        sx={(theme) => ({
          gridArea: "c",
          backgroundColor: alpha(_color, 0.3),
          borderLeft: `solid 0.5px ${_color}`,
          "&:disabled": {
            borderLeft: `solid 1px ${theme.palette.text.disabled}`,
            backgroundColor: alpha("#FFFFFF", 0.1),
          },
          "&:hover": {
            backgroundColor: alpha(_color, 0.4),
          },
        })}
        onClick={increment}
      >
        <ArrowDropUpIcon
          sx={{
            color: disabled ? alpha("#FFFFFF", 0.3) : _color,
            height: "18px",
          }}
        />
      </Box>
    </FormControl>
  );
};
