import React, { useState, useEffect, useMemo, useRef } from "react";
import { Typography, Box } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

interface CountdownComponentProps {
  time: string;
}

type Interval = {
  interval: string;
  time: number;
};

export const CountdownComponent = ({ time }: CountdownComponentProps) => {
  dayjs.extend(duration);
  const [endDate] = useState(dayjs(time));
  const [timeLeft, setTimeLeft] = useState(() => {
    const diff = dayjs.duration(endDate.diff(dayjs()));
    if (diff.asSeconds() > 0) {
      return diff;
    }
    return dayjs.duration(0);
  });
  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const diff = dayjs.duration(endDate.diff(dayjs()));
      setTimeLeft(diff);
    }, 1000);
    intervalRef.current = intervalId;
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timeLeft.asSeconds() <= 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
      return;
    }
  }, [timeLeft]);

  const intervalList = useMemo((): Interval[] => {
    let l: {
      interval: string;
      time: number;
    }[] = [];
    if (timeLeft.days()) {
      l = l.concat([
        {
          interval: `${timeLeft.days() > 1 ? "days :" : "day :"}`,
          time: timeLeft.days(),
        },
      ]);
    }
    if (timeLeft.hours()) {
      l = l.concat([
        {
          interval: `${timeLeft.hours() > 1 ? "hours :" : "hour :"}`,
          time: timeLeft.hours(),
        },
      ]);
    }
    l = l.concat([
      {
        interval: "min :",
        time: timeLeft.minutes(),
      },
      {
        interval: "sec",
        time: timeLeft.seconds(),
      },
    ]);

    return l;
  }, [timeLeft]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 36,
      }}
    >
      {intervalList.map((v, i) => {
        return (
          <React.Fragment key={i}>
            <Typography
              sx={{ fontSize: { sm: 14, xs: 12 } }}
              id={`interval-value-${i}`}
            >
              {v.time}
            </Typography>
            <Typography
              id={`interval-label-${i}`}
              key={`${i}-${v.interval}`}
              sx={(theme) => ({
                mx: 1,
                color: theme.palette.text.tertiary,
                fontSize: { sm: 14, xs: 12 },
              })}
            >
              {v.interval}
            </Typography>
          </React.Fragment>
        );
      })}
    </Box>
  );
};
