export class IndustryClassification {
  public industryCode = 0;

  public industryName = "";

  public subIndustryCode = 0;

  public subIndustryName = "";

  constructor(industryClassification?: IndustryClassification) {
    if (!industryClassification) {
      return;
    }
    this.industryName = industryClassification.industryName;
    this.industryCode = industryClassification.industryCode;
    this.subIndustryCode = industryClassification.subIndustryCode;
    this.subIndustryName = industryClassification.subIndustryName;
  }
}

export const IndustryClassificationOptions: IndustryClassification[] = [
  {
    industryName: "Energy Equipment & Services",
    industryCode: 101010,
    subIndustryName: "Oil & Gas Drilling",
    subIndustryCode: 10101010,
  },
  {
    industryName: "Energy Equipment & Services",
    industryCode: 101010,
    subIndustryName: "Oil & Gas Equipment & Services",
    subIndustryCode: 10101020,
  },
  {
    industryName: "Oil, Gas & Consumable Fuels",
    industryCode: 101020,
    subIndustryName: "Integrated Oil & Gas",
    subIndustryCode: 10102010,
  },
  {
    industryName: "Oil, Gas & Consumable Fuels",
    industryCode: 101020,
    subIndustryName: "Oil & Gas Exploration & Production",
    subIndustryCode: 10102020,
  },
  {
    industryName: "Oil, Gas & Consumable Fuels",
    industryCode: 101020,
    subIndustryName: "Oil & Gas Refining & Marketing",
    subIndustryCode: 10102030,
  },
  {
    industryName: "Oil, Gas & Consumable Fuels",
    industryCode: 101020,
    subIndustryName: "Oil & Gas Storage & Transportation",
    subIndustryCode: 10102040,
  },
  {
    industryName: "Oil, Gas & Consumable Fuels",
    industryCode: 101020,
    subIndustryName: "Coal & Consumable Fuel",
    subIndustryCode: 10102050,
  },
  {
    industryName: "Chemicals",
    industryCode: 151010,
    subIndustryName: "Commodity Chemicals",
    subIndustryCode: 15101010,
  },
  {
    industryName: "Chemicals",
    industryCode: 151010,
    subIndustryName: "Diversified Chemicals",
    subIndustryCode: 15101020,
  },
  {
    industryName: "Chemicals",
    industryCode: 151010,
    subIndustryName: "Fertilizers & Agricultural Chemicals",
    subIndustryCode: 15101030,
  },

  {
    industryName: "Chemicals",
    industryCode: 151010,
    subIndustryName: "Industrial Gases",
    subIndustryCode: 15101040,
  },

  {
    industryName: "Chemicals",
    industryCode: 151010,
    subIndustryName: "Speciality Chemicals",
    subIndustryCode: 15101050,
  },

  {
    industryName: "Construction Materials",
    industryCode: 151020,
    subIndustryName: "Speciality Chemicals",
    subIndustryCode: 15102010,
  },
  {
    industryName: "Containers & Packaging",
    industryCode: 151030,
    subIndustryName: "Metal & Glass Containers",
    subIndustryCode: 15103010,
  },
  {
    industryName: "Containers & Packaging",
    industryCode: 151030,
    subIndustryName: "Paper Packaging",
    subIndustryCode: 15103020,
  },

  {
    industryName: "Metals & Mining",
    industryCode: 151040,
    subIndustryName: "Aluminium",
    subIndustryCode: 15104010,
  },
  {
    industryName: "Metals & Mining",
    industryCode: 151040,
    subIndustryName: "Diversified Metals & Mining",
    subIndustryCode: 15104020,
  },
  {
    industryName: "Metals & Mining",
    industryCode: 151040,
    subIndustryName: "Copper",
    subIndustryCode: 15104025,
  },
  {
    industryName: "Metals & Mining",
    industryCode: 151040,
    subIndustryName: "Gold",
    subIndustryCode: 15104030,
  },
  {
    industryName: "Metals & Mining ",
    industryCode: 151040,
    subIndustryName: "Precious Metals & Minerals",
    subIndustryCode: 15104040,
  },
  {
    industryName: "Metals & Mining",
    industryCode: 151040,
    subIndustryName: "Silver",
    subIndustryCode: 15104045,
  },
  {
    industryName: "Metals & Mining",
    industryCode: 151040,
    subIndustryName: "Steel",
    subIndustryCode: 15104050,
  },

  {
    label: "Paper & Forest Products - Forest Products",
    industryName: "Paper & Forest Products",
    industryCode: 151050,
    subIndustryName: "Forest Products",
    subIndustryCode: 15105010,
  },
  {
    industryName: "Paper & Forest Products",
    industryCode: 151050,
    subIndustryName: "Paper Products",
    subIndustryCode: 15105020,
  },

  {
    industryName: "Aerospace & Defence",
    industryCode: 201010,
    subIndustryName: "Aerospace & Defence",
    subIndustryCode: 20101010,
  },

  {
    industryName: "Building Products",
    industryCode: 201020,
    subIndustryName: "Building Products",
    subIndustryCode: 20102010,
  },

  {
    industryName: "Construction & Engineering",
    industryCode: 201030,
    subIndustryName: "Construction & Engineering",
    subIndustryCode: 20103010,
  },

  {
    industryName: "Electrical Equipment",
    industryCode: 201040,
    subIndustryName: "Electrical Components & Equipment",
    subIndustryCode: 20104010,
  },
  {
    industryName: "Electrical Equipment",
    industryCode: 201040,
    subIndustryName: " Heavy Electrical Equipment",
    subIndustryCode: 20104020,
  },

  {
    industryName: "Industrial Conglomerates",
    industryCode: 201050,
    subIndustryName: "Industrial Conglomerates",
    subIndustryCode: 20105010,
  },

  {
    industryName: "Machinery",
    industryCode: 201060,
    subIndustryName: " Construction Machinery & Heavy Trucks",
    subIndustryCode: 20106010,
  },
  {
    industryName: "Machinery",
    industryCode: 201060,
    subIndustryName: "Agricultural & Farm Machinery",
    subIndustryCode: 20106015,
  },
  {
    industryName: "Machinery",
    industryCode: 201060,
    subIndustryName: " Industrial Machinery",
    subIndustryCode: 20106020,
  },

  {
    industryName: "Trading Companies & Distributors",
    industryCode: 201070,
    subIndustryName: "Trading Companies & Distributors",
    subIndustryCode: 20107010,
  },
  {
    industryName: "Commercial Services & Supplies",
    industryCode: 202010,
    subIndustryName: "Commercial Printing",
    subIndustryCode: 20201010,
  },
  {
    industryName: "Commercial Services & Supplies",
    industryCode: 202010,
    subIndustryName: "Environmental & Facilities Services",
    subIndustryCode: 20201050,
  },
  {
    industryName: "Commercial Services & Supplies",
    industryCode: 202010,
    subIndustryName: "Office Services & Supplies",
    subIndustryCode: 20201060,
  },

  {
    industryName: "Commercial Services & Supplies",
    industryCode: 202010,
    subIndustryName: "Diversified Support Services",
    subIndustryCode: 20201070,
  },

  {
    industryName: "Commercial Services & Supplies",
    industryCode: 202010,
    subIndustryName: "Security & Alarm Services",
    subIndustryCode: 20201080,
  },

  {
    industryName: "Professional Services",
    industryCode: 202020,
    subIndustryName: "Human Resource & Employment Services",
    subIndustryCode: 20202010,
  },

  {
    industryName: "Professional Services",
    industryCode: 202020,
    subIndustryName: "Research & Consulting Services",
    subIndustryCode: 20202020,
  },

  {
    industryName: "Air Freight & Logistics",
    industryCode: 203010,
    subIndustryName: "Air Freight & Logistics",
    subIndustryCode: 20301010,
  },

  {
    industryName: "Airlines",
    industryCode: 203020,
    subIndustryName: "Airlines",
    subIndustryCode: 20302010,
  },

  {
    industryName: "Marine",
    industryCode: 203030,
    subIndustryName: "Marine",
    subIndustryCode: 20303010,
  },

  {
    industryName: "Road & Rail",
    industryCode: 203040,
    subIndustryName: "Railroads",
    subIndustryCode: 20304010,
  },

  {
    industryName: "Road & Rail",
    industryCode: 203040,
    subIndustryName: "Trucking",
    subIndustryCode: 20304020,
  },

  {
    industryName: "Transportation Infrastructure ",
    industryCode: 203050,
    subIndustryName: " Airport Services",
    subIndustryCode: 20305010,
  },
  {
    industryName: "Transportation Infrastructure ",
    industryCode: 203050,
    subIndustryName: "Highways & Railtracks",
    subIndustryCode: 20305020,
  },
  {
    industryName: "Transportation Infrastructure ",
    industryCode: 203050,
    subIndustryName: "Marine Ports & Services",
    subIndustryCode: 20305030,
  },

  {
    industryName: "Auto Components",
    industryCode: 251010,
    subIndustryName: "Auto Parts & Equipment",
    subIndustryCode: 25101010,
  },
  {
    industryName: "Auto Components",
    industryCode: 251010,
    subIndustryName: "Tires & Rubber",
    subIndustryCode: 25101020,
  },
  {
    industryName: "Auto Components",
    industryCode: 251020,
    subIndustryName: "Tires & Rubber",
    subIndustryCode: 25102010,
  },
  {
    industryName: "Auto Components",
    industryCode: 251020,
    subIndustryName: "Motorcycle Manufacturers",
    subIndustryCode: 25102020,
  },

  {
    industryName: "Household Durables ",
    industryCode: 252010,
    subIndustryName: "Consumer Electronics",
    subIndustryCode: 25201010,
  },
  {
    industryName: "Household Durables ",
    industryCode: 252010,
    subIndustryName: "Home Furnishings",
    subIndustryCode: 25201020,
  },
  {
    industryName: "Household Durables ",
    industryCode: 252010,
    subIndustryName: "Homebuilding",
    subIndustryCode: 25201030,
  },
  {
    industryName: "Household Durables ",
    industryCode: 252010,
    subIndustryName: "Household Appliances",
    subIndustryCode: 25201040,
  },
  {
    industryName: "Household Durables ",
    industryCode: 252010,
    subIndustryName: "Housewares & Specialties",
    subIndustryCode: 25201050,
  },

  {
    industryName: "Leisure Products",
    industryCode: 252020,
    subIndustryName: "Leisure Products",
    subIndustryCode: 25202010,
  },

  {
    industryName: "Textiles, Apparel & Luxury Goods",
    industryCode: 252030,
    subIndustryName: "Apparel, Accessories & Luxury Goods",
    subIndustryCode: 25203010,
  },
  {
    industryName: "Textiles, Apparel & Luxury Goods",
    industryCode: 252030,
    subIndustryName: "Footwear",
    subIndustryCode: 25203020,
  },
  {
    industryName: "Textiles, Apparel & Luxury Goods",
    industryCode: 252030,
    subIndustryName: "Textiles",
    subIndustryCode: 25203030,
  },

  {
    industryName: "Hotels, Restaurants & Leisure",
    industryCode: 253010,
    subIndustryName: "Casinos & Gaming",
    subIndustryCode: 25301010,
  },
  {
    industryName: "Hotels, Restaurants & Leisure",
    industryCode: 253010,
    subIndustryName: "Hotels, Resorts & Cruise Lines",
    subIndustryCode: 25301020,
  },
  {
    industryName: "Hotels, Restaurants & Leisure",
    industryCode: 253010,
    subIndustryName: "Leisure Facilities",
    subIndustryCode: 25301030,
  },
  {
    industryName: "Hotels, Restaurants & Leisure",
    industryCode: 253010,
    subIndustryName: "Restaurants",
    subIndustryCode: 25301040,
  },

  {
    industryName: "Diversified Consumer Services",
    industryCode: 253020,
    subIndustryName: "Education Services",
    subIndustryCode: 25302010,
  },
  {
    industryName: "Diversified Consumer Services",
    industryCode: 253020,
    subIndustryName: "Specialized Consumer Services",
    subIndustryCode: 25302020,
  },

  {
    industryName: "Distributors",
    industryCode: 255010,
    subIndustryName: "Distributors",
    subIndustryCode: 25501010,
  },

  {
    industryName: "Internet & Direct Marketing Retail",
    industryCode: 255020,
    subIndustryName: "Internet & Direct Marketing Retail",
    subIndustryCode: 25502020,
  },

  {
    industryName: "Multiline Retail",
    industryCode: 255030,
    subIndustryName: "Department Stores",
    subIndustryCode: 25503010,
  },
  {
    industryName: "Multiline Retail",
    industryCode: 255030,
    subIndustryName: " General Merchandise Stores",
    subIndustryCode: 25503020,
  },

  {
    industryName: "Specialty Retail",
    industryCode: 255040,
    subIndustryName: " Apparel Retail",
    subIndustryCode: 25504010,
  },
  {
    industryName: "Specialty Retail",
    industryCode: 255040,
    subIndustryName: "Computer & Electronics Retail",
    subIndustryCode: 25504020,
  },
  {
    industryName: "Specialty Retail",
    industryCode: 255040,
    subIndustryName: "Home Improvement Retail",
    subIndustryCode: 25504030,
  },
  {
    industryName: "Specialty Retail",
    industryCode: 255040,
    subIndustryName: "Specialty Stores",
    subIndustryCode: 25504040,
  },
  {
    industryName: "Specialty Retail",
    industryCode: 255040,
    subIndustryName: "Automotive Retail",
    subIndustryCode: 25504050,
  },
  {
    industryName: "Specialty Retail",
    industryCode: 255040,
    subIndustryName: "Automotive Retail",
    subIndustryCode: 25504060,
  },

  {
    industryName: "Food & Staples Retailing",
    industryCode: 301010,
    subIndustryName: "Drug Retail",
    subIndustryCode: 30101010,
  },
  {
    industryName: "Food & Staples Retailing",
    industryCode: 301010,
    subIndustryName: "Food Distributors",
    subIndustryCode: 30101020,
  },
  {
    industryName: "Food & Staples Retailing",
    industryCode: 301010,
    subIndustryName: "Food Retail",
    subIndustryCode: 30101030,
  },
  {
    industryName: "Food & Staples Retailing",
    industryCode: 301010,
    subIndustryName: "Hypermarkets & Super Centres",
    subIndustryCode: 30101040,
  },

  {
    industryName: "Beverages",
    industryCode: 302010,
    subIndustryName: "Brewer",
    subIndustryCode: 30201010,
  },
  {
    industryName: "Beverages",
    industryCode: 302010,
    subIndustryName: "Distillers & Vitners",
    subIndustryCode: 30201020,
  },
  {
    industryName: "Beverages",
    industryCode: 302010,
    subIndustryName: "Soft Drinks",
    subIndustryCode: 30201030,
  },

  {
    industryName: "Food Products",
    industryCode: 302020,
    subIndustryName: "Agricultural Products",
    subIndustryCode: 30202010,
  },
  {
    industryName: "Food Products",
    industryCode: 302020,
    subIndustryName: "Packaged Foods & Meats",
    subIndustryCode: 30202030,
  },

  {
    industryName: "Tobacco",
    industryCode: 302030,
    subIndustryName: "Tobacco",
    subIndustryCode: 30203010,
  },

  {
    industryName: "Household Products",
    industryCode: 303010,
    subIndustryName: "Household Products",
    subIndustryCode: 30301010,
  },

  {
    industryName: "Personal Products ",
    industryCode: 303020,
    subIndustryName: "Personal Products ",
    subIndustryCode: 30302020,
  },

  {
    industryName: "Health Care Equipment & Supplies",
    industryCode: 351010,
    subIndustryName: "Health Care Equipment",
    subIndustryCode: 35101010,
  },
  {
    industryName: "Health Care Equipment & Supplies",
    industryCode: 351010,
    subIndustryName: "Health Care Supplies",
    subIndustryCode: 35101020,
  },

  {
    industryName: "Health Care Providers and Services",
    industryCode: 351020,
    subIndustryName: "Health Care Distributors",
    subIndustryCode: 35102010,
  },
  {
    industryName: "Health Care Providers and Services",
    industryCode: 351020,
    subIndustryName: "Health Care Services",
    subIndustryCode: 35102015,
  },
  {
    industryName: "Health Care Providers and Services",
    industryCode: 351020,
    subIndustryName: " Health Care Facilities",
    subIndustryCode: 35102020,
  },
  {
    industryName: "Health Care Providers and Services",
    industryCode: 351020,
    subIndustryName: "Managed Health Care",
    subIndustryCode: 35102030,
  },

  {
    industryName: "Health Care Technology",
    industryCode: 351030,
    subIndustryName: "Health Care Technology",
    subIndustryCode: 35103010,
  },

  {
    industryName: "Biotechnology",
    industryCode: 352010,
    subIndustryName: "Biotechnology",
    subIndustryCode: 35201010,
  },

  {
    industryName: "Pharmaceuticals",
    industryCode: 352020,
    subIndustryName: "Pharmaceuticals",
    subIndustryCode: 35202010,
  },

  {
    industryName: "Life Sciences Tools & Services",
    industryCode: 352030,
    subIndustryName: "Life Sciences Tools & Services",
    subIndustryCode: 35203010,
  },

  {
    industryName: "Banks",
    industryCode: 401010,
    subIndustryName: "Diversified Banks",
    subIndustryCode: 40101010,
  },
  {
    industryName: "Banks",
    industryCode: 401010,
    subIndustryName: "Regional Banks",
    subIndustryCode: 40101015,
  },

  {
    industryName: "Thrifts & Mortgage Finance",
    industryCode: 401020,
    subIndustryName: "Thrifts & Mortgage Finance",
    subIndustryCode: 40102010,
  },

  {
    industryName: "Diversified Financial Services",
    industryCode: 402010,
    subIndustryName: "Other Diversified Financial Services",
    subIndustryCode: 40201020,
  },
  {
    industryName: "Diversified Financial Services",
    industryCode: 402010,
    subIndustryName: "Multi-Sector Holdings",
    subIndustryCode: 40201030,
  },
  {
    industryName: "Diversified Financial Services",
    industryCode: 402010,
    subIndustryName: "Specialized Finance",
    subIndustryCode: 40201040,
  },

  {
    industryName: "Consumer Finance",
    industryCode: 402020,
    subIndustryName: "Consumer Finance",
    subIndustryCode: 40202010,
  },

  {
    industryName: "Capital Markets",
    industryCode: 402030,
    subIndustryName: "Asset Management & Custody Banks",
    subIndustryCode: 40203010,
  },
  {
    industryName: "Capital Markets",
    industryCode: 402030,
    subIndustryName: "Investment Banking & Brokerage",
    subIndustryCode: 40203020,
  },
  {
    industryName: "Capital Markets",
    industryCode: 402030,
    subIndustryName: "Diversified Capital Markets",
    subIndustryCode: 40203030,
  },
  {
    industryName: "Capital Markets",
    industryCode: 402030,
    subIndustryName: "Financial Exchanges & Data",
    subIndustryCode: 40203040,
  },

  {
    industryName: "Mortgage Real Estate Investment Trusts (REITs)",
    industryCode: 402040,
    subIndustryName: "Mortgage REITs",
    subIndustryCode: 40204010,
  },

  {
    industryName: "Insurance",
    industryCode: 403010,
    subIndustryName: "Insurance Brokers",
    subIndustryCode: 40301010,
  },
  {
    industryName: "Insurance",
    industryCode: 403010,
    subIndustryName: "Life & Health Insurance",
    subIndustryCode: 40301020,
  },
  {
    industryName: "Insurance",
    industryCode: 403010,
    subIndustryName: "Multi-line Insurance",
    subIndustryCode: 40301030,
  },
  {
    industryName: "Insurance",
    industryCode: 403010,
    subIndustryName: "Property & Casualty Insurance",
    subIndustryCode: 40301040,
  },
  {
    industryName: "Insurance",
    industryCode: 403010,
    subIndustryName: "Reinsurance",
    subIndustryCode: 40301050,
  },

  {
    industryName: "IT Services",
    industryCode: 451020,
    subIndustryName: "IT Consulting & Other Services",
    subIndustryCode: 45102010,
  },
  {
    industryName: "IT Services",
    industryCode: 451020,
    subIndustryName: "Data Processing & Outsourced Services",
    subIndustryCode: 45102020,
  },
  {
    industryName: "IT Services",
    industryCode: 451020,
    subIndustryName: "Internet Services & Infrastructure",
    subIndustryCode: 45102030,
  },

  {
    industryName: "Software",
    industryCode: 451030,
    subIndustryName: "Application Software",
    subIndustryCode: 45103010,
  },
  {
    industryName: "Software",
    industryCode: 451030,
    subIndustryName: "Systems Software",
    subIndustryCode: 45103020,
  },

  {
    industryName: "Communications Equipment",
    industryCode: 452010,
    subIndustryName: "Communications Equipment",
    subIndustryCode: 45201020,
  },

  {
    industryName: "Technology Hardware, Storage & Peripherals",
    industryCode: 452020,
    subIndustryName: "Technology Hardware, Storage & Peripherals",
    subIndustryCode: 45202030,
  },

  {
    industryName: "Electronic Equipment, Instruments",
    industryCode: 452030,
    subIndustryName: "Electronic Equipment & Instruments",
    subIndustryCode: 45203010,
  },
  {
    industryName: "Electronic Equipment, Instruments",
    industryCode: 452030,
    subIndustryName: "Electronic Components",
    subIndustryCode: 45203015,
  },
  {
    industryName: "Electronic Equipment, Instruments",
    industryCode: 452030,
    subIndustryName: "Electronic Manufacturing Services",
    subIndustryCode: 45203020,
  },
  {
    industryName: "Electronic Equipment, Instruments",
    industryCode: 452030,
    subIndustryName: "Technology Distributors",
    subIndustryCode: 45203030,
  },

  {
    industryName: "Semiconductors & Semiconductor Equipment",
    industryCode: 453010,
    subIndustryName: "Semiconductor Equipment",
    subIndustryCode: 45301010,
  },
  {
    industryName: "Semiconductors & Semiconductor Equipment",
    industryCode: 453010,
    subIndustryName: "Semiconductor",
    subIndustryCode: 45301020,
  },

  {
    industryName: "Diversified Telecommunication Services",
    industryCode: 501010,
    subIndustryName: "Alternative Carriers",
    subIndustryCode: 50101010,
  },

  {
    industryName: "Diversified Telecommunication Services",
    industryCode: 501010,
    subIndustryName: "Integrated Telecommunications Services",
    subIndustryCode: 50101020,
  },
  {
    industryName: "Wireless Telecommunication Services",
    industryCode: 501020,
    subIndustryName: "Wireless Telecommunication Services",
    subIndustryCode: 50102010,
  },

  {
    industryName: "Media",
    industryCode: 502010,
    subIndustryName: "Advertising",
    subIndustryCode: 50201010,
  },
  {
    industryName: "Media",
    industryCode: 502010,
    subIndustryName: "Broadcasting",
    subIndustryCode: 50201020,
  },
  {
    industryName: "Media",
    industryCode: 502010,
    subIndustryName: "Cable & Satellite",
    subIndustryCode: 50201030,
  },
  {
    industryName: "Media",
    industryCode: 502010,
    subIndustryName: "Cable & Satellite",
    subIndustryCode: 50201040,
  },

  {
    industryName: "Entertainment",
    industryCode: 502020,
    subIndustryName: "Movies & Entertainment",
    subIndustryCode: 50202010,
  },
  {
    industryName: "Entertainment",
    industryCode: 502020,
    subIndustryName: "Interactive Home Entertainment",
    subIndustryCode: 50202020,
  },

  {
    industryName: "Interactive Media & Services",
    industryCode: 502030,
    subIndustryName: "Interactive Media & Services",
    subIndustryCode: 50203010,
  },

  {
    industryName: "Electric Utilities",
    industryCode: 551010,
    subIndustryName: "Electric Utilities",
    subIndustryCode: 55101010,
  },

  {
    industryName: "Gas Utilities",
    industryCode: 551020,
    subIndustryName: "Gas Utilities",
    subIndustryCode: 55102010,
  },

  {
    industryName: "Water Utilities",
    industryCode: 551030,
    subIndustryName: "Water Utilities",
    subIndustryCode: 55103010,
  },

  {
    industryName: "Multi-Utilities",
    industryCode: 551040,
    subIndustryName: "Multi-Utilities",
    subIndustryCode: 55104010,
  },

  {
    industryName: "Independent Power and Renewable Electricity Producers",
    industryCode: 551050,
    subIndustryName: "Independent Power Producers & Energy Traders",
    subIndustryCode: 55105010,
  },
  {
    industryName: "Independent Power and Renewable Electricity Producers",
    industryCode: 551050,
    subIndustryName: " Renewable Electricity",
    subIndustryCode: 55105020,
  },

  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Diversified REITs",
    subIndustryCode: 60101010,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Industrial REITs",
    subIndustryCode: 60101020,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Hotel & Resort REITs",
    subIndustryCode: 60101030,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Office REITs",
    subIndustryCode: 60101040,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Health Care REITs",
    subIndustryCode: 60101050,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Residential REITs",
    subIndustryCode: 60101060,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Retail REITs",
    subIndustryCode: 60101070,
  },
  {
    industryName: "Equity Real Estate Investment Trusts (REITs)",
    industryCode: 601010,
    subIndustryName: "Specialized REITs",
    subIndustryCode: 60101080,
  },

  {
    industryName: "Real Estate Management & Development",
    industryCode: 601020,
    subIndustryName: "Diversified Real Estate Activities",
    subIndustryCode: 60102010,
  },
  {
    industryName: "Real Estate Management & Development",
    industryCode: 601020,
    subIndustryName: "Real Estate Operating Companies",
    subIndustryCode: 60102020,
  },
  {
    industryName: "Real Estate Management & Development",
    industryCode: 601020,
    subIndustryName: "Real Estate Development",
    subIndustryCode: 60102030,
  },
  {
    industryName: "Real Estate Management & Development",
    industryCode: 601020,
    subIndustryName: "Real Estate Services",
    subIndustryCode: 60102040,
  },
].map((suggestion) => ({
  value: suggestion.subIndustryName,
  label: suggestion.label,
  industryName: suggestion.industryName,
  industryCode: suggestion.industryCode,
  subIndustryName: suggestion.subIndustryName,
  subIndustryCode: suggestion.subIndustryCode,
}));
