import { ValidateEmail } from "utilities/validation";

export const FormValidator = (field: string, value: string): string[] => {
  const errorMessages: string[] = [];
  if (field === "email") {
    if (value !== "" && !ValidateEmail(String(value))) {
      errorMessages.push("Email is invalid");
    }
  }
  return errorMessages;
};

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  values: { name: string; surname: string; email: string },
  setInvalidFormFields: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let allValid = true;
  let InvalidFormFields = {};

  if (values.name === "") {
    InvalidFormFields = { ...InvalidFormFields, name: "Name not provided" };
    allValid = false;
  }

  if (values.surname === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      surname: "Last name not provided",
    };
    allValid = false;
  }

  if (values.email === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      email: "Please provide a email",
    };
    allValid = false;
  }

  if (values.email === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      email: "Please provide a email",
    };
    allValid = false;
  }

  setInvalidFormFields(InvalidFormFields);
  return allValid;
};

export const ClientAdminFormFieldsValidationsState = {
  name: undefined,
  surname: undefined,
  email: undefined,
};
