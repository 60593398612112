import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";

export interface RunTransactionIDForwardAsyncRequest {
  context: Context;
  transactionID: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RunTransactionIDForwardAsyncResponse {}

export interface RunTransactionIDBackwardAsyncRequest {
  context: Context;
  transactionID: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RunTransactionIDBackwardAsyncResponse {}

export interface ValidationPhaseControllerServiceProvider {
  serviceProvider: string;

  RunTransactionIDForwardAsync(
    request: RunTransactionIDForwardAsyncRequest,
  ): Promise<RunTransactionIDForwardAsyncResponse>;

  RunTransactionIDBackwardAsync(
    request: RunTransactionIDBackwardAsyncRequest,
  ): Promise<RunTransactionIDBackwardAsyncResponse>;
}

const ValidationPhaseControllerServiceProviderName =
  "consistency-TransactionRunnerAsync";

export const TransactionRunnerAsync: ValidationPhaseControllerServiceProvider =
  {
    serviceProvider: ValidationPhaseControllerServiceProviderName,
    async RunTransactionIDForwardAsync(
      request: RunTransactionIDForwardAsyncRequest,
    ): Promise<RunTransactionIDForwardAsyncResponse> {
      return jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${TransactionRunnerAsync.serviceProvider}.RunTransactionIDForwardAsync`,
        request,
      });
    },
    async RunTransactionIDBackwardAsync(
      request: RunTransactionIDBackwardAsyncRequest,
    ): Promise<RunTransactionIDBackwardAsyncResponse> {
      return jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${TransactionRunnerAsync.serviceProvider}.RunTransactionIDBackwardAsync`,
        request,
      });
    },
  };
