import { QueryClient } from "react-query";
import { hash } from "../hash/hash";

// BuildQueryKey Given a unique key it returns an hashed react query key with
// the option of tagging it or not. Adding tag matters when invalidating queries
export function BuildQueryKey(uniqueKey: string, tag?: string): string {
  return `${hash(uniqueKey)}:${tag ? tag : ""}`;
}

// GenerateAPIRequestUniqueKey return a string that uniquely identifies an API request
export function GenerateAPIRequestUniqueKey(
  serviceProviderName: string,
  methodName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: Record<string, any>,
) {
  return `${serviceProviderName}.${methodName}.(${JSON.stringify(request)})`;
}

export function BuildMutationKey(
  serviceProviderName: string,
  methodName: string,
): string {
  return `${serviceProviderName}.${methodName}`;
}

export function CheckQueryCacheDataValid(
  key: string,
  queryClient: QueryClient,
): boolean {
  // retrieve the query from query cache
  const query = queryClient.getQueryCache().find(key, { exact: false });

  // query found
  if (query && query.state.status === "success") {
    // confirm that the data is not expired
    if (
      query.options.cacheTime &&
      new Date().getTime() - new Date(query.state.dataUpdatedAt).getTime() <
        query.options.cacheTime
    ) {
      return true;
    }
  }

  return false;
}
