import React from "react";
import { Avatar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "AvatarAndDirection";

const classes = {
  layout: `${PREFIX}-layout`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.layout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.custom.lavender,
    width: "40px",
    height: "40px",
  },
}));

interface AvatarAndDirectionProps {
  name: string;
  pictureDLURL: string;
  receives: boolean;
  pastTense?: boolean;
}

export function AvatarAndDirection(props: AvatarAndDirectionProps) {
  return (
    <Root className={classes.layout}>
      <Avatar
        sizes="small"
        color="primary"
        src={props.pictureDLURL}
        className={classes.avatar}
        children={props.name.length ? props.name[0] : "?"}
      />
      <Typography
        variant="h6"
        children={`${props.name} ${
          props.receives
            ? props.pastTense
              ? "Received"
              : "Receives"
            : props.pastTense
              ? "Paid"
              : "Pays"
        }`}
      />
    </Root>
  );
}
