"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allAssetflowCategories = void 0;
exports.assetflowCategoryToString = assetflowCategoryToString;
exports.stringToAssetflowCategory = stringToAssetflowCategory;
const assetflowCategory_pb_1 = require("./assetflowCategory_pb");
// Get all asset flow categoryies as enum values
exports.allAssetflowCategories = Object.values(assetflowCategory_pb_1.AssetflowCategory).filter((value) => typeof value === "number");
// Define explicit mappings between AssetflowCategory enums and custom string representations
const assetflowCategoryToStringMapping = {
    [assetflowCategory_pb_1.AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY]: "-",
    [assetflowCategory_pb_1.AssetflowCategory.DIVIDEND_ASSETFLOW_CATEGORY]: "Dividend",
    [assetflowCategory_pb_1.AssetflowCategory.INTEREST_ASSETFLOW_CATEGORY]: "Interest",
    [assetflowCategory_pb_1.AssetflowCategory.PRINCIPAL_ASSETFLOW_CATEGORY]: "Principal",
};
// Reverse mapping from string to AssetflowCategory enum
const stringToAssetflowCategoryMapping = {};
for (const [key, value] of Object.entries(assetflowCategoryToStringMapping)) {
    stringToAssetflowCategoryMapping[value] = Number(key);
}
class UnsupportedAssetflowCategoryError extends Error {
    constructor(assetflowCategory) {
        const message = `Unsupported AssetflowCategory: ${assetflowCategory}`;
        super(message);
        this.assetflowCategory = assetflowCategory;
    }
}
/**
 * Converts a AssetflowCategory enum instance to a custom string representation.
 * @param {AssetflowCategory} assetflowCategory - The asset flow category to convert.
 * @returns {string} The custom string representation of the asset flow category.
 */
function assetflowCategoryToString(assetflowCategory) {
    if (assetflowCategory in assetflowCategoryToStringMapping) {
        return assetflowCategoryToStringMapping[assetflowCategory];
    }
    else {
        throw new UnsupportedAssetflowCategoryError(assetflowCategory);
    }
}
class UnsupportedAssetflowCategoryStringError extends Error {
    constructor(assetflowCategoryStr) {
        const message = `Unsupported asset flow category string: ${assetflowCategoryStr}`;
        super(message);
        this.assetflowCategoryStr = assetflowCategoryStr;
    }
}
/**
 * Converts a custom string representation to a AssetflowCategory enum instance.
 * @param {string} assetflowCategoryStr - The custom string representation of the asset flow category.
 * @returns {AssetflowCategory} The corresponding AssetflowCategory enum instance.
 */
function stringToAssetflowCategory(assetflowCategoryStr) {
    if (assetflowCategoryStr in stringToAssetflowCategoryMapping) {
        return stringToAssetflowCategoryMapping[assetflowCategoryStr];
    }
    else {
        throw new UnsupportedAssetflowCategoryStringError(assetflowCategoryStr);
    }
}
