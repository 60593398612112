import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "react-global-configuration";
import { Environment } from "../const";
import { useLocalStorage } from "./persistentStateStore/useLocalStorage";

export const useTestURLQueryParamsHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateStore } = useLocalStorage();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const testParam = params.get("test");
    const clearStateParam = params.get("clear");

    // Check if the web app is running in non-production environment
    if (config.get("environment") === Environment.Production) {
      // Do nothing if we are running in a prod environment
      return;
    }

    // Check if test param is present and equals 'true'
    if (testParam !== "true") {
      // Do nothing if test param is not found or not equal to 'true'
      return;
    }

    // If both test=true and clearState=true, clear the localStorage key
    if (clearStateParam === "true") {
      updateStore((store) => {
        return {
          ...store,
          testURLParamConfig: undefined,
        };
      });
      navigate(window.location.pathname);
      return;
    }

    const loginSessionExpiry = params.get("loginSessionExpiry");
    if (loginSessionExpiry) {
      updateStore((store) => {
        return {
          ...store,
          testURLParamConfig: {
            ...store.testURLParamConfig,
            sessionExpiry: parseInt(loginSessionExpiry),
          },
        };
      });
      console.debug("loginSessionExpiry set to:", parseInt(loginSessionExpiry));
    }
    navigate(window.location.pathname);
  }, [location, navigate]);
};
