import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import { DateGenerationRule } from "@mesh/common-js/dist/financial/dateGenerationRule_pb";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { ValidationResult } from "common/validation";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export type NonPerpetualScheduleConfigurationValidationData = {
  smartInstrument: SmartInstrument;
};

export function validateNonPerpetualScheduleConfiguration(
  conf: NonPerpetualScheduleConfiguration,
  data: NonPerpetualScheduleConfigurationValidationData,
  fieldValidationsPrefix?: string,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };
  const notValid = (field: string, helperText: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${fieldValidationsPrefix ?? ""}-${field}`
    ] = helperText;
  };

  // perform necessary conversions
  const firstPossibleDate = protobufTimestampToDayjs(
    data.smartInstrument.getIssuedate() ?? new Timestamp(),
  );
  const startDate = protobufTimestampToDayjs(
    conf.getStartdate() ?? new Timestamp(),
  );
  const firstScheduledDate = conf.getFirstscheduleddate()
    ? protobufTimestampToDayjs(conf.getFirstscheduleddate() ?? new Timestamp())
    : null;
  const secondToLastScheduledDate = conf.getSecondtolastscheduleddate()
    ? protobufTimestampToDayjs(
        conf.getSecondtolastscheduleddate() ?? new Timestamp(),
      )
    : null;
  const endDate = protobufTimestampToDayjs(
    conf.getEnddate() ?? new Timestamp(),
  );

  if (startDate.isBefore(firstPossibleDate)) {
    notValid("startDate", "Must be on or after Issue Date");
  }

  if (firstScheduledDate) {
    if (
      firstScheduledDate.isBefore(startDate) ||
      firstScheduledDate.isSame(startDate)
    ) {
      notValid("firstScheduledDate", "Must be after Start Date");
    } else if (
      firstScheduledDate.isAfter(endDate) ||
      firstScheduledDate.isSame(endDate)
    ) {
      notValid("firstScheduledDate", "Must be before End Date");
    }
  }

  if (secondToLastScheduledDate) {
    if (
      secondToLastScheduledDate.isBefore(startDate) ||
      secondToLastScheduledDate.isSame(startDate)
    ) {
      notValid("secondToLastScheduledDate", "Must be after Start Date");
    } else if (
      secondToLastScheduledDate.isAfter(endDate) ||
      secondToLastScheduledDate.isSame(endDate)
    ) {
      notValid("secondToLastScheduledDate", "Must be before End Date");
    } else if (firstScheduledDate) {
      if (
        secondToLastScheduledDate.isBefore(firstScheduledDate) ||
        secondToLastScheduledDate.isSame(firstScheduledDate)
      ) {
        notValid(
          "secondToLastScheduledDate",
          "Must be after First Scheduled Date",
        );
      }
    }
  }

  if (endDate.isBefore(startDate)) {
    notValid("endDate", "Must be after Start Date");
  }

  if (conf.getFrequency() === Frequency.UNDEFINED_FREQUENCY) {
    notValid("frequency", "Must be set");
  }

  if (conf.getCalendarsList().length === 0) {
    notValid("calendars", "Cannot be blank");
  }

  if (
    conf.getBusinessdayconvention() ===
    BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION
  ) {
    notValid("businessDayConvention", "Must be set");
  }

  if (
    conf.getDategenerationrule() ===
    DateGenerationRule.UNDEFINED_DATE_GENERATION_RULE
  ) {
    notValid("dateGenerationRule", "Must be set");
  }

  return validationResult;
}
