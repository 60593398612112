import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { BPTable } from "components/Table";
import {
  Model as RemunerationFeeViewModel,
  Updater as RemunerationFeeViewUpdater,
  useRead,
} from "james/views/remunerationFeeView";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { NewSorting, Query } from "james/search/query";
import { useSnackbar } from "notistack";
import {
  InfoOutlined as InfoIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { Amount } from "components/Ledger/Amount";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "Fee";

const classes = {
  root: `${PREFIX}-root`,
  stablecoinCellLayout: `${PREFIX}-stablecoinCellLayout`,
  infoIcon: `${PREFIX}-infoIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: { padding: theme.spacing(2) },

  [`& .${classes.stablecoinCellLayout}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.infoIcon}`]: {
    cursor: "pointer",
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export function Fee() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const [apiLoading, setAPILoading] = useState(false);

  const { readRequest, readResponse, setReadRequest, loading } = useRead({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleFullUpdate = async () => {
    setAPILoading(true);
    try {
      await RemunerationFeeViewUpdater.FullUpdate({
        context: authContext,
      });
      setReadRequest({
        ...readRequest,
        query: new Query(initialQuery),
      });
      enqueueSnackbar("Full Update Complete", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error performing full update: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Performing Full Update: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setAPILoading(false);
  };

  const remunerationFeesViewConfig = viewConfiguration.Fees
    ? viewConfiguration.Fees
    : {};
  const remunerationFeesModelViewConfig = remunerationFeesViewConfig.ViewModel
    ? remunerationFeesViewConfig.ViewModel
    : {};

  return (
    <Root className={classes.root}>
      <BPTable
        height={window.innerHeight - 90 - noticeBannerHeight - 48}
        title="Fees"
        loading={loading || apiLoading}
        data={readResponse.models}
        totalNoRecords={readResponse.total}
        query={readRequest.query}
        onQueryChange={(query) =>
          setReadRequest({
            ...readRequest,
            query,
          })
        }
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          // add control for view full update
          // only MC has the permissions required
          // to see this button
          if (remunerationFeesModelViewConfig.Update) {
            controls.push(
              <Button
                variant="outlined"
                id="remunerationFees-viewFullUpdate-button"
                children="full view update"
                onClick={handleFullUpdate}
              />,
            );
          }

          controls.push(
            <IconButton
              disabled={loading}
              size="small"
              id="remunerationFees-listRefresh-button"
              onClick={() =>
                setReadRequest({
                  ...readRequest,
                  query: new Query(initialQuery),
                })
              }
            >
              <ReloadIcon />
            </IconButton>,
          );

          return controls;
        })()}
        columns={[
          {
            field: "feeName",
            label: "Name",
          },
          {
            field: "description",
            label: "Description",
          },
          {
            field: "responsibleGroupName",
            label: "Responsible Group",
            accessor: (data) => {
              const viewModel = data as RemunerationFeeViewModel;
              return (
                <div className={classes.stablecoinCellLayout}>
                  <Typography
                    color="inherit"
                    children={viewModel.responsibleGroupName}
                  />
                  <Tooltip
                    placement="top"
                    title={`Group Client: ${viewModel.responsibleGroupsClientName}`}
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
              );
            },
          },
          {
            field: "feeAmount",
            label: "Amount",
            accessor: (data) => (
              <Amount
                amount={(data as RemunerationFeeViewModel).feeAmount}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            ),
          },
          {
            field: "vatRate",
            label: "VAT",
            accessor: (data) => (
              <Amount
                amount={(data as RemunerationFeeViewModel).feeAmount.setValue(
                  data.feeAmount.value.multipliedBy(data.vatRate),
                )}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
              />
            ),
          },
          {
            field: "state",
            label: "State",
          },
        ]}
      />
    </Root>
  );
}
