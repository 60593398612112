"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allLimitOrderTypes = void 0;
exports.limitOrderTypeToString = limitOrderTypeToString;
exports.stringToLimitOrderType = stringToLimitOrderType;
const limitOrder_pb_1 = require("./limitOrder_pb");
exports.allLimitOrderTypes = Object.values(limitOrder_pb_1.LimitOrderType).filter((value) => typeof value === "number");
const limitOrderTypeToStringMapping = {
    [limitOrder_pb_1.LimitOrderType.BUY]: "Buy",
    [limitOrder_pb_1.LimitOrderType.SELL]: "Sell",
};
const stringToLimitOrderTypeMapping = {};
for (const [key, value] of Object.entries(limitOrderTypeToStringMapping)) {
    stringToLimitOrderTypeMapping[value] = Number(key);
}
class UnsupportedLimitOrderTypeError extends Error {
    constructor(limitOrderType) {
        const message = `Unsupported LimitOrderType: ${limitOrderType}`;
        super(message);
        this.limitOrderType = limitOrderType;
    }
}
function limitOrderTypeToString(limitOrderType) {
    if (limitOrderType in limitOrderTypeToStringMapping) {
        return limitOrderTypeToStringMapping[limitOrderType];
    }
    else {
        throw new UnsupportedLimitOrderTypeError(limitOrderType);
    }
}
class UnsupportedLimitOrderTypeStringError extends Error {
    constructor(limitOrderTypeStr) {
        const message = `Unsupported limit order type string: ${limitOrderTypeStr}`;
        super(message);
        this.limitOrderTypeStr = limitOrderTypeStr;
    }
}
function stringToLimitOrderType(limitOrderTypeStr) {
    if (limitOrderTypeStr in stringToLimitOrderTypeMapping) {
        return stringToLimitOrderTypeMapping[limitOrderTypeStr];
    }
    else {
        throw new UnsupportedLimitOrderTypeStringError(limitOrderTypeStr);
    }
}
