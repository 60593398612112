import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import meshLogo from "assets/images/logo/meshLogo.svg";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

const PREFIX = "PublicMainAppBar";

const classes = {
  root: `${PREFIX}-root`,
  meshLogo: `${PREFIX}-meshLogo`,
  appBarButtonsWrapper: `${PREFIX}-appBarButtonsWrapper`,
  typographyMarginRight: `${PREFIX}-typographyMarginRight`,
  loginButtonWrapper: `${PREFIX}-loginButtonWrapper`,
  divider: `${PREFIX}-divider`,
  textDisabled: `${PREFIX}-textDisabled`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 1, 0, 1),
    backgroundColor: theme.palette.custom.midnight,
  },

  [`& .${classes.meshLogo}`]: {
    cursor: "pointer",
  },

  [`& .${classes.appBarButtonsWrapper}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.typographyMarginRight}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.loginButtonWrapper}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.divider}`]: {
    height: 16,
    marginRight: theme.spacing(0.5),
    border: `0.5px solid ${theme.palette.text.disabled}`,
  },

  [`& .${classes.textDisabled}`]: {
    color: theme.palette.text.disabled,
  },
}));

export function PublicMainAppBar() {
  const navigate = useNavigate();

  return (
    <Root className={classes.root}>
      <img
        className={classes.meshLogo}
        id="mainAppBar-mesh-image"
        src={meshLogo}
        alt="Mesh Logo"
        height={45}
      />
      <div className={classes.appBarButtonsWrapper}>
        <Typography
          variant="body1"
          className={cx(classes.typographyMarginRight, classes.textDisabled)}
        >
          You are not currently logged in
        </Typography>
        <div className={classes.loginButtonWrapper}>
          <div className={classes.divider} />
          <Button
            variant="text"
            id="publicMainAppBar-login-button"
            onClick={() => navigate("/sign-in")}
            color="primary"
          >
            Login
          </Button>
        </div>
        <Button
          id="publicMainAppBar-signUp-button"
          onClick={() => navigate("/sign-up")}
          variant="contained"
          color="primary"
        >
          Sign Up
        </Button>
      </div>
    </Root>
  );
}
