import { NativeAssetTokenCode, StellarNetwork } from "james/stellar";
import { Token } from "james/ledger";

export type GetAssetOverviewRequest = {
  token: Token;
};

export type GetAssetOverviewResponse = {
  created: number;
  trades: number;
  rating: {
    age: number;
    trades: number;
    payments: number;
    trustLines: number;
    volume7D: number;
    interop: number;
    liquidity: number;
    average: number;
  };
};

export class StellarExpertClient {
  private url = "";

  constructor(url: string) {
    this.url = url;
  }

  async getAssetOverview(
    request: GetAssetOverviewRequest,
  ): Promise<GetAssetOverviewResponse> {
    // do request
    const responseObject = await fetch(
      `${this.url}/explorer/${getNetworkString(
        request.token.network as StellarNetwork,
      )}/asset/${getTokenAssetString(request.token)}`,
      {
        method: "GET",
        mode: "cors",
        headers: new Headers(),
      },
    );

    // check response code
    if (responseObject.status !== 200) {
      throw new UnexpectedCommunicationError(
        `http request failed with code: ${responseObject.status}`,
      );
    }

    // parse and return
    return await responseObject.json();
  }
}

export class UnexpectedCommunicationError extends Error {
  name = "Unexpected Communication Error";
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class UnexpectedStellarNetworkError extends Error {
  name = "Unexpected Network";
  message: string;

  constructor(network: StellarNetwork) {
    super();
    this.message = `got unexpected stellar network: '${network}'`;
  }
}

export function getTokenAssetString(token: Token): string {
  if (token.code === NativeAssetTokenCode) {
    return NativeAssetTokenCode;
  } else {
    return `${token.code}-${token.issuer}`;
  }
}

export function getNetworkString(network: StellarNetwork): string {
  switch (network) {
    case StellarNetwork.PublicNetwork:
      return "public";
    case StellarNetwork.TestSDFNetwork:
      return "testnet";
    default:
      throw new UnexpectedStellarNetworkError(network);
  }
}
