import dayjs from "dayjs";
import { AuditEntry } from "../audit";
import { Currency } from "./Currency";

export const FiatCurrencyTypeName = "mesh::financial/FiatCurrency";

export class FiatCurrency implements Currency {
  public ["@type"]: string = FiatCurrencyTypeName;

  public id = "";

  public ownerID = "";

  public code = "";

  public name = "";

  public settlementCutoffTime: string = dayjs().format();

  public country = "";

  public timeZoneOffset = 0;

  public timeZoneName = "";

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(fiatCurrency?: FiatCurrency) {
    if (!fiatCurrency) {
      return;
    }
    this.id = fiatCurrency.id;
    this.ownerID = fiatCurrency.ownerID;
    this.code = fiatCurrency.code;
    this.name = fiatCurrency.name;
    this.settlementCutoffTime = fiatCurrency.settlementCutoffTime;
    this.country = fiatCurrency.country;
    this.timeZoneOffset = fiatCurrency.timeZoneOffset;
    this.timeZoneName = fiatCurrency.timeZoneName;
    this.auditEntry = new AuditEntry(fiatCurrency.auditEntry);
  }

  currencyID(): string {
    return this.id;
  }

  currencyCode(): string {
    return this.code;
  }

  currencyName(): string {
    return this.name;
  }

  firstCutOffAfter(reference: string): string {
    const referenceUTC = dayjs(reference);
    const settlementCutoffTimeUTC = dayjs(this.settlementCutoffTime);

    const cutOffDayAtReferenceTime = dayjs()
      .year(settlementCutoffTimeUTC.year())
      .month(settlementCutoffTimeUTC.month())
      .date(settlementCutoffTimeUTC.date())
      .hour(referenceUTC.hour())
      .minute(referenceUTC.minute())
      .second(referenceUTC.second())
      .millisecond(referenceUTC.millisecond());

    // if cut off day at the given reference time is >= cut off
    if (
      cutOffDayAtReferenceTime.isAfter(settlementCutoffTimeUTC) ||
      cutOffDayAtReferenceTime.isSame(settlementCutoffTimeUTC, "millisecond")
    ) {
      // then the next cut off is a day after the given reference
      // at the settlement cut off time
      const referencePlus1Day = referenceUTC.add(24, "hours");
      return dayjs(referencePlus1Day)
        .hour(settlementCutoffTimeUTC.hour())
        .minute(settlementCutoffTimeUTC.minute())
        .second(settlementCutoffTimeUTC.second())
        .millisecond(settlementCutoffTimeUTC.millisecond())
        .format();
    }
    // otherwise the next cut off is on the same day as the given reference
    // at the settlement cut off time
    return dayjs(referenceUTC)
      .hour(settlementCutoffTimeUTC.hour())
      .minute(settlementCutoffTimeUTC.minute())
      .second(settlementCutoffTimeUTC.second())
      .millisecond(settlementCutoffTimeUTC.millisecond())
      .format();
  }

  firstCutOffBefore(): string {
    throw new Error("not implemented");
  }

  firstStartOfDayBefore(reference: string): string {
    const referenceUTC = dayjs(reference);
    const settlementCutoffTimeUTC = dayjs(this.settlementCutoffTime);

    // get a start of day for the currency in utc
    const currencyStartOfDay = settlementCutoffTimeUTC.startOf("day");

    // get a point in time on the start of day at the given reference time
    const currencyStartOfDayDayAtReferenceTime = dayjs(currencyStartOfDay)
      .hour(referenceUTC.hour())
      .minute(referenceUTC.minute())
      .second(referenceUTC.second())
      .millisecond(referenceUTC.millisecond());

    // if start of day day at the given reference time is <= start of day
    if (
      currencyStartOfDayDayAtReferenceTime.isBefore(currencyStartOfDay) ||
      currencyStartOfDayDayAtReferenceTime.isSame(
        currencyStartOfDay,
        "millisecond",
      )
    ) {
      // then the last start of day is a day before the given reference
      // at the currency start of day time
      const referenceMinus1Day = referenceUTC.subtract(24, "hours");
      return dayjs(referenceMinus1Day)
        .hour(currencyStartOfDay.hour())
        .minute(currencyStartOfDay.minute())
        .second(currencyStartOfDay.second())
        .millisecond(currencyStartOfDay.millisecond())
        .format();
    }

    // otherwise the last start of day is on the same day as the given reference
    // at the start of day time
    return dayjs(referenceUTC)
      .hour(currencyStartOfDay.hour())
      .minute(currencyStartOfDay.minute())
      .second(currencyStartOfDay.second())
      .millisecond(currencyStartOfDay.millisecond())
      .format();
  }
}
