import {
  Button,
  IconButton,
  Typography,
  Box,
  alpha,
  Tooltip,
} from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
  EditOutlined as EditIcon,
  RemoveRedEyeOutlined as ViewIcon,
  PriceChange as PriceIcon,
  Assignment as SecuritiesRegisterIcon,
  StorefrontSharp as MarketListingIcon,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import React, { useEffect, useRef, useState } from "react";
import {
  ReadManySmartInstrumentRequest,
  ReadManySmartInstrumentResponse,
} from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as PastQuery } from "james/search/query";
import { BPTable } from "components/Table";
import {
  SmartInstrument,
  SmartInstrumentState,
} from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import {
  smartInstrumentTypeToString,
  unitToString,
} from "@mesh/common-js/dist/financial";
import { SmartInstrumentStateChip } from "../Builder/components/StateChip";
import { useNavigate } from "react-router-dom";
import { ViewMode } from "../Builder/Context";
import { futureNetworkToString } from "@mesh/common-js/dist/ledger";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import { CapturePriceDialog } from "views/Marketplace/components/CapturePriceDialog";
import { Token } from "james/ledger";
import { SynchroniseSmartInstrumentOracleDataRequest } from "@mesh/common-js/dist/financial/oracleDataSynchroniser_pb";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { MintAssetDialog } from "components/Dialogs/Minting";

const initialQuery = new Query().setOffset(0).setLimit(15);

export type TableProps = {
  system: boolean;
};

export const Table = (props: TableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration } = useApplicationContext();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    financial: {
      smartInstrumentReader,
      smartInstrumentReaderUNSCOPED,
      oracleDataSynchroniser,
    },
  } = useAPIContext();
  const [readRequest, setReadRequest] =
    useState<ReadManySmartInstrumentRequest>(
      new ReadManySmartInstrumentRequest()
        .setContext(authContext.toFuture())
        .setQuery(initialQuery),
    );
  const [readResponse, setReadResponse] =
    useState<ReadManySmartInstrumentResponse>(
      new ReadManySmartInstrumentResponse(),
    );
  const [loading, setLoading] = useState(false);
  const [showPriceCaptureScreen, setShowPriceCaptureScreen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManySmartInstrumentResponse;
        if (props.system) {
          response =
            await smartInstrumentReaderUNSCOPED.readManySmartInstrumentUNSCOPED(
              readRequest,
            );
        } else {
          response =
            await smartInstrumentReader.readManySmartInstrument(readRequest);
        }
        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error("error reading smart instrument", e);
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted]);
  const [selectedSmartInstrument, setSelectedSmartInstrument] = useState<
    SmartInstrument | undefined
  >(undefined);
  const [synchronisingOracleData, setSynchronisingOracleData] = useState(false);
  const [showMintDialog, setShowMintDialog] = useState(false);

  return (
    <>
      <BPTable
        loading={loading}
        height={window.innerHeight - 88 - noticeBannerHeight}
        singleSelect
        noDataSplashComponent={noDataSplashComponent()}
        onSingleSelectChange={(data) =>
          setSelectedSmartInstrument(data as SmartInstrument)
        }
        title={"Smart Instruments"}
        query={PastQuery.fromFutureQuery(readRequest.getQuery())}
        onQueryChange={(query) =>
          setReadRequest(readRequest.setQuery(query.toFutureQuery()))
        }
        data={readResponse.getRecordsList()}
        totalNoRecords={readResponse.getTotal()}
        // filters={(() => {
        //   const filters: React.ReactNode[] = [
        //     <TextField
        //       id={"smartInstrumentTable-numberFilter-textField"}
        //       sx={{ width: 160 }}
        //       variant={"outlined"}
        //       margin={"dense"}
        //       label={"Number"}
        //       placeholder={"Start Typing..."}
        //       value={numberSearchCriteria}
        //       InputLabelProps={{ shrink: true }}
        //       onChange={(e) => setNumberSearchCriteria(e.target.value)}
        //     />,
        //     <DateField
        //       label={"From"}
        //       disabled={loading}
        //       id={"smartInstrumentTable-spotDateTimeFromFilter-dateField"}
        //       value={
        //         spotDateTimeCriterionFrom ? spotDateTimeCriterionFrom.date : null
        //       }
        //       onChange={(newValue) => {
        //         if (!(newValue && dateIsValid(newValue.date()))) {
        //           setSpotDateTimeCriterionFrom(undefined);
        //         } else {
        //           setSpotDateTimeCriterionFrom(
        //             newValue
        //               ? {
        //                 date: newValue.startOf("day").format(),
        //                 inclusive: true,
        //                 ignore: false,
        //               }
        //               : undefined,
        //           );
        //         }
        //       }}
        //       renderInput={(textFieldProps: TextFieldProps) => (
        //         <TextField
        //           {...textFieldProps}
        //           id={"smartInstrumentTable-spotDateTimeFromFilter-dateFieldTextField"}
        //           sx={{ width: 160 }}
        //           variant={"outlined"}
        //           margin={"dense"}
        //         />
        //       )}
        //     />,
        //     <DateField
        //       label={"To"}
        //       disabled={loading}
        //       id={"smartInstrumentTable-spotDateTimeToFilter-dateField"}
        //       value={
        //         spotDateTimeCriterionTo ? spotDateTimeCriterionTo.date : null
        //       }
        //       onChange={(newValue) => {
        //         if (!(newValue && dateIsValid(newValue))) {
        //           setSpotDateTimeCriterionTo(undefined);
        //         } else {
        //           setSpotDateTimeCriterionTo(
        //             newValue
        //               ? {
        //                 date: newValue.endOf("day").format(),
        //                 inclusive: true,
        //                 ignore: false,
        //               }
        //               : undefined,
        //           );
        //         }
        //       }}
        //       renderInput={(textFieldProps: TextFieldProps) => (
        //         <TextField
        //           {...textFieldProps}
        //           id={"smartInstrumentTable-spotDateTimeToFilter-dateFieldTextField"}
        //         />
        //       )}
        //     />,
        //     <Autocomplete
        //       isOptionEqualToValue={(option, value) => option === value}
        //       id={"smartInstrumentTable-assetFilter-autocomplete"}
        //       getOptionLabel={(option: TokenViewModel) =>
        //         `${option.token.code} - ${option.issuer}`
        //       }
        //       options={ledgerTokenViewReadResponse.models}
        //       loading={ledgerTokenViewReadLoading}
        //       onChange={(_, selected: TokenViewModel | null) =>
        //         setAssetToken(selected)
        //       }
        //       value={assetToken}
        //       onInputChange={(e, newInputValue, reason) => {
        //         if (reason === "reset") {
        //           return;
        //         }
        //         if (newInputValue === "") {
        //           setLedgerTokenViewReadRequest({
        //             ...ledgerTokenViewReadRequest,
        //             criteria: defaultTokenCriteria,
        //           });
        //         } else {
        //           setLedgerTokenViewReadRequest({
        //             ...ledgerTokenViewReadRequest,
        //             criteria: {
        //               ...defaultTokenCriteria,
        //               "token.code": TextSubstringCriterion(newInputValue),
        //             },
        //           });
        //         }
        //       }}
        //       renderInput={(params) => (
        //         <TextField
        //           {...params}
        //           label={"Asset"}
        //           id={"smartInstrumentTable-assetFilter-autocompleteTextField"}
        //           fullWidth
        //           InputLabelProps={{ shrink: true }}
        //           InputProps={{
        //             ...params.InputProps,
        //             placeholder: "Select...",
        //             sx: { width: 200 },
        //           }}
        //         />
        //       )}
        //     />,
        //     <Autocomplete
        //       isOptionEqualToValue={(option, value) => option === value}
        //       id={"smartInstrumentTable-stateFilter-autocomplete"}
        //       disabled={loading}
        //       multiple
        //       options={AllSpotStates}
        //       filterSelectedOptions
        //       onChange={(_, value: SpotState[]) =>
        //         setSpotStatusesForCriterion(value)
        //       }
        //       ChipProps={{
        //         color: "info",
        //         size: "small",
        //       }}
        //       value={spotStatusesForCriterion}
        //       renderTags={(spotStates: SpotState[]) =>
        //         spotStates.map((s, idx) => (
        //           <SpotStateChip
        //             key={idx}
        //             chipProps={{
        //               onDelete: () =>
        //                 setSpotStatusesForCriterion((prev) =>
        //                   prev.filter((prevState) => prevState !== s),
        //                 ),
        //               deleteIcon: (
        //                 <CancelIcon
        //                   sx={(theme) => ({
        //                     color: `${theme.palette.text.secondary} !important`,
        //                     "&:hover": {
        //                       color: `${theme.palette.secondary.contrastText} !important`,
        //                     },
        //                   })}
        //                 />
        //               ),
        //             }}
        //             state={s}
        //           />
        //         ))
        //       }
        //       renderInput={(params) => (
        //         <TextField
        //           {...params}
        //           id={"smartInstrumentTable-stateFilter-autocompleteTextField"}
        //           sx={{ width: 317 }}
        //           label={"State"}
        //           variant={"outlined"}
        //           margin={"dense"}
        //           InputLabelProps={{ shrink: true }}
        //           placeholder={
        //             spotStatusesForCriterion.length ? undefined : "Select..."
        //           }
        //         />
        //       )}
        //     />,
        //   ];

        //   // if any criteria is set then show a clear all filters button
        //   if (
        //     numberSearchCriteria ||
        //     spotStatusesForCriterion.length ||
        //     spotDateTimeCriterionFrom ||
        //     spotDateTimeCriterionTo ||
        //     assetToken
        //   ) {
        //     filters.push(
        //       <Button
        //         sx={{ marginTop: "10px" }}
        //         id={"smartInstrumentTable-clearAllFilters-button"}
        //         variant={"contained"}
        //         color={"secondary"}
        //         children={"clear all"}
        //         onClick={() => {
        //           setNumberSearchCriteria("");
        //           setSpotStatusesForCriterion([]);
        //           setSpotDateTimeCriterionFrom(undefined);
        //           setSpotDateTimeCriterionTo(undefined);
        //           setAssetToken(null);
        //         }}
        //         startIcon={<ClearFiltersIcon />}
        //       />,
        //     );
        //   }

        //   return filters;
        // })()}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];
          if (selectedSmartInstrument) {
            if (
              selectedSmartInstrument.getState() ===
                SmartInstrumentState.ISSUED_SMART_INSTRUMENT_STATE &&
              (selectedSmartInstrument.getToken()?.getNetwork().toString() ==
                FutureNetwork.STELLAR_TEST_SDF_NETWORK.toString() ||
                selectedSmartInstrument.getToken()?.getNetwork().toString() ==
                  FutureNetwork.STELLAR_PUBLIC_NETWORK.toString())
            ) {
              // SYNCH ORACLE DATA
              if (
                viewConfiguration["Smart Instruments"]?.OracleData
                  ?.Synchronisation
              ) {
                controls.push(
                  <LoadingButton
                    loading={synchronisingOracleData}
                    variant={"contained"}
                    id={"smartInstrumentTable-synchroniseOracleData-button"}
                    children={"Synchronise Oracle Data"}
                    color={"primary"}
                    onClick={async () => {
                      setSynchronisingOracleData(true);
                      try {
                        await oracleDataSynchroniser.synchroniseSmartInstrumentOracleData(
                          new SynchroniseSmartInstrumentOracleDataRequest()
                            .setContext(authContext.toFuture())
                            .setSmartinstrumentid(
                              selectedSmartInstrument.getId(),
                            ),
                        );
                        enqueueSnackbar("Synchronised", { variant: "success" });
                      } catch (e) {
                        console.error(
                          `error synchronising smart instrument data: ${e}`,
                        );
                        enqueueSnackbar("Failed!", { variant: "warning" });
                      }
                      setSynchronisingOracleData(false);
                    }}
                  />,
                );
              }

              // MINT
              if (
                viewConfiguration["Smart Instruments"]?.Mint &&
                selectedSmartInstrument.getDirectmintingallowed()
              ) {
                controls.push(
                  <Button
                    variant={"outlined"}
                    id={"smartInstrumentTable-mint-button"}
                    children={"Mint"}
                    color={"secondary"}
                    onClick={() => setShowMintDialog(true)}
                  />,
                );
              }

              // VIEW SECURITIES REGISTER
              controls.push(
                <Tooltip title="View Securities Register" placement="top">
                  <span>
                    <IconButton
                      id="smartInstrumentTable-securitiesRegister-button"
                      size="small"
                      onClick={() => {
                        if (
                          !selectedSmartInstrument ||
                          !selectedSmartInstrument.getToken()
                        ) {
                          return;
                        }

                        const query = new URLSearchParams();
                        query.set(
                          "code",
                          selectedSmartInstrument.getToken()?.getCode() ?? "",
                        );
                        query.set(
                          "issuer",
                          selectedSmartInstrument.getToken()?.getIssuer() ?? "",
                        );
                        query.set(
                          "network",
                          selectedSmartInstrument
                            .getToken()
                            ?.getNetwork()
                            .toString() ?? "",
                        );

                        navigate({
                          pathname:
                            "/builder/smart-instruments/securities-register",
                          search: query.toString(),
                        });
                      }}
                    >
                      <SecuritiesRegisterIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              // CAPTURE PRICE
              if (viewConfiguration["Smart Instruments"]?.CapturePrice) {
                controls.push(
                  <Tooltip title="Capture Price" placement="top">
                    <span>
                      <IconButton
                        id="instrumentsTable-capturePrice-button"
                        size="small"
                        onClick={() => setShowPriceCaptureScreen(true)}
                      >
                        <PriceIcon />
                      </IconButton>
                    </span>
                  </Tooltip>,
                );
              }
            }

            // GO TO MARKET LISTING
            if (
              (selectedSmartInstrument.getState() ==
                SmartInstrumentState.PREISSUED_SMART_INSTRUMENT_STATE ||
                selectedSmartInstrument.getState() ==
                  SmartInstrumentState.ISSUED_SMART_INSTRUMENT_STATE) &&
              (selectedSmartInstrument.getToken()?.getNetwork().toString() ==
                FutureNetwork.STELLAR_TEST_SDF_NETWORK.toString() ||
                selectedSmartInstrument.getToken()?.getNetwork().toString() ==
                  FutureNetwork.STELLAR_PUBLIC_NETWORK.toString())
            ) {
              controls.push(
                <Tooltip title="Go to Market Listing" placement="top">
                  <span>
                    <IconButton
                      id="smartInstrumentTable-listing-button"
                      size="small"
                      onClick={() => {
                        const token = selectedSmartInstrument.getToken();
                        if (!token) {
                          return;
                        }

                        const query = new URLSearchParams();

                        query.set("token-code", token.getCode());
                        query.set("token-issuer", token.getIssuer());
                        query.set(
                          "token-network",
                          token.getNetwork().toString(),
                        );

                        navigate({
                          pathname: "/builder/market-listing/listing",
                          search: query.toString(),
                        });
                      }}
                    >
                      <MarketListingIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );
            }

            // EDIT DRAFT
            if (
              selectedSmartInstrument.getState() ===
                SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE &&
              viewConfiguration["Smart Instruments"]?.Write?.UpdateDraft
            ) {
              controls.push(
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconButton
                      id="smartInstrumentTable-edit-button"
                      size="small"
                      onClick={() => {
                        if (!selectedSmartInstrument) {
                          return;
                        }
                        const query = new URLSearchParams();
                        query.set("id", selectedSmartInstrument.getId());
                        query.set("mode", ViewMode.Edit);
                        navigate({
                          pathname: "/builder/smart-instruments/builder",
                          search: query.toString(),
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );
            }

            // VIEW
            controls.push(
              <Tooltip title="View" placement="top">
                <span>
                  <IconButton
                    id="smartInstrumentTable-view-button"
                    size="small"
                    onClick={() => {
                      if (!selectedSmartInstrument) {
                        return;
                      }
                      const query = new URLSearchParams();
                      query.set("id", selectedSmartInstrument.getId());
                      query.set("mode", ViewMode.View);
                      navigate({
                        pathname: "/builder/smart-instruments/builder",
                        search: query.toString(),
                      });
                    }}
                  >
                    <ViewIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            );
          } else {
            if (viewConfiguration["Smart Instruments"]?.Write?.CreateDraft) {
              controls.push(
                <Button
                  variant={"contained"}
                  id={"smartInstrumentTable-buildNew-button"}
                  children={"Build New"}
                  color={"primary"}
                  onClick={() => {
                    const query = new URLSearchParams();
                    query.set("mode", ViewMode.Edit);
                    navigate({
                      pathname: "/builder/smart-instruments/builder",
                      search: query.toString(),
                    });
                  }}
                />,
              );
            }
          }

          controls.push(
            <Tooltip title="Refresh" placement="top">
              <span>
                <IconButton
                  id={"smartInstrumentTable-reload-iconButton"}
                  size={"small"}
                  disabled={loading}
                  onClick={() =>
                    setReadRequest(
                      new ReadManySmartInstrumentRequest()
                        .setContext(readRequest.getContext())
                        .setCriteriaList(readRequest.getCriteriaList())
                        .setQuery(readRequest.getQuery()),
                    )
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controls;
        })()}
        columns={[
          {
            label: "Name",
            field: "name",
            accessor: (data) => {
              const model = data as SmartInstrument;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getName()}
                </Typography>
              );
            },
          },
          {
            label: "Type",
            field: "type",
            accessor: (data) => {
              const model = data as SmartInstrument;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {smartInstrumentTypeToString(model.getType())}
                </Typography>
              );
            },
          },
          {
            label: "Network",
            field: "token.network",
            accessor: (data) => {
              const model = data as SmartInstrument;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {futureNetworkToString(
                    model.getToken()?.getNetwork() ??
                      FutureNetwork.UNDEFINED_NETWORK,
                  )}
                </Typography>
              );
            },
          },
          {
            label: "Issue Date",
            field: "issuedate",
            accessor: (data) => {
              const model = data as SmartInstrument;
              return (
                <Typography
                  // id={`smartInstrumentTable-spotNumber-text-${model.number}`}
                  color={"inherit"}
                  variant={"body1"}
                >
                  {protobufTimestampToDayjs(
                    model.getIssuedate() ?? new Timestamp(),
                  ).format(DateTimeFormatWithOffset)}
                </Typography>
              );
            },
          },
          {
            label: "Unit Category",
            field: "unit",
            accessor: (data) => {
              const model = data as SmartInstrument;
              return (
                <Typography
                  // id={`smartInstrumentTable-spotNumber-text-${model.number}`}
                  color={"inherit"}
                  variant={"body1"}
                >
                  {unitToString(model.getUnit())}
                </Typography>
              );
            },
          },
          {
            label: "State",
            field: "state",
            accessor: (data) => {
              const model = data as SmartInstrument;
              return <SmartInstrumentStateChip state={model.getState()} />;
            },
          },
        ]}
      />
      {selectedSmartInstrument && showPriceCaptureScreen && (
        <CapturePriceDialog
          assetID={selectedSmartInstrument.getId()}
          token={Token.fromFutureToken(selectedSmartInstrument.getToken())}
          assetIssueDate={
            selectedSmartInstrument.getIssuedate()?.toDate().toString() ?? ""
          }
          assetName={selectedSmartInstrument.getName()}
          open={showPriceCaptureScreen}
          handleClose={() => setShowPriceCaptureScreen(false)}
        />
      )}
      {selectedSmartInstrument && showMintDialog && (
        <MintAssetDialog
          onCloseClick={() => setShowMintDialog(false)}
          assetToMintID={selectedSmartInstrument.getId()}
        />
      )}
    </>
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>smart instruments</i> once you've created one.
            </span>
          }
        />
      </Box>
    </Box>
  );
};
