import config from "react-global-configuration";
import { Amount, Token } from "james/ledger";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Context } from "../security";
import { DirectOrder } from "./DirectOrder";
import BigNumber from "bignumber.js";

export interface IssuerSubmitDirectBuyOrderRequest {
  context: Context;
  counterpartyGroupID: string;
  buyToken: Token;
  amountIncl: Amount;
  price: Amount;
  tokens: Amount;
}

export interface IssuerSubmitDirectBuyOrderResponse {
  directOrder: DirectOrder;
}

export interface IssuerSubmitDirectSellOrderRequest {
  context: Context;
  counterpartyGroupID: string;
  tokens: Amount;
  price: Amount;
}

export interface IssuerSubmitDirectSellOrderResponse {
  directOrder: DirectOrder;
}

export interface InvestorSubmitDirectBuyOrderRequest {
  context: Context;
  initiatingPartyGroupID: string;
  buyToken: Token;
  amountIncl: Amount;
  estimatedPrice: Amount;
  slippage: BigNumber;
  tokens: Amount;
}

export interface InvestorSubmitDirectBuyOrderResponse {
  directOrder: DirectOrder;
}

export interface InvestorSubmitDirectSellOrderRequest {
  context: Context;
  initiatingPartyGroupID: string;
  tokens: Amount;
  estimatedPrice: Amount;
  slippage: BigNumber;
}

export interface InvestorSubmitDirectSellOrderResponse {
  directOrder: DirectOrder;
}

export const DirectOrderSubmitterServiceProviderName =
  "market-DirectOrderSubmitter";

export const DirectOrderSubmitter = {
  async IssuerSubmitDirectBuyOrder(
    request: IssuerSubmitDirectBuyOrderRequest,
    additionalHeaders?: Headers,
  ): Promise<IssuerSubmitDirectBuyOrderResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DirectOrderSubmitterServiceProviderName}.IssuerSubmitDirectBuyOrder`,
      request,
    });

    return { directOrder: new DirectOrder(response.directOrder) };
  },
  async IssuerSubmitDirectSellOrder(
    request: IssuerSubmitDirectSellOrderRequest,
    additionalHeaders?: Headers,
  ): Promise<IssuerSubmitDirectSellOrderResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DirectOrderSubmitterServiceProviderName}.IssuerSubmitDirectSellOrder`,
      request,
    });
    return { directOrder: new DirectOrder(response.directOrder) };
  },
  async InvestorSubmitDirectBuyOrder(
    request: InvestorSubmitDirectBuyOrderRequest,
    additionalHeaders?: Headers,
  ): Promise<InvestorSubmitDirectBuyOrderResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DirectOrderSubmitterServiceProviderName}.InvestorSubmitDirectBuyOrder`,
      request,
    });

    return { directOrder: new DirectOrder(response.directOrder) };
  },
  async InvestorSubmitDirectSellOrder(
    request: InvestorSubmitDirectSellOrderRequest,
    additionalHeaders?: Headers,
  ): Promise<InvestorSubmitDirectSellOrderResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DirectOrderSubmitterServiceProviderName}.InvestorSubmitDirectSellOrder`,
      request,
    });

    return { directOrder: new DirectOrder(response.directOrder) };
  },
};
