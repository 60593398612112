import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FileCopy as CopyPasteIcon,
  Refresh as ReloadIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { SourceAccountStateChip } from "./Chips";
import { useIsMounted } from "hooks";
import { useSnackbar } from "notistack";
import { SourceAccountStateController, StellarNetwork } from "james/stellar";
import { useApplicationContext } from "context/Application/Application";
import { Model, Reader } from "james/views/stellarSourceAccountView";
import { useErrorContext } from "context/Error";
import { FETable } from "components/Table/FETable";
import { NewSorting, Query } from "james/search/query";
import { LedgerAccountState } from "james/ledger";
import config from "react-global-configuration";
import { Environment } from "const";
import { Popover } from "components/PopOver/Popover";
import ReactJson from "react-json-view";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const SourceAccountView = () => {
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { current: productionEnvironment } = useRef(
    config.get("environment") === Environment.Production,
  );
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  // fetch all source account view models
  const [sourceAccountViewModels, setSourceAccountViewModels] = useState<
    Model[]
  >([]);
  const [fetchingSourceAccountViewModels, setFetchingSourceAccountViewModels] =
    useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchingSourceAccountViewModels(true);
      try {
        const readResponse = await Reader.Read({
          context: authContext,
          criteria: {},
          query: new Query({
            limit: 0,
            offset: 0,
            sorting: [NewSorting("id", "desc")],
          }),
        });
        if (isMounted()) {
          setSourceAccountViewModels(readResponse.models);
        }
      } catch (e) {
        errorContextDefaultErrorFeedback(e);
      }
      setFetchingSourceAccountViewModels(false);
    })();
  }, [isMounted, reload]);

  const [sourceAccountCreationInProgress, setSourceAccountCreationInProgress] =
    useState(false);
  const createSourceAccount = useCallback(async () => {
    setSourceAccountCreationInProgress(true);
    try {
      await SourceAccountStateController.OpenNewSourceAccount({
        context: authContext,
        network: productionEnvironment
          ? StellarNetwork.PublicNetwork
          : StellarNetwork.TestSDFNetwork,
      });
      enqueueSnackbar("Source Account Creation in Progress", {
        variant: "success",
      });
    } catch (e: unknown) {
      errorContextDefaultErrorFeedback(e);
    }
    setSourceAccountCreationInProgress(false);
  }, [authContext, enqueueSnackbar]);

  const [selectedSourceAccount, setSelectedSourceAccount] = useState<
    Model | undefined
  >(undefined);
  const [sourceAccountOpeningInProgress, setSourceAccountOpeningInProgress] =
    useState(false);
  const openSourceAccount = useCallback(async () => {
    setSourceAccountOpeningInProgress(true);
    try {
      if (!selectedSourceAccount) {
        throw new Error("source account not set");
      }
      await SourceAccountStateController.OpenSourceAccount({
        context: authContext,
        sourceAccountID: selectedSourceAccount.id,
      });
      enqueueSnackbar("Source Account Opening in Progress", {
        variant: "success",
      });
    } catch (e: unknown) {
      errorContextDefaultErrorFeedback(e);
    }
    setSourceAccountOpeningInProgress(false);
  }, [authContext, enqueueSnackbar, selectedSourceAccount]);

  const allSourceAccounts = sourceAccountViewModels.length;
  const lockedSourceAccounts = sourceAccountViewModels.filter(
    (ac) => ac.lock.id !== "",
  ).length;
  const freeSourceAccounts = sourceAccountViewModels.filter(
    (ac) => ac.state === LedgerAccountState.Open && ac.lock.id === "",
  ).length;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - 140px - ${noticeBannerHeight}px)`,
        gap: 2,
      }}
    >
      <Card
        sx={{
          flex: 1,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box>
            <Typography>Total Source Accounts: {allSourceAccounts}</Typography>
            <Typography>
              Locked Source Accounts: {lockedSourceAccounts}
            </Typography>
            <Typography color={freeSourceAccounts <= 5 ? "error" : undefined}>
              Free Source Accounts: {freeSourceAccounts}
            </Typography>
          </Box>
          {(viewConfiguration?.Ledger?.["Source Accounts"]?.[
            "Open Source Account"
          ] ??
            undefined) && (
            <Box>
              <Button
                disabled={sourceAccountCreationInProgress}
                onClick={createSourceAccount}
                color={"primary"}
                variant={"contained"}
              >
                Open New Source Account
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
      <FETable
        title={"Source Accounts"}
        singleSelect
        onSingleSelectChange={(data) =>
          setSelectedSourceAccount(data as Model | undefined)
        }
        data={sourceAccountViewModels}
        height={window.innerHeight - 250 - noticeBannerHeight}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (
            (viewConfiguration?.Ledger?.["Source Accounts"]?.[
              "Open Source Account"
            ] ??
              undefined) &&
            selectedSourceAccount &&
            selectedSourceAccount.state === LedgerAccountState.Closed
          ) {
            controls.push(
              <Button
                disabled={sourceAccountOpeningInProgress}
                onClick={openSourceAccount}
                color={"primary"}
                variant={"contained"}
              >
                Open
              </Button>,
            );
          }

          controls.push(
            <IconButton
              size={"small"}
              disabled={fetchingSourceAccountViewModels}
              onClick={() => setReload((prev) => !prev)}
            >
              <ReloadIcon />
            </IconButton>,
          );

          return controls;
        })()}
        columns={[
          {
            label: "ID",
            field: "id",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const txn = data as Model;
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <Typography
                    sx={{
                      width: 100,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="body1"
                    children={txn.id}
                  />
                  <CopyPasteIcon
                    sx={(theme) => ({
                      fontSize: 20,
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(txn.id)
                        .then(() => enqueueSnackbar("Source Account ID copied"))
                    }
                  />
                </Box>
              );
            },
          },
          {
            label: "Public Key",
            field: "publicKey",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const srcAcc = data as Model;
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <Typography
                    sx={{
                      width: 100,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="body1"
                    children={srcAcc.publicKey}
                  />
                  <CopyPasteIcon
                    sx={(theme) => ({
                      fontSize: 20,
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(srcAcc.publicKey)
                        .then(() =>
                          enqueueSnackbar("Source Account Public Key copied"),
                        )
                    }
                  />
                </Box>
              );
            },
          },
          {
            label: "State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const txn = data as Model;
              return <SourceAccountStateChip state={txn.state} />;
            },
          },
          {
            label: "Locked",
            field: "lock",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => (
              <LockDetailCard sourceAccountViewModel={data as Model} />
            ),
          },
        ]}
      />
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default SourceAccountView;

type LockDetailCardProps = {
  sourceAccountViewModel: Model;
};

const LockDetailCard = (props: LockDetailCardProps) => {
  const { enqueueSnackbar } = useSnackbar();

  if (props.sourceAccountViewModel.lock.id === "") {
    return <Box />;
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      popOverComponent={
        <Card style={{ backgroundColor: "#1d1f21" }}>
          <CardContent>
            <ReactJson
              name={false}
              enableClipboard={(e) =>
                navigator.clipboard
                  .writeText(JSON.stringify(e))
                  .then(() => enqueueSnackbar("copied"))
              }
              src={props.sourceAccountViewModel.lock}
              theme="google"
            />
          </CardContent>
        </Card>
      }
    >
      <Tooltip title="Select to view Lock" placement="top">
        <LockIcon
          sx={{
            color: "secondary.light",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Popover>
  );
};
