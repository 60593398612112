"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allFeeTypes = void 0;
exports.feeTypeToString = feeTypeToString;
exports.stringToFeeType = stringToFeeType;
const feeSchedule_pb_1 = require("./feeSchedule_pb");
exports.allFeeTypes = Object.values(feeSchedule_pb_1.FeeType).filter((value) => typeof value === "number");
const feeTypeToStringMap = {
    [feeSchedule_pb_1.FeeType.UNDEFINED_FEE_TYPE]: "-",
    [feeSchedule_pb_1.FeeType.ADVISORY_FEE_TYPE]: "Advisory Fee",
    [feeSchedule_pb_1.FeeType.CREATION_FEE_TYPE]: "Creation Fee",
    [feeSchedule_pb_1.FeeType.MARKETING_FEE_TYPE]: "Marketing Fee",
    [feeSchedule_pb_1.FeeType.FIRST_PLACEMENT_FEE_TYPE]: "1st Placement Fee",
    [feeSchedule_pb_1.FeeType.UTILISATION_OF_STANDARD_LEGAL_AGREEMENT_FEE_TYPE]: "Utilisation Of Standard Legal Agreement Fee",
    [feeSchedule_pb_1.FeeType.ASSETS_UNDER_MANAGEMENT_FEE_TYPE]: "Assets Under Management Fee",
};
const stringToFeeTypeMapping = {};
for (const [key, value] of Object.entries(feeTypeToStringMap)) {
    stringToFeeTypeMapping[value] = Number(key);
}
class UnsupportedFeeTypeError extends Error {
    constructor(feeType) {
        const message = `Unsupported FeeType: ${feeType}`;
        super(message);
        this.dayCountConvention = feeType;
    }
}
/**
 * Converts a DayCountConvention enum instance to a custom string representation.
 * @param {FeeType} feeType - The day count convention to convert.
 * @returns {string} The custom string representation of the day count convention.
 */
function feeTypeToString(feeType) {
    if (feeType in feeTypeToStringMap) {
        return feeTypeToStringMap[feeType];
    }
    else {
        throw new UnsupportedFeeTypeError(feeType);
    }
}
class UnsupportedFeeTypeStringError extends Error {
    constructor(feeTypeStr) {
        const message = `Unsupported FeeType: ${feeTypeStr}`;
        super(message);
        this.feeTypeStr = feeTypeStr;
    }
}
function stringToFeeType(feeTypeStr) {
    if (feeTypeStr in stringToFeeTypeMapping) {
        return stringToFeeTypeMapping[feeTypeStr];
    }
    else {
        throw new UnsupportedFeeTypeStringError(feeTypeStr);
    }
}
