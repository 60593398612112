import React, { useEffect, useState } from "react";
import {
  Typography,
  Autocomplete,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Client, ClientKYCStatus } from "james/client";
import {
  KYCDocumentClassification,
  getDocTypesForDocumentClass,
  KYCDocument,
  KYCDocumentType,
  KYCDocumentState,
} from "james/kyc/KYCDocument";
import { GetAppOutlined as DownloadIcon } from "@mui/icons-material";
import { download } from "utilities/network/download";
import { useDocumentsViewStore } from "./store";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";

interface DocumentsProps {
  client: Client;
  readOnly?: boolean;
}

export const Documents = ({ client }: DocumentsProps) => {
  const store = useDocumentsViewStore();
  useInitialDataFetch(client);

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        pt: 3,
        px: 3,
        maxWidth: 400,
      }}
    >
      <IdentityDocumentsDownloader
        verificationInProgress={
          client.kycStatus === ClientKYCStatus.VerificationInProgressStatus
        }
        header={KYCDocumentClassification.IdentificationDocument}
        clientID={client.id}
        kycDocument={store.identificationClassDocument}
        documentClassification={
          KYCDocumentClassification.IdentificationDocument
        }
        documentTypes={getDocTypesForDocumentClass(
          KYCDocumentClassification.IdentificationDocument,
        )}
        stepsCompletedCheck={() => null}
        refreshClientKYCDocuments={() => null}
      />
      <ResidentialAddressDocumentUploader
        verificationInProgress={
          client.kycStatus === ClientKYCStatus.VerificationInProgressStatus
        }
        header={KYCDocumentClassification.ProofOfResidence}
        clientID={client.id}
        kycDocumentRefs={store.proofOfResidenceClassDocument}
        documentClassification={KYCDocumentClassification.ProofOfResidence}
        documentTypes={getDocTypesForDocumentClass(
          KYCDocumentClassification.ProofOfResidence,
        )}
        stepsCompletedCheck={() => null}
        refreshClientKYCDocuments={() => null}
      />
    </Box>
  );
};

interface DocumentUploaderProps {
  clientID: string;
  header: string;
  kycDocument: KYCDocument;
  documentClassification: KYCDocumentClassification;
  documentTypes: KYCDocumentType[];
  verificationInProgress: boolean;
  stepsCompletedCheck: () => void;
  refreshClientKYCDocuments: () => void;
  hideBorder: boolean;
}

export function DocumentDownloader(props: DocumentUploaderProps) {
  const [errorMessage] = useState<string | undefined>(undefined);

  const handleDownloadDocument = () => {
    download(props.kycDocument.downloadURL, props.kycDocument.name);
  };

  return (
    <Box>
      {!!props.header && (
        <Typography variant="h6" children={props.header} sx={{ mb: 1 }} />
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 60px",
          alignItems: "center",
        }}
      >
        <Autocomplete<"" | KYCDocumentType>
          id={`kycDialog-${props.documentClassification}documentType-autocomplete`}
          options={props.documentTypes}
          value={props.kycDocument.type}
          disabled={!!props.kycDocument.name || props.verificationInProgress}
          isOptionEqualToValue={(option, value) =>
            value === props.kycDocument.type
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={
                !!props.kycDocument.name || props.verificationInProgress
              }
              label="Document Type"
              variant="outlined"
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )}
        />
        <Button
          sx={{ ml: 1, height: 38, mt: 0.5 }}
          id="kycDialog-documentDownload-button"
          variant="outlined"
          disabled={!props.kycDocument.type}
          startIcon={<DownloadIcon />}
          onClick={handleDownloadDocument}
        />
      </Box>
      {props.kycDocument.state === KYCDocumentState.Uploaded && (
        <div>
          <Typography
            variant="body2"
            color="secondary"
            children={props.kycDocument.name}
          />
        </div>
      )}
    </Box>
  );
}

interface IdentityDocumentsDownloaderProps {
  clientID: string;
  header: string;
  kycDocument: KYCDocument[];
  documentClassification: KYCDocumentClassification;
  documentTypes: KYCDocumentType[];
  verificationInProgress: boolean;
  stepsCompletedCheck: () => void;
  refreshClientKYCDocuments: () => void;
}

export function IdentityDocumentsDownloader(
  props: IdentityDocumentsDownloaderProps,
) {
  switch (props.kycDocument.length) {
    case 0:
      // indeterminate state no document was found
      return <></>;

    case 1:
      // determine if we have id card back or front document ref if so render the corresponding document uploader
      // if it is front and back document type of passport render document uploader for them and hidden one
      return (
        <DocumentDownloader
          clientID={props.clientID}
          header={props.header}
          kycDocument={props.kycDocument[0]}
          documentClassification={props.documentClassification}
          documentTypes={props.documentTypes}
          verificationInProgress={props.verificationInProgress}
          stepsCompletedCheck={props.stepsCompletedCheck}
          refreshClientKYCDocuments={props.refreshClientKYCDocuments}
          hideBorder={false}
        />
      );

    case 2:
      // the only possibilities here are the ID card back
      return (
        <>
          <DocumentDownloader
            clientID={props.clientID}
            header={props.header}
            kycDocument={props.kycDocument[0]}
            documentClassification={props.documentClassification}
            documentTypes={props.documentTypes}
            verificationInProgress={props.verificationInProgress}
            stepsCompletedCheck={props.stepsCompletedCheck}
            refreshClientKYCDocuments={props.refreshClientKYCDocuments}
            hideBorder
          />
          <DocumentDownloader
            clientID={props.clientID}
            header=""
            kycDocument={props.kycDocument[1]}
            documentClassification={props.documentClassification}
            documentTypes={props.documentTypes}
            verificationInProgress={props.verificationInProgress}
            stepsCompletedCheck={props.stepsCompletedCheck}
            refreshClientKYCDocuments={props.refreshClientKYCDocuments}
            hideBorder={false}
          />
        </>
      );
    default:
      // indeterminate state more than 2 document refs was uploaded
      return <></>;
  }
}

interface ResidentialAddressDocumentUploaderProps {
  clientID: string;
  header: string;
  kycDocumentRefs: KYCDocument[];
  documentClassification: KYCDocumentClassification;
  documentTypes: KYCDocumentType[];
  verificationInProgress: boolean;
  stepsCompletedCheck: () => void;
  refreshClientKYCDocuments: () => void;
}

export function ResidentialAddressDocumentUploader(
  props: ResidentialAddressDocumentUploaderProps,
) {
  switch (props.kycDocumentRefs.length) {
    case 0:
      // indeterminate state no document was found
      return <></>;

    case 1:
      return (
        <DocumentDownloader
          clientID={props.clientID}
          header={props.header}
          kycDocument={props.kycDocumentRefs[0]}
          documentClassification={props.documentClassification}
          documentTypes={props.documentTypes}
          verificationInProgress={props.verificationInProgress}
          stepsCompletedCheck={props.stepsCompletedCheck}
          refreshClientKYCDocuments={props.refreshClientKYCDocuments}
          hideBorder
        />
      );

    default:
      // indeterminate state more than one document was found
      return <></>;
  }
}
