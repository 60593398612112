import { useEffect } from "react";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { IDIdentifier } from "james/search/identifier";
import { Manager } from "james/client/ManagerRead";
import { useClientDetailsViewStore } from "../store";

export const useInitialDataFetch = (client: Client) => {
  const store = useClientDetailsViewStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();

  useEffect(() => {
    // Guard clauses to early exit if no client or authContext is provided.
    if (!client) {
      return;
    }

    if (!authContext) {
      return;
    }

    store.setLoading(true);
    // IIFE to handle async operations within useEffect.
    (async () => {
      try {
        const response = await Manager.RetrieveClientUnscoped({
          context: authContext,
          identifier: IDIdentifier(client.id),
        });

        store.setLastUpdateBy(response.client.auditEntry.userID);
        store.setLastUpdateDate(response.client.auditEntry.time);
        store.setStateFrom(response.client);
        store.setCachedClient(response.client);
      } catch (e) {
        // Handle errors by translating and possibly setting UI-specific flags.
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving client details: ${
            err.message ? err.message : err.toString()
          }`,
        );
        errorContextDefaultErrorFeedback(e);
      }
      // Reset loading state regardless of outcome.
      store.setLoading(false);
    })();
  }, [client, authContext]);
};
