import { GroupClassification } from "james/group";

export class GroupRoles {
  public groupID = "";

  public groupName = "";

  public groupClassification: GroupClassification | "" = "";

  public roleNames: string[] = [];

  constructor(groupRoles?: GroupRoles) {
    if (!groupRoles) {
      return;
    }
    this.groupID = groupRoles.groupID;
    this.groupName = groupRoles.groupName;
    this.groupClassification = groupRoles.groupClassification;
    this.roleNames = [...groupRoles.roleNames];
  }
}
