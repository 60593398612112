import { Identifier } from "james/search/identifier/Identifier";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { PublicIndicativePrice } from ".";

export interface PublicFetchLatestIndicativePriceRequest {
  assetIdentifier: Identifier;
}

export interface PublicFetchLatestIndicativePriceResponse {
  publicIndicativePrice: PublicIndicativePrice;
}

export const IndicativePriceFetcher = {
  serviceProvider: "market-IndicativePriceFetcher",
  async PublicFetchLatestIndicativePrice(
    request: PublicFetchLatestIndicativePriceRequest,
  ): Promise<PublicFetchLatestIndicativePriceResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshPublicURL"),
      method: `${IndicativePriceFetcher.serviceProvider}.PublicFetchLatestIndicativePrice`,
      request,
    });
    return {
      publicIndicativePrice: new PublicIndicativePrice(
        response.publicIndicativePrice,
      ),
    };
  },
};
