import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { RouteType } from "routes/Route";
import { Router } from "routes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Detail } from "./Detail";
import { Table } from "./Table";

const PREFIX = "Accounts";

const classes = {
  root: `${PREFIX}-root`,
  searchAccountsTextField: `${PREFIX}-searchAccountsTextField`,
  accountCardsWrapper: `${PREFIX}-accountCardsWrapper`,
  breadCrumbLayout: `${PREFIX}-breadCrumbLayout`,
  accountManagementBreadcrumb: `${PREFIX}-accountManagementBreadcrumb`,
  accountNumberBreadCrumb: `${PREFIX}-accountNumberBreadCrumb`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridRowGap: theme.spacing(2),
    padding: `0px ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    overflow: "hidden",
  },

  [`& .${classes.searchAccountsTextField}`]: {
    width: 400,
  },

  [`& .${classes.accountCardsWrapper}`]: {
    maxHeight: 500,
    overflowY: "auto",
  },

  [`& .${classes.breadCrumbLayout}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.accountManagementBreadcrumb}`]: {
    cursor: "pointer",
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.primary.light,
      textDecoration: "underline",
    },
  },

  [`& .${classes.accountNumberBreadCrumb}`]: {
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(0.5),
  },
}));

const accountRoutes: RouteType[] = [
  {
    name: "accounts",
    id: "accounts-table",
    path: "/market-management/accounts/table",
    component: <Table />,
  },
  {
    name: "accounts",
    id: "accounts-table",
    path: "/market-management/accounts/detail",
    component: <Detail />,
  },
];

export function Accounts() {
  const navigate = useNavigate();
  const [activeRoutePath, setActiveRoutePath] = useState("");
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // navigate to first tab
  useEffect(() => {
    // navigate to first available route unless a
    // path to one of them has already been set
    for (const tabRoute of accountRoutes) {
      // check if the current path matches the tab route
      if (location.pathname.includes(tabRoute.path)) {
        setActiveRoutePath(tabRoute.path);
        return;
      }
    }
    navigate(accountRoutes[0].path);
    setActiveRoutePath(accountRoutes[0].path);
  }, [location.pathname]);

  // do not render until active tab has been determined
  if (!activeRoutePath) {
    return null;
  }

  return (
    <Root className={classes.root}>
      {(() => {
        switch (activeRoutePath) {
          case "/market-management/accounts/table":
            return (
              <Typography variant="h2" color="secondary">
                Account Management
              </Typography>
            );
          case "/market-management/accounts/detail":
            return (
              <div className={classes.breadCrumbLayout}>
                <Typography
                  variant="caption"
                  className={classes.accountManagementBreadcrumb}
                  onClick={() => navigate("/market-management/accounts/table")}
                >
                  Account Management
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.accountNumberBreadCrumb}
                >
                  {searchParams.get("number")}
                </Typography>
              </div>
            );
          default:
            return null;
        }
      })()}
      <Router baseURL={"/market-management/accounts"} routes={accountRoutes} />
    </Root>
  );
}
