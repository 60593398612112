import React, { useEffect, useMemo, useState } from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import { AssetType } from "james/views/marketListingView/Model";
import { InstrumentRiskProfile } from "james/financial";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { ContentType } from "./HowDoesItWorkContentProvider";
import cx from "classnames";
import { useErrorContext } from "context/Error";

type RiskSectionType = {
  rating: ContentType[];
  body: ContentType[];
  info?: ContentType[];
  link?: ContentType[];
  outro?: ContentType[];
};

export const RiskSection = ({
  marketListingViewModel,
  section,
}: HowDoesItWorkSectionProps) => {
  const content = section.content as RiskSectionType;
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const theme = useTheme();

  const isTradfi = useMemo((): boolean => {
    switch (marketListingViewModel.assetType) {
      case AssetType.ETF:
      case AssetType.ETN:
      case AssetType.RightsToAnETF:
      case AssetType.RightsToAnETN:
        return true;
    }
    return false;
  }, [marketListingViewModel]);

  const smartTradeFi =
    marketListingViewModel.assetType === AssetType.RightsToAnAMC;

  const isBond =
    marketListingViewModel.assetType === AssetType.Bond ||
    marketListingViewModel.assetType === AssetType.PreferenceShare;

  const [issuerName, setIssuerName] = useState("");
  useEffect(() => {
    (async () => {
      try {
        setIssuerName(
          (await getLedgerTokenViewModel(marketListingViewModel.token)).issuer,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting issuer name: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [marketListingViewModel.token, getLedgerTokenViewModel]);

  const [RiskColor] = useState((): string => {
    switch (marketListingViewModel.assetType) {
      case AssetType.CryptoCurrency:
      case AssetType.RightsToAFiatCurrency:
      case AssetType.RightsToACryptoCurrency:
      case AssetType.YieldBearingStablecoin:
        return "#FFB400";
    }

    switch (marketListingViewModel.instrumentRiskProfile) {
      case InstrumentRiskProfile.Low:
        return theme.palette.secondary.light;
      case InstrumentRiskProfile.High:
        return "#D94A4B";
      case InstrumentRiskProfile.Moderate:
        return "#FFCA83";
      case InstrumentRiskProfile.LowToModerate:
        return "#ACDDB2";
      case InstrumentRiskProfile.ModerateToHigh:
        return "#F27049";
      default:
        return theme.palette.text.primary;
    }
  });

  const rating = useMemo(
    () => (content.rating ? content.rating[0] : undefined),
    [],
  );
  const body = useMemo(() => (content.body ? content.body[0] : undefined), []);

  const link = useMemo(() => (content.link ? content.link[0] : undefined), []);
  const footer = useMemo(
    () => (content.outro ? content.outro[0] : undefined),
    [],
  );

  return (
    <>
      <Box
        sx={{
          pt: {
            sm: 5,
            xs: 3,
          },
          px: {
            sm: 5,
            xs: 3,
          },
          pb: 5,
          maxWidth: {
            lg: "716",
          },
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>
          {rating ? rating.title : ""}
        </Typography>
        {rating && !isBond && rating.info && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              mt: 1,
              color: RiskColor,
            }}
          >
            {rating?.info[0]}
          </Typography>
        )}
        {!isBond && (
          <Box
            sx={{
              width: "44px",
              height: "4px",
              borderRadius: "4px",
              backgroundColor: RiskColor,
              my: 3,
            }}
          />
        )}
        {!isTradfi && body?.title && (
          <Typography variant="h5" sx={{ mb: 2 }}>
            {body.title}
          </Typography>
        )}
        {!isTradfi && body?.info ? (
          body.info.map((v, i) => {
            return (
              <Box>
                <Typography
                  key={i}
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  {v}
                </Typography>
                {link?.info && (
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: 16,
                      lineHeight: "21px",
                    }}
                  >
                    <Link
                      href={link.info[0].toString()}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      underline="none"
                    >
                      Read More
                    </Link>
                  </Typography>
                )}
                <Box
                  className={cx({
                    bond: isBond,
                  })}
                  sx={{
                    "&.bond": {
                      mt: 4,
                      borderBottom: `1px solid ${theme.palette.text.disabled}`,
                    },
                  }}
                ></Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            {marketListingViewModel.instrumentRiskProfileDescription}
          </Typography>
        )}
        {issuerName && isTradfi && (
          <Typography
            sx={{
              color: theme.palette.custom.lavender,
              mt: 4,
            }}
          >
            As defined by <b>{issuerName}</b>
          </Typography>
        )}
        {smartTradeFi && (
          <Typography
            sx={{
              color: theme.palette.custom.lavender,
              mt: 4, //FIXME: This is hardcoded
            }}
          >
            As defined by <b>AnBro Capital Investments</b>
          </Typography>
        )}
        <Box
          sx={{
            py: 4,
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
            columnGap: 10,
          }}
        >
          {content.info &&
            content.info.map((c, i) => (
              <Box
                key={i}
                sx={{
                  boxSizing: "border-box", // Include padding in the width calculation
                  pb: 4,
                  mb: 4,
                  width: { sm: 340, xs: "100%" },
                  // mr: { xs: 2, sm: 0 },
                  "&:last-child": {
                    mb: 0,
                    gridColumn: { sm: "span 2" },
                    width: "100%",
                  },
                  borderBottom: `1px solid ${theme.palette.text.disabled}`,
                }}
              >
                <Box>
                  <Typography
                    sx={(theme) => ({
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: theme.palette.warning.light,
                    })}
                  >
                    {c.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "left",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {c.info}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
        {content.outro && (
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "left",
              color: theme.palette.text.secondary,
            }}
          >
            {footer?.info}
          </Typography>
        )}
      </Box>
    </>
  );
};
