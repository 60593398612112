export class RoleGroups {
  public roleName = "";

  public groupNames: string[] = [];

  constructor(roleGroups?: RoleGroups) {
    if (!roleGroups) {
      return;
    }
    this.roleName = roleGroups.roleName;
    this.groupNames = [...roleGroups.groupNames];
  }
}
