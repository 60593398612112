import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { styled } from "@mui/material/styles";

import { Box, Typography } from "@mui/material";

import { CompanyRegStep } from "views/KYB/CompanyRegistration";
import { FormFieldsValidation } from "./Validation";

import { BusinessAddress } from "./components";
import { FormState } from "views/KYB/useFormState";
import camelCase from "lodash/camelCase";
import debounce from "lodash/debounce";
import { FormValidator } from "../CompanyDetails/Validation";
import { Address } from "james/location";

const PREFIX = "ContactDetails";

const classes = {
  addressLayout: `${PREFIX}-addressLayout`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.addressLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}));

interface ContactDetailsProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  businessAddressSame: boolean;
  setBusinessAddressSame: (value: boolean) => void;
  headOfficeAddressSame: boolean;
  setHeadOfficeAddressSame: (value: boolean) => void;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
  nextPressed: boolean;
  setNextPressed: Dispatch<SetStateAction<boolean>>;
  savingInProgress: boolean;
  formState: FormState;
}

export const ContactDetails: React.FC<ContactDetailsProps> = (
  props: ContactDetailsProps,
) => {
  const {
    onChange,
    onNext,
    businessAddressSame,
    setBusinessAddressSame,
    invalidStep,
    inValid,
    nextPressed,
    setNextPressed,
    savingInProgress,
    formState,
  } = props;

  const [invalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>({});

  const [ranValidation, setRanValidation] = useState(false);

  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...invalidFormFields };
      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        delete InvalidFormField[field];
      }

      setInvalidFormFields(InvalidFormField);
    }, 600);

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const keys = name.split(".") as (keyof Address)[];
    const newAddress = new Address({
      ...formState.company.businessAddress,
      [keys[1]]: event.target.value,
    } as Address);
    InlineFormValidation(camelCase(name), event.target.value)();
    onChange("businessAddress")(newAddress);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAutoComplete = (name: string, value: any) => {
    const keys = name.split(".") as (keyof Address)[];
    const newAddress = new Address({
      ...formState.company.businessAddress,
      [keys[1]]: value,
    } as Address);
    InlineFormValidation(camelCase(name), value)();
    onChange("businessAddress")(newAddress);
  };

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(
      invalidFormFields,
      formState.company,
      setInvalidFormFields,
    );
  }

  useEffect(() => {
    if (!savingInProgress && nextPressed) {
      if (
        FormFieldsValidation(
          invalidFormFields,
          formState.company,
          setInvalidFormFields,
        )
      ) {
        onNext(CompanyRegStep.companyRepresentative)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.contactDetails, false);
      } else {
        onNext(CompanyRegStep.companyRepresentative)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.contactDetails, true);
      }
    }
  }, [savingInProgress, nextPressed]);

  return (
    <Root>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary">
            Company Contact Information
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="subtitle2"
        style={{ marginTop: "8px", marginBottom: "32px" }}
      >
        From which address does your company predominantly operate?
      </Typography>

      <div className={classes.addressLayout}>
        <BusinessAddress
          businessAddressSame={businessAddressSame}
          setBusinessAddressSame={setBusinessAddressSame}
          onChangeValue={onUpdate}
          onAutoComplete={onAutoComplete}
          formState={formState}
          FormFieldValid={invalidFormFields}
          setFormFieldValid={setInvalidFormFields}
        />
      </div>
    </Root>
  );
};
