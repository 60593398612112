import { GroupUser } from "james/user";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React, { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AccountOperator, AddSignatoriesResponse } from "james/stellar";
import { useSnackbar } from "notistack";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";

import { AccountSignatoriesContext } from "views/Accounts/components/AccountDetails/AccountDetails";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

interface AccountSignatoryCardProps {
  signatoryGroupUser: GroupUser;
  accountID?: string;
  renderCheckBox: boolean;
  onClick?: (gUser: GroupUser) => void;
}

export function AccountSignatoryCard(props: AccountSignatoryCardProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const theme = useTheme();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const {
    retrieveGroupUsers,
    accountSignatoriesBeingRemoved,
    setAccountSignatoriesBeingRemoved,
  } = useContext(AccountSignatoriesContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const { registerNotificationCallback } = useNotificationContext();

  const handleRemoveAccountSignatories = async () => {
    setAccountSignatoriesBeingRemoved({
      ...accountSignatoriesBeingRemoved,
      [props.signatoryGroupUser.id]: true,
    });

    let addSignatoriesResponse: AddSignatoriesResponse;
    try {
      // notify the user that the operation is in progress
      enqueueSnackbar(
        `Removing ${props.signatoryGroupUser.fullName} as a signatory`,
        {
          variant: "info",
        },
      );

      addSignatoriesResponse = await AccountOperator.RemoveSignatories({
        context: authContext,
        accountID: `${props.accountID}`,
        userIDs: [props.signatoryGroupUser.id],
      });

      setAccountSignatoriesBeingRemoved({
        ...accountSignatoriesBeingRemoved,
        [props.signatoryGroupUser.id]: false,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error! Removing Signatory Failed: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      setAccountSignatoriesBeingRemoved({
        ...accountSignatoriesBeingRemoved,
        [props.signatoryGroupUser.id]: false,
      });
      return;
    }

    try {
      // register a callback to fire once the signatory has been added
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: addSignatoriesResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === addSignatoriesResponse.transactionID
          ) {
            enqueueSnackbar(
              `Removed Signatory - ${props.signatoryGroupUser.fullName}`,
              { variant: "success" },
            );
            retrieveGroupUsers();
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === addSignatoriesResponse.transactionID
          ) {
            enqueueSnackbar("Error! Removing Signatories Failed", {
              variant: "error",
            });
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === addSignatoriesResponse.transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong while removing signatories",
              { variant: "warning" },
            );
          }
          setAccountSignatoriesBeingRemoved({
            ...accountSignatoriesBeingRemoved,
            [props.signatoryGroupUser.id]: false,
          });
          deregister();
        },
      );
    } catch (e) {
      console.error(`error subscribing to transaction updates: ${e}`);
      enqueueSnackbar(
        `Unable to Subscribe to Transaction Updates. Please refresh to see if signatories have been removed.`,
        { variant: "warning" },
      );
      setAccountSignatoriesBeingRemoved({
        ...accountSignatoriesBeingRemoved,
        [props.signatoryGroupUser.id]: false,
      });
      return;
    }
  };

  const initials = signatoryInitials(props.signatoryGroupUser.fullName);

  const [onChecked, setOnChecked] = useState(false);

  return (
    <div>
      {isMobile && (
        <Card
          id={`accountManagement-signatory-card-${props.accountID}`}
          sx={{
            display: "flex",
            height: "64px",
            maxWidth: "497px",
            my: 2,
            padding: theme.spacing(0, 3, 0, 2),
            boxShadow: "none",
          }}
        >
          <Grid
            sx={{
              maxWidth: 48,
              width: "100%",
            }}
            alignItems="center"
            justifyContent="space-between"
            container
            item
          >
            <Avatar
              sx={{
                backgroundColor: theme.palette.custom.lavender,
              }}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid
            sx={{
              padding: theme.spacing(0, 0, 0, 2),
            }}
            alignItems="center"
            justifyContent="space-between"
            container
          >
            <Grid
              sx={{
                width: 160,
                whiteSpace: "nowrap",
              }}
              item
            >
              <Typography
                style={{ marginBottom: theme.spacing(0.5) }}
                variant="h6"
              >
                {props.signatoryGroupUser.fullName}
              </Typography>
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                color="textSecondary"
                variant="body2"
              >
                {props.signatoryGroupUser.roles.map((v, idx) => {
                  if (props.signatoryGroupUser.roles.length > 3 && idx === 2) {
                    return "...";
                  }
                  if (props.signatoryGroupUser.roles.length > 3 && idx > 2) {
                    return "";
                  }
                  if (
                    props.signatoryGroupUser.roles.length === 1 ||
                    props.signatoryGroupUser.roles.length === idx + 1
                  ) {
                    return `${v.name} `;
                  }
                  return `${v.name}, `;
                })}
              </Typography>
            </Grid>
            {props.renderCheckBox ? (
              <Grid item>
                <Checkbox
                  id={`addSignatries-${props.signatoryGroupUser.email}-checkbox`}
                  checked={onChecked}
                  onChange={() => {
                    setOnChecked(!onChecked);
                    if (props.onClick) props.onClick(props.signatoryGroupUser);
                  }}
                  name="checkedB"
                  color="primary"
                />
              </Grid>
            ) : (
              <Grid item>
                {accountSignatoriesBeingRemoved[props.signatoryGroupUser.id] ? (
                  <CircularProgress size={25} />
                ) : viewConfiguration.RemoveSignatories ? (
                  <IconButton
                    id={`accountSignatoryCard-remove${props.signatoryGroupUser.email}-button`}
                    size="small"
                    onClick={handleRemoveAccountSignatories}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Grid>
            )}
          </Grid>
        </Card>
      )}
      {!isMobile && (
        <Card
          id={`accountManagement-signatory-card-${props.accountID}`}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "64px",
            px: 2,
            my: 2,
            boxShadow: "none",
            gap: 2,
          }}
        >
          <Avatar
            sx={{
              backgroundColor: theme.palette.custom.lavender,
            }}
          >
            {initials}
          </Avatar>

          <Box sx={{ width: "calc(100% - 120px)" }}>
            <Typography
              sx={{
                mb: 0.5,
              }}
              noWrap
              variant="h6"
            >
              {props.signatoryGroupUser.fullName}
            </Typography>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100%",
              }}
              noWrap
              color="textSecondary"
              variant="body2"
            >
              {props.signatoryGroupUser.roles.map((v, idx) => {
                if (props.signatoryGroupUser.roles.length > 3 && idx === 2) {
                  return "...";
                }
                if (props.signatoryGroupUser.roles.length > 3 && idx > 2) {
                  return "";
                }
                if (
                  props.signatoryGroupUser.roles.length === 1 ||
                  props.signatoryGroupUser.roles.length === idx + 1
                ) {
                  return `${v.name} `;
                }
                return `${v.name}, `;
              })}
            </Typography>
          </Box>

          <Box sx={{ ml: "auto" }}>
            {props.renderCheckBox ? (
              <Checkbox
                id={`addSignatries-${props.signatoryGroupUser.email}-checkbox`}
                checked={onChecked}
                onChange={() => {
                  setOnChecked(!onChecked);
                  if (props.onClick) {
                    props.onClick(props.signatoryGroupUser);
                  }
                }}
                name="checkedB"
                color="primary"
              />
            ) : accountSignatoriesBeingRemoved[props.signatoryGroupUser.id] ? (
              <CircularProgress size={25} />
            ) : viewConfiguration.RemoveSignatories ? (
              <IconButton
                id={`accountSignatoryCard-remove${props.signatoryGroupUser.email}-button`}
                size="small"
                onClick={handleRemoveAccountSignatories}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </Box>
        </Card>
      )}
    </div>
  );
}

function signatoryInitials(fullName: string) {
  const fullNameList = fullName.split(" ");
  let initials = fullNameList[0].substring(0, 1).toUpperCase();

  if (fullNameList.length > 1) {
    initials += fullNameList[fullNameList.length - 1]
      .substring(0, 1)
      .toUpperCase();
  }
  return initials;
}
