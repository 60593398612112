import { StellarAccount } from "james/stellar";
import { Balance } from "./Balance";

export const ModelTypeName = "mesh::stellarAccountView/Model";

export class Model extends StellarAccount {
  ["@type"]: string = ModelTypeName;
  accountOwnerGroupName = "";
  accountOwnerClientName = "";
  balances: Balance[] = [];

  constructor(model?: Model) {
    super(model);
    if (!model) {
      return;
    }
    this.accountOwnerGroupName = model.accountOwnerGroupName;
    this.accountOwnerClientName = model.accountOwnerClientName;
    this.balances = model.balances.map((b) => new Balance(b));
  }
}
