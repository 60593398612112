import { create } from "zustand";
import {
  CompanyRepresentative,
  ConnectedCompany,
  LegalForm,
} from "james/legal";
import { Address } from "james/location";

interface TConnectedEntitiesDialogState {
  businessName: string;
  registeredName: string;
  registrationNumber: string;
  legalForm: LegalForm | "";
  registeredAddress: Address;
  businessAddress: Address;
  headOfficeAddress: Address;
  companyRepresentative: CompanyRepresentative;
}

const initialConnectedEntitiesDialogState: TConnectedEntitiesDialogState = {
  businessName: "",
  registeredName: "",
  registrationNumber: "",
  legalForm: "",
  registeredAddress: new Address(),
  businessAddress: new Address(),
  headOfficeAddress: new Address(),
  companyRepresentative: new CompanyRepresentative(),
};

interface TConnectedEntitiesDialogActions {
  setBusinessName: (val: string) => void;
  setRegisteredName: (val: string) => void;
  setRegistrationNumber: (val: string) => void;
  setLegalForm: (val: LegalForm | "") => void;
  setRegisteredAddress: (val: Address) => void;
  setBusinessAddress: (val: Address) => void;
  setHeadOfficeAddress: (val: Address) => void;
  setCompanyRepresentative: (val: CompanyRepresentative) => void;
  getConnectedEntitiesObject: () => ConnectedCompany;
  setStateFrom: (connectedEntities: ConnectedCompany) => void;
  clear: () => void;
}

export const useConnectedEntitiesDialogStore = create<
  TConnectedEntitiesDialogState & TConnectedEntitiesDialogActions
>((set, get) => ({
  ...initialConnectedEntitiesDialogState,
  setBusinessName: (val: string) => set(() => ({ businessName: val })),
  setRegisteredName: (val: string) => set(() => ({ registeredName: val })),
  setRegistrationNumber: (val: string) =>
    set(() => ({ registrationNumber: val })),
  setLegalForm: (val: LegalForm | "") => set(() => ({ legalForm: val })),
  setRegisteredAddress: (val: Address) =>
    set(() => ({ registeredAddress: val })),
  setBusinessAddress: (val: Address) => set(() => ({ businessAddress: val })),
  setHeadOfficeAddress: (val: Address) =>
    set(() => ({ headOfficeAddress: val })),
  getConnectedEntitiesObject: () => {
    const state = get();
    return new ConnectedCompany({
      id: "",
      businessName: state.businessName,
      legalForm: state.legalForm,
      registeredName: state.registeredName,
      registrationNumber: state.registrationNumber,
      businessAddress: state.businessAddress,
      registeredAddress: state.registeredAddress,
      headOfficeAddress: state.headOfficeAddress,
      companyRepresentative: state.companyRepresentative,
    });
  },
  setStateFrom: (connectedEntities: ConnectedCompany) =>
    set(() => ({
      id: connectedEntities.id,
      businessName: connectedEntities.businessName,
      legalForm: connectedEntities.legalForm,
      registeredName: connectedEntities.registeredName,
      registrationNumber: connectedEntities.registrationNumber,
      businessAddress: connectedEntities.businessAddress,
      registeredAddress: connectedEntities.registeredAddress,
      headOfficeAddress: connectedEntities.headOfficeAddress,
      companyRepresentative: connectedEntities.companyRepresentative,
    })),
  clear: () => set(() => ({ ...initialConnectedEntitiesDialogState })),
  setCompanyRepresentative: (val: CompanyRepresentative) =>
    set(() => ({ companyRepresentative: val })),
}));
