import { ValidationResult } from "common/validation";
import { LegalForm } from "james/legal";
import { Company } from "james/legal/company";

export type FormState = {
  companyName: string;
  profilePictureData: string;
  companyType: LegalForm;
  company: Company;
};

export type FormUpdaterSpecsType = {
  companyName: (value: string, prevState?: FormState) => FormState;
  profilePictureData: (value: string, prevState?: FormState) => FormState;
  companyType: (value: LegalForm, prevState?: FormState) => FormState;
  company: (value: Company, prevState?: FormState) => FormState;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  companyName: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    companyName: value,
  }),
  profilePictureData: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    profilePictureData: value,
  }),
  companyType: (
    value: LegalForm,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    companyType: value,
  }),
  company: (value: Company, prevState?: FormState): FormState => {
    const f = prevState as FormState;
    return {
      ...f,
      company: value,
    };
  },
};

export const validationFunc = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!formState.companyName) {
    newValidationState.fieldValidations.companyName =
      "Please enter company name";
  }

  return newValidationState;
};

const initializeState = (): FormState => {
  return {
    companyName: "",
    profilePictureData: "",
    companyType: LegalForm.SouthAfricanCompanyLegalForm,
    company: new Company(),
  };
};

export const initialState = initializeState();
