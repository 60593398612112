import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MarketListingViewModel } from "james/views/marketListingView";
import { useEffect, useState } from "react";
import { SkeletonLoader } from "components/SkeletonLoader";
import { AssetInformation } from "../Context";
import { ReadMore } from "@mesh/common-js-react/dist/Utility";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";

interface SecuritiesRegisterOverviewProps {
  marketListingViewModel: MarketListingViewModel;
  assetInformation: AssetInformation;
  loading: boolean;
}

export const SecuritiesRegisterOverview = ({
  marketListingViewModel,
  assetInformation,
  loading,
}: SecuritiesRegisterOverviewProps) => {
  const [data, setData] = useState<MarketListingViewModel>(
    marketListingViewModel ?? new MarketListingViewModel(),
  );
  const [asset, setAsset] = useState<AssetInformation>(assetInformation);

  const getAssetUnitLabel = (assetUnit: Unit) => {
    switch (assetUnit) {
      case Unit.BOND_UNIT:
        return "Bond";
      case Unit.PREFERENCE_SHARE_UNIT:
        return "Pref. Share";
      case Unit.NOTE_UNIT:
        return "Note";
      case Unit.SHARE_UNIT:
        return "Share";
      default:
        return "Unit";
    }
  };

  useEffect(() => {
    if (
      marketListingViewModel === undefined ||
      assetInformation === undefined
    ) {
      return;
    }
    setData(marketListingViewModel);
    setAsset(assetInformation);
  }, [marketListingViewModel, assetInformation]);
  return (
    <Box mb={5}>
      <Typography variant="h1" fontWeight={600} mb={2}>
        Securities Register
      </Typography>
      <Typography variant="body1" mb={3} sx={{ fontStyle: "italic" }}>
        "A list of noteholders for certificated securities whose transfer has
        been restricted"
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            Number of Restricted Securities:
          </Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="body1">
              {asset.assetIssuedUnits}{" "}
              {getAssetUnitLabel(marketListingViewModel?.assetUnit)}
              {asset.assetIssuedUnits > 1 ? "s" : ""}
            </Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            Issuer:
          </Typography>

          <SkeletonLoader loading={loading}>
            <Typography variant="body1">{asset.issuerName}</Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            Asset Name:
          </Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="body1">{asset.assetName}</Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            State of Instrument:
          </Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="body1">{asset.assetState}</Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            Tokens in Circulation:
          </Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="body1">
              {asset.assetIssuedUnits}{" "}
              {getAssetUnitLabel(marketListingViewModel?.assetUnit)}
              {asset.assetIssuedUnits > 1 ? "s" : ""}
            </Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            Issue Date:
          </Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="body1">
              {dayjs(data?.assetIssueDate).format("YYYY/MM/DD")}
            </Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            Maturity Date:
          </Typography>
          <Typography variant="body1">{asset.assetMaturityDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" variant="subtitle2">
            No "uncertificated" note:
          </Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="body1">
              {"0 (Zero) notes are held in uncertificated form"}
            </Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={12}>
          <Typography color="GrayText" variant="subtitle2">
            Transfer Restrictions Applicable:
          </Typography>
          <SkeletonLoader loading={loading}>
            <ReadMore variant="body1">
              {
                "There are substantial restrictions on the transferability of the Securities certificated herein. All sales and transfers of the Securities must be made in accordance with the Terms and Conditions of the Securities as contained in the Prospectus. The transfer and/or sale of the Securities shall be initiated, processed, and effected exclusively between users on the Mesh Platform. Any transfer of the Securities, or rights therein, made in violation of the transfer provisions set forth herein will be void."
              }
            </ReadMore>
          </SkeletonLoader>
        </Grid>
      </Grid>
    </Box>
  );
};
