import { Amount } from "james/ledger";
import { ValidationResult } from "common/validation";
import BigNumber from "bignumber.js";

export type FormState = {
  tokensToBurn: Amount;
  balance: Amount;
  fee: Amount;
  vat: Amount;
  feeTokenBalance: Amount;
};

export type FormUpdaterSpecsType = {
  tokensToBurn: (value: Amount, prevState?: FormState) => FormState;
  balance: (value: Amount, prevState?: FormState) => FormState;
  fee: (value: Amount, prevState?: FormState) => FormState;
  vat: (rate: BigNumber, prevState?: FormState) => FormState;
  feeTokenBalance: (value: Amount, prevState?: FormState) => FormState;
};
export const FormUpdater: FormUpdaterSpecsType = {
  tokensToBurn: (
    value: Amount,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    tokensToBurn: value,
  }),
  balance: (
    value: Amount,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    balance: value,
  }),
  fee: (value: Amount, prevState: FormState = {} as FormState): FormState => ({
    ...prevState,
    fee: value,
  }),
  vat: (
    rate: BigNumber,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    vat: prevState.fee.setValue(rate.multipliedBy(prevState.fee.value)),
  }),
  feeTokenBalance: (
    value: Amount,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    feeTokenBalance: value,
  }),
};

export const FormValidator = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (formState.tokensToBurn.value.isZero()) {
    newValidationState.fieldValidations.tokensToBurn =
      "Cannot be equal or less than 0";
    newValidationState.valid = false;
  }

  if (formState.tokensToBurn.value.isGreaterThan(formState.balance.value)) {
    newValidationState.fieldValidations.tokensToBurn =
      "Exceeds available balance";
    newValidationState.valid = false;
  }

  if (
    formState.feeTokenBalance.value.isLessThan(
      formState.fee.value.plus(formState.vat.value),
    )
  ) {
    newValidationState.fieldValidations.fee = "Insufficient Funds";
    newValidationState.valid = false;
  }

  return newValidationState;
};
