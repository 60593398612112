import { ValidationResult } from "common/validation";
import { FormData, ListingStateChangeAction } from "./ListingManagementDialog";
import { Mechanism, QuoteParameter } from "james/market";
import { Amount, PlatformMinimumDealSize, Token } from "james/ledger";
import { MaxSetLimitAmount } from "james/stellar";
import _ from "lodash";

export type FormUpdaterSpecsType = {
  baseToken: (baseToken: Token, prevFormData?: FormData) => FormData;
  marketMechanisms: (
    marketMechanism: Array<Mechanism>,
    prevFormData?: FormData,
  ) => FormData;
  addQuoteParameter: (
    updateProps: {
      quoteToken: Token;
      mechanismIdx: number;
    },
    prevFormData?: FormData,
  ) => FormData;
  removeQuoteParameter: (
    updateProps: {
      mechanismIdx: number;
      quoteParameterIdx: number;
    },
    prevFormData?: FormData,
  ) => FormData;
  updateQuoteParameterMaxDealSize: (
    updateProps: {
      mechanismIdx: number;
      quoteParameterIdx: number;
      amount: Amount;
    },
    prevFormData?: FormData,
  ) => FormData;
  updateQuoteParameterMinDealSize: (
    updateProps: {
      mechanismIdx: number;
      quoteParameterIdx: number;
      amount: Amount;
    },
    prevFormData?: FormData,
  ) => FormData;

  listingStateChangeAction: (
    listingStateChangeAction: ListingStateChangeAction | undefined,
    prevFormData?: FormData,
  ) => FormData;
  lastActionAnnotation: (
    lastActionAnnotation: string,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  baseToken(baseToken: Token, prevFormData?: FormData): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      baseToken,
    };
  },
  marketMechanisms(
    marketMechanisms: Array<Mechanism>,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      marketMechanisms: marketMechanisms,
    };
  },
  addQuoteParameter(
    updateProps: {
      mechanismIdx: number;
      quoteToken: Token;
    },
    prevFormData?: FormData,
  ): FormData {
    prevFormData = _.cloneDeep(prevFormData) as FormData;
    prevFormData.marketMechanisms[updateProps.mechanismIdx].quoteParameters = [
      ...prevFormData.marketMechanisms[updateProps.mechanismIdx]
        .quoteParameters,
      new QuoteParameter({
        quoteToken: updateProps.quoteToken,
        minimumDealSize: prevFormData.baseToken.newAmountOf(
          PlatformMinimumDealSize,
        ),
        maximumDealSize: prevFormData.baseToken.newAmountOf(MaxSetLimitAmount),
      }),
    ];

    return {
      ...prevFormData,
      marketMechanisms: prevFormData.marketMechanisms,
    };
  },
  removeQuoteParameter(
    updateProps: {
      mechanismIdx: number;
      quoteParameterIdx: number;
    },
    prevFormData?: FormData,
  ): FormData {
    prevFormData = _.cloneDeep(prevFormData) as FormData;
    prevFormData.marketMechanisms[updateProps.mechanismIdx].quoteParameters =
      prevFormData.marketMechanisms[
        updateProps.mechanismIdx
      ].quoteParameters.filter(
        (_, idx) => idx !== updateProps.quoteParameterIdx,
      );
    return {
      ...prevFormData,
      marketMechanisms: prevFormData.marketMechanisms,
    };
  },
  updateQuoteParameterMaxDealSize(
    updateProps: {
      mechanismIdx: number;
      quoteParameterIdx: number;
      amount: Amount;
    },
    prevFormData?: FormData,
  ): FormData {
    prevFormData = _.cloneDeep(prevFormData) as FormData;
    prevFormData.marketMechanisms[updateProps.mechanismIdx].quoteParameters[
      updateProps.quoteParameterIdx
    ].maximumDealSize = updateProps.amount;
    return {
      ...prevFormData,
      marketMechanisms: prevFormData.marketMechanisms,
    };
  },
  updateQuoteParameterMinDealSize(
    updateProps: {
      mechanismIdx: number;
      quoteParameterIdx: number;
      amount: Amount;
    },
    prevFormData?: FormData,
  ): FormData {
    prevFormData = _.cloneDeep(prevFormData) as FormData;
    prevFormData.marketMechanisms[updateProps.mechanismIdx].quoteParameters[
      updateProps.quoteParameterIdx
    ].minimumDealSize = updateProps.amount;
    return {
      ...prevFormData,
      marketMechanisms: prevFormData.marketMechanisms,
    };
  },
  listingStateChangeAction(
    listingStateChangeAction: ListingStateChangeAction | undefined,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      listingStateChangeAction,
      lastActionAnnotation: "",
    };
  },
  lastActionAnnotation(
    lastActionAnnotation: string,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      lastActionAnnotation,
    };
  },
};

export const validationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // validate minimum and maximum deal sizes
  formData.marketMechanisms.map((m, mechanismIdx) => {
    // ensure at least 1 quote parameter is given
    if (!m.quoteParameters.length) {
      validationResult.valid = false;

      validationResult.fieldValidations.quoteTokens = "Select at least 1";
    }
    m.quoteParameters.forEach((qp, quoteParameterIdx) => {
      // minimum deal size validation
      if (qp.minimumDealSize.value.lt(PlatformMinimumDealSize)) {
        validationResult.valid = false;
        validationResult.fieldValidations[
          `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-minimumDealSize`
        ] = `Must be > ${PlatformMinimumDealSize}`;
      } else if (qp.minimumDealSize.value.gte(qp.maximumDealSize.value)) {
        validationResult.valid = false;
        validationResult.fieldValidations[
          `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-minimumDealSize`
        ] = `Must be < Max`;
      }

      // maximum deal size validation
      if (qp.maximumDealSize.value.lt(PlatformMinimumDealSize)) {
        validationResult.valid = false;
        validationResult.fieldValidations[
          `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-maximumDealSize`
        ] = `Must be > ${PlatformMinimumDealSize}`;
      } else if (qp.minimumDealSize.value.gte(qp.maximumDealSize.value)) {
        validationResult.valid = false;
        validationResult.fieldValidations[
          `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-maximumDealSize`
        ] = `Must be > Min`;
      }
    });
  });

  if (formData.listingStateChangeAction && !formData.lastActionAnnotation) {
    validationResult.valid = false;
    validationResult.fieldValidations.lastActionAnnotation = "Cannot be blank";
  }

  return validationResult;
};
