import React, { useCallback } from "react";

import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { Company } from "james/legal/company/Company";
import { InviteClientClaims } from "james/security/claims";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import { CompanyDetails, CompanyProfile, ContactDetails } from "./components";
import { ClientAdministrator } from "./components/ClientAdministratorData/ClientAdministratorData";
import { CompanyRepresentative } from "./components/CompanyRepresentative/CompanyRepresentative";
import { ConnectedIndividual } from "./components/ConnectedIndividual/ConnectedIndividual";
import { NonIndividual } from "./components/NonIndividual/NonIndividual";
import { ClientAdminStepValidator, CompanyStepsValidator } from "./Validation";

interface FrameworkProps {
  inviteClientClaims: InviteClientClaims;
  activeStep: CompanyRegStep;
  setActiveStep: (step: CompanyRegStep) => void;
  company: Company;
  admin: { name: string; surname: string; email: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAdmin: any;
}

interface StepType {
  companyRegStep: CompanyRegStep;
  component: React.ReactNode;
  label: React.ReactNode | string;
  CompanyStepsValidator?: (
    companyRegStep: CompanyRegStep,
    company: Company,
  ) => boolean;
  ClientAdminStepValidator?: (admin: {
    name: string;
    surname: string;
    email: string;
  }) => boolean;
}

export function Framework(props: FrameworkProps) {
  const {
    activeStep,
    setActiveStep,
    onChange,
    company,
    admin,
    setAdmin,
    inviteClientClaims,
  } = props;

  const [businessAddressSame, setBusinessAddressSame] =
    React.useState<boolean>(false);
  const [headOfficeAddressSame, setHeadOfficeAddressSame] =
    React.useState<boolean>(false);
  const [postalOfficeAddressSame, setPostalOfficeAddressSame] =
    React.useState<boolean>(false);

  const [stepValidationStatus, setStepValidationStatus] =
    React.useState<StepValidStatusType>({
      ...StepValidStatus,
    });

  const onNext = (step: CompanyRegStep) => () => {
    setActiveStep(step);
  };

  const isAllStepsValid = () => {
    for (const key in stepValidationStatus) {
      // Ensure key is part of stepValidationType
      if (Object.prototype.hasOwnProperty.call(stepValidationStatus, key)) {
        if (key !== "clientAdministratorData") {
          // Get typed key to resolve key implicit type error
          const typedKey = key as keyof StepValidStatusType;
          if (stepValidationStatus[typedKey] === true) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const invalidateStep = (step: CompanyRegStep, validStatus: boolean) => {
    setStepValidationStatus({
      ...stepValidationStatus,
      [`${step}`]: validStatus,
    });
  };

  const steps: StepType[] = [
    {
      companyRegStep: CompanyRegStep.companyProfile,
      component: (
        <CompanyProfile
          onNext={onNext}
          inviteClientClaims={inviteClientClaims}
          onChange={onChange}
          company={company}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.companyProfile}
        />
      ),
      label: "Company Profile",
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.companyDetails,
      component: (
        <CompanyDetails
          onNext={onNext}
          onChange={onChange}
          company={company}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.companyDetails}
        />
      ),
      label: "Company Details",
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.contactDetails,
      component: (
        <ContactDetails
          onNext={onNext}
          onChangeValue={onChange}
          company={company}
          setBusinessAddressSame={setBusinessAddressSame}
          businessAddressSame={businessAddressSame}
          headOfficeAddressSame={headOfficeAddressSame}
          setHeadOfficeAddressSame={setHeadOfficeAddressSame}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.contactDetails}
        />
      ),
      label: "Contact Details",
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.companyRepresentative,
      component: (
        <CompanyRepresentative
          onNext={onNext}
          onChange={onChange}
          company={company}
          postalOfficeAddressSame={postalOfficeAddressSame}
          setPostalOfficeAddressSame={setPostalOfficeAddressSame}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.companyRepresentative}
        />
      ),
      label: "Company Representative",
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.connectedIndividuals,
      component: (
        <ConnectedIndividual
          onNext={onNext}
          onChange={onChange}
          company={company}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.connectedIndividuals}
        />
      ),
      label: "Connected Individuals",
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.connectedNonIndividuals,
      component: (
        <NonIndividual
          onNext={onNext}
          onChange={onChange}
          company={company}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.connectedNonIndividuals}
        />
      ),
      label: (
        <Typography variant="inherit">
          Connected Entity
          <Typography variant="inherit" color="textSecondary">
            {" Optional"}
          </Typography>
        </Typography>
      ),
      CompanyStepsValidator,
    },
    {
      companyRegStep: CompanyRegStep.clientAdministratorData,
      component: (
        <ClientAdministrator
          onNext={onNext}
          onChange={setAdmin}
          admin={admin}
          invalidStep={invalidateStep}
          inValid={stepValidationStatus.clientAdministratorData}
          isAllStepsValid={isAllStepsValid}
        />
      ),
      label: "Client Administrator",
      ClientAdminStepValidator,
    },
  ];

  const getActiveStep = useCallback(
    () => steps.findIndex((step) => step.companyRegStep === activeStep),
    [steps],
  );

  const handleStep = (idx: number) => () => {
    const currentStep = steps[getActiveStep()];
    if (currentStep.companyRegStep === CompanyRegStep.clientAdministratorData) {
      if (
        currentStep.ClientAdminStepValidator &&
        currentStep.ClientAdminStepValidator(admin)
      ) {
        invalidateStep(currentStep.companyRegStep, false);
        setActiveStep(steps[idx].companyRegStep);
        return;
      }
    } else if (
      currentStep.CompanyStepsValidator &&
      currentStep.CompanyStepsValidator(currentStep.companyRegStep, company)
    ) {
      invalidateStep(currentStep.companyRegStep, false);
      setActiveStep(steps[idx].companyRegStep);
      return;
    }
    invalidateStep(currentStep.companyRegStep, true);
    setActiveStep(steps[idx].companyRegStep);
  };

  const renderComponent = () => {
    const step = steps.find((s) => s.companyRegStep === activeStep);
    if (step) {
      return step.component;
    }
    return null;
  };

  return (
    <>
      <Stepper
        activeStep={getActiveStep()}
        alternativeLabel
        nonLinear
        sx={{
          background: "transparent",
        }}
      >
        {steps.map((step, idx) => (
          <Step
            id={`companyRegistration-${step.companyRegStep}-stepper`}
            key={idx}
            completed={stepValidationStatus[`${step.companyRegStep}`] === false}
          >
            <StepButton
              id={`${step.companyRegStep}-stepperButton`}
              onClick={handleStep(idx)}
            >
              <StepLabel
                id={`companyRegistration-${step.companyRegStep}-stepperLabel`}
                error={stepValidationStatus[`${step.companyRegStep}`]}
              >
                <Typography variant="caption">{step.label}</Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {renderComponent()}
    </>
  );
}

type StatusType = boolean | undefined;

// Ensure the step valid types match up with the company register steps
type StepValidStatusType = {
  [K in CompanyRegStep]: StatusType;
};

const StepValidStatus: StepValidStatusType = {
  companyProfile: undefined,
  companyDetails: undefined,
  contactDetails: undefined,
  companyRepresentative: undefined,
  connectedIndividuals: undefined,
  connectedNonIndividuals: undefined,
  clientAdministratorData: undefined,
  landing: undefined,
  confirmation: undefined,
};
