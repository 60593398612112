import { Context } from "james/security";
import config from "react-global-configuration";
import {
  JSONRPCRequestOpts,
  jsonRPCRequest,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface StartVerificationRequest {
  context: Context;
  clientID: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StartVerificationResponse {}

export interface SetKYCStatusVerifiedRequest {
  context: Context;
  clientID: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetKYCStatusVerifiedResponse {}

export interface SetKYCStatusOutstandingRequest {
  context: Context;
  clientID: string;
  reason: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetKYCStatusOutstandingResponse {}

export const ClientKYBStateController = {
  serviceProvider: "kyb-ClientKYBStateController",
  async StartVerification(
    request: StartVerificationRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<StartVerificationResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ClientKYBStateController.serviceProvider}.StartVerification`,
        request,
      },
      opts,
    );
  },
  async SetKYCStatusVerified(
    request: SetKYCStatusVerifiedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SetKYCStatusVerifiedResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ClientKYBStateController.serviceProvider}.SetKYCStatusVerified`,
        request,
      },
      opts,
    );
  },
  async SetKYCStatusOutstanding(
    request: SetKYCStatusOutstandingRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SetKYCStatusOutstandingResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ClientKYBStateController.serviceProvider}.SetKYCStatusOutstanding`,
        request,
      },
      opts,
    );
  },
};
