import React from "react";
import { Box, Typography } from "@mui/material";
import googleIcon from "assets/images/googleIcon.jpg";

interface ContinueWithGoogleProps {
  onClick?: () => void;
  dataLinkAttribute: string;
  companyOnboarding?: boolean;
}

export const ContinueWithGoogle = (props: ContinueWithGoogleProps) => (
  <Box
    id={"authenticate-with-google-button"}
    sx={(theme) => ({
      display: "flex",
      cursor: "pointer",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.common.white,
      gridTemplateColumns: "auto 1fr",
      gridColumnGap: 10,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: theme.spacing(6),
      marginBottom: props.companyOnboarding
        ? theme.spacing(10)
        : theme.spacing(2),
    })}
    data-link-info={props.dataLinkAttribute}
    onClick={props.onClick}
  >
    <img
      src={googleIcon}
      alt=""
      style={{
        height: 28,
      }}
    />
    <Typography
      sx={{
        color: "#00000061",
      }}
      fontSize="17px"
      variant="h4"
      children="Continue with Google"
    />
  </Box>
);
