import { Context } from "james/security";
import { IdentificationType } from "james/legal";
import { User } from "james/user";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

interface RegisterUserRequest {
  context: Context;
  firstName: string;
  middleNames: string;
  lastName: string;
  nationality: string;
  identificationType: IdentificationType;
  identificationNumber: string;
}

interface RegisterUserResponse {
  user: User;
}

interface InitiateUserRegistrationRequest {
  context: Context;
  companyName: string;
  firebaseUserIDToken: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InitiateUserRegistrationResponse {}

export const CompanyUserRegistrar = {
  serviceProvider: "onboarding-CompanyUserRegistrar",
  async RegisterUser(
    request: RegisterUserRequest,
    additionalHeaders?: Headers,
  ): Promise<RegisterUserResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${CompanyUserRegistrar.serviceProvider}.RegisterUser`,
        request,
      },
      {
        additionalHeaders,
      },
    );
  },
  async InitiateUserRegistration(
    request: InitiateUserRegistrationRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<InitiateUserRegistrationResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${CompanyUserRegistrar.serviceProvider}.InitiateUserRegistration`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { token: response.token };
  },
};
