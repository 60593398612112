import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";

export interface UpdateForAccountRequest {
  context: Context;
  accountIdentifier: Identifier;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateForAccountResponse {}

export const Updater = {
  serviceProvider: "stellarClaimableBalanceView-Updater",
  async UpdateForAccount(
    request: UpdateForAccountRequest,
  ): Promise<UpdateForAccountResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.UpdateForAccount`,
      request,
    });
  },
};
