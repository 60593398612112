import { Token } from "pkgTemp/ledger";
import { AllStellarNetworks, StellarNetwork } from "./Network";

export function NewLiquidityPoolToken(tokenA: Token, tokenB: Token): Token {
  return new Token({
    code: `${tokenA.code}/${tokenB.code}`,
    issuer: `${tokenA.issuer}/${tokenB.issuer}`,
    network: tokenA.network,
  });
}

export function IsLiquidityPoolToken(token: Token): boolean {
  return (
    token.code.split("/").length === 2 &&
    token.issuer.split("/").length === 2 &&
    AllStellarNetworks.includes(token.network as StellarNetwork)
  );
}

export function LiquidityPoolTokenToReserveTokens(
  token: Token,
): [Token, Token] {
  if (!IsLiquidityPoolToken(token)) {
    throw new TypeError(`expected liquidity pool token but got: ${token}`);
  }

  const tokenCodes = token.code.split("/");
  const tokenIssuers = token.issuer.split("/");

  return [
    new Token({
      code: tokenCodes[0],
      issuer: tokenIssuers[0],
      network: token.network,
    }),
    new Token({
      code: tokenCodes[1],
      issuer: tokenIssuers[1],
      network: token.network,
    }),
  ];
}
