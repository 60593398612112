import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { Identifier } from "james/search/identifier/Identifier";
import { AfterEffect } from "./AfterEffect";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveAfterEffectRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveAfterEffectResponse {
  afterEffect: AfterEffect;
}

export interface SearchAfterEffectsRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchAfterEffectsResponse {
  records: AfterEffect[];
  total: number;
}

export const AfterEffectRepository = {
  serviceProvider: "consistency-AfterEffectRepository",
  async RetrieveAfterEffect(
    request: RetrieveAfterEffectRequest,
  ): Promise<RetrieveAfterEffectResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("consistencyHorizonAuthorizedURL"),
      method: `${AfterEffectRepository.serviceProvider}.RetrieveAfterEffect`,
      request,
    });
    return {
      afterEffect: new AfterEffect(response.afterEffect),
    };
  },
  async SearchAfterEffects(
    request: SearchAfterEffectsRequest,
  ): Promise<SearchAfterEffectsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("consistencyHorizonAuthorizedURL"),
      method: `${AfterEffectRepository.serviceProvider}.SearchAfterEffects`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map((m: AfterEffect) => new AfterEffect(m)),
    };
  },
};

export function useSearchAfterEffects(
  initialSearchAfterEffectsRequest?: SearchAfterEffectsRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchAfterEffectsRequest, setSearchAfterEffectsRequest] =
    useState<SearchAfterEffectsRequest>(
      initialSearchAfterEffectsRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchAfterEffectsResponse, setSearchAfterEffectsResponse] =
    useState<SearchAfterEffectsResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        setSearchAfterEffectsResponse(
          await AfterEffectRepository.SearchAfterEffects(
            searchAfterEffectsRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for afterEffects: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for afterEffects: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchAfterEffectsRequest, shouldNotExecute]);

  return {
    searchAfterEffectsRequest,
    setSearchAfterEffectsRequest,
    searchAfterEffectsResponse,
    loading,
    error,
  };
}
