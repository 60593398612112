import { Document } from "james/document";
import config from "react-global-configuration";
import { ETN } from "./ETN";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export interface DraftUpdateETNRequest {
  context: Context;
  etn: ETN;
}

export interface DraftUpdateETNResponse {
  etn: ETN;
}

export interface ChangeETNMaturityDateRequest {
  context: Context;
  etnID: string;
  maturityDate: string;
}

export interface ChangeETNMaturityDateResponse {
  etn: ETN;
}

export interface ChangeETNHoldingsRequest {
  context: Context;
  etnID: string;
  updatedHoldings: Holding[];
}

export interface ChangeETNHoldingsResponse {
  etn: ETN;
}

export interface ChangeETNSectorAllocationsRequest {
  context: Context;
  etnID: string;
  updatedSectorAllocations: SectorAllocation[];
}

export interface ChangeETNSectorAllocationsResponse {
  etn: ETN;
}

export interface ChangeETNCountryAllocationsRequest {
  context: Context;
  etnID: string;
  updatedCountryAllocations: CountryAllocation[];
}

export interface ChangeETNCountryAllocationsResponse {
  etn: ETN;
}

export interface ChangeETNAnnualPerformanceLogRequest {
  context: Context;
  etnID: string;
  updatedAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
}

export interface ChangeETNAnnualPerformanceLogResponse {
  etn: ETN;
}

export interface ChangeETNSupportingDocumentsRequest {
  context: Context;
  etnID: string;
  updatedSupportingDocuments: Document[];
}

export interface ChangeETNSupportingDocumentsResponse {
  etn: ETN;
}

export const ETNUpdater = {
  serviceProvider: "financial-ETNUpdater",
  async DraftUpdateETN(
    request: DraftUpdateETNRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.DraftUpdateETN`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
  async ChangeETNMaturityDate(
    request: ChangeETNMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNMaturityDateResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.ChangeETNMaturityDate`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
  async ChangeETNHoldings(
    request: ChangeETNHoldingsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNHoldingsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.ChangeETNHoldings`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
  async ChangeETNSectorAllocations(
    request: ChangeETNSectorAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNSectorAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.ChangeETNSectorAllocations`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
  async ChangeETNCountryAllocations(
    request: ChangeETNCountryAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNCountryAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.ChangeETNCountryAllocations`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
  async ChangeETNAnnualPerformanceLog(
    request: ChangeETNAnnualPerformanceLogRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNAnnualPerformanceLogResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.ChangeETNAnnualPerformanceLog`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
  async ChangeETNSupportingDocuments(
    request: ChangeETNSupportingDocumentsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETNSupportingDocumentsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETNUpdater.serviceProvider}.ChangeETNSupportingDocuments`,
      request,
    });
    return { etn: new ETN(response.etn) };
  },
};
