import React from "react";
import { Box, Button, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const MarketplacePortfolioBanner = () => {
  return (
    <Box
      id="marketplace_portfolio_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-end",
        backgroundPosition: { sm: "35%", md: 0 },
        pt: "57px",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "diversified portfolio",
        component_driver: "showcase",
      })}
      data-testid="marketplace_portfolio_banner"
    >
      <Box
        sx={(theme) => ({
          width: 473,
          ml: {
            lg: "120px",
            md: "80px",
            sm: "60px",
          },
          mr: {
            lg: "118px",
            md: "80px",
            sm: "60px",
          },
          "& .red": {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Typography
          textAlign="end"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
        >
          Manage your <span className="red">diversified portfolio</span>
        </Typography>
        <Typography
          textAlign="end"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
          mb={2}
        >
          {" "}
          of assets, all in one place.
        </Typography>
        <Button
          data-link-info={JSON.stringify({
            content_interaction_id: "hero-banner-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "explore",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
          sx={{ display: "none" }}
          variant="contained"
          color="primary"
        >
          Explore
        </Button>
      </Box>
    </Box>
  );
};
