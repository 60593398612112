import { DataComponentInfo, InteractionDriver } from "const/gtm";
import React from "react";
import { Box } from "@mui/material";

export const RibbonContainer = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        height: 48,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
      data-component-info={JSON.stringify({
        component_id: "primary_nav",
        component_business_name: "Primary and profile navigation",
        component_title: "Top Navigation",
        component_driver: InteractionDriver.Navigation,
      } as DataComponentInfo)}
      data-testid="ribbon-container-data-component"
    >
      {children}
    </Box>
  );
};
