import config from "react-global-configuration";
import { Context } from "../security";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Amount } from "../ledger/Amount";

export type SubmitDefundOrderRequest = {
  context: Context;
  accountID: string;
  bankAccountID: string;
  amount: Amount;
  express: boolean;
};

export type SubmitDefundOrderResponse = {
  transactionID: string;
  number: string;
};

export type SettleDefundOrderRequest = {
  context: Context;
  defundOrderID: string;
  bankReference: string;
};

export type SettleDefundOrderResponse = Record<string, never>;

export const DefundOrderStateControllerServiceProviderName =
  "banking-DefundOrderStateController";

export const DefundOrderStateController = {
  async SubmitDefundOrder(
    request: SubmitDefundOrderRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SubmitDefundOrderResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DefundOrderStateControllerServiceProviderName}.SubmitDefundOrder`,
        request,
      },
      opts,
    );
    return {
      transactionID: response.transactionID,
      number: response.number,
    };
  },
  async SettleDefundOrder(
    request: SettleDefundOrderRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SettleDefundOrderResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DefundOrderStateControllerServiceProviderName}.SettleDefundOrder`,
        request,
      },
      opts,
    );
  },
};
