import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DateField } from "components/FormFields";
import { DirectOrderState } from "james/market/DirectOrder";
import React from "react";
import { useEffectRunAfterFirstRender } from "hooks/useEffectRunAfterFirstRender";
import { dateIsValid } from "utilities/date";
import { DirectOrderStateChip } from "views/Marketplace/components/Orders/components/Chips";
import {
  Cancel as CancelIcon,
  Close as ClearIcon,
  DeleteForever as ClearFiltersIcon,
} from "@mui/icons-material";
import {
  DirectOrderSearchCriteria,
  FilterValues,
  GetDirectOrderSearchCriteria,
} from "../OrdersScrollList";
import { TableMode } from "views/Marketplace/components/Orders/Table";

interface OrderScrollFiltersProps {
  filterValues: FilterValues;
  setFilter: React.Dispatch<React.SetStateAction<FilterValues>>;
  setCriteria: React.Dispatch<React.SetStateAction<DirectOrderSearchCriteria>>;
  loading: boolean;
}

const historyTableModeDirectOrderStatesForFilter = [
  DirectOrderState.Cancelled,
  DirectOrderState.Declined,
  DirectOrderState.Failed,
  DirectOrderState.Accepted,
  DirectOrderState.Settled,
];

export const OrderScrollFilters = ({
  filterValues,
  setFilter,
  loading,
  setCriteria,
}: OrderScrollFiltersProps) => {
  const theme = useTheme();
  const { textSearch, orderStatuses, dateEnd, dateStart } = filterValues;

  const showButton = (): boolean => {
    return !!(orderStatuses.length || textSearch || dateEnd || dateStart);
  };

  useEffectRunAfterFirstRender(() => {
    setCriteria(
      GetDirectOrderSearchCriteria({
        ...filterValues,
        tableMode: TableMode.history,
      }),
    );
  }, [filterValues]);

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        margin="dense"
        id="directOrdersTable-textSearch-textField"
        label="Search"
        placeholder="Start Typing..."
        value={textSearch}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          setFilter({
            ...filterValues,
            textSearch: e.target.value,
          });
        }}
      />
      <Box sx={(theme) => ({ display: "flex", padding: theme.spacing(1, 0) })}>
        <DateField
          label="From"
          disabled={loading}
          id="directOrdersTable-orderDateTimeFrom-dateField"
          value={dateStart ? dateStart.date : null}
          onChange={(newValue) => {
            if (!(newValue && dateIsValid(newValue))) {
              setFilter({
                ...filterValues,
                dateStart: undefined,
              });
            } else {
              setFilter({
                ...filterValues,
                dateStart: newValue
                  ? {
                      date: newValue.startOf("day").format(),
                      inclusive: true,
                      ignore: false,
                    }
                  : undefined,
              });
            }
          }}
          renderInput={(textFieldProps: TextFieldProps) => (
            <TextField
              fullWidth
              {...textFieldProps}
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (() => (
                  <>
                    {dateStart && (
                      <Tooltip title="Clear" placement="top">
                        <IconButton
                          sx={{
                            color: theme.palette.action.disabled,
                            cursor: "pointer",
                            "&:hover": {
                              color: theme.palette.action.active,
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setFilter({
                              ...filterValues,
                              dateStart: undefined,
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {textFieldProps.InputProps &&
                    textFieldProps.InputProps.endAdornment
                      ? textFieldProps.InputProps.endAdornment
                      : null}
                  </>
                ))(),
              }}
            />
          )}
        />
        <Box sx={(theme) => ({ width: theme.spacing(3) })} />
        <DateField
          label="To"
          disabled={loading}
          id="directOrdersTable-orderDateTimeTo-dateField"
          value={dateEnd ? dateEnd.date : null}
          onChange={(newValue) => {
            if (!(newValue && dateIsValid(newValue))) {
              setFilter({
                ...filterValues,
                dateEnd: undefined,
              });
            } else {
              setFilter({
                ...filterValues,
                dateEnd: newValue
                  ? {
                      date: newValue.startOf("day").format(),
                      inclusive: true,
                      ignore: false,
                    }
                  : undefined,
              });
            }
          }}
          renderInput={(textFieldProps: TextFieldProps) => (
            <TextField
              fullWidth
              {...textFieldProps}
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (() => (
                  <>
                    {dateEnd && (
                      <Tooltip title="Clear" placement="top">
                        <IconButton
                          sx={{
                            color: theme.palette.action.disabled,
                            cursor: "pointer",
                            "&:hover": {
                              color: theme.palette.action.active,
                            },
                          }}
                          size="small"
                          onClick={() => {
                            setFilter({
                              ...filterValues,
                              dateEnd: undefined,
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {textFieldProps.InputProps &&
                    textFieldProps.InputProps.endAdornment
                      ? textFieldProps.InputProps.endAdornment
                      : null}
                  </>
                ))(),
              }}
            />
          )}
        />
      </Box>
      <Autocomplete
        isOptionEqualToValue={(option, value) => option === value}
        fullWidth
        id="directOrdersTable-state-autocomplete"
        disabled={loading}
        multiple
        options={historyTableModeDirectOrderStatesForFilter}
        filterSelectedOptions
        onChange={(_, value: DirectOrderState[]) => {
          setFilter({
            ...filterValues,
            orderStatuses: value,
          });
        }}
        value={orderStatuses}
        renderTags={(directOrderStates: DirectOrderState[]) =>
          directOrderStates.map((s, idx) => (
            <Box sx={{ padding: "2px" }} key={`chip-box-${idx}`}>
              <DirectOrderStateChip
                key={idx}
                state={s}
                chipProps={{
                  onDelete: () => {
                    setFilter({
                      ...filterValues,
                      orderStatuses: orderStatuses.filter((v) => v !== s),
                    });
                  },
                  deleteIcon: (
                    <CancelIcon
                      sx={(theme) => ({
                        color: `${theme.palette.text.secondary} !important`,
                        "&:hover": {
                          color: `${theme.palette.secondary.contrastText} !important`,
                        },
                      })}
                    />
                  ),
                }}
              />
            </Box>
          ))
        }
        ChipProps={{
          color: "info",
          size: "small",
          deleteIcon: (
            <CancelIcon
              sx={(theme) => ({
                color: `${theme.palette.text.secondary} !important`,
                "&:hover": {
                  color: `${theme.palette.secondary.contrastText} !important`,
                },
              })}
            />
          ),
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="State"
            variant="outlined"
            margin="dense"
            placeholder={orderStatuses.length ? undefined : "Select..."}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
      <Button
        disabled={!showButton()}
        sx={{
          height: "48px",
          backgroundColor: theme.palette.secondary.main,
          display: "flex",
          marginTop: theme.spacing(2),
        }}
        variant="contained"
        fullWidth
        onClick={() => {
          setFilter({
            textSearch: "",
            orderStatuses: [],
          });
        }}
      >
        <ClearFiltersIcon />
        Clear Filters
      </Button>
    </>
  );
};
