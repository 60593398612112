import {
  AssetStateListerServiceProviderName,
  ListingStateController,
  ListAssetRequest,
  ListAssetResponse,
  ActivateListingRequest,
  ActivateListingResponse,
  DeactivateListingRequest,
  DeactivateListingResponse,
  RetryListAssetResponse,
  RetryListAssetRequest,
} from "james/market";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { BuildMutationKey } from "utilities/reactQuery";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export type ListingStateController = {
  ListAsset: (
    request: ListAssetRequest,
    opts?: JSONRPCRequestOpts,
  ) => Promise<ListAssetResponse>;
  RetryListAsset: (
    request: RetryListAssetRequest,
    opts?: JSONRPCRequestOpts,
  ) => Promise<RetryListAssetResponse>;
  ActivateListing: (
    request: ActivateListingRequest,
    opts?: JSONRPCRequestOpts,
  ) => Promise<ActivateListingResponse>;
  DeactivateListing: (
    request: DeactivateListingRequest,
    opts?: JSONRPCRequestOpts,
  ) => Promise<DeactivateListingResponse>;
};

export function useRQListingStateController() {
  const reactQueryClient = useQueryClient();

  const listAssetMutation = useMutation({
    mutationFn: async (variables: {
      request: ListAssetRequest;
      opts?: JSONRPCRequestOpts;
    }): Promise<ListAssetResponse> => {
      return await ListingStateController.ListAsset(variables.request);
    },
    mutationKey: BuildMutationKey(
      AssetStateListerServiceProviderName,
      "ListAsset",
    ),
    onSuccess: async () => {
      // invalidate all the marketListingView.Read(...) queries
      await reactQueryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().includes("ListingViewModels"),
      });
    },
  });

  const retryListAssetMutation = useMutation({
    mutationFn: async (variables: {
      request: RetryListAssetRequest;
      opts?: JSONRPCRequestOpts;
    }): Promise<RetryListAssetResponse> => {
      return await ListingStateController.RetryListAsset(variables.request);
    },
    mutationKey: BuildMutationKey(
      AssetStateListerServiceProviderName,
      "RetryListAsset",
    ),
    onSuccess: async () => {
      // invalidate all the marketListingView.Read(...) queries
      await reactQueryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().includes("ListingViewModels"),
      });
    },
  });

  const activateListingMutation = useMutation({
    mutationFn: async (variables: {
      request: ActivateListingRequest;
      opts?: JSONRPCRequestOpts;
    }): Promise<ActivateListingResponse> => {
      return await ListingStateController.ActivateListing(variables.request);
    },
    mutationKey: BuildMutationKey(
      AssetStateListerServiceProviderName,
      "ActivateListing",
    ),
    onSuccess: async () => {
      // invalidate all the marketListingView.Read(...) queries
      await reactQueryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().includes("ListingViewModels"),
      });
    },
  });

  const deactivateListingMutation = useMutation({
    mutationFn: async (variables: {
      request: DeactivateListingRequest;
      opts?: JSONRPCRequestOpts;
    }): Promise<DeactivateListingResponse> => {
      return await ListingStateController.DeactivateListing(variables.request);
    },
    mutationKey: BuildMutationKey(
      AssetStateListerServiceProviderName,
      "Deactivate",
    ),
    onSuccess: async () => {
      // invalidate all the marketListingView.Read(...) queries
      await reactQueryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().includes("ListingViewModels"),
      });
    },
  });

  const ListAsset = useCallback(
    async (
      request: ListAssetRequest,
      opts?: JSONRPCRequestOpts,
    ): Promise<ListAssetResponse> => {
      return listAssetMutation.mutateAsync({
        request,
        opts,
      });
    },
    [listAssetMutation],
  );

  const RetryListAsset = useCallback(
    async (
      request: RetryListAssetRequest,
      opts?: JSONRPCRequestOpts,
    ): Promise<RetryListAssetResponse> => {
      return retryListAssetMutation.mutateAsync({
        request,
        opts,
      });
    },
    [retryListAssetMutation],
  );

  const ActivateListing = useCallback(
    async (
      request: ActivateListingRequest,
      opts?: JSONRPCRequestOpts,
    ): Promise<ActivateListingResponse> => {
      return activateListingMutation.mutateAsync({
        request,
        opts,
      });
    },
    [activateListingMutation],
  );

  const DeactivateListing = useCallback(
    async (
      request: DeactivateListingRequest,
      opts?: JSONRPCRequestOpts,
    ): Promise<DeactivateListingResponse> => {
      return deactivateListingMutation.mutateAsync({
        request,
        opts,
      });
    },
    [deactivateListingMutation],
  );

  return { ListAsset, RetryListAsset, ActivateListing, DeactivateListing };
}
