import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const WaterFinWaitingListBanner = () => {
  return (
    <Box
      id="waterfin_explore_bond_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "die mos",
        component_driver: "showcase",
      })}
      data-testid="waterfin_explore_bond_banner"
    >
      <Box
        sx={{
          width: 600,
          ml: {
            lg: "600px",
            md: "400px",
            sm: "300px",
          },
        }}
      >
        <Typography
          textAlign="start"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
        >
          Plus inflation returns.
        </Typography>
        <Typography
          textAlign="start"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
          pb="14px"
        >
          {" "}
          Real social impact.
        </Typography>
        <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
          <Typography
            textAlign="start"
            fontSize={14}
            letterSpacing={0}
            color={(theme) => theme.palette.secondary.main}
          >
            Our Preference Shares pay monthly dividends.
          </Typography>
          <Button
            id="waterFinBanner-exploreBond-button"
            href="https://app.mesh.trade/market/asset-overview?code=ZAWFINPSH001&issuer=GBE7F5YFS6MXNVPFZGZDLN5DOJVUI4UVIM66PJFVMKLO64IFKFXK634T&network=Stellar%20Public&utm_source=mesh-app&utm_medium=display&utm_campaign=mtg_get_ttl_wfin-ps-1_stc-54_jul-24_aug-24_za_pfmza_sign-up_mesh-app_cro_none_none_none&utm_marketing_tactic=cro&utm_creative_format=image&utm_id=mtg_get_ttl_wfin-ps-1_stc-54&utm_content=wfin-fomo-banner&utm_term=display_wfin-fomo-banner&utm_source_platform=mesh-app"
            variant="contained"
            color="primary"
            rel="noopener noreferrer"
            target="_blank"
            data-link-info={JSON.stringify({
              content_interaction_id: "hero-banner-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "view asset",
              content_interaction_type: InteractionType.Button,
              content_interaction_driver: InteractionDriver.Explore,
            } as DataLinkInfoType)}
          >
            View Asset
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
