import React from "react";
import { Router } from "routes";
import { Table } from "./Table";

export function Crypto() {
  return (
    <Router
      baseURL={"/market-management/assets/crypto"}
      redirectPath={"/market-management/assets/crypto/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/market-management/assets/crypto/table",
          component: <Table />,
        },
      ]}
    />
  );
}
