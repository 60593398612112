import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { LimitOrderState } from "@mesh/common-js/dist/market/limitOrder_pb";
import { DataRequest } from "@mesh/common-js-react/dist/Tables";
import { ReadManyModelRequest } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReader_meshproto_pb";
import { TabOption, useExchangeDashboardStore } from "../store";

export const FilterMap: Record<TabOption, number[]> = {
  [TabOption.MyOffers]: [
    LimitOrderState.SUBMISSION_IN_PROGRESS,
    LimitOrderState.CANCELLATION_IN_PROGRESS,
    LimitOrderState.OPEN,
    LimitOrderState.COMPLETE_IN_PROGRESS,
  ],
  [TabOption.History]: [
    LimitOrderState.SUBMISSION_FAILED,
    LimitOrderState.CANCELLED,
    LimitOrderState.COMPLETE,
  ],
};

export const useSearchLimitOrderView = () => {
  const store = useExchangeDashboardStore();
  const { views } = useAPIContext();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  const searchMarketLimitOrderView = async (request: DataRequest) => {
    try {
      store.updateOpenOrdersState.setSearchingOffers(true);
      const response =
        await views.marketLimitOrderViewModelReader.readManyModel(
          new ReadManyModelRequest()
            .setContext(authContext.toFuture())
            .setCriteriaList(request.criteria.toCriterionList())
            .setQuery(request.query),
        );

      store.updateOpenOrdersState.setSearchingOffers(false);
      return response;
    } catch (e) {
      store.updateOpenOrdersState.setSearchingOffers(false);
      store.setLoading(false);
      const err = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(err);
    }
  };

  return {
    searchMarketLimitOrderView,
  };
};
