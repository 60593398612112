import { IdentifierType } from ".";

export interface RegisteredNameIdentifierType {
  type: IdentifierType;
  registeredName: string;
}

export function RegisteredNameIdentifier(
  registeredName: string,
): RegisteredNameIdentifierType {
  return {
    type: IdentifierType.RegisteredNameIdentifierTypeName,
    registeredName,
  };
}
