"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allDeferrabilitys = void 0;
exports.deferrabilityToString = deferrabilityToString;
exports.stringToDeferrability = stringToDeferrability;
const deferrability_pb_1 = require("./deferrability_pb");
// Get all deferrabilitys as enum values
exports.allDeferrabilitys = Object.values(deferrability_pb_1.Deferrability).filter((value) => typeof value === "number");
// Define explicit mappings between Deferrability enums and custom string representations
const deferrabilityToStringMapping = {
    [deferrability_pb_1.Deferrability.UNDEFINED_DEFERRABILITY]: "-",
    [deferrability_pb_1.Deferrability.NON_DEFERRABLE_DEFERRABILITY]: "Non-Deferrable",
    [deferrability_pb_1.Deferrability.DEFERRABLE_CUMULATIVE_DEFERRABILITY]: "Deferrable Cumulative",
    [deferrability_pb_1.Deferrability.DEFERRABLE_NON_CUMULATIVE_DEFERRABILITY]: "Deferrable Non-Cumulative",
};
// Reverse mapping from string to Deferrability enum
const stringToDeferrabilityMapping = {};
for (const [key, value] of Object.entries(deferrabilityToStringMapping)) {
    stringToDeferrabilityMapping[value] = Number(key);
}
class UnsupportedDeferrabilityError extends Error {
    constructor(deferrability) {
        const message = `Unsupported Deferrability: ${deferrability}`;
        super(message);
        this.deferrability = deferrability;
    }
}
/**
 * Converts a Deferrability enum instance to a custom string representation.
 * @param {Deferrability} deferrability - The deferrability to convert.
 * @returns {string} The custom string representation of the deferrability.
 */
function deferrabilityToString(deferrability) {
    if (deferrability in deferrabilityToStringMapping) {
        return deferrabilityToStringMapping[deferrability];
    }
    else {
        throw new UnsupportedDeferrabilityError(deferrability);
    }
}
class UnsupportedDeferrabilityStringError extends Error {
    constructor(deferrabilityStr) {
        const message = `Unsupported deferrability string: ${deferrabilityStr}`;
        super(message);
        this.deferrabilityStr = deferrabilityStr;
    }
}
/**
 * Converts a custom string representation to a Deferrability enum instance.
 * @param {string} deferrabilityStr - The custom string representation of the deferrability.
 * @returns {Deferrability} The corresponding Deferrability enum instance.
 */
function stringToDeferrability(deferrabilityStr) {
    if (deferrabilityStr in stringToDeferrabilityMapping) {
        return stringToDeferrabilityMapping[deferrabilityStr];
    }
    else {
        throw new UnsupportedDeferrabilityStringError(deferrabilityStr);
    }
}
