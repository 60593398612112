import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { ViewConfiguration } from "james/configuration";
import { useLocation, useNavigate } from "react-router-dom";
import { Router } from "routes";
import cx from "classnames";
import { Transactions } from "./Transactions";
import { Dashboard } from "./Dashboard";
import { AfterEffects } from "./AfterEffects";
import { useApplicationContext } from "context/Application/Application";

const PREFIX = "Consistency";

const classes = {
  content: `${PREFIX}-content`,
  tabBar: `${PREFIX}-tabBar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.tabBar}`]: {
    paddingLeft: theme.spacing(4),
  },
}));

const consistencyTabRoutes: RouteType[] = [
  {
    name: "Consistency",
    id: "consistency-consistency",
    path: "/consistency/consistency",
    component: <Dashboard />,
    allowSubPaths: true,
  },
  {
    name: "Transactions",
    id: "consistency-transactions",
    path: "/consistency/transactions",
    component: <Transactions />,
    allowSubPaths: true,
  },
  {
    name: "After Effects",
    id: "consistency-afterEffects",
    path: "/consistency/afterEffects",
    component: <AfterEffects />,
    allowSubPaths: true,
  },
];

function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  const superAdminViewConfigurationRules = viewConfig.Consistency;
  if (!superAdminViewConfigurationRules) {
    console.error("no consistency view configuration");
    return [];
  }

  return consistencyTabRoutes.filter(
    (route) => superAdminViewConfigurationRules[route.name],
  );
}

export function Consistency() {
  const navigate = useNavigate();
  const location = useLocation();
  const { viewConfiguration } = useApplicationContext();
  const availableTabRoutes = determineAvailableTabRoutes(viewConfiguration);

  // determine the active path each time the component renders
  const currentRoute = availableTabRoutes.find((r) =>
    location.pathname.includes(r.path),
  );

  // only set the active path if the route is not wallet
  let activePath = availableTabRoutes.length ? availableTabRoutes[0].path : "";
  if (currentRoute) {
    activePath = currentRoute.path;
  }

  return (
    <Root>
      <Paper square className={classes.tabBar}>
        <Grid container>
          <Grid item>
            <Tabs
              value={activePath}
              onChange={(_, value) => {
                if (activePath === value) {
                  return;
                }
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {availableTabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <div className={cx(classes.content, "meshScroll")}>
        <Router
          baseURL={"/consistency"}
          redirectPath={"/consistency/consistency"}
          routes={availableTabRoutes}
        />
      </div>
    </Root>
  );
}
