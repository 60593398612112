import { create } from "zustand";
import { TradesListActions, TradesListProps } from "../components/TradesList";
import {
  TradeCardActions,
  TradeCardProps,
} from "../components/TradeCard/TradeCard";
import { BigNumber } from "bignumber.js";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import { LimitOrderType } from "@mesh/common-js/dist/market/limitOrder_pb";
import { Balance } from "james/stellar/Account";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { OrderBook } from "pkgTemp/stellar/Orders";
import { StellarTrade } from "pkgTemp/stellar/Trades";
import { OrderBookActions, OrderBookProps } from "../components/OrderBook";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";
import dayjs from "dayjs";
import { TradeCardValidations } from "./validations";
import { QuoteParameter } from "james/market";
import { Model as stellarAccountViewModel } from "james/views/stellarAccountView";
import { MyOffersActions, MyOffersProps } from "../components/MyOffers";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";

export enum TabOption {
  MyOffers = "MyOffers",
  History = "History",
}

export type validationFunc<T> = (form: T) => {
  error: string;
  valid: boolean;
};

export type FormValidationType<T> = T & {
  fieldErrors: Partial<Record<keyof T, string>>;
  fieldValidations: Partial<Record<keyof T, validationFunc<T>>>;
  valid: boolean;
  isValid: (
    form: T,
    fieldValidations: Partial<Record<keyof T, validationFunc<T>>>,
  ) => {
    fieldErrors: Partial<Record<keyof T, string>>;
    valid: boolean;
  };
};

export enum ExchangeTab {
  Trade = "Trade",
  OrderBook = "Book",
  Orders = "Orders",
  History = "History",
}

export const AllExchangeTabs = [
  ExchangeTab.Trade,
  ExchangeTab.OrderBook,
  ExchangeTab.Orders,
  ExchangeTab.History,
];

interface ExchangeDashboardStore {
  selectedTab: ExchangeTab;
  loading: boolean;
  tradesListState: TradesListProps;
  orderBookState: OrderBookProps;
  tradeCardState: FormValidationType<TradeCardProps>;
  openOrdersState: MyOffersProps;
}

interface ExchangeDashboardActions {
  setLoading: (loading: boolean) => void;
  setSelectedTab: (tab: ExchangeTab) => void;
  selectLimitOrderFromBook: (
    price: number,
    amount: number,
    cardOption: LimitOrderType,
  ) => void;
  updateTradesListState: TradesListActions;
  updateOrderBookState: OrderBookActions;
  updateTradeCardState: TradeCardActions;
  updateOpenOrdersState: MyOffersActions;
  clearStore: () => void;
}

const initialExchangeDashboardStoreState: ExchangeDashboardStore = {
  selectedTab: ExchangeTab.Trade,
  loading: true,
  tradesListState: {
    allTrades: {},
    tradeIds: new Set<string>(),
    loading: false,
    serverConnectionStatus: ServerConnectionStatus.Connecting,
    datesList: [],
    tradesList: [],
  },
  orderBookState: {
    spread: new BigNumber(0),
    orderBookState: new OrderBook(),
    highestPrice: new BigNumber(0),
    loading: false,
    serverConnectionStatus: ServerConnectionStatus.Connecting,
  },
  openOrdersState: {
    selectedTab: TabOption.MyOffers,
    marketLimitOrderViewModels: [],
    total: 0,
    loading: true,
    searchingOffers: false,
    cancelingOrder: false,
  },
  tradeCardState: TradeCardValidations,
};

export const useExchangeDashboardStore = create<
  ExchangeDashboardStore & ExchangeDashboardActions
>((set, get) => ({
  ...initialExchangeDashboardStoreState,
  setSelectedTab: (val: ExchangeTab) => set({ selectedTab: val }),
  setLoading: (val: boolean) => set({ loading: val }),
  selectLimitOrderFromBook: (
    price: number,
    amount: number,
    cardOption: LimitOrderType,
  ) => {
    const store = get();
    const state = get().tradeCardState;
    const p = new BigNumber(price);

    set({
      tradeCardState: {
        ...state,
        amountFocused: true,
        cardOption: cardOption,
      },
    });
    store.updateTradeCardState.setPrice(
      state.price.setValue(bigNumberToDecimal(p)),
    );
    setTimeout(() => store.updateTradeCardState.setAmountFocused(false), 200);
  },
  updateTradesListState: {
    allTrades: (val: Record<string, StellarTrade[]>) => {
      const tradeListState = get().tradesListState;
      set({
        tradesListState: {
          ...tradeListState,
          allTrades: val,
        },
      });
    },
    pushTrade: (val: StellarTrade[]) => {
      const tradeListState = get().tradesListState;
      const allTrades = tradeListState.allTrades;
      const datesList = tradeListState.datesList;
      for (const trade of val) {
        const date = dayjs(trade.ledgerCloseTime).format("YYYY/MM/DD");
        if (allTrades[date]) {
          allTrades[date].unshift(trade);
        } else {
          allTrades[date] = [trade];
          datesList.unshift(date);
        }
      }
      set({
        tradesListState: {
          ...tradeListState,
          allTrades: allTrades,
          datesList: datesList,
        },
      });
    },
    tradeIds: (val: Set<string>) => {
      const tradeListState = get().tradesListState;
      set({
        tradesListState: {
          ...tradeListState,
          tradeIds: val,
        },
      });
    },
    loading: (val: boolean) => {
      const tradeListState = get().tradesListState;
      set({
        tradesListState: {
          ...tradeListState,
          loading: val,
        },
      });
    },
    serverConnectionStatus: (val: ServerConnectionStatus) => {
      const tradeListState = get().tradesListState;
      set({
        tradesListState: {
          ...tradeListState,
          serverConnectionStatus: val,
        },
      });
    },
    datesList: (val: string[]) => {
      const tradeListState = get().tradesListState;
      set({
        tradesListState: {
          ...tradeListState,
          datesList: val,
        },
      });
    },
    tradesList: (tradesList: StellarTrade[]) => {
      const tradeListState = get().tradesListState;
      set({
        tradesListState: {
          ...tradeListState,
          tradesList: tradesList,
        },
      });
    },
  },
  updateOrderBookState: {
    orderBookState: (val: OrderBook) => {
      const orderBookState = get().orderBookState;
      set({
        orderBookState: {
          ...orderBookState,
          orderBookState: val,
        },
      });
    },
    highestPrice: (val: BigNumber) => {
      const orderBookState = get().orderBookState;
      set({
        orderBookState: {
          ...orderBookState,
          highestPrice: val,
        },
      });
    },
    loading: (value: boolean) => {
      const orderBookState = get().orderBookState;
      set({
        orderBookState: {
          ...orderBookState,
          loading: value,
        },
      });
    },
    serverConnectionStatus: (value: ServerConnectionStatus) => {
      const orderBookState = get().orderBookState;
      set({
        orderBookState: {
          ...orderBookState,
          serverConnectionStatus: value,
        },
      });
    },
    spread: (value: BigNumber) => {
      const orderBookState = get().orderBookState;
      set({
        orderBookState: {
          ...orderBookState,
          spread: value,
        },
      });
    },
    precisionFromString: (value: string) => {
      const orderBookState = get().orderBookState;
      const orderBook = new OrderBook({
        bids: orderBookState.orderBookState.bids,
        asks: orderBookState.orderBookState.asks,
        precision: new BigNumber(value),
      });

      set({
        orderBookState: {
          ...orderBookState,
          orderBookState: orderBook,
          highestPrice: orderBook.getHighestOrder(),
        },
      });
    },
  },
  updateTradeCardState: {
    setAmountFocused: (val: boolean) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          amountFocused: val,
        },
      });
    },
    setLoading: (val: boolean) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          loading: val,
        },
      });
    },
    setPrice: (val: FutureAmount) => {
      const state = get().tradeCardState;

      const total = decimalToBigNumber(
        state.price.getValue() ?? new Decimal(),
      ).multipliedBy(
        decimalToBigNumber(state.amount.getValue() ?? new Decimal()),
      );

      state.estimatedTotal.setValue(bigNumberToDecimal(total));
      const newState = {
        ...state,
        price: new FutureAmount()
          .setValue(val.getValue())
          .setToken(val.getToken()),
        estimatedTotal: new FutureAmount()
          .setValue(state.estimatedTotal.getValue())
          .setToken(state.estimatedTotal.getToken()),
      };

      const response = state.isValid?.(newState, newState.fieldValidations);

      set({
        tradeCardState: {
          ...newState,
          fieldErrors: response.fieldErrors,
          valid: response?.valid ?? true,
        },
      });
    },
    setAmount: (val: FutureAmount) => {
      const state = get().tradeCardState;

      const total = decimalToBigNumber(
        state.price.getValue() ?? new Decimal(),
      ).multipliedBy(
        decimalToBigNumber(state.amount.getValue() ?? new Decimal()),
      );

      state.estimatedTotal.setValue(bigNumberToDecimal(total));
      const newState = {
        ...state,
        amount: new FutureAmount()
          .setValue(val.getValue())
          .setToken(val.getToken()),
        estimatedTotal: new FutureAmount()
          .setValue(state.estimatedTotal.getValue())
          .setToken(state.estimatedTotal.getToken()),
      };

      const response = state.isValid?.(newState, newState.fieldValidations);

      set({
        tradeCardState: {
          ...newState,
          fieldErrors: response.fieldErrors,
          valid: response.valid,
        },
      });
    },
    setEstimatedTotal: (val: FutureAmount) => {
      const state = get().tradeCardState;
      const amount = decimalToBigNumber(
        state.estimatedTotal.getValue() ?? new Decimal(),
      ).dividedBy(decimalToBigNumber(state.price.getValue() ?? new Decimal()));

      const a = state.amount.setValue(bigNumberToDecimal(amount.dp(6)));
      const newState = {
        ...state,
        estimatedTotal: val,
        amount: new FutureAmount()
          .setValue(a.getValue())
          .setToken(a.getToken()),
      };

      const response = state.isValid?.(newState, newState.fieldValidations);

      set({
        tradeCardState: {
          ...newState,
          fieldErrors: response.fieldErrors,
          valid: response.valid,
        },
      });
    },
    setCardOption: (val: LimitOrderType) => {
      const state = get().tradeCardState;

      const newState = {
        ...state,
        cardOption: val,
      };

      const response = state.isValid?.(newState, newState.fieldValidations);
      set({
        tradeCardState: {
          ...newState,
          cardOption: val,
          valid: response.valid,
          fieldErrors: response.fieldErrors,
        },
      });
    },
    setBalance: (val?: Record<LimitOrderType, Balance>) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          balance: val,
        },
      });
    },
    clear: () => {
      set({
        tradeCardState: initialExchangeDashboardStoreState.tradeCardState,
      });
    },
    setTradeCardForm: (
      price: FutureAmount,
      amount: FutureAmount,
      estimatedTotal: FutureAmount,
    ) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          price: price,
          amount: amount,
          estimatedTotal: estimatedTotal,
        },
      });
    },
    setSourceAccount: (val: stellarAccountViewModel) => {
      const state = get().tradeCardState;

      const newState = {
        ...state,
        sourceAccount: val,
      };

      const response = state.isValid?.(newState, newState.fieldValidations);

      set({
        tradeCardState: {
          ...newState,
          fieldErrors: response.fieldErrors,
          valid: response.valid,
        },
      });
    },
    setPotentialSourceAccounts: (val: stellarAccountViewModel[]) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          potentialSourceAccounts: val,
        },
      });
    },
    setIsSignatory: (val: boolean) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          isSignatory: val,
        },
      });
    },
    setQuoteParemeter: (val?: QuoteParameter) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          quoteParemeter: val,
        },
      });
    },
    setFee: (val: { fee: FutureAmount; vat: FutureAmount }) => {
      const state = get().tradeCardState;
      set({
        tradeCardState: {
          ...state,
          fee: val,
        },
      });
    },
  },
  updateOpenOrdersState: {
    setSelectedTab: (val) => {
      const state = get().openOrdersState;
      set({
        openOrdersState: {
          ...state,
          selectedTab: val,
        },
      });
    },
    setMarketLimitOrderViewModels: (val: Model[]) => {
      const state = get().openOrdersState;
      set({
        openOrdersState: {
          ...state,
          marketLimitOrderViewModels: val,
        },
      });
    },
    setTotal: (val: number) => {
      const state = get().openOrdersState;
      set({
        openOrdersState: {
          ...state,
          total: val,
        },
      });
    },
    setLoading: (val: boolean) => {
      const state = get().openOrdersState;
      set({
        openOrdersState: {
          ...state,
          loading: val,
        },
      });
    },
    setSearchingOffers: (val: boolean) => {
      const state = get().openOrdersState;
      set({
        openOrdersState: {
          ...state,
          searchingOffers: val,
        },
      });
    },
    setCancelingOrder: (val: boolean) => {
      const state = get().openOrdersState;
      set({
        openOrdersState: {
          ...state,
          cancelingOrder: val,
        },
      });
    },
  },
  clearStore: () =>
    set({
      loading: initialExchangeDashboardStoreState.loading,
      tradesListState: initialExchangeDashboardStoreState.tradesListState,
      orderBookState: initialExchangeDashboardStoreState.orderBookState,
      tradeCardState: initialExchangeDashboardStoreState.tradeCardState,
    }),
}));
