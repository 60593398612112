import { Transaction, TransactionAction } from "./Transaction";

export class RunningTransaction {
  public action: TransactionAction | 0 = 0;
  public runCount = 0;
  public transaction: Transaction = new Transaction();

  constructor(rt?: RunningTransaction) {
    if (!rt) {
      return;
    }
    this.action = rt.action;
    this.runCount = rt.runCount;
    this.transaction = new Transaction(rt.transaction);
  }
}
