import { Environment } from "const";
import dayjs from "dayjs";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import React, { useContext, useEffect, useMemo, useState } from "react";
import config from "react-global-configuration";

interface ContextType {
  featureEnabledEnvironment: boolean;
  currentTime: Timestamp;
  setCurrentTime: React.Dispatch<React.SetStateAction<Timestamp>>;
  simulatedTimeEnabled: boolean;
  setSimulatedTimeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const simulatedCurrentTimeLocalStorageKey = "simulatedCurrentTime";
export const simulatedTimeEnabledLocalStorageKey = "simulatedTimeEnabled";

const Context = React.createContext({} as ContextType);

function SimulatedTimeContext({ children }: { children: React.ReactNode }) {
  const [currentTime, setCurrentTime] = useState<Timestamp>(
    Timestamp.fromDate(new Date(dayjs().toISOString())),
  );
  const [simulatedTimeEnabled, setSimulatedTimeEnabled] = useState<boolean>(
    config.get("simulatedTimeEnabled"),
  );
  const environment = config.get("environment") as Environment;

  useEffect(() => {
    const prevConfig = JSON.parse(config.serialize());
    config.set(
      {
        ...prevConfig,
        currentTime: currentTime.toDate().toISOString(),
      },
      { freeze: false, assign: false },
    );
  }, [currentTime]);

  useEffect(() => {
    const prevConfig = JSON.parse(config.serialize());
    config.set(
      {
        ...prevConfig,
        setSimulatedTimeEnabled: simulatedTimeEnabled,
      },
      { freeze: false, assign: false },
    );
  }, [simulatedTimeEnabled]);

  const featureEnabledEnvironment = useMemo(() => {
    return [
      Environment.Local,
      Environment.Development,
      Environment.Testing,
      Environment.Staging,
    ].includes(environment);
  }, [environment]);

  return (
    <Context.Provider
      value={{
        currentTime: currentTime,
        setCurrentTime: setCurrentTime,
        simulatedTimeEnabled: simulatedTimeEnabled,
        setSimulatedTimeEnabled: setSimulatedTimeEnabled,
        featureEnabledEnvironment: featureEnabledEnvironment,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useSimulatedTimeContext = () => useContext(Context);
export { useSimulatedTimeContext, SimulatedTimeContext };
