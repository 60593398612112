import { Chip, ChipProps } from "@mui/material";
import { ListingState } from "james/market/Listing";
import React from "react";

export interface ListingStateChipProps extends ChipProps {
  state: ListingState | "";
}

export function ListingStateChip({ state, ...props }: ListingStateChipProps) {
  switch (state) {
    case ListingState.Active:
      return (
        <Chip
          {...props}
          color="success"
          id={`listingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case ListingState.Inactive:
      return (
        <Chip
          {...props}
          color="warning"
          id={`listingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case ListingState.ListingInProgress:
      return (
        <Chip
          {...props}
          color="warning"
          id={`listingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case ListingState.ListingFailed:
    case ListingState.ListingUnderInvestigation:
      return (
        <Chip
          {...props}
          color="error"
          id={`listingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case "":
      return (
        <Chip
          {...props}
          color="info"
          id={`listingStateChip-${state}-chip`}
          size={"small"}
          label={"Listing Does Not Exist"}
        />
      );

    default:
      return <>{state}</>;
  }
}
