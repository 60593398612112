"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoDataSplash = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const icons_material_1 = require("@mui/icons-material");
const NoDataSplash = ({ title, body, icon = true, }) => {
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }, children: [icon && ((0, jsx_runtime_1.jsx)(icons_material_1.FaceOutlined, { sx: (theme) => ({
                    fontSize: 110,
                    color: (0, material_1.alpha)(theme.palette.background.default, 0.5),
                }) })), (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h4", sx: (theme) => ({
                    fontWeight: "bold",
                    color: theme.palette.text.tertiary,
                    margin: theme.spacing(2, 0),
                }), children: title ? title : "No Results Found" }), (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", sx: {
                    width: "226px",
                    color: (theme) => theme.palette.text.disabled,
                    textAlign: "center",
                }, children: body ? body : "Please try using different search criteria" })] }));
};
exports.NoDataSplash = NoDataSplash;
