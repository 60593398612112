import React, { useContext } from "react";
import {
  StellarAccountContext,
  useStellarAccountContext,
} from "./StellarAccount";

interface ContextType {
  stellarAccountContext: StellarAccountContext;
}

const Context = React.createContext({} as ContextType);

export function AccountContext({ children }: { children?: React.ReactNode }) {
  const stellarAccountContext = useStellarAccountContext();

  return (
    <Context.Provider value={{ stellarAccountContext }}>
      {children}
    </Context.Provider>
  );
}

const useAccountContext = () => useContext(Context);
export { useAccountContext };
