import dayjs from "dayjs";
import { AssetType } from "james/views/marketListingView/Model";
import { InstrumentRiskProfile } from "james/financial";
import { Token } from "james/ledger";
import { Mechanism, MechanismType, QuoteParameter } from "james/market";
import { Model as MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView/Model";
import { MediaImage } from "james/media/Image";

export const PublicModelTypeName = "mesh::marketListingView/PublicModel";

export class PublicModel {
  ["@type"]: string = PublicModelTypeName;
  public token: Token = new Token();
  public tokenIconURL = "";

  public listingMarketMechanisms: Mechanism[] = [];

  public assetOwnerClientShortName = "";
  public assetShortName = "";
  public assetType: AssetType | "" = "";
  public assetIssueDate: string = dayjs().format();
  public assetName = "";

  public instrumentRiskProfile: InstrumentRiskProfile | "" = "";
  public instrumentRiskProfileDescription = "";

  public returnDescription = "";
  public returnValue = "";

  public marketSubscriptionOrderBookViewModel: MarketSubscriptionOrderBookViewModel | null =
    null;

  public issuerLogo: MediaImage = new MediaImage();

  constructor(publicModel?: PublicModel) {
    if (!publicModel) {
      return;
    }
    this.token = new Token(publicModel.token);
    this.tokenIconURL = publicModel.tokenIconURL;
    this.listingMarketMechanisms = publicModel.listingMarketMechanisms.map(
      (m) => new Mechanism(m),
    );
    this.assetOwnerClientShortName = publicModel.assetOwnerClientShortName;
    this.assetShortName = publicModel.assetShortName;
    this.assetType = publicModel.assetType;
    this.assetIssueDate = publicModel.assetIssueDate;
    this.assetName = publicModel.assetName;
    this.instrumentRiskProfile = publicModel.instrumentRiskProfile;
    this.instrumentRiskProfileDescription =
      publicModel.instrumentRiskProfileDescription;
    this.returnDescription = publicModel.returnDescription;
    this.returnValue = publicModel.returnValue;

    if (publicModel.marketSubscriptionOrderBookViewModel) {
      this.marketSubscriptionOrderBookViewModel =
        new MarketSubscriptionOrderBookViewModel(
          publicModel.marketSubscriptionOrderBookViewModel,
        );
    }

    this.issuerLogo = new MediaImage(publicModel.issuerLogo);
  }

  getMarketMechanismQuoteParameter(
    mechanismType: MechanismType,
    quoteToken: Token,
  ): QuoteParameter {
    // find given mechanism type
    for (const marketMechanism of this.listingMarketMechanisms) {
      if (marketMechanism.type === mechanismType) {
        // find quote parameter for given quote token
        for (const quoteParameter of marketMechanism.quoteParameters) {
          if (quoteParameter.quoteToken.isEqualTo(quoteToken)) {
            return quoteParameter;
          }
        }

        // matching quote parameter not found
        throw new Error(`quote token '${quoteToken.string()}' not supported`);
      }
    }

    // matching market mechanism not found
    throw new Error(`market mechanism ${mechanismType} not supported`);
  }
}
