import { Box, Link, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import React from "react";
import { useApplicationContext } from "context/Application/Application";

interface TopBarProps {
  leave: (func: () => void) => void;
}

export const TopBar = ({ leave }: TopBarProps) => {
  const theme = useTheme();
  const { logout } = useApplicationContext();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: {
          sm: theme.spacing(3, 2, 1, 2),
          xs: theme.spacing(1, 2),
        },
        [theme.breakpoints.down("sm")]: {
          backgroundColor: theme.palette.background.default,
          position: "relative",
          top: 0,
        },
      }}
    >
      <Link
        id="TobBar-Back-Link"
        children="< Back"
        underline="none"
        onClick={() =>
          leave(() => {
            navigate(-1);
          })
        }
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link
          id="TobBar-Logout-Link"
          sx={{
            mx: theme.spacing(2),
            color: theme.palette.text.secondary,
            fontSize: "14px",
          }}
          onClick={() =>
            leave(async () => {
              await logout();
              navigate("/sign-in");
            })
          }
          underline="none"
        >
          Logout
        </Link>
        <Link
          id="TobBar-NeedHelp-Link"
          underline="none"
          target="_blank"
          href={"https://support.mesh.trade/support/home"}
        >
          Need Help?
        </Link>
      </Box>
    </Box>
  );
};
