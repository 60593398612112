import { Amount } from "james/ledger";
import { SubscriptionOrderState } from "james/market/SubscriptionOrder";
import dayjs from "dayjs";

export class Model {
  public id = "";
  public subscriptionOrderID = "";
  public ownerID = "";
  public subscriptionOrderBookOwnerID = "";
  public subscriptionOrderBookID = "";
  public subscriptionOrderBookNumber = "";
  public number = "";
  public assetName = "";
  public assetShortName = "";
  public timeSubmitted = dayjs().format();

  public investorClientName = "";
  public investorProfilePictureURL = "";
  public investorClientID = "";

  public issuerClientName = "";
  public issuerProfilePictureURL = "";
  public issuerClientID = "";

  public state: SubscriptionOrderState | "" = "";
  public amount: Amount = new Amount();
  public tokens: Amount = new Amount();
  public unitPrice: Amount = new Amount();
  public resolutionCount = 0;
  public failureReasons: string[] = [];

  constructor(l?: Model) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.subscriptionOrderID = l.subscriptionOrderID;
    this.ownerID = l.ownerID;
    this.subscriptionOrderBookOwnerID = l.subscriptionOrderBookOwnerID;
    this.subscriptionOrderBookID = l.subscriptionOrderBookID;
    this.subscriptionOrderBookNumber = l.subscriptionOrderBookNumber;
    this.number = l.number;
    this.assetName = l.assetName;
    this.assetShortName = l.assetShortName;
    this.timeSubmitted = l.timeSubmitted;

    this.investorClientName = l.investorClientName;
    this.investorProfilePictureURL = l.investorProfilePictureURL;
    this.investorClientID = l.investorClientID;

    this.issuerClientName = l.issuerClientName;
    this.issuerProfilePictureURL = l.issuerProfilePictureURL;
    this.issuerClientID = l.issuerClientID;

    this.state = l.state;
    this.amount = new Amount(l.amount);
    this.tokens = new Amount(l.tokens);
    this.unitPrice = new Amount(l.unitPrice);
    this.resolutionCount = l.resolutionCount;
    this.failureReasons = [...l.failureReasons];
  }
}
