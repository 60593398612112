import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Model } from "./Model";

export interface UpdateForCurrencyStablecoinRequest {
  context: Context;
  currencyStablecoinID: string;
}

// tslint:disable-next-line:no-empty-interface
export interface UpdateForCurrencyStablecoinResponse {
  model: Model;
}

export interface FullUpdateRequest {
  context: Context;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FullUpdateResponse {}

export const Updater = {
  serviceProvider: "financialCurrencyStablecoinView-Updater",
  async UpdateForCurrencyStablecoin(
    request: UpdateForCurrencyStablecoinRequest,
  ): Promise<UpdateForCurrencyStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.UpdateForCurrencyStablecoin`,
      request,
    });
    return { model: new Model(response.model) };
  },
  async FullUpdate(request: FullUpdateRequest): Promise<FullUpdateResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.FullUpdate`,
      request,
    });
  },
};
