export class FirebaseUserCancelledError extends Error {
  static ErrorCode = 100301;

  /** error type */
  public name = "Firebase User Cancelled Error";

  /** error message */
  public message = "";

  constructor() {
    super();
    this.message = "user login redirect cancelled";
  }
}
