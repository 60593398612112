export enum InvestorProfile {
  Conservative = "Conservative",
  Balanced = "Balanced",
  Aggressive = "Aggressive",
}

export const AllInvestorProfiles: InvestorProfile[] = [
  InvestorProfile.Conservative,
  InvestorProfile.Balanced,
  InvestorProfile.Aggressive,
];
