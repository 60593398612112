import { LiquidityPool, LiquidityPoolRegistration } from "james/stellar";

export const ModelTypeName = "mesh::stellarRegisteredLiquidityPoolView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  public liquidityPoolRegistration: LiquidityPoolRegistration =
    new LiquidityPoolRegistration();

  public liquidityPool: LiquidityPool = new LiquidityPool();

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.liquidityPoolRegistration = new LiquidityPoolRegistration(
      model.liquidityPoolRegistration,
    );
    this.liquidityPool = new LiquidityPool(model.liquidityPool);
  }
}
