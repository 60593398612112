import { ScheduleConfigurationWrapper } from "@mesh/common-js/dist/financial";
import { ScheduleConfigurationType } from "@mesh/common-js/dist/financial/scheduleConfigurationType_pb";
import { ScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfiguration_pb";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ValidationResult } from "common/validation";
import { validateNonPerpetualScheduleConfiguration } from "./components";

export type ScheduleConfigurationValidationData = {
  smartInstrument: SmartInstrument;
};

export function validateScheduleConfiguration(
  scheduleConfiguration: ScheduleConfiguration,
  data: ScheduleConfigurationValidationData,
  fieldValidationsPrefix?: string,
): ValidationResult {
  switch (
    new ScheduleConfigurationWrapper(scheduleConfiguration)
      .scheduleConfigurationType
  ) {
    case ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE: {
      const conf = scheduleConfiguration.getNonperpetualscheduleconfiguration();
      if (!conf) {
        throw new TypeError("leg is not set");
      }
      return validateNonPerpetualScheduleConfiguration(
        conf,
        data,
        fieldValidationsPrefix,
      );
    }

    case ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE:
      return {
        valid: false,
        fieldValidations: {},
      };

    case ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE:
    default:
      throw new TypeError("invalid or unknown smart instrument leg type");
  }
}
