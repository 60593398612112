import { Notification } from "../notification/Notification";

export const AccountChangedNotificationTypeName =
  "mesh::stellar/AccountChangedNotification";

export class AccountChangedNotification implements Notification {
  public ["@type"]: string = AccountChangedNotificationTypeName;

  public accountLedgerID = "";

  constructor(n?: AccountChangedNotification) {
    if (!n) {
      return;
    }
    this.accountLedgerID = n.accountLedgerID;
  }

  notificationType(): string {
    return AccountChangedNotificationTypeName;
  }
}
