import React from "react";
import { Box, Breakpoint, Button, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import { useNavigate } from "react-router-dom";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { OnboardingCard } from "../../../../../OnboardingCard/OnboardingCard";

interface ContactUsCardProps {
  width: Breakpoint;
}

export function ContactUsCard(props: ContactUsCardProps) {
  const navigate = useNavigate();
  return (
    <OnboardingCard linksFooter={<></>}>
      <VerticalSlide transitionDuration={1 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            width: "278px",
            padding: isWidthDown("sm", props.width)
              ? theme.spacing(4.5, 0, 3)
              : theme.spacing(5.5, 0, 3),
          })}
          align="center"
          fontSize="20px"
          fontWeight="600"
          children="Your information has been submitted"
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(3),
            color: theme.palette.text.tertiary,
            width: "315px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={
            "Our sales team will contact you shortly to complete the process."
          }
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={3 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(3),
            color: theme.palette.text.tertiary,
            width: "315px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={
            "If you want to start trading now, you can also sign up as an individual."
          }
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={4 / 5}>
        <Button
          id="signUp-companySendRequest-individualSignUpButton"
          sx={(theme) => ({
            height: theme.spacing(6),
            boxShadow: 5,
            width: "100%",
            marginBottom: theme.spacing(2),
          })}
          color="primary"
          variant="contained"
          onClick={() => {
            navigate("/sign-up/country-selector");
          }}
          size="large"
          children="Individual sign up"
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          id="signUp-companySendRequest-meshWebsiteButton"
          sx={(theme) => ({
            height: theme.spacing(6),
            boxShadow: 5,
            width: "100%",
          })}
          color="secondary"
          variant="contained"
          onClick={() => {
            window.open("https://www.mesh.trade/", "_blank");
          }}
          size="large"
          children="Mesh website"
        />
      </VerticalSlide>
      <Typography
        sx={(theme) => ({
          padding: theme.spacing(8, 0, 1, 0),
          color: theme.palette.text.tertiary,
        })}
        fontSize={"16px"}
        align={"center"}
        children={"Or follow us on"}
      />
      <Box
        sx={(theme) => ({
          width: "170px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          paddingBottom: isWidthDown("sm", props.width) ? "" : theme.spacing(8),
        })}
      >
        <IconButton
          id="signUp-companySendRequest-facebookButton"
          sx={{ margin: 0, padding: 0 }}
          onClick={() => {
            window.open("https://www.facebook.com/Mesh.trade/", "_blank");
          }}
          disableRipple
        >
          <FacebookIcon
            sx={(theme) => ({
              color: theme.palette.text.tertiary,
              width: theme.spacing(5),
              height: theme.spacing(5),
            })}
          />
        </IconButton>
        <IconButton
          id="signUp-companySendRequest-twitterButton"
          sx={{ margin: 0, padding: 0 }}
          onClick={() => {
            window.open("https://twitter.com/mesh_trade", "_blank");
          }}
          disableRipple
        >
          <TwitterIcon
            sx={(theme) => ({
              color: theme.palette.text.tertiary,
              width: theme.spacing(4),
              height: theme.spacing(3.25),
            })}
          />
        </IconButton>
        <IconButton
          id="signUp-companySendRequest-linkedInButton"
          sx={{ margin: 0, padding: 0 }}
          onClick={() => {
            window.open(
              "https://www.linkedin.com/company/mesh-trade/",
              "_blank",
            );
          }}
          disableRipple
        >
          <LinkedInIcon
            sx={(theme) => ({
              color: theme.palette.text.tertiary,
              width: theme.spacing(5),
              height: theme.spacing(5),
            })}
          />
        </IconButton>
      </Box>
    </OnboardingCard>
  );
}
