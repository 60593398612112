export interface StockExchange {
  code: string;
  name: string;
}

export const AllStockExchanges: StockExchange[] = [
  {
    code: "XBUE",
    name: "Bolsa de Comercio de Buenos Aires",
  },
  {
    code: "XNEC",
    name: "National Stock Exchange of Australia",
  },
  {
    code: "XASX",
    name: "Asx - All Markets",
  },
  {
    code: "XWBO",
    name: "Wiener Boerse Ag",
  },
  {
    code: "XBAH",
    name: "Bahrain Bourse",
  },
  {
    code: "XDHA",
    name: "Dhaka Stock Exchange Ltd",
  },
  {
    code: "XBRU",
    name: "Nyse Euronext - Euronext Brussels",
  },
  {
    code: "BVMF",
    name: "XBSP",
  },
  {
    code: "XCNQ",
    name: "Canadian Securities Exchange",
  },
  {
    code: "XTSE",
    name: "Toronto Stock Exchange",
  },
  {
    code: "XTSX",
    name: "TSX Venture Exchange",
  },
  {
    code: "NEOE",
    name: "Aequitas NEO Exchange (Lit Book)",
  },
  {
    code: "XSGO",
    name: "Santiago Stock Exchange",
  },
  {
    code: "XSHG",
    name: "Shanghai Stock Exchange",
  },
  {
    code: "XSHE",
    name: "Shenzhen Stock Exchange",
  },
  {
    code: "XBOG",
    name: "Bolsa De Valores De Colombia",
  },
  {
    code: "XZAG",
    name: "Zagreb Stock Exchange",
  },
  {
    code: "XCYS",
    name: "Cyprus Stock Exchange",
  },
  {
    code: "XPRA",
    name: "Prague Stock Exchange",
  },
  {
    code: "XCSE",
    name: "Omx Nordic Exchange Copenhagen A/S",
  },
  {
    code: "XCAI",
    name: "Egyptian Exchange",
  },
  {
    code: "XHEL",
    name: "Nasdaq Omx Helsinki Ltd.",
  },
  {
    code: "XPAR",
    name: "Euronext Paris",
  },
  {
    code: "XEQT",
    name: "Equiduct Trading",
  },
  {
    code: "XBER",
    name: "Boerse Berlin",
  },
  {
    code: "XDUS",
    name: "Dusseldorf Stock Exchange",
  },
  {
    code: "XFRA",
    name: "Frankfurt Stock Exchange",
  },
  {
    code: "XMUN",
    name: "Munich Stock Exchange",
  },
  {
    code: "XSTU",
    name: "Stuttgart Stock Exchange",
  },
  {
    code: "XETR",
    name: "Deutsche Boerse Xetra",
  },
  {
    code: "XQTX",
    name: "QUOTRIX",
  },
  {
    code: "XATH",
    name: "Athens Exchange S.A. Cash Market",
  },
  {
    code: "XHKG",
    name: "Hong Kong Exchange",
  },
  {
    code: "XBUD",
    name: "Budapest Stock Exchange",
  },
  {
    code: "XICE",
    name: "First North Iceland",
  },
  {
    code: "XBOM",
    name: "BSE LTD",
  },
  {
    code: "XNSE",
    name: "National Stock Exchange Of India",
  },
  {
    code: "XIDX",
    name: "Indonesia Stock Exchange",
  },
  {
    code: "XDUB",
    name: "Irish Stock Exchange - All Market",
  },
  {
    code: "XTAE",
    name: "Tel Aviv Stock Exchange",
  },
  {
    code: "MTAA",
    name: "Borsa Italiana S.P.A.",
  },
  {
    code: "XTKS",
    name: "Tokyo Stock Exchange",
  },
  {
    code: "XAMM",
    name: "Amman Stock Exchange",
  },
  {
    code: "XNAI",
    name: "Nairobi Stock Exchange",
  },
  {
    code: "XKUW",
    name: "Kuwait Stock Exchange",
  },
  {
    code: "XLUX",
    name: "Luxembourg Stock Exchange",
  },
  {
    code: "XKLS",
    name: "Bursa Malaysia",
  },
  {
    code: "XMEX",
    name: "Bolsa Mexicana De Valores (Mexican Stock Exchange)",
  },
  {
    code: "XCAS",
    name: "Casablanca Stock Exchange",
  },
  {
    code: "XNZE",
    name: "New Zealand Exchange Ltd",
  },
  {
    code: "XNSA",
    name: "Nigerian Stock Exchange",
  },
  {
    code: "XOSL",
    name: "Oslo Bors Asa",
  },
  {
    code: "NOTC",
    name: "Norwegian OTC Market",
  },
  {
    code: "XMUS",
    name: "Muscat Securities Market",
  },
  {
    code: "XKAR",
    name: "Karachi Stock Exchange (Guarantee) Limited",
  },
  {
    code: "XLIM",
    name: "Bolsa De Valores De Lima",
  },
  {
    code: "XPHS",
    name: "Philippine Stock Exchange Inc.",
  },
  {
    code: "XWAR",
    name: "Warsaw Stock Exchange/Equities/Main Market",
  },
  {
    code: "XLIS",
    name: "Nyse Euronext - Euronext Lisbon",
  },
  {
    code: "DSMD",
    name: "Qatar Exchange",
  },
  {
    code: "XBSE",
    name: "Spot Regulated Market - Bvb",
  },
  {
    code: "MISX",
    name: "Standard-Classica-Forts",
  },
  {
    code: "XSAU",
    name: "Saudi Stock Exchange",
  },
  {
    code: "XBRV",
    name: "Regional Securities Exchange SA",
  },
  {
    code: "XSES",
    name: "Singapore Exchange",
  },
  {
    code: "XLJU",
    name: "Ljubljana Stock Exchange (Official Market)",
  },
  {
    code: "XJSE",
    name: "Johannesburg Stock Exchange",
  },
  {
    code: "XKRX",
    name: "Korea Exchange (Stock Market)",
  },
  {
    code: "XKOS",
    name: "Korea Exchange (Kosdaq)",
  },
  {
    code: "XMAD",
    name: "Bolsa De Madrid",
  },
  {
    code: "XCOL",
    name: "Colombo Stock Exchange",
  },
  {
    code: "XNGM",
    name: "Nordic Growth Market",
  },
  {
    code: "XSTO",
    name: "Nasdaq Omx Nordic",
  },
  {
    code: "XSWX",
    name: "Six Swiss Exchange",
  },
  {
    code: "XVTX",
    name: "Six Swiss - Blue Chips Segment",
  },
  {
    code: "XDSE",
    name: "Damascus Securities Exchange",
  },
  {
    code: "ROCO",
    name: "Tapei Exchange",
  },
  {
    code: "XTAI",
    name: "Taiwan Stock Exchange",
  },
  {
    code: "XBKK",
    name: "Stock Exchange Of Thailand",
  },
  {
    code: "TOMX",
    name: "TOM MTF",
  },
  {
    code: "XAMS",
    name: "Nyse Euronext - Euronext Amsterdam",
  },
  {
    code: "XIST",
    name: "Istanbul Stock Exchange",
  },
  {
    code: "XDFM",
    name: "Dubai Financial Market",
  },
  {
    code: "DIFX",
    name: "Nasdaq Dubai",
  },
  {
    code: "XADS",
    name: "Abu Dhabi Securities Exchange",
  },
  {
    code: "BATE",
    name: "BATS Trading Europe",
  },
  {
    code: "CHIX",
    name: "Chi-X Eruope",
  },
  {
    code: "XLON",
    name: "London Stock Exchange",
  },
  {
    code: "XPOS",
    name: "ITG Posit",
  },
  {
    code: "TRQX",
    name: "Turquoise",
  },
  {
    code: "BOAT",
    name: "Cinnober BOAT",
  },
  {
    code: "XASE",
    name: "NYSE American",
  },
  {
    code: "BATS",
    name: "CBOE BATS BZX",
  },
  {
    code: "XNYS",
    name: "New York Stock Exchange",
  },
  {
    code: "ARCX",
    name: "NYSE Arca",
  },
  {
    code: "XNMS",
    name: "NASDAQ Global Market",
  },
  {
    code: "XNCM",
    name: "NASDAQ Capital Market",
  },
  {
    code: "OOTC",
    name: "OTC markets",
  },
  {
    code: "XNGS",
    name: "NASDAQ Global Select",
  },
  {
    code: "HSTC",
    name: "Hanoi Stock Exchange",
  },
  {
    code: "XSTC",
    name: "Hochiminh Stock Exchange",
  },
];
