import { AuditEntry } from "james/audit";
import { StellarTransaction, StellarTransactionTypeName } from "james/stellar";
import { LedgerNetwork } from "./Network";

export enum TransactionState {
  Draft = "Draft",
  SigningInProgress = "Signing in Progress",
  Pending = "Pending",
  SubmissionInProgress = "Submission in Progress",
  Failed = "Failed",
  Indeterminate = "Indeterminate",
  Successful = "Successful",
}

export const AllTransactionStates: TransactionState[] = [
  TransactionState.Draft,
  TransactionState.SigningInProgress,
  TransactionState.Pending,
  TransactionState.SubmissionInProgress,
  TransactionState.Failed,
  TransactionState.Indeterminate,
  TransactionState.Successful,
];

export interface Transaction {
  ["@type"]: string;

  transactionID(): string;

  transactionState(): TransactionState | "";

  transactionNetwork(): LedgerNetwork | "";

  // TODO: consider implementing with promise return
  // transactionFailureReasons(): ([]TransactionFailureReason, error)

  transactionStateResolutionCount(): number;

  transactionDescription(): string;

  transactionMetaData(): { [key: string]: string };

  transactionAuditEntry(): AuditEntry;
}

export function NewTransaction(transaction: Transaction): Transaction {
  switch (transaction["@type"]) {
    case StellarTransactionTypeName:
      return new StellarTransaction(transaction as StellarTransaction);
    default:
      throw new TypeError(`unknown transaction type ${transaction["@type"]}`);
  }
}
