export function randomColor(): string {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function isHex(color: string): boolean {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color);
}

function getRGBObject(color: string): { r: number; g: number; b: number } {
  const result =
    /rgba?\((25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,\s*?(25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,\s*?(25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,?\s*([01]\.?\d*?)?\)/i.exec(
      color,
    );
  if (!result) {
    throw new Error(`unable to parse ${color} as rgb`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error(`unable to convert ${hex} to rgb`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

const threshold = 110;

function colorsAreClose(color1: string, color2: string): boolean {
  const color1RGB = isHex(color1) ? hexToRgb(color1) : getRGBObject(color1);
  const color2RGB = isHex(color2) ? hexToRgb(color2) : getRGBObject(color2);

  if (!color1RGB) {
    console.error("could not convert color 1 to rgb");
    return false;
  }
  if (!color2RGB) {
    console.error("could not convert color 2 to rgb");
    return false;
  }

  const rDist = Math.abs(color1RGB.r - color2RGB.r);
  const gDist = Math.abs(color1RGB.g - color2RGB.g);
  const bDist = Math.abs(color1RGB.b - color2RGB.b);

  return rDist + gDist + bDist < threshold;
}

const globalDisallowedColors: string[] = [
  "#ffffff",
  "#000000",
  "#424242",
  "#303030",
  "#7aa2c9",
  "#354cbd",
  "#2418b6",
  "#25544c",
];

export function getRandomColor(disallowedColors: string[]) {
  disallowedColors = [...disallowedColors, ...globalDisallowedColors];

  // generate a new random color
  let newColor = randomColor();

  let whileCount = 0;

  // while this newColor is
  while (
    // in the disallowed colors OR
    disallowedColors.includes(newColor) ||
    // it is similar to one of the values in the disallowed colors
    disallowedColors.reduce(
      // eslint-disable-next-line
      (previousValue: boolean, currentValue: string) =>
        previousValue ? previousValue : colorsAreClose(newColor, currentValue),
      false,
    )
  ) {
    whileCount++;
    if (whileCount > 500) {
      console.warn("could not find different enough color");
      return newColor;
    }

    // generate another one
    newColor = randomColor();
  }

  // return in
  return newColor;
}
