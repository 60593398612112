import { Entry } from "james/audit/Entry";
import { GroupWithRoles } from "./GroupWithRoles";

export enum GroupClassification {
  ClientClassification = "Client",
  DivisionClassification = "Division",
  DepartmentClassification = "Department",
  TeamClassification = "Team",
  FundClassification = "Fund",
  PortfolioClassification = "Portfolio",
}

export const AllGroupClassifications: GroupClassification[] = [
  GroupClassification.ClientClassification,
  GroupClassification.DivisionClassification,
  GroupClassification.DepartmentClassification,
  GroupClassification.TeamClassification,
  GroupClassification.FundClassification,
  GroupClassification.PortfolioClassification,
];

export const GroupTypeName = "mesh::group/Group";

export class Group {
  public ["@type"]: string = GroupTypeName;

  public id = "";

  public ownerID = "";

  public name = "";

  public classification: GroupClassification | "" = "";

  public description = "";

  public auditEntry: Entry = new Entry();

  constructor(group?: Group | GroupWithRoles) {
    if (!group) {
      return;
    }
    this.id = group.id;
    this.ownerID = group.ownerID;
    this.name = group.name;
    this.classification = group.classification;
    this.description = group.description;
    this.auditEntry = new Entry(group.auditEntry);
  }
}
