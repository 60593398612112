/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {
  FilterList as FilterIcon,
  KeyboardArrowDown as ExpandRowIcon,
  KeyboardArrowUp as CollapseRowIcon,
  UnfoldLess as CollapseAllRowsIcon,
} from "@mui/icons-material";
import cx from "classnames";
import { NewSorting, Query } from "james/search/query";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  StyledDiv,
  StyledPaper,
  classes,
  tableFilterPanelHeight,
  tableTitleRowHeight,
} from "components/Table/style";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export interface BPTableProps {
  // Required properties
  columns: Column[];
  query: Query | undefined;
  onQueryChange: (newQuery: Query) => void;
  title: string | React.ReactNode;
  data: { [key: string]: any }[];
  totalNoRecords: number;

  // optional properties
  loading?: boolean;
  height?: number;
  filters?: React.ReactNode[];
  toolBarControls?: React.ReactNode[];
  onRowClick?: (rowClickProps: {
    clickedRowData: { [key: string]: any };
    clickedRowIdx: number;
  }) => void;
  onSelectedDataChange?: (allSelectedData: { [key: string]: any }[]) => void;
  onFilterPanelClose?: () => void;
  disableSelect?: boolean;
  singleSelect?: boolean;
  onSingleSelectChange?: (
    selectedRowData: { [key: string]: any } | undefined,
  ) => void;
  divTable?: boolean;
  selectedRowCheck?: (selectedRowData: { [key: string]: any }) => boolean;
  expandRowComponent?: ExpandRowComponentProps;
  hideCondenseTableSwitch?: boolean;
  initialDenseTable?: boolean;
  filterRowStyling?: SxProps;
  noDataSplashComponent?: ReactNode;
  collapsible?: boolean;
  initialCollapsedState?: boolean;
  interactionDriver?: InteractionDriver;
  style?: SxProps<Theme>;
}

export interface ExpandRowComponentProps {
  component: (selectedRowData: { [key: string]: any }) => React.ReactNode;
  maxHeight?: number;
}

export interface Column {
  field: string;
  label: React.ReactNode;
  minWidth?: number;
  maxWidth?: number;
  align?: "right";
  accessor?: (
    data: { [key: string]: any },
    rowIsSelected: boolean,
  ) => string | number | React.ReactNode;
  sortable?: boolean;
}

function BPTable(props: BPTableProps) {
  const theme = useTheme();

  const [collapsed, setCollapsed] = useState(!!props.initialCollapsedState);

  const [paginationComponentHeight, setPaginationComponentHeight] =
    useState(56);
  const [tableHeadHeight, setTableHeadHeight] = useState(53);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const tableHeight = props.height ? props.height : 600;
  const [denseTable, setDenseTable] = useState(!!props.initialDenseTable);
  const tableWrapperHeight = filterPanelOpen
    ? tableHeight -
      tableTitleRowHeight -
      tableFilterPanelHeight -
      paginationComponentHeight
    : tableHeight - tableTitleRowHeight - paginationComponentHeight;
  const loadingWrapperHeight = tableWrapperHeight - tableHeadHeight - 10;

  const prevData = usePrevious(props.data);
  const [query, setQuery] = useState(
    new Query({
      limit: props.query ? props.query.limit : 10,
      offset: props.query ? props.query.offset : 0,
      sorting: props.query ? props.query.sorting : [],
    }),
  );

  const [singleSelectRowIndex, setSingleSelectRowIndex] = useState(-1);
  const [selectedRowIndices, setSelectedRowIndices] = useState<number[]>([]);
  const { singleSelect, onSelectedDataChange, onSingleSelectChange } = props;
  const clearSelectedRowState = useCallback(() => {
    // clear all selected row state
    setSelectedRowIndices([]);
    setSingleSelectRowIndex(-1);

    // and call change callbacks if provided
    if (onSelectedDataChange) {
      onSelectedDataChange([]);
    }
    if (singleSelect && onSingleSelectChange) {
      onSingleSelectChange(undefined);
    }
  }, [onSingleSelectChange, singleSelect, onSelectedDataChange]);

  const [rowExpandedStates, setRowExpandedStates] = useState<{
    [key: number]: boolean;
  }>({});
  const clearExpandedRowState = useCallback(() => {
    setRowExpandedStates({});
  }, []);

  useEffect(() => {
    // this checks for a change in data to remove row selection
    if (!isEqual(props.data, prevData)) {
      clearSelectedRowState();
      clearExpandedRowState();
      return;
    }

    // if the data itself has not changed (i.e. contents of objects)
    // this checks to see if new objects have been constructed
    if (props.data.length > 0 && prevData !== undefined) {
      const prevDataTyped: { [key: string]: any }[] = prevData as unknown as {
        [key: string]: any;
      }[];
      if (props.data[0] !== prevDataTyped[0]) {
        clearSelectedRowState();
        clearExpandedRowState();
      }
    }
  }, [
    props,
    props.data,
    prevData,
    clearExpandedRowState,
    clearSelectedRowState,
  ]);

  // check for change in filters prop so that if filters are removed the panel
  // is closed
  useEffect(() => {
    if (!(props.filters && props.filters.length) && filterPanelOpen) {
      setFilterPanelOpen(false);
    }
  }, [props.filters, filterPanelOpen]);

  // if external query changes
  useEffect(() => {
    if (
      // external query is set
      props.query && // AND
      // external query is different from internal query
      !isEqual(props.query, query)
    ) {
      // then update internal query
      setQuery(new Query(props.query));

      // and clear table selected and expanded row state
      clearSelectedRowState();
      clearExpandedRowState();
    }
  }, [clearExpandedRowState, props.query]);

  const handleRowSelect =
    (clickedRowIdx: number, clickedRowData: { [key: string]: any }) => () => {
      // if select is disabled
      if (props.disableSelect) {
        // do nothing
        return;
      }

      // otherwise run row click callback
      if (props.onRowClick) {
        props.onRowClick({
          clickedRowData,
          clickedRowIdx,
        });
      }

      // if single select mode is active
      if (props.singleSelect) {
        // and row is already selected
        if (singleSelectRowIndex === clickedRowIdx) {
          // then --> deselect
          setSingleSelectRowIndex(-1);
          setSelectedRowIndices([]);
          if (props.onSingleSelectChange) {
            props.onSingleSelectChange(undefined);
          }
        } else {
          // otherwise different or no row selected --> select
          setSingleSelectRowIndex(clickedRowIdx);
          setSelectedRowIndices([clickedRowIdx]);

          // and call onSingleSelectChange callback if provided
          if (props.onSingleSelectChange) {
            props.onSingleSelectChange(clickedRowData);
          }
        }
      } else {
        // Otherwise single select mode is not active.
        // The rest of this deals with multiselect functionality.

        // prepare updated selected row indices
        let updatedSelectedRowIndices: number[] = [];

        // if this row is already selected
        if (selectedRowIndices.includes(clickedRowIdx)) {
          // then remove the selected row idx
          updatedSelectedRowIndices = selectedRowIndices.filter(
            (selectedRowIdx) => selectedRowIdx !== clickedRowIdx,
          );
        } else {
          // otherwise add this row idx as a selected one
          updatedSelectedRowIndices = [...selectedRowIndices, clickedRowIdx];
        }

        // set selected row indices
        setSelectedRowIndices(updatedSelectedRowIndices);

        // and call onSelectedDataChange callback if provided
        if (props.onSelectedDataChange) {
          props.onSelectedDataChange(
            props.data.filter((data, idx) =>
              updatedSelectedRowIndices.includes(idx),
            ),
          );
        }
      }
    };

  const handleSelectAll = () => {
    if (props.disableSelect) {
      return;
    }

    // if all rows are already selected
    if (selectedRowIndices.length === props.data.length) {
      // then clear rows selection
      setSelectedRowIndices([]);

      // and call onSelectedDataChange callback if provided
      if (props.onSelectedDataChange) {
        props.onSelectedDataChange([]);
      }
    } else {
      // otherwise not all rows are selected
      // set all rows to selected
      setSelectedRowIndices(props.data.map((data, idx) => idx));

      // and call onSelectedDataChange callback if provided
      if (props.onSelectedDataChange) {
        props.onSelectedDataChange(props.data);
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (props.loading) {
      // do nothing while loading
      return;
    }

    // otherwise calculate new offset
    const newOffset = newPage * query.limit;
    if (query.offset === newOffset) {
      // do nothing if this does not change the query
      return;
    }

    // otherwise update the query
    query.offset = newPage * query.limit;
    setQuery(new Query(query));

    // call given query change callback
    props.onQueryChange(query);

    // and clear table state as required
    clearSelectedRowState();
    clearExpandedRowState();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (props.loading) {
      // do nothing while loading
      return;
    }

    // otherwise calculate new limit
    const newLimit = +event.target.value;
    if (query.limit === newLimit) {
      // do nothing if this does not change the query
      return;
    }

    // update the query
    query.offset = 0;
    query.limit = newLimit;
    const newQuery = new Query(query);
    setQuery(newQuery);

    // call given query change callback
    props.onQueryChange(newQuery);

    // and clear table state as required
    setRowExpandedStates({});
    setSelectedRowIndices([]);
  };

  const handleUpdateSorting = (col: Column) => () => {
    if (props.loading) {
      // do nothing while loading
      return;
    }

    // otherwise prepare and calculate an updated query
    const updatedQuery = new Query(query);

    // check to see if there is already sorting for this column field
    const sortingForFieldIdx = updatedQuery.sorting.findIndex(
      (sorting) => sorting.field === col.field,
    );

    // if there is already sorting for this field
    if (sortingForFieldIdx > -1) {
      // Do the following:
      // - toggle filter order if in 'asc' to 'desc'
      // - removes the filter if in 'desc'

      // First remove the existing filter
      updatedQuery.sorting = updatedQuery.sorting.filter(
        (s, idx) => idx !== sortingForFieldIdx,
      );

      // Then if the existing sorting was 'asc'
      if (query.sorting[sortingForFieldIdx].sortOrder === "asc") {
        // add in new filter in 'desc' (i.e. toggle)
        updatedQuery.sorting = [
          NewSorting(col.field, "desc"),
          ...updatedQuery.sorting,
        ];
      }
    } else {
      // otherwise no sorting exists for this field yet

      // add new ascending order filter
      updatedQuery.sorting = [
        NewSorting(col.field, "asc"),
        ...updatedQuery.sorting,
      ];
    }

    // set updated query and call given callback
    setQuery(updatedQuery);
    props.onQueryChange(updatedQuery);
  };

  const handleToggleFilterPanel = () => {
    // if the filter panel is open
    if (filterPanelOpen) {
      // close it
      setFilterPanelOpen(false);

      // clear selected and expanded states as required
      clearExpandedRowState();
      clearSelectedRowState();
    } else {
      // otherwise just open it
      setFilterPanelOpen(true);
    }
  };

  const renderCellData = (
    data: { [key: string]: any },
    rowIsSelected: boolean,
    field?: string,
    accessor?: (
      data: { [key: string]: any },
      rowIsSelected: boolean,
    ) => string | number | React.ReactNode,
  ): string | number | React.ReactNode => {
    try {
      // if an accessor function was provided, call it with the data
      let accessedData: string | number | React.ReactNode = "";
      if (accessor) {
        accessedData = accessor(data, rowIsSelected);
      } else if (field) {
        // otherwise use provided field to get data
        accessedData = data[field];
      } else {
        console.error("neither field nor accessor provided to column");
        return "-";
      }
      if (isString(accessedData) || isNumber(accessedData)) {
        return (
          <Typography variant="body1" children={accessedData} color="inherit" />
        );
      }
      return accessedData;
    } catch (e: any) {
      console.error("error rendering cell data", e);
      return "-";
    }
  };

  const tableElements = (
    <>
      <div
        id="tableToolbar"
        className={cx(classes.tableTitleLayout, {
          [classes.tableToolbarRowSelectedHighlight]:
            !props.singleSelect && selectedRowIndices.length > 0,
        })}
      >
        {!!props.collapsible && (
          <IconButton
            size="small"
            id="bpTable-toggleFilterPanelOpened-button"
            onClick={() => setCollapsed(!collapsed)}
            className={cx(classes.filterIcon, {
              [classes.filterIconPanelOpen]: collapsed,
            })}
          >
            {collapsed ? (
              <ExpandRowIcon
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-search",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Icon,
                  content_interaction_text: "expand filter",
                  content_interaction_driver:
                    props.interactionDriver ?? InteractionDriver.Search,
                } as DataLinkInfoType)}
              />
            ) : (
              <CollapseRowIcon
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-search",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Icon,
                  content_interaction_text: "expand filter",
                  content_interaction_driver:
                    props.interactionDriver ?? InteractionDriver.Search,
                } as DataLinkInfoType)}
              />
            )}
          </IconButton>
        )}
        {!props.singleSelect && selectedRowIndices.length > 0 ? (
          // only show number selected in multi-select mode when there are 1 or more selected
          <Typography
            variant="subtitle1"
            color="inherit"
            id="tableToolbar-selectedItemsText"
          >
            {`${selectedRowIndices.length} Selected`}
          </Typography>
        ) : isString(props.title) ? (
          <Typography variant="subtitle1" id="tableToolbar-title">
            {props.title}
          </Typography>
        ) : (
          props.title
        )}
        <div className={classes.tableTitleControlLayout}>
          <Collapse in={!collapsed} timeout="auto">
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyItems={"end"}
            >
              {props.toolBarControls
                ? props.toolBarControls.map((f, idx) => (
                    <Grid item key={idx}>
                      {f}
                    </Grid>
                  ))
                : null}
              {props.filters &&
                !!props.filters.length && ( // only show filter button if filters given
                  <Grid item>
                    <IconButton
                      size="small"
                      id="bpTable-toggleFilterPanelOpened-button"
                      onClick={handleToggleFilterPanel}
                      className={cx(classes.filterIcon, {
                        [classes.filterIconPanelOpen]: filterPanelOpen,
                      })}
                      data-link-info={JSON.stringify({
                        content_interaction_id: "info-search",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_text: "expand filter",
                        content_interaction_driver:
                          props.interactionDriver ?? InteractionDriver.Search,
                      } as DataLinkInfoType)}
                    >
                      <FilterIcon
                        data-link-info={JSON.stringify({
                          content_interaction_id: "info-search",
                          content_interaction_action: InteractionAction.Click,
                          content_interaction_type: InteractionType.Icon,
                          content_interaction_text: "expand filter",
                          content_interaction_driver:
                            props.interactionDriver ?? InteractionDriver.Search,
                        } as DataLinkInfoType)}
                      />
                    </IconButton>
                  </Grid>
                )}
            </Grid>
          </Collapse>
        </div>
      </div>
      <Collapse in={!collapsed} timeout="auto">
        <Collapse in={filterPanelOpen}>
          <Box
            sx={{
              display: "flex",
              borderBottom: `1px solid ${theme.palette.divider}`,
              padding: theme.spacing(1, 3),
              alignItems: "center",
            }}
            className={"meshScroll"}
          >
            <Grid
              container
              columnSpacing={theme.spacing(2)}
              rowSpacing={theme.spacing(1)}
              alignItems="flex-start"
              sx={props.filterRowStyling}
            >
              {props.filters?.map((f, idx) => (
                <Grid item key={idx}>
                  {f}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>
        {!props.data.length && props.noDataSplashComponent && !props.loading ? (
          <Box
            className={cx(classes.tableWrapper, "meshScroll")}
            sx={{ height: tableWrapperHeight }}
          >
            {props.noDataSplashComponent}
          </Box>
        ) : (
          <div
            className={cx(classes.tableWrapper, "meshScroll")}
            style={{ height: tableWrapperHeight }}
          >
            <Table
              stickyHeader
              padding="none"
              size={denseTable ? "small" : "medium"}
            >
              <TableHead
                ref={(tableHeadRef: HTMLTableSectionElement) => {
                  if (props.loading) {
                    return;
                  }
                  if (!tableHeadRef) {
                    return;
                  }
                  if (
                    tableHeadRef.clientHeight &&
                    tableHeadRef.clientHeight !== tableHeadHeight
                  ) {
                    setTableHeadHeight(tableHeadRef.clientHeight);
                  }
                }}
                classes={{ root: classes.tableHead }}
              >
                <TableRow id="tableHead-row">
                  {
                    // only show 'check all' check box if neither disableSelect nor singleSelect are set
                    !(props.disableSelect || props.singleSelect) && (
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        padding="checkbox"
                      >
                        <Checkbox
                          id="tableHead-checkbox"
                          color="primary"
                          indeterminate={
                            !(
                              selectedRowIndices.length === props.data.length ||
                              selectedRowIndices.length === 0
                            )
                          }
                          checked={
                            selectedRowIndices.length !== 0 &&
                            props.data.length === selectedRowIndices.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )
                  }
                  {props.expandRowComponent && (
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      padding="checkbox"
                    >
                      <IconButton
                        size="small"
                        onClick={() => setRowExpandedStates({})}
                      >
                        <CollapseAllRowsIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  {props.columns.map((col, idx) => {
                    // if column is not sortable return a plain cell with label
                    if (col.sortable !== undefined && !col.sortable) {
                      return (
                        <TableCell
                          key={idx}
                          align={col.align}
                          style={{
                            minWidth: col.minWidth,
                            maxWidth: col.maxWidth,
                          }}
                          className={cx(classes.tableHeaderCell, {
                            [classes.tableCellFirstColumnSingleSelectModeOn]:
                              (props.singleSelect || props.disableSelect) &&
                              !idx,
                          })}
                        >
                          {col.label}
                        </TableCell>
                      );
                    }

                    // otherwise column assumed to be sortable
                    // look sorting for this column field
                    const sortingForField = query.sorting.find(
                      (sorting) => sorting.field === col.field,
                    );
                    return (
                      <TableCell
                        key={idx}
                        align={col.align}
                        style={{
                          minWidth: col.minWidth,
                          maxWidth: col.maxWidth,
                        }}
                        className={cx(classes.tableHeaderCell, {
                          [classes.tableCellFirstColumnSingleSelectModeOn]:
                            (props.singleSelect || props.disableSelect) && !idx,
                        })}
                      >
                        <TableSortLabel
                          active={!!sortingForField}
                          direction={
                            sortingForField
                              ? sortingForField.sortOrder
                              : undefined
                          }
                          onClick={handleUpdateSorting(col)}
                        >
                          {col.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {!props.loading && (
                <TableBody>
                  {props.data.map((data, rowIdx) => {
                    const rowIsSelected = props.selectedRowCheck
                      ? props.selectedRowCheck(data)
                      : props.singleSelect
                        ? rowIdx === singleSelectRowIndex
                        : selectedRowIndices.includes(rowIdx);
                    return (
                      <React.Fragment key={rowIdx}>
                        <TableRow
                          id={`tablerow-${String(rowIdx)}`}
                          onClick={handleRowSelect(rowIdx, data)}
                          className={cx(classes.tableRow, {
                            [classes.tableRowSelected]:
                              rowIsSelected ||
                              (props.expandRowComponent &&
                                rowExpandedStates[rowIdx]),
                          })}
                        >
                          {
                            // only show check box on row if neither disableSelect nor singleSelect are set
                            !(props.disableSelect || props.singleSelect) && (
                              <TableCell
                                id={`tablerow-${String(rowIdx)}-checkbox`}
                                align="center"
                                className={cx(classes.tableCell, {
                                  [classes.tableCellNoBorderBottom]:
                                    props.expandRowComponent &&
                                    rowExpandedStates[rowIdx],
                                })}
                                padding="checkbox"
                              >
                                <Checkbox
                                  id={`checkbox-${String(rowIdx)}`}
                                  color="primary"
                                  checked={rowIsSelected}
                                />
                              </TableCell>
                            )
                          }
                          {props.expandRowComponent && (
                            <TableCell
                              id={`tablerow-${String(rowIdx)}-expandRow`}
                              align="center"
                              className={cx(classes.tableCell, {
                                [classes.tableCellNoBorderBottom]:
                                  props.expandRowComponent &&
                                  rowExpandedStates[rowIdx],
                              })}
                              padding="checkbox"
                            >
                              <IconButton
                                size="small"
                                color={
                                  rowExpandedStates[rowIdx]
                                    ? "primary"
                                    : undefined
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRowExpandedStates({
                                    ...rowExpandedStates,
                                    [rowIdx]: !rowExpandedStates[rowIdx],
                                  });
                                }}
                              >
                                {rowExpandedStates[rowIdx] ? (
                                  <CollapseRowIcon />
                                ) : (
                                  <ExpandRowIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          )}
                          {props.columns.map((col, colIdx) => (
                            <TableCell
                              sx={{ maxWidth: col.maxWidth }}
                              id={`tablerow-${String(rowIdx)}-col-${String(
                                colIdx,
                              )}`}
                              key={colIdx}
                              className={cx(classes.tableCell, {
                                [classes.tableCellMinNonDensePadding]:
                                  !denseTable,
                                [classes.tableCellFirstColumnSingleSelectModeOn]:
                                  (props.singleSelect || props.disableSelect) &&
                                  !colIdx,
                              })}
                            >
                              {renderCellData(
                                data,
                                rowIsSelected,
                                col.field,
                                col.accessor,
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        {props.expandRowComponent && (
                          <TableRow
                            id={`expandRow-${String(rowIdx)}`}
                            onClick={(e) => e.stopPropagation()}
                            className={cx(classes.tableRowExpandRow)}
                          >
                            <TableCell
                              colSpan={
                                props.columns.length +
                                (props.disableSelect ? 1 : 2)
                              }
                              className={cx({
                                [classes.tableRowExpandCellOpen]:
                                  rowExpandedStates[rowIdx],
                                [classes.tableRowExpandCellClosed]:
                                  !rowExpandedStates[rowIdx],
                              })}
                            >
                              <Collapse
                                in={rowExpandedStates[rowIdx]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <div
                                  className={cx(
                                    classes.expandRowComponentWrapper,
                                    "meshScroll",
                                  )}
                                  style={{
                                    maxHeight: props.expandRowComponent
                                      .maxHeight
                                      ? props.expandRowComponent.maxHeight
                                      : 200,
                                  }}
                                >
                                  {props.expandRowComponent.component(data)}
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {props.loading && (
              <Box
                // Loading Wrapper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: loadingWrapperHeight,
                }}
              >
                <CircularProgress size={70} />
              </Box>
            )}
          </div>
        )}
        <div className={classes.tableFooter}>
          {!props.hideCondenseTableSwitch && (
            <div className={classes.tableFooterCondenseSwitchLayout}>
              <Switch
                color="primary"
                checked={denseTable}
                onChange={() => setDenseTable(!denseTable)}
                inputProps={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  "data-link-info": JSON.stringify({
                    content_interaction_id: "info-search",
                    content_interaction_action: InteractionAction.Filter,
                    content_interaction_type: InteractionType.Switch,
                    content_interaction_text: "condensed table",
                    content_interaction_driver:
                      props.interactionDriver ?? InteractionDriver.Search,
                  } as DataLinkInfoType),
                }}
              />
              <Typography children="Condensed Table" variant="body2" />
            </div>
          )}
          <TablePagination
            classes={{ root: classes.tablePaginationRoot }}
            ref={(paginationRef: HTMLDivElement) => {
              if (!paginationRef) {
                return;
              }
              if (
                paginationRef.clientHeight &&
                paginationComponentHeight !== paginationRef.clientHeight
              ) {
                setPaginationComponentHeight(paginationRef.clientHeight);
              }
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 100]}
            component="div"
            count={props.totalNoRecords}
            rowsPerPage={query.limit}
            page={query.offset / query.limit}
            backIconButtonProps={{ "aria-label": "previous page" }}
            nextIconButtonProps={{ "aria-label": "next page" }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Collapse>
    </>
  );

  if (props.divTable) {
    return (
      <StyledDiv sx={props.style} className={classes.root}>
        {tableElements}
      </StyledDiv>
    );
  }
  return (
    <StyledPaper sx={props.style} className={classes.root}>
      {tableElements}
    </StyledPaper>
  );
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export { BPTable };
