import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import meshMiniLogoNoWords from "assets/images/logo/meshLogoNoWords.svg";
import { useSnackbar } from "notistack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFirebaseContext } from "context/Firebase";
import { FirebaseError } from "@firebase/util";
import * as Sentry from "@sentry/react";
import { Close as CloseIcon, Error as ResolveIcon } from "@mui/icons-material";
import { ValidatePassword } from "utilities/validation";
import debounce from "lodash/debounce";
import { TabContext, TabPanel } from "@mui/lab";
import { PasswordRevealTextField } from "components/PasswordReveal/PasswordReveal";
import { Helmet } from "react-helmet-async";
import background from "assets/images/background/background.png";

const PREFIX = "ResetPassword";

const classes = {
  root: `${PREFIX}-root`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  resetPasswordCard: `${PREFIX}-resetPasswordCard`,
  resetPasswordCardContent: `${PREFIX}-resetPasswordCardContent`,
  newPasswordFormField: `${PREFIX}-newPasswordFormField`,
  newPasswordButton: `${PREFIX}-newPasswordButton`,
  formfieldsBox: `${PREFIX}-formfieldsBox`,
  buttonFormfieldsBox: `${PREFIX}-buttonFormfieldsBox`,
  img: `${PREFIX}-img`,
  boldTypography: `${PREFIX}-boldTypography`,
  returnToSignInButton: `${PREFIX}-returnToSignInButton`,
  tooltipCard: `${PREFIX}-tooltipCard`,
  tooltipArrow: `${PREFIX}-tooltipArrow`,
  tooltipCardHeaderSection: `${PREFIX}-tooltipCardHeaderSection`,
  tooltipResolveText: `${PREFIX}-tooltipResolveText`,
  tooltipIcon: `${PREFIX}-tooltipIcon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },

  [`& .${classes.backgroundImage}`]: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
  },

  [`& .${classes.resetPasswordCard}`]: {
    width: "800px",
    height: "471px",
    backgroundColor: "rgba(35, 32, 65, 0.75)",
    padding: theme.spacing(4, 3, 3, 7),
    borderRadius: "10px",
  },

  [`& .${classes.resetPasswordCardContent}`]: {
    display: "grid",
    gridRowGap: theme.spacing(2),
    gridTemplateRows: "repeat(4, auto)",
    width: "490px",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.newPasswordFormField}`]: {
    width: "240px",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.newPasswordButton}`]: {},

  [`& .${classes.formfieldsBox}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridColumnGap: theme.spacing(2),
  },

  [`& .${classes.buttonFormfieldsBox}`]: {
    display: "grid",
    gridTemplateColumns: "200px 200px",
    gridColumnGap: theme.spacing(2),
  },

  [`& .${classes.img}`]: {
    marginBottom: theme.spacing(5),
    cursor: "pointer",
  },

  [`& .${classes.boldTypography}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.returnToSignInButton}`]: {
    marginTop: theme.spacing(4),
  },

  //
  // Tooltip CSS
  //
  [`& .${classes.tooltipCard}`]: {
    width: "211px",
    backgroundColor: theme.palette.secondary.light,
    padding: "16px",
    display: "grid",
    gridTemplateRows: "auto",
    color: "black",
    marginTop: "6px",
  },

  [`& .${classes.tooltipArrow}`]: {
    color: theme.palette.secondary.light,
  },

  [`& .${classes.tooltipCardHeaderSection}`]: {
    display: "grid",
    gridTemplateColumns: "20px 1fr 20px",
    gridColumnGap: "4px",
    paddingBottom: "8px",
  },

  [`& .${classes.tooltipResolveText}`]: {
    fontSize: 12,
    fontWeight: "bold",
    alignSelf: "center",
  },

  [`& .${classes.tooltipIcon}`]: {
    width: "22px",
    height: "22px",
    color: "black",
  },
}));

enum ResetPasswordState {
  ResetPassword = "ResetPassword",
  ResetPasswordSuccess = "ResetPasswordSuccess",
  Loading = "Loading",
}

type ValidationStateType = {
  [key: string]: string | string[] | undefined;
};

export function ResetPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState<ResetPasswordState>(
    ResetPasswordState.ResetPassword,
  );
  const { firebaseResetPassword, firebaseVerifyPasswordResetCode } =
    useFirebaseContext();
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPasword] = useState("");
  const [newPassWordRepeated, setNewPasswordRepeated] = useState("");
  const [resetPasswordCode, setResetPasswordCode] = useState("");
  const [openPasswordTooltip, setOpenPasswordTooltip] =
    useState<boolean>(false);
  const [validationState, setValidationState] = useState<ValidationStateType>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const performValidation = (): boolean => {
    const newValidationState: ValidationStateType = {};

    const passwordValidationState = ValidatePassword(newPassword);
    if (passwordValidationState.length !== 0 && !openPasswordTooltip) {
      newValidationState.newPasswordFormField = passwordValidationState;
      setOpenPasswordTooltip(true);
      setValidationState(newValidationState);
      return !Object.keys(newValidationState).length;
    }

    if (newPassword !== newPassWordRepeated) {
      newValidationState.newPasswordRepeated = "Passwords do not match";
    }

    setValidationState(newValidationState);

    return !Object.keys(newValidationState).length;
  };

  const performInlineValidation = (field: string, value: string) =>
    debounce(() => {
      const newValidationState: ValidationStateType = {};
      if (field === "newPassword") {
        const passwordValidationState = ValidatePassword(value);
        if (passwordValidationState.length !== 0 && openPasswordTooltip) {
          newValidationState.newPasswordFormField = passwordValidationState;
          setValidationState(newValidationState);
        } else if (openPasswordTooltip) {
          // close the popper
          setOpenPasswordTooltip(false);
        }
      }
    }, 600);

  // useEffect that sets the code from the url when the app loads
  useEffect(() => {
    const code = searchParams.get("oobCode");
    if (code === null) {
      return;
    }
    (async () => {
      try {
        setState(ResetPasswordState.Loading);
        await firebaseVerifyPasswordResetCode(code);
        setState(ResetPasswordState.ResetPassword);
      } catch (e) {
        const err = e as FirebaseError;
        switch (err.code) {
          case "auth/invalid-action-code":
            console.error(err.message ? err.message : err.toString());
            enqueueSnackbar("Reset password link is invalid/expired.", {
              variant: "error",
              persist: true,
            });
            navigate("/sign-in");
            break;
          default:
            Sentry.captureException(e);
            console.error(
              `unexpected error resetting password: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar("Unexpected error resetting password", {
              variant: "error",
            });
            break;
        }
      }
    })();
    setResetPasswordCode(code);
  }, [firebaseVerifyPasswordResetCode, enqueueSnackbar, history]);

  const handleResetPassword = async () => {
    if (!performValidation()) {
      return;
    }

    // display loading screen
    setState(ResetPasswordState.Loading);

    try {
      await firebaseResetPassword(newPassword, resetPasswordCode);
      setState(ResetPasswordState.ResetPasswordSuccess);
    } catch (e) {
      const err = e as FirebaseError;
      switch (err.code) {
        case "auth/expired-action-code":
          console.error(err.message ? err.message : err.toString());
          enqueueSnackbar("Reset password link expired", { variant: "error" });
          break;
        default:
          Sentry.captureException(e);
          console.error(
            `unexpected error resetting password: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Unexpected error resetting password", {
            variant: "error",
          });
          break;
      }
      setState(ResetPasswordState.ResetPassword);
    }
  };

  return (
    <Background>
      <Helmet>
        <title>Mesh | Reset Password</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="mesh account view to view your portfolio and account balances"
        />
      </Helmet>
      <TabContext value={state}>
        <StyledBox sx={{ p: 3 }}>
          <TabPanel
            value={ResetPasswordState.ResetPassword}
            sx={{
              p: 0,
              width: { md: "800px", xs: "100%" },
            }}
          >
            <Box
              sx={{
                width: { md: "800px", xs: "100%" },
                [theme.breakpoints.up("sm")]: {
                  backgroundColor: "rgba(35, 32, 65, 0.75)",
                  borderRadius: "10px",
                  height: "471px",
                  padding: theme.spacing(4, 3, 3, 7),
                },
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Box
                component="img"
                id="resetPassword-meshLogo1-img"
                className={classes.img}
                alt=""
                width={230}
                src={meshMiniLogo}
                onClick={() => navigate("/sign-in")}
              />
              <Box
                sx={{
                  display: "grid",
                  gridRowGap: theme.spacing(1),
                  gridTemplateRows: "repeat(4, auto)",
                  width: { sm: "490px", xs: "100%" },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(2),
                  },
                }}
              >
                <Typography
                  className={classes.boldTypography}
                  variant="h3"
                  align={isMobile ? "center" : "left"}
                  sx={{ mb: 1 }}
                >
                  Reset Password
                </Typography>
                {!isMobile && (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                    }}
                  >
                    Please enter your new password below.
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "grid",
                    mt: 1,
                    gridGap: theme.spacing(1),
                    [theme.breakpoints.up("sm")]: {
                      gridTemplateColumns: "250px 250px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      gridTemplateRows: "auto auto",
                    },
                  }}
                >
                  <Tooltip
                    id="resetPassword-passwordValidation-tooltip"
                    open={openPasswordTooltip}
                    placement="bottom-start"
                    title={
                      <>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "20px 1fr 20px",
                            gridColumnGap: "4px",
                            paddingBottom: "8px",
                          }}
                        >
                          <ResolveIcon className={classes.tooltipIcon} />
                          <Typography
                            variant="body2"
                            className={classes.tooltipResolveText}
                          >
                            Please Resolve:
                          </Typography>
                          <IconButton
                            id="resetPassword-passwordValidationTooltipClose-button"
                            onClick={() => setOpenPasswordTooltip(false)}
                            aria-label="close"
                            className={classes.tooltipIcon}
                            size="large"
                          >
                            <CloseIcon
                              id="resetPassword-passwordValidationTooltipClose-icon"
                              className={classes.tooltipIcon}
                            />
                          </IconButton>
                        </Box>
                        {(() => (
                          <>
                            {validationState &&
                              validationState.newPasswordFormField &&
                              (
                                validationState.newPasswordFormField as string[]
                              ).map((value, idx) => (
                                <Typography key={idx} variant="body2">
                                  {value}
                                </Typography>
                              ))}
                          </>
                        ))()}
                      </>
                    }
                  >
                    <span>
                      <PasswordRevealTextField
                        fullWidth={isMobile}
                        id="resetPassword-password-textfield"
                        type="password"
                        InputLabelProps={{ shrink: undefined }}
                        autoComplete="current-password"
                        value={newPassword}
                        size="medium"
                        onChange={(e) => {
                          setNewPasword(e.target.value);
                          performInlineValidation(
                            "newPassword",
                            e.target.value,
                          )();
                        }}
                        sx={{
                          [theme.breakpoints.up("sm")]: {
                            width: "240px",
                            marginBottom: theme.spacing(2),
                          },
                        }}
                        label="New Password"
                      />
                    </span>
                  </Tooltip>
                  <PasswordRevealTextField
                    fullWidth={isMobile}
                    id="resetPassword-repeatedPassword-textfield"
                    type="password"
                    InputLabelProps={{ shrink: undefined }}
                    autoComplete="current-password"
                    value={newPassWordRepeated}
                    size="medium"
                    onChange={(e) => setNewPasswordRepeated(e.target.value)}
                    sx={{
                      [theme.breakpoints.up("sm")]: {
                        width: "240px",
                        marginBottom: theme.spacing(2),
                      },
                    }}
                    label="Repeat Password"
                    error={
                      validationState
                        ? !!validationState.newPasswordRepeated
                        : false
                    }
                    helperText={
                      validationState && validationState.newPasswordRepeated
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    mt: 1,
                    [theme.breakpoints.up("sm")]: {
                      gridTemplateColumns: "200px 200px",
                      gap: theme.spacing(2),
                    },
                    [theme.breakpoints.down("sm")]: {
                      gridTemplateRows: "48px 48px",
                      gap: theme.spacing(5),
                    },
                  }}
                >
                  <Button
                    fullWidth={isMobile}
                    disabled={newPassword === "" && newPassWordRepeated === ""}
                    size={isMobile ? "large" : "medium"}
                    id="resetPassword-reset-button"
                    component="div"
                    sx={{
                      [theme.breakpoints.up("sm")]: {
                        width: "200px",
                      },
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleResetPassword}
                  >
                    reset password
                  </Button>
                  <Button
                    fullWidth={isMobile}
                    size={isMobile ? "large" : "medium"}
                    id="resetPassword-returnToSignIn-button"
                    onClick={() => navigate("/sign-in")}
                    component="div"
                    sx={{
                      [theme.breakpoints.up("sm")]: {
                        width: "200px",
                      },
                    }}
                    variant="outlined"
                  >
                    return to sign in
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel
            value={ResetPasswordState.ResetPasswordSuccess}
            sx={{
              p: 0,
              width: { md: "800px", xs: "100%", sm: "600px" },
            }}
          >
            <Box
              sx={{
                width: { md: "800px", xs: "100%", sm: "600px" },
                [theme.breakpoints.up("sm")]: {
                  backgroundColor: "rgba(35, 32, 65, 0.75)",
                  borderRadius: "10px",
                  height: "471px",
                  padding: theme.spacing(4, 3, 3, 7),
                },
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Box
                component="img"
                id="resetPassword-meshLogo2-img"
                className={classes.img}
                alt=""
                width={230}
                src={meshMiniLogo}
                onClick={() => navigate("/sign-in")}
              />
              <Box
                sx={{
                  display: "grid",
                  gridRowGap: theme.spacing(2),
                  gridTemplateRows: "repeat(3, auto)",
                  width: { sm: "490px", xs: "100%" },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(2),
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-start" },
                  }}
                >
                  <Typography
                    className={classes.boldTypography}
                    variant="h3"
                    align={isMobile ? "center" : "left"}
                    sx={{ maxWidth: { xs: "200px", sm: "100%" } }}
                  >
                    Your password has been reset
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  align={isMobile ? "center" : "left"}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    mt: 1,
                  })}
                >
                  Feel free to proceed to sign in.
                </Typography>
                <Box
                  sx={{
                    [theme.breakpoints.up("sm")]: {
                      width: "115px",
                    },
                  }}
                >
                  <Button
                    sx={{
                      mt: 3,
                      [theme.breakpoints.down("sm")]: {
                        height: "48px",
                      },
                      [theme.breakpoints.up("sm")]: {
                        with: "115px",
                      },
                    }}
                    fullWidth={isMobile}
                    size={isMobile ? "large" : "medium"}
                    id="resetPassword-continue-button"
                    component="div"
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/sign-in")}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel
            value={ResetPasswordState.Loading}
            sx={{
              p: 0,
              width: { md: "800px", xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box
                component="img"
                className={classes.img}
                alt=""
                width={130}
                src={meshMiniLogoNoWords}
                onClick={() => navigate("/sign-in")}
              />
            </Box>
          </TabPanel>
        </StyledBox>
      </TabContext>
    </Background>
  );
}

interface BackgroundProps {
  children?: React.ReactNode;
}

const Background = ({ children }: BackgroundProps): JSX.Element => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return smDown ? (
    <Box
      sx={{
        backgroundColor: "rgba(35, 32, 65, 1)",
        width: "100vw",
        height: "100vh",
      }}
    >
      {children}
    </Box>
  ) : (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
