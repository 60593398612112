export const ModelTypeName = "mesh::potDirectOrderParticipantView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  groupID = "";

  name = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.groupID = model.groupID;
    this.name = model.name;
  }
}
