import { ValidationResult } from "common/validation";
import { ValidateEmailDomain, ValidatePassword } from "utilities/validation";

export type FormState = {
  email: string;
  password: string;
  companyName: string;
};

export type formUpdaterSpecsType = {
  email: (value: string, prevState?: FormState) => FormState;
  password: (value: string, prevState?: FormState) => FormState;
  companyName: (value: string, prevState?: FormState) => FormState;
};

export const formUpdaterSpecs: formUpdaterSpecsType = {
  email: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    email: value,
  }),
  password: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    password: value,
  }),
  companyName: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    companyName: value,
  }),
};

export const validationFunc = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  const validatePasswordResponse = ValidatePassword(formState.password);
  if (validatePasswordResponse.length !== 0) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.password =
      validatePasswordResponse.join(",");
  }

  const validateEmailResponse = ValidateEmailDomain(formState.email);
  if (validateEmailResponse.length !== 0) {
    newValidationState.valid = false;
    newValidationState.fieldValidations.email = validateEmailResponse.join(",");
  }

  if (formState.companyName === "") {
    newValidationState.fieldValidations.companyName =
      "company name is a required field";
  }

  return newValidationState;
};

const initializeState = (): FormState => {
  return {
    email: "",
    password: "",
    companyName: "",
  };
};

export const initialState = initializeState();
