import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import React from "react";
import { alpha, Backdrop, Box, Skeleton } from "@mui/material";
import { ChartLegend } from "./components/ChartLegend";
import { Token } from "james/ledger";
import { StellarNetwork } from "james/stellar";
import { useAssetColorPicker } from "hooks";

interface AssetAllocationChartProps {
  // loading to render the loading state of the component
  loading: boolean;
  // asset allocation to render allong with their legend
  assetAllocations: { asset: Token; percentage: string }[];
  // optional prop to position the no text that is being rendered on the legend
  noDataTextLeftPos?: number;
}

export const AssetAllocationChart = (props: AssetAllocationChartProps) => {
  const getColor = useAssetColorPicker(props.assetAllocations.length === 0);

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "88px auto",
        marginTop: theme.spacing(2),
        columnGap: theme.spacing(2),
        position: "relative",
      })}
    >
      <Backdrop
        sx={{
          position: "absolute",
          borderRadius: "4px",
          zIndex: 1,
          left: `${props.noDataTextLeftPos}px`,
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0),
        }}
        open={props.assetAllocations.length === 0 && !props.loading}
      >
        No data to display.
      </Backdrop>
      {props.assetAllocations && !props.loading ? (
        <ResponsiveContainer width="100%" height={97}>
          <PieChart
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Pie
              width="80px"
              height="92px"
              innerRadius={16}
              outerRadius={39}
              data={
                props.assetAllocations.length !== 0
                  ? props.assetAllocations.map((val) => ({
                      allocation: val.asset.code,
                      percentage: +val.percentage,
                    }))
                  : assetAllocationChartDummyData.map((val) => ({
                      allocation: val.asset.code,
                      percentage: +val.percentage,
                    }))
              }
              dataKey="percentage"
              nameKey="allocation"
              stroke="none"
            >
              {props.assetAllocations.map((entry) => (
                <Cell
                  key={`${entry.asset.code.toString()}:${entry.asset.issuer.toString()}`}
                  fill={getColor(
                    `${entry.asset.code.toString()}:${entry.asset.issuer.toString()}`,
                  )}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton height={88} width={"100%"} variant={"circular"} />
      )}
      {props.assetAllocations ? (
        <ChartLegend data={props.assetAllocations} />
      ) : null}
    </Box>
  );
};

const assetAllocationChartDummyData = [
  {
    asset: new Token({
      code: "mZAR",
      issuer: "issuer",
      network: StellarNetwork.TestSDFNetwork,
    }),
    percentage: "40",
  },
];
