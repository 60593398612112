import { Amount } from "james/ledger";
import dayjs from "dayjs";
import { AuditEntry } from "../audit";

export enum DefundOrderState {
  Pending = "Pending",
  UnderInvestigation = "Under Investigation",
  AwaitingSettlement = "Awaiting Settlement",
  Settled = "Settled",
  SettlementInProgress = "Settlement In Progress",
  Cancelled = "Cancelled",
}

export const AllDefundOrderStates: DefundOrderState[] = [
  DefundOrderState.Pending,
  DefundOrderState.UnderInvestigation,
  DefundOrderState.AwaitingSettlement,
  DefundOrderState.Settled,
  DefundOrderState.SettlementInProgress,
  DefundOrderState.Cancelled,
];

export const DefundOrderTypeName = "mesh::banking/DefundOrder";

export class DefundOrder {
  ["@type"]: string = DefundOrderTypeName;

  id = "";

  number = "";

  ledgerAccountID = "";

  bankReference = "";

  express = false;

  amount: Amount = new Amount();

  state: DefundOrderState | "" = "";

  bankAccountID = "";

  auditEntry: AuditEntry = new AuditEntry();

  creationDate: string = dayjs().format();

  userID = "";

  ownerID = "";

  constructor(defundOrder?: DefundOrder) {
    if (!defundOrder) {
      return;
    }

    this.id = defundOrder.id;
    this.number = defundOrder.number;
    this.ledgerAccountID = defundOrder.ledgerAccountID;
    this.bankReference = defundOrder.bankReference;
    this.express = defundOrder.express;
    this.amount = new Amount(defundOrder.amount);
    this.bankAccountID = defundOrder.bankAccountID;
    this.state = defundOrder.state;
    this.auditEntry = new AuditEntry(defundOrder.auditEntry);
    this.creationDate = defundOrder.creationDate;
    this.userID = defundOrder.userID;
    this.ownerID = defundOrder.ownerID;
  }
}
