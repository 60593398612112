import React, { useState } from "react";
import { Header } from "components/Cards/MarketDirectOrderViewCard/Components/Header";
import { OrderDirectionAndState } from "components/Cards/MarketDirectOrderViewCard/Components/OrderDirectionAndState";
import { AvatarAndDirection } from "components/Cards/MarketDirectOrderViewCard/Components/AvatarAndDirection";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DirectOrderState, DirectOrderType } from "james/market/DirectOrder";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import { DirectOrderStateController } from "james/market/DirectOrderStateController";
import { useNotificationContext } from "context/Notification";
import { GroupNotificationChannel } from "james/group";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
} from "james/views/marketDirectOrderView";
import { Notification } from "james/notification/Notification";
import { IssuerAwaitingConfirmationCardProps } from "./IssuerAwaitingConfirmationCard";
import { WarningDialogOptions } from "views/InstrumentBuilder/Instruments/common";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { useApplicationContext } from "context/Application/Application";
import {
  TransactionDetails,
  TransactionStage,
  TransactionTypes,
} from "types/gtm";
import dayjs from "dayjs";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { StyledCard, classes } from "../cardStyle";
import { useErrorContext } from "context/Error";

export function InitiatingPartyCard(
  props: IssuerAwaitingConfirmationCardProps,
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();
  const { pushTransactionAbandon } = useGTMTriggersPusher();
  const ordersViewConfiguration = viewConfiguration.Marketplace
    ? viewConfiguration.Marketplace
      ? viewConfiguration.Marketplace.Orders
      : {}
    : {};
  const isBuy =
    props.marketDirectOrderViewModel.orderType === DirectOrderType.Buy;

  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const [cancellationInProgress, setCancellationInProgress] = useState(false);
  const handleCancel = async () => {
    setCancellationInProgress(true);
    try {
      // cancel direct order
      await DirectOrderStateController.CancelDirectOrderID({
        context: authContext,
        directOrderID: props.marketDirectOrderViewModel.directOrderID,
      });

      // close the card
      props.onActionComplete();

      // notify that cancellation is in progress
      enqueueSnackbar(
        `Order #${props.marketDirectOrderViewModel.number} is being cancelled`,
        { variant: "info" },
      );
    } catch (e) {
      console.error(
        `error cancelling direct order ${props.marketDirectOrderViewModel.directOrderID}`,
        e,
      );
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Cancelling Order #${props.marketDirectOrderViewModel.number}: ${err.message}`,
        { variant: "warning" },
      );
      setCancellationInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached cancelled
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: props.marketDirectOrderViewModel.initiatingPartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID ===
              props.marketDirectOrderViewModel.directOrderID
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Cancelling:
              case DirectOrderState.ClearanceFailed:
              case DirectOrderState.Failing:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.Cancelled:
                pushTransactionAbandon({
                  // use the Direct Order Number to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the asset type
                  transaction_asset_type:
                    props.assetIssuanceTokenViewModel.tokenCategory,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating: "-",
                  transaction_stage: TransactionStage.abandon,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage to zero
                  transaction_slippage: "0",
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency: n.model.feeAmount.token.code.toString(),
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile: "-",
                } as TransactionDetails);
                enqueueSnackbar(`Order #${n.model.number} cancelled`, {
                  variant: "success",
                });
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registering for order notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Order Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  return (
    <StyledCard
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        borderRadius: "10px",
        [theme.breakpoints.down("sm")]: {
          borderRadius: 0,
          height: "100%",
        },
      }}
    >
      <Header {...props} showLoading={cancellationInProgress} />
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(3, 4),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        }}
      >
        <OrderDirectionAndState
          viewingAsInitiatingParty
          marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        />
        <AvatarAndDirection
          name={props.marketDirectOrderViewModel.initiatingPartyClientName}
          pictureDLURL={
            props.marketDirectOrderViewModel.initiatingPartyProfilePictureDLURL
          }
          receives={false} // Issuer pays...
        />
        <Box className={classes.sectionWithRows1Gap}>
          <Typography
            color="textSecondary"
            variant="caption"
            children={isBuy ? "Amount" : "Tokens"}
          />
          <Amount
            id="issuerAwaitingConfirmationCard-pays-amount"
            reverse={!isBuy}
            codeTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountCode,
            }}
            valueTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountValue,
            }}
            formatTextNumOpts={{
              noDecimalPlaces: isBuy ? 2 : 7,
              addDecimalPadding: isBuy,
            }}
            amount={
              isBuy
                ? // Buy:
                  // Show what issuer will give pay: amountIncl
                  props.marketDirectOrderViewModel.amountIncl
                : // Sell:
                  // Show what issuer will give pay: tokens
                  props.marketDirectOrderViewModel.tokens
            }
          />
          <Box className={classes.tradeFeeLayout}>
            <Typography
              variant="caption"
              className={classes.disabledText}
              children="Direct Order Fee:"
            />
            <Amount
              id="issuerAwaitingConfirmationCard-fees-amount"
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketDirectOrderViewModel.feeAmount}
            />
          </Box>
          <Box className={classes.tradeFeeLayout}>
            <Typography
              variant="caption"
              className={classes.disabledText}
              children="VAT on Fee:"
            />
            <Amount
              id="issuerAwaitingConfirmationCard-vat-amount"
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketDirectOrderViewModel.feeAmount.setValue(
                props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                  props.marketDirectOrderViewModel.vatRate,
                ),
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          padding: theme.spacing(3, 4),
        }}
      >
        <AvatarAndDirection
          name={props.marketDirectOrderViewModel.initiatingPartyClientName}
          pictureDLURL={
            props.marketDirectOrderViewModel.initiatingPartyProfilePictureDLURL
          }
          receives // Issuer receives...
        />
        <Box className={classes.sectionWithRows1Gap}>
          <Box className={classes.sectionWith2EqualColumns}>
            <Typography
              color="textSecondary"
              variant="caption"
              children={isBuy ? "Tokens" : "Amount"}
            />
            <Typography
              color="textSecondary"
              variant="caption"
              children="Price per Token"
            />
          </Box>
          <Box className={classes.sectionWith2EqualColumns}>
            <Amount
              id="issuerAwaitingConfirmationCard-receives-amount"
              reverse={isBuy}
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: isBuy ? 7 : 2,
                addDecimalPadding: !isBuy,
              }}
              amount={
                isBuy
                  ? // Buy:
                    // Show what issuer will receive: tokens
                    props.marketDirectOrderViewModel.tokens
                  : // Sell:
                    // Show what issuer will receive: amountIncl
                    props.marketDirectOrderViewModel.amountIncl
              }
            />
            <Amount
              id="issuerAwaitingConfirmationCard-price-amount"
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketDirectOrderViewModel.price}
            />
          </Box>
        </Box>
        {ordersViewConfiguration.Cancel && (
          <Box
            sx={[
              {
                padding: theme.spacing(2, 3),
                width: "100%",
              },
              !(isMobile || isDesktop) && {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <Tooltip
              placement={isMobile ? "top" : "right"}
              title={(() => {
                switch (true) {
                  case cancellationInProgress:
                    return "Calculation in Progress";
                  case !props.userIsSignatoryOnTradingAcc:
                    return "You are not a Signatory on the Trading Account";
                }
                return "";
              })()}
            >
              <span>
                <Button
                  id="issuerAwaitingConfirmationCard-initiatingParty-cancel-button"
                  fullWidth
                  sx={[!(isMobile || isDesktop) && { width: "328px" }]}
                  variant="outlined"
                  disabled={
                    cancellationInProgress || !props.userIsSignatoryOnTradingAcc
                  }
                  onClick={() =>
                    setWarningDialogOptions({
                      title: "Warning",
                      messageParagraphs: [
                        "Are you sure you want to CANCEL your order?",
                        "You will not be able to undo this action.",
                      ],
                      yesMethod: () => {
                        setWarningDialogOptions(null);
                        handleCancel().finally();
                      },
                      noMethod: () => setWarningDialogOptions(null),
                    })
                  }
                  children="cancel"
                />
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>

      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={warningDialogOptions?.yesMethod ?? (() => null)}
        onNoClick={warningDialogOptions?.noMethod ?? (() => null)}
        title={warningDialogOptions?.title}
        messageParagraphs={warningDialogOptions?.messageParagraphs ?? [""]}
        disableControls={cancellationInProgress}
        showProgressIndicator={cancellationInProgress}
      />
    </StyledCard>
  );
}
