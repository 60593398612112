export enum SpotType {
  Buy = "Buy",
  Sell = "Sell",
}

export const AllSpotTypes: SpotType[] = [SpotType.Buy, SpotType.Sell];

export enum SpotState {
  Pending = "Pending",
  Settled = "Settled",
  Failed = "Failed",
  UnderInvestigation = "Under Investigation",
}

export const AllSpotStates: SpotState[] = [
  SpotState.Pending,
  SpotState.Settled,
  SpotState.Failed,
  SpotState.UnderInvestigation,
];
