import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTimer } from "hooks/useTimer";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { SubscriptionOrderBookStateController } from "james/market/SubscriptionOrderBookStateController";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";

interface Props {
  assetName: string;
  subscriptionOrderBook: SubscriptionOrderBook;
  onClose: () => void;
}

export const CancellationConfirmationDialog: React.FC<Props> = ({
  subscriptionOrderBook,
  onClose,
  assetName,
}: Props) => {
  const theme = useTheme();
  const { timerProgress, timerCompleted, timerValue, startTimer, stopTimer } =
    useTimer();
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = useCallback(async () => {
    setLoading(true);
    try {
      await SubscriptionOrderBookStateController.RequestSubscriptionOrderBookIDCancellation(
        {
          context: authContext,
          subscriptionOrderBookID: subscriptionOrderBook.id,
        },
      );
      enqueueSnackbar(`Cancellation in Progress - Refresh to Monitor`, {
        variant: "success",
      });
    } catch (e) {
      console.error("error requesting cancellation", e);
      errorContextDefaultWarningFeedback(e, "error requesting cancellation");
    }
    setLoading(false);
  }, [authContext, subscriptionOrderBook]);

  useEffect(() => {
    (async () => {
      if (!approved) {
        return;
      }

      if (timerCompleted) {
        await handleCancel();
        onClose();
      }
    })();
  }, [timerCompleted]);

  return (
    <Dialog open>
      <DialogTitle sx={(theme) => ({ padding: theme.spacing(2) })}>
        Cancellation Confirmation
      </DialogTitle>
      <DialogContent
        style={{
          alignItems: "center",
          minWidth: "590px",
          padding: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Typography>Please confirm that you would like to cancel:</Typography>
          <Typography>
            Subscription <b>{subscriptionOrderBook.number}</b> for{" "}
            <b>{assetName}</b>
          </Typography>
          <Typography sx={{ color: theme.palette.warning.light }}>
            This action <b>CANNOT</b> be undone!
          </Typography>
        </Box>
        <DialogActions>
          {!approved && (
            <Tooltip placement="top" title={"Close"}>
              <span>
                <Button
                  id="fundReceipt-updateFunding"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onClick={onClose}
                  children="Close"
                />
              </span>
            </Tooltip>
          )}
          <Tooltip
            placement="top"
            title={!approved ? "Confirm Cancellation" : "Cancel"}
          >
            <span>
              <LoadingButton
                disabled={loading}
                id="fundReceipt-fundAccount-balance"
                variant={
                  timerCompleted ? "text" : approved ? "outlined" : "contained"
                }
                color={approved ? "secondary" : "primary"}
                fullWidth
                size="small"
                onClick={() => {
                  if (approved) {
                    setApproved(false);
                    stopTimer();
                    return;
                  } else {
                    setApproved(true);
                    startTimer(10);
                    return;
                  }
                }}
              >
                {timerCompleted ? (
                  <CircularProgress size={21} variant="indeterminate" />
                ) : approved ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      lineHeight="0px"
                      color="text.primary"
                      children="Cancel"
                    />
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        size={21}
                        variant="determinate"
                        value={100 - timerProgress}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                          }}
                          variant="caption"
                          component="div"
                          color="text.primary"
                        >
                          {timerValue === 0 ? `` : `${Math.round(timerValue)}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  "Confirm"
                )}
              </LoadingButton>
            </span>
          </Tooltip>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
