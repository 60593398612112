import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  SlideProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Cancel as CancelIcon, Close as CloseIcon } from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { GroupUser, UserCompository } from "james/user";
import { TextField } from "components/FormFields/TextField";
import {
  TextExactCriterion,
  TextNINListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import { useSnackbar } from "notistack";
import { Query } from "james/search/query";
import { SearchGroupUsersRequest } from "james/user/Compository";
import { TextNINListCriterionType } from "james/search/criterion/text/NINList";
import { AccountOperator, AddSignatoriesResponse } from "james/stellar";
import { AccountSignatoriesContext } from "../AccountDetails/AccountDetails";
import { AccountSignatoryCard } from "../AccountDetails/components/AccountSignatoriesList/components/AccountSignatoryCard";
import { useNotificationContext } from "context/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

interface AddSignatoryDialogProps {
  open: boolean;
  onClose: () => void;
  accountID: string;
}

let searchGroupUsersTimeout: NodeJS.Timeout;

export function AddSignatoryDialog(props: AddSignatoryDialogProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { errorContextErrorTranslator } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [usersToBeAddedAsSignatories, setUsersToBeAddedAsSignatories] =
    useState<GroupUser[]>([]);
  const [availableUsersAsASignatories, setAvailableUsersAsSignatories] =
    useState<GroupUser[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { accountSignatories, retrieveGroupUsers } = useContext(
    AccountSignatoriesContext,
  );
  const { authContext, viewConfiguration, loginClaims } =
    useApplicationContext();
  const [searchGroupUsersRequest, setSearchGroupUsersRequest] =
    useState<SearchGroupUsersRequest>({
      context: authContext,
      criteria: {
        clientID: TextExactCriterion(loginClaims.clientID),
      },
      query: new Query(),
    });

  // fetch group users
  useEffect(() => {
    let mounted = true;
    const deb = setTimeout(() => {
      const searchGroupUsers = async () => {
        if (mounted) {
          setLoading(true);
        }
        try {
          if (accountSignatories.length) {
            const idExclusionCriteria = searchGroupUsersRequest.criteria.id
              ? TextNINListCriterion([
                  ...(
                    searchGroupUsersRequest.criteria
                      .id as TextNINListCriterionType
                  ).list,
                  ...accountSignatories.map((gu) => gu.id),
                ])
              : TextNINListCriterion(accountSignatories.map((gu) => gu.id));

            // search for group users, excluded existing signatories
            if (mounted) {
              setAvailableUsersAsSignatories(
                (
                  await UserCompository.SearchGroupUsers(
                    accountSignatories.length
                      ? {
                          ...searchGroupUsersRequest,
                          criteria: {
                            ...searchGroupUsersRequest.criteria,
                            id: idExclusionCriteria,
                            clientID: TextExactCriterion(loginClaims.clientID),
                          },
                        }
                      : searchGroupUsersRequest,
                  )
                ).records,
              );
            }
          } else if (mounted) {
            setAvailableUsersAsSignatories(
              (await UserCompository.SearchGroupUsers(searchGroupUsersRequest))
                .records,
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error searching for group users: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error searching for group users: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
        }
        if (mounted) {
          setLoading(false);
        }
      };
      clearTimeout(searchGroupUsersTimeout);
      searchGroupUsersTimeout = setTimeout(searchGroupUsers, 400);
    }, 450);

    return () => {
      mounted = false;
      clearTimeout(deb);
    };
  }, [
    searchGroupUsersRequest,
    enqueueSnackbar,
    accountSignatories,
    loginClaims.clientID,
  ]);

  const SignatoryCardOnClick = (gUser: GroupUser) => {
    setUsersToBeAddedAsSignatories([...usersToBeAddedAsSignatories, gUser]);
    setSearchGroupUsersRequest({
      ...searchGroupUsersRequest,
      criteria: {
        ...searchGroupUsersRequest.criteria,
        id: TextNINListCriterion(
          [...usersToBeAddedAsSignatories, gUser].map((gu) => gu.id),
        ),
      },
    });
  };

  const selectedUserChipOnDelete = (gUser: GroupUser) => {
    const updatedUsersToBeAddedAsSignatories =
      usersToBeAddedAsSignatories.filter((v) => v.id !== gUser.id);
    setUsersToBeAddedAsSignatories(updatedUsersToBeAddedAsSignatories);
    if (updatedUsersToBeAddedAsSignatories.length > 0) {
      setSearchGroupUsersRequest({
        ...searchGroupUsersRequest,
        criteria: {
          ...searchGroupUsersRequest.criteria,
          id: TextNINListCriterion(
            [...updatedUsersToBeAddedAsSignatories].map((gu) => gu.id),
          ),
        },
      });
    } else {
      const updatedRequest = searchGroupUsersRequest;
      delete updatedRequest.criteria.id;
      setSearchGroupUsersRequest({
        ...updatedRequest,
      });
    }
  };

  const searchSignatory = (nameText: string) => {
    setSearchGroupUsersRequest({
      ...searchGroupUsersRequest,
      criteria: {
        ...searchGroupUsersRequest.criteria,
        fullName: TextSubstringCriterion(!nameText ? " " : nameText),
      },
    });
  };

  const handleAddSignatories = async () => {
    setLoading(true);

    let addSignatoriesResponse: AddSignatoriesResponse;
    try {
      // notify the user that the operation is in progress
      enqueueSnackbar(
        `Adding ${usersToBeAddedAsSignatories.length} account signatories`,
        {
          variant: "info",
        },
      );

      addSignatoriesResponse = await AccountOperator.AddSignatories({
        context: authContext,
        accountID: props.accountID,
        userIDs: usersToBeAddedAsSignatories.map((gu) => gu.id),
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error! Adding Signatories Failed: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      setLoading(false);
      return;
    }

    try {
      // register a callback to fire once the signatory has been added
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: addSignatoriesResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === addSignatoriesResponse.transactionID
          ) {
            enqueueSnackbar("Success! Signatories Added", {
              variant: "success",
            });
            retrieveGroupUsers();
            props.onClose();
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === addSignatoriesResponse.transactionID
          ) {
            enqueueSnackbar("Error! Adding Signatories Failed", {
              variant: "warning",
            });
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === addSignatoriesResponse.transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong while adding signatories",
              { variant: "warning" },
            );
          }

          setLoading(false);
          deregister();
        },
      );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Unable to Subscribe to Transaction Notifications: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "warning" },
      );
      setLoading(false);
      return;
    }
  };

  return (
    <>
      {!isMobile && (
        <Dialog fullScreen open={props.open}>
          <DialogTitle
            sx={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid
              sx={{
                alignItems: "center",
              }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid
                item
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  alignItems: "center",
                  gridColumnGap: theme.spacing(2),
                })}
              >
                <Box
                  sx={{
                    height: 32,
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <img alt="" width={40} src={meshMiniLogo} />
                </Box>
                <Typography variant="h5">Add Signatory</Typography>
              </Grid>
              <Grid item>
                {usersToBeAddedAsSignatories.length > 0 &&
                  viewConfiguration.AddSignatories && (
                    <Button
                      id="addSignatoryDialog-add-button"
                      sx={(theme) => ({
                        width: "110px",
                        marginRight: theme.spacing(1),
                      })}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                      size="small"
                      children="add"
                      onClick={handleAddSignatories}
                    />
                  )}
                <IconButton
                  size="small"
                  id="addSignatory-close-button"
                  onClick={props.onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.midnight,
              padding: `${theme.spacing(2)} !important`,
              overflow: "hidden",
              [theme.breakpoints.up("sm")]: {
                backgroundColor: theme.palette.custom.midnight,
                padding: `${theme.spacing(6, 9, 6, 9)} !important`,
                overflow: "hidden",
              },
            })}
          >
            <Typography color="textSecondary" variant="body1">
              Users to be selected as signatories
            </Typography>
            <Box
              id="addSignatory-selectedUserChip-wrapper"
              className={"meshScroll"}
              sx={(theme) => ({
                display: "flex",
                overflowX: "auto",
                width: "100%",
                margin: theme.spacing(2, 0, 2, 0),
              })}
            >
              {usersToBeAddedAsSignatories.map((v, idx) => (
                <React.Fragment key={idx}>
                  <Chip
                    id={`addSignatoryDialog-${v.email}-chip`}
                    disabled={loading}
                    sx={(theme) => ({
                      margin: theme.spacing(0, 1, 1, 0),
                      backgroundColor: theme.palette.info.main,
                    })}
                    deleteIcon={
                      <CancelIcon
                        sx={(theme) => ({
                          color: `${theme.palette.text.secondary} !important`,
                          "&:hover": {
                            color: `${theme.palette.secondary.contrastText} !important`,
                          },
                        })}
                      />
                    }
                    clickable
                    label={v.fullName}
                    onDelete={() => selectedUserChipOnDelete(v)}
                  />
                </React.Fragment>
              ))}
              {usersToBeAddedAsSignatories.length === 0 && (
                <Typography
                  variant="body1"
                  style={{
                    color: theme.palette.warning.main,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  No users selected
                </Typography>
              )}
            </Box>
            <Typography
              style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              variant="h6"
            >
              Company Users
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Select one or more users to add as signatories to the account:
            </Typography>
            <TextField
              id="addSignatory-search-formfield"
              onChange={(e) => searchSignatory(e.target.value)}
              style={{
                width: "100%",
                maxWidth: "340px",
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              label="Search"
            />
            {!loading && (
              <Box
                sx={{
                  height: "calc(100vh - 300px)",
                  maxWidth: "500px",
                  marginBottom: theme.spacing(2),
                  overflowY: "auto",
                  pr: { sm: 2 },
                }}
                className={"meshScroll"}
              >
                {availableUsersAsASignatories.map((v) => (
                  <React.Fragment key={v.id}>
                    <AccountSignatoryCard
                      signatoryGroupUser={v}
                      onClick={SignatoryCardOnClick}
                      renderCheckBox={true}
                    />
                  </React.Fragment>
                ))}
              </Box>
            )}
            {loading && (
              <Box
                sx={(theme) => ({
                  height: "calc(100vh - 300px)",
                  maxWidth: "500px",
                  paddingRight: theme.spacing(1),
                })}
                className={"meshScroll"}
              >
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      )}
      {isMobile && (
        <Dialog
          fullScreen
          open={props.open}
          TransitionComponent={slideTransition}
        >
          <DialogTitle
            sx={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid
              sx={{
                alignItems: "center",
              }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid
                item
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  alignItems: "center",
                  gridColumnGap: theme.spacing(2),
                })}
              >
                <Box
                  sx={{
                    height: 32,
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <img alt="" width={40} src={meshMiniLogo} />
                </Box>
                <Typography variant="h5">Add Signatory</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  id="addSignatory-close-button"
                  onClick={props.onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.midnight,
              padding: `${theme.spacing(3)} !important`,
              boxShadow: "0 -10px 12px -14px #000 inset",
            })}
          >
            <Typography color="textSecondary" variant="body1">
              Users to be selected as signatories:
            </Typography>
            <Box
              id="addSignatory-selectedUserChip-wrapper"
              className={"meshScroll"}
              sx={(theme) => ({
                display: "flex",
                overflowX: "auto",
                width: "100%",
                margin: theme.spacing(2, 0, 2, 0),
              })}
            >
              {usersToBeAddedAsSignatories.map((v, idx) => (
                <React.Fragment key={idx}>
                  <Chip
                    id={`addSignatoryDialog-${v.email}-chip`}
                    disabled={loading}
                    sx={(theme) => ({
                      "&.MuiChip-deleteIcon": {
                        color: `${theme.palette.common.black}!important`,
                      },
                      margin: theme.spacing(0, 1, 1, 0),
                      color: theme.palette.background.default,
                      backgroundColor: theme.palette.secondary.light,
                    })}
                    clickable
                    label={v.fullName}
                    onDelete={() => selectedUserChipOnDelete(v)}
                  />
                </React.Fragment>
              ))}
              {usersToBeAddedAsSignatories.length === 0 && (
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.warning.main,
                    fontSize: "14px",
                    fontWeight: theme.typography.fontWeightMedium,
                    marginBottom: theme.spacing(2),
                  }}
                >
                  No users selected
                </Typography>
              )}
            </Box>
            <Typography
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              variant="h6"
            >
              Company Users
            </Typography>
            <Typography
              sx={{
                marginBottom: theme.spacing(1),
              }}
              color="textSecondary"
              variant="body1"
            >
              Select one or more users to add as signatories to the account:
            </Typography>
            <Grid
              sx={{
                margin: theme.spacing(2, 0, 2, 0),
                height: 48,
              }}
              item
            >
              <TextField
                id="addSignatory-search-formfield"
                disabled={loading}
                onChange={(e) => searchSignatory(e.target.value)}
                sx={{
                  width: "100%",
                }}
                label={"Search"}
              />
            </Grid>
            {!loading && (
              <Box
                sx={{
                  height: "calc(100vh - 300px)",
                  maxWidth: "500px",
                }}
                className={"meshScroll"}
              >
                {availableUsersAsASignatories.map((v) => (
                  <React.Fragment key={v.id}>
                    <AccountSignatoryCard
                      signatoryGroupUser={v}
                      onClick={SignatoryCardOnClick}
                      renderCheckBox={true}
                    />
                  </React.Fragment>
                ))}
              </Box>
            )}
            {loading && (
              <Box
                sx={(theme) => ({
                  height: "calc(100vh - 300px)",
                  maxWidth: "500px",
                  paddingRight: theme.spacing(1),
                })}
                className={"meshScroll"}
              >
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
                <Skeleton animation="wave" width={"100%"} height={"64px"} />
              </Box>
            )}
          </DialogContent>
          <Grid
            sx={{
              maxWidth: "497px",
              width: "100%",
              padding: theme.spacing(3, 3, 5, 3),
              backgroundColor: theme.palette.custom.midnight,
            }}
            alignItems="center"
            justifyContent="center"
            item
          >
            <Button
              id="addSignatoryDialog-add-button"
              sx={{
                width: "100%",
                height: 48,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: "17px",
              }}
              variant="contained"
              color="primary"
              children="add"
              onClick={handleAddSignatories}
              disabled={usersToBeAddedAsSignatories.length === 0}
            />
          </Grid>
        </Dialog>
      )}
    </>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" {...tprops} />;
};
