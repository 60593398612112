import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Card,
  Divider,
  MenuItem,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import range from "lodash/range";
import { NumberIncrementField } from "components/FormFields/NumberField";
import { Amount } from "components/Ledger/Amount";
import { LegendIcon } from "./LegendIcon";
import BigNumber from "bignumber.js";
import { CashFlowType, Model } from "james/views/financialCashFlowView/Model";
import dayjs from "dayjs";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";
import { Amount as LedgerAmount } from "james/ledger";
import { useAccountContext } from "context/Account/Account";

import { TextField } from "components/FormFields";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { PaymentState } from "@mesh/common-js/dist/financial/payment_pb";
import { frequencyToString } from "@mesh/common-js/dist/financial";

interface EstimateRepaymentCardProps {
  subscriptionOrderBook: MarketSubscriptionOrderBookViewModel;
  cashFlowViewModels: Model[];
  unitsInCirculation: LedgerAmount;
  totalNominal: LedgerAmount;
  instrumentCouponFrequency: Frequency;
  unit: string;
  paymentType: string;
  termEndDescription: string;
}

export const EstimateRepaymentCard = ({
  subscriptionOrderBook,
  cashFlowViewModels,
  unitsInCirculation,
  instrumentCouponFrequency,
  unit,
  paymentType,
  termEndDescription,
}: EstimateRepaymentCardProps) => {
  const [frequency, setFrequency] = useState<Frequency>(
    instrumentCouponFrequency,
  );
  const [amount, setAmount] = useState(1);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { stellarAccountContext } = useAccountContext();
  const [totalTokensOwned, setTotalTokensOwned] = useState<BigNumber>();
  const { current: isPublic } = useRef(window.location.href.includes("public"));

  const [dataSet, setDataSet] = useState<DataSetType[]>([]);

  useEffect(() => {
    if (!isPublic) return;
    let tokensOwned = new BigNumber(0);
    stellarAccountContext.accounts.forEach((a) => {
      const balance = a.getTokenBalance(subscriptionOrderBook.token);
      if (balance) {
        tokensOwned = tokensOwned.plus(balance.amount.value);
      }
    });

    setTotalTokensOwned(tokensOwned);
  }, [stellarAccountContext, stellarAccountContext.loading]);

  useEffect(() => {
    setDataSet(aggregateDate(cashFlowViewModels, amount));
  }, [cashFlowViewModels, amount, frequency]);

  const [estValueAtMaturity, setValueAtMaturity] = useState<LedgerAmount>(
    new LedgerAmount(),
  );
  const [nextPayAmount, setNextPayAmount] = useState<LedgerAmount>(
    new LedgerAmount(),
  );
  const [nextPayDate, setNextPayDate] = useState("");

  useEffect(() => {
    if (cashFlowViewModels.length > 0) {
      let amountValue = new LedgerAmount();
      for (let i = 0; i < cashFlowViewModels.length; i++) {
        amountValue.token = cashFlowViewModels[i].amount.token;
        amountValue = amountValue.setValue(
          amountValue.value.plus(cashFlowViewModels[i].amount.value),
        );
      }

      setValueAtMaturity(
        amountValue.setValue(amountValue.value.multipliedBy(amount)),
      );
    }

    let nextPayment: Model | undefined = undefined;
    for (const m of cashFlowViewModels) {
      if (
        !(
          m.paymentState === PaymentState.COMPLETE_PAYMENT_STATE ||
          m.paymentState === PaymentState.PAID_PAYMENT_STATE
        )
      ) {
        nextPayment = m;
        break;
      }
    }

    const na = new LedgerAmount(nextPayment?.amount);
    setNextPayAmount(
      na.setValue(
        na.value.dividedBy(unitsInCirculation.value).multipliedBy(amount),
      ),
    );
    setNextPayDate(nextPayment?.date ?? "");
  }, [cashFlowViewModels, amount]);

  const couponFrequencyIntervalMap = {
    [Frequency.MONTHLY_FREQUENCY]: 1,
    [Frequency.QUARTERLY_FREQUENCY]: 3,
    [Frequency.SEMI_ANNUALLY_FREQUENCY]: 6,
    [Frequency.ANNUALLY_FREQUENCY]: 12,
    [Frequency.UNDEFINED_FREQUENCY]: 0,
  };

  const aggregateDate = (
    models: Model[],
    subscriptionQuantity: number,
  ): DataSetType[] => {
    const newDataSet: DataSetType[] = [];
    const interval =
      couponFrequencyIntervalMap[frequency] /
      couponFrequencyIntervalMap[instrumentCouponFrequency];

    const dateMap: Record<string, DataSetType> = {};

    for (let i = 0; i < models.length; i++) {
      const model = models[i];

      const formattedDate = dayjs(model.date).format("YYYY-MM");
      if (!dateMap[formattedDate]) {
        dateMap[formattedDate] = {
          aggregate: 0,
          amount: 0,
          total: 0,
          paid: false,
        };
      }

      switch (model.cashFlowType) {
        case CashFlowType.InterestCashFlow:
          dateMap[formattedDate].amount = model.amount.value.toNumber();
          dateMap[formattedDate].paid =
            model.paymentState === PaymentState.PAID_PAYMENT_STATE;
          break;
        case CashFlowType.DividendCashFlow:
          dateMap[formattedDate].amount = model.amount.value.toNumber();
          break;
        case CashFlowType.PrincipalCashFlow:
          dateMap[formattedDate].total = model.amount.value.toNumber();
          break;
      }

      if (i > 0) {
        const previous = dateMap[dayjs(models[i - 1].date).format("YYYY-MM")];
        if (previous) {
          dateMap[formattedDate].aggregate =
            previous.aggregate + models[i - 1].amount.value.toNumber();
        }
      }
    }

    Object.keys(dateMap)
      .sort()
      .forEach((key) => {
        newDataSet.push(dateMap[key]);
      });

    const filteredDataSet: DataSetType[] = [];
    let aggregatedValue = {
      aggregate: 0,
      amount: 0,
      total: 0,
      paid: false,
    };
    for (let i = 0; i < newDataSet.length; i++) {
      if ((i + 1) % interval === 0) {
        filteredDataSet.push({
          aggregate:
            i + 1 >= interval
              ? newDataSet[i + 1 - interval].aggregate * subscriptionQuantity
              : 0,
          amount:
            (aggregatedValue.amount + newDataSet[i].amount) *
            subscriptionQuantity,
          total:
            (aggregatedValue.total + newDataSet[i].total) *
            subscriptionQuantity,
          paid: newDataSet[i].paid,
        });
        aggregatedValue = {
          aggregate: newDataSet[i].aggregate,
          amount: 0,
          total: 0,
          paid: false,
        };
      } else {
        aggregatedValue = {
          aggregate: newDataSet[i].aggregate,
          amount: aggregatedValue.amount + newDataSet[i].amount,
          total: aggregatedValue.total + newDataSet[i].total,
          paid: newDataSet[i].paid,
        };
      }
    }

    return filteredDataSet;
  };

  return (
    <Card
      sx={(theme) => ({
        p: { sm: 3, xs: 2 },
        pt: 4,
        backgroundColor: theme.palette.custom.midnight,
        width: { lg: 774, xs: "100%" },
        borderRadius: { xs: "4px", sm: "8px" },
      })}
    >
      {smDown && (
        <>
          <Box>
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.palette.text.primary,
                ml: 1,
                mt: 1,
                mb: 3,
              })}
            >
              Estimated Payments
            </Typography>
            <Box
              sx={(theme) => ({
                pb: 2,
                pl: { xs: 1, sm: 0 },
                color: theme.palette.text.disabled,
                ".primary": {
                  color: theme.palette.text.primary,
                },
                [theme.breakpoints.down("sm")]: {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                },
              })}
            >
              <Box>
                <NumberIncrementField
                  width={smDown ? "118px" : "88px"}
                  disallowNegative
                  noDecimals
                  value={amount}
                  variant={smDown ? "middle-input" : "left-input"}
                  minValue={1}
                  maxValue={subscriptionOrderBook.overSubscriptionAmount.value
                    .dividedBy(subscriptionOrderBook.unitPrice.value)
                    .toNumber()}
                  onChange={(e) => {
                    setAmount(+e);
                  }}
                />
                {totalTokensOwned && totalTokensOwned.gt(0) && (
                  <Typography noWrap sx={{ mr: 1, mt: 1 }}>
                    You{" "}
                    <span className="primary">
                      own {totalTokensOwned.toFormat()} {unit}
                      {totalTokensOwned.gt(1) ? "s" : ""}
                    </span>{" "}
                    at
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection:
                      unit === "preference share" ? "column" : "row",
                    mt: 1,
                  }}
                >
                  <Amount
                    codeTypographyProps={{
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                        fontSize: "10px",
                      }),
                    }}
                    valueTypographyProps={{
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                        fontSize: "10px",
                      }),
                    }}
                    amount={subscriptionOrderBook.unitPrice}
                  />
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      ml: unit === "preference share" ? "0px" : "4px",
                      fontSize: "10px",
                    })}
                  >
                    per {unit}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    })}
                  >
                    Next Est. {paymentType} Payment
                  </Typography>
                  <Amount
                    valueTypographyProps={{
                      variant: "subtitle1",
                      className: "primary",
                      sx: (theme) => ({
                        fontWeight: theme.typography.fontWeightMedium,
                        maxWidth: "100%",
                      }),
                    }}
                    codeTypographyProps={{
                      variant: "subtitle1",
                      className: "primary",
                      sx: (theme) => ({
                        fontWeight: theme.typography.fontWeightMedium,
                      }),
                    }}
                    amount={nextPayAmount}
                  />
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    })}
                  >
                    On {dayjs(nextPayDate).format("YYYY-MM-DD")}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    })}
                  >
                    Est. Investment Value at {termEndDescription}
                  </Typography>
                  <Amount
                    valueTypographyProps={{
                      variant: "subtitle1",
                      className: "primary",
                      sx: (theme) => ({
                        fontWeight: theme.typography.fontWeightMedium,
                      }),
                    }}
                    codeTypographyProps={{
                      variant: "subtitle1",
                      className: "primary",
                      sx: (theme) => ({
                        fontWeight: theme.typography.fontWeightMedium,
                      }),
                    }}
                    amount={estValueAtMaturity}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mb: 2, mt: 1 }} orientation={"horizontal"} />
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          justifyContent: "space-between",
          mb: 2,
          ml: { sm: 0, xs: 2 },
        }}
      >
        {!smDown && (
          <Typography variant="body1" sx={{ ml: { xs: 1, sm: 0 } }}>
            Estimated Payments
          </Typography>
        )}
        {/* legend */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { sm: "center", xs: "flex-start" },
            ml: { sm: 3, xs: 1 },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                sm: "column",
                xs: "row",
              },
            }}
          >
            <Box
              sx={(theme) => ({
                display: "grid",
                color: theme.palette.text.disabled,
                [theme.breakpoints.up("sm")]: {
                  gridTemplateColumns: "repeat(3, 1fr)",
                },
                [theme.breakpoints.down("sm")]: {
                  gridTemplateRows: "repeat(3, 1fr)",
                },
                "& > *": {
                  mr: { sm: 1 },
                  fontSize: {
                    sm: "14px",
                    xs: "10px",
                  },
                },
                my: { sm: 0, xs: 1 },
              })}
            >
              <LegendIcon
                label={`${paymentType}`}
                color={(theme) => theme.palette.warning.main}
              />
              <LegendIcon
                label={`Aggregate`}
                color={(theme) => theme.palette.primary.main}
              />
              <LegendIcon label={`Principal`} color={() => "#56D9FD"} />
            </Box>
            <Box
              sx={(theme) => ({
                display: "grid",
                color: theme.palette.text.disabled,
                alignItems: "center",
                ml: { xs: 3, md: 0, lg: 0 },
                [theme.breakpoints.up("sm")]: {
                  gridTemplateColumns: "repeat(3, 1fr)",
                },
                [theme.breakpoints.down("sm")]: {
                  gridTemplateRows: "repeat(3, 1fr)",
                },
                "& > *": {
                  mr: 1,
                  fontSize: {
                    sm: "14px",
                    xs: "10px",
                  },
                },
                my: { sm: 0, xs: 1 },
              })}
            >
              <LegendIcon
                label="Realised"
                fill
                color={(theme) => theme.palette.warning.main}
              />
              <LegendIcon
                label="Realised"
                fill
                color={(theme) => theme.palette.primary.main}
              />
              <LegendIcon label="Realised" fill color={() => "#56D9FD"} />
            </Box>
          </Box>
          <TextField
            select
            color="secondary"
            sx={(theme) => ({
              width: "160px",
              "& .MuiOutlinedInput-root": {
                "& .MuiSvgIcon-root": {
                  color: theme.palette.secondary.main,
                },
                "& fieldset": {
                  borderColor: theme.palette.secondary.main,
                },
                "&.Mui-focused fieldset": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                },
              },
            })}
            value={frequency}
            onChange={(e) => setFrequency(+e.target.value)}
          >
            <MenuItem value={instrumentCouponFrequency}>
              {frequencyToString(instrumentCouponFrequency)}
            </MenuItem>
            <MenuItem value={Frequency.ANNUALLY_FREQUENCY}>
              {frequencyToString(Frequency.ANNUALLY_FREQUENCY)}
            </MenuItem>
          </TextField>
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "200px 8px auto",
            xs: "auto",
          },
        }}
      >
        {/* left hand section */}
        {!smDown && (
          <Box
            sx={(theme) => ({
              pb: 2,
              pl: { xs: 1, sm: 0 },
              color: theme.palette.text.disabled,
              ".primary": {
                color: theme.palette.text.primary,
              },
              [theme.breakpoints.down("sm")]: {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              },
            })}
          >
            <Box>
              <NumberIncrementField
                width={"88px"}
                disallowNegative
                noDecimals
                value={amount}
                variant={"left-input"}
                minValue={1}
                maxValue={subscriptionOrderBook.overSubscriptionAmount.value
                  .dividedBy(subscriptionOrderBook.unitPrice.value)
                  .toNumber()}
                onChange={(e) => {
                  setAmount(+e);
                }}
              />
              {totalTokensOwned && totalTokensOwned.gt(0) && (
                <Typography noWrap sx={{ mr: 1, mt: 1 }}>
                  You{" "}
                  <span className="primary">
                    own {totalTokensOwned.toFormat()} {unit}
                    {totalTokensOwned.gt(1) ? "s" : ""}
                  </span>{" "}
                  at
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: unit === "preference share" ? "column" : "row",
                  mt: 1,
                }}
              >
                <Amount
                  codeTypographyProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.secondary,
                      fontSize: "12px",
                    }),
                  }}
                  valueTypographyProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.secondary,
                      fontSize: "12px",
                    }),
                  }}
                  amount={subscriptionOrderBook.unitPrice}
                />
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    ml: unit === "preference share" ? "0px" : "4px",
                    fontSize: "12px",
                  })}
                >
                  per {unit}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Divider sx={{ my: 2 }} />

              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                })}
              >
                Next Est. {paymentType} Payment
              </Typography>
              <Box sx={{ width: "200px" }}>
                <Amount
                  rootStyles={{
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                  valueTypographyProps={{
                    variant: "subtitle1",
                    className: "primary",
                    sx: (theme) => ({
                      fontWeight: theme.typography.fontWeightMedium,
                      textOverflow: "ellipsis",
                    }),
                  }}
                  codeTypographyProps={{
                    variant: "subtitle1",
                    className: "primary",
                    sx: (theme) => ({
                      fontWeight: theme.typography.fontWeightMedium,
                    }),
                  }}
                  amount={nextPayAmount}
                />
              </Box>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                })}
              >
                On {dayjs(nextPayDate).format("YYYY-MM-DD")}
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                  mt: {
                    sm: 0,
                    xs: 2,
                  },
                })}
              >
                Est. Investment Value at {termEndDescription}
              </Typography>
              <Amount
                rootStyles={{
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
                valueTypographyProps={{
                  variant: "subtitle1",
                  className: "primary",
                  sx: (theme) => ({
                    fontWeight: theme.typography.fontWeightMedium,
                    maxWidth: "150px",
                  }),
                }}
                codeTypographyProps={{
                  variant: "subtitle1",
                  className: "primary",
                  sx: (theme) => ({
                    fontWeight: theme.typography.fontWeightMedium,
                  }),
                }}
                amount={estValueAtMaturity}
              />
            </Box>
          </Box>
        )}
        {!smDown && <Divider sx={{ ml: 1 }} orientation={"vertical"} />}
        {/* graph section */}
        <Box>
          {/* graph */}
          <Box
            sx={{
              width: {
                lg: "500px",
                xs: "100%",
              },
              height: "280px",
              pl: { sm: 1 },
              pt: 1,
            }}
          >
            <EstimatedRepaymentsGraph
              dataSet={dataSet}
              frequency={frequency}
              tokenCode={
                cashFlowViewModels.length > 0
                  ? cashFlowViewModels[0].amount.token.code
                  : ""
              }
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

type DataSetType = {
  aggregate: number;
  amount: number;
  total: number;
  paid: boolean;
};

interface EstimatedRepaymentsGraphProps {
  dataSet: DataSetType[];
  tokenCode: string;
  frequency: Frequency;
}

const EstimatedRepaymentsGraph = ({
  dataSet,
  tokenCode,
  frequency,
}: EstimatedRepaymentsGraphProps) => {
  const theme = useTheme();

  const [startEndIndex, setStartEndIndex] = useState<[number, number]>([
    0,
    dataSet.length - 1,
  ]);

  useEffect(() => {
    setStartEndIndex([0, dataSet.length - 1]);
  }, [dataSet, frequency]);

  const interval = useMemo(() => {
    const inRange = startEndIndex[1] - startEndIndex[0];
    if (inRange >= 100) {
      return 10;
    } else if (inRange >= 80) {
      return 8;
    } else if (inRange >= 60) {
      return 6;
    } else if (inRange >= 30) {
      return 4;
    } else if (inRange > 10) {
      return 1;
    }

    return 0;
  }, [startEndIndex]);

  const newData = useMemo(
    () =>
      dataSet.map((data) => {
        return {
          aggregate: data.paid ? 0 : data.aggregate,
          amount: data.paid ? 0 : data.amount,
          total: data.paid ? 0 : data.total,
          fillAggregate: data.paid ? data.aggregate : 0,
          fillCoupon: data.paid ? data.amount : 0,
          fillTotal: data.paid ? data.total : 0,
        };
      }),
    [dataSet],
  );

  const xAxisLabelMap = {
    [Frequency.UNDEFINED_FREQUENCY]: "-",
    [Frequency.MONTHLY_FREQUENCY]: "Month",
    [Frequency.QUARTERLY_FREQUENCY]: "Quarter",
    [Frequency.SEMI_ANNUALLY_FREQUENCY]: "Half-Year",
    [Frequency.ANNUALLY_FREQUENCY]: "Year",
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography
        sx={(theme) => ({
          height: "20px",
          mt: "120px",
          transform: "rotate(-90deg)",
          color: theme.palette.text.disabled,
          ml: { xs: -1 },
          mr: { xs: -1 },
        })}
      >
        {tokenCode}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={newData} margin={{ top: 16, bottom: 24, left: -8 }}>
          <defs>
            <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorWarning" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.warning.main}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.warning.main}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorSecondary" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"#56D9FD"} stopOpacity={0.4} />
              <stop offset="95%" stopColor={"#56D9FD"} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid
            horizontal={true}
            vertical={false}
            stroke={theme.palette.text.disabled}
          />
          <YAxis tick={CustomTick} axisLine={false} tickLine={false} />
          <XAxis
            interval={interval}
            tick={({ x, y, payload }) => {
              return (
                <g transform={`translate(${x},${y})`}>
                  <text x={0} y={-6} dy={16} textAnchor="middle" fill="#666">
                    {payload.value + 1 + startEndIndex[0]}
                  </text>
                </g>
              );
            }}
            tickLine={false}
          >
            <Label
              value={xAxisLabelMap[frequency]}
              offset={-8}
              position="insideBottom"
            />
          </XAxis>
          <Tooltip
            cursor={{ fill: alpha(theme.palette.custom.grape, 0.32) }}
            content={({ active, payload }) => {
              if (active && payload && payload.length > 0) {
                return (
                  <Box
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      p: 1,
                      backgroundColor: theme.palette.custom.cardInner,
                      borderRadius: "8px",
                      boxShadow: 1,
                      gap: 1,
                    })}
                  >
                    <LegendIcon
                      label={`${tokenCode} ${new BigNumber(
                        (payload[1].value
                          ? payload[1].value?.toString()
                          : payload[4].value?.toString()) ?? "0",
                      ).toFixed(2)}`}
                      color={(theme) => theme.palette.warning.main}
                    />
                    <LegendIcon
                      label={`${tokenCode} ${new BigNumber(
                        (payload[0].value
                          ? payload[0].value?.toString()
                          : payload[3].value?.toString()) ?? "0",
                      ).toFixed(2)}`}
                      color={(theme) => theme.palette.primary.main}
                    />
                    {!!payload[2].value && (payload[2].value as number) > 0 && (
                      <LegendIcon
                        label={`${tokenCode} ${new BigNumber(
                          (payload[2].value
                            ? payload[2].value?.toString()
                            : payload[5].value?.toString()) ?? "0",
                        ).toFixed(2)}`}
                        color={() => "#56D9FD"}
                      />
                    )}
                  </Box>
                );
              }

              return null;
            }}
          />
          <Bar
            stackId="a"
            stroke={theme.palette.primary.main}
            fill={"url(#colorPrimary)"}
            dataKey="aggregate"
          />
          <Bar
            stackId="a"
            stroke={theme.palette.warning.main}
            fill={"url(#colorWarning)"}
            dataKey="amount"
          />
          <Bar
            stackId="a"
            dataKey="total"
            stroke={"#56D9FD"}
            fill={"url(#colorSecondary)"}
          />
          <Bar
            stackId="a"
            stroke={theme.palette.primary.main}
            fill={theme.palette.primary.main}
            dataKey="fillAggregate"
          />
          <Bar
            stackId="a"
            stroke={theme.palette.warning.main}
            fill={theme.palette.warning.main}
            dataKey="fillCoupon"
          />
          <Bar
            stackId="a"
            dataKey="fillTotal"
            stroke={"#56D9FD"}
            fill={"#56D9FD"}
          />
          {dataSet.length > 20 && (
            <Brush
              stroke={theme.palette.secondary.main}
              fill={theme.palette.custom.midnight}
              r={dataSet.length}
              travellerWidth={8}
              y={232}
              onChange={(s) => {
                setStartEndIndex([
                  s.startIndex ?? 0,
                  s.endIndex ?? dataSet.length - 1,
                ]);
              }}
            >
              <BrushRailWay
                r={60}
                x={0}
                y={8}
                width={dataSet.length * 4}
                height={20}
                paddingTop={4}
                paddingRight={4}
                paddingBottom={4}
                paddingLeft={4}
              />
            </Brush>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

type BrushRailWayProps = {
  r: number;
  x: number;
  y: number;
  width: number;
  height: number;
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
};

export const BrushRailWay: React.FC<BrushRailWayProps> = ({
  r,
  x,
  y,
  width,
  height,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
}) => {
  const theme = useTheme();
  if (r === 0) return <></>;
  const rectWidth = (width - paddingLeft - paddingRight) / r;
  const rectHeight = height - paddingTop - paddingBottom;

  return (
    <svg x={x} y={y} width={width} height={height}>
      {range(r).map((index) => {
        return (
          <rect
            key={index}
            x={index * rectWidth + paddingLeft}
            y={paddingTop}
            width={1}
            height={rectHeight}
            rx="1"
            fill={theme.palette.text.disabled}
            stroke="none"
          />
        );
      })}
    </svg>
  );
};

interface CustomTickProps {
  x: number;
  y: number;
  payload: {
    value: number;
  };
}

const CustomTick = ({ x, y, payload }: CustomTickProps) => {
  return (
    <g transform={`translate(${x - 5},${y - 5})`}>
      <text
        x="-40"
        y="-2"
        fontSize={12}
        textAnchor="start"
        fill={alpha("#FFFFFF", 0.4)}
      >
        {new BigNumber(payload.value).toFormat()}
      </text>
      <rect
        x="-39"
        y="4.3"
        width="52"
        height="1"
        fill={alpha("#FFFFFF", 0.4)}
      />
    </g>
  );
};
