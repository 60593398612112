/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from "react";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { Box, CircularProgress, styled } from "@mui/material";
import { Query } from "james/search/query";
import { SortingType } from "james/search/query/Query";
import { useEffectRunAfterFirstRender } from "hooks/useEffectRunAfterFirstRender";
import cx from "classnames";

const PREFIX = "InfiniteScrollList";

const classes = {
  hideScrollbars: `${PREFIX}-hideScrollbars`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.hideScrollbars}`]: {
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

type InfiniteScrollListProps<T> = {
  /**
   * total is the total amount data that will need to be rendered.
   */
  total: number;
  /**
   * loadMoreData is a callback function that is called to retrieve data as the user scrolls
   * or when a criteria or query changes. The updateOffset callback function needs to be called
   * right after the api call to update the query offset for a future api call.
   */
  loadMoreData: (
    criteria: any,
    query: Query,
    updateOffset: () => void,
  ) => () => void;
  /**
   * data is the current retrieved data.
   */
  data: T[];
  /**
   * clearData callback function is supposed to clear the cached data that the InfiniteScrollList has. This is important for
   * the criteria and query changes to function correctly. Example callback could setModels([])
   */
  clearData: () => void;
  /**
   * isLoading is for indicating to the InfiniteScrollList that an api call to fetch more data is in progress.
   */
  isLoading: boolean;
  /**
   * renderData gets called for each row on the InfiniteScrollList, with data for that row. It should return a component to be
   * rendered on that row.
   */
  renderData: (data: T) => React.ReactNode;
  /**
   * noDataSplashComponent is the optional component to be rendered when there is no data. By default nothing gets rendered.
   */
  noDataSplashComponent?: JSX.Element;
  /**
   * batchSize is the amount of data that needs to be retrieved at a time. Everytime loadMoreData get called, the default.
   * the value is 10.
   */
  batchSize?: number;
  /**
   * rowGap is the gap is the gap between the rows of the list. The default is 8.
   */
  rowGap?: number;
  /**
   * threshold is the range near the end of the list should loadMoreData be triggered. The default value is 0.
   */
  threshold?: number;
  /**
   * renderLoadingRow is the component to be rendered while more data is being retrieved.
   */
  renderLoadingRow?: React.ReactNode;
  /**
   * rowHeight is the height each row should be on the list. Ideally make the rowHeight to be the same as the component height being returned
   * by the renderData callback. By default it is 100px.
   */
  rowHeight?: number;
  /**
   * rowWidth is the width of each row on the list. This can be thought of as the list width as well.
   * By default it is 360px.
   */
  rowWidth?: number | string;
  /**
   * listHeight is the height of the list's viewport. By default, it is 500px.
   */
  listHeight?: number;
  /**
   * initialCriteria is the initial criteria to be applied on the call of loadMoreItems.
   */
  initialCriteria?: any;
  /**
   * initialSorting is the initial query sorting to be applied on the first call of loadMoreItems.
   */
  initialSorting?: SortingType[];
  /**
   * disableMeshScroll disables the meshScroll on the list and uses the native scroll.
   */
  disableMeshScroll?: boolean;

  /**
   * hideScrollbars hides scrollbars completely.
   */
  hideScrollbars?: boolean;

  /**
   * lastRowPadding adds some padding when the last row component is visible. By default, it is zero
   */
  lastRowPadding?: number;

  /**
   * children is the components to be rendered above the InfiniteScrollList, with callback functions to update
   * the criteria and sorting used in loadMoreItems exposed.
   */
  children?: (props: {
    setCriteria: (criteria: any | ((prevCriteria: any) => any)) => void;
    setSorting: (sorting: SortingType[] | (() => SortingType[])) => void;
  }) => React.ReactNode;
};

export const InfiniteScrollList = <T extends object>(
  props: InfiniteScrollListProps<T>,
) => {
  const {
    total,
    loadMoreData,
    data,
    clearData,
    isLoading,
    renderData,
    renderLoadingRow,
    noDataSplashComponent,
    lastRowPadding = 0,
    threshold = 0,
    rowGap = 4,
    batchSize = 10,
    rowWidth = 360,
    rowHeight = 100,
    listHeight = 500,
    disableMeshScroll = false,
    hideScrollbars = false,
    initialCriteria = {},
    initialSorting = [],
  } = props;

  const [criteria, setCriteria] = useState<any>(initialCriteria);
  const [sorting, setSorting] = useState<SortingType[]>(initialSorting);
  const [offset, setOffset] = useState(0);
  const [manualTrigger, setManualTrigger] = useState(false);
  const manualTriggerTimeoutRef = useRef<any>(undefined);

  // whenever the criteria or the sorting changes
  // then this useEffect trigger a manual retrieve of data by calling loadMoreData
  useEffectRunAfterFirstRender(() => {
    setOffset(0);
    clearTimeout(manualTriggerTimeoutRef.current);
    manualTriggerTimeoutRef.current = setTimeout(async () => {
      clearData();
      setManualTrigger(true);
    }, 1000);
  }, [criteria, sorting]);

  useEffectRunAfterFirstRender(() => {
    if (!manualTrigger) {
      return;
    }

    if (manualTrigger) {
      loadMoreData(
        criteria,
        new Query({
          offset: 0,
          sorting: sorting,
          limit: batchSize,
        }),
        () => {
          return;
        },
      )();
      setOffset(offset + batchSize);
      setManualTrigger(false);
    }
  }, [manualTrigger]);

  return (
    <StyledBox sx={{ width: "100%" }}>
      {props.children ? (
        props.children({
          setCriteria,
          setSorting,
        })
      ) : (
        <></>
      )}
      <InfiniteLoader
        isItemLoaded={(index) => !!data[index]}
        itemCount={
          total > data.length || data.length === 0
            ? data.length + 1
            : data.length
        }
        minimumBatchSize={batchSize}
        threshold={threshold}
        loadMoreItems={loadMoreData(
          criteria,
          new Query({
            offset: offset,
            sorting: sorting,
            limit: batchSize,
          }),
          () => {
            setOffset(offset + batchSize);
          },
        )}
      >
        {({ onItemsRendered, ref }) => (
          <List
            height={listHeight}
            itemCount={
              total > data.length || data.length === 0
                ? data.length + 1
                : data.length
            }
            itemSize={() => rowHeight}
            overscanCount={10}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={rowWidth}
            className={cx(
              { ["meshScroll"]: !disableMeshScroll },
              { [classes.hideScrollbars]: hideScrollbars },
            )}
          >
            {ListRow(
              data,
              renderData,
              isLoading,
              rowGap,
              { height: listHeight, width: rowWidth },
              lastRowPadding,
              renderLoadingRow,
              noDataSplashComponent,
            )}
          </List>
        )}
      </InfiniteLoader>
    </StyledBox>
  );
};

const ListRow = <T extends object>(
  data: T[],
  renderData: (data: T) => React.ReactNode,
  isLoading: boolean,
  rowGap: number,
  size: { height: number; width: number | string },
  lastRowPadding?: number,
  renderLoadingRow?: React.ReactNode,
  noDataCardSplashCard?: React.ReactNode,
) =>
  function RowComponent(rowProps: { index: number; style: any }) {
    if (data.length === 0 && !isLoading) {
      if (noDataCardSplashCard) {
        return (
          <Box
            sx={{
              display: "flex",
              height: size.height - 32,
              width: size.width,
              backgroundColor: (theme) => theme.palette.background.paper,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              margin: (theme) => theme.spacing(2, 0),
            }}
          >
            {noDataCardSplashCard}
          </Box>
        );
      }
      return <div />;
    }

    // if there is data at the index call the renderData
    if (data[rowProps.index]) {
      let styles = {
        ...rowProps.style,
        top: `${rowProps.style.top + rowProps.index * rowGap}px`,
      };

      // if we are on the last row and we must add bottom padding
      if (rowProps.index + 1 === data.length) {
        styles = {
          ...styles,
          height: styles.height + lastRowPadding,
          paddingBottom: lastRowPadding,
        };
      }

      return (
        <Box
          sx={{
            display: "flex",
          }}
          style={styles}
        >
          {renderData(data[rowProps.index])}
        </Box>
      );
    }

    if (renderLoadingRow) {
      const styles = {
        ...rowProps.style,
        top: `${rowProps.style.top + rowProps.index * rowGap}px`,
      };

      return (
        <Box sx={{ display: "flex", flexDirection: "column" }} style={styles}>
          {renderLoadingRow}
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        style={rowProps.style}
      >
        <CircularProgress size={25} />
      </Box>
    );
  };
