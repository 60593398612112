import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

export const PasswordRevealTextField = React.forwardRef(function MyComponent(
  props: TextFieldProps,
  ref,
) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };

  //  Spread the props to the underlying DOM element.
  return (
    <TextField
      {...props}
      ref={ref as React.RefObject<HTMLDivElement> | null | undefined}
      inputProps={{
        "data-private": "redact",
        type: showPassword ? "text" : "password",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            {showPassword ? (
              <IconButton edge={"end"} onClick={handleHidePassword}>
                <VisibilityOff />
              </IconButton>
            ) : (
              <IconButton edge={"end"} onClick={handleShowPassword}>
                <Visibility />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
});
