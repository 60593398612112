import React, { ChangeEvent } from "react";

import { styled } from "@mui/material/styles";

import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ConnectedIndividual } from "james/legal";
import { Company } from "james/legal/company";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import { countries } from "james/country";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
const PREFIX = "BusinessAddress";

const classes = {
  titleLayout: `${PREFIX}-titleLayout`,
  equalColumns2Gap: `${PREFIX}-equalColumns2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.titleLayout}`]: {
    height: 35,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.equalColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2),
  },
}));

interface BusinessAddressProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue: (name: string) => (value: any) => void;
  company: Company;
  businessAddressSame: boolean;
  setBusinessAddressSame: (value: boolean) => void;
  FormFieldValid: { [key: string]: string | undefined };
  setFormFieldValid: (value: { [key: string]: string | undefined }) => void;
}

export const BusinessAddress: React.FC<BusinessAddressProps> = (
  props: BusinessAddressProps,
) => {
  const {
    company,
    onChangeValue,
    businessAddressSame,
    setBusinessAddressSame,
    FormFieldValid,
    setFormFieldValid,
  } = props;

  const businessAddress = businessAddressSame
    ? company.registeredAddress
    : new Address(company.businessAddress);

  const InlineFormValidation = (field: string, address: Address) =>
    debounce(() => {
      let InvalidFormField = { ...FormFieldValid, [field]: undefined };
      if (isEqual(new Address(address), new Address())) {
        InvalidFormField = {
          ...InvalidFormField,
          businessAddressCountryCode: undefined,
          businessAddressCity: undefined,
          businessAddressAddressLine1: undefined,
        };
      }
      setFormFieldValid(InvalidFormField);
    }, 600);

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    updateState(name, event.target.value);
  };

  const autoCompleteOnUpdate =
    (name: string) =>
    (
      __: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(name, countryOption ? countryOption.value : "");
    };

  const updateState = (name: string, value: string) => {
    const keys = name.split(".") as (keyof ConnectedIndividual)[];
    const newAddress = new Address({
      ...businessAddress,
      [keys[1]]: value,
    } as Address);
    onChangeValue("businessAddress")(newAddress);
    InlineFormValidation(camelCase(name), newAddress)();
  };

  const sameAsRegisteredAddress = () => {
    if (businessAddressSame) {
      onChangeValue("businessAddress")(new Address(company.registeredAddress));
    } else {
      onChangeValue("businessAddress")(new Address(businessAddress));
    }
    setBusinessAddressSame(!businessAddressSame);
  };

  return (
    <Root>
      <div className={classes.titleLayout}>
        <Typography variant="h6" children="Business Address" />
        <FormControlLabel
          id="contactDetails-businessAddress-sameAsResidential"
          labelPlacement="start"
          control={
            <Checkbox
              color="primary"
              checked={businessAddressSame}
              onChange={sameAsRegisteredAddress}
            />
          }
          label="Copy Registered"
        />
      </div>
      {businessAddress && (
        <>
          <CountryDropdown
            id="contactDetails-businessAddress-country"
            isOptionEqualToValue={(option, value) => {
              if (value.value === "") {
                return true;
              }
              return option.value === value.value;
            }}
            disabled={businessAddressSame}
            onChange={autoCompleteOnUpdate("businessAddress.countryCode")}
            value={
              businessAddress.countryCode === ""
                ? {
                    value: "",
                    label: "",
                  }
                : {
                    value: businessAddress.countryCode,
                    label: countries.filter(
                      (c) =>
                        c.value === new Address(businessAddress).countryCode,
                    )[0].label,
                  }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Country"
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select...",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={Boolean(FormFieldValid.businessAddressCountryCode)}
                fullWidth
                helperText={FormFieldValid.businessAddressCountryCode}
              />
            )}
          />
          <TextField
            id="contactDetails-businessAddress-line1"
            margin="dense"
            disabled={businessAddressSame}
            variant="outlined"
            label="Address 1"
            fullWidth
            onChange={onChange("businessAddress.addressLine1")}
            value={businessAddress.addressLine1}
            error={Boolean(FormFieldValid.businessAddressAddressLine1)}
            helperText={FormFieldValid.businessAddressAddressLine1}
          />
          <TextField
            id="contactDetails-businessAddress-line2"
            margin="dense"
            placeholder="Optional"
            disabled={businessAddressSame}
            variant="outlined"
            label="Address 2"
            fullWidth
            onChange={onChange("businessAddress.addressLine2")}
            value={businessAddress.addressLine2}
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-businessAddress-city"
              margin="dense"
              disabled={businessAddressSame}
              variant="outlined"
              label="City"
              fullWidth
              onChange={onChange("businessAddress.city")}
              value={businessAddress.city}
              error={Boolean(FormFieldValid.businessAddressCity)}
              helperText={FormFieldValid.businessAddressCity}
            />
            <TextField
              id="contactDetails-businessAddress-suburb"
              margin="dense"
              variant="outlined"
              label="Suburb"
              placeholder="Optional"
              disabled={businessAddressSame}
              fullWidth
              onChange={onChange("businessAddress.suburb")}
              value={businessAddress.suburb}
              helperText={FormFieldValid.businessAddressSuburb}
            />
          </div>
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-businessAddress-province"
              margin="dense"
              disabled={businessAddressSame}
              variant="outlined"
              placeholder="Optional"
              label="Province"
              fullWidth
              onChange={onChange("businessAddress.province")}
              value={businessAddress.province}
              helperText={FormFieldValid.businessAddressProvince}
            />
            <TextField
              id="contactDetails-businessAddress-postalCode"
              margin="dense"
              disabled={businessAddressSame}
              variant="outlined"
              label="Postal Code"
              placeholder="Optional"
              fullWidth
              onChange={onChange("businessAddress.postalCode")}
              value={businessAddress.postalCode}
              helperText={FormFieldValid.businessAddressPostalCode}
            />
          </div>
        </>
      )}
    </Root>
  );
};
