import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";

export interface GenerateCompanyUserRegistrationTokenRequest {
  firebaseUserIDToken: string;
}

export interface GenerateCompanyUserRegistrationTokenResponse {
  token: string;
}

export const CompanyUserRegistrationControllerServiceProviderName =
  "user-CompanyClientUserRegistrationController";

export const CompanyClientUserRegistrationController = {
  async GenerateCompanyClientUserRegistrationToken(
    request: GenerateCompanyUserRegistrationTokenRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateCompanyUserRegistrationTokenResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshPublicURL"),
        method: `${CompanyUserRegistrationControllerServiceProviderName}.GenerateCompanyClientUserRegistrationToken`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { token: response.token };
  },
};
