import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { CompanyRegStep } from "views/KYB/CompanyRegistration";
import {
  IndustryClassification,
  IndustryClassificationOptions,
} from "james/legal/IndustryClassification";
import { FormFieldsValidation, FormValidator } from "./Validation";
import { LegalForm } from "james/legal";
import { AllStockExchanges } from "james/financial/StockExchange";
import { FormState } from "views/KYB/useFormState";
import { FieldValidations } from "common/validation";

interface CompanyDetailsProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  onChange: (name: string) => (value: unknown) => void;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
  formState: FormState;
  fieldValidations: FieldValidations;
  nextPressed: boolean;
  setNextPressed: Dispatch<SetStateAction<boolean>>;
  savingInProgress: boolean;
}

export const CompanyDetails = (props: CompanyDetailsProps) => {
  const { formState, invalidStep, setNextPressed, onChange, onNext, inValid } =
    props;
  const [InvalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [ranValidation, setRanValidation] = useState(false);

  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...InvalidFormFields };
      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        delete InvalidFormField[field];
      }

      setInvalidFormFields(InvalidFormField);
    }, 600);

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    InlineFormValidation(name, event.target.value)();
    onChange(name)(event.target.value);
  };

  useEffect(() => {
    if (!props.savingInProgress && props.nextPressed) {
      if (
        FormFieldsValidation(
          InvalidFormFields,
          formState.company,
          setInvalidFormFields,
        )
      ) {
        onNext(CompanyRegStep.contactDetails)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.companyDetails, false);
      } else {
        onNext(CompanyRegStep.contactDetails)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.companyDetails, true);
      }
    }
  }, [props.savingInProgress, props.nextPressed]);

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(
      InvalidFormFields,
      formState.company,
      setInvalidFormFields,
    );
  }

  return (
    <div>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography
            variant="h4"
            id="companyReg-companyDetails-title"
            color="secondary"
          >
            Company Details
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography
          style={{ marginTop: "8px", marginBottom: "32px" }}
          variant="subtitle2"
        >
          Add your company details below.
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 50%)",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gap: 2,
            }}
          >
            <TextField
              id="companyDetails-countryOfIncorporation"
              margin="dense"
              variant="outlined"
              select
              fullWidth
              label="Country of Incorporation"
              value={props.formState.company.countryOfIncorporation}
              onChange={onUpdate("countryOfIncorporation")}
              helperText={InvalidFormFields.countryOfIncorporation}
              error={Boolean(InvalidFormFields.countryOfIncorporation)}
            >
              <MenuItem value="ZA">SOUTH AFRICA</MenuItem>
            </TextField>
            {props.formState.company.formOfIncorporation !==
              LegalForm.PartnershipLegalForm && (
              <TextField
                id="companyDetails-taxReferenceNumber"
                margin="dense"
                fullWidth
                label="Tax Reference Number"
                variant="outlined"
                value={props.formState.company.taxReferenceNumber}
                error={Boolean(InvalidFormFields.taxReferenceNumber)}
                helperText={InvalidFormFields.taxReferenceNumber}
                onChange={onUpdate("taxReferenceNumber")}
                placeholder="0000000000"
              />
            )}
            {[
              LegalForm.CloseCorporationLegalForm,
              LegalForm.SouthAfricanCompanyLegalForm,
              LegalForm.ForeignCompanyLegalForm,
              LegalForm.ListedCompanyLegalForm,
            ].includes(
              props.formState.company.formOfIncorporation as LegalForm,
            ) && (
              <TextField
                id="companyDetails-vatRegistrationNumber"
                margin="dense"
                fullWidth
                variant="outlined"
                value={props.formState.company.vatRegistrationNumber}
                label="VAT Registration Number"
                error={Boolean(InvalidFormFields.vatRegistrationNumber)}
                helperText={InvalidFormFields.vatRegistrationNumber}
                onChange={onUpdate("vatRegistrationNumber")}
                placeholder="0000000000"
              />
            )}
            {[
              LegalForm.ForeignCompanyLegalForm,
              LegalForm.ListedCompanyLegalForm,
            ].includes(
              props.formState.company.formOfIncorporation as LegalForm,
            ) && (
              <Autocomplete
                id="companyDetails-industryClassification"
                options={IndustryClassificationOptions}
                getOptionLabel={(option) =>
                  !isEqual(
                    new IndustryClassification(option),
                    new IndustryClassification(),
                  )
                    ? `${option.industryName} - ${option.subIndustryName}`
                    : ""
                }
                isOptionEqualToValue={(option, value) => {
                  if (
                    (value.industryName as IndustryClassification | "") === ""
                  ) {
                    return true;
                  }
                  return option.industryName === value.industryName;
                }}
                value={props.formState.company.industryClassification}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Industry Classification"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    error={Boolean(InvalidFormFields.industryClassification)}
                    fullWidth
                    helperText={InvalidFormFields.industryClassification}
                  />
                )}
              />
            )}
          </Box>
          {props.formState.company.formOfIncorporation ===
            LegalForm.ListedCompanyLegalForm && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="companyDetails-listedExchange"
                options={AllStockExchanges.sort((a, b) =>
                  a.name.localeCompare(b.name),
                )}
                renderOption={(props, option) => (
                  <Typography
                    {...props}
                    key={option.name}
                    children={`${option.code}: ${option.name}`}
                  />
                )}
                filterOptions={(options, { inputValue, getOptionLabel }) => {
                  const inputValues = inputValue
                    .trim()
                    .toLowerCase()
                    .split(" ");

                  return options.filter((option) => {
                    const label = getOptionLabel(option).toLowerCase();

                    // Check if all input values are present in either the name or code
                    // Return the option if it matches
                    return inputValues.every(
                      (inputValue) =>
                        label.includes(inputValue) ||
                        option.code.toLowerCase().includes(inputValue),
                    );
                  });
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Listed Exchange"
                    margin="dense"
                    variant="outlined"
                  />
                )}
              />
              <TextField
                id="companyDetails-listingReference"
                label="Listing Reference"
                onChange={onUpdate("listingReference")}
                margin="dense"
                fullWidth
                variant="outlined"
                value={props.formState.company.listingReference}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
