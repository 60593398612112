import { Asset } from "stellar-sdk";
import { UnexpectedAssetType } from "./errors";
import { Token } from "pkgTemp/ledger";
import { StellarNetwork } from "./Network";

export const NativeAssetTokenCode = "XLM";

export function tokenToAsset(token: Token): Asset {
  if (token.code === NativeAssetTokenCode) {
    return new Asset(NativeAssetTokenCode);
  }
  return new Asset(token.code, token.issuer);
}

export function primitiveAssetToToken(
  {
    asset_code,
    asset_issuer,
    asset_type,
  }: { asset_code: string; asset_issuer: string; asset_type: string },
  network: StellarNetwork,
): Token {
  switch (asset_type) {
    case "credit_alphanum4":
    case "credit_alphanum12":
      return new Token({
        code: asset_code,
        issuer: asset_issuer,
        network,
      });

    case "native":
      return new Token({
        code: NativeAssetTokenCode,
        issuer: network,
        network,
      });

    default:
      throw new UnexpectedAssetType(asset_type);
  }
}

export function tokenFromAssetString(
  assetString: string,
  network: StellarNetwork,
): Token {
  const assetSlice = assetString.split(":");

  if (assetSlice.length === 2) {
    return new Token({
      code: assetSlice[0],
      issuer: assetSlice[1],
      network: network,
    });
  }

  return new Token({
    network: network,
    issuer: network,
    code: NativeAssetTokenCode,
  });
}
