import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import { AccountOperator, TransferResponse } from "james/stellar";
import { useNotificationContext } from "context/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { LedgerIDIdentifier, NumberIdentifier } from "james/search/identifier";

import { TransferAccountType } from "../TransferMainDialog";
import { useApplicationContext } from "context/Application/Application";
import { TransactionTypes } from "types/gtm";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { useErrorContext } from "context/Error";

const PREFIX = "TransferConfirmationDialog";

const classes = {
  boldText: `${PREFIX}-boldText`,
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  amountFormField: `${PREFIX}-amountFormField`,
  warningColor: `${PREFIX}-warningColor`,
  warningColorLightVariant: `${PREFIX}-warningColorLightVariant`,
  typographyWordBreak: `${PREFIX}-typographyWordBreak`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.dialogTitleContent}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.amountFormField}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.warningColor}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.warningColorLightVariant}`]: {
    color: alpha(theme.palette.warning.main, 0.5),
  },

  [`& .${classes.typographyWordBreak}`]: {
    wordBreak: "break-word",
  },
}));

interface TransferConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onTransferSubmissionComplete: () => void;
  transferAmount: LedgerAmount;
  recipientAccountType: string;
  recipientAccountID: string;
  fromAccountID: string;
  reference: string;
  transferFeeSection: React.ReactNode;
  hideTransferFee: boolean;
}

export function TransferConfirmationDialog(
  props: TransferConfirmationDialogProps,
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { registerNotificationCallback } = useNotificationContext();
  const [disableTransferButton, setDisableTransferButton] = useState(false);
  const { pushTransferComplete } = useGTMTriggersPusher();
  const handleTransfer = useCallback(async () => {
    // The transfer button is being disabled here to prevent the scenario where
    // the transfer confirmation dialog is taking too long to close. Resulting
    // in the user being able to invoke handleTransferAndSubmit twice.
    setDisableTransferButton(true);

    // perform transfer with consistency
    // close the card
    props.onTransferSubmissionComplete();

    // notify the user a transfer is in progress
    enqueueSnackbar("Transfer in progress", { variant: "info" });

    // perform the transfer
    let transferResponse: TransferResponse;
    try {
      transferResponse = await AccountOperator.Transfer({
        context: authContext,
        fromAccountID: props.fromAccountID,
        toAccountIdentifier:
          props.recipientAccountType === TransferAccountType.StellarAccountType
            ? LedgerIDIdentifier(props.recipientAccountID)
            : NumberIdentifier(props.recipientAccountID),
        amount: props.transferAmount,
        reference: props.reference,
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
      return;
    }

    try {
      // register callback to fire once the transfer has been submitted
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: transferResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === transferResponse.transactionID
          ) {
            enqueueSnackbar("Success! Transfer Complete", {
              variant: "success",
            });
            pushTransferComplete({
              account_no: props.fromAccountID,
              account_transaction_type: TransactionTypes.Transfer,
              account_transaction_amount: props.transferAmount.value.toString(),
            });
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === transferResponse.transactionID
          ) {
            enqueueSnackbar("Error! Transfer Submission Failed", {
              variant: "error",
            });
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === transferResponse.transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the transfer and its status is being investigated",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error(
        "error registring for transfer transaction notifications",
        e,
      );
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Transfer Transaction - Please Check Your Account and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  }, [props, authContext, enqueueSnackbar, registerNotificationCallback]);

  return (
    <StyledDialog
      open={props.open}
      PaperProps={{
        sx: [
          isMobile && {
            "&.MuiDialog-paper": {
              position: "absolute",
              bottom: 0,
              width: "100%",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              margin: 0,
            },
          },
        ],
      }}
    >
      <DialogTitle
        sx={[
          {
            height: 52,
            padding: {
              sm: theme.spacing(0, 3),
              xs: theme.spacing(0, 2, 0, 3),
            },
          },
        ]}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className={classes.dialogTitleContent}>
            <Typography variant="h5">Transfer Confirmation</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={props.onClose}
              disabled={false}
              id="transferConfirmation-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          width: {
            sm: 392,
            xs: "100%",
          },
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "repeat(3,auto)",
            gridRowGap: theme.spacing(2),
            padding: {
              sm: theme.spacing(4),
              xs: theme.spacing(3),
            },
          }}
        >
          <Typography variant="h6">
            Please confirm your transfer request.
          </Typography>
          <div>
            <Typography color="textSecondary" variant="caption">
              Recipient Account Type
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              {props.recipientAccountType}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Recipient Account ID
            </Typography>
            <Typography
              className={classes.typographyWordBreak}
              color="textSecondary"
              variant="subtitle1"
            >
              {props.recipientAccountID}
            </Typography>
          </div>
          {props.reference && (
            <div>
              <Typography color="textSecondary" variant="caption">
                Reference
              </Typography>
              <Typography color="textSecondary" variant="subtitle1">
                {props.reference}
              </Typography>
            </div>
          )}
        </Box>
        {!props.hideTransferFee && (
          <Box
            sx={{
              padding: {
                sm: theme.spacing(0, 4, 3, 4),
                xs: theme.spacing(0, 3, 3, 3),
              },
            }}
          >
            {/* Render Transfer fee component */}
            {props.transferFeeSection}
          </Box>
        )}
        <Box
          sx={{
            padding: {
              sm: theme.spacing(4),
              xs: theme.spacing(3, 3, 5, 3),
            },
            backgroundColor: theme.palette.custom.midnight,
            display: "flex",
            flexDirection: "column",
            alignItems: {
              sm: "flex-start",
              xs: "center",
            },
          }}
        >
          {!isMobile && (
            <Typography color="textSecondary" variant="subtitle1">
              Amount
            </Typography>
          )}
          <Amount
            valueTypographyProps={{
              variant: "h4",
              sx: {
                fontWeight: "bold",
                color: theme.palette.warning.main,
              },
            }}
            codeTypographyProps={{
              variant: "h4",
              sx: {
                color: theme.palette.custom.yellow,
                fontWeight: "bold",
              },
            }}
            amount={props.transferAmount}
          />
          <Button
            id="transferConfirmationDialog-confirm-button"
            sx={{
              marginTop: theme.spacing(3),
              height: {
                sm: 36,
                xs: 48,
              },
            }}
            variant="contained"
            disabled={disableTransferButton}
            fullWidth
            color="primary"
            onClick={handleTransfer}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
}
