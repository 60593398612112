import { Box, Typography } from "@mui/material";
import { ClientManagerWrite } from "james/client";
import { IDIdentifier } from "james/search/identifier";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { InfoProfilePicture, WebLinks } from "./components";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

const firstSteps = {
  profilePictureAndInfo: 0,
  webLinks: 1,
  loadingScreen: 2,
};

export function CompanyFirstSteps() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext, loginClaims, refreshLoginClaims } =
    useApplicationContext();
  const [firstStepsStep, setFirstStepsStep] = useState(
    firstSteps.profilePictureAndInfo,
  );
  const [companyDescription, setCompanyDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleCompleteCompanyProfile = async (webLinks: {
    companyWebsite: string;
    linkedIn: string;
    twitter: string;
    otherSite: string;
  }) => {
    // FIXME: if the following api call fails then the UI breaks with no recovery
    setFirstStepsStep(firstSteps.loadingScreen);
    try {
      await ClientManagerWrite.CompleteClientProfile({
        context: authContext,
        clientIdentifier: IDIdentifier(loginClaims.clientID),
        companyDescription,
        companyWebsite: webLinks.companyWebsite,
        companyLinkedIn: webLinks.linkedIn,
        companyTwitter: webLinks.twitter,
        companyOtherSite: webLinks.otherSite,
      });
      await refreshLoginClaims();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Completing Profile: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      console.error(`error completing profile: ${e}`);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        p: {
          sm: "80px 120px 0 120px",
          md: "80px 240px 0 240px",
          xs: "24px 48px",
        },
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        gridRowGap: theme.spacing(1),
      })}
      id="companyFirstStepsViewRoot"
    >
      <Typography variant="h1">Hello {loginClaims.firstName}</Typography>
      <div>
        {(() => {
          switch (firstStepsStep) {
            case firstSteps.profilePictureAndInfo:
              return (
                <InfoProfilePicture
                  companyDescription={companyDescription}
                  onNext={(newCompanyDescription: string) => {
                    setCompanyDescription(newCompanyDescription);
                    setFirstStepsStep(firstSteps.webLinks);
                  }}
                />
              );
            case firstSteps.webLinks:
              return (
                <WebLinks
                  onNext={(webLinks: {
                    companyWebsite: string;
                    linkedIn: string;
                    twitter: string;
                    otherSite: string;
                  }) => handleCompleteCompanyProfile(webLinks)}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </Box>
  );
}
