import React, { useEffect, useRef, useState } from "react";
import { StellarAssetPublicInfoDataSheetSection } from "james/marketData";
import {
  Box,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TokenInformation, useStellarContext } from "context/Stellar/Stellar";
import { useIsMounted } from "hooks";
import { Info as InfoIcon } from "@mui/icons-material";
import { formatTextNum } from "utilities/number";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { AssetType } from "james/views/marketListingView/Model";

export type Props = {
  dataSheetSection: StellarAssetPublicInfoDataSheetSection;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
};

const stablecoinAssetTypes: (AssetType | "")[] = [
  AssetType.RightsToACryptoCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
];

export const StellarAssetPublicInfoDataSheetSectionRenderer = (
  props: Props,
) => {
  const isMounted = useIsMounted();
  const { stellarContextGetTokenInformation } = useStellarContext();
  const [tokenInfo, setTokenInfo] = useState<TokenInformation | undefined>(
    undefined,
  );
  const { current: isStablecoin } = useRef(
    stablecoinAssetTypes.includes(props.marketListingViewModel.assetType),
  );
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      try {
        const retrievedTokenInfo = await stellarContextGetTokenInformation(
          props.dataSheetSection.assetToken,
        );
        if (isMounted()) {
          setTokenInfo(retrievedTokenInfo);
        }
      } catch (e) {
        console.error(`error getting token information: ${e}`);
      }
    })();
  }, [props.dataSheetSection.assetToken]);

  if (tokenInfo) {
    return (
      <Box
        sx={{
          padding: theme.spacing(3, 3, 3, 0),
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(2, 1fr)",
            sm: "40% 50%",
            xs: "1fr",
          },
          rowGap: {
            sm: theme.spacing(3),
            xs: theme.spacing(2),
          },
          columnGap: {
            sm: 2,
          },
        }}
      >
        <Typography
          variant={"subtitle2"}
          sx={{
            color: "text.disabled",
            gridColumn: {
              xs: "unset",
              sm: "1/3",
            },
          }}
          children={tokenInfo.description}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(1),
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                color={"textSecondary"}
                children={"Stellar Expert Rating"}
              />
              <Tooltip
                title={
                  "Composite rating based on asset age, established trustlines, weekly trading volume, payments and trades count."
                }
                arrow
                placement={"top"}
              >
                <InfoIcon
                  sx={{
                    color: "secondary.light",
                    cursor: "pointer",
                    fontSize: "15px",
                  }}
                />
              </Tooltip>
            </Box>
            <Typography
              variant={"body1"}
              children={
                tokenInfo.rating
                  ? tokenInfo.rating
                  : "Information not available"
              }
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(1),
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                color={"textSecondary"}
                children={"First Transaction"}
              />
              <Tooltip
                title={
                  "The timestamp of the first asset transaction, guideline as to age of token."
                }
                arrow
                placement={"top"}
              >
                <InfoIcon
                  sx={{
                    color: "secondary.light",
                    cursor: "pointer",
                    fontSize: "15px",
                  }}
                />
              </Tooltip>
            </Box>
            <Typography
              variant={"body1"}
              children={tokenInfo.firstTransaction.format(
                "YYYY-MM-DD HH:mm:ss",
              )}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(1),
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                color={"textSecondary"}
                children={"Total Trades Count"}
              />
              <Tooltip
                title={
                  "Total count of all trades with the asset recorded on the ledger."
                }
                placement={"top"}
              >
                <InfoIcon
                  sx={{
                    color: "secondary.light",
                    cursor: "pointer",
                    fontSize: "15px",
                  }}
                />
              </Tooltip>
            </Box>
            <Typography
              variant={"body1"}
              children={
                tokenInfo.totalTrades
                  ? formatTextNum(tokenInfo.totalTrades)
                  : "Information not available"
              }
            />
          </Box>
          {tokenInfo.conditions && (
            <Box>
              <Typography
                variant={"caption"}
                color={"textSecondary"}
                children={"Issuing Conditions"}
              />
              <Typography variant={"body1"} children={tokenInfo.conditions} />
            </Box>
          )}
        </Box>
        {isStablecoin && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Box>
              <Typography
                variant={"caption"}
                color={"textSecondary"}
                children={"Redemption Instructions"}
              />
              <Typography
                variant={"body1"}
                children={
                  tokenInfo.redemptionInstructions
                    ? tokenInfo.redemptionInstructions
                    : "Information not available"
                }
              />
            </Box>
            <Box>
              <Typography
                variant={"caption"}
                color={"textSecondary"}
                children={"Attestation Of Reserve"}
              />
              <Typography
                variant={"body1"}
                children={
                  tokenInfo.attestationOfReserve ? (
                    <Link
                      color={"secondary"}
                      underline={"hover"}
                      children={tokenInfo.attestationOfReserve}
                      onClick={() =>
                        window.open(
                          tokenInfo.attestationOfReserve.startsWith("http")
                            ? tokenInfo.attestationOfReserve
                            : `http://${tokenInfo.attestationOfReserve}`,
                          "_blank",
                        )
                      }
                    />
                  ) : (
                    "Information not available"
                  )
                }
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          padding: theme.spacing(3, 3, 3, 0),
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(2, 1fr)",
            xs: "1fr",
          },
          rowGap: {
            sm: theme.spacing(3),
            xs: theme.spacing(2),
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            gridColumn: {
              xs: "unset",
              sm: "1/3",
            },
          }}
        >
          <Skeleton width={"100%"} height={"16px"} />
          <Skeleton width={"80%"} height={"16px"} />
        </Box>
        <Box>
          <Skeleton width={"100px"} height={"8px"} />
          <Skeleton width={"150px"} height={"17px"} />
        </Box>
        <Box>
          <Skeleton width={"100px"} height={"8px"} />
          <Skeleton width={"150px"} height={"17px"} />
        </Box>
        <Box>
          <Skeleton width={"100px"} height={"8px"} />
          <Skeleton width={"150px"} height={"17px"} />
        </Box>
        <Box>
          <Skeleton width={"100px"} height={"8px"} />
          <Skeleton width={"150px"} height={"17px"} />
        </Box>
      </Box>
    );
  }
};
