import React, {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useState,
} from "react";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Typography, Link, Box } from "@mui/material";
import { ClientKYCStatus } from "james/client";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { ClientKind } from "james/client/Client";

interface KYBStatusBannerProps {
  setHideBanner: Dispatch<SetStateAction<boolean>>;
}

export function KYBStatusBanner({ setHideBanner }: KYBStatusBannerProps) {
  const { myClient, myClientRetrievalErr } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [
    displayKYBOutstandingWithoutReasonSnackbar,
    setDisplayKYBOutstandingWithoutReasonSnackbar,
  ] = useState(false);
  const [
    displayKYBOutstandingWithReasonSnackbar,
    setDisplayKYBOutstandingWithReasonSnackbar,
  ] = useState(false);
  const [
    displayKYBVerificationInProgressSnackbar,
    setKYBVerificationInProgressSnackbar,
  ] = useState(false);
  const navigate = useNavigate();

  // determine which KYB snackbars should be shown on log in
  useLayoutEffect(() => {
    if (myClientRetrievalErr && !myClient) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      return;
    }

    if (!myClient) {
      return;
    }

    setDisplayKYBOutstandingWithoutReasonSnackbar(
      myClient.kycStatus === ClientKYCStatus.Outstanding &&
        myClient.kycStatusReason === "",
    );
    setDisplayKYBOutstandingWithReasonSnackbar(
      myClient.kycStatus === ClientKYCStatus.Outstanding &&
        myClient.kycStatusReason !== "",
    );
    setKYBVerificationInProgressSnackbar(
      myClient.kycStatus === ClientKYCStatus.VerificationInProgressStatus,
    );
  }, [myClient]);

  return (
    <>
      {displayKYBOutstandingWithoutReasonSnackbar && (
        <Box
          id={"KYBStatusBanner-KYBOutstandingWithoutReason-Div"}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.warning.dark,
            height: theme.spacing(6),
            alignItems: "center",
            justifyContent: "space-between",
            width: window.innerWidth,
            px: 2,
          })}
        >
          <Box
            sx={() => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            })}
          >
            <WarningIcon />
            <Typography
              sx={(theme) => ({
                paddingLeft: theme.spacing(1.5),
                fontSize: "12px",
              })}
              variant="body2"
            >
              Company verification required.{" "}
              {myClient &&
                myClient.clientKind !== ClientKind.IndividualType && (
                  <Link
                    id={
                      "KYBStatusBanner-KYBOutstandingWithoutReason-VerifyNow-Link"
                    }
                    sx={(theme) => ({
                      color: theme.palette.text.primary,
                      textDecoration: "underline",
                      textDecorationColor: theme.palette.text.primary,
                    })}
                    onClick={() => {
                      navigate("/kyb");
                    }}
                    fontSize="12px"
                    component="button"
                    underline="always"
                  >
                    Verify now
                  </Link>
                )}
            </Typography>
          </Box>
          <IconButton
            size="small"
            id={"KYBStatusBanner-KYBOutstandingWithoutReason-Close-Button"}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
            })}
            onClick={() => {
              displayKYBOutstandingWithoutReasonSnackbar
                ? setDisplayKYBOutstandingWithoutReasonSnackbar(false)
                : setDisplayKYBOutstandingWithReasonSnackbar(false);
              setHideBanner(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {displayKYBOutstandingWithReasonSnackbar && (
        <Box
          id={"KYBStatusBanner-KYBOutstandingWithReason-Div"}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.warning.dark,
            height: theme.spacing(6),
            alignItems: "center",
            justifyContent: "space-between",
            width: window.innerWidth,
            pr: 2,
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: theme.spacing(2),
            })}
          >
            <WarningIcon />
            <Typography
              sx={(theme) => ({
                paddingLeft: theme.spacing(1.5),
                fontSize: "12px",
              })}
              variant="body2"
            >
              Company verification unsuccessful.{" "}
              <Link
                id={"KYBStatusBanner-KYBOutstandingWithReason-Review-Link"}
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                  textDecorationColor: theme.palette.text.primary,
                })}
                onClick={() => {
                  navigate("/kyb");
                }}
                fontSize="12px"
                component="button"
                underline="always"
              >
                Review
              </Link>
            </Typography>
          </Box>
          <IconButton
            size="small"
            id={"KYBStatusBanner-KYBOutstandingWithReason-Close-Button"}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
            })}
            onClick={() => {
              displayKYBOutstandingWithReasonSnackbar
                ? setDisplayKYBOutstandingWithReasonSnackbar(false)
                : setDisplayKYBOutstandingWithReasonSnackbar(false);
              setHideBanner(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {displayKYBVerificationInProgressSnackbar && (
        <Box
          id={"KYBStatusBanner-KYBVerificationInProgress-Div"}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.info.dark,
            height: theme.spacing(6),
            alignItems: "center",
            justifyContent: "space-between",
            width: window.innerWidth,
            pr: 2,
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: theme.spacing(2),
            })}
          >
            <InfoIcon />
            <Typography
              sx={(theme) => ({
                paddingLeft: theme.spacing(1.5),
                fontSize: "12px",
              })}
              variant="body2"
            >
              Your information is being verified
            </Typography>
          </Box>
          <IconButton
            size="small"
            id={"KYBStatusBanner-KYBVerificationInProgress-Close-Button"}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
            })}
            onClick={() => {
              displayKYBVerificationInProgressSnackbar
                ? setKYBVerificationInProgressSnackbar(false)
                : setKYBVerificationInProgressSnackbar(false);
              setHideBanner(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}
