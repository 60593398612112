import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { AfterEffect, AfterEffectState } from "./AfterEffect";

export interface UNSAFESetAfterEffectIDStateRequest {
  context: Context;
  afterEffectID: string;
  state: AfterEffectState;
  lastActionAnnotation: string;
}

export interface UNSAFESetAfterEffectIDStateResponse {
  afterEffect: AfterEffect;
}

export interface AfterEffectStateControllerServiceProvider {
  serviceProvider: string;

  UNSAFESetAfterEffectIDState(
    request: UNSAFESetAfterEffectIDStateRequest,
  ): Promise<UNSAFESetAfterEffectIDStateResponse>;
}

const AfterEffectStateControllerServiceProviderName =
  "consistency-AfterEffectStateController";

export const AfterEffectStateController: AfterEffectStateControllerServiceProvider =
  {
    serviceProvider: AfterEffectStateControllerServiceProviderName,
    async UNSAFESetAfterEffectIDState(
      request: UNSAFESetAfterEffectIDStateRequest,
    ): Promise<UNSAFESetAfterEffectIDStateResponse> {
      const response = await jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${AfterEffectStateController.serviceProvider}.UNSAFESetAfterEffectIDState`,
        request,
      });
      return { afterEffect: new AfterEffect(response.afterEffect) };
    },
  };
