import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Context } from "../security";
import { Amount } from "../ledger/Amount";
import { IndicativePrice, IndicativePriceType } from "./IndicativePrice";

export interface RecordIndicativePriceRequest {
  context: Context;
  assetID: string;
  buyPrice: Amount;
  sellPrice: Amount;
  timeOfPrice: string;
  type: IndicativePriceType;
}

export type RecordIndicativePriceRequestField =
  keyof RecordIndicativePriceRequest;
export type RecordIndicativePriceRequestValue<
  T extends RecordIndicativePriceRequestField,
> = RecordIndicativePriceRequest[T];

export interface RecordIndicativePriceResponse {
  indicativePrice: IndicativePrice;
}

export const IndicativePriceRecorderServiceProviderName =
  "market-IndicativePriceRecorder";

export const IndicativePriceRecorder = {
  async RecordIndicativePrice(
    request: RecordIndicativePriceRequest,
    additionalHeaders?: Headers,
  ): Promise<RecordIndicativePriceResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${IndicativePriceRecorderServiceProviderName}.RecordIndicativePrice`,
      request,
    });

    return { indicativePrice: new IndicativePrice(response.indicativePrice) };
  },
};
