import { Context } from "james/security";
import { Company } from "james/legal/company";
import { Client } from "james/client";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";

export interface RegisterCompanyClientRequest {
  context: Context;
  company: Company;
  administratorFirstName: string;
  administratorLastName: string;
  administratorEmail: string;
}

export interface RegisterCompanyClientResponse {
  client: Client;
}

export const CompanyClientRegistrar = {
  serviceProvider: "onboarding-CompanyClientRegistrar",
  async RegisterCompanyClient(
    request: RegisterCompanyClientRequest,
    additionalHeaders?: Headers,
  ): Promise<RegisterCompanyClientResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${CompanyClientRegistrar.serviceProvider}.RegisterCompanyClient`,
        request,
      },
      {
        additionalHeaders,
      },
    );
  },
};
