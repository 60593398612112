import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FaceOutlined as FaceIcon, Close } from "@mui/icons-material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { DateField, TextNumField } from "components/FormFields";
import { TouchedFields, ValidationResult } from "common/validation";
import { DateFieldHlpTxt, NumFieldHlpTxt } from "validationHelperText";
import {
  IndicativePriceRecorder,
  RecordIndicativePriceRequest,
} from "james/market/IndicativePriceRecorder";
import { IndicativePriceRepository } from "james/market";
import {
  IndicativePrice,
  IndicativePriceType,
} from "james/market/IndicativePrice";
import { TextExactCriterion, TextListCriterion } from "james/search/criterion";
import { NewSorting, Query } from "james/search/query";
import { SearchIndicativePricesResponse } from "james/market/IndicativePriceRepository";
import { CurrencyCollection } from "james/financial/CurrencyCollection";
import { IDIdentifier, TokenIdentifier } from "james/search/identifier";
import { CurrencyStablecoin } from "james/financial";
import { Currency } from "james/financial/Currency";
import InfoIcon from "@mui/icons-material/Info";
import cx from "classnames";
import { CurrencyStablecoinRepository } from "james/financial/CurrencyStablecoinRepository";
import { TextField } from "components/FormFields/TextField";
import { dateIsValid } from "utilities/date/dateIsValid";
import { PriceHistoryTable } from "./components/PriceHistoryTable";
import BigNumber from "bignumber.js";
import { useApplicationContext } from "context/Application/Application";
import { TranslatedError, useErrorContext } from "context/Error";
import { Token } from "james/ledger";
import {
  Model as LedgerTokenViewModel,
  Reader as LedgerTokenViewReader,
} from "james/views/ledgerTokenView";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { TokenIconViewUpload } from "components/Ledger/Token";

const PREFIX = "CapturePriceDialog";

const classes = {
  titleRoot: `${PREFIX}-titleRoot`,
  contentRoot: `${PREFIX}-contentRoot`,
  captureSection: `${PREFIX}-captureSection`,
  captureForm: `${PREFIX}-captureForm`,
  typeLayout: `${PREFIX}-typeLayout`,
  infoInfoIcon: `${PREFIX}-infoInfoIcon`,
  infoTooltipText: `${PREFIX}-infoTooltipText`,
  infoTooltipTextSpacing: `${PREFIX}-infoTooltipTextSpacing`,
  tokenAmountCode: `${PREFIX}-tokenAmountCode`,
  dateField: `${PREFIX}-dateField`,
  saveButton: `${PREFIX}-saveButton`,
  priceHistory: `${PREFIX}-priceHistory`,
  captureFormTitle: `${PREFIX}-captureFormTitle`,
  grey: `${PREFIX}-grey`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.titleRoot}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto 48px",
    gap: theme.spacing(1),
    alignItems: "center",
    backgroundColor: theme.palette.custom.midnight,
    borderBottom: "none",
  },

  [`& .${classes.contentRoot}`]: {
    padding: theme.spacing(0),
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },

  [`& .${classes.captureSection}`]: {
    padding: theme.spacing(5),
    display: "grid",
    justifyItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },

  [`& .${classes.captureForm}`]: {
    margin: theme.spacing(4, 0),
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
    },
  },

  [`& .${classes.typeLayout}`]: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.infoInfoIcon}`]: {
    color: theme.palette.secondary.light,
    cursor: "pointer",
  },

  [`& .${classes.infoTooltipText}`]: {
    display: "block",
  },

  [`& .${classes.infoTooltipTextSpacing}`]: {
    marginBottom: theme.spacing(2.5),
  },

  [`& .${classes.tokenAmountCode}`]: {
    marginRight: 8,
    color: theme.palette.text.tertiary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.dateField}`]: {
    gridColumn: "1 / span 2",
  },

  [`& .${classes.saveButton}`]: {
    width: "120px",
  },

  [`& .${classes.priceHistory}`]: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },

  [`& .${classes.captureFormTitle}`]: {
    fontWeight: 600,
    justifySelf: "start",
    [theme.breakpoints.down("md")]: {
      justifySelf: "center",
    },
  },

  [`& .${classes.grey}`]: {
    color: theme.palette.text.secondary,
  },
}));

interface CapturePriceProps {
  assetID: string;
  valuationToken?: Token;
  token: Token;
  assetIssueDate: string;
  assetName: string;
  handleClose: () => void;
  open: boolean;
}

function performValidation(
  touchedFields: TouchedFields,
  recordPriceRequest: RecordIndicativePriceRequest,
  assetIssueDate: string,
  ignoreTouchedFields: boolean,
) {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  // determine the validity of dates
  const timeOfPriceValid = dateIsValid(recordPriceRequest.timeOfPrice);
  const assetIssueDateValid = dateIsValid(assetIssueDate);

  // if all of the dates are valid
  if (timeOfPriceValid && assetIssueDateValid) {
    // then date validation can be done

    // parse necessary date times
    const dayjsTimeOfPrice = dayjs(recordPriceRequest.timeOfPrice);
    const dayjsAssetIssueDate = dayjs(assetIssueDate);

    //  if the timeOfPrice is in the future
    if (dayjsTimeOfPrice.isAfter(dayjs())) {
      // then the validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.timeOfPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.timeOfPrice =
          DateFieldHlpTxt.NotInTheFuture;
      }
    } else if (dayjsTimeOfPrice.isBefore(dayjsAssetIssueDate, "day")) {
      // if the time of price is before the issue date of the asset

      // then the validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.timeOfPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.timeOfPrice =
          "Cannot be before issue date";
      }
    }
  } else {
    // otherwise 1 or more are not valid - no further date validation can be done

    // show errors on fields with invalid dates

    //  if the timeOfPrice is not valid
    if (!timeOfPriceValid) {
      // then the validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.timeOfPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.timeOfPrice = "Must be a valid date";
      }
    }

    //  if the assetIssueDateValid is not valid
    if (!assetIssueDateValid) {
      // then the validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.assetIssueDate) {
        // then an error message should be shown on it
        validationResult.fieldValidations.assetIssueDate =
          "Must be a valid date";
      }
    }
  }

  // if the buyPrice is not set (minimum check)
  if (recordPriceRequest.buyPrice.value.lte(new BigNumber("0"))) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.buyPrice) {
      // then an error message should be shown on it
      validationResult.fieldValidations.buyPrice =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (recordPriceRequest.buyPrice.value.gt(new BigNumber("10000000"))) {
    // if buy price greater than arbitrary 10,000,000 max

    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.buyPrice) {
      // then an error message should be shown on it
      validationResult.fieldValidations.buyPrice =
        "Cannot be more than 10,000,000";
    }
  }

  // OR if the sellPrice is not set
  if (recordPriceRequest.sellPrice.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.sellPrice) {
      // then an error message should be shown on it
      validationResult.fieldValidations.sellPrice =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (recordPriceRequest.sellPrice.value.gt(new BigNumber("10000000"))) {
    // if sell price greater than arbitrary 10,000,000 max

    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.sellPrice) {
      // then an error message should be shown on it
      validationResult.fieldValidations.sellPrice =
        "Cannot be more than 10,000,000";
    }
  }

  // OR if the sellPrice is higher than the buy price
  if (
    recordPriceRequest.sellPrice.value.gt(recordPriceRequest.buyPrice.value)
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if buy price have been touched
    if (ignoreTouchedFields || touchedFields.buyPrice) {
      // then error messages should be shown on it
      validationResult.fieldValidations.buyPrice =
        "Must be greater than sell price";
    }

    // and if sell price have been touched
    if (ignoreTouchedFields || touchedFields.sellPrice) {
      // then error messages should be shown on it
      validationResult.fieldValidations.sellPrice =
        "Must be less than buy price";
    }
  }

  return validationResult;
}

export function CapturePriceDialog(props: CapturePriceProps) {
  const { authContext } = useApplicationContext();
  const theme = useTheme();

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();
  const [recordPriceRequest, setRecordPriceRequest] =
    useState<RecordIndicativePriceRequest>({
      context: authContext,
      assetID: props.assetID,
      // set again later when valudation token view model retrieved
      buyPrice: new Token().newAmountOf("0"),
      sellPrice: new Token().newAmountOf("0"),
      timeOfPrice: dayjs().format(),
      // Set again later when valuation currency retrieved.
      type: IndicativePriceType.IntraDay,
    });

  // Fetch possible valuation tokens
  const [isLoadingValuationToken, setLoadingValuationToken] = useState(false);
  const [
    possibleValuationTokenViewModels,
    setPossibleValuationTokenViewModels,
  ] = useState<LedgerTokenViewModel[]>([]);
  const [valuationTokenViewModel, setValuationTokenViewModel] = useState<
    undefined | LedgerTokenViewModel
  >(undefined);
  useEffect(() => {
    (async () => {
      try {
        setLoadingValuationToken(true);
        const retrievedLedgerTokenViewModels = (
          await LedgerTokenViewReader.Read({
            criteria: {
              tokenCategory: TextExactCriterion(
                TokenCategory.RightsToFiatCurrency,
              ),
              "token.network": TextListCriterion([props.token.network]),
            },
          })
        ).models;

        const givenValuationTokenViewModel =
          retrievedLedgerTokenViewModels.find((vm) =>
            vm.token.isEqualTo(props.valuationToken ?? new Token()),
          );
        if (givenValuationTokenViewModel) {
          setValuationTokenViewModel(givenValuationTokenViewModel);
        } else {
          setValuationTokenViewModel(retrievedLedgerTokenViewModels[0]);
        }

        setPossibleValuationTokenViewModels(retrievedLedgerTokenViewModels);
      } catch (e) {
        const translatedError = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(translatedError);
      }
      setLoadingValuationToken(false);
    })();
  }, [authContext, props.valuationToken, enqueueSnackbar]);

  useEffect(() => {
    if (!valuationTokenViewModel) {
      return;
    }
    setRecordPriceRequest({
      ...recordPriceRequest,
      buyPrice: valuationTokenViewModel.token.newAmountOf(
        recordPriceRequest.buyPrice.value,
      ),
      sellPrice: valuationTokenViewModel.token.newAmountOf(
        recordPriceRequest.sellPrice.value,
      ),
    });
  }, [valuationTokenViewModel]);

  // Fetch fiat currency cut off time
  const [loadingValuationCurrency, setLoadingValuationCurrency] =
    useState(false);
  const [valuationCurrency, setValuationCurrency] = useState<
    Currency | undefined
  >(undefined);
  useEffect(() => {
    if (!valuationTokenViewModel) {
      return;
    }
    setLoadingValuationCurrency(true);
    (async () => {
      let valuationCurrencyStablecoin: CurrencyStablecoin;
      try {
        // retrieve valuation stablecoin
        valuationCurrencyStablecoin = (
          await CurrencyStablecoinRepository.RetrieveCurrencyStablecoin({
            context: authContext,
            identifier: TokenIdentifier(valuationTokenViewModel.token),
          })
        ).currencyStablecoin;
      } catch (e) {
        const translatedError = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(translatedError);
        return;
      }

      // retrieve and set valuation currency
      try {
        const retrievedValuationCurrency = (
          await CurrencyCollection.RetrieveCurrency({
            context: authContext,
            identifier: IDIdentifier(valuationCurrencyStablecoin.currencyID),
          })
        ).currency;
        setValuationCurrency(retrievedValuationCurrency);

        // get cut off time today
        const now = dayjs();
        const cutOffTimeToday = dayjs(
          retrievedValuationCurrency.firstCutOffAfter(
            retrievedValuationCurrency.firstStartOfDayBefore(now.format()),
          ),
        );
        if (!dateIsValid(cutOffTimeToday)) {
          const translatedError = new TranslatedError(
            101001,
            "Error determining cut off time today",
          );
          errorContextDefaultErrorFeedback(translatedError);
          return;
        }

        // initialise request
        setRecordPriceRequest((prevRequest) => ({
          ...prevRequest,
          timeOfPrice: now.format(),
          type:
            now.isSame(cutOffTimeToday) || now.isAfter(cutOffTimeToday)
              ? IndicativePriceType.COB
              : IndicativePriceType.IntraDay,
        }));
      } catch (e) {
        const translatedError = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(translatedError);
      }
    })().finally(() => setLoadingValuationCurrency(false));
  }, [authContext, enqueueSnackbar, valuationTokenViewModel]);

  // Fetch indicative price history
  const [refreshPrices, setRefreshPrices] = useState(0);
  const [isLoadingPrices, setLoadingPrices] = React.useState(false);
  const [searchIndicativePricesResponse, setSearchIndicativePricesResponse] =
    useState<SearchIndicativePricesResponse>({
      records: [],
      total: 0,
    });
  useEffect(() => {
    if (!valuationTokenViewModel) {
      return;
    }
    (async () => {
      setLoadingPrices(true);
      try {
        // find most recent indicative prices recorded for the asset
        const response = await IndicativePriceRepository.SearchIndicativePrice({
          context: authContext,
          criteria: {
            "token.code": TextExactCriterion(props.token.code),
            "token.issuer": TextExactCriterion(props.token.issuer),
            "token.network": TextExactCriterion(props.token.network),
            "buyPrice.token.code": TextExactCriterion(
              valuationTokenViewModel.token.code,
            ),
            "buyPrice.token.issuer": TextExactCriterion(
              valuationTokenViewModel.token.issuer,
            ),
            "buyPrice.token.network": TextExactCriterion(
              valuationTokenViewModel.token.network,
            ),
          },
          query: new Query({
            limit: 100,
            offset: 0,
            sorting: [NewSorting("timeOfPrice", "desc")],
          }),
        });

        // confirm at least 1 record was retrieved
        if (!response.records.length) {
          setLoadingPrices(false);
          return;
        }

        const latestPrice = new IndicativePrice(response.records[0]);
        setRecordPriceRequest((prevRequest: RecordIndicativePriceRequest) => ({
          ...prevRequest,
          buyPrice: latestPrice.buyPrice,
          sellPrice: latestPrice.sellPrice,
        }));
        setSearchIndicativePricesResponse(response);
        setLoadingPrices(false);
      } catch (e) {
        setLoadingPrices(false);
        const translatedError = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(translatedError);
      }
    })();
  }, [
    props.token,
    authContext,
    enqueueSnackbar,
    refreshPrices,
    valuationTokenViewModel,
  ]);

  // Record new indicative price
  const [isRecordingPrice, setIsRecordingPrice] = useState(false);
  const handleRecordIndicativePrice = async () => {
    if (!valuationCurrency) {
      return;
    }

    // get cutoff time today
    const now = dayjs();
    const cutOffTimeToday = dayjs(
      valuationCurrency.firstCutOffAfter(
        valuationCurrency.firstStartOfDayBefore(now.format()),
      ),
    );
    if (!dateIsValid(cutOffTimeToday)) {
      const translatedError = new TranslatedError(
        101001,
        "Error determining cut off time today",
      );
      errorContextDefaultErrorFeedback(translatedError);

      return;
    }

    // Parse selected time of price for its date component and
    // set the time component to now. This is done to account for
    // any delay that may have taken place between the last dialog
    // manipulation and pushing the button that triggers this handler.
    // The effect of this is as if the user selected the same date again
    // when pushing the button that triggers this handler.
    const selectedTimeOfPrice = dayjs(recordPriceRequest.timeOfPrice)
      .hour(now.hour())
      .minute(now.minute())
      .second(now.second())
      .millisecond(now.millisecond());

    // determine type and time of price
    // based on the constructed dayjsTimeOfPrice
    let type: IndicativePriceType;
    let timeOfPrice: string;

    // if dayjsTimeOfPrice
    if (
      // is before the start of today
      selectedTimeOfPrice.isBefore(dayjs().startOf("d")) || // OR
      // equal or later to COB today
      selectedTimeOfPrice.isSame(cutOffTimeToday) ||
      selectedTimeOfPrice.isAfter(cutOffTimeToday)
    ) {
      // then the type is close of business
      type = IndicativePriceType.COB;
      // set time to close of business on the selected date
      timeOfPrice = dayjs()
        .year(selectedTimeOfPrice.year())
        .month(selectedTimeOfPrice.month())
        .date(selectedTimeOfPrice.date())
        .hour(cutOffTimeToday.hour())
        .minute(cutOffTimeToday.minute())
        .second(cutOffTimeToday.second())
        .millisecond(cutOffTimeToday.millisecond())
        .format();
    } else {
      // otherwise it is intraday
      type = IndicativePriceType.IntraDay;

      // Set time of price to now.
      // NOTE: This is set again on the backend.
      timeOfPrice = now.format();
    }

    // Create a temporary request for API.
    // This is necessary to prevent updating time component of timeOfPrice in
    // request which may prevent the date field from working if said component
    // is later than the current time.
    const temporaryRequestForAPI: RecordIndicativePriceRequest = {
      ...recordPriceRequest,
      timeOfPrice,
      type,
    };

    // Update request with determined type and timeOfPrice as if
    // they had selected the same date again.
    setRecordPriceRequest({
      ...recordPriceRequest,
      type,
      timeOfPrice: selectedTimeOfPrice.format(),
    });

    const isValidResult = performValidation(
      {},
      temporaryRequestForAPI,
      props.assetIssueDate,
      true,
    );
    setValidationResult(isValidResult);
    if (isValidResult.valid) {
      // record the new indicative price
      setIsRecordingPrice(true);

      try {
        await IndicativePriceRecorder.RecordIndicativePrice(
          temporaryRequestForAPI,
        );
        enqueueSnackbar("New price recorded", { variant: "success" });
        // trigger a refresh of prices
        setRefreshPrices(refreshPrices + 1);
      } catch (e) {
        const translatedError = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(
          translatedError,
          "Error Recording Indicative Price",
        );
      }

      setIsRecordingPrice(false);
    }
  };

  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    valid: true,
    fieldValidations: {},
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (field: string) => (newValue: any) => {
    if (!valuationCurrency) {
      return;
    }

    // get cutoff time today
    const now = dayjs();
    const cutOffTimeToday = dayjs(
      valuationCurrency.firstCutOffAfter(
        valuationCurrency.firstStartOfDayBefore(now.format()),
      ),
    );
    if (!dateIsValid(cutOffTimeToday)) {
      const translatedError = new TranslatedError(
        101001,
        "Error determining cut off time today",
      );
      errorContextDefaultErrorFeedback(translatedError);
      return;
    }

    // prepare updated request
    const updatedRequest = {
      ...recordPriceRequest,
      [field]: newValue,
    } as RecordIndicativePriceRequest;

    // if time of price is set, also update the type
    if (field === "timeOfPrice" && dateIsValid(newValue)) {
      const newTimeOfPriceDayjs = dayjs(newValue);

      // if the selected date is
      if (
        // before the start of today
        newTimeOfPriceDayjs.isBefore(dayjs().startOf("d")) || // OR
        // equal or later to COB today
        newTimeOfPriceDayjs.isSame(cutOffTimeToday) ||
        newTimeOfPriceDayjs.isAfter(cutOffTimeToday)
      ) {
        // then the type is close of business
        updatedRequest.type = IndicativePriceType.COB;
      } else {
        // otherwise it is intraday
        updatedRequest.type = IndicativePriceType.IntraDay;
      }
    }

    // prepare updated touched fields
    const updatedTouchedFields = {
      ...touchedFields,
      [field]: true,
    };

    // set updated touched fields
    setTouchedFields(updatedTouchedFields);

    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 800ms
    validationTimeoutRef.current = setTimeout(() => {
      setValidationResult(
        performValidation(
          updatedTouchedFields,
          updatedRequest,
          props.assetIssueDate,
          false,
        ),
      );
    }, 800);

    setRecordPriceRequest(updatedRequest);
  };

  const isLoading =
    isLoadingPrices ||
    isLoadingValuationToken ||
    isRecordingPrice ||
    loadingValuationCurrency;

  return (
    <StyledDialog
      open={props.open}
      maxWidth={sm ? "sm" : "md"}
      fullWidth
      fullScreen={sm}
    >
      <DialogTitle
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr auto 48px",
          gap: theme.spacing(1),
          alignItems: "center",
          backgroundColor: theme.palette.custom.midnight,
          borderBottom: "none",
        })}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { sm: "center" },
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            ml: {
              xs: 1,
              sm: 2,
              md: 1,
            },
          }}
        >
          <Typography
            variant="h6"
            children="Capture Price"
            sx={{
              fontSize: "16px",
            }}
          />
          <Typography
            id="capturePriceDialog-asset-name"
            noWrap
            sx={(theme) => ({
              color: {
                sm: theme.palette.text.secondary,
                xs: theme.palette.text.disabled,
              },
              fontSize: {
                xs: "12px",
                sm: "16px",
              },
              ml: { sm: 1 },
            })}
            children={props.assetName}
          />
        </Box>
        <div>{isRecordingPrice && <CircularProgress size={24} />}</div>
        <IconButton
          id="capturePriceDialog-close-button"
          aria-label="close capture price"
          onClick={props.handleClose}
          disabled={isRecordingPrice}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          padding: theme.spacing(0),
          display: "grid",
          gridTemplateColumns: "2fr 3fr",
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            p: 5,
            [theme.breakpoints.down("md")]: {
              px: 4,
              pt: 2,
              pb: 5,
            },
            [theme.breakpoints.down("sm")]: {
              px: 3,
              pb: 0,
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              mb: 4,
              fontWeight: "bold",
              justifySelf: "start",
              [theme.breakpoints.down("md")]: {
                justifySelf: "center",
                maxWidth: "312px",
              },
            })}
            variant="h4"
            children="Set Indicative Price"
          />
          {!props.valuationToken && ( // show token selection if token was not given
            <TextField
              readOnly={loadingValuationCurrency}
              fullWidth
              sx={{ maxWidth: 200 }}
              label="Token to Record Price"
              select
              value={valuationTokenViewModel?.id}
              onChange={(e) => {
                const selected = possibleValuationTokenViewModels.find(
                  (cst) => cst.id === e.target.value,
                );
                if (selected) {
                  setValuationTokenViewModel(selected);
                }
              }}
            >
              {possibleValuationTokenViewModels.map((cst, idx) => (
                <MenuItem key={idx} value={cst.id}>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    })}
                  >
                    <TokenIconViewUpload
                      disableChangeIcon
                      size={23}
                      token={cst.token}
                    />
                    <Typography children={cst.token.code} />
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box
            sx={(theme) => ({
              display: "grid",
              columnGap: 2,
              gridTemplateColumns: "1fr 1fr",
              [theme.breakpoints.down("md")]: {
                maxWidth: "312px",
              },
              my: {
                md: 4,
                xs: 2,
              },
            })}
          >
            <TextNumField
              id="capturePriceDialog-buyPrice-textNumField"
              disabled={isLoading}
              disallowNegative
              label={"Buy"}
              value={recordPriceRequest.buyPrice.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange("buyPrice")(
                  recordPriceRequest.buyPrice.setValue(event.target.value),
                )
              }
              InputProps={{
                startAdornment: (
                  <Tooltip
                    title={`Issued by ${valuationTokenViewModel?.issuer}`}
                    placement="top"
                  >
                    <Typography
                      variant="body1"
                      className={classes.tokenAmountCode}
                      children={valuationTokenViewModel?.token.code}
                    />
                  </Tooltip>
                ),
              }}
              error={!!validationResult.fieldValidations.buyPrice}
              helperText={validationResult.fieldValidations.buyPrice}
            />

            <TextNumField
              id="capturePriceDialog-sellPrice-textNumField"
              disabled={isLoading}
              disallowNegative
              label={"Sell"}
              value={recordPriceRequest.sellPrice.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange("sellPrice")(
                  recordPriceRequest.sellPrice.setValue(event.target.value),
                )
              }
              InputProps={{
                startAdornment: (
                  <Tooltip
                    title={`Issued by ${valuationTokenViewModel?.issuer}`}
                    placement="top"
                  >
                    <Typography
                      variant="body1"
                      className={classes.tokenAmountCode}
                      children={valuationTokenViewModel?.token.code}
                    />
                  </Tooltip>
                ),
              }}
              error={!!validationResult.fieldValidations.sellPrice}
              helperText={validationResult.fieldValidations.sellPrice}
            />
            <Box
              sx={{
                gridColumn: "1 / span 2",
              }}
            >
              <DateField
                disableFuture
                label="Date"
                onChange={(newDate) => {
                  handleChange("timeOfPrice")(
                    newDate ? newDate.format() : dayjs().format(),
                  );
                }}
                value={recordPriceRequest.timeOfPrice}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="capturePriceDialog-captureDate-dateField"
                    fullWidth
                    disabled={isLoading}
                    helperText={validationResult.fieldValidations.timeOfPrice}
                    error={!!validationResult.fieldValidations.timeOfPrice}
                  />
                )}
              />
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                })}
              >
                <Typography
                  className={classes.grey}
                  variant="caption"
                  children="Type:"
                />
                <Typography
                  color="textSecondary"
                  variant="caption"
                  children={recordPriceRequest.type}
                />
                <Tooltip
                  title={
                    <div>
                      <Typography
                        className={classes.infoTooltipText}
                        variant="caption"
                        children="Historical prices are saved as COB."
                      />
                      <Typography
                        className={cx(
                          classes.infoTooltipText,
                          classes.infoTooltipTextSpacing,
                        )}
                        variant="caption"
                        children="Prices saved today are either:"
                      />
                      <Typography
                        className={classes.infoTooltipText}
                        variant="caption"
                        children="- Intraday: when saved before CCY cut-off"
                      />
                      <Typography
                        className={classes.infoTooltipText}
                        variant="caption"
                        children="- COB: when saved after CCY cut-off"
                      />
                    </div>
                  }
                  arrow
                  placement="right"
                >
                  <InfoIcon className={classes.infoInfoIcon} />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: {
                md: "flex-start",
                sm: "flex-end",
              },
              pr: 1,
            }}
          >
            {!xs && (
              <Button
                id="capturePriceDialog-save-button"
                disabled={isLoading || !validationResult.valid}
                className={classes.saveButton}
                variant="contained"
                children="Save"
                color="primary"
                onClick={handleRecordIndicativePrice}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            padding: {
              sm: 5,
              xs: 2,
            },
            backgroundColor: theme.palette.background.default,
            width: "100%",
          })}
        >
          <Typography
            children="Price History"
            sx={{
              fontSize: "16px",
              mx: 1,
            }}
          />
          <PriceHistoryTable
            isLoading={isLoadingPrices || isLoadingValuationToken}
            searchIndicativePricesResponse={searchIndicativePricesResponse}
            valuationTokenViewModel={valuationTokenViewModel}
            noDataSplashComponent={
              <NoDataSplash
                text="No Captured Prices"
                subText="You will see an entry once you capture a price"
                iconColor={alpha(theme.palette.text.tertiary, 0.5)}
              />
            }
          />
        </Box>
        {xs && (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.cardInner,
              position: "sticky",
              bottom: 0,
              pt: 3,
              px: 3,
              pb: 5,
            })}
          >
            <Button
              id="capturePriceDialog-save-button"
              disabled={isLoading || !validationResult.valid}
              fullWidth
              sx={{
                height: "48px",
              }}
              variant="contained"
              children="Save"
              color="primary"
              onClick={handleRecordIndicativePrice}
            />
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  );
}

interface NoDataSplashProps {
  text: string;
  subText: string;
  iconColor?: string;
}

const NoDataSplash = ({ text, subText, iconColor }: NoDataSplashProps) => {
  return (
    <>
      <FaceIcon
        sx={(theme) => ({
          fontSize: 110,
          color: iconColor
            ? iconColor
            : alpha(theme.palette.background.default, 0.5),
        })}
      />
      <Typography
        variant="h4"
        sx={(theme) => ({
          fontWeight: "bold",
          color: theme.palette.text.tertiary,
          margin: theme.spacing(2, 0),
        })}
        children={text}
      />
      <Typography
        variant="h5"
        sx={{
          width: "226px",
          color: (theme) => theme.palette.text.tertiary,
          textAlign: "center",
        }}
        children={subText}
      />
    </>
  );
};
