import config from "react-global-configuration";
import { Context } from "../security";
import { ETFStablecoin } from "./ETFStablecoin";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";
import { ETF } from "./ETF";

export type CoordinateIssueETFStablecoinRequest = {
  context: Context;
  etfStablecoinID: string;
  issuanceAccountID: string;
};

export type CoordinateIssueETFStablecoinResponse = {
  etf: ETF;
  etfStablecoin: ETFStablecoin;
};

export type CoordinatePreIssueETFStablecoinRequest = {
  context: Context;
  etfStablecoinID: string;
  issuanceAccountID: string;
};

export type CoordinatePreIssueETFStablecoinResponse = {
  etf: ETF;
  etfStablecoin: ETFStablecoin;
};

export type CoordinateMarkETFStablecoinIssuedRequest = {
  context: Context;
  etfStablecoinID: string;
};

export type CoordinateMarkETFStablecoinIssuedResponse = {
  etf: ETF;
  etfStablecoin: ETFStablecoin;
};

export type CoordinateMarkETFStablecoinDeletedRequest = {
  context: Context;
  etfStablecoinID: string;
};

export type CoordinateMarkETFStablecoinDeletedResponse = {
  etf: ETF;
  etfStablecoin: ETFStablecoin;
};

export const ETFStablecoinStateChangerCoordinator = {
  serviceProvider: "financial-ETFStablecoinStateChangerCoordinator",
  async CoordinateIssueETFStablecoin(
    request: CoordinateIssueETFStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateIssueETFStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETFStablecoinStateChangerCoordinator.serviceProvider}.CoordinateIssueETFStablecoin`,
        request,
      },
      opts,
    );
    return {
      etf: new ETF(response.etf),
      etfStablecoin: new ETFStablecoin(response.etfStablecoin),
    };
  },
  async CoordinatePreIssueETFStablecoin(
    request: CoordinatePreIssueETFStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinatePreIssueETFStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETFStablecoinStateChangerCoordinator.serviceProvider}.CoordinatePreIssueETFStablecoin`,
        request,
      },
      opts,
    );
    return {
      etf: new ETF(response.etf),
      etfStablecoin: new ETFStablecoin(response.etfStablecoin),
    };
  },
  async CoordinateMarkETFStablecoinIssued(
    request: CoordinateMarkETFStablecoinIssuedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMarkETFStablecoinIssuedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETFStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMarkETFStablecoinIssued`,
        request,
      },
      opts,
    );
    return {
      etf: new ETF(response.etf),
      etfStablecoin: new ETFStablecoin(response.etfStablecoin),
    };
  },
  async CoordinateMarkETFStablecoinDeleted(
    request: CoordinateMarkETFStablecoinDeletedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMarkETFStablecoinDeletedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETFStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMarkETFStablecoinDeleted`,
        request,
      },
      opts,
    );
    return {
      etf: new ETF(response.etf),
      etfStablecoin: new ETFStablecoin(response.etfStablecoin),
    };
  },
};
