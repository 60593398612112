import { alpha, Card, styled } from "@mui/material";

const PREFIX = "styles";

export const classes = {
  sectionWithPaddingLR: `${PREFIX}-sectionWithPaddingLR`,
  sectionWithRows1Gap: `${PREFIX}-sectionWithRows1Gap`,
  sectionWithColumns2Gap: `${PREFIX}-sectionWithColumns2Gap`,
  sectionWithRows2Gap: `${PREFIX}-sectionWithRows2Gap`,
  sectionWithBottomBorder: `${PREFIX}-sectionWithBottomBorder`,
  sectionWithPaperBackground: `${PREFIX}-sectionWithPaperBackground`,
  sectionWith2EqualColumns: `${PREFIX}-sectionWith2EqualColumns`,
  ledgerAmountCode: `${PREFIX}-ledgerAmountCode`,
  ledgerAmountValue: `${PREFIX}-ledgerAmountValue`,
  disabledText: `${PREFIX}-disabledText`,
  tradeFeeLayout: `${PREFIX}-tradeFeeLayout`,
  singleButtonLayout: `${PREFIX}-singleButtonLayout`,
  doubleButtonLayout: `${PREFIX}-doubleButtonLayout`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  linkText: `${PREFIX}-linkText`,
  estimatedValuesTitle: `${PREFIX}-estimatedValuesTitle`,
  estimatedValuesTooltipArrow: `${PREFIX}-estimatedValuesTooltipArrow`,
  estimatedValuesInfoIcon: `${PREFIX}-estimatedValuesInfoIcon`,
};

export const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.sectionWithPaddingLR}`]: {
    padding: theme.spacing(3, 4),
  },

  [`& .${classes.sectionWithRows1Gap}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  [`& .${classes.sectionWithColumns2Gap}`]: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },

  [`& .${classes.sectionWithRows2Gap}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  [`& .${classes.sectionWithBottomBorder}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.sectionWithPaperBackground}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.sectionWith2EqualColumns}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [`& .${classes.ledgerAmountCode}`]: {
    color: alpha(theme.palette.warning.main, 0.38),
    cursor: "pointer",
    "&:hover": {
      color: alpha(theme.palette.warning.main, 0.5),
    },
  },

  [`& .${classes.ledgerAmountValue}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.disabledText}`]: {
    color: theme.palette.text.disabled,
  },

  [`& .${classes.tradeFeeLayout}`]: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.singleButtonLayout}`]: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.doubleButtonLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginTop: theme.spacing(3),
    columnGap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: 8,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.linkText}`]: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.light,
      textDecoration: "underline",
    },
  },

  [`& .${classes.estimatedValuesTitle}`]: {
    padding: theme.spacing(3, 0),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.estimatedValuesTooltipArrow}`]: {
    color: theme.palette.secondary.light,
  },

  [`& .${classes.estimatedValuesInfoIcon}`]: {
    color: theme.palette.secondary.light,
    cursor: "pointer",
  },
}));
