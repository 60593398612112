import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";
import { ConnectedCompany, LegalForm } from "james/legal/ConnectedCompany";
import camelCase from "lodash/camelCase";
import React, { useEffect } from "react";
import cx from "classnames";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
import { useConnectedEntitiesDialogStore } from "./store";
import { CompanyRepresentative } from "james/legal";
import { Address } from "james/location";
import { countries, CountryOption } from "james/country/countries";
import Add from "@mui/icons-material/Add";

const PREFIX = "NonConnectedIndividualDialog";

const classes = {
  dialogPaperOverride: `${PREFIX}-dialogPaperOverride`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogContent: `${PREFIX}-dialogContent`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  sectionWithColumns1Gap: `${PREFIX}-sectionWithColumns1Gap`,
  sectionHelperText: `${PREFIX}-sectionHelperText`,
  sectionWith2EqColumns2Gap: `${PREFIX}-sectionWith2EqColumns2Gap`,
  addressWithCopyTitleLayout: `${PREFIX}-addressWithCopyTitleLayout`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogPaperOverride}`]: {
    backgroundColor: theme.palette.custom.midnight,
    width: "880px",
  },

  [`& .${classes.dialogTitle}`]: {
    padding: theme.spacing(2, 3),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(2, 3),
    display: "grid",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(2.5),
    overflowY: "scroll",
  },

  [`& .${classes.sectionHeading}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.sectionWithColumns1Gap}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },

  [`& .${classes.sectionHelperText}`]: {
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.sectionWith2EqColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },

  [`& .${classes.addressWithCopyTitleLayout}`]: {
    margin: theme.spacing(-0.8, 0),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },
}));

interface ConnectedEntitiesDialogProps {
  open: boolean;
  onClose: () => void;
  connectedCompany?: ConnectedCompany;
  onAdd: (c: ConnectedCompany) => void;
  onUpdate: (c: ConnectedCompany) => void;
}

export const ConnectedEntitiesDialog: React.FC<ConnectedEntitiesDialogProps> = (
  props: ConnectedEntitiesDialogProps,
) => {
  const store = useConnectedEntitiesDialogStore();

  useEffect(() => {
    if (props.connectedCompany) {
      store.setStateFrom(props.connectedCompany);
    }
  }, [props.connectedCompany]);

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaperOverride }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">Connected Entity</Typography>
        <div className={classes.sectionWithColumns1Gap}>
          {!props.connectedCompany && (
            <Button
              variant="contained"
              color="secondary"
              id="connectedNonIndividual-add"
              onClick={() => {
                props.onAdd(store.getConnectedEntitiesObject());
              }}
            >
              <Add />
              Entity
            </Button>
          )}
          {props.connectedCompany && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.onUpdate(store.getConnectedEntitiesObject());
              }}
            >
              Update
            </Button>
          )}

          <IconButton
            id="connectedNonIndividual-closeDialog"
            onClick={props.onClose}
            size="small"
          >
            <Icon>
              <Clear />
            </Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={cx(classes.dialogContent, "meshScroll")}>
        {/* ----Company Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Company Details"
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "32px",
            }}
          >
            <Box>
              <TextField
                id="connectedNonIndividual-companyDetails-businessName"
                variant="outlined"
                fullWidth
                label="Name"
                value={store.businessName}
                onChange={(e) => store.setBusinessName(e.target.value)}
              />
              <TextField
                variant="outlined"
                fullWidth
                id="connectedNonIndividual-companyDetails-registeredName"
                label="Registered Name"
                value={store.registeredName}
                onChange={(e) => store.setRegisteredName(e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                id="connectedNonIndividual-companyDetails-registrationNumber"
                label="Reg. Number"
                value={store.registrationNumber}
                onChange={(e) => store.setRegistrationNumber(e.target.value)}
                placeholder="YYYY/MMMMMM/NN"
              />
              <TextField
                select
                id="connectedNonIndividual-companyDetails-legalForm"
                variant="outlined"
                fullWidth
                margin="dense"
                value={store.legalForm}
                onChange={(e) =>
                  store.setLegalForm(e.target.value as LegalForm)
                }
                label="Legal Form"
              >
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.SoleProprietorShipLegalForm,
                  )}`}
                  value={LegalForm.SoleProprietorShipLegalForm}
                >
                  {LegalForm.SoleProprietorShipLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.CloseCorporationLegalForm,
                  )}`}
                  value={LegalForm.CloseCorporationLegalForm}
                >
                  {LegalForm.CloseCorporationLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.SouthAfricanCompanyLegalForm,
                  )}`}
                  value={LegalForm.SouthAfricanCompanyLegalForm}
                >
                  {LegalForm.SouthAfricanCompanyLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.ForeignCompanyLegalForm,
                  )}`}
                  value={LegalForm.ForeignCompanyLegalForm}
                >
                  {LegalForm.ForeignCompanyLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.ListedCompanyLegalForm,
                  )}`}
                  value={LegalForm.ListedCompanyLegalForm}
                >
                  {LegalForm.ListedCompanyLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.PartnershipLegalForm,
                  )}`}
                  value={LegalForm.PartnershipLegalForm}
                >
                  {LegalForm.PartnershipLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.TrustLegalForm,
                  )}`}
                  value={LegalForm.TrustLegalForm}
                >
                  {LegalForm.TrustLegalForm}
                </MenuItem>
                <MenuItem
                  id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                    LegalForm.OtherLegalForm,
                  )}`}
                  value={LegalForm.OtherLegalForm}
                >
                  {LegalForm.OtherLegalForm}
                </MenuItem>
              </TextField>
            </Box>
          </Box>
        </div>

        {/* ---- Representative Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Company Representative Details"
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "32px",
            }}
          >
            <Box>
              <TextField
                id="connectedNonIndividual-representativeContactDetails-firstName"
                variant="outlined"
                fullWidth
                label="Name"
                margin="dense"
                value={store.companyRepresentative.firstName}
                onChange={(e) =>
                  store.setCompanyRepresentative(
                    new CompanyRepresentative({
                      ...store.companyRepresentative,
                      firstName: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-representativeContactDetails-middleName"
                variant="outlined"
                fullWidth
                label="Middle Names"
                margin="dense"
                value={store.companyRepresentative.middleNames}
                onChange={(e) =>
                  store.setCompanyRepresentative(
                    new CompanyRepresentative({
                      ...store.companyRepresentative,
                      middleNames: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-representativeContactDetails-lastName"
                variant="outlined"
                fullWidth
                label="Surname"
                value={store.companyRepresentative.lastName}
                onChange={(e) =>
                  store.setCompanyRepresentative(
                    new CompanyRepresentative({
                      ...store.companyRepresentative,
                      lastName: e.target.value,
                    }),
                  )
                }
                margin="dense"
              />
            </Box>
            <Box>
              <TextField
                id="connectedNonIndividual-representativeContactDetails-emailAddress"
                variant="outlined"
                fullWidth
                label="Email"
                margin="dense"
                value={store.companyRepresentative.emailAddress}
                onChange={(e) =>
                  store.setCompanyRepresentative(
                    new CompanyRepresentative({
                      ...store.companyRepresentative,
                      emailAddress: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-representativeContactDetails-cellphoneNumber"
                variant="outlined"
                fullWidth
                label="Mobile"
                value={store.companyRepresentative.cellphoneNumber}
                onChange={(e) =>
                  store.setCompanyRepresentative(
                    new CompanyRepresentative({
                      ...store.companyRepresentative,
                      cellphoneNumber: e.target.value,
                    }),
                  )
                }
                margin="dense"
              />
              <TextField
                variant="outlined"
                fullWidth
                id="connectedNonIndividual-representativeContactDetails-telephoneNumber"
                label="Telephone"
                value={store.companyRepresentative.telephoneNumber}
                onChange={(e) =>
                  store.setCompanyRepresentative(
                    new CompanyRepresentative({
                      ...store.companyRepresentative,
                      telephoneNumber: e.target.value,
                    }),
                  )
                }
                margin="dense"
              />
            </Box>
          </Box>
        </div>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "32px",
          }}
        >
          {/* ---- Business Office Address ---- */}
          <Box>
            <div>
              <Box
                className={classes.addressWithCopyTitleLayout}
                sx={{ paddingBottom: "8px" }}
              >
                <Typography
                  className={classes.sectionHeading}
                  variant="h6"
                  children="Business Address"
                />
              </Box>
              <CountryDropdown
                id="connectedNonIndividual-businessAddress-country"
                isOptionEqualToValue={(option, value) => {
                  if (value.value === "") {
                    return true;
                  }
                  return option.value === value.value;
                }}
                onChange={(__: object, country: CountryOption | null) => {
                  if (country) {
                    store.setBusinessAddress(
                      new Address({
                        ...store.businessAddress,
                        countryCode: country.value,
                      }),
                    );
                  }
                }}
                value={(() => {
                  const c = countries.find(
                    (countryOption) =>
                      countryOption.value === store.businessAddress.countryCode,
                  );
                  return c || null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Country"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <TextField
                id="connectedNonIndividual-businessAddress-addressLine1"
                variant="outlined"
                fullWidth
                margin="dense"
                label="Address 1"
                value={store.businessAddress.addressLine1}
                onChange={(e) =>
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      addressLine1: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-businessAddress-addressLine2"
                variant="outlined"
                fullWidth
                label="Address 2"
                margin="dense"
                value={store.businessAddress.addressLine2}
                onChange={(e) =>
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      addressLine2: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-businessAddress-suburb"
                variant="outlined"
                fullWidth
                label="Suburb"
                placeholder="Optional"
                margin="dense"
                value={store.businessAddress.suburb}
                onChange={(e) =>
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      suburb: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-businessAddress-city"
                variant="outlined"
                fullWidth
                label="City"
                margin="dense"
                value={store.businessAddress.city}
                onChange={(e) =>
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      city: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-businessAddress-province"
                variant="outlined"
                fullWidth
                label="Province"
                margin="dense"
                placeholder="Optional"
                value={store.businessAddress.province}
                onChange={(e) =>
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      city: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-businessAddress-postalCode"
                variant="outlined"
                label="Postal Code"
                margin="dense"
                value={store.businessAddress.postalCode}
                onChange={(e) =>
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      postalCode: e.target.value,
                    }),
                  )
                }
              />
            </div>
          </Box>
          {/* ---- Registered Address ---- */}
          <Box>
            <div>
              <Typography
                className={classes.sectionHeading}
                variant="h6"
                children="Registered Address"
              />
              <>
                <CountryDropdown
                  id="connectedNonIndividual-registeredAddress-country"
                  isOptionEqualToValue={(option, value) => {
                    if (value.value === "") {
                      return true;
                    }
                    return option.value === value.value;
                  }}
                  onChange={(__: object, country: CountryOption | null) => {
                    if (country) {
                      store.setRegisteredAddress(
                        new Address({
                          ...store.registeredAddress,
                          countryCode: country.value,
                        }),
                      );
                    }
                  }}
                  value={(() => {
                    const c = countries.find(
                      (countryOption) =>
                        countryOption.value ===
                        store.registeredAddress.countryCode,
                    );
                    return c || null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Country"
                      InputProps={{
                        ...params.InputProps,
                        placeholder: "Select...",
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <TextField
                  id="connectedNonIndividual-registeredAddress-addressLine1"
                  variant="outlined"
                  fullWidth
                  label="Address 1"
                  value={store.registeredAddress.addressLine1}
                  onChange={(e) =>
                    store.setRegisteredAddress(
                      new Address({
                        ...store.registeredAddress,
                        addressLine1: e.target.value,
                      }),
                    )
                  }
                  margin="dense"
                />
                <TextField
                  id="connectedNonIndividual-registeredAddress-addressLine2"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Address 2"
                  value={store.registeredAddress.addressLine2}
                  onChange={(e) =>
                    store.setRegisteredAddress(
                      new Address({
                        ...store.registeredAddress,
                        addressLine2: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedNonIndividual-registeredAddress-suburb"
                  variant="outlined"
                  fullWidth
                  label="Suburb"
                  placeholder="Optional"
                  margin="dense"
                  value={store.registeredAddress.suburb}
                  onChange={(e) =>
                    store.setRegisteredAddress(
                      new Address({
                        ...store.registeredAddress,
                        suburb: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedNonIndividual-registeredAddress-city"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="City"
                  value={store.registeredAddress.city}
                  onChange={(e) =>
                    store.setRegisteredAddress(
                      new Address({
                        ...store.registeredAddress,
                        city: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedNonIndividual-registeredAddress-province"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Province"
                  placeholder="Optional"
                  value={store.registeredAddress.province}
                  onChange={(e) =>
                    store.setRegisteredAddress(
                      new Address({
                        ...store.registeredAddress,
                        province: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedNonIndividual-registeredAddress-postalCode"
                  variant="outlined"
                  margin="dense"
                  label="Postal Code"
                  value={store.registeredAddress.postalCode}
                  onChange={(e) =>
                    store.setRegisteredAddress(
                      new Address({
                        ...store.registeredAddress,
                        postalCode: e.target.value,
                      }),
                    )
                  }
                />
              </>
            </div>
          </Box>
        </Box>
        {/* ---- Head Office Address ---- */}
        <div>
          <Box
            className={classes.addressWithCopyTitleLayout}
            sx={{ paddingBottom: "8px" }}
          >
            <Typography
              className={classes.sectionHeading}
              variant="h6"
              children="Head Office Address"
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "32px",
            }}
          >
            <Box>
              <TextField
                id="connectedNonIndividual-headOfficeAddress-addressLine1"
                variant="outlined"
                fullWidth
                label="Address 1"
                margin="dense"
                value={store.headOfficeAddress.addressLine1}
                onChange={(e) =>
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      addressLine1: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-headOfficeAddress-addressLine2"
                variant="outlined"
                fullWidth
                label="Address 2"
                margin="dense"
                value={store.headOfficeAddress.addressLine2}
                onChange={(e) =>
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      addressLine2: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-headOfficeAddress-suburb"
                variant="outlined"
                fullWidth
                label="Suburb"
                margin="dense"
                placeholder="Optional"
                value={store.headOfficeAddress.suburb}
                onChange={(e) =>
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      suburb: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-headOfficeAddress-city"
                variant="outlined"
                fullWidth
                margin="dense"
                label="City"
                value={store.headOfficeAddress.city}
                onChange={(e) =>
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      city: e.target.value,
                    }),
                  )
                }
              />
            </Box>
            <Box>
              <CountryDropdown
                id="connectedNonIndividual-headOfficeAddress-country"
                isOptionEqualToValue={(option, value) => {
                  if (value.value === "") {
                    return true;
                  }
                  return option.value === value.value;
                }}
                onChange={(__: object, country: CountryOption | null) => {
                  if (country) {
                    store.setHeadOfficeAddress(
                      new Address({
                        ...store.headOfficeAddress,
                        countryCode: country.value,
                      }),
                    );
                  }
                }}
                value={(() => {
                  const c = countries.find(
                    (countryOption) =>
                      countryOption.value ===
                      store.headOfficeAddress.countryCode,
                  );
                  return c || null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Country"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <TextField
                id="connectedNonIndividual-headOfficeAddress-province"
                variant="outlined"
                fullWidth
                label="Province"
                margin="dense"
                placeholder="Optional"
                value={store.headOfficeAddress.province}
                onChange={(e) =>
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      province: e.target.value,
                    }),
                  )
                }
              />
              <TextField
                id="connectedNonIndividual-headOfficeAddress-postalCode"
                variant="outlined"
                label="Postal Code"
                margin="dense"
                value={store.headOfficeAddress.postalCode}
                onChange={(e) =>
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      postalCode: e.target.value,
                    }),
                  )
                }
              />
            </Box>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};
