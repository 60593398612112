import { styled } from "@mui/material/styles";
import React, { Dispatch, SetStateAction } from "react";
import { Framework } from "./components";
import { FormState, FormUpdaterSpecsType } from "views/KYB/useFormState";
import { ValidationResult } from "common/validation";

const PREFIX = "CompanyRegistrationView";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.content}`]: {
    paddingTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: 800,
  },
}));

export enum CompanyRegStep {
  companyProfile = "companyProfile",
  contactDetails = "contactDetails",
  companyDetails = "companyDetails",
  companyRepresentative = "companyRepresentative",
  connectedIndividuals = "connectedIndividuals",
  connectedNonIndividuals = "connectedNonIndividuals",
  confirmation = "confirmation",
}

interface CompanyRegistrationViewProps {
  formState: FormState;
  formUpdater: FormUpdaterSpecsType;
  validationResult: ValidationResult;
  nextPressed: boolean;
  setNextPressed: Dispatch<SetStateAction<boolean>>;
  savingInProgress: boolean;
  setActiveStep: Dispatch<SetStateAction<CompanyRegStep>>;
  activeStep: CompanyRegStep;
  handleSubmitButton: () => Promise<void>;
  confirmInfo: boolean;
  setConfirmInfo: Dispatch<SetStateAction<boolean>>;
}

export const CompanyRegistrationView = ({
  formState,
  formUpdater,
  validationResult,
  nextPressed,
  setNextPressed,
  savingInProgress,
  setActiveStep,
  activeStep,
  handleSubmitButton,
  confirmInfo,
  setConfirmInfo,
}: CompanyRegistrationViewProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (name: string) => (value: any) => {
    formUpdater.company({
      ...formState.company,
      [name]: value,
    });
  };

  const { fieldValidations } = validationResult;

  const renderStep = (step: CompanyRegStep) => {
    return (
      <Framework
        activeStep={step}
        setActiveStep={setActiveStep}
        onChange={onChange}
        fieldValidations={fieldValidations}
        formState={formState}
        nextPressed={nextPressed}
        setNextPressed={setNextPressed}
        savingInProgress={savingInProgress}
        confirmInfo={confirmInfo}
        handleSubmitButton={handleSubmitButton}
        setConfirmInfo={setConfirmInfo}
      />
    );
  };

  return (
    <Root id="company-registration-root">
      <div className={classes.root}>
        <div className={classes.content}>{renderStep(activeStep)}</div>
      </div>
    </Root>
  );
};
