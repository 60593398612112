import {
  CurrencyAmountInstrumentManagementFee,
  CurrencyAmountInstrumentManagementFeeTypeName,
} from "./InstrumentManagementFeeCurrencyAmount";
import {
  PercentageInstrumentManagementFee,
  PercentageInstrumentManagementFeeTypeName,
} from "./InstrumentManagementFeePercentage";

export enum InstrumentManagementFeeFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export const AllInstrumentManagementFeeFrequencies: InstrumentManagementFeeFrequency[] =
  [
    InstrumentManagementFeeFrequency.Daily,
    InstrumentManagementFeeFrequency.Weekly,
    InstrumentManagementFeeFrequency.Monthly,
    InstrumentManagementFeeFrequency.Yearly,
  ];

export enum InstrumentManagementFeeType {
  CurrencyAmount = "Currency Amount",
  Percentage = "Percentage",
}

export const AllInstrumentManagementFeeTypes: InstrumentManagementFeeType[] = [
  InstrumentManagementFeeType.CurrencyAmount,
  InstrumentManagementFeeType.Percentage,
];

export interface InstrumentManagementFee {
  ["@type"]: string;

  instrumentManagementFeeType(): InstrumentManagementFeeType;
}

export function NewInstrumentManagementFee(
  i: InstrumentManagementFee,
): InstrumentManagementFee {
  switch (i["@type"]) {
    case CurrencyAmountInstrumentManagementFeeTypeName:
      return new CurrencyAmountInstrumentManagementFee(
        i as CurrencyAmountInstrumentManagementFee,
      );

    case PercentageInstrumentManagementFeeTypeName:
      return new PercentageInstrumentManagementFee(
        i as PercentageInstrumentManagementFee,
      );

    default:
      throw new TypeError(
        `unknown instrument management fee type ${i["@type"]}`,
      );
  }
}
