import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionOrder } from "./SubscriptionOrder";
import { Amount, Token } from "james/ledger";

export type ResolveSubscriptionOrderIDStateRequest = {
  context: Context;
  subscriptionOrderID: string;
  ignoreResolutionCount: boolean;
};

export type ResolveSubscriptionOrderIDStateResponse = {
  subscriptionOrder: SubscriptionOrder;
};

export type SubmitSubscriptionOrderRequest = {
  context: Context;
  sourceAccountID: string;
  token: Token;
  amount: Amount;
};

export type SubmitSubscriptionOrderResponse = {
  subscriptionOrder: SubscriptionOrder;
  transactionID: string;
};

export const SubscriptionOrderStateControllerServiceProviderName =
  "market-SubscriptionOrderStateController";

export const SubscriptionOrderStateController = {
  async SubmitSubscriptionOrder(
    request: SubmitSubscriptionOrderRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SubmitSubscriptionOrderResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderStateControllerServiceProviderName}.SubmitSubscriptionOrder`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrder: new SubscriptionOrder(response.subscriptionOrder),
      transactionID: response.transactionID,
    };
  },
  async ResolveSubscriptionOrderIDState(
    request: ResolveSubscriptionOrderIDStateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveSubscriptionOrderIDStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderStateControllerServiceProviderName}.ResolveSubscriptionOrderIDState`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrder: new SubscriptionOrder(response.subscriptionOrder),
    };
  },
};
