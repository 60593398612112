import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteType } from "./Route";

interface RouterProps {
  // Routes is a list of all of the routes that this
  // router will create matches for.
  // ONLY 1 can match at a time.
  routes: RouteType[];

  // baseURL is removed from the path because matching is done relative to the
  // base <Route />. This should be populated when the <Route> is relative to a
  // parent <Route/>
  baseURL?: string;

  // This 'redirectPath' can be specified to redirect a user
  // if none of the paths matched
  redirectPath?: string;
}

export const Router = (props: RouterProps) => {
  const { baseURL, routes, redirectPath } = props;
  return (
    <Routes>
      {routes.map((route, key) => {
        if (route.component == null) {
          console.warn(`Route ${route.name} has no component to render`);
          return null;
        }
        // remove the baseURL from the route.path because the <Route /> path prop
        // has to be a path that is relative to all parent <Route />s
        let path = route.path;
        if (baseURL) {
          if (route.path.startsWith(baseURL)) {
            path = route.path.slice(baseURL.length);
          } else {
            // `Skipping ${route.name} route because the route's path=${route.path}
            // does not start with the Router baseURL=${baseURL}`
            return null;
          }
        }

        // allow sub paths
        if (route.allowSubPaths) {
          path = `${path}/*`;
        }

        // `Adding ${route.name} route at baseURL=${baseURL} path=${path}`
        return <Route key={key} path={path} element={route.component} />;
      })}
      {redirectPath && (
        <Route path="*" element={<Navigate to={redirectPath} replace />} />
      )}
    </Routes>
  );
};
