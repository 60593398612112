import React from "react";
import LiveHelp from "@mui/icons-material/LiveHelp";
import { Button } from "@mui/material";

export function HelpWidget() {
  return (
    <Button
      sx={(theme) => ({
        display: "flex",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(0.5),
        width: theme.spacing(17.75),
        height: theme.spacing(4.5),
        gap: theme.spacing(0.5),
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(3, 0, 3, 0),
      })}
      target="_blank"
      href={"https://support.mesh.trade/support/home"}
    >
      <LiveHelp />
      need help?
    </Button>
  );
}
