import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Model } from "james/views/marketListingView/Model";
import { useExchangeStore } from "../../../store";
import { motion } from "framer-motion";
import { Amount as AmountType, Token } from "james/ledger";
import { IconPair } from "../../IconPair/IconPair";
import { useStellarContext } from "context/Stellar";
import { useErrorContext } from "context/Error";
import { Amount } from "components/Ledger/Amount";
import * as StellarSDK from "stellar-sdk";
import { SpotTradeDialog } from "components/SpotTradeDialog";
import { MechanismType } from "james/market";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface ExchangeCardProps {
  marketListingViewModel: Model;
  quoteToken: Token;
}

export const ExchangeCard = ({
  marketListingViewModel,
  quoteToken,
}: ExchangeCardProps) => {
  const navigate = useNavigate();
  const exchangeStore = useExchangeStore();
  const quote = quoteToken.code;
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { stellarTradesViewer } = useStellarContext();
  const [, setLoading] = useState(false);
  const [lastTradedPrice, setLastTradedPrice] = useState<AmountType>();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [showSpotDialog, setShowSpotDialog] = useState(false);
  const [isBuy, setIsBuy] = useState(false);
  const [menu, setMenu] = useState(false);
  const menuRef = React.useRef<HTMLDivElement>();
  const spotMechanism = marketListingViewModel.listingMarketMechanisms.find(
    (m) => {
      if (m.type === MechanismType.Spot) {
        return m;
      }
    },
  );

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true);
      let baseAsset = StellarSDK.Asset.native();
      let counterAsset = StellarSDK.Asset.native();

      if (
        marketListingViewModel.token.code !== baseAsset.code &&
        marketListingViewModel.token.issuer !== baseAsset.issuer
      ) {
        baseAsset = new StellarSDK.Asset(
          marketListingViewModel.token.code,
          marketListingViewModel.token.issuer,
        );
      }
      if (
        quoteToken.code !== counterAsset.code &&
        quoteToken.issuer !== counterAsset.issuer
      ) {
        counterAsset = new StellarSDK.Asset(quoteToken.code, quoteToken.issuer);
      }
      try {
        const response = await stellarTradesViewer.FetchTradesForPair({
          baseAsset: baseAsset,
          counterAsset: counterAsset,
          cursor: "now",
          order: "desc",
          limit: 1,
        });
        setLoading(false);
        if (response.trades.length > 0) {
          setLastTradedPrice(response.trades[0].price);
        }
      } catch (e) {
        setLoading(false);
        errorContextDefaultErrorFeedback(e);
      }
    });
  }, [marketListingViewModel, quoteToken]);

  return (
    <>
      <motion.div
        layoutId={marketListingViewModel.id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        whileHover={{
          scaleX: 1.01,
          scaleY: 1.02,
        }}
        whileTap={{
          scale: 0.98,
        }}
      >
        <Card
          sx={{
            display: "grid",
            gridTemplateColumns: {
              md: "repeat(3, 1fr)",
              xs: "1fr 1fr",
            },
            alignItems: "center",
            width: "100%",
            px: {
              sm: 3,
              xs: 1,
            },
            py: 1,
          }}
          onClick={() => {
            exchangeStore.setMarketListingPair({
              base: marketListingViewModel.token,
              counter: quoteToken,
            });
            exchangeStore.setSelectedMarketListing(marketListingViewModel);
            navigate(`${marketListingViewModel.token.code}/${quote}`);
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconPair
              firstToken={marketListingViewModel.token}
              secondToken={quoteToken}
              size={mdDown ? 24 : 42}
            />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: {
                    xs: "normal",
                    sm: "bold",
                  },
                }}
              >
                {marketListingViewModel.token.code}/{quoteToken.code}
              </Typography>
              {mdDown &&
                (lastTradedPrice ? (
                  <Amount
                    valueTypographyProps={{
                      variant: "body1",
                    }}
                    amount={lastTradedPrice}
                  />
                ) : (
                  <Skeleton width="60px" height="10px" />
                ))}
            </Box>
          </Box>

          {/* price */}
          {!mdDown &&
            (lastTradedPrice ? (
              <Box sx={{ py: 1 }}>
                <Amount amount={lastTradedPrice} />
              </Box>
            ) : (
              <Skeleton width="200px" height="34px" />
            ))}

          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {spotMechanism && !smDown && (
              <>
                <ButtonBase
                  sx={{
                    width: {
                      xs: 40,
                      sm: 80,
                    },
                    px: {
                      xs: 1,
                      sm: 3,
                    },
                    py: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                  onClick={(e) => {
                    if (e.stopPropagation) {
                      e.stopPropagation();
                    }
                    setIsBuy(true);
                    setShowSpotDialog(true);
                  }}
                >
                  Buy
                </ButtonBase>

                <Box
                  sx={(theme) => ({
                    borderLeft: `1px solid ${theme.palette.text.disabled}`,
                    width: 0,
                    height: 32,
                    mx: {
                      xs: 0,
                      sm: 1,
                    },
                  })}
                />

                <ButtonBase
                  sx={{
                    width: {
                      xs: 40,
                      sm: 80,
                    },
                    px: {
                      xs: 1,
                      sm: 3,
                    },
                    py: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                  onClick={(e) => {
                    if (e.stopPropagation) {
                      e.stopPropagation();
                    }
                    setIsBuy(false);
                    setShowSpotDialog(true);
                  }}
                >
                  Sell
                </ButtonBase>
              </>
            )}

            {!smDown ? (
              <Button
                variant="outlined"
                sx={{
                  ml: 2,
                }}
              >
                View
              </Button>
            ) : (
              <>
                <Box ref={menuRef}>
                  <IconButton
                    onClick={(e) => {
                      if (e.stopPropagation) {
                        e.stopPropagation();
                      }
                      setMenu(true);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                <Menu
                  anchorEl={menuRef.current}
                  open={menu}
                  onClose={() => setMenu(false)}
                >
                  <MenuItem
                    onClick={() => {
                      exchangeStore.setMarketListingPair({
                        base: marketListingViewModel.token,
                        counter: quoteToken,
                      });
                      exchangeStore.setSelectedMarketListing(
                        marketListingViewModel,
                      );
                      navigate(`${marketListingViewModel.token.code}/${quote}`);
                    }}
                  >
                    View Exchange
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      if (e.stopPropagation) {
                        e.stopPropagation();
                      }
                      setIsBuy(true);
                      setShowSpotDialog(true);
                      setMenu(false);
                    }}
                  >
                    Buy
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      if (e.stopPropagation) {
                        e.stopPropagation();
                      }
                      setIsBuy(true);
                      setShowSpotDialog(true);
                      setMenu(false);
                    }}
                  >
                    Sell
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Card>
      </motion.div>
      {spotMechanism && showSpotDialog && (
        <SpotTradeDialog
          baseToken={marketListingViewModel.token}
          closeDialog={() => setShowSpotDialog(false)}
          openAsBuy={isBuy}
        />
      )}
    </>
  );
};
