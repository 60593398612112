import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Box,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import background from "assets/images/background/background.png";

export const MUADialog = (props: {
  open: boolean;
  onSubmit: (code: string) => void;
  onCancel: () => void;
}) => {
  const theme = useTheme();
  const [value, setValue] = useState("");

  const handleOnChange = (val: string) => {
    val = val.replace(/[^0-9]/g, "");
    setValue(val);
  };

  return (
    <Dialog open={props.open}>
      <DialogContent>
        <div>
          <Grid
            item
            sx={{
              display: "grid",
              gridTemplateColumns: "120px auto",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: 120,
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                padding: "16px",
              }}
            >
              <img alt="" width="100%" src={meshMiniLogo} />
            </Box>
          </Grid>
          <Box
            sx={{
              backgroundColor: theme.palette.background.default,
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.custom.midnight,
                padding: "48px",
              }}
            >
              <Typography
                variant={"body1"}
                children={
                  "Please enter the code sent by an authorising user for multi user authentication"
                }
              />
              <TextField
                fullWidth
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                id="MUADialog-code-textField"
                value={value}
                onChange={(e) => handleOnChange(e.target.value)}
              />
              <Button
                sx={{ margin: "16px" }}
                variant="outlined"
                id="MUADialog-cancel-button"
                children="Cancel"
                onClick={props.onCancel}
              />
              <Button
                sx={{ margin: "16px" }}
                variant="outlined"
                id="MUADialog-confirm-button"
                children="Confirm"
                onClick={() => props.onSubmit(value)}
              />
            </Box>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
};
