import React, { useEffect, useState } from "react";
import { useApplicationContext } from "context/Application/Application";
import { UserGuide } from "james/user/User";

/**
 The PlatformGuidedJourneyRenderDelay delays the rendering of a component if
 the user has is going through the platform guided journey.
 This is necessary in certain cases where we need to register a component without
 immediately rendering its child components for improved UI/UX.
 */
export const PlatformGuidedJourneyRenderDelay = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { loginClaims } = useApplicationContext();
  const [show, setShow] = useState(() => {
    return loginClaims.completedGuides.includes(
      UserGuide.PlatformGuidedJourney,
    );
  });

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 10);
  }, []);

  return show ? <>{children}</> : <></>;
};
