import {
  allAssetClasses,
  assetClassToString,
  smartInstrumentAttributeTypeToString,
} from "@mesh/common-js/dist/financial";
import { AssetClassSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeAssetClass_pb";
import { MenuItem } from "@mui/material";
import React from "react";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import { ValidationResult } from "common/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { AssetClass } from "@mesh/common-js/dist/financial/assetClass_pb";

export type AssetClassAttributeFormProps = {
  assetClassAttribute: AssetClassSmartInstrumentAttribute;
  onChange: (
    updatedAssetClassAttribute: AssetClassSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const AssetClassAttributeForm = (
  props: AssetClassAttributeFormProps,
) => {
  const fieldValidationResult = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ];
  };

  return (
    <TextField
      size="small"
      fullWidth
      id={"assetClassAttributeForm-assetClassAttribute-selectField"}
      label={"Asset Class"}
      disabled={props.disabled}
      readOnly={props.readOnly}
      select
      placeholder="Select..."
      error={!!fieldValidationResult("assetClass")}
      helperText={fieldValidationResult("assetClass")}
      value={props.assetClassAttribute.getAssetclass()}
      onChange={(e) => {
        props.onChange(
          props.assetClassAttribute.setAssetclass(
            Number(e.target.value) as AssetClass,
          ),
        );
      }}
    >
      {allAssetClasses.map((v) => {
        return (
          <MenuItem key={v} value={v}>
            {assetClassToString(v)}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
