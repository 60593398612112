import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";

export interface RunAfterEffectIDAsyncRequest {
  context: Context;
  afterEffectID: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RunAfterEffectIDAsyncResponse {}

export interface ValidationPhaseControllerServiceProvider {
  serviceProvider: string;

  RunAfterEffectIDAsync(
    request: RunAfterEffectIDAsyncRequest,
  ): Promise<RunAfterEffectIDAsyncResponse>;
}

const ValidationPhaseControllerServiceProviderName =
  "consistency-AfterEffectRunnerAsync";

export const AfterEffectRunnerAsync: ValidationPhaseControllerServiceProvider =
  {
    serviceProvider: ValidationPhaseControllerServiceProviderName,
    async RunAfterEffectIDAsync(
      request: RunAfterEffectIDAsyncRequest,
    ): Promise<RunAfterEffectIDAsyncResponse> {
      return jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${AfterEffectRunnerAsync.serviceProvider}.RunAfterEffectIDAsync`,
        request,
      });
    },
  };
