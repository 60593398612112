import config from "react-global-configuration";
import { Amount } from "james/ledger";
import { Context } from "../security";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface CalculateAccountDefundingFeesRequest {
  context: Context;
  defundAmount: Amount;
  express: boolean;
}

export interface CalculateAccountDefundingFeesResponse {
  feeAmount: Amount;
  vatAmount: Amount;
}

export interface CalculateAccountMaxDefundAmountRequest {
  context: Context;
  available: Amount;
}

export interface CalculateAccountMaxDefundAmountResponse {
  maxDefundAmount: Amount;
}

const AccountDefundingFeeCalculatorServiceProviderName =
  "remuneration-AccountDefundingFeeCalculator";

export const AccountDefundingFeeCalculator = {
  async CalculateAccountDefundingFees(
    request: CalculateAccountDefundingFeesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<CalculateAccountDefundingFeesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${AccountDefundingFeeCalculatorServiceProviderName}.CalculateAccountDefundingFees`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return {
      feeAmount: new Amount(response.feeAmount),
      vatAmount: new Amount(response.vatAmount),
    };
  },
  async CalculateAccountMaxDefundAmount(
    request: CalculateAccountMaxDefundAmountRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<CalculateAccountMaxDefundAmountResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${AccountDefundingFeeCalculatorServiceProviderName}.CalculateAccountMaxDefundAmount`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { maxDefundAmount: new Amount(response.maxDefundAmount) };
  },
};
