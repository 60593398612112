export enum TokenCode {
  mZAR = "mZAR",
  USDC = "USDC",
}

export enum CurrencyCode {
  ZAR = "ZAR",
  USD = "USD",
}

export const mapToStandardCurrency = (txnCurrency: string): string => {
  if (txnCurrency.toLowerCase() === TokenCode.mZAR.toLowerCase()) {
    return CurrencyCode.ZAR;
  }

  if (txnCurrency.toLowerCase() === TokenCode.USDC.toLowerCase()) {
    return CurrencyCode.USD;
  }
  return txnCurrency;
};
