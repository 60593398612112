import {
  Typography,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Model as FinancialPaymentViewModel } from "@mesh/common-js/dist/views/financialPaymentView/model_pb";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";
import { useAPIContext } from "context/API";
import { FundPaymentRequest } from "@mesh/common-js/dist/financial/paymentFunder_pb";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification";
import { Notification } from "james/notification/Notification";
import { useState } from "react";

export type ConfirmationModalTypes = {
  payment: FinancialPaymentViewModel;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConfirmationModal = (props: ConfirmationModalTypes) => {
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { registerNotificationCallback } = useNotificationContext();
  const {
    financial: { paymentFunder },
  } = useAPIContext();

  const [loading, setLoading] = useState(false);
  const handleConfirmation = async () => {
    if (!props.payment) {
      return;
    }

    setLoading(true);

    // fund and get funding transaction id
    let fundingTransactionID: string;
    try {
      fundingTransactionID = (
        await paymentFunder.fundPayment(
          new FundPaymentRequest()
            .setContext(authContext.toFuture())
            .setPaymentid(props.payment.getPaymentid())
            .setAmount(props.payment.getAmount()),
        )
      ).getFundingtransactionid();

      // close dialog
      props.setOpen(false);

      // indicate that funding has begun
      enqueueSnackbar("Funding in Progress", {
        variant: "success",
      });
    } catch (e) {
      console.error(`error performing funding: ${e}`);
      enqueueSnackbar(`Error Performing Funding - Please Try Again`);
      setLoading(false);
      return;
    }

    try {
      // register callback to fire once the deposit has been submitted
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: fundingTransactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (n instanceof TransactionSucceededNotification) {
            enqueueSnackbar("Success! Funding Complete", {
              variant: "success",
            });
          }

          if (n instanceof TransactionFailedNotification) {
            enqueueSnackbar("Error! Funding Failed", {
              variant: "error",
            });
          }

          if (n instanceof TransactionSubmissionResolutionFailedNotification) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the funding and its status is being investigated",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error(
        "error registering for funding transaction notifications",
        e,
      );
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Funding Transaction - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  return (
    <Dialog open={props.open} aria-labelledby="payment-confirmation-modal">
      <DialogTitle
        sx={{
          p: 2,
          minWidth: 400,
        }}
      >
        <Typography variant="h5">Confirm Funding</Typography>
        {loading && <CircularProgress size={15} />}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mt: 1, mb: 2, width: "100%" }}>
          You are about to fund:
        </Typography>
        <Amount
          valueTypographyProps={{ variant: "h5" }}
          amount={
            props.payment.getAmount()
              ? LedgerAmount.fromFutureAmount(props.payment.getAmount())
              : new LedgerAmount()
          }
          formatTextNumOpts={{ noDecimalPlaces: 7 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          disabled={loading}
          onClick={() => props.setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 3 }}
          disabled={loading}
          onClick={handleConfirmation}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
