import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";

export type ResolveTransactionIDSubmissionRequest = {
  context: Context;
  transactionID: string;
  ignoreConfirmationCount: boolean;
};

export type ResolveTransactionIDSubmissionResponse = Record<string, never>;

export const TransactionSubmissionResolverServiceProviderName =
  "ledger-TransactionSubmissionResolver";

export const TransactionSubmissionResolver = {
  async ResolveTransactionIDSubmission(
    request: ResolveTransactionIDSubmissionRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveTransactionIDSubmissionResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${TransactionSubmissionResolverServiceProviderName}.ResolveTransactionIDSubmission`,
        request,
      },
      opts,
    );
  },
};
