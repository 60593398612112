import { Entry } from "james/audit/Entry";

export enum AfterEffectState {
  Pending = "Pending",
  Complete = "Complete",
}

export const AllAfterEffectStates: AfterEffectState[] = [
  AfterEffectState.Pending,
  AfterEffectState.Complete,
];

export class AfterEffect {
  public id = "";

  public transactionID = "";

  public state: AfterEffectState | "" = "";

  public lastActionAnnotation = "";

  public serviceURI = "";

  public payload = "";

  public metaData: { [key: string]: string } = {};

  public auditEntry: Entry = new Entry();

  constructor(afterEffect?: AfterEffect) {
    if (!afterEffect) {
      return;
    }
    this.id = afterEffect.id;
    this.transactionID = afterEffect.transactionID;
    this.state = afterEffect.state;
    this.auditEntry = new Entry(afterEffect.auditEntry);
    this.serviceURI = afterEffect.serviceURI;
    this.payload = afterEffect.payload;
    this.lastActionAnnotation = afterEffect.lastActionAnnotation;
    this.metaData = { ...afterEffect.metaData };
  }
}
