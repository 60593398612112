import React, { useState } from "react";
import { ListingContextProvider, useListingContext, ViewMode } from "./Context";
import { alpha, Box } from "@mui/system";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import { ListingStateChip } from "./ListingStateChip";
import { allMechanismTypes, Mechanism, MechanismType } from "james/market";
import {
  Close as DeleteIcon,
  FaceOutlined as FaceIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import {
  SubscriptionMarketMechanismForm,
  DirectOrderMarketMechanismForm,
  SpotMarketMechanismForm,
  ToggleActivationDialog,
} from "./components";
import { useApplicationContext } from "context/Application/Application";
import { ListingState } from "james/market/Listing";
import { LimitOrderMechanismForm } from "./components/LimitOrderMechanismForm/LimitOrderMechanismForm";

export const Listing = () => {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Smart Instruments"]?.ReadUNSCOPED;

  return (
    <ListingContextProvider system={system}>
      <ListingBodyWrapper />
    </ListingContextProvider>
  );
};

const ListingBodyWrapper = () => {
  const { initialisationError, clearInitialisationError, initialised } =
    useListingContext();

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 72,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Listing"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>Something went wrong while initialsing.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (!initialised) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 72,
        }}
      >
        <Card>
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <CircularProgress size={70} />
            <Typography
              variant="h5"
              color="textSecondary"
              children="Getting things ready for you..."
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return <ListingBody />;
};

const ListingBody = () => {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Smart Instruments"]?.ReadUNSCOPED;
  const {
    formData,
    formUpdater,
    formDataValidationResult,
    viewMode,
    setEditViewMode,
    apiCallInProgress,
    performSave,
    retryListing,
    canEditListingInGroup,
    reload,
  } = useListingContext();

  const [showToggleActivationDialog, setShowToggleActivationDialog] =
    useState(false);

  const readOnly = viewMode === ViewMode.View;

  const [storedSelectedMarketMechanismIdx, setSelectedMarketMechanismIdx] =
    useState(formData.listing.marketMechanisms.length > 0 ? 0 : -1);
  const selectedMarketMechanismIdx =
    storedSelectedMarketMechanismIdx >
    formData.listing.marketMechanisms.length - 1
      ? 0
      : storedSelectedMarketMechanismIdx;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          padding: theme.spacing(1),
          alignItems: "center",
          gap: theme.spacing(1),
        })}
      >
        <Typography variant="h5">
          Manage Listing for <b>{formData.smartInstrumentToList.getName()}</b>
        </Typography>
        <ListingStateChip
          sx={{ marginRight: "auto" }}
          state={formData.listing.state}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          {(() => {
            const actions: React.ReactNode[] = [];

            // show action buttons if user has write permission (in the group that owns the instrument)
            if (
              viewConfiguration["Market Listing"]?.Write &&
              canEditListingInGroup(formData.smartInstrumentToList.getOwnerid())
            ) {
              // if listing doesn't exist yet then show only create button
              if (formData.listing.id === "") {
                actions.push(
                  <Tooltip
                    placement="top"
                    title={(() => {
                      if (!formDataValidationResult.valid) {
                        const problems: string[] = [];
                        Object.keys(
                          formDataValidationResult.fieldValidations,
                        ).forEach((k) => {
                          if (formDataValidationResult.fieldValidations[k]) {
                            problems.push(
                              `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                            );
                          }
                        });
                        return (
                          <Box>
                            <Typography>
                              All problems need to be resolved before listing
                              can be created:
                            </Typography>
                            <ul>
                              {problems.map((p, idx) => (
                                <li key={idx}>{p}</li>
                              ))}
                            </ul>
                          </Box>
                        );
                      }
                    })()}
                  >
                    <span>
                      <Button
                        id={"marketListings-createListing-button"}
                        variant="contained"
                        color="primary"
                        disabled={
                          !formDataValidationResult.valid || apiCallInProgress
                        }
                        onClick={performSave}
                      >
                        Create Listing
                      </Button>
                    </span>
                  </Tooltip>,
                );
              } else {
                // listing already exists

                // prepare save/edit button
                const saveEditButton =
                  viewMode === ViewMode.Edit ? (
                    <Tooltip
                      placement="top"
                      title={(() => {
                        if (!formDataValidationResult.valid) {
                          const problems: string[] = [];
                          Object.keys(
                            formDataValidationResult.fieldValidations,
                          ).forEach((k) => {
                            if (formDataValidationResult.fieldValidations[k]) {
                              problems.push(
                                `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                              );
                            }
                          });
                          return (
                            <Box>
                              <Typography>
                                All problems need to be resolved before changes
                                can be saved:
                              </Typography>
                              <ul>
                                {problems.map((p, idx) => (
                                  <li key={idx}>{p}</li>
                                ))}
                              </ul>
                            </Box>
                          );
                        }
                      })()}
                    >
                      <span>
                        <Button
                          id={"marketListings-save-button"}
                          variant="contained"
                          color="primary"
                          disabled={
                            !formDataValidationResult.valid || apiCallInProgress
                          }
                          onClick={performSave}
                        >
                          Save
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <Button
                      id={"marketListings-edit-button"}
                      variant="contained"
                      color="primary"
                      disabled={apiCallInProgress}
                      onClick={setEditViewMode}
                    >
                      Edit
                    </Button>
                  );

                // prepare activate / deactivatate
                const activateDeactivateButton = (
                  <Button
                    id={"marketListings-activateDeactivate-button"}
                    variant="contained"
                    color="primary"
                    disabled={apiCallInProgress}
                    onClick={() => setShowToggleActivationDialog(true)}
                  >
                    {formData.listing.state === ListingState.Active
                      ? "Deactivate"
                      : "Activate"}
                  </Button>
                );

                // show different buttons depending on state
                switch (formData.listing.state) {
                  case ListingState.Active:
                    actions.push(activateDeactivateButton);
                    actions.push(saveEditButton);
                    break;

                  case ListingState.Inactive:
                    actions.push(activateDeactivateButton);
                    actions.push(saveEditButton);
                    break;

                  case ListingState.ListingInProgress:
                    // show no buttons while listing is in progress
                    break;

                  case ListingState.ListingFailed:
                    // show retry button
                    actions.push(
                      <Button
                        id={"marketListings-retryListing-button"}
                        variant="contained"
                        color="primary"
                        disabled={apiCallInProgress}
                        onClick={retryListing}
                      >
                        Retry Listing
                      </Button>,
                    );
                    break;
                }
              }
            }

            actions.push(
              <Tooltip title={"Refresh"}>
                <span>
                  <IconButton
                    onClick={reload}
                    disabled={apiCallInProgress}
                    id={"listing-refresh-iconButton"}
                    size={"small"}
                  >
                    <ReloadIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            );

            return actions;
          })().map((a, idx) => (
            <React.Fragment key={`listing-action-${idx}`}>{a}</React.Fragment>
          ))}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grapeLight,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        })}
      >
        <Box
          sx={(theme) => ({
            minHeight: 48,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1),
          })}
        >
          {!!formData.listing.marketMechanisms.length && (
            <Tabs
              scrollButtons
              visibleScrollbar
              // ensure that a marketmechanism is always selected - this code makes sure that a reasonable marketmechanism is still selected with a marketmechanism is deleted
              value={selectedMarketMechanismIdx}
            >
              {formData.listing.marketMechanisms.map(
                (marketMechanism: Mechanism, marketmechanismIdx: number) => {
                  return (
                    <Tab
                      id={`smartInstrumentForm-marketmechanism${marketmechanismIdx}-tab`}
                      key={marketmechanismIdx}
                      value={marketmechanismIdx}
                      label={
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: theme.spacing(0.5),
                          })}
                        >
                          <Typography>{marketMechanism.type}</Typography>
                          {viewMode === ViewMode.Edit && (
                            <Tooltip
                              placement="top"
                              title={"Delete Market Mechanism"}
                            >
                              <IconButton
                                id={`marketListings-marketMechanism${marketmechanismIdx}-delete-tabIconButon`}
                                onClick={() =>
                                  formUpdater.removeMarketMechanism(
                                    marketMechanism.type,
                                  )
                                }
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  padding: "4px",
                                }}
                              >
                                <DeleteIcon sx={{ fontSize: "16px" }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      }
                      onClick={() =>
                        setSelectedMarketMechanismIdx(marketmechanismIdx)
                      }
                    />
                  );
                },
              )}
            </Tabs>
          )}
          {viewMode === ViewMode.Edit && (
            <TextField
              size="small"
              sx={(theme) => ({
                width: 200,
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1),
              })}
              id={
                "smartInstrumentForm-potentialMarketMechanismType-selectField"
              }
              disabled={apiCallInProgress}
              label="Add Market Mechanism"
              select
              placeholder="Select..."
              value={"-"}
              onChange={(e) => {
                formUpdater.addMarketMechanism(e.target.value as MechanismType);
                setSelectedMarketMechanismIdx(
                  formData.listing.marketMechanisms.length - 1,
                );
              }}
            >
              {[...allMechanismTypes, "-"]
                .filter(
                  (mechanismType) =>
                    !formData.listing.marketMechanisms.find(
                      (m) => m.type === mechanismType,
                    ),
                )
                .map((v) => {
                  return (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  );
                })}
            </TextField>
          )}
        </Box>
      </Box>
      <Box
        className="meshScroll"
        sx={{
          height: window.innerHeight - 176,
          overflowY: "auto",
          overflowX: "hidden",
          padding: 2,
        }}
      >
        {(() => {
          const marketMechanism =
            formData.listing.marketMechanisms[selectedMarketMechanismIdx];
          if (!marketMechanism) {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <FaceIcon
                    sx={(theme) => ({
                      fontSize: 110,
                      color: alpha(theme.palette.background.default, 0.5),
                    })}
                  />
                  <Typography
                    color="secondary"
                    variant="h4"
                    children="No Mechanisms Added Yet!"
                  />
                  <Typography
                    variant="body2"
                    children={"Add at Least 1 Mechanism"}
                  />
                </Box>
              </Box>
            );
          }

          switch (marketMechanism.type) {
            case MechanismType.Subscription:
              return (
                <SubscriptionMarketMechanismForm
                  listing={formData.listing}
                  system={system}
                  ledgerTokenViewModels={formData.ledgerTokenViewModels}
                  marketMechanism={marketMechanism}
                  smartInstrument={formData.smartInstrumentToList}
                  onUpdate={(updatedMarketMechanism) => {
                    formUpdater.updateMarketMechanism(updatedMarketMechanism);
                  }}
                  disabled={apiCallInProgress}
                  readOnly={readOnly}
                  formDataValidationResult={formDataValidationResult}
                />
              );

            case MechanismType.DirectOrder:
              return (
                <DirectOrderMarketMechanismForm
                  ledgerTokenViewModels={formData.ledgerTokenViewModels}
                  marketMechanism={marketMechanism}
                  onUpdate={formUpdater.updateMarketMechanism}
                  disabled={apiCallInProgress}
                  readOnly={readOnly}
                  formDataValidationResult={formDataValidationResult}
                />
              );

            case MechanismType.Spot:
              return (
                <SpotMarketMechanismForm
                  ledgerTokenViewModels={formData.ledgerTokenViewModels}
                  marketMechanism={marketMechanism}
                  onUpdate={formUpdater.updateMarketMechanism}
                  disabled={apiCallInProgress}
                  readOnly={readOnly}
                  formDataValidationResult={formDataValidationResult}
                />
              );

            case MechanismType.LimitOrder:
              return (
                <LimitOrderMechanismForm
                  ledgerTokenViewModels={formData.ledgerTokenViewModels}
                  marketMechanism={marketMechanism}
                  onUpdate={formUpdater.updateMarketMechanism}
                  disabled={apiCallInProgress}
                  readOnly={readOnly}
                  formDataValidationResult={formDataValidationResult}
                />
              );

            default:
              return "Invalid Mechanism";
          }
        })()}
      </Box>
      {showToggleActivationDialog && (
        <ToggleActivationDialog
          onClose={() => {
            setShowToggleActivationDialog(false);
            reload();
          }}
        />
      )}
    </Paper>
  );
};
