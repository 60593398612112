import { Amount } from "james/ledger";
import dayjs from "dayjs";

export class IndicativePriceDataPoint {
  public buyPrice: Amount = new Amount();

  public sellPrice: Amount = new Amount();

  public date: string = dayjs().format();

  constructor(i?: IndicativePriceDataPoint) {
    if (!i) {
      return;
    }
    this.date = i.date;
    this.buyPrice = new Amount(i.buyPrice);
    this.sellPrice = new Amount(i.sellPrice);
  }
}
