import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface AuthorizeChannelRequest {
  channelName: string;
  socketID: string;
}

export interface AuthorizeChannelResponse {
  authorizationString: string;
}

const ChannelAuthorizerServiceProviderName = "pusher-ChannelAuthorizer";

export const ChannelAuthorizer = {
  async AuthorizeChannel(
    request: AuthorizeChannelRequest,
  ): Promise<AuthorizeChannelResponse> {
    return jsonRPCRequest({
      url: `${config.get("meshURL")}/api/pusher/channel-authorizer`,
      method: `${ChannelAuthorizerServiceProviderName}.AuthorizeChannel`,
      request,
    });
  },
};
