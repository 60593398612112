import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";

const PREFIX = "Review";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitle}`]: {
    padding: theme.spacing(1, 0),
  },
}));

interface ReviewProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  onClose: () => void;
  open: boolean;
}

export function Review(props: ReviewProps) {
  const { onNext, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleProceed = () => {
    onNext(CompanyRegStep.clientAdministratorData)();
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography className={classes.dialogTitle} variant="h5">
          Fields Required
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Not all required fields have been completed. Please review the
          information you have supplied.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          id="companyRegistration-reviewNotification-button"
          onClick={handleProceed}
          variant="contained"
          color="primary"
        >
          Review
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
