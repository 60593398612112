import { ClaimsType } from "james/security/claims";

export class InviteClient {
  public type: ClaimsType = ClaimsType.InviteClientClaimsType;

  public invitingUserID = "";

  public companyName = "";

  public clientType = "";

  public expirationTime = 0;

  public clientShortName = "";

  constructor(inviteClient?: InviteClient) {
    if (!inviteClient) {
      return;
    }
    this.type = inviteClient.type;
    this.invitingUserID = inviteClient.invitingUserID;
    this.companyName = inviteClient.companyName;
    this.clientShortName = inviteClient.clientShortName;
    this.clientType = inviteClient.clientType;
    this.expirationTime = inviteClient.expirationTime;
  }

  public static NewFromJWT(jwt: string): InviteClient {
    const claimsPayloadData = jwt.substring(
      jwt.indexOf(".") + 1,
      jwt.lastIndexOf("."),
    );
    const payloadString = atob(claimsPayloadData);
    const tokenPOJO = JSON.parse(payloadString);

    return new InviteClient(tokenPOJO);
  }
}
