import { Asset, Horizon } from "stellar-sdk";
import { ServerClient } from "./ClientServer";
import { BigNumber } from "bignumber.js";

export interface Order {
  price: BigNumber;
  amount: BigNumber;
  total: BigNumber;
}

export interface OrderBookProps {
  bids: Order[];
  asks: Order[];
  precision: BigNumber;
}

export class OrderBook {
  bids: Order[] = [];
  asks: Order[] = [];
  aggregatedBids: Order[] = [];
  aggregatedAsks: Order[] = [];
  precision: BigNumber = new BigNumber(0.1);

  constructor(props?: OrderBookProps) {
    if (!props) return;
    this.asks = props.asks;
    this.bids = props.bids;
    this.aggregatedBids = this.aggregateOrders(
      props.bids,
      props.precision,
    ).sort((a, b) => b.price.minus(a.price).toNumber());
    this.aggregatedAsks = this.aggregateOrders(
      props.asks,
      props.precision,
    ).sort((a, b) => a.price.minus(b.price).toNumber());
    this.precision = props.precision;
  }

  public static fromOrderBookRecord(
    orderBookRecord: Horizon.ServerApi.OrderbookRecord,
    precision: BigNumber,
  ): OrderBook {
    const bids = orderBookRecord.bids
      .map((bid) => {
        return {
          price: new BigNumber(parseFloat(bid.price)),
          amount: new BigNumber(parseFloat(bid.amount)).dividedBy(
            new BigNumber(parseFloat(bid.price)),
          ),
          total: new BigNumber(parseFloat(bid.amount)),
        };
      })
      .sort((a, b) => b.price.minus(a.price).toNumber());

    const asks = orderBookRecord.asks
      .map((ask) => {
        return {
          price: new BigNumber(parseFloat(ask.price)),
          amount: new BigNumber(parseFloat(ask.amount)),
          total: new BigNumber(parseFloat(ask.amount)).multipliedBy(
            new BigNumber(parseFloat(ask.price)),
          ),
        };
      })
      .sort((a, b) => b.price.minus(a.price).toNumber());

    return new OrderBook({
      bids: bids,
      asks: asks,
      precision: precision,
    });
  }

  public getHighestOrder = () => {
    let highestOrder = new BigNumber(0);
    for (const ask of this.aggregatedAsks) {
      if (ask.amount.gte(highestOrder)) {
        highestOrder = ask.amount;
      }
    }
    for (const bid of this.aggregatedBids) {
      if (bid.amount.gte(highestOrder)) {
        highestOrder = bid.amount;
      }
    }

    return highestOrder;
  };

  private aggregateOrders(orders: Order[], precision: BigNumber) {
    const newOrders: Record<number, Order> = {};
    let base = new BigNumber(0);
    let i = 0;
    for (const order of orders) {
      if (order.price.minus(base).absoluteValue().gte(precision) || i === 0) {
        base = order.price;
        newOrders[base.toNumber()] = {
          price: base,
          amount: order.amount,
          total: order.total,
        };
        i++;
        continue;
      }

      newOrders[base.toNumber()] = {
        price: base,
        amount: newOrders[base.toNumber()].amount.plus(order.amount),
        total: newOrders[base.toNumber()].total.plus(order.total),
      };
      i++;
    }

    return Object.keys(newOrders).map((key) => newOrders[+key]);
  }
}

export type FetchOrderBookRequest = {
  baseAsset: Asset;
  counterAsset: Asset;
  cursor?: string;
  order?: "desc" | "asc";
  limit?: number;
};

export type FetchOrderBookResponse = {
  orderBook: OrderBook;
};

export type NewStellarOrdersViewer = {
  client: ServerClient;
};

export type StreamOrdersRequest = {
  baseAsset: Asset;
  counterAsset: Asset;
  onUpdate: (orderBook: Horizon.ServerApi.OrderbookRecord) => void;
  onError: () => void;
  limit?: number;
  order: "desc" | "asc";
};

export class StellarOrdersViewer {
  private readonly stellarClient: ServerClient;

  constructor(props: NewStellarOrdersViewer) {
    this.stellarClient = props.client;
  }

  async FetchOrderBook(
    request: FetchOrderBookRequest,
  ): Promise<FetchOrderBookResponse> {
    try {
      const response = await this.stellarClient.orderBook(
        request.baseAsset,
        request.counterAsset,
        request.cursor,
        request.order,
        request.limit,
      );

      return {
        orderBook: OrderBook.fromOrderBookRecord(response, new BigNumber(1)),
      };
    } catch (e) {
      console.error(e);
      throw new Error(`FetchOrdersForPair: ${e}`);
    }
  }

  async StreamOrderBook(request: StreamOrdersRequest) {
    try {
      return this.stellarClient.streamOrderBook(
        request.baseAsset,
        request.counterAsset,
        request.order,
        request.onUpdate,
        request.onError,
        request.limit,
      );
    } catch (err) {
      console.error(err);
    }
  }
}
