import { Box } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import React, { useCallback } from "react";
import { countries } from "james/country";
import { useFirebaseContext } from "context/Firebase";
import { useNavigate } from "react-router-dom";
import { CountrySelectorCard } from "./components/CountrySelectorCard/CountrySelectorCard";
import { useWidth } from "utilities/general";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import { useUserRegistrationHelper } from "hooks/helperAPI/useUserRegistrationHelper";
import { UserRegisteringLoader } from "components/Loaders";
import background from "assets/images/background/background.png";

interface CountrySelectorProps {
  selectedCountry: (countryCode: string) => void;
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const { firebaseAuthenticated, firebaseUserIDToken, firebaseUser } =
    useFirebaseContext();
  const navigate = useNavigate();
  const { registerIndividualUser, individualUserRegistrationInProgress } =
    useUserRegistrationHelper();
  const width = useWidth();

  const handleCountryOfResidenceSelection = useCallback(
    async (countryCode: string) => {
      if (countryCode === countries[200].value) {
        if (firebaseAuthenticated) {
          // start the user registration process once the user click submit c
          if (!firebaseUser?.emailVerified) {
            navigate("/sign-up/verify-email");
          } else {
            await registerIndividualUser(firebaseUserIDToken);
          }
        } else {
          navigate("/sign-up/register");
        }
      } else {
        props.selectedCountry(countryCode);
        navigate("/sign-up/non-sa-client");
      }
    },
    [
      firebaseAuthenticated,
      firebaseUserIDToken,
      registerIndividualUser,
      firebaseUser,
    ],
  );
  if (individualUserRegistrationInProgress) {
    return <UserRegisteringLoader />;
  }

  return (
    <>
      {isWidthUp("sm", width) ? (
        <Box
          sx={{
            display: "grid",
            backgroundImage: `url(${background})`,
            gridTemplateRows: "auto 1fr",
            backgroundSize: "cover",
            height: "100vh",
            overflowY: isWidthUp("md", width) ? "hidden" : "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            <CountrySelectorCard
              onSubmit={handleCountryOfResidenceSelection}
              onGoBack={() => navigate("/sign-up")}
            />
          </Box>
        </Box>
      ) : (
        <>
          <CountrySelectorCard
            onSubmit={handleCountryOfResidenceSelection}
            onGoBack={() => navigate("/sign-up")}
          />
        </>
      )}
    </>
  );
};
