import { ValidationResult } from "common/validation";

export type FormState = {
  firstName: string;
  lastName: string;
  acceptPaasAndAusa: boolean;
  profilePictureURL: string;
};

export type FormUpdaterSpecsType = {
  // my person fields
  firstName: (value: string, prevState?: FormState) => FormState;
  lastName: (value: string, prevState?: FormState) => FormState;
  // accepted Ausa and Paas field
  acceptPaasAndAusa: (value: boolean, prevState?: FormState) => FormState;
  //store profile picture
  profilePictureUrl: (value: string, prevState?: FormState) => FormState;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  firstName: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    firstName: value,
  }),
  lastName: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    lastName: value,
  }),
  acceptPaasAndAusa: (
    value: boolean,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    acceptPaasAndAusa: value,
  }),
  profilePictureUrl: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    profilePictureURL: value,
  }),
};

export const validationFunc = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!formState.firstName) {
    newValidationState.fieldValidations.firstName = "Please enter first name";
  }
  if (!formState.lastName) {
    newValidationState.fieldValidations.lastName = "Please enter last name";
  }

  return newValidationState;
};

const initializeState = (): FormState => {
  const acceptPaasAndAusa = false;
  return {
    firstName: "",
    lastName: "",
    acceptPaasAndAusa,
    profilePictureURL: "",
  };
};

export const initialState = initializeState();
