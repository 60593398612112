import { Entry } from "../audit/Entry";

export enum KeyType {
  Stellar = "Stellar",
  Ethereum = "Ethereum",
  EOS = "EOS",
}

export enum CustodianType {
  User = "User",
  Mesh = "Mesh",
}

export class Key {
  public id = "";

  public ownerGroupID = "";

  public ownerUserID = "";

  public publicKey = "";

  public type: KeyType | "" = "";

  public custodianType: CustodianType | "" = "";

  public auditEntry: Entry = new Entry();

  constructor(key?: Key) {
    if (!key) {
      return;
    }

    this.id = key.id;
    this.ownerGroupID = key.ownerGroupID;
    this.ownerUserID = key.ownerUserID;
    this.publicKey = key.publicKey;
    this.type = key.type;
    this.custodianType = key.custodianType;
    this.auditEntry = new Entry(key.auditEntry);
  }
}
