import { Amount } from "james/ledger";
import { ValidationResult } from "common/validation";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import { Model as RecipientModel } from "james/views/stellarRecipientView";
import { Fee } from "james/remuneration/Fee";
import BigNumber from "bignumber.js";

export type State = {
  userID: string;
  transferAmount: Amount;
  mZARBalance: Amount;
  transferTokenAvailableBalance: Amount;
  transferFees: Fee[] | undefined;
  ledgerAccountModel: StellarAccountViewModel;
  selectedRecipient: RecipientModel | null;
};

export type FormUpdaterSpecsType = {
  userID: (value: string, prevState?: State) => State;
  transferTokenAvailableBalance: (value: Amount, prevState?: State) => State;
  mZARBalance: (value: Amount, prevState?: State) => State;
  transferAmount: (value: Amount, prevState?: State) => State;
  transferFees: (value: Fee[] | undefined, prevState?: State) => State;
  ledgerAccountModel: (
    value: StellarAccountViewModel,
    prevState?: State,
  ) => State;
  selectedRecipient: (value: RecipientModel, prevState?: State) => State;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  userID(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      userID: value,
    };
  },
  transferTokenAvailableBalance(
    value: Amount,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      transferTokenAvailableBalance: value,
    };
  },
  transferAmount(value: Amount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferAmount: value,
    };
  },
  transferFees(value: Fee[] | undefined, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferFees: value,
    };
  },
  mZARBalance(value: Amount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      mZARBalance: value,
    };
  },
  ledgerAccountModel(
    value: StellarAccountViewModel,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      ledgerAccountModel: value,
    };
  },
  selectedRecipient(
    value: RecipientModel,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      selectedRecipient: value,
    };
  },
};

type UnfilteredValidationResultType = {
  valid: boolean;
  fieldValidations: { [key: string]: string | null };
};

export let UnfilteredValidationResult: UnfilteredValidationResultType = {
  valid: true,
  fieldValidations: {},
};

// define your validation func
export const validationFunc = async (
  formState: State,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  // validate that transfer amount is not less than 0.0002
  if (formState.transferAmount.value.lt(new BigNumber(0.00002))) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be less than 0.0000200";
  }

  // validate that transfer amount greater than 0
  if (formState.transferAmount.value.isZero()) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be empty or zero";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  if (
    formState.selectedRecipient &&
    formState.selectedRecipient.defaultMemo === ""
  ) {
    newValidationState.fieldValidations.selectedRecipient =
      "Memo on recipient cannot empty";
    newValidationState.fieldValidations.transferHoverText =
      "Memo on recipient cannot empty";
  }

  if (formState.transferFees) {
    // calculate total fee amount
    const totalFeeAmount = formState.transferFees.reduce(
      (total, current) =>
        current
          .feeAmount()
          .setValue(current.feeTotal().value.plus(total.value)),
      new Amount(),
    ).value;

    // in this scenario we want to validate if the total transfer amount is less than the total available
    // when transferred token and fee token are the same
    if (
      formState.mZARBalance.token.isEqualTo(
        formState.transferTokenAvailableBalance.token,
      )
    ) {
      if (
        totalFeeAmount
          .plus(formState.transferAmount.value)
          .gt(formState.transferTokenAvailableBalance.value)
      ) {
        newValidationState.fieldValidations.transferFees =
          "Insufficient balance for fees";
        newValidationState.fieldValidations.transferHoverText =
          "Insufficient balance for fees";
      }
    } else if (totalFeeAmount.gt(formState.mZARBalance.value)) {
      newValidationState.fieldValidations.transferFees =
        "Insufficient balance for fees";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance for fees";
    }

    if (
      formState.transferTokenAvailableBalance.value.lt(
        formState.transferAmount.value,
      )
    ) {
      newValidationState.fieldValidations.transferAmount =
        "Insufficient balance to make transfer";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance to make transfer";
    }
  }

  if (Object.keys(newValidationState.fieldValidations).length > 0) {
    newValidationState.valid = false;
  }

  UnfilteredValidationResult = newValidationState;
  return newValidationState;
};
