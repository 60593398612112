import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import meshMiniLogoNoWords from "assets/images/logo/meshLogoNoWords.svg";
import { useFirebaseContext } from "context/Firebase";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { TabContext, TabPanel } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import background from "assets/images/background/background.png";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

enum ForgotPasswordState {
  SendResetLink = "SendResetLink",
  PasswordResetLinkSuccess = "PasswordResetLinkSuccess",
  Loading = "Loading",
}

export function ForgotPassword() {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState<ForgotPasswordState>(
    ForgotPasswordState.SendResetLink,
  );
  const { firebaseResetPasswordEmail } = useFirebaseContext();
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [emailIsValid, setEmailIsValid] = useState(false);

  useEffect(() => {
    if (email === "") {
      setEmailIsValid(false);
      return;
    }

    setEmailIsValid(true);
  }, [email]);

  const handleSendResetPasswordEmail = async () => {
    // display loading screen
    setState(ForgotPasswordState.Loading);

    try {
      await firebaseResetPasswordEmail(email);
      setState(ForgotPasswordState.PasswordResetLinkSuccess);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      switch (e.code) {
        case "auth/invalid-email":
          console.error(e.message ? e.message : e.toString());
          enqueueSnackbar("Email is invalid", { variant: "error" });
          break;
        default:
          Sentry.captureException(e);
          console.error(
            `unexpected error sending reset password email: ${
              e.message ? e.message : e.toString()
            }`,
          );
          enqueueSnackbar(
            "could not send reset password email, please contact support",
            { variant: "error" },
          );
          break;
      }
      setState(ForgotPasswordState.SendResetLink);
    }
  };

  return (
    <Background>
      <Helmet>
        <title>Mesh | Forgot Password</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Forgot your password? Let us help." />
      </Helmet>
      <TabContext value={state}>
        <Box
          sx={{
            p: 3,
          }}
          data-component-info={JSON.stringify({
            component_id: "forgot_password",
            component_business_name: "Forgot Password",
            component_title: "Send reset link",
            component_driver: InteractionDriver.Join,
          } as DataComponentInfo)}
        >
          <TabPanel
            value={ForgotPasswordState.SendResetLink}
            sx={{
              p: 0,
              width: { md: "800px", xs: "100%" },
            }}
          >
            <Box
              sx={{
                width: { md: "800px", xs: "100%" },
                [theme.breakpoints.up("sm")]: {
                  backgroundColor: "rgba(35, 32, 65, 0.75)",
                  borderRadius: "10px",
                  height: "471px",
                  padding: theme.spacing(4, 5, 3, 7),
                },
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  marginBottom: theme.spacing(5),
                  cursor: "pointer",
                }}
                alt=""
                width={230}
                src={meshMiniLogo}
                onClick={() => navigate("/sign-in")}
              />
              <Box
                sx={{
                  display: "grid",
                  gridRowGap: theme.spacing(3),
                  gridTemplateRows: "repeat(4, auto)",
                  width: { sm: "490px", xs: "100%" },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(2),
                  },
                }}
              >
                <Typography
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                  variant="h3"
                  align={smDown ? "center" : "left"}
                >
                  Send Reset Link
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "flex-start",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    align={smDown ? "center" : "left"}
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        maxWidth: "330px",
                      },
                      [theme.breakpoints.up("sm")]: {
                        maxWidth: "490px",
                      },
                      color: theme.palette.text.secondary,
                    }}
                  >
                    To reset your password please verify your Email. This will
                    allow us to send you a reset link.
                  </Typography>
                </Box>
                <TextField
                  fullWidth={smDown}
                  id="forgotPassword-email-textfield"
                  value={email}
                  size="medium"
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    [theme.breakpoints.up("sm")]: {
                      width: "240px",
                    },
                  }}
                  InputLabelProps={{ shrink: undefined }}
                  label="Verify Email"
                />
                <Box
                  sx={{
                    display: "grid",
                    [theme.breakpoints.up("sm")]: {
                      gridTemplateColumns: "200px 200px",
                      gap: theme.spacing(2),
                    },
                    [theme.breakpoints.down("sm")]: {
                      gridTemplateRows: "48px 48px",
                      gap: theme.spacing(5),
                    },
                  }}
                >
                  <Button
                    fullWidth={smDown}
                    size={smDown ? "large" : "medium"}
                    id="forgotPassword-sendResetLink-button"
                    component="div"
                    variant="contained"
                    disabled={!emailIsValid}
                    color="primary"
                    onClick={handleSendResetPasswordEmail}
                    data-link-info={JSON.stringify({
                      content_interaction_id: "send-reset-link",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_type: InteractionType.Link,
                      content_interaction_text: "send reset link",
                      content_interaction_driver: InteractionDriver.SignIn,
                    } as DataLinkInfoType)}
                  >
                    Send Reset Link
                  </Button>
                  <Button
                    fullWidth={smDown}
                    size={smDown ? "large" : "medium"}
                    id="forgotPassword-returnToSignIn-button"
                    onClick={() => navigate("/sign-in")}
                    component="div"
                    variant="outlined"
                    data-link-info={JSON.stringify({
                      content_interaction_id: "return-to-sign-in",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_type: InteractionType.Link,
                      content_interaction_text: "return to sign in",
                      content_interaction_driver: InteractionDriver.SignIn,
                    } as DataLinkInfoType)}
                  >
                    return to sign in
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel
            value={ForgotPasswordState.PasswordResetLinkSuccess}
            sx={{
              p: 0,
              width: { md: "800px", xs: "100%" },
            }}
          >
            <Box
              sx={{
                width: { md: "800px", xs: "100%" },
                [theme.breakpoints.up("sm")]: {
                  backgroundColor: "rgba(35, 32, 65, 0.75)",
                  height: "471px",
                  padding: theme.spacing(4, 5, 3, 7),
                  borderRadius: "10px",
                },
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  marginBottom: theme.spacing(5),
                  cursor: "pointer",
                }}
                alt=""
                width={230}
                src={meshMiniLogo}
                onClick={() => navigate("/sign-in")}
              />
              <Box
                sx={{
                  display: "grid",
                  gridRowGap: theme.spacing(3),
                  gridTemplateRows: "repeat(4, auto)",
                  width: { sm: "490px", xs: "100%" },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(2),
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-start" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme.typography.fontWeightBold,
                      maxWidth: { xs: "270px", sm: "600px" },
                    }}
                    variant="h3"
                    align={smDown ? "center" : "left"}
                  >
                    Password Reset email has been sent
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  align={smDown ? "center" : "left"}
                  sx={{
                    mx: { xs: 2, sm: 0 },
                    color: theme.palette.text.secondary,
                    maxWidth: "540px",
                  }}
                >
                  To complete the password reset, find the email in your
                  mailbox. It will contain a link which you must follow to
                  complete the reset process.
                </Typography>
                <Button
                  fullWidth={smDown}
                  size={smDown ? "large" : "medium"}
                  sx={{
                    mt: 2,
                    [theme.breakpoints.up("sm")]: {
                      width: "230px",
                      height: "36px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      height: "48px",
                    },
                  }}
                  id="forgotPassword-returnToSignIn2-button"
                  component="div"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/sign-in")}
                >
                  Return to sign in
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel
            value={ForgotPasswordState.Loading}
            sx={{
              p: 0,
              width: { md: "800px", xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box
                component="img"
                sx={{
                  marginBottom: theme.spacing(5),
                  cursor: "pointer",
                }}
                alt=""
                width={130}
                src={meshMiniLogoNoWords}
                onClick={() => navigate("/sign-in")}
              />
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Background>
  );
}

interface BackgroundProps {
  children?: React.ReactNode;
}

const Background = ({ children }: BackgroundProps): JSX.Element => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return smDown ? (
    <Box
      sx={{
        backgroundColor: "rgba(35, 32, 65, 1)",
        width: "100vw",
        height: "100vh",
      }}
    >
      {children}
    </Box>
  ) : (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
