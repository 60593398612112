import React, { ChangeEvent, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Context } from "james/security";
import { Company, Manager } from "james/legal/company";
import { Identifier } from "james/search/identifier/Identifier";
import debounce from "lodash/debounce";
import {
  RegisteredNameIdentifier,
  RegistrationNumberIdentifier,
} from "james/search/identifier";
import { InviteClientClaims } from "james/security/claims";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import { CompanyExistsDialog } from "./Dialogs/CompanyExistsDialog";
import { ErrorDialog } from "./Dialogs/ErrorDialog";
import {
  CompanyProfileFormFieldValidation,
  FormFieldsValidation,
  FormValidator,
} from "./Validation";
import { AllLegalForms, LegalForm } from "james/legal/ConnectedCompany";

const PREFIX = "CompanyProfile";

const classes = {
  formField: `${PREFIX}-formField`,
  infoText: `${PREFIX}-infoText`,
  sectionWithRows2Gap: `${PREFIX}-sectionWithRows2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formField}`]: {
    width: 368,
  },

  [`& .${classes.infoText}`]: {
    width: 540,
    paddingBottom: theme.spacing(3),
  },

  [`&.${classes.sectionWithRows2Gap}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

interface CompanyProfileProps {
  inviteClientClaims: InviteClientClaims;
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  company: Company;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
}

export const CompanyProfile = (props: CompanyProfileProps) => {
  const {
    company,
    onChange,
    onNext,
    inValid,
    invalidStep,
    inviteClientClaims,
  } = props;
  const [ranValidation, setRanValidation] = useState(false);
  const [FormFieldValid, setFormFieldValid] = useState({
    ...CompanyProfileFormFieldValidation,
  });
  const [companyExistsCheckInProgress, setCompanyExistsCheckInProgress] =
    useState(false);
  const [companyExistsDialogOpen, setCompanyExistsDialogOpen] =
    useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const InlineFormValidation = (field: string, value: string | null) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...FormFieldValid };
      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        delete InvalidFormField[field];
      }

      setFormFieldValid(InvalidFormField);
    }, 600);

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(FormFieldValid, company, setFormFieldValid);
  }

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    InlineFormValidation(name, event.target.value)();
    onChange(name)(event.target.value);
  };

  const autoCompleteOnUpdate =
    (name: string) =>
    (event: React.SyntheticEvent, legalForm: string | null) => {
      InlineFormValidation(name, legalForm)();
      onChange(name)(legalForm);
    };

  const companyExists = async (
    identifier: Identifier,
  ): Promise<boolean | undefined> => {
    setCompanyExistsCheckInProgress(true);
    const response = await Manager.CheckCompanyExistence({
      context: new Context({ userID: inviteClientClaims.invitingUserID }),
      identifier,
    });
    setCompanyExistsCheckInProgress(false);
    return response.exists;
  };

  const nextStep = async () => {
    try {
      if (
        company.registeredName.trim() &&
        (await companyExists(
          RegisteredNameIdentifier(company.registeredName.trim()),
        ))
      ) {
        setCompanyExistsDialogOpen(true);
        return;
      }
      if (
        company.registrationNumber.trim() &&
        (await companyExists(
          RegistrationNumberIdentifier(company.registrationNumber.trim()),
        ))
      ) {
        setCompanyExistsDialogOpen(true);
        return;
      }
    } catch (e) {
      console.error("could not check company existence", e);
      setErrorDialogOpen(true);
      return;
    }
    if (FormFieldsValidation(FormFieldValid, company, setFormFieldValid)) {
      onNext(CompanyRegStep.companyDetails)();
      invalidStep(CompanyRegStep.companyProfile, false);
    } else {
      onNext(CompanyRegStep.companyDetails)();
      invalidStep(CompanyRegStep.companyProfile, true);
    }
  };

  return (
    <Root className={classes.sectionWithRows2Gap}>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary" children="Your Company" />
        </Box>
        <Tooltip
          title={
            companyExistsCheckInProgress
              ? "Checking that company is not already registered..."
              : ""
          }
        >
          <span>
            <Button
              id="companyProfile-next"
              variant="contained"
              color="primary"
              onClick={nextStep}
              disabled={companyExistsCheckInProgress}
              children="Next"
            />
          </span>
        </Tooltip>
      </Box>

      {/* Content */}
      <Typography variant="subtitle2" className={classes.infoText}>
        Add your company name and registration details. We use this information
        to create your company’s profile.
      </Typography>
      <TextField
        id="companyProfile-registeredName"
        className={classes.formField}
        disabled={companyExistsCheckInProgress}
        margin="dense"
        variant="outlined"
        label="Registered Name"
        fullWidth
        onChange={onUpdate("registeredName")}
        value={company.registeredName}
        error={Boolean(FormFieldValid.registeredName)}
        helperText={FormFieldValid.registeredName}
      />
      <Autocomplete
        isOptionEqualToValue={(option, value) => option === value}
        id="companyProfile-formOfIncorporation-autocomplete"
        options={AllLegalForms}
        onChange={autoCompleteOnUpdate("formOfIncorporation")}
        value={company.formOfIncorporation}
        sx={{
          width: 368,
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            id="companyProfile-formOfIncorporation-textField"
            helperText={FormFieldValid.formOfIncorporation}
            error={Boolean(FormFieldValid.formOfIncorporation)}
            margin="dense"
            variant="outlined"
            label="Form Of Incorporation"
          />
        )}
      />
      <Collapse
        in={
          ![
            LegalForm.SoleProprietorShipLegalForm,
            LegalForm.PartnershipLegalForm,
          ].includes(company.formOfIncorporation as LegalForm)
        }
      >
        <TextField
          id="companyProfile-companyRegistrationNumber"
          disabled={companyExistsCheckInProgress}
          className={classes.formField}
          margin="dense"
          variant="outlined"
          label="Company Registration Number"
          onChange={onUpdate("registrationNumber")}
          value={company.registrationNumber}
          placeholder="YYYY/MMMMMM/NN"
        />
      </Collapse>

      <CompanyExistsDialog
        onDismissClick={() => setCompanyExistsDialogOpen(false)}
        showDialog={companyExistsDialogOpen}
      />
      <ErrorDialog
        onClose={() => setErrorDialogOpen(false)}
        open={errorDialogOpen}
      />
    </Root>
  );
};
