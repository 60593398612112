import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import { useNotificationContext } from "context/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";

import { useApplicationContext } from "context/Application/Application";
import { Model as RecipientModel } from "james/views/stellarRecipientView";
import { useRequestContext } from "context/Request/Request";

const PREFIX = "OffPlatformTransferConfirmationDialog";

const classes = {
  boldText: `${PREFIX}-boldText`,
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  amountFormField: `${PREFIX}-amountFormField`,
  warningColor: `${PREFIX}-warningColor`,
  warningColorLightVariant: `${PREFIX}-warningColorLightVariant`,
  typographyWordBreak: `${PREFIX}-typographyWordBreak`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.dialogTitleContent}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.amountFormField}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.warningColor}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.warningColorLightVariant}`]: {
    color: alpha(theme.palette.warning.main, 0.5),
  },

  [`& .${classes.typographyWordBreak}`]: {
    wordBreak: "break-word",
  },
}));

interface OffPlatformTransferConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onTransferSubmissionComplete: () => void;
  recipientModel: RecipientModel;
  transferAmount: LedgerAmount;
  fromAccountID: string;
  transferFeeSection: React.ReactNode;
  hideTransferFee: boolean;
}

export function OffPlatformTransferConfirmationDialog(
  props: OffPlatformTransferConfirmationDialogProps,
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { registerNotificationCallback } = useNotificationContext();
  const [disableTransferButton, setDisableTransferButton] = useState(false);
  const requestContext = useRequestContext();

  const handleTransfer = useCallback(async () => {
    // The transfer button is being disabled here to prevent the scenario where
    // the transfer confirmation dialog is taking too long to close. Resulting
    // in the user being able to invoke handleTransferAndSubmit twice.
    setDisableTransferButton(true);

    // perform transfer
    const transferOffPlatformResponse =
      await requestContext.ServiceProviders.accountOperator.TransferOffPlatform(
        {
          context: authContext,
          fromAccountID: props.fromAccountID,
          recipientID: props.recipientModel.id,
          recipientLabel: props.recipientModel.label,
          amount: props.transferAmount,
        },
        undefined,
        {
          onError: async () => {
            enqueueSnackbar("Error! Transfer Submission Failed", {
              variant: "error",
            });
          },
        },
      );

    // close the card
    props.onTransferSubmissionComplete();

    // notify the user a transfer is in progress
    enqueueSnackbar("Transfer in progress", { variant: "info" });

    // register callback to fire once the transfer has been submitted
    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: transferOffPlatformResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === transferOffPlatformResponse.transactionID
          ) {
            enqueueSnackbar("Success! Transfer Complete", {
              variant: "success",
            });
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === transferOffPlatformResponse.transactionID
          ) {
            enqueueSnackbar("Error! Transfer Submission Failed", {
              variant: "error",
            });
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === transferOffPlatformResponse.transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the transfer and its status is being investigated",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error(
        "error registering for off platform transfer notifications",
        e,
      );
      enqueueSnackbar(
        "Warning! Error Registering Transfer Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  }, [props, authContext, enqueueSnackbar, registerNotificationCallback]);

  return (
    <StyledDialog
      open={props.open}
      PaperProps={{
        sx: [
          isMobile && {
            "&.MuiDialog-paper": {
              position: "absolute",
              bottom: 0,
              width: "100%",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              margin: 0,
            },
          },
        ],
      }}
    >
      <DialogTitle
        sx={[
          {
            height: 52,
            padding: {
              sm: theme.spacing(0, 3),
              xs: theme.spacing(0, 2, 0, 3),
            },
          },
        ]}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className={classes.dialogTitleContent}>
            <Typography variant="h5">
              Transfer Off Platform Confirmation
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={props.onClose}
              disabled={false}
              id="offPlatformTransferConfirmation-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          width: {
            sm: 392,
            xs: "100%",
          },
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "repeat(3,auto)",
            gridRowGap: theme.spacing(2),
            padding: {
              sm: theme.spacing(4),
              xs: theme.spacing(3),
            },
          }}
        >
          <Typography variant="h6">
            Please confirm your off platform transfer request.
          </Typography>
          <div>
            <Typography color="textSecondary" variant="caption">
              Recipient Label
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              {props.recipientModel.label}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Recipient Stellar Address
            </Typography>
            <Typography
              className={classes.typographyWordBreak}
              color="textSecondary"
              variant="subtitle1"
            >
              {props.recipientModel.address}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Recipient Memo
            </Typography>
            <Typography
              className={classes.typographyWordBreak}
              color="textSecondary"
              variant="subtitle1"
            >
              {props.recipientModel.defaultMemo}
            </Typography>
          </div>
        </Box>
        {!props.hideTransferFee && (
          <Box
            sx={{
              padding: {
                sm: theme.spacing(0, 4, 3, 4),
                xs: theme.spacing(0, 3, 3, 3),
              },
            }}
          >
            {/* Render Transfer fee component */}
            {props.transferFeeSection}
          </Box>
        )}
        <Box
          sx={{
            padding: {
              sm: theme.spacing(4),
              xs: theme.spacing(3, 3, 5, 3),
            },
            backgroundColor: theme.palette.custom.midnight,
            display: "flex",
            flexDirection: "column",
            alignItems: {
              sm: "flex-start",
              xs: "center",
            },
          }}
        >
          {!isMobile && (
            <Typography color="textSecondary" variant="subtitle1">
              Amount
            </Typography>
          )}
          <Amount
            valueTypographyProps={{
              variant: "h4",
              sx: {
                fontWeight: "bold",
                color: theme.palette.warning.main,
              },
            }}
            codeTypographyProps={{
              variant: "h4",
              sx: {
                color: theme.palette.custom.yellow,
                fontWeight: "bold",
              },
            }}
            amount={props.transferAmount}
          />
          <Button
            id="transferConfirmationDialog-confirm-button"
            sx={{
              marginTop: theme.spacing(3),
              height: {
                sm: 36,
                xs: 48,
              },
            }}
            variant="contained"
            disabled={disableTransferButton}
            fullWidth
            color="primary"
            onClick={handleTransfer}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
}
