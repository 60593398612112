// use ID number to determine the date of birth
export function IDNumberToDOBConverter(identificationNumber: string) {
  // check that identificationNumber is not empty
  if (identificationNumber === "") {
    return "";
  }
  // check that identificationNumber is at-least 6 characters long
  if (identificationNumber.length < 6) {
    return "";
  }

  // check that there only numbers
  const reg = /^\d+$/;
  if (!reg.test(identificationNumber)) {
    return "";
  }

  const year = identificationNumber.substring(0, 2);
  const day = identificationNumber.substring(4, 6);
  const month = identificationNumber.substring(2, 4);

  const cutoff = new Date().getFullYear() - 2000;

  return month + "/" + day + "/" + (Number(year) > cutoff ? "19" : "20") + year;
}
