// source: i8n/countryCode.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.i8n.CountryCode', null, global);
/**
 * @enum {number}
 */
proto.i8n.CountryCode = {
  UNDEFINED_COUNTRY_CODE: 0,
  AFGHANISTAN_AF_COUNTRY_CODE: 1,
  ALAND_ISLANDS_AX_COUNTRY_CODE: 2,
  ALBANIA_AL_COUNTRY_CODE: 3,
  ALGERIA_DZ_COUNTRY_CODE: 4,
  AMERICAN_SAMOA_AS_COUNTRY_CODE: 5,
  ANDORRA_AD_COUNTRY_CODE: 6,
  ANGOLA_AO_COUNTRY_CODE: 7,
  ANGUILLA_AI_COUNTRY_CODE: 8,
  ANTARCTICA_AQ_COUNTRY_CODE: 9,
  ANTIGUA_AND_BARBUDA_AG_COUNTRY_CODE: 10,
  ARGENTINA_AR_COUNTRY_CODE: 11,
  ARMENIA_AM_COUNTRY_CODE: 12,
  ARUBA_AW_COUNTRY_CODE: 13,
  AUSTRALIA_AU_COUNTRY_CODE: 14,
  AUSTRIA_AT_COUNTRY_CODE: 15,
  AZERBAIJAN_AZ_COUNTRY_CODE: 16,
  BAHAMAS_BS_COUNTRY_CODE: 17,
  BAHRAIN_BH_COUNTRY_CODE: 18,
  BANGLADESH_BD_COUNTRY_CODE: 19,
  BARBADOS_BB_COUNTRY_CODE: 20,
  BELARUS_BY_COUNTRY_CODE: 21,
  BELGIUM_BE_COUNTRY_CODE: 22,
  BELIZE_BZ_COUNTRY_CODE: 23,
  BENIN_BJ_COUNTRY_CODE: 24,
  BERMUDA_BM_COUNTRY_CODE: 25,
  BHUTAN_BT_COUNTRY_CODE: 26,
  BOLIVIA_PLURINATIONAL_STATE_OF_BO_COUNTRY_CODE: 27,
  BONAIRE_SINT_EUSTATIUS_AND_SABA_BQ_COUNTRY_CODE: 28,
  BOSNIA_AND_HERZEGOVINA_BA_COUNTRY_CODE: 29,
  BOTSWANA_BW_COUNTRY_CODE: 30,
  BOUVET_ISLAND_BV_COUNTRY_CODE: 31,
  BRAZIL_BR_COUNTRY_CODE: 32,
  BRITISH_INDIAN_OCEAN_TERRITORY_IO_COUNTRY_CODE: 33,
  BRUNEI_DARUSSALAM_BN_COUNTRY_CODE: 34,
  BULGARIA_BG_COUNTRY_CODE: 35,
  BURKINA_FASO_BF_COUNTRY_CODE: 36,
  BURUNDI_BI_COUNTRY_CODE: 37,
  CABO_VERDE_CV_COUNTRY_CODE: 38,
  CAMBODIA_KH_COUNTRY_CODE: 39,
  CAMEROON_CM_COUNTRY_CODE: 40,
  CANADA_CA_COUNTRY_CODE: 41,
  CAYMAN_ISLANDS_KY_COUNTRY_CODE: 42,
  CENTRAL_AFRICAN_REPUBLIC_CF_COUNTRY_CODE: 43,
  CHAD_TD_COUNTRY_CODE: 44,
  CHILE_CL_COUNTRY_CODE: 45,
  CHINA_CN_COUNTRY_CODE: 46,
  CHRISTMAS_ISLAND_CX_COUNTRY_CODE: 47,
  COCOS_KEELING_ISLANDS_CC_COUNTRY_CODE: 48,
  COLOMBIA_CO_COUNTRY_CODE: 49,
  COMOROS_KM_COUNTRY_CODE: 50,
  CONGO_CG_COUNTRY_CODE: 51,
  CONGO_DEMOCRATIC_REPUBLIC_OF_THE_CD_COUNTRY_CODE: 52,
  COOK_ISLANDS_CK_COUNTRY_CODE: 53,
  COSTA_RICA_CR_COUNTRY_CODE: 54,
  COTE_DIVOIRE_CI_COUNTRY_CODE: 55,
  CROATIA_HR_COUNTRY_CODE: 56,
  CUBA_CU_COUNTRY_CODE: 57,
  CURACAO_CW_COUNTRY_CODE: 58,
  CYPRUS_CY_COUNTRY_CODE: 59,
  CZECHIA_CZ_COUNTRY_CODE: 60,
  DENMARK_DK_COUNTRY_CODE: 61,
  DJIBOUTI_DJ_COUNTRY_CODE: 62,
  DOMINICA_DM_COUNTRY_CODE: 63,
  DOMINICAN_REPUBLIC_DO_COUNTRY_CODE: 64,
  ECUADOR_EC_COUNTRY_CODE: 65,
  EGYPT_EG_COUNTRY_CODE: 66,
  EL_SALVADOR_SV_COUNTRY_CODE: 67,
  EQUATORIAL_GUINEA_GQ_COUNTRY_CODE: 68,
  ERITREA_ER_COUNTRY_CODE: 69,
  ESTONIA_EE_COUNTRY_CODE: 70,
  ESWATINI_SZ_COUNTRY_CODE: 71,
  ETHIOPIA_ET_COUNTRY_CODE: 72,
  FALKLAND_ISLANDS_MALVINAS_FK_COUNTRY_CODE: 73,
  FAROE_ISLANDS_FO_COUNTRY_CODE: 74,
  FIJI_FJ_COUNTRY_CODE: 75,
  FINLAND_FI_COUNTRY_CODE: 76,
  FRANCE_FR_COUNTRY_CODE: 77,
  FRENCH_GUIANA_GF_COUNTRY_CODE: 78,
  FRENCH_POLYNESIA_PF_COUNTRY_CODE: 79,
  FRENCH_SOUTHERN_TERRITORIES_TF_COUNTRY_CODE: 80,
  GABON_GA_COUNTRY_CODE: 81,
  GAMBIA_GM_COUNTRY_CODE: 82,
  GEORGIA_GE_COUNTRY_CODE: 83,
  GERMANY_DE_COUNTRY_CODE: 84,
  GHANA_GH_COUNTRY_CODE: 85,
  GIBRALTAR_GI_COUNTRY_CODE: 86,
  GREECE_GR_COUNTRY_CODE: 87,
  GREENLAND_GL_COUNTRY_CODE: 88,
  GRENADA_GD_COUNTRY_CODE: 89,
  GUADELOUPE_GP_COUNTRY_CODE: 90,
  GUAM_GU_COUNTRY_CODE: 91,
  GUATEMALA_GT_COUNTRY_CODE: 92,
  GUERNSEY_GG_COUNTRY_CODE: 93,
  GUINEA_GN_COUNTRY_CODE: 94,
  GUINEABISSAU_GW_COUNTRY_CODE: 95,
  GUYANA_GY_COUNTRY_CODE: 96,
  HAITI_HT_COUNTRY_CODE: 97,
  HEARD_ISLAND_AND_MCDONALD_ISLANDS_HM_COUNTRY_CODE: 98,
  HOLY_SEE_VA_COUNTRY_CODE: 99,
  HONDURAS_HN_COUNTRY_CODE: 100,
  HONG_KONG_HK_COUNTRY_CODE: 101,
  HUNGARY_HU_COUNTRY_CODE: 102,
  ICELAND_IS_COUNTRY_CODE: 103,
  INDIA_IN_COUNTRY_CODE: 104,
  INDONESIA_ID_COUNTRY_CODE: 105,
  IRAN_ISLAMIC_REPUBLIC_OF_IR_COUNTRY_CODE: 106,
  IRAQ_IQ_COUNTRY_CODE: 107,
  IRELAND_IE_COUNTRY_CODE: 108,
  ISLE_OF_MAN_IM_COUNTRY_CODE: 109,
  ISRAEL_IL_COUNTRY_CODE: 110,
  ITALY_IT_COUNTRY_CODE: 111,
  JAMAICA_JM_COUNTRY_CODE: 112,
  JAPAN_JP_COUNTRY_CODE: 113,
  JERSEY_JE_COUNTRY_CODE: 114,
  JORDAN_JO_COUNTRY_CODE: 115,
  KAZAKHSTAN_KZ_COUNTRY_CODE: 116,
  KENYA_KE_COUNTRY_CODE: 117,
  KIRIBATI_KI_COUNTRY_CODE: 118,
  KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KP_COUNTRY_CODE: 119,
  KOREA_REPUBLIC_OF_KR_COUNTRY_CODE: 120,
  KUWAIT_KW_COUNTRY_CODE: 121,
  KYRGYZSTAN_KG_COUNTRY_CODE: 122,
  LAO_PEOPLES_DEMOCRATIC_REPUBLIC_LA_COUNTRY_CODE: 123,
  LATVIA_LV_COUNTRY_CODE: 124,
  LEBANON_LB_COUNTRY_CODE: 125,
  LESOTHO_LS_COUNTRY_CODE: 126,
  LIBERIA_LR_COUNTRY_CODE: 127,
  LIBYA_LY_COUNTRY_CODE: 128,
  LIECHTENSTEIN_LI_COUNTRY_CODE: 129,
  LITHUANIA_LT_COUNTRY_CODE: 130,
  LUXEMBOURG_LU_COUNTRY_CODE: 131,
  MACAO_MO_COUNTRY_CODE: 132,
  MADAGASCAR_MG_COUNTRY_CODE: 133,
  MALAWI_MW_COUNTRY_CODE: 134,
  MALAYSIA_MY_COUNTRY_CODE: 135,
  MALDIVES_MV_COUNTRY_CODE: 136,
  MALI_ML_COUNTRY_CODE: 137,
  MALTA_MT_COUNTRY_CODE: 138,
  MARSHALL_ISLANDS_MH_COUNTRY_CODE: 139,
  MARTINIQUE_MQ_COUNTRY_CODE: 140,
  MAURITANIA_MR_COUNTRY_CODE: 141,
  MAURITIUS_MU_COUNTRY_CODE: 142,
  MAYOTTE_YT_COUNTRY_CODE: 143,
  MEXICO_MX_COUNTRY_CODE: 144,
  MICRONESIA_FEDERATED_STATES_OF_FM_COUNTRY_CODE: 145,
  MOLDOVA_REPUBLIC_OF_MD_COUNTRY_CODE: 146,
  MONACO_MC_COUNTRY_CODE: 147,
  MONGOLIA_MN_COUNTRY_CODE: 148,
  MONTENEGRO_ME_COUNTRY_CODE: 149,
  MONTSERRAT_MS_COUNTRY_CODE: 150,
  MOROCCO_MA_COUNTRY_CODE: 151,
  MOZAMBIQUE_MZ_COUNTRY_CODE: 152,
  MYANMAR_MM_COUNTRY_CODE: 153,
  NAMIBIA_NA_COUNTRY_CODE: 154,
  NAURU_NR_COUNTRY_CODE: 155,
  NEPAL_NP_COUNTRY_CODE: 156,
  NETHERLANDS_KINGDOM_OF_THE_NL_COUNTRY_CODE: 157,
  NEW_CALEDONIA_NC_COUNTRY_CODE: 158,
  NEW_ZEALAND_NZ_COUNTRY_CODE: 159,
  NICARAGUA_NI_COUNTRY_CODE: 160,
  NIGER_NE_COUNTRY_CODE: 161,
  NIGERIA_NG_COUNTRY_CODE: 162,
  NIUE_NU_COUNTRY_CODE: 163,
  NORFOLK_ISLAND_NF_COUNTRY_CODE: 164,
  NORTH_MACEDONIA_MK_COUNTRY_CODE: 165,
  NORTHERN_MARIANA_ISLANDS_MP_COUNTRY_CODE: 166,
  NORWAY_NO_COUNTRY_CODE: 167,
  OMAN_OM_COUNTRY_CODE: 168,
  PAKISTAN_PK_COUNTRY_CODE: 169,
  PALAU_PW_COUNTRY_CODE: 170,
  PALESTINE_STATE_OF_PS_COUNTRY_CODE: 171,
  PANAMA_PA_COUNTRY_CODE: 172,
  PAPUA_NEW_GUINEA_PG_COUNTRY_CODE: 173,
  PARAGUAY_PY_COUNTRY_CODE: 174,
  PERU_PE_COUNTRY_CODE: 175,
  PHILIPPINES_PH_COUNTRY_CODE: 176,
  PITCAIRN_PN_COUNTRY_CODE: 177,
  POLAND_PL_COUNTRY_CODE: 178,
  PORTUGAL_PT_COUNTRY_CODE: 179,
  PUERTO_RICO_PR_COUNTRY_CODE: 180,
  QATAR_QA_COUNTRY_CODE: 181,
  REUNION_RE_COUNTRY_CODE: 182,
  ROMANIA_RO_COUNTRY_CODE: 183,
  RUSSIAN_FEDERATION_RU_COUNTRY_CODE: 184,
  RWANDA_RW_COUNTRY_CODE: 185,
  SAINT_BARTHELEMY_BL_COUNTRY_CODE: 186,
  SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA_SH_COUNTRY_CODE: 187,
  SAINT_KITTS_AND_NEVIS_KN_COUNTRY_CODE: 188,
  SAINT_LUCIA_LC_COUNTRY_CODE: 189,
  SAINT_MARTIN_FRENCH_PART_MF_COUNTRY_CODE: 190,
  SAINT_PIERRE_AND_MIQUELON_PM_COUNTRY_CODE: 191,
  SAINT_VINCENT_AND_THE_GRENADINES_VC_COUNTRY_CODE: 192,
  SAMOA_WS_COUNTRY_CODE: 193,
  SAN_MARINO_SM_COUNTRY_CODE: 194,
  SAO_TOME_AND_PRINCIPE_ST_COUNTRY_CODE: 195,
  SAUDI_ARABIA_SA_COUNTRY_CODE: 196,
  SENEGAL_SN_COUNTRY_CODE: 197,
  SERBIA_RS_COUNTRY_CODE: 198,
  SEYCHELLES_SC_COUNTRY_CODE: 199,
  SIERRA_LEONE_SL_COUNTRY_CODE: 200,
  SINGAPORE_SG_COUNTRY_CODE: 201,
  SINT_MAARTEN_DUTCH_PART_SX_COUNTRY_CODE: 202,
  SLOVAKIA_SK_COUNTRY_CODE: 203,
  SLOVENIA_SI_COUNTRY_CODE: 204,
  SOLOMON_ISLANDS_SB_COUNTRY_CODE: 205,
  SOMALIA_SO_COUNTRY_CODE: 206,
  SOUTH_AFRICA_ZA_COUNTRY_CODE: 207,
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS_GS_COUNTRY_CODE: 208,
  SOUTH_SUDAN_SS_COUNTRY_CODE: 209,
  SPAIN_ES_COUNTRY_CODE: 210,
  SRI_LANKA_LK_COUNTRY_CODE: 211,
  SUDAN_SD_COUNTRY_CODE: 212,
  SURINAME_SR_COUNTRY_CODE: 213,
  SVALBARD_AND_JAN_MAYEN_SJ_COUNTRY_CODE: 214,
  SWEDEN_SE_COUNTRY_CODE: 215,
  SWITZERLAND_CH_COUNTRY_CODE: 216,
  SYRIAN_ARAB_REPUBLIC_SY_COUNTRY_CODE: 217,
  TAIWAN_PROVINCE_OF_CHINA_TW_COUNTRY_CODE: 218,
  TAJIKISTAN_TJ_COUNTRY_CODE: 219,
  TANZANIA_UNITED_REPUBLIC_OF_TZ_COUNTRY_CODE: 220,
  THAILAND_TH_COUNTRY_CODE: 221,
  TIMORLESTE_TL_COUNTRY_CODE: 222,
  TOGO_TG_COUNTRY_CODE: 223,
  TOKELAU_TK_COUNTRY_CODE: 224,
  TONGA_TO_COUNTRY_CODE: 225,
  TRINIDAD_AND_TOBAGO_TT_COUNTRY_CODE: 226,
  TUNISIA_TN_COUNTRY_CODE: 227,
  TURKIYE_TR_COUNTRY_CODE: 228,
  TURKMENISTAN_TM_COUNTRY_CODE: 229,
  TURKS_AND_CAICOS_ISLANDS_TC_COUNTRY_CODE: 230,
  TUVALU_TV_COUNTRY_CODE: 231,
  UGANDA_UG_COUNTRY_CODE: 232,
  UKRAINE_UA_COUNTRY_CODE: 233,
  UNITED_ARAB_EMIRATES_AE_COUNTRY_CODE: 234,
  UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND_GB_COUNTRY_CODE: 235,
  UNITED_STATES_OF_AMERICA_US_COUNTRY_CODE: 236,
  UNITED_STATES_MINOR_OUTLYING_ISLANDS_UM_COUNTRY_CODE: 237,
  URUGUAY_UY_COUNTRY_CODE: 238,
  UZBEKISTAN_UZ_COUNTRY_CODE: 239,
  VANUATU_VU_COUNTRY_CODE: 240,
  VENEZUELA_BOLIVARIAN_REPUBLIC_OF_VE_COUNTRY_CODE: 241,
  VIET_NAM_VN_COUNTRY_CODE: 242,
  VIRGIN_ISLANDS_BRITISH_VG_COUNTRY_CODE: 243,
  VIRGIN_ISLANDS_US_VI_COUNTRY_CODE: 244,
  WALLIS_AND_FUTUNA_WF_COUNTRY_CODE: 245,
  WESTERN_SAHARA_EH_COUNTRY_CODE: 246,
  YEMEN_YE_COUNTRY_CODE: 247,
  ZAMBIA_ZM_COUNTRY_CODE: 248,
  ZIMBABWE_ZW_COUNTRY_CODE: 249
};

goog.object.extend(exports, proto.i8n);
