import { Entry } from "james/audit/Entry";
import { Operation } from "./Operation";

export enum WriteOperationState {
  Pending = "Pending",
  RunningForward = "Running Forward",
  RunningBackward = "Running Backward",
  Complete = "Complete",
  Failed = "Failed",
  RolledBack = "Rolled Back",
}

export const AllWriteOperationStates: WriteOperationState[] = [
  WriteOperationState.Pending,
  WriteOperationState.RunningForward,
  WriteOperationState.RunningBackward,
  WriteOperationState.Complete,
  WriteOperationState.Failed,
  WriteOperationState.RolledBack,
];

export const WriteOperationTypeName = "consistency::consistency/WriteOperation";

export class WriteOperation implements Operation {
  public ["@type"]: string = WriteOperationTypeName;

  public id = "";

  public transactionID = "";

  public state: WriteOperationState | "" = "";

  public lastActionAnnotation = "";

  public serviceURI = "";

  public payload = "";

  public conflictSignatures: string[] = [];

  public metaData: { [key: string]: string } = {};

  public auditEntry: Entry = new Entry();

  constructor(writeOperation?: WriteOperation) {
    if (!writeOperation) {
      return;
    }
    this.id = writeOperation.id;
    this.transactionID = writeOperation.transactionID;
    this.state = writeOperation.state;
    this.lastActionAnnotation = writeOperation.lastActionAnnotation;
    this.serviceURI = writeOperation.serviceURI;
    this.payload = writeOperation.payload;
    this.conflictSignatures = [...writeOperation.conflictSignatures];
    this.metaData = { ...writeOperation.metaData };
    this.auditEntry = new Entry(writeOperation.auditEntry);
  }

  operationID(): string {
    return this.id;
  }

  operationConflictSignatures(): string[] {
    return this.conflictSignatures;
  }

  operationServiceURI(): string {
    return this.serviceURI;
  }

  operationAuditEntry(): Entry {
    return this.auditEntry;
  }

  operationMetadata(): { [key: string]: string } {
    return this.metaData;
  }
}
