import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface DownloadAccountStatementRequest {
  context: Context;
  accountID: string;
  timePeriod: DateRange;
}

export interface DownloadAccountStatementResponse {
  excelFileBase64: string;
}

export class DateRange {
  fromDate = "";
  toDate = "";

  constructor(dateRange?: DateRange) {
    if (!dateRange) {
      return;
    }

    this.fromDate = dateRange.fromDate;
    this.toDate = dateRange.toDate;
  }
}

export const AccountStatementDownloaderServiceProviderName =
  "reporting-AccountStatementDownloader";

export const AccountStatementDownloader = {
  async DownloadAccountStatement(
    request: DownloadAccountStatementRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DownloadAccountStatementResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${AccountStatementDownloaderServiceProviderName}.DownloadAccountStatement`,
        request,
      },
      opts,
    );
  },
};
