import { IDIdentifierType } from "james/search/identifier/ID";
import { Person } from "./Person";
import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface SearchCompaniesRequest {
  context: Context;
  identifier: IDIdentifierType;
  person: Person;
}

export interface SearchCompaniesResponse {
  person: Person;
}

export const Repository = {
  serviceProvider: "person-Repository",
  async UpdatePerson(
    request: SearchCompaniesRequest,
  ): Promise<SearchCompaniesResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.UpdatePerson`,
      request,
    });
    return {
      person: new Person(response.person),
    };
  },
};
