import { create } from "zustand";
import { Client, ClientKYCStatus, ClientStatus } from "james/client";
import dayjs from "dayjs";

interface TClientDetailsViewStoreState {
  loading: boolean;
  registrationDate: string;
  kycResetDate: string;
  kycVerificationDate: string;
  shortName: string;
  name: string;
  website: string;
  linkedIn: string;
  twitter: string;
  otherSite: string;
  paasAgreementVersion: string;
  description: string;
  kycStatusReason: string;
  cachedClient: Client;
  lastUpdateDate: string;
  lastUpdateBy: string;
  selectedKYCStatus: ClientKYCStatus;
  selectedClientStatus: ClientStatus;
}

interface TClientDetailsViewStoreActions {
  setLoading: (val: boolean) => void;
  setRegistrationDate: (val: string) => void;
  setKYCResetDate: (val: string) => void;
  setKYCVerificationDate: (val: string) => void;
  setShortName: (val: string) => void;
  setName: (val: string) => void;
  setWebsite: (val: string) => void;
  setLinkedin: (val: string) => void;
  setOtherSite: (val: string) => void;
  setTwitter: (val: string) => void;
  setPAASAgreementVersion: (val: string) => void;
  setDescription: (val: string) => void;
  setKYCStatusReason: (val: string) => void;
  setCachedClient: (val: Client) => void;
  setLastUpdateDate: (val: string) => void;
  setLastUpdateBy: (val: string) => void;
  setSelectedKYCStatus: (val: ClientKYCStatus) => void;
  setSelectedClientStatus: (val: ClientStatus) => void;
  setStateFrom: (client: Client) => void;
  getClientObject: () => Client;
  clear: () => void;
}

const initialClientDetailsViewStoreState: TClientDetailsViewStoreState = {
  loading: false,
  registrationDate: "",
  kycResetDate: "",
  kycVerificationDate: "",
  shortName: "",
  name: "",
  website: "",
  linkedIn: "",
  twitter: "",
  otherSite: "",
  paasAgreementVersion: "",
  description: "",
  kycStatusReason: "",
  cachedClient: new Client(),
  lastUpdateBy: "",
  lastUpdateDate: dayjs().format(),
  selectedClientStatus: ClientStatus.ActiveStatus,
  selectedKYCStatus: ClientKYCStatus.Outstanding,
};

export const useClientDetailsViewStore = create<
  TClientDetailsViewStoreState & TClientDetailsViewStoreActions
>((set, get) => ({
  ...initialClientDetailsViewStoreState,
  setLoading: (val: boolean) => set(() => ({ loading: val })),
  setRegistrationDate: (val: string) =>
    set(() => ({ registrationDate: dayjs(val).format() })),
  setKYCResetDate: (val: string) =>
    set(() => ({ kycResetDate: dayjs(val).format() })),
  setKYCVerificationDate: (val: string) =>
    set(() => ({ kycVerificationDate: dayjs(val).format() })),
  setShortName: (val: string) => set(() => ({ shortName: val })),
  setName: (val: string) => set(() => ({ name: val })),
  setWebsite: (val: string) => set(() => ({ website: val })),
  setLinkedin: (val: string) => set(() => ({ linkedIn: val })),
  setTwitter: (val: string) => set(() => ({ twitter: val })),
  setOtherSite: (val: string) => set(() => ({ otherSite: val })),
  setPAASAgreementVersion: (val: string) =>
    set(() => ({ paasAgreementVersion: val })),
  setDescription: (val: string) => set(() => ({ description: val })),
  setKYCStatusReason: (val: string) => set(() => ({ kycStatusReason: val })),
  setCachedClient: (val: Client) => set({ cachedClient: val }),
  setLastUpdateBy: (val: string) => set(() => ({ lastUpdateBy: val })),
  setLastUpdateDate: (val: string) => set(() => ({ lastUpdateDate: val })),
  setStateFrom: (client: Client) =>
    set(() => ({
      clientTypes: client.clientTypes,
      clientKind: client.clientKind,
      name: client.name,
      shortName: client.shortName,
      website: client.website,
      linkedIn: client.linkedIn,
      otherSite: client.otherSite,
      twitter: client.twitter,
      description: client.description,
      registrationDate: client.registrationDate,
      status: client.status,
      kycStatusReason: client.kycStatusReason,
      selectedKYCStatus: client.kycStatus as ClientKYCStatus,
      selectedClientStatus: client.status as ClientStatus,
      kycResetDate: client.kycResetDate,
      kycVerificationDate: client.kycVerificationDate,
      paasAgreementVersion: client.paasAgreementVersion,
    })),
  // ui
  setSelectedKYCStatus: (val: ClientKYCStatus) =>
    set(() => ({ selectedKYCStatus: val })),
  setSelectedClientStatus: (val: ClientStatus) =>
    set(() => ({ selectedClientStatus: val })),
  getClientObject: () => {
    const state = get();
    return new Client({
      ...state.cachedClient,
      shortName: state.shortName,
      name: state.name,
      website: state.website,
      linkedIn: state.linkedIn,
      twitter: state.twitter,
      otherSite: state.otherSite,
      paasAgreementVersion: state.paasAgreementVersion,
      description: state.description,
      kycStatusReason: state.kycStatusReason,
      kycResetDate: dayjs(state.kycResetDate).format(),
      registrationDate: dayjs(state.registrationDate).format(),
      kycVerificationDate: dayjs(state.kycVerificationDate).format(),
    });
  },
  clear: () => set(() => initialClientDetailsViewStoreState),
}));
