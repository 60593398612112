import BigNumber from "bignumber.js";
//this is a default table for the ratio of the ranges for the waiting list
//if there is no min deal size it defaults to this
const defaultRatioTable = [
  { min: 1, max: 2 },
  { min: 2, max: 10 },
  { min: 10, max: 20 },
  { min: 20, max: 100 },
  { min: 100, max: 200 },
  { min: 200, max: 1000 },
  { min: 1000, max: 2000 },
  { min: 2000, max: Infinity },
];
//this func takes the max deal size and min deal size of a financial instrument
//it returns a table that can be mapped onto a dynamic component
export const generateRatioTable: (
  maxDealSize: BigNumber,
  minDealSize: BigNumber,
) => { min: BigNumber; max: BigNumber | typeof Infinity }[] = (
  maxDealSize: BigNumber,
  minDealSize: BigNumber,
) => {
  const ratioTable = [];
  for (let i = 0; i < defaultRatioTable.length; i++) {
    // checks if the range between min deal size and max deal size is reached
    // this is if the max deal size is equal to the min for an iteration of the default table
    // or if the max deal size is less then the max value of the iteration
    // it then sets the last row to max deal size and infinity the breaks the loop
    if (
      maxDealSize.isEqualTo(defaultRatioTable[i].min) ||
      maxDealSize.lt(defaultRatioTable[i].max)
    ) {
      //this adds the last entry for the table
      ratioTable.push({
        min: maxDealSize,
        max: Infinity,
      });
      break;
    }
    // otherwise just push the min deal size multipled by min from the table
    // this is done to the min deal size with max so it can make the correct ratio
    ratioTable.push({
      min: minDealSize.multipliedBy(defaultRatioTable[i].min),
      max: minDealSize.multipliedBy(defaultRatioTable[i].max),
    });
  }
  return ratioTable;
};
