import { Context } from "james/security";
import { Company } from "james/legal/company/Company";
import {
  JSONRPCRequestOpts,
  jsonRPCRequest,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";

export interface UpdateMyCompanyRequest {
  context: Context;
  company: Company;
}

export interface UpdateMyCompanyResponse {
  company: Company;
}

export interface UpdateCompanyUnscopedRequest {
  context: Context;
  company: Company;
}

export interface UpdateCompanyUnscopedResponse {
  company: Company;
}

export const Updater = {
  serviceProvider: "company-Updater",
  async UpdateMyCompany(
    request: UpdateMyCompanyRequest,
    additionalHeaders?: Headers,
  ): Promise<UpdateMyCompanyResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${Updater.serviceProvider}.UpdateMyCompany`,
        request,
      },
      {
        additionalHeaders,
      },
    );
  },
  async UpdateCompanyUnscoped(
    request: UpdateCompanyUnscopedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<UpdateCompanyUnscopedResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${Updater.serviceProvider}.UpdateCompanyUnscoped`,
        request,
      },
      opts,
    );
  },
};
