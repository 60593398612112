import { Token, Amount, TransactionState } from "james/ledger";
import dayjs from "dayjs";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { LedgerNetwork } from "../../ledger/Network";
import BigNumber from "bignumber.js";

export class Model {
  public id = "";
  public subscriptionOrderBookID = "";
  public ownerID = "";
  public number = "";
  public assetName = "";
  public token: Token = new Token();
  public exchangeNetwork: LedgerNetwork | "" = "";
  public unitPrice: Amount = new Amount();
  public state: SubscriptionOrderBookState | "" = "";
  public openDate: string = dayjs().format();
  public closeDate: string = dayjs().format();
  public settlementDate: string = dayjs().format();
  public fractionalisationAllowed = false;
  public subscriptionAmount: Amount = new Amount();
  public overSubscriptionAmount: Amount = new Amount();
  public committedAmount: Amount = new Amount();
  public subscribedAmount: Amount = new Amount();
  public subscribedTokens: Amount = new Amount();
  public settlementAmount: Amount = new Amount();
  public settlementTokens: Amount = new Amount();
  public leftOverTokens: BigNumber = new BigNumber("0");
  public underwritingIDs: string[] = [];
  public stateResolutionCount = 0;
  public settlementTransactionState: TransactionState | "" = "";
  public settlementTransactionID = "";

  constructor(l?: Model) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.subscriptionOrderBookID = l.subscriptionOrderBookID;
    this.ownerID = l.ownerID;
    this.number = l.number;
    this.assetName = l.assetName;
    this.token = new Token(l.token);
    this.exchangeNetwork = l.exchangeNetwork;
    this.unitPrice = new Amount(l.unitPrice);
    this.state = l.state;
    this.openDate = l.openDate;
    this.closeDate = l.closeDate;
    this.settlementDate = l.settlementDate;
    this.fractionalisationAllowed = l.fractionalisationAllowed;
    this.subscriptionAmount = new Amount(l.subscriptionAmount);
    this.overSubscriptionAmount = new Amount(l.overSubscriptionAmount);
    this.committedAmount = new Amount(l.committedAmount);
    this.subscribedAmount = new Amount(l.subscribedAmount);
    this.subscribedTokens = new Amount(l.subscribedTokens);
    this.settlementAmount = new Amount(l.settlementTokens);
    this.settlementTokens = new Amount(l.settlementTokens);
    this.leftOverTokens = new BigNumber(l.leftOverTokens);
    if (l.underwritingIDs) {
      this.underwritingIDs = [...l.underwritingIDs];
    }
    this.stateResolutionCount = l.stateResolutionCount;
    this.settlementTransactionState = l.settlementTransactionState;
    this.settlementTransactionID = l.settlementTransactionID;
  }
}
