import { ClaimsType } from "james/security/claims";
import { UserGuide, UserState } from "../../user/User";
import { ClientKind, ClientStatus } from "../../client/Client";

export class Login {
  public type: ClaimsType | "" = "";

  public userID = "";

  public expirationTime = 0;

  public clientID = "";

  public firstName = "";

  public lastName = "";

  public email = "";

  public kycStatus = "";

  public userState: UserState | "" = "";

  public clientKind: ClientKind | "" = "";

  public clientStatus: ClientStatus | "" = "";

  public paasAgreementAcceptanceRequired = false;

  public ausAgreementAcceptanceRequired = false;

  public completedGuides: UserGuide[] = [];

  constructor(login?: Login) {
    if (!login) {
      return;
    }
    this.type = login.type;
    this.userID = login.userID;
    this.clientID = login.clientID;
    this.email = login.email;
    this.firstName = login.firstName;
    this.lastName = login.lastName;
    this.kycStatus = login.kycStatus;
    this.userState = login.userState;
    this.clientKind = login.clientKind;
    this.clientStatus = login.clientStatus;
    this.expirationTime = login.expirationTime;
    this.paasAgreementAcceptanceRequired =
      login.paasAgreementAcceptanceRequired;
    this.ausAgreementAcceptanceRequired = login.ausAgreementAcceptanceRequired;
    this.completedGuides = login.completedGuides;
  }

  public static NewFromJWT(jwt: string): Login {
    const claimsPayloadData = jwt.substring(
      jwt.indexOf(".") + 1,
      jwt.lastIndexOf("."),
    );

    const payloadString = window.atob(claimsPayloadData);
    const tokenPOJO = JSON.parse(payloadString);

    return new Login(tokenPOJO);
  }
}
