import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface RequestMUARequest {
  context: Context;
  requestRaw: string;
  serviceName: string;
  serviceProvider: string;
  requestId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RequestMUAResponse {}

export const Authorizer = {
  serviceProvider: "authorization-Authorizer",
  async RequestMUA(
    request: RequestMUARequest,
    additionalHeaders?: Headers,
  ): Promise<RequestMUAResponse> {
    await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${Authorizer.serviceProvider}.RequestMUA`,
        request,
      },
      {
        additionalHeaders: additionalHeaders,
      },
    );
    return {};
  },
};
