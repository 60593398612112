export class SocialMediaLinks {
  public facebook = "";
  public linkedin = "";
  public web = "";
  public youtube = "";

  constructor(s?: SocialMediaLinks) {
    if (!s) return;

    this.facebook = s.facebook;
    this.linkedin = s.linkedin;
    this.web = s.web;
    this.youtube = s.youtube;
  }
}
