import { AuditEntry } from "../audit";

export enum KYCDocumentClassification {
  ProofOfResidence = "Proof of Address",
  IdentificationDocument = "Identification Document",
}

export enum KYCDocumentState {
  Pending = "Pending",
  Uploaded = "Uploaded",
  Deleted = "Deleted",
}

export enum KYCDocumentType {
  // Proof of Identity
  SouthAfricanIDBookType = "South African ID Book",
  SouthAfricanIDCardFrontBackType = "South African ID Card - Front + Back",
  SouthAfricanIDCardFrontType = "South African ID Card - Front",
  SouthAfricanIDCardBackType = "South African ID Card - Back",
  SouthAfricanDriversLicenseFrontBackDocumentType = "South African Driver's License - Front + Back",
  SouthAfricanDriversLicenseFrontDocumentType = "South African Driver's License - Front",
  SouthAfricanDriversLicenseBackDocumentType = "South African Driver's License - Back",
  // PassportType = "Passport",
  // Proof of residence
  BankStatement = "Bank Statement",
  BodyCorporateCorrespondenceType = "Body Corporate Correspondence",
  CellularOrTelephoneInvoiceType = "Cellular or Telephone Invoice",
  GovernmentIssuedDocumentType = "Government Issued Document",
  InsurancePolicyType = "Insurance Policy",
  InvestmentStatementType = "Investment Statement",
  IT3TaxCertificateType = "IT3 Tax Certificate",
  LeaseAgreementType = "Lease Agreement",
  LetterFromMunicipalRepresentativeType = "Letter from Municipal Representative",
  LetterFromTraditionalAuthorityType = "Letter from Traditional Authority",
  LevyStatementType = "Levy Statement",
  MortgageStatementType = "Mortgage Statement",
  MotorVehicleLicenseType = "Motor Vehicle License",
  MunicipalRatesAndTaxesType = "Municipal Rates and Taxes",
  OfficialSARSDocumentType = "Official SARS Document",
  RetailOrCreditAccountStatementType = "Retail or Credit Account Statement",
  SiteVisitType = "Site Visit",
  TelevisionLicenseType = "Television License",
  UtilityBill = "Utility Bill",
}

export const getDocTypesForDocumentClass = (
  docClass: KYCDocumentClassification | "",
) => {
  switch (docClass) {
    case KYCDocumentClassification.IdentificationDocument:
      return [
        KYCDocumentType.SouthAfricanIDCardFrontBackType,
        KYCDocumentType.SouthAfricanIDCardFrontType,
        KYCDocumentType.SouthAfricanIDCardBackType,
        KYCDocumentType.SouthAfricanIDBookType,
        KYCDocumentType.SouthAfricanDriversLicenseFrontBackDocumentType,
        KYCDocumentType.SouthAfricanDriversLicenseFrontDocumentType,
        KYCDocumentType.SouthAfricanDriversLicenseBackDocumentType,
      ];
    case KYCDocumentClassification.ProofOfResidence:
      return [
        KYCDocumentType.UtilityBill,
        KYCDocumentType.BodyCorporateCorrespondenceType,
        KYCDocumentType.CellularOrTelephoneInvoiceType,
        KYCDocumentType.GovernmentIssuedDocumentType,
        KYCDocumentType.InsurancePolicyType,
        KYCDocumentType.InvestmentStatementType,
        KYCDocumentType.IT3TaxCertificateType,
        KYCDocumentType.LeaseAgreementType,
        KYCDocumentType.LetterFromMunicipalRepresentativeType,
        KYCDocumentType.LetterFromTraditionalAuthorityType,
        KYCDocumentType.LevyStatementType,
        KYCDocumentType.MortgageStatementType,
        KYCDocumentType.MotorVehicleLicenseType,
        KYCDocumentType.MunicipalRatesAndTaxesType,
        KYCDocumentType.OfficialSARSDocumentType,
        KYCDocumentType.RetailOrCreditAccountStatementType,
        KYCDocumentType.SiteVisitType,
        KYCDocumentType.TelevisionLicenseType,
        KYCDocumentType.BankStatement,
      ];
    default:
      return [];
  }
};

export class KYCDocument {
  public id = "";

  public ownerID = "";

  public clientID = "";

  public classification: KYCDocumentClassification | "" = "";

  public type: KYCDocumentType | "" = "";

  public state: KYCDocumentState | "" = "";

  public name = "";

  public mimeType = "";

  public description = "";

  public downloadURL = "";

  public uploadURL = "";

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(kycDocument?: KYCDocument) {
    if (!kycDocument) {
      return;
    }
    this.id = kycDocument.id;
    this.ownerID = kycDocument.ownerID;
    this.clientID = kycDocument.clientID;
    this.classification = kycDocument.classification;
    this.type = kycDocument.type;
    this.state = kycDocument.state;
    this.name = kycDocument.name;
    this.mimeType = kycDocument.mimeType;
    this.description = kycDocument.description;
    this.uploadURL = kycDocument.uploadURL;
    this.downloadURL = kycDocument.downloadURL;
    this.auditEntry = new AuditEntry(kycDocument.auditEntry);
  }
}
