import { Amount } from "james/ledger";
import { Fee, FeeState } from "./Fee";
import { Entry } from "../audit/Entry";
import BigNumber from "bignumber.js";

export const DigitalInstrumentFirstTimeMintingFeeTypeName =
  "mesh::remuneration/DigitalInstrumentFirstTimeMintingFee";

export class DigitalInstrumentFirstTimeMintingFee implements Fee {
  public ["@type"]: string = DigitalInstrumentFirstTimeMintingFeeTypeName;

  public id = "";

  public responsibleGroupID = "";

  public digitalInstrumentID = "";

  public amount: Amount = new Amount();

  public vatRate: BigNumber = BigNumber("0");

  public transactionID = "";

  public auditEntry: Entry = new Entry();

  public state: FeeState = FeeState.Pending;

  constructor(fee?: DigitalInstrumentFirstTimeMintingFee) {
    if (!fee) {
      return;
    }
    this.id = fee.id;
    this.responsibleGroupID = fee.responsibleGroupID;
    this.digitalInstrumentID = fee.digitalInstrumentID;
    this.amount = new Amount(fee.amount);
    this.vatRate = new BigNumber(fee.vatRate);
    this.transactionID = fee.transactionID;
    this.auditEntry = new Entry(fee.auditEntry);
    this.state = fee.state;
  }

  feeAmount(): Amount {
    return this.amount;
  }

  feeVATRate(): BigNumber {
    return this.vatRate;
  }

  feeTotal(): Amount {
    return this.amount.setValue(
      this.amount.value.plus(this.amount.value.multipliedBy(this.vatRate)),
    );
  }

  feeID(): string {
    return this.id;
  }

  feeName(): string {
    return "First Time Minting Fee";
  }

  feeResponsibleGroupID(): string {
    return this.responsibleGroupID;
  }

  feeTransactionID(): string {
    return this.transactionID;
  }

  feeState(): FeeState {
    return this.state;
  }
}
