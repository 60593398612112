import { Context } from "james/security";
import { Client } from "james/client";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Identifier } from "james/search/identifier/Identifier";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface RetrieveGroupClientRequest {
  context: Context;
  groupID: string;
}

export interface RetrieveGroupClientResponse {
  client: Client;
}

export interface RetrieveMyClientRequest {
  context: Context;
}

export interface RetrieveMyClientResponse {
  client: Client;
}

export interface RetrieveClientUnscopedRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveClientUnscopedResponse {
  client: Client;
}

export const ClientManagerReadServiceProviderName = "client-ManagerRead";

export const Manager = {
  async RetrieveGroupClient(
    request: RetrieveGroupClientRequest,
    additionalHeaders?: Headers,
  ): Promise<RetrieveGroupClientResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshAuthorizedURL"),
      method: `${ClientManagerReadServiceProviderName}.RetrieveGroupClient`,
      request,
    });
  },
  async RetrieveMyClient(
    request: RetrieveMyClientRequest,
    additionalHeaders?: Headers,
  ): Promise<RetrieveMyClientResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshAuthenticatedURL"),
      method: `${ClientManagerReadServiceProviderName}.RetrieveMyClient`,
      request,
    });
    return { client: new Client(response.client) };
  },
  async RetrieveClientUnscoped(
    request: RetrieveClientUnscopedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveClientUnscopedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${ClientManagerReadServiceProviderName}.RetrieveClientUnscoped`,
        request,
      },
      opts,
    );
    return { client: new Client(response.client) };
  },
};
