import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const PREFIX = "CompanyExistsDialog";

const classes = {
  dialogTitleRoot: `${PREFIX}-dialogTitleRoot`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleRoot}`]: {
    backgroundColor: theme.palette.custom.midnight,
    border: "none",
    padding: theme.spacing(2),
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 629,
  },
}));

export interface CompanyExistsDialogProps {
  showDialog: boolean;
  onDismissClick: () => void;
}

export function CompanyExistsDialog(props: CompanyExistsDialogProps) {
  return (
    <StyledDialog
      open={props.showDialog}
      onClose={props.onDismissClick}
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <Typography variant="h5" children="Company already exists!" />
      </DialogTitle>
      <DialogContent className={classes.dialogContentRootOverride}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography>
              The company that you have entered has already been registered on
              Mesh. Please get in touch with your company for administrator
              access.
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row-reverse">
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  children="dismiss"
                  id="companyExistDialog-dismiss-button"
                  onClick={props.onDismissClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
