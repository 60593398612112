import React from "react";
import { Router } from "routes";
import { Table } from "./Table";
import { View } from "./View";

export function Transactions() {
  return (
    <Router
      baseURL={"/consistency/transactions"}
      redirectPath={"/consistency/transactions/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/consistency/transactions/table",
          component: <Table />,
        },
        {
          name: "View",
          id: "view",
          path: "/consistency/transactions/view",
          component: <View />,
        },
      ]}
    />
  );
}
