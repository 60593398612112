import { AuditEntry } from "james/audit";
import { Asset } from "james/ledger/Asset";
import { Token } from "james/ledger/Token";
import dayjs from "dayjs";

export const CurrencyStablecoinTypeName = "mesh::financial/CurrencyStablecoin";

export class CurrencyStablecoin implements Asset {
  public ["@type"]: string = CurrencyStablecoinTypeName;

  public id = "";

  public ownerID = "";

  public name = "";

  public shortName = "";

  public rate = 0;

  public currencyID = "";

  public token: Token = new Token();

  public issueDate: string = dayjs().format();

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(currencyStablecoin?: CurrencyStablecoin) {
    if (!currencyStablecoin) {
      return;
    }
    this.id = currencyStablecoin.id;
    this.ownerID = currencyStablecoin.ownerID;
    this.name = currencyStablecoin.name;
    this.shortName = currencyStablecoin.shortName;
    this.rate = currencyStablecoin.rate;
    this.currencyID = currencyStablecoin.currencyID;
    this.token = new Token(currencyStablecoin.token);
    this.issueDate = currencyStablecoin.issueDate;
    this.auditEntry = new AuditEntry(currencyStablecoin.auditEntry);
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetShortName(): string {
    return this.shortName;
  }
}
