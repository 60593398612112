import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQueries, useQueryClient } from "react-query";
import { useApplicationContext } from "context/Application/Application";
import { UnexpectedTranslatedError } from "context/Error";
import { useFirebaseContext } from "../Firebase";
import { v4 as uuidV4 } from "uuid";
import { ContextType, PromiseResolvers, QueryOpts } from "./type";

const Context = React.createContext({} as ContextType);

export function QueryCacheControllerContext({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [queryList, setQueryList] = useState<
    {
      key: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: () => Promise<any>;
      cacheTime?: number;
      staleTime: number;
    }[]
  >([]);

  const promiseLookup = useRef<{ [id: string]: PromiseResolvers }>({});
  const queryLookup = useRef<{ [key: string]: boolean }>({});
  const reactQueryClient = useQueryClient();
  const { userAuthenticated } = useApplicationContext();
  const { firebaseAuthenticated } = useFirebaseContext();

  const queriesResult = useQueries(
    queryList.map((v) => ({
      queryKey: v.key,
      queryFn: v.callback,
      cacheTime: v.cacheTime,
      staleTime: v.staleTime,
      // by default this is set false
      refetchOnWindowFocus: false,
    })),
  );

  useEffect(() => {
    for (const val of queryList) {
      const query = reactQueryClient
        .getQueryCache()
        .find(val.key, { exact: true });

      // if query is not in the cache yet, continue
      if (!query) {
        continue;
      }

      if (query.state.status === "success") {
        for (const currentKey in promiseLookup.current) {
          if (currentKey.includes(val.key)) {
            promiseLookup.current[currentKey]?.resolvePromise(query.state.data);
            delete promiseLookup.current[currentKey];
          }
        }
        continue;
      }

      if (query.state.status === "error") {
        for (const currentKey in promiseLookup.current) {
          if (currentKey.includes(val.key)) {
            promiseLookup.current[currentKey]?.rejectPromise(query.state.error);
            if (promiseLookup.current[currentKey]) {
              delete promiseLookup.current[currentKey];
            }
          }
        }

        reactQueryClient.removeQueries(query.queryKey);
        setQueryList((v) => v.filter((v) => v.key !== val.key));
        delete queryLookup.current[val.key];
      }
    }
  }, [queriesResult]);

  // clean up state when logging out
  useEffect(() => {
    if (!(userAuthenticated && firebaseAuthenticated)) {
      for (const query of queryList) {
        reactQueryClient.removeQueries(query.key);
      }
      setQueryList([]);
      promiseLookup.current = {};
      queryLookup.current = {};
    }
  }, [userAuthenticated, firebaseAuthenticated]);

  const registerQuery = useCallback(
    (ID: string, callback: () => Promise<unknown>, opts?: QueryOpts) => {
      // query found
      if (queryLookup.current[ID]) {
        return;
      }

      //
      // if we reach here it means the query was not found,
      // so a new query will be created
      //

      // add query to list
      setQueryList((v) => [
        ...v,
        {
          key: ID,
          callback,
          staleTime: opts && opts.staleTime ? opts.staleTime : 0, // 0 seconds
          cacheTime: opts && opts.cacheTime ? opts.cacheTime : 5 * 60 * 1000, // 5 min
        },
      ]);

      // add query to lookup
      queryLookup.current[ID] = true;
    },
    [reactQueryClient],
  );

  const executeQuery = useCallback(
    async (ID: string): Promise<unknown> => {
      // determine if there is query matching the key already in the reactQueryClient cache
      const cachedQuery = reactQueryClient
        .getQueryCache()
        .find(ID, { exact: true });

      // query found
      if (cachedQuery) {
        // find query from the QueryCacheController queryList
        const queryFromList = queryList.find((v) => v.key === ID);

        //
        // check to see if the query cache is expired or not.
        // If expired remove the query from cache
        //
        if (
          cachedQuery.options.cacheTime &&
          new Date().getTime() -
            new Date(cachedQuery.state.dataUpdatedAt).getTime() >
            cachedQuery.options.cacheTime
        ) {
          reactQueryClient.removeQueries(ID, { exact: true });
          setQueryList((v) => v.filter((v) => v.key !== ID));
          delete queryLookup.current[ID];
          if (queryFromList) {
            registerQuery(ID, queryFromList.callback, {
              staleTime: queryFromList.staleTime,
              cacheTime: queryFromList.cacheTime,
            });
          }
        } else {
          //
          // if we reach here the data from the cache has not expired
          //

          //
          // return data from the query cache if the query is in success state
          //
          if (cachedQuery.state.status === "success") {
            // if the data is stale invalidate the data
            if (cachedQuery.isStale()) {
              reactQueryClient
                .invalidateQueries(cachedQuery.queryKey, {
                  exact: true,
                })
                .finally();
            }

            return cachedQuery.state.data;
          }

          // if the query is in a error state, the query will get reset
          if (cachedQuery.state.status === "error") {
            // remove the query from the reactQueryClient cache
            reactQueryClient.removeQueries(ID, { exact: true });
            // remove from the list queries that query cache controller cares about
            setQueryList((v) => v.filter((v) => v.key !== ID));
            // remove from the query lookup
            delete queryLookup.current[ID];

            if (queryFromList) {
              // re-register the query
              registerQuery(ID, queryFromList.callback, {
                staleTime: queryFromList.staleTime,
                cacheTime: queryFromList.cacheTime,
              });
            }
          }
        }
      }

      //
      // if we reach here it implies that query was not found in the
      // in reactQueryClient cache. So we need to confirm that query is
      // inside the local state before constructing a promise to await it
      //
      if (!queryLookup.current[ID]) {
        throw UnexpectedTranslatedError;
      }

      //
      // construct the promise to be resolved once the query state is
      // success or error
      //

      const uuid = uuidV4();
      promiseLookup.current[`${uuid}-${ID}`] = {
        resolvePromise: () => {
          return;
        },
        rejectPromise: () => {
          return;
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new Promise<any>(
        (
          resolve: (result: unknown) => void,
          reject: (reason?: unknown) => void,
        ) => {
          promiseLookup.current[`${uuid}-${ID}`].resolvePromise = resolve;
          promiseLookup.current[`${uuid}-${ID}`].rejectPromise = reject;
        },
      );
    },
    [reactQueryClient, queryList],
  );

  return (
    <Context.Provider
      value={{
        registerQuery,
        executeQuery,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useQueryCacheControllerContext = () => useContext(Context);
export { useQueryCacheControllerContext };
