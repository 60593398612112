import { Box, Skeleton, useTheme } from "@mui/material";
import React from "react";

export const LoadingRow = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          height: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          backgroundColor: (theme) => theme.palette.custom.midnight,
          gridTemplateRows: "auto 0px",
          alignContent: "center",
          width: "100%",
          display: "grid",
          padding: (theme) => theme.spacing(1.8, 2, 1.8, 2),
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={91}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={91}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={91}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={79}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={119}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={91}
            height={14}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={119}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={91}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={56}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={64}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={86}
            height={14}
          />
          <Box sx={() => ({ height: (theme) => theme.spacing(1.8) })} />
          <Skeleton
            sx={{
              transform: "none",
            }}
            width={100}
            height={14}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: 36,
          backgroundColor: theme.palette.custom.grape,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <Skeleton
          sx={{
            transform: "none",
          }}
          width={100}
          height={14}
        />
      </Box>
    </>
  );
};
