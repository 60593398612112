import { IdentifierType } from ".";

export interface IDIdentifierType {
  type: IdentifierType;
  id: string;
}

export function IDIdentifier(id: string): IDIdentifierType {
  return {
    type: IdentifierType.IDIdentifierTypeName,
    id,
  };
}
