import { Amount } from "james/ledger";
import dayjs from "dayjs";

export enum LiquidityPoolType {
  ConstantProduct = "Constant Product",
  Unknown = "Unknown",
}

export class LiquidityPool {
  public id = "";

  public type: LiquidityPoolType | "" = "";

  public amountA: Amount = new Amount();

  public amountB: Amount = new Amount();

  public totalShares: Amount = new Amount();

  public totalTrustLines = 0;

  public feeBasisPoints = 0;

  public lastModified: string = dayjs().format();

  constructor(liquidityPool?: LiquidityPool) {
    if (!liquidityPool) {
      return;
    }
    this.id = liquidityPool.id;
    this.type = liquidityPool.type;
    this.amountA = new Amount(liquidityPool.amountA);
    this.amountB = new Amount(liquidityPool.amountB);
    this.totalShares = new Amount(liquidityPool.totalShares);
    this.totalTrustLines = liquidityPool.totalTrustLines;
    this.feeBasisPoints = liquidityPool.feeBasisPoints;
    this.lastModified = liquidityPool.lastModified;
  }
}
