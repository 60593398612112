import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CompanyRepresentative as CompanyRepresentativeClass } from "james/legal";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { CompanyRegStep } from "views/KYB/CompanyRegistration";
import { countries } from "james/country";
import { FormFieldsValidation, FormValidator } from "./Validation";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
import { FormState } from "views/KYB/useFormState";

const PREFIX = "CompanyRepresentative";

const classes = {
  sectionsLayout: `${PREFIX}-sectionsLayout`,
  sectionTitleLayout: `${PREFIX}-sectionTitleLayout`,
  sectionHelperText: `${PREFIX}-sectionHelperText`,
  equalColumns2Gap: `${PREFIX}-equalColumns2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.sectionsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.sectionTitleLayout}`]: {
    height: 35,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.sectionHelperText}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.equalColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2),
  },
}));

interface CompanyRepresentativeProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  postalOfficeAddressSame: boolean;
  setPostalOfficeAddressSame: (value: boolean) => void;
  invalidStep: (step: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
  formState: FormState;
  nextPressed: boolean;
  setNextPressed: Dispatch<SetStateAction<boolean>>;
  savingInProgress: boolean;
}

export function CompanyRepresentative(props: CompanyRepresentativeProps) {
  const {
    onChange,
    onNext,
    postalOfficeAddressSame,
    setPostalOfficeAddressSame,
    inValid,
    invalidStep,
    nextPressed,
    savingInProgress,
    setNextPressed,
    formState,
  } = props;
  const companyRepresentative = { ...formState.company.companyRepresentative };
  const [invalidFormFields, setInvalidFormFields] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  const [ranValidation, setRanValidation] = useState(false);

  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormFields = { ...invalidFormFields };
      if (
        formState.company.companyRepresentative.cellphoneNumber !== "" ||
        formState.company.companyRepresentative.emailAddress !== "" ||
        formState.company.companyRepresentative.telephoneNumber !== ""
      ) {
        delete InvalidFormFields.emailAddress;
        delete InvalidFormFields.telephoneNumber;
        delete InvalidFormFields.cellphoneNumber;
      }

      if (
        isEqual(
          new Address(formState.company.companyRepresentative.postalAddress),
          new Address(),
        )
      ) {
        delete InvalidFormFields.postalAddressCountryCode;
        delete InvalidFormFields.postalAddressCity;
        delete InvalidFormFields.postalAddressAddressLine1;
      }
      if (errors.length !== 0) {
        InvalidFormFields = {
          ...InvalidFormFields,
          [field]: errors.join("; "),
        };
      } else {
        delete InvalidFormFields[field];
      }
      setInvalidFormFields(InvalidFormFields);
    }, 600);

  if (postalOfficeAddressSame) {
    companyRepresentative.postalAddress = new Address(
      formState.company.companyRepresentative.physicalAddress,
    );
  }

  useEffect(() => {
    (() => {
      if (postalOfficeAddressSame) {
        setInvalidFormFields((value) => {
          const InvalidFormFields = { ...value };
          delete InvalidFormFields.postalAddressCity;
          delete InvalidFormFields.postalAddressCountryCode;
          delete InvalidFormFields.postalAddressAddressLine1;
          return InvalidFormFields;
        });
      }
    })();
  }, [postalOfficeAddressSame]);

  if (!companyRepresentative.postalAddress) {
    companyRepresentative.postalAddress = new Address(
      companyRepresentative.postalAddress,
    );
  }

  const inputOnUpdate =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      updateState(name, event.target.value);
    };

  const autoCompleteOnUpdate =
    (name: string) =>
    (
      _: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(name, countryOption ? countryOption.value : "");
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateState = (name: string, value: any) => {
    const keys = name.split(".") as (keyof CompanyRepresentativeClass)[];
    const newCompanyRepresentative = companyRepresentative;
    switch (keys.length) {
      case 1:
        newCompanyRepresentative[keys[0]] = value;
        break;
      case 2:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (newCompanyRepresentative[keys[0]] as any)[keys[1]] = value;
        if (postalOfficeAddressSame && keys[0] === "physicalAddress") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (newCompanyRepresentative.postalAddress as any)[keys[1]] = value;
        }
        break;
      default:
        console.error("unable to update company representative");
    }
    InlineFormValidation(camelCase(name), value)();
    onChange("companyRepresentative")(
      new CompanyRepresentativeClass(
        newCompanyRepresentative as CompanyRepresentativeClass,
      ),
    );
  };

  const sameAsCompanyRegisteredAddress = () => {
    setPostalOfficeAddressSame(!postalOfficeAddressSame);
  };

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(
      invalidFormFields,
      formState.company.companyRepresentative,
      setInvalidFormFields,
    );
  }

  useEffect(() => {
    if (!savingInProgress && nextPressed) {
      if (
        FormFieldsValidation(
          invalidFormFields,
          formState.company.companyRepresentative,
          setInvalidFormFields,
        )
      ) {
        onNext(CompanyRegStep.connectedIndividuals)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.companyRepresentative, false);
      } else {
        onNext(CompanyRegStep.connectedIndividuals)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.companyRepresentative, true);
      }
    }
  }, [savingInProgress, nextPressed]);

  return (
    <Root>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary">
            Company Representative
          </Typography>
        </Box>
      </Box>

      <Typography
        style={{ marginTop: "8px", marginBottom: "32px" }}
        variant="subtitle2"
      >
        Add the details of an authorised person who may act on behalf of your
        company.
      </Typography>

      <div className={classes.sectionsLayout}>
        {/* Personal Details */}
        <div>
          <Typography
            className={classes.sectionTitleLayout}
            variant="h6"
            children="Personal Details"
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="companyRepresentative-personalDetails-firstName"
              variant="outlined"
              fullWidth
              margin="dense"
              label="First Name"
              value={companyRepresentative.firstName}
              onChange={inputOnUpdate("firstName")}
              error={Boolean(invalidFormFields.firstName)}
              helperText={invalidFormFields.firstName}
            />
            <TextField
              id="companyRepresentative-personalDetails-middleName"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Middle Names(s)"
              value={companyRepresentative.middleNames}
              onChange={inputOnUpdate("middleNames")}
              placeholder="Optional"
              helperText={invalidFormFields.middleNames}
            />
          </div>
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="companyRepresentative-personalDetails-lastName"
              variant="outlined"
              fullWidth
              margin="dense"
              label="Last Name"
              value={companyRepresentative.lastName}
              onChange={inputOnUpdate("lastName")}
              error={Boolean(invalidFormFields.lastName)}
              helperText={invalidFormFields.lastName}
            />
          </div>
        </div>

        {/* Contact Details */}
        <div>
          <Typography
            className={classes.sectionTitleLayout}
            variant="h6"
            children="Contact Details"
          />
          <Typography variant="body2" className={classes.sectionHelperText}>
            You are required to enter at least one of the following.
          </Typography>
          <TextField
            id="companyRepresentative-contactDetails-email"
            variant="outlined"
            fullWidth
            label="Email"
            margin="dense"
            value={companyRepresentative.emailAddress}
            onChange={inputOnUpdate("emailAddress")}
            error={Boolean(invalidFormFields.emailAddress)}
            helperText={invalidFormFields.emailAddress}
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="companyRepresentative-contactDetails-mobile"
              variant="outlined"
              margin="dense"
              fullWidth
              label="Mobile"
              value={companyRepresentative.cellphoneNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (
                  /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                  value.length === 0
                ) {
                  inputOnUpdate("cellphoneNumber")(event);
                }
              }}
              error={Boolean(invalidFormFields.cellphoneNumber)}
              helperText={invalidFormFields.cellphoneNumber}
            />
            <TextField
              id="companyRepresentative-contactDetails-telephone"
              variant="outlined"
              fullWidth
              margin="dense"
              label="Telephone"
              value={companyRepresentative.telephoneNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (
                  /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                  value.length === 0
                ) {
                  inputOnUpdate("telephoneNumber")(event);
                }
              }}
              error={Boolean(invalidFormFields.telephoneNumber)}
              helperText={invalidFormFields.telephoneNumber}
            />
          </div>
        </div>

        {/* Physical Address */}
        {companyRepresentative.physicalAddress && (
          <div>
            <Typography
              className={classes.sectionTitleLayout}
              variant="h6"
              children="Physical Address"
            />
            <CountryDropdown
              id="companyRepresentative-physicalAddress-country"
              isOptionEqualToValue={(option, value) => {
                if (value.value === "") {
                  return true;
                }
                return option === value;
              }}
              onChange={autoCompleteOnUpdate("physicalAddress.countryCode")}
              value={
                companyRepresentative.physicalAddress.countryCode === ""
                  ? {
                      value: "",
                      label: "",
                    }
                  : {
                      value: companyRepresentative.physicalAddress.countryCode,
                      label: countries.filter(
                        (c) =>
                          c.value ===
                          new Address(companyRepresentative.physicalAddress)
                            .countryCode,
                      )[0].label,
                    }
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Country"
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Select...",
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={Boolean(invalidFormFields.physicalAddressCountryCode)}
                  fullWidth
                  helperText={invalidFormFields.physicalAddressCountryCode}
                />
              )}
            />
            <TextField
              id="companyRepresentative-physicalAddress-line1"
              variant="outlined"
              fullWidth
              label="Address 1"
              margin="dense"
              value={companyRepresentative.physicalAddress.addressLine1}
              onChange={inputOnUpdate("physicalAddress.addressLine1")}
              error={Boolean(invalidFormFields.physicalAddressAddressLine1)}
              helperText={invalidFormFields.physicalAddressAddressLine1}
            />
            <TextField
              id="companyRepresentative-physicalAddress-line2"
              variant="outlined"
              margin="dense"
              fullWidth
              label="Address 2 (Optional)"
              value={companyRepresentative.physicalAddress.addressLine2}
              onChange={inputOnUpdate("physicalAddress.addressLine2")}
              helperText={invalidFormFields.physicalAddressAddressLine2}
            />
            <div className={classes.equalColumns2Gap}>
              <TextField
                variant="outlined"
                fullWidth
                id="companyRepresentative-physicalAddress-suburb"
                margin="dense"
                label="City"
                value={companyRepresentative.physicalAddress.city}
                onChange={inputOnUpdate("physicalAddress.city")}
                error={Boolean(invalidFormFields.physicalAddressCity)}
                helperText={invalidFormFields.physicalAddressCity}
              />
              <TextField
                id="companyRepresentative-physicalAddress-city"
                variant="outlined"
                fullWidth
                label="Suburb"
                margin="dense"
                value={companyRepresentative.physicalAddress.suburb}
                onChange={inputOnUpdate("physicalAddress.suburb")}
                error={Boolean(invalidFormFields.physicalAddressSuburb)}
                helperText={invalidFormFields.physicalAddressSuburb}
              />
            </div>
            <div className={classes.equalColumns2Gap}>
              <TextField
                id="companyRepresentative-physicalAddress-province"
                variant="outlined"
                fullWidth
                label="Province"
                margin="dense"
                value={companyRepresentative.physicalAddress.province}
                onChange={inputOnUpdate("physicalAddress.province")}
                error={Boolean(invalidFormFields.physicalAddressProvince)}
                helperText={invalidFormFields.physicalAddressProvince}
              />
              <TextField
                id="companyRepresentative-physicalAddress-postalCode"
                variant="outlined"
                fullWidth
                label="Postal Code"
                margin="dense"
                value={companyRepresentative.physicalAddress.postalCode}
                onChange={inputOnUpdate("physicalAddress.postalCode")}
                error={Boolean(invalidFormFields.physicalAddressPostalCode)}
                helperText={invalidFormFields.physicalAddressPostalCode}
              />
            </div>
          </div>
        )}

        {/* Postal Address */}
        <div>
          <div className={classes.sectionTitleLayout}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h6" children="Postal Address" />
              <Typography
                sx={(theme) => ({
                  paddingLeft: "4px",
                  color: theme.palette.text.disabled,
                })}
                fontSize="12px"
                children="(optional)"
              />
            </Box>
            <FormControlLabel
              id="companyRepresentative-postalAddress-sameAsPhysicalAddress"
              labelPlacement="start"
              control={
                <Checkbox
                  color="primary"
                  checked={postalOfficeAddressSame}
                  onChange={sameAsCompanyRegisteredAddress}
                />
              }
              label="Copy Physical"
            />
          </div>
          <CountryDropdown
            id="companyRepresentative-postalAddress-country"
            isOptionEqualToValue={(option, value) => {
              if (value.value === "") {
                return true;
              }
              return option === value;
            }}
            disabled={postalOfficeAddressSame}
            onChange={autoCompleteOnUpdate("postalAddress.countryCode")}
            value={
              companyRepresentative.postalAddress.countryCode === ""
                ? {
                    value: "",
                    label: "",
                  }
                : {
                    value: companyRepresentative.postalAddress.countryCode,
                    label: countries.filter(
                      (c) =>
                        c.value ===
                        new Address(companyRepresentative.postalAddress)
                          .countryCode,
                    )[0].label,
                  }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Country"
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select...",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={Boolean(invalidFormFields.postalAddressCountryCode)}
                fullWidth
                helperText={invalidFormFields.postalAddressCountryCode}
              />
            )}
          />
          <TextField
            id="companyRepresentative-postalAddress-line1"
            disabled={postalOfficeAddressSame}
            variant="outlined"
            margin="dense"
            fullWidth
            label="Address 1"
            value={companyRepresentative.postalAddress.addressLine1}
            onChange={inputOnUpdate("postalAddress.addressLine1")}
            error={Boolean(invalidFormFields.postalAddressAddressLine1)}
            helperText={invalidFormFields.postalAddressAddressLine1}
          />
          <TextField
            id="companyRepresentative-postalAddress-line2"
            disabled={postalOfficeAddressSame}
            variant="outlined"
            fullWidth
            margin="dense"
            label="Address 2 (Optional)"
            value={companyRepresentative.postalAddress.addressLine2}
            onChange={inputOnUpdate("postalAddress.addressLine2")}
            helperText={invalidFormFields.postalAddressAddressLine2}
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="companyRepresentative-postalAddress-city"
              disabled={postalOfficeAddressSame}
              variant="outlined"
              fullWidth
              label="City"
              margin="dense"
              value={companyRepresentative.postalAddress.city}
              onChange={inputOnUpdate("postalAddress.city")}
              error={Boolean(invalidFormFields.postalAddressCity)}
              helperText={invalidFormFields.postalAddressCity}
            />
            <TextField
              id="companyRepresentative-postalAddress-suburb"
              disabled={postalOfficeAddressSame}
              variant="outlined"
              fullWidth
              label="Suburb"
              margin="dense"
              value={companyRepresentative.postalAddress.suburb}
              onChange={inputOnUpdate("postalAddress.suburb")}
              helperText={invalidFormFields.postalAddressSuburb}
            />
          </div>
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="companyRepresentative-postalAddress-province"
              disabled={postalOfficeAddressSame}
              variant="outlined"
              fullWidth
              label="Province"
              margin="dense"
              value={companyRepresentative.postalAddress.province}
              onChange={inputOnUpdate("postalAddress.province")}
              helperText={invalidFormFields.postalAddressProvince}
            />
            <TextField
              id="companyRepresentative-postalAddress-postalCode"
              disabled={postalOfficeAddressSame}
              variant="outlined"
              fullWidth
              label="Postal Code"
              margin="dense"
              value={companyRepresentative.postalAddress.postalCode}
              onChange={inputOnUpdate("postalAddress.postalCode")}
              helperText={invalidFormFields.postalAddressPostalCode}
            />
          </div>
        </div>
      </div>
    </Root>
  );
}
