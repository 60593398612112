import { ValidationResult } from "common/validation";
import {
  IndependentReview,
  SocialMediaLinks,
  NewsArticle,
  MarketingMedia,
  InstrumentMarketingContent,
} from "james/marketing";
import { Asset } from "james/ledger/Asset";
import BigNumber from "bignumber.js";

export interface MarketingMediaFormData {
  asset: Asset;
  instrumentMarketingContent: InstrumentMarketingContent;
}

export type FormUpdaterSpecsType = {
  estimatedAnnualReturn: (
    estimatedAnnualReturn: BigNumber,
    prevFormData?: MarketingMediaFormData,
  ) => MarketingMediaFormData;
  investmentObjective: (
    investmentObjective: string,
    prevFormData?: MarketingMediaFormData,
  ) => MarketingMediaFormData;
  socialMediaLinks: (
    socialMediaLinks: SocialMediaLinks,
    prevFormData?: MarketingMediaFormData,
  ) => MarketingMediaFormData;
  independentReviews: (
    independentReviews: IndependentReview[],
    prevFormData?: MarketingMediaFormData,
  ) => MarketingMediaFormData;
  marketingMedia: (
    marketingMedia: MarketingMedia[],
    prevFormData?: MarketingMediaFormData,
  ) => MarketingMediaFormData;
  newsArticles: (
    newsArticles: NewsArticle[],
    prevFormData?: MarketingMediaFormData,
  ) => MarketingMediaFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  estimatedAnnualReturn: (
    estimatedAnnualReturn: BigNumber,
    prevFormData?: MarketingMediaFormData,
  ): MarketingMediaFormData => {
    const formData = prevFormData as MarketingMediaFormData;
    formData.instrumentMarketingContent.estimatedAnnualReturn =
      estimatedAnnualReturn;
    return {
      ...formData,
      instrumentMarketingContent: new InstrumentMarketingContent(
        formData.instrumentMarketingContent,
      ),
    };
  },
  investmentObjective: (
    investmentObjective: string,
    prevFormData?: MarketingMediaFormData,
  ): MarketingMediaFormData => {
    const formData = prevFormData as MarketingMediaFormData;
    formData.instrumentMarketingContent.investmentObjective =
      investmentObjective;
    return {
      ...formData,
      instrumentMarketingContent: new InstrumentMarketingContent(
        formData.instrumentMarketingContent,
      ),
    };
  },
  socialMediaLinks: (
    socialMediaLinks: SocialMediaLinks,
    prevFormData?: MarketingMediaFormData,
  ): MarketingMediaFormData => {
    const formData = prevFormData as MarketingMediaFormData;
    formData.instrumentMarketingContent.socialMediaLinks.facebook =
      socialMediaLinks.facebook;
    formData.instrumentMarketingContent.socialMediaLinks.web =
      socialMediaLinks.web;
    formData.instrumentMarketingContent.socialMediaLinks.linkedin =
      socialMediaLinks.linkedin;
    formData.instrumentMarketingContent.socialMediaLinks.youtube =
      socialMediaLinks.youtube;

    return {
      ...formData,
      instrumentMarketingContent: new InstrumentMarketingContent(
        formData.instrumentMarketingContent,
      ),
    };
  },
  independentReviews: (
    independentReviews: IndependentReview[],
    prevFormData?: MarketingMediaFormData,
  ): MarketingMediaFormData => {
    const formData = prevFormData as MarketingMediaFormData;
    formData.instrumentMarketingContent.independentReviews = independentReviews;
    return {
      ...formData,
      instrumentMarketingContent: new InstrumentMarketingContent(
        formData.instrumentMarketingContent,
      ),
    };
  },
  marketingMedia: (
    marketingMedia: MarketingMedia[],
    prevFormData?: MarketingMediaFormData,
  ): MarketingMediaFormData => {
    const formData = prevFormData as MarketingMediaFormData;
    formData.instrumentMarketingContent.marketMedia = marketingMedia;
    return {
      ...formData,
      instrumentMarketingContent: new InstrumentMarketingContent(
        formData.instrumentMarketingContent,
      ),
    };
  },
  newsArticles: (
    newsArticles: NewsArticle[],
    prevFormData?: MarketingMediaFormData,
  ): MarketingMediaFormData => {
    const formData = prevFormData as MarketingMediaFormData;
    formData.instrumentMarketingContent.newsArticles = newsArticles;
    return {
      ...formData,
      instrumentMarketingContent: new InstrumentMarketingContent(
        formData.instrumentMarketingContent,
      ),
    };
  },
};

export const formDataValidationFunc = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formData: MarketingMediaFormData,
): Promise<ValidationResult> => {
  const validationResult: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  // TODO: There are no validation rules as of yet. Add them here.

  return validationResult;
};
