import { Identifier } from "james/search/identifier/Identifier";
import { Context } from "james/security";
import { InstrumentMarketingContent } from "./InstrumentMarketingContent";
import { Query } from "james/search/query";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";

export interface ReadOneMarketingContentRequest {
  context: Context;
  identifier: Identifier;
}

export interface ReadOneMarketingContentResponse {
  instrumentMarketingContent: InstrumentMarketingContent;
}

export interface ReadManyMarketingContentRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadManyMarketingContentResponse {
  records: InstrumentMarketingContent[];
  total: number;
}

export const Reader = {
  serviceProvider: "marketing-Reader",
  async ReadOneMarketingContent(
    request: ReadOneMarketingContentRequest,
  ): Promise<ReadOneMarketingContentResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Reader.serviceProvider}.ReadOne`,
      request,
    });
    return {
      instrumentMarketingContent: new InstrumentMarketingContent(
        response.instrumentMarketingContent,
      ),
    };
  },
  async ReadManyMarketingContent(
    request: ReadManyMarketingContentRequest,
  ): Promise<ReadManyMarketingContentResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Reader.serviceProvider}.ReadMany`,
      request,
    });
    return {
      records: response.records.map(
        (i: InstrumentMarketingContent) => new InstrumentMarketingContent(i),
      ),
      total: response.total,
    };
  },
};

export const UnscopedReader = {
  serviceProvider: "marketing-ReaderUNSCOPED",
  async ReadOneMarketingContentUNSCOPED(
    request: ReadOneMarketingContentRequest,
  ): Promise<ReadOneMarketingContentResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${UnscopedReader.serviceProvider}.ReadOneUNSCOPED`,
      request,
    });
    return {
      instrumentMarketingContent: new InstrumentMarketingContent(
        response.InstrumentMarketingContent,
      ),
    };
  },
  async ReadManyMarketingContentUNSCOPED(
    request: ReadManyMarketingContentRequest,
  ): Promise<ReadManyMarketingContentResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${UnscopedReader.serviceProvider}.ReadManyUNSCOPED`,
      request,
    });
    return {
      records: response.records.map(
        (i: InstrumentMarketingContent) => new InstrumentMarketingContent(i),
      ),
      total: response.total,
    };
  },
};

export function useReadOneMarketingContent(doUnscopedRead?: boolean) {
  const [readRequest, setReadRequest] = useState<
    ReadOneMarketingContentRequest | undefined
  >();
  const [readResponse, setReadResponse] = useState<
    ReadOneMarketingContentResponse | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (!readRequest) {
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        setReadResponse(
          doUnscopedRead
            ? await UnscopedReader.ReadOneMarketingContentUNSCOPED(readRequest)
            : await Reader.ReadOneMarketingContent(readRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading one instrument marketing content: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error reading one instrument marketing content: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [readRequest, doUnscopedRead]);

  return {
    readRequest,
    setReadRequest,
    readResponse,
    loading,
    error,
  };
}

export function useReadManyMarketingContent(
  initialRequest: ReadManyMarketingContentRequest,
  doUnscopedRead: boolean,
) {
  const { authContext } = useApplicationContext();
  const [readRequest, setReadRequest] = useState<
    ReadManyMarketingContentRequest | undefined
  >(
    initialRequest || {
      context: authContext,
      criteria: {},
      query: new Query(),
    },
  );
  const [readResponse, setReadResponse] =
    useState<ReadManyMarketingContentResponse>({
      records: [],
      total: 0,
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (!readRequest) {
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        setReadResponse(
          doUnscopedRead
            ? await UnscopedReader.ReadManyMarketingContentUNSCOPED(readRequest)
            : await Reader.ReadManyMarketingContent(readRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading many instrument marketing content: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error reading many instrument marketing content: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [readRequest, doUnscopedRead]);

  return {
    readRequest,
    setReadRequest,
    readResponse,
    loading,
    error,
  };
}
