import config from "react-global-configuration";
import { ETFStablecoin } from "./ETFStablecoin";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Amount } from "../ledger/Amount";

export interface DraftUpdateETFStablecoinRequest {
  context: Context;
  etfStablecoin: ETFStablecoin;
}

export interface DraftUpdateETFStablecoinResponse {
  etfStablecoin: ETFStablecoin;
}

export interface IncreaseETFStablecoinMaximumUnitsRequest {
  context: Context;
  etfStablecoinID: string;
  amount: Amount;
}

export interface IncreaseETFStablecoinMaximumUnitsResponse {
  etfStablecoin: ETFStablecoin;
}

export interface DecreaseETFStablecoinMaximumUnitsRequest {
  context: Context;
  etfStablecoinID: string;
  amount: Amount;
}

export interface DecreaseETFStablecoinMaximumUnitsResponse {
  etfStablecoin: ETFStablecoin;
}

export interface ChangeETFStablecoinMaturityDateRequest {
  context: Context;
  etfStablecoinID: string;
  maturityDate: string;
}

export interface ChangeETFStablecoinMaturityDateResponse {
  etfStablecoin: ETFStablecoin;
}

export const ETFStablecoinUpdater = {
  serviceProvider: "financial-ETFStablecoinUpdater",
  async DraftUpdateETFStablecoin(
    request: DraftUpdateETFStablecoinRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateETFStablecoinResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFStablecoinUpdater.serviceProvider}.DraftUpdateETFStablecoin`,
      request,
    });
    return { etfStablecoin: new ETFStablecoin(response.etfStablecoin) };
  },
  async IncreaseETFStablecoinMaximumUnits(
    request: IncreaseETFStablecoinMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<IncreaseETFStablecoinMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFStablecoinUpdater.serviceProvider}.IncreaseETFStablecoinMaximumUnits`,
      request,
    });
    return { etfStablecoin: new ETFStablecoin(response.etfStablecoin) };
  },
  async DecreaseETFStablecoinMaximumUnits(
    request: DecreaseETFStablecoinMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<DecreaseETFStablecoinMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFStablecoinUpdater.serviceProvider}.DecreaseETFStablecoinMaximumUnits`,
      request,
    });
    return { etfStablecoin: new ETFStablecoin(response.etfStablecoin) };
  },
  async ChangeETFStablecoinMaturityDate(
    request: ChangeETFStablecoinMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFStablecoinMaturityDateResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFStablecoinUpdater.serviceProvider}.ChangeETFStablecoinMaturityDate`,
      request,
    });
    return { etfStablecoin: new ETFStablecoin(response.etfStablecoin) };
  },
};
