import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { DataSheetSection } from "./DataSheetSection";
import { Amount } from "james/ledger";
import { Model } from "james/views/financialCashFlowView/Model";

export const AssetRepaymentTermsDataSheetSectionTypeName =
  "mesh::marketData/AssetRepaymentTerms";

export class AssetRepaymentTermsDataSheetSection implements DataSheetSection {
  public ["@type"]: string = AssetRepaymentTermsDataSheetSectionTypeName;

  public sectionName: string = "";
  public totalNominal: Amount = new Amount();
  public unitsInCirculation: Amount = new Amount();
  public frequency: Frequency = Frequency.MONTHLY_FREQUENCY;
  public instrumentID = "";
  public cashFlowViewModels: Model[] = [];
  public termStart = "";
  public maturityDate = "";

  constructor(l?: AssetRepaymentTermsDataSheetSection) {
    if (!l) {
      return;
    }
    this.sectionName = l.sectionName;
    this.totalNominal = new Amount(l.totalNominal);
    this.unitsInCirculation = new Amount(l.unitsInCirculation);
    this.frequency = l.frequency;
    this.instrumentID = l.instrumentID;
    this.cashFlowViewModels = l.cashFlowViewModels.map((m) => new Model(m));
    this.termStart = l.termStart;
    this.maturityDate = l.maturityDate;
  }

  public DataSheetSectionName(): string {
    return this.sectionName;
  }
}
