import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";

export type AddMyMeshKeySignatureToTransactionRequest = {
  context: Context;
  transactionID: string;
};

export type AddMyMeshKeySignatureToTransactionResponse = Record<string, never>;

export const TransactionSignatureController = {
  serviceProvider: "stellar-TransactionSignatureController",
  async AddMyMeshKeySignatureToTransaction(
    request: AddMyMeshKeySignatureToTransactionRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<AddMyMeshKeySignatureToTransactionResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthenticatedURL"),
        method: `${TransactionSignatureController.serviceProvider}.AddMyMeshKeySignatureToTransaction`,
        request,
      },
      opts,
    );
  },
};
