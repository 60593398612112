import { Breakpoint } from "@mui/material";
import { BreakpointObject, VirtualElement } from "../types";

export function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
    x: x,
    y: y,
    toJSON: () => ``,
  });
}

export const virtualElement: VirtualElement = {
  getBoundingClientRect: generateGetBoundingClientRect(),
};

export function getBreakpointValue<T>(
  ob: Partial<BreakpointObject<T>>,
  breakpoint: Breakpoint,
): T | undefined {
  const points: Breakpoint[] = ["xs", "sm", "md", "lg", "xl"];

  const idx = points.lastIndexOf(breakpoint);

  for (let i = idx; i >= 0; i--) {
    if (ob[points[i]]) {
      return ob[points[i]];
    }
  }
}
