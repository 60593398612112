import { Box } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import React, { useEffect, useRef, useState } from "react";
import { IndividualClientRegisterCard } from "./components/IndividualClient/IndividualClientRegisterCard";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import { UserRegisteringLoader } from "components/Loaders";
import { useUserRegistrationHelper } from "hooks/helperAPI/useUserRegistrationHelper";
import { useFirebaseContext } from "context/Firebase";
import background from "assets/images/background/background.png";
import { InitiateUserRegistration } from "../../const";
import { CompanyClientRegisterCard } from "./components/CompanyClient/CompanyClientRegisterCard";

export const Register = () => {
  const width = useWidth();
  const { firebaseUserIDToken, firebaseUser } = useFirebaseContext();
  const userRegistrationStarted = useRef(false);
  const [companyName, setCompanyName] = useState("");
  const {
    registerIndividualUser,
    individualUserRegistrationInProgress,
    companyUserRegistrationInProgress,
    registerCompanyUser,
  } = useUserRegistrationHelper();
  const isIndividualClient = localStorage.getItem(InitiateUserRegistration);

  useEffect(() => {
    if (!firebaseUserIDToken) {
      return;
    }

    if (!firebaseUser) {
      return;
    }

    if (!firebaseUser.emailVerified) {
      return;
    }

    if (userRegistrationStarted.current) {
      return;
    }

    userRegistrationStarted.current = true;

    if (isIndividualClient) {
      registerIndividualUser(firebaseUserIDToken).finally(
        () => (userRegistrationStarted.current = false),
      );
    } else {
      registerCompanyUser(firebaseUserIDToken, companyName).finally(
        () => (userRegistrationStarted.current = false),
      );
    }
  }, [firebaseUserIDToken, firebaseUser]);

  if (
    individualUserRegistrationInProgress ||
    companyUserRegistrationInProgress
  ) {
    return <UserRegisteringLoader />;
  }

  return (
    <>
      {isWidthUp("sm", width) ? (
        <Box
          sx={{
            display: "grid",
            backgroundImage: `url(${background})`,
            gridTemplateRows: "auto 1fr",
            backgroundSize: "cover",
            height: "100vh",
            overflowY: isWidthUp("md", width) ? "hidden" : "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            {isIndividualClient ? (
              <IndividualClientRegisterCard />
            ) : (
              <CompanyClientRegisterCard setCompanyName={setCompanyName} />
            )}
          </Box>
        </Box>
      ) : isIndividualClient ? (
        <IndividualClientRegisterCard />
      ) : (
        <CompanyClientRegisterCard setCompanyName={setCompanyName} />
      )}
    </>
  );
};
