import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import meshMiniLogo2 from "assets/images/logo/meshLogoNoWords.svg";
import { useWidth } from "utilities/general";
import { LoadingEllipses } from "components/Animations/LoadingEllipses";
import background from "assets/images/background/background.png";

export function LoadingScreen() {
  const width = useWidth();
  return (
    <>
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: isWidthUp("sm", width) ? `url(${background})` : "",
          backgroundSize: "cover",
          height: "100vh",
          width: "100%",
          gap: theme.spacing(3),
        })}
      >
        <Box
          component="img"
          style={{
            width: 100,
          }}
          src={meshMiniLogo2}
        />
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            paddingTop: theme.spacing(2),
          })}
        >
          <Typography fontSize="16px">Submitting signup request</Typography>
          <LoadingEllipses />
        </Box>
      </Box>
    </>
  );
}
