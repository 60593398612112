import React from "react";
import { Chip, ChipProps, Theme } from "@mui/material";
import { TransactionState } from "james/ledger";

export interface TransactionStateChipProps {
  state: TransactionState | "";
  chipProps?: ChipProps;
}

export function TransactionStateChip(props: TransactionStateChipProps) {
  switch (props.state) {
    case TransactionState.Draft:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case TransactionState.SigningInProgress:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case TransactionState.Pending:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case TransactionState.SubmissionInProgress:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case TransactionState.Failed:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.warning.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case TransactionState.Indeterminate:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    case TransactionState.Successful:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.success.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={props.state}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}
