import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { AssetType } from "james/views/marketListingView/Model";
import { SectionRenderers } from "./HowDoesItWork";

export interface ContentType {
  title: string;
  info?: string[];
  imgURL?: string;
  imageLabel?: string;
  index?: number;
  link?: string;
}

export interface HowDoesItWorkSection {
  sectionName: SectionRenderers;
  title: string;
  fields: string[];
  content: Record<string, ContentType[]>;
}

export const HowDoesItWorkContentProvider = {
  fetchContent(
    marketListingViewModel:
      | MarketListingViewModel
      | PublicMarketListingViewModel,
  ): HowDoesItWorkSection[] {
    return [
      getDescriptionContent(marketListingViewModel),
      getStructureContent(marketListingViewModel),
      getRiskContent(marketListingViewModel),
      getBlockChainContent(marketListingViewModel),
    ];
  },
};

const getDescriptionContent = (
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel,
): HowDoesItWorkSection => {
  switch (marketListingViewModel.assetType) {
    case AssetType.CryptoCurrency:
      if (marketListingViewModel.token.code === "XLM") {
        return {
          sectionName: SectionRenderers.DescriptionSection,
          title: "What am I buying?",
          fields: ["what", "who", "definition"],
          content: {
            what: [
              {
                title: "What is XLM",
                imageLabel: "XLM",
                info: [
                  `XLM is the native currency on the Stellar network. 
                To operate on the Stellar blockchain, a user would require 
                a minimum balance in XLM. It also serves as a cryptocurrency 
                for buying and selling, the price fluctuates with supply and 
                demand across various crypto exchanges.`,
                ],
              },
            ],
            who: [
              {
                title: "Who is this for",
                info: [
                  "XLM is a popular crypto currency to hold in a diversified portfolio.",
                ],
              },
            ],
          },
        };
      } else if (marketListingViewModel.token.code === "AQUA") {
        return {
          sectionName: SectionRenderers.DescriptionSection,
          title: "What am I buying",
          fields: ["what", "who", "definition"],
          content: {
            what: [
              {
                title: "What is Aqua",
                imageLabel: "Aqua",
                info: [
                  `AQUA is the currency for rewards and on-chain voting in Aquarius. 
              Aquarius allows the community to set rewards for selected markets 
              through on-chain voting.`,
                ],
              },
            ],
            who: [
              {
                title: "Who is this for",
                info: [
                  "Users that want to earn rewards for providing liquidity to liquidity pools.",
                ],
              },
            ],
          },
        };
      }
      break;
    case AssetType.YieldBearingStablecoin:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition"],
        content: {
          what: [
            {
              title: `What is ${marketListingViewModel.token.code}`,
              imageLabel: `${marketListingViewModel.token.code}`,
              info: [`A tokenized proof of ownership of the underlying.`],
            },
          ],
          who: [
            {
              title: "who is this for",
              info: [
                "Users looking for the benefit of daily interest payments, combined with ownership of a crypto stablecoin.",
              ],
            },
          ],
          definition: [
            {
              title: "Definition:",
            },
            {
              title: "Yieldcoin",
              info: [
                `Yield coins, or Savings coins are coins/tokens where you earn daily interest for 
              holding them in your account. Your balance of yield coins will therefore increase 
              every day. The interest is an annual percentage yield, compounded daily.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToACryptoCurrency:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition"],
        content: {
          what: [
            {
              title: "What is a Stablecoin?",
              imageLabel: `${marketListingViewModel.token.code}`,
              info: [
                "A digital asset on the blockchain (token), signifying proof of ownership of the underlying.",
              ],
            },
          ],
          who: [
            {
              title: "Who is this for?",
              info: [
                "Users who want to own the native currency of another blockchain",
              ],
            },
          ],
          definition: [
            {
              title: "Definition:",
            },
            {
              title: "Crypto Stablecoin",
              info: [
                `There are different ledgers/blockchains available today, each called a network. 
              While networks do not speak to one another, it is possible to buy, for example, 
              Bitcoin on the Stellar network by means of Stablecoin. An issuer will hold the 
              native currency in their custody, and issue equivalent amounts of stablecoin on a 
              different network/blockchain. It is important to know who issued the stablecoin, 
              as we trust them to sell you the rights to a token in their custody, 
              available for redemption at any time.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToAFiatCurrency:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition"],
        content: {
          what: [
            {
              title: "What is a Stablecoin?",
              imageLabel: "Fiat",
              info: [
                "A digital asset on the blockchain (token), signifying proof of ownership of the underlying.",
              ],
            },
          ],
          who: [
            {
              title: "Who is this for?",
              info: [
                "Users who want to buy the rights to Fiat currency, with ownership record stored in the blockchain",
              ],
            },
          ],
          definition: [
            {
              title: "Definition:",
            },
            {
              title: "Fiat Stablecoin",
              info: [
                `Stablecoins (coins of stable value) were created to solve the cryptocurrency 
              volatility problem. These coins are designed and managed to trade 1:1 with their 
              Fiat counterparts, on which their value is based. The value of Fiat will remain 
              stable in most cases for the foreseeable future.`,
              ],
            },
          ],
        },
      };
    case AssetType.ETN:
    case AssetType.ETF:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition"],
        content: {
          what: [
            {
              title: `What is an ${marketListingViewModel.assetType}`,
              imageLabel: `${marketListingViewModel.assetType}`,
              info: [
                `A ${
                  marketListingViewModel.assetType === AssetType.ETN
                    ? "Note"
                    : "Fund"
                } 
                that is the vessel for funding a 
                reference portfolio or basket of global assets.`,
              ],
            },
          ],
          who: [
            {
              title: "who is this for",
              info: [
                `Individuals/Companies who wish to reduce their risk exposure and earn stable returns.`,
                `Anyone who wants exposure to a wide range of instruments/securities, 
              even on other exchanges, without needing to individually buy and manage the constituents.`,
              ],
            },
          ],
          definition: [
            {
              title: "Definition:",
            },
            {
              title: "New Instrument",
              info: [
                `A digital asset on the blockchain (token), 
            signifying proof of ownership of the underlying. 
            The underlying is a reference portfolio of assets 
            across one or various exchanges, managed by an asset manager. 
            The basket is tracked and the returns of the constituents affect 
            the price of this instrument.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToAnETF:
    case AssetType.RightsToAnETN:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition"],
        content: {
          what: [
            {
              title: `What is an ${
                marketListingViewModel.assetType.split(" ")[3]
              }`,
              imageLabel: `${marketListingViewModel.assetType.split(" ")[3]}`,
              info: [
                `A listed 
                  ${
                    marketListingViewModel.assetType === AssetType.RightsToAnETN
                      ? "Note"
                      : "Fund"
                  } 
                  that is the tradable vessel for funding a reference portfolio or basket of global assets.`,
              ],
            },
          ],
          who: [
            {
              title: "Who is this for?",
              info: [
                `Individuals/Companies who wish to reduce their risk exposure and earn stable returns.`,
                `Anyone who wants exposure to a wide range of instruments/securities, 
              even on other exchanges, without needing to individually buy and manage the constituents.`,
              ],
            },
          ],
          definition: [
            {
              title: "Definition:",
            },
            {
              title: "Rights to",
              info: [
                `A digital asset on the blockchain (token), signifying proof of 
              ownership of the underlying. The underlying is a traded instrument, 
              that is linked to a reference portfolio/basket of assets across one 
              or various exchanges, managed by an asset manager. The basket is 
              tracked and the returns of the constituents affect the price of this instrument.`,
              ],
            },
          ],
        },
      };
    case AssetType.Bond:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition", "outro"],
        content: {
          what: [
            {
              title: `What is a bond`,
              imageLabel: `BOND`,
              info: [
                `A Bond is a debt security issued by a company or government. 
                It is like a loan from the Investor to the Issuer, 
                with a set term and regular coupon payments from the Issuer. 
                The regularity of the coupon payments is determined by the 
                Issuer. Unlike a loan, the principal investment is returned 
                to the Investor when the Bond matures.`,
              ],
            },
          ],
          who: [
            {
              title: "Who is this for?",
              info: [
                `Notes may not be a suitable investment option for all investors as 
                they are a risky investment. Each potential Investor in any 
                Note Programme must determine the suitability of the investment in 
                light of their own circumstances. In particular, each potential 
                Investor should:`,
              ],
            },
          ],
          definition: [
            {
              title: "Types of Bonds",
            },
            {
              title: "Fixed Rate Bond",
              info: [
                `Offers fixed coupon payments until maturity, at 
                which point the principal is returned to the investor.`,
              ],
            },
            {
              title: "Floating Rate Bond",
              info: [
                `Offers variable coupon payments that are tied to a reference 
                interest rate, with the principal returned at maturity.`,
              ],
            },
          ],
          investors: [
            {
              title: "1",
              info: [
                `Have sufficient knowledge and experience to make a 
                meaningful evaluation of the Notes, the merits and 
                risks of investing in the Notes and the information 
                contained in the Prospectus.`,
              ],
            },
            {
              title: "4",
              info: [
                `Have access to, and knowledge of, appropriate tools to 
                evaluate, in the context of his/her particular 
                financial situation, an investment in the Notes and t
                he impact such an investment will have on his/her 
                overall investment portfolio.`,
              ],
            },

            {
              title: "2",
              info: [
                `Have sufficient financial resources and liquidity to 
                bear all of the risks of an investment in the Notes.`,
              ],
            },

            {
              title: "5",
              info: [
                `Understand thoroughly the terms of the Notes and be 
                familiar with the behaviour of any relevant financial 
                markets.`,
              ],
            },
            {
              title: "3",
              info: [
                `Be able to evaluate (either alone or with help of a 
                  financial advisor) possible scenarios for economic, 
                  interest rate and other factors that may affect 
                  his/her investment and his/her ability to bear 
                  the applicable risks.`,
              ],
            },
          ],
          outro: [
            {
              title: "Outro",
              info: [
                `Bonds are recognised as a core component of any well-diversified 
              portfolio, offering regular interest payments and returning the 
              principal at maturity. They are accessible to individuals, 
              institutions, governments, or companies.`,
              ],
            },
          ],
        },
      };
    case AssetType.PreferenceShare:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition", "outro"],
        content: {
          what: [
            {
              title: `What is a Preference Share`,
              imageLabel: `PS`,
              info: [
                `Preference Shares are  a type of equity 
                that gives shareholders priority 
                when it comes to dividends and the distribution 
                of assets. If the company decides to pay out 
                dividends or liquidate, Preference Shareholders 
                receive their due before common shareholders.`,
              ],
            },
            {
              title: "",
              info: [
                `Preference Shares offer a fixed return based on a 
                benchmark interest rate, such as the prime lending rate. 
                As the benchmark interest rate changes, so does the 
                dividend payout. This fixed return contrasts with 
                ordinary shares, where dividends are not set in 
                advance and depend on the company’s profitability.`,
              ],
            },
            {
              title: "",
              info: [
                `Dividends on Preference Shares must be paid when 
                the company declares an ordinary dividend.`,
              ],
            },
            {
              title: "",
              info: [
                `Unlike ordinary shares, Preference Shareholders 
                typically are not able to vote at annual general 
                meetings, however, they do get to have their say 
                in the event that the company is being wound down
                and assets liquidated to settle obligations.`,
              ],
            },
            {
              title: "",
              info: [
                `The value of Preference Shares is generally influenced 
                by interest rates and the company's creditworthiness 
                rather than the company's share price performance.`,
              ],
            },
          ],
          who: [
            {
              title: "Who is this for?",
              info: [
                `Preference Shares are ideal for investors seeking a reliable dividend stream and a lower-risk investment compared to ordinary shares.`,
                `Each potential Investor in any Preference Share Programme must determine the suitability of the investment in light of their own circumstances. In particular, each potential Investor should:`,
              ],
            },
          ],
          definition: [
            {
              title: "Types of Preference Shares",
            },
            {
              title: "Cumulative",
              info: [
                `Any dividends missed by the company 
                will be made up in the future.`,
              ],
            },
            {
              title: "Redeemable/Callable",
              info: [
                `The issuing company can buy back these 
                shares at a future date for a predetermined 
                price, similar to some bonds.`,
              ],
            },
            {
              title: "Non-Cumulative",
              info: [
                `Missed dividends do not accumulate. 
                These shares usually offer higher yields 
                to compensate for the higher risk.`,
              ],
            },
            {
              title: "Perpetual",
              info: [
                `These do not have a redemption date, and investors must
                 sell them on the market to recoup their investment.`,
              ],
            },
          ],
          investors: [
            {
              title: "1",
              info: [
                `Have sufficient knowledge and experience to make a 
                meaningful evaluation of the Notes, the merits and 
                risks of investing in the Notes and the information 
                contained in the Prospectus;`,
              ],
            },
            {
              title: "4",
              info: [
                `Understand thoroughly the terms of the Preference Shares and be 
                familiar with the behaviour of any relevant financial markets;`,
              ],
            },

            {
              title: "2",
              info: [
                `Have access to, and knowledge of, appropriate tools 
                to evaluate, in the context of his/her particular 
                financial situation, an investment in the 
                Preference Shares and the impact such an investment
                will have on his/her overall investment portfolio;`,
              ],
            },

            {
              title: "5",
              info: [
                `And be able to evaluate (either alone or with the help 
                of a financial advisor) possible scenarios for 
                economic, interest rate and other factors that may 
                affect his/her investment and his/her ability to 
                bear the applicable risks.`,
              ],
            },
            {
              title: "3",
              info: [
                `Have sufficient financial resources and liquidity to bear all of 
                the risks of an investment in the Preference Shares;
`,
              ],
            },
          ],
          outro: [
            {
              title: "Outro",
              info: [
                `Investing in fixed income instruments is an excellent way to diversify a portfolio. 
                Preference Shares not only offer attractive yields and diversification benefits, 
                but they are also subject to a different tax regime (generally lower) 
                than traditional fixed-income instruments and provide more 
                certainty of cash flows than ordinary shares.`,
              ],
            },
          ],
          readMore: [
            {
              title: "Positive attributes of Preference Shares:",
              info: [
                `Preference Share dividends are taxed at a lower rate compared to interest income from bonds.`,
                `Preference Shares offer low correlation with other asset classes, making them a valuable tool for diversifying and spreading portfolio risk.`,
                `Recent regulatory changes have reduced the supply of Preference Shares, increasing demand and supporting prices.`,
                `The main returns from Preference Shares come from dividends, not capital gains.`,
              ],
            },
            {
              title: "Negative attributes of Preference Shares:",
              info: [
                `Dividends from floating rate Preference Shares adjust with interest rates, meaning that if interest rates decline, the dividend payments also decrease. For fixed rate Preference Shares, increasing interest rates do not result in higher dividend amounts.`,
                `The capital value of Preference Shares is mainly influenced by the company's creditworthiness. If the company's creditworthiness declines, it negatively impacts the value of the Preference Shares.`,
                `Preference Shares do not benefit from increases in the company's earnings or stock price. While they offer stable dividends similar to debt instruments, their capital value does not rise with the company’s financial performance`,
              ],
            },
          ],
          links: [
            {
              title: "1",
              link: "https://support.mesh.trade/support/solutions/articles/80001148609-what-is-a-preference-share-",
            },
            {
              title: "2",
              link: "https://support.mesh.trade/support/solutions/articles/80001145611-who-is-the-ideal-preference-share-investor",
            },
          ],
        },
      };
    case AssetType.RightsToAnAMC:
      return {
        sectionName: SectionRenderers.DescriptionSection,
        title: "What am I buying?",
        fields: ["what", "who", "definition", "outro"],
        content: {
          what: [
            {
              title: `What is an AMC`,
              imageLabel: `AMC`,
              info: [
                `An AMC (Actively Managed Certificate) is a structured financial 
                product that provides synthetic exposure to a reference portfolio 
                of assets. The portfolio is actively managed by a professional 
                portfolio manager who makes investment decisions to meet specific 
                investment objectives.`,
              ],
            },
          ],
          who: [
            {
              title: "Who is this for?",
              info: [
                `Individuals or companies looking for actively managed 
                investment strategies with exposure to a specific portfolio 
                or basket of assets.`,
                `This is ideal for those who want to participate in a 
                tailored investment strategy managed by professionals without 
                directly handling the investments themselves.`,
              ],
            },
          ],
          definition: [
            {
              title: "Definition",
            },
            {
              title: "“Rights to AMC”",
              info: [
                ` A digital asset on the blockchain (token) signifies proof of 
                entitlement to the economic performance of the underlying. 
                The underlying AMC is a traded instrument issued by a financial 
                institution, linked to an actively managed reference portfolio. 
                The value of this asset is directly influenced by the performance of 
                the reference portfolio`,
              ],
            },
          ],
        },
      };
  }

  return {
    sectionName: SectionRenderers.DescriptionSection,
    title: "What am I buying?",
    fields: [],
    content: {},
  };
};

const getStructureContent = (
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel,
): HowDoesItWorkSection => {
  switch (marketListingViewModel.assetType) {
    case AssetType.CryptoCurrency:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it packaged?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              title: "Cryptocurrency on the Stellar network.",
              imgURL:
                marketListingViewModel.token.code === "XLM"
                  ? "stellar"
                  : "stablecoin",
            },
          ],
          additionalInfo: [
            {
              title: "Where does the price come from",
              imgURL: "price",
              info: [
                `Supply and demand. The price shown on Mesh gives you the best 
                price available across various crypto exchanges. Automated market makers, 
                or Liquidity pools, are also used.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToACryptoCurrency:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it packaged?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              index: 1,
              title: "A Stablecoin representing crypto",
              imgURL: "stablecoin",
              info: [
                `A stablecoin is a digital representation of currency based in blockchain 
              technology, that references an underlying asset.`,
              ],
            },
            {
              index: 2,
              imgURL: "underlying",
              title: "Underlying Asset",
              info: [
                `The underlying can be any asset but is mostly fiat currency or cryptocurrency.
                The underlying asset will be in the custody of the issuer of the stablecoin.`,
              ],
            },
          ],
          additionalInfo: [
            {
              index: 1,
              title: "Where does the price come from",
              imgURL: "price",
              info: [
                `Supply and demand, with a strong correlation to the price of the underlying cryptocurrency.`,
                `The price shown on Mesh gives you the best price available 
                      across various crypto exchanges. Automated market makers, or 
                      Liquidity pools, are also used.`,
              ],
            },
            {
              index: 2,
              title: "Concept of rights to the underlying",
              imgURL: "document",
              info: [
                `You do not own the underlying, you own the right to the underlying. 
                  Your exposure to growth stays the same.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToAFiatCurrency:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it packaged?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              index: 1,
              title: "A Stablecoin representing Fiat",
              imgURL: "stablecoin",
              info: [
                `A stablecoin is a digital representation of currency based in blockchain technology, 
                that references an underlying asset.`,
              ],
            },
            {
              index: 2,
              title: "Underlying Asset",
              imgURL: "underlying",
              info: [
                `The underlying can be any asset but is mostly fiat 
                currency or cryptocurrency. The underlying asset will be in the custody of the issuer of the stablecoin.`,
              ],
            },
          ],
          additionalInfo: [
            {
              title: "Where does the price come from",
              imgURL: "price",
              info: [
                `For most fiat stablecoins, the value is pegged 1:1 to the underlying fiat currency.`,
                `The price shown on Mesh gives you the best price available 
                  across various crypto exchanges. Automated market makers, or Liquidity pools, are also used.`,
              ],
            },
            {
              title: "Concept of rights to the underlying",
              imgURL: "document",
              info: [
                `You do not own the underlying, you own the right to the underlying. 
                  Your exposure to growth stays the same.`,
              ],
            },
          ],
        },
      };
    case AssetType.YieldBearingStablecoin:
      return getYieldCoinContent(marketListingViewModel);
    case AssetType.ETN:
    case AssetType.ETF:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it packaged?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              title: "Reference portfolio or basket of global assets",
              imgURL: "stablecoin",
              index: 1,
              info: [
                `A diversified portfolio of global assets, carefully managed by asset managers.`,
              ],
            },
            {
              index: 2,
              imgURL: "basket",
              title: "A digital asset on Mesh",
              info: [
                `A blockchain based Mesh token that represents the right of fractional 
                  ownership of the underlying asset, the reference portfolio.`,
              ],
            },
          ],
          additionalInfo: [
            {
              index: 1,
              title: "Where does the price come from ",
              imgURL: "price",
              info: [
                `The market maker determines the price. This may differ from exchange prices, 
                  as the market maker, who has the underlying in custody, determines the price.`,
              ],
            },
            {
              index: 2,
              imgURL: "document",
              title: "Concept of rights to the underlying",
              info: [
                `Tokens are based on the economic development of the underlying asset they 
                  represent. The economic performance is made up of the market value and any 
                  dividends or fund distributions.`,
              ],
            },
            {
              index: 3,
              title: "Dividends",
              imgURL: "percentage",
              info: [
                `The dividends from your token are reinvested in the reference portfolio, 
                  essentially increasing its value`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToAnETF:
    case AssetType.RightsToAnETN:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it packaged?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              index: 1,
              imgURL: "basket",
              title: "Reference portfolio or basket of global assets",
              info: [
                `A diversified portfolio of global assets, carefully managed by asset managers`,
              ],
            },
            {
              index: 2,
              imgURL: "wallet",
              title: "A listed Note or Fund",
              info: [
                `This is the tradable vessel for funding the basket of global assets. 
                  These funds are applied to the Reference portfolio.`,
              ],
            },
            {
              index: 3,
              imgURL: "stabelcoin",
              title: "A digital asset on Mesh",
              info: [
                `A blockchain based Mesh token that represents the right of ownership 
                  of the underlying asset, namely the note or fund`,
              ],
            },
          ],
          additionalInfo: [
            {
              index: 1,
              imgURL: "price",
              title: "Where does the price come from ",
              info: [
                `The market maker determines the price. This may differ from exchange prices, 
                  as the market maker, who has the underlying in custody, determines the price.`,
              ],
            },
            {
              index: 2,
              imgURL: "document",
              title: "Concept of rights to the underlying",
              info: [
                `Tokens are based on the economic development of the underlying asset they 
                  represent. The economic performance is made up of the market value and any 
                  dividends or fund distributions.`,
              ],
            },
            {
              index: 3,
              imgURL: "percentage",
              title: "Dividends",
              info: [
                `The dividends from your token are reinvested in the reference portfolio, 
                  essentially increasing its value`,
              ],
            },
          ],
        },
      };
    case AssetType.Bond:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it packaged?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              index: 1,
              imgURL: "stabelcoin",
              title: "A Tokenised Asset on Mesh represents Ownership",
              info: [
                `Also called a Token, a Tokenised Asset is a Digital Asset that can be 
                traded and managed on the Blockchain, offering benefits such as 
                fractional ownership and increased liquidity. `,

                `Bond Tokens are made available to the public via the Mesh.trade 
                platform. The Bond is traded as a Token with full economic and 
                legal rights as determined by its Prospectus. `,
              ],
            },
          ],
          additionalInfo: [
            {
              index: 1,
              imgURL: "price",
              title: "Where does the price come from ",
              info: [
                `On the Mesh platform, this Bond's price in an investor's portfolio is 
                recorded at it’s nominal value plus any accrued interest. The general 
                pricing of a Bond on the open market involves discounting expected 
                cash flows to the present and is influenced by factors such as supply 
                and demand, term to maturity, and creditworthiness.`,
              ],
            },
            {
              index: 2,
              imgURL: "document",
              title: "Coupon Payments",
              info: [
                `A coupon payment is the periodic interest paid on a Bond, calculated 
                as a percentage of its nominal value, and scheduled at intervals 
                (monthly, quarterly, semi-annually, or annually) until the 
                bond matures, as decided by the Issuer.`,
              ],
            },
            {
              index: 3,
              imgURL: "meshX1",
              title: "What does Prime mean?",
              info: [
                `Prime is the interest rate banks charge their most creditworthy 
                customers. It often serves as a reference for setting coupon rates 
                on floating rate Bonds.`,
              ],
            },
          ],
        },
      };
    case AssetType.PreferenceShare:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it structured?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              index: 1,
              imgURL: "stabelcoin",
              title: "A Tokenised Asset on Mesh represents Ownership",
              info: [
                `Also called a Token, a Tokenised Asset is a Digital Asset that can 
                be traded and managed on the Blockchain, offering benefits 
                such as fractional ownership and increased liquidity.`,

                `Preference Share Tokens are made available to the 
                public via the Mesh.trade platform. The Preference Share 
                is traded as a Token with full economic and legal 
                rights as determined by its Prospectus.`,
              ],
            },
          ],
          additionalInfo: [
            {
              index: 1,
              imgURL: "price",
              title: "Where does the price come from?",
              info: [
                `On the Mesh platform, this Preference Share’s price in an 
                investor's portfolio is recorded at its 
                nominal value. The general pricing of a Preference Share on the open 
                market involves discounting expected cash flows to the present and 
                is influenced by factors such as supply and demand, 
                term to maturity, and creditworthiness.`,
              ],
            },
            {
              index: 2,
              imgURL: "document",
              title:
                "What are the indicative returns, and how are they calculated?",
              info: [
                `Indicative returns provide a current snapshot of the dividend rate for a 
                Preference Share, which can be set at a fixed rate or calculated using a 
                specific formula linked to a benchmark interest rate. These returns change 
                over time based on market conditions, offering investors insight into both 
                the present and potential future returns.

`,
                `The formula helps determine how your returns will change over time. For 
                instance, a rate of '90% of Prime' adjusts to 90% of the current prime rate. 
                Thus, if the prime rate stands at 10%, the applicable rate would then be 9%.`,
              ],
            },
            {
              index: 3,
              imgURL: "mzarCoin",
              title: "Dividend Payments",
              info: [
                `Dividends are regular payments made to Preference Shareholders,
                 calculated using the dividend rate and the Preference Share's
                  nominal value. For floating rate Preference Shares, the dividend 
                  amounts can vary as the dividend rate is tied to an underlying 
                  benchmark interest rate, such as the prime lending rate. Payments 
                  are made at set intervals (monthly, quarterly, semi-annually, 
                  or annually) and continue until the final redemption date is
                  reached or the shares are redeemed early by the issuer.`,
              ],
            },
            {
              index: 4,
              imgURL: "meshX1",
              title: "What does Prime mean?",
              info: [
                `Prime is the interest rate banks charge their most creditworthy customers.
                 It often serves as a reference for setting dividend rates 
                 on floating rate Preference Shares.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToAnAMC:
      return {
        sectionName: SectionRenderers.StructureSection,
        title: "How is it structured?",
        fields: ["packaged", "additionalInfo"],
        content: {
          packaged: [
            {
              index: 1,
              imgURL: "basket",
              title: "Reference portfolio of global assets",
              info: [
                `An actively managed portfolio comprising a diverse range of assets, overseen by a professional 
                portfolio manager to achieve specific investment goals.`,

                `The portfolio is not directly owned by investors but serves as a benchmark for calculating 
                returns and fees.`,
              ],
            },
            {
              index: 2,
              imgURL: "wallet",
              title: "A listed Note",
              info: [
                `This is the tradable instrument that provides synthetic exposure to the reference portfolio.`,

                `Investors buy the AMC to gain exposure to the performance of the reference portfolio as managed
                 by the portfolio manager, without direct ownership of the portfolio’s assets.`,
              ],
            },
            {
              index: 3,
              imgURL: "stablecoin",
              title: "A digital asset on Mesh",
              info: [
                `A blockchain-based Mesh token that represents a right to the economic performance of the underlying AMC. `,

                `This token is a digital proof of the holder’s entitlement to returns based on the 
                reference portfolio's performance, as determined by the financial institution issuing the AMC.`,
              ],
            },
          ],
          additionalInfo: [
            {
              index: 1,
              imgURL: "price",
              title: "Where does the price come from?",
              info: [
                `The price is determined by the market maker, who holds the underlying in 
                custody, and may slightly differ from exchange prices. On the exchange, 
                the price reflects the net asset value (NAV) of the reference portfolio.`,
              ],
            },
            {
              index: 2,
              imgURL: "document",
              title: "Concept of rights to the underlying",
              info: [
                `Tokens represent economic rights to the performance of the underlying AMC, 
                including changes in market value and any distributions made. These rights 
                are secured and tracked on the blockchain, providing transparency and proof 
                of entitlement.`,
              ],
            },
            {
              index: 3,
              imgURL: "percentage",
              title: "Distributions",
              info: [
                `The distributions from the underlying AMC are automatically reinvested into the
                 reference portfolio. This reinvestment increases the overall value of the 
                 portfolio, which in turn increases the value of the tokens over time, 
                 compounding the returns.`,
              ],
            },
          ],
        },
      };
  }

  return {
    sectionName: SectionRenderers.StructureSection,
    title: "How is it packaged?",
    fields: [],
    content: {},
  };
};

const getYieldCoinContent = (
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel,
): HowDoesItWorkSection => {
  const sectionName = SectionRenderers.StructureSection;
  const title = "How is it packaged?";
  const contentMap: Record<string, ContentType[]> = {};

  contentMap.packaged = [
    {
      index: 1,
      imgURL: "stablecoin",
      title: "Yield coin token available on Mesh",
    },
  ];
  switch (marketListingViewModel.token.code) {
    case "yXLM":
      contentMap.packaged = [
        ...contentMap.packaged,
        {
          index: 2,
          imgURL: "lumenStablecoin",
          title: "Based on the Stellar native currency XLM",
        },
      ];
      contentMap.description = [
        {
          title: "yXLM Structure",
          info: [
            `yXLM is anchored to the native XLM currency on Stellar. Holding yXLM 
            in your account will allow you to earn daily interest in yXLM, which can then 
            be traded 1:1 (or close to) with XLM.`,
          ],
        },
      ];
      break;
    case "yBTC":
      contentMap.packaged = [
        ...contentMap.packaged,
        {
          index: 2,
          imgURL: "lumenStablecoin",
          title: "Based on the BTC stablecoin on Stellar",
        },
        {
          index: 3,
          imgURL: "btcStablecoin",
          title: "Based on the BTC coin on the Bitcoin Blockchain",
        },
      ];
      contentMap.description = [
        {
          title: "yBTC Structure",
          info: [
            `yBTC is anchored to the BTC stablecoin token issued by Ultrastellar. 
            Holding yBTC in your account will allow you to earn daily interest in yBTC, 
            which can then be traded 1:1 (or close to) with BTC. BTC is a crypto stablecoin.`,
          ],
        },
      ];
      break;
    case "yUSDC":
      contentMap.packaged = [
        ...contentMap.packaged,
        {
          index: 2,
          imgURL: "lumenStablecoin",
          title: "Based on the USDC stablecoin on Stellar",
        },
        {
          index: 3,
          imgURL: "stablecoin",
          title: "Based on the Fiat currency USD",
        },
      ];
      contentMap.description = [
        {
          title: "yUSD Structure",
          info: [
            `yUSDC is anchored to the USDC token issued by Circle.io. Holding 
            yUSDC in your account will allow you to earn daily interest in yUSDC, which 
            can then be traded 1:1 (or close to) with USDC. USDC is a USD based stablecoin.`,
          ],
        },
      ];
      break;
    case "yETH":
      contentMap.packaged = [
        ...contentMap.packaged,
        {
          index: 2,
          imgURL: "lumenStablecoin",
          title: "Based on the ETH stablecoin on Stellar",
        },
        {
          index: 3,
          imgURL: "stablecoin",
          title: "Based on the ETH coin on the Ethereum Blockchain",
        },
      ];
      contentMap.description = [
        {
          title: "yETH Structure",
          info: [
            `yETH is anchored to the ETH stablecoin token issued by Ultrastellar. 
            Holding yETH in your account will allow you to earn daily interest in yETH, 
            which can then be traded 1:1 (or close to) with ETH. ETH is a crypto stablecoin.`,
          ],
        },
      ];
      break;
  }

  return {
    sectionName: sectionName,
    title: title,
    fields: ["packaged", "description"],
    content: {
      ...contentMap,
      additionalInfo: [
        {
          index: 3,
          title: "Where does the price come from?",
          imgURL: "price",
          info: [
            `Linked to the price of the underlying coin.`,
            `The price shown on Mesh gives you the best price available across various crypto exchanges. 
              Automated market makers, or Liquidity pools, are also used.`,
          ],
        },
        {
          index: 4,
          title: "Interest/where does the money come from?",
          imgURL: "document",
          info: [
            `So where does the money come from, for the issuer to be able to pay interest to 
              you every day? The funds generated from the sale of the yield coin tokens are reinvested 
              by asset managers, in both traditional finance as well as decentralized finance projects. 
              The proceeds from those investments makes it possible to pay the holders of yield coins interest.`,
          ],
        },
      ],
    },
  };
};

const getRiskContent = (
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel,
): HowDoesItWorkSection => {
  switch (marketListingViewModel.assetType) {
    case AssetType.YieldBearingStablecoin:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "body"],
        content: {
          rating: [
            {
              title: "Every YieldCoin is",
              info: ["Unique"],
            },
          ],
          body: [
            {
              title: "",
              info: [
                `Every yield coin is unique in its risks and should be considered 
                before making an informed decision. There is always the risk that 
                the issuer of a token defaults resulting in the loss of your investment. 
                This is extreme, and care should be taken to only trust issuers with 
                a proven track record and sufficient liquidity. Other risks include 
                bugs in software and hacking attacks. While blockchain is not immune 
                to these challenges, well distributed public blockchains are extremely 
                robust and they can be considered secure given their track record.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToACryptoCurrency:
    case AssetType.RightsToAFiatCurrency:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "body"],
        content: {
          rating: [
            {
              title: "Crypto currencies can be",
              info: ["Volatile"],
            },
          ],
          body: [
            {
              title: "",
              info: [
                `Crypto currencies can be volatile. The price of the underlying 
                may change, and you could lose significant value. Other risks include 
                bugs in software and hacking attacks. While blockchain is not immune 
                to these challenges, well distributed public blockchains are extremely 
                robust and they can be considered secure given their track record.`,
              ],
            },
          ],
        },
      };
    case AssetType.CryptoCurrency:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "body"],
        content: {
          rating: [
            {
              title: "Cryptocurrencies can be",
              info: ["Volatile"],
            },
          ],
          body: [
            {
              title: "",
              info: [
                `${marketListingViewModel.token.code} will always have 
                utility in the Stellar network, but the price of ${marketListingViewModel.token.code} may 
                change, and you could lose significant value. Other risks include bugs 
                in software and hacking attacks. While blockchain is not immune 
                to these challenges, well distributed public blockchains are extremely 
                robust and they can be considered secure given their track record.`,
              ],
            },
          ],
        },
      };
    case AssetType.ETN:
    case AssetType.ETF:
    case AssetType.RightsToAnETF:
    case AssetType.RightsToAnETN:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "body"],
        content: {
          rating: [
            {
              title: "This asset is rated",
              info: [`${marketListingViewModel.instrumentRiskProfile} Risk`],
            },
          ],
          body: [
            {
              title: "",
              info: [marketListingViewModel.instrumentRiskProfileDescription],
            },
          ],
        },
      };
    case AssetType.Bond:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "link", "body", "outro"],
        content: {
          link: [
            {
              title: "link",
              info: [
                `https://support.mesh.trade/support/solutions/articles/80001133098-how-to-understand-the-risk-associated-with-public-offerings`,
              ],
            },
          ],
          body: [
            {
              title: "Public Offering / Prospectus Risk",
              info: [
                `It is important to be aware that the Notes on offer are 
                unlisted and are not readily marketable, and should be 
                considered a risk-capital investment.`,
              ],
            },
          ],
          info: [
            {
              title: "Credit Risk",
              info: [
                "This is the risk that the bond issuer may default on its " +
                  "debt obligations and fail to make interest payments or repay the" +
                  " principal amount. Bonds issued by companies or governments with " +
                  "a low credit rating are considered riskier than those with a " +
                  "higher rating.",
              ],
            },
            {
              title: "Interest Rate Risk",
              info: [
                "This is the risk that changes in interest rates may cause " +
                  "the value of the bond to fluctuate. When interest rates rise, the " +
                  "value of existing bonds decreases, and when interest rates fall, " +
                  "the value of existing bonds increases.",
              ],
            },
            {
              title: "Inflation Risk",
              info: [
                "This is the risk that inflation may erode the purchasing " +
                  "power of the bond's future cash flows. If inflation rises, the " +
                  "bond's future interest and principal payments may be worth " +
                  "less in real terms.",
              ],
            },
            {
              title: "Liquidity Risk",
              info: [
                "This is the risk that inflation may erode the purchasing " +
                  "power of the bond's future cash flows. If inflation rises, the " +
                  "bond's future interest and principal payments may be worth less i" +
                  "n real terms.",
              ],
            },
            {
              title: "Call Risk",
              info: [
                "This is the risk that the bond may be called, or redeemed, " +
                  "by the issuer before maturity. This can be detrimental to the " +
                  "bondholder if the bond is called when interest rates are low " +
                  "because the bondholder may have to reinvest the proceeds at a " +
                  "lower interest rate.",
              ],
            },
          ],
          outro: [
            {
              title: "outro",
              info: [
                `It is worth noting that Bonds are considered a relatively stable 
                asset since they pay regular interest and return the principal 
                upon maturity.`,
              ],
            },
          ],
        },
      };
    case AssetType.PreferenceShare:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "link", "body", "outro"],
        content: {
          link: [
            {
              title: "link",
              info: [
                `https://support.mesh.trade/support/solutions/articles/80001133098-how-to-understand-the-risk-associated-with-public-offerings`,
              ],
            },
          ],
          body: [
            {
              title: "Public Offering / Prospectus Risk",
              info: [
                `It is important to be aware that the Preference Shares on offer are 
                unlisted and are not readily marketable, and should be 
                considered a risk-capital investment.`,
              ],
            },
          ],
          info: [
            {
              title: "Credit Risk",
              info: [
                `The issuer’s credit rating will impact the Preference 
                 Share’s credit risk. If the issuer’s creditworthiness deteriorates,
                 there is an increased possibility that they may delay dividend payments 
                 or, in the worst case, default on their obligations.`,
              ],
            },
            {
              title: "Interest Rate Risk",
              info: [
                `While preference shares may offer some protection against interest rate 
                fluctuations, a decline in the dividend rate will lead to decreased income 
                for the investor.`,
              ],
            },
            {
              title: "Inflation Risk",
              info: [
                `While preference shares may offer some protection against interest rate 
                fluctuations, a decline in the dividend rate will lead to decreased income 
                for the investor.`,
              ],
            },
            {
              title: "Liquidity Risk",
              info: [
                `Preference Shares have relatively low liquidity, making them harder to 
                sell on secondary markets, particularly during times of market stress or 
                if the issuer is experiencing financial difficulties.`,
              ],
            },
            {
              title: "Call Risk",
              info: [
                `Some Preference Shares may have call provisions that allow the issuer to 
                redeem the Preference Shares before maturity. For example, if interest rates 
                decline significantly, the issuer may exercise the call option and repay the 
                Preference Share early, leaving the investor to reinvest the proceeds 
                elsewhere at a lower interest rate.`,
              ],
            },
          ],
          outro: [
            {
              title: "outro",
              info: [
                `It is worth noting that Preference Shares are considered 
                a relatively stable asset since they pay regular dividends.`,
              ],
            },
          ],
        },
      };
    case AssetType.RightsToAnAMC:
      return {
        sectionName: SectionRenderers.RiskSection,
        title: "How to understand risk",
        fields: ["rating", "body"],
        content: {
          rating: [
            {
              title: "This asset is rated",
              info: [`${marketListingViewModel.instrumentRiskProfile} Risk`],
            },
          ],
          body: [
            {
              title: "",
              info: [marketListingViewModel.instrumentRiskProfileDescription],
            },
          ],
        },
      };
  }

  // FIXME: what is this doing here?
  return {
    sectionName: SectionRenderers.RiskSection,
    title: "How to understand risk",
    fields: ["rating", "body"],
    content: {},
  };
};

const getBlockChainContent = (
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel,
): HowDoesItWorkSection => {
  const contentMap: Record<string, ContentType[]> = {
    benefits: [
      {
        title: "Benefits of Blockchain",
        info: [
          `The Blockchain provides ease of access. Many 
          traditional assets across asset classes feature 
          significant barriers to entry:`,
        ],
      },
    ],
    benefitList: [
      {
        title: "",
        info: [
          `Investors usually act through brokers, forex traders or other 
          middlemen. Each of these parties take a margin for the services 
          they provide. The Blockchain automates activities that these 
          parties usually perform manually, simplifying the process and 
          removing this extra cost.`,
        ],
      },
      {
        title: "",
        info: [
          `Many investments have a minimum investment amount stipulated, 
          which inadvertently locks out users that do not wish to invest 
          or do not have the required capital. The Blockchain allows for 
          fractional ownership, meaning you can consistently invest only 
          the amounts that you feel comfortable with.`,
        ],
      },
      {
        title: "",
        info: [
          `Monitoring and viewing your entire portfolio of traditional 
          finance instruments alongside digital assets in one view is 
          often not feasible. The Blockchain can give you full visibility 
          of your entire investment portfolio.`,
        ],
      },
    ],
    account: [
      {
        title: "Your blockchain account…",
        info: [
          `…acts as a record of your list of balances in the Blockchain 
          database / network. Your ownership is represented on a permanent 
          ledger, accessible even off the platform (i.e. directly on the 
            network), and can be viewed in an anonymous form by anyone.`,
        ],
      },
    ],
    description: [
      {
        title: "The Blockchain",
        info: [
          `Blockchains are massive record keeping systems that can be accessed
          by anyone. Information on the network is anonymous, meaning no 
          personal information about an individual is attached to accounts.`,
        ],
      },
    ],
  };

  switch (marketListingViewModel.assetType) {
    case AssetType.YieldBearingStablecoin:
      contentMap.benefitList = [
        ...contentMap.benefitList,
        {
          title: "",
          info: [
            `An additional benefit for yield coins is that you earn interest 
          for simply having them in your account.`,
          ],
        },
      ];
      break;
  }

  return {
    sectionName: SectionRenderers.BlockchainSection,
    title: "What is the blockchain?",
    fields: ["benefits", "description", "account", "benefitList"],
    content: contentMap,
  };
};
