import { Entry } from "james/audit/Entry";
import dayjs from "dayjs";
import { Operation, NewOperation } from "./Operation";
import { AfterEffect } from "./AfterEffect";
import { WriteOperation, WriteOperationState } from "./WriteOperation";

export enum TransactionState {
  Draft = "Draft",
  Discarded = "Discarded",
  Submitted = "Submitted",
  Rejected = "Rejected",
  Accepted = "Accepted",
  Complete = "Complete",
  RolledBack = "Rolled Back",
}

export enum TransactionAction {
  AddOperation,
  AddAfterEffect,
  Discard,
  Submit,
  Accept,
  Reject,
  RunForward,
  RunBackward,
  MarkTransactionComplete,
  MarkTransactionRolledBack,
}

export const AllTransactionStates: TransactionState[] = [
  TransactionState.Draft,
  TransactionState.Discarded,
  TransactionState.Submitted,
  TransactionState.Rejected,
  TransactionState.Accepted,
  TransactionState.Complete,
  TransactionState.RolledBack,
];

export class Transaction {
  public id = "";

  public createdAt = "";

  public state: TransactionState | "" = "";

  public lastActionAnnotation = "";

  public metaData: { [key: string]: string } = {};

  public originatingLocation = "";

  public originatingProcess = "";

  public operations: Operation[] = [];

  public afterEffects: AfterEffect[] = [];

  public auditEntry: Entry = new Entry();

  constructor(transaction?: Transaction) {
    if (!transaction) {
      return;
    }
    this.id = transaction.id;
    this.createdAt = transaction.createdAt;
    this.state = transaction.state;
    this.lastActionAnnotation = transaction.lastActionAnnotation;
    this.metaData = { ...transaction.metaData };
    this.originatingLocation = transaction.originatingLocation;
    this.originatingProcess = transaction.originatingProcess;
    this.operations = transaction.operations.map((o) => NewOperation(o));
    this.afterEffects = transaction.afterEffects.map(
      (ae) => new AfterEffect(ae),
    );
    this.auditEntry = new Entry(transaction.auditEntry);
  }

  // writeOperationsState returns an aggregate WriteOperationState based on the
  // state of WriteOperation(s) contained within the transaction as well as the
  // time at which this state was last changed.
  writeOperationsState(): [WriteOperationState, dayjs.Dayjs] {
    // Prepare variable to keep track of last modified write operation that
    // contains the 'aggregate state'.
    // Initialised to 'Complete' since transactions that have no write operations
    // (i.e. only read operations) have no changes to make during the commit phase
    // to any persistence layers.
    // i.e. Transactions with ONLY read operations are complete on submission.
    // NOTE: THESE TRANSACTIONS CANNOT BE EXCLUDED since a decision to NOT
    // write may have relied on these reads!!!
    let lastModifiedWriteOp = new WriteOperation({
      ...new WriteOperation(),
      state: WriteOperationState.Complete,
      auditEntry: new Entry({
        ...new Entry(),
        time: this.auditEntry.time,
      }),
    } as WriteOperation);

    // Prepare variable to keep track of the number of write operations in the transaction
    let writeOperationCount = 0;

    // Prepare variable to hold an index of the no. of write operations in each state
    const writeOperationStateCountIdx: { [key: string]: number } = {
      [WriteOperationState.Pending]: 0,
      [WriteOperationState.RunningForward]: 0,
      [WriteOperationState.RunningBackward]: 0,
      [WriteOperationState.Complete]: 0,
      [WriteOperationState.Failed]: 0,
      [WriteOperationState.RolledBack]: 0,
    };

    // get most recently modified operation & count the no. of write operations
    this.operations.forEach((o) => {
      // do nothing more for non-write operations
      if (!(o instanceof WriteOperation)) {
        return;
      }

      // increment write operation count
      writeOperationCount++;

      // increment relevant index
      writeOperationStateCountIdx[o.state]++;

      // check and update if this is the latest operation
      if (
        dayjs(lastModifiedWriteOp.auditEntry.time).isSame(o.auditEntry.time) ||
        dayjs(lastModifiedWriteOp.auditEntry.time).isBefore(o.auditEntry.time)
      ) {
        lastModifiedWriteOp = new WriteOperation(o);
      }
    });

    // Using gathered information determine 'aggregate' write operations state
    switch (true) {
      // Transactions with no write operations are considered complete
      // at whatever time they were last modified.
      case writeOperationCount === 0:
        return [WriteOperationState.Complete, dayjs(this.auditEntry.time)];

      //
      // Static States
      //
      // All write operations are pending
      case writeOperationStateCountIdx[WriteOperationState.Pending] ===
        writeOperationCount:
        return [
          WriteOperationState.Pending,
          dayjs(lastModifiedWriteOp.auditEntry.time),
        ];
      // Any write operation is failed
      case writeOperationStateCountIdx[WriteOperationState.Failed] > 0:
        return [
          WriteOperationState.Failed,
          dayjs(lastModifiedWriteOp.auditEntry.time),
        ];
      // All write operations are complete
      case writeOperationStateCountIdx[WriteOperationState.Complete] ===
        writeOperationCount:
        return [
          WriteOperationState.Complete,
          dayjs(lastModifiedWriteOp.auditEntry.time),
        ];
      // All write operations are either rolled back or pending
      case writeOperationStateCountIdx[WriteOperationState.RolledBack] +
        writeOperationStateCountIdx[WriteOperationState.Pending] ===
        writeOperationCount:
        return [
          WriteOperationState.RolledBack,
          dayjs(lastModifiedWriteOp.auditEntry.time),
        ];

      //
      // Running States
      //
      // (Last modified write operation is RunningForward) OR
      // (Last modified write operation is Complete but not all of them are)
      case lastModifiedWriteOp.state === WriteOperationState.RunningForward ||
        (lastModifiedWriteOp.state === WriteOperationState.Complete &&
          writeOperationStateCountIdx[WriteOperationState.Complete] !==
            writeOperationCount):
        return [
          WriteOperationState.RunningForward,
          dayjs(lastModifiedWriteOp.auditEntry.time),
        ];

      // (Last modified write operation is RunningBackward) OR
      // (Last modified write is Rolled Back but not all of them are)
      case lastModifiedWriteOp.state === WriteOperationState.RunningBackward ||
        (lastModifiedWriteOp.state === WriteOperationState.RolledBack &&
          writeOperationStateCountIdx[WriteOperationState.RolledBack] !==
            writeOperationCount):
        return [
          WriteOperationState.RunningBackward,
          dayjs(lastModifiedWriteOp.auditEntry.time),
        ];

      // Unexpected combinations indicate a bug that cannot be tolerated.
      // i.e. automated resolution in a situation where this case is running
      // is likely not possible.
      default:
        throw new TypeError(
          `invalid transaction operation state combination: '${this}': InconsistentTransactionState`,
        );
    }
  }
}
