import dayjs from "dayjs";
import { DataSheetSection, NewDataSheetSection } from "./DataSheetSection";

export class AssetDataSheet {
  public sections: DataSheetSection[] = [];

  public generatedAt: string = dayjs().format();

  constructor(assetDataSheet?: AssetDataSheet) {
    if (!assetDataSheet) {
      return;
    }

    this.sections = assetDataSheet.sections.map((v) => NewDataSheetSection(v));
    this.generatedAt = assetDataSheet.generatedAt;
  }
}
