import React from "react";
import { Box, Chip, useTheme } from "@mui/material";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { smartInstrumentStateToString } from "@mesh/common-js/dist/financial";

export type SmartInstrumentStateChipProps = {
  state: SmartInstrumentState;
};

export function SmartInstrumentStateChip(props: SmartInstrumentStateChipProps) {
  const theme = useTheme();
  const chipStyles = {
    chip: {
      fontWeight: 400,
    },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    success: { backgroundColor: theme.palette.success.main },
    secondary: { backgroundColor: theme.palette.info.main },
  };

  switch (props.state) {
    case SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          id="SmartInstrumentStateChip-draft-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.PREISSUING_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          id="SmartInstrumentStateChip-preIssuing-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.PREISSUANCE_FAILED_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          id="SmartInstrumentStateChip-preIssuanceFailed-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.PREISSUED_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          id="SmartInstrumentStateChip-preIssued-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.ISSUING_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          id="SmartInstrumentStateChip-issuing-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.ISSUANCE_FAILED_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          id="SmartInstrumentStateChip-issuanceFailed-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.ISSUED_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          id="SmartInstrumentStateChip-issued-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.MATURED_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          id="SmartInstrumentStateChip-matured-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    case SmartInstrumentState.CANCELLED_SMART_INSTRUMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          id="SmartInstrumentStateChip-cancelled-chip"
          size="small"
          label={smartInstrumentStateToString(props.state)}
        />
      );

    default:
      return <Box>{props.state}</Box>;
  }
}
