import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { hasError } from "../UserProfileDialog";

interface LeaveDialogProps {
  leaveFunc: () => void;
  closeDialog: () => void;
  updateFunc: () => void;
  errors: Record<string, string | undefined>;
}

export const LeaveDialog = ({
  leaveFunc,
  closeDialog,
  updateFunc,
  errors,
  ...props
}: LeaveDialogProps & DialogProps) => {
  const theme = useTheme();

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: {
          width: { sm: "640px" },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            borderRadius: "10px 10px 0 0",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>Did you mean to leave?</Typography>
        <IconButton id="LeaveDialogue-Close-IconButton" onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={(theme) => ({ p: theme.spacing(3) })}>
        <Box>
          <Typography
            sx={(theme) => ({
              mt: 3,
              mb: 1,
              color: theme.palette.text.secondary,
            })}
          >
            You are about to leave this page without saving your changes. Are
            you sure you want to proceed?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "flex-end" },
              width: "100%",
              pt: theme.spacing(2),
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              pb: {
                sm: 0,
                xs: theme.spacing(2),
              },
            }}
          >
            <Button
              id="LeaveDialogue-ContinueWithoutSaving-Button"
              variant="outlined"
              sx={{
                mx: { sm: theme.spacing(2) },
                mb: {
                  xs: theme.spacing(3),
                  sm: 0,
                },
                height: {
                  sm: "36px",
                  xs: "48px",
                },
              }}
              onClick={leaveFunc}
            >
              continue without saving
            </Button>
            <Button
              id="LeaveDialogue-SaveChanges-Button"
              variant="contained"
              disabled={hasError(errors)}
              sx={{
                backgroundColor: theme.palette.primary.main,
                height: {
                  sm: "36px",
                  xs: "48px",
                },
              }}
              onClick={async () => {
                await updateFunc();
                closeDialog();
                leaveFunc();
              }}
            >
              SAVE CHANGES
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
