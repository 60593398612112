import React from "react";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { Router } from "routes";
import { Table } from "./components/Table/Table";

const complianceTabRoutes: RouteType[] = [
  {
    name: "Client List",
    id: "compliance-table",
    path: "/market-management/compliance",
    component: <Table />,
  },
];

export function Compliance() {
  return (
    <Box>
      <NavigationSection />
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 2, 0, 2),
          height: "calc(100vh - 100px)",
        }}
        className={"meshScroll"}
      >
        <Router
          baseURL={"/market-management/compliance"}
          routes={complianceTabRoutes}
        />
      </Box>
    </Box>
  );
}

const NavigationSection = () => {
  return (
    <Paper
      sx={{
        paddingLeft: (theme) => theme.spacing(4),
      }}
      square
    >
      <Grid container>
        <Grid item>
          <Tabs value={"Clients"} textColor={"inherit"}>
            <Tab key={0} value={"Clients"} label={"Clients"} />
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
};
