import React, { useState } from "react";
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EditableInfo } from "../util";
import { ContactDetails } from "./components/ContactDetails";
import { ProfilePhotoSection } from "./components/ProfilePhotoSection";
import { SkeletonLoader } from "components/SkeletonLoader";
import { Person } from "james/legal/person";
import dayjs from "dayjs";

interface ProfileCardProps {
  setEditing: React.Dispatch<React.SetStateAction<number>>;
  editValue: number;
  person: Person;
  setPerson: React.Dispatch<React.SetStateAction<Person | undefined>>;
  loadingMyPerson: boolean;
  updateFunc: (info: Partial<Person>) => void;
  errors: Record<string, string | undefined>;
  setErrors: (error: Record<string, string | undefined>) => void;
}

export const ProfileCard = ({
  setEditing,
  editValue,
  person: myPerson,
  setPerson,
  loadingMyPerson,
  updateFunc,
  errors,
  setErrors,
}: ProfileCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [fileTooLarge, setFileTooLarge] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(3, 5),
          borderRadius: "10px",
          my: theme.spacing(1),
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: "606px",
          margin: theme.spacing(1.5),
        },
        [theme.breakpoints.down("sm")]: {
          p: 3,
          width: "100%",
        },
      }}
    >
      <SkeletonLoader loading={loadingMyPerson}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            alignItems: "center",
          }}
        >
          <Typography variant="h1" children="Hello" sx={{ fontSize: "38px" }} />
          <Typography
            variant="h1"
            sx={{
              color: theme.palette.primary.main,
              fontSize: "38px",
              fontWeight: "bold",
              mt: {
                sm: 0,
                xs: 2,
              },
              ml: {
                xs: 0,
                sm: 2,
              },
            }}
          >
            {myPerson.firstName}
          </Typography>
        </Box>
      </SkeletonLoader>
      <SkeletonLoader loading={loadingMyPerson}>
        <Typography
          align={isMobile ? "center" : "left"}
          sx={{
            color: theme.palette.text.secondary,
            mt: theme.spacing(2),
            mb: 4,
          }}
          children={
            isMobile
              ? "Info about you and your preferences"
              : "Update and manage your user profile, preferences and contact information."
          }
        />
      </SkeletonLoader>

      {/* My Details section */}
      <Box>
        <SkeletonLoader loading={loadingMyPerson}>
          <Typography sx={{ fontWeight: "bold" }}>My Details</Typography>
        </SkeletonLoader>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              sm: "column",
              xs: "row-reverse",
            },
            [theme.breakpoints.down("sm")]: {
              justifyContent: "flex-end",
            },
          }}
        >
          <Box
            sx={{
              display: "grid",
              [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "2fr 3fr 2fr",
                gridColumnGap: theme.spacing(2),
              },
              [theme.breakpoints.down("sm")]: {
                gridTemplateRows: "repeat(3, 1fr)",
              },
              my: theme.spacing(1),
            }}
          >
            <EditableInfo
              id="ProfileCard-FistName"
              loading={loadingMyPerson}
              label="First Name"
              value={myPerson.firstName}
            />
            <EditableInfo
              id="ProfileCard-MiddleName"
              loading={loadingMyPerson}
              label="Middle Name"
              value={myPerson.middleNames}
            />
            <EditableInfo
              loading={loadingMyPerson}
              label="Last Name"
              value={myPerson.lastName}
            />
          </Box>
          <ProfilePhotoSection
            initialLoad={loadingMyPerson}
            setFileTooLarge={setFileTooLarge}
            fileTooLarge={fileTooLarge}
          />
        </Box>
        {isMobile && fileTooLarge && (
          <Typography
            sx={{
              color: theme.palette.error.light,
              mb: 3,
            }}
          >
            You can upload jpg, gif or png image files.
            <br /> Max size of 5mb.
          </Typography>
        )}
        {isMobile && <Divider sx={{ mb: 3, mt: 1 }} />}
      </Box>
      {/* About Me Section */}
      <Box>
        <SkeletonLoader loading={loadingMyPerson}>
          <Typography sx={{ fontWeight: "bold" }}>About Me</Typography>
        </SkeletonLoader>

        <Box
          sx={{
            display: "grid",
            my: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
              gridColumnGap: theme.spacing(2),
            },
          }}
        >
          <EditableInfo
            id="ProfileCard-DOB"
            loading={loadingMyPerson}
            label="Date of Birth"
            value={dayjs(myPerson.dateOfBirth).format("DD/MM/YYYY")}
          />
          <EditableInfo
            id="ProfileCard-Nationality"
            loading={loadingMyPerson}
            label="Nationality"
            value={myPerson.nationality}
          />
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />

      <ContactDetails
        initialPerson={myPerson}
        loadingMyPerson={loadingMyPerson}
        setGlobalEditing={setEditing}
        editValue={editValue}
        updateFunc={updateFunc}
        setPerson={setPerson}
        errors={errors}
        setErrors={setErrors}
      />
      <Divider sx={{ my: 3 }} />
      {/* Address Section */}
      <Box>
        <SkeletonLoader loading={loadingMyPerson}>
          <Typography sx={{ fontWeight: "bold" }}>Address</Typography>
        </SkeletonLoader>
        <Box
          sx={{
            display: "grid",
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
              gridColumnGap: theme.spacing(2),
            },
            my: theme.spacing(1),
          }}
        >
          <EditableInfo
            id="ProfileCard-StreetAdress1"
            loading={loadingMyPerson}
            label="Street Address 1"
            value={myPerson.residentialAddress.addressLine1}
          />
          <EditableInfo
            id="ProfileCard-StreetAdress2"
            loading={loadingMyPerson}
            label="Street Address 2"
            value={myPerson.residentialAddress.addressLine2}
          />
          <EditableInfo
            id="ProfileCard-Suburb"
            loading={loadingMyPerson}
            label="Suburb"
            value={myPerson.residentialAddress.suburb}
          />
          <EditableInfo
            id="ProfileCard-City"
            loading={loadingMyPerson}
            label="City"
            value={myPerson.residentialAddress.city}
          />
          <EditableInfo
            id="ProfileCard-Province"
            loading={loadingMyPerson}
            label="Province"
            value={myPerson.residentialAddress.province}
          />
          <EditableInfo
            id="ProfileCard-AreaCode"
            loading={loadingMyPerson}
            label="Area Code"
            value={myPerson.residentialAddress.postalCode}
          />
        </Box>
      </Box>
    </Box>
  );
};
