import { AuditEntry } from "james/audit";
import { LedgerAccountState } from "james/ledger";
import { StellarNetwork } from "./Network";

export const SourceAccountTypeName = "mesh::stellar/SourceAccount";

export class SourceAccount {
  public ["@type"]: string = SourceAccountTypeName;

  public id = "";
  public publicKey = "";
  public state: LedgerAccountState | "" = "";
  public network: StellarNetwork | "" = "";
  public sequenceNumber = 0;
  public auditEntry: AuditEntry = new AuditEntry();

  constructor(sourceAccount?: SourceAccount) {
    if (!sourceAccount) {
      return;
    }
    this.id = sourceAccount.id;
    this.publicKey = sourceAccount.publicKey;
    this.state = sourceAccount.state;
    this.network = sourceAccount.network;
    this.sequenceNumber = sourceAccount.sequenceNumber;
    this.auditEntry = new AuditEntry(sourceAccount.auditEntry);
  }
}
