import { useEffect, useState } from "react";
import {
  Model as AccountViewModel,
  Model as StellarAccountViewModel,
} from "james/views/stellarAccountView";
import { useAccountContext } from "context/Account/Account";
import {
  Determiner,
  ScopeFields,
} from "../../../james/search/scope/Determiner";
import { Permission } from "james/security";
import { SpotStateControllerServiceProviderName } from "james/market";
import { TextNINListCriterion } from "james/search/criterion";
import { LedgerAccountCategory } from "james/ledger";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import { SubscriptionOrderStateControllerServiceProviderName } from "james/market/SubscriptionOrderStateController";
import { useErrorContext } from "context/Error";

const MapServiceToPermission = (serviceName: string): string => {
  switch (serviceName) {
    case "SubmitSpot":
      return SpotStateControllerServiceProviderName;
    case "SubmitSubscriptionOrder":
      return SubscriptionOrderStateControllerServiceProviderName;
    default:
      return "";
  }
};

export const usePotentialSourceAccount = (
  serviceName: string,
  sourceAccountID?: string,
) => {
  const { errorContextErrorTranslator } = useErrorContext();
  const [potentialSourceAccounts, setPotentialSourceAccounts] = useState<
    StellarAccountViewModel[]
  >([]);
  const { authContext } = useApplicationContext();
  const { stellarAccountContext } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const [
    initialAccountLoadedSuccessfully,
    setInitialAccountLoadedSuccessfully,
  ] = useState(false);
  const [potentialSourceAccountsLoaded, setPotentialSourceAccountsLoaded] =
    useState(false);
  const stellarAccountContextError = stellarAccountContext.error;
  const stellarAccountContextLoading = stellarAccountContext.loading;
  const stellarAccountContextKeys = stellarAccountContext.keys;
  const serviceProvider = MapServiceToPermission(serviceName);

  useEffect(() => {
    if (!initialAccountLoadedSuccessfully) {
      return;
    }
    setPotentialSourceAccountsLoaded(false);
    // get potential source accounts and set selected account
    try {
      (async () => {
        // fetch potential source accounts
        const determineScopeCriteriaByRolesResponse =
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName,
              serviceProvider,
              description: "-",
            }),
            criteria: {
              category: TextNINListCriterion([
                LedgerAccountCategory.Issuance,
                LedgerAccountCategory.Clearance,
              ]),
            },
            scopeFields: [ScopeFields.OwnerIDField],
            buildScopeTree: true,
          });

        const ownerIDList: string[] =
          determineScopeCriteriaByRolesResponse.criteria.ownerID.list;

        const retrievedPotentialSourceAccounts: AccountViewModel[] =
          stellarAccountContext.accounts.filter(
            (val) =>
              ownerIDList.includes(val.ownerID) &&
              !(
                val.category === LedgerAccountCategory.Issuance ||
                val.category === LedgerAccountCategory.Clearance
              ),
          );

        if (!retrievedPotentialSourceAccounts.length) {
          console.error("expected at least 1 potential source account, got 0");
          throw new Error(
            "expected at least 1 potential source account, got 0",
          );
        }

        setPotentialSourceAccounts(retrievedPotentialSourceAccounts);

        // set selected account
        let chosen: StellarAccountViewModel | undefined;
        if (sourceAccountID) {
          chosen = retrievedPotentialSourceAccounts.find(
            (psa) => psa.id === sourceAccountID,
          );
        }

        try {
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(
              chosen?.ledgerID
                ? chosen.ledgerID
                : retrievedPotentialSourceAccounts[0].ledgerID,
            ),
          );
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }
        setPotentialSourceAccountsLoaded(true);
      })();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error fetching required data: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error fetching required data: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
  }, [initialAccountLoadedSuccessfully]);

  return {
    potentialSourceAccounts,
    potentialSourceAccountsLoaded,
    stellarAccountContextKeys,
    initialAccountLoadedSuccessfully,
    stellarAccountContextLoading,
    stellarAccountContextError,
    setInitialAccountLoadedSuccessfully,
  };
};
