import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Model } from "./Model";

export interface UpdateForInstrumentRequest {
  context: Context;
  instrumentID: string;
}

// tslint:disable-next-line:no-empty-interface
export interface UpdateForInstrumentResponse {
  model: Model;
}

export interface FullUpdateRequest {
  context: Context;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FullUpdateResponse {}

export const Updater = {
  serviceProvider: "financialInstrumentView-Updater",
  async UpdateForInstrument(
    request: UpdateForInstrumentRequest,
  ): Promise<UpdateForInstrumentResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.UpdateForInstrument`,
      request,
    });
    return { model: new Model(response.model) };
  },
  async FullUpdate(request: FullUpdateRequest): Promise<FullUpdateResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.FullUpdate`,
      request,
    });
  },
};
