import { TextField } from "@mesh/common-js-react/dist/FormFields";
import {
  MenuItem,
  Typography,
  InputAdornment,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import { alpha, Box } from "@mui/system";
import { ValidationResult } from "common/validation";
import { TextNumField } from "components/FormFields";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Token } from "james/ledger";
import { Listing, Mechanism, MechanismType } from "james/market";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import React, { useState } from "react";
import {
  Info as InfoIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import { SubscriptionOrderBookManagement } from "./components/SubscriptionOrderBookManagement";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";

export type SubscriptionMarketMechanismFormProps = {
  system: boolean;
  smartInstrument: SmartInstrument;
  listing: Listing;
  ledgerTokenViewModels: LedgerTokenViewModel[];
  marketMechanism: Mechanism;
  onUpdate: (updatedMarketMechanism: Mechanism) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

enum FormTab {
  Listing = "Listing",
  SubscriptionOrderBook = "Subscription Order Book",
}

const allFormTabs: FormTab[] = [FormTab.Listing, FormTab.SubscriptionOrderBook];

export const SubscriptionMarketMechanismForm = (
  props: SubscriptionMarketMechanismFormProps,
) => {
  const [activeTab, setActiveTab] = useState(FormTab.Listing);
  if (props.marketMechanism.type !== MechanismType.Subscription) {
    return "invalid market mechanism";
  }

  // subscription market mechanism assumed to have only 1 quote pair
  if (
    props.marketMechanism.quoteParameters.length === 0 ||
    props.marketMechanism.quoteParameters.length > 1
  ) {
    return "invalid market subscription mechanism";
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grapeLight,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        })}
      >
        <Tabs scrollButtons visibleScrollbar value={activeTab}>
          {allFormTabs.map((ft) => (
            <Tab
              id={`smartInstrumentForm-marketmechanismSubscription-${ft}-tab`}
              value={ft}
              key={ft}
              label={ft}
              onClick={() => setActiveTab(ft)}
            />
          ))}
        </Tabs>
      </Box>
      {(() => {
        switch (activeTab) {
          case FormTab.Listing:
            return <ListingComponent {...props} />;
          case FormTab.SubscriptionOrderBook:
            if (props.listing.id === "") {
              // listing doesn't exist yet
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: theme.spacing(0.5),
                    })}
                  >
                    <FaceIcon
                      sx={(theme) => ({
                        fontSize: 110,
                        color: alpha(theme.palette.background.default, 0.5),
                      })}
                    />
                    <Typography
                      color="secondary"
                      variant="h4"
                      children="Listing Doesn't Exist Yet"
                    />
                    <Typography
                      variant="body2"
                      children={
                        "Once you have created the listing you will be able to set up the subscription order book here"
                      }
                    />
                  </Box>
                </Box>
              );
            } else {
              return (
                <SubscriptionOrderBookManagement
                  system={props.system}
                  listingQuoteAssetTokenViewModel={
                    props.ledgerTokenViewModels.find((vm) =>
                      vm.token.isEqualTo(
                        props.marketMechanism.quoteParameters[0].quoteToken,
                      ),
                    ) ?? new LedgerTokenViewModel()
                  }
                  smartInstrument={props.smartInstrument}
                />
              );
            }

          default:
            return null;
        }
      })()}
    </Box>
  );
};

const ListingComponent = (props: SubscriptionMarketMechanismFormProps) => {
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${MechanismType.Subscription}-${field}`
    ];
  };

  // get quote parameter
  const quoteParameter = props.marketMechanism.quoteParameters[0];

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
        alignItems: "center",
      })}
    >
      <TextField
        id="marketListing-mechanisms-subscriptionMechanism-quoteToken-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        fullWidth
        sx={{ maxWidth: 200 }}
        label="Quote Token"
        select
        value={
          props.ledgerTokenViewModels.find((cst) =>
            cst.token.isEqualTo(quoteParameter.quoteToken),
          )?.id ?? ""
        }
        onChange={(e) => {
          quoteParameter.quoteToken =
            props.ledgerTokenViewModels.find((cst) => cst.id === e.target.value)
              ?.token ?? new Token();
          props.marketMechanism.quoteParameters = [quoteParameter];
          props.onUpdate(props.marketMechanism);
        }}
        error={!!getFieldValidation("quoteToken")}
        helperText={getFieldValidation("quoteToken")}
      >
        {props.ledgerTokenViewModels.map((cst, idx) => (
          <MenuItem key={idx} value={cst.id}>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(1),
              })}
            >
              <TokenIconViewUpload
                disableChangeIcon
                size={23}
                token={cst.token}
              />
              <Typography children={cst.token.code} />
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <TextNumField
        id="marketListing-mechanisms-subscriptionMechanism-minimumDealSize-textNumField"
        readOnly={props.readOnly}
        disabled={props.disabled}
        fullWidth
        noDecimalPlaces={7}
        label={"Minimum Deal Size"}
        disallowNegative
        value={quoteParameter.minimumDealSize.value}
        sx={{ maxWidth: 320 }}
        onChange={(e) => {
          quoteParameter.minimumDealSize =
            quoteParameter.minimumDealSize.setValue(e.target.value);
          props.marketMechanism.quoteParameters = [quoteParameter];
          props.onUpdate(props.marketMechanism);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
              {quoteParameter.minimumDealSize.token.code}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              children={
                <Tooltip
                  placement="top"
                  title={`Minimum amount of "${quoteParameter.minimumDealSize.token.code}" that can be bought or sold for "${quoteParameter.quoteToken.code}"`}
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              }
            />
          ),
        }}
        error={!!getFieldValidation("minimumDealSize")}
        helperText={getFieldValidation("minimumDealSize")}
      />
      <TextNumField
        id="marketListing-mechanisms-subscriptionMechanism-maximumDealSize-textNumField"
        readOnly={props.readOnly}
        disabled={props.disabled}
        fullWidth
        noDecimalPlaces={7}
        label={"Maximum Deal Size"}
        disallowNegative
        value={quoteParameter.maximumDealSize.value}
        sx={{ maxWidth: 320 }}
        onChange={(e) => {
          quoteParameter.maximumDealSize =
            quoteParameter.maximumDealSize.setValue(e.target.value);
          props.marketMechanism.quoteParameters = [quoteParameter];
          props.onUpdate(props.marketMechanism);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
              {quoteParameter.maximumDealSize.token.code}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              children={
                <Tooltip
                  placement="top"
                  title={`Maximum amount of "${quoteParameter.maximumDealSize.token.code}" that can be bought or sold for "${quoteParameter.quoteToken.code}"`}
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              }
            />
          ),
        }}
        error={!!getFieldValidation("maximumDealSize")}
        helperText={getFieldValidation("maximumDealSize")}
      />
    </Box>
  );
};
