import { ConnectedIndividual, IdentificationType } from "james/legal";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import {
  LuhnAlgorithm,
  ValidateDateOfBirth,
  ValidateEmail,
} from "utilities/validation";

export const FormValidator = (field: string, value: string): string[] => {
  const errorMessages: string[] = [];
  switch (field) {
    case "emailAddress":
      if (String(value) !== "" && !ValidateEmail(String(value))) {
        errorMessages.push("Invalid");
      }
      break;
    case "dateOfBirth":
      if (String(value) !== "" && !ValidateDateOfBirth(String(value))) {
        errorMessages.push("Invalid");
      }
      break;
    default:
  }
  return errorMessages;
};

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  individual: ConnectedIndividual,
  setFormFieldValid?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  // Validate every form field
  let InvalidFormFields = {};

  if (individual.firstName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      firstName: "Please provide name",
    };
  }

  if (individual.connectionType === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      connectionType: "Please provide connection type",
    };
  }

  if (individual.lastName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      lastName: "Please provide surname",
    };
  }

  if (
    individual.dateOfBirth !== "" &&
    !ValidateDateOfBirth(individual.dateOfBirth)
  ) {
    InvalidFormFields = { ...InvalidFormFields, dateOfBirth: "Invalid" };
  }

  if (individual.dateOfBirth === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      dateOfBirth: "Please provide date of birth",
    };
  }

  if (individual.identificationNumber === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      identificationNumber: "Please provide identification number",
    };
  } else if (
    individual.identificationType ===
      IdentificationType.SouthAfricanIDIdentificationType &&
    individual.identificationNumber.length !== 13
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      identificationNumber: "Identification number must be 13 digits",
    };
  } else if (
    individual.identificationType ===
      IdentificationType.SouthAfricanIDIdentificationType &&
    !LuhnAlgorithm(individual.identificationNumber)
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      identificationNumber: "Invalid",
    };
  }

  if (individual.emailAddress === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      emailAddress: "Provide an email address",
    };
  }

  if (
    individual.emailAddress !== "" &&
    !ValidateEmail(individual.emailAddress)
  ) {
    InvalidFormFields = { ...InvalidFormFields, emailAddress: "Invalid" };
  }

  if (individual.nationality === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      nationality: "Provide nationality",
    };
  }

  if (individual.identificationType === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      identificationType: "Identification type is required",
    };
  }

  if (
    individual.nationality === "ZA" &&
    individual.identificationType !==
      IdentificationType.SouthAfricanIDIdentificationType
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      identificationType: "Identification type invalid",
    };
  }

  if (
    individual.nationality !== "ZA" &&
    individual.identificationType ===
      IdentificationType.SouthAfricanIDIdentificationType
  ) {
    InvalidFormFields = {
      ...InvalidFormFields,
      identificationType: "Identification type invalid",
    };
  }
  if (new Address(individual.physicalAddress).addressLine1 === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      physicalAddressAddressLine1: "Address line required",
    };
  }

  if (new Address(individual.physicalAddress).city === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      physicalAddressCity: "City required",
    };
  }

  if (new Address(individual.physicalAddress).countryCode === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      physicalAddressCountryCode: "Country code is required",
    };
  }

  if (
    individual.postalAddress &&
    !isEqual(new Address(individual.postalAddress), new Address())
  ) {
    if (individual.postalAddress.addressLine1 === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        postalAddressAddressLine1: "Address line missing",
      };
    }

    if (individual.postalAddress.city === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        postalAddressCity: "City is missing",
      };
    }

    if (individual.postalAddress.countryCode === "") {
      InvalidFormFields = {
        ...InvalidFormFields,
        postalAddressCountryCode: "Country code is missing",
      };
    }
  }

  if (setFormFieldValid) {
    setFormFieldValid(InvalidFormFields);
  }

  return !Object.keys(InvalidFormFields).length;
};

export const IndividualFormFieldValidationState: {
  [key: string]: string | undefined;
} = {};
