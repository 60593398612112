import { create } from "zustand";
import { KYCDocument } from "james/kyc/KYCDocument";

interface DocumentsViewStore {
  loading: boolean;
  identificationClassDocument: KYCDocument[];
  proofOfResidenceClassDocument: KYCDocument[];
}

interface DocumentsViewStoreActions {
  setLoading: (val: boolean) => void;
  setIdentificationClassDocument: (val: KYCDocument[]) => void;
  setProofOfResidenceClassDocument: (val: KYCDocument[]) => void;
  clear: () => void;
}

const initialDocumentsViewStoreState: DocumentsViewStore = {
  loading: false,
  identificationClassDocument: [],
  proofOfResidenceClassDocument: [],
};

export const useDocumentsViewStore = create<
  DocumentsViewStore & DocumentsViewStoreActions
>((set) => ({
  ...initialDocumentsViewStoreState,
  setIdentificationClassDocument: (val: KYCDocument[]) =>
    set(() => ({ identificationClassDocument: val })),
  setProofOfResidenceClassDocument: (val: KYCDocument[]) =>
    set(() => ({ proofOfResidenceClassDocument: val })),
  setLoading: (val: boolean) => set(() => ({ loading: val })),
  clear: () => set(() => initialDocumentsViewStoreState),
}));
