import React from "react";
import { LedgerNetwork } from "james/ledger/Network";
import { useMediaQuery, Breakpoint, Theme, Box } from "@mui/material";
import { AllStellarNetworks, StellarNetwork } from "james/stellar";
import stellar from "./stellar.png";
import stellarIconOnly from "./stellarIconOnly.png";

export type NetworkLogoProps = {
  network: LedgerNetwork | "-";
  breakPoint?: Breakpoint;
};

export const NetworkLogo = (props: NetworkLogoProps) => {
  const useSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(props.breakPoint ?? "md"),
  );

  switch (true) {
    case AllStellarNetworks.includes(props.network as StellarNetwork):
      return (
        <Box
          component={"img"}
          alt={"stellar-network-logo"}
          sx={{ height: "22px" }}
          src={useSmall ? stellarIconOnly : stellar}
        />
      );
    default:
      return null;
  }
};
