import { Document } from "james/document";
import config from "react-global-configuration";
import { ETF } from "./ETF";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export interface DraftUpdateETFRequest {
  context: Context;
  etf: ETF;
}

export interface DraftUpdateETFResponse {
  etf: ETF;
}

export interface ChangeETFMaturityDateRequest {
  context: Context;
  etfID: string;
  maturityDate: string;
}

export interface ChangeETFMaturityDateResponse {
  etf: ETF;
}

export interface ChangeETFHoldingsRequest {
  context: Context;
  etfID: string;
  updatedHoldings: Holding[];
}

export interface ChangeETFHoldingsResponse {
  etf: ETF;
}

export interface ChangeETFSectorAllocationsRequest {
  context: Context;
  etfID: string;
  updatedSectorAllocations: SectorAllocation[];
}

export interface ChangeETFSectorAllocationsResponse {
  etf: ETF;
}

export interface ChangeETFCountryAllocationsRequest {
  context: Context;
  etfID: string;
  updatedCountryAllocations: CountryAllocation[];
}

export interface ChangeETFCountryAllocationsResponse {
  etf: ETF;
}

export interface ChangeETFAnnualPerformanceLogRequest {
  context: Context;
  etfID: string;
  updatedAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
}

export interface ChangeETFAnnualPerformanceLogResponse {
  etf: ETF;
}

export interface ChangeETFSupportingDocumentsRequest {
  context: Context;
  etfID: string;
  updatedSupportingDocuments: Document[];
}

export interface ChangeETFSupportingDocumentsResponse {
  etf: ETF;
}

export const ETFUpdater = {
  serviceProvider: "financial-ETFUpdater",
  async DraftUpdateETF(
    request: DraftUpdateETFRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.DraftUpdateETF`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
  async ChangeETFMaturityDate(
    request: ChangeETFMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFMaturityDateResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.ChangeETFMaturityDate`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
  async ChangeETFHoldings(
    request: ChangeETFHoldingsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFHoldingsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.ChangeETFHoldings`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
  async ChangeETFSectorAllocations(
    request: ChangeETFSectorAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFSectorAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.ChangeETFSectorAllocations`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
  async ChangeETFCountryAllocations(
    request: ChangeETFCountryAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFCountryAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.ChangeETFCountryAllocations`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
  async ChangeETFAnnualPerformanceLog(
    request: ChangeETFAnnualPerformanceLogRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFAnnualPerformanceLogResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.ChangeETFAnnualPerformanceLog`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
  async ChangeETFSupportingDocuments(
    request: ChangeETFSupportingDocumentsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeETFSupportingDocumentsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${ETFUpdater.serviceProvider}.ChangeETFSupportingDocuments`,
      request,
    });
    return { etf: new ETF(response.etf) };
  },
};
