import React, { useState } from "react";
import { SetupCompanyAdminClientProfileCard } from "./components/SetupCompanyAdminClientProfileCard";
import { SetupCompanyClientProfileCard } from "./components/setupCompanyClientProfileCard";
import { useApplicationContext } from "context/Application/Application";

export enum CompanyClientStep {
  CompanyAdminDetails = 1,
  CompanyDetails,
}

export function SetupCompanyClientProfile() {
  const { loginClaims } = useApplicationContext();
  const [activeStep, setActiveStep] = useState(
    (() => {
      if (
        loginClaims.firstName === "" ||
        loginClaims.lastName === "" ||
        loginClaims.ausAgreementAcceptanceRequired
      ) {
        return CompanyClientStep.CompanyAdminDetails;
      }
      return CompanyClientStep.CompanyDetails;
    })(),
  );

  const renderStep = (step: CompanyClientStep) => {
    switch (step) {
      case CompanyClientStep.CompanyAdminDetails:
        return (
          <SetupCompanyAdminClientProfileCard setActiveStep={setActiveStep} />
        );

      case CompanyClientStep.CompanyDetails:
        return <SetupCompanyClientProfileCard />;

      default:
        return (
          <SetupCompanyAdminClientProfileCard setActiveStep={setActiveStep} />
        );
    }
  };
  return <>{renderStep(activeStep)}</>;
}
