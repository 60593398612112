import React from "react";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { ViewConfiguration } from "james/configuration";
import { useNavigate } from "react-router-dom";
import { Router } from "routes";
import { useApplicationContext } from "context/Application/Application";
import { Table } from "./MarketListings/Table";

const tabRoutes: RouteType[] = [
  {
    name: "Market Listings",
    id: "marketListings",
    path: "/market-management/market-listing/market-listings",
    component: <Table />,
    allowSubPaths: true,
  },
];

function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  const viewConfigurationRules = viewConfig["MC Market Listing"];
  if (!viewConfigurationRules) {
    console.error("no market listings view configuration");
    return [];
  }

  return tabRoutes.filter((route) => viewConfigurationRules[route.name]);
}

export function MarketListing() {
  const navigate = useNavigate();
  const { viewConfiguration } = useApplicationContext();
  const availableTabRoutes = determineAvailableTabRoutes(viewConfiguration);

  // determine the active path each time the component renders
  const currentRoute = availableTabRoutes.find((r) =>
    window.location.pathname.includes(r.path),
  );

  // set only the active path if the route is not wallet
  let activePath = availableTabRoutes.length ? availableTabRoutes[0].path : "";
  if (currentRoute) {
    activePath = currentRoute.path;
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Paper square sx={{ pl: 4 }}>
        <Grid container>
          <Grid item>
            <Tabs
              value={activePath}
              onChange={(_, value) => {
                if (activePath === value) {
                  return;
                }
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {availableTabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <Box className={"meshScroll"} sx={{ height: "80%" }}>
        <Router
          baseURL={"/market-management/market-listing/"}
          redirectPath={"/market-management/market-listing/market-listings"}
          routes={availableTabRoutes}
        />
      </Box>
    </Box>
  );
}
