import { Box } from "@mui/material";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { Token } from "james/ledger";
import React from "react";

export interface IconPairProps {
  firstToken: Token;
  secondToken: Token;
  size?: number;
}

export const IconPair = ({ firstToken, secondToken, size }: IconPairProps) => {
  return (
    <Box
      id="icon-box"
      sx={{
        position: "relative",
        width: size ? size * 2 - size / 4 : 80,
        height: size ?? 48,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          zIndex: 1,
          borderRadius: "50%",
          boxShadow: "4px 0 6px -2px #000000",
        }}
      >
        <IconViewUpload size={size ?? 48} token={firstToken} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <IconViewUpload size={size ?? 48} token={secondToken} />
      </Box>
    </Box>
  );
};
