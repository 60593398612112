import { Amount } from "james/ledger";
import { TokenCategory } from "../ledgerTokenView/Model";

export class Model {
  public id = "";

  public ownerID = "";

  public assetCode = "";

  public name = "";

  public amount: Amount = new Amount();

  public issuerName = "";

  public archived = false;

  public claimAnytime = false;

  public claimableBalanceID = "";

  public claimableBalanceSource = "";

  public claimableBalanceTokenCategory: TokenCategory | "" = "";

  public claimed = false;

  public claimantAccountLedgerID = "";

  public reference = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }

    this.id = model.id;
    this.ownerID = model.ownerID;
    this.assetCode = model.assetCode;
    this.name = model.name;
    this.amount = new Amount(model.amount);
    this.issuerName = model.issuerName;
    this.archived = model.archived;
    this.claimAnytime = model.claimAnytime;
    this.claimableBalanceID = model.claimableBalanceID;
    this.claimableBalanceSource = model.claimableBalanceSource;
    this.claimableBalanceTokenCategory = model.claimableBalanceTokenCategory;
    this.claimed = model.claimed;
    this.claimantAccountLedgerID = model.claimantAccountLedgerID;
    this.reference = model.reference;
  }
}
