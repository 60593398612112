import { TextSubstringCriterionTypeName } from "james/search/criterion";

export interface TextSubstringCriterionType {
  type: string;
  text: string;
}

function TextSubstringCriterion(text: string): TextSubstringCriterionType {
  return {
    type: TextSubstringCriterionTypeName,
    text,
  };
}

export { TextSubstringCriterion };
