import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Model } from "./Model";

export interface UpdateForCryptoCurrencyRequest {
  context: Context;
  cryptoCurrencyID: string;
}

// tslint:disable-next-line:no-empty-interface
export interface UpdateForCryptoCurrencyResponse {
  model: Model;
}

export interface FullUpdateRequest {
  context: Context;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FullUpdateResponse {}

export const Updater = {
  serviceProvider: "financialCryptoCurrencyView-Updater",
  async UpdateForCryptoCurrency(
    request: UpdateForCryptoCurrencyRequest,
  ): Promise<UpdateForCryptoCurrencyResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.UpdateForCryptoCurrency`,
      request,
    });
    return { model: new Model(response.model) };
  },
  async FullUpdate(request: FullUpdateRequest): Promise<FullUpdateResponse> {
    return await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.FullUpdate`,
      request,
    });
  },
};
