import { Role } from "james/role";
import { User } from "./User";
import { Group } from "../group/Group";

export class GroupUser extends User {
  public fullName = "";

  public roles: Role[] = [];

  public groups: Group[] = [];

  constructor(groupUser?: GroupUser) {
    super(groupUser);
    if (!groupUser) {
      return;
    }
    this.roles = groupUser.roles.map((r) => new Role(r));
    this.fullName = groupUser.fullName;
    this.groups = groupUser.groups.map((g) => new Group(g));
  }
}
