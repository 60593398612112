import React, { useState } from "react";
import {
  Box,
  useMediaQuery,
  Theme,
  Card,
  CardContent,
  Typography,
  alpha,
} from "@mui/material";
import { SubscriptionOrderTable } from "components/MarketSubscriptions/SubscriptionOrderTable";
import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import { SubscriptionOrderViewCard } from "./components/SubscriptionOrderViewCard";
import { SubscriptionOrderInfScrollList } from "views/Marketplace/components/Subscriptions/components/SubscriptionOrderInfScrollList";
import { FaceOutlined } from "@mui/icons-material";
import cx from "classnames";
import { DataComponentInfo } from "const/gtm";

export const Subscriptions = () => {
  const [orders, setOrders] = useState<MarketSubscriptionOrderViewModel[]>([]);
  const [selectedOrder, setSelectedOrder] =
    useState<MarketSubscriptionOrderViewModel>();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const tablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "lg"),
  );

  return smUp ? (
    <>
      <Box
        className={cx("meshScroll", {
          "has-orders": orders.length !== 0,
        })}
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.breakpoints.values.lg,
          padding: theme.spacing(3, 3, 3, 3),
          justifyContent: "center",
          columnGap: theme.spacing(4),
          rowGap: theme.spacing(4),
          "&.has-orders": {
            display: "grid",
            [theme.breakpoints.down("lg")]: {
              gridTemplateRows: "auto auto",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: "800px 400px",
            },
          },
        })}
        data-component-info={JSON.stringify({
          component_id: "subscription_order_list",
          component_business_name: "subscription orders",
          component_title: "subscription orders list",
          component_driver: "list_subscription_orders",
        } as DataComponentInfo)}
      >
        <SubscriptionOrderTable
          system={false}
          height={window.innerHeight - 108 - 48 - 48 - (tablet ? 24 : 0)}
          onSelect={(order) => setSelectedOrder(order)}
          hideColumns={["Res. Count", "Book", "Name"]}
          onUpdateOrders={(orders) => setOrders(orders)}
        />
        {orders.length > 0 && (
          <Box
            sx={{
              width: { lg: 420, sm: "100%" },
              maxHeight: 540,
            }}
          >
            {selectedOrder ? (
              <SubscriptionOrderViewCard selectedOrder={selectedOrder} />
            ) : (
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardContent
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaceOutlined
                    sx={(theme) => ({
                      fontSize: 110,
                      color: alpha(theme.palette.background.default, 0.5),
                    })}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={(theme) => ({
                      textAlign: "center",
                      color: theme.palette.text.secondary,
                      width: 250,
                    })}
                    children="Please select a row on the left to display an order"
                  />
                </CardContent>
              </Card>
            )}
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Box>
      <SubscriptionOrderInfScrollList />
    </Box>
  );
};
