import { StellarNetwork } from "james/stellar";

export type RecipientStateType = RecipientState;

export enum RecipientState {
  Active = "Active",
  Inactive = "Inactive",
}

export class Model {
  public id = "";
  public state: RecipientStateType = RecipientState.Active;
  public address = "";
  public network: StellarNetwork = StellarNetwork.TestSDFNetwork;
  public defaultMemo = "";
  public label = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.state = model.state;
    this.address = model.address;
    this.network = model.network;
    this.defaultMemo = model.defaultMemo;
    this.label = model.label;
  }
}
