import {
  InstrumentManagementFee,
  InstrumentManagementFeeType,
} from "./InstrumentManagementFee";
import { Money } from "../money";

export const CurrencyAmountInstrumentManagementFeeTypeName =
  "mesh::financial/CurrencyAmountInstrumentManagementFee";

export type NewCurrencyAmountInstrumentManagementFeeProps = {
  amount: Money;
};

export class CurrencyAmountInstrumentManagementFee
  implements InstrumentManagementFee
{
  public ["@type"]: string = CurrencyAmountInstrumentManagementFeeTypeName;

  public amount: Money = new Money();

  constructor(f?: NewCurrencyAmountInstrumentManagementFeeProps) {
    if (!f) {
      return;
    }
    this.amount = new Money(f.amount);
  }

  instrumentManagementFeeType(): InstrumentManagementFeeType {
    return InstrumentManagementFeeType.CurrencyAmount;
  }
}
