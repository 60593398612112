import React from "react";
import { Accounts } from "./Accounts";

export function AccountManagement() {
  return (
    <div>
      <Accounts />;
    </div>
  );
}
