import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AddAPhotoRounded as AddAPhotoIcon } from "@mui/icons-material";
import cx from "classnames";
import { countries } from "james/country";
import { AllIdentificationTypes, IdentificationType } from "james/legal";
import {
  Person,
  PersonManagerRead,
  PersonManagerWrite,
} from "james/legal/person";
import { UserManagerRead, UserProfile as Profile } from "james/user";
import React, { useEffect, useRef, useState } from "react";
import { ValidateEmail } from "utilities/validation";
import { useSnackbar } from "notistack";
import {
  GenerateAPIKeyForMyUserResponse,
  IsAPIKeySetForMyUserResponse,
} from "james/security/apiKey/Manager";
import { APIKeyManager } from "james/security/apiKey";
import { doUpload } from "utilities/network/upload";
import { useApplicationContext } from "context/Application/Application";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
import { useErrorContext } from "context/Error";
const PREFIX = "UserProfile";

const classes = {
  root: `${PREFIX}-root`,
  cardContentRootOverride: `${PREFIX}-cardContentRootOverride`,
  userProfileCardHeaderTitleLayout: `${PREFIX}-userProfileCardHeaderTitleLayout`,
  cardHeaderTitleLayout: `${PREFIX}-cardHeaderTitleLayout`,
  loadingCircularProgressWrapper: `${PREFIX}-loadingCircularProgressWrapper`,
  cardHeaderTitleButtonLayout: `${PREFIX}-cardHeaderTitleButtonLayout`,
  cardHeaderTitleText: `${PREFIX}-cardHeaderTitleText`,
  cardHeaderRootOverride: `${PREFIX}-cardHeaderRootOverride`,
  infoLayout: `${PREFIX}-infoLayout`,
  profilePictureLayout: `${PREFIX}-profilePictureLayout`,
  profilePictureAvatar: `${PREFIX}-profilePictureAvatar`,
  profilePictureAvatarNoImage: `${PREFIX}-profilePictureAvatarNoImage`,
  profilePictureVertical: `${PREFIX}-profilePictureVertical`,
  profilePictureHorizontal: `${PREFIX}-profilePictureHorizontal`,
  editPictureLabel: `${PREFIX}-editPictureLabel`,
  editPictureLabelDisabled: `${PREFIX}-editPictureLabelDisabled`,
  formField: `${PREFIX}-formField`,
  emailFormField: `${PREFIX}-emailFormField`,
  myGroupsLayout: `${PREFIX}-myGroupsLayout`,
  myGroupsRow: `${PREFIX}-myGroupsRow`,
  myGroupsHeading: `${PREFIX}-myGroupsHeading`,
  myGroupName: `${PREFIX}-myGroupName`,
  hiddenInput: `${PREFIX}-hiddenInput`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "calc(100%)",
    margin: 0,
    padding: theme.spacing(1),
  },

  [`& .${classes.cardContentRootOverride}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.userProfileCardHeaderTitleLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
  },

  [`& .${classes.cardHeaderTitleLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.loadingCircularProgressWrapper}`]: {
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.cardHeaderTitleButtonLayout}`]: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },

  [`& .${classes.cardHeaderTitleText}`]: {},

  [`& .${classes.cardHeaderRootOverride}`]: {
    height: "50px !important",
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
  },

  [`& .${classes.infoLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto auto",
    justifyItems: "center",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.profilePictureLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto auto",
    justifyItems: "center",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.profilePictureAvatar}`]: {
    width: 143,
    height: 143,
    cursor: "pointer",
    alignItems: "start",
  },

  [`& .${classes.profilePictureAvatarNoImage}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  [`& .${classes.profilePictureVertical}`]: {
    width: 143,
  },

  [`& .${classes.profilePictureHorizontal}`]: {
    height: 143,
  },

  [`& .${classes.editPictureLabel}`]: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.editPictureLabelDisabled}`]: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },

  [`& .${classes.formField}`]: {
    width: 220,
  },

  [`& .${classes.emailFormField}`]: {
    width: 454,
  },

  [`& .${classes.myGroupsLayout}`]: {
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },

  [`& .${classes.myGroupsRow}`]: {
    paddingTop: 8,
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(4),
  },

  [`& .${classes.myGroupsHeading}`]: {
    paddingTop: 4,
    fontSize: "14px",
    lineHeight: "16px",
  },

  [`& .${classes.myGroupName}`]: {
    paddingLeft: theme.spacing(3),
  },

  [`& .${classes.hiddenInput}`]: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },
}));

const userProfileStates = {
  viewing: 0,
  editing: 1,
};

export const UserProfile = () => {
  const {
    viewConfiguration,
    authContext,
    refreshProfilePictureURL,
    refreshMyPerson,
  } = useApplicationContext();
  const [originalMyPerson, setOriginalMyPerson] = useState(new Person());
  const [myPerson, setMyPerson] = useState(new Person());
  const [myProfile, setMyProfile] = useState<Profile>(new Profile());
  const [loading, setLoading] = useState(false);
  const [userProfileState, setUserProfileState] = useState(
    userProfileStates.viewing,
  );
  const [fieldValidation, setFieldValidation] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [uploadInputRef, setUploadInputRef] = useState<HTMLInputElement | null>(
    null,
  );
  const [uploadClick, setUploadClick] = useState<(() => void) | null>(null);
  const [isAPIKeySetForMyUserResponse, setIsAPIKeySetForMyUserResponse] =
    useState<IsAPIKeySetForMyUserResponse>({ result: false });
  const [generateAPIKeyForMyUserResponse, setGenerateAPIKeyForMyUserResponse] =
    useState<GenerateAPIKeyForMyUserResponse>({
      apiKey: "",
    });
  const { enqueueSnackbar } = useSnackbar();
  const [profilePictureUploadInProgress, setProfilePictureUploadInProgress] =
    useState(false);
  const [profilePictureUploadPercentage, setProfilePictureUploadPercentage] =
    useState(0);
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);
  const { errorContextErrorTranslator } = useErrorContext();

  //
  // initial load
  //
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setMyPerson(
          (
            await PersonManagerRead.RetrieveMyPerson({
              context: authContext,
            })
          ).person,
        );
        // only try find api key if user has view permission
        if (viewConfiguration["User Profile - API Key Card"]) {
          setIsAPIKeySetForMyUserResponse(
            await APIKeyManager.IsAPIKeySetForMyUser({
              context: authContext,
            }),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving my person: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error retrieving my person: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      try {
        setMyProfile(
          (
            await UserManagerRead.RetrieveMyProfile({
              context: authContext,
            })
          ).profile,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving my user profile: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error retrieving my user profile: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setLoading(false);
    };
    fetchData().finally();
  }, [authContext, viewConfiguration, enqueueSnackbar]);

  const performValidation: () => { [key: string]: string | undefined } = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    if (myPerson.firstName === "") {
      newValidationState.firstName = "Cannot Be Blank";
    }
    if (myPerson.lastName === "") {
      newValidationState.lastName = "Cannot Be Blank";
    }
    if (myPerson.contactDetails.emailAddress === "") {
      newValidationState.emailAddress = "Cannot Be Blank";
    } else if (!ValidateEmail(myPerson.contactDetails.emailAddress)) {
      newValidationState.emailAddress = "Invalid";
    }
    return newValidationState;
  };

  const handleClearValidation = (field: string) => {
    setFieldValidation({
      ...fieldValidation,
      [field]: undefined,
    });
  };

  const attachInputClick = (inputRef: HTMLInputElement) => {
    if (uploadInputRef !== null) {
      return;
    }
    if (!inputRef) {
      return;
    }
    if (uploadClick !== null) {
      return;
    }
    setUploadInputRef(inputRef);
    setUploadClick(() => () => {
      inputRef.click();
    });
  };

  const handleUpdateInfo = async () => {
    // perform field validations
    const validations = performValidation();
    if (Object.keys(validations).length > 0) {
      // if there are any errors, return
      setFieldValidation(validations);
      return;
    }

    setLoading(true);
    try {
      const updateMyPersonResponse = await PersonManagerWrite.UpdateMyPerson({
        context: authContext,
        person: myPerson,
      });
      await refreshMyPerson();
      setMyPerson(new Person(updateMyPersonResponse.person));
      enqueueSnackbar("Updated", { variant: "success" });
      setUserProfileState(userProfileStates.viewing);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `Error Updating Profile: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `Error Updating Profile: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
    }
    setLoading(false);
  };

  const handleSelectPictureFile = async () => {
    if (uploadInputRef === null) {
      console.error("upload input ref is null");
      return;
    }

    try {
      // confirm that a file has been selected
      if (!(uploadInputRef.files && uploadInputRef.files.length)) {
        console.error("no upload input ref files");
        return;
      }

      // get a reference to the selected file and confirm it is not null
      const fileToUpload = uploadInputRef.files[0];
      if (!fileToUpload) {
        console.error("file to upload is null");
        return;
      }

      setProfilePictureUploadInProgress(true);
      setProfilePictureUploadPercentage(0);

      // construct a file reader
      // read file
      // perform upload
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const fileData: ArrayBuffer | null =
          fileReader.result as ArrayBuffer | null;
        if (fileData === null) {
          console.error("file data is null");
          return;
        }

        const uploadURL = (
          await UserManagerRead.GetMyProfilePictureUploadURL({
            context: authContext,
          })
        ).url;
        doUpload({
          url: uploadURL,
          documentType: fileToUpload.type,
          documentData: fileData,
          onComplete: refreshProfilePictureURL,
          onProgress: (percentageComplete: number) =>
            setProfilePictureUploadPercentage(percentageComplete),
        });
      };

      fileReader.readAsArrayBuffer(uploadInputRef.files[0]);
      setProfilePictureUploadInProgress(false);
    } catch (e) {
      console.error("error trying to access picture file", e);
      setProfilePictureUploadInProgress(false);
    }
  };

  const handleGenerateAPIKeyForUser = async () => {
    setLoading(true);
    try {
      setGenerateAPIKeyForMyUserResponse(
        await APIKeyManager.GenerateAPIKeyForMyUser({
          context: authContext,
        }),
      );
      enqueueSnackbar("api key generated", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error("error generating api key", err);
      enqueueSnackbar(
        `error generating api key: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setLoading(false);
  };

  return (
    <StyledGrid container spacing={2} direction="row" className={classes.root}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            classes={{ root: classes.cardHeaderRootOverride }}
            title={
              <div className={classes.userProfileCardHeaderTitleLayout}>
                <div className={classes.cardHeaderTitleText}>
                  My User Profile
                </div>
                <div className={classes.loadingCircularProgressWrapper}>
                  {loading && <CircularProgress size={25} />}
                  {profilePictureUploadInProgress && (
                    <CircularProgress
                      size={25}
                      variant={
                        profilePictureUploadPercentage
                          ? "determinate"
                          : "indeterminate"
                      }
                      value={profilePictureUploadPercentage}
                    />
                  )}
                </div>
                <div className={classes.cardHeaderTitleButtonLayout}>
                  {(() => {
                    switch (userProfileState) {
                      case userProfileStates.viewing:
                        return (
                          <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setOriginalMyPerson(new Person(myPerson));
                              setUserProfileState(userProfileStates.editing);
                            }}
                          >
                            Edit
                          </Button>
                        );
                      case userProfileStates.editing:
                        return (
                          <>
                            <Button
                              disabled={loading}
                              variant="outlined"
                              onClick={() => {
                                setMyPerson(new Person(originalMyPerson));
                                setFieldValidation({});
                                setUserProfileState(userProfileStates.viewing);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              disabled={loading}
                              variant="contained"
                              color="primary"
                              onClick={handleUpdateInfo}
                              sx={{ ml: 2 }}
                            >
                              Update
                            </Button>
                          </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              </div>
            }
          />
          <CardContent
            classes={{
              root: cx(classes.cardContentRootOverride, classes.infoLayout),
            }}
          >
            <div className={classes.profilePictureLayout}>
              <Avatar
                className={cx(classes.profilePictureAvatar, {
                  [classes.profilePictureAvatarNoImage]:
                    !myProfile.profilePictureURL,
                })}
                onClick={() => {
                  if (uploadClick != null) {
                    uploadClick();
                  }
                }}
              >
                {myProfile.profilePictureURL ? (
                  <img
                    onLoad={() => {
                      if (profilePictureRef.current) {
                        setProfilePictureVertical(
                          profilePictureRef.current.naturalWidth <
                            profilePictureRef.current.naturalHeight,
                        );
                      }
                    }}
                    ref={profilePictureRef}
                    alt=""
                    src={myProfile.profilePictureURL}
                    className={cx({
                      [classes.profilePictureVertical]: profilePictureVertical,
                      [classes.profilePictureHorizontal]:
                        !profilePictureVertical,
                    })}
                  />
                ) : (
                  <AddAPhotoIcon />
                )}
              </Avatar>
              <Typography
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  if (uploadClick != null) {
                    uploadClick();
                  }
                }}
                variant="subtitle1"
                className={cx(classes.editPictureLabel, {
                  [classes.editPictureLabelDisabled]: loading,
                })}
              >
                {myProfile.profilePictureURL ? "Update Picture" : "Add Picture"}
              </Typography>
              <input
                disabled={loading}
                ref={attachInputClick}
                onChange={handleSelectPictureFile}
                type="file"
                accept=".png,.jpg,.jpeg"
                className={classes.hiddenInput}
              />
            </div>
            <div>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h6">Personal Details</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.formField}
                        label="First Name"
                        variant="outlined"
                        value={myPerson.firstName}
                        onChange={(e) => {
                          handleClearValidation("firstName");
                          myPerson.firstName = e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.firstName}
                        helperText={fieldValidation.firstName}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.formField}
                        label="Middle Name(s)"
                        variant="outlined"
                        value={myPerson.middleNames}
                        onChange={(e) => {
                          handleClearValidation("middleNames");
                          myPerson.middleNames = e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.middleNames}
                        helperText={fieldValidation.middleNames}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.formField}
                        label="Last Name"
                        variant="outlined"
                        value={myPerson.lastName}
                        onChange={(e) => {
                          handleClearValidation("lastName");
                          myPerson.lastName = e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.lastName}
                        helperText={fieldValidation.lastName}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField
                        margin="dense"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        placeholder="YYYY-MM-DD"
                        className={classes.formField}
                        label="Date of Birth"
                        variant="outlined"
                        value={myPerson.dateOfBirth}
                        onChange={(e) => {
                          handleClearValidation("dateOfBirth");
                          myPerson.dateOfBirth = e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.dateOfBirth}
                        helperText={fieldValidation.dateOfBirth}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <CountryDropdown
                        value={(() => {
                          const country = countries.find(
                            (option) => option.label === myPerson.nationality,
                          );
                          return {
                            value: country?.value ?? "",
                            label: country?.label ?? "",
                          };
                        })()}
                        onChange={(_, value) => {
                          if (!value) return;
                          handleClearValidation("nationality");
                          myPerson.nationality = value.label;
                          setMyPerson(new Person(myPerson));
                        }}
                        readOnly={
                          userProfileState !== userProfileStates.editing
                        }
                        sx={{
                          width: 220,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField
                        select
                        label={"Identification Type"}
                        error={!!fieldValidation.identificationType}
                        helperText={fieldValidation.identificationType}
                        value={myPerson.identificationType}
                        onChange={(e) => {
                          handleClearValidation("identificationType");
                          myPerson.identificationType = e.target
                            .value as IdentificationType;
                          setMyPerson(new Person(myPerson));
                        }}
                        inputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                        sx={{
                          width: 220,
                        }}
                      >
                        {AllIdentificationTypes.map((c, idx) => (
                          <MenuItem key={idx} value={c}>
                            {c}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.formField}
                        label="Identification Number"
                        variant="outlined"
                        value={myPerson.identificationNumber}
                        onChange={(e) => {
                          handleClearValidation("identificationNumber");
                          myPerson.identificationNumber = e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.identificationNumber}
                        helperText={fieldValidation.identificationNumber}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h6">Contact Details</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.emailFormField}
                        label="Email"
                        variant="outlined"
                        value={myPerson.contactDetails.emailAddress}
                        onChange={(e) => {
                          handleClearValidation("emailAddress");
                          myPerson.contactDetails.emailAddress = e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.emailAddress}
                        helperText={fieldValidation.emailAddress}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.formField}
                        label="Mobile"
                        variant="outlined"
                        value={myPerson.contactDetails.cellphoneNumber}
                        onChange={(e) => {
                          handleClearValidation("cellphoneNumber");
                          myPerson.contactDetails.cellphoneNumber =
                            e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.cellphoneNumber}
                        helperText={fieldValidation.cellphoneNumber}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        margin="dense"
                        className={classes.formField}
                        label="Telephone"
                        variant="outlined"
                        value={myPerson.contactDetails.telephoneNumber}
                        onChange={(e) => {
                          handleClearValidation("telephoneNumber");
                          myPerson.contactDetails.telephoneNumber =
                            e.target.value;
                          setMyPerson(new Person(myPerson));
                        }}
                        error={!!fieldValidation.telephoneNumber}
                        helperText={fieldValidation.telephoneNumber}
                        InputProps={{
                          readOnly:
                            userProfileState !== userProfileStates.editing,
                        }}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card>
              <CardHeader
                classes={{ root: classes.cardHeaderRootOverride }}
                title={
                  <div className={classes.cardHeaderTitleLayout}>
                    <div className={classes.cardHeaderTitleText}>
                      Manage My Password
                    </div>
                    <div className={classes.cardHeaderTitleButtonLayout}>
                      <Button disabled={loading} variant="outlined">
                        update
                      </Button>
                    </div>
                  </div>
                }
              />
              <CardContent classes={{ root: classes.cardContentRootOverride }}>
                Manage Password Content
              </CardContent>
            </Card>
          </Grid>
          {viewConfiguration["User Profile - API Key Card"] && (
            <Grid item>
              <Card>
                <CardHeader
                  classes={{ root: classes.cardHeaderRootOverride }}
                  title={
                    <div className={classes.cardHeaderTitleText}>
                      API Access
                    </div>
                  }
                />
                <CardContent
                  classes={{ root: classes.cardContentRootOverride }}
                >
                  {(() => {
                    // return progress indicator if loading
                    if (loading) {
                      return <CircularProgress />;
                    }

                    // if generate response key is not blank
                    // a key has just been generated
                    // render the key with a warning message
                    if (generateAPIKeyForMyUserResponse.apiKey !== "") {
                      return (
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography>API Key</Typography>
                          </Grid>
                          <Grid item>
                            {generateAPIKeyForMyUserResponse.apiKey}
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" color="error">
                              Please take note of this key now. It will no
                              longer be accessible once you leave this screen.
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }

                    // a key has been generated before
                    // display that information to the user and
                    // a button for regeneration
                    if (isAPIKeySetForMyUserResponse.result) {
                      return (
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography>An api key already exists</Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={handleGenerateAPIKeyForUser}
                            >
                              regenerate
                            </Button>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" color="error">
                              Regeneration will prevent access with the current
                              key!
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }

                    // a key has never been generated for user
                    // render generate button
                    return (
                      <Grid container direction="column">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleGenerateAPIKeyForUser}
                          >
                            generate key
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })()}
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item>
            <Card>
              <CardHeader
                classes={{ root: classes.cardHeaderRootOverride }}
                title="My Groups"
              />
              <CardContent
                classes={{
                  root: cx(
                    classes.cardContentRootOverride,
                    classes.myGroupsLayout,
                  ),
                }}
              >
                <div
                  className={cx(
                    classes.myGroupsRow,
                    classes.myGroupsHeading,
                    classes.myGroupName,
                  )}
                >
                  Group Name
                </div>
                <div
                  className={cx(classes.myGroupsRow, classes.myGroupsHeading)}
                >
                  Roles & Responsibilities
                </div>
                {myProfile.groupRoles.map((groupRole, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className={cx(classes.myGroupsRow, classes.myGroupName)}
                    >
                      {groupRole.groupName}
                    </div>
                    <div className={classes.myGroupsRow}>
                      {groupRole.roleNames.join(", ")}
                    </div>
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};
