import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { SystemInspector } from "james/system";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  TextFieldProps,
  useTheme,
  Button,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReportDownloader } from "james/analytics/reportDownloader";
import { FinancialCurrencyStablecoinViewReader } from "james/views/financialCurrencyStablecoinView";
import { useApplicationContext } from "context/Application/Application";
import { TextExactCriterion } from "james/search/criterion";
import { UnderlyingCurrencyCategory } from "james/views/financialCurrencyStablecoinView/Model";
import { Token } from "james/ledger";
import { downloadExcelFile } from "utilities/network/download";
import dayjs from "dayjs";
import { DateField } from "components/FormFields";
import { dateIsValid } from "utilities/date/dateIsValid";
import { TextField } from "components/FormFields/TextField";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { LoadingEllipses } from "components/Animations/LoadingEllipses";
import { useErrorContext } from "context/Error";

const PREFIX = "System";

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
  },
}));

export function System() {
  const [loading, setLoading] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [jamesVersion, setJamesVersion] = useState("---");
  const { enqueueSnackbar } = useSnackbar();
  const [valuationToken, setValuationToken] = useState<Token>(new Token());
  const [fromDate, setFromDate] = useState<DateRangeValue | undefined>(
    undefined,
  );
  const [toDate, setToDate] = useState<DateRangeValue | undefined>(undefined);
  const { viewConfiguration, authContext } = useApplicationContext();
  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined,
  );
  const theme = useTheme();
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    (async () => {
      setLoading(true);

      await Promise.all([
        (async () => {
          try {
            setJamesVersion(
              (await SystemInspector.GetJamesVersion({})).version,
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error getting james version: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `error getting james version: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
          }
        })(),

        (async () => {
          try {
            const financialCurrencyStableCoin = (
              await FinancialCurrencyStablecoinViewReader.Read({
                context: authContext,
                criteria: {
                  underlyingCurrencyCategory: TextExactCriterion(
                    UnderlyingCurrencyCategory.Fiat,
                  ),
                  shortName: TextExactCriterion("mZAR"),
                },
              })
            ).models;

            if (financialCurrencyStableCoin.length) {
              // then set the starting valuation token
              setValuationToken(
                new Token(financialCurrencyStableCoin[0].token),
              );
            } else {
              console.error("could not find valuation currency");
              return;
            }
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `unable to search for potential valuation stable coin: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),
      ]);
      setLoading(false);
    })();
  }, [enqueueSnackbar]);

  const handleDownloadAnalyticsReport = async () => {
    if (fromDate && toDate) {
      setDownloadingReport(true);
      try {
        const downloadAnalyticsReportResponse =
          await ReportDownloader.DownloadAnalyticsReport({
            context: authContext,
            valuationToken,
            fromDate: fromDate.date,
            toDate: toDate.date,
          });
        downloadExcelFile({
          encodedString: downloadAnalyticsReportResponse.excelFileBase64,
          fileName:
            dayjs(toDate.date).format("YYYY-MM-DD") + "_analyticsReport",
          fileExt: ".xlsx",
        });
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `unable to download analytics report: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    }
    setDownloadingReport(false);
  };

  const validateDate = (date: string, field: string) => {
    setErrors({
      ...errors,
      [field]: "",
    });
    if (date.length >= 8 && !dateIsValid(dayjs(date).startOf("day").format())) {
      setErrors({
        ...errors,
        [field]: "Invalid date",
      });
    }
  };

  return (
    <Root>
      <Card className={classes.card}>
        <CardHeader
          title={
            <div className={classes.title}>
              <Typography variant="h5" children="Operations Information" />
              {loading && <CircularProgress size={25} />}
            </div>
          }
        />
        <CardContent>
          <div>
            <Typography
              variant="body2"
              color="textSecondary"
              children="James Version"
            />
            <Typography variant="h5" children={jamesVersion} />
          </div>
        </CardContent>
      </Card>
      {viewConfiguration?.BetaFeatures?.DownloadAnalyticsReport && (
        <Card className={classes.card}>
          <CardHeader
            title={
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5" children="Analytics Report" />
                  {loading && <CircularProgress size={25} />}
                  <Button
                    id="system-analyticsReportDowload-button"
                    color="primary"
                    disabled={
                      loading || !fromDate || !toDate || downloadingReport
                    }
                    variant="contained"
                    onClick={handleDownloadAnalyticsReport}
                  >
                    Download
                  </Button>
                </Box>
              </>
            }
          />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: downloadingReport ? "center" : "",
              margin: theme.spacing(0, 0, 3),
            })}
          >
            {downloadingReport ? (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: theme.spacing(2),
                })}
              >
                <Typography sx={{ paddingRight: "8px" }} fontSize="16px">
                  Downloading Report
                </Typography>
                <LoadingEllipses />
              </Box>
            ) : (
              <>
                <DateField
                  label={"From"}
                  id={"system-analyticsReportFromDate-dateField"}
                  value={fromDate ? fromDate.date : null}
                  onChange={(newValue) => {
                    if (
                      toDate &&
                      dayjs(newValue).isAfter(toDate?.date ?? dayjs())
                    ) {
                      setErrors({
                        ...errors,
                        fromDate: "From date cannot be after To date",
                      });
                      setFromDate(
                        newValue
                          ? {
                              date: dayjs(newValue).startOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      );
                    } else if (!(newValue && dateIsValid(newValue))) {
                      setFromDate(undefined);
                    } else {
                      setErrors(undefined);
                      setFromDate(
                        newValue
                          ? {
                              date: dayjs(newValue).startOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      );
                    }
                  }}
                  renderInput={(textFieldProps: TextFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      id={"system-analyticsReportFromDate-dateFieldTextField"}
                      sx={{ width: 240 }}
                      variant={"outlined"}
                      margin={"dense"}
                      helperText={errors?.fromDate ?? ""}
                      FormHelperTextProps={{
                        sx: { color: theme.palette.error.main },
                      }}
                      onChange={(e) => validateDate(e.target.value, "fromDate")}
                    />
                  )}
                />
                <DateField
                  label={"To"}
                  id={"system-analyticsReportToDate-dateField"}
                  value={toDate ? toDate.date : null}
                  onChange={(newValue) => {
                    if (fromDate && dayjs(newValue).isBefore(fromDate.date)) {
                      setErrors({
                        ...errors,
                        toDate: "To date cannot be before From date",
                      });
                      setToDate(
                        newValue
                          ? {
                              date: dayjs(newValue).startOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      );
                    } else if (!(newValue && dateIsValid(newValue))) {
                      setToDate(undefined);
                    } else {
                      setErrors(undefined);
                      setToDate(
                        newValue
                          ? {
                              date: dayjs(newValue).startOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      );
                    }
                  }}
                  renderInput={(textFieldProps: TextFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      id={"system-analyticsReportToDate-dateFieldTextField"}
                      sx={{ width: 240 }}
                      variant={"outlined"}
                      margin={"dense"}
                      helperText={errors?.toDate ?? ""}
                      FormHelperTextProps={{
                        sx: { color: theme.palette.error.main },
                      }}
                      onChange={(e) => validateDate(e.target.value, "toDate")}
                    />
                  )}
                />
                <TextField
                  label="Valuation Stablecoin"
                  id="digitalETFDialog-valuationToken-textField"
                  readOnly
                  value={"mZAR - Mesh B.V."}
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}
          </CardContent>
        </Card>
      )}
    </Root>
  );
}
