import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  PusherSubscriptionErrorNotification,
  PusherSubscriptionSucceededNotification,
  usePusherContext,
} from "context/Pusher";
import { Notification, NewNotification } from "james/notification/Notification";
import { Channel as NotificationChannel } from "james/notification/Channel";
import { v4 as uuidV4 } from "uuid";
import { Channel } from "pusher-js";
import * as Sentry from "@sentry/react";
import { usePrevious } from "hooks/usePrevious";
import { useApplicationContext } from "context/Application/Application";

interface ContextType {
  // registerNotificationCallback can be called to register a callback that will
  // be called when any of the given notificationTypes are delivered on the
  // given channel.
  // Returns a de-registration function that should be called when the registration
  // is no longer required.
  // Note that all subscribed channels will be killed when auth session ends.
  registerNotificationCallback: (
    // channel on which to register callBack
    channel: NotificationChannel,
    // list of notification Types for which given callBack should be registered
    notificationTypes: string[],
    // callBack to register
    callBack: (e: Notification) => void,
  ) => Promise<() => void>;
  notificationContextInitialised: boolean;
}

const Context = React.createContext({} as ContextType);

type CallbackRegistryEntry = {
  id: string;
  callback: (e: Notification) => void;
};

type ChanelCallbackRegistry = {
  channel: Channel;
  callbackRegistry: { [key: string]: CallbackRegistryEntry[] };
};

export const ErrCouldNotSubscribeToNotificationsChannel =
  "could not subscribe to notifications";

export function NotificationContext({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { pusherConnection } = usePusherContext();
  const { userAuthenticated } = useApplicationContext();
  const prevAppContextLoggedIn = usePrevious(userAuthenticated);
  const pusherSubscribedChannelRegistry = useRef<{
    [key: string]: ChanelCallbackRegistry;
  }>({});

  // when the user logs out we deregister all the pusher notification channels
  useEffect(() => {
    // if the logged in state has changed, and you are no longer logged in unsubscribe
    // from all pusher channels
    if (
      prevAppContextLoggedIn !== userAuthenticated &&
      !userAuthenticated &&
      pusherConnection
    ) {
      Object.keys(pusherSubscribedChannelRegistry.current).forEach(
        (pusherChannelName) => {
          // unsubscribe from channel
          pusherConnection.unsubscribe(pusherChannelName);

          // delete channel from registry
          delete pusherSubscribedChannelRegistry.current[pusherChannelName];
        },
      );
    }
  }, [userAuthenticated, pusherConnection, prevAppContextLoggedIn]);

  // prepare registration callback for clients of this context to register a callback that should be
  // called when a notification of any of the given types are delivered on the given channel
  const registerNotificationCallback = useCallback(
    async (
      channel: NotificationChannel,
      notificationTypes: string[],
      callback: (n: Notification) => void,
    ) => {
      if (!pusherConnection) {
        throw new Error("pusher connection not yet initialised");
      }

      // convert given notification.Channel to pusherChannelName and prepare pusher channel pusherChannelSubscription
      const pusherChannelName = notificationChannelToPusherChannelName(channel);

      // if this channel has not yet been subscribed to then subscribe to it and add an entry
      // for it into the registry of subscribed pusher channels
      if (
        !Object.prototype.hasOwnProperty.call(
          pusherSubscribedChannelRegistry.current,
          pusherChannelName,
        )
      ) {
        pusherSubscribedChannelRegistry.current[pusherChannelName] = {
          channel: await new Promise<Channel>(
            (
              resolve: (result: Channel) => void,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              reject: (reason?: any) => void,
            ) => {
              // subscribe using the pusher connection to a particular channel
              const c = pusherConnection.subscribe(pusherChannelName);

              // TODO: reduce this later once db performance is optimised.
              //  reject the new subscription promise if the timeout happens after 30 seconds
              //  assume subscription has failed and reject the new subscription promise
              //  if subscription has still not succeeded after 30s.
              let timedOut = false;
              const timeoutRef = setTimeout(() => {
                timedOut = true;
                reject(c);
                Sentry.captureException(
                  "suspected pusher bug: timeout waiting for subscription outcome",
                );
              }, 30000);

              // bind callback to return the channel on successful subscription
              c.bind(PusherSubscriptionSucceededNotification, () => {
                if (timedOut) {
                  Sentry.captureException(
                    "suspected pusher bug: successful subscription after timeout",
                  );
                  return;
                }
                clearTimeout(timeoutRef);
                resolve(c);
              });

              // bind callback to throw an error on failed subscription
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              c.bind(PusherSubscriptionErrorNotification, (e: any) => {
                if (timedOut) {
                  Sentry.captureException(
                    "suspected pusher bug: failed subscription after timeout",
                  );
                  return;
                }
                clearTimeout(timeoutRef);
                console.error(
                  `error subscribing to pusher channel '${pusherChannelName}: ${
                    e.message ? e.message : e.toString()
                  }'`,
                );
                reject(ErrCouldNotSubscribeToNotificationsChannel);
              });
            },
          ),
          callbackRegistry: {},
        };
      }

      // prepare unique id for the given callback
      const callbackID = uuidV4();

      // for every notification type delivered on this channel that this callback should be called for
      notificationTypes.forEach((notificationType) => {
        // if there is not yet a callbacks registry for this notification type on this channel then initialise a registry
        // and a bind a callback for this notification type on this channel
        if (
          !Object.prototype.hasOwnProperty.call(
            pusherSubscribedChannelRegistry.current[pusherChannelName]
              .callbackRegistry,
            notificationType,
          )
        ) {
          // initialise registry
          pusherSubscribedChannelRegistry.current[
            pusherChannelName
          ].callbackRegistry[notificationType] = [];

          // bind callback
          pusherSubscribedChannelRegistry.current[
            pusherChannelName
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ].channel.bind(notificationType, (data: any) => {
            // parse notification when it is delivered
            let notification: Notification;
            try {
              notification = NewNotification(data as Notification);
            } catch (e) {
              console.error(`error constructing notification: ${e}`);
              return;
            }

            // and call given callback with notification
            try {
              // check if registry has been cleared for this pusherChannelName
              // (this happens if one of the registered callbacks de-registers)
              if (!pusherSubscribedChannelRegistry.current[pusherChannelName]) {
                return;
              }

              // call each callback
              pusherSubscribedChannelRegistry.current[
                pusherChannelName
              ].callbackRegistry[notificationType].forEach((regEntry) =>
                regEntry.callback(notification),
              );
            } catch (e) {
              console.error(`error invoking notification callback: ${e}`);
            }
          });
        }

        // add the callback to the registry
        pusherSubscribedChannelRegistry.current[
          pusherChannelName
        ].callbackRegistry[notificationType].push({
          id: callbackID,
          callback,
        });
      });

      // return function that can be called by client to remove their given callback
      // from all the places it was placed in the pusherChannelNameNotificationTypeCallbackRegistry
      return () => {
        // for every notification type that there exists a callback registry for on this subscribed channel
        // filter out all callbacks entries with a matching id
        Object.keys(
          pusherSubscribedChannelRegistry.current[pusherChannelName]
            .callbackRegistry,
        ).forEach((notificationType) => {
          if (
            Object.prototype.hasOwnProperty.call(
              pusherSubscribedChannelRegistry.current[pusherChannelName]
                .callbackRegistry,
              notificationType,
            )
          ) {
            // for a given notification type filter out any callbacks registered for this type on this channel with a matching id
            pusherSubscribedChannelRegistry.current[
              pusherChannelName
            ].callbackRegistry[notificationType] =
              pusherSubscribedChannelRegistry.current[
                pusherChannelName
              ].callbackRegistry[notificationType].filter(
                (regEntry) => regEntry.id !== callbackID,
              );

            // if there are no more callbacks in the registry for this type on the channel after filtering then remove the type registry
            if (
              !pusherSubscribedChannelRegistry.current[pusherChannelName]
                .callbackRegistry[notificationType].length
            ) {
              delete pusherSubscribedChannelRegistry.current[pusherChannelName]
                .callbackRegistry[notificationType];
            }
          }
        });

        // if the callback registry on this channel is empty once filtering is complete then unsubscribe the channel
        // and delete it from the registry of subscribed pusher channels
        if (
          !Object.keys(
            pusherSubscribedChannelRegistry.current[pusherChannelName]
              .callbackRegistry,
          ).length
        ) {
          pusherSubscribedChannelRegistry.current[
            pusherChannelName
          ].channel.unsubscribe();
          delete pusherSubscribedChannelRegistry.current[pusherChannelName];
        }
      };
    },
    [pusherConnection],
  );

  const notificationContextInitialised = useMemo(
    () => !!pusherConnection,
    [pusherConnection],
  );

  return (
    <Context.Provider
      value={{
        registerNotificationCallback,
        notificationContextInitialised,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useNotificationContext = () => useContext(Context);
export { useNotificationContext };

// notificationChannelToPusherChannel 'serializes' a given notification.Channel into a pusher
// channel (which is just a string of a particular format).
// Ref: https://pusher.com/docs/channels/using_channels/channels/
function notificationChannelToPusherChannelName(
  c: NotificationChannel,
): string {
  // set prefix if required
  let prefix = "";
  if (c.isPrivate()) {
    prefix = "private-";
  }

  // construct channel
  let channel = `${prefix}notification,${c.channelType()},${c.id()}`;

  // replace non-allowed characters
  [
    ["/", "_"],
    [":", ";"],
  ].forEach((pair) => {
    channel = channel.replace(RegExp(pair[0], "g"), pair[1]);
  });

  return channel;
}
