import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./theme";

export function MuiThemeContext({ children }: { children: React.ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
