import { Token } from "james/ledger";
import { Entry } from "../audit/Entry";
import { Mechanism, MechanismType, QuoteParameter } from "./Mechanism";
import { LedgerNetwork } from "james/ledger/Network";

const ListingTypeName = "mesh::market/Listing";

export enum ListingState {
  Active = "Active",
  Inactive = "Inactive",
  ListingInProgress = "Listing in Progress",
  ListingFailed = "Listing Failed",
  ListingUnderInvestigation = "Listing Under Investigation",
}

export const AllListingStates: ListingState[] = [
  ListingState.Active,
  ListingState.Inactive,
  ListingState.ListingInProgress,
  ListingState.ListingFailed,
  ListingState.ListingUnderInvestigation,
];

export class Listing {
  public ["@type"]: string = ListingTypeName;
  public id = "";
  public ownerID = "";
  public token: Token = new Token();
  public state: ListingState | "" = "";
  public lastActionAnnotation = "";
  public marketMechanisms: Mechanism[] = [];
  public exchangeNetwork: LedgerNetwork = "-";
  public auditEntry: Entry = new Entry();

  constructor(l?: Listing) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.ownerID = l.ownerID;
    this.token = new Token(l.token);
    this.state = l.state;
    this.lastActionAnnotation = l.lastActionAnnotation;
    this.exchangeNetwork = l.exchangeNetwork;
    this.marketMechanisms = l.marketMechanisms.map((m) => new Mechanism(m));
    this.auditEntry = new Entry(l.auditEntry);
  }

  getMarketMechanismQuoteParameter(
    mechanismType: MechanismType,
    quoteToken: Token,
  ): QuoteParameter {
    // find given mechanism type
    for (const marketMechanism of this.marketMechanisms) {
      if (marketMechanism.type === mechanismType) {
        // find quote parameter for given quote token
        for (const quoteParameter of marketMechanism.quoteParameters) {
          if (quoteParameter.quoteToken.isEqualTo(quoteToken)) {
            return quoteParameter;
          }
        }

        // matching quote parameter not found
        throw new Error(`quote token '${quoteToken.string()}' not supported`);
      }
    }

    // matching market mechanism not found
    throw new Error(`market mechanism ${mechanismType} not supported`);
  }
}
