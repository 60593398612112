import {
  AnnualPerformanceLogSmartInstrumentAttribute,
  SmartInstrumentAnnualPerformanceLogEntry,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeAnnualPerformanceLog_pb";
import { Box, InputAdornment, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { ValidationResult } from "common/validation";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import dayjs from "dayjs";
import range from "lodash/range";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { TextNumField } from "@mesh/common-js-react/dist/FormFields";

export type AnnualPerformanceLogAttributeFormProps = {
  startDate: Timestamp;
  annualPerformanceLogAttribute: AnnualPerformanceLogSmartInstrumentAttribute;
  onChange: (
    updatedAnnualPerformanceLogAttribute: AnnualPerformanceLogSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

const shortMonths = dayjs.monthsShort();

export const AnnualPerformanceLogAttributeForm = (
  props: AnnualPerformanceLogAttributeFormProps,
) => {
  // convert given start date to dayjs
  const startDate = useMemo(
    () => protobufTimestampToDayjs(props.startDate),
    [props.startDate],
  );

  // uncomment the following if validation is required - atm. no validation required
  // const fieldValidationResult = (field: string) => {
  //   return props.formDataValidationResult.fieldValidations[
  //     `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
  //   ];
  // };

  // prepare a year range
  const years = useMemo(
    () => range(startDate.year(), dayjs().year() + 1),
    [startDate],
  );

  // initialise incoming performance log
  const annualPerformanceLog = useMemo(() => {
    // get now
    const now = dayjs();

    // prepare a new performance log
    let newAnnualPerformanceLog: SmartInstrumentAnnualPerformanceLogEntry[] =
      [];

    // process given performance log
    years.forEach((year) => {
      // for every required year
      // look for an associated performance log entry
      let ple = props.annualPerformanceLogAttribute
        .getEntriesList()
        .find((p) => p.getYear() === year);
      if (!ple) {
        // if not found then add in
        ple = new SmartInstrumentAnnualPerformanceLogEntry().setYear(year);
      }

      // ensure no months in the future have a value if the entry is for this year
      if (ple.getYear() === now.year()) {
        for (const month of ple.getMonthlyperformanceMap().keys()) {
          if (month >= now.month() + 1) {
            ple.getMonthlyperformanceMap().del(month);
          }
        }
      }

      // add to the new annual performance log
      newAnnualPerformanceLog.push(ple);
    });

    // sort in descending order
    newAnnualPerformanceLog = newAnnualPerformanceLog.sort((x, y) => {
      if (x.getYear() > y.getYear()) {
        return -1;
      }
      if (x.getYear() < y.getYear()) {
        return 1;
      }
      return 0;
    });

    return newAnnualPerformanceLog;
  }, [years]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
      }}
    >
      {/* Table Layout */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "60px repeat(13, 90px)",
          alignItems: "center",
        }}
      >
        {/* Table Header */}
        <Box />
        {range(0, 12).map((monthIdx) => (
          <Typography
            key={monthIdx}
            variant="body1"
            sx={(theme) => ({
              justifySelf: "center",
              border: `solid 1px ${theme.palette.divider}`,
              width: "80px",
            })}
            children={shortMonths[monthIdx]}
          />
        ))}
        <Typography
          variant="body1"
          sx={(theme) => ({
            justifySelf: "center",
            border: `solid 1px ${theme.palette.divider}`,
            width: "80px",
          })}
          children="Annual"
        />
      </Box>

      {/* Table Body */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "60px repeat(13, 90px)",
          alignItems: "center",
          maxHeight: 565,
          overflowX: "hidden",
          overflowY: "auto",
        }}
        className={"meshScroll"}
      >
        {/* For every performance log entry add a row ... */}
        {annualPerformanceLog.map((pl, idx) => {
          // get now
          const now = dayjs();

          // the year cannot have annual performance entered if
          const yearHasNoAnnualPerformance =
            // it is before the year that the related instrument was issued
            pl.getYear() < startDate.year();

          return (
            <React.Fragment key={idx}>
              <Typography
                color="textSecondary"
                variant="body1"
                id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${idx}`}
                sx={{ justifySelf: "center" }}
                children={+pl.getYear() ? pl.getYear() : "-"}
              />
              {range(0, 12).map((monthIdx) => {
                // get the first day of this month idx
                const firstDayOfMonth = dayjs()
                  .year(pl.getYear())
                  .month(monthIdx)
                  .day(1);

                // no returns can be submitted for this month if
                const noReturnForMonth =
                  // the first day of this month is in the future
                  firstDayOfMonth.isAfter(now) || // OR
                  // the first day of this month is in a month before the
                  // issue date of the related instrument
                  firstDayOfMonth.isBefore(startDate, "month") || // OR
                  // the end of the month is still in the future
                  firstDayOfMonth.endOf("month").isAfter(now);

                return props.readOnly ? (
                  // View Mode
                  <Typography
                    key={monthIdx}
                    variant="body1"
                    color="textSecondary"
                    id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${monthIdx}-${idx}`}
                    sx={{ justifySelf: "center" }}
                    children={
                      pl.getMonthlyperformanceMap().get(monthIdx + 1)
                        ? `${decimalToBigNumber(pl.getMonthlyperformanceMap().get(monthIdx + 1) ?? new Decimal())}%`
                        : "-"
                    }
                  />
                ) : // NOT viewMode - i.e. Edit mode
                noReturnForMonth ? (
                  <Box key={monthIdx} />
                ) : (
                  <TextNumField
                    key={monthIdx}
                    disabled={props.disabled}
                    variant="outlined"
                    fullWidth
                    id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${monthIdx}-${idx}`}
                    noDecimalPlaces={2}
                    InputProps={{
                      sx: {
                        // border: props.fieldValidations[
                        //   `annualPerformanceLog-${idx}-year-${
                        //     monthIdx + 1
                        //   }-month`
                        // ]
                        //   ? `1px solid ${theme.palette.error.main}`
                        //   : "",
                        borderRadius: 0,
                      },
                      endAdornment: (
                        <InputAdornment position="end" children="%" />
                      ),
                    }}
                    value={
                      annualPerformanceLog[idx]
                        .getMonthlyperformanceMap()
                        .get(monthIdx + 1) ?? new Decimal()
                    }
                    onChange={(newValue) => {
                      annualPerformanceLog[idx]
                        .getMonthlyperformanceMap()
                        .set(monthIdx + 1, newValue);
                      props.onChange(
                        props.annualPerformanceLogAttribute.setEntriesList(
                          annualPerformanceLog,
                        ),
                      );
                    }}
                  />
                );
              })}
              {props.readOnly ? (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-textField-${idx}`}
                  children={
                    pl.getAnnualperformance()
                      ? `${decimalToBigNumber(pl.getAnnualperformance() ?? new Decimal())}%`
                      : "-"
                  }
                />
              ) : yearHasNoAnnualPerformance ? (
                <Box />
              ) : (
                <TextNumField
                  variant="outlined"
                  fullWidth
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-textField-${idx}`}
                  disabled={props.disabled}
                  InputProps={{
                    sx: { borderRadius: 0 },
                    endAdornment: (
                      <InputAdornment position="end" children="%" />
                    ),
                  }}
                  value={annualPerformanceLog[idx].getAnnualperformance()}
                  onChange={(e) => {
                    annualPerformanceLog[idx] =
                      annualPerformanceLog[idx].setAnnualperformance(e);
                    props.onChange(
                      props.annualPerformanceLogAttribute.setEntriesList(
                        annualPerformanceLog,
                      ),
                    );
                  }}
                />
              )}
              {!!props.formDataValidationResult.fieldValidations[
                `annualPerformanceLog-${idx}-year`
              ] && (
                <Typography
                  color="error"
                  variant="body2"
                  id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-helperText-${idx}`}
                  sx={{
                    gridColumn: "1/15",
                  }}
                  // children={
                  //   props.fieldValidations[`annualPerformanceLog-${idx}-year`]
                  // }
                  children={"error!"}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
