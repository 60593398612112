import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export interface GenerateIndividualClientUserRegistrationTokenRequest {
  firebaseUserIDToken: string;
}

export interface GenerateIndividualClientUserRegistrationTokenResponse {
  token: string;
}

export const IndividualClientUserRegistrationControllerServiceProviderName =
  "user-IndividualClientUserRegistrationController";

export const IndividualClientUserRegistrationController = {
  async GenerateIndividualClientUserRegistrationToken(
    request: GenerateIndividualClientUserRegistrationTokenRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateIndividualClientUserRegistrationTokenResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshPublicURL"),
        method: `${IndividualClientUserRegistrationControllerServiceProviderName}.GenerateIndividualClientUserRegistrationToken`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { token: response.token };
  },
};
