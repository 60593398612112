import React from "react";
import { RouteType } from "routes/Route";
import { Router } from "routes";
import { Table } from "./Table";

const transactionViewRoutes: RouteType[] = [
  {
    name: "Table",
    id: "table",
    path: "/market-management/assets/fiat/table",
    component: <Table />,
  },
];

function determineAvailableRoutes(): RouteType[] {
  return transactionViewRoutes;
}

export function Fiat() {
  const availableRoutes = determineAvailableRoutes();

  return (
    <Router
      baseURL={"/market-management/assets/fiat"}
      redirectPath={"/market-management/assets/fiat/table"}
      routes={availableRoutes}
    />
  );
}
