import { Box, Skeleton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AllBankAccountTypes } from "james/banking";
import { LinkedBankAccountCard } from "./components/LinkedBankAccountCard";
import { AccountsContext } from "views/Accounts/Accounts";

export function BankAccountsList() {
  const { bankAccounts } = useContext(AccountsContext);

  if (!bankAccounts) {
    return (
      <>
        <Skeleton width="100%" height={65} />
        <Skeleton width="100%" height={65} />
      </>
    );
  }

  return (
    <>
      {(() => {
        const elements: JSX.Element[] = [];
        AllBankAccountTypes.forEach((bankAccountType, idx) => {
          const filteredBankAccount = bankAccounts.filter(
            (v) => v.accountType === bankAccountType,
          );

          if (filteredBankAccount.length !== 0) {
            elements.push(
              <Typography
                key={idx}
                sx={(theme) => ({ margin: theme.spacing(2, 0, 3, 0) })}
                variant="h6"
              >
                {bankAccountType}
              </Typography>,
            );
            filteredBankAccount.forEach((b) => {
              elements.push(
                <Box
                  key={b.id}
                  sx={(theme) => ({ margin: theme.spacing(0, 0, 1, 0) })}
                >
                  <LinkedBankAccountCard bankAccount={b} />
                </Box>,
              );
            });
          }
        });
        return elements;
      })()}
    </>
  );
}
