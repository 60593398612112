import { Context } from "james/security";
import { ViewConfiguration } from "james/configuration";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

interface GetMyViewConfigurationRequest {
  context: Context;
}

interface GetMyViewConfigurationResponse {
  viewConfiguration: ViewConfiguration;
}

export const Manager = {
  serviceProvider: "configuration-Manager",
  async GetMyViewConfiguration(
    request: GetMyViewConfigurationRequest,
  ): Promise<GetMyViewConfigurationResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${Manager.serviceProvider}.GetMyViewConfiguration`,
      request,
    });
  },
};
