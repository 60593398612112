import {
  CancelLimitOrderRequest,
  SubmitLimitOrderRequest,
} from "@mesh/common-js/dist/market/limitOrderStateController_pb";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useExchangeDashboardStore } from "../store";
import { useExchangeStore } from "../../../store";
import { useErrorContext } from "context/Error";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { useSnackbar } from "notistack";
import {
  BadAuthorisationTransactionFailureCode,
  IncorrectAccountConfigurationTransactionFailureCode,
  InsufficientBalanceTransactionFailureCode,
  OfferNotFound,
  SelfCross,
} from "james/ledger";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";

export const useLimitOrderController = () => {
  const { authContext } = useApplicationContext();
  const exchangeStore = useExchangeStore();
  const store = useExchangeDashboardStore();
  const { market } = useAPIContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();
  const { enqueueSnackbar } = useSnackbar();

  const submitLimitOrder = async () => {
    let limitOrderSubmitTransactionID: string;
    store.updateTradeCardState.setLoading(true);
    enqueueSnackbar("Submission in Progress", {
      variant: "info",
    });
    try {
      const request = new SubmitLimitOrderRequest()
        .setAmount(store.tradeCardState.amount)
        .setContext(authContext.toFuture())
        .setListingid(exchangeStore.selectedMarketListing?.listingID ?? "")
        .setPrice(store.tradeCardState.price)
        .setSourceaccountid(store.tradeCardState.sourceAccount.id)
        .setType(store.tradeCardState.cardOption);

      limitOrderSubmitTransactionID = (
        await market.limitOrderStateControllerPromiseClient.submitLimitOrder(
          request,
        )
      ).getTransactionid();
      try {
        // register callback to fire once the deposit has been submitted
        const deregister = await registerNotificationCallback(
          new TransactionNotificationChannel({
            transactionID: limitOrderSubmitTransactionID,
            private: true,
          }),
          [
            TransactionSucceededNotificationTypeName,
            TransactionFailedNotificationTypeName,
            TransactionSubmissionResolutionFailedNotificationTypeName,
          ],
          (n: Notification) => {
            if (n instanceof TransactionSucceededNotification) {
              enqueueSnackbar("Success! Submission Complete", {
                variant: "success",
              });
            }

            if (n instanceof TransactionFailedNotification) {
              const errorMessage = n.codes
                .map((code) => {
                  switch (code) {
                    case InsufficientBalanceTransactionFailureCode:
                      return "Insufficient balance";
                    case SelfCross:
                      return "Invalid price level - cannot cross your own order";
                    case BadAuthorisationTransactionFailureCode:
                      return "Unauthorized - Insufficient signatures";
                    case IncorrectAccountConfigurationTransactionFailureCode:
                      return "Account does not trust asset being transferred";
                    case OfferNotFound:
                      return "Offer not found";
                    default:
                      return "Please try again";
                  }
                })
                .join(", ");
              enqueueSnackbar("Error! Submit failed: " + errorMessage, {
                variant: "error",
              });
            }

            if (
              n instanceof TransactionSubmissionResolutionFailedNotification
            ) {
              enqueueSnackbar(
                "Warning! Something has gone wrong with the submission and its status is being investigated",
                { variant: "warning" },
              );
            }
            deregister();
          },
        );
      } catch (e) {
        console.error(
          "error registering for submission transaction notifications",
          e,
        );
        enqueueSnackbar(
          "Warning! Unable to Register for Notifications on Submission Transaction - Please Refresh to Monitor.",
          { variant: "warning" },
        );
      }

      store.updateTradeCardState.setLoading(false);
    } catch (err) {
      store.updateTradeCardState.setLoading(false);
      enqueueSnackbar(`Error Performing Submission - Please Try Again`, {
        variant: "error",
      });
      errorContextDefaultErrorFeedback(err);
    }
  };

  const cancelLimitOrder = async (model: Model) => {
    let limitOrderCancelTransactionID: string;
    try {
      store.updateOpenOrdersState.setCancelingOrder(true);
      enqueueSnackbar("Cancellation in Progress", {
        variant: "info",
      });
      limitOrderCancelTransactionID = (
        await market.limitOrderStateControllerPromiseClient.cancelLimitOrder(
          new CancelLimitOrderRequest()
            .setContext(authContext.toFuture())
            .setLimitorderid(model.getLimitorderid())
            .setSourceaccountid(store.tradeCardState.sourceAccount.id),
        )
      ).getTransactionid();
      try {
        // register callback to fire once the deposit has been submitted
        const deregister = await registerNotificationCallback(
          new TransactionNotificationChannel({
            transactionID: limitOrderCancelTransactionID,
            private: true,
          }),
          [
            TransactionSucceededNotificationTypeName,
            TransactionFailedNotificationTypeName,
            TransactionSubmissionResolutionFailedNotificationTypeName,
          ],
          (n: Notification) => {
            if (n instanceof TransactionSucceededNotification) {
              enqueueSnackbar("Success! Cancellation Complete", {
                variant: "success",
              });
            }

            if (n instanceof TransactionFailedNotification) {
              const errorMessage = n.codes
                .map((code) => {
                  switch (code) {
                    case InsufficientBalanceTransactionFailureCode:
                      return "Insufficient Balance";
                    case BadAuthorisationTransactionFailureCode:
                      return "Unauthorized - Insufficient Signatures";
                    case IncorrectAccountConfigurationTransactionFailureCode:
                      return "Account does not trust asset being transferred";
                    case OfferNotFound:
                      return "Offer Not Found";
                    default:
                      return "Something Went Wrong";
                  }
                })
                .join(", ");
              enqueueSnackbar("Error! Cancellation Failed: " + errorMessage, {
                variant: "error",
              });
            }

            if (
              n instanceof TransactionSubmissionResolutionFailedNotification
            ) {
              enqueueSnackbar(
                "Warning! Something has gone wrong with the cancellation and its status is being investigated",
                { variant: "warning" },
              );
            }
            deregister();
          },
        );
      } catch (e) {
        console.error(
          "error registering for cancellation transaction notifications",
          e,
        );
        enqueueSnackbar(
          "Warning! Unable to Register for Notifications on Cancellation Transaction - Please Refresh to Monitor.",
          { variant: "warning" },
        );
      }
      store.updateOpenOrdersState.setCancelingOrder(false);
    } catch (e) {
      store.updateOpenOrdersState.setCancelingOrder(false);
      errorContextDefaultErrorFeedback(e);
    }
  };

  return {
    submitLimitOrder,
    cancelLimitOrder,
  };
};
