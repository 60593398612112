import { BuilderContextProvider } from "./Context";
import { Layout } from "./Layout";

export type BuilderProps = {
  system: boolean;
};

export const Builder = (props: BuilderProps) => {
  return (
    <BuilderContextProvider system={props.system}>
      <Layout system={props.system} />
    </BuilderContextProvider>
  );
};
