import React, { ReactNode, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSearchTransactions } from "consistency/consistency/TransactionRepository";
import { BPTable } from "components/Table";
import { NewSorting, Query } from "james/search/query";
import dayjs from "dayjs";
import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  FileCopy as CopyPasteIcon,
  FilterList as FilterByIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { ConsistencyTransaction } from "consistency/consistency";
import { useNavigate } from "react-router-dom";
import {
  DateRangeCriterion,
  TextListCriterion,
  TextNINListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  AllTransactionStates,
  TransactionState,
} from "consistency/consistency/Transaction";
import { useSnackbar } from "notistack";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { DateField } from "components/FormFields";
import { ReadOperation } from "consistency/consistency/ReadOperation";
import { WriteOperation } from "consistency/consistency/WriteOperation";

import { dateIsValid } from "utilities/date/dateIsValid";
import MultiRowInfoIcon from "../Dashboard/MultiRowInfoIcon";
import { useApplicationContext } from "context/Application/Application";

const PREFIX = "Table";

const classes = {
  autoRefreshSwitchLayout: `${PREFIX}-autoRefreshSwitchLayout`,
  autoRefreshCircularLoaderWrapper: `${PREFIX}-autoRefreshCircularLoaderWrapper`,
  clickableText: `${PREFIX}-clickableText`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  row: `${PREFIX}-row`,
  copyPasteIcon: `${PREFIX}-copyPasteIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.autoRefreshSwitchLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    border: `solid 1px ${theme.palette.divider}`,
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.autoRefreshCircularLoaderWrapper}`]: {
    width: 30,
  },

  [`& .${classes.clickableText}`]: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.light,
    },
    cursor: "pointer",
  },

  [`& .${classes.textSearchField}`]: {
    width: 400,
  },

  [`& .${classes.dateFilterField}`]: {
    width: 180,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
  },

  [`& .${classes.copyPasteIcon}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("auditEntry.time", "desc")],
});

export function Table() {
  const { authContext } = useApplicationContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    searchTransactionsResponse,
    searchTransactionsRequest,
    setSearchTransactionsRequest,
    loading: searchTransactionsLoading,
  } = useSearchTransactions({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });

  const [selectedTransactions, setSelectedTransactions] = useState<
    ConsistencyTransaction[]
  >([]);
  const [autoRefresh, setAutoRefresh] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { id: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            originatingLocation: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
          {
            originatingProcess: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  const [stateInclCriterion, setStateInclCriterion] = useState<
    TransactionState[]
  >([]);
  const [stateNInCriterion, setStateNInCriterion] = useState<
    TransactionState[]
  >([]);
  const [dateTimeCriterionFrom, setDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [dateTimeCriterionTo, setDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (stateInclCriterion.length) {
      criteria = {
        ...criteria,
        state: TextListCriterion(stateInclCriterion),
      };
    }

    if (stateNInCriterion.length) {
      criteria = {
        ...criteria,
        state: TextNINListCriterion(stateNInCriterion),
      };
    }

    if (dateTimeCriterionFrom || dateTimeCriterionTo) {
      criteria["auditEntry.time"] = DateRangeCriterion(
        dateTimeCriterionFrom,
        dateTimeCriterionTo,
      );
    }

    setSearchTransactionsRequest({
      context: searchTransactionsRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    stateInclCriterion,
    stateNInCriterion,
    textSearchCriterion,
    searchTransactionsRequest.context,
    setSearchTransactionsRequest,
    authContext,
    dateTimeCriterionFrom,
    dateTimeCriterionTo,
  ]);

  useEffect(() => {
    if (autoRefresh) {
      const timeOut = setTimeout(
        () =>
          setSearchTransactionsRequest({
            ...searchTransactionsRequest,
            query: new Query(initialQuery),
          }),
        1000,
      );
      return () => clearTimeout(timeOut);
    }
  }, [autoRefresh, searchTransactionsRequest, setSearchTransactionsRequest]);

  return (
    <BPTable
      height={window.innerHeight - 138}
      title="Transactions"
      loading={autoRefresh ? undefined : searchTransactionsLoading}
      data={searchTransactionsResponse.records}
      totalNoRecords={searchTransactionsResponse.total}
      onSelectedDataChange={(selectedData) =>
        setSelectedTransactions(selectedData as ConsistencyTransaction[])
      }
      query={searchTransactionsRequest.query}
      onQueryChange={(query) =>
        setSearchTransactionsRequest({
          ...searchTransactionsRequest,
          query,
        })
      }
      toolBarControls={(() => {
        const controlsToRender: ReactNode[] = [];
        if (selectedTransactions.length === 1) {
          controlsToRender.push(
            <Button
              variant="contained"
              color="secondary"
              children="View"
              onClick={() =>
                navigate(
                  `/consistency/transactions/view?id=${selectedTransactions[0].id}`,
                )
              }
            />,
          );
        }

        controlsToRender.push(
          <Root className={classes.autoRefreshSwitchLayout}>
            <Switch
              color="primary"
              checked={autoRefresh}
              onChange={(e) => setAutoRefresh(e.target.checked)}
            />
            <Typography children="Stream" variant="body2" />
            {autoRefresh && (
              <div className={classes.autoRefreshCircularLoaderWrapper}>
                {searchTransactionsLoading && autoRefresh && (
                  <CircularProgress size={20} />
                )}
              </div>
            )}
          </Root>,
        );

        if (!autoRefresh) {
          controlsToRender.push(
            <Tooltip title="Reload">
              <span>
                <IconButton
                  size="small"
                  onClick={() =>
                    setSearchTransactionsRequest({
                      ...searchTransactionsRequest,
                      query: new Query(initialQuery),
                    })
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );
        }

        return controlsToRender;
      })()}
      filters={[
        <TextField
          variant="outlined"
          margin="dense"
          className={classes.textSearchField}
          label="Search Text Fields"
          placeholder="Start Typing..."
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterionTextField ? (
              <InputAdornment
                position="end"
                children={
                  <IconButton
                    size="small"
                    onClick={() => setTextSearchCriterionTextField("")}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterionTextField}
          onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
        />,
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          multiple
          options={AllTransactionStates}
          filterSelectedOptions
          onChange={(
            _: React.SyntheticEvent<Element, Event>,
            value: TransactionState[],
          ) => setStateInclCriterion(value)}
          ChipProps={{
            color: "info",
            size: "small",
            deleteIcon: (
              <CancelIcon
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textSearchField}
              label="In State"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              placeholder={stateInclCriterion.length ? undefined : "Select..."}
            />
          )}
        />,
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          multiple
          options={AllTransactionStates}
          filterSelectedOptions
          onChange={(
            _: React.SyntheticEvent<Element, Event>,
            value: TransactionState[],
          ) => setStateNInCriterion(value)}
          ChipProps={{
            color: "info",
            size: "small",
            deleteIcon: (
              <CancelIcon
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textSearchField}
              label="NOT In State"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              placeholder={stateNInCriterion.length ? undefined : "Select..."}
            />
          )}
        />,
        <DateField
          label="From"
          id="consistencyTransactionTable-orderDateTimeFrom-dateField"
          className={classes.dateFilterField}
          value={dateTimeCriterionFrom ? dateTimeCriterionFrom.date : null}
          onChange={(newValue) => {
            if (!(newValue && dateIsValid(newValue))) {
              setDateTimeCriterionFrom(undefined);
            } else {
              setDateTimeCriterionFrom(
                newValue
                  ? {
                      date: newValue.startOf("day").format(),
                      inclusive: true,
                      ignore: false,
                    }
                  : undefined,
              );
            }
          }}
          renderInput={(textFieldProps: TextFieldProps) => (
            <TextField
              {...textFieldProps}
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (() => (
                  <>
                    {dateTimeCriterionFrom && (
                      <Tooltip title="Clear" placement="top">
                        <IconButton
                          className={classes.copyPasteIcon}
                          size="small"
                          onClick={() => setDateTimeCriterionFrom(undefined)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {textFieldProps.InputProps &&
                    textFieldProps.InputProps.endAdornment
                      ? textFieldProps.InputProps.endAdornment
                      : null}
                  </>
                ))(),
              }}
            />
          )}
        />,
        <DateField
          label="To"
          id="consistencyTransactionTable-orderDateTimeTo-dateField"
          className={classes.dateFilterField}
          value={dateTimeCriterionTo ? dateTimeCriterionTo.date : null}
          onChange={(newValue) => {
            if (!(newValue && dateIsValid(newValue))) {
              setDateTimeCriterionTo(undefined);
            } else {
              setDateTimeCriterionTo(
                newValue
                  ? {
                      date: newValue.endOf("day").format(),
                      inclusive: true,
                      ignore: false,
                    }
                  : undefined,
              );
            }
          }}
          renderInput={(textFieldProps: TextFieldProps) => (
            <TextField
              {...textFieldProps}
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (() => (
                  <>
                    {dateTimeCriterionTo && (
                      <Tooltip title="Clear" placement="top">
                        <IconButton
                          className={classes.copyPasteIcon}
                          size="small"
                          onClick={() => {
                            setDateTimeCriterionTo(undefined);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {textFieldProps.InputProps &&
                    textFieldProps.InputProps.endAdornment
                      ? textFieldProps.InputProps.endAdornment
                      : null}
                  </>
                ))(),
              }}
            />
          )}
        />,
      ]}
      columns={[
        {
          field: "",
          label: "",
          sortable: false,
          accessor: (data) => (
            <Tooltip placement="top" title="View detail in new tab">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `${
                      window.location.origin
                    }/consistency/transactions/view?id=${
                      (data as ConsistencyTransaction).id
                    }`,
                    "_blank",
                  );
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          ),
        },
        {
          field: "id",
          label: "ID",
          minWidth: 280,
          accessor: (data) => {
            const txn = data as ConsistencyTransaction;
            return (
              <div className={classes.row}>
                <Typography variant="body1" children={txn.id} />
                <CopyPasteIcon
                  className={classes.copyPasteIcon}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(txn.id)
                      .then(() => enqueueSnackbar("Transaction ID copied"))
                  }
                />
              </div>
            );
          },
        },
        {
          field: "state",
          label: "State",
          minWidth: 140,
          accessor: (data) => {
            const txn = data as ConsistencyTransaction;
            return (
              <div className={classes.row}>
                <Typography variant="body1" children={txn.state} />
                <MultiRowInfoIcon
                  invertColors
                  title={txn.lastActionAnnotation}
                />
              </div>
            );
          },
        },
        {
          field: "auditEntry.time",
          label: "Last Modified",
          minWidth: 160,
          accessor: (data) =>
            dayjs((data as ConsistencyTransaction).auditEntry.time).format(
              "DD/MM/YYYY HH:mm:ss",
            ),
        },
        {
          field: "writeOperationState",
          label: "Write Op State",
          sortable: false,
          minWidth: 155,
          accessor: (data) => {
            const txn = data as ConsistencyTransaction;
            const [writeOperationState, lastModified] =
              txn.writeOperationsState();
            return `${writeOperationState} @ ${lastModified.format(
              "DD/MM/YYYY HH:mm:ss",
            )}`;
          },
        },
        {
          field: "duration",
          label: "Duration",
          sortable: false,
          minWidth: 80,
          accessor: (data) => {
            const transaction = data as ConsistencyTransaction;
            const [, lastModified] = transaction.writeOperationsState();
            let diff: number;
            if (
              transaction.state === TransactionState.RolledBack ||
              transaction.state === TransactionState.Complete
            ) {
              diff = dayjs(transaction.auditEntry.time).diff(
                dayjs(transaction.createdAt),
                "seconds",
                true,
              );
            } else {
              diff = lastModified.diff(
                dayjs(transaction.createdAt),
                "seconds",
                true,
              );
            }
            return `${diff}s`;
          },
        },
        {
          field: "noOperations",
          label: "#Ops",
          sortable: false,
          minWidth: 60,
          accessor: (data) =>
            (data as ConsistencyTransaction).operations.length,
        },
        {
          field: "noOperations",
          label: "#R-Ops",
          sortable: false,
          minWidth: 72,
          accessor: (data) =>
            (data as ConsistencyTransaction).operations.filter(
              (o) => o instanceof ReadOperation,
            ).length,
        },
        {
          field: "noOperations",
          label: "#W-Ops",
          sortable: false,
          minWidth: 72,
          accessor: (data) =>
            (data as ConsistencyTransaction).operations.filter(
              (o) => o instanceof WriteOperation,
            ).length,
        },
        {
          field: "noAfterEffects",
          label: "#AEs",
          sortable: false,
          minWidth: 65,
          accessor: (data) =>
            (data as ConsistencyTransaction).afterEffects.length,
        },
        {
          field: "originatingProcess",
          label: "Process",
          minWidth: 400,
          accessor: (data) => {
            let processed = "";
            for (const c of (data as ConsistencyTransaction)
              .originatingProcess) {
              processed += c;
              if (c === "/") {
                processed += " ";
              }
            }

            return (
              <div className={classes.row}>
                <Tooltip title="filter for this process">
                  <FilterByIcon
                    className={classes.copyPasteIcon}
                    onClick={() =>
                      setTextSearchCriterionTextField(
                        (data as ConsistencyTransaction).originatingProcess,
                      )
                    }
                  />
                </Tooltip>
                <Typography variant="body1" children={processed} />
              </div>
            );
          },
        },
        {
          field: "originatingLocation",
          label: "Originating Location",
          minWidth: 400,
          accessor: (data) => {
            let processed = "";
            for (const c of (data as ConsistencyTransaction)
              .originatingLocation) {
              processed += c;
              if (c === "/") {
                processed += " ";
              }
            }

            return processed;
          },
        },
      ]}
    />
  );
}
