import { DownloadableDocument } from "james/document/DownloadableDocument";
import { DataSheetSection } from "./DataSheetSection";

export const AssetDocumentsDataSheetSectionTypeName =
  "mesh::marketData/AssetDocuments";

export const AssetDocumentsDataSheetSectionName = "Documents";

export class AssetDocumentsDataSheetSection implements DataSheetSection {
  public ["@type"] = AssetDocumentsDataSheetSectionTypeName;

  public documents: DownloadableDocument[] = [];

  public DataSheetSectionName(): string {
    return AssetDocumentsDataSheetSectionName;
  }

  constructor(assetDocuments?: AssetDocumentsDataSheetSection) {
    if (!assetDocuments) {
      return;
    }

    this.documents = assetDocuments.documents.map(
      (v) => new DownloadableDocument(v),
    );
  }
}
