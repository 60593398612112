import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BigNumber from "bignumber.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import {
  MarketSubscriptionOrderBookSettlementViewReader,
  MarketSubscriptionOrderBookSettlementViewUnscopedReader,
  MarketSubscriptionOrderBookSettlementViewModel,
} from "james/views/marketSubscriptionOrderBookSettlementView";
import { TextExactCriterion } from "james/search/criterion";
import { TransactionSignatureController } from "james/stellar/TransactionSignatureController";
import { SubscriptionOrderBookStateChip } from "components/MarketSubscriptions/Chips";
import {
  SubscriptionOrderBook,
  SubscriptionOrderBookState,
} from "james/market/SubscriptionOrderBook";
import { LedgerAccountCategory, TransactionState } from "james/ledger";
import { Amount } from "components/Ledger/Amount";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import { SubscriptionUnderwritingTable } from "components/MarketSubscriptions";
import { SubscriptionOrderTable } from "components/MarketSubscriptions/SubscriptionOrderTable";
import {
  TransactionSucceededNotificationTypeName,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionFailedNotification,
  TransactionSubmissionResolutionFailedNotification,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification/Notification";
import { Notification } from "james/notification/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import { enqueueSnackbar } from "notistack";
import { useIsMounted } from "hooks";
import { CancellationConfirmationDialog } from "./components";
import { Refresh as ReloadIcon } from "@mui/icons-material";
import { useStellarAccountContext } from "context/Account/StellarAccount";
import {
  CategoryOwnerIDNetworkIdentifier,
  LedgerIDIdentifier,
} from "james/search/identifier";
import { UnderwritingManagementDialog } from "./components/UnderwritingManagementDialog";

export type Props = {
  system: boolean;
  assetName: string;
  subscriptionOrderBook: SubscriptionOrderBook;
  settlementTransactionID?: string;
  settlementTransactionState?: TransactionState | "";
  reload: () => void;
  underWriterView?: boolean;
};

export const SubscriptionOrderBookDetail = (props: Props) => {
  const isMounted = useIsMounted();
  const {
    checkUserSignatoryOnAccount,
    loading: stellarAccountContextLoading,
    accounts,
  } = useStellarAccountContext();
  const { registerNotificationCallback } = useNotificationContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);
  const [showUnderwriterManagementDialog, setShowUnderwriterManagementDialog] =
    useState(false);

  const [reloadToggle, setReloadToggle] = useState(false);
  const reload = () => {
    setReloadToggle((t) => !t);
    props.reload();
  };

  const [issuanceAccSignatory, setIssuanceAccSignatory] = useState(false);
  const [tradingAccSignatory, setTradingAccSignatory] = useState(false);
  useEffect(() => {
    (async () => {
      if (stellarAccountContextLoading || !accounts.length) {
        return;
      }
      try {
        setIssuanceAccSignatory(
          await checkUserSignatoryOnAccount(
            LedgerIDIdentifier(props.subscriptionOrderBook.token.issuer),
          ),
        );
        setTradingAccSignatory(
          await checkUserSignatoryOnAccount(
            CategoryOwnerIDNetworkIdentifier(
              LedgerAccountCategory.Trading,
              props.subscriptionOrderBook.ownerID,
              props.subscriptionOrderBook.token.network,
            ),
          ),
        );
      } catch (e) {
        console.error("error determing signatory status", e);
      }
    })();
  }, [stellarAccountContextLoading]);

  const [settlementModel, setSettlementModel] = useState<
    MarketSubscriptionOrderBookSettlementViewModel | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      if (props.underWriterView) return;

      // if book not yet set then do nothing
      if (props.subscriptionOrderBook.id === "") {
        return;
      }

      // if model already set and is for the current book token then do nothing
      if (
        settlementModel &&
        settlementModel.outstandingTokens.token.isEqualTo(
          props.subscriptionOrderBook.token,
        )
      ) {
        return;
      }
      try {
        setSettlementModel(
          props.system || props.underWriterView
            ? (
                await MarketSubscriptionOrderBookSettlementViewUnscopedReader.UnscopedReadOne(
                  {
                    context: authContext,
                    criteria: {
                      id: TextExactCriterion(props.subscriptionOrderBook.id),
                    },
                  },
                )
              ).model
            : (
                await MarketSubscriptionOrderBookSettlementViewReader.ReadOne({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(props.subscriptionOrderBook.id),
                  },
                })
              ).model,
        );
      } catch (e) {
        errorContextDefaultWarningFeedback(
          e,
          "error reading subscription order settlement view",
        );
      }
    })();
  }, [props.subscriptionOrderBook, authContext, reloadToggle]);

  const [signingInProgress, setSigningInProgress] = useState(false);
  const handleSign = useCallback(async () => {
    if (
      !props.settlementTransactionID ||
      props.settlementTransactionID === ""
    ) {
      return;
    }

    // indicate that signing is in progress
    setSigningInProgress(true);
    try {
      await TransactionSignatureController.AddMyMeshKeySignatureToTransaction({
        context: authContext,
        transactionID: props.settlementTransactionID,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error signing: ${err.message ? err.message : err.toString()}`,
      );
      errorContextDefaultWarningFeedback(e, "error signing for settlement");
      setSigningInProgress(false);
    }

    // notify that signing was successful
    enqueueSnackbar(`Signing Successful - Settlement in Progress`, {
      variant: "success",
    });

    // register for listing notification feedback
    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: props.settlementTransactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            !props.settlementTransactionID ||
            props.settlementTransactionID === ""
          ) {
            return;
          }

          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === props.settlementTransactionID
          ) {
            if (isMounted()) {
              reload();
              setSigningInProgress(false);
            }
            enqueueSnackbar(`Settlement Successful`, {
              variant: "success",
            });
          } else if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === props.settlementTransactionID
          ) {
            if (isMounted()) {
              reload();
              setSigningInProgress(false);
            }
            enqueueSnackbar(
              "Settlement Failed. Settlement Will be Retried. Please Refresh Until You Can Sign Again.",
              {
                variant: "warning",
              },
            );
          } else if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === props.settlementTransactionID
          ) {
            if (isMounted()) {
              reload();
              setSigningInProgress(false);
            }
            enqueueSnackbar(
              "Something Has Gone Wrong With Settlement - Please Contact Support",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      setSigningInProgress(false);
      console.error("error registering to monitor settlement transaction", e);
      enqueueSnackbar("Refresh to Monitor Settlement Progress", {
        variant: "success",
      });
    }
  }, [authContext]);

  return (
    <>
      <CardContent
        sx={{
          pt: 3,
          px: 4,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            borderBottom: `${theme.palette.divider} 1px solid`,
            alignItems: "center",
          })}
        >
          <Typography
            variant={"h4"}
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            Book {props.subscriptionOrderBook.number}
          </Typography>
          <SubscriptionOrderBookStateChip
            state={props.subscriptionOrderBook.state}
          />
          {signingInProgress && <CircularProgress size={20} />}
          <Box sx={{ marginLeft: "auto" }} />
          {viewConfiguration?.["Subscriptions"]?.["Sign for Settlement"] &&
            props.subscriptionOrderBook.state ===
              SubscriptionOrderBookState.SettlementInProgress &&
            props.settlementTransactionState ===
              TransactionState.SigningInProgress && (
              <Box sx={{ marginLeft: "auto" }}>
                <Tooltip
                  title={(() => {
                    if (stellarAccountContextLoading || signingInProgress) {
                      return "Loading...";
                    }

                    if (!(issuanceAccSignatory || tradingAccSignatory)) {
                      return "You are not a signatory on either the issuance or trading account";
                    }

                    if (!issuanceAccSignatory) {
                      return "You are not a signatory on the asset issuance account";
                    }

                    if (!tradingAccSignatory) {
                      return "You are not a signatory on the owner group trading account";
                    }

                    return "";
                  })()}
                >
                  <span>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      disabled={
                        signingInProgress ||
                        !(issuanceAccSignatory && tradingAccSignatory) ||
                        stellarAccountContextLoading
                      }
                      onClick={handleSign}
                    >
                      Sign for Settlement
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          {viewConfiguration?.["Subscriptions"]?.["Request Cancellation"] &&
            (
              [
                SubscriptionOrderBookState.Pending,
                SubscriptionOrderBookState.Open,
                SubscriptionOrderBookState.Closed,
              ] as (SubscriptionOrderBookState | "")[]
            ).includes(props.subscriptionOrderBook.state) && (
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setShowCancellationDialog(true)}
              >
                Cancel
              </Button>
            )}
          <Tooltip title={"Refresh"}>
            <span>
              <IconButton
                onClick={reload}
                id={"subscriptionOrderBookView-refresh-iconButton"}
                size={"small"}
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            columnGap: 5,
            rowGap: 2,
          }}
        >
          <>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Open Date
              </Typography>
              <Typography
                id={"primaryMarket-subscriptionOrderBook-openDate"}
                variant="h5"
              >
                {dayjs(props.subscriptionOrderBook.openDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Close Date
              </Typography>
              <Typography
                variant="h5"
                id={"primaryMarket-subscriptionOrderBook-closeDate"}
              >
                {dayjs(props.subscriptionOrderBook.closeDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Settlement Date
              </Typography>
              <Typography
                variant="h5"
                id={"primaryMarket-subscriptionOrderBook-settlementDate"}
              >
                {dayjs(props.subscriptionOrderBook.settlementDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box />
          </>

          <>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Unit Price
              </Typography>
              <Amount
                id={"primaryMarket-subscriptionOrderBook-unitPrice"}
                amount={props.subscriptionOrderBook.unitPrice}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Subscription Total
              </Typography>
              <Amount
                id={"primaryMarket-subscriptionOrderBook-subscriptionAmount"}
                amount={props.subscriptionOrderBook.subscriptionAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Over Subscription Total
              </Typography>
              <Amount
                id={
                  "primaryMarket-subscriptionOrderBook-overSubscriptionAmount"
                }
                amount={props.subscriptionOrderBook.overSubscriptionAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Marketing Threshold Amount
              </Typography>
              <Amount
                id={
                  "primaryMarket-subscriptionOrderBook-marketingThresholdSubscriptionAmount"
                }
                amount={
                  props.subscriptionOrderBook
                    .marketingThresholdSubscriptionAmount
                }
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
          </>
        </Box>
      </CardContent>

      {/* Settlement Information */}
      {!props.underWriterView && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="primaryMarket-settlementInformation-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Settlement Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              padding: theme.spacing(3, 4, 5, 4),
              display: "flex",
              flexDirection: "column",
              gap: 1,
            })}
          >
            {settlementModel ? (
              <>
                <Box>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: theme.spacing(2),
                    })}
                  >
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Amount Subscribed
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.subscribedAmount}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginLeft: "auto",
                      }}
                    >
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Outstanding Amount
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.outstandingAmount}
                      />
                    </Box>
                  </Box>
                  <LoadingBar
                    percent={settlementModel.subscribedAmount.value
                      .dividedBy(
                        props.subscriptionOrderBook.subscriptionAmount.value,
                      )
                      .multipliedBy(new BigNumber(100))}
                  />
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      marginTop: theme.spacing(2),
                    })}
                  >
                    <Box>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.subscribedTokens}
                      />
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Tokens Subscribed
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginLeft: "auto",
                      }}
                    >
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.outstandingTokens}
                      />
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Outstanding Tokens
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* ------------------ Settlement Amount ------------------ */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Total Settlement Amount
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementAmount}
                      />
                    </Box>

                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Left Over Amount
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementAmount.setValue(
                          settlementModel.leftOverAmount,
                        )}
                      />
                    </Box>
                  </Box>

                  {/* ------------------ Settlement Tokens ------------------ */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Total Settlement Tokens
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementTokens}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Left Over Tokens
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementTokens.setValue(
                          settlementModel.leftOverTokens,
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  rowGap: theme.spacing(2),
                  alignItems: "center",
                  justifyItems: "center",
                })}
              >
                <CircularProgress size={40} />
                <Typography
                  variant={"h5"}
                  color={"textSecondary"}
                  children={"Getting things ready for you..."}
                />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {/* Underwriters */}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="primaryMarket-underwriters-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Underwriters
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pt: 3,
            px: 4,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {settlementModel && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: 5,
              }}
            >
              <Box>
                <Typography
                  variant={"body2"}
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  Total Underwriter Take Up Amount
                </Typography>
                <Amount
                  codeTypographyProps={{
                    variant: "h5",
                    sx: (theme) => ({
                      color: theme.palette.text.secondary,
                    }),
                  }}
                  valueTypographyProps={{
                    variant: "h5",
                  }}
                  amount={settlementModel.underwriterTakeUpAmount}
                />
              </Box>
              <Box>
                <Typography
                  variant={"body2"}
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  Total Underwriter Take Up Tokens
                </Typography>
                <Amount
                  codeTypographyProps={{
                    variant: "h5",
                    sx: (theme) => ({
                      color: theme.palette.text.secondary,
                    }),
                  }}
                  valueTypographyProps={{
                    variant: "h5",
                  }}
                  amount={settlementModel.underwriterTakeUpTokens}
                />
              </Box>
              {viewConfiguration?.["Subscriptions"]?.["Underwriter"]?.["Add"] &&
                (
                  [
                    SubscriptionOrderBookState.Pending,
                    SubscriptionOrderBookState.Open,
                    SubscriptionOrderBookState.Closed,
                  ] as (SubscriptionOrderBookState | "")[]
                ).includes(props.subscriptionOrderBook.state) && (
                  <Box sx={{ marginLeft: "auto" }}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => setShowUnderwriterManagementDialog(true)}
                    >
                      Add Underwriter
                    </Button>
                  </Box>
                )}
            </Box>
          )}
          <SubscriptionUnderwritingTable
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            system={props.system}
            height={400}
            subscriptionOrderBookID={props.subscriptionOrderBook.id}
            hideColumns={["Res. Count", "Asset Name", "Book"]}
            showEmptyCard
          />
        </AccordionDetails>
      </Accordion>

      {/* Subscriptions */}
      {!props.underWriterView && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary
              sx={(theme) => ({
                pl: 4,
                flexDirection: "row-reverse",
                backgroundColor: theme.palette.custom.midnight,
              })}
              expandIcon={
                <ExpandMoreIcon
                  id="primaryMarket-subscriptions-accordionToggleIconButton"
                  color="primary"
                />
              }
            >
              <Typography sx={{ ml: 4 }} variant={"h6"}>
                Subscriptions
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                pt: 3,
                px: 4,
                pb: 5,
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <SubscriptionOrderTable
                style={(theme) => ({
                  ".BPTable-tableWrapper": {
                    backgroundColor: theme.palette.custom.grape,
                  },
                })}
                system={props.system}
                height={400}
                hideColumns={["Res. Count", "Asset Name", "Book"]}
                subscriptionOrderBookID={props.subscriptionOrderBook.id}
                showEmptyCard
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {showCancellationDialog && (
        <CancellationConfirmationDialog
          assetName={props.assetName}
          subscriptionOrderBook={props.subscriptionOrderBook}
          onClose={() => setShowCancellationDialog(false)}
        />
      )}

      {showUnderwriterManagementDialog && (
        <UnderwritingManagementDialog
          onClose={() => {
            setShowUnderwriterManagementDialog(false);
            reload();
          }}
          subscriptionOrderBook={props.subscriptionOrderBook}
        />
      )}
    </>
  );
};

interface NoDataCardProps {
  title: string;
  subTitle: string[];
  refresh?: () => void;
  loading?: boolean;
}
export const NoDataCard = ({
  title,
  subTitle,
  refresh,
  loading,
}: NoDataCardProps) => (
  <Card
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      textAlign: "center",
      backgroundColor: theme.palette.custom.grape,
      height: 166,
      position: "relative",
    })}
  >
    {refresh && (
      <IconButton
        sx={{ position: "absolute", top: 4, right: 8 }}
        onClick={refresh}
      >
        <RefreshIcon />
      </IconButton>
    )}
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <Typography
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          variant="h3"
          children={title}
          color="textSecondary"
        />
        {subTitle.map((text, idx) => {
          return (
            <Typography
              key={idx}
              variant="body1"
              children={text}
              color="textSecondary"
            />
          );
        })}
      </>
    )}
  </Card>
);
