import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import cx from "classnames";

interface LegendIconProps {
  color: (theme: Theme) => string;
  label: string;
  fill?: boolean;
}

export const LegendIcon = ({ color, fill, label }: LegendIconProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        className={cx({
          fill: fill,
        })}
        sx={(theme) => ({
          borderRadius: "50%",
          border: `1px solid ${color(theme)}`,
          height: "8px",
          width: "8px",
          mr: 1,
          boxShadow: `inset 0 0 3px 0 ${color(theme)}, 0 0 8px 0 ${color(
            theme,
          )}`,
          "&.fill": {
            backgroundColor: color,
          },
        })}
      />
      <Typography
        sx={(theme) => ({
          fontSize: "10px",
          color: theme.palette.text.secondary,
        })}
      >
        {label}
      </Typography>
    </Box>
  );
};
