import { Context } from "../security";
import { Key } from "./Key";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export type FetchMyKeysRequest = {
  context: Context;
};

export type FetchMyKeysResponse = {
  keys: Key[];
};

export const UserKeyFetcher = {
  serviceProvider: "key-UserKeyFetcher",
  async FetchMyKeys(request: FetchMyKeysRequest): Promise<FetchMyKeysResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${UserKeyFetcher.serviceProvider}.FetchMyKeys`,
      request,
    });
    return {
      keys: response.keys.map((k: Key) => new Key(k)),
    };
  },
};
