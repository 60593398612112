import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import {
  Manager,
  Profile as CompanyProfileClass,
  Profile,
} from "james/legal/company";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
const PREFIX = "WebLinks";

const classes = {
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  skipButton: `${PREFIX}-skipButton`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.heading}`]: {
    color: theme.palette.secondary.main,
  },

  [`& .${classes.subHeading}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.skipButton}`]: {
    marginTop: theme.spacing(7),
  },
}));

interface InfoProfilePicturesProps {
  onNext: (webLinks: {
    companyWebsite: string;
    linkedIn: string;
    twitter: string;
    otherSite: string;
  }) => void;
}

export const WebLinks: React.FC<InfoProfilePicturesProps> = (
  props: InfoProfilePicturesProps,
) => {
  const [companyProfile, setCompanyProfile] = useState(new Profile());
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [webLinks, setWebLinks] = useState({
    companyWebsite: "",
    linkedIn: "",
    twitter: "",
    otherSite: "",
  });
  const { authContext } = useApplicationContext();

  const fieldsUntouched = isEqual(webLinks, {
    companyWebsite: "",
    linkedIn: "",
    twitter: "",
    otherSite: "",
  });

  useEffect(() => {
    Manager.RetrieveMyCompanyProfile({
      context: authContext,
    })
      .then((response) => {
        setCompanyProfile(response.profile);
      })
      .catch((e) => {
        errorContextDefaultErrorFeedback(e);
      });
  }, []);

  if (isEqual(companyProfile, new CompanyProfileClass())) {
    return (
      <Backdrop sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <StyledGrid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.heading} variant="h4">
          Welcome to {companyProfile.companyName}
          &apos;s first steps on Mesh
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.subHeading} variant="h6">
          If there are any additional sites, or sources of information regarding{" "}
          {companyProfile.companyName} that you would want users of Mesh to be
          aware of, add them to the profile now.
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField
              value={webLinks.companyWebsite}
              onChange={(e) =>
                setWebLinks({
                  ...webLinks,
                  companyWebsite: e.target.value,
                })
              }
              label="Company Website"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              value={webLinks.linkedIn}
              onChange={(e) =>
                setWebLinks({
                  ...webLinks,
                  linkedIn: e.target.value,
                })
              }
              label="Linked-in"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              value={webLinks.twitter}
              onChange={(e) =>
                setWebLinks({
                  ...webLinks,
                  twitter: e.target.value,
                })
              }
              label="Twitter"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              value={webLinks.otherSite}
              onChange={(e) =>
                setWebLinks({
                  ...webLinks,
                  otherSite: e.target.value,
                })
              }
              label="Other Site"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          id="companyFirstSteps-weblinks-skipOrNext-button"
          onClick={() => props.onNext(webLinks)}
          className={classes.skipButton}
          variant={fieldsUntouched ? "outlined" : "contained"}
          color={fieldsUntouched ? undefined : "primary"}
        >
          {fieldsUntouched ? "Skip" : "Finish"}
        </Button>
      </Grid>
    </StyledGrid>
  );
};
