import { Amount } from "james/ledger";
import dayjs from "dayjs";
import { BankAccount, DefundOrderState } from "../../banking";

export const ModelTypeName = "mesh::bankingDefundOrderView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  id = "";

  number = "";

  clientName = "";

  accountNumber = "";

  bankReference = "";

  bankAccount: BankAccount = new BankAccount();

  amount: Amount = new Amount();

  feeAmount: Amount = new Amount();

  vatRate: string = "0";

  state: DefundOrderState | "" = "";

  express = false;

  creationDate: string = dayjs().format();

  constructor(model?: Model) {
    if (!model) {
      return;
    }

    this.id = model.id;
    this.number = model.number;
    this.clientName = model.clientName;
    this.accountNumber = model.accountNumber;
    this.bankReference = model.bankReference;
    this.bankAccount = new BankAccount(model.bankAccount);
    this.amount = new Amount(model.amount);
    this.feeAmount = new Amount(model.feeAmount);
    this.vatRate = model.vatRate;
    this.state = model.state;
    this.express = model.express;
    this.creationDate = model.creationDate;
  }
}
