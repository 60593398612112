import { Context } from "james/security";
import { Query } from "james/search/query";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { Identifier } from "james/search/identifier/Identifier";
import { Currency, NewCurrency } from "./Currency";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveCurrencyRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveCurrencyResponse {
  currency: Currency;
}

export interface SearchCurrencyRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchCurrencyResponse {
  records: Currency[];
  total: number;
}

export const CurrencyCollection = {
  serviceProvider: "financial-CurrencyCollection",
  async RetrieveCurrency(
    request: RetrieveCurrencyRequest,
  ): Promise<RetrieveCurrencyResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${CurrencyCollection.serviceProvider}.RetrieveCurrency`,
      request,
    });
    return {
      currency: NewCurrency(response.currency),
    };
  },
  async SearchCurrency(
    request: SearchCurrencyRequest,
  ): Promise<SearchCurrencyResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${CurrencyCollection.serviceProvider}.SearchCurrency`,
      request,
    });
    return {
      total: response.total,
      records: response.records.map((m: Currency) => NewCurrency(m)),
    };
  },
};

export function useSearchCurrency(
  initialSearchCurrencyRequest?: SearchCurrencyRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchCurrencyRequest, setSearchCurrencyRequest] =
    useState<SearchCurrencyRequest>(
      initialSearchCurrencyRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchCurrencyResponse, setSearchCurrencyResponse] =
    useState<SearchCurrencyResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchCurrencyResponse(
          await CurrencyCollection.SearchCurrency(searchCurrencyRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for currency: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for currency: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchCurrencyRequest, shouldNotExecute]);

  return {
    searchCurrencyRequest,
    setSearchCurrencyRequest,
    searchCurrencyResponse,
    loading,
    error,
  };
}
