import React, { useMemo } from "react";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import BigNumber from "bignumber.js";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { AssetSubscriptionOverviewDataSheetSection } from "james/marketData/AssetSubscriptionOverviewDataSheetSection";

interface AssetSubscriptionOverviewRendererProps {
  dataSheetSection: AssetSubscriptionOverviewDataSheetSection;
}

export const AssetSubscriptionOverviewRenderer = ({
  dataSheetSection: {
    subscriptionAmount,
    subscribedAmount,
    underwriterCommittmentAmount,
  },
}: AssetSubscriptionOverviewRendererProps) => {
  const subscribedPercentage = useMemo(() => {
    if (subscriptionAmount && subscribedAmount) {
      return subscribedAmount.value
        .dividedBy(subscriptionAmount.value)
        .multipliedBy(100);
    }
    return new BigNumber(0);
  }, [subscriptionAmount, subscribedAmount]);

  const underwriterCommittmentPercent = useMemo(() => {
    if (subscriptionAmount && underwriterCommittmentAmount) {
      if (underwriterCommittmentAmount.value.gt(subscriptionAmount.value)) {
        return new BigNumber(100);
      }
      return underwriterCommittmentAmount.value
        .dividedBy(subscriptionAmount.value)
        .multipliedBy(100);
    }
    return new BigNumber(0);
  }, [subscriptionAmount, underwriterCommittmentAmount]);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ pt: 4, pb: 5, pr: { sm: 5, xs: 3 } }}>
      {subscribedAmount && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              justifyContent: "space-between",
              mt: 2,
              gap: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightMedium,
                mb: { sm: 2, xs: 0 },
              })}
              children={`${subscribedPercentage.dp(2)}% Subscribed`}
            />
          </Box>
          {smDown && (
            <SubscriptionTargetLegendItem
              subscriptionAmount={subscribedAmount}
            />
          )}
          <LoadingBar
            percent={subscribedPercentage}
            secondaryPercent={underwriterCommittmentPercent}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2,
              gap: 2,
              alignItems: "flex-end",
            }}
          >
            {/* FIXME: Edge case for amounts in excess of 10,000,000 casue warping on mobile screens */}
            {/* FIXME: Added the same row thing as well */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexBasis: { xs: "100%", sm: "auto" },
                gap: { sm: 5, xs: 2 },
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              {/* Amount Subscribed */}
              <Box>
                <Typography
                  variant="caption"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  children="Amount Subscribed"
                />
                <Amount
                  reverse={true}
                  codeTypographyProps={{
                    sx: (theme) => ({
                      color: theme.palette.success.light,
                      fontSize: "16px",
                      fontWeight: theme.typography.fontWeightBold,
                    }),
                  }}
                  valueTypographyProps={{
                    sx: (theme) => ({
                      fontSize: "16px",
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.success.light,
                    }),
                  }}
                  amount={
                    subscribedAmount.isUndefined()
                      ? subscriptionAmount.setValue("0")
                      : subscribedAmount
                  }
                />
              </Box>
              {/* Amount Underwritten */}
              {underwriterCommittmentAmount.value.gt(0) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    flexBasis: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                    children="Underwritter Commitment"
                  />
                  <Amount
                    reverse={true}
                    codeTypographyProps={{
                      sx: (theme) => ({
                        color: theme.palette.secondary.main,
                        fontSize: "16px",
                        fontWeight: theme.typography.fontWeightBold,
                      }),
                    }}
                    valueTypographyProps={{
                      sx: (theme) => ({
                        fontSize: "16px",
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.secondary.main,
                      }),
                    }}
                    amount={
                      underwriterCommittmentAmount.isUndefined()
                        ? underwriterCommittmentAmount.setValue("0")
                        : underwriterCommittmentAmount
                    }
                  />
                </Box>
              )}
            </Box>
            {/* Subscription Target */}
            {!smDown && (
              <SubscriptionTargetLegendItem
                subscriptionAmount={subscribedAmount}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

interface SubscriptionTargetLegendItemProps {
  subscriptionAmount: AmountType;
}

const SubscriptionTargetLegendItem = ({
  subscriptionAmount,
}: SubscriptionTargetLegendItemProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: { sm: "column", xs: "row" },
      alignItems: { sm: "flex-end", xs: "flex-start" },
      py: { sm: 0, xs: 2 },
    }}
  >
    <Typography
      variant="caption"
      sx={(theme) => ({
        color: theme.palette.text.secondary,
        mr: { sm: 0, xs: 1 },
      })}
      children="Target Amount"
    />
    <Amount
      reverse={true}
      codeTypographyProps={{
        sx: (theme) => ({
          fontSize: "16px",
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: 1,
        }),
      }}
      valueTypographyProps={{
        sx: (theme) => ({
          fontSize: "16px",
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: 1,
        }),
      }}
      amount={subscriptionAmount}
    />
  </Box>
);
