import config from "react-global-configuration";
import { Context } from "../security";
import { BankAccount, BankAccountType, BankName } from "./bankAccount";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

interface UpdateBankAccountTypeRequest {
  context: Context;
  bankAccountID: string;
  bankAccountType: BankAccountType;
}

interface UpdateBankAccountTypeResponse {
  bankAccount: BankAccount;
}

interface UpdateBankAccountNumberRequest {
  context: Context;
  bankAccountID: string;
  bankAccountNumber: string;
}

interface UpdateBankAccountNumberResponse {
  bankAccount: BankAccount;
}

interface UpdateBankNameRequest {
  context: Context;
  bankAccountID: string;
  bankName: BankName;
}

interface UpdateBankNameResponse {
  bankAccount: BankAccount;
}

interface UpdateBranchCodeRequest {
  context: Context;
  bankAccountID: string;
  branchCode: string;
}

interface UpdateBranchCodeResponse {
  bankAccount: BankAccount;
}

interface UpdateBankAccountUnscopedRequest {
  context: Context;
  bankAccountID: string;
  bankAccount: BankAccount;
}

interface UpdateBankAccountUnscopedResponse {
  bankAccount: BankAccount;
}

export const BankAccountUpdaterServiceProviderName =
  "banking-BankAccountUpdater";

export const BankAccountUpdater = {
  async UpdateBankAccountType(
    request: UpdateBankAccountTypeRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<UpdateBankAccountTypeResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountUpdaterServiceProviderName}.UpdateBankAccountType`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return {
      bankAccount: new BankAccount(response.bankAccount),
    };
  },
  async UpdateBankAccountNumber(
    request: UpdateBankAccountNumberRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<UpdateBankAccountNumberResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountUpdaterServiceProviderName}.UpdateBankAccountNumber`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      bankAccount: new BankAccount(response.bankAccount),
    };
  },
  async UpdateBankName(
    request: UpdateBankNameRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<UpdateBankNameResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountUpdaterServiceProviderName}.UpdateBankName`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      bankAccount: new BankAccount(response.bankAccount),
    };
  },
  async UpdateBranchCode(
    request: UpdateBranchCodeRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<UpdateBranchCodeResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountUpdaterServiceProviderName}.UpdateBranchCode`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      bankAccount: new BankAccount(response.bankAccount),
    };
  },
  async UpdateBankAccountUnscoped(
    request: UpdateBankAccountUnscopedRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<UpdateBankAccountUnscopedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountUpdaterServiceProviderName}.UpdateBankAccountUnscoped`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      bankAccount: new BankAccount(response.bankAccount),
    };
  },
};
