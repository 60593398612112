import { ValidationResult } from "common/validation";
import { MechanismType } from "james/market";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import dayjs from "dayjs";

export type FormData = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type FormUpdaterSpecsType = {
  subscriptionOrderBook: (
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  subscriptionOrderBook(
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: FormData,
  ) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(subscriptionOrderBook),
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${MechanismType.Subscription}-${field}`
    ] = message;
  };

  const fractionalisationAllowed =
    formData.subscriptionOrderBook.fractionalisationAllowed;
  const unitPrice = formData.subscriptionOrderBook.unitPrice.value;
  const subscriptionAmount =
    formData.subscriptionOrderBook.subscriptionAmount.value;
  const overSubscriptionAmount =
    formData.subscriptionOrderBook.overSubscriptionAmount.value;
  const marketingThresholdSubscriptionAmount =
    formData.subscriptionOrderBook.marketingThresholdSubscriptionAmount.value;

  if (unitPrice.isLessThanOrEqualTo(0)) {
    invalid("unitPrice", "Must be > 0");
  }

  if (subscriptionAmount.isLessThanOrEqualTo(0)) {
    invalid("subscriptionAmount", "Must be > 0");
  }
  if (
    !fractionalisationAllowed &&
    subscriptionAmount.mod(unitPrice).abs().isGreaterThan(0)
  ) {
    invalid("subscriptionAmount", "Must be an integer mutliple of Unit Price");
  }

  if (overSubscriptionAmount.isLessThanOrEqualTo(0)) {
    invalid("overSubscriptionAmount", "Must be > 0");
  }
  if (overSubscriptionAmount.isLessThanOrEqualTo(subscriptionAmount)) {
    invalid("overSubscriptionAmount", "Must be > Subscription Amount");
  }
  if (
    !fractionalisationAllowed &&
    overSubscriptionAmount.mod(unitPrice).abs().isGreaterThan(0)
  ) {
    invalid(
      "overSubscriptionAmount",
      "Must be an integer multiple of Unit Price",
    );
  }

  if (marketingThresholdSubscriptionAmount.isLessThan(0)) {
    invalid("marketingThresholdSubscriptionAmount", "Must be >= 0");
  }

  // The date checks are handled by the component
  // A user can manually enter an invalid date
  // So these validations are needed
  const openDate = dayjs(formData.subscriptionOrderBook.openDate);
  const closeDate = dayjs(formData.subscriptionOrderBook.closeDate);
  const settlementDate = dayjs(formData.subscriptionOrderBook.settlementDate);

  if (openDate.startOf("day").isBefore(dayjs().endOf("day"))) {
    invalid("openDate", "Open date should be after today");
  }
  if (openDate.endOf("day").isAfter(settlementDate.startOf("day"))) {
    invalid("openDate", "Open date should be before settlement date");
  }

  if (
    closeDate.endOf("day").isAfter(settlementDate.add(1, "day").startOf("day"))
  ) {
    invalid("closeDate", "Close date should be before settlement date");
  }

  if (closeDate.startOf("day").isBefore(openDate.endOf("day"))) {
    invalid("closeDate", "Close date should be after open date");
    invalid("openDate", "Open date should be before close date");
  }

  return validationResult;
};
