import { useCallback } from "react";
import {
  Reader,
  ReadResponse,
  ReadRequest,
} from "james/views/marketListingView/Reader";
import {
  BuildQueryKey,
  GenerateAPIRequestUniqueKey,
} from "utilities/reactQuery";
import { useQueryCacheControllerContext } from "context/QueryCacheController/QueryCacheController";
import { ReactQueryOpt } from "./type";

export type MarketListingViewReader = {
  Read: (
    request: ReadRequest,
    reactQueryOpts?: ReactQueryOpt,
  ) => Promise<ReadResponse>;
};

export function useRQMarketListingViewReader(): MarketListingViewReader {
  const { registerQuery, executeQuery } = useQueryCacheControllerContext();

  const Read = useCallback(
    async (request: ReadRequest): Promise<ReadResponse> => {
      // create unique Key for the API request
      const UniqueKey = GenerateAPIRequestUniqueKey(
        Reader.serviceProvider,
        "Read",
        request,
      );

      // build query Key and use it as an ID to register the query
      const ID = BuildQueryKey(UniqueKey, "ListingViewModels");

      registerQuery(ID, () => Reader.Read(request), {
        cacheTime: 5 * 60 * 1000, // 5 minutes
        staleTime: 5 * 1000, // 5 seconds
      });

      return (await executeQuery(ID)) as Promise<ReadResponse>;
    },
    [registerQuery, executeQuery],
  );

  return {
    Read,
  };
}
