import { Context } from "../security";
import { Transaction } from "./Transaction";
import { TransactionFailureReason } from "./TransactionInspector";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export type AnalyseFailedTransactionRequest = {
  context: Context;
  transaction: Transaction;
};

export type AnalyseFailedTransactionResponse = {
  failureReasons: TransactionFailureReason[];
};

export const TransactionFailureAnalyser = {
  serviceProvider: "ledger-TransactionFailureAnalyser",
  async AnalyseFailedTransaction(
    request: AnalyseFailedTransactionRequest,
  ): Promise<AnalyseFailedTransactionResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${TransactionFailureAnalyser.serviceProvider}.AnalyseFailedTransaction`,
      request,
    });
  },
};
