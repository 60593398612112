import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  MarketListingViewModel,
  MarketListingViewUpdater,
  useMarketListingViewReaderRead,
} from "james/views/marketListingView";
import { AllListingStates, ListingState } from "james/market/Listing";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import { ListingStateChip } from "views/MarketListing/MarketListings/Chips";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  InfoOutlined as InfoIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { ListingManagementDialog } from "components/ListingManagementDialog";
import { useSnackbar } from "notistack";
import { MechanismType } from "james/market";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "Table";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  row: `${PREFIX}-row`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.textSearchField}`]: {
    width: 350,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },

  [`& .${classes.statusSelectFilterField}`]: {
    width: 230,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
});

export function Table() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [selectedListingViewModel, setSelectedListingViewModel] = useState<
    MarketListingViewModel | undefined
  >(undefined);
  const [showManagementDialog, setShowManagementDialog] = useState(false);
  const [fullUpdateLoading, setFullUpdateLoading] = useState(false);
  const marketListingsViewConfiguration =
    viewConfiguration["MC Market Listing"]?.["Market Listings"];
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { readResponse, loading, setReadRequest, readRequest } =
    useMarketListingViewReaderRead({
      context: authContext,
      query: new Query(initialQuery),
      criteria: {},
    });
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [listingStatusesCriterion, setListingStatusesCriterion] = useState<
    ListingState[]
  >([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { assetName: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            assetShortName: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
          { assetType: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            listingLastActionAnnotation: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (listingStatusesCriterion.length) {
      criteria.listingState = TextListCriterion(listingStatusesCriterion);
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    listingStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  return (
    <Root>
      <BPTable
        singleSelect
        loading={loading || fullUpdateLoading}
        height={window.innerHeight - 138 - noticeBannerHeight}
        title={"Market Listings"}
        data={readResponse.models}
        onSingleSelectChange={(data) =>
          setSelectedListingViewModel(data as MarketListingViewModel)
        }
        columns={[
          {
            field: "tokenIcon",
            sortable: false,
            label: "",
            accessor: (data) => (
              <IconViewUpload token={(data as MarketListingViewModel).token} />
            ),
          },
          {
            label: "Name",
            field: "assetName",
          },
          {
            label: "Short Name",
            field: "assetShortName",
          },
          {
            label: "Type",
            field: "assetType",
          },
          {
            label: "Mechanism",
            field: "listingMarketMechanisms",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) =>
              (data as MarketListingViewModel).listingMarketMechanisms
                .map((m) => m.type)
                .join(","),
          },
          {
            label: "Placement",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              return (
                <div className={classes.row}>
                  <ListingStateChip
                    state={(data as MarketListingViewModel).listingState}
                  />
                  <Tooltip
                    placement={"top"}
                    title={
                      (data as MarketListingViewModel)
                        .listingLastActionAnnotation
                    }
                  >
                    <InfoIcon className={classes.iconButton} />
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
        query={readRequest.query}
        onQueryChange={(query: Query) =>
          setReadRequest({
            ...readRequest,
            query,
          })
        }
        totalNoRecords={readResponse.total}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (
            marketListingsViewConfiguration &&
            marketListingsViewConfiguration.Operate
          ) {
            if (
              // do not show button for direct order listings
              // TODO: thinking required on scoping of asset fetcher and activate / deactivate
              (selectedListingViewModel &&
                !selectedListingViewModel.listingMarketMechanisms.find(
                  (m) => m.type === MechanismType.DirectOrder,
                )) ||
              !selectedListingViewModel
            ) {
              controls.push(
                <Button
                  id={`marketListingsTable-${
                    selectedListingViewModel ? "edit" : "new listing"
                  }-button`}
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => setShowManagementDialog(true)}
                  children={selectedListingViewModel ? "edit" : "new Listing"}
                />,
              );
            }
          }

          if (
            viewConfiguration?.["MC Market Listing"]?.["Market Listings"]
              ?.ViewModel?.FullUpdate
          ) {
            controls.push(
              <Button
                variant={"contained"}
                color={"primary"}
                children={"Full Update"}
                onClick={async () => {
                  setFullUpdateLoading(true);
                  try {
                    await MarketListingViewUpdater.FullUpdate({
                      context: authContext,
                    });
                    setReadRequest({ ...readRequest });
                    enqueueSnackbar("Full Update Complete", {
                      variant: "success",
                    });
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    console.error(
                      `error performing full update: ${
                        err.message ? err.message : err.toString()
                      }`,
                    );
                    enqueueSnackbar(
                      `Error Performing Full Update: ${
                        err.message ? err.message : err.toString()
                      }`,
                      { variant: "error" },
                    );
                  }
                  setFullUpdateLoading(false);
                }}
              />,
            );
          }

          controls.push(
            <Tooltip title={"Refresh"}>
              <span>
                <IconButton
                  onClick={() =>
                    setReadRequest({
                      ...readRequest,
                      query: new Query(initialQuery),
                    })
                  }
                  id={"marketListingsTable-refresh-iconButton"}
                  disabled={loading}
                  size={"small"}
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controls;
        })()}
        filters={[
          <TextField
            id={"marketListingsTable-textFilter-textField"}
            variant={"outlined"}
            margin={"dense"}
            className={classes.textSearchField}
            label={"Search Text Field"}
            placeholder={"Start Typing..."}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position={"end"}
                  children={
                    <IconButton
                      id={
                        "marketListingsTable-textFilterClearButton-iconButton"
                      }
                      size={"small"}
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"marketListingsTable-stateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={AllListingStates}
            filterSelectedOptions
            onChange={(_, value: ListingState[]) =>
              setListingStatusesCriterion(value)
            }
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={"marketListingsTable-stateFilter-autocompleteTextField"}
                className={classes.statusSelectFilterField}
                label={"State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  listingStatusesCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
        ]}
      />
      {showManagementDialog && (
        <ListingManagementDialog
          closeDialog={() => setShowManagementDialog(false)}
          baseToken={
            selectedListingViewModel
              ? selectedListingViewModel.token
              : undefined
          }
        />
      )}
    </Root>
  );
}
