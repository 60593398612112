import { StellarAccount, StellarAccountTypeName } from "james/stellar";
import { LedgerNetwork } from "./Network";
import dayjs from "dayjs";

export enum LedgerAccountCategory {
  Issuance = "Issuance",
  Trading = "Trading",
  Operational = "Operational",
  MarketInfrastructure = "Market Infrastructure",
  Fee = "Fee",
  Clearance = "Clearance",
}

export const AllLedgerAccountCategories: LedgerAccountCategory[] = [
  LedgerAccountCategory.Issuance,
  LedgerAccountCategory.Clearance,
  LedgerAccountCategory.Trading,
  LedgerAccountCategory.Operational,
  LedgerAccountCategory.MarketInfrastructure,
  LedgerAccountCategory.Fee,
];

export enum LedgerAccountState {
  OpeningAccountState = "Opening",
  Open = "Open",
  Closed = "Closed",
  UnderInvestigationAccountState = "Under Investigation",
}

export const AllLedgerAccountStates: LedgerAccountState[] = [
  LedgerAccountState.OpeningAccountState,
  LedgerAccountState.Open,
  LedgerAccountState.Closed,
  LedgerAccountState.UnderInvestigationAccountState,
];

export interface Account {
  ["@type"]: string;

  accountID(): string;

  accountOwnerID(): string;

  accountCategory(): LedgerAccountCategory | "";

  accountState(): LedgerAccountState | "";

  accountLedgerNetwork(): LedgerNetwork | "";

  createdAt(): dayjs.Dayjs;
}

export function NewAccount(account: Account): Account {
  switch (account["@type"]) {
    case StellarAccountTypeName:
      return new StellarAccount(account as StellarAccount);
    default:
      throw new TypeError(`unknown account type: ${account["@type"]}`);
  }
}
