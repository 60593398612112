export class ContactDetails {
  public emailAddress = "";

  public telephoneNumber = "";

  public cellphoneNumber = "";

  constructor(contactDetails?: ContactDetails) {
    if (!contactDetails) {
      return;
    }
    this.emailAddress = contactDetails.emailAddress;
    this.telephoneNumber = contactDetails.telephoneNumber;
    this.cellphoneNumber = contactDetails.cellphoneNumber;
  }
}
