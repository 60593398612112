import React, { useState } from "react";
import { Router } from "routes/Router";
import { Fee } from "./components/Fee";
import { RouteType } from "routes/Route";
import { IssuerFees } from "./components/IssuerFees";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const feesRoutes: RouteType[] = [
  {
    name: "fees",
    id: "fees-table",
    path: "/market-management/fees",
    component: <Fee />,
  },
  {
    name: "fees",
    id: "issuer-fees",
    path: "/market-management/fees/issuer-fees",
    component: <IssuerFees />,
  },
];

export const Fees = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(() => {
    if (location.pathname.includes("issuer-fees")) return 1;
    if (location.pathname.includes("fees")) return 0;
  });

  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.cardInner,
        })}
      >
        <Tabs value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
          <Tab
            title="Issuer fees"
            onClick={() => navigate("")}
            label="Fees"
            value={0}
          />
          <Tab
            title="Issuer fees"
            onClick={() => navigate("issuer-fees")}
            label="Issuer Fees"
            value={1}
          />
        </Tabs>
      </Box>
      <Router baseURL={"/market-management/fees"} routes={feesRoutes} />
    </Box>
  );
};
