import React from "react";
import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useValidatedForm } from "hooks/useForm";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import {
  FormState,
  formUpdaterSpecs,
  initialState,
  validationFunc,
} from "views/SignUp/components/NonSouthAfricanClient/useFormState";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import {
  InteractionAction,
  InteractionType,
  InteractionDriver,
  DataLinkInfoType,
} from "const/gtm";

interface SubmitEmailCardProps {
  onSubmitEmail: (email: string) => Promise<void>;
  onGoBack: () => void;
}

export function SubmitEmailCard({
  onSubmitEmail,
  onGoBack,
}: SubmitEmailCardProps) {
  const navigate = useNavigate();
  const initialTouchedFields: Set<string> = new Set();

  // validate individual client sign up details
  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm(
      validationFunc,
      async (): Promise<FormState> => {
        return {
          email: "",
        };
      },
      formUpdaterSpecs,
      initialState,
      initialTouchedFields,
    );

  const { fieldValidations, valid } = validationResult;

  return (
    <OnboardingCard
      linksFooter={
        <>
          <Typography>
            <Link
              id={"SignUp-NonSAClient-SubmitEmailCard-GoBack-Link"}
              sx={(theme) => ({
                marginBottom: theme.spacing(1),
                fontWeight: "bold",
              })}
              onClick={onGoBack}
              fontSize={"14px"}
              component="button"
              underline="hover"
              color="secondary"
            >
              Go Back
            </Link>
          </Typography>
          <Box
            sx={(theme) => ({
              display: "flex",
              paddingBottom: theme.spacing(5.5),
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: "14px",
              })}
              variant="body1"
            >
              Already Registered?
            </Typography>
            <Link
              id={"SignUp-NonSAClient-SubmitEmailCard-SignIn-Link"}
              sx={(theme) => ({
                paddingLeft: theme.spacing(1),
                fontWeight: "bold",
              })}
              onClick={() => {
                navigate("/sign-in");
              }}
              fontSize={"14px"}
              component="button"
              underline="hover"
              color="secondary"
              data-link-info={JSON.stringify({
                content_interaction_id: "sign-in-button",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "already registered - sign in",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.SignIn,
              } as DataLinkInfoType)}
            >
              Sign In
            </Link>
          </Box>
        </>
      }
    >
      <VerticalSlide transitionDuration={1 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 3, 0.5),
          })}
          fontSize="20px"
          fontWeight="600"
          children="Good things take time"
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(2.5),
            color: theme.palette.text.tertiary,
            width: "290px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={"We are working on making Mesh accessible to all users."}
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={3 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(4),
            color: theme.palette.text.tertiary,
            width: "318px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={
            "Do you wish to be notified once Mesh becomes available in your region?" +
            " Fill in your email below."
          }
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={4 / 5}>
        <TextField
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
            margin: 0,
          })}
          fullWidth
          label="Email"
          id="signUp-countryOfResidence-email-textField"
          value={formState.email}
          inputProps={{ type: "email" }}
          error={!!fieldValidations.email}
          helperText={fieldValidations.email}
          onChange={(e) =>
            formUpdater.email(e.target.value.replace(/\s+/g, ""))
          }
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          id="signUp-countryOfResidence-submitEmail-button"
          sx={(theme) => ({
            height: theme.spacing(6),
            boxShadow: 5,
            width: "100%",
            marginBottom: theme.spacing(2),
          })}
          color="primary"
          variant="contained"
          size="large"
          disabled={!formState.email || validationInProgress || !valid}
          onClick={() => onSubmitEmail(formState.email)}
          children="Submit email"
        />
      </VerticalSlide>
      <Typography>
        <Link
          id="signUp-countryOfResidence-viewSupportedRegions-link"
          sx={(theme) => ({
            marginBottom: theme.spacing(13),
            fontWeight: "bold",
          })}
          onClick={() => {
            window.open("https://www.mesh.trade/supported-regions/", "_blank");
          }}
          fontSize={"14px"}
          component="button"
          underline="hover"
          color="secondary"
        >
          View Supported Regions
        </Link>
      </Typography>
    </OnboardingCard>
  );
}
