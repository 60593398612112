import React from "react";
import { Box, Typography } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { LoadingEllipses } from "components/Animations/LoadingEllipses";
import background from "assets/images/background/background.png";

export const UserRegisteringLoader = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        overflowY: "unset",
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        gap: theme.spacing(3),
      })}
    >
      <img alt="" width={100} src={meshMiniLogo} />
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          paddingTop: theme.spacing(2),
        })}
      >
        <Typography fontSize="16px">Registration in progress</Typography>
        <LoadingEllipses />
      </Box>
    </Box>
  );
};
