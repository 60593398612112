import { Company } from "james/legal/company";

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  values: Company,
  setFormFieldValid?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let InvalidFormFields = {};

  if (values.businessAddress.addressLine1 === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressAddressLine1: "Address line is missing",
    };
  }

  if (values.businessAddress.city === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressCity: "City is missing",
    };
  }

  if (values.businessAddress.countryCode === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressCountryCode: "Country Code is missing",
    };
  }

  if (values.businessAddress.countryCode === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressCountryCode: "Country code is required",
    };
  }

  if (values.businessAddress.suburb === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressSuburb: "Suburb is required",
    };
  }

  if (values.businessAddress.province === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressProvince: "Province is required",
    };
  }

  if (values.businessAddress.postalCode === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      businessAddressPostalCode: "Postal code is required",
    };
  }

  if (setFormFieldValid) {
    setFormFieldValid(InvalidFormFields);
  }
  return !Object.keys(InvalidFormFields).length;
};

export const ContactDetailFormFieldValidationState: {
  [key: string]: string | undefined;
} = {};
