import config from "react-global-configuration";
import { Context } from "../security";
import { BankAccount, BankName } from "./bankAccount";
import { jsonRPCRequest } from "../../utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";

interface CreateNewBankAccountRequest {
  context: Context;
  ownerID: string;
  countryCode: string;
  bankName: BankName;
  accountType: string;
  branchCode: string;
  bankAccountNumber: string;
}

interface CreateNewBankAccountResponse {
  bankAccount: BankAccount;
}

interface CreateNewBankAccountUnscopedRequest {
  context: Context;
  ownerID: string;
  countryCode: string;
  bankName: BankName;
  accountType: string;
  branchCode: string;
  bankAccountNumber: string;
}

interface CreateNewBankAccountUnscopedResponse {
  bankAccount: BankAccount;
}

export const BankAccountCreatorServiceProviderName =
  "banking-BankAccountCreator";

export const BankAccountCreator = {
  async CreateNewBankAccount(
    request: CreateNewBankAccountRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<CreateNewBankAccountResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountCreatorServiceProviderName}.CreateNewBankAccount`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async CreateNewBankAccountUnscoped(
    request: CreateNewBankAccountUnscopedRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<CreateNewBankAccountUnscopedResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${BankAccountCreatorServiceProviderName}.CreateNewBankAccountUnscoped`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
};
