"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleConfigurationWrapper = void 0;
const scheduleConfiguration_pb_1 = require("./scheduleConfiguration_pb");
const scheduleConfigurationType_pb_1 = require("./scheduleConfigurationType_pb");
/**
 * Wrapper class for ScheduleConfiguration.
 */
class ScheduleConfigurationWrapper {
    /**
     * Constructs a new ScheduleConfigurationWrapper instance.
     * @param {ScheduleConfiguration} scheduleConfiguration - The schedule configuration  instance to wrap.
     */
    constructor(scheduleConfiguration) {
        this._scheduleConfiguration =
            scheduleConfiguration !== null && scheduleConfiguration !== void 0 ? scheduleConfiguration : new scheduleConfiguration_pb_1.ScheduleConfiguration();
    }
    /**
     * Gets the type of the wrapped schedule configuration .
     * @returns {ScheduleConfigurationType} The type of the wrapped schedule configuration .
     */
    get scheduleConfigurationType() {
        switch (true) {
            case this._scheduleConfiguration.hasPerpetualscheduleconfiguration():
                return scheduleConfigurationType_pb_1.ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE;
            case this._scheduleConfiguration.hasNonperpetualscheduleconfiguration():
                return scheduleConfigurationType_pb_1.ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE;
            default:
                return scheduleConfigurationType_pb_1.ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE;
        }
    }
    /**
     * Gets the wrapped schedule configuration  instance.
     * @returns {ScheduleConfiguration} The wrapped schedule configuration .
     */
    get scheduleConfiguration() {
        return this._scheduleConfiguration;
    }
}
exports.ScheduleConfigurationWrapper = ScheduleConfigurationWrapper;
