import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { usePersonViewStore } from "../store";
import { Repository } from "james/legal/person/Repository";
import { IDIdentifier } from "james/search/identifier";

export const useSavePersonDetails = () => {
  const store = usePersonViewStore();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const savePersonDetails = async () => {
    store.setLoading(true);
    try {
      const person = store.getPersonObject();
      await Repository.UpdatePerson({
        context: authContext,
        identifier: IDIdentifier(person.id),
        person: person,
      });
    } catch (e) {
      console.error("error saving person information", e);
      errorContextDefaultErrorFeedback(e, "error saving person information");
    }
    store.setLoading(false);
  };

  return {
    savePersonDetails,
  };
};
