export function LuhnAlgorithm(code: string): boolean {
  const { length } = code;
  let sum = 0;
  let isSecond = false;
  let digit = 0;

  for (let i = length - 1; i >= 0; i--) {
    digit = parseInt(code[i], undefined);

    if (isSecond) {
      digit *= 2;
    }
    sum += parseInt(String(digit / 10), undefined);
    sum += parseInt(String(digit % 10), undefined);
    isSecond = !isSecond;
  }
  return sum % 10 === 0;
}

export function ValidatePassportNumber(code: string): boolean {
  const { length } = code;

  // must be between 3 and 9 characters,  may only contain alpha num chars, no lower case
  return length <= 9 && length >= 3 && /^[A-Z0-9]*$/.test(code);
}

export function ValidateEmail(email: string): boolean {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}

export function ValidateEmailDomain(email: string): string[] {
  const emailInvalidReason: string[] = [];

  if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+/.test(email)) {
    emailInvalidReason.push("Please include a username");
  }

  if (!/@/.test(email)) {
    const char = '"@"';
    emailInvalidReason.push(`Please include an ${char}`);
  }

  if (/@/.test(email)) {
    if (
      !/@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email,
      )
    ) {
      emailInvalidReason.push("Requires a valid domain");
    }
  }

  return emailInvalidReason;
}

export function ValidateRegistrationNumber(reg: string): boolean {
  const splitReg = reg.split("/");
  if (splitReg.length === 3) {
    if (
      !(
        (String(parseInt(splitReg[0], undefined)).length === 4 &&
          splitReg[0].length === 4) ||
        (String(parseInt(splitReg[0], undefined)).length === 3 &&
          splitReg[0].length === 4 &&
          splitReg[0][0] === "0")
      )
    ) {
      return false;
    }
    if (
      !(
        (String(parseInt(splitReg[1], undefined)).length === 6 &&
          splitReg[1].length === 6) ||
        (String(parseInt(splitReg[1], undefined)).length === 5 &&
          splitReg[1].length === 6 &&
          splitReg[1][0] === "0")
      )
    ) {
      return false;
    }

    if (
      !(
        (String(parseInt(splitReg[2], undefined)).length === 2 &&
          splitReg[2].length === 2) ||
        (String(parseInt(splitReg[2], undefined)).length === 1 &&
          splitReg[2].length === 2 &&
          splitReg[2][0] === "0")
      )
    ) {
      return false;
    }

    return true;
  }
  return false;
}

export function ValidateDateOfBirth(dateOfBirth: string): boolean {
  const splitReg = dateOfBirth.split("-");

  if (splitReg.length === 3) {
    if (
      !(
        (String(parseInt(splitReg[0], undefined)).length === 4 &&
          splitReg[0].length === 4) ||
        (String(parseInt(splitReg[0], undefined)).length === 3 &&
          splitReg[0].length === 4 &&
          splitReg[0][0] === "0")
      )
    ) {
      return false;
    }
    if (
      !(
        (String(parseInt(splitReg[1], undefined)).length === 2 &&
          splitReg[1].length === 2) ||
        (String(parseInt(splitReg[1], undefined)).length === 1 &&
          splitReg[1].length === 2 &&
          splitReg[1][0] === "0")
      )
    ) {
      return false;
    }

    if (
      !(
        (String(parseInt(splitReg[2], undefined)).length === 2 &&
          splitReg[2].length === 2) ||
        (String(parseInt(splitReg[2], undefined)).length === 1 &&
          splitReg[2].length === 2 &&
          splitReg[2][0] === "0")
      )
    ) {
      return false;
    }

    return true;
  }
  return false;
}

export function ValidateClientShortName(shortName: string): boolean {
  return /^([A-Z0-9]{3,4})$/.test(shortName);
}

// validator to check if password meets password policy
// Todo unit test this
export function ValidatePassword(password: string): string[] {
  const passwordInvalidReason: string[] = [];
  if (password.length <= 7) {
    passwordInvalidReason.push("Minimum 8 characters");
  }

  if (!/^(.*[a-z].*)$/.test(password)) {
    passwordInvalidReason.push("One lowercase letter");
  }

  if (!/^(.*[A-Z].*)$/.test(password)) {
    passwordInvalidReason.push("One uppercase letter");
  }

  if (!/^(.*\d.*)$/.test(password)) {
    passwordInvalidReason.push("One number");
  }

  if (!/^(.*\W.*)$/.test(password)) {
    passwordInvalidReason.push("One special character");
  }

  return passwordInvalidReason;
}

export function ValidateContactNumber(contactNum: string): boolean {
  return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
    contactNum,
  );
}

export function ValidateTaxReferenceNumber(taxNumber: string): boolean {
  if (taxNumber.length !== 10) {
    return false;
  }

  if (
    !(
      taxNumber[0] === "0" ||
      taxNumber[0] === "1" ||
      taxNumber[0] === "2" ||
      taxNumber[0] === "3" ||
      taxNumber[0] === "9"
    )
  ) {
    return false;
  }

  return LuhnAlgorithm(taxNumber);
}
