import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

export interface TimelineItemProps {
  hasConnector?: boolean;
  title: string;
  subtitle?: string;
  wrapSubtitle?: boolean;
  body?: string | React.ReactNode;
}

export const TimelineItem: React.FC<TimelineItemProps> = ({
  hasConnector,
  title,
  subtitle,
  wrapSubtitle,
  body,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldWrapSubtitle = wrapSubtitle ?? isMobile;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "start",
      }}
    >
      {/* Dot & Connector */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: theme.spacing(3),
          height: "100%",
          position: "relative",
          mt: 0.5,
        }}
      >
        {/* Dot */}
        <Box
          sx={{
            width: 12,
            height: 12,
            backgroundColor: theme.palette.warning.light,
            borderRadius: "50%",
          }}
        />

        {/* Connector if defined */}
        {hasConnector && (
          <Box
            sx={{
              width: 2,
              flexGrow: 1,
              backgroundColor: theme.palette.common.white,
              opacity: 0.32,
              height: "calc(100% - 32px)",
              position: "absolute",
              top: "22px",
            }}
          />
        )}
      </Box>

      {/* Content */}
      <Box
        sx={{
          pb: hasConnector ? 2 : 0,
        }}
      >
        {/* Title */}
        <Typography
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "baseline",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            gap: shouldWrapSubtitle ? 0 : theme.spacing(1),
          }}
        >
          {title}

          {/* Subtitle if defined */}
          {subtitle && (
            <span
              style={{
                color: theme.palette.warning.light,
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                ...(shouldWrapSubtitle && { width: "100%" }),
              }}
            >
              {subtitle}
            </span>
          )}
        </Typography>

        {/* Body if defined */}
        {body && (
          <Box
            sx={{
              color: theme.palette.text.secondary,
              lineHeight: theme.typography.body1.lineHeight,
            }}
          >
            {body}
          </Box>
        )}
      </Box>
    </Box>
  );
};
