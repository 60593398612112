import { DataSheetSection } from "./DataSheetSection";
import { IndependentReview } from "james/marketing";

export const AssetIndependentReviewDataSheetSectionTypeName =
  "mesh::marketData/IndependentReview";

export const AssetIndependentReviewDataSheetSectionName = "Independent Reviews";

export class AssetIndependentReviewDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = AssetIndependentReviewDataSheetSectionTypeName;

  public independentReview?: IndependentReview;

  constructor(l?: AssetIndependentReviewDataSheetSection) {
    if (!l) {
      return;
    }

    this.independentReview = l.independentReview;
  }

  public DataSheetSectionName(): string {
    return AssetIndependentReviewDataSheetSectionName;
  }
}
