import { Context } from "james/security";
import { UserProfile } from "james/user";
import config from "react-global-configuration";
import { User } from "./User";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { Role } from "../role";

interface RetrieveMyUserRequest {
  context: Context;
}

interface RetrieveMyUserResponse {
  user: User;
}

interface RetrieveMyProfileRequest {
  context: Context;
}

interface RetrieveMyProfileResponse {
  profile: UserProfile;
}

interface GetMyProfilePictureDownloadURLRequest {
  context: Context;
}

interface GetMyProfilePictureDownloadURLResponse {
  url: string;
}

interface GetMyProfilePictureUploadURLRequest {
  context: Context;
}

interface GetMyProfilePictureUploadURLResponse {
  url: string;
}

interface RetrieveMyRolesRequest {
  context: Context;
}

interface RetrieveMyRolesResponse {
  roles: Role[];
}

export const ManagerRead = {
  serviceProvider: "user-ManagerRead",
  async RetrieveMyProfile(
    request: RetrieveMyProfileRequest,
  ): Promise<RetrieveMyProfileResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${ManagerRead.serviceProvider}.RetrieveMyProfile`,
      request,
    });
    return { profile: new UserProfile(response.profile) };
  },
  async RetrieveMyUser(
    request: RetrieveMyUserRequest,
  ): Promise<RetrieveMyUserResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${ManagerRead.serviceProvider}.RetrieveMyUser`,
      request,
    });
    return { user: new User(response.user) };
  },
  async GetMyProfilePictureDownloadURL(
    request: GetMyProfilePictureDownloadURLRequest,
  ): Promise<GetMyProfilePictureDownloadURLResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${ManagerRead.serviceProvider}.GetMyProfilePictureDownloadURL`,
      request,
    });
  },
  async GetMyProfilePictureUploadURL(
    request: GetMyProfilePictureUploadURLRequest,
  ): Promise<GetMyProfilePictureUploadURLResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${ManagerRead.serviceProvider}.GetMyProfilePictureUploadURL`,
      request,
    });
  },
  async RetrieveMyRoles(
    request: RetrieveMyRolesRequest,
  ): Promise<RetrieveMyRolesResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${ManagerRead.serviceProvider}.RetrieveMyRoles`,
      request,
    });
    return { roles: response.roles.map((v: Role) => new Role(v)) };
  },
};
