import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DirectOrderStateChip } from "views/Marketplace/components/Orders/components/Chips";

import { Model as MarketDirectOrderViewModel } from "james/views/marketDirectOrderView";
import { DirectOrderState } from "../../../../../james/market/DirectOrder";
import { Error as ErrorIcon } from "@mui/icons-material";

const PREFIX = "OrderDirectionAndState";

const classes = {
  directionAndStateSectionLayout: `${PREFIX}-directionAndStateSectionLayout`,
  directionAndStateSectionOrderType: `${PREFIX}-directionAndStateSectionOrderType`,
};

const Root = styled("div")(() => ({
  [`&.${classes.directionAndStateSectionLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.directionAndStateSectionOrderType}`]: {
    fontWeight: "bold",
  },
}));

interface OrderDirectionAndStateProps {
  viewingAsInitiatingParty: boolean;
  marketDirectOrderViewModel: MarketDirectOrderViewModel;
}

export function OrderDirectionAndState(props: OrderDirectionAndStateProps) {
  return props.marketDirectOrderViewModel.state !== DirectOrderState.Failed ? (
    <Root className={classes.directionAndStateSectionLayout}>
      <div>
        <Typography
          className={classes.directionAndStateSectionOrderType}
          variant="h4"
          children={`${props.marketDirectOrderViewModel.orderType.toUpperCase()} ORDER`}
        />
        <Typography
          variant="body1"
          color="textSecondary"
          children={
            // if the card is being viewed from the perspective of the initiating party
            props.viewingAsInitiatingParty
              ? // then the perspective is that this order was sent to the counterparty
                `To ${props.marketDirectOrderViewModel.counterpartyClientName}`
              : // otherwise the perspective is that this order received from the initiating party
                `From ${props.marketDirectOrderViewModel.initiatingPartyClientName}`
          }
        />
      </div>
      <div>
        <DirectOrderStateChip state={props.marketDirectOrderViewModel.state} />
      </div>
    </Root>
  ) : (
    <Root className={classes.directionAndStateSectionLayout}>
      <div>
        <Typography
          className={classes.directionAndStateSectionOrderType}
          variant="h4"
          children={`${props.marketDirectOrderViewModel.orderType.toUpperCase()} ORDER`}
        />
        <Typography
          variant="body1"
          color="textSecondary"
          children={
            // if the card is being viewed from the perspective of the initiating party
            props.viewingAsInitiatingParty
              ? // then the perspective is that this order was sent to the counterparty
                `To ${props.marketDirectOrderViewModel.counterpartyClientName}`
              : // otherwise the perspective is that this order received from the initiating party
                `From ${props.marketDirectOrderViewModel.initiatingPartyClientName}`
          }
        />
      </div>
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(1),
          alignItems: "center",
        })}
      >
        <DirectOrderStateChip state={props.marketDirectOrderViewModel.state} />
        <Tooltip
          title={props.marketDirectOrderViewModel.failureReasons.join(", ")}
          placement={"top"}
          arrow
        >
          <ErrorIcon
            sx={{
              color: "secondary.light",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </Box>
    </Root>
  );
}
