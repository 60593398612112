export class InstrumentAnnualPerformanceLogEntry {
  public year = 0;

  public annualPerformance = "0";

  public monthlyPerformance: { [key: number]: string } = {};

  constructor(i?: InstrumentAnnualPerformanceLogEntry) {
    if (!i) {
      return;
    }
    this.year = i.year;
    this.annualPerformance = i.annualPerformance;
    this.monthlyPerformance = {};
    for (const monthIdx in i.monthlyPerformance) {
      if (
        Object.prototype.hasOwnProperty.call(i.monthlyPerformance, monthIdx)
      ) {
        this.monthlyPerformance[monthIdx] = i.monthlyPerformance[monthIdx];
      }
    }
  }
}
