import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";

export interface HeartbeatTickRequest {
  context: Context;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeartbeatTickResponse {}

export interface SinoatrialServiceProvider {
  serviceProvider: string;

  HeartbeatTick(request: HeartbeatTickRequest): Promise<HeartbeatTickResponse>;
}

const SinoatrialServiceProviderName = "consistency-Sinoatrial";

export const Sinoatrial: SinoatrialServiceProvider = {
  serviceProvider: SinoatrialServiceProviderName,
  async HeartbeatTick(
    request: HeartbeatTickRequest,
  ): Promise<HeartbeatTickResponse> {
    return jsonRPCRequestDEPRECATED({
      url: config.get("consistencyHorizonAuthorizedURL"),
      method: `${Sinoatrial.serviceProvider}.HeartbeatTick`,
      request,
    });
  },
};
