// Uncomment the following if validation required - atm. no validation required
// import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
// import { AnnualPerformanceLogSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeAnnualPerformanceLog_pb";
// import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { ValidationResult } from "common/validation";

export function validateAnnualPerformanceLogAttribute(): ValidationResult {
  // uncomment the following if validation is required - atm. no validation required
  // attribute: AnnualPerformanceLogSmartInstrumentAttribute,
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // uncomment the following if validation is required - atm. no validation required
  // const invalid = (field: string, message: string) => {
  //   validationResult.valid = false;
  //   validationResult.fieldValidations[
  //     `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.ANNUAL_PERFORMANCE_LOG_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
  //   ] = message;
  // };

  return validationResult;
}
