import { StellarSourceAccount } from "james/stellar";
import { Lock } from "james/locking";

export class Model extends StellarSourceAccount {
  public lock: Lock = new Lock();

  constructor(model?: Model) {
    super(model);
    if (!model) {
      return;
    }
    this.lock = new Lock(model.lock);
  }
}
