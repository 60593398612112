import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useClientRepositorySearchClients } from "james/client/Repository";
import { Query } from "james/search/query";
import { useApplicationContext } from "context/Application/Application";
import { TextExactCriterion } from "james/search/criterion";
import { Client, ClientKind } from "james/client/Client";
import { FeeDialog } from "./FeeDialog";
import { BPTable } from "components/Table";

const initialQuery: Query = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

export const IssuerFees = () => {
  const { authContext } = useApplicationContext();
  const {
    searchClientsResponse,
    searchClientsRequest,
    setSearchClientsRequest,
    loading,
  } = useClientRepositorySearchClients({
    context: authContext,
    criteria: {
      clientKind: TextExactCriterion(ClientKind.CompanyType),
    },
    query: initialQuery,
  });
  const [showDialog, setShowDialog] = useState(false);
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);

  return (
    <Box
      sx={{
        m: 3,
      }}
    >
      <BPTable
        columns={[
          {
            field: "Name",
            label: "Name",
            accessor: (data) => {
              const client = data as Client;
              return <Typography>{client.name}</Typography>;
            },
          },
        ]}
        query={searchClientsRequest.query}
        onQueryChange={(query: Query) =>
          setSearchClientsRequest({ ...searchClientsRequest, query })
        }
        onSelectedDataChange={(data) => {
          setSelectedClients(data as Client[]);
        }}
        loading={loading}
        title={"Company Clients"}
        data={searchClientsResponse.records}
        totalNoRecords={searchClientsResponse.total}
        toolBarControls={(() => {
          if (selectedClients?.length === 1) {
            return [
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowDialog(true)}
              >
                View
              </Button>,
            ];
          }
          return [];
        })()}
      />
      {showDialog && selectedClients.length === 1 && (
        <FeeDialog
          closeDialog={() => setShowDialog(false)}
          open={selectedClients?.length === 1 && showDialog}
          client={selectedClients[0]}
        />
      )}
    </Box>
  );
};
