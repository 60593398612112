import { AfterEffect } from "./AfterEffect";

export class RunningAfterEffect {
  public actionsRun: { [key: string]: number } = {};

  public afterEffect: AfterEffect = new AfterEffect();

  constructor(rt?: RunningAfterEffect) {
    if (!rt) {
      return;
    }
    this.actionsRun = { ...rt.actionsRun };
    this.afterEffect = new AfterEffect(rt.afterEffect);
  }
}
