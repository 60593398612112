import dayjs from "dayjs";

export function dateIsValid(date: dayjs.ConfigType): boolean {
  const dayjsDate = dayjs(date);
  if (!dayjsDate.isValid()) {
    return false;
  }

  return !(
    dayjsDate.date() > 31 ||
    dayjsDate.date() < 1 ||
    dayjsDate.month() > 11 ||
    dayjsDate.month() < 0 ||
    dayjsDate.year() > 2600 ||
    dayjsDate.year() < 1970
  );
}
