import BigNumber from "bignumber.js";
import { ValidationResult } from "common/validation";
import { Mechanism, MechanismType } from "james/market";

export function validateSubscriptionMarketMechanism(
  marketMechanism: Mechanism,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${MechanismType.Subscription}-${field}`
    ] = message;
  };

  // confirm only 1 quote parameter for subscriptions
  if (marketMechanism.quoteParameters.length !== 1) {
    invalid(
      "quoteParameters",
      "there should always be exactly 1 quote parameter for subscriptions",
    );
    return validationResult;
  }

  // get quote paramater to perform validations
  const quoteParamater = marketMechanism.quoteParameters[0];

  const maxDealSize = quoteParamater.maximumDealSize.value;
  const minDealSize = quoteParamater.minimumDealSize.value;

  if (maxDealSize.isLessThanOrEqualTo(0)) {
    invalid("maximumDealSize", "Must be > 0");
  }
  if (minDealSize.isLessThan(BigNumber("0.00002"))) {
    invalid("minimumDealSize", "Must be >= 0.00002");
  }
  if (maxDealSize.isLessThan(minDealSize)) {
    invalid("maximumDealSize", "Must be > Minimum");
    invalid("minimumDealSize", "Must be < Maximum");
  }

  return validationResult;
}
