import config from "react-global-configuration";
import { Context } from "../security";
import { ETNStablecoin } from "./ETNStablecoin";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";
import { ETN } from "./ETN";

export type CoordinateIssueETNStablecoinRequest = {
  context: Context;
  etnStablecoinID: string;
  issuanceAccountID: string;
};

export type CoordinateIssueETNStablecoinResponse = {
  etn: ETN;
  etnStablecoin: ETNStablecoin;
};

export type CoordinatePreIssueETNStablecoinRequest = {
  context: Context;
  etnStablecoinID: string;
  issuanceAccountID: string;
};

export type CoordinatePreIssueETNStablecoinResponse = {
  etn: ETN;
  etnStablecoin: ETNStablecoin;
};

export type CoordinateMarkETNStablecoinIssuedRequest = {
  context: Context;
  etnStablecoinID: string;
};

export type CoordinateMarkETNStablecoinIssuedResponse = {
  etn: ETN;
  etnStablecoin: ETNStablecoin;
};

export type CoordinateMarkETNStablecoinDeletedRequest = {
  context: Context;
  etnStablecoinID: string;
};

export type CoordinateMarkETNStablecoinDeletedResponse = {
  etn: ETN;
  etnStablecoin: ETNStablecoin;
};

export const ETNStablecoinStateChangerCoordinator = {
  serviceProvider: "financial-ETNStablecoinStateChangerCoordinator",
  async CoordinateIssueETNStablecoin(
    request: CoordinateIssueETNStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateIssueETNStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETNStablecoinStateChangerCoordinator.serviceProvider}.CoordinateIssueETNStablecoin`,
        request,
      },
      opts,
    );
    return {
      etn: new ETN(response.etn),
      etnStablecoin: new ETNStablecoin(response.etnStablecoin),
    };
  },
  async CoordinatePreIssueETNStablecoin(
    request: CoordinatePreIssueETNStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinatePreIssueETNStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETNStablecoinStateChangerCoordinator.serviceProvider}.CoordinatePreIssueETNStablecoin`,
        request,
      },
      opts,
    );
    return {
      etn: new ETN(response.etn),
      etnStablecoin: new ETNStablecoin(response.etnStablecoin),
    };
  },
  async CoordinateMarkETNStablecoinIssued(
    request: CoordinateMarkETNStablecoinIssuedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMarkETNStablecoinIssuedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETNStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMarkETNStablecoinIssued`,
        request,
      },
      opts,
    );
    return {
      etn: new ETN(response.etn),
      etnStablecoin: new ETNStablecoin(response.etnStablecoin),
    };
  },
  async CoordinateMarkETNStablecoinDeleted(
    request: CoordinateMarkETNStablecoinDeletedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMarkETNStablecoinDeletedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETNStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMarkETNStablecoinDeleted`,
        request,
      },
      opts,
    );
    return {
      etn: new ETN(response.etn),
      etnStablecoin: new ETNStablecoin(response.etnStablecoin),
    };
  },
};
