import React from "react";
import { Box, Button, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const EURCBanner = () => {
  return (
    <Box
      id="eurc_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "eurc_banner",
        component_business_name: "eurc banner",
        component_title: "eurc",
        component_driver: "showcase",
      })}
      data-testid="eurc_banner"
    >
      <Box
        sx={(theme) => ({
          width: {
            lg: 550,
            md: 450,
            sm: 250,
          },
          ml: {
            lg: "50px",
            md: "80px",
            sm: "60px",
          },
          mr: {
            lg: "118px",
            md: "100px",
            sm: "60px",
          },
          "& .red": {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Box>
          <Typography
            textAlign="start"
            fontSize={28}
            letterSpacing={0}
            lineHeight={"32px"}
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightBold,
              marginBottom: 1,
            })}
          >
            Trade EURC on Mesh now
          </Typography>
          <Typography
            textAlign="start"
            fontSize={18}
            letterSpacing={0}
            sx={{ marginBottom: 1.5 }}
          >
            Circle's fully backed Euro stablecoin
          </Typography>
        </Box>
        <Button
          id="eurc-viewAsset-button"
          href="https://app.mesh.trade/public/market/asset-overview?code=EURC&issuer=GDHU6WRG4IEQXM5NZ4BMPKOXHW76MZM4Y2IEMFDVXBSDP6SJY4ITNPP2&network=Stellar%20Public&utm_source=mesh-app&utm_medium=display&utm_campaign=mtg_get_ttl_eurc-listing_stc-66_sep-24_dec-24_za_pfmza_crypto_mesh-app_cro_none_platform_none&utm_marketing_tactic=cro&utm_creative_format=button&utm_id=mtg_get_ttl_eurc-listing_stc-66&utm_content=view-asset&utm_term=display_view-asset&utm_source_platform=carousel-banners"
          variant="contained"
          color="primary"
          rel="noopener noreferrer"
          target="_blank"
          data-link-info={JSON.stringify({
            content_interaction_id: "eurc-banner-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "view asset",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
        >
          View Asset
        </Button>
      </Box>
    </Box>
  );
};
