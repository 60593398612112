import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import meshMiniLogo from "../../assets/images/logo/meshLogoNoWords.svg";
import background from "assets/images/background/background.png";

const PREFIX = "Splash";

const classes = {
  root: `${PREFIX}-root`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  image: `${PREFIX}-image`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  [`&.${classes.backgroundImage}`]: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
  },

  [`& .${classes.image}`]: {
    cursor: "pointer",
  },
}));

export function Splash() {
  // sentry to notify us someone found 403 page
  useEffect(() => {
    Sentry.captureException(new Error("user found 403 page"));
  }, []);

  return (
    <Root className={classes.backgroundImage}>
      <div className={classes.root}>
        <img
          className={classes.image}
          alt=""
          width={100}
          style={{ alignSelf: "center" }}
          onClick={() => window.open("/", "_self")}
          src={meshMiniLogo}
        />
        <Typography
          variant="h6"
          style={{
            alignSelf: "center",
            marginTop: 32,
          }}
          children={"You don't have access to view this page - 403"}
        />
      </div>
    </Root>
  );
}
