import { MenuItem } from "@mui/material";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import React from "react";

interface CustomMenuItemProps {
  id: string;
  code: string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
  componentKey: number | string;
}

export const CustomMenuItem = ({
  id,
  label,
  onClick,
  disabled,
  componentKey,
}: CustomMenuItemProps) => {
  return (
    <MenuItem
      id={id}
      key={componentKey}
      onClick={onClick}
      disabled={disabled}
      data-link-info={JSON.stringify({
        content_interaction_id: "action-popup",
        content_interaction_action: InteractionAction.Click,
        content_interaction_text: `tab>my account>${label.toLocaleLowerCase()}`,
        content_interaction_type: InteractionType.Link,
        content_interaction_driver: InteractionDriver.BurnAsset,
      } as DataLinkInfoType)}
    >
      {label}
    </MenuItem>
  );
};
