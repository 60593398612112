import React, { useMemo } from "react";
import { Box, alpha } from "@mui/material";
import { motion } from "framer-motion";
import min from "lodash/min";
import BigNumber from "bignumber.js";

export interface LoadingBarProps {
  percent: BigNumber;
  backgroundColor?: string;
  secondaryPercent?: BigNumber;
}

/**
 * @component
 * @prop {number} percent is a percentage number eg: 100, 29, 33.33 etc...
 * @example
 * ```
 *  <LoadingBar percent={33.34} />
 * ```
 */
export const LoadingBar = ({
  percent,
  backgroundColor,
  secondaryPercent,
}: LoadingBarProps) => {
  const [width, overSubscribeWidth] = useMemo(() => {
    return percent.isGreaterThan(100)
      ? ["100%", `${min([percent.minus(100).toFixed(), 100])}%`]
      : [`${percent}%`, 0];
  }, [percent]);

  const secondaryPercentWidth = useMemo(() => {
    return `${secondaryPercent}%`;
  }, [secondaryPercent]);

  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        backgroundColor: backgroundColor ?? theme.palette.custom.midnight,
        borderRadius: "8px",
        width: "100%",
        height: "12px",
      })}
    >
      {secondaryPercent && secondaryPercent.isGreaterThan(0) && (
        <Box
          component={motion.div}
          initial={{
            width: 0,
          }}
          animate={{
            width: secondaryPercentWidth,
            transition: {
              delay: 0.2,
              duration: 1,
              type: "spring",
              stiffness: 50,
            },
          }}
          sx={(theme) => ({
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            minWidth: "16px",
            borderRadius: "8px",
            border: `1px solid ${theme.palette.info.light}`,
            background: `linear-gradient(to right, ${theme.palette.background.default} ${width}, ${alpha(theme.palette.secondary.main, 1.0)})`,
          })}
        />
      )}
      {percent.isGreaterThan(0) && (
        <Box
          component={motion.div}
          initial={{
            width: 0,
          }}
          animate={{
            width: width,
            transition: {
              delay: 0.2,
              duration: 1,
              type: "spring",
              stiffness: 50,
            },
          }}
          sx={(theme) => ({
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            minWidth: "16px",
            borderRadius: "8px",
            border: `1px solid ${theme.palette.success.light}`,
            background: `linear-gradient(to right, ${theme.palette.background.default}, ${theme.palette.success.light})`,
          })}
        />
      )}
      {percent.isGreaterThan(100) && (
        <Box
          component={motion.div}
          initial={{
            width: 0,
          }}
          animate={{
            width: overSubscribeWidth,
            transition: {
              delay: 1,
              duration: 1,
              type: "spring",
              stiffness: 50,
            },
          }}
          sx={(theme) => ({
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            minWidth: "16px",
            borderRadius: "8px",
            border: `1px solid ${theme.palette.success.light}`,
            backgroundColor: theme.palette.success.light,
          })}
        />
      )}
    </Box>
  );
};
