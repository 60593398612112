import { Context } from "james/security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export type FullUpdateRequest = {
  context: Context;
};

export type FullUpdateResponse = Record<string, never>;

export const Updater = {
  serviceProvider: "financialYieldBearingStablecoinView-Updater",
  async FullUpdate(request: FullUpdateRequest): Promise<FullUpdateResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Updater.serviceProvider}.FullUpdate`,
      request,
    });
  },
};
