import { Asset } from "james/ledger/Asset";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger/Token";
import { ETFStablecoin, ETFStablecoinTypeName } from "./ETFStablecoin";
import { ETNStablecoin, ETNStablecoinTypeName } from "./ETNStablecoin";
import { FinancialInstrumentStablecoinState } from "./InstrumentStablecoinState";

export interface InstrumentStablecoin extends Asset {
  instrumentStablecoinInstrumentID(): string;

  instrumentStablecoinValuationToken(): Token;

  instrumentStablecoinMaturityDate(): string;

  instrumentStablecoinMaximumUnits(): Amount;

  instrumentStablecoinState(): FinancialInstrumentStablecoinState;
}

export function NewInstrumentStablecoin(
  is: InstrumentStablecoin,
): InstrumentStablecoin {
  switch (is["@type"]) {
    case ETFStablecoinTypeName:
      return new ETFStablecoin(is as ETFStablecoin);

    case ETNStablecoinTypeName:
      return new ETNStablecoin(is as ETNStablecoin);

    default:
      throw new TypeError(`unknown instrument stablecoin type ${is["@type"]}`);
  }
}

export function InstrumentStablecoinImplementedBy(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
): object is InstrumentStablecoin {
  return [ETFStablecoinTypeName, ETNStablecoinTypeName].includes(
    object["@type"],
  );
}
