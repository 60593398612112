import { Box, Typography } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import React from "react";

interface ContinueWithFacebookProps {
  onClick?: () => void;
  dataLinkAttribute?: string;
}

export const ContinueWithFacebook = (props: ContinueWithFacebookProps) => (
  <Box
    id={"authenticate-with-facebook-button"}
    sx={(theme) => ({
      display: "flex",
      cursor: "pointer",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.info.dark,
      gridTemplateColumns: "auto 1fr",
      gridColumnGap: 10,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: theme.spacing(6),
      marginBottom: theme.spacing(2),
    })}
    data-link-info={props.dataLinkAttribute}
    onClick={props.onClick}
  >
    <FacebookRoundedIcon
      sx={{
        height: 38,
      }}
    />
    <Typography
      fontSize="17px"
      variant="h4"
      children="Continue with Facebook"
    />
  </Box>
);
