import { ValidationResult } from "common/validation";
import { Amount } from "james/ledger";
import { NumFieldHlpTxt } from "validationHelperText";
import { FormData } from "./MintDialog";

export type FormUpdaterSpecsType = {
  mintAmount: (mintAmount: Amount, prevFormData?: FormData) => FormData;
  destinationAccountAddress: (
    destinationAccountAddress: string,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  mintAmount(mintAmount: Amount, prevFormData?: FormData): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      mintAmount,
    };
  },
  destinationAccountAddress(
    destinationAccountAddress: string,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      destinationAccountAddress,
    };
  },
};

export const validationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // confirm given value for mintAmount is > 0
  if (formData.mintAmount.value.isZero()) {
    validationResult.valid = false;
    validationResult.fieldValidations.mintAmount =
      NumFieldHlpTxt.MustBeGreaterThan0;
  }

  return validationResult;
};
