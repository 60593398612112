import dayjs from "dayjs";
import { Amount } from "james/ledger";
import { DirectOrderState, DirectOrderType } from "../../market/DirectOrder";

export const ModelTypeName = "mesh::marketDirectOrderView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  public id = "";

  public directOrderID = "";

  public number = "";

  public orderType: DirectOrderType | "" = "";

  public state: DirectOrderState | "" = "";

  public initiatingPartyClientID = "";

  public initiatingPartyGroupID = "";

  public initiatingPartyClientName = "";

  public initiatingPartyProfilePictureDLURL = "";

  public counterpartyClientID = "";

  public counterpartyGroupID = "";

  public counterpartyClientName = "";

  public counterpartyProfilePictureDLURL = "";

  public amountIncl: Amount = new Amount();

  public price: Amount = new Amount();

  public tokens: Amount = new Amount();

  public feeAmount: Amount = new Amount();

  public vatRate: string = "0";

  public orderDateTime: string = dayjs().format();

  public listingID = "";

  public assetOwnerID = "";

  public assetShortName = "";

  public assetIssuingClientID = "";

  public assetName = "";

  public assetID = "";

  public lastModifiedTime: string = dayjs().format();

  public clearanceAccountLedgerID = "";

  public stateResolutionCount = 0;

  public failureReasons: string[] = [];

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;

    this.directOrderID = model.directOrderID;
    this.number = model.number;
    this.orderType = model.orderType;
    this.state = model.state;

    this.initiatingPartyClientID = model.initiatingPartyClientID;
    this.initiatingPartyGroupID = model.initiatingPartyGroupID;
    this.initiatingPartyClientName = model.initiatingPartyClientName;
    this.initiatingPartyProfilePictureDLURL =
      model.initiatingPartyProfilePictureDLURL;

    this.counterpartyClientID = model.counterpartyClientID;
    this.counterpartyGroupID = model.counterpartyGroupID;
    this.counterpartyClientName = model.counterpartyClientName;
    this.counterpartyProfilePictureDLURL =
      model.counterpartyProfilePictureDLURL;

    this.amountIncl = new Amount(model.amountIncl);
    this.price = new Amount(model.price);
    this.tokens = new Amount(model.tokens);
    this.feeAmount = new Amount(model.feeAmount);
    this.vatRate = model.vatRate;

    this.orderDateTime = model.orderDateTime;

    this.listingID = model.listingID;
    this.assetOwnerID = model.assetOwnerID;
    this.assetName = model.assetName;
    this.assetShortName = model.assetShortName;
    this.assetIssuingClientID = model.assetIssuingClientID;
    this.assetID = model.assetID;

    this.lastModifiedTime = model.lastModifiedTime;

    this.clearanceAccountLedgerID = model.clearanceAccountLedgerID;

    this.stateResolutionCount = model.stateResolutionCount;

    this.failureReasons = [...model.failureReasons];
  }
}
