import { SpotType } from "./Spot";
import { Amount, Token } from "pkgTemp/ledger/TokenAmount";
import { SpotPricer } from "./SpotPricer";
import {
  ExcessiveTruncationError,
  InvalidSpotTypeError,
  UnexpectedAmountError,
} from "./errors";
import BigNumber from "bignumber.js";

export type CalculateSpotRequest = {
  noFee: boolean;
  spotType: SpotType;
  baseAmount: Amount;
  quoteAmount: Amount;
};

export type CalculateSpotResponse = {
  baseAmount: Amount;
  quoteAmount: Amount;
  estimatedPrice: Amount;
  exactFeeAmount: Amount;
  vatAmount: Amount;
  estFeeAmount: Amount;
  path: Token[];
};

export type NewSpotCalculatorProps = {
  feeMultiplier: BigNumber;
  vatRate: BigNumber;
  spotPricer: SpotPricer;
};

const big1 = new BigNumber("1");
const big0 = new BigNumber("0");

export class SpotCalculator {
  private readonly vatRate: BigNumber;
  private readonly feeMultiplier: BigNumber;
  private readonly spotPricer: SpotPricer;

  constructor(props: NewSpotCalculatorProps) {
    this.feeMultiplier = props.feeMultiplier;
    this.vatRate = props.vatRate;
    this.spotPricer = props.spotPricer;
  }

  async CalculateSpot(
    request: CalculateSpotRequest,
  ): Promise<CalculateSpotResponse> {
    // confirm that neither base nor quote amount are undefined
    if (request.baseAmount.isUndefined()) {
      throw new UnexpectedAmountError("expected base amount to be defined");
    }
    if (request.quoteAmount.isUndefined()) {
      throw new UnexpectedAmountError("expected quote amount to be defined");
    }

    // confirm that only one of the given amounts (base or quote) are zero
    if (
      request.baseAmount.value.isZero() === request.quoteAmount.value.isZero()
    ) {
      throw new UnexpectedAmountError(
        "only one of base or quote amount should be zero",
      );
    }

    // confirm that neither base nor quote amount are < 0
    if (request.baseAmount.value.isNegative()) {
      throw new UnexpectedAmountError(
        `base amount '${request.baseAmount.value}' is < 0`,
      );
    }
    if (request.quoteAmount.value.isNegative()) {
      throw new UnexpectedAmountError(
        `quote amount '${request.quoteAmount.value}' is < 0`,
      );
    }

    // proceed based on given spot type and whether given base or quote amount is zero
    switch (request.spotType) {
      case SpotType.Buy: {
        if (request.baseAmount.value.isZero()) {
          // BUY with base amount zero i.e.
          // BUY some amount of the base asset for an exact quoteAmount.
          // Given quote amount includes fee.

          // Where sendAmount is the exact amount of quote asset that
          // will be sent to BUY some amount of base asset, feeAmount
          // is calculated as follows:
          //    quoteAmount = sendAmount + sendAmount*feeMultiplier*(1+vatRate)
          //      :- sendAmount = quoteAmount/(1 + feeMultiplier*(1+vatRate))
          //      :- feeAmount = sendAmount*feeMultiplier
          //      :- feeAmount = (quoteAmount*feeMultiplier)/(1 + feeMultiplier*(1+vatRate))
          const rawFeeAmount = request.noFee
            ? big0
            : request.quoteAmount.value.multipliedBy(
                this.feeMultiplier.dividedBy(
                  big1.plus(
                    this.feeMultiplier.multipliedBy(big1.plus(this.vatRate)),
                  ),
                ),
              );
          const feeAmount = request.quoteAmount.setValue(rawFeeAmount);
          if (!request.noFee && feeAmount.value.isZero()) {
            throw new ExcessiveTruncationError("feeAmount", rawFeeAmount);
          }
          const vatAmount = feeAmount.setValue(
            feeAmount.value.multipliedBy(this.vatRate),
          );

          // price the spot
          const priceSpotResponse = await this.spotPricer.PriceSpot({
            spotType: request.spotType,
            baseAmount: request.baseAmount,
            quoteAmount: request.quoteAmount.setValue(
              request.quoteAmount.value
                .minus(feeAmount.value)
                .minus(vatAmount.value),
            ),
          });

          // return result
          return {
            baseAmount: priceSpotResponse.baseAmount,
            quoteAmount: request.quoteAmount,
            exactFeeAmount: feeAmount,
            vatAmount,
            estFeeAmount: feeAmount,
            estimatedPrice: priceSpotResponse.estimatedPrice,
            path: priceSpotResponse.path,
          };
        } else {
          // BUY with quote amount zero i.e.
          // BUY an exact amount of the base asset for some quoteAmount.
          // Given base amount excludes fee.

          // Where receive amount is the exact amount of base asset that will be received
          // fee amount is calculated as follows (note: receiveAmount == baseAmount):
          //    exactFeeAmount = receiveAmount*feeMultiplier
          const rawExactFeeAmount = request.noFee
            ? big0
            : request.baseAmount.value.multipliedBy(this.feeMultiplier);
          const exactFeeAmount = request.baseAmount.setValue(rawExactFeeAmount);
          if (!request.noFee && exactFeeAmount.value.isZero()) {
            throw new ExcessiveTruncationError(
              "exactFeeAmount",
              rawExactFeeAmount,
            );
          }
          const exactVatAmount = exactFeeAmount.setValue(
            exactFeeAmount.value.multipliedBy(this.vatRate),
          );

          // price the spot
          const priceSpotResponse = await this.spotPricer.PriceSpot({
            spotType: request.spotType,
            // Base amount for pricing calculated by adding fee amount
            // since the fee amount is not included in the given base
            // amount and will need to be bought.
            // (Note: excessive truncation error cannot take place here)
            baseAmount: request.baseAmount.setValue(
              request.baseAmount.value
                .plus(exactFeeAmount.value)
                .plus(exactVatAmount.value),
            ),
            quoteAmount: request.quoteAmount,
          });

          // est. fee amount is given in quote amount and is calculated as follows:
          // estFeeAmount = exactFeeAmount*estimatedPrice
          const rawEstFeeAmount = exactFeeAmount.value.multipliedBy(
            priceSpotResponse.estimatedPrice.value,
          );
          const estFeeAmount = request.quoteAmount.setValue(rawEstFeeAmount);
          if (!request.noFee && estFeeAmount.value.isZero()) {
            throw new ExcessiveTruncationError("estFeeAmount", rawEstFeeAmount);
          }
          const estVatAmount = estFeeAmount.setValue(
            estFeeAmount.value.multipliedBy(this.vatRate),
          );

          // return result
          return {
            baseAmount: request.baseAmount,
            quoteAmount: priceSpotResponse.quoteAmount,
            exactFeeAmount,
            estFeeAmount,
            vatAmount: estVatAmount,
            estimatedPrice: priceSpotResponse.estimatedPrice,
            path: priceSpotResponse.path,
          };
        }
      }

      case SpotType.Sell: {
        if (request.baseAmount.value.isZero()) {
          // SELL with base amount zero i.e.
          // SELL some amount of the base asset to receive an exact quoteAmount.
          // Given quote amount excludes fee.

          // Where receive amount is the exact amount of quote asset that will be received
          // fee amount is calculated as follows (note: receiveAmount == quoteAmount):
          //    exactFeeAmount = receiveAmount*feeMultiplier
          const rawExactFeeAmount = request.noFee
            ? big0
            : request.quoteAmount.value.multipliedBy(this.feeMultiplier);
          const exactFeeAmount =
            request.quoteAmount.setValue(rawExactFeeAmount);
          if (!request.noFee && exactFeeAmount.value.isZero()) {
            throw new ExcessiveTruncationError(
              "exactFeeAmount",
              rawExactFeeAmount,
            );
          }
          const exactVatAmount = exactFeeAmount.setValue(
            exactFeeAmount.value.multipliedBy(this.vatRate),
          );

          // price the spot
          const priceSpotResponse = await this.spotPricer.PriceSpot({
            spotType: request.spotType,
            baseAmount: request.baseAmount,
            // Quote amount for pricing calculated by adding fee amount
            // since the fee amount is not included in the given quote
            // amount and will need to be bought.
            // (Note: excessive truncation error cannot take place here)
            quoteAmount: request.quoteAmount.setValue(
              request.quoteAmount.value
                .plus(exactFeeAmount.value)
                .plus(exactVatAmount.value),
            ),
          });

          // est. fee amount is given in base amount and is calculated as follows:
          // estFeeAmount = exactFeeAmount/estimatedPrice
          const rawEstFeeAmount = exactFeeAmount.value.dividedBy(
            priceSpotResponse.estimatedPrice.value,
          );
          const estFeeAmount = request.baseAmount.setValue(
            exactFeeAmount.value.dividedBy(
              priceSpotResponse.estimatedPrice.value,
            ),
          );
          if (!request.noFee && estFeeAmount.value.isZero()) {
            throw new ExcessiveTruncationError("estFeeAmount", rawEstFeeAmount);
          }
          const estVatAmount = estFeeAmount.setValue(
            estFeeAmount.value.multipliedBy(this.vatRate),
          );

          // return result
          return {
            baseAmount: priceSpotResponse.baseAmount,
            quoteAmount: request.quoteAmount,
            exactFeeAmount,
            estFeeAmount,
            vatAmount: estVatAmount,
            estimatedPrice: priceSpotResponse.estimatedPrice,
            path: priceSpotResponse.path,
          };
        } else {
          // SELL with quote amount zero i.e.
          // SELL an exact amount of the base asset for some quoteAmount.
          // Given base amount includes fee.

          // Where sendAmount is the exact amount of base asset that
          // will be sent (i.e. SOLD) to get some amount of quote asset, feeAmount
          // is calculated as follows:
          //    baseAmount = sendAmount + sendAmount*feeMultiplier
          //      :- sendAmount = baseAmount/(1 + feeMultiplier)*(1+vatRate)
          //    feeAmount = sendAmount*feeMultiplier
          //      :- feeAmount = (baseAmount*feeMultiplier)/(1 + feeMultiplier*(1+vatRate))
          const rawFeeAmount = request.noFee
            ? big0
            : request.baseAmount.value.multipliedBy(
                this.feeMultiplier.dividedBy(
                  big1.plus(
                    this.feeMultiplier.multipliedBy(big1.plus(this.vatRate)),
                  ),
                ),
              );
          const feeAmount = request.baseAmount.setValue(rawFeeAmount);
          if (!request.noFee && feeAmount.value.isZero()) {
            throw new ExcessiveTruncationError("feeAmount", rawFeeAmount);
          }

          const vatAmount = feeAmount.setValue(
            feeAmount.value.multipliedBy(this.vatRate),
          );

          // price the spot
          const priceSpotResponse = await this.spotPricer.PriceSpot({
            spotType: request.spotType,
            quoteAmount: request.quoteAmount,
            baseAmount: request.baseAmount.setValue(
              request.baseAmount.value
                .minus(feeAmount.value)
                .minus(vatAmount.value),
            ),
          });

          // return result
          return {
            baseAmount: request.baseAmount,
            quoteAmount: priceSpotResponse.quoteAmount,
            exactFeeAmount: feeAmount,
            estFeeAmount: feeAmount,
            vatAmount,
            estimatedPrice: priceSpotResponse.estimatedPrice,
            path: priceSpotResponse.path,
          };
        }
      }

      default:
        throw new InvalidSpotTypeError(request.spotType);
    }
  }
}
