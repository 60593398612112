import React, {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useState,
} from "react";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Typography, Link, Box } from "@mui/material";
import { ClientKYCStatus } from "james/client";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { ClientKind } from "james/client/Client";

interface KYCStatusBannerProps {
  setHideBanner: Dispatch<SetStateAction<boolean>>;
}

export function KYCStatusBanner({ setHideBanner }: KYCStatusBannerProps) {
  const { myClient, myClientRetrievalErr } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [
    displayKYCOutstandingWithoutReasonSnackbar,
    setDisplayKYCOutstandingWithoutReasonSnackbar,
  ] = useState(false);
  const [
    displayKYCOutstandingWithReasonSnackbar,
    setDisplayKYCOutstandingWithReasonSnackbar,
  ] = useState(false);
  const [
    displayKYCVerificationInProgressSnackbar,
    setKYCVerificationInProgressSnackbar,
  ] = useState(false);
  const navigate = useNavigate();

  // determine which KYC snackbars should be shown on log in
  useLayoutEffect(() => {
    if (myClientRetrievalErr && !myClient) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      return;
    }

    if (!myClient) {
      return;
    }

    setDisplayKYCOutstandingWithoutReasonSnackbar(
      myClient.kycStatus === ClientKYCStatus.Outstanding &&
        myClient.kycStatusReason === "",
    );
    setDisplayKYCOutstandingWithReasonSnackbar(
      myClient.kycStatus === ClientKYCStatus.Outstanding &&
        myClient.kycStatusReason !== "",
    );
    setKYCVerificationInProgressSnackbar(
      myClient.kycStatus === ClientKYCStatus.VerificationInProgressStatus,
    );
  }, [myClient]);

  return (
    <>
      {displayKYCOutstandingWithoutReasonSnackbar && (
        <Box
          id={"KYCStatusBanner-KYCOutstandingWithoutReason-Div"}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.warning.dark,
            height: theme.spacing(6),
            alignItems: "center",
            justifyContent: "space-between",
            width: window.innerWidth,
            px: 2,
          })}
        >
          <Box
            sx={() => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            })}
          >
            <WarningIcon />
            <Typography
              sx={(theme) => ({
                paddingLeft: theme.spacing(1.5),
                fontSize: "12px",
              })}
              variant="body2"
            >
              Identity verification required.{" "}
              {myClient && myClient.clientKind !== ClientKind.CompanyType && (
                <Link
                  id={
                    "KYCStatusBanner-KYCOutstandingWithoutReason-VerifyNow-Link"
                  }
                  sx={(theme) => ({
                    color: theme.palette.text.primary,
                    textDecoration: "underline",
                    textDecorationColor: theme.palette.text.primary,
                  })}
                  onClick={() => {
                    navigate("/kyc");
                  }}
                  fontSize="12px"
                  component="button"
                  underline="always"
                >
                  Verify now
                </Link>
              )}
            </Typography>
          </Box>
          <IconButton
            size="small"
            id={"KYCStatusBanner-KYCOutstandingWithoutReason-Close-Button"}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
            })}
            onClick={() => {
              displayKYCOutstandingWithoutReasonSnackbar
                ? setDisplayKYCOutstandingWithoutReasonSnackbar(false)
                : setDisplayKYCOutstandingWithReasonSnackbar(false);
              setHideBanner(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {displayKYCOutstandingWithReasonSnackbar && (
        <Box
          id={"KYCStatusBanner-KYCOutstandingWithReason-Div"}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.warning.dark,
            height: theme.spacing(6),
            alignItems: "center",
            justifyContent: "space-between",
            width: window.innerWidth,
            pr: 1,
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: theme.spacing(2),
            })}
          >
            <WarningIcon />
            <Typography
              sx={(theme) => ({
                paddingLeft: theme.spacing(1.5),
                fontSize: "12px",
              })}
              variant="body2"
            >
              Identity verification unsuccessful.{" "}
              <Link
                id={"KYCStatusBanner-KYCOutstandingWithReason-Review-Link"}
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                  textDecorationColor: theme.palette.text.primary,
                })}
                onClick={() => {
                  navigate("/kyc");
                }}
                fontSize="12px"
                component="button"
                underline="always"
              >
                Review
              </Link>
            </Typography>
          </Box>
          <IconButton
            size="small"
            id={"KYCStatusBanner-KYCOutstandingWithReason-Close-Button"}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
              mr: 1,
            })}
            onClick={() => {
              displayKYCOutstandingWithReasonSnackbar
                ? setDisplayKYCOutstandingWithReasonSnackbar(false)
                : setDisplayKYCOutstandingWithReasonSnackbar(false);
              setHideBanner(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {displayKYCVerificationInProgressSnackbar && (
        <Box
          id={"KYCStatusBanner-KYCVerificationInProgress-Div"}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.info.dark,
            height: theme.spacing(6),
            alignItems: "center",
            justifyContent: "space-between",
            width: window.innerWidth,
            pr: 2,
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: theme.spacing(2),
            })}
          >
            <InfoIcon />
            <Typography
              sx={(theme) => ({
                paddingLeft: theme.spacing(1.5),
                fontSize: "12px",
              })}
              variant="body2"
            >
              Your information is being verified
            </Typography>
          </Box>
          <IconButton
            size="small"
            id={"KYCStatusBanner-KYCVerificationInProgress-Close-Button"}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
            })}
            onClick={() => {
              displayKYCVerificationInProgressSnackbar
                ? setKYCVerificationInProgressSnackbar(false)
                : setKYCVerificationInProgressSnackbar(false);
              setHideBanner(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}
