import { Entry } from "james/audit/Entry";
import { Operation } from "./Operation";

export const ReadOperationTypeName = "consistency::consistency/ReadOperation";

export class ReadOperation implements Operation {
  public ["@type"]: string = ReadOperationTypeName;

  public id = "";

  public transactionID = "";

  public conflictSignatures: string[] = [];

  public serviceURI = "";

  public metaData: { [key: string]: string } = {};

  public auditEntry: Entry = new Entry();

  constructor(readOperation?: ReadOperation) {
    if (!readOperation) {
      return;
    }
    this.id = readOperation.id;
    this.transactionID = readOperation.transactionID;
    this.conflictSignatures = [...readOperation.conflictSignatures];
    this.serviceURI = readOperation.serviceURI;
    this.metaData = { ...readOperation.metaData };
    this.auditEntry = new Entry(readOperation.auditEntry);
  }

  operationID(): string {
    return this.id;
  }

  operationConflictSignatures(): string[] {
    return this.conflictSignatures;
  }

  operationServiceURI(): string {
    return this.serviceURI;
  }

  operationAuditEntry(): Entry {
    return this.auditEntry;
  }

  operationMetadata(): { [key: string]: string } {
    return this.metaData;
  }
}
