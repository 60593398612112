import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Token } from "james/ledger";
import { useAssetColorPicker } from "hooks";

const shortenName = (name: string) => {
  return name.substring(0, 2) + ".." + name.substring(name.length - 3);
};

interface LegendProps {
  data: { asset: Token; percentage: string }[];
}

export function ChartLegend(props: LegendProps) {
  const { data } = props;
  const getColor = useAssetColorPicker(data.length === 0);
  return (
    <Box
      className={"meshScroll"}
      sx={(theme) => ({
        display: "grid",
        gridTemplateRows: `repeat(${props.data.length}, auto)`,
        rowGap: theme.spacing(),
        marginTop: theme.spacing(),
        justifyContent: "start",
        overflowY: "auto",
        height: "65px",
      })}
    >
      {data.map((entry) => (
        <Tooltip
          key={`${entry.asset.code.toString()}:${entry.asset.issuer.toString()}`}
          title={`${entry.percentage.toString()}% - ${entry.asset.code}`}
        >
          <Box
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "15px auto auto",
              color: theme.palette.text.secondary,
              alignItems: "center",
            })}
          >
            <div>
              <Box
                sx={(theme) => ({
                  height: theme.spacing(),
                  backgroundColor: `${getColor(
                    `${entry.asset.code.toString()}:${entry.asset.issuer.toString()}`,
                  )}`,
                  width: theme.spacing(),
                  borderRadius: theme.spacing(),
                })}
              />
            </div>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                textTransform: "none",
              })}
              variant={"overline"}
            >
              {`${entry.percentage.toString()}% -`}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                textTransform: "none",
              })}
              variant={"overline"}
            >
              {entry.asset.code.length > 6
                ? shortenName(entry.asset.code)
                : entry.asset.code}
            </Typography>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
}
