import React, { useContext, useMemo } from "react";
import { FeeScheduleCreatorPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleCreator_grpc_web_pb";
import { FeeScheduleUpdaterPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleUpdater_grpc_web_pb";
import { FeeScheduleReaderPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleReader_meshproto_grpc_web_pb";
import { SmartInstrumentRateResetGeneratorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentRateResetGenerator_grpc_web_pb";
import { SmartInstrumentCalculatorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentCalculator_grpc_web_pb";
import { SmartInstrumentStateControllerPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentStateController_grpc_web_pb";
import { SmartInstrumentUpdaterPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentUpdater_grpc_web_pb";
import { SmartInstrumentReaderPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_grpc_web_pb";
import { PrimeRateRecorderPromiseClient } from "@mesh/common-js/dist/financial/primeRateRecorder_grpc_web_pb";
import { PrimeRateReaderPromiseClient } from "@mesh/common-js/dist/financial/primeRateReader_meshproto_grpc_web_pb";
import { SmartInstrumentReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentReaderUNSCOPED_meshproto_grpc_web_pb";
import { SmartInstrumentOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentOrchestrator_grpc_web_pb";
import { RateResetReaderPromiseClient } from "@mesh/common-js/dist/financial/rateResetReader_meshproto_grpc_web_pb";
import { RateResetReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/rateResetReaderUNSCOPED_meshproto_grpc_web_pb";
import { OracleDataSynchroniserPromiseClient } from "@mesh/common-js/dist/financial/oracleDataSynchroniser_grpc_web_pb";
import { OracleDataSynchronisationOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/oracleDataSynchronisationOrchestrator_grpc_web_pb";
import { AssetflowDeferralReaderPromiseClient } from "@mesh/common-js/dist/financial/assetflowDeferralReader_meshproto_grpc_web_pb";
import { AssetflowDeferralReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/assetflowDeferralReaderUNSCOPED_meshproto_grpc_web_pb";
import { AssetflowReaderPromiseClient } from "@mesh/common-js/dist/financial/assetflowReader_meshproto_grpc_web_pb";
import { AssetflowReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/assetflowReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelReaderPromiseClient as FinancialPaymentViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/financialPaymentView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as FinancialPaymentViewModelReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/views/financialPaymentView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as FinancialPaymentViewModelUpdaterPromiseClient } from "@mesh/common-js/dist/views/financialPaymentView/modelUpdater_grpc_web_pb";
import { ModelReaderPromiseClient as FinancialPaymentFundingViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as FinancialPaymentFundingViewModelUpdaterPromiseClient } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelUpdater_grpc_web_pb";
import { PublicSubscriptionCommitmentManagerPromiseClient } from "@mesh/common-js/dist/market/publicSubscriptionCommitmentManager_grpc_web_pb";
import { SubscriptionCommitmentManagerPromiseClient } from "@mesh/common-js/dist/market/subscriptionCommitmentManager_grpc_web_pb";
import { AssetHolderLookupReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/ledger/assetHolderLookupReaderUNSCOPED_meshproto_grpc_web_pb";
import { AssetHolderLookupReaderPromiseClient } from "@mesh/common-js/dist/ledger/assetHolderLookupReader_meshproto_grpc_web_pb";
import { PaymentRecipientReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/paymentRecipientReaderUNSCOPED_meshproto_grpc_web_pb";
import { PaymentRecipientReaderPromiseClient } from "@mesh/common-js/dist/financial/paymentRecipientReader_meshproto_grpc_web_pb";
import { PaymentTriggerFetcherPromiseClient } from "@mesh/common-js/dist/financial/paymentTriggerFetcher_grpc_web_pb";
import { PaymentTriggerFetcherUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/paymentTriggerFetcherUNSCOPED_grpc_web_pb";
import { AssetHolderLookupOrchestratorPromiseClient } from "@mesh/common-js/dist/ledger/assetHolderLookupOrchestrator_grpc_web_pb";
import { SubscriptionOrderBookOrchestratorPromiseClient } from "@mesh/common-js/dist/market/subscriptionOrderBookOrchestrator_grpc_web_pb";
import { PaymentFunderPromiseClient } from "@mesh/common-js/dist/financial/paymentFunder_grpc_web_pb";
import { PaymentStateControllerPromiseClient } from "@mesh/common-js/dist/financial/paymentStateController_grpc_web_pb";
import { PaymentOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/paymentOrchestrator_grpc_web_pb";
import config from "react-global-configuration";
import { LoggingInterceptor } from "./LoggingInterceptor";
import { FundingOrderManagerPromiseClient } from "@mesh/common-js/dist/banking/fundingOrderManager_grpc_web_pb";
import { FundingOrderStateControllerPromiseClient } from "@mesh/common-js/dist/banking/fundingOrderStateController_grpc_web_pb";
import { FundingOrderReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/banking/fundingOrderReaderUNSCOPED_meshproto_grpc_web_pb";
import { SimulatedTimeInterceptor } from "./SimulatedTimeInterceptor";
import { useSimulatedTimeContext } from "context/Time/SimulatedTime";
import { LimitOrderStateControllerPromiseClient } from "@mesh/common-js/dist/market/limitOrderStateController_grpc_web_pb";
import { ModelReaderPromiseClient as MarketLimitOrderViewReaderPromiseClient } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as MarketLimitOrderViewReaderUnscopedPromiseClient } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as MarketLimitOrderViewUpdaterPromiseClient } from "@mesh/common-js/dist/views/marketLimitOrderView/modelUpdater_grpc_web_pb";
import { LimitOrderFeeCalculatorPromiseClient } from "@mesh/common-js/src/market/limitOrderFeeCalculator_grpc_web_pb";

export type API = {
  remuneration: {
    feeScheduleCreator: FeeScheduleCreatorPromiseClient;
    feeScheduleUpdater: FeeScheduleUpdaterPromiseClient;
    feeScheduleReader: FeeScheduleReaderPromiseClient;
  };
  financial: {
    // financial.SmartInstrument
    smartInstrumentCalculator: SmartInstrumentCalculatorPromiseClient;
    smartInstrumentStateController: SmartInstrumentStateControllerPromiseClient;
    smartInstrumentOrchestrator: SmartInstrumentOrchestratorPromiseClient;
    smartInstrumentUpdater: SmartInstrumentUpdaterPromiseClient;
    smartInstrumentReader: SmartInstrumentReaderPromiseClient;
    primeRateRecorder: PrimeRateRecorderPromiseClient;
    primeRateReader: PrimeRateReaderPromiseClient;
    smartInstrumentReaderUNSCOPED: SmartInstrumentReaderUNSCOPEDPromiseClient;
    // financial.OracleData
    oracleDataSynchroniser: OracleDataSynchroniserPromiseClient;
    oracleDataSynchronisationOrchestrator: OracleDataSynchronisationOrchestratorPromiseClient;
    // financial.RateReset
    smartInstrumentRateResetGenerator: SmartInstrumentRateResetGeneratorPromiseClient;
    rateResetReader: RateResetReaderPromiseClient;
    rateResetReaderUNSCOPED: RateResetReaderUNSCOPEDPromiseClient;
    // financial.Assetflow
    assetflowReader: AssetflowReaderPromiseClient;
    assetflowReaderUNSCOPED: AssetflowReaderUNSCOPEDPromiseClient;
    // financial.AssetflowDeferral
    assetflowDeferralReader: AssetflowDeferralReaderPromiseClient;
    assetflowDeferralReaderUNSCOPED: AssetflowDeferralReaderUNSCOPEDPromiseClient;
    // financial.PaymentTrigger
    paymentTriggerFetcher: PaymentTriggerFetcherPromiseClient;
    paymentTriggerFetcherUNSCOPED: PaymentTriggerFetcherUNSCOPEDPromiseClient;
    // financial.Payment
    paymentFunder: PaymentFunderPromiseClient;
    paymentStateController: PaymentStateControllerPromiseClient;
    paymentOrchestrator: PaymentOrchestratorPromiseClient;
    // financial.PaymentRecipient
    paymentRecipientReader: PaymentRecipientReaderPromiseClient;
    paymentRecipientReaderUNSCOPED: PaymentRecipientReaderUNSCOPEDPromiseClient;
  };
  banking: {
    fundingOrderManager: FundingOrderManagerPromiseClient;
    fundingOrderReaderUNSCOPED: FundingOrderReaderUNSCOPEDPromiseClient;
    fundingOrderStateController: FundingOrderStateControllerPromiseClient;
  };
  market: {
    subscriptionOrderBookOrchestrator: SubscriptionOrderBookOrchestratorPromiseClient;
    subscriptionCommitmentManager: SubscriptionCommitmentManagerPromiseClient;
    publicSubscriptionCommitmentManager: PublicSubscriptionCommitmentManagerPromiseClient;
    limitOrderStateControllerPromiseClient: LimitOrderStateControllerPromiseClient;
    limitOrderFeeCalculator: LimitOrderFeeCalculatorPromiseClient;
  };
  ledger: {
    ledgerAssetHolderLookupReaderUNSCOPED: AssetHolderLookupReaderUNSCOPEDPromiseClient;
    ledgerAssetHolderLookupReader: AssetHolderLookupReaderPromiseClient;
    ledgerAssetHolderLookupOrchestrator: AssetHolderLookupOrchestratorPromiseClient;
  };
  views: {
    financialPaymentViewModelReader: FinancialPaymentViewModelReaderPromiseClient;
    financialPaymentViewModelUNSCOPEDReader: FinancialPaymentViewModelReaderUNSCOPEDPromiseClient;
    financialPaymentViewModelUpdater: FinancialPaymentViewModelUpdaterPromiseClient;
    financialPaymentFundingViewModelReader: FinancialPaymentFundingViewModelReaderPromiseClient;
    financialPaymentFundingViewModelUNSCOPEDReader: FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient;
    financialPaymentFundingViewModelUpdater: FinancialPaymentFundingViewModelUpdaterPromiseClient;
    marketLimitOrderViewModelReader: MarketLimitOrderViewReaderPromiseClient;
    marketLimitOrderViewModelUnscopedReader: MarketLimitOrderViewReaderUnscopedPromiseClient;
    marketLimitOrderViewUpdaterPromiseClient: MarketLimitOrderViewUpdaterPromiseClient;
  };
};

const defaultContext: API = {
  remuneration: {
    feeScheduleCreator: new FeeScheduleCreatorPromiseClient(""),
    feeScheduleUpdater: new FeeScheduleUpdaterPromiseClient(""),
    feeScheduleReader: new FeeScheduleReaderPromiseClient(""),
  },
  financial: {
    // financial.instrument
    smartInstrumentCalculator: new SmartInstrumentCalculatorPromiseClient(""),
    smartInstrumentStateController:
      new SmartInstrumentStateControllerPromiseClient(""),
    smartInstrumentOrchestrator: new SmartInstrumentOrchestratorPromiseClient(
      "",
    ),
    smartInstrumentUpdater: new SmartInstrumentUpdaterPromiseClient(""),
    smartInstrumentReader: new SmartInstrumentReaderPromiseClient(""),
    primeRateRecorder: new PrimeRateRecorderPromiseClient(""),
    primeRateReader: new PrimeRateReaderPromiseClient(""),
    smartInstrumentReaderUNSCOPED:
      new SmartInstrumentReaderUNSCOPEDPromiseClient(""),
    // financial.OracleData
    oracleDataSynchroniser: new OracleDataSynchroniserPromiseClient(""),
    oracleDataSynchronisationOrchestrator:
      new OracleDataSynchronisationOrchestratorPromiseClient(""),
    // financial.RateReset
    smartInstrumentRateResetGenerator:
      new SmartInstrumentRateResetGeneratorPromiseClient(""),
    rateResetReader: new RateResetReaderPromiseClient(""),
    rateResetReaderUNSCOPED: new RateResetReaderUNSCOPEDPromiseClient(""),
    // financial.Assetflow
    assetflowReader: new AssetflowReaderPromiseClient(""),
    assetflowReaderUNSCOPED: new AssetflowReaderUNSCOPEDPromiseClient(""),
    // financial.AssetflowDeferral
    assetflowDeferralReader: new AssetflowDeferralReaderPromiseClient(""),
    assetflowDeferralReaderUNSCOPED:
      new AssetflowDeferralReaderUNSCOPEDPromiseClient(""),
    // financial.PaymentTrigger
    paymentTriggerFetcher: new PaymentTriggerFetcherPromiseClient(""),
    paymentTriggerFetcherUNSCOPED:
      new PaymentTriggerFetcherUNSCOPEDPromiseClient(""),
    // financial.Payment
    paymentFunder: new PaymentFunderPromiseClient(""),
    paymentStateController: new PaymentStateControllerPromiseClient(""),
    paymentOrchestrator: new PaymentOrchestratorPromiseClient(""),
    // financial.PaymentRecipient
    paymentRecipientReader: new PaymentRecipientReaderPromiseClient(""),
    paymentRecipientReaderUNSCOPED:
      new PaymentRecipientReaderUNSCOPEDPromiseClient(""),
  },
  banking: {
    fundingOrderManager: new FundingOrderManagerPromiseClient(""),
    fundingOrderReaderUNSCOPED: new FundingOrderReaderUNSCOPEDPromiseClient(""),
    fundingOrderStateController: new FundingOrderStateControllerPromiseClient(
      "",
    ),
  },
  market: {
    subscriptionOrderBookOrchestrator:
      new SubscriptionOrderBookOrchestratorPromiseClient(""),
    subscriptionCommitmentManager:
      new SubscriptionCommitmentManagerPromiseClient(""),
    publicSubscriptionCommitmentManager:
      new PublicSubscriptionCommitmentManagerPromiseClient(""),
    limitOrderStateControllerPromiseClient:
      new LimitOrderStateControllerPromiseClient(""),
    limitOrderFeeCalculator: new LimitOrderFeeCalculatorPromiseClient(""),
  },
  ledger: {
    ledgerAssetHolderLookupReader: new AssetHolderLookupReaderPromiseClient(""),
    ledgerAssetHolderLookupReaderUNSCOPED:
      new AssetHolderLookupReaderUNSCOPEDPromiseClient(""),
    ledgerAssetHolderLookupOrchestrator:
      new AssetHolderLookupOrchestratorPromiseClient(""),
  },
  views: {
    financialPaymentViewModelReader:
      new FinancialPaymentViewModelReaderPromiseClient(""),
    financialPaymentViewModelUNSCOPEDReader:
      new FinancialPaymentViewModelReaderUNSCOPEDPromiseClient(""),
    financialPaymentViewModelUpdater:
      new FinancialPaymentViewModelUpdaterPromiseClient(""),
    financialPaymentFundingViewModelReader:
      new FinancialPaymentFundingViewModelReaderPromiseClient(""),
    financialPaymentFundingViewModelUNSCOPEDReader:
      new FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient(""),
    financialPaymentFundingViewModelUpdater:
      new FinancialPaymentFundingViewModelUpdaterPromiseClient(""),
    marketLimitOrderViewModelReader:
      new MarketLimitOrderViewReaderPromiseClient(""),
    marketLimitOrderViewModelUnscopedReader:
      new MarketLimitOrderViewReaderUnscopedPromiseClient(""),
    marketLimitOrderViewUpdaterPromiseClient:
      new MarketLimitOrderViewUpdaterPromiseClient(""),
  },
};

type ServiceProviderPromiseClientConstructor<T> = new (
  hostname: string,
  credentials?:
    | {
        [index: string]: string;
      }
    | null
    | undefined,
  options?:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [index: string]: any;
      }
    | null
    | undefined,
) => T;

export const APIContext = React.createContext<API>(defaultContext);

export const APIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { simulatedTimeEnabled, currentTime } = useSimulatedTimeContext();
  const loggingInterceptor = useMemo<LoggingInterceptor>(
    () => new LoggingInterceptor(),
    [],
  );
  const simulatedTimeInterceptor = useMemo<SimulatedTimeInterceptor>(() => {
    return new SimulatedTimeInterceptor(simulatedTimeEnabled, currentTime);
  }, [simulatedTimeEnabled, currentTime]);
  const apiURL = config.get("apiGatewayURL");

  const serviceProviderConstructor: <T>(
    serviceProviderConstructor: ServiceProviderPromiseClientConstructor<T>,
  ) => T = (serviceProviderConstructor) => {
    return new serviceProviderConstructor(apiURL, null, {
      withCredentials: true,
      unaryInterceptors: [loggingInterceptor, simulatedTimeInterceptor],
    });
  };

  const api: API = useMemo<API>(
    () => ({
      remuneration: {
        feeScheduleCreator: serviceProviderConstructor(
          FeeScheduleCreatorPromiseClient,
        ),
        feeScheduleUpdater: serviceProviderConstructor(
          FeeScheduleUpdaterPromiseClient,
        ),
        feeScheduleReader: serviceProviderConstructor(
          FeeScheduleReaderPromiseClient,
        ),
      },
      financial: {
        // financial.SmartInstrument
        smartInstrumentCalculator: serviceProviderConstructor(
          SmartInstrumentCalculatorPromiseClient,
        ),
        smartInstrumentStateController: serviceProviderConstructor(
          SmartInstrumentStateControllerPromiseClient,
        ),
        smartInstrumentOrchestrator: serviceProviderConstructor(
          SmartInstrumentOrchestratorPromiseClient,
        ),
        smartInstrumentUpdater: serviceProviderConstructor(
          SmartInstrumentUpdaterPromiseClient,
        ),
        smartInstrumentReader: serviceProviderConstructor(
          SmartInstrumentReaderPromiseClient,
        ),
        smartInstrumentReaderUNSCOPED: serviceProviderConstructor(
          SmartInstrumentReaderUNSCOPEDPromiseClient,
        ),
        // financial.OracleData
        oracleDataSynchroniser: serviceProviderConstructor(
          OracleDataSynchroniserPromiseClient,
        ),
        oracleDataSynchronisationOrchestrator: serviceProviderConstructor(
          OracleDataSynchronisationOrchestratorPromiseClient,
        ),
        // financial.RateReset
        smartInstrumentRateResetGenerator: serviceProviderConstructor(
          SmartInstrumentRateResetGeneratorPromiseClient,
        ),
        rateResetReader: serviceProviderConstructor(
          RateResetReaderPromiseClient,
        ),
        rateResetReaderUNSCOPED: serviceProviderConstructor(
          RateResetReaderUNSCOPEDPromiseClient,
        ),
        // financial.Assetflow
        assetflowReader: serviceProviderConstructor(
          AssetflowReaderPromiseClient,
        ),
        assetflowReaderUNSCOPED: serviceProviderConstructor(
          AssetflowReaderUNSCOPEDPromiseClient,
        ),
        // financial.AssetflowDeferral
        assetflowDeferralReader: serviceProviderConstructor(
          AssetflowDeferralReaderPromiseClient,
        ),
        assetflowDeferralReaderUNSCOPED: serviceProviderConstructor(
          AssetflowDeferralReaderUNSCOPEDPromiseClient,
        ),
        // financial.PaymentTrigger
        paymentTriggerFetcher: serviceProviderConstructor(
          PaymentTriggerFetcherPromiseClient,
        ),
        paymentTriggerFetcherUNSCOPED: serviceProviderConstructor(
          PaymentTriggerFetcherUNSCOPEDPromiseClient,
        ),
        // financial.Payment
        paymentFunder: serviceProviderConstructor(PaymentFunderPromiseClient),
        paymentStateController: serviceProviderConstructor(
          PaymentStateControllerPromiseClient,
        ),
        paymentOrchestrator: serviceProviderConstructor(
          PaymentOrchestratorPromiseClient,
        ),
        // financial.PaymentRecipient
        paymentRecipientReader: serviceProviderConstructor(
          PaymentRecipientReaderPromiseClient,
        ),
        paymentRecipientReaderUNSCOPED: serviceProviderConstructor(
          PaymentRecipientReaderUNSCOPEDPromiseClient,
        ),
        primeRateRecorder: serviceProviderConstructor(
          PrimeRateRecorderPromiseClient,
        ),
        primeRateReader: serviceProviderConstructor(
          PrimeRateReaderPromiseClient,
        ),
      },
      banking: {
        fundingOrderManager: serviceProviderConstructor(
          FundingOrderManagerPromiseClient,
        ),
        fundingOrderReaderUNSCOPED: serviceProviderConstructor(
          FundingOrderReaderUNSCOPEDPromiseClient,
        ),
        fundingOrderStateController: serviceProviderConstructor(
          FundingOrderStateControllerPromiseClient,
        ),
      },
      market: {
        subscriptionOrderBookOrchestrator: serviceProviderConstructor(
          SubscriptionOrderBookOrchestratorPromiseClient,
        ),
        subscriptionCommitmentManager: serviceProviderConstructor(
          SubscriptionCommitmentManagerPromiseClient,
        ),
        publicSubscriptionCommitmentManager: serviceProviderConstructor(
          PublicSubscriptionCommitmentManagerPromiseClient,
        ),
        limitOrderStateControllerPromiseClient: serviceProviderConstructor(
          LimitOrderStateControllerPromiseClient,
        ),
        limitOrderFeeCalculator: serviceProviderConstructor(
          LimitOrderFeeCalculatorPromiseClient,
        ),
      },
      ledger: {
        ledgerAssetHolderLookupReader: serviceProviderConstructor(
          AssetHolderLookupReaderPromiseClient,
        ),
        ledgerAssetHolderLookupReaderUNSCOPED: serviceProviderConstructor(
          AssetHolderLookupReaderUNSCOPEDPromiseClient,
        ),
        ledgerAssetHolderLookupOrchestrator: serviceProviderConstructor(
          AssetHolderLookupOrchestratorPromiseClient,
        ),
      },
      views: {
        financialPaymentViewModelReader: serviceProviderConstructor(
          FinancialPaymentViewModelReaderPromiseClient,
        ),
        financialPaymentViewModelUNSCOPEDReader: serviceProviderConstructor(
          FinancialPaymentViewModelReaderUNSCOPEDPromiseClient,
        ),
        financialPaymentViewModelUpdater: serviceProviderConstructor(
          FinancialPaymentViewModelUpdaterPromiseClient,
        ),
        financialPaymentFundingViewModelReader: serviceProviderConstructor(
          FinancialPaymentFundingViewModelReaderPromiseClient,
        ),
        financialPaymentFundingViewModelUNSCOPEDReader:
          serviceProviderConstructor(
            FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient,
          ),
        financialPaymentFundingViewModelUpdater: serviceProviderConstructor(
          FinancialPaymentFundingViewModelUpdaterPromiseClient,
        ),
        marketLimitOrderViewModelReader: serviceProviderConstructor(
          MarketLimitOrderViewReaderPromiseClient,
        ),
        marketLimitOrderViewModelUnscopedReader: serviceProviderConstructor(
          MarketLimitOrderViewReaderUnscopedPromiseClient,
        ),
        marketLimitOrderViewUpdaterPromiseClient: serviceProviderConstructor(
          MarketLimitOrderViewUpdaterPromiseClient,
        ),
      },
    }),
    [apiURL, simulatedTimeEnabled, currentTime],
  );

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};

export const useAPIContext = () => useContext(APIContext);
