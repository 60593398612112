import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";

export interface StartValidationRequest {
  context: Context;
  lastActionAnnotation: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StartValidationResponse {}

export interface StopValidationRequest {
  context: Context;
  lastActionAnnotation: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StopValidationResponse {}

export interface ValidationPhaseControllerServiceProvider {
  serviceProvider: string;

  StartValidation(
    request: StartValidationRequest,
  ): Promise<StartValidationResponse>;
  StopValidation(
    request: StopValidationRequest,
  ): Promise<StopValidationResponse>;
}

const ValidationPhaseControllerServiceProviderName =
  "consistency-ValidationPhaseController";

export const ValidationPhaseController: ValidationPhaseControllerServiceProvider =
  {
    serviceProvider: ValidationPhaseControllerServiceProviderName,
    async StartValidation(
      request: StartValidationRequest,
    ): Promise<StartValidationResponse> {
      return jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${ValidationPhaseController.serviceProvider}.StartValidation`,
        request,
      });
    },
    async StopValidation(
      request: StopValidationRequest,
    ): Promise<StopValidationResponse> {
      return jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${ValidationPhaseController.serviceProvider}.StopValidation`,
        request,
      });
    },
  };
