import React from "react";
import { Autocomplete, Box, MenuItem, Typography } from "@mui/material";
import {
  allAssetflowCategories,
  assetflowCategoryToString,
} from "@mesh/common-js/dist/financial/assetFlowCategory";
import { Token } from "james/ledger";
import { TokenIconViewUpload } from "components/Ledger/Token";
import {
  LedgerAmountField,
  DateFieldShowTime,
} from "@mesh/common-js-react/dist/FormFields";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import {
  allBusinessDayConventions,
  allCalendars,
  businessDayConventionToString,
  calendarToString,
} from "@mesh/common-js/dist/financial";
import { Model as LedgerTokenViewModel } from "../../../../../../../../../james/views/ledgerTokenView";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import { AmountWrapper } from "@mesh/common-js/dist/ledger";
import { BulletSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegBullet_pb";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { ValidationResult } from "common/validation";
import { TextField } from "components/FormFields";
import { ShiftingPeriodForm } from "../../../ShiftingPeriodForm";
import { ShiftingPeriod } from "@mesh/common-js/dist/financial/shiftingPeriod_pb";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { applyTimeFromDateToDate } from "@mesh/common-js/dist/timeTools";
dayjs.extend(utc);
dayjs.extend(timezone);

export type BulletSmartInstrumentLegFormProps = {
  smartInstrumentIssueDate?: Timestamp;
  timezone: Timezone;
  disabled: boolean;
  readOnly: boolean;
  leg: BulletSmartInstrumentLeg;
  onChange: (updatedLeg: BulletSmartInstrumentLeg) => void;
  ledgerTokenViewModels: LedgerTokenViewModel[];
  formDataValidationResult: ValidationResult;
};

export const BulletSmartInstrumentLegForm = (
  props: BulletSmartInstrumentLegFormProps,
) => {
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `bulletSmartInstrumentLeg-${props.leg.getId()}-${field}`
    ];
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
      })}
    >
      <TextField
        id="smartInstrument-legs-bulletLeg-name-textField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"Leg Name"}
        value={props.leg.getName()}
        onChange={(e) => props.onChange(props.leg.setName(e.target.value))}
        error={!!getFieldValidation("name")}
        helperText={getFieldValidation("name")}
      />
      <Typography variant="h6">Asset Flow</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexWrap: "wrap",
          columnGap: theme.spacing(1),
          padding: theme.spacing(0.5, 2),
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <TextField
          id="smartInstrument-legs-bulletLeg-amountToken-selectField"
          disabled={props.disabled}
          readOnly={props.readOnly}
          fullWidth
          sx={{ maxWidth: 320 }}
          label="Amount Asset"
          select
          value={
            props.ledgerTokenViewModels.find((cst) =>
              cst.token.isEqualTo(
                Token.fromFutureToken(props.leg.getAmount()?.getToken()),
              ),
            )?.id ?? ""
          }
          onChange={(e) =>
            props.onChange(
              props.leg.setAmount(
                new AmountWrapper(props.leg.getAmount()).setToken(
                  (
                    props.ledgerTokenViewModels.find(
                      (cst) => cst.id === e.target.value,
                    )?.token ?? new Token()
                  ).toFutureToken(),
                ).amount,
              ),
            )
          }
          error={!!getFieldValidation("amountToken")}
          helperText={getFieldValidation("amountToken")}
        >
          {props.ledgerTokenViewModels.map((cst, idx) => (
            <MenuItem key={idx} value={cst.id}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing(1),
                })}
              >
                <TokenIconViewUpload
                  disableChangeIcon
                  size={23}
                  token={cst.token}
                />
                <Typography children={cst.token.code} />
              </Box>
            </MenuItem>
          ))}
        </TextField>
        <LedgerAmountField
          id={"smartInstrument-legs-bulletLeg-amountValue-ledgerAmountField"}
          noDecimalPlaces={7}
          disabled={props.disabled}
          readOnly={props.readOnly}
          fullWidth
          sx={{ maxWidth: 320 }}
          disallowNegative
          label="Amount"
          value={props.leg.getAmount()}
          onChange={(newValue: FutureAmount) =>
            props.onChange(props.leg.setAmount(newValue))
          }
          error={!!getFieldValidation("amount")}
          helperText={getFieldValidation("amount")}
        />
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={"smartInstrument-legs-bulletLeg-assetFlowCategory-selectField"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Asset Flow Category"
          select
          value={props.leg.getAssetflowcategory()}
          onChange={(e) =>
            props.onChange(
              props.leg.setAssetflowcategory(Number(e.target.value)),
            )
          }
          error={!!getFieldValidation("assetFlowCategory")}
          helperText={getFieldValidation("assetFlowCategory")}
        >
          {allAssetflowCategories.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {assetflowCategoryToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <DateFieldShowTime
          id={"smartInstrument-legs-bulletLeg-date-dateField"}
          sx={{ maxWidth: 245, width: "100%" }}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"Date"}
          value={props.leg.getDate()}
          onChange={(newValue) =>
            props.onChange(
              props.leg.setDate(
                applyTimeFromDateToDate(
                  props.smartInstrumentIssueDate,
                  newValue,
                ),
              ),
            )
          }
          error={!!getFieldValidation("date")}
          helperText={getFieldValidation("date")}
          timezone={props.timezone}
        />
        <Autocomplete
          multiple
          fullWidth
          disabled={props.disabled}
          readOnly={props.readOnly}
          sx={{ maxWidth: 320 }}
          id={"smartInstrument-legs-bulletLeg-calendars-selectField"}
          options={allCalendars.filter(
            (c) => c !== Calendar.UNDEFINED_CALENDAR,
          )}
          getOptionLabel={(option: Calendar) => calendarToString(option)}
          filterSelectedOptions
          ChipProps={{ size: "small" }}
          value={props.leg.getCalendarsList()}
          onChange={(_, value: Calendar[]) =>
            props.onChange(props.leg.setCalendarsList(value))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={props.disabled}
              readOnly={props.readOnly}
              label="Calendars"
              placeholder="Select..."
              error={!!getFieldValidation("calendars")}
              helperText={getFieldValidation("calendars")}
            />
          )}
        />
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-bulletLeg-businessDayConvention-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Business Day Convention"
          select
          value={props.leg.getBusinessdayconvention()}
          onChange={(e) =>
            props.onChange(
              props.leg.setBusinessdayconvention(Number(e.target.value)),
            )
          }
          error={!!getFieldValidation("businessDayConvention")}
          helperText={getFieldValidation("businessDayConvention")}
        >
          {allBusinessDayConventions.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {businessDayConventionToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      <Typography variant="h6">Record Period</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexWrap: "wrap",
          columnGap: theme.spacing(2),
          padding: theme.spacing(0.5, 2),
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <ShiftingPeriodForm
          disabled={props.disabled}
          readOnly={props.readOnly}
          period={props.leg.getRecordperiod() ?? new ShiftingPeriod()}
          onChange={(updatedShiftingPeriod: ShiftingPeriod) =>
            props.onChange(props.leg.setRecordperiod(updatedShiftingPeriod))
          }
          formDataValidationResult={props.formDataValidationResult}
          validationResultFieldPrefix={`bulletSmartInstrumentLeg-${props.leg.getId()}-recordPeriod`}
        />
      </Box>
    </Box>
  );
};
