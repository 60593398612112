import { Token } from "james/ledger/Token";
import { AuditEntry } from "james/audit";

export const LiquidityPoolRegistrationTypeName =
  "mesh::stellar/LiquidityPoolRegistration";

export class LiquidityPoolRegistration {
  public ["@type"]: string = LiquidityPoolRegistrationTypeName;

  public id = "";

  public ownerID = "";

  public liquidityPoolID = "";

  public token: Token = new Token();

  public monitorEffects = false;

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(liquidityPoolRegistration?: LiquidityPoolRegistration) {
    if (!liquidityPoolRegistration) {
      return;
    }
    this.id = liquidityPoolRegistration.id;
    this.ownerID = liquidityPoolRegistration.ownerID;
    this.liquidityPoolID = liquidityPoolRegistration.liquidityPoolID;
    this.token = new Token(liquidityPoolRegistration.token);
    this.monitorEffects = liquidityPoolRegistration.monitorEffects;
    this.auditEntry = new AuditEntry(liquidityPoolRegistration.auditEntry);
  }
}
