import React from "react";
import { getAuth } from "firebase/auth";

import { AuthProvider, useFirebaseApp } from "reactfire";

export const FirebaseProductsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  return <AuthProvider sdk={auth}>{children}</AuthProvider>;
};
