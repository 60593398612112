import React from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { TextField } from "components/FormFields";
import { Close as CloseIcon } from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { useNavigate } from "react-router-dom";
import { InstrumentsViewPaths } from "./Instruments";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";

const PREFIX = "AllTokenClasses";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  dialogContent: `${PREFIX}-dialogContent`,
  chooseTokenClassLayout: `${PREFIX}-chooseTokenClassLayout`,
  chooseTokenClassFirstRow: `${PREFIX}-chooseTokenClassFirstRow`,
  chooseTokenClassFormField: `${PREFIX}-chooseTokenClassFormField`,
  boldText: `${PREFIX}-boldText`,
  lastActionAnnotationDialogTitleRootOverride: `${PREFIX}-lastActionAnnotationDialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  //
  // Dialog Title
  //
  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
  },

  [`& .${classes.heading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 40,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  //
  // Dialog Content
  //
  [`& .${classes.dialogContent}`]: {
    padding: "0px !important",
  },

  //
  // Choose Token Class
  //
  [`& .${classes.chooseTokenClassLayout}`]: {
    padding: theme.spacing(2),
    maxWidth: 758,
    display: "grid",
    gridTemplateColumns: "auto",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.chooseTokenClassFirstRow}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(3),
  },

  [`& .${classes.chooseTokenClassFormField}`]: {
    width: 250,
  },

  //
  // other
  //
  [`& .${classes.boldText}`]: {
    fontWeight: "bold",
  },

  //
  // last action annotation dialog
  //
  [`& .${classes.lastActionAnnotationDialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 499,
  },
}));

export enum TokenClass {
  RightsToInstrument = "Rights To An Instrument",
}

export const AllTokenClasses: TokenClass[] = [TokenClass.RightsToInstrument];

export function NewInstrumentDialog() {
  const { myClient } = useApplicationContext();
  const navigate = useNavigate();

  return (
    <StyledDialog open fullScreen>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item className={classes.heading}>
            <div className={classes.miniLogoWrapper}>
              <img alt="" width="100%" src={meshMiniLogo} />
            </div>
            <Typography variant="h5" children="Instrument Builder" />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip
              title="Close"
              placement="top"
              onClick={() => navigate(InstrumentsViewPaths.Table)}
            >
              <span>
                <IconButton id="newInstrumentDialog-close-button" size="small">
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.chooseTokenClassLayout}>
          <div className={classes.chooseTokenClassFirstRow}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              className={classes.chooseTokenClassFormField}
              id="newInstrumentDialog-tokenClass-autoComplete"
              options={AllTokenClasses}
              onChange={(__: object, value) => {
                if (!value) {
                  return;
                }
                switch (value) {
                  case TokenClass.RightsToInstrument:
                    navigate(InstrumentsViewPaths.RightsToETF);
                    break;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="newInstrumentDialog-tokenClass-autoCompleteTextField"
                  label="Token Class"
                  placeholder="Select..."
                  variant="outlined"
                />
              )}
            />
            <TextField
              label="Issuer"
              id="newInstrumentDialog-issuer-readOnlyTextField"
              value={new Client(myClient).name}
              readOnly
              disabled
            />
          </div>
          <Typography
            variant="h4"
            className={classes.boldText}
            children="Define Your Instrument"
          />
          <Typography variant="body1">
            On Mesh you are able to define the type of instrument that you would
            like to build. At the dayjs you can select between creating “Rights
            to an Instrument” or a “New Instrument”.
          </Typography>
        </div>
      </DialogContent>
    </StyledDialog>
  );
}
