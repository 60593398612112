import React from "react";
import { Button, TextField, Typography, Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import {
  CompanyRequestFormState,
  companyRequestFormUpdaterSpecs,
  companyRequestValidationFunc,
  completedInFormFields,
  initialState,
} from "../../useFormState";
import { useWidth } from "utilities/general";
import { useValidatedForm } from "hooks/useForm";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { OnboardingCard } from "../../../OnboardingCard/OnboardingCard";
import {
  InteractionDriver,
  DataLinkInfoType,
  InteractionAction,
  InteractionType,
} from "const/gtm";

interface CompanyOnboardingRequestCardProps {
  onSendRequest: (
    adminName: string,
    adminEmail: string,
    companyName: string,
  ) => Promise<void>;
}

export function CompanyOnboardingRequestCard(
  props: CompanyOnboardingRequestCardProps,
) {
  const navigate = useNavigate();
  const width = useWidth();
  const initialTouchedFields: Set<string> = new Set();

  // validate company request details
  const [
    formState,
    validationResult,
    formUpdater,
    validationInProgress,
    touchedFields,
  ] = useValidatedForm(
    companyRequestValidationFunc,
    async (): Promise<CompanyRequestFormState> => {
      return {
        companyRequest: {
          adminName: "",
          adminEmail: "",
          companyName: "",
        },
      };
    },
    companyRequestFormUpdaterSpecs,
    initialState,
    initialTouchedFields,
  );

  const { companyName, adminName, adminEmail } = formState.companyRequest;

  const { fieldValidations, valid: formFieldsValid } = validationResult;

  // check if all form fields are complete with no errors
  const { companyRequestFormCompleted } = completedInFormFields({
    touchedFields: touchedFields,
    validationResult: validationResult,
  });

  return (
    <OnboardingCard
      linksFooter={
        <>
          <Typography>
            <Link
              id={"SignUp-CompanyOnboardingRequestCard-GoBack-Link"}
              sx={(theme) => ({
                marginBottom: theme.spacing(1),
                fontWeight: "bold",
              })}
              onClick={() => {
                navigate("/sign-up/client-selector");
              }}
              fontSize={"14px"}
              component="button"
              underline="hover"
              color="secondary"
            >
              Go Back
            </Link>
          </Typography>
          <Box
            sx={(theme) => ({
              display: "flex",
              paddingBottom: theme.spacing(5.5),
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: "14px",
              })}
              variant="body1"
            >
              Already Registered?
            </Typography>
            <Link
              id={"SignUp-CompanyOnboardingRequestCard-SignIn-Link"}
              sx={(theme) => ({
                paddingLeft: theme.spacing(1),
                fontWeight: "bold",
              })}
              onClick={() => {
                navigate("/sign-in");
              }}
              fontSize={"14px"}
              component="button"
              underline="hover"
              color="secondary"
              data-link-info={JSON.stringify({
                content_interaction_id: "sign-in-button",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "already registered - sign in",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.SignIn,
              } as DataLinkInfoType)}
            >
              Sign In
            </Link>
          </Box>
        </>
      }
    >
      <VerticalSlide transitionDuration={1 / 6}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            padding: isWidthDown("sm", width)
              ? theme.spacing(3.5, 0, 4, 0)
              : theme.spacing(5.5, 0, 4, 0),
          })}
          fontSize="20px"
          fontWeight="600"
          align="center"
          children="Company sign up request"
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 6}>
        <Typography
          sx={(theme) => ({
            paddingBottom: theme.spacing(4),
            color: theme.palette.text.tertiary,
          })}
          align="center"
          fontSize="16px"
        >
          Fill in your company name and <br /> nominate a legal admin.
        </Typography>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={3 / 6}>
        <TextField
          id="signUp-companyName-textField"
          label="Company Name"
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
            margin: 0,
          })}
          fullWidth
          value={companyName}
          variant="outlined"
          error={!!fieldValidations.companyName}
          helperText={fieldValidations.companyName}
          onChange={(e) => formUpdater.companyName(e.target.value)}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={4 / 6}>
        <TextField
          id="signUp-adminName-textField"
          label="Admin Name"
          sx={(theme) => ({
            width: "100%",
            paddingBottom: theme.spacing(2),
            margin: 0,
          })}
          value={adminName}
          variant="outlined"
          error={!!fieldValidations.adminName}
          helperText={fieldValidations.adminName}
          onChange={(e) => formUpdater.adminName(e.target.value)}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={5 / 6}>
        <TextField
          sx={(theme) => ({
            width: "100%",
            paddingBottom: theme.spacing(2),
            margin: 0,
          })}
          label="Admin Email"
          id="signUp-adminEmail-textField"
          value={adminEmail}
          inputProps={{ type: "email" }}
          error={!!fieldValidations.adminEmail}
          helperText={fieldValidations.adminEmail}
          onChange={(e) =>
            formUpdater.adminEmail(e.target.value.replace(/\s+/g, ""))
          }
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          id="signUp-companySendRequest-button"
          sx={(theme) => ({
            height: theme.spacing(6),
            boxShadow: 5,
            width: "100%",
            marginBottom: theme.spacing(13),
          })}
          color="primary"
          variant="contained"
          size="large"
          disabled={
            !companyName ||
            !adminName ||
            !adminEmail ||
            validationInProgress ||
            !companyRequestFormCompleted
          }
          onClick={async () => {
            if (formFieldsValid) {
              await props.onSendRequest(adminName, adminEmail, companyName);
            }
          }}
          children="Send Request"
        />
      </VerticalSlide>
    </OnboardingCard>
  );
}
