import React, { useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  useMediaQuery,
  DialogContent,
  DialogTitle,
  DialogProps,
  Typography,
  IconButton,
  useTheme,
  Dialog,
  Button,
  Theme,
  Box,
  MenuItem,
  Link,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  MarketListingViewModel,
  Reader as MarketListingViewReader,
} from "james/views/marketListingView";
import dayjs from "dayjs";
import { Amount as AmountType, Token } from "james/ledger";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import CloseIcon from "@mui/icons-material/Close";
import { Amount } from "components/Ledger/Amount";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";
import { AmountIncrementField } from "components/FormFields/NumberField";
import { DateTimeFormat12 } from "const/dateformats";
import { AssetType } from "james/views/marketListingView/Model";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import BigNumber from "bignumber.js";
import { SubscriptionOrderStateController } from "james/market/SubscriptionOrderStateController";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import LogRocket from "logrocket";
import {
  FormDataUpdaterSpecsType,
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForm";
import { useValidatedForm } from "hooks/useForm";
import { SubscriptionOrder } from "james/market/SubscriptionOrder";
import { AssetEvent } from "const/logRocket";
import { Key } from "james/key/Key";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import { TextExactCriterion, TextListCriterion } from "james/search/criterion";
import { ListingState } from "james/market/Listing";
import { NewSorting, Query } from "james/search/query";
import { QuoteParameter, Mechanism, MechanismType } from "james/market";
import { usePotentialSourceAccount } from "components/SubscriptionTicketDialog/components/usePotentialSourceAccount";
import { useErrorContext } from "context/Error";
import { DataComponentInfo, InteractionDriver } from "const/gtm";
import duration from "dayjs/plugin/duration";
import { TextField } from "components/FormFields";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";
import { frequencyToString } from "@mesh/common-js/dist/financial";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { useMarketContext } from "context/Market";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";

export type FormData = {
  subscriptionQuantity: AmountType;
  investmentAmount: AmountType;
  accountBalance: AmountType;
  termsChecked: boolean;
  model: MarketListingViewModel;
  userKeys: Key[];
  selectedSourceAccountViewModel: StellarAccountViewModel;
  availablePayAssetBalance: AmountType;
  signatoryOnSourceAccount: boolean;
  quoteParameter?: QuoteParameter;
  unit: string;
};

export interface SubscriptionTicketDialogProps {
  dialogProps: DialogProps;
  marketListingViewModel: MarketListingViewModel;
  closeDialog: () => void;
  baseToken: Token;
}

const getAssetUnitLabel = (assetUnit: Unit) => {
  switch (assetUnit) {
    case Unit.BOND_UNIT:
      return "Bond";
    case Unit.PREFERENCE_SHARE_UNIT:
      return "Pref. Share";
    case Unit.NOTE_UNIT:
      return "Note";
    case Unit.SHARE_UNIT:
      return "Share";
    default:
      return "Unit";
  }
};

const getAssetUnitFormalLabel = (assetUnit: Unit) => {
  switch (assetUnit) {
    case Unit.BOND_UNIT:
      return "Bond";
    case Unit.PREFERENCE_SHARE_UNIT:
      return "Preference Share";
    case Unit.NOTE_UNIT:
      return "Note";
    case Unit.SHARE_UNIT:
      return "Share";
    default:
      return "Unit";
  }
};

const getRateLabel = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.PreferenceShare:
      return "Dividend Rate";
    case AssetType.Bond:
      return "Coupon Rate";
    default:
      return "Rate";
  }
};

export const SubscriptionTicketDialog = ({
  dialogProps,
  marketListingViewModel,
  closeDialog,
  baseToken,
}: SubscriptionTicketDialogProps) => {
  dayjs.extend(duration);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { marketContextMonitorSubscriptionOrder } = useMarketContext();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();

  const [endDate] = useState(
    dayjs(
      marketListingViewModel.marketSubscriptionOrderBookViewModel?.closeDate,
    ),
  );
  const [timeLeft] = useState(dayjs.duration(endDate.diff(dayjs())));
  const [accountIndex, setAccountIndex] = useState(0);
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const assetUnit = getAssetUnitLabel(marketListingViewModel.assetUnit);
  const assetUnitFormal = getAssetUnitFormalLabel(
    marketListingViewModel.assetUnit,
  );
  const rate = marketListingViewModel.assetType
    ? getRateLabel(marketListingViewModel.assetType)
    : "Rate";

  const {
    initialAccountLoadedSuccessfully,
    potentialSourceAccountsLoaded,
    stellarAccountContextKeys,
    potentialSourceAccounts,
    setInitialAccountLoadedSuccessfully,
    stellarAccountContextLoading,
    stellarAccountContextError,
  } = usePotentialSourceAccount("SubmitSubscriptionOrder");

  // Validations
  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      const subscriptionQuantity =
        marketListingViewModel.listingMarketMechanisms[0].quoteParameters[0]
          .minimumDealSize;
      const unitPrice =
        marketListingViewModel.marketSubscriptionOrderBookViewModel?.unitPrice;
      const initialFormData: FormData = {
        subscriptionQuantity: baseToken.newAmountOf(
          marketListingViewModel.listingMarketMechanisms[0].quoteParameters[0]
            .minimumDealSize.value,
        ),
        investmentAmount: new AmountType(
          unitPrice?.setValue(
            unitPrice.value.multipliedBy(subscriptionQuantity.value),
          ),
        ),
        accountBalance: new AmountType(),
        termsChecked: false,
        model: marketListingViewModel,
        userKeys: stellarAccountContextKeys,
        selectedSourceAccountViewModel: new StellarAccountViewModel(),
        signatoryOnSourceAccount: false,
        availablePayAssetBalance: new AmountType(),
        unit: assetUnit,
      };

      try {
        await Promise.all([
          async () => {
            const retrievedPotentialBaseTokenListingViewModels = (
              await MarketListingViewReader.Read({
                context: authContext,
                criteria: {
                  $or: [
                    { listingState: TextExactCriterion(ListingState.Active) },
                    baseToken.toFilter(),
                  ],
                  assetType: TextListCriterion([AssetType.Bond]),
                },
                query: new Query({
                  limit: 50,
                  offset: 0,
                  sorting: [NewSorting("id", "desc")],
                }),
              })
            ).models;

            // build an index of potential quote tokens
            const quoteTokenCodeIdx: {
              [key: string]: Token;
            } = {};
            let initialBaseTokenSubscriptionMechanism!: Mechanism;
            let initialBaseTokenListingViewModel!: MarketListingViewModel;
            retrievedPotentialBaseTokenListingViewModels.forEach(
              (mv: MarketListingViewModel) => {
                const subscriptionMechanism = mv.listingMarketMechanisms.find(
                  (mm) => mm.type === MechanismType.Subscription,
                );
                if (!subscriptionMechanism) {
                  console.error(
                    `no subscription market mechanism on listing retrieved for ${mv.token.code}`,
                  );
                  throw new Error(
                    `no subscription market mechanism on listing retrieved for ${mv.token.code}`,
                  );
                }

                // set initial base token market mechanism
                if (
                  !(
                    initialBaseTokenSubscriptionMechanism ||
                    initialBaseTokenListingViewModel
                  ) &&
                  mv.token.isEqualTo(baseToken)
                ) {
                  initialBaseTokenSubscriptionMechanism = subscriptionMechanism;
                  initialBaseTokenListingViewModel = mv;
                }

                // add to quote token idx
                subscriptionMechanism.quoteParameters.forEach((qp) => {
                  quoteTokenCodeIdx[qp.quoteToken.string()] = qp.quoteToken;
                });
              },
            );
            if (
              !(
                initialBaseTokenSubscriptionMechanism ||
                initialBaseTokenListingViewModel
              )
            ) {
              console.error(
                "initial base token spot market mechanism and listing view model not among those retrieved",
              );
              throw new Error(
                "initial base token spot market mechanism and listing view model not among those retrieved",
              );
            }

            // determine initial quote token
            let initialQuoteToken: Token =
              initialBaseTokenSubscriptionMechanism.quoteParameters[0]
                .quoteToken;
            if (
              marketListingViewModel.marketSubscriptionOrderBookViewModel
                ?.subscriptionAmount.token.code
            ) {
              const initialQuoteTokenParameter =
                initialBaseTokenSubscriptionMechanism.quoteParameters.find(
                  (qp) =>
                    qp.quoteToken.code ===
                    marketListingViewModel.marketSubscriptionOrderBookViewModel
                      ?.subscriptionAmount.token.code,
                );
              if (initialQuoteTokenParameter) {
                initialQuoteToken = initialQuoteTokenParameter.quoteToken;
              }
            }
            initialFormData.investmentAmount =
              initialQuoteToken.newAmountOf("0");
            initialFormData.model = initialBaseTokenListingViewModel;
          },
        ]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error fetching required data: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error fetching required data: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        closeDialog();
      }

      return initialFormData;
    },
    formDataUpdaterSpecs,
    {
      subscriptionQuantity:
        marketListingViewModel.listingMarketMechanisms[0].quoteParameters[0]
          .minimumDealSize,
      investmentAmount: new AmountType(
        marketListingViewModel.marketSubscriptionOrderBookViewModel?.unitPrice,
      ),
      accountBalance: new AmountType(),
      termsChecked: false,
      model: marketListingViewModel,
      userKeys: [],
      selectedSourceAccountViewModel: new StellarAccountViewModel(),
      signatoryOnSourceAccount: false,
      availablePayAssetBalance: new AmountType(),
      unit: "",
    },
    new Set<string>([
      "subscriptionQuantity",
      "termsChecked",
      "selectedSourceAccountViewModel",
    ]),
  );

  const accountLabel = formDataValidationResult.valid
    ? "Select Your Mesh Account"
    : "Error";

  useEffect(() => {
    if (initialAccountLoadedSuccessfully) {
      return;
    }

    if (stellarAccountContextLoading) {
      return;
    }

    if (stellarAccountContextError) {
      console.error(`initialization error: ${stellarAccountContextError}`);
      enqueueSnackbar(`Initialization Error: ${stellarAccountContextError}`, {
        variant: "error",
      });

      // close the dialog
      closeDialog();
    }

    setInitialAccountLoadedSuccessfully(true);
  }, [
    stellarAccountContextError,
    stellarAccountContextLoading,
    initialAccountLoadedSuccessfully,
    enqueueSnackbar,
  ]);

  // useEffect that sets the trading Account
  useEffect(() => {
    setSourceAccount(accountIndex);
  }, [potentialSourceAccounts, potentialSourceAccountsLoaded, accountIndex]);

  const setSourceAccount = (accountIndex: number) => {
    // if the stellarAccountContext accounts are still being loaded return
    if (!potentialSourceAccountsLoaded) {
      return;
    }

    const quoteParameter =
      marketListingViewModel.listingMarketMechanisms[0].quoteParameters[0];

    const quoteToken = quoteParameter.quoteToken;

    const quoteTokenBalance =
      potentialSourceAccounts[accountIndex].getTokenBalance(quoteToken);

    formDataUpdate.quoteParameter(quoteParameter);
    formDataUpdate.selectedSourceAccViewModel(
      potentialSourceAccounts[accountIndex],
    );
    formDataUpdate.accountBalance(
      quoteTokenBalance
        ? quoteTokenBalance.availableBalance()
        : quoteToken.newAmountOf("0"),
    );
  };

  const subscribedPercentage = useMemo((): BigNumber => {
    if (marketListingViewModel.marketSubscriptionOrderBookViewModel) {
      return marketListingViewModel.marketSubscriptionOrderBookViewModel.subscribedAmount.value
        .dividedBy(
          marketListingViewModel.marketSubscriptionOrderBookViewModel
            .subscriptionAmount.value,
        )
        .multipliedBy(100)
        .dp(2);
    }
    return new BigNumber(0);
  }, [marketListingViewModel]);

  const handleSubmitSubscriptionOrder = async () => {
    setSubmittingOrder(true);
    let subscriptionOrder: SubscriptionOrder;
    try {
      subscriptionOrder = (
        await SubscriptionOrderStateController.SubmitSubscriptionOrder({
          context: authContext,
          sourceAccountID: formData.selectedSourceAccountViewModel.accountID(),
          token: marketListingViewModel.token,
          amount: formData.investmentAmount,
        })
      ).subscriptionOrder;

      // notify that submission is in progress
      enqueueSnackbar(
        `Subscription #${subscriptionOrder.number} is being submitted`,
        {
          variant: "info",
        },
      );
      getLedgerTokenViewModel(marketListingViewModel.token)
        .then((ledgerTokenViewModel) => {
          marketContextMonitorSubscriptionOrder({
            subscriptionOrder: subscriptionOrder,
            assetListingViewModel: marketListingViewModel,
            ledgerTokenViewModel: ledgerTokenViewModel,
          });
        })
        .catch((e) => {
          console.error("error fetching ledger token view model:", e);
        });
      closeDialog();

      LogRocket.track(AssetEvent.placeSubscription, {
        assetName: marketListingViewModel.assetName,
        assetShortName: marketListingViewModel.assetShortName,
        assetType: marketListingViewModel.assetType,
      });
    } catch (e) {
      console.error("`error submitting direct order`", e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Order: ${err.message}`, {
        variant: "warning",
      });
      setSubmittingOrder(false);
      return;
    }
  };

  const setMax = () => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel) return;

    const orderBook =
      marketListingViewModel.marketSubscriptionOrderBookViewModel;

    const remaining = orderBook.overSubscriptionAmount.value
      .minus(orderBook.subscribedAmount.value)
      .dividedBy(orderBook.unitPrice.value);

    const units = formData.accountBalance.value.dividedBy(
      orderBook.unitPrice.value,
    );

    const maxDealSize = marketListingViewModel.getMarketMechanismQuoteParameter(
      MechanismType.Subscription,
      orderBook.unitPrice.token,
    ).maximumDealSize;

    let n = BigNumber.min(units, maxDealSize.value, remaining);
    if (
      !marketListingViewModel.marketSubscriptionOrderBookViewModel
        .fractionalisationAllowed
    ) {
      n = n.integerValue(BigNumber.ROUND_FLOOR);
    }
    formDataUpdate.investmentAmount(
      formData.investmentAmount.setValue(
        n.multipliedBy(
          marketListingViewModel.marketSubscriptionOrderBookViewModel.unitPrice
            .value,
        ),
      ),
    );
    formDataUpdate.subscriptionQuantity(
      formData.subscriptionQuantity.setValue(n),
    );
  };

  const interval = useMemo(() => {
    if (timeLeft.days()) {
      return {
        interval: `${timeLeft.days() > 1 ? "days" : "day"}`,
        time: timeLeft.days(),
      };
    }
    if (timeLeft.hours()) {
      return {
        interval: `${timeLeft.hours() > 1 ? "hours" : "hour"}`,
        time: timeLeft.hours(),
      };
    }
    if (timeLeft.minutes()) {
      return {
        interval: "min",
        time: timeLeft.minutes(),
      };
    }

    return {
      interval: "sec",
      time: timeLeft.seconds(),
    };
  }, []);

  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        ...dialogProps.PaperProps,
        sx: {
          width: { sm: 450 },
        },
        "data-component-info": JSON.stringify({
          component_id: "asset_card",
          component_business_name: "asset_card",
          component_title: formData.model.assetShortName,
          component_driver: InteractionDriver.DriveTransaction,
        } as DataComponentInfo),
      }}
      fullScreen={smDown}
    >
      <SubscriptionTicketTitle
        marketListingViewModel={marketListingViewModel}
        submittingOrder={submittingOrder}
        formData={formData}
        formDataUpdate={formDataUpdate}
        closeDialog={closeDialog}
      />
      {marketListingViewModel.marketSubscriptionOrderBookViewModel &&
      potentialSourceAccountsLoaded ? (
        <>
          <DialogContent
            sx={(theme) => ({
              p: 0,
              backgroundColor: theme.palette.custom.midnight,
            })}
            className={smDown ? "" : "meshScroll"}
          >
            {/* Subscription Amount */}
            <Box
              sx={(theme) => ({
                px: { sm: 6, xs: 3 },
                pt: { sm: 3, xs: 3 },
                pb: { sm: 4, xs: 3 },
                backgroundColor: theme.palette.custom.cardInner,
              })}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: "bold", mb: 3 }}
                children={`How many ${assetUnit}s would you like?`}
              />
              <AmountIncrementField
                id="NumberIncrementField"
                disallowNegative
                noDecimals
                disabled={submittingOrder}
                color={
                  formDataValidationResult.fieldValidations.subscriptionQuantity
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main
                }
                width={"100%"}
                variant={smDown ? "middle-input" : "left-input"}
                value={formData.subscriptionQuantity}
                inputBaseProps={{
                  inputProps: {
                    sx: {
                      textAlign: "left",
                      px: 2,
                      mb: 0,
                    },
                  },
                  endAdornment: (
                    <Link
                      underline="none"
                      onClick={setMax}
                      sx={{
                        mr: 2,
                        fontWeight: 600,
                      }}
                    >
                      MAX
                    </Link>
                  ),
                }}
                onChange={(s, n) => {
                  if (s === "") {
                    formDataUpdate.investmentAmount(
                      formData.investmentAmount.setValue("0"),
                    );
                    formDataUpdate.subscriptionQuantity(
                      formData.subscriptionQuantity.setValue("0"),
                    );
                    return;
                  }
                  if (n.value.isNaN()) return;
                  formDataUpdate.subscriptionQuantity(n);
                  const v = new AmountType(
                    marketListingViewModel.marketSubscriptionOrderBookViewModel?.unitPrice,
                  );
                  formDataUpdate.investmentAmount(
                    formData.investmentAmount.setValue(
                      n.value.multipliedBy(v.value),
                    ),
                  );
                }}
              />

              {!formDataValidationResult.fieldValidations.payAmount && (
                <Box
                  sx={{
                    alignSelf: "start",
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                  }}
                >
                  {/* Account Selector*/}
                  {potentialSourceAccounts.length > 1 && (
                    <TextField
                      select
                      value={accountIndex}
                      label={accountLabel}
                      fullWidth
                      disabled={!potentialSourceAccountsLoaded}
                      error={!formDataValidationResult.valid}
                      sx={{ mt: 1 }}
                    >
                      {potentialSourceAccounts.map((account, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            value={idx}
                            onClick={() => setAccountIndex(idx)}
                          >
                            {account.accountCategory()} - {account.number}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                  {formDataValidationResult.fieldValidations
                    .subscriptionQuantity ? (
                    <Box sx={{ mb: 2, ml: 2 }}>
                      <Typography
                        variant={"caption"}
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {
                          formDataValidationResult.fieldValidations
                            .subscriptionQuantity
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        mb: 2,
                        ml: 2,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{ color: "text.disabled", mr: 0.5 }}
                      >
                        Available:
                      </Typography>
                      <Amount
                        id="spotTradeDialog-payAvailableBalance-amount"
                        amount={formData.accountBalance}
                        formatTextNumOpts={{ noDecimalPlaces: 2 }}
                        codeTypographyProps={{
                          variant: "caption",
                          color: "textSecondary",
                        }}
                        valueTypographyProps={{
                          variant: "caption",
                          color: "textSecondary",
                        }}
                      />
                    </Box>
                  )}
                  {formDataValidationResult.fieldValidations.subscriptionQuantity?.includes(
                    "Insufficient Balance: ",
                  ) && (
                    <Box>
                      <Button
                        sx={{ mb: 2 }}
                        fullWidth={false}
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowFundAccDialog(true)}
                      >
                        Fund Account
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {showFundAccDialog && (
                <FundAccountDialog
                  accountID={formData.selectedSourceAccountViewModel.id}
                  open
                  onClose={() => setShowFundAccDialog(false)}
                />
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                    children={`Price per ${assetUnit}`}
                  />
                  <Amount
                    valueTypographyProps={{
                      sx: {
                        fontWeight: "bold",
                      },
                      noWrap: true,
                    }}
                    amount={
                      marketListingViewModel
                        .marketSubscriptionOrderBookViewModel.unitPrice
                    }
                  />
                </Box>
                <Box>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                  >
                    Investment Amount
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Amount
                      codeTypographyProps={{
                        sx: (theme) => ({
                          color: theme.palette.custom.yellow,
                        }),
                      }}
                      valueTypographyProps={{
                        sx: (theme) => ({
                          color: theme.palette.warning.light,
                          fontWeight: "bold",
                        }),
                        noWrap: true,
                      }}
                      amount={formData.investmentAmount}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Subscription Information Section */}
            <Box
              sx={{
                py: 3,
                px: { sm: 6, xs: 3 },
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="caption"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  children="Subscription Closing Date"
                />
                <Box sx={{ display: "flex" }}>
                  <Typography id="closing-date-typography">
                    {dayjs(
                      marketListingViewModel
                        .marketSubscriptionOrderBookViewModel.closeDate,
                    ).format(DateTimeFormat12) + " "}
                  </Typography>
                  <Typography
                    id="time-left-typography"
                    sx={(theme) => ({
                      color: theme.palette.warning.light,
                      ml: 0.5,
                    })}
                  >
                    ({interval.time} {interval.interval} left)
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                    children={rate}
                  />
                  <Typography
                    id="coupon-rate-typography"
                    sx={{ fontWeight: "bold" }}
                  >
                    {marketListingViewModel.returnDescription}
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      variant="caption"
                      sx={(theme) => ({ color: theme.palette.text.secondary })}
                      children="Frequency"
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      sx={{ fontWeight: "bold", justifyContent: "flex-end" }}
                    >{`${frequencyToString(
                      marketListingViewModel.frequency,
                    )}`}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ pb: "3px" }}>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                    children="Total Nominal"
                  />
                  <Amount
                    id="subscription-total-amountField"
                    codeTypographyProps={{
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                        fontSize: "16px",
                        fontWeight: 600,
                      }),
                    }}
                    valueTypographyProps={{
                      sx: {
                        fontWeight: 600,
                        fontSize: "16px",
                      },
                    }}
                    amount={
                      marketListingViewModel
                        .marketSubscriptionOrderBookViewModel.subscriptionAmount
                    }
                  />
                </Box>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    backgroundColor: theme.palette.custom.midnight,
                  })}
                >
                  <Typography
                    id="percentage-typography"
                    sx={{ fontWeight: 600, fontSize: "16px" }}
                  >
                    {subscribedPercentage.toString()}%
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                    children="Subscribed"
                  />
                </Box>
              </Box>
              <LoadingBar
                percent={subscribedPercentage}
                backgroundColor={theme.palette.background.default}
              />
            </Box>

            {showConfirmationDialog && (
              <ConfirmationDialog
                dialogProps={dialogProps}
                closeDialog={() => setShowConfirmationDialog(false)}
                subscribe={handleSubmitSubscriptionOrder}
                smDown={smDown}
                subscriptionAmount={
                  marketListingViewModel.marketSubscriptionOrderBookViewModel
                    .unitPrice
                }
                open={showConfirmationDialog}
                numberOfUnits={formData.subscriptionQuantity}
                assetUnit={assetUnit}
                assetUnitFormal={assetUnitFormal}
                investmentAmount={formData.investmentAmount}
                subscriptionTicketHeader={
                  <SubscriptionTicketTitle
                    marketListingViewModel={marketListingViewModel}
                    submittingOrder={submittingOrder}
                    formData={formData}
                    formDataUpdate={formDataUpdate}
                    closeDialog={closeDialog}
                  />
                }
                submittingOrder={submittingOrder}
                height={potentialSourceAccounts.length > 1 ? "682px" : "616px"}
              />
            )}
          </DialogContent>
          {!smDown && <Divider></Divider>}
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: { sm: 6, xs: 3 },
              pb: 4,
              pt: 3,
              boxShadow: {
                xs: "0px 0px 18px 4px rgba(0,0,0,0.4);",
                sm: 0,
              },
            }}
          >
            {" "}
            <Tooltip
              placement="bottom"
              arrow
              title={(() => {
                switch (true) {
                  case !!formDataValidationResult.fieldValidations
                    .selectedSourceAccountViewModel:
                    return "You are not a signatory on the trading account";
                  case !!formDataValidationResult.fieldValidations
                    .subscriptionQuantity:
                    return formDataValidationResult.fieldValidations
                      .subscriptionQuantity;
                  default:
                    return "";
                }
              })()}
            >
              <span style={{ width: "100%" }}>
                <Button
                  fullWidth={true}
                  size={smDown ? "large" : "medium"}
                  variant="contained"
                  color="primary"
                  disabled={
                    submittingOrder ||
                    formDataValidationInProgress ||
                    !formDataValidationResult.valid ||
                    !potentialSourceAccountsLoaded
                  }
                  title={(() => {
                    switch (true) {
                      case !!formDataValidationResult.fieldValidations
                        .selectedSourceAccountViewModel:
                        return "You are not a signatory on the trading account";
                    }
                    return "";
                  })()}
                  onClick={() => {
                    setShowConfirmationDialog(true);
                  }}
                >
                  Next
                </Button>
              </span>
            </Tooltip>
          </DialogTitle>
        </>
      ) : (
        <Dialog
          PaperProps={{
            "data-component-info": JSON.stringify({
              component_id: "asset_card",
              component_business_name: "asset_card",
              component_title: formData.model.assetShortName,
              component_driver: InteractionDriver.DriveTransaction,
            } as DataComponentInfo),
          }}
          open
          fullScreen={smDown}
        >
          <SubscriptionTicketTitle
            marketListingViewModel={marketListingViewModel}
            submittingOrder={submittingOrder}
            formData={formData}
            formDataUpdate={formDataUpdate}
            closeDialog={closeDialog}
          />
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: { sm: 537 },
              width: { sm: 450 },
            }}
          >
            <Box
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: theme.spacing(2),
                alignItems: "center",
                justifyItems: "center",
              })}
            >
              <CircularProgress size={70} />
              <Typography
                variant={"h5"}
                color={"textSecondary"}
                children={"Getting things ready for you..."}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Dialog>
  );
};

interface SubscriptionTicketTitleProps {
  marketListingViewModel: MarketListingViewModel;
  submittingOrder: boolean;
  formData: FormData;
  formDataUpdate: FormDataUpdaterSpecsType;
  closeDialog: () => void;
}

const SubscriptionTicketTitle = ({
  marketListingViewModel,
  submittingOrder,
  formData,
  formDataUpdate,
  closeDialog,
}: SubscriptionTicketTitleProps) => {
  return (
    <DialogTitle
      sx={{
        display: "grid",
        gridTemplateColumns: "56px auto 40px",
        height: 80,
      }}
    >
      <IconViewUpload
        id={`smallHeader-icon-iconViewUpload-${marketListingViewModel.assetName}`}
        token={marketListingViewModel.token}
        tokenIconDownloadURL={marketListingViewModel.tokenIconURL}
        tokenOwnerID={marketListingViewModel.assetOwnerID}
        size={48}
      />
      <Box sx={{ maxWidth: "80%" }}>
        <Typography
          variant="h5"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
          children={marketListingViewModel.assetName}
        />
        <Typography
          variant="caption"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
          children={marketListingViewModel.assetShortName}
        />
      </Box>
      {submittingOrder && <CircularProgress size={20} />}
      {!submittingOrder && (
        <IconButton
          sx={{ width: 24, height: 24 }}
          disabled={submittingOrder}
          onClick={() => {
            closeDialog();

            formDataUpdate.subscriptionQuantity(
              formData.subscriptionQuantity.setValue(new BigNumber(1)),
            );
            formDataUpdate.investmentAmount(
              formData.investmentAmount.setValue(
                marketListingViewModel.marketSubscriptionOrderBookViewModel
                  ?.unitPrice.value ?? new BigNumber(0),
              ),
            );
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};
