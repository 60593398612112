import {
  IconButton,
  Typography,
  Box,
  alpha,
  Tooltip,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
  OpenInNew as OpenInNewIcon,
  QueryStats,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ReadManyPaymentRecipientRequest,
  ReadManyPaymentRecipientResponse,
} from "@mesh/common-js/dist/financial/paymentRecipientReader_meshproto_pb";
import { PaymentRecipient as PaymentRecipient } from "@mesh/common-js/dist/financial/paymentRecipient_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as PastQuery } from "james/search/query";
import { BPTable } from "components/Table";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { Popover } from "components/PopOver/Popover";
import { PaymentRecipientData } from "@mesh/common-js/dist/financial/paymentRecipientData_pb";
import { HoldingEntryAccountLabelPrettyString } from "james/financial";
import { newTextSubstringCriterion } from "@mesh/common-js/dist/search";

export type TableProps = {
  system: boolean;
  height: number;
  filter?: Criterion[];
};

export const Table = (props: TableProps) => {
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const {
    financial: { paymentRecipientReader, paymentRecipientReaderUNSCOPED },
  } = useAPIContext();
  const [readRequest, setReadRequest] =
    useState<ReadManyPaymentRecipientRequest>(
      new ReadManyPaymentRecipientRequest()
        .setContext(authContext.toFuture())
        .setQuery(new Query().setOffset(0).setLimit(15))
        .setCriteriaList(props.filter ?? []),
    );
  const updateReadRequestQuery = useCallback(
    (newreadRequestQuery: Query) =>
      setReadRequest(
        new ReadManyPaymentRecipientRequest()
          .setContext(readRequest.getContext())
          .setCriteriaList(readRequest.getCriteriaList())
          .setQuery(newreadRequestQuery),
      ),
    [readRequest],
  );
  const updateReadRequestCriteria = useCallback(
    (newReadRequestCriteria: Criterion[]) =>
      setReadRequest(
        new ReadManyPaymentRecipientRequest()
          .setContext(readRequest.getContext())
          .setCriteriaList(
            props.filter
              ? [...props.filter, ...newReadRequestCriteria]
              : newReadRequestCriteria,
          )
          .setQuery(readRequest.getQuery()),
      ),
    [readRequest],
  );
  const [readResponse, setReadResponse] =
    useState<ReadManyPaymentRecipientResponse>(
      new ReadManyPaymentRecipientResponse(),
    );
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManyPaymentRecipientResponse;
        if (props.system) {
          response =
            await paymentRecipientReaderUNSCOPED.readManyPaymentRecipientUNSCOPED(
              readRequest,
            );
        } else {
          response =
            await paymentRecipientReader.readManyPaymentRecipient(readRequest);
        }
        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error("error reading payment recipients", e);
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted]);

  const [textCriteriaContent, setTextCriteriaContent] = useState("");
  useEffect(() => {
    if (textCriteriaContent) {
      updateReadRequestCriteria([
        newTextSubstringCriterion("accountledgerid", textCriteriaContent),
      ]);
    } else {
      updateReadRequestCriteria([]);
    }
  }, [textCriteriaContent]);

  return (
    <BPTable
      loading={loading}
      height={props.height}
      noDataSplashComponent={noDataSplashComponent()}
      disableSelect
      title={"Payment Recipients"}
      query={PastQuery.fromFutureQuery(readRequest.getQuery())}
      onQueryChange={(query) => updateReadRequestQuery(query.toFutureQuery())}
      data={readResponse.getRecordsList()}
      totalNoRecords={readResponse.getTotal()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        controls.push(
          <IconButton
            id={"paymentRecipientTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() =>
              setReadRequest(
                new ReadManyPaymentRecipientRequest()
                  .setContext(readRequest.getContext())
                  .setCriteriaList(readRequest.getCriteriaList())
                  .setQuery(readRequest.getQuery()),
              )
            }
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      filters={[
        <TextField
          id={"paymentRecipientsTable-accountLedgerID-textField"}
          sx={{ width: 160 }}
          variant={"outlined"}
          margin={"dense"}
          label={"Account Ledger ID"}
          placeholder={"Start Typing..."}
          value={textCriteriaContent}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setTextCriteriaContent(e.target.value)}
        />,
      ]}
      columns={[
        {
          label: "Account Ledger ID",
          field: "accountledgerid",
          accessor: (data) => {
            const paymentRecipient = data as PaymentRecipient;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                {paymentRecipient.getAccountledgerid()}
              </Typography>
            );
          },
        },
        {
          label: "Amount",
          field: "dueamount.value.float",
          accessor: (data) => {
            const paymentRecipient = data as PaymentRecipient;
            return (
              <Amount
                amount={LedgerAmount.fromFutureAmount(
                  paymentRecipient.getDueamount(),
                )}
                formatTextNumOpts={{ noDecimalPlaces: 7 }}
              />
            );
          },
        },
        {
          label: "Number of Tokens",
          field: "data",
          accessor: (data) => {
            const paymentRecipient = data as PaymentRecipient;
            const paymentRecipientData = paymentRecipient.getData();

            if (!paymentRecipientData) {
              return <Amount amount={new LedgerAmount()} />;
            } else {
              const perUnitHeldAmount = paymentRecipientData
                .getPerunitheldpaymentrecipientdata()
                ?.getAmountheld();
              return (
                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <Amount
                    rootStyles={{ mr: 3 }}
                    amount={LedgerAmount.fromFutureAmount(perUnitHeldAmount)}
                  />
                  <Popover
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    popOverComponent={
                      <RecipientData recipientData={paymentRecipientData} />
                    }
                  >
                    <Tooltip
                      title="Select to view recipient data"
                      placement="top"
                    >
                      <QueryStats
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Popover>
                </Box>
              );
            }
          },
        },
        {
          field: "",
          label: "",
          accessor: (data) => {
            const paymentRecipient = data as PaymentRecipient;
            const ledgerNetwork = paymentRecipient
              .getDueamount()
              ?.getToken()
              ?.getNetwork();
            return (
              <Tooltip
                placement="top"
                title="View payment recipient account on blockchain explorer."
              >
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    switch (ledgerNetwork) {
                      case FutureNetwork.STELLAR_TEST_SDF_NETWORK:
                        window.open(
                          `https://stellar.expert/explorer/testnet/account/${paymentRecipient.getAccountledgerid()}`,
                          "_blank",
                        );
                        break;
                      case FutureNetwork.STELLAR_PUBLIC_NETWORK:
                        window.open(
                          `https://stellar.expert/explorer/public/account/${paymentRecipient.getAccountledgerid()}`,
                          "_blank",
                        );
                        break;
                    }
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            );
          },
        },
      ]}
    />
  );
};

interface recipientDataProps {
  recipientData: PaymentRecipientData;
}

const RecipientData = (props: recipientDataProps) => {
  const perUnitHeld = props.recipientData.getPerunitheldpaymentrecipientdata();

  if (!perUnitHeld) {
    return <div>No Per Unit Held Data Available</div>;
  }

  return (
    <Card style={{ backgroundColor: "#1d1f21" }}>
      <CardContent>
        {
          <ul>
            <li>
              <p>
                <strong>Amount Held</strong>:{" "}
                <Amount
                  amount={LedgerAmount.fromFutureAmount(
                    perUnitHeld.getAmountheld(),
                  )}
                  formatTextNumOpts={{ noDecimalPlaces: 7 }}
                />
              </p>
            </li>
            <li>
              <p>
                <strong>Amount per Unit</strong>:{" "}
                <Amount
                  amount={LedgerAmount.fromFutureAmount(
                    perUnitHeld.getUnitamount(),
                  )}
                  formatTextNumOpts={{ noDecimalPlaces: 7 }}
                />
              </p>
            </li>
            <li>
              <p>
                <strong>Entries List</strong>
              </p>
              <ul>
                {perUnitHeld.getEntriesList().map((entry, idx) => {
                  return (
                    <li key={idx}>
                      <p>
                        <strong>Holding Entry Account Label</strong>:{" "}
                        {HoldingEntryAccountLabelPrettyString(
                          entry.getAccountlabel(),
                        )}
                      </p>
                      <p>
                        <strong>Amount Held</strong>:{" "}
                        <Amount
                          amount={LedgerAmount.fromFutureAmount(
                            entry.getAmountheld(),
                          )}
                        />
                      </p>
                      <p>
                        <strong>Ledger Account ID</strong>:{" "}
                        {entry.getLedgeraccountid()}
                      </p>
                      <p>
                        <strong>Ownership Record ID</strong>:{" "}
                        {entry.getOwnershiprecordid()}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        }
      </CardContent>
    </Card>
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>Payment Recipients</i> here once the asset holder
              lookup is complete.
            </span>
          }
        />
      </Box>
    </Box>
  );
};
