// source: financial/dateGenerationRule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.DateGenerationRule', null, global);
/**
 * @enum {number}
 */
proto.financial.DateGenerationRule = {
  UNDEFINED_DATE_GENERATION_RULE: 0,
  BACKWARD_DATE_GENERATION_RULE: 1,
  FORWARD_DATE_GENERATION_RULE: 2,
  ZERO_DATE_GENERATION_RULE: 3,
  THIRD_WEDNESDAY_DATE_GENERATION_RULE: 4,
  THIRD_WEDNESDAY_INCLUSIVE_DATE_GENERATION_RULE: 5,
  TWENTIETH_DATE_GENERATION_RULE: 6,
  TWENTIETH_IMM_DATE_GENERATION_RULE: 7,
  OLD_CDS_DATE_GENERATION_RULE: 8,
  CDS_DATE_GENERATION_RULE: 9,
  CDS2015_DATE_GENERATION_RULE: 10
};

goog.object.extend(exports, proto.financial);
