import { AuditEntryActionType } from "james/audit";
import dayjs from "dayjs";

export class Entry {
  public userID = "";

  public action: AuditEntryActionType =
    AuditEntryActionType.CREATEDAuditActionType;

  public time: string = dayjs().format();

  public version = 0;

  constructor(entry?: Entry) {
    if (!entry) {
      return;
    }
    this.userID = entry.userID;
    this.action = entry.action;
    this.time = entry.time;
    this.version = entry.version;
  }
}
