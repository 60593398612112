import React from "react";

export interface RouteType {
  name: string;
  id: string;
  path: string;
  icon?: React.ComponentType;
  component?: JSX.Element;
  allowSubPaths?: boolean;
}

export const redirectURLKey = "redirect_url";
