import { create } from "zustand";
import dayjs from "dayjs";
import {
  CompanyRepresentative,
  ConnectedCompany,
  ConnectedIndividual,
  LegalForm,
} from "james/legal";
import { Address } from "james/location";
import { StockExchange } from "james/financial/StockExchange";
import { Company } from "james/legal/company";
import { IndustryClassification } from "james/legal/IndustryClassification";

interface TCompanyDetailsViewState {
  loading: boolean;
  // company information
  registeredName: string;
  listedExchange: StockExchange;
  industryClassification: IndustryClassification;
  taxReferenceNumber: string;
  vatRegistrationNumber: string;
  taxNumber: string;
  registrationNumber: string;
  vatNumber: string;
  listingReference: string;
  countryOfIncorporation: string;
  formOfIncorporation: LegalForm | "";
  // company addresses
  businessAddress: Address;
  registeredAddress: Address;
  headOfficeAddress: Address;
  // connected individuals
  connectedIndividuals: ConnectedIndividual[];
  connectedCompanies: ConnectedCompany[];
  // company representative
  companyRepresentative: CompanyRepresentative;
  // cached company
  cachedCompany: Company;
  lastUpdateDate: string;
  lastUpdateBy: string;
  // ui
  showConnectedIndividualDialog: boolean;
  selectedConnectedIndividual: ConnectedIndividual | undefined;
  showConnectedEntitiesDialog: boolean;
  selectedConnectedEntity: ConnectedCompany | undefined;
  selectedConnectedIndividualIdx: number;
  selectedConnectedEntitiesIdx: number;
}

interface TCompanyDetailsViewActions {
  setLoading: (val: boolean) => void;
  setRegisteredName: (val: string) => void;
  setListedExchange: (val: StockExchange) => void;
  setIndustryClassification: (val: IndustryClassification) => void;
  setTexReferenceNumber: (val: string) => void;
  setVATRegistrationNumber: (val: string) => void;
  setTaxNumber: (val: string) => void;
  setRegistrationNumber: (val: string) => void;
  setVATNumber: (val: string) => void;
  setListingReference: (val: string) => void;
  setCountryOfIncorporation: (val: string) => void;
  setFormOfIncorporation: (val: LegalForm) => void;
  setBusinessAddress: (val: Address) => void;
  setRegisteredAddress: (val: Address) => void;
  setHeadOfficeAddress: (val: Address) => void;
  setCachedCompany: (val: Company) => void;
  setCompanyRepresentative: (val: CompanyRepresentative) => void;
  setLastUpdateDate: (val: string) => void;
  setLastUpdateBy: (val: string) => void;
  setShowConnectedIndividualDialog: (val: boolean) => void;
  setShowConnectedEntitiesDialog: (val: boolean) => void;
  setConnectedIndividuals: (val: ConnectedIndividual[]) => void;
  updateConnectedIndividualInList: (
    idx: number,
    val: ConnectedIndividual,
  ) => void;
  addConnectedIndividualToList: (val: ConnectedIndividual) => void;
  removeConnectedIndividualFromList: (idx: number) => void;
  setConnectedCompanies: (val: ConnectedCompany[]) => void;
  updateConnectedCompanyInList: (idx: number, val: ConnectedCompany) => void;
  addConnectedEntityToList: (val: ConnectedCompany) => void;
  removeConnectedEntityFromList: (idx: number) => void;
  setSelectedConnectedIndividualIdx: (idx: number) => void;
  setSelectedConnectedEntitiesIdx: (idx: number) => void;
  setSelectedConnectedIndividual: (
    val: ConnectedIndividual | undefined,
  ) => void;
  setSelectedConnectedEntity: (val: ConnectedCompany | undefined) => void;
  setStateFrom: (company: Company) => void;
  getCompanyObject: () => Company;
  clear: () => void;
}

const initialCompanyDetailsViewState: TCompanyDetailsViewState = {
  loading: false,
  registeredName: "",
  listedExchange: { code: "", name: "" },
  industryClassification: new IndustryClassification(),
  taxReferenceNumber: "",
  vatRegistrationNumber: "",
  taxNumber: "",
  registrationNumber: "",
  vatNumber: "",
  listingReference: "",
  countryOfIncorporation: "",
  formOfIncorporation: "",
  businessAddress: new Address(),
  registeredAddress: new Address(),
  headOfficeAddress: new Address(),
  connectedIndividuals: [],
  connectedCompanies: [],
  companyRepresentative: new CompanyRepresentative(),
  cachedCompany: new Company(),
  lastUpdateBy: "",
  lastUpdateDate: dayjs().format(),
  showConnectedIndividualDialog: false,
  selectedConnectedIndividual: undefined,
  selectedConnectedEntity: undefined,
  showConnectedEntitiesDialog: false,
  selectedConnectedIndividualIdx: -1,
  selectedConnectedEntitiesIdx: -1,
};

export const useCompanyDetailViewStore = create<
  TCompanyDetailsViewState & TCompanyDetailsViewActions
>((set, get) => ({
  ...initialCompanyDetailsViewState,
  setLoading: (val: boolean) => set(() => ({ loading: val })),
  setRegisteredName: (val: string) => set(() => ({ registeredName: val })),
  setListedExchange: (val: StockExchange) =>
    set(() => ({ listedExchange: val })),
  setIndustryClassification: (val: IndustryClassification) =>
    set(() => ({ industryClassification: val })),
  setTexReferenceNumber: (val: string) =>
    set(() => ({ taxReferenceNumber: val })),
  setVATRegistrationNumber: (val: string) =>
    set(() => ({ vatRegistrationNumber: val })),
  setTaxNumber: (val: string) => set(() => ({ taxNumber: val })),
  setRegistrationNumber: (val: string) =>
    set(() => ({ registrationNumber: val })),
  setVATNumber: (val: string) => set(() => ({ vatNumber: val })),
  setListingReference: (val: string) => set(() => ({ listingReference: val })),
  setCountryOfIncorporation: (val: string) =>
    set(() => ({ countryOfIncorporation: val })),
  setFormOfIncorporation: (val: LegalForm | "") =>
    set({ formOfIncorporation: val }),
  // company addresses
  setBusinessAddress: (val: Address) => set(() => ({ businessAddress: val })),
  setRegisteredAddress: (val: Address) =>
    set(() => ({ registeredAddress: val })),
  setHeadOfficeAddress: (val: Address) => set({ headOfficeAddress: val }),
  // connected individuals
  setConnectedIndividuals: (val: ConnectedIndividual[]) =>
    set(() => ({ connectedIndividuals: val })),
  setConnectedCompanies: (val: ConnectedCompany[]) =>
    set(() => ({ connectedCompanies: val })),
  setCompanyRepresentative: (val: CompanyRepresentative) =>
    set({ companyRepresentative: val }),
  // cached company
  setCachedCompany: (val: Company) => set({ cachedCompany: val }),
  setLastUpdateBy: (val: string) => set(() => ({ lastUpdateBy: val })),
  setLastUpdateDate: (val: string) => set(() => ({ lastUpdateDate: val })),
  // ui
  setShowConnectedIndividualDialog: (val: boolean) =>
    set(() => ({ showConnectedIndividualDialog: val })),
  setShowConnectedEntitiesDialog: (val: boolean) =>
    set(() => ({ showConnectedEntitiesDialog: val })),
  updateConnectedIndividualInList: (idx: number, val: ConnectedIndividual) => {
    const state = get();
    const list = state.connectedIndividuals;
    list[idx] = val;
    set(() => ({ connectedIndividuals: [...list] }));
  },
  addConnectedIndividualToList: (val: ConnectedIndividual) => {
    const state = get();
    set(() => ({
      connectedIndividuals: [...state.connectedIndividuals, val],
    }));
  },
  removeConnectedIndividualFromList: (idx: number) => {
    const state = get();
    const list = state.connectedIndividuals;
    list.splice(idx, 1);
    set(() => ({
      connectedIndividuals: [...list],
      selectedConnectedIndividualIdx: -1,
    }));
  },
  removeConnectedEntityFromList: (idx: number) => {
    const state = get();
    const list = state.connectedCompanies;
    list.splice(idx, 1);
    set(() => ({
      connectedCompanies: [...list],
      selectedConnectedEntitiesIdx: -1,
    }));
  },
  updateConnectedCompanyInList: (idx: number, val: ConnectedCompany) => {
    const state = get();
    const list = state.connectedCompanies;
    list[idx] = val;
    set(() => ({ connectedCompanies: [...list] }));
  },
  addConnectedEntityToList: (val: ConnectedCompany) => {
    const state = get();
    set(() => ({
      connectedCompanies: [...state.connectedCompanies, val],
    }));
  },
  setSelectedConnectedIndividualIdx: (val: number) =>
    set({ selectedConnectedIndividualIdx: val }),
  setSelectedConnectedEntitiesIdx: (val: number) =>
    set({ selectedConnectedEntitiesIdx: val }),
  setSelectedConnectedEntity: (val: ConnectedCompany | undefined) =>
    set({ selectedConnectedEntity: val }),
  setSelectedConnectedIndividual: (val: ConnectedIndividual | undefined) =>
    set({ selectedConnectedIndividual: val }),
  setStateFrom: (company: Company) =>
    set({
      registeredName: company.registeredName,
      listedExchange: company.listedExchange,
      industryClassification: company.industryClassification,
      listingReference: company.listingReference,
      taxReferenceNumber: company.taxReferenceNumber,
      registrationNumber: company.registrationNumber,
      vatRegistrationNumber: company.vatRegistrationNumber,
      companyRepresentative: company.companyRepresentative,
      countryOfIncorporation: company.countryOfIncorporation,
      formOfIncorporation: company.formOfIncorporation,
      registeredAddress: company.registeredAddress,
      businessAddress: company.businessAddress,
      headOfficeAddress: company.headOfficeAddress,
      connectedIndividuals: company.connectedIndividuals,
      connectedCompanies: company.connectedCompanies,
    }),
  getCompanyObject: () => {
    const state = get();
    return new Company({
      ...state.cachedCompany,
      registeredName: state.registeredName,
      listedExchange: state.listedExchange,
      industryClassification: state.industryClassification,
      listingReference: state.listingReference,
      taxReferenceNumber: state.taxReferenceNumber,
      registrationNumber: state.registrationNumber,
      vatRegistrationNumber: state.vatRegistrationNumber,
      companyRepresentative: state.companyRepresentative,
      countryOfIncorporation: state.countryOfIncorporation,
      formOfIncorporation: state.formOfIncorporation as LegalForm,
      registeredAddress: state.registeredAddress,
      businessAddress: state.businessAddress,
      headOfficeAddress: state.headOfficeAddress,
      connectedIndividuals: state.connectedIndividuals,
      connectedCompanies: state.connectedCompanies,
    });
  },
  clear: () => set(() => initialCompanyDetailsViewState),
}));
