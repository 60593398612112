import { Context } from "james/security";
import { ClientProfile, ClientType } from "james/client";
import { Identifier } from "james/search/identifier/Identifier";
import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export type InviteCompanyClientRequest = {
  context: Context;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  clientShortName: string;
  clientTypes: ClientType[];
};

export type InviteCompanyClientResponse = Record<string, never>;

export type CompleteClientProfileRequest = {
  context: Context;
  clientIdentifier: Identifier;
  companyDescription: string;
  companyWebsite: string;
  companyLinkedIn: string;
  companyTwitter: string;
  companyOtherSite: string;
};

export type CompleteClientProfileResponse = Record<string, never>;

export type UpdateClientProfileRequest = {
  context: Context;
  clientIdentifier: Identifier;
  profile: ClientProfile;
};

export type UpdateClientProfileResponse = Record<string, never>;

export const ClientManagerWriteServiceProviderName = "client-ManagerWrite";

export const ManagerWrite = {
  async InviteCompanyClient(
    request: InviteCompanyClientRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<InviteCompanyClientResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ClientManagerWriteServiceProviderName}.InviteCompanyClient`,
        request,
      },
      opts,
    );
  },
  async CompleteClientProfile(
    request: CompleteClientProfileRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CompleteClientProfileResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ClientManagerWriteServiceProviderName}.CompleteClientProfile`,
        request,
      },
      opts,
    );
  },
  async UpdateClientProfile(
    request: UpdateClientProfileRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<UpdateClientProfileResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ClientManagerWriteServiceProviderName}.UpdateClientProfile`,
        request,
      },
      opts,
    );
  },
};
