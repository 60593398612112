import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import { AssetDataSheet } from "./AssetDataSheet";

export interface ReadAssetDataSheetRequest {
  assetIdentifier: Identifier;
}

export interface ReadAssetDataSheetResponse {
  dataSheet: AssetDataSheet;
}

export const AssetDataSheetReader = {
  serviceProvider: "marketData-AssetDataSheetReader",
  async ReadAssetDataSheet(
    request: ReadAssetDataSheetRequest,
  ): Promise<ReadAssetDataSheetResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshPublicURL"),
      method: `${AssetDataSheetReader.serviceProvider}.ReadAssetDataSheet`,
      request,
    });
    return { dataSheet: new AssetDataSheet(response.dataSheet) };
  },
};
