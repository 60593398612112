import { Context, Permission } from "james/security";
import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export enum ScopeFields {
  IDField = "id",
  OwnerIDField = "ownerID",
}

export interface DetermineScopeCriteriaByRolesRequest {
  context: Context;
  service: Permission;
  scopeFields: ScopeFields[];
  buildScopeTree: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface DetermineScopeCriteriaByRolesResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface DetermineScopeCriteriaByClientRequest {
  context: Context;
  scopeFields: ScopeFields[];
  buildScopeTree: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface DetermineScopeIdentifierByClientResponse {
  identifier: Identifier;
}

export interface DetermineScopeIdentifierByRolesRequest {
  context: Context;
  service: Permission;
  scopeFields: ScopeFields[];
  buildScopeTree: boolean;
  identifier: Identifier;
}

export interface DetermineScopeIdentifierByRolesResponse {
  identifier: Identifier;
}

export interface DetermineScopeAuthorisationByClientRequest {
  context: Context;
  groupID: Identifier;
  buildScopeTree: boolean;
}

export interface DetermineScopeAuthorisationByClientResponse {
  authorized: boolean;
}

export interface DetermineScopeAuthorisationByRolesRequest {
  context: Context;
  groupID: string;
  buildScopeTree: boolean;
  service: Permission;
}

export interface DetermineScopeAuthorisationByRolesResponse {
  authorized: boolean;
}

export const Determiner = {
  serviceProvider: "scope-Determiner",
  async DetermineScopeCriteriaByRoles(
    request: DetermineScopeCriteriaByRolesRequest,
  ): Promise<DetermineScopeCriteriaByRolesResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Determiner.serviceProvider}.DetermineScopeCriteriaByRoles`,
      request,
    });
    return { criteria: response.criteria };
  },
  async DetermineScopeAuthorisationByRoles(
    request: DetermineScopeAuthorisationByRolesRequest,
  ): Promise<DetermineScopeAuthorisationByRolesResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Determiner.serviceProvider}.DetermineScopeAuthorisationByRoles`,
      request,
    });
    return { authorized: response.authorized };
  },
  async DetermineScopeAuthorisationByClient(
    request: DetermineScopeAuthorisationByClientRequest,
  ): Promise<DetermineScopeAuthorisationByClientResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${Determiner.serviceProvider}.DetermineScopeAuthorisationByClient`,
      request,
    });
    return { authorized: response.authorized };
  },
};
