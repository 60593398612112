export class IndustryClassification {
  public industryCode = 0;

  public industryName = "";

  public subIndustryCode = 0;

  public subIndustryName = "";

  constructor(industryClassification?: IndustryClassification) {
    if (!industryClassification) {
      return;
    }
    this.industryCode = industryClassification.industryCode;
    this.industryName = industryClassification.industryName;
    this.subIndustryCode = industryClassification.subIndustryCode;
    this.subIndustryName = industryClassification.subIndustryName;
  }
}
