import React from "react";
import { Box, BoxProps } from "@mui/material";
import { DataComponentInfo } from "const/gtm";

export const GettingStartedStepWrapper = (props: BoxProps) => {
  return (
    <Box
      data-component-info={JSON.stringify({
        component_business_name: "login tour",
        component_driver: "tutorial",
        component_id: "start-login-tour",
        component_title: "crypto and digital instruments",
      } as DataComponentInfo)}
      data-testid="getting_started_step_gtm_wrapper"
      {...props}
    />
  );
};
