import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, YAxis } from "recharts";
import { Token } from "james/ledger";
import { useIsMounted } from "hooks";
import { useRQPriceHistorian } from "hooks/reactQuery/useRQPriceHistorian";
import { HistoricalPrice, Period, Resolution } from "pkgTemp/market";

interface PerformanceChartSplashProps {
  baseToken: Token;
  quoteToken: Token;
}

export const CryptoPerformanceChartSplash = (
  props: PerformanceChartSplashProps,
) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const { GetPriceForPeriod: PriceHistorianGetPriceForPeriod } =
    useRQPriceHistorian();
  const [prices, setPrices] = useState<HistoricalPrice[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const prices = (
          await PriceHistorianGetPriceForPeriod({
            baseToken: props.baseToken,
            quoteToken: props.quoteToken,
            period: Period._1M,
            resolution: Resolution.Day,
          })
        ).prices;
        if (isMounted()) {
          setPrices(prices);
        }
      } catch (e) {
        console.error(`error getting prices: ${e}`);
      }
    })();
  }, [props.baseToken, props.quoteToken]);

  // render nothing if no prices were found
  if (!prices.length) {
    return null;
  }

  return (
    <ResponsiveContainer width={"100%"} height={"90%"}>
      <AreaChart
        data={prices}
        margin={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <defs>
          <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.4}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <YAxis domain={["auto", "auto"]} hide />
        <Area
          dataKey={(val: HistoricalPrice) => val.avgPrice.value.toNumber()}
          stroke={"none"}
          fill={"url(#colorPrimary)"}
          fillOpacity={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
