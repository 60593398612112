import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";

export function EmailSubmittedCard() {
  return (
    <OnboardingCard linksFooter={<></>}>
      <VerticalSlide transitionDuration={1 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 4, 0.5),
          })}
          fontSize="20px"
          fontWeight="600"
          children="Thank You!"
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(3),
            color: theme.palette.text.tertiary,
            width: "312px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={"We have received your information."}
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={3 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(3),
            color: theme.palette.text.tertiary,
            width: "312px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={
            "You will be notified once Mesh becomes available in your region."
          }
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={4 / 5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            paddingBottom: theme.spacing(5),
            color: theme.palette.text.tertiary,
            width: "312px",
          })}
          fontSize={"16px"}
          align={"center"}
          children={"For more information visit our website..."}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          id="signUp-countryOfResidence-emailSubmittedCard-meshWebsite-button"
          sx={(theme) => ({
            height: theme.spacing(6),
            boxShadow: 5,
            width: "100%",
          })}
          color="primary"
          variant="contained"
          onClick={() => {
            window.open("https://www.mesh.trade/", "_blank");
          }}
          size="large"
          children="Mesh website"
        />
      </VerticalSlide>
      <Typography
        sx={(theme) => ({
          padding: theme.spacing(8, 0, 1, 0),
          color: theme.palette.text.tertiary,
        })}
        fontSize={"16px"}
        align={"center"}
        children={"Or follow us on"}
      />
      <Box
        sx={(theme) => ({
          width: "170px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          paddingBottom: theme.spacing(8),
        })}
      >
        <IconButton
          id="signUp-countryOfResidence-emailSubmittedCard-facebookButton"
          sx={{ margin: 0, padding: 0 }}
          onClick={() => {
            window.open("https://www.facebook.com/Mesh.trade/", "_blank");
          }}
          disableRipple
        >
          <FacebookIcon
            sx={(theme) => ({
              color: theme.palette.text.tertiary,
              width: theme.spacing(5),
              height: theme.spacing(5),
            })}
          />
        </IconButton>
        <IconButton
          id="signUp-countryOfResidence-emailSubmittedCard-twitterButton"
          sx={{ margin: 0, padding: 0 }}
          onClick={() => {
            window.open("https://twitter.com/mesh_trade", "_blank");
          }}
          disableRipple
        >
          <TwitterIcon
            sx={(theme) => ({
              color: theme.palette.text.tertiary,
              width: theme.spacing(4),
              height: theme.spacing(3.25),
            })}
          />
        </IconButton>
        <IconButton
          id="signUp-countryOfResidence-emailSubmittedCard-linkedInButton"
          sx={{ margin: 0, padding: 0 }}
          onClick={() => {
            window.open(
              "https://www.linkedin.com/company/mesh-trade/",
              "_blank",
            );
          }}
          disableRipple
        >
          <LinkedInIcon
            sx={(theme) => ({
              color: theme.palette.text.tertiary,
              width: theme.spacing(5),
              height: theme.spacing(5),
            })}
          />
        </IconButton>
      </Box>
    </OnboardingCard>
  );
}
