import React, { useCallback, useState } from "react";
import { CompanyOnboardingRequestCard } from "./components/CompanyOnboardingRequestCard/CompanyOnboardingRequestCard";
import { Box } from "@mui/material";
import { useWidth } from "utilities/general";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { ContactUs } from "./components/ContactUs/ContactUs";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import * as Sentry from "@sentry/react";
import { CompanyOnboardingRequestMailer } from "james/onboarding/CompanyOnboardingRequestMailer";
import { useSnackbar } from "notistack";
import background from "assets/images/background/background.png";
import { useErrorContext } from "context/Error";

export function CompanyOnboardingRequest() {
  const { errorContextErrorTranslator } = useErrorContext();
  const [displayContactUsCard, setContactUsCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const width = useWidth();
  const { enqueueSnackbar } = useSnackbar();

  // on send request send new company onboarding request email
  const onSendRequest = useCallback(
    async (adminName: string, adminEmail: string, companyName: string) => {
      setLoading(true);
      try {
        // set sentry user
        Sentry.setUser({
          email: adminEmail,
          id: "",
          ip_address: "{{auto}}",
        });

        await CompanyOnboardingRequestMailer.SendCompanyOnboardingRequestMail({
          adminEmail,
          adminName,
          companyName,
        });

        setContactUsCard(true);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        Sentry.captureException(
          `error sending company client onboarding request email: ${
            err.message ? err.message : err.toString()
          }`,
        );
        console.error(
          `error sending company client onboarding request email: ${
            err.message ? err.message : err.toString()
          }`,
        );

        enqueueSnackbar(
          `error sending company client onboarding request email: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      setLoading(false);
    },
    [],
  );

  // display the loading view
  if (loading) {
    return <LoadingScreen />;
  }

  // display mesh contact us card
  if (displayContactUsCard) {
    return <ContactUs />;
  }

  return (
    <>
      {isWidthUp("sm", width) ? (
        <Box
          sx={{
            display: "grid",
            backgroundImage: `url(${background})`,
            gridTemplateRows: "auto 1fr",
            backgroundSize: "cover",
            height: "100vh",
            overflowY: isWidthUp("md", width) ? "hidden" : "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            <CompanyOnboardingRequestCard onSendRequest={onSendRequest} />
          </Box>
        </Box>
      ) : (
        <CompanyOnboardingRequestCard onSendRequest={onSendRequest} />
      )}
    </>
  );
}
