import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EditableInfo } from "views/UserProfileDialog/util";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Person, PersonContactDetails } from "james/legal/person";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataValidationFunc,
  formUpdater,
  FormUpdaterType,
} from "./contactDetailsUseValidatedForm";
import isEqual from "lodash/isEqual";

interface ContactDetailsProps {
  initialPerson: Person;
  loadingMyPerson: boolean;
  setGlobalEditing: React.Dispatch<React.SetStateAction<number>>;
  editValue: number;
  updateFunc: (info: Partial<Person>) => void;
  setPerson: React.Dispatch<React.SetStateAction<Person | undefined>>;
  errors: Record<string, string | undefined>;
  setErrors: (error: Record<string, string | undefined>) => void;
}

export const ContactDetails = ({
  initialPerson,
  loadingMyPerson,
  setGlobalEditing,
  editValue,
  updateFunc,
  setPerson,
  setErrors,
}: ContactDetailsProps): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [displayDetails, setDisplayDetails] = useState<PersonContactDetails>(
    initialPerson.contactDetails,
  );

  const [contactState, validationResults, updateForm, validationInProgress] =
    useValidatedForm<PersonContactDetails, FormUpdaterType>(
      formDataValidationFunc,
      async () => new PersonContactDetails(),
      formUpdater,
      new PersonContactDetails(),
      new Set<string>([]),
      { skipTouchedFieldsOnValidation: true },
    );

  // keep errors in form aligned with errors in parent component
  useEffect(() => {
    setErrors(
      validationResults.fieldValidations as {
        [key: string]: string | undefined;
      },
    );
  }, [validationResults.fieldValidations]);

  // keep initial person in parent component aligned with form state
  useEffect(() => {
    setPerson(
      new Person({
        ...initialPerson,
        contactDetails: new PersonContactDetails(contactState),
      }),
    );
  }, [contactState]);

  useEffect(() => {
    if (loadingMyPerson) {
      return;
    }
    updateForm.cellphoneNumber(initialPerson.contactDetails.cellphoneNumber);
    updateForm.telephoneNumber(initialPerson.contactDetails.telephoneNumber);
    updateForm.emailAddress(initialPerson.contactDetails.emailAddress);
    setDisplayDetails(initialPerson.contactDetails);
  }, [loadingMyPerson, initialPerson]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SkeletonLoader loading={loadingMyPerson}>
          <Typography sx={{ fontWeight: "bold" }}>Contact Details</Typography>
        </SkeletonLoader>
        {!loadingMyPerson && (
          <IconButton
            id="ContactDetails-Edit-IconButton"
            size={"small"}
            onClick={() => {
              if (!editMode) {
                setGlobalEditing(editValue + 1);
              } else {
                setGlobalEditing(editValue - 1);
              }
              setEditMode(!editMode);
            }}
            sx={(theme) => ({ mx: theme.spacing(2) })}
          >
            <EditRoundedIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "grid",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
            gridColumnGap: theme.spacing(3),
          },
          mb: theme.spacing(1),
        }}
      >
        <EditableInfo
          id="ContactDetails-Mobile"
          loading={loadingMyPerson}
          disabled={loading}
          fullWidth
          label="Mobile"
          value={
            editMode
              ? contactState.cellphoneNumber
              : displayDetails.cellphoneNumber
          }
          editing={editMode}
          onChange={(e) => {
            updateForm.cellphoneNumber(e.target.value);
          }}
          onClear={() => {
            updateForm.cellphoneNumber("");
          }}
          FormHelperTextProps={{
            sx: {
              color: theme.palette.error.main,
              my: 0,
            },
          }}
          helperText={validationResults.fieldValidations.cellphoneNumber}
        />
        <EditableInfo
          id="ContactDetails-Telephone"
          loading={loadingMyPerson}
          disabled={loading}
          fullWidth
          label="Telephone"
          value={
            editMode
              ? contactState.telephoneNumber
              : displayDetails.telephoneNumber
          }
          editing={editMode}
          onChange={(e) => {
            updateForm.telephoneNumber(e.target.value);
          }}
          onClear={() => {
            updateForm.telephoneNumber("");
          }}
          FormHelperTextProps={{
            sx: {
              color: theme.palette.error.main,
              my: 0,
            },
          }}
          helperText={validationResults.fieldValidations.telephoneNumber}
        />
        <EditableInfo
          id="ContactDetails-Email"
          loading={loadingMyPerson}
          disabled={loading}
          fullWidth
          label="Email"
          value={contactState.emailAddress}
        />
      </Box>
      <Collapse in={editMode && !isSmDown}>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Button
            id="ContactDetails-Save-Button"
            fullWidth={isSmDown}
            variant="outlined"
            children="Save"
            size="large"
            disabled={
              loading || !validationResults.valid || validationInProgress
            }
            sx={{
              height: {
                xs: "48px",
                sm: "36px",
              },
            }}
            onClick={async () => {
              setLoading(true);
              if (!isEqual(contactState, initialPerson.contactDetails)) {
                await updateFunc({
                  contactDetails: contactState,
                });
              }
              setEditMode(false);
              setGlobalEditing(editValue - 1);
              setLoading(false);
            }}
          />
          {loading && (
            <CircularProgress sx={(theme) => ({ mx: theme.spacing(2) })} />
          )}
        </Box>
      </Collapse>
    </>
  );
};
