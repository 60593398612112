// source: financial/smartInstrumentAttributeExternalParticipantsInformation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
goog.exportSymbol('proto.financial.ExternalParticipantInformationCategory', null, global);
goog.exportSymbol('proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute', null, global);
goog.exportSymbol('proto.financial.SmartInstrumentExternalParticipantInformationEntry', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.repeatedFields_, null);
};
goog.inherits(proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.displayName = 'proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.SmartInstrumentExternalParticipantInformationEntry.repeatedFields_, null);
};
goog.inherits(proto.financial.SmartInstrumentExternalParticipantInformationEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.SmartInstrumentExternalParticipantInformationEntry.displayName = 'proto.financial.SmartInstrumentExternalParticipantInformationEntry';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.financial.SmartInstrumentExternalParticipantInformationEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute}
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute;
  return proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute}
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.financial.SmartInstrumentExternalParticipantInformationEntry;
      reader.readMessage(value,proto.financial.SmartInstrumentExternalParticipantInformationEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.financial.SmartInstrumentExternalParticipantInformationEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SmartInstrumentExternalParticipantInformationEntry entries = 1;
 * @return {!Array<!proto.financial.SmartInstrumentExternalParticipantInformationEntry>}
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.financial.SmartInstrumentExternalParticipantInformationEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.financial.SmartInstrumentExternalParticipantInformationEntry, 1));
};


/**
 * @param {!Array<!proto.financial.SmartInstrumentExternalParticipantInformationEntry>} value
 * @return {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute} returns this
*/
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.SmartInstrumentExternalParticipantInformationEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry}
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.SmartInstrumentExternalParticipantInformationEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute} returns this
 */
proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.SmartInstrumentExternalParticipantInformationEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
category: jspb.Message.getFieldWithDefault(msg, 1, 0),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
address: jspb.Message.getFieldWithDefault(msg, 3, ""),
contactnumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
email: jspb.Message.getFieldWithDefault(msg, 5, ""),
descriptionList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
logo: jspb.Message.getFieldWithDefault(msg, 7, ""),
weblink: jspb.Message.getFieldWithDefault(msg, 8, ""),
youtubelink: jspb.Message.getFieldWithDefault(msg, 9, ""),
linkedinlink: jspb.Message.getFieldWithDefault(msg, 10, ""),
facebooklink: jspb.Message.getFieldWithDefault(msg, 11, ""),
instagramlink: jspb.Message.getFieldWithDefault(msg, 12, ""),
twitterlink: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.SmartInstrumentExternalParticipantInformationEntry;
  return proto.financial.SmartInstrumentExternalParticipantInformationEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.financial.ExternalParticipantInformationCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeblink(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubelink(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedinlink(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacebooklink(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagramlink(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitterlink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.SmartInstrumentExternalParticipantInformationEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescriptionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWeblink();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getYoutubelink();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLinkedinlink();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFacebooklink();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInstagramlink();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTwitterlink();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional ExternalParticipantInformationCategory category = 1;
 * @return {!proto.financial.ExternalParticipantInformationCategory}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getCategory = function() {
  return /** @type {!proto.financial.ExternalParticipantInformationCategory} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.financial.ExternalParticipantInformationCategory} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contactNumber = 4;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string description = 6;
 * @return {!Array<string>}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getDescriptionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setDescriptionList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.addDescription = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.clearDescriptionList = function() {
  return this.setDescriptionList([]);
};


/**
 * optional string logo = 7;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string webLink = 8;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getWeblink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setWeblink = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string youTubeLink = 9;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getYoutubelink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setYoutubelink = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string linkedInLink = 10;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getLinkedinlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setLinkedinlink = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string facebookLink = 11;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getFacebooklink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setFacebooklink = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string instagramLink = 12;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getInstagramlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setInstagramlink = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string twitterLink = 13;
 * @return {string}
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.getTwitterlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.SmartInstrumentExternalParticipantInformationEntry} returns this
 */
proto.financial.SmartInstrumentExternalParticipantInformationEntry.prototype.setTwitterlink = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * @enum {number}
 */
proto.financial.ExternalParticipantInformationCategory = {
  UNDEFINED_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 0,
  GUARANTOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 1,
  ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 2,
  ISSUER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 3,
  AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 4,
  PAYING_AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 5,
  TRANSFER_AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 6,
  CALCULATION_AGENT_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 7,
  CUSTODIAN_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 8,
  MARKET_MAKER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 9,
  EXCHANGE_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 10,
  BROKER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 11,
  MANAGER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 12,
  INVESTMENT_MANAGER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 13,
  PORTFOLIO_MANAGER_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 14,
  PORTFOLIO_ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 15,
  REFERENCE_PORTFOLIO_ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 16,
  DELEGATED_REFERENCE_PORTFOLIO_ADVISOR_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY: 17
};

goog.object.extend(exports, proto.financial);
