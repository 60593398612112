import BigNumber from "bignumber.js";
import numbro from "numbro";
import { FormatMoneyResponse, Formatter } from "./formatter";
import { Money } from "../money";
import { Format, getFormat } from "./Format";

class StdFormatter implements Formatter {
  formatMoney(amount: Money): FormatMoneyResponse {
    const { currency, fractionDigits, format, internalFormatter } =
      currencyParts(amount.currencyCode);

    const divider = new BigNumber(10).pow(fractionDigits);
    const decimalValue = new BigNumber(amount.amount).div(divider);

    const text = internalFormatter.format(decimalValue.toNumber());
    const symbolAtStart = format.template.startsWith("$");
    const textValue = text.replace(currency, "").trim();
    return {
      text,
      textValue,
      symbol: currency,
      symbolAtStart,
    };
  }

  formatMoneyText(text: string, currencyCode: string): string {
    if (text === "") {
      return text;
    }

    const bigTextValue = new BigNumber(text);
    if (bigTextValue.isNaN()) {
      return "";
    }

    const { currency, group, decimal, literals, internalFormatter } =
      currencyParts(currencyCode);
    if (text.length === 1) {
      if (["-", decimal].includes(text)) {
        return "";
      }
      return text;
    }

    // capture and remove any multiply modifier
    let multiplierModifier = new BigNumber(1);
    // tslint:disable-next-line:forin
    for (const modifier in specialCharacterModifiers) {
      const re1 = new RegExp(`$([0-9])*{modifier}$`);
      if (re1.test(text)) {
        text = text.replace(re1, "");
        multiplierModifier = new BigNumber(10).pow(
          specialCharacterModifiers[modifier],
        );
      }
    }

    // remove the currency/symbol
    // there must only be one occurrence of currency
    if (text.includes(currency)) {
      text = text.replace(currency, "");
    }

    // remove the literals
    for (const literal of literals) {
      text = text.replace(new RegExp(escapeRegExp(literal), "g"), "");
    }

    // remove the grouping symbols
    let re = new RegExp(escapeRegExp(group), "g");
    text = text.replace(re, "");

    // replace the decimal with '.' for js parsing
    // there MUST be only one occurrence of decimal
    if (decimal && decimal !== "") {
      text = text.replace(decimal, ".");
    }

    // strip all remaining illegal characters
    re = new RegExp(/[^0-9.-]+/g);
    if (re.test(text)) {
      console.warn(`value contains illegal characters: ${text}, removing them`);
      text = text.replace(re, "");
    }

    // try and parse the string as a Big
    try {
      const decimalValue = new BigNumber(text).times(multiplierModifier);
      let hasDecimal = text.includes(".");
      let fractionString = hasDecimal ? text.slice(text.indexOf(".") + 1) : "";
      if (!multiplierModifier.eq(1)) {
        // we remove the decimal point if the multiplier increased the value beyond the decimal point
        const fractionDecimal = decimalValue.mod(1);
        hasDecimal = fractionDecimal.gt(0);
        // adjust the fraction string (this will throw away trailing zeros)
        fractionString = hasDecimal ? fractionDecimal.toString().slice(2) : "";
      }

      return internalFormatter
        .formatToParts(decimalValue.toNumber())
        .map(({ type, value }) => {
          switch (type) {
            // remove the currency and literal
            case "currency":
            case "literal":
              return "";
            case "fraction":
              // if fractionString is bigger than value the currency was rounded by the formatter
              if (fractionString.length > value.length) {
                // use the rounded value
                return value;
              }
              return fractionString;
            case "decimal":
              return hasDecimal ? value : "";
            default:
              return value;
          }
        })
        .reduce((result, part) => result + part);
    } catch (e) {
      console.error(`Could not parse value '${text}' as decimal:`, e);
    }

    return "";
  }

  parseMoney(text: string, currencyCode: string): Money {
    if (text === "") {
      return new Money({
        amount: 0,
        currencyCode,
      });
    }

    const bigValueText = new BigNumber(text);
    if (bigValueText.isNaN()) {
      return new Money({
        amount: 0,
        currencyCode,
      });
    }

    let value = text;
    const { currency, group, decimal, literals, fractionDigits } =
      currencyParts(currencyCode);

    if (text.length === 1) {
      if (["-", decimal].includes(text)) {
        return new Money({
          amount: 0,
          currencyCode,
        });
      }
    }

    // remove the currency/symbol
    // there must only be one occurence of currency
    value = value.replace(currency, "");

    // remove the literals
    for (const literal of literals) {
      value = value.replace(new RegExp(escapeRegExp(literal), "g"), "");
    }

    value = value.replace(new RegExp(escapeRegExp(group), "g"), "");

    // replace the decimal with '.' for js parsing
    // there MUST be only one occurence of decimal
    if (decimal && decimal !== "") {
      value = value.replace(decimal, ".");
    }

    // try and parse the string as a Big
    try {
      const decimalValue = new BigNumber(value);

      // convert to minor units and return Money object
      const multiplier = new BigNumber(10).pow(fractionDigits);
      let minorUnitsValue = decimalValue.times(multiplier);

      if (!minorUnitsValue.mod(1).eq(0)) {
        minorUnitsValue = minorUnitsValue.integerValue(BigNumber.ROUND_DOWN);
        console.warn(`Wrong usage of text to money with value ${text}
      The value may have a maximum of ${fractionDigits} fractional digits
      The return value's minor units will be rounded to ${minorUnitsValue}`);
      }

      return new Money({
        amount: minorUnitsValue.toNumber(),
        currencyCode,
      });
    } catch (e) {
      console.error(`Could not parse value '${value}' as decimal:`, e);
      return new Money({
        amount: 0,
        currencyCode,
      });
    }
  }
}

/* Helper functions */
const currencyParts = (currencyCode: string) => {
  const format = getFormat(currencyCode);
  const internalFormatter = getInternalCurrencyFormatter(format);

  return {
    fractionDigits: format.fraction,
    currency: format.grapheme,
    group: format.thousand,
    decimal: format.decimal,
    literals: [], // TODO: Maybe interrogate the format.template for literals
    internalFormatter,
    format,
  };
};

const getInternalCurrencyFormatter = (format: Format) => {
  const roundingFunction = (value: number): number =>
    new BigNumber(value).decimalPlaces(format.fraction).toNumber();

  const formatToParts = (decimalValue: number): Intl.NumberFormatPart[] => {
    const parts: Intl.NumberFormatPart[] = [];

    for (const char of format.template) {
      switch (char) {
        case "$": {
          parts.push({
            type: "currency",
            value: format.grapheme,
          });
          break;
        }
        case "1": {
          const textValue = numbro(decimalValue).format({
            thousandSeparated: true,
            mantissa: format.fraction,
            roundingFunction,
          });
          const numberParts = textValue.split(".");
          const groups = numberParts[0].split(",");
          for (const group of groups) {
            parts.push({
              type: "integer",
              value: group,
            });
            parts.push({
              type: "group",
              value: ",",
            });
          }
          // remove the last group added
          parts.pop();
          // add the decimal point and fraction
          if (numberParts.length === 2) {
            parts.push({
              type: "decimal",
              value: ".",
            });
            parts.push({
              type: "fraction",
              value: numberParts[1],
            });
          }
          break;
        }
        default: {
          parts.push({
            type: "literal",
            value: char,
          });
          break;
        }
      }
    }
    return parts;
  };

  const formatF = (decimalValue: number) => {
    const textValue = numbro(decimalValue).format({
      thousandSeparated: true,
      mantissa: format.fraction,
      optionalMantissa: true,
      roundingFunction,
    });
    let result = format.template.replace("1", textValue);
    result = result.replace("$", format.grapheme);
    return result;
  };

  return {
    format: formatF,
    formatToParts,
  };
};

const specialCharacterModifiers: { [key: string]: number } = {
  k: 3,
  m: 6,
  b: 9,
};

const escapeRegExp = (re: string) =>
  re.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

export { StdFormatter };
