import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
import { AssetClass } from "@mesh/common-js/dist/financial/assetClass_pb";
import { AssetClassSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeAssetClass_pb";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { ValidationResult } from "common/validation";

export function validateAssetClassAttribute(
  attribute: AssetClassSmartInstrumentAttribute,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.ASSET_CLASS_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ] = message;
  };

  if (attribute.getAssetclass() === AssetClass.UNDEFINED_ASSET_CLASS) {
    invalid("assetClass", "Must be set");
  }

  return validationResult;
}
