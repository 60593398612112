import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { TextField, TextNumField } from "components/FormFields";
import {
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FinancialCurrencyStablecoinRepository,
  FinancialInstrumentCollection,
  FinancialInstrumentStablecoinCollection,
} from "james/financial";
import { InstrumentStablecoin } from "james/financial/InstrumentStablecoin";
import {
  ListAssetRequestDEPRECATED,
  ListAssetRequestDEPRECATEDField,
  ListAssetRequestDEPRECATEDValue,
  ListingStateController,
} from "james/market/ListingStateController";
import { useSnackbar } from "notistack";
import { IDIdentifier } from "james/search/identifier/ID";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import cx from "classnames";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { formatTextNum } from "utilities/number";
import isEqual from "lodash/isEqual";
import range from "lodash/range";
import dayjs from "dayjs";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import { LedgerAccountCategory, PlatformMinimumDealSize } from "james/ledger";
import {
  AssetFeeGenerator,
  GenerateAssetListingFeeResponse,
} from "james/remuneration";
import { Amount } from "james/ledger/Amount";
import {
  IndicativePriceRecorder,
  RecordIndicativePriceRequest,
  RecordIndicativePriceRequestField,
  RecordIndicativePriceRequestValue,
} from "james/market/IndicativePriceRecorder";
import { IndicativePriceType } from "james/market/IndicativePrice";
import { TouchedFields, ValidationResult } from "common/validation";
import { NumFieldHlpTxt, TextFieldHlpTxt } from "validationHelperText";
import { ClientKYCStatus, Client } from "james/client";
import { LedgerIDIdentifier, TokenIdentifier } from "james/search/identifier";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { ListingInspector, MechanismType } from "james/market";
import { Instrument } from "james/financial/Instrument";
import { Currency } from "james/financial/Currency";
import { CurrencyCollection } from "james/financial/CurrencyCollection";
import {
  Mechanism as MarketMechanism,
  QuoteParameter,
} from "james/market/Mechanism";
import { WarningDialogOptions } from "./common";
import { MarketMakerResponsibilitiesDialog } from "./MarketMakerResponsibilitiesDialog";
import { InstrumentStateChip } from "./Chips";
import { InstrumentsViewPaths } from "./Instruments";
import BigNumber from "bignumber.js";
import { useAccountContext } from "context/Account/Account";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { LedgerNetwork } from "james/ledger/Network";

const PREFIX = "PlaceInstrumentStablecoinDialog";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  dialogContent: `${PREFIX}-dialogContent`,
  loadingDialogContent: `${PREFIX}-loadingDialogContent`,
  loadingProgressWrapper: `${PREFIX}-loadingProgressWrapper`,
  dialogContentRow1: `${PREFIX}-dialogContentRow1`,
  dialogContentRow1Left: `${PREFIX}-dialogContentRow1Left`,
  dialogContentRow1TokenIcon: `${PREFIX}-dialogContentRow1TokenIcon`,
  dialogContentRow1InstrumentStablecoinDetails: `${PREFIX}-dialogContentRow1InstrumentStablecoinDetails`,
  dialogContentRow1InstrumentStablecoinDetailsTextField: `${PREFIX}-dialogContentRow1InstrumentStablecoinDetailsTextField`,
  dialogContentRow2: `${PREFIX}-dialogContentRow2`,
  dialogContentRow2Column1: `${PREFIX}-dialogContentRow2Column1`,
  dialogContentRow2Column2: `${PREFIX}-dialogContentRow2Column2`,
  pricingSectionHeadingLayout: `${PREFIX}-pricingSectionHeadingLayout`,
  pricingSectionMarketMakerNameLayout: `${PREFIX}-pricingSectionMarketMakerNameLayout`,
  pricingSectionIssuePrice: `${PREFIX}-pricingSectionIssuePrice`,
  pricingSectionCapturePriceLayout: `${PREFIX}-pricingSectionCapturePriceLayout`,
  pricingIssuePriceTokenAmountCode: `${PREFIX}-pricingIssuePriceTokenAmountCode`,
  pricingMarketMakerAgreementAcceptLayout: `${PREFIX}-pricingMarketMakerAgreementAcceptLayout`,
  pricingMarketMakerAgreementLabel: `${PREFIX}-pricingMarketMakerAgreementLabel`,
  tradeParametersTextFieldLayout: `${PREFIX}-tradeParametersTextFieldLayout`,
  tradeParametersTokenAmountCode: `${PREFIX}-tradeParametersTokenAmountCode`,
  tradeParametersMaxUnitsInIssueLayout: `${PREFIX}-tradeParametersMaxUnitsInIssueLayout`,
  tradeParametersMaxUnitsInIssueInfoIcon: `${PREFIX}-tradeParametersMaxUnitsInIssueInfoIcon`,
  marketDetailsLayout: `${PREFIX}-marketDetailsLayout`,
  marketMechanismItemLayout: `${PREFIX}-marketMechanismItemLayout`,
  marketMechanismInfoIcon: `${PREFIX}-marketMechanismInfoIcon`,
  marketingInformationLayout: `${PREFIX}-marketingInformationLayout`,
  marketingInformationEstimatedReturnEndAdornmentLayout: `${PREFIX}-marketingInformationEstimatedReturnEndAdornmentLayout`,
  marketingInformationGeneralDescriptionFieldTextArea: `${PREFIX}-marketingInformationGeneralDescriptionFieldTextArea`,
  marketingGeneralDescriptionFieldLayout: `${PREFIX}-marketingGeneralDescriptionFieldLayout`,
  marketingGeneralDescriptionFieldError: `${PREFIX}-marketingGeneralDescriptionFieldError`,
  feeSection: `${PREFIX}-feeSection`,
  feeControlRowLayout: `${PREFIX}-feeControlRowLayout`,
  tokenAmount: `${PREFIX}-tokenAmount`,
  tokenAmountIssuer: `${PREFIX}-tokenAmountIssuer`,
  disabledText: `${PREFIX}-disabledText`,
  feeDetailLineItemsWrapper: `${PREFIX}-feeDetailLineItemsWrapper`,
  feeDetailBodyLineItemLayout: `${PREFIX}-feeDetailBodyLineItemLayout`,
  feeDetailBodyWhyTheseFeesLink: `${PREFIX}-feeDetailBodyWhyTheseFeesLink`,
  feeDetailBodyWhyTheseFeesLinkDisabled: `${PREFIX}-feeDetailBodyWhyTheseFeesLinkDisabled`,
  feeDetailAccAvailableAmountLabel: `${PREFIX}-feeDetailAccAvailableAmountLabel`,
  feeDetailAccAndFeeRow: `${PREFIX}-feeDetailAccAndFeeRow`,
  boldText: `${PREFIX}-boldText`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  sectionHelperText: `${PREFIX}-sectionHelperText`,
  warningText: `${PREFIX}-warningText`,
  infoIcon: `${PREFIX}-infoIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  //
  // Dialog Title
  //
  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
  },

  [`& .${classes.heading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 40,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  //
  // Dialog Content
  //
  [`& .${classes.dialogContent}`]: {
    padding: 0,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "165px 528px 1fr auto",
  },

  [`& .${classes.loadingDialogContent}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    alignItems: "center",
    justifyItems: "center",
  },

  [`& .${classes.loadingProgressWrapper}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: theme.spacing(2),
    alignItems: "center",
    justifyItems: "center",
  },

  //
  // dialogContentRow1
  //
  [`& .${classes.dialogContentRow1}`]: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.custom.midnight,
    display: "grid",
    gridTemplateColumns: "1fr 1.6fr",
    columnGap: theme.spacing(10),
  },

  [`& .${classes.dialogContentRow1Left}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.dialogContentRow1TokenIcon}`]: {
    alignSelf: "center",
  },

  [`& .${classes.dialogContentRow1InstrumentStablecoinDetails}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    marginLeft: theme.spacing(4),
  },

  [`& .${classes.dialogContentRow1InstrumentStablecoinDetailsTextField}`]: {
    minWidth: 200,
  },

  //
  // dialogContentRow2
  //
  [`& .${classes.dialogContentRow2}`]: {
    padding: theme.spacing(4),
    display: "grid",
    gridTemplateColumns: "1fr 1.6fr",
    columnGap: theme.spacing(10),
  },

  [`& .${classes.dialogContentRow2Column1}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: theme.spacing(13.5),
  },

  [`& .${classes.dialogContentRow2Column2}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    rowGap: theme.spacing(6),
  },

  //
  // pricing
  //
  [`& .${classes.pricingSectionHeadingLayout}`]: {
    display: "grid",
    columnGap: theme.spacing(1),
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.pricingSectionMarketMakerNameLayout}`]: {
    display: "grid",
    columnGap: theme.spacing(1),
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    margin: theme.spacing(1, 0, 2, 0),
  },

  [`& .${classes.pricingSectionIssuePrice}`]: {
    minWidth: 230,
    margin: theme.spacing(0.5, 0, 2, 0),
  },

  [`& .${classes.pricingSectionCapturePriceLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    columnGap: theme.spacing(2),
    margin: theme.spacing(0.5, 0, 2, 0),
  },

  [`& .${classes.pricingIssuePriceTokenAmountCode}`]: {
    marginRight: 8,
    color: theme.palette.text.tertiary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.pricingMarketMakerAgreementAcceptLayout}`]: {
    display: "grid",
    columnGap: theme.spacing(0.5),
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },

  [`& .${classes.pricingMarketMakerAgreementLabel}`]: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  //
  // trade parameters
  //
  [`& .${classes.tradeParametersTextFieldLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(3),
  },

  [`& .${classes.tradeParametersTokenAmountCode}`]: {
    marginRight: 8,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.tradeParametersMaxUnitsInIssueLayout}`]: {
    display: "grid",
    gridTemplateColumns: "180px 1fr",
    alignItems: "center",
  },

  [`& .${classes.tradeParametersMaxUnitsInIssueInfoIcon}`]: {
    marginLeft: -45,
    zIndex: 1,
  },

  //
  // marketDetails
  //
  [`& .${classes.marketDetailsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "280px 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
  },

  [`& .${classes.marketMechanismItemLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },

  [`& .${classes.marketMechanismInfoIcon}`]: {
    zIndex: 1,
    marginLeft: -35,
  },

  //
  // marketingInformation
  //
  [`& .${classes.marketingInformationLayout}`]: {},

  [`& .${classes.marketingInformationEstimatedReturnEndAdornmentLayout}`]: {
    display: "grid",
    columnGap: theme.spacing(0.5),
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },

  [`& .${classes.marketingInformationGeneralDescriptionFieldTextArea}`]: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(0.5, 1),
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw/2)",
    maxWidth: "calc(100vw/2)",
    borderRadius: 4,
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    resize: "none",
  },

  [`& .${classes.marketingGeneralDescriptionFieldLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    marginTop: theme.spacing(1),
    gridRowGap: theme.spacing(0.5),
  },

  [`& .${classes.marketingGeneralDescriptionFieldError}`]: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  //
  // dialogContentPlacementFees
  //
  [`& .${classes.feeSection}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.feeControlRowLayout}`]: {
    padding: theme.spacing(1, 3, 1, 1),
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto auto auto 1fr",
    columnGap: theme.spacing(1),
  },

  [`& .${classes.tokenAmount}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(0.5),
  },

  [`& .${classes.tokenAmountIssuer}`]: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.disabledText}`]: {
    color: theme.palette.action.disabled,
  },

  [`& .${classes.feeDetailLineItemsWrapper}`]: {
    height: 80,
    display: "grid",
    alignItems: "center",
    paddingLeft: theme.spacing(8),
  },

  [`& .${classes.feeDetailBodyLineItemLayout}`]: {
    display: "grid",
    columnGap: theme.spacing(1),
    gridTemplateColumns: "180px auto",
  },

  [`& .${classes.feeDetailBodyWhyTheseFeesLink}`]: {
    marginTop: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.feeDetailBodyWhyTheseFeesLinkDisabled}`]: {
    marginTop: theme.spacing(1),
    color: theme.palette.action.disabled,
  },

  [`& .${classes.feeDetailAccAvailableAmountLabel}`]: {
    marginLeft: theme.spacing(7),
  },

  [`& .${classes.feeDetailAccAndFeeRow}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto auto auto 1fr",
    columnGap: theme.spacing(1),
    padding: theme.spacing(3),
  },

  //
  // other
  //
  [`& .${classes.boldText}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.sectionHeading}`]: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.sectionHelperText}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.warningText}`]: {
    color: theme.palette.warning.light,
  },

  [`& .${classes.infoIcon}`]: {
    marginRight: -10,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

const bigZero = new BigNumber("0");

const bigArbitraryMax = new BigNumber("10000000");

function performValidation(
  instrumentStablecoin: InstrumentStablecoin,
  createNewListingRequest: ListAssetRequestDEPRECATED,
  recordIndicativePriceRequest: RecordIndicativePriceRequest,
  shouldShowEstimatedReturn: boolean,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  //
  // createNewListingRequest.issuePrice &&
  // recordIndicativePriceRequest.buyPrice &&
  // recordIndicativePriceRequest.sellPrice
  //
  // if the issue date is in the future (i.e. instrument stablecoin pre-issued)
  if (!dayjs(instrumentStablecoin.assetIssueDate()).isAfter(dayjs())) {
    // otherwise the issuance date is in the past
    // if the buyPrice is not set
    if (recordIndicativePriceRequest.buyPrice.value.lte(bigZero)) {
      // then validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.buyPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.buyPrice =
          NumFieldHlpTxt.MustBeGreaterThan0;
      }
    } else if (
      recordIndicativePriceRequest.buyPrice.value.gt(bigArbitraryMax)
    ) {
      // if buy price greater than arbitrary 10,000,000 max

      // then validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.buyPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.buyPrice =
          "Cannot be more than 10,000,000";
      }
    }

    // OR if the sellPrice is not set
    if (recordIndicativePriceRequest.sellPrice.value.lte(bigZero)) {
      // then validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.sellPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.sellPrice =
          NumFieldHlpTxt.MustBeGreaterThan0;
      }
    } else if (
      recordIndicativePriceRequest.sellPrice.value.gt(bigArbitraryMax)
    ) {
      // if sell price greater than arbitrary 10,000,000 max

      // then validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.sellPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.sellPrice =
          "Cannot be more than 10,000,000";
      }
    }

    // If sell price is greater than buy price
    if (
      recordIndicativePriceRequest.sellPrice.value.gt(
        recordIndicativePriceRequest.buyPrice.value,
      )
    ) {
      // then validation has failed
      validationResult.valid = false;

      // and if the buy price field has been touched
      if (ignoreTouchedFields || touchedFields.buyPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.buyPrice =
          "Must be greater than sell price";
      }

      // and if the sell price field has been touched
      if (ignoreTouchedFields || touchedFields.sellPrice) {
        // then an error message should be shown on it
        validationResult.fieldValidations.sellPrice =
          "Must be less than buy price";
      }
    }
  }

  //
  // createNewListingRequest.minimumDealSize
  //
  // if the minimumDealSize is < 0.00002
  if (
    createNewListingRequest.marketMechanism.quoteParameters[0].minimumDealSize.value.lt(
      PlatformMinimumDealSize,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.minimumDealSize) {
      // then an error message should be shown on it
      validationResult.fieldValidations.minimumDealSize =
        "Cannot be less than 0.0000200";
    }
  } else if (
    // otherwise if minimumDealSize is >= maximumDealSize
    createNewListingRequest.marketMechanism.quoteParameters[0].minimumDealSize.value.gte(
      createNewListingRequest.marketMechanism.quoteParameters[0].maximumDealSize
        .value,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.minimumDealSize) {
      // then an error message should be shown on it
      validationResult.fieldValidations.minimumDealSize =
        "Must be less than maximum";
    }
  }

  //
  // createNewListingRequest.maximumDealSize
  //
  // if the maximumDealSize is 0
  if (
    createNewListingRequest.marketMechanism.quoteParameters[0].maximumDealSize.value.lte(
      bigZero,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.maximumDealSize) {
      // then an error message should be shown on it
      validationResult.fieldValidations.maximumDealSize =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (
    // otherwise if maximumDealSize is <= minimumDealSize
    createNewListingRequest.marketMechanism.quoteParameters[0].maximumDealSize.value.lte(
      createNewListingRequest.marketMechanism.quoteParameters[0].minimumDealSize
        .value,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.maximumDealSize) {
      // then an error message should be shown on it
      validationResult.fieldValidations.maximumDealSize =
        "Must be more than minimum";
    }
  } else if (
    // otherwise if the maximumDealSize is > the instrument stablecoin's maximum units
    createNewListingRequest.marketMechanism.quoteParameters[0].maximumDealSize.value.gt(
      instrumentStablecoin.instrumentStablecoinMaximumUnits().value,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.maximumDealSize) {
      // then an error message should be shown on it
      validationResult.fieldValidations.maximumDealSize =
        "Exceeds maximum units in issue";
    }
  }

  //
  // createNewListingRequest.estimatedAnnualReturn
  //
  // if the estimatedAnnualReturn should be shown
  if (shouldShowEstimatedReturn) {
    // and if it is not set
    if (+createNewListingRequest.estimatedAnnualReturn === 0) {
      // then validation has failed
      validationResult.valid = false;

      // and if the field has been touched
      if (ignoreTouchedFields || touchedFields.estimatedAnnualReturn) {
        // then an error message should be shown on it
        validationResult.fieldValidations.estimatedAnnualReturn =
          NumFieldHlpTxt.MustBeGreaterThan0;
      }
    }
  }

  //
  // createNewListingRequest.investmentObjective
  //
  // if the investmentObjective is not set
  if (!createNewListingRequest.investmentObjective) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.investmentObjective) {
      // then an error message should be shown on it
      validationResult.fieldValidations.investmentObjective =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  return validationResult;
}

export function PlaceInstrumentStablecoinDialog() {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { authContext, myClient, myClientKYCStatus, myClientRetrievalErr } =
    useApplicationContext();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const [listingInProgress, setListingInProgress] = useState(false);
  const [instrumentStablecoin, setInstrumentStablecoin] = useState<
    InstrumentStablecoin | undefined
  >(undefined);
  const [instrumentBackingStablecoin, setInstrumentBackingStablecoin] =
    useState<Instrument | undefined>(undefined);
  const [valuationTokenViewModel, setValuationTokenViewModel] = useState<
    LedgerTokenViewModel | undefined
  >(undefined);
  const [valuationCurrency, setValuationCurrency] = useState<
    Currency | undefined
  >(undefined);
  const [
    acceptMarketMakerResponsibilities,
    setAcceptMarketMakerResponsibilities,
  ] = useState(false);
  const [showFeeDetail, setShowFeeDetail] = useState(false);
  const [marketMakerResponsibilitiesOpen, setMarketMakerResponsibilitiesOpen] =
    useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const [feeAccountViewModel, setFeeAccountViewModel] = useState<
    StellarAccountViewModel | undefined
  >(undefined);
  const [generateAssetListingFeeResponse, setGenerateAssetListingFeeResponse] =
    useState<GenerateAssetListingFeeResponse | undefined>(undefined);
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [fee, setFee] = useState<Amount>(new Amount());
  const [vat, setVAT] = useState<Amount>(new Amount());
  const [feeAccountFeeAssetBalance, setFeeAccountFeeAssetBalance] =
    useState<Amount>(new Amount());
  const [userIsSignatoryOnFeeAccount, setUserIsSignatoryOnFeeAccount] =
    useState(false);
  const kycStatusVerified =
    myClientKYCStatus === ClientKYCStatus.VerifiedStatus;
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    valid: false,
    fieldValidations: {},
  });
  const shouldShowEstimatedReturnsField = useRef(false);
  const instrumentStablecoinIssued = useRef(false);
  const navigate = useNavigate();

  // requests
  const [createNewListingRequest, setCreateNewListingRequest] = useState<
    ListAssetRequestDEPRECATED | undefined
  >(undefined);
  const [copyOfCreateNewListingRequest, setCopyOfCreateNewListingRequest] =
    useState<ListAssetRequestDEPRECATED | undefined>(undefined);
  const [recordIndicativePriceRequest, setRecordIndicativePriceRequest] =
    useState<RecordIndicativePriceRequest | undefined>(undefined);
  const [
    copyOfRecordIndicativePriceRequest,
    setCopyOfRecordIndicativePriceRequest,
  ] = useState<RecordIndicativePriceRequest | undefined>(undefined);
  const changesMade = !(
    isEqual(createNewListingRequest, copyOfCreateNewListingRequest) &&
    isEqual(recordIndicativePriceRequest, copyOfRecordIndicativePriceRequest)
  );
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { stellarAccountContext } = useAccountContext();

  // --------------------------------------------------------------------------
  // Initial Component Load
  // --------------------------------------------------------------------------
  // navigate to table if no id query param has been set
  useEffect(() => {
    const idFromURL = searchParams.get("id");
    if (!idFromURL) {
      navigate(InstrumentsViewPaths.Table);
      return;
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      // try and get a instrument stablecoin ID from the url
      const idFromURL = searchParams.get("id");
      if (!idFromURL) {
        return;
      }

      if (
        instrumentStablecoin &&
        instrumentStablecoin.assetID() === idFromURL
      ) {
        // if instrument stablecoin is already set then initial load is complete
        return;
      }

      // if the stellarAccountContext accounts are still being loaded return
      if (stellarAccountContext.loading) {
        return;
      }

      if (!myClient && myClientRetrievalErr) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        navigate(InstrumentsViewPaths.Table);
        return;
      }

      if (!myClient) {
        return;
      }

      // if there was an error retrieving the accounts from the stellarContext
      // show an snackbar and then close the dialog
      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        navigate(InstrumentsViewPaths.Table);
        return;
      }

      // retrieve the instrument stablecoin that is to be listed
      let retrievedInstrumentStablecoin: InstrumentStablecoin;
      try {
        retrievedInstrumentStablecoin = (
          await FinancialInstrumentStablecoinCollection.RetrieveInstrumentStablecoin(
            {
              context: authContext,
              identifier: IDIdentifier(idFromURL),
            },
          )
        ).instrumentStablecoin;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving instrument stablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Retrieving Rights to Instrument: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
        navigate(InstrumentsViewPaths.Table);
        return;
      }
      // if execution reaches here then the instrument stablecoin was retrieved
      setInstrumentStablecoin(retrievedInstrumentStablecoin);
      instrumentStablecoinIssued.current = dayjs(
        retrievedInstrumentStablecoin.assetIssueDate(),
      ).isBefore(dayjs());

      // retrieve the instrument by which the stablecoin is backed
      let retrievedBackingInstrument: Instrument;
      try {
        retrievedBackingInstrument = (
          await FinancialInstrumentCollection.RetrieveInstrument({
            context: authContext,
            identifier: IDIdentifier(
              retrievedInstrumentStablecoin.instrumentStablecoinInstrumentID(),
            ),
          })
        ).instrument;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving instrument backing stablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Retrieving Rights to Instrument: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
        navigate(InstrumentsViewPaths.Table);
        return;
      }
      // if execution reaches here then the backing instrument was retrieved
      setInstrumentBackingStablecoin(retrievedBackingInstrument);

      // prepare initial createNewListingRequest
      const initialCreateNewListingRequest: ListAssetRequestDEPRECATED = {
        context: authContext,
        assetToken: retrievedInstrumentStablecoin.assetToken(),
        exchangeNetwork: retrievedInstrumentStablecoin.assetToken()
          .network as LedgerNetwork,
        marketMechanism: new MarketMechanism({
          type: MechanismType.DirectOrder,
          quoteParameters: [
            new QuoteParameter({
              quoteToken:
                retrievedInstrumentStablecoin.instrumentStablecoinValuationToken(),
              minimumDealSize: retrievedInstrumentStablecoin
                .assetToken()
                .newAmountOf("0"),
              maximumDealSize: retrievedInstrumentStablecoin
                .assetToken()
                .newAmountOf("0"),
            }),
          ],
        }),
        estimatedAnnualReturn: BigNumber(0),
        investmentObjective: "",
      };

      // confirm that a listing does not already exist for the instrument stablecoin
      try {
        if (
          (
            await ListingInspector.DoesListingForTokenExist({
              context: authContext,
              token: retrievedInstrumentStablecoin.assetToken(),
            })
          ).exists
        ) {
          enqueueSnackbar("Rights to Instrument Already Placed", {
            variant: "warning",
          });
          // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
          navigate(InstrumentsViewPaths.Table);
          return;
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error checking if placement exists for instrument stablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Checking if Placement Exists for Rights to Instrument: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
        navigate(InstrumentsViewPaths.Table);
        return;
      }

      // retrieve the trading account of the group that owns the instrument stablecoin
      // aka. the fee account
      const updatedFeeAccountViewModel = stellarAccountContext.accounts.find(
        (v) =>
          v.ownerID === retrievedInstrumentStablecoin.assetOwnerID() &&
          v.category === LedgerAccountCategory.Trading,
      );
      if (!updatedFeeAccountViewModel) {
        console.error(`error retrieving owner group trading account`);
        enqueueSnackbar(`Error Retrieving Owner Group Trading Account`, {
          variant: "error",
        });

        navigate(InstrumentsViewPaths.Table);

        return;
      }

      setFeeAccountViewModel(updatedFeeAccountViewModel);

      // get fee token, look for balance in fee acc, and set fee balance
      const feeBalance = updatedFeeAccountViewModel.getTokenBalance(
        retrievedInstrumentStablecoin.instrumentStablecoinValuationToken(),
      );
      if (feeBalance) {
        setFeeAccountFeeAssetBalance(feeBalance.amount);
      } else {
        setFeeAccountFeeAssetBalance(
          retrievedInstrumentStablecoin
            .instrumentStablecoinValuationToken()
            .newAmountOf("0"),
        );
      }

      // check if user is an signatory on the account
      try {
        setUserIsSignatoryOnFeeAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(updatedFeeAccountViewModel.ledgerID),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      // load associated required data
      await Promise.all([
        // retrieve a view model of the token in which the instrument stablecoin is valuated
        (async () => {
          try {
            setValuationTokenViewModel(
              await getLedgerTokenViewModel(
                retrievedInstrumentStablecoin.instrumentStablecoinValuationToken(),
              ),
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving valuation token: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `Error Retrieving Valuation Token: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
            // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
            navigate(InstrumentsViewPaths.Table);
          }
        })(),
        // generate instrument stablecoin listing fee
        (async () => {
          try {
            const updatedGenerateAssetListingFeeResponse =
              await AssetFeeGenerator.GenerateAssetListingFee({
                context: authContext,
                asset: retrievedInstrumentStablecoin,
              });
            setGenerateAssetListingFeeResponse(
              updatedGenerateAssetListingFeeResponse,
            );
            setFee(
              updatedGenerateAssetListingFeeResponse.fees.reduce(
                (total, fee) =>
                  fee
                    .feeAmount()
                    .setValue(total.value.plus(fee.feeAmount().value)),
                new Amount() as Amount,
              ),
            );
            setVAT(
              updatedGenerateAssetListingFeeResponse.fees.reduce(
                (total, fee) =>
                  fee
                    .feeAmount()
                    .setValue(
                      total.value.plus(
                        fee.feeAmount().value.multipliedBy(fee.feeVATRate()),
                      ),
                    ),
                new Amount() as Amount,
              ),
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error generating instrument stablecoin listing fees: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `Error Generating Rights to Instrument Stablecoin Listing Fees: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
            // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
            navigate(InstrumentsViewPaths.Table);
          }
        })(),

        // get fiat currency in which the instrument stablecoin is valuated
        (async () => {
          try {
            // retrieve valuation stablecoin
            const valuationCurrencyStablecoin = (
              await FinancialCurrencyStablecoinRepository.RetrieveCurrencyStablecoin(
                {
                  context: authContext,
                  identifier: TokenIdentifier(
                    retrievedInstrumentStablecoin.instrumentStablecoinValuationToken(),
                  ),
                },
              )
            ).currencyStablecoin;

            // retrieve valuation stablecoin underlying currency
            setValuationCurrency(
              (
                await CurrencyCollection.RetrieveCurrency({
                  context: authContext,
                  identifier: IDIdentifier(
                    valuationCurrencyStablecoin.currencyID,
                  ),
                })
              ).currency,
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving valuation currency stablecoin: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `Error Retrieving Valuation Currency Stablecoin: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
            // TODO: navigate is not allowed in useLayoutEffect and might not trigger here
            navigate(InstrumentsViewPaths.Table);
          }
        })(),
      ]);

      // determine if estimated returns field should be shown
      const annualPerformanceLog =
        retrievedBackingInstrument.instrumentAnnualPerformanceLog();
      if (annualPerformanceLog.length) {
        nextAnnualPerformanceLogEntry: for (
          let i = 0;
          i < annualPerformanceLog.length;
          i++
        ) {
          if (+annualPerformanceLog[i].annualPerformance !== 0) {
            break;
          }
          for (const month in annualPerformanceLog[i].monthlyPerformance) {
            if (
              Object.prototype.hasOwnProperty.call(
                annualPerformanceLog[i].monthlyPerformance,
                month,
              )
            ) {
              if (+annualPerformanceLog[i].monthlyPerformance !== 0) {
                break nextAnnualPerformanceLogEntry;
              }
            }
          }

          // if execution reaches here then the estimated returns field should be shown
          if (i === annualPerformanceLog.length - 1) {
            shouldShowEstimatedReturnsField.current = true;
          }
        }
      } else {
        shouldShowEstimatedReturnsField.current = true;
      }

      const initialRecordIndicativePriceRequest: RecordIndicativePriceRequest =
        {
          context: authContext,
          assetID: retrievedInstrumentStablecoin.assetID(),
          buyPrice: retrievedInstrumentStablecoin
            .instrumentStablecoinValuationToken()
            .newAmountOf("0"),
          sellPrice: retrievedInstrumentStablecoin
            .instrumentStablecoinValuationToken()
            .newAmountOf("0"),
          timeOfPrice: "", // populated on submission
          type: IndicativePriceType.IntraDay,
        };

      setRecordIndicativePriceRequest(initialRecordIndicativePriceRequest);
      setCopyOfRecordIndicativePriceRequest(
        initialRecordIndicativePriceRequest,
      );
      setCreateNewListingRequest(initialCreateNewListingRequest);
      setCopyOfCreateNewListingRequest(initialCreateNewListingRequest);
      setInitialLoading(false);
    })();
  }, [
    enqueueSnackbar,
    history,
    instrumentStablecoin,
    myClient,
    authContext,
    stellarAccountContext.loading,
    stellarAccountContext.error,
  ]);

  // --------------------------------------------------------------------------
  //  Listing Request Update
  // --------------------------------------------------------------------------
  const handleUpdateCreateNewListingRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends ListAssetRequestDEPRECATEDField>(
      newValue: ListAssetRequestDEPRECATEDValue<T>,
    ) => {
      if (
        !(
          instrumentStablecoin &&
          createNewListingRequest &&
          recordIndicativePriceRequest
        )
      ) {
        return;
      }

      // prepare updated request
      const updatedCreateNewListingRequest = {
        ...createNewListingRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // set updated touched fields
      setTouchedFields(updatedTouchedFields);

      // clear any pending validation
      clearTimeout(validationTimeoutRef.current);

      // defer validation to take place in 800ms
      setValidationInProgress(true);
      clearTimeout(validationTimeoutRef.current);
      validationTimeoutRef.current = setTimeout(() => {
        setValidationResult(
          performValidation(
            instrumentStablecoin,
            updatedCreateNewListingRequest,
            recordIndicativePriceRequest,
            shouldShowEstimatedReturnsField.current,
            updatedTouchedFields,
            false,
          ),
        );
        setValidationInProgress(false);
      }, 800);

      setCreateNewListingRequest(updatedCreateNewListingRequest);
    };

  // --------------------------------------------------------------------------
  //  Record Indicative Price Request Update
  // --------------------------------------------------------------------------
  const handleUpdateRecordIndicativePriceRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends RecordIndicativePriceRequestField>(
      newValue: RecordIndicativePriceRequestValue<T>,
    ) => {
      if (
        !(
          instrumentStablecoin &&
          createNewListingRequest &&
          recordIndicativePriceRequest
        )
      ) {
        return;
      }

      // prepare updated request
      const updatedRecordIndicativePriceRequest = {
        ...recordIndicativePriceRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // set updated touched fields
      setTouchedFields(updatedTouchedFields);

      // clear any pending validation
      clearTimeout(validationTimeoutRef.current);

      // defer validation to take place in 800ms
      setValidationInProgress(true);
      clearTimeout(validationTimeoutRef.current);
      validationTimeoutRef.current = setTimeout(() => {
        setValidationResult(
          performValidation(
            instrumentStablecoin,
            createNewListingRequest,
            updatedRecordIndicativePriceRequest,
            shouldShowEstimatedReturnsField.current,
            updatedTouchedFields,
            false,
          ),
        );
        setValidationInProgress(false);
      }, 800);

      setRecordIndicativePriceRequest(updatedRecordIndicativePriceRequest);
    };

  return (
    <>
      <StyledDialog open fullScreen>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item className={classes.heading}>
              <div className={classes.miniLogoWrapper}>
                <img alt="" width="100%" src={meshMiniLogo} />
              </div>
              <Typography
                variant="h5"
                children="Place Rights to Instrument On Marketplace"
              />
            </Grid>
            {listingInProgress && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" spacing={1} alignItems="center">
            {!initialLoading && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={(() => {
                    switch (true) {
                      case !validationResult.valid:
                        return "All fields must be completed";

                      case !userIsSignatoryOnFeeAccount:
                        return "You are not a signatory on the trading account";

                      case feeAccountFeeAssetBalance.value.lt(
                        fee.value.plus(vat.value),
                      ):
                        return "Insufficient balance in Fee Acc";

                      case !acceptMarketMakerResponsibilities:
                        return "You must accept the Market Maker Responsibilities";
                    }

                    return "";
                  })()}
                >
                  <span>
                    <Button
                      id="placeInstrumentStablecoinDialog-place-button"
                      variant="contained"
                      children="place on marketplace"
                      color="primary"
                      disabled={
                        initialLoading ||
                        validationInProgress ||
                        listingInProgress ||
                        !validationResult.valid ||
                        !userIsSignatoryOnFeeAccount ||
                        feeAccountFeeAssetBalance.value.lt(
                          fee.value.plus(vat.value),
                        ) ||
                        !acceptMarketMakerResponsibilities
                      }
                      onClick={() =>
                        setWarningDialogOptions({
                          title: "Place on Marketplace",
                          messageParagraphs: [
                            `Once your rights to an instrument is placed on the Marketplace all Mesh participants will be able to
                     view it.`,
                            "This action cannot be undone.",
                            "Select 'Yes' to continue with placement or 'No' to continue editing.",
                          ],
                          yesMethod: async () => {
                            if (!kycStatusVerified) {
                              enqueueSnackbar(
                                "A Verified KYC Status is Required to Perform Placement",
                                { variant: "warning" },
                              );
                              return;
                            }

                            if (
                              !(
                                createNewListingRequest &&
                                recordIndicativePriceRequest &&
                                instrumentStablecoin &&
                                valuationCurrency
                              )
                            ) {
                              return;
                            }

                            // get the time now
                            const now = dayjs();

                            // get cut-off time today
                            const cutOffToday = dayjs(
                              valuationCurrency.firstCutOffAfter(
                                valuationCurrency.firstStartOfDayBefore(
                                  dayjs().format(),
                                ),
                              ),
                            );

                            setListingInProgress(true);

                            try {
                              // perform indicative price recording if instrument is issued
                              if (instrumentStablecoinIssued.current) {
                                // set price type and timeOfPrice
                                if (now.isBefore(cutOffToday)) {
                                  // it is intra-day
                                  recordIndicativePriceRequest.type =
                                    IndicativePriceType.IntraDay;
                                  recordIndicativePriceRequest.timeOfPrice =
                                    now.format();
                                } else {
                                  // it is COB or later
                                  recordIndicativePriceRequest.type =
                                    IndicativePriceType.COB;
                                  recordIndicativePriceRequest.timeOfPrice =
                                    cutOffToday.format();
                                }

                                // record the indicative price
                                await IndicativePriceRecorder.RecordIndicativePrice(
                                  recordIndicativePriceRequest,
                                );
                              }

                              // perform listing
                              await ListingStateController.ListAsset({
                                context: createNewListingRequest.context,
                                assetToken: createNewListingRequest.assetToken,
                                marketMechanisms: [
                                  createNewListingRequest.marketMechanism,
                                ],
                                estimatedAnnualReturn:
                                  createNewListingRequest.estimatedAnnualReturn,
                                investmentObjective:
                                  createNewListingRequest.investmentObjective,
                                exchangeNetwork:
                                  createNewListingRequest.exchangeNetwork,
                              });

                              enqueueSnackbar("Rights to Instrument Placed", {
                                variant: "success",
                              });
                              navigate(InstrumentsViewPaths.Table);
                              return;
                            } catch (e) {
                              const err =
                                errorContextErrorTranslator.translateError(e);
                              console.error(
                                `error placing instrument: ${
                                  err.message ? err.message : err.toString()
                                }`,
                              );
                              enqueueSnackbar(
                                "Error Placing Rights to Instrument",
                                { variant: "error" },
                              );
                            }

                            setListingInProgress(false);
                          },
                          noMethod: () => setWarningDialogOptions(null),
                        })
                      }
                    />
                  </span>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip
                title="Close"
                placement="top"
                onClick={() => {
                  if (changesMade) {
                    setWarningDialogOptions({
                      title: "Close?",
                      messageParagraphs: [
                        `Leaving this page will result in all of the changes that you have made
                      to your placement being lost.`,
                        "Select 'Yes' to go back to the instrument dashboard or 'No' to continue editing.",
                      ],
                      yesMethod: () => navigate(InstrumentsViewPaths.Table),
                      noMethod: () => setWarningDialogOptions(null),
                    });
                  } else {
                    navigate(InstrumentsViewPaths.Table);
                  }
                }}
              >
                <span>
                  <IconButton
                    disabled={listingInProgress}
                    id="placeInstrumentStablecoinDialog-close-button"
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        {(() => {
          // show loading screen while loading
          if (
            initialLoading ||
            !(
              instrumentStablecoin &&
              instrumentBackingStablecoin &&
              valuationTokenViewModel &&
              createNewListingRequest &&
              generateAssetListingFeeResponse &&
              feeAccountViewModel &&
              recordIndicativePriceRequest
            )
          ) {
            return (
              <DialogContent className={classes.loadingDialogContent}>
                <div className={classes.loadingProgressWrapper}>
                  <CircularProgress size={70} />
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    children="Getting things ready for you..."
                  />
                </div>
              </DialogContent>
            );
          }
          return (
            <DialogContent
              ref={dialogContentRef}
              classes={{ root: cx(classes.dialogContent, "meshScroll") }}
            >
              {/* Row 1 */}
              <div className={classes.dialogContentRow1}>
                <div className={classes.dialogContentRow1Left}>
                  <div className={classes.dialogContentRow1TokenIcon}>
                    <TokenIconViewUpload
                      token={instrumentStablecoin.assetToken()}
                      tokenOwnerID={instrumentStablecoin.assetOwnerID()}
                      size={96}
                    />
                  </div>
                  <div
                    className={
                      classes.dialogContentRow1InstrumentStablecoinDetails
                    }
                  >
                    <TextField
                      className={
                        classes.dialogContentRow1InstrumentStablecoinDetailsTextField
                      }
                      disabled={listingInProgress}
                      id="placeInstrumentStablecoinDialog-instrumentName-textField"
                      label="Instrument Name"
                      value={instrumentBackingStablecoin.instrumentName()}
                      readOnly
                    />
                    <div>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        children="Token Class"
                      />
                      <Typography
                        className={cx(classes.boldText, classes.warningText)}
                        variant="h4"
                        children="Right"
                      />
                    </div>
                    <TextField
                      className={
                        classes.dialogContentRow1InstrumentStablecoinDetailsTextField
                      }
                      id="placeInstrumentStablecoinDialog-instrumentShortName-textField"
                      disabled={listingInProgress}
                      label="Instrument Short Name"
                      value={instrumentStablecoin.assetShortName()}
                      readOnly
                    />
                    <div>
                      <InstrumentStateChip
                        state={instrumentStablecoin.instrumentStablecoinState()}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Typography
                    children="Notice:"
                    className={classes.sectionHeading}
                    variant="subtitle1"
                  />
                  <Typography variant="body1" color="textSecondary">
                    You are about to place your rights to an instrument on Mesh
                    Marketplace. Investors will be able to discover and trade
                    it. Before that can occur we need some more information from
                    you.
                  </Typography>
                </div>
              </div>

              {/* Row 2 */}
              <div className={classes.dialogContentRow2}>
                <div className={classes.dialogContentRow2Column1}>
                  {/* ---------------- Pricing ----------------  */}
                  <div>
                    <div className={classes.pricingSectionHeadingLayout}>
                      <Typography
                        variant="h4"
                        className={classes.boldText}
                        children="Pricing"
                      />
                      {instrumentStablecoinIssued.current && (
                        <Tooltip
                          placement="top"
                          title={"Prices based on the investor's perspective"}
                        >
                          <InfoIcon className={classes.infoIcon} />
                        </Tooltip>
                      )}
                    </div>
                    <div
                      className={classes.pricingSectionMarketMakerNameLayout}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.boldText}
                        children="Market Maker:"
                      />
                      <Typography
                        color="textSecondary"
                        children={new Client(myClient).name}
                      />
                    </div>
                    {instrumentStablecoinIssued.current ? (
                      // issue date has passed :- show capture price fields
                      <div className={classes.pricingSectionCapturePriceLayout}>
                        <TextNumField
                          id="placeInstrumentStablecoinDialog-buyPrice-textNumField"
                          disabled={listingInProgress}
                          disallowNegative
                          label="Buy"
                          value={recordIndicativePriceRequest.buyPrice.value}
                          onChange={(e) =>
                            handleUpdateRecordIndicativePriceRequest(
                              "buyPrice",
                            )(
                              recordIndicativePriceRequest.buyPrice.setValue(
                                e.target.value,
                              ),
                            )
                          }
                          InputProps={{
                            startAdornment: (
                              <Tooltip
                                title={`Issued by ${valuationTokenViewModel.issuer}`}
                                placement="top"
                              >
                                <Typography
                                  variant="body1"
                                  className={
                                    classes.pricingIssuePriceTokenAmountCode
                                  }
                                  children={valuationTokenViewModel.token.code}
                                />
                              </Tooltip>
                            ),
                          }}
                          error={!!validationResult.fieldValidations.buyPrice}
                          helperText={
                            validationResult.fieldValidations.buyPrice
                          }
                        />
                        <TextNumField
                          id="placeInstrumentStablecoinDialog-sellPrice-textNumField"
                          disabled={listingInProgress}
                          disallowNegative
                          label="Sell"
                          value={recordIndicativePriceRequest.sellPrice.value}
                          onChange={(e) =>
                            handleUpdateRecordIndicativePriceRequest(
                              "sellPrice",
                            )(
                              recordIndicativePriceRequest.sellPrice.setValue(
                                e.target.value,
                              ),
                            )
                          }
                          InputProps={{
                            startAdornment: (
                              <Tooltip
                                title={`Issued by ${valuationTokenViewModel.issuer}`}
                                placement="top"
                              >
                                <Typography
                                  variant="body1"
                                  className={
                                    classes.pricingIssuePriceTokenAmountCode
                                  }
                                  children={valuationTokenViewModel.token.code}
                                />
                              </Tooltip>
                            ),
                          }}
                          error={!!validationResult.fieldValidations.sellPrice}
                          helperText={
                            validationResult.fieldValidations.sellPrice
                          }
                        />
                      </div>
                    ) : (
                      <Box />
                    )}
                    <div
                      className={
                        classes.pricingMarketMakerAgreementAcceptLayout
                      }
                    >
                      <Checkbox
                        id="placeInstrumentStablecoinDialog-acceptMarketMakerResponsibilities-checkBox"
                        disabled={listingInProgress}
                        checked={acceptMarketMakerResponsibilities}
                        onChange={(e) =>
                          setAcceptMarketMakerResponsibilities(e.target.checked)
                        }
                      />
                      <Typography
                        id="placeInstrumentStablecoinDialog-acceptMarketMakerResponsibilitiesLink-link"
                        variant="body1"
                        color="secondary"
                        onClick={() => setMarketMakerResponsibilitiesOpen(true)}
                        className={classes.pricingMarketMakerAgreementLabel}
                        children="Accept Market Maker Responsibilities"
                      />
                    </div>
                  </div>

                  {/* ---------------- Trade Parameters ----------------  */}
                  <div>
                    <Typography
                      variant="h4"
                      className={cx(classes.boldText, classes.sectionHeading)}
                      children="Trade Parameters"
                    />
                    <Typography
                      variant="body1"
                      className={classes.sectionHelperText}
                      color="textSecondary"
                    >
                      Parameters for trade and settlement. These limits will
                      define how a trade can occur in your instrument.
                    </Typography>
                    <div className={classes.tradeParametersTextFieldLayout}>
                      <div
                        className={classes.tradeParametersMaxUnitsInIssueLayout}
                      >
                        <TextNumField
                          id="placeInstrumentStablecoinDialog-maximumUnitsInIssue-textNumField"
                          disabled={listingInProgress}
                          disallowNegative
                          readOnly
                          label="Maximum Units in Issue"
                          value={
                            instrumentStablecoin.instrumentStablecoinMaximumUnits()
                              .value
                          }
                        />
                        <InputAdornment
                          position="start"
                          children={
                            <Tooltip
                              placement="top"
                              title="Maximum number of tokens the issuer is looking to mint on Mesh"
                            >
                              <InfoIcon
                                className={cx(
                                  classes.infoIcon,
                                  classes.tradeParametersMaxUnitsInIssueInfoIcon,
                                )}
                              />
                            </Tooltip>
                          }
                        />
                      </div>
                      <TextField
                        id="placeInstrumentStablecoinDialog-settlementStablecoin-textNumField"
                        disabled={listingInProgress}
                        readOnly
                        label="Settlement Stablecoin"
                        value=""
                        InputProps={{
                          startAdornment: (
                            <Tooltip
                              title={`Issued by ${valuationTokenViewModel.issuer}`}
                              placement="top"
                            >
                              <Typography
                                variant="body1"
                                className={
                                  classes.tradeParametersTokenAmountCode
                                }
                                children={valuationTokenViewModel.token.code}
                              />
                            </Tooltip>
                          ),
                        }}
                      />
                      <TextNumField
                        id="placeInstrumentStablecoinDialog-minimumDealSize-textNumField"
                        disabled={listingInProgress}
                        disallowNegative
                        noDecimalPlaces={7}
                        label="Minimum Token Deal Size"
                        value={
                          createNewListingRequest.marketMechanism
                            .quoteParameters[0].minimumDealSize.value
                        }
                        onChange={(e) =>
                          handleUpdateCreateNewListingRequest(
                            "marketMechanism",
                            ["minimumDealSize"],
                          )(
                            new MarketMechanism({
                              ...createNewListingRequest.marketMechanism,
                              quoteParameters: [
                                new QuoteParameter({
                                  ...createNewListingRequest.marketMechanism
                                    .quoteParameters[0],
                                  minimumDealSize:
                                    createNewListingRequest.marketMechanism.quoteParameters[0].minimumDealSize.setValue(
                                      e.target.value,
                                    ),
                                }),
                              ],
                            }),
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              children={
                                <Tooltip
                                  placement="top"
                                  title="Mesh minimum: 0.0000200"
                                >
                                  <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                              }
                            />
                          ),
                        }}
                        error={
                          !!validationResult.fieldValidations.minimumDealSize
                        }
                        helperText={
                          validationResult.fieldValidations.minimumDealSize
                        }
                      />
                      <TextNumField
                        id="placeInstrumentStablecoinDialog-maximumDealSize-textNumField"
                        disabled={listingInProgress}
                        disallowNegative
                        noDecimalPlaces={7}
                        label="Maximum Token Deal Size"
                        value={
                          createNewListingRequest.marketMechanism
                            .quoteParameters[0].maximumDealSize.value
                        }
                        onChange={(e) =>
                          handleUpdateCreateNewListingRequest(
                            "marketMechanism",
                            ["maximumDealSize"],
                          )(
                            new MarketMechanism({
                              ...createNewListingRequest.marketMechanism,
                              quoteParameters: [
                                new QuoteParameter({
                                  ...createNewListingRequest.marketMechanism
                                    .quoteParameters[0],
                                  maximumDealSize:
                                    createNewListingRequest.marketMechanism.quoteParameters[0].maximumDealSize.setValue(
                                      e.target.value,
                                    ),
                                }),
                              ],
                            }),
                          )
                        }
                        error={
                          !!validationResult.fieldValidations.maximumDealSize
                        }
                        helperText={
                          validationResult.fieldValidations.maximumDealSize
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.dialogContentRow2Column2}>
                  {/* ----------- Market Details ----------- */}
                  <div className={classes.marketDetailsLayout}>
                    <Typography
                      variant="h4"
                      className={classes.sectionHeading}
                      children="Market"
                    />
                    <div />
                    <div className={classes.marketMechanismItemLayout}>
                      <TextField
                        id="placeInstrumentStablecoinDialog-marketMechanism-textField"
                        disabled={listingInProgress}
                        readOnly
                        label="Market Mechanism"
                        value="Open Market"
                      />
                      <Tooltip
                        placement="top"
                        title="Rights to instrument will be available on the marketplace"
                      >
                        <InfoIcon
                          className={cx(
                            classes.infoIcon,
                            classes.marketMechanismInfoIcon,
                          )}
                        />
                      </Tooltip>
                    </div>
                    <TextField
                      id="placeInstrumentStablecoinDialog-audience-textField"
                      disabled={listingInProgress}
                      readOnly
                      label="Audience"
                      value="All Mesh Participants"
                    />
                  </div>

                  {/* ----------- Marketing Information ----------- */}
                  <div>
                    <Typography
                      variant="h4"
                      className={classes.sectionHeading}
                      children="Marketing Information"
                    />
                    <Typography
                      variant="body1"
                      className={classes.sectionHelperText}
                      color="textSecondary"
                    >
                      Provide us with the following information to help
                      investors understand why they should buy your instrument
                      rights and what their possible return could be.
                    </Typography>
                    {shouldShowEstimatedReturnsField.current ? (
                      <TextNumField
                        id="placeInstrumentStablecoinDialog-estimatedAnnualReturn-textNumField"
                        disabled={listingInProgress}
                        disallowNegative
                        label="Estimated Annual Return"
                        value={`${createNewListingRequest.estimatedAnnualReturn}`}
                        onChange={(e) =>
                          handleUpdateCreateNewListingRequest(
                            "estimatedAnnualReturn",
                          )(e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              children={
                                <div
                                  className={
                                    classes.marketingInformationEstimatedReturnEndAdornmentLayout
                                  }
                                >
                                  <div children="%" />
                                  <Tooltip
                                    placement="top"
                                    title="% Estimated return on investment on instrument"
                                  >
                                    <InfoIcon className={classes.infoIcon} />
                                  </Tooltip>
                                </div>
                              }
                            />
                          ),
                        }}
                        error={
                          !!validationResult.fieldValidations
                            .estimatedAnnualReturn
                        }
                        helperText={
                          validationResult.fieldValidations
                            .estimatedAnnualReturn
                        }
                      />
                    ) : (
                      <div
                        style={{
                          width: 213.141,
                          height: 55,
                        }}
                      />
                    )}
                    <div
                      className={classes.marketingGeneralDescriptionFieldLayout}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        children="Investment Objective"
                      />
                      <TextareaAutosize
                        id="placeInstrumentStablecoinDialog-investmentObjective-textArea"
                        disabled={listingInProgress}
                        minRows={8}
                        maxRows={8}
                        // disabled={apiLoading}
                        value={createNewListingRequest.investmentObjective}
                        onChange={(e) => {
                          let newValue: string = e.target.value;
                          if (newValue.length >= 500) {
                            newValue = newValue.slice(0, 500);
                          }
                          handleUpdateCreateNewListingRequest(
                            "investmentObjective",
                          )(newValue);
                        }}
                        className={cx(
                          classes.marketingInformationGeneralDescriptionFieldTextArea,
                          "meshScroll",
                          {
                            [classes.marketingGeneralDescriptionFieldError]:
                              !!validationResult.fieldValidations
                                .investmentObjective,
                          },
                        )}
                      />
                      {validationResult.fieldValidations.investmentObjective ? (
                        <Typography
                          variant="body2"
                          color="error"
                          children={
                            validationResult.fieldValidations
                              .investmentObjective
                          }
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children={`${
                            500 -
                            createNewListingRequest.investmentObjective.length
                          } characters left`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ height: 80 }} />

              {/* ----------- Fee Section ----------- */}
              <div className={classes.feeSection}>
                <div className={classes.feeControlRowLayout}>
                  <Tooltip
                    title={showFeeDetail ? "Show Less" : "Show More"}
                    placement="top"
                  >
                    <span>
                      <IconButton
                        id="placeInstrumentStablecoinDialog-toggleFeeDetail-iconButton"
                        onClick={() => {
                          // open fee detail
                          setShowFeeDetail(!showFeeDetail);

                          // and scroll to the bottom in 500ms
                          range(10, 500, 10).forEach((v) =>
                            setTimeout(() => {
                              if (dialogContentRef.current) {
                                dialogContentRef.current.scrollTop =
                                  dialogContentRef.current.scrollHeight;
                              }
                            }, v),
                          );
                        }}
                        size="large"
                      >
                        {showFeeDetail ? (
                          <ExpandLessIcon color="primary" />
                        ) : (
                          <ExpandMoreIcon color="primary" />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    children="Total Fee:"
                  />
                  <div className={classes.tokenAmount}>
                    <Tooltip
                      title={`Issued by ${valuationTokenViewModel.issuer}`}
                      placement="top"
                    >
                      <Typography
                        variant="body1"
                        className={classes.tokenAmountIssuer}
                        children={valuationTokenViewModel.token.code}
                      />
                    </Tooltip>
                    <Typography
                      variant="body1"
                      children={formatTextNum(fee.value.plus(vat.value), {
                        addDecimalPadding: true,
                      })}
                    />
                  </div>
                </div>
                <Collapse in={showFeeDetail}>
                  <div className={classes.feeDetailLineItemsWrapper}>
                    <div>
                      {generateAssetListingFeeResponse.fees.map((f, idx) => (
                        <div key={idx}>
                          <div className={classes.feeDetailBodyLineItemLayout}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              children={f.feeName()}
                            />

                            <div className={classes.tokenAmount}>
                              <Tooltip
                                title={`Issued by ${valuationTokenViewModel.issuer}`}
                                placement="top"
                              >
                                <Typography
                                  color="textSecondary"
                                  className={classes.tokenAmountIssuer}
                                  variant="body1"
                                  children={valuationTokenViewModel.token.code}
                                />
                              </Tooltip>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                children={formatTextNum(f.feeAmount().value, {
                                  addDecimalPadding: true,
                                })}
                              />
                            </div>
                          </div>
                          <div
                            key={idx}
                            className={classes.feeDetailBodyLineItemLayout}
                          >
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              children={"VAT on " + f.feeName()}
                            />

                            <div className={classes.tokenAmount}>
                              <Tooltip
                                title={`Issued by ${valuationTokenViewModel.issuer}`}
                                placement="top"
                              >
                                <Typography
                                  color="textSecondary"
                                  className={classes.tokenAmountIssuer}
                                  variant="body1"
                                  children={valuationTokenViewModel.token.code}
                                />
                              </Tooltip>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                children={formatTextNum(
                                  f
                                    .feeAmount()
                                    .value.multipliedBy(f.feeVATRate()),
                                  {
                                    addDecimalPadding: true,
                                  },
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                      <Typography
                        className={classes.feeDetailBodyWhyTheseFeesLink}
                        id="placeInstrumentStablecoinDialog-whyTheseFees-link"
                        variant="body1"
                        color="secondary"
                        onClick={() =>
                          window.open("https://mesh.trade/fees", "_blank")
                        }
                        children="Why these fees?"
                      />
                    </div>
                  </div>
                  <div className={classes.feeDetailAccAndFeeRow}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      children="Fee Acc:"
                    />
                    <Typography
                      variant="body2"
                      children={`${feeAccountViewModel.accountOwnerGroupName} Trading Acc.`}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      children="Available:"
                      className={classes.feeDetailAccAvailableAmountLabel}
                    />
                    <div className={classes.tokenAmount}>
                      <Tooltip
                        title={`Issued by ${valuationTokenViewModel.issuer}`}
                        placement="top"
                      >
                        <Typography
                          className={classes.tokenAmountIssuer}
                          variant="body2"
                          color="textSecondary"
                          children={valuationTokenViewModel.token.code}
                        />
                      </Tooltip>
                      <Typography
                        variant="body2"
                        children={formatTextNum(
                          feeAccountFeeAssetBalance.value,
                          { addDecimalPadding: true },
                        )}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
            </DialogContent>
          );
        })()}
      </StyledDialog>
      <MarketMakerResponsibilitiesDialog
        showDialog={marketMakerResponsibilitiesOpen}
        onCloseClick={() => setMarketMakerResponsibilitiesOpen(false)}
        tokenClass="Rights Token"
        responsibilities={[
          "Issuer to provide at least one price on a daily basis.",
          "Issuer accepts liability for this instrument.",
          "Issuer is to maintain balance of reference instrument to ensure fulfillment of trades made for this instrument.",
        ]}
      />
      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={listingInProgress}
        showProgressIndicator={listingInProgress}
      />
    </>
  );
}
