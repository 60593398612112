import { create } from "zustand";
import {
  ConnectedIndividual,
  ConnectionType,
  IdentificationType,
} from "james/legal";
import { Address } from "james/location";
import dayjs from "dayjs";

interface TConnectedIndividualDialogState {
  connectionType: ConnectionType | "";
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  nationality: string;
  identificationType: IdentificationType | "";
  identificationNumber: string;
  physicalAddress: Address;
  postalAddress: Address;
  postalAddressSameAs: boolean;
  telephoneNumber: string;
  cellphoneNumber: string;
  emailAddress: string;
  copyAddressChecked: boolean;
}

const initialConnectedIndividualDialogState: TConnectedIndividualDialogState = {
  connectionType: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  nationality: "",
  identificationType: "",
  identificationNumber: "",
  physicalAddress: new Address(),
  postalAddress: new Address(),
  postalAddressSameAs: false,
  telephoneNumber: "",
  cellphoneNumber: "",
  emailAddress: "",
  copyAddressChecked: false,
};

interface TConnectedIndividualDialogActions {
  setConnectionType: (val: ConnectionType | "") => void;
  setFirstName: (val: string) => void;
  setMiddleName: (val: string) => void;
  setLastName: (val: string) => void;
  setDateOfBirth: (val: string) => void;
  setNationality: (val: string) => void;
  setIdentificationType: (val: IdentificationType | "") => void;
  setIdentificationNumber: (val: string) => void;
  setPhysicalAddress: (val: Address | undefined) => void;
  setPostalAddress: (val: Address | undefined) => void;
  setPostalAddressSameAs: (val: boolean) => void;
  setTelephoneNumber: (val: string) => void;
  setCellphoneNumber: (val: string) => void;
  setEmailAddress: (val: string) => void;
  setCopyAddressChecked: (val: boolean) => void;
  getConnectedIndividualObject: () => ConnectedIndividual;
  setStateFrom: (connectedIndividual: ConnectedIndividual) => void;
  clear: () => void;
}

export const useConnectedIndividualDialogStore = create<
  TConnectedIndividualDialogState & TConnectedIndividualDialogActions
>((set, get) => ({
  ...initialConnectedIndividualDialogState,
  setConnectionType: (val: ConnectionType | "") =>
    set(() => ({ connectionType: val })),
  setFirstName: (val: string) => set(() => ({ firstName: val })),
  setMiddleName: (val: string) => set(() => ({ middleName: val })),
  setLastName: (val: string) => set(() => ({ lastName: val })),
  setDateOfBirth: (val: string) =>
    set(() => ({ dateOfBirth: dayjs(val).format() })),
  setNationality: (val: string) => set(() => ({ nationality: val })),
  setIdentificationType: (val: IdentificationType | "") =>
    set(() => ({ identificationType: val })),
  setIdentificationNumber: (val: string) =>
    set(() => ({ identificationNumber: val })),
  setPhysicalAddress: (val: Address | undefined) =>
    set(() => ({ physicalAddress: val })),
  setPostalAddress: (val: Address | undefined) =>
    set(() => ({ postalAddress: val })),
  setTelephoneNumber: (val: string) => set(() => ({ telephoneNumber: val })),
  setCellphoneNumber: (val: string) => set(() => ({ cellphoneNumber: val })),
  setEmailAddress: (val: string) => set(() => ({ emailAddress: val })),
  getConnectedIndividualObject: () => {
    const state = get();
    return new ConnectedIndividual({
      connectionType: state.connectionType,
      firstName: state.firstName,
      middleNames: state.middleName,
      lastName: state.lastName,
      dateOfBirth: dayjs(state.dateOfBirth).format(),
      nationality: state.nationality,
      identificationNumber: state.identificationNumber,
      identificationType: state.identificationType,
      physicalAddress: state.physicalAddress,
      postalAddress: state.postalAddress,
      telephoneNumber: state.telephoneNumber,
      cellphoneNumber: state.cellphoneNumber,
      emailAddress: state.emailAddress,
    });
  },
  setCopyAddressChecked: (val: boolean) =>
    set(() => ({ copyAddressChecked: val })),
  clear: () => set(() => ({ ...initialConnectedIndividualDialogState })),
  setStateFrom: (connectedIndividual: ConnectedIndividual) =>
    set(() => ({
      connectionType: connectedIndividual.connectionType,
      firstName: connectedIndividual.firstName,
      middleName: connectedIndividual.middleNames,
      lastName: connectedIndividual.lastName,
      dateOfBirth: connectedIndividual.dateOfBirth,
      nationality: connectedIndividual.nationality,
      identificationNumber: connectedIndividual.identificationNumber,
      identificationType: connectedIndividual.identificationType,
      physicalAddress: connectedIndividual.physicalAddress,
      postalAddress: connectedIndividual.postalAddress,
      telephoneNumber: connectedIndividual.telephoneNumber,
      cellphoneNumber: connectedIndividual.cellphoneNumber,
      emailAddress: connectedIndividual.emailAddress,
    })),
  setPostalAddressSameAs: (val: boolean) =>
    set(() => ({ postalAddressSameAs: val })),
}));
