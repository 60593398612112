import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { UserAuthenticated } from "const";

interface ValidateRequest {
  token: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ValidateResponse {}

export const Validator = {
  serviceProvider: "token-Validator",
  async Validate(request: ValidateRequest): Promise<ValidateResponse> {
    const validateTokenResponse = await jsonRPCRequest({
      url: config.get("meshValidateTokenURL"),
      method: `${Validator.serviceProvider}.Validate`,
      request,
    });
    sessionStorage.setItem(UserAuthenticated, UserAuthenticated);
    return validateTokenResponse;
  },
};
