import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import { ShiftingPeriod } from "@mesh/common-js/dist/financial/shiftingPeriod_pb";
import { ValidationResult } from "common/validation";
import { validatePeriod } from "../PeriodForm";

export function validateShiftingShiftingPeriod(
  shiftingPeriod: ShiftingPeriod,
  fieldValidationsPrefix?: string,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };
  const notValid = (field: string, helperText: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${fieldValidationsPrefix ?? ""}-${field}`
    ] = helperText;
  };

  if (shiftingPeriod.getCalendarsList().length === 0) {
    notValid("calendars", "Cannot be blank");
  }

  if (
    shiftingPeriod.getBusinessdayconvention() ===
    BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION
  ) {
    notValid("businessDayConvention", "Must be set");
  }

  const period = shiftingPeriod.getPeriod();
  if (!period) {
    throw new TypeError("period is not set");
  }
  const scheduleValidationResult = validatePeriod(
    period,
    fieldValidationsPrefix + "-shiftingPeriod",
  );
  validationResult.valid =
    scheduleValidationResult.valid && validationResult.valid;
  for (const field in scheduleValidationResult.fieldValidations) {
    validationResult.fieldValidations[field] =
      scheduleValidationResult.fieldValidations[field];
  }

  return validationResult;
}
