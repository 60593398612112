/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import debounce from "lodash/debounce";
import React, { ChangeEvent, useState } from "react";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import { Review } from "./components/Dialogs/Review";
import { Warning } from "./components/Dialogs/Warning";
import {
  ClientAdminFormFieldsValidationsState,
  FormFieldsValidation,
  FormValidator,
} from "./Validation";
const PREFIX = "ClientAdministrator";

const classes = {
  fieldLayout: `${PREFIX}-fieldLayout`,
  sectionWith2EqColumns2Gap: `${PREFIX}-sectionWith2EqColumns2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.fieldLayout}`]: {
    width: 368,
  },

  [`& .${classes.sectionWith2EqColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },
}));

interface ClientAdminProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  onChange: any;
  admin: { name: string; surname: string; email: string };
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
  isAllStepsValid: () => boolean;
}

export const ClientAdministrator = (props: ClientAdminProps) => {
  const { admin, onChange, onNext, invalidStep, inValid, isAllStepsValid } =
    props;
  const adminData = admin;
  const [FormFieldValid, setFormFieldValid] = useState<{
    [key: string]: string | undefined;
  }>({ ...ClientAdminFormFieldsValidationsState });
  const [ranValidation, setRanValidation] = useState(false);
  const [showWarningDialog, setShowWarning] = useState(false);
  const [showReviewDialog, setReviewDialog] = useState(false);

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(FormFieldValid, admin, setFormFieldValid);
  }

  const nextStep = () => {
    if (FormFieldsValidation(FormFieldValid, admin, setFormFieldValid)) {
      // Are all the steps Valid if so only go to confirmation
      if (isAllStepsValid()) {
        setShowWarning(true);
      }
      invalidStep(CompanyRegStep.clientAdministratorData, false);
    } else {
      invalidStep(CompanyRegStep.clientAdministratorData, true);
      setReviewDialog(true);
    }
  };

  const InlineFormValidation = (field: string, value: any) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...FormFieldValid };

      if (admin.email === "") {
        InvalidFormField = { ...InvalidFormField, email: undefined };
      }

      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        InvalidFormField = { ...InvalidFormField, [field]: undefined };
      }
      setFormFieldValid(InvalidFormField);
    }, 600);

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const newAdminInfo = { ...adminData, [name]: event.target.value };
    InlineFormValidation(name, event.target.value)();
    onChange(newAdminInfo);
  };

  return (
    <Root>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary">
            Client Administrator
          </Typography>
        </Box>
        <Button
          id="clientAdministratorData-next"
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          Next
        </Button>
      </Box>
      <Typography
        style={{ marginTop: "8px", marginBottom: "40px" }}
        variant="subtitle2"
      >
        Add the details of the person who will be the official administrator for
        your company.
      </Typography>

      <div className={classes.fieldLayout}>
        <div className={classes.sectionWith2EqColumns2Gap}>
          <TextField
            fullWidth
            id="clientAdministratorData-name"
            label="First Name"
            margin="dense"
            variant="outlined"
            value={adminData.name}
            onChange={onUpdate("name")}
            error={Boolean(FormFieldValid.name)}
            helperText={FormFieldValid.name}
          />
          <TextField
            fullWidth
            id="clientAdministratorData-surname"
            label="Last Name"
            margin="dense"
            variant="outlined"
            value={adminData.surname}
            onChange={onUpdate("surname")}
            error={Boolean(FormFieldValid.surname)}
            helperText={FormFieldValid.surname}
          />
        </div>
        <TextField
          id="clientAdministratorData-email"
          fullWidth
          label="Email"
          margin="dense"
          variant="outlined"
          value={adminData.email}
          onChange={onUpdate("email")}
          error={Boolean(FormFieldValid.email)}
          helperText={FormFieldValid.email}
        />
      </div>
      {showWarningDialog && (
        <Warning open onNext={onNext} onClose={() => setShowWarning(false)} />
      )}
      {showReviewDialog && (
        <Review open onNext={onNext} onClose={() => setReviewDialog(false)} />
      )}
    </Root>
  );
};
