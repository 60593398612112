import React from "react";
import { Autocomplete, AutocompleteProps, Box, TextField } from "@mui/material";
import { countries } from "james/country";
import { CountryOption } from "james/country/countries";

type CountryDropdownProps = AutocompleteProps<
  CountryOption,
  undefined,
  undefined,
  undefined,
  "div"
>;

export const CountryDropdown = (props: Partial<CountryDropdownProps>) => {
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option === value}
      {...props}
      options={countries}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={
        props.renderInput ??
        ((params) => (
          <TextField
            {...params}
            margin="dense"
            label="Nationality"
            variant="outlined"
            fullWidth
          />
        ))
      }
    />
  );
};
