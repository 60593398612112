export enum InstrumentRiskProfile {
  Low = "Low",
  LowToModerate = "Low to Moderate",
  Moderate = "Moderate",
  ModerateToHigh = "Moderate to High",
  High = "High",
}

export const AllInstrumentRiskProfiles: InstrumentRiskProfile[] = [
  InstrumentRiskProfile.Low,
  InstrumentRiskProfile.LowToModerate,
  InstrumentRiskProfile.Moderate,
  InstrumentRiskProfile.ModerateToHigh,
  InstrumentRiskProfile.High,
];

export const RiskColorMap: Record<InstrumentRiskProfile | "", string> = {
  [InstrumentRiskProfile.Low]: "#acdcdd",
  [InstrumentRiskProfile.LowToModerate]: "#ACDDB2",
  [InstrumentRiskProfile.Moderate]: "#FFCA83",
  [InstrumentRiskProfile.ModerateToHigh]: "#F27049",
  [InstrumentRiskProfile.High]: "#D94A4B",
  "": "rgba(255, 255, 255, 0.60)",
};
