export class Document {
  public id = "";

  public name = "";

  public mimeType = "";

  public description = "";

  constructor(document?: Document) {
    if (!document) {
      return;
    }
    this.id = document.id;
    this.name = document.name;
    this.mimeType = document.mimeType;
    this.description = document.description;
  }
}
