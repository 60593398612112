import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Token } from "james/ledger";
import { Model } from "./model";

export interface ReadRequest {
  context: Context;
  token: Token;
}

export interface ReadResponse {
  models: Model[];
}

export const Reader = {
  serviceProvider: "ledgerAssetHolderView-Reader",
  async Read(
    request: ReadRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<ReadResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${Reader.serviceProvider}.Read`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return {
      models: response.models.map((m: Model) => new Model(m)),
    };
  },
};
