// source: financial/smartInstrumentAttribute.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var financial_smartInstrumentAttributeSectorAllocations_pb = require('../financial/smartInstrumentAttributeSectorAllocations_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeSectorAllocations_pb);
var financial_smartInstrumentAttributeCountryAllocations_pb = require('../financial/smartInstrumentAttributeCountryAllocations_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeCountryAllocations_pb);
var financial_smartInstrumentAttributeFiatCurrencyAllocations_pb = require('../financial/smartInstrumentAttributeFiatCurrencyAllocations_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeFiatCurrencyAllocations_pb);
var financial_smartInstrumentAttributeHoldingAllocations_pb = require('../financial/smartInstrumentAttributeHoldingAllocations_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeHoldingAllocations_pb);
var financial_smartInstrumentAttributeRiskAssessment_pb = require('../financial/smartInstrumentAttributeRiskAssessment_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeRiskAssessment_pb);
var financial_smartInstrumentAttributeExternalParticipantsInformation_pb = require('../financial/smartInstrumentAttributeExternalParticipantsInformation_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeExternalParticipantsInformation_pb);
var financial_smartInstrumentAttributeAssetClass_pb = require('../financial/smartInstrumentAttributeAssetClass_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeAssetClass_pb);
var financial_smartInstrumentAttributeAnnualPerformanceLog_pb = require('../financial/smartInstrumentAttributeAnnualPerformanceLog_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttributeAnnualPerformanceLog_pb);
goog.exportSymbol('proto.financial.SmartInstrumentAttribute', null, global);
goog.exportSymbol('proto.financial.SmartInstrumentAttribute.SmartinstrumentattributeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.SmartInstrumentAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.financial.SmartInstrumentAttribute.oneofGroups_);
};
goog.inherits(proto.financial.SmartInstrumentAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.SmartInstrumentAttribute.displayName = 'proto.financial.SmartInstrumentAttribute';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.financial.SmartInstrumentAttribute.oneofGroups_ = [[1,2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.financial.SmartInstrumentAttribute.SmartinstrumentattributeCase = {
  SMARTINSTRUMENTATTRIBUTE_NOT_SET: 0,
  SECTORALLOCATIONSSMARTINSTRUMENTATTRIBUTE: 1,
  COUNTRYALLOCATIONSSMARTINSTRUMENTATTRIBUTE: 2,
  FIATCURRENCYALLOCATIONSSMARTINSTRUMENTATTRIBUTE: 3,
  HOLDINGALLOCATIONSSMARTINSTRUMENTATTRIBUTE: 4,
  RISKASSESSMENTSMARTINSTRUMENTATTRIBUTE: 5,
  EXTERNALPARTICIPANTSINFORMATIONSMARTINSTRUMENTATTRIBUTE: 6,
  ASSETCLASSSMARTINSTRUMENTATTRIBUTE: 7,
  ANNUALPERFORMANCELOGSMARTINSTRUMENTATTRIBUTE: 8
};

/**
 * @return {proto.financial.SmartInstrumentAttribute.SmartinstrumentattributeCase}
 */
proto.financial.SmartInstrumentAttribute.prototype.getSmartinstrumentattributeCase = function() {
  return /** @type {proto.financial.SmartInstrumentAttribute.SmartinstrumentattributeCase} */(jspb.Message.computeOneofCase(this, proto.financial.SmartInstrumentAttribute.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.SmartInstrumentAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.SmartInstrumentAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.SmartInstrumentAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.SmartInstrumentAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
sectorallocationssmartinstrumentattribute: (f = msg.getSectorallocationssmartinstrumentattribute()) && financial_smartInstrumentAttributeSectorAllocations_pb.SectorAllocationsSmartInstrumentAttribute.toObject(includeInstance, f),
countryallocationssmartinstrumentattribute: (f = msg.getCountryallocationssmartinstrumentattribute()) && financial_smartInstrumentAttributeCountryAllocations_pb.CountryAllocationsSmartInstrumentAttribute.toObject(includeInstance, f),
fiatcurrencyallocationssmartinstrumentattribute: (f = msg.getFiatcurrencyallocationssmartinstrumentattribute()) && financial_smartInstrumentAttributeFiatCurrencyAllocations_pb.FiatCurrencyAllocationsSmartInstrumentAttribute.toObject(includeInstance, f),
holdingallocationssmartinstrumentattribute: (f = msg.getHoldingallocationssmartinstrumentattribute()) && financial_smartInstrumentAttributeHoldingAllocations_pb.HoldingAllocationsSmartInstrumentAttribute.toObject(includeInstance, f),
riskassessmentsmartinstrumentattribute: (f = msg.getRiskassessmentsmartinstrumentattribute()) && financial_smartInstrumentAttributeRiskAssessment_pb.RiskAssessmentSmartInstrumentAttribute.toObject(includeInstance, f),
externalparticipantsinformationsmartinstrumentattribute: (f = msg.getExternalparticipantsinformationsmartinstrumentattribute()) && financial_smartInstrumentAttributeExternalParticipantsInformation_pb.ExternalParticipantsInformationSmartInstrumentAttribute.toObject(includeInstance, f),
assetclasssmartinstrumentattribute: (f = msg.getAssetclasssmartinstrumentattribute()) && financial_smartInstrumentAttributeAssetClass_pb.AssetClassSmartInstrumentAttribute.toObject(includeInstance, f),
annualperformancelogsmartinstrumentattribute: (f = msg.getAnnualperformancelogsmartinstrumentattribute()) && financial_smartInstrumentAttributeAnnualPerformanceLog_pb.AnnualPerformanceLogSmartInstrumentAttribute.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.SmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.SmartInstrumentAttribute;
  return proto.financial.SmartInstrumentAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.SmartInstrumentAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.SmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrumentAttributeSectorAllocations_pb.SectorAllocationsSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeSectorAllocations_pb.SectorAllocationsSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setSectorallocationssmartinstrumentattribute(value);
      break;
    case 2:
      var value = new financial_smartInstrumentAttributeCountryAllocations_pb.CountryAllocationsSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeCountryAllocations_pb.CountryAllocationsSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setCountryallocationssmartinstrumentattribute(value);
      break;
    case 3:
      var value = new financial_smartInstrumentAttributeFiatCurrencyAllocations_pb.FiatCurrencyAllocationsSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeFiatCurrencyAllocations_pb.FiatCurrencyAllocationsSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setFiatcurrencyallocationssmartinstrumentattribute(value);
      break;
    case 4:
      var value = new financial_smartInstrumentAttributeHoldingAllocations_pb.HoldingAllocationsSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeHoldingAllocations_pb.HoldingAllocationsSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setHoldingallocationssmartinstrumentattribute(value);
      break;
    case 5:
      var value = new financial_smartInstrumentAttributeRiskAssessment_pb.RiskAssessmentSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeRiskAssessment_pb.RiskAssessmentSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setRiskassessmentsmartinstrumentattribute(value);
      break;
    case 6:
      var value = new financial_smartInstrumentAttributeExternalParticipantsInformation_pb.ExternalParticipantsInformationSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeExternalParticipantsInformation_pb.ExternalParticipantsInformationSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setExternalparticipantsinformationsmartinstrumentattribute(value);
      break;
    case 7:
      var value = new financial_smartInstrumentAttributeAssetClass_pb.AssetClassSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeAssetClass_pb.AssetClassSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setAssetclasssmartinstrumentattribute(value);
      break;
    case 8:
      var value = new financial_smartInstrumentAttributeAnnualPerformanceLog_pb.AnnualPerformanceLogSmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttributeAnnualPerformanceLog_pb.AnnualPerformanceLogSmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.setAnnualperformancelogsmartinstrumentattribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.SmartInstrumentAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.SmartInstrumentAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.SmartInstrumentAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.SmartInstrumentAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectorallocationssmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrumentAttributeSectorAllocations_pb.SectorAllocationsSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getCountryallocationssmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrumentAttributeCountryAllocations_pb.CountryAllocationsSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getFiatcurrencyallocationssmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      financial_smartInstrumentAttributeFiatCurrencyAllocations_pb.FiatCurrencyAllocationsSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getHoldingallocationssmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      financial_smartInstrumentAttributeHoldingAllocations_pb.HoldingAllocationsSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getRiskassessmentsmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      financial_smartInstrumentAttributeRiskAssessment_pb.RiskAssessmentSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getExternalparticipantsinformationsmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      financial_smartInstrumentAttributeExternalParticipantsInformation_pb.ExternalParticipantsInformationSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getAssetclasssmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      financial_smartInstrumentAttributeAssetClass_pb.AssetClassSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
  f = message.getAnnualperformancelogsmartinstrumentattribute();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      financial_smartInstrumentAttributeAnnualPerformanceLog_pb.AnnualPerformanceLogSmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional SectorAllocationsSmartInstrumentAttribute sectorAllocationsSmartInstrumentAttribute = 1;
 * @return {?proto.financial.SectorAllocationsSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getSectorallocationssmartinstrumentattribute = function() {
  return /** @type{?proto.financial.SectorAllocationsSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeSectorAllocations_pb.SectorAllocationsSmartInstrumentAttribute, 1));
};


/**
 * @param {?proto.financial.SectorAllocationsSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setSectorallocationssmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearSectorallocationssmartinstrumentattribute = function() {
  return this.setSectorallocationssmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasSectorallocationssmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CountryAllocationsSmartInstrumentAttribute countryAllocationsSmartInstrumentAttribute = 2;
 * @return {?proto.financial.CountryAllocationsSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getCountryallocationssmartinstrumentattribute = function() {
  return /** @type{?proto.financial.CountryAllocationsSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeCountryAllocations_pb.CountryAllocationsSmartInstrumentAttribute, 2));
};


/**
 * @param {?proto.financial.CountryAllocationsSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setCountryallocationssmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearCountryallocationssmartinstrumentattribute = function() {
  return this.setCountryallocationssmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasCountryallocationssmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FiatCurrencyAllocationsSmartInstrumentAttribute fiatCurrencyAllocationsSmartInstrumentAttribute = 3;
 * @return {?proto.financial.FiatCurrencyAllocationsSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getFiatcurrencyallocationssmartinstrumentattribute = function() {
  return /** @type{?proto.financial.FiatCurrencyAllocationsSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeFiatCurrencyAllocations_pb.FiatCurrencyAllocationsSmartInstrumentAttribute, 3));
};


/**
 * @param {?proto.financial.FiatCurrencyAllocationsSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setFiatcurrencyallocationssmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearFiatcurrencyallocationssmartinstrumentattribute = function() {
  return this.setFiatcurrencyallocationssmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasFiatcurrencyallocationssmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HoldingAllocationsSmartInstrumentAttribute holdingAllocationsSmartInstrumentAttribute = 4;
 * @return {?proto.financial.HoldingAllocationsSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getHoldingallocationssmartinstrumentattribute = function() {
  return /** @type{?proto.financial.HoldingAllocationsSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeHoldingAllocations_pb.HoldingAllocationsSmartInstrumentAttribute, 4));
};


/**
 * @param {?proto.financial.HoldingAllocationsSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setHoldingallocationssmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearHoldingallocationssmartinstrumentattribute = function() {
  return this.setHoldingallocationssmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasHoldingallocationssmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RiskAssessmentSmartInstrumentAttribute riskAssessmentSmartInstrumentAttribute = 5;
 * @return {?proto.financial.RiskAssessmentSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getRiskassessmentsmartinstrumentattribute = function() {
  return /** @type{?proto.financial.RiskAssessmentSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeRiskAssessment_pb.RiskAssessmentSmartInstrumentAttribute, 5));
};


/**
 * @param {?proto.financial.RiskAssessmentSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setRiskassessmentsmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearRiskassessmentsmartinstrumentattribute = function() {
  return this.setRiskassessmentsmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasRiskassessmentsmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ExternalParticipantsInformationSmartInstrumentAttribute externalParticipantsInformationSmartInstrumentAttribute = 6;
 * @return {?proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getExternalparticipantsinformationsmartinstrumentattribute = function() {
  return /** @type{?proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeExternalParticipantsInformation_pb.ExternalParticipantsInformationSmartInstrumentAttribute, 6));
};


/**
 * @param {?proto.financial.ExternalParticipantsInformationSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setExternalparticipantsinformationsmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearExternalparticipantsinformationsmartinstrumentattribute = function() {
  return this.setExternalparticipantsinformationsmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasExternalparticipantsinformationsmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AssetClassSmartInstrumentAttribute assetClassSmartInstrumentAttribute = 7;
 * @return {?proto.financial.AssetClassSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getAssetclasssmartinstrumentattribute = function() {
  return /** @type{?proto.financial.AssetClassSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeAssetClass_pb.AssetClassSmartInstrumentAttribute, 7));
};


/**
 * @param {?proto.financial.AssetClassSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setAssetclasssmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearAssetclasssmartinstrumentattribute = function() {
  return this.setAssetclasssmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasAssetclasssmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AnnualPerformanceLogSmartInstrumentAttribute annualPerformanceLogSmartInstrumentAttribute = 8;
 * @return {?proto.financial.AnnualPerformanceLogSmartInstrumentAttribute}
 */
proto.financial.SmartInstrumentAttribute.prototype.getAnnualperformancelogsmartinstrumentattribute = function() {
  return /** @type{?proto.financial.AnnualPerformanceLogSmartInstrumentAttribute} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrumentAttributeAnnualPerformanceLog_pb.AnnualPerformanceLogSmartInstrumentAttribute, 8));
};


/**
 * @param {?proto.financial.AnnualPerformanceLogSmartInstrumentAttribute|undefined} value
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
*/
proto.financial.SmartInstrumentAttribute.prototype.setAnnualperformancelogsmartinstrumentattribute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.financial.SmartInstrumentAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.SmartInstrumentAttribute} returns this
 */
proto.financial.SmartInstrumentAttribute.prototype.clearAnnualperformancelogsmartinstrumentattribute = function() {
  return this.setAnnualperformancelogsmartinstrumentattribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.SmartInstrumentAttribute.prototype.hasAnnualperformancelogsmartinstrumentattribute = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.financial);
