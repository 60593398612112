import { AuditEntry } from "../audit";

export enum BankName {
  ABSABank = "Absa Bank Limited",
  AfricanBank = "African Bank Limited",
  BidvestBank = "Bidvest Bank Limited",
  CapitecBank = "Capitec Bank Limited",
  DiscoveryBank = "Discovery Bank Limited",
  FirstNationalBank = "First National Bank (FNB)",
  FirstRandBank = "First Rand Bank",
  GrindRodBank = "GrindRod Bank Limited",
  InvestecBank = "Investec Bank Limited",
  MercantileBank = "Mercantile Bank Limited",
  NedbankBank = "Nedbank Limited",
  OldMutualBank = "Old Mutual",
  SasfinBank = "Sasfin Bank Limited",
  Postbank = "Postbank",
  TymeBank = "TymeBank",
  StandardBank = "Standard Bank Limited",
}

export function bankUniversalBranchCode(bankName: BankName): string {
  switch (bankName) {
    case BankName.ABSABank:
      return "632005";
    case BankName.AfricanBank:
      return "430000";
    case BankName.BidvestBank:
      return "462005";
    case BankName.SasfinBank:
      return "683000";
    case BankName.CapitecBank:
      return "470010";
    case BankName.DiscoveryBank:
      return "679000";
    case BankName.FirstNationalBank:
      return "250655";
    case BankName.FirstRandBank:
      return "250655";
    case BankName.GrindRodBank:
      return "223626";
    case BankName.InvestecBank:
      return "580105";
    case BankName.NedbankBank:
      return "198765";
    case BankName.MercantileBank:
      return "450105";
    case BankName.OldMutualBank:
      return "462005";
    case BankName.Postbank:
      return "460005";
    case BankName.TymeBank:
      return "678910";
    case BankName.StandardBank:
      return "051001";
    default:
      return "";
  }
}

export const AllBankNames: BankName[] = [
  BankName.ABSABank,
  BankName.BidvestBank,
  BankName.AfricanBank,
  BankName.CapitecBank,
  BankName.DiscoveryBank,
  BankName.FirstNationalBank,
  BankName.FirstRandBank,
  BankName.GrindRodBank,
  BankName.InvestecBank,
  BankName.MercantileBank,
  BankName.NedbankBank,
  BankName.OldMutualBank,
  BankName.SasfinBank,
  BankName.Postbank,
  BankName.TymeBank,
  BankName.StandardBank,
];

export const getBankAccountTypes = (
  bankName: BankName | "",
): Array<BankAccountType | ""> => {
  switch (bankName) {
    case BankName.ABSABank:
    case BankName.FirstNationalBank:
    case BankName.NedbankBank:
    case BankName.StandardBank:
    case BankName.Postbank:
      return AllBankAccountTypes;
    default:
      return [
        BankAccountType.ChequeCurrent,
        BankAccountType.Savings,
        BankAccountType.MoneyMarket,
      ];
  }
};

export enum BankAccountType {
  ChequeCurrent = "Cheque/Current",
  Savings = "Savings",
  MoneyMarket = "Money Market",
  Mzansi = "Mzansi",
}

export const AllBankAccountTypes: BankAccountType[] = [
  BankAccountType.ChequeCurrent,
  BankAccountType.Savings,
  BankAccountType.MoneyMarket,
  BankAccountType.Mzansi,
];

export class BankAccount {
  public id = "";

  public ownerID = "";

  public countryCode = "";

  public bankName: BankName | "" = "";

  public accountType: BankAccountType | "" = "";

  public branchCode = "";

  public number = "";

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(bankAccount?: BankAccount) {
    if (!bankAccount) {
      return;
    }

    this.id = bankAccount.id;
    this.ownerID = bankAccount.ownerID;
    this.countryCode = bankAccount.countryCode;
    this.bankName = bankAccount.bankName;
    this.accountType = bankAccount.accountType;
    this.branchCode = bankAccount.branchCode;
    this.number = bankAccount.number;
    this.auditEntry = new AuditEntry(bankAccount.auditEntry);
  }
}
