import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Listing, Mechanism } from "james/market";

export type UpdateListingMarketMechanismRequest = {
  context: Context;
  listingID: string;
  marketMechanism: Mechanism;
};

export type UpdateListingMarketMechanismResponse = {
  listing: Listing;
};

export type UpdateListingMarketMechanismsRequest = {
  context: Context;
  listingID: string;
  marketMechanisms: Mechanism[];
};

export type UpdateListingMarketMechanismsResponse = {
  listing: Listing;
};

const AssetStateListerServiceProviderName = "market-ListingUpdater";

export const ListingUpdater = {
  async UpdateListingMarketMechanism(
    request: UpdateListingMarketMechanismRequest,
    additionalHeaders?: Headers,
  ): Promise<UpdateListingMarketMechanismResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetStateListerServiceProviderName}.UpdateListingMarketMechanism`,
        request,
      },
      { additionalHeaders },
    );

    return { listing: new Listing(response.listing) };
  },
  async UpdateListingMarketMechanisms(
    request: UpdateListingMarketMechanismsRequest,
    additionalHeaders?: Headers,
  ): Promise<UpdateListingMarketMechanismsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetStateListerServiceProviderName}.UpdateListingMarketMechanisms`,
        request,
      },
      { additionalHeaders },
    );

    return { listing: new Listing(response.listing) };
  },
};
