import React, { useCallback, useContext, useRef } from "react";
import { sleep } from "utilities/general";
import { Token } from "james/ledger";
import { AllStellarNetworks, StellarNetwork } from "james/stellar";
import { useStellarContext } from "context/Stellar";

interface ContextType {
  ledgerContextGetAssetTokenDescription: (token: Token) => Promise<string>;
  ledgerContextGetTokenIssuerInformation: (
    token: Token,
  ) => Promise<IssuerInformation>;
}

export type IssuerInformation = {
  name: string;
  description: string;
  logoURL: string;
  email: string;
  website: string;
  twitter: string;
};

const Context = React.createContext({} as ContextType);

export function LedgerContext({ children }: { children?: React.ReactNode }) {
  const {
    stellarContextGetTokenDescription,
    stellarContextGetTokenIssuerInformation,
  } = useStellarContext();

  // cached domain toml file retrieval
  const { current: assetTokenDescriptionFetchInProgressFor } = useRef<{
    [key: string]: boolean;
  }>({});
  const { current: assetTokenDescriptionCache } = useRef<{
    [key: string]: string;
  }>({});
  const ledgerContextGetAssetTokenDescription: (
    token: Token,
  ) => Promise<string> = useCallback(
    async (token: Token) => {
      // if the description is already stored return it
      if (assetTokenDescriptionCache[token.string()]) {
        return assetTokenDescriptionCache[token.string()];
      }

      // check if a fetch for the description has been recorded for this home domain
      if (assetTokenDescriptionFetchInProgressFor[token.string()]) {
        // if so then description fetch is in progress, wait for that fetch to finish

        // keep track of how many wait sleep cycles have taken place
        let waitCount = 0;

        // for as long as a description is not in the cache...
        while (!assetTokenDescriptionCache[token.string()]) {
          // wait for 500ms
          await sleep(500);

          // check if wait count exceeded
          if (waitCount > 4) {
            // exceeded, return blank description
            console.error("timeout waiting for description to be fetched");
            return "Unknown";
          }

          // wait count not exceeded - increment wait count and go again
          waitCount++;
        }

        // description is now set, return it
        return assetTokenDescriptionCache[token.string()];
      }

      // mark fetch in progress
      assetTokenDescriptionFetchInProgressFor[token.string()] = true;

      if (token.issuer === token.network) {
        // if asset token is issued by its network then the description is a default
        assetTokenDescriptionCache[token.string()] =
          `Native Coin on the ${token.network} Network`;
      } else if (AllStellarNetworks.includes(token.network as StellarNetwork)) {
        // if token is issued on the stellar network then use the stellar context description method
        assetTokenDescriptionCache[token.string()] =
          await stellarContextGetTokenDescription(token);
      } else {
        assetTokenDescriptionCache[token.string()] = "Unknown";
      }

      // mark fetch complete
      delete assetTokenDescriptionFetchInProgressFor[token.string()];

      // and return it
      return assetTokenDescriptionCache[token.string()];
    },
    [
      assetTokenDescriptionCache,
      assetTokenDescriptionFetchInProgressFor,
      stellarContextGetTokenDescription,
    ],
  );

  const ledgerContextGetTokenIssuerInformation: (
    token: Token,
  ) => Promise<IssuerInformation> = useCallback(
    async (token: Token) => {
      return stellarContextGetTokenIssuerInformation(token);
    },
    [stellarContextGetTokenIssuerInformation],
  );

  return (
    <Context.Provider
      value={{
        ledgerContextGetAssetTokenDescription,
        ledgerContextGetTokenIssuerInformation,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useLedgerContext = () => useContext(Context);
export { useLedgerContext };
