"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTriggerWrapper = void 0;
const timestamp_pb_1 = require("google-protobuf/google/protobuf/timestamp_pb");
const paymentTrigger_pb_1 = require("./paymentTrigger_pb");
const amount_pb_1 = require("../ledger/amount_pb");
const assetFlowCategory_1 = require("./assetFlowCategory");
const assetflowCategory_pb_1 = require("./assetflowCategory_pb");
/**
 * Wrapper class for PaymentTrigger.
 */
class PaymentTriggerWrapper {
    /**
     * Constructs a new PaymentTriggerWrapper instance.
     * @param {PaymentTrigger} paymentTrigger - The payment trigger instance to wrap.
     * @throws {TypeError} If the wrapped payment trigger does not have a valid payment trigger set.
     */
    constructor(paymentTrigger) {
        this._paymentTrigger = paymentTrigger !== null && paymentTrigger !== void 0 ? paymentTrigger : new paymentTrigger_pb_1.PaymentTrigger();
    }
    /**
     * Gets the ID of the wrapped payment trigger.
     * @returns {string} The ID of the wrapped payment trigger.
     * @throws {TypeError} If the wrapped payment trigger does not have a valid payment trigger set.
     */
    get id() {
        var _a, _b, _c, _d;
        switch (true) {
            case this._paymentTrigger.hasAssetflow():
                return (_b = (_a = this._paymentTrigger.getAssetflow()) === null || _a === void 0 ? void 0 : _a.getId()) !== null && _b !== void 0 ? _b : "";
            case this._paymentTrigger.hasCall():
                return (_d = (_c = this._paymentTrigger.getCall()) === null || _c === void 0 ? void 0 : _c.getId()) !== null && _d !== void 0 ? _d : "";
            default:
                throw new TypeError("The wrapped payment trigger does not have a valid payment trigger set");
        }
    }
    /**
     * Gets the record date of the wrapped payment trigger.
     * @returns {Timestamp} The recorddate of the wrapped payment trigger.
     * @throws {TypeError} If the wrapped payment trigger does not have a valid payment trigger set.
     */
    get recorddate() {
        var _a, _b, _c, _d;
        switch (true) {
            case this._paymentTrigger.hasAssetflow():
                return ((_b = (_a = this._paymentTrigger.getAssetflow()) === null || _a === void 0 ? void 0 : _a.getRecorddate()) !== null && _b !== void 0 ? _b : new timestamp_pb_1.Timestamp());
            case this._paymentTrigger.hasCall():
                return ((_d = (_c = this._paymentTrigger.getCall()) === null || _c === void 0 ? void 0 : _c.getRecorddate()) !== null && _d !== void 0 ? _d : new timestamp_pb_1.Timestamp());
            default:
                throw new TypeError("The wrapped payment trigger does not have a valid payment trigger set");
        }
    }
    /**
     * Gets the amount of the wrapped payment trigger.
     * @returns {FutureAmount} The amount of the wrapped payment trigger.
     * @throws {TypeError} If the wrapped payment trigger does not have a valid payment trigger set.
     */
    get amount() {
        var _a, _b, _c, _d;
        switch (true) {
            case this._paymentTrigger.hasAssetflow():
                return ((_b = (_a = this._paymentTrigger.getAssetflow()) === null || _a === void 0 ? void 0 : _a.getAmount()) !== null && _b !== void 0 ? _b : new amount_pb_1.FutureAmount());
            case this._paymentTrigger.hasCall():
                return ((_d = (_c = this._paymentTrigger.getCall()) === null || _c === void 0 ? void 0 : _c.getAmount()) !== null && _d !== void 0 ? _d : new amount_pb_1.FutureAmount());
            default:
                throw new TypeError("The wrapped payment trigger does not have a valid payment trigger set");
        }
    }
    /**
     * Gets the fixed value of the wrapped payment trigger.
     * @returns {boolean} The fixed value of the wrapped payment trigger.
     * @throws {TypeError} If the wrapped payment trigger does not have a valid payment trigger set.
     */
    get fixed() {
        var _a, _b;
        switch (true) {
            case this._paymentTrigger.hasAssetflow():
                return (_b = (_a = this._paymentTrigger.getAssetflow()) === null || _a === void 0 ? void 0 : _a.getFixed()) !== null && _b !== void 0 ? _b : false;
            case this._paymentTrigger.hasCall():
                // calls are fixed as soon as they are created (i.e. amount not affected by time)
                return true;
            default:
                throw new TypeError("The wrapped payment trigger does not have a valid payment trigger set");
        }
    }
    /**
     * Gets a description of the wrapped payment trigger.
     * @returns {string} a description of the wrapped payment trigger.
     * @throws {TypeError} If the wrapped payment trigger does not have a valid payment trigger set.
     */
    get description() {
        var _a, _b, _c;
        switch (true) {
            case this._paymentTrigger.hasAssetflow():
                return `${(0, assetFlowCategory_1.assetflowCategoryToString)((_b = (_a = this._paymentTrigger.getAssetflow()) === null || _a === void 0 ? void 0 : _a.getCategory()) !== null && _b !== void 0 ? _b : assetflowCategory_pb_1.AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY)} Assetflow ${(_c = this._paymentTrigger.getAssetflow()) === null || _c === void 0 ? void 0 : _c.getNumber()}`;
            case this._paymentTrigger.hasCall():
                return "Call";
            default:
                throw new TypeError("The wrapped payment trigger does not have a valid payment trigger set");
        }
    }
    /**
     * Gets the wrapped payment trigger instance.
     * @returns {PaymentTrigger} The wrapped payment trigger.
     */
    get paymentTrigger() {
        return this._paymentTrigger;
    }
}
exports.PaymentTriggerWrapper = PaymentTriggerWrapper;
