import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Router } from "routes";
import { RouteType } from "routes/Route";
import { ViewConfiguration } from "james/configuration";
import cx from "classnames";
import { CreateOrEditGroupDialog } from "components/CreateOrEditGroupDialog";
import { Group } from "james/group";
import { InviteCompanyDialog } from "components/InviteCompanyDialog";
import { Groups } from "./components/Groups";
import { Users } from "./components/Users";
import { Companies } from "./components/Companies";
import { AddOrInviteUsersDialog } from "./components/Groups/components/Explore/AddOrInviteUsersDialog";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { useErrorContext } from "context/Error";

const PREFIX = "AdminManagement";

const classes = {
  tabBar: `${PREFIX}-tabBar`,
  content: `${PREFIX}-content`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tabBar}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2, 2, 0, 2),
    height: "calc(100vh - 100px)",
    overflow: "hidden",
  },
}));

const operationsTabRoutes: RouteType[] = [
  {
    name: "Groups",
    id: "groups-tab-route",
    path: "/administration/manage/groups",
    component: <Groups />,
    allowSubPaths: true,
  },
  {
    name: "Users",
    id: "users-tab-route",
    path: "/administration/manage/users",
    component: <Users />,
  },
  {
    name: "Companies",
    id: "companies-tab-route",
    path: "/administration/manage/companies",
    component: <Companies />,
  },
];

function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  const administrationViewConfiguration = viewConfig.Administration;
  if (!administrationViewConfiguration) {
    return [];
  }
  return operationsTabRoutes.filter(
    (tabRoute) => administrationViewConfiguration[tabRoute.name],
  );
}

export const AdminManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { viewConfiguration, myClient, myClientRetrievalErr } =
    useApplicationContext();
  const [activeTabRoutePath, setActiveTabRoutePath] = useState("");
  const [createOrEditGroupDialogOpen, setCreateOrEditGroupDialogOpen] =
    useState(false);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [inviteCompanyDialogOpen, setInviteCompanyDialogOpen] = useState(false);
  const availableTabRoutes = determineAvailableTabRoutes(viewConfiguration);

  useEffect(() => {
    // navigate to the marketplace
    if (!myClient && myClientRetrievalErr) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      navigate("/market/markets");
    }
  }, [myClientRetrievalErr]);

  // navigate to first tab
  useEffect(() => {
    // confirm that some tab routes are available
    if (!availableTabRoutes.length) {
      console.error("no routes for administration available");
      return;
    }

    // navigate to first available route unless a
    // path to one of them has already been set
    for (const tabRoute of availableTabRoutes) {
      // check if the current path matches the tab route
      if (location.pathname.includes(tabRoute.path)) {
        setActiveTabRoutePath(tabRoute.path);
        return;
      }
    }
    navigate(availableTabRoutes[0].path);
    setActiveTabRoutePath(availableTabRoutes[0].path);
  }, [location.pathname]);

  // do not render until active tab has been determined
  if (!activeTabRoutePath) {
    return null;
  }

  if (!viewConfiguration.Administration) {
    console.error("no administration tab routes");
    return null;
  }

  if (!myClient) {
    return (
      <Backdrop
        sx={() => ({ zIndex: (theme) => theme.zIndex.drawer + 1 })}
        open
      >
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Root>
      <Paper square className={classes.tabBar}>
        <Grid container>
          <Grid item>
            <Tabs
              value={activeTabRoutePath}
              onChange={(_, value) => {
                if (activeTabRoutePath === value) {
                  return;
                }
                setActiveTabRoutePath(value);
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {availableTabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              id="MCAdmin-addGroup-button"
              variant="outlined"
              children="Add Group"
              onClick={() => setCreateOrEditGroupDialogOpen(true)}
            />
          </Grid>
          {viewConfiguration.Administration.InviteClientButton && (
            <Grid item>
              <Button
                id="MCAdmin-inviteCompany-button"
                variant="contained"
                color="secondary"
                children="Invite Company"
                onClick={() => setInviteCompanyDialogOpen(true)}
              />
            </Grid>
          )}
          {viewConfiguration.Administration.InviteUserButton && (
            <Grid item>
              <Button
                id="MCAdmin-inviteUser-button"
                variant="contained"
                color="secondary"
                children="+ Invite User"
                onClick={() => setInviteUserDialogOpen(true)}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <div className={cx(classes.content, "meshScroll")}>
        <Router
          baseURL={"/administration/manage"}
          redirectPath={"/administration/manage/groups"}
          routes={availableTabRoutes}
        />
      </div>
      {createOrEditGroupDialogOpen && (
        <CreateOrEditGroupDialog
          closeDialog={() => setCreateOrEditGroupDialogOpen(false)}
          onCreateGroup={(createdGroup: Group) => {
            navigate(
              `/administration/manage/view/groups/explore?id=${createdGroup.id}`,
            );
          }}
        />
      )}
      {inviteUserDialogOpen && (
        <AddOrInviteUsersDialog
          groupID={new Client(myClient).ownerID}
          closeDialog={() => setInviteUserDialogOpen(false)}
        />
      )}
      {inviteCompanyDialogOpen && (
        <InviteCompanyDialog
          closeDialog={() => setInviteCompanyDialogOpen(false)}
        />
      )}
    </Root>
  );
};
