import React, { useCallback, useContext, useRef } from "react";
import { ErrorTranslator } from "./ErrorTranslator";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { TranslatedError } from "./TranslatedError";

interface ContextType {
  errorContextDefaultErrorFeedback: (
    e: unknown,
    extraLogInfoString?: string,
  ) => void;
  errorContextDefaultWarningFeedback: (
    e: unknown,
    extraLogInfoString?: string,
  ) => void;
  errorContextErrorTranslator: ErrorTranslator;
}

const Context = React.createContext({} as ContextType);

class BlankTranslatedErrorMessage extends Error {
  /** error type */
  public name = "Blank Translated Error Message";

  /** error message */
  public message = "";

  constructor(err: TranslatedError) {
    super();
    this.message = `translated error message is blank for error code: ${err.code}`;
  }
}

export function ErrorContext({ children }: { children?: React.ReactNode }) {
  const { current: errorTranslator } = useRef(new ErrorTranslator());
  const { enqueueSnackbar } = useSnackbar();
  const defaultErrorFeedback = useCallback(
    (e: unknown, extraLogInfoString?: string) => {
      const err = errorTranslator.translateError(e);
      let msg;
      if (err.message === "") {
        Sentry.captureException(new BlankTranslatedErrorMessage(err));
        msg = `Process failed with code: ${err.code}`;
      } else {
        msg = `${err.message} [${err.code}]`;
      }
      console.error(
        extraLogInfoString ? `${extraLogInfoString}: ${msg}` : msg,
        e,
      );
      enqueueSnackbar(msg, { variant: "error" });
    },
    [enqueueSnackbar, errorTranslator],
  );

  const defaultWarningFeedback = useCallback(
    (e: unknown, extraLogInfoString?: string) => {
      const err = errorTranslator.translateError(e);
      let msg;
      if (err.message === "") {
        Sentry.captureException(new BlankTranslatedErrorMessage(err));
        msg = `Process failed with code: ${err.code}`;
      } else {
        msg = `${err.message} [${err.code}]`;
      }
      console.warn(
        extraLogInfoString ? `${extraLogInfoString}: ${msg}` : msg,
        e,
      );
      enqueueSnackbar(msg, { variant: "warning" });
    },
    [enqueueSnackbar, errorTranslator],
  );

  return (
    <Context.Provider
      value={{
        errorContextErrorTranslator: errorTranslator,
        errorContextDefaultErrorFeedback: defaultErrorFeedback,
        errorContextDefaultWarningFeedback: defaultWarningFeedback,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useErrorContext = () => useContext(Context);
export { useErrorContext };
