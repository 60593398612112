import { HoldingEntryAccountLabel } from "@mesh/common-js/dist/financial/paymentRecipientDataPerUnitHeld_pb";

export const HoldingEntryAccountLabelPrettyString = (
  entry: HoldingEntryAccountLabel,
) => {
  switch (entry) {
    case HoldingEntryAccountLabel.BENEFICIAL_OWNER_ACCOUNT_HOLDING_ENTRY_ACCOUNT_LABEL:
      return "Beneficial Owner Account";
    case HoldingEntryAccountLabel.DIRECT_ORDER_HOLDING_ACCOUNT_HOLDING_ENTRY_ACCOUNT_LABEL:
      return "Direct Order";
    default:
      return "Unknown";
  }
};
