import config from "react-global-configuration";
import { Token } from "james/ledger";
import { Context } from "../security";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";
import { ETNStablecoin } from "./ETNStablecoin";
import { Amount } from "../ledger/Amount";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { RatingAgency } from "./RatingAgency";
import {
  InstrumentManagementFee,
  InstrumentManagementFeeFrequency,
} from "./InstrumentManagementFee";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { ETN } from "./ETN";
import { Document } from "james/document";

export interface CoordinateCreateNewETNStablecoinRequest {
  context: Context;
  ownerID: string;
  etnFields: {
    name: string;
    isin: string;
    exchange: string;
    exchangeCode: string;
    assetClass: AssetClassDEPRECATED | "";
    issueDate: string;
    maturityDate: string;
    issuerName: string;
    issuerRatingAgency: RatingAgency | "";
    issuerCreditRating: string;
    issuerManagementFee: InstrumentManagementFee;
    issuerManagementFeeFrequency: InstrumentManagementFeeFrequency;
    supportingDocuments: Document[];
    investorProfile: InvestorProfile | "";
    investorProfileDescription: string;
    riskProfile: InstrumentRiskProfile | "";
    riskProfileDescription: string;
    holdings: Holding[];
    sectorAllocations: SectorAllocation[];
    countryAllocations: CountryAllocation[];
    annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
  };
  stablecoinFields: {
    issueDate: string;
    maturityDate: string;
    maximumUnits: Amount;
    valuationToken: Token;
  };
}

export interface CoordinateCreateNewETNStablecoinResponse {
  etn: ETN;
  etnStablecoin: ETNStablecoin;
}

export const ETNStablecoinCreatorCoordinator = {
  serviceProvider: "financial-ETNStablecoinCreatorCoordinator",
  async CoordinateCreateNewETNStablecoin(
    request: CoordinateCreateNewETNStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateCreateNewETNStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${ETNStablecoinCreatorCoordinator.serviceProvider}.CoordinateCreateNewETNStablecoin`,
        request,
      },
      opts,
    );
    return {
      etn: new ETN(response.etn),
      etnStablecoin: new ETNStablecoin(response.etnStablecoin),
    };
  },
};
