import React from "react";
import { ShiftingPeriod } from "@mesh/common-js/dist/financial/shiftingPeriod_pb";
import { Autocomplete, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import {
  allBusinessDayConventions,
  allCalendars,
  businessDayConventionToString,
  calendarToString,
} from "@mesh/common-js/dist/financial";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ValidationResult } from "common/validation";
import { TextField } from "components/FormFields";
import { PeriodForm } from "../PeriodForm";
import { Period } from "@mesh/common-js/dist/financial/period_pb";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
dayjs.extend(utc);
dayjs.extend(timezone);

export type ShiftingPeriodFormProps = {
  disabled: boolean;
  readOnly: boolean;
  period: ShiftingPeriod;
  onChange: (updatedShiftingPeriod: ShiftingPeriod) => void;
  formDataValidationResult: ValidationResult;
  validationResultFieldPrefix: string;
  labelPrefix?: string;
};

export const ShiftingPeriodForm = (props: ShiftingPeriodFormProps) => {
  const getIDPrefx = (field: string) => {
    return `${props.validationResultFieldPrefix}-${field}`;
  };
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${props.validationResultFieldPrefix}-${field}`
    ];
  };

  return (
    <>
      <PeriodForm
        disabled={props.disabled}
        readOnly={props.readOnly}
        period={props.period.getPeriod() ?? new Period()}
        onChange={(updatedPeriod: Period) =>
          props.onChange(props.period.setPeriod(updatedPeriod))
        }
        formDataValidationResult={props.formDataValidationResult}
        validationResultFieldPrefix={`${props.validationResultFieldPrefix}-shiftingPeriod`}
        labelPrefix={props.labelPrefix}
      />
      <Autocomplete
        multiple
        fullWidth
        disabled={props.disabled}
        readOnly={props.readOnly}
        sx={{ maxWidth: 320 }}
        id={getIDPrefx("calendars-selectField")}
        options={allCalendars.filter((c) => c !== Calendar.UNDEFINED_CALENDAR)}
        getOptionLabel={(option: Calendar) => calendarToString(option)}
        filterSelectedOptions
        ChipProps={{ size: "small" }}
        value={props.period.getCalendarsList()}
        onChange={(_, value: Calendar[]) =>
          props.onChange(props.period.setCalendarsList(value))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={props.disabled}
            readOnly={props.readOnly}
            label={
              (props.labelPrefix ? `${props.labelPrefix} ` : "") +
              "Period Calendars"
            }
            placeholder="Select..."
            error={!!getFieldValidation("calendars")}
            helperText={getFieldValidation("calendars")}
          />
        )}
      />
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id={getIDPrefx("businessDayConvention-selectField")}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={
          (props.labelPrefix ? `${props.labelPrefix} ` : "") +
          "Period Business Day Convention"
        }
        select
        value={props.period.getBusinessdayconvention()}
        onChange={(e) =>
          props.onChange(
            props.period.setBusinessdayconvention(Number(e.target.value)),
          )
        }
        error={!!getFieldValidation("businessDayConvention")}
        helperText={getFieldValidation("businessDayConvention")}
      >
        {allBusinessDayConventions.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {businessDayConventionToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};
