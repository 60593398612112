import { ClaimsType } from "james/security/claims";

export class InviteUser {
  public type: ClaimsType | "" = "";

  public invitingUserID = "";

  public invitedUserEmailAddress = "";

  public expirationTime = 0;

  public roleIDs: string[] = [];

  public clientID = "";

  public clientName = "";

  public ownerID = "";

  public firstName = "";

  public lastName = "";

  constructor(inviteUser?: InviteUser) {
    if (!inviteUser) {
      return;
    }
    this.type = inviteUser.type;
    this.invitingUserID = inviteUser.invitingUserID;
    this.invitedUserEmailAddress = inviteUser.invitedUserEmailAddress;
    this.expirationTime = inviteUser.expirationTime;
    this.roleIDs = inviteUser.roleIDs.map((id) => id);
    this.clientID = inviteUser.clientID;
    this.clientName = inviteUser.clientName;
    this.ownerID = inviteUser.ownerID;
    this.firstName = inviteUser.firstName;
    this.lastName = inviteUser.lastName;
  }

  public static NewFromJWT(jwt: string): InviteUser {
    const claimsPayloadData = jwt.substring(
      jwt.indexOf(".") + 1,
      jwt.lastIndexOf("."),
    );
    const payloadString = atob(claimsPayloadData);
    const tokenPOJO = JSON.parse(payloadString);

    return new InviteUser(tokenPOJO);
  }
}
