import React from "react";
import { Box, Button, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const DirectOwnershipBanner = () => {
  return (
    <Box
      id="marketplace_banner_direct_ownership_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        backgroundPosition: "100%",
        display: "flex",
        justifyContent: "flex-end",
        pt: "57px",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "direct ownership",
        component_driver: "showcase",
      })}
      data-testid="marketplace_banner_direct_ownership_banner"
    >
      <Box
        sx={{
          maxWidth: 585,
          mr: {
            lg: "118px",
            md: "80px",
            sm: "60px",
          },
          ml: 2,
        }}
      >
        <Typography
          fontSize={28}
          letterSpacing={0}
          textAlign={"end"}
          lineHeight={"32px"}
          mb={2}
          sx={(theme) => ({
            "& .red": {
              color: theme.palette.primary.main,
            },
          })}
        >
          Experience the power of{" "}
          <span className="red">direct&nbsp;ownership: </span>
          secure, low-cost and lightning-fast.
        </Typography>
        <Button
          data-link-info={JSON.stringify({
            content_interaction_id: "hero-banner-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "explore",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
          sx={{ display: "none" }}
          variant="contained"
          color="primary"
        >
          Explore
        </Button>
      </Box>
    </Box>
  );
};
