export class Money {
  public amount = 0;

  public currencyCode = "";

  constructor(money?: Money) {
    if (!money) {
      return;
    }
    this.amount = money.amount;
    this.currencyCode = money.currencyCode;
  }
}
