import React from "react";
import { Box, Typography } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { LoadingEllipses } from "components/Animations/LoadingEllipses";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import background from "assets/images/background/background.png";

export const UserLoginLoader = () => {
  const { Banner } = useAppNoticeContext();

  return (
    <div>
      {Banner}
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "fixed",
          alignItems: "center",
          [theme.breakpoints.up("sm")]: {
            backgroundImage: `url(${background})`,
          },
          backgroundSize: "cover",
          height: "100vh",
          overflowY: "unset",
          width: "100%",
          gap: theme.spacing(3),
        })}
      >
        <img alt="" width={100} src={meshMiniLogo} />
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: theme.spacing(2),
          })}
        >
          <Typography fontSize="16px">Authenticating</Typography>
          <LoadingEllipses />
        </Box>
      </Box>
    </div>
  );
};
