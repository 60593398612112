import React, { forwardRef, ReactNode, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { SnackbarContent, SnackbarKey, useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/material";

const PREFIX = "WarningWithActions";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
};

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "@media (min-width:600px)": {
      minWidth: "344px !important",
    },
  },

  [`& .${classes.card}`]: {
    backgroundColor: theme.palette.warning.dark,
    width: "100%",
    borderRadius: theme.spacing(0.5),
  },
}));

export interface CustomSnackbarProps {
  id: SnackbarKey;
  message: ReactNode;
  actions?: ReactNode;
  inLine?: boolean;
}

export const WarningWithActions = forwardRef<
  HTMLDivElement,
  CustomSnackbarProps
>(({ ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <StyledSnackbarContent ref={ref} className={classes.root}>
      <Box className={classes.card}>
        <Box
          sx={(theme) => ({
            flexDirection: "row",
            display: "flex",
            padding: theme.spacing(1.5, 2, 1.5, 2.5),
            alignItems: "center",
          })}
        >
          <IconButton size={"small"}>
            <WarningAmberIcon
              fontSize={"small"}
              sx={(theme) => ({
                color: theme.palette.secondary.contrastText,
              })}
            />
          </IconButton>
          <Typography
            sx={(theme) => ({
              paddingLeft: 1,
              width: "100%",
              color: theme.palette.secondary.contrastText,
            })}
            variant="body2"
          >
            {props.message}
          </Typography>
          {props.inLine && props.actions}
          {props.inLine && (
            <IconButton size="small" onClick={handleCloseSnackbar}>
              <CloseIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText,
                })}
                fontSize="small"
              />
            </IconButton>
          )}
          {!props.inLine && (
            <IconButton size="small" onClick={handleCloseSnackbar}>
              <CloseIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText,
                })}
                fontSize="small"
              />
            </IconButton>
          )}
        </Box>
        {!props.inLine && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            sx={(theme) => ({
              padding: !props.inLine ? theme.spacing(0, 2.5, 1, 2) : 0,
            })}
          >
            {props.actions}
          </Box>
        )}
      </Box>
    </StyledSnackbarContent>
  );
});

WarningWithActions.displayName = "WarningWithActions";
