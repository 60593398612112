import {
  allExternalParticipantInformationCategorys,
  smartInstrumentAllocationExternalParticipantInformationCategoryToString,
  smartInstrumentAttributeTypeToString,
} from "@mesh/common-js/dist/financial";
import {
  ExternalParticipantsInformationSmartInstrumentAttribute,
  ExternalParticipantInformationCategory,
  SmartInstrumentExternalParticipantInformationEntry,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeExternalParticipantsInformation_pb";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  MenuItem,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, Box, useTheme } from "@mui/system";
import React from "react";
import {
  Close as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import { ValidationResult } from "common/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";

export type ExternalParticipantAttributeFormProps = {
  externalParticipantsInformationAttribute: ExternalParticipantsInformationSmartInstrumentAttribute;
  onChange: (
    updatedExternalParticipantsInformationAttribute: ExternalParticipantsInformationSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const ExternalParticipantAttributeForm = (
  props: ExternalParticipantAttributeFormProps,
) => {
  const theme = useTheme();
  const entries =
    props.externalParticipantsInformationAttribute.getEntriesList();

  const fieldValidationResult = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.EXTERNAL_PARTICIPANTS_INFORMATION_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ];
  };
  const categoryHasError: (
    category: ExternalParticipantInformationCategory,
  ) => boolean = (category: ExternalParticipantInformationCategory) => {
    for (const field in props.formDataValidationResult.fieldValidations) {
      if (field.includes(`entries-category-${category}`)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box sx={{ marginTop: 0.6, width: 450 }}>
      {!!fieldValidationResult("entries") && (
        <Typography color={"error"} variant="body2">
          {fieldValidationResult("entries")}
        </Typography>
      )}
      <Box>
        {entries.map((thisEntry, entryIdx) => (
          <>
            <Accordion
              key={`sector-${thisEntry.getCategory()}-${entryIdx}`}
              disableGutters
              elevation={0}
              square
              sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`,
                "&::before": {
                  display: "none",
                },
              })}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "16px" }} />}
                sx={(theme) => ({
                  padding: theme.spacing(0, 0.5, 0, 0.5),
                  backgroundColor: "rgba(0, 0, 0, .03)",
                  minHeight: "30px",
                  flexDirection: "row-reverse",
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(180deg)",
                  },
                  "& .MuiAccordionSummary-content": {
                    margin: theme.spacing(0, 0.5, 0, 0.5),
                  },
                  ...theme.applyStyles("dark", {
                    backgroundColor: categoryHasError(thisEntry.getCategory())
                      ? alpha(theme.palette.error.main, 0.1)
                      : "rgba(255, 255, 255, .05)",
                  }),
                })}
              >
                <Box
                  sx={() => ({
                    flexGrow: 1,
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",
                  })}
                >
                  <Typography
                    variant="body2"
                    sx={(theme) => ({
                      color: categoryHasError(thisEntry.getCategory())
                        ? theme.palette.error.main
                        : theme.palette.text.primary,
                    })}
                  >
                    {smartInstrumentAllocationExternalParticipantInformationCategoryToString(
                      thisEntry.getCategory(),
                    )}
                  </Typography>
                  {!props.readOnly && (
                    <Tooltip placement="top" title={"Remove"}>
                      <IconButton
                        size={"small"}
                        id={`${thisEntry.getCategory()}-${entryIdx}-sectorAllocationsAttributeForm-sector-remove-iconButton`}
                        onClick={() =>
                          props.onChange(
                            props.externalParticipantsInformationAttribute.setEntriesList(
                              entries.filter(
                                (entry) =>
                                  entry.getCategory() !==
                                  thisEntry.getCategory(),
                              ),
                            ),
                          )
                        }
                        sx={{
                          width: "24px",
                          height: "24px",
                          padding: "4px",
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!!fieldValidationResult(`${thisEntry.getCategory()}`) && (
                    <Typography
                      sx={{ marginTop: -1 }}
                      color={"error"}
                      variant="body2"
                    >
                      {fieldValidationResult(`${thisEntry.getCategory()}`)}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={(theme) => ({
                  padding: theme.spacing(0.5),
                  borderTop: "1px solid rgba(0, 0, 0, .125)",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: theme.spacing(1),
                })}
              >
                <TextField
                  id={`participant-${thisEntry.getCategory()}-name-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Name"}
                  value={
                    props.readOnly
                      ? thisEntry.getName()
                        ? thisEntry.getName()
                        : "-"
                      : thisEntry.getName()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setName(e.target.value);
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-name`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-name`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-address-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Address"}
                  value={
                    props.readOnly
                      ? thisEntry.getAddress()
                        ? thisEntry.getAddress()
                        : "-"
                      : thisEntry.getAddress()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setAddress(e.target.value);
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-address`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-address`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-contactNumber-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Contact Number"}
                  value={
                    props.readOnly
                      ? thisEntry.getContactnumber()
                        ? thisEntry.getContactnumber()
                        : "-"
                      : thisEntry.getContactnumber()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setContactnumber(
                      e.target.value,
                    );
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-contactNumber`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-contactNumber`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-email-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Email"}
                  value={
                    props.readOnly
                      ? thisEntry.getEmail()
                        ? thisEntry.getEmail()
                        : "-"
                      : thisEntry.getEmail()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setEmail(e.target.value);
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-email`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-email`,
                  )}
                />
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.divider}`,
                    padding: theme.spacing(0.5),
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(1),
                  })}
                >
                  <Typography
                    variant="caption"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    Description
                  </Typography>
                  {(thisEntry.getDescriptionList() ?? ([] as string[])).map(
                    (paragraph: string, paragraphIdx: number) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {props.readOnly ? (
                          <Typography
                            sx={{ width: 400 }}
                            variant="body1"
                            color="textSecondary"
                          >
                            {props.readOnly
                              ? paragraph
                                ? paragraph
                                : "-"
                              : paragraph}
                          </Typography>
                        ) : (
                          <Box>
                            <TextareaAutosize
                              minRows={5}
                              maxRows={5}
                              value={paragraph}
                              onChange={(e) => {
                                // prepare truncated value
                                let newValue: string = e.target.value;
                                if (newValue.length >= 450) {
                                  newValue = newValue.slice(0, 450);
                                }

                                // update description
                                const descriptionList =
                                  thisEntry.getDescriptionList();
                                descriptionList[paragraphIdx] = newValue;
                                entries[entryIdx] =
                                  thisEntry.setDescriptionList([
                                    ...descriptionList,
                                  ]);
                                props.onChange(
                                  props.externalParticipantsInformationAttribute.setEntriesList(
                                    entries,
                                  ),
                                );
                              }}
                              placeholder="Add a paragraph..."
                              style={{
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.background.paper,
                                width: 400,
                                maxWidth: 400,
                              }}
                            />
                            <Typography variant="body2" color="textSecondary">
                              {450 - paragraph.length} Characters Left
                            </Typography>
                          </Box>
                        )}
                        {!props.readOnly && (
                          <Tooltip placement="top" title={"Remove"}>
                            <IconButton
                              size={"small"}
                              id={`participant-${thisEntry.getCategory()}-description-paragraph-${paragraphIdx}-removeButton`}
                              onClick={() => {
                                entries[entryIdx] =
                                  thisEntry.setDescriptionList(
                                    thisEntry
                                      .getDescriptionList()
                                      .filter(
                                        (_, pIdx) => pIdx !== paragraphIdx,
                                      ),
                                  );
                                props.onChange(
                                  props.externalParticipantsInformationAttribute.setEntriesList(
                                    entries,
                                  ),
                                );
                              }}
                              sx={{
                                width: "24px",
                                height: "24px",
                                padding: "4px",
                              }}
                            >
                              <DeleteIcon sx={{ fontSize: "16px" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    ),
                  )}
                  {!props.readOnly && (
                    <Tooltip
                      title={
                        thisEntry.getDescriptionList().length === 1
                          ? "only 1 paragraph allowed atm."
                          : undefined
                      }
                    >
                      <span>
                        <Button
                          variant="outlined"
                          disabled={thisEntry.getDescriptionList().length === 1}
                          onClick={() => {
                            entries[entryIdx] = thisEntry.setDescriptionList([
                              ...(thisEntry.getDescriptionList() ??
                                ([] as string[])),
                              "",
                            ]);
                            props.onChange(
                              props.externalParticipantsInformationAttribute.setEntriesList(
                                entries,
                              ),
                            );
                          }}
                        >
                          Add paragraph
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Box>
                <TextField
                  id={`participant-${thisEntry.getCategory()}-logo-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Logo"}
                  value={
                    props.readOnly
                      ? thisEntry.getLogo()
                        ? thisEntry.getLogo()
                        : "-"
                      : thisEntry.getLogo()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setLogo(e.target.value);
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-logo`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-logo`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-webLink-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Web Link"}
                  value={
                    props.readOnly
                      ? thisEntry.getWeblink()
                        ? thisEntry.getWeblink()
                        : "-"
                      : thisEntry.getWeblink()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setWeblink(e.target.value);
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-webLink`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-webLink`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-linkedInLink-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"LinkedIn Link"}
                  value={
                    props.readOnly
                      ? thisEntry.getLinkedinlink()
                        ? thisEntry.getLinkedinlink()
                        : "-"
                      : thisEntry.getLinkedinlink()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setLinkedinlink(
                      e.target.value,
                    );
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-linkedInLink`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-linkedInLink`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-twitterLink-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Twitter Link"}
                  value={
                    props.readOnly
                      ? thisEntry.getTwitterlink()
                        ? thisEntry.getTwitterlink()
                        : "-"
                      : thisEntry.getTwitterlink()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setTwitterlink(
                      e.target.value,
                    );
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-twitterLink`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-twitterLink`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-instagramLink-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Instagram Link"}
                  value={
                    props.readOnly
                      ? thisEntry.getInstagramlink()
                        ? thisEntry.getInstagramlink()
                        : "-"
                      : thisEntry.getInstagramlink()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setInstagramlink(
                      e.target.value,
                    );
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-instagramLink`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-instagramLink`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-facebookLink-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"Facebook Link"}
                  value={
                    props.readOnly
                      ? thisEntry.getFacebooklink()
                        ? thisEntry.getFacebooklink()
                        : "-"
                      : thisEntry.getFacebooklink()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setFacebooklink(
                      e.target.value,
                    );
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-facebookLink`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-facebookLink`,
                  )}
                />
                <TextField
                  id={`participant-${thisEntry.getCategory()}-youTubeLink-textField`}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  label={"YouTube Link"}
                  value={
                    props.readOnly
                      ? thisEntry.getYoutubelink()
                        ? thisEntry.getYoutubelink()
                        : "-"
                      : thisEntry.getYoutubelink()
                  }
                  onChange={(e) => {
                    entries[entryIdx] = thisEntry.setYoutubelink(
                      e.target.value,
                    );
                    props.onChange(
                      props.externalParticipantsInformationAttribute.setEntriesList(
                        entries,
                      ),
                    );
                  }}
                  error={
                    !!fieldValidationResult(
                      `entries-category-${thisEntry.getCategory()}-youTubeLink`,
                    )
                  }
                  helperText={fieldValidationResult(
                    `entries-category-${thisEntry.getCategory()}-youTubeLink`,
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </>
        ))}

        {/* Render the "add new" control row at the end if not in read only mode */}
        {!props.readOnly && (
          <Box sx={{ marginTop: 1 }}>
            <TextField
              size="small"
              fullWidth
              id={"sectorAllocationsAttributeForm-addASector-selectField"}
              disabled={props.disabled}
              select
              label="Add a Participant"
              value={
                ExternalParticipantInformationCategory.UNDEFINED_EXTERNAL_PARTICIPANT_INFORMATION_CATEGORY
              }
              onChange={(e) => {
                props.onChange(
                  props.externalParticipantsInformationAttribute.setEntriesList(
                    [
                      ...entries,
                      new SmartInstrumentExternalParticipantInformationEntry().setCategory(
                        Number(
                          e.target.value,
                        ) as ExternalParticipantInformationCategory,
                      ),
                    ],
                  ),
                );
              }}
            >
              {allExternalParticipantInformationCategorys
                .filter(
                  // only show non-used values
                  (sector) =>
                    !entries.find(
                      (existingEntry) => existingEntry.getCategory() === sector,
                    ),
                )
                .map((v) => {
                  return (
                    <MenuItem key={v} value={v}>
                      {smartInstrumentAllocationExternalParticipantInformationCategoryToString(
                        v,
                      )}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Box>
        )}
      </Box>
    </Box>
  );
};
