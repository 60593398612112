import React from "react";
import { Period } from "@mesh/common-js/dist/financial/period_pb";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { allTimeUnits, timeUnitToString } from "@mesh/common-js/dist/financial";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ValidationResult } from "common/validation";
import { TimeUnit } from "@mesh/common-js/dist/financial/timeUnit_pb";
import { TextField } from "components/FormFields";
dayjs.extend(utc);
dayjs.extend(timezone);

export type PeriodFormProps = {
  disabled: boolean;
  readOnly: boolean;
  period: Period;
  onChange: (updatedPeriod: Period) => void;
  formDataValidationResult: ValidationResult;
  validationResultFieldPrefix: string;
  labelPrefix?: string;
};

export const PeriodForm = (props: PeriodFormProps) => {
  const getIDPrefx = (field: string) => {
    return `${props.validationResultFieldPrefix}-${field}`;
  };
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${props.validationResultFieldPrefix}-${field}`
    ];
  };

  return (
    <>
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id={getIDPrefx("count-numField")}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={
          (props.labelPrefix ? `${props.labelPrefix} ` : "") + "Period Count"
        }
        InputProps={{ type: "number" }}
        value={props.period.getCount() ?? 0}
        onChange={(e) =>
          props.onChange(props.period.setCount(Number(Number(e.target.value))))
        }
        error={!!getFieldValidation("periodCount")}
        helperText={getFieldValidation("periodCount")}
      />
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id={getIDPrefx("timeUnit-selectField")}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={
          (props.labelPrefix ? `${props.labelPrefix} ` : "") +
          "Period Time Unit"
        }
        select
        value={props.period.getTimeunit()}
        onChange={(e) =>
          props.onChange(props.period.setTimeunit(Number(e.target.value)))
        }
        error={!!getFieldValidation("timeUnit")}
        helperText={getFieldValidation("timeUnit")}
      >
        {allTimeUnits.map((v: TimeUnit) => {
          return (
            <MenuItem key={v} value={v}>
              {timeUnitToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};
