import { Address } from "james/location";

// AssetParticipants class for handling both core and underlying participants
export class AssetParticipants {
  public instrumentParticipants: InstrumentParticipants =
    new InstrumentParticipants();
  public linkedParticipants?: LinkedParticipants = undefined;

  constructor(p?: AssetParticipants) {
    if (!p) {
      return;
    }
    this.instrumentParticipants = new InstrumentParticipants(
      p.instrumentParticipants,
    );
    if (p.linkedParticipants) {
      this.linkedParticipants = new LinkedParticipants(p.linkedParticipants);
    }
  }
}

// CoreParticipants class for managing the core participants
export class InstrumentParticipants {
  public issuer: ParticipantProfile = new ParticipantProfile();
  public underwriters: UnderwriterProfile[] = [];
  public guarantor?: ParticipantProfile = undefined;
  public externalParticipants: ParticipantProfile[] = [];

  constructor(p?: InstrumentParticipants) {
    if (!p) {
      return;
    }
    this.issuer = new ParticipantProfile(p.issuer);
    this.underwriters = p.underwriters.map((u) => new UnderwriterProfile(u));
    if (p.guarantor) {
      this.guarantor = new ParticipantProfile(p.guarantor);
    }
    if (p.externalParticipants) {
      this.externalParticipants = p.externalParticipants.map(
        (e) => new ParticipantProfile(e),
      );
    }
  }
}

// LinkedParticipants class for managing the participants of the underlying asset
export class LinkedParticipants {
  public otherLinkedParticipants: ParticipantProfile[] = [];
  public issuer: ParticipantProfile = new ParticipantProfile();

  constructor(p?: LinkedParticipants) {
    if (!p) {
      return;
    }
    this.issuer = new ParticipantProfile(p.issuer);
    if (p.otherLinkedParticipants) {
      this.otherLinkedParticipants = p.otherLinkedParticipants.map(
        (e) => new ParticipantProfile(e),
      );
    }
  }
}

// UnderwriterProfile class for handling underwriter details
export class UnderwriterProfile {
  public name: string = "";
  public address: Address = new Address();
  public contactNumber: string = "";
  public email: string = "";
  public description: string[] = [];
  public percentageUnderwritten: string = "";
  public logoUrl: string = "";
  public webLink: string = "";
  public twitterLink: string = "";
  public facebookLink: string = "";
  public instagramLink: string = "";
  public youtubeLink: string = "";
  public linkedInLink: string = "";

  constructor(p?: Partial<UnderwriterProfile>) {
    if (p) {
      this.name = p.name || "";
      this.address = p.address ? new Address(p.address) : new Address();
      this.contactNumber = p.contactNumber || "";
      this.email = p.email || "";
      this.description = p.description || [];
      this.percentageUnderwritten = p.percentageUnderwritten || "";
      this.logoUrl = p.logoUrl || "";
      this.webLink = p.webLink || "";
      this.twitterLink = p.twitterLink || "";
      this.facebookLink = p.facebookLink || "";
      this.instagramLink = p.instagramLink || "";
      this.youtubeLink = p.youtubeLink || "";
      this.linkedInLink = p.linkedInLink || "";
    }
  }
}

export enum ExternalParticipantProfileCategory {
  Undefined = "Undefined",
  Guarantor = "Guarantor",
  Advisor = "Advisor",
  Issuer = "Issuer",
  Agent = "Agent",
  PayingAgent = "Paying Agent",
  TransferAgent = "Transfer Agent",
  CalculationAgent = "Calculation Agent",
  Custodian = "Custodian",
  MarketMaker = "Market Maker",
  Exchange = "Exchange",
  Broker = "Broker",
  Manager = "Manager",
  InvestmentManager = "Investment Manager",
  PortfolioManager = "Portfolio Manager",
  PortfolioAdvisor = "Portfolio Advisor",
  ReferencePortfolioAdvisor = "Reference Portfolio Advisor",
  DelegatedReferencePortfoliAdvisor = "Delegated Reference Portfolio Advisor",
}

// ExternalParticipantProfile class for handling other participants
export class ParticipantProfile {
  public category: ExternalParticipantProfileCategory =
    ExternalParticipantProfileCategory.Undefined;
  public name: string = "";
  public address: string = "";
  public contactNumber: string = "";
  public email: string = "";
  public description: string[] = [];
  public logoUrl: string = "";
  public webLink: string = "";
  public twitterLink: string = "";
  public facebookLink: string = "";
  public instagramLink: string = "";
  public youtubeLink: string = "";
  public linkedInLink: string = "";

  constructor(p?: Partial<ParticipantProfile>) {
    if (!p) {
      return;
    }
    this.category = p.category || ExternalParticipantProfileCategory.Undefined;
    this.name = p.name || "";
    this.address = p.address || "";
    this.contactNumber = p.contactNumber || "";
    this.email = p.email || "";
    this.description = p.description || [];
    this.logoUrl = p.logoUrl || "";
    this.webLink = p.webLink || "";
    this.twitterLink = p.twitterLink || "";
    this.facebookLink = p.facebookLink || "";
    this.instagramLink = p.instagramLink || "";
    this.youtubeLink = p.youtubeLink || "";
    this.linkedInLink = p.linkedInLink || "";
  }
}
