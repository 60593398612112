import { Period } from "@mesh/common-js/dist/financial/period_pb";
import { TimeUnit } from "@mesh/common-js/dist/financial/timeUnit_pb";
import { ValidationResult } from "common/validation";

export function validatePeriod(
  period: Period,
  fieldValidationsPrefix?: string,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };
  const notValid = (field: string, helperText: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${fieldValidationsPrefix ?? ""}-${field}`
    ] = helperText;
  };

  if (period.getCount() < 0) {
    notValid("periodCount", "Must be >= 0");
  }

  if (period.getTimeunit() === TimeUnit.UNDEFINED_TIME_UNIT) {
    notValid("timeUnit", "Must be set");
  }

  return validationResult;
}
