import { IdentifierType } from ".";

export interface CategoryOwnerIDNetworkIdentifierType {
  type: IdentifierType;
  ownerID: string;
  category: string;
  network: string;
}

function CategoryOwnerIDNetworkIdentifier(
  category: string,
  ownerID: string,
  network: string,
): CategoryOwnerIDNetworkIdentifierType {
  return {
    type: IdentifierType.CategoryOwnerIDNetworkTypeName,
    category,
    ownerID,
    network,
  };
}

export { CategoryOwnerIDNetworkIdentifier };
