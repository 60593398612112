import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import {
  ConnectedIndividual,
  ConnectionType,
  IdentificationType,
} from "james/legal";
import React, { useEffect } from "react";
import camelCase from "lodash/camelCase";
import { Clear } from "@mui/icons-material";
import cx from "classnames";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
import { useConnectedIndividualDialogStore } from "./store";
import { countries } from "james/country";
import { CountryOption } from "james/country/countries";
import { Address } from "james/location";
import Add from "@mui/icons-material/Add";
import { DateField } from "components/FormFields";
import dayjs from "dayjs";
import { dateIsValid } from "utilities/date";

const PREFIX = "ConnectedIndividualDialog";

const classes = {
  dialogPaperOverride: `${PREFIX}-dialogPaperOverride`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogContent: `${PREFIX}-dialogContent`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  sectionWithColumns1Gap: `${PREFIX}-sectionWithColumns1Gap`,
  sectionHelperText: `${PREFIX}-sectionHelperText`,
  sectionWith2EqColumns2Gap: `${PREFIX}-sectionWith2EqColumns2Gap`,
  postalAddressTitleLayout: `${PREFIX}-postalAddressTitleLayout`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogPaperOverride}`]: {
    backgroundColor: theme.palette.custom.midnight,
    width: "880px",
  },

  [`& .${classes.dialogTitle}`]: {
    padding: theme.spacing(2, 3),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContent}`]: {
    padding: `${theme.spacing(2, 3)} !important`,
    display: "grid",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(2.5),
    overflowY: "scroll",
  },

  [`& .${classes.sectionHeading}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.sectionWithColumns1Gap}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },

  [`& .${classes.sectionHelperText}`]: {
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.sectionWith2EqColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },

  [`& .${classes.postalAddressTitleLayout}`]: {
    margin: theme.spacing(-0.8, 0),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    paddingBottom: "24px",
  },
}));

interface ConnectedIndividualProps {
  open: boolean;
  onClose: () => void;
  connectedIndividual?: ConnectedIndividual;
  onAdd: (c: ConnectedIndividual) => void;
  onUpdate: (c: ConnectedIndividual) => void;
}

export const ConnectedIndividualDialog: React.FC<ConnectedIndividualProps> = (
  props: ConnectedIndividualProps,
) => {
  const store = useConnectedIndividualDialogStore();

  useEffect(() => {
    if (props.connectedIndividual) {
      store.setStateFrom(props.connectedIndividual);
    }
  }, [props.connectedIndividual]);

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaperOverride }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">Connected Individual</Typography>
        <div className={classes.sectionWithColumns1Gap}>
          {!props.connectedIndividual && (
            <Button
              id="connectedIndividual-addIndividual"
              variant="contained"
              color="secondary"
              onClick={() => {
                props.onAdd(store.getConnectedIndividualObject());
              }}
            >
              <Add />
              Individual
            </Button>
          )}
          {props.connectedIndividual && (
            <Button
              onClick={() => {
                props.onUpdate(store.getConnectedIndividualObject());
              }}
              variant="contained"
              color="secondary"
            >
              Update
            </Button>
          )}

          <IconButton
            onClick={props.onClose}
            id="connectedIndividual-closeDialog"
            size="small"
          >
            <Icon>
              <Clear />
            </Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={cx(classes.dialogContent, "meshScroll")}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "32px",
          }}
        >
          <Box>
            <TextField
              id="connectedIndividual-connectionToCompany"
              margin="dense"
              select
              variant="outlined"
              fullWidth
              value={store.connectionType}
              onChange={(e) =>
                store.setConnectionType(e.target.value as ConnectionType)
              }
              label="Connection To Company"
            >
              <MenuItem
                id={`connectedIndividual-connectionToCompany-${ConnectionType.ManagerConnectionType}`}
                value={ConnectionType.ManagerConnectionType}
              >
                {ConnectionType.ManagerConnectionType}
              </MenuItem>
              <MenuItem
                id={`connectedIndividual-connectionToCompany-${ConnectionType.ShareholderConnectionType}`}
                value={ConnectionType.ShareholderConnectionType}
              >
                {ConnectionType.ShareholderConnectionType}
              </MenuItem>
              <MenuItem
                id={`connectedIndividual-connectionToCompany-${ConnectionType.AgentConnectionType}`}
                value={ConnectionType.AgentConnectionType}
              >
                {ConnectionType.AgentConnectionType}
              </MenuItem>
              <MenuItem
                id={`connectedIndividual-connectionToCompany-${ConnectionType.SoleProprietorConnectionType}`}
                value={ConnectionType.SoleProprietorConnectionType}
              >
                {ConnectionType.SoleProprietorConnectionType}
              </MenuItem>
              <MenuItem
                id={`connectedIndividual-connectionToCompany-${ConnectionType.PartnerConnectionType}`}
                value={ConnectionType.PartnerConnectionType}
              >
                {ConnectionType.PartnerConnectionType}
              </MenuItem>
            </TextField>
          </Box>
          <Box></Box>
        </Box>
        {/* ---- Personal Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Personal Details"
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "32px",
            }}
          >
            <Box>
              <TextField
                id="connectedIndividual-personalDetails-firstName"
                margin="dense"
                variant="outlined"
                fullWidth
                label="First Name"
                value={store.firstName}
                onChange={(e) => store.setFirstName(e.target.value)}
              />
              <TextField
                id="connectedIndividual-personalDetails-lastName"
                margin="dense"
                variant="outlined"
                fullWidth
                label="Last Name"
                value={store.lastName}
                onChange={(e) => store.setLastName(e.target.value)}
              />
              <TextField
                id="connectedIndividual-personalDetails-middleName"
                margin="dense"
                variant="outlined"
                fullWidth
                label="Middle Names(s)"
                value={store.middleName}
                onChange={(e) => store.setMiddleName(e.target.value)}
              />
              <DateField
                id="connectedIndividual-personalDetails-dateOfBirth"
                label="Date of Birth"
                sx={{
                  width: "100%",
                }}
                value={store.dateOfBirth}
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    return;
                  }
                  store.setDateOfBirth(dayjs(newValue).format());
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    placeholder="MM/DD/YYYY"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="complianceDashboard-bankingDetail-country-autocomplete"
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                }}
                options={countries}
                getOptionLabel={(option) => (option ? option.label : "")}
                onChange={(__: object, country: CountryOption | null) => {
                  if (country) {
                    store.setNationality(country.value);
                  }
                }}
                value={(() => {
                  const c = countries.find(
                    (countryOption) =>
                      countryOption.value === store.nationality,
                  );
                  return c || null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Nationality"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    fullWidth
                  />
                )}
              />

              <TextField
                id="connectedIndividual-personalDetails-identificationType"
                select
                margin="dense"
                variant="outlined"
                fullWidth
                label="Identification Type"
                value={store.identificationType}
                onChange={(e) =>
                  store.setIdentificationType(
                    e.target.value as IdentificationType,
                  )
                }
              >
                <MenuItem
                  id={`connectedIndividual-personalDetails-identificationType-${camelCase(
                    IdentificationType.SouthAfricanIDIdentificationType,
                  )}`}
                  value={IdentificationType.SouthAfricanIDIdentificationType}
                >
                  {IdentificationType.SouthAfricanIDIdentificationType}
                </MenuItem>
                <MenuItem
                  id={`connectedIndividual-personalDetails-identificationType-${camelCase(
                    IdentificationType.PassportIdentificationType,
                  )}`}
                  value={IdentificationType.PassportIdentificationType}
                >
                  {IdentificationType.PassportIdentificationType}
                </MenuItem>
              </TextField>
              <TextField
                id="connectedIndividual-personalDetails-identificationNumber"
                margin="dense"
                variant="outlined"
                fullWidth
                label="Identification Number"
                value={store.identificationNumber}
                onChange={(e) => store.setIdentificationNumber(e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                id="connectedIndividual-contactDetails-email"
                margin="dense"
                variant="outlined"
                fullWidth
                value={store.emailAddress}
                onChange={(e) => store.setEmailAddress(e.target.value)}
                label="Email"
              />
              <TextField
                id="connectedIndividual-contactDetails-mobile"
                variant="outlined"
                fullWidth
                label="Mobile"
                value={store.cellphoneNumber}
                onChange={(e) => store.setCellphoneNumber(e.target.value)}
                margin="dense"
              />
              <TextField
                id="connectedIndividual-contactDetails-telephone"
                margin="dense"
                variant="outlined"
                fullWidth
                value={store.telephoneNumber}
                onChange={(e) => store.setTelephoneNumber(e.target.value)}
                label="Telephone"
              />
            </Box>
          </Box>
        </div>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "32px",
          }}
        >
          <Box>
            {/* ---- Physical Address ---- */}
            <div>
              <Typography
                variant="h6"
                children="Physical Address"
                className={classes.sectionHeading}
              />
              <>
                <TextField
                  id="connectedIndividual-physicalAddress-line1"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Address 1"
                  value={store.physicalAddress.addressLine1}
                  onChange={(e) =>
                    store.setPhysicalAddress(
                      new Address({
                        ...store.physicalAddress,
                        addressLine1: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedIndividual-physicalAddress-line2"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Address 2"
                  value={store.physicalAddress.addressLine2}
                  onChange={(e) =>
                    store.setPhysicalAddress(
                      new Address({
                        ...store.physicalAddress,
                        addressLine2: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedIndividual-physicalAddress-suburb"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Suburb"
                  value={store.physicalAddress.suburb}
                  onChange={(e) =>
                    store.setPhysicalAddress(
                      new Address({
                        ...store.physicalAddress,
                        suburb: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedIndividual-physicalAddress-city"
                  variant="outlined"
                  fullWidth
                  label="City"
                  margin="dense"
                  value={store.physicalAddress.city}
                  onChange={(e) =>
                    store.setPhysicalAddress(
                      new Address({
                        ...store.physicalAddress,
                        city: e.target.value,
                      }),
                    )
                  }
                />
                <TextField
                  id="connectedIndividual-physicalAddress-province"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Province"
                  placeholder="Optional"
                  value={store.physicalAddress.province}
                  onChange={(e) =>
                    store.setPhysicalAddress(
                      new Address({
                        ...store.physicalAddress,
                        province: e.target.value,
                      }),
                    )
                  }
                />
                <CountryDropdown
                  id="connectedIndividual-physicalAddress-country"
                  onChange={(__: object, country: CountryOption | null) => {
                    if (country) {
                      store.setPhysicalAddress(
                        new Address({
                          ...store.physicalAddress,
                          countryCode: country.value,
                        }),
                      );
                    }
                  }}
                  value={(() => {
                    const c = countries.find(
                      (countryOption) =>
                        countryOption.value ===
                        store.physicalAddress.countryCode,
                    );
                    return c || null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="connectedIndividual-physicalAddress-country-TextField"
                      margin="dense"
                      label="Country"
                      InputProps={{
                        ...params.InputProps,
                        placeholder: "Select...",
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <TextField
                  id="connectedIndividual-physicalAddress-postalCode"
                  variant="outlined"
                  margin="dense"
                  label="Postal Code"
                  value={store.physicalAddress.postalCode}
                  onChange={(e) =>
                    store.setPhysicalAddress(
                      new Address({
                        ...store.physicalAddress,
                        postalCode: e.target.value,
                      }),
                    )
                  }
                />
              </>
            </div>
          </Box>
          <Box>
            {/* ---- Postal Address ---- */}
            <div>
              <Typography
                className={classes.sectionHeading}
                variant="h6"
                children="Postal Address"
              />
              <>
                <TextField
                  id="connectedIndividual-postalAddress-line1"
                  variant="outlined"
                  fullWidth
                  label="Address 1"
                  disabled={store.copyAddressChecked}
                  value={store.postalAddress.addressLine1}
                  onChange={(e) =>
                    store.setPostalAddress(
                      new Address({
                        ...store.postalAddress,
                        addressLine1: e.target.value,
                      }),
                    )
                  }
                  margin="dense"
                />
                <TextField
                  id="connectedIndividual-postalAddress-line2"
                  variant="outlined"
                  fullWidth
                  label="Address 2"
                  disabled={store.copyAddressChecked}
                  value={store.postalAddress.addressLine2}
                  onChange={(e) =>
                    store.setPostalAddress(
                      new Address({
                        ...store.postalAddress,
                        addressLine2: e.target.value,
                      }),
                    )
                  }
                  margin="dense"
                />

                <TextField
                  id="connectedIndividual-postalAddress-suburb"
                  variant="outlined"
                  fullWidth
                  label="Suburb"
                  margin="dense"
                  value={store.postalAddress.suburb}
                  disabled={store.copyAddressChecked}
                  onChange={(e) =>
                    store.setPostalAddress(
                      new Address({
                        ...store.postalAddress,
                        suburb: e.target.value,
                      }),
                    )
                  }
                  placeholder="Optional"
                />
                <TextField
                  id="connectedIndividual-postalAddress-city"
                  variant="outlined"
                  fullWidth
                  label="City"
                  value={store.postalAddress.city}
                  disabled={store.copyAddressChecked}
                  onChange={(e) =>
                    store.setPostalAddress(
                      new Address({
                        ...store.postalAddress,
                        city: e.target.value,
                      }),
                    )
                  }
                  margin="dense"
                />

                <TextField
                  id="connectedIndividual-postalAddress-province"
                  variant="outlined"
                  fullWidth
                  label="Province"
                  placeholder="Optional"
                  disabled={store.copyAddressChecked}
                  value={store.postalAddress.province}
                  onChange={(e) =>
                    store.setPostalAddress(
                      new Address({
                        ...store.postalAddress,
                        province: e.target.value,
                      }),
                    )
                  }
                  margin="dense"
                />
                <CountryDropdown
                  id="connectedIndividual-postalAddress-country"
                  disabled={store.copyAddressChecked}
                  onChange={(__: object, country: CountryOption | null) => {
                    if (country) {
                      store.setPostalAddress(
                        new Address({
                          ...store.physicalAddress,
                          countryCode: country.value,
                        }),
                      );
                    }
                  }}
                  value={(() => {
                    const c = countries.find(
                      (countryOption) =>
                        countryOption.value === store.postalAddress.countryCode,
                    );
                    return c || null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="connectedIndividual-postalAddress-country-TextField"
                      margin="dense"
                      label="Country"
                      InputProps={{
                        ...params.InputProps,
                        placeholder: "Select...",
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <TextField
                  id="connectedIndividual-postalAddress-postalCode"
                  variant="outlined"
                  label="Postal Code"
                  disabled={store.copyAddressChecked}
                  value={store.postalAddress.postalCode}
                  onChange={(e) =>
                    store.setPostalAddress(
                      new Address({
                        ...store.postalAddress,
                        postalCode: e.target.value,
                      }),
                    )
                  }
                  margin="dense"
                />
              </>
            </div>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};
