import React, { useEffect, useState } from "react";
import { TransactionBatch } from "james/ledger/TransactionBatch";
import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { TransactionBatchStateChip } from "./Chips";
import { TransactionQueueEntryTable } from "./TransactionQueueEntryTable";
import { TransactionBatchRepository } from "james/ledger/TransactionBatchRepository";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { Refresh as ReloadIcon } from "@mui/icons-material";

type ViewTransactionBatchProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactionBatchCriteria: any;
  height: number;
};

export function ViewTransactionBatch(props: ViewTransactionBatchProps) {
  const { authContext } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();

  const [reload, setReload] = useState(false);
  const [transactionBatch, setTransactionBatch] = useState<
    TransactionBatch | undefined
  >(undefined);
  const [fetchingBatch, setFetchingBatch] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchingBatch(true);
      try {
        setTransactionBatch(
          (
            await TransactionBatchRepository.SearchTransactionBatches({
              context: authContext,
              criteria: props.transactionBatchCriteria,
            })
          ).records[0],
        );
      } catch (e) {
        errorContextDefaultErrorFeedback(e);
      }
      setFetchingBatch(false);
    })();
  }, [authContext, reload]);

  if (fetchingBatch) {
    return (
      <Card>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (!transactionBatch) {
    return (
      <Card>
        <CardContent>
          <Typography variant={"body1"}>No Batch</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      })}
    >
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
            }}
          >
            <Box>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing(1),
                })}
              >
                <Typography variant={"body1"}>
                  - Distribution Batch Transaction is in state:
                </Typography>
                <TransactionBatchStateChip state={transactionBatch.state} />
              </Box>
              <Typography variant={"body1"}>
                - Batch contains {transactionBatch.transactionQueueIDs.length}{" "}
                queues
              </Typography>
            </Box>
            <IconButton
              id={"ledgerTransactionTable-reload-iconButton"}
              size={"small"}
              disabled={fetchingBatch}
              onClick={() => setReload(!reload)}
            >
              <ReloadIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={(theme) => ({
          height: props.height - 85,
          overflowY: "scroll",
          overflowX: "hidden",
          backgroundColor: theme.palette.background.paper,
        })}
        className={"meshScroll"}
      >
        {transactionBatch.transactionQueueIDs.map((queueID) => (
          <React.Fragment key={queueID}>
            <TransactionQueueEntryTable
              collapsible
              initialCollapsedState
              height={0.6 * props.height}
              transactionQueueID={queueID}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
}
