import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import { useEffect, useState } from "react";
import { useIsMounted } from "hooks";
import { IndicativePriceDataPoint } from "./IndicativePriceDataPoint";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { useRQIndicativePriceHistoryGenerator } from "../../hooks/reactQuery/useRQIndicaticePriceGenerator";
import { useErrorContext } from "context/Error";

export enum DateFilter {
  _1W = "1W",
  _1M = "1M",
  _3M = "3M",
  _6M = "6M",
  _1Y = "1Y",
  ALL = "ALL",
}

export const AllDateFilters = [
  DateFilter.ALL,
  DateFilter._1W,
  DateFilter._1M,
  DateFilter._3M,
  DateFilter._6M,
  DateFilter._1Y,
];

export interface GenerateRequest {
  assetIdentifier: Identifier;
  dateFilter: DateFilter;
}

export interface GenerateResponse {
  priceHistory: IndicativePriceDataPoint[];
}

export const IndicativePriceHistoryGenerator = {
  serviceProvider: "marketData-IndicativePriceHistoryGenerator",
  async Generate(request: GenerateRequest): Promise<GenerateResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${IndicativePriceHistoryGenerator.serviceProvider}.Generate`,
      request,
    });
    return {
      priceHistory: response.priceHistory.map(
        (c: IndicativePriceDataPoint) => new IndicativePriceDataPoint(c),
      ),
    };
  },
};

export function useGenerateIndicativePriceHistory(
  initialGenerateIndicativePriceHistoryRequest: GenerateRequest,
) {
  const isMounted = useIsMounted();
  const [
    generateIndicativePriceHistoryRequest,
    setGenerateIndicativePriceHistoryRequest,
  ] = useState<GenerateRequest>(initialGenerateIndicativePriceHistoryRequest);
  const [
    generateIndicativePriceHistoryResponse,
    setGenerateIndicativePriceHistoryResponse,
  ] = useState<GenerateResponse>({
    priceHistory: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const { Generate: GenerateIndicativePriceHistory } =
    useRQIndicativePriceHistoryGenerator();
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    (async () => {
      if (isMounted()) {
        setLoading(true);
      }

      try {
        const response = await GenerateIndicativePriceHistory(
          generateIndicativePriceHistoryRequest,
        );
        if (isMounted()) {
          setGenerateIndicativePriceHistoryResponse(response);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error generating indicative price history: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error generating indicative price history: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }

      setLoading(false);
    })();
  }, [generateIndicativePriceHistoryRequest, isMounted]);

  return {
    generateIndicativePriceHistoryRequest,
    setGenerateIndicativePriceHistoryRequest,
    generateIndicativePriceHistoryResponse,
    loading,
    error,
  };
}
