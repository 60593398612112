import { ClaimsType } from "./types";

export class UserRegistration {
  public type: ClaimsType | "" = "";
  public expirationTime = 0;
  public systemUserID = "";
  public email = "";

  constructor(individualUserRegistration?: UserRegistration) {
    if (!individualUserRegistration) {
      return;
    }

    this.type = individualUserRegistration.type;
    this.expirationTime = individualUserRegistration.expirationTime;
    this.systemUserID = individualUserRegistration.systemUserID;
    this.email = individualUserRegistration.email;
  }

  public static NewFromJWT(jwt: string): UserRegistration {
    const claimsPayloadData = jwt.substring(
      jwt.indexOf(".") + 1,
      jwt.lastIndexOf("."),
    );

    const payloadString = atob(claimsPayloadData);
    const tokenPOJO = JSON.parse(payloadString);

    return new UserRegistration(tokenPOJO);
  }
}

export class CompanyRegistration {
  public type: ClaimsType | "" = "";
  public expirationTime = 0;
  public systemUserID = "";
  public email = "";

  constructor(companyUserRegistration?: CompanyRegistration) {
    if (!companyUserRegistration) {
      return;
    }

    this.type = companyUserRegistration.type;
    this.expirationTime = companyUserRegistration.expirationTime;
    this.systemUserID = companyUserRegistration.systemUserID;
    this.email = companyUserRegistration.email;
  }

  public static NewFromJWT(jwt: string): CompanyRegistration {
    const claimsPayloadData = jwt.substring(
      jwt.indexOf(".") + 1,
      jwt.lastIndexOf("."),
    );

    const payloadString = atob(claimsPayloadData);
    const tokenPOJO = JSON.parse(payloadString);

    return new CompanyRegistration(tokenPOJO);
  }
}
