import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { Close as CloseIcon } from "@mui/icons-material";
import { Client } from "james/client";
import { BankAccountView } from "./components/BankAccountDetailsView";
import { CompanyView } from "./components/CompanyDetailsView/CompanyView";
import { ClientView } from "./components/ClientDetailsView/ClientView";
import { PersonTab } from "./components/PersonView/PersonTab";
import { ClientKind } from "james/client/Client";
import { Documents } from "./components/Documents/Documents";
import { TransactionDetailsView } from "./components/TransactionDetailsView/TransactionDetailsView";
import { useApplicationContext } from "context/Application/Application";

type DashboardProps = {
  open: boolean;
  closeDialog: () => void;
  client: Client;
  refresh: () => void;
  readOnly?: boolean;
};

export enum View {
  ClientDetails = "Client Details",
  PersonDetails = "Person Details",
  CompanyDetails = "Company Details",
  BankAccountDetails = "Bank Account Details",
  ClientDocuments = "Client Documents",
  TransactionDetails = "Transaction Details",
}

export function Dashboard(props: DashboardProps) {
  const [currentView, setCurrentView] = useState<View>(View.ClientDetails);
  const { viewConfiguration } = useApplicationContext();
  return (
    <Dialog open={props.open} fullScreen>
      <HeaderSection
        closeDialog={props.closeDialog}
        name={props.client.name}
        refresh={props.refresh}
      />
      <DialogContent
        sx={{
          overflowY: "hidden",
        }}
      >
        <Tabs
          value={currentView}
          onChange={(event, value) => {
            setCurrentView(value);
          }}
          textColor={"inherit"}
        >
          <Tab value={View.ClientDetails} label={"Client"} />
          {props.client.clientKind === ClientKind.IndividualType && (
            <Tab value={View.PersonDetails} label={"Person"} />
          )}
          {props.client.clientKind === ClientKind.CompanyType && (
            <Tab value={View.CompanyDetails} label={"Company"} />
          )}
          <Tab value={View.BankAccountDetails} label={"Bank Account"} />
          <Tab value={View.TransactionDetails} label={"Accounts"} />
          <Tab value={View.ClientDocuments} label={"Documents"} />
        </Tabs>
        {currentView === View.BankAccountDetails && (
          <BankAccountView
            Client={props.client}
            readOnly={!viewConfiguration["Compliance"]}
          />
        )}
        {currentView === View.CompanyDetails && (
          <CompanyView
            Client={props.client}
            readOnly={!viewConfiguration["Compliance"]}
          />
        )}
        {currentView === View.ClientDetails && (
          <ClientView
            Client={props.client}
            readOnly={!viewConfiguration["Compliance"]}
          />
        )}
        {currentView === View.PersonDetails && (
          <PersonTab
            client={props.client}
            readOnly={!viewConfiguration["Compliance"]}
          />
        )}
        {currentView === View.ClientDocuments && (
          <Documents
            client={props.client}
            readOnly={!viewConfiguration["Compliance"]}
          />
        )}
        {currentView === View.TransactionDetails && (
          <TransactionDetailsView
            client={props.client}
            readOnly={!viewConfiguration["Compliance"]}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

type TitleSectionProps = {
  closeDialog: () => void;
  name: string;
  refresh: () => void;
};

const HeaderSection = (props: TitleSectionProps) => {
  return (
    <DialogTitle
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        display: "grid",
        gridTemplateColumns: "auto 1fr",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          gap: "8px",
        }}
      >
        <img alt="" height={32} src={meshMiniLogo} />
        <Typography
          alignSelf="center"
          variant="h5"
          children="Compliance View"
        />
        <Typography
          alignSelf="center"
          variant="h5"
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
          children={props.name}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title="Close" placement="top">
          <span>
            <IconButton
              id={"ComplianceDashboard-close-button"}
              size="small"
              onClick={() => {
                props.refresh();
                props.closeDialog();
              }}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </DialogTitle>
  );
};
