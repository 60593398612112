import React from "react";
import { useTheme } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { ListingState } from "james/market/Listing";

export interface ListingStateChipProps {
  state: ListingState | "";
}

export function ListingStateChip(props: ListingStateChipProps) {
  const theme = useTheme();
  switch (props.state) {
    case ListingState.Active:
      return (
        <Chip
          sx={{
            backgroundColor: theme.palette.success.main,
          }}
          id={"listingStateChip-active-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case ListingState.Inactive:
      return (
        <Chip
          sx={{
            backgroundColor: theme.palette.warning.main,
          }}
          id={"listingStateChip-inactive-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case ListingState.ListingInProgress:
      return (
        <Chip
          sx={{
            backgroundColor: theme.palette.info.main,
          }}
          id={"listingStateChip-listingInProgress-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case ListingState.ListingFailed:
      return (
        <Chip
          sx={{
            backgroundColor: theme.palette.error.main,
          }}
          id={"listingStateChip-listingFailed-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case ListingState.ListingUnderInvestigation:
      return (
        <Chip
          sx={{
            backgroundColor: theme.palette.error.main,
          }}
          id={"listingStateChip-listingUnderInvestigation-chip"}
          size={"small"}
          label={props.state}
        />
      );

    case "":
      return (
        <Chip
          sx={{
            backgroundColor: theme.palette.error.main,
          }}
          id={"listingStateChip-none-chip"}
          size={"small"}
          label={"None"}
        />
      );

    default:
      return <>{props.state}</>;
  }
}
