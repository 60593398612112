// source: financial/calendar.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.Calendar', null, global);
/**
 * @enum {number}
 */
proto.financial.Calendar = {
  UNDEFINED_CALENDAR: 0,
  AUSTRALIA_CALENDAR: 1,
  ARGENTINA_CALENDAR: 2,
  BRAZIL_CALENDAR: 3,
  CANADA_CALENDAR: 4,
  CHINA_CALENDAR: 5,
  CZECH_REPUBLIC_CALENDAR: 6,
  DENMARK_CALENDAR: 7,
  FINLAND_CALENDAR: 8,
  FRANCE_CALENDAR: 9,
  GERMANY_CALENDAR: 10,
  HONG_KONG_CALENDAR: 11,
  HUNGARY_CALENDAR: 12,
  ICELAND_CALENDAR: 13,
  INDIA_CALENDAR: 14,
  INDONESIA_CALENDAR: 15,
  ISRAEL_CALENDAR: 16,
  ITALY_CALENDAR: 17,
  JAPAN_CALENDAR: 18,
  MEXICO_CALENDAR: 19,
  NEW_ZEALAND_CALENDAR: 20,
  NORWAY_CALENDAR: 21,
  POLAND_CALENDAR: 22,
  ROMANIA_CALENDAR: 23,
  RUSSIA_CALENDAR: 24,
  RUSSIA_SETTLEMENT_CALENDAR: 25,
  RUSSIA_MOEX_CALENDAR: 26,
  SAUDI_ARABIA_CALENDAR: 27,
  SINGAPORE_CALENDAR: 28,
  SLOVAKIA_CALENDAR: 29,
  SOUTH_AFRICA_CALENDAR: 30,
  SOUTH_KOREA_CALENDAR: 31,
  SWEDEN_CALENDAR: 32,
  SWITZERLAND_CALENDAR: 33,
  TAIWAN_CALENDAR: 34,
  TARGET_CALENDAR: 35,
  THAILAND_CALENDAR: 36,
  TURKEY_CALENDAR: 37,
  UKRAINE_CALENDAR: 40,
  UNITED_KINGDOM_CALENDAR: 41,
  UNITED_KINGDOM_SETTLEMENT_CALENDAR: 42,
  UNITED_STATES_SETTLEMENT_CALENDAR: 43,
  UNITED_STATES_NYSE_CALENDAR: 44,
  UNITED_STATES_GOVERNMENT_BOND_CALENDAR: 45,
  UNITED_STATES_NERC_CALENDAR: 46,
  UNITED_STATES_LIBOR_IMPACT_CALENDAR: 47,
  UNITED_STATES_FEDERAL_RESERVE_CALENDAR: 48,
  UTC_BLANK_CALENDAR: 49
};

goog.object.extend(exports, proto.financial);
