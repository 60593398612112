import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Token } from "james/ledger";

export interface RequestIconUploadURLForTokenRequest {
  context: Context;
  token: Token;
}

export interface RequestIconUploadURLForTokenResponse {
  uploadURL: string;
}

export interface RequestIconDownloadURLForTokenRequest {
  context: Context;
  token: Token;
}

export interface RequestIconDownloadURLForTokenResponse {
  downloadURL: string;
}

export const TokenIconControllerServiceProviderName =
  "ledger-TokenIconController";

export const TokenIconController = {
  async RequestIconUploadURLForToken(
    request: RequestIconUploadURLForTokenRequest,
    additionalHeaders?: Headers,
  ): Promise<RequestIconUploadURLForTokenResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshAuthorizedURL"),
      method: `${TokenIconControllerServiceProviderName}.RequestIconUploadURLForToken`,
      request,
    });
  },
  async RequestIconDownloadURLForToken(
    request: RequestIconDownloadURLForTokenRequest,
    additionalHeaders?: Headers,
  ): Promise<RequestIconDownloadURLForTokenResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshAuthenticatedURL"),
      method: `${TokenIconControllerServiceProviderName}.RequestIconDownloadURLForToken`,
      request,
    });
  },
};
