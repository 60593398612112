interface DoUploadArgs {
  url: string;
  documentData: ArrayBuffer;
  documentType: string;
  onStart?: () => void;
  onComplete?: () => void;
  onProgress?: (percentageComplete: number) => void;
  onError?: (e: Error) => void;
}

export function doUpload({
  url,
  documentData,
  documentType,
  onStart,
  onComplete,
  onProgress,
  onError,
}: DoUploadArgs) {
  const xhr = new XMLHttpRequest();

  try {
    if (onProgress) {
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const percentage = Math.round((e.loaded * 100) / e.total);
          onProgress(percentage);
        }
      });
    }

    if (onStart) {
      xhr.upload.addEventListener("loadstart", onStart, false);
    }

    if (onComplete) {
      xhr.addEventListener("load", onComplete, false);
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        //if complete
        if (xhr.status === 200) {
          //check if "OK" (200)
          //success
        } else {
          if (onError) {
            console.error(
              `${xhr.status} server error while uploading document`,
            );
            onError(
              new Error(`${xhr.status} server error while uploading document`),
            );
          }
        }
      }
    };

    xhr.open("PUT", url);
    xhr.setRequestHeader("Content-Type", documentType);
    xhr.send(new Int8Array(documentData));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error(
      `error performing upload: ${e.message ? e.message : e.toString()}`,
    );
    if (onError) {
      onError(e);
    }
  }
}
