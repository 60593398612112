import { Context } from "james/security";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";

export type StartVerificationRequest = {
  context: Context;
  clientID: string;
};

export type StartVerificationResponse = Record<string, never>;

export type SetKYCStatusVerifiedRequest = {
  context: Context;
  clientID: string;
};

export type SetKYCStatusVerifiedResponse = Record<string, never>;

export type SetKYCStatusOutstandingRequest = {
  context: Context;
  clientID: string;
  reason: string;
};

export type SetKYCStatusOutstandingResponse = Record<string, never>;

export const IndividualClientKYCStateController = {
  serviceProvider: "kyc-IndividualClientKYCStateController",
  async StartVerification(
    request: StartVerificationRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<StartVerificationResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${IndividualClientKYCStateController.serviceProvider}.StartVerification`,
        request,
      },
      opts,
    );
  },
  async SetKYCStatusVerified(
    request: SetKYCStatusVerifiedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SetKYCStatusVerifiedResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${IndividualClientKYCStateController.serviceProvider}.SetKYCStatusVerified`,
        request,
      },
      opts,
    );
  },
  async SetKYCStatusOutstanding(
    request: SetKYCStatusOutstandingRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SetKYCStatusOutstandingResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${IndividualClientKYCStateController.serviceProvider}.SetKYCStatusOutstanding`,
        request,
      },
      opts,
    );
  },
};
