import React, { useState } from "react";
import { Box, Card, Link, Tooltip, Typography } from "@mui/material";
import { InfoPopUp } from "./InfoPopUp";
import { Info as InfoIcon } from "@mui/icons-material";
import { ParticipantProfile } from "james/ledger/AssetParticipants";

interface GuarantorSectionProps {
  guarantor: ParticipantProfile;
}

export const GuarantorSection = ({ guarantor }: GuarantorSectionProps) => {
  return (
    <>
      <Box
        sx={{
          mt: 4,
          mx: { sm: 5, xs: 3 },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            Guarantor
          </Typography>
          <Tooltip
            title={`The Guarantor makes sure that if the Issuer fails to make its 
              statutory payments, it will guarantee payment of these commitments.`}
            arrow
            placement="top"
          >
            <InfoIcon
              sx={(theme) => ({
                ml: 1,
                height: { sm: 16, xs: 20 },
                width: { sm: 16, xs: 20 },
                color: theme.palette.text.disabled,
              })}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 2,
          }}
        >
          <GuarantorLineItem guarantor={guarantor} />
        </Box>
      </Box>
    </>
  );
};

interface GuarantorLineItemProps {
  guarantor: ParticipantProfile;
}

export const GuarantorLineItem = ({ guarantor }: GuarantorLineItemProps) => {
  const [showAssetViewMoreDialog, setShowViewMoreDialog] = useState(false);
  const handleClickOpen = () => {
    setShowViewMoreDialog(true);
  };
  return (
    <>
      <Card
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.custom.midnight,
          px: 3,
          py: 2,
          width: "auto",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            width: "100%",
            alignItems: { sm: "center" },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              mr: "auto",
            })}
          >
            {guarantor.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center", xs: "flex-start" },
              ml: { sm: "auto", xs: 0 },
              mt: { sm: 0, xs: 1 },
            }}
          >
            <Link
              onClick={handleClickOpen}
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                ml: { sm: 3, xs: "auto" },
              })}
              underline="hover"
            >
              View
            </Link>
          </Box>
        </Box>
      </Card>
      <InfoPopUp
        dialogProps={{ open: showAssetViewMoreDialog }}
        closeDialog={() => setShowViewMoreDialog(false)}
        title="Guarantor Info"
        heading={guarantor.name}
        contactNumber={guarantor.contactNumber}
        email={guarantor.email}
        website={guarantor.webLink}
        webLink={guarantor.webLink}
        address={guarantor.address}
        twitterLink={guarantor.twitterLink}
        facebookLink={guarantor.facebookLink}
        instagramLink={guarantor.instagramLink}
        youtubeLink={guarantor.youtubeLink}
        linkedInLink={guarantor.linkedInLink}
      />
    </>
  );
};
