import { useApplicationContext } from "context/Application/Application";
import { useCallback, useState } from "react";
import { IndividualClientUserRegistrationController } from "james/user/IndividualClientUserRegistrationController";
import {
  CompanyRegistration,
  UserRegistration,
} from "james/security/claims/userRegistration";
import { TokenValidator } from "james/security/token";
import { IndividualClientUserRegistrar } from "james/user/IndividualClientUserRegistrar";
import { Context as AuthContext } from "james/security";
import { ErrUserAlreadyRegistered } from "james/user/userRegistrationControllerErrors";
import { Authenticator } from "james/security/authentication";
import { useFirebaseContext } from "context/Firebase";
import { useErrorContext } from "context/Error";
import {
  InitiateCompanyAdminRegistration,
  InitiateUserRegistration,
} from "views/SignUp/const";
import { useGTMTriggersPusher } from "../analytics/useGTMTriggersPusher";
import {
  userAffiliations,
  userCategories,
  userLoginTypes,
} from "../../const/gtm";
import { hash } from "utilities/hash/hash";
import { CompanyClientUserRegistrationController } from "james/user/CompanyClientUserRegistrationController";
import { CompanyUserRegistrar } from "james/onboarding/companyUserRegistrar";

export type userRegistrationHelper = {
  registerIndividualUser: (firebaseUserIDToken: string) => Promise<void>;
  individualUserRegistrationInProgress: boolean;
  registerCompanyUser: (
    firebaseUserIDToken: string,
    companyName: string,
  ) => Promise<void>;
  companyUserRegistrationInProgress: boolean;
};

export const useUserRegistrationHelper = (): userRegistrationHelper => {
  const { loginClaimsUpdate, logout } = useApplicationContext();
  const { firebaseLogout } = useFirebaseContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const [
    individualUserRegistrationInProgress,
    setIndividualUserRegistrationInProgress,
  ] = useState(false);
  const [
    companyUserRegistrationInProgress,
    setCompanyUserRegistrationInProgress,
  ] = useState(false);

  const { pushSignUpCompletedEvent } = useGTMTriggersPusher();

  const registerIndividualUser = useCallback(
    async (firebaseUserIDToken: string) => {
      // do not attempt to register the user if they weren't trying
      // to register on mesh
      if (!localStorage.getItem(InitiateUserRegistration)) {
        return;
      }

      setIndividualUserRegistrationInProgress(true);
      try {
        // 1. Request a token to initiate the user registration
        const individualClientUserRegistrationToken =
          await IndividualClientUserRegistrationController.GenerateIndividualClientUserRegistrationToken(
            { firebaseUserIDToken },
          );
        const individualClientUserRegistrationClaims =
          UserRegistration.NewFromJWT(
            individualClientUserRegistrationToken.token,
          );

        // 2. Validate the token this will set the token in the cookies
        await TokenValidator.Validate({
          token: individualClientUserRegistrationToken.token,
        });

        // 3. register the user on mesh
        await IndividualClientUserRegistrar.InitiateUserRegistration({
          context: new AuthContext({
            userID: individualClientUserRegistrationClaims.systemUserID,
          }),
          firebaseUserIDToken,
        });

        // 4. login the user
        const loginResponse = await Authenticator.Login({
          firebaseUserIDToken,
        });

        pushSignUpCompletedEvent({
          user_id: loginResponse.loginClaims.userID,
          user_login_type: userLoginTypes.loggedIn,
          hashed_email: hash(loginResponse.loginClaims.email),
          user_kyc_request_date: "",
          user_kyc_approved_date: "",
          user_primary_affiliation: userAffiliations.internal,
          user_category: userCategories.active,
          user_kyc_status: loginResponse.loginClaims.kycStatus,
        });

        // 5. set the login claims and related data as well
        await loginClaimsUpdate(loginResponse.loginClaims);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        switch (err.code) {
          case ErrUserAlreadyRegistered:
            {
              // 1. login the user
              const loginResponse = await Authenticator.Login({
                firebaseUserIDToken,
              });

              // 2. set the login claims and related data as well
              await loginClaimsUpdate(loginResponse.loginClaims);
            }
            break;

          default: {
            errorContextDefaultErrorFeedback(err);
            await firebaseLogout();
            await logout().finally();
          }
        }
      }

      setIndividualUserRegistrationInProgress(false);
    },
    [],
  );

  const registerCompanyUser = useCallback(
    async (firebaseUserIDToken: string, companyName: string) => {
      if (!localStorage.getItem(InitiateCompanyAdminRegistration)) {
        return;
      }

      try {
        setCompanyUserRegistrationInProgress(true);

        const companyUserRegistrationToken =
          await CompanyClientUserRegistrationController.GenerateCompanyClientUserRegistrationToken(
            { firebaseUserIDToken },
          );

        const companyRegistrationClaims = CompanyRegistration.NewFromJWT(
          companyUserRegistrationToken.token,
        );

        // 2. Validate the token this will set the token in the cookies
        await TokenValidator.Validate({
          token: companyUserRegistrationToken.token,
        });

        // 3. register the user on mesh
        await CompanyUserRegistrar.InitiateUserRegistration({
          context: new AuthContext({
            userID: companyRegistrationClaims.systemUserID,
          }),
          firebaseUserIDToken,
          companyName,
        });

        // 4. login the user
        const loginResponse = await Authenticator.Login({
          firebaseUserIDToken,
        });

        // 5. set the login claims and related data as well
        await loginClaimsUpdate(loginResponse.loginClaims);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        switch (err.code) {
          case ErrUserAlreadyRegistered:
            {
              // 1. login the user
              const loginResponse = await Authenticator.Login({
                firebaseUserIDToken,
              });

              // 2. set the login claims and related data as well
              await loginClaimsUpdate(loginResponse.loginClaims);
            }
            break;

          default: {
            await firebaseLogout();
            errorContextDefaultErrorFeedback(err);
            await logout().finally();
          }
        }
      }

      setCompanyUserRegistrationInProgress(false);
    },
    [],
  );

  return {
    registerIndividualUser,
    individualUserRegistrationInProgress,
    companyUserRegistrationInProgress,
    registerCompanyUser,
  };
};
