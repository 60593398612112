import { PersonContactDetails } from "james/legal/person";
import { ValidationResult } from "common/validation";

export type FormUpdaterType = {
  telephoneNumber: (
    val: string,
    prevFormData?: PersonContactDetails,
  ) => PersonContactDetails;
  cellphoneNumber: (
    val: string,
    prevFormData?: PersonContactDetails,
  ) => PersonContactDetails;
  emailAddress: (
    val: string,
    prevFormData?: PersonContactDetails,
  ) => PersonContactDetails;
};

export const formUpdater: FormUpdaterType = {
  cellphoneNumber: (num: string, prevFormData?: PersonContactDetails) => {
    prevFormData = prevFormData as PersonContactDetails;
    return new PersonContactDetails({
      ...prevFormData,
      cellphoneNumber: num,
    });
  },
  telephoneNumber: (num: string, prevFormData?: PersonContactDetails) => {
    prevFormData = prevFormData as PersonContactDetails;
    return new PersonContactDetails({
      ...prevFormData,
      telephoneNumber: num,
    });
  },
  emailAddress: (email: string, prevFormData?: PersonContactDetails) => {
    prevFormData = prevFormData as PersonContactDetails;
    return new PersonContactDetails({
      ...prevFormData,
      emailAddress: email,
    });
  },
};

export const formDataValidationFunc = async (
  formData: PersonContactDetails,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched validationResult.fieldValidations
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  if (formData.cellphoneNumber.length > 0) {
    if (!/^[+]?[0-9]+$/.test(formData.cellphoneNumber)) {
      validationResult.valid = false;
      validationResult.fieldValidations.cellphoneNumber = "Invalid format";
    } else if (
      formData.cellphoneNumber.length < 10 ||
      formData.cellphoneNumber.length > 15
    ) {
      validationResult.valid = false;
      validationResult.fieldValidations.cellphoneNumber = "Invalid length";
    }
  }

  if (formData.telephoneNumber.length > 0) {
    if (!/^[+]?[0-9]+$/.test(formData.telephoneNumber)) {
      validationResult.valid = false;
      validationResult.fieldValidations.telephoneNumber = "Invalid format";
    } else if (
      formData.telephoneNumber.length < 10 ||
      formData.telephoneNumber.length > 15
    ) {
      validationResult.valid = false;
      validationResult.fieldValidations.telephoneNumber = "Invalid length";
    }
  }

  return validationResult;
};
