import React, { ReactNode } from "react";
import { motion } from "framer-motion";

interface VerticalSlideProps {
  // The duration it takes to  transition the component in seconds
  transitionDuration: number;
  // If slide down is true, the component at the top should have the shortest duration
  slideDown?: boolean;
  // The component to be animated
  children: ReactNode;
  // Y is the vertical distance the animation will go, by default the y value is set to 15
  y?: number;
  // Sets the width to this component, for all children component to reference the width from this component as the container
  width?: string | number;
}

export const VerticalSlide = React.forwardRef(function MyComponent(
  {
    transitionDuration,
    children,
    slideDown,
    y = 15,
    width,
  }: VerticalSlideProps,
  ref,
) {
  return (
    <motion.div
      initial={{ y: slideDown ? -y : y, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: transitionDuration }}
      style={{ width: width }}
      ref={ref as React.Ref<HTMLDivElement> | undefined}
    >
      {children}
    </motion.div>
  );
});
