import { smartInstrumentAttributeTypeToString } from "@mesh/common-js/dist/financial";
import { HoldingAllocationsSmartInstrumentAttribute } from "@mesh/common-js/dist/financial/smartInstrumentAttributeHoldingAllocations_pb";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import BigNumber from "bignumber.js";
import { ValidationResult } from "common/validation";

export function validateHoldingAllocationsAttribute(
  attribute: HoldingAllocationsSmartInstrumentAttribute,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.HOLDING_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ] = message;
  };

  // sum total allocation
  const totalAllocation = attribute
    .getEntriesList()
    .reduce(
      (sum, entry) =>
        sum.plus(decimalToBigNumber(entry.getAmount() ?? new Decimal())),
      BigNumber("0"),
    );

  // at least 1 allocation should be added
  if (!attribute.getEntriesList().length) {
    invalid("total", "Add at least 1");
  }

  // confirm that total allocation not greater than 100
  if (totalAllocation.isGreaterThan(BigNumber("100"))) {
    invalid("total", "Cannot be > 100%");
  }

  const holdingIndex: { [key: string]: boolean } = {};
  attribute.getEntriesList().forEach((entry) => {
    // - confirm that no allocations zero
    if (decimalToBigNumber(entry.getAmount() ?? new Decimal()).isZero()) {
      invalid(`${entry.getHolding()}`, "Must be > 0%");
    }

    // - confirm no duplicate holdings
    if (!holdingIndex[entry.getHolding()]) {
      holdingIndex[entry.getHolding()] = true;
    } else {
      invalid(`${entry.getHolding()}`, "Remove duplicate");
    }

    // - confirm no blank holding namees
    if (entry.getHolding().replaceAll(" ", "") === "") {
      invalid(`${entry.getHolding()}`, "Name cannot be blank");
    }
  });

  return validationResult;
}
