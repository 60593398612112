import { Context } from "james/security";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Person } from "james/legal/person/Person";
import { KYCDocument } from "./KYCDocument";

export interface GetClientKYCDocumentsRequest {
  context: Context;
  clientID: string;
}

export interface GetClientKYCDocumentsResponse {
  documents: KYCDocument[];
}

export interface GetIndividualClientPersonalInfoRequest {
  context: Context;
  clientID: string;
}

export interface GetIndividualClientPersonalInfoResponse {
  person: Person;
}

export const ClientDetailsInspector = {
  serviceProvider: "kyc-ClientDetailsInspector",
  async GetClientKYCDocuments(
    request: GetClientKYCDocumentsRequest,
  ): Promise<GetClientKYCDocumentsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${ClientDetailsInspector.serviceProvider}.GetClientKYCDocuments`,
      request,
    });
    return {
      documents: response.documents.map(
        (val: KYCDocument) => new KYCDocument(val),
      ),
    };
  },
  async GetIndividualClientPersonalInfo(
    request: GetIndividualClientPersonalInfoRequest,
  ): Promise<GetIndividualClientPersonalInfoResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${ClientDetailsInspector.serviceProvider}.GetIndividualClientPersonalInfo`,
      request,
    });
    return { person: new Person(response.person) };
  },
};
