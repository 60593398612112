import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { WriteOperation, WriteOperationState } from "./WriteOperation";

export interface UNSAFESetWriteOperationIDStateRequest {
  context: Context;
  writeOperationID: string;
  state: WriteOperationState;
  lastActionAnnotation: string;
}

export interface UNSAFESetWriteOperationIDStateResponse {
  writeOperation: WriteOperation;
}

export interface WriteOperationStateControllerServiceProvider {
  serviceProvider: string;

  UNSAFESetWriteOperationIDState(
    request: UNSAFESetWriteOperationIDStateRequest,
  ): Promise<UNSAFESetWriteOperationIDStateResponse>;
}

const WriteOperationStateControllerServiceProviderName =
  "consistency-WriteOperationStateController";

export const WriteOperationStateController: WriteOperationStateControllerServiceProvider =
  {
    serviceProvider: WriteOperationStateControllerServiceProviderName,
    async UNSAFESetWriteOperationIDState(
      request: UNSAFESetWriteOperationIDStateRequest,
    ): Promise<UNSAFESetWriteOperationIDStateResponse> {
      const response = await jsonRPCRequestDEPRECATED({
        url: config.get("consistencyHorizonAuthorizedURL"),
        method: `${WriteOperationStateController.serviceProvider}.UNSAFESetWriteOperationIDState`,
        request,
      });
      return { writeOperation: new WriteOperation(response.writeOperation) };
    },
  };
