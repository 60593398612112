import { MeshCoreLayout } from "layouts";
import { Router } from "routes";
import { InstrumentBuilderRouteBuilder } from "routes/private";
import { RouteType } from "routes/Route";
import { useApplicationContext } from "context/Application/Application";

export const InstrumentBuilder = () => {
  const { viewConfiguration } = useApplicationContext();

  const instrumentBuilderRoutes =
    InstrumentBuilderRouteBuilder(viewConfiguration);

  const allInstrumentBuilderRoutes: RouteType[] = [
    ...instrumentBuilderRoutes.otherRoutes,
  ];
  if (instrumentBuilderRoutes.homeRoute) {
    allInstrumentBuilderRoutes.push(instrumentBuilderRoutes.homeRoute);
  }
  if (instrumentBuilderRoutes.userProfileRoute) {
    allInstrumentBuilderRoutes.push(instrumentBuilderRoutes.userProfileRoute);
  }
  if (instrumentBuilderRoutes.clientProfileRoute) {
    allInstrumentBuilderRoutes.push(instrumentBuilderRoutes.clientProfileRoute);
  }

  return (
    <MeshCoreLayout
      homeRoute={instrumentBuilderRoutes.homeRoute}
      userProfileRoute={instrumentBuilderRoutes.userProfileRoute}
      clientProfileRoute={instrumentBuilderRoutes.clientProfileRoute}
      otherRoutes={instrumentBuilderRoutes.otherRoutes}
    >
      <Router
        baseURL={"/builder"}
        redirectPath={"/builder"}
        routes={allInstrumentBuilderRoutes}
      />
    </MeshCoreLayout>
  );
};
