import React from "react";
import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Variant } from "@mui/material/styles/createTypography";

interface MiniDisplayCardProps {
  title: string;
  tooltip: string;
  info: string;
  infoHeading?: Variant;
}

export const MiniDisplayCard = ({
  title,
  tooltip,
  info,
  infoHeading,
}: MiniDisplayCardProps) => {
  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: {
          sm: "flex-start",
          xs: "center",
        },
        alignItems: {
          sm: "flex-start",
          xs: "center",
        },
        py: 2,
        pl: { sm: 3 },
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.custom.midnight,
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Typography variant="body2" children={title} />
        {tooltip != "" && (
          <Tooltip title={tooltip} placement="top">
            <IconButton sx={{ p: 0, ml: 1 }}>
              <InfoIcon
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Typography
        variant={infoHeading ? infoHeading : "h4"}
        sx={(theme) => ({
          color: theme.palette.text.primary,
          fontWeight: "bold",
        })}
        children={info}
      />
    </Card>
  );
};
