import { Context } from "james/security";
import { Query } from "james/search/query";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Model } from "./Model";

export interface ReadRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadResponse {
  models: Model[];
  total: number;
}

export interface ReadOneRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
}

export interface ReadOneResponse {
  model: Model;
}

export const Reader = {
  serviceProvider: "stellarSourceAccountView-Reader",
  async Read(
    request: ReadRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<ReadResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${Reader.serviceProvider}.Read`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      models: response.models.map((m: Model) => new Model(m)),
      total: response.total,
    };
  },
  async ReadOne(request: ReadOneRequest): Promise<ReadOneResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Reader.serviceProvider}.ReadOne`,
      request,
    });
    return {
      model: new Model(response.model),
    };
  },
};
