export const PublicModelTypeName = "mesh::clientPublicProfileView/PublicModel";

export class PublicModel {
  ["@type"]: string = PublicModelTypeName;

  clientName = "";
  description = "";
  website = "";
  twitter = "";
  clientProfilePicture = "";

  constructor(publicModel?: PublicModel) {
    if (!publicModel) {
      return;
    }
    this.clientName = publicModel.clientName;
    this.description = publicModel.description;
    this.website = publicModel.website;
    this.twitter = publicModel.twitter;
    this.clientProfilePicture = publicModel.clientProfilePicture;
  }
}
