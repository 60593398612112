import { FundingOrderState } from "@mesh/common-js/dist/banking/fundingOrderState_pb";
import { fundingOrderStateToString } from "@mesh/common-js/dist/banking/fundingOrderState";

export enum FundingOrderStatusReason {
  FundingOrderApproved = "Funding Approved",
  FundingOrderRetried = "Funding Order Retried",
  FundingOrderCancelled = "Funding Order Cancelled",
}

export const AllFundingOrderStates: string[] = [
  fundingOrderStateToString(
    FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE,
  ),
  fundingOrderStateToString(
    FundingOrderState.SETTLEMENT_IN_PROGRESS_FUNDING_ORDER_STATE,
  ),
  fundingOrderStateToString(FundingOrderState.CANCELLED_FUNDING_ORDER_STATE),
  fundingOrderStateToString(FundingOrderState.SETTLED_FUNDING_ORDER_STATE),
  fundingOrderStateToString(FundingOrderState.FAILED_FUNDING_ORDER_STATE),
  fundingOrderStateToString(
    FundingOrderState.UNDER_INVESTIGATION_FUNDING_ORDER_STATE,
  ),
];
