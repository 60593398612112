import { Box, Skeleton, SkeletonProps } from "@mui/material";
import React from "react";

interface LoaderProps {
  loading: boolean;
}

export const SkeletonLoader = ({
  children,
  loading,
  ...props
}: LoaderProps & SkeletonProps): JSX.Element => {
  return loading ? (
    <Skeleton {...props}>{children}</Skeleton>
  ) : (
    <Box>{children}</Box>
  );
};
