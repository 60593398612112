import { Box } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import React from "react";
import { ClientSelectorCard } from "./components/ClientSelectorCard";
import { useWidth } from "utilities/general";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import background from "assets/images/background/background.png";

export const ClientSelector = () => {
  const width = useWidth();
  return (
    <>
      {isWidthUp("sm", width) ? (
        <Box
          sx={{
            display: "grid",
            backgroundImage: `url(${background})`,
            gridTemplateRows: "auto 1fr",
            backgroundSize: "cover",
            height: "100vh",
            overflowY: isWidthUp("md", width) ? "hidden" : "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            id={"sign_up"}
            sx={{
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            <ClientSelectorCard />
          </Box>
        </Box>
      ) : (
        <>
          <ClientSelectorCard />
        </>
      )}
    </>
  );
};
