import config from "react-global-configuration";
import { Identifier } from "james/search/identifier/Identifier";
import { PublicModel } from "./Model";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface PublicReadOneRequest {
  identifier: Identifier;
}

export interface PublicReadOneResponse {
  publicModel: PublicModel;
}

export const Reader = {
  serviceProvider: "clientPublicProfileView-Reader",
  async PublicReadOne(
    request: PublicReadOneRequest,
  ): Promise<PublicReadOneResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${Reader.serviceProvider}.PublicReadOne`,
      request,
    });

    return {
      publicModel: new PublicModel(response.publicModel),
    };
  },
};
