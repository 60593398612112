import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { SnackbarKey, SnackbarProvider } from "notistack";
const PREFIX = "SnackbarContext";

const classes = {
  loadingRoot: `${PREFIX}-loadingRoot`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  content: `${PREFIX}-content`,
  snackbarIconColor: `${PREFIX}-snackbarIconColor`,
  infoSnackbar: `${PREFIX}-infoSnackbar`,
  successSnackbar: `${PREFIX}-successSnackbar`,
  errorSnackbar: `${PREFIX}-errorSnackbar`,
  warningSnackbar: `${PREFIX}-warningSnackbar`,
  containerAnchorOriginBottomLeft: `${PREFIX}-containerAnchorOriginBottomLeft`,
};

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  [`& .${classes.loadingRoot}`]: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.backgroundImage}`]: {
    minHeight: "100%",
    minWidth: "1024px",
    width: "100%",
    height: "auto",
    position: "fixed",
    top: 0,
    left: 0,
  },

  [`& .${classes.content}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.snackbarIconColor}`]: {
    color: `${theme.palette.secondary.contrastText} !important`,
  },

  [`& .${classes.infoSnackbar}`]: {
    backgroundColor: `${theme.palette.info.dark} !important`,
  },

  [`& .${classes.successSnackbar}`]: {
    backgroundColor: `${theme.palette.success.dark} !important`,
  },

  [`& .${classes.errorSnackbar}`]: {
    backgroundColor: `${theme.palette.error.dark} !important`,
  },

  [`& .${classes.warningSnackbar}`]: {
    backgroundColor: `${theme.palette.warning.dark} !important`,
  },

  [`& .${classes.containerAnchorOriginBottomLeft}`]: {
    paddingLeft: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      bottom: `${78}px !important`,
    },
  },
}));

export function SnackbarContext({ children }: { children?: React.ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notistackRef = React.createRef() as any;
  const onClose = (key: SnackbarKey) => () => {
    notistackRef?.current.closeSnackbar(key);
  };

  return (
    <StyledSnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      action={(key) => (
        <IconButton
          className={classes.snackbarIconColor}
          onClick={onClose(key)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
      classes={{
        // variantInfo: classes.infoSnackbar,
        // variantSuccess: classes.successSnackbar,
        // variantError: classes.errorSnackbar,
        // variantWarning: classes.warningSnackbar,
        containerAnchorOriginBottomLeft:
          classes.containerAnchorOriginBottomLeft,
      }}
    >
      {children}
    </StyledSnackbarProvider>
  );
}
