import { IconButton, TextField, Tooltip } from "@mui/material";
import {
  EditOutlined as EditIcon,
  ExploreOutlined as ExploreIcon,
} from "@mui/icons-material";
import { CreateOrEditGroupDialog } from "components/CreateOrEditGroupDialog/CreateOrEditGroupDialog";
import { BPTable } from "components/Table";
import { Group } from "james/group";
import { useSearchGroupsWithRoles } from "james/group/Compository";
import { GroupWithRoles } from "james/group/GroupWithRoles";
import { TextSubstringCriterion } from "james/search/criterion/text";
import { Query } from "james/search/query";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const initialQuery = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

export function Table() {
  const [createOrEditGroupDialogOpen, setCreateOrEditGroupDialogOpen] =
    useState(false);
  const [selectedGroupsWithRoles, setSelectedGroupsWithRoles] = useState<
    GroupWithRoles[]
  >([]);
  const { authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    loading,
    searchGroupsWithRolesResponse,
    searchGroupsWithRolesRequest,
    setSearchGroupsWithRolesRequest,
  } = useSearchGroupsWithRoles({
    context: authContext,
    query: new Query(initialQuery),
    criteria: {},
  });
  const navigate = useNavigate();

  return (
    <>
      <BPTable
        height={window.innerHeight - 138 - noticeBannerHeight}
        loading={loading}
        columns={[
          {
            field: "name",
            label: "Name",
          },
          {
            field: "roles",
            label: "Roles",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const groupWithRoles = data as GroupWithRoles;
              return groupWithRoles.roles.map((role) => role.name).join(", ");
            },
          },
        ]}
        data={searchGroupsWithRolesResponse.records}
        title="Groups"
        filters={[
          <TextField
            id="groupsTable-groupName-formField"
            label="Group Name"
            onChange={(e) => {
              if (e.target.value) {
                setSearchGroupsWithRolesRequest({
                  ...searchGroupsWithRolesRequest,
                  criteria: {
                    ...searchGroupsWithRolesRequest.criteria,
                    name: TextSubstringCriterion(e.target.value),
                  },
                  query: new Query(initialQuery),
                });
              } else if (searchGroupsWithRolesRequest.criteria.name) {
                delete searchGroupsWithRolesRequest.criteria.name;
                setSearchGroupsWithRolesRequest({
                  ...searchGroupsWithRolesRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
            placeholder="Start Typing..."
          />,
        ]}
        toolBarControls={(() => {
          if (selectedGroupsWithRoles.length === 1) {
            // only 1 group selected, show edit and explore buttons
            return [
              <Tooltip title="Edit">
                <IconButton
                  id="groupsTable-edit-button"
                  size="small"
                  onClick={() => setCreateOrEditGroupDialogOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>,
              <Tooltip title="Explore">
                <IconButton
                  id="groupsTable-explore-button"
                  size="small"
                  onClick={() =>
                    navigate(
                      `/administration/manage/groups/explore?id=${selectedGroupsWithRoles[0].id}`,
                    )
                  }
                >
                  <ExploreIcon />
                </IconButton>
              </Tooltip>,
            ];
          }
          if (selectedGroupsWithRoles.length > 1) {
            // more than 1 group selected, return no buttons for now
            return [];
          }
        })()}
        query={searchGroupsWithRolesRequest.query}
        onQueryChange={(query: Query) =>
          setSearchGroupsWithRolesRequest({
            ...searchGroupsWithRolesRequest,
            query,
          })
        }
        totalNoRecords={searchGroupsWithRolesResponse.total}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelectedDataChange={(allSelectedData: { [key: string]: any }[]) =>
          setSelectedGroupsWithRoles(allSelectedData as GroupWithRoles[])
        }
      />

      {createOrEditGroupDialogOpen && (
        <CreateOrEditGroupDialog
          group={new Group(selectedGroupsWithRoles[0])}
          closeDialog={() => setCreateOrEditGroupDialogOpen(false)}
          onUpdateGroup={() =>
            setSearchGroupsWithRolesRequest({
              ...searchGroupsWithRolesRequest,
              query: new Query(initialQuery),
            })
          }
          onCreateGroup={(createdGroup: Group) => {
            navigate(
              `/administration/manage/groups/explore?id=${createdGroup.id}`,
            );
          }}
        />
      )}
    </>
  );
}
