"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allFiatCurrencyCodes = void 0;
exports.fiatCurrencyCodeToString = fiatCurrencyCodeToString;
exports.stringToFiatCurrencyCode = stringToFiatCurrencyCode;
const fiatCurrencyCode_pb_1 = require("./fiatCurrencyCode_pb");
// Get all frequencies as enum values
exports.allFiatCurrencyCodes = Object.values(fiatCurrencyCode_pb_1.FiatCurrencyCode).filter((value) => typeof value === "number");
// Define explicit mappings between FiatCurrencyCode enums and custom string representations
const fiatCurrencyCodeToStringMapping = {
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UNDEFINED_FIAT_CURRENCY_CODE]: "-",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BARBADOS_DOLLAR_BBD_FIAT_CURRENCY_CODE]: "BBD - Barbados Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CFA_FRANC_BEAC_XAF_FIAT_CURRENCY_CODE]: "XAF - CFA Franc BEAC",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AUSTRALIAN_DOLLAR_AUD_FIAT_CURRENCY_CODE]: "AUD - Australian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.US_DOLLAR_USD_FIAT_CURRENCY_CODE]: "USD - US Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.EURO_EUR_FIAT_CURRENCY_CODE]: "EUR - Euro",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZLOTY_PLN_FIAT_CURRENCY_CODE]: "PLN - Zloty",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DOBRA_STN_FIAT_CURRENCY_CODE]: "STN - Dobra",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NORWEGIAN_KRONE_NOK_FIAT_CURRENCY_CODE]: "NOK - Norwegian Krone",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOND_MARKETS_UNIT_EUROPEAN_COMPOSITE_UNIT_EURCO_XBA_FIAT_CURRENCY_CODE]: "XBA - Bond Markets Unit European Composite Unit (EURCO)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOND_MARKETS_UNIT_EUROPEAN_MONETARY_UNIT_EMU6_XBB_FIAT_CURRENCY_CODE]: "XBB - Bond Markets Unit European Monetary Unit (E.M.U.-6)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ANDORRAN_PESETA_ADP_FIAT_CURRENCY_CODE]: "ADP - Andorran Peseta",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GUINEABISSAU_PESO_GWP_FIAT_CURRENCY_CODE]: "GWP - Guinea-Bissau Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ITALIAN_LIRA_ITL_FIAT_CURRENCY_CODE]: "ITL - Italian Lira",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.IRISH_POUND_IEP_FIAT_CURRENCY_CODE]: "IEP - Irish Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_SHEKEL_ILR_FIAT_CURRENCY_CODE]: "ILR - Old Shekel",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LUXEMBOURG_CONVERTIBLE_FRANC_LUC_FIAT_CURRENCY_CODE]: "LUC - Luxembourg Convertible Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LUXEMBOURG_FINANCIAL_FRANC_LUL_FIAT_CURRENCY_CODE]: "LUL - Luxembourg Financial Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_LEU_ROL_FIAT_CURRENCY_CODE]: "ROL - Old Leu",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RUSSIAN_RUBLE_RUR_FIAT_CURRENCY_CODE]: "RUR - Russian Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOLIVAR_FUERTE_VEF_FIAT_CURRENCY_CODE]: "VEF - Bolivar Fuerte",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZIMBABWE_DOLLAR_NEW_ZWN_FIAT_CURRENCY_CODE]: "ZWN - Zimbabwe Dollar (new)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DANISH_KRONE_DKK_FIAT_CURRENCY_CODE]: "DKK - Danish Krone",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOL_PEN_FIAT_CURRENCY_CODE]: "PEN - Sol",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.EAST_CARIBBEAN_DOLLAR_XCD_FIAT_CURRENCY_CODE]: "XCD - East Caribbean Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SUCRE_XSU_FIAT_CURRENCY_CODE]: "XSU - Sucre",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TUNISIAN_DINAR_TND_FIAT_CURRENCY_CODE]: "TND - Tunisian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.VATU_VUV_FIAT_CURRENCY_CODE]: "VUV - Vatu",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.INTI_PEI_FIAT_CURRENCY_CODE]: "PEI - Inti",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZLOTY_PLZ_FIAT_CURRENCY_CODE]: "PLZ - Zloty",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SPANISH_PESETA_ESA_FIAT_CURRENCY_CODE]: "ESA - Spanish Peseta",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOLIVIANO_BOB_FIAT_CURRENCY_CODE]: "BOB - Boliviano",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.POUND_STERLING_GBP_FIAT_CURRENCY_CODE]: "GBP - Pound Sterling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NORTH_KOREAN_WON_KPW_FIAT_CURRENCY_CODE]: "KPW - North Korean Won",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALAGASY_ARIARY_MGA_FIAT_CURRENCY_CODE]: "MGA - Malagasy Ariary",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RIAL_OMANI_OMR_FIAT_CURRENCY_CODE]: "OMR - Rial Omani",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RWANDA_FRANC_RWF_FIAT_CURRENCY_CODE]: "RWF - Rwanda Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEONE_SLL_FIAT_CURRENCY_CODE]: "SLL - Leone",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SINGAPORE_DOLLAR_SGD_FIAT_CURRENCY_CODE]: "SGD - Singapore Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CFP_FRANC_XPF_FIAT_CURRENCY_CODE]: "XPF - CFP Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_ROMANIAN_LEU_RON_FIAT_CURRENCY_CODE]: "RON - New Romanian Leu",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.FINANCIAL_RAND_ZAL_FIAT_CURRENCY_CODE]: "ZAL - Financial Rand",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SPANISH_PESETA_ESP_FIAT_CURRENCY_CODE]: "ESP - Spanish Peseta",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOLIVAR_VEB_FIAT_CURRENCY_CODE]: "VEB - Bolivar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_DONG_VNC_FIAT_CURRENCY_CODE]: "VNC - Old Dong",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NGULTRUM_BTN_FIAT_CURRENCY_CODE]: "BTN - Ngultrum",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.YUAN_RENMINBI_CNY_FIAT_CURRENCY_CODE]: "CNY - Yuan Renminbi",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.EL_SALVADOR_COLON_SVC_FIAT_CURRENCY_CODE]: "SVC - El Salvador Colon",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.HONG_KONG_DOLLAR_HKD_FIAT_CURRENCY_CODE]: "HKD - Hong Kong Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LOTI_LSL_FIAT_CURRENCY_CODE]: "LSL - Loti",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MAURITIUS_RUPEE_MUR_FIAT_CURRENCY_CODE]: "MUR - Mauritius Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RAND_ZAR_FIAT_CURRENCY_CODE]: "ZAR - Rand",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_ZEALAND_DOLLAR_NZD_FIAT_CURRENCY_CODE]: "NZD - New Zealand Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GOLD_XAU_FIAT_CURRENCY_CODE]: "XAU - Gold",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CROATIAN_KUNA_HRK_FIAT_CURRENCY_CODE]: "HRK - Croatian Kuna",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PATHET_LAO_KIP_LAJ_FIAT_CURRENCY_CODE]: "LAJ - Pathet Lao Kip",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BULGARIAN_LEV_BGN_FIAT_CURRENCY_CODE]: "BGN - Bulgarian Lev",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LARI_GEL_FIAT_CURRENCY_CODE]: "GEL - Lari",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KENYAN_SHILLING_KES_FIAT_CURRENCY_CODE]: "KES - Kenyan Shilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TUGRIK_MNT_FIAT_CURRENCY_CODE]: "MNT - Tugrik",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SAINT_HELENA_POUND_SHP_FIAT_CURRENCY_CODE]: "SHP - Saint Helena Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.HRYVNIA_UAH_FIAT_CURRENCY_CODE]: "UAH - Hryvnia",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_YUGOSLAVIAN_DINAR_YUD_FIAT_CURRENCY_CODE]: "YUD - New Yugoslavian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DJIBOUTI_FRANC_DJF_FIAT_CURRENCY_CODE]: "DJF - Djibouti Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.QUETZAL_GTQ_FIAT_CURRENCY_CODE]: "GTQ - Quetzal",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NETHERLANDS_ANTILLEAN_GUILDER_ANG_FIAT_CURRENCY_CODE]: "ANG - Netherlands Antillean Guilder",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KYAT_BUK_FIAT_CURRENCY_CODE]: "BUK - Kyat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.FRENCH_FRANC_FRF_FIAT_CURRENCY_CODE]: "FRF - French Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MEXICAN_PESO_MXP_FIAT_CURRENCY_CODE]: "MXP - Mexican Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NETHERLANDS_GUILDER_NLG_FIAT_CURRENCY_CODE]: "NLG - Netherlands Guilder",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_SHILLING_UGW_FIAT_CURRENCY_CODE]: "UGW - Old Shilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.YUGOSLAVIAN_DINAR_YUN_FIAT_CURRENCY_CODE]: "YUN - Yugoslavian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BAHRAINI_DINAR_BHD_FIAT_CURRENCY_CODE]: "BHD - Bahraini Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UNIDAD_DE_FOMENTO_CLF_FIAT_CURRENCY_CODE]: "CLF - Unidad de Fomento",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NAKFA_ERN_FIAT_CURRENCY_CODE]: "ERN - Nakfa",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GUYANA_DOLLAR_GYD_FIAT_CURRENCY_CODE]: "GYD - Guyana Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BALBOA_PAB_FIAT_CURRENCY_CODE]: "PAB - Balboa",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CFA_FRANC_BCEAO_XOF_FIAT_CURRENCY_CODE]: "XOF - CFA Franc BCEAO",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CODES_SPECIFICALLY_RESERVED_FOR_TESTING_PURPOSES_XTS_FIAT_CURRENCY_CODE]: "XTS - Codes specifically reserved for testing purposes",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PESO_ARGENTINO_ARP_FIAT_CURRENCY_CODE]: "ARP - Peso Argentino",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TALONAS_LTT_FIAT_CURRENCY_CODE]: "LTT - Talonas",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MOZAMBIQUE_METICAL_MZM_FIAT_CURRENCY_CODE]: "MZM - Mozambique Metical",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PORTUGUESE_ESCUDO_PTE_FIAT_CURRENCY_CODE]: "PTE - Portuguese Escudo",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SERBIAN_DINAR_CSD_FIAT_CURRENCY_CODE]: "CSD - Serbian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZAMBIAN_KWACHA_ZMK_FIAT_CURRENCY_CODE]: "ZMK - Zambian Kwacha",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BELIZE_DOLLAR_BZD_FIAT_CURRENCY_CODE]: "BZD - Belize Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.FALKLAND_ISLANDS_POUND_FKP_FIAT_CURRENCY_CODE]: "FKP - Falkland Islands Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MEXICAN_UNIDAD_DE_INVERSION_UDI_MXV_FIAT_CURRENCY_CODE]: "MXV - Mexican Unidad de Inversion (UDI)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOLOMON_ISLANDS_DOLLAR_SBD_FIAT_CURRENCY_CODE]: "SBD - Solomon Islands Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.WIR_FRANC_CHW_FIAT_CURRENCY_CODE]: "CHW - WIR Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_TAIWAN_DOLLAR_TWD_FIAT_CURRENCY_CODE]: "TWD - New Taiwan Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TRINIDAD_AND_TOBAGO_DOLLAR_TTD_FIAT_CURRENCY_CODE]: "TTD - Trinidad and Tobago Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TURKISH_LIRA_TRY_FIAT_CURRENCY_CODE]: "TRY - Turkish Lira",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PESO_URUGUAYO_UYU_FIAT_CURRENCY_CODE]: "UYU - Peso Uruguayo",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CONVERTIBLE_FRANC_BEC_FIAT_CURRENCY_CODE]: "BEC - Convertible Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.FINANCIAL_FRANC_BEL_FIAT_CURRENCY_CODE]: "BEL - Financial Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DRACHMA_GRD_FIAT_CURRENCY_CODE]: "GRD - Drachma",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RUPIAH_IDR_FIAT_CURRENCY_CODE]: "IDR - Rupiah",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZAIRE_ZRZ_FIAT_CURRENCY_CODE]: "ZRZ - Zaire",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BELARUSIAN_RUBLE_BYN_FIAT_CURRENCY_CODE]: "BYN - Belarusian Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ICELAND_KRONA_ISK_FIAT_CURRENCY_CODE]: "ISK - Iceland Krona",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LIBERIAN_DOLLAR_LRD_FIAT_CURRENCY_CODE]: "LRD - Liberian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AUSTRAL_ARA_FIAT_CURRENCY_CODE]: "ARA - Austral",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MARK_DER_DDR_DDM_FIAT_CURRENCY_CODE]: "DDM - Mark der DDR",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CEDI_GHC_FIAT_CURRENCY_CODE]: "GHC - Cedi",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SUDANESE_POUND_SDP_FIAT_CURRENCY_CODE]: "SDP - Sudanese Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KARBOVANET_UAK_FIAT_CURRENCY_CODE]: "UAK - Karbovanet",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GOURDE_HTG_FIAT_CURRENCY_CODE]: "HTG - Gourde",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.WON_KRW_FIAT_CURRENCY_CODE]: "KRW - Won",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.QATARI_RIAL_QAR_FIAT_CURRENCY_CODE]: "QAR - Qatari Rial",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CORDOBA_NIC_FIAT_CURRENCY_CODE]: "NIC - Cordoba",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TOLAR_SIT_FIAT_CURRENCY_CODE]: "SIT - Tolar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SUDANESE_POUND_SDG_FIAT_CURRENCY_CODE]: "SDG - Sudanese Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.WIR_FRANC_FOR_ELECTRONIC_CHC_FIAT_CURRENCY_CODE]: "CHC - WIR Franc (for electronic)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BAHAMIAN_DOLLAR_BSD_FIAT_CURRENCY_CODE]: "BSD - Bahamian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LAO_KIP_LAK_FIAT_CURRENCY_CODE]: "LAK - Lao Kip",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MOLDOVAN_LEU_MDL_FIAT_CURRENCY_CODE]: "MDL - Moldovan Leu",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MOZAMBIQUE_METICAL_MZN_FIAT_CURRENCY_CODE]: "MZN - Mozambique Metical",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOUTH_SUDANESE_POUND_SSP_FIAT_CURRENCY_CODE]: "SSP - South Sudanese Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.URUGUAY_PESO_EN_UNIDADES_INDEXADAS_UI_UYI_FIAT_CURRENCY_CODE]: "UYI - Uruguay Peso en Unidades Indexadas (UI)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BELARUSIAN_RUBLE_BYR_FIAT_CURRENCY_CODE]: "BYR - Belarusian Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KRONA_A53_CSJ_FIAT_CURRENCY_CODE]: "CSJ - Krona A/53",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GUINEA_ESCUDO_GWE_FIAT_CURRENCY_CODE]: "GWE - Guinea Escudo",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LUXEMBOURG_FRANC_LUF_FIAT_CURRENCY_CODE]: "LUF - Luxembourg Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KWANZA_AOA_FIAT_CURRENCY_CODE]: "AOA - Kwanza",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PHILIPPINE_PESO_PHP_FIAT_CURRENCY_CODE]: "PHP - Philippine Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TALA_WST_FIAT_CURRENCY_CODE]: "WST - Tala",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DINAR_BAD_FIAT_CURRENCY_CODE]: "BAD - Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_CRUZADO_BRN_FIAT_CURRENCY_CODE]: "BRN - New Cruzado",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CRUZEIRO_REAL_BRR_FIAT_CURRENCY_CODE]: "BRR - Cruzeiro Real",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CYPRUS_POUND_CYP_FIAT_CURRENCY_CODE]: "CYP - Cyprus Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SYLI_GNS_FIAT_CURRENCY_CODE]: "GNS - Syli",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LITHUANIAN_LITAS_LTL_FIAT_CURRENCY_CODE]: "LTL - Lithuanian Litas",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALTESE_POUND_MTP_FIAT_CURRENCY_CODE]: "MTP - Maltese Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_URUGUAY_PESO_UYN_FIAT_CURRENCY_CODE]: "UYN - Old Uruguay Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ALGERIAN_DINAR_DZD_FIAT_CURRENCY_CODE]: "DZD - Algerian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ARUBAN_FLORIN_AWG_FIAT_CURRENCY_CODE]: "AWG - Aruban Florin",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CANADIAN_DOLLAR_CAD_FIAT_CURRENCY_CODE]: "CAD - Canadian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CAYMAN_ISLANDS_DOLLAR_KYD_FIAT_CURRENCY_CODE]: "KYD - Cayman Islands Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SYRIAN_POUND_SYP_FIAT_CURRENCY_CODE]: "SYP - Syrian Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TANZANIAN_SHILLING_TZS_FIAT_CURRENCY_CODE]: "TZS - Tanzanian Shilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MARKKA_FIM_FIAT_CURRENCY_CODE]: "FIM - Markka",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALDIVE_RUPEE_MVQ_FIAT_CURRENCY_CODE]: "MVQ - Maldive Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ROUBLE_SUR_FIAT_CURRENCY_CODE]: "SUR - Rouble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.US_DOLLAR_SAME_DAY_USS_FIAT_CURRENCY_CODE]: "USS - US Dollar (Same day)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_ZAIRE_ZRN_FIAT_CURRENCY_CODE]: "ZRN - New Zaire",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RIEL_KHR_FIAT_CURRENCY_CODE]: "KHR - Riel",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.COSTA_RICAN_COLON_CRC_FIAT_CURRENCY_CODE]: "CRC - Costa Rican Colon",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.INDIAN_RUPEE_INR_FIAT_CURRENCY_CODE]: "INR - Indian Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CORDOBA_ORO_NIO_FIAT_CURRENCY_CODE]: "NIO - Cordoba Oro",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SRI_LANKA_RUPEE_LKR_FIAT_CURRENCY_CODE]: "LKR - Sri Lanka Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SURINAM_DOLLAR_SRD_FIAT_CURRENCY_CODE]: "SRD - Surinam Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UNIDAD_PREVISIONAL_UYW_FIAT_CURRENCY_CODE]: "UYW - Unidad Previsional",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SILVER_XAG_FIAT_CURRENCY_CODE]: "XAG - Silver",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DEUTSCHE_MARK_DEM_FIAT_CURRENCY_CODE]: "DEM - Deutsche Mark",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UICFRANC_XFU_FIAT_CURRENCY_CODE]: "XFU - UIC-Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CABO_VERDE_ESCUDO_CVE_FIAT_CURRENCY_CODE]: "CVE - Cabo Verde Escudo",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CHILEAN_PESO_CLP_FIAT_CURRENCY_CODE]: "CLP - Chilean Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CONGOLESE_FRANC_CDF_FIAT_CURRENCY_CODE]: "CDF - Congolese Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PESO_CONVERTIBLE_CUC_FIAT_CURRENCY_CODE]: "CUC - Peso Convertible",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.WIR_EURO_CHE_FIAT_CURRENCY_CODE]: "CHE - WIR Euro",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOND_MARKETS_UNIT_EUROPEAN_UNIT_OF_ACCOUNT_17_EUA17_XBD_FIAT_CURRENCY_CODE]: "XBD - Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_LEK_ALK_FIAT_CURRENCY_CODE]: "ALK - Old Lek",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEV_A62_BGK_FIAT_CURRENCY_CODE]: "BGK - Lev A/62",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KROON_EEK_FIAT_CURRENCY_CODE]: "EEK - Kroon",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GEORGIAN_COUPON_GEK_FIAT_CURRENCY_CODE]: "GEK - Georgian Coupon",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LATVIAN_RUBLE_LVR_FIAT_CURRENCY_CODE]: "LVR - Latvian Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_TURKISH_LIRA_TRL_FIAT_CURRENCY_CODE]: "TRL - Old Turkish Lira",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AZERBAIJAN_MANAT_AZN_FIAT_CURRENCY_CODE]: "AZN - Azerbaijan Manat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CZECH_KORUNA_CZK_FIAT_CURRENCY_CODE]: "CZK - Czech Koruna",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UGANDA_SHILLING_UGX_FIAT_CURRENCY_CODE]: "UGX - Uganda Shilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.US_DOLLAR_NEXT_DAY_USN_FIAT_CURRENCY_CODE]: "USN - US Dollar (Next day)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PLATINUM_XPT_FIAT_CURRENCY_CODE]: "XPT - Platinum",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CRUZEIRO_BRB_FIAT_CURRENCY_CODE]: "BRB - Cruzeiro",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEV_BGL_FIAT_CURRENCY_CODE]: "BGL - Lev",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LOTI_LSM_FIAT_CURRENCY_CODE]: "LSM - Loti",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOL_PEH_FIAT_CURRENCY_CODE]: "PEH - Sol",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.URUGUAYAN_PESO_UYP_FIAT_CURRENCY_CODE]: "UYP - Uruguayan Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RINET_FUNDS_CODE_XRE_FIAT_CURRENCY_CODE]: "XRE - RINET Funds Code",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BERMUDIAN_DOLLAR_BMD_FIAT_CURRENCY_CODE]: "BMD - Bermudian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MVDOL_BOV_FIAT_CURRENCY_CODE]: "BOV - Mvdol",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PULA_BWP_FIAT_CURRENCY_CODE]: "BWP - Pula",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BURUNDI_FRANC_BIF_FIAT_CURRENCY_CODE]: "BIF - Burundi Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DOMINICAN_PESO_DOP_FIAT_CURRENCY_CODE]: "DOP - Dominican Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TENGE_KZT_FIAT_CURRENCY_CODE]: "KZT - Tenge",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SERBIAN_DINAR_RSD_FIAT_CURRENCY_CODE]: "RSD - Serbian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZAMBIAN_KWACHA_ZMW_FIAT_CURRENCY_CODE]: "ZMW - Zambian Kwacha",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BELGIAN_FRANC_BEF_FIAT_CURRENCY_CODE]: "BEF - Belgian Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CRUZADO_BRC_FIAT_CURRENCY_CODE]: "BRC - Cruzado",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KORUNA_CSK_FIAT_CURRENCY_CODE]: "CSK - Koruna",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.EKWELE_GQE_FIAT_CURRENCY_CODE]: "GQE - Ekwele",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GHANA_CEDI_GHP_FIAT_CURRENCY_CODE]: "GHP - Ghana Cedi",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OLD_KRONA_ISJ_FIAT_CURRENCY_CODE]: "ISJ - Old Krona",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LATVIAN_LATS_LVL_FIAT_CURRENCY_CODE]: "LVL - Latvian Lats",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TIMOR_ESCUDO_TPE_FIAT_CURRENCY_CODE]: "TPE - Timor Escudo",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UGANDA_SHILLING_UGS_FIAT_CURRENCY_CODE]: "UGS - Uganda Shilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GOLDFRANC_XFO_FIAT_CURRENCY_CODE]: "XFO - Gold-Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AFGHANI_AFN_FIAT_CURRENCY_CODE]: "AFN - Afghani",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.COMORIAN_FRANC_KMF_FIAT_CURRENCY_CODE]: "KMF - Comorian Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DALASI_GMD_FIAT_CURRENCY_CODE]: "GMD - Dalasi",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.IRANIAN_RIAL_IRR_FIAT_CURRENCY_CODE]: "IRR - Iranian Rial",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_ISRAELI_SHEQEL_ILS_FIAT_CURRENCY_CODE]: "ILS - New Israeli Sheqel",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOM_KGS_FIAT_CURRENCY_CODE]: "KGS - Som",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEPALESE_RUPEE_NPR_FIAT_CURRENCY_CODE]: "NPR - Nepalese Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SAUDI_RIYAL_SAR_FIAT_CURRENCY_CODE]: "SAR - Saudi Riyal",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOLIVAR_SOBERANO_VES_FIAT_CURRENCY_CODE]: "VES - Bolívar Soberano",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.THE_CODES_ASSIGNED_FOR_TRANSACTIONS_WHERE_NO_CURRENCY_IS_INVOLVED_XXX_FIAT_CURRENCY_CODE]: "XXX - The codes assigned for transactions where no currency is involved",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CROATIAN_DINAR_HRD_FIAT_CURRENCY_CODE]: "HRD - Croatian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEU_A52_ROK_FIAT_CURRENCY_CODE]: "ROK - Leu A/52",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEK_ALL_FIAT_CURRENCY_CODE]: "ALL - Lek",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GUINEAN_FRANC_GNF_FIAT_CURRENCY_CODE]: "GNF - Guinean Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.JORDANIAN_DINAR_JOD_FIAT_CURRENCY_CODE]: "JOD - Jordanian Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALAWI_KWACHA_MWK_FIAT_CURRENCY_CODE]: "MWK - Malawi Kwacha",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SWEDISH_KRONA_SEK_FIAT_CURRENCY_CODE]: "SEK - Swedish Krona",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PALLADIUM_XPD_FIAT_CURRENCY_CODE]: "XPD - Palladium",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CRUZEIRO_BRE_FIAT_CURRENCY_CODE]: "BRE - Cruzeiro",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UNIDAD_DE_VALOR_CONSTANTE_UVC_ECV_FIAT_CURRENCY_CODE]: "ECV - Unidad de Valor Constante (UVC)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DOBRA_STD_FIAT_CURRENCY_CODE]: "STD - Dobra",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TURKMENISTAN_MANAT_TMM_FIAT_CURRENCY_CODE]: "TMM - Turkmenistan Manat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RUFIYAA_MVR_FIAT_CURRENCY_CODE]: "MVR - Rufiyaa",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KINA_PGK_FIAT_CURRENCY_CODE]: "PGK - Kina",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UAE_DIRHAM_AED_FIAT_CURRENCY_CODE]: "AED - UAE Dirham",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UZBEKISTAN_SUM_UZS_FIAT_CURRENCY_CODE]: "UZS - Uzbekistan Sum",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AZERBAIJAN_MANAT_AYM_FIAT_CURRENCY_CODE]: "AYM - Azerbaijan Manat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PESO_BOLIVIANO_BOP_FIAT_CURRENCY_CODE]: "BOP - Peso boliviano",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEV_A52_BGJ_FIAT_CURRENCY_CODE]: "BGJ - Lev A/52",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SLOVAK_KORUNA_SKK_FIAT_CURRENCY_CODE]: "SKK - Slovak Koruna",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CUBAN_PESO_CUP_FIAT_CURRENCY_CODE]: "CUP - Cuban Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MOROCCAN_DIRHAM_MAD_FIAT_CURRENCY_CODE]: "MAD - Moroccan Dirham",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NAIRA_NGN_FIAT_CURRENCY_CODE]: "NGN - Naira",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AFGHANI_AFA_FIAT_CURRENCY_CODE]: "AFA - Afghani",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SUCRE_ECS_FIAT_CURRENCY_CODE]: "ECS - Sucre",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOL_PES_FIAT_CURRENCY_CODE]: "PES - Sol",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.UNIDAD_DE_VALOR_REAL_COU_FIAT_CURRENCY_CODE]: "COU - Unidad de Valor Real",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KUWAITI_DINAR_KWD_FIAT_CURRENCY_CODE]: "KWD - Kuwaiti Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEBANESE_POUND_LBP_FIAT_CURRENCY_CODE]: "LBP - Lebanese Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SCHILLING_ATS_FIAT_CURRENCY_CODE]: "ATS - Schilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BELARUSIAN_RUBLE_BYB_FIAT_CURRENCY_CODE]: "BYB - Belarusian Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZIMBABWE_DOLLAR_ZWD_FIAT_CURRENCY_CODE]: "ZWD - Zimbabwe Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ARMENIAN_DRAM_AMD_FIAT_CURRENCY_CODE]: "AMD - Armenian Dram",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GIBRALTAR_POUND_GIP_FIAT_CURRENCY_CODE]: "GIP - Gibraltar Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DENAR_MKD_FIAT_CURRENCY_CODE]: "MKD - Denar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALAYSIAN_RINGGIT_MYR_FIAT_CURRENCY_CODE]: "MYR - Malaysian Ringgit",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MEXICAN_PESO_MXN_FIAT_CURRENCY_CODE]: "MXN - Mexican Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RUSSIAN_RUBLE_RUB_FIAT_CURRENCY_CODE]: "RUB - Russian Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SWISS_FRANC_CHF_FIAT_CURRENCY_CODE]: "CHF - Swiss Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BAHT_THB_FIAT_CURRENCY_CODE]: "THB - Baht",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PAANGA_TOP_FIAT_CURRENCY_CODE]: "TOP - Pa'anga",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TURKMENISTAN_NEW_MANAT_TMT_FIAT_CURRENCY_CODE]: "TMT - Turkmenistan New Manat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PESO_ARY_FIAT_CURRENCY_CODE]: "ARY - Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.EUROPEAN_CURRENCY_UNIT_ECU_XEU_FIAT_CURRENCY_CODE]: "XEU - European Currency Unit (E.C.U)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ARGENTINE_PESO_ARS_FIAT_CURRENCY_CODE]: "ARS - Argentine Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ETHIOPIAN_BIRR_ETB_FIAT_CURRENCY_CODE]: "ETB - Ethiopian Birr",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SDR_SPECIAL_DRAWING_RIGHT_XDR_FIAT_CURRENCY_CODE]: "XDR - SDR (Special Drawing Right)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.IRAQI_DINAR_IQD_FIAT_CURRENCY_CODE]: "IQD - Iraqi Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ADB_UNIT_OF_ACCOUNT_XUA_FIAT_CURRENCY_CODE]: "XUA - ADB Unit of Account",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NAMIBIA_DOLLAR_NAD_FIAT_CURRENCY_CODE]: "NAD - Namibia Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PAKISTAN_RUPEE_PKR_FIAT_CURRENCY_CODE]: "PKR - Pakistan Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOMONI_TJS_FIAT_CURRENCY_CODE]: "TJS - Somoni",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.YEMENI_RIAL_YER_FIAT_CURRENCY_CODE]: "YER - Yemeni Rial",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZIMBABWE_DOLLAR_ZWL_FIAT_CURRENCY_CODE]: "ZWL - Zimbabwe Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KWANZA_AOK_FIAT_CURRENCY_CODE]: "AOK - Kwanza",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RHODESIAN_DOLLAR_RHD_FIAT_CURRENCY_CODE]: "RHD - Rhodesian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_DINAR_YUM_FIAT_CURRENCY_CODE]: "YUM - New Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BRUNEI_DOLLAR_BND_FIAT_CURRENCY_CODE]: "BND - Brunei Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GHANA_CEDI_GHS_FIAT_CURRENCY_CODE]: "GHS - Ghana Cedi",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SOMALI_SHILLING_SOS_FIAT_CURRENCY_CODE]: "SOS - Somali Shilling",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.ZIMBABWE_DOLLAR_ZWR_FIAT_CURRENCY_CODE]: "ZWR - Zimbabwe Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.CONVERTIBLE_MARK_BAM_FIAT_CURRENCY_CODE]: "BAM - Convertible Mark",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.COLOMBIAN_PESO_COP_FIAT_CURRENCY_CODE]: "COP - Colombian Peso",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.FORINT_HUF_FIAT_CURRENCY_CODE]: "HUF - Forint",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.JAMAICAN_DOLLAR_JMD_FIAT_CURRENCY_CODE]: "JMD - Jamaican Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LIBYAN_DINAR_LYD_FIAT_CURRENCY_CODE]: "LYD - Libyan Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BOND_MARKETS_UNIT_EUROPEAN_UNIT_OF_ACCOUNT_9_EUA9_XBC_FIAT_CURRENCY_CODE]: "XBC - Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALI_FRANC_MLF_FIAT_CURRENCY_CODE]: "MLF - Mali Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SUDANESE_DINAR_SDD_FIAT_CURRENCY_CODE]: "SDD - Sudanese Dinar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.RHODESIAN_DOLLAR_ZWC_FIAT_CURRENCY_CODE]: "ZWC - Rhodesian Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TAKA_BDT_FIAT_CURRENCY_CODE]: "BDT - Taka",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.BRAZILIAN_REAL_BRL_FIAT_CURRENCY_CODE]: "BRL - Brazilian Real",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.FIJI_DOLLAR_FJD_FIAT_CURRENCY_CODE]: "FJD - Fiji Dollar",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LEMPIRA_HNL_FIAT_CURRENCY_CODE]: "HNL - Lempira",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OUGUIYA_MRU_FIAT_CURRENCY_CODE]: "MRU - Ouguiya",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.GUARANI_PYG_FIAT_CURRENCY_CODE]: "PYG - Guarani",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SEYCHELLES_RUPEE_SCR_FIAT_CURRENCY_CODE]: "SCR - Seychelles Rupee",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KWANZA_REAJUSTADO_AOR_FIAT_CURRENCY_CODE]: "AOR - Kwanza Reajustado",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.AZERBAIJANIAN_MANAT_AZM_FIAT_CURRENCY_CODE]: "AZM - Azerbaijanian Manat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SYLI_GNE_FIAT_CURRENCY_CODE]: "GNE - Syli",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.POUND_ILP_FIAT_CURRENCY_CODE]: "ILP - Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALAGASY_FRANC_MGF_FIAT_CURRENCY_CODE]: "MGF - Malagasy Franc",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.A_ACCOUNT_CONVERTIBLE_PESETA_ACCOUNT_ESB_FIAT_CURRENCY_CODE]: 'ESB - "A" Account (convertible Peseta Account)',
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.LILANGENI_SZL_FIAT_CURRENCY_CODE]: "SZL - Lilangeni",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.TAJIK_RUBLE_TJR_FIAT_CURRENCY_CODE]: "TJR - Tajik Ruble",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.EGYPTIAN_POUND_EGP_FIAT_CURRENCY_CODE]: "EGP - Egyptian Pound",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.YEN_JPY_FIAT_CURRENCY_CODE]: "JPY - Yen",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.PATACA_MOP_FIAT_CURRENCY_CODE]: "MOP - Pataca",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.KYAT_MMK_FIAT_CURRENCY_CODE]: "MMK - Kyat",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.DONG_VND_FIAT_CURRENCY_CODE]: "VND - Dong",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.NEW_KWANZA_AON_FIAT_CURRENCY_CODE]: "AON - New Kwanza",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MALTESE_LIRA_MTL_FIAT_CURRENCY_CODE]: "MTL - Maltese Lira",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.OUGUIYA_MRO_FIAT_CURRENCY_CODE]: "MRO - Ouguiya",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.MOZAMBIQUE_ESCUDO_MZE_FIAT_CURRENCY_CODE]: "MZE - Mozambique Escudo",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.SURINAM_GUILDER_SRG_FIAT_CURRENCY_CODE]: "SRG - Surinam Guilder",
    [fiatCurrencyCode_pb_1.FiatCurrencyCode.YEMENI_DINAR_YDD_FIAT_CURRENCY_CODE]: "YDD - Yemeni Dinar",
};
// Reverse mapping from string to FiatCurrencyCode enum
const stringToFiatCurrencyCodeMapping = {};
for (const [key, value] of Object.entries(fiatCurrencyCodeToStringMapping)) {
    stringToFiatCurrencyCodeMapping[value] = Number(key);
}
class UnsupportedFiatCurrencyCodeError extends Error {
    constructor(fiatCurrencyCode) {
        const message = `Unsupported FiatCurrencyCode: ${fiatCurrencyCode}`;
        super(message);
        this.fiatCurrencyCode = fiatCurrencyCode;
    }
}
/**
 * Converts a FiatCurrencyCode enum instance to a custom string representation.
 * @param {FiatCurrencyCode} fiatCurrencyCode - The fiatCurrencyCode to convert.
 * @returns {string} The custom string representation of the fiatCurrencyCode.
 */
function fiatCurrencyCodeToString(fiatCurrencyCode) {
    if (fiatCurrencyCode in fiatCurrencyCodeToStringMapping) {
        return fiatCurrencyCodeToStringMapping[fiatCurrencyCode];
    }
    else {
        throw new UnsupportedFiatCurrencyCodeError(fiatCurrencyCode);
    }
}
class UnsupportedFiatCurrencyCodeStringError extends Error {
    constructor(fiatCurrencyCodeStr) {
        const message = `Unsupported fiatCurrencyCode string: ${fiatCurrencyCodeStr}`;
        super(message);
        this.fiatCurrencyCodeStr = fiatCurrencyCodeStr;
    }
}
/**
 * Converts a custom string representation to a FiatCurrencyCode enum instance.
 * @param {string} fiatCurrencyCodeStr - The custom string representation of the fiatCurrencyCode.
 * @returns {FiatCurrencyCode} The corresponding FiatCurrencyCode enum instance.
 */
function stringToFiatCurrencyCode(fiatCurrencyCodeStr) {
    if (fiatCurrencyCodeStr in stringToFiatCurrencyCodeMapping) {
        return stringToFiatCurrencyCodeMapping[fiatCurrencyCodeStr];
    }
    else {
        throw new UnsupportedFiatCurrencyCodeStringError(fiatCurrencyCodeStr);
    }
}
