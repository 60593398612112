import { ContextFuture } from "@mesh/common-js/dist/security/context_pb";

export type newContextProps = {
  userID: string;
};

export class Context {
  public userID = "";

  constructor(context?: Context | newContextProps) {
    if (!context) {
      return;
    }
    this.userID = context.userID;
  }

  toFuture(): ContextFuture {
    return new ContextFuture().setUserid(this.userID);
  }
}
