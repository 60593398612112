import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";

export function LoadingSplash() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "calc(100vh - 400px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          height: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          rowGap: theme.spacing(2),
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <CircularProgress size={70} />
        <Typography
          variant="h5"
          color="textSecondary"
          children="Getting things ready for you..."
        />
      </Box>
    </Box>
  );
}
