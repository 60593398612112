import { Context } from "james/security";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Group } from "./Group";

export interface AddGroupRequest {
  context: Context;
  ownerID: string;
  name: string;
  classification: string;
  description: string;
  roleTypes: string[];
}

export interface AddGroupResponse {
  group: Group;
}

export interface AddRoleToGroupRequest {
  context: Context;
  groupID: string;
  roleType: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddRoleToGroupResponse {}

export const Manager = {
  serviceProvider: "group-Manager",
  async AddGroup(
    request: AddGroupRequest,
    additionalHeaders?: Headers,
  ): Promise<AddGroupResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${Manager.serviceProvider}.AddGroup`,
      request,
    });
    return { group: new Group(response.group) };
  },
  async AddRoleToGroup(
    request: AddRoleToGroupRequest,
    additionalHeaders?: Headers,
  ): Promise<AddRoleToGroupResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${Manager.serviceProvider}.AddRoleToGroup`,
      request,
    });
  },
};
