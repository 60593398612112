import React, { useEffect, useMemo, useState } from "react";
import { Query } from "james/search/query";
import { BPTable } from "components/Table";
import { IconButton, Tooltip } from "@mui/material";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { Model, Reader } from "james/views/ledgerAssetHolderView";
import { Token, Amount as LedgerAmount } from "james/ledger";
import { Amount } from "components/Ledger/Amount";
import DownloadIcon from "@mui/icons-material/Download";
import { useRQPriceHistorian } from "hooks/reactQuery/useRQPriceHistorian";
import { Period, Resolution } from "pkgTemp/market";
import { AllStellarNetworks } from "james/stellar";
import BigNumber from "bignumber.js";
import { createSearchParams, useNavigate } from "react-router-dom";

export type Props = {
  token: Token;
  excludedTokenCodes: Array<string>;
  mZAR?: Token;
};

const downloadCSV = (data: Model[]): void => {
  const filteredData = data.map((d) => ({
    amountHeld: d.amountHeld.toString().replaceAll(",", ""),
    accountNumber: d.accountNumber,
    holderName: d.holderName,
    holderEmailAddress: d.holderEmailAddress,
    clientKind: d.clientKind,
  }));

  const refinedData: Array<Array<string>> = [
    [
      "amountHeld",
      "accountNumber",
      "holderName",
      "holderEmailAddress",
      "clientKind",
    ],
  ];
  filteredData.forEach((item) =>
    refinedData.push([
      item.amountHeld,
      item.accountNumber,
      item.holderName,
      item.holderEmailAddress,
      item.clientKind,
    ]),
  );

  let csvContent = "data:text/csv;charset=utf-8,";
  refinedData.forEach((row) => {
    csvContent += row.join(",") + "\n";
  });

  const link = document.createElement("a");
  link.setAttribute("href", encodeURI(csvContent));
  link.setAttribute("download", "asset_holders.csv");
  document.body.appendChild(link); // Required for FF

  link.click();
};

export const AssetHoldersList = ({
  token,
  excludedTokenCodes,
  mZAR,
}: Props) => {
  const [apiLoading, setAPILoading] = useState(false);
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const [data, setData] = useState<Model[]>([]);
  const [query, setQuery] = useState<Query>(
    new Query({ limit: 10, offset: 0, sorting: [] }),
  );
  const { GetPriceForPeriod: PriceHistorianGetPriceForPeriod } =
    useRQPriceHistorian();
  const [price, setPrice] = useState<LedgerAmount | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (
        mZAR &&
        !(token.code in excludedTokenCodes) &&
        token.network in AllStellarNetworks
      ) {
        try {
          const prices = (
            await PriceHistorianGetPriceForPeriod({
              baseToken: token,
              quoteToken: mZAR,
              period: Period._ALL,
              resolution: Resolution.Week,
            })
          ).prices;
          if (prices.length > 0) {
            setPrice(prices[0].avgPrice);
          }
        } catch (e) {
          console.error(`error getting prices: ${e}`);
        }
      } else {
        setPrice(
          new LedgerAmount({
            value: new BigNumber(1),
            token: mZAR || new Token(),
          }),
        );
      }
    })();
  }, [mZAR, token, excludedTokenCodes]);

  useEffect(() => {
    setTimeout(async () => {
      setAPILoading(true);
      try {
        const response = await Reader.Read({
          context: authContext,
          token: token,
        });

        setData(
          response.models
            // .filter(({ id }) => id !== "Unknown")
            .sort((a, b) => {
              if (a.holderName > b.holderName) {
                return 1;
              } else {
                return -1;
              }
            }),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving asset holder view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setAPILoading(false);
    });
  }, [token, authContext]);

  const paginatedData = useMemo(() => {
    return data.slice(query.offset, query.offset + query.limit);
  }, [data, query]);

  return (
    <BPTable
      loading={apiLoading}
      height={400}
      singleSelect
      onSingleSelectChange={(_) => _}
      title={"Asset holders"}
      onQueryChange={(newQuery) => setQuery(newQuery)}
      data={paginatedData}
      totalNoRecords={data.length}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];
        controls.push(
          <Tooltip title={"Download"}>
            <span>
              <IconButton
                id={"assetHoldersTable-download-iconButton"}
                disabled={false}
                size={"small"}
                onClick={() => downloadCSV(data)}
              >
                <DownloadIcon />
              </IconButton>
            </span>
          </Tooltip>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "Client ID",
          field: "id",
          sortable: false,
        },
        {
          label: "Email",
          field: "holderEmailAddress",
          sortable: false,
        },
        {
          label: "Units Held",
          field: "amountHeld.value.float",
          sortable: false,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [key: string]: any }) => (
            <Amount amount={(data as Model).amountHeld} />
          ),
        },
        {
          label: "mZAR Value",
          field: "mZARValue",
          sortable: false,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [key: string]: any }) =>
            price ? (
              <Amount
                amount={price?.setValue(
                  (data as Model).amountHeld.value.multipliedBy(price?.value),
                )}
              />
            ) : (
              "-"
            ),
        },
        {
          label: "Client Kind",
          field: "clientKind",
          sortable: false,
        },
        {
          field: "",
          label: "",
          minWidth: 40,
          sortable: false,
          accessor: (data) => {
            const row = data as Model;
            return (
              <Tooltip placement="top" title="View client">
                <IconButton
                  size="small"
                  id={row.holderEmailAddress}
                  onClick={() =>
                    navigate({
                      pathname: "/market-management/compliance",
                      search: `?${createSearchParams({
                        clientID: row.id,
                      })}`,
                    })
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            );
          },
        },
      ]}
      query={query}
    />
  );
};
