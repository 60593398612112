import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Amount } from "james/ledger";
import { formatTextNum, getNoDecimals } from "utilities/number";
import LoadingButton from "@mui/lab/LoadingButton";

type ConfirmBurnAssetCardProps = {
  onConfirmButtonClick: () => void;
  onGoBackButtonClick: () => void;
  onCloseButtonClick: () => void;
  fee: Amount;
  vat: Amount;
  tokensToBurn: Amount;
  tokensToBurnIssuerName: string;
  feePaymentTokenBalance?: Amount;
  feePaymentTokenIssuerName: string;
  feeAccountName: string;
  tokenBurnInProgress: boolean;
};

export function ConfirmBurnAssetCard(props: ConfirmBurnAssetCardProps) {
  const [expandFeeSection, setExpandFeeSection] = useState(false);

  const tokensToBurnDecimals = useMemo(
    (): number => getNoDecimals(props.tokensToBurn.value),
    [props.tokensToBurn],
  );

  return (
    <Card>
      <CardHeader
        sx={{
          padding: (theme) => theme.spacing(2, 3, 2, 3),
        }}
        title={
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
            }}
          >
            <Typography component={"div"} variant="h5">
              Burn Tokens
            </Typography>
            <Box>
              <IconButton
                id="ConfirmBurnAssetCard-Close-Button"
                onClick={props.onCloseButtonClick}
                disabled={props.tokenBurnInProgress}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        }
      />
      <CardContent
        sx={{
          padding: "40px !important",
        }}
      >
        <Typography
          children={
            "Are you sure you want to proceed with burning the following tokens:"
          }
          variant={"h6"}
          component={"div"}
          sx={{
            marginBottom: "16px",
          }}
        />
        {/* Burn amount */}
        <Box
          sx={{
            marginBottom: "16px",
          }}
        >
          <Typography
            color={"textSecondary"}
            children={"Amount"}
            variant={"caption"}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={`Issued by ${props.tokensToBurnIssuerName}`}
              placement="top"
            >
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.light,
                  },
                })}
                children={props.tokensToBurn.token.code}
              />
            </Tooltip>
            <Typography
              sx={{ marginLeft: "4px" }}
              children={`${formatTextNum(props.tokensToBurn.value, {
                noDecimalPlaces: tokensToBurnDecimals,
              })}`}
              variant={"subtitle1"}
            />
          </Box>
        </Box>
        {/* Burn fee collapse section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
            height: "16px",
          }}
        >
          <IconButton
            id="ConfirmBurnAssetCard-expand-button"
            onClick={() => setExpandFeeSection(!expandFeeSection)}
            size="small"
          >
            {!expandFeeSection && <KeyboardArrowDown color="primary" />}
            {expandFeeSection && <KeyboardArrowUp color="primary" />}
          </IconButton>
          <Typography
            sx={{
              marginLeft: (theme) => theme.spacing(0.5),
              marginRight: (theme) => theme.spacing(1),
            }}
            color="textSecondary"
            variant="body1"
            children="Total Fee:"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ marginRight: "4px" }}
              variant="body1"
              component={"p"}
            >
              <>
                <Tooltip
                  title={`Issued by ${props.feePaymentTokenIssuerName}`}
                  placement="top"
                >
                  <Typography
                    component={"span"}
                    sx={(theme) => ({
                      cursor: "pointer",
                      "&:hover": {
                        color: theme.palette.primary.light,
                      },
                    })}
                  >
                    {props.fee.token.code}&nbsp;
                  </Typography>
                </Tooltip>
                <Typography component={"span"}>
                  {formatTextNum(props.fee.value.plus(props.vat.value))}
                </Typography>
              </>
            </Typography>
          </Box>
        </Box>
        <Collapse timeout={200} in={expandFeeSection}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: (theme) => theme.spacing(0, 0, 1, 5),
            }}
          >
            <Link
              sx={{
                cursor: "pointer",
                marginBottom: (theme) => theme.spacing(2),
                marginTop: (theme) => theme.spacing(2),
              }}
              variant={"body1"}
              color="secondary"
              target="_blank"
              underline={"hover"}
              href="https://mesh.trade/fees"
              children={"Why these fees?"}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  marginRight: (theme) => theme.spacing(2),
                }}
              >
                Fee Acc:
              </Typography>
              <Typography noWrap variant="body2" component="span">
                {props.feeAccountName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                color="textSecondary"
                variant="body2"
                children="Burn Fee:"
              />
              <Tooltip
                title={`Issued by ${props.feePaymentTokenIssuerName}`}
                placement="top"
              >
                <Typography
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0.5, 0, 1),
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.light,
                    },
                  })}
                  variant="body2"
                  children={props.fee ? props.fee.token.code : ""}
                />
              </Tooltip>
              <Typography
                variant="body2"
                children={
                  props.fee
                    ? formatTextNum(props.fee.value, {
                        noDecimalPlaces: 2,
                      })
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                color="textSecondary"
                variant="body2"
                children="VAT on Fee"
              />
              <Tooltip
                title={`Issued by ${props.feePaymentTokenIssuerName}`}
                placement="top"
              >
                <Typography
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0.5, 0, 1),
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.light,
                    },
                  })}
                  variant="body2"
                  children={props.vat ? props.vat.token.code : ""}
                />
              </Tooltip>
              <Typography
                variant="body2"
                children={
                  props.vat
                    ? formatTextNum(props.vat.value, {
                        noDecimalPlaces: 2,
                      })
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                color="textSecondary"
                variant="body2"
                children="Available:"
              />
              <Tooltip
                title={`Issued by ${props.feePaymentTokenIssuerName}`}
                placement="top"
              >
                <Typography
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0.5, 0, 1),
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.light,
                    },
                  })}
                  variant="body2"
                  children={
                    props.feePaymentTokenBalance
                      ? props.feePaymentTokenBalance.token.code
                      : ""
                  }
                />
              </Tooltip>
              <Typography
                variant="body2"
                children={
                  props.feePaymentTokenBalance
                    ? formatTextNum(props.feePaymentTokenBalance.value, {
                        noDecimalPlaces: 2,
                      })
                    : ""
                }
              />
            </Box>
          </Box>
        </Collapse>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
          }}
        >
          <Button
            id="ConfirmBurnAssetCard-Submit-Button"
            sx={{
              height: {
                xs: "48px",
                sm: "36px",
              },
              marginTop: "24px",
            }}
            fullWidth
            variant="outlined"
            disabled={props.tokenBurnInProgress}
            onClick={props.onGoBackButtonClick}
          >
            Go Back
          </Button>
          <LoadingButton
            id="ConfirmBurnAssetCard-Submit-Button"
            onClick={props.onConfirmButtonClick}
            sx={{
              height: {
                xs: "48px",
                sm: "36px",
              },
              marginTop: "24px",
            }}
            fullWidth
            loadingIndicator={<CircularProgress size={16} />}
            disabled={props.tokenBurnInProgress}
            loading={props.tokenBurnInProgress}
            color="primary"
            variant="contained"
          >
            Confirm
          </LoadingButton>
        </Box>
      </CardContent>
    </Card>
  );
}
