import { Address } from "james/location";

export class CompanyRepresentative {
  public firstName = "";

  public lastName = "";

  public telephoneNumber = "";

  public cellphoneNumber = "";

  public emailAddress = "";

  public middleNames = "";

  public physicalAddress: Address | undefined = undefined;

  public postalAddress: Address | undefined = undefined;

  constructor(companyRepresentative?: CompanyRepresentative) {
    if (!companyRepresentative) {
      return;
    }
    this.firstName = companyRepresentative.firstName;
    this.middleNames = companyRepresentative.middleNames;
    this.lastName = companyRepresentative.lastName;
    this.telephoneNumber = companyRepresentative.telephoneNumber;
    this.cellphoneNumber = companyRepresentative.cellphoneNumber;
    this.emailAddress = companyRepresentative.emailAddress;
    if (companyRepresentative.physicalAddress) {
      this.physicalAddress = new Address(companyRepresentative.physicalAddress);
    } else {
      this.physicalAddress = new Address();
    }
    if (companyRepresentative.postalAddress) {
      this.postalAddress = new Address(companyRepresentative.postalAddress);
    }
  }
}
