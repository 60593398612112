import { ReadManyAssetHolderLookupRequest } from "@mesh/common-js/dist/ledger/assetHolderLookupReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import React, { useContext, useEffect, useState } from "react";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { useIsMounted } from "hooks";
import { AssetHolderLookup } from "@mesh/common-js/dist/ledger/assetHolderLookup_pb";
import { OrchestrateAssetHolderLookupsRequest } from "@mesh/common-js/dist/ledger/assetHolderLookupOrchestrator_pb";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";

export type OwnershipContextType = {
  loading: boolean;
  query: Query;
  setQuery: React.Dispatch<React.SetStateAction<Query>>;
  assetHolderLookups: AssetHolderLookup[];
  numAssetHolderLookups: number;
  handleOrchestrateAssetHolderLookups: () => void;
  reload: () => void;
};

// use zero value semantics to set defaults
const OwnershipContext = React.createContext<OwnershipContextType>(
  {} as OwnershipContextType,
);

export const useOwnershipContext = () => useContext(OwnershipContext);

export const OwnershipContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ system, children }) => {
  const { authContext } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [reloadRequested, setReloadRequested] = useState(false);
  const reload = () => {
    setReloadRequested(!reloadRequested);
  };
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState(
    new Query()
      .setOffset(0)
      .setLimit(15)
      .setSortingList([
        new Sorting().setField("date").setOrder(SortingOrder.ASC_SORTING_ORDER),
      ]),
  );
  const [assetHolderLookups, setAssetHolderLookups] = useState<
    AssetHolderLookup[]
  >([]);
  const [numAssetHolderLookups, setNumAssetHolderLookups] = useState(0);
  const [orchestrated, setOrchestrated] = useState(false);
  const isMounted = useIsMounted();

  const {
    ledger: {
      ledgerAssetHolderLookupReader,
      ledgerAssetHolderLookupReaderUNSCOPED,
      ledgerAssetHolderLookupOrchestrator,
    },
  } = useAPIContext();

  // fetch data
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const assetHolderLookupsReadResponse = system
          ? await ledgerAssetHolderLookupReaderUNSCOPED.readManyAssetHolderLookupUNSCOPED(
              new ReadManyAssetHolderLookupRequest()
                .setContext(authContext.toFuture())
                .setQuery(query),
            )
          : await ledgerAssetHolderLookupReader.readManyAssetHolderLookup(
              new ReadManyAssetHolderLookupRequest()
                .setContext(authContext.toFuture())
                .setQuery(query),
            );
        setAssetHolderLookups(assetHolderLookupsReadResponse.getRecordsList());
        setNumAssetHolderLookups(assetHolderLookupsReadResponse.getTotal());
      } catch (e) {
        errorContextDefaultErrorFeedback(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [isMounted, query, orchestrated, reloadRequested]);

  const handleOrchestrateAssetHolerLookups = async () => {
    try {
      setLoading(true);
      // orchestrate
      await ledgerAssetHolderLookupOrchestrator.orchestrateAssetHolderLookups(
        new OrchestrateAssetHolderLookupsRequest().setContext(
          authContext.toFuture(),
        ),
      );
      setOrchestrated(!orchestrated);
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OwnershipContext.Provider
      value={{
        assetHolderLookups: assetHolderLookups,
        numAssetHolderLookups: numAssetHolderLookups,
        loading: loading,
        query: query,
        setQuery: setQuery,
        handleOrchestrateAssetHolderLookups: handleOrchestrateAssetHolerLookups,
        reload: reload,
      }}
    >
      {children}
    </OwnershipContext.Provider>
  );
};
