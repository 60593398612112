import { CalculatePredictedLimitOrderFeeRequest } from "@mesh/common-js/src/market/limitOrderFeeCalculator_pb";
import { useAPIContext } from "context/API";
import { useExchangeDashboardStore } from "../store";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";

export const useFeeFetcher = () => {
  const { market } = useAPIContext();
  const store = useExchangeDashboardStore();

  const fetchFee = async () => {
    try {
      const response =
        await market.limitOrderFeeCalculator.calculatePredictedLimitOrderFee(
          new CalculatePredictedLimitOrderFeeRequest().setLimitordersoldamount(
            store.tradeCardState.estimatedTotal,
          ),
        );

      store.updateTradeCardState.setFee({
        fee: response.getFeeamount() ?? new FutureAmount(),
        vat: response.getVatamount() ?? new FutureAmount(),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    fetchFee,
  };
};
