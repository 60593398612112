import dayjs from "dayjs";
import { Amount } from "james/ledger";

const PublicIndicativePriceTypeName = "mesh::market/PublicIndicativePrice";

export class PublicIndicativePrice {
  public ["@type"]: string = PublicIndicativePriceTypeName;

  public buyPrice: Amount = new Amount();

  public sellPrice: Amount = new Amount();

  public timeOfPrice: string = dayjs().format();

  constructor(l?: PublicIndicativePrice) {
    if (!l) {
      return;
    }
    this.buyPrice = new Amount(l.buyPrice);
    this.sellPrice = new Amount(l.sellPrice);
    this.timeOfPrice = l.timeOfPrice;
  }
}
