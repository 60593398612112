import { useApplicationContext } from "context/Application/Application";
import { useTransactionHistoryViewStore } from "../store";
import {
  UnscopedReader,
  UnscopedReadRequest,
} from "james/views/stellarTransactionHistoryView/UnscopedReader";
import { useErrorContext } from "context/Error";

export const useSearchTransactionHistoryView = () => {
  const store = useTransactionHistoryViewStore();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  const searchTransactionHistoryView = async (
    request: Omit<UnscopedReadRequest, "context">,
  ) => {
    try {
      store.setLoading(true);
      const response = await UnscopedReader.Read({
        context: authContext,
        criteria: request.criteria,
        query: request.query,
      });
      store.setTransactionViewModels(response.models);
      store.setTotal(response.total);
      store.setLoading(false);
    } catch (e) {
      store.setLoading(false);
      const err = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(err);
    }
  };

  return {
    searchTransactionHistoryView,
  };
};
