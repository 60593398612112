import { Company } from "james/legal/company";

export const FormValidator = (
  field: string,
  value: string | null,
): string[] => {
  const errorMessages: string[] = [];

  if (field === "formOfIncorporation") {
    if (!value) {
      errorMessages.push("Company form of Incorporation not provided");
    }
  }

  return errorMessages;
};

export const FormFieldsValidation = (
  invalidFormFields: { [key: string]: string | undefined },
  company: Company,
  setInvalidFormFields?: (value: { [key: string]: string | undefined }) => void,
): boolean => {
  let InvalidFormFields = {};

  if (!company.formOfIncorporation) {
    InvalidFormFields = {
      ...InvalidFormFields,
      formOfIncorporation: "Company form of Incorporation not provided",
    };
  }

  if (company.registeredName === "") {
    InvalidFormFields = {
      ...InvalidFormFields,
      registeredName: "Company registered name not provided",
    };
  }
  if (setInvalidFormFields) {
    setInvalidFormFields(InvalidFormFields);
  }
  return !Object.keys(InvalidFormFields).length;
};

export const CompanyProfileFormFieldValidation: {
  [key: string]: string | undefined;
} = {};
