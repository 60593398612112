import { CompanyRepresentative } from "james/legal";
import { Address } from "james/location";

export enum LegalForm {
  SoleProprietorShipLegalForm = "Sole Proprietorship",
  CloseCorporationLegalForm = "Close Corporation",
  SouthAfricanCompanyLegalForm = "South African Company",
  ForeignCompanyLegalForm = "Foreign Company",
  ListedCompanyLegalForm = "Listed Company",
  PartnershipLegalForm = "Partnership",
  TrustLegalForm = "Trust",
  OtherLegalForm = "Other",
}

export class ConnectedCompany {
  public id = "";

  public businessName = "";

  public legalForm: LegalForm | "" = "";

  public registeredName = "";

  public registrationNumber = "";

  public registeredAddress: Address = new Address();

  public businessAddress: Address = new Address();

  public headOfficeAddress: Address = new Address();

  public companyRepresentative: CompanyRepresentative =
    new CompanyRepresentative();

  constructor(connectedCompany?: ConnectedCompany) {
    if (!connectedCompany) {
      return;
    }
    this.id = connectedCompany.id;
    this.businessName = connectedCompany.businessName;
    this.legalForm = connectedCompany.legalForm;
    this.registeredName = connectedCompany.registeredName;
    this.registrationNumber = connectedCompany.registrationNumber;
    if (connectedCompany.registeredAddress) {
      this.registeredAddress = new Address(connectedCompany.registeredAddress);
    }
    if (connectedCompany.businessAddress) {
      this.businessAddress = new Address(connectedCompany.businessAddress);
    }
    if (connectedCompany.headOfficeAddress) {
      this.headOfficeAddress = new Address(connectedCompany.headOfficeAddress);
    }
    this.companyRepresentative = new CompanyRepresentative(
      connectedCompany.companyRepresentative,
    );
  }
}

export const AllLegalForms: LegalForm[] = [
  LegalForm.SoleProprietorShipLegalForm,
  LegalForm.CloseCorporationLegalForm,
  LegalForm.SouthAfricanCompanyLegalForm,
  LegalForm.ForeignCompanyLegalForm,
  LegalForm.ListedCompanyLegalForm,
  LegalForm.PartnershipLegalForm,
  LegalForm.TrustLegalForm,
  LegalForm.OtherLegalForm,
];
