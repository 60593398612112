import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { TransactionQueueEntry } from "./TransactionQueueEntry";
import { useApplicationContext } from "context/Application/Application";
import { useEffect, useRef, useState } from "react";
import { useErrorContext } from "context/Error";

export interface RetrieveTransactionQueueEntryRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveTransactionQueueEntryResponse {
  transactionQueueEntry: TransactionQueueEntry;
}

export interface SearchTransactionQueueEntriesRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchTransactionQueueEntriesResponse {
  records: TransactionQueueEntry[];
  total: number;
}

export const TransactionQueueEntryRepository = {
  serviceProvider: "ledger-TransactionQueueEntryRepository",
  async RetrieveTransactionQueueEntry(
    request: RetrieveTransactionQueueEntryRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveTransactionQueueEntryResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TransactionQueueEntryRepository.serviceProvider}.RetrieveTransactionQueueEntry`,
        request,
      },
      opts,
    );
    return {
      transactionQueueEntry: new TransactionQueueEntry(
        response.transactionQueueEntry,
      ),
    };
  },
  async SearchTransactionQueueEntries(
    request: SearchTransactionQueueEntriesRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SearchTransactionQueueEntriesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TransactionQueueEntryRepository.serviceProvider}.SearchTransactionQueueEntries`,
        request,
      },
      opts,
    );
    return {
      records: response.records.map(
        (c: TransactionQueueEntry) => new TransactionQueueEntry(c),
      ),
      total: response.total,
    };
  },
};

export function useSearchTransactionQueueEntries(
  initialSearchTransactionQueueEntriesRequest?: SearchTransactionQueueEntriesRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [
    searchTransactionQueueEntriesRequest,
    setSearchTransactionQueueEntriesRequest,
  ] = useState<SearchTransactionQueueEntriesRequest>(
    initialSearchTransactionQueueEntriesRequest || {
      context: authContext,
      query: new Query(),
      criteria: {},
    },
  );
  const [
    searchTransactionQueueEntriesResponse,
    setSearchTransactionQueueEntriesResponse,
  ] = useState<SearchTransactionQueueEntriesResponse>({
    total: 0,
    records: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchTransactionQueueEntriesResponse(
          await TransactionQueueEntryRepository.SearchTransactionQueueEntries(
            searchTransactionQueueEntriesRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for transactionQueueEntries: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for transactionQueueEntries: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchTransactionQueueEntriesRequest, shouldNotExecute]);

  return {
    searchTransactionQueueEntriesRequest,
    setSearchTransactionQueueEntriesRequest,
    searchTransactionQueueEntriesResponse,
    loading,
    error,
  };
}
