import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

export const CircularProgressWithLabel = (
  props: CircularProgressProps & {
    value: number;
    progress: number;
    hideZeroValue: boolean;
  },
) => {
  return (
    <Tooltip
      title="You can only resend confirmation code email every 120s. Confirmation code will expire in 5 min"
      placement={"top"}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size={25}
          variant="determinate"
          value={props.progress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
            }}
            variant="caption"
            component="div"
            color="text.primary"
          >
            {props.hideZeroValue && props.value == 0
              ? ``
              : `${Math.round(props.value)}`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};
