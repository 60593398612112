import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

interface PublishNonSouthAfricanClientEventRequest {
  clientEmail: string;
  countryOfResidence: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PublishNonSouthAfricanClientEventResponse {}

export const NonSouthAfricanClientEventPublisherServiceProviderName =
  "onboarding-NonSouthAfricanClientEventPublisher";

export const NonSouthAfricanClientEventPublisher = {
  async PublishNonSouthAfricanClientEvent(
    request: PublishNonSouthAfricanClientEventRequest,
  ): Promise<PublishNonSouthAfricanClientEventResponse> {
    return jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${NonSouthAfricanClientEventPublisherServiceProviderName}.PublishNonSouthAfricanClientEvent`,
      request,
    });
  },
};
