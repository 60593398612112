import {
  InstrumentManagementFee,
  InstrumentManagementFeeType,
} from "./InstrumentManagementFee";
import BigNumber from "bignumber.js";

export const PercentageInstrumentManagementFeeTypeName =
  "mesh::financial/PercentageInstrumentManagementFee";

export type NewPercentageInstrumentManagementFeeProps = {
  percentage: BigNumber;
};

export class PercentageInstrumentManagementFee
  implements InstrumentManagementFee
{
  public ["@type"]: string = PercentageInstrumentManagementFeeTypeName;

  public percentage: BigNumber = new BigNumber("0");

  constructor(f?: NewPercentageInstrumentManagementFeeProps) {
    if (!f) {
      return;
    }
    this.percentage = new BigNumber(f.percentage);
  }

  instrumentManagementFeeType(): InstrumentManagementFeeType {
    return InstrumentManagementFeeType.Percentage;
  }
}
