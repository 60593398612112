import BigNumber from "bignumber.js";
import { Criterion } from "pkgTemp/search/criterion/Criterion";
import { TextExactCriterion } from "pkgTemp/search/criterion";
import { LedgerNetwork, networkToFutureNetwork } from "pkgTemp/ledger/Network";
import { StellarNetwork } from "pkgTemp/stellar";
import { BitcoinNetwork } from "pkgTemp/bitcoin";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { bigNumberToDecimal } from "@mesh/common-js/dist/num";

export type NewAmountArgs = {
  value: BigNumber;
  token: newTokenProps;
};

export class Amount {
  public value: BigNumber = new BigNumber("0");

  public token: Token = new Token();

  constructor(amount?: NewAmountArgs) {
    if (!amount) {
      return;
    }
    this.value = new BigNumber(amount.value);
    this.token = new Token(amount.token);
  }

  setValue(value: string | BigNumber): Amount {
    return this.token.newAmountOf(value);
  }

  isUndefined(): boolean {
    return this.token.isUndefined();
  }

  toFutureAmount(): FutureAmount {
    return new FutureAmount()
      .setToken(this.token.toFutureToken())
      .setValue(bigNumberToDecimal(this.value));
  }
}

export type newTokenProps = {
  code: string;
  issuer: string;
  network: LedgerNetwork | "-";
};

export class Token {
  public code = "-";

  public issuer = "-";

  public network: LedgerNetwork | "-" = "-";

  constructor(token?: newTokenProps) {
    if (!token) {
      return;
    }
    this.code = token.code;
    this.issuer = token.issuer;
    this.network = token.network;
  }

  toFutureToken(): FutureToken {
    return new FutureToken()
      .setCode(this.code)
      .setIssuer(this.issuer)
      .setNetwork(networkToFutureNetwork(this.network));
  }

  newAmountOf(value: string | BigNumber): Amount {
    value = new BigNumber(value);
    switch (this.network) {
      case StellarNetwork.TestSDFNetwork:
      case StellarNetwork.PublicNetwork:
        return new Amount({
          value: value.decimalPlaces(7, BigNumber.ROUND_DOWN),
          token: new Token(this),
        });

      case BitcoinNetwork.TestNetwork:
      case BitcoinNetwork.PublicNetwork:
        return new Amount({
          value: value.decimalPlaces(8, BigNumber.ROUND_DOWN),
          token: new Token(this),
        });

      default:
        return new Amount({
          value,
          token: new Token(this),
        });
    }
  }

  isUndefined(): boolean {
    return this.code === "-" && this.issuer === "-" && this.network === "-";
  }

  isEqualTo(t2: Token): boolean {
    return (
      this.code === t2.code &&
      this.issuer === t2.issuer &&
      this.network === t2.network
    );
  }

  toFilter(): { [key: string]: Criterion } {
    return {
      "token.code": TextExactCriterion(this.code),
      "token.issuer": TextExactCriterion(this.issuer),
      "token.network": TextExactCriterion(this.network),
    };
  }

  string(): string {
    return `${this.code} by ${this.issuer} on ${this.network}`;
  }
}
