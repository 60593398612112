import React from "react";
import { Box, Chip, useTheme } from "@mui/material";
import { PaymentState } from "@mesh/common-js/dist/financial/payment_pb";
import { paymentStateToString } from "@mesh/common-js/dist/financial";

export type PaymentStateChipProps = {
  state: PaymentState;
};

export function PaymentStateChip(props: PaymentStateChipProps) {
  const theme = useTheme();
  const chipStyles = {
    chip: {
      fontWeight: 400,
    },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    success: { backgroundColor: theme.palette.success.main },
    secondary: { backgroundColor: theme.palette.info.main },
  };

  switch (props.state) {
    case PaymentState.PENDING_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.PAYMENT_IN_PROGRESS_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.PAYMENT_FAILED_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.error,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.PAID_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.CLEAN_UP_IN_PROGRESS_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.CLEAN_UP_UNDER_INVESTIGATION_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.CLEAN_UP_FAILED_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.warning,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );
    case PaymentState.COMPLETE_PAYMENT_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          size="small"
          label={paymentStateToString(props.state)}
        />
      );

    default:
      return <Box>{props.state}</Box>;
  }
}
