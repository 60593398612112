import { SpotType } from "./Spot";
import BigNumber from "bignumber.js";
import { Period, Resolution } from "./PriceHistorian";

export class UnexpectedAmountError extends Error {
  name = "Unexpected Amount";
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class InvalidSpotTypeError extends Error {
  name = "Invalid Spot Type";
  message: string;

  constructor(type: SpotType) {
    super();
    this.message = `spot type: '${type}'`;
  }
}

export class CouldNotGetPrice extends Error {
  name = "Could Not Get Price";
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class ExcessiveTruncationError extends Error {
  name = "ExcessiveTruncationError";
  message = "a number has been lost due to excessive truncation error";

  constructor(numberDescription: string, raw: BigNumber) {
    super();
    this.message = `number '${numberDescription}' with raw value ${raw.toString()} has been lost due to excess truncation error`;
  }
}

export class UnexpectedTokenError extends Error {
  name = "Unexpected Token";
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class InvalidDateTimeError extends Error {
  name = "Invalid DateTime";
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class InvalidResolutionError extends Error {
  name = "Invalid Resolution";
  message: string;

  constructor(resolution: Resolution) {
    super();
    this.message = `invalid resolution: ${resolution}`;
  }
}

export class InvalidPeriodError extends Error {
  name = "Invalid Period";
  message: string;

  constructor(period: Period, extra?: string) {
    super();
    this.message = `invalid period: ${period}`;
    if (extra) {
      this.message = this.message + `: ${extra}`;
    }
  }
}
