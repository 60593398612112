import { Token } from "james/ledger";
import { IdentifierType } from ".";

export interface TokenIdentifierType {
  type: IdentifierType;
  code: string;
  issuer: string;
  network: string;
  field?: string;
}

function TokenIdentifier(token: Token, field?: string): TokenIdentifierType {
  return {
    type: IdentifierType.TokenTypeName,
    code: token.code,
    issuer: token.issuer,
    network: token.network,
    field,
  };
}

export { TokenIdentifier };
