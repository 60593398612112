import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import React, { useState } from "react";

const PREFIX = "IssueWarningDialog";

const classes = {
  issueWarningDialogTitle: `${PREFIX}-issueWarningDialogTitle`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  placementDecisionLayout: `${PREFIX}-placementDecisionLayout`,
  clickableText: `${PREFIX}-clickableText`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.issueWarningDialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 499,
    padding: "20px 24px !important",
  },

  [`& .${classes.placementDecisionLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(0.5),
  },

  [`& .${classes.clickableText}`]: {
    cursor: "pointer",
  },
}));

export interface IssueWarningDialogOptions {
  preIssuing: boolean;
  yesMethod: (placementAfterIssue: boolean) => void;
  noMethod: () => void;
}

export interface IssueWarningDialogProps {
  showDialog: boolean;
  onCloseClick: () => void;
  onAwayClick?: () => void;
  onYesClick: (placementAfterIssue: boolean) => void;
  onNoClick: () => void;
  disableControls?: boolean;
  showProgressIndicator?: boolean;
  preIssuing: boolean;
}

export function IssueWarningDialog(props: IssueWarningDialogProps) {
  const [placementChoice, setPlacementChoice] = useState(false);

  return (
    <StyledDialog
      open={props.showDialog}
      onClose={props.onAwayClick ? props.onAwayClick : undefined}
      maxWidth="lg"
    >
      <DialogTitle classes={{ root: classes.issueWarningDialogTitle }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography
              variant="h5"
              children={`${
                props.preIssuing ? "Pre-Issue" : "Issue"
              } Instrument`}
            />
          </Grid>
          {props.showProgressIndicator && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  size="small"
                  disabled={props.showProgressIndicator}
                  id="issueWarningDialog-close-button"
                  onClick={props.onCloseClick}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContentRootOverride}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1">
                  Once an instrument has been{" "}
                  {props.preIssuing ? "pre-issued" : "issued"}, all key
                  information cannot be changed. Are you sure you want to{" "}
                  {props.preIssuing ? "pre-issue" : "issue"}?
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body1">
                  You also have the option to place your instrument directly on
                  the Mesh market place.
                </Typography>
              </Grid>
              <Grid item className={classes.placementDecisionLayout}>
                <Checkbox
                  id="issueWarningDialog-placeOnMarketPlace-checkbox"
                  disabled={props.disableControls}
                  checked={placementChoice}
                  onChange={(e) => setPlacementChoice(e.target.checked)}
                />
                <Typography
                  variant="body1"
                  id="issueWarningDialog-placeOnMarketPlace-checkboxText"
                  className={classes.clickableText}
                  onClick={() => {
                    if (props.preIssuing) {
                      return;
                    }
                    setPlacementChoice(!placementChoice);
                  }}
                >
                  Place on Mesh Market Place
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  children="yes"
                  disabled={
                    props.showProgressIndicator || props.disableControls
                  }
                  id="issueWarningDialog-yes-button"
                  onClick={() => props.onYesClick(placementChoice)}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  children="no"
                  disabled={props.showProgressIndicator}
                  id="issueWarningDialog-no-button"
                  onClick={() => props.onNoClick()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
