import React from "react";
import { OwnershipTable } from "components/Ledger/Ownership/OwnershipTable";
import { useApplicationContext } from "context/Application/Application";
import { OwnershipContextProvider } from "./components/Context";

const Ownership = () => {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Ledger"]?.Ownership?.ReadUNSCOPED;

  return (
    <OwnershipContextProvider system={system}>
      <OwnershipTable height={window.innerHeight - 120} />
    </OwnershipContextProvider>
  );
};

export { Ownership as StellarOwnership };
