import {
  pageDevice,
  userAffiliations,
  userCategories,
  userLoginTypes,
} from "const/gtm";
import { ClientKYCStatus } from "james/client";

export type UserDetails = {
  user_id: string;
  hashed_email: string;
  user_category: userCategories;
  user_kyc_status: string;
  user_kyc_request_date: string;
  user_kyc_approved_date: string;
  user_login_type: userLoginTypes;
  user_primary_affiliation: userAffiliations;
};

export enum TutorialNames {
  First_Time_Login_Steps = "first login steps",
  Platform_Guided_Journey = "platform guided journey",
}

export enum TutorialTypes {
  Onboarding = "onboarding",
  Marketplace = "marketplace",
}

export type TutorialDetails = {
  tutorial_name: TutorialNames;
  tutorial_type: TutorialTypes;
  tutorial_start: boolean;
  tutorial_complete: boolean;
};

export type SearchDetails = {
  search_term: string; // e.g. term that has been search e.g. crypto
  search_results: number; //
  search_start: boolean;
  search_complete: boolean;
};

export type ErrorDetails = {
  error_url: string;
  error_type: string;
  error_message: string;
};

export type AccountDetails = {
  account_no: string;
  account_transaction_type: TransactionTypes;
  account_transaction_amount: string;
};

export enum TransactionTypes {
  Listing = "listing",
  Defund = "defund",
  Fund = "fund",
  Transfer = "transfer",
  spotTrade = "spot trade",
  directOrder = "direct Order",
}

export type PageDetails = {
  page_name: string;
  page_section: string; //
  page_type: PageType;
  page_device_type: pageDevice;
  page_url: string;
  page_title: string;
};

export enum PageType {
  informational = "informational",
  Transactional = "transactional",
  Management = "management",
  Settings = "settings",
  Generic = "generic",
}

export type KYCSubmittedDetails = {
  user_kyc_request_date: string;
  user_kyc_status: ClientKYCStatus;
};

export enum TransactionStage {
  start = "Start",
  complete = "Complete",
  abandon = "Abandon",
}

export type TransactionDetails = {
  transaction_id: string;
  transaction_type: TransactionTypes; // The type of transaction e.g. sell/trade/listing
  transaction_asset_sell_price: string;
  transaction_asset_buy_price: string;
  transaction_asset_name: string;
  transaction_asset_id: string;
  transaction_asset_type: string;
  transaction_asset_issuer: string;
  transaction_asset_risk_rating: string;
  transaction_asset_investor_profile: string;
  transaction_date: string;
  transaction_currency: string;
  transaction_investment_amount: string; // Metric
  transaction_slippage: string; // metric
  transaction_stage: TransactionStage;
  transaction_trade_fee: string; //metric
};

export type AssetDetailViewCommerceDetails = {
  transaction_type: TransactionTypes;
  transaction_asset_sell_price: string;
  transaction_asset_buy_price: string;
  transaction_asset_name: string;
  transaction_asset_id: string;
  transaction_asset_type: string;
  transaction_asset_issuer: string;
  transaction_asset_risk_rating: string;
  transaction_asset_investor_profile: string;
  transaction_currency: string;
};

// data layer fields
export type DataLayer = {
  page: PageDetails | object;
  user: UserDetails | object;
  platform: {
    platform_name: "mesh-trade-platform"; // constant
    platform_type: "internal"; // constant
    platform_category: "trading-app"; // constant
    platform_prefix: "MTP"; // constant MTP (Mesh Trading Platform)
    platform_ecosystem_type: "internal"; // constant
    platform_base_country: "south africa"; // constant
    platform_country_code: "ZA";
  };
  tutorial: TutorialDetails | object;
  search: SearchDetails | object;
  error: ErrorDetails | object;
  transaction: TransactionDetails | object;
  account: AccountDetails | object;
};
