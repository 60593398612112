import { Company } from "james/legal/company";
import { CompanyRegStep } from "views/KYB/CompanyRegistration";
import { FormFieldsValidation as ContactDetailFormsValidation } from "./components/ContactDetails/Validation";
import { FormFieldsValidation as CompanyProfileFormsValidation } from "./components/CompanyProfile/Validation";
import { FormFieldsValidation as CompanyDetailValidation } from "./components/CompanyDetails/Validation";
import { FormFieldsValidation as CompanyRepresentativeValidation } from "./components/CompanyRepresentative/Validation";

// StepsValidator
export const CompanyStepsValidator = (
  companyRegStep: CompanyRegStep,
  company: Company,
): boolean => {
  switch (companyRegStep) {
    case CompanyRegStep.companyProfile: {
      return CompanyProfileFormsValidation({}, company, undefined);
    }
    case CompanyRegStep.companyDetails: {
      return CompanyDetailValidation({}, company, undefined);
    }
    case CompanyRegStep.contactDetails: {
      return ContactDetailFormsValidation({}, company, undefined);
    }
    case CompanyRegStep.companyRepresentative: {
      return CompanyRepresentativeValidation(
        {},
        company.companyRepresentative,
        undefined,
      );
    }
    case CompanyRegStep.connectedIndividuals: {
      return true;
    }
    case CompanyRegStep.connectedNonIndividuals: {
      return true;
    }
    default:
  }
  return false;
};
