import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, Typography } from "@mui/material";
import { CompanyRegStep } from "views/KYB/CompanyRegistration";
import {
  CompanyProfileFormFieldValidation,
  FormFieldsValidation,
} from "./Validation";
import { FormState } from "views/KYB/useFormState";
import { FieldValidations } from "common/validation";
import debounce from "lodash/debounce";
import { FormValidator } from "../CompanyDetails/Validation";

const PREFIX = "CompanyProfile";

const classes = {
  formField: `${PREFIX}-formField`,
  infoText: `${PREFIX}-infoText`,
  sectionWithRows2Gap: `${PREFIX}-sectionWithRows2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formField}`]: {
    width: 368,
  },

  [`& .${classes.infoText}`]: {
    width: 540,
    paddingBottom: theme.spacing(3),
  },

  [`&.${classes.sectionWithRows2Gap}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

interface CompanyProfileProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
  formState: FormState;
  fieldValidations: FieldValidations;
  nextPressed: boolean;
  setNextPressed: Dispatch<SetStateAction<boolean>>;
  savingInProgress: boolean;
}

export const CompanyProfile = (props: CompanyProfileProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    fieldValidations,
    setNextPressed,
    formState,
    onChange,
    onNext,
    inValid,
    invalidStep,
    nextPressed,
    savingInProgress,
  } = props;
  const [ranValidation, setRanValidation] = useState(false);
  const [FormFieldValid, setFormFieldValid] = useState({
    ...CompanyProfileFormFieldValidation,
  });
  const [InvalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>({});
  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...InvalidFormFields };
      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        delete InvalidFormField[field];
      }

      setInvalidFormFields(InvalidFormField);
    }, 600);

  if (inValid && !ranValidation) {
    setRanValidation(true);
    FormFieldsValidation(FormFieldValid, formState.company, setFormFieldValid);
  }

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    InlineFormValidation(name, event.target.value)();
    onChange(name)(event.target.value);
  };

  useEffect(() => {
    if (!props.savingInProgress && props.nextPressed) {
      onNext(CompanyRegStep.companyDetails)();
      setNextPressed(false);
      invalidStep(CompanyRegStep.companyProfile, false);
    }
  }, [props.savingInProgress, props.nextPressed]);

  useEffect(() => {
    if (!savingInProgress && nextPressed) {
      if (
        FormFieldsValidation(
          InvalidFormFields,
          formState.company,
          setInvalidFormFields,
        )
      ) {
        onNext(CompanyRegStep.companyDetails)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.companyProfile, false);
      } else {
        onNext(CompanyRegStep.companyDetails)();
        setNextPressed(false);
        invalidStep(CompanyRegStep.companyProfile, true);
      }
    }
  }, [savingInProgress, nextPressed]);

  return (
    <Root className={classes.sectionWithRows2Gap}>
      {/* Header */}
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary" children="Your Company" />
        </Box>
      </Box>

      {/* Content */}
      <Typography variant="subtitle2" className={classes.infoText}>
        Add your company name and registration details. We use this information
        to create your company’s profile.
      </Typography>
      <TextField
        id="companyProfile-registeredName"
        className={classes.formField}
        margin="dense"
        variant="outlined"
        label="Registered Name"
        fullWidth
        onChange={onUpdate("registeredName")}
        value={formState.company.registeredName}
        error={!!fieldValidations.companyName}
        helperText={fieldValidations.companyName}
      />
      <TextField
        id="companyProfile-companyRegistrationNumber"
        className={classes.formField}
        margin="dense"
        variant="outlined"
        label="Company Registration Number"
        onChange={onUpdate("registrationNumber")}
        value={formState.company.registrationNumber}
        placeholder="YYYY/MMMMMM/NN"
      />
    </Root>
  );
};
