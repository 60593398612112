import React, { useCallback, useState } from "react";
import { NonSouthAfricanClientEventPublisher } from "james/onboarding/NonSouthAfricanClientEventPublisher";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { SubmitEmailCard } from "views/SignUp/components/NonSouthAfricanClient/components/SubmitEmailCard/SubmitEmailCard";
import { EmailSubmittedCard } from "views/SignUp/components/NonSouthAfricanClient/components/EmailSubmittedCard/EmailSubmittedCard";

interface NonSouthAfricanProps {
  countryCode: string;
  onGoBack: () => void;
}

export function NonSouthAfricanClientCard({
  countryCode,
  onGoBack,
}: NonSouthAfricanProps) {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  const handleNonSouthAfricanSelection = useCallback(
    async (countryCode: string, email: string) => {
      try {
        await NonSouthAfricanClientEventPublisher.PublishNonSouthAfricanClientEvent(
          {
            clientEmail: email,
            countryOfResidence: countryCode,
          },
        );
        setTimeout(() => {
          setEmailSubmitted(true);
        }, 1500);
        enqueueSnackbar("Email Sent", { variant: "success" });
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        Sentry.setUser({
          email: email,
          id: "",
          ip_address: "{{auto}}",
        });
        errorContextDefaultErrorFeedback(err);
      }
    },
    [],
  );

  return (
    <>
      {!emailSubmitted && (
        <SubmitEmailCard
          onSubmitEmail={(email) =>
            handleNonSouthAfricanSelection(countryCode, email)
          }
          onGoBack={onGoBack}
        />
      )}
      {emailSubmitted && <EmailSubmittedCard />}
    </>
  );
}
