import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Popover,
  useTheme,
} from "@mui/material";
import { AccountBalanceWallet, SwapHoriz, Store } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientKYCStatus } from "james/client";
import { useApplicationContext } from "context/Application/Application";
import { useAppTourContext } from "context/AppTour/AppTour";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const pathnameIndexMap: { [key: string]: number } = {
  "/builder": 0,
  "/market/markets": 1,
  "/market": 1,
  "/market/orders": 2,
  "/market/trades": 2,
  "/market/subscriptions": 2,
  "/accounts": 4,
  "/administration": 5,
  "/market-management": 6,
};

export const MobileNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [value, setValue] = React.useState(pathnameIndexMap[location.pathname]);
  const { viewConfiguration, myClientKYCStatus } = useApplicationContext();
  const [unauthorisedAnchorEl, setUnauthorisedAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const { registerElement } = useAppTourContext();

  useEffect(() => {
    setValue(pathnameIndexMap[location.pathname]);
  }, [location.pathname]);

  return (
    <Paper
      sx={{
        zIndex: 3,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={[
          {
            height: "75px",
            width: "100%",
            paddingTop: (theme) => theme.spacing(1),
            paddingBottom: (theme) => theme.spacing(3),
            px: 1,
            boxShadow: "0px -3px 6px #00000029",
          },
        ]}
        showLabels={true}
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          ref={registerElement("mobileNavigator-marketsNavigation-button")}
          id={"mobileNavigator-marketsNavigation-button"}
          sx={(theme) => ({
            "&.Mui-selected": {
              color: theme.palette.custom.lavender + " !important",
            },
          })}
          onClick={() => {
            navigate("/market/markets");
          }}
          label="Markets"
          icon={<Store />}
          value={1}
        />
        <BottomNavigationAction
          id={"mobileNavigator-tradesNavigation-button"}
          sx={(theme) => ({
            "&.Mui-selected": {
              color: viewConfiguration.Marketplace.Trades?.Read
                ? theme.palette.custom.lavender + " !important"
                : theme.palette.text.disabled + " !important",
            },
          })}
          label="Activity"
          onClick={(e) => {
            if (viewConfiguration.Marketplace.Orders) {
              navigate("/market/orders");
              return;
            }
            if (viewConfiguration.Marketplace.Trades) {
              navigate("/market/trades");
              return;
            }
            if (viewConfiguration.Marketplace.Subscriptions) {
              navigate("/market/subscriptions");
              return;
            }
            setUnauthorisedAnchorEl(e.currentTarget);
          }}
          disableRipple={!viewConfiguration.Marketplace.Trades?.Read}
          value={2}
          icon={<SwapHoriz />}
        />
        {viewConfiguration.Marketplace.Exchange?.User && (
          <BottomNavigationAction
            ref={registerElement("mobileNavigator-walletNavigation-button")}
            onClick={() => navigate("exchange")}
            value={3}
            sx={(theme) => ({
              "&.Mui-selected": {
                color: location.pathname.includes("/exchange")
                  ? theme.palette.custom.lavender + " !important"
                  : theme.palette.text.disabled + " !important",
              },
            })}
            icon={<TrendingUpIcon />}
            label="Exchange"
          />
        )}
        <BottomNavigationAction
          ref={registerElement("mobileNavigator-walletNavigation-button")}
          id={"mobileNavigator-walletNavigation-button"}
          sx={(theme) => ({
            "&.Mui-selected": {
              color: location.pathname.includes("/accounts")
                ? theme.palette.custom.lavender + " !important"
                : theme.palette.text.disabled + " !important",
            },
          })}
          onClick={() => {
            navigate("/accounts");
          }}
          value={4}
          disableRipple={myClientKYCStatus !== ClientKYCStatus.VerifiedStatus}
          label="Accounts"
          icon={<AccountBalanceWallet />}
        />
      </BottomNavigation>
      <Popover
        id={"mobileNavigator-unAuthorised-popOver"}
        PaperProps={{
          sx: {
            backgroundColor: `${theme.palette.secondary.light} !important`,
            borderRadius: "4px",
            color: "black",
            padding: theme.spacing(0.5, 1),
          },
        }}
        open={Boolean(unauthorisedAnchorEl)}
        anchorEl={unauthorisedAnchorEl}
        onClose={() => setUnauthorisedAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorPosition={{
          top: 0,
          left: 0,
        }}
      >
        You do not have the required permissions
      </Popover>
    </Paper>
  );
};
