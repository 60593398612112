import {
  GetPriceForPeriodRequest,
  GetPriceForPeriodResponse,
} from "pkgTemp/market";
import { useCallback } from "react";
import { useMarketContext } from "context/Market";
import {
  BuildQueryKey,
  GenerateAPIRequestUniqueKey,
} from "utilities/reactQuery";
import { useQueryCacheControllerContext } from "context/QueryCacheController/QueryCacheController";
import { ReactQueryOpt } from "./type";

export type PriceHistorian = {
  GetPriceForPeriod: (
    request: GetPriceForPeriodRequest,
    reactQueryOpts?: ReactQueryOpt,
  ) => Promise<GetPriceForPeriodResponse>;
};

export function useRQPriceHistorian() {
  const { registerQuery, executeQuery } = useQueryCacheControllerContext();
  const { marketContextPriceHistorian } = useMarketContext();
  const GetPriceForPeriod = useCallback(
    async (
      request: GetPriceForPeriodRequest,
    ): Promise<GetPriceForPeriodResponse> => {
      // create unique Key for the API request
      const uniqueKey = GenerateAPIRequestUniqueKey(
        "PriceHistorian",
        "GetPriceForPeriod",
        request,
      );

      // build query Key and use it as an ID to register the query
      const ID = BuildQueryKey(uniqueKey, "PriceHistorian");

      registerQuery(
        ID,
        () => marketContextPriceHistorian.GetPriceForPeriod(request),
        {
          staleTime: 0, // 0 min
          cacheTime: 10 * 60 * 1000, // 10 min
        },
      );

      return (await executeQuery(ID)) as Promise<GetPriceForPeriodResponse>;
    },
    [registerQuery, executeQuery],
  );

  return { GetPriceForPeriod };
}
