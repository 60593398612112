import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  Fade,
  FadeProps,
  Slide,
  SlideProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAccountContext } from "context/Account/Account";
import {
  AssetFetcher,
  AssetTokeniser,
  AssetWrapper,
  BurnAssetResponse,
  LedgerAccountCategory,
  Token,
} from "james/ledger";
import { useApplicationContext } from "context/Application/Application";
import { Asset } from "james/ledger/Asset";
import { LedgerIDIdentifier, TokenIdentifier } from "james/search/identifier";
import { AssetFeeGenerator } from "james/remuneration";
import { UnexpectedTranslatedError, useErrorContext } from "context/Error";
import { ConfirmBurnAssetCard } from "./components/ConfirmAssetCard/ConfirmBurnAssetCard";
import { BurnAssetCard } from "./components/BurnAssetCard/BurnAssetCard";
import { useValidatedForm } from "hooks/useForm";
import {
  FormState,
  FormUpdater,
  FormUpdaterSpecsType,
  FormValidator,
} from "./components/BurnAssetCard/useFormState";
import { useSnackbar } from "notistack";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { useCurrentAPICall, useIsMounted } from "hooks";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { ManagingCompanyClientName, mZARTokenCode } from "const";
import {
  newTextExactCriterion,
  newUint32ExactCriterion,
} from "@mesh/common-js/dist/search";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { useAPIContext } from "context/API";
import BigNumber from "bignumber.js";

type BurnAssetDialogProps = {
  Token: Token;
  AccountLedgerID: string;
  open: boolean;
  onClose: () => void;
};

export function BurnAssetDialog(props: BurnAssetDialogProps) {
  const {
    financial: { smartInstrumentReader },
  } = useAPIContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { stellarAccountContext } = useAccountContext();
  const { authContext } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [displayConfirmBurnAssetCard, setDisplayConfirmBurnAssetCard] =
    useState(false);
  const [userIsSignatoryOnAccount, setUserIsSignatoryOnAccount] =
    useState(false);
  const [feeCalcInProgress, setFeeCalcInProgress] = useState(true);
  const [assetToBurn, setAssetToBurn] = useState<AssetWrapper | undefined>(
    undefined,
  );
  const { enqueueSnackbar } = useSnackbar();
  const { registerNotificationCallback } = useNotificationContext();
  const [burnInProgress, setBurnInProgress] = useState(false);
  const [tokensToBurnIssuerName, setTokensToBurnIssuerName] = useState("");
  const [feePaymentTokenIssuerName, setFeePaymentTokenIssuerName] =
    useState("");

  const [formState, formValidationResult, formUpdater] = useValidatedForm<
    FormState,
    FormUpdaterSpecsType
  >(
    FormValidator,
    undefined,
    FormUpdater,
    {
      tokensToBurn: props.Token.newAmountOf("0"),
      balance: props.Token.newAmountOf("0"),
      fee: props.Token.newAmountOf("0"),
      vat: props.Token.newAmountOf("0"),
      feeTokenBalance: props.Token.newAmountOf("0"),
    },
    new Set<string>([]),
  );
  const { errorContextErrorTranslator } = useErrorContext();

  const [
    isCurrentGenerateBurnAssetFeesAPICall,
    initGenerateBurnAssetFeesAPICall,
  ] = useCurrentAPICall();

  const isMounted = useIsMounted();

  const tradingAccMesh = useMemo(() => {
    const accountHoldingTokenToBurn = stellarAccountContext.accounts.find(
      (v) => v.ledgerID === props.AccountLedgerID,
    );
    if (!accountHoldingTokenToBurn) {
      return undefined;
    }

    const tokenToBurnBalance = accountHoldingTokenToBurn.balances.find((v) =>
      v.tokenViewModel.token.isEqualTo(props.Token),
    );

    if (!tokenToBurnBalance) {
      return undefined;
    }

    return stellarAccountContext.accounts.find(
      (a) =>
        a.category === LedgerAccountCategory.Trading &&
        tokenToBurnBalance.tokenViewModel.ownerID === a.ownerID,
    );
  }, [stellarAccountContext.accounts]);

  const feePaymentTokenBalance = useMemo(() => {
    if (!tradingAccMesh) {
      return undefined;
    }

    const bal = tradingAccMesh.balances.find(
      (b) =>
        b.tokenViewModel.issuer === ManagingCompanyClientName &&
        b.tokenViewModel.token.code === mZARTokenCode,
    );

    if (!bal) {
      return undefined;
    }

    formUpdater.feeTokenBalance(bal.amount);
    setFeePaymentTokenIssuerName(bal.tokenViewModel.issuer);
    return bal;
  }, [tradingAccMesh, formUpdater]);

  const burnTokenBalance = useMemo(() => {
    // find the account
    const accountHoldingTokenToBurn = stellarAccountContext.accounts.find(
      (v) => v.ledgerID === props.AccountLedgerID,
    );
    if (!accountHoldingTokenToBurn) {
      return undefined;
    }

    const tokenToBurnBalance = accountHoldingTokenToBurn.balances.find((v) =>
      v.tokenViewModel.token.isEqualTo(props.Token),
    );

    if (!tokenToBurnBalance) {
      return undefined;
    }

    formUpdater.balance(tokenToBurnBalance.amount);
    setTokensToBurnIssuerName(tokenToBurnBalance.tokenViewModel.issuer);

    return tokenToBurnBalance.amount;
  }, [stellarAccountContext.accounts, formUpdater]);

  useEffect(() => {
    stellarAccountContext
      .checkUserSignatoryOnAccount(LedgerIDIdentifier(props.AccountLedgerID))
      .then((value) => setUserIsSignatoryOnAccount(value))
      .catch((e) => {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error checking if user is signatory on account:
         ${err.message ? err.message : err.toString()}`,
        );
        errorContextDefaultErrorFeedback(e);
        // close the dialog
        props.onClose();
      });
  }, [stellarAccountContext.accounts]);

  useEffect(() => {
    (async () => {
      // retrieve the asset that is to be burned
      let retrievedAsset: AssetWrapper;
      try {
        // first try and retrieve asset as a smart instrument
        const retrievedSmartInstrument = (
          await smartInstrumentReader.readOneSmartInstrument(
            new ReadOneSmartInstrumentRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([
                newTextExactCriterion("token.code", props.Token.code),
                newTextExactCriterion("token.issuer", props.Token.issuer),
                newUint32ExactCriterion(
                  "token.network",
                  props.Token.toFutureToken().getNetwork(),
                ),
              ]),
          )
        ).getSmartinstrument();
        if (!retrievedSmartInstrument) {
          throw new Error("smart instrument not set after retrieval");
        }
        retrievedAsset = new AssetWrapper(retrievedSmartInstrument);
      } catch (e) {
        if (`${e}`.includes("not found")) {
          try {
            const retrievedOldAsset = (
              await AssetFetcher.FetchAsset({
                context: authContext,
                identifier: TokenIdentifier(props.Token),
              })
            ).asset;
            retrievedAsset = new AssetWrapper(retrievedOldAsset);
          } catch (e) {
            console.error(`error retrieving asset: ${e}`);
            errorContextDefaultErrorFeedback(e);
            props.onClose();
            return;
          }
        } else {
          console.error(`error retrieving asset: ${e}`);
          errorContextDefaultErrorFeedback(e);
          props.onClose();
          return;
        }
      }

      setAssetToBurn(retrievedAsset);
    })();
  }, []);

  // calculate the fee
  const feeGenerationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (!assetToBurn) {
      return;
    }
    setFeeCalcInProgress(true);

    const { apiCallID, abortController } = initGenerateBurnAssetFeesAPICall();

    clearTimeout(feeGenerationTimeoutRef.current);
    feeGenerationTimeoutRef.current = setTimeout(async () => {
      // no fees for smart instrument for now
      if (assetToBurn.isSmartInstrument()) {
        formUpdater.fee(new Token().newAmountOf("0"));
        formUpdater.vat(BigNumber("0"));
        return;
      }

      // otherwise calculate
      try {
        const response = await AssetFeeGenerator.GenerateAssetBurningFees(
          {
            context: authContext,
            asset: assetToBurn.wrappedAsset as Asset,
            noTokensToBurn: formState.tokensToBurn,
          },
          { signal: abortController.signal },
        );
        if (isCurrentGenerateBurnAssetFeesAPICall(apiCallID) && isMounted()) {
          setFeeCalcInProgress(false);
          if (response.fees.length > 0) {
            formUpdater.fee(response.fees[0].feeAmount());
            formUpdater.vat(response.fees[0].feeVATRate());
          }
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === JSONRPCCallAbortedError.ErrorCode) {
          return;
        }
        console.error(
          `error calculating asset burning fee: ${
            err.message ? err.message : err.toString()
          }`,
        );

        if (isCurrentGenerateBurnAssetFeesAPICall(apiCallID) && isMounted()) {
          errorContextDefaultErrorFeedback(err);
          // close the dialog
          props.onClose();
        }
      }
    }, 500);
  }, [assetToBurn, formState.tokensToBurn]);

  const slideTransition = useCallback((tprops: SlideProps) => {
    return <Slide direction="up" {...tprops} />;
  }, []);

  const fadeTransition = useCallback((tprops: FadeProps) => {
    return <Fade in {...tprops} />;
  }, []);

  const monitorBurnTransaction = useCallback(
    async (transactionID: string) => {
      // register callback to fire once the mint transaction has settled
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === transactionID
          ) {
            enqueueSnackbar(`Success! The tokens have been burned.`, {
              variant: "success",
            });
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === transactionID
          ) {
            enqueueSnackbar(`Error! Token burn failed.`, {
              variant: "error",
            });
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the token burn and its being investigated.",
              { variant: "warning" },
            );
          }
          deregister();
          setBurnInProgress(false);
          props.onClose();
        },
      );
    },
    [registerNotificationCallback, enqueueSnackbar, formState.tokensToBurn],
  );

  const onConfirmTokenBurn = useCallback(async () => {
    setBurnInProgress(true);

    if (!assetToBurn) {
      console.error(`token to burn not defined: ${assetToBurn}`);
      errorContextDefaultErrorFeedback(UnexpectedTranslatedError);
      setBurnInProgress(false);
      return;
    }
    // find the account
    const account = stellarAccountContext.accounts.find(
      (v) => v.ledgerID === props.AccountLedgerID,
    );
    if (!account) {
      console.error(`account not found: ${account}`);
      errorContextDefaultErrorFeedback(UnexpectedTranslatedError);
      setBurnInProgress(false);
      return;
    }

    // perform burn
    let burnAssetResponse: BurnAssetResponse;
    try {
      burnAssetResponse = await AssetTokeniser.BurnAsset({
        context: authContext,
        assetID: assetToBurn.assetID(),
        accountID: account.accountID(),
        amount: formState.tokensToBurn,
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(e, "Error Burining Asset");
      setBurnInProgress(false);
      return;
    }

    try {
      await monitorBurnTransaction(burnAssetResponse.transactionID);
    } catch (e) {
      console.error("error registring for burn transaction notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Burn Transaction - Please Check the Instrument Table and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  }, [formState.tokensToBurn, assetToBurn, stellarAccountContext.accounts]);

  const onMaxButton = useCallback(async () => {
    if (!burnTokenBalance) {
      console.warn("burn token balance not defined");
      return;
    }

    formUpdater.tokensToBurn(burnTokenBalance);
  }, [formUpdater, burnTokenBalance]);

  return (
    <Dialog
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": isMobile
            ? {
                width: "100%",
                padding: "0px",
                margin: "0px",
                position: "absolute",
                bottom: 0,
              }
            : {
                maxWidth: "392px",
                width: "100%",
              },
        },
      }}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
      open={props.open}
    >
      {displayConfirmBurnAssetCard ? (
        <ConfirmBurnAssetCard
          onCloseButtonClick={props.onClose}
          onConfirmButtonClick={onConfirmTokenBurn}
          fee={formState.fee}
          vat={formState.vat}
          tokensToBurn={formState.tokensToBurn}
          tokensToBurnIssuerName={tokensToBurnIssuerName}
          feeAccountName={
            tradingAccMesh
              ? `${tradingAccMesh.accountOwnerGroupName} Trade Acc`
              : ""
          }
          onGoBackButtonClick={() =>
            setDisplayConfirmBurnAssetCard(!displayConfirmBurnAssetCard)
          }
          feePaymentTokenBalance={feePaymentTokenBalance?.amount}
          feePaymentTokenIssuerName={feePaymentTokenIssuerName}
          tokenBurnInProgress={burnInProgress}
        />
      ) : (
        <BurnAssetCard
          tokensToBurnBalance={burnTokenBalance}
          tokensToBurnIssuerName={tokensToBurnIssuerName}
          feePaymentTokenIssuerName={feePaymentTokenIssuerName}
          userIsSignatoryOnAccount={userIsSignatoryOnAccount}
          feeCalcInProgress={feeCalcInProgress}
          fee={formState.fee}
          vat={formState.vat}
          feeAccountName={
            tradingAccMesh
              ? `${tradingAccMesh.accountOwnerGroupName} Trade Acc`
              : ""
          }
          feePaymentTokenBalance={feePaymentTokenBalance?.amount}
          tokensToBurn={formState.tokensToBurn}
          onTokensToBurnChange={(a) => formUpdater.tokensToBurn(a)}
          onCloseButtonClick={props.onClose}
          onSubmitButtonClick={() =>
            setDisplayConfirmBurnAssetCard(!displayConfirmBurnAssetCard)
          }
          onMaxButtonClick={onMaxButton}
          validationResult={formValidationResult}
        />
      )}
    </Dialog>
  );
}
