import { DateRangeCriterionTypeName } from "pkgTemp/search/criterion";
import dayjs from "dayjs";

export interface DateRangeValue {
  date: string;
  inclusive?: boolean;
  ignore?: boolean;
}

export interface DateRangeCriterionType {
  type: string;
  start: DateRangeValue;
  end: DateRangeValue;
}

function DateRangeCriterion(
  start: DateRangeValue = {
    date: dayjs().format(),
    ignore: true,
  },
  end: DateRangeValue = {
    date: dayjs().format(),
    ignore: true,
  },
): DateRangeCriterionType {
  return {
    type: DateRangeCriterionTypeName,
    start,
    end,
  };
}

export { DateRangeCriterion };
