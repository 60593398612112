import { Context } from "james/security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export type RetrieveTransactionStateRequest = {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
};

export type RetrieveTransactionStateResponse = {
  state: string;
};

export type TransactionFailureReason = { code: string; description: string };

export const TransactionInspector = {
  serviceProvider: "ledger-TransactionInspector",
  async RetrieveTransactionState(
    request: RetrieveTransactionStateRequest,
  ): Promise<RetrieveTransactionStateResponse> {
    return jsonRPCRequest({
      url: config.get("meshAuthenticatedURL"),
      method: `${TransactionInspector.serviceProvider}.RetrieveTransactionState`,
      request,
    });
  },
};
