import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Skeleton, Tooltip, Typography, TypographyProps } from "@mui/material";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { useIsMounted } from "hooks";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Token } from "james/ledger";
import { useErrorContext } from "context/Error";
const PREFIX = "Info";

const classes = {
  layout: `${PREFIX}-layout`,
  infoIcon: `${PREFIX}-infoIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.layout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },

  [`& .${classes.infoIcon}`]: {
    cursor: "pointer",
  },
}));

export interface AmountProps {
  token: Token;
  codeTypographyProps?: TypographyProps;
}

export function Info(props: AmountProps) {
  const isMounted = useIsMounted();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const [model, setModel] = useState<LedgerTokenViewModel>(
    new LedgerTokenViewModel(),
  );

  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    (async () => {
      // do not attempt to retrieve the view model for
      // a blank token id
      if (props.token.isUndefined()) {
        return;
      }

      try {
        const retrievedModel = await getLedgerTokenViewModel(props.token);
        if (isMounted()) {
          setModel(retrievedModel);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting ledger on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [props.token, getLedgerTokenViewModel, isMounted]);

  // as long as model ID is not set
  // return a skeleton loader
  if (!model.id) {
    return <Skeleton width={100} height={30} />;
  }

  return (
    <Root className={classes.layout}>
      <Typography color="inherit" children={model.token.code} />
      <Tooltip placement="top" title={`Issued by ${model.issuer}`}>
        <InfoIcon className={classes.infoIcon} />
      </Tooltip>
    </Root>
  );
}
