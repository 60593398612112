import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { ConnectedCompany } from "james/legal/ConnectedCompany";
import React, { useState } from "react";
import { CompanyRegStep } from "views/CompanyRegistration/CompanyRegistration";
import { FETable } from "components/Table/FETable";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditOutlined as EditIcon } from "@mui/icons-material";
import { NonConnectedIndividualDialog } from "./components/Dialog/NonConnectedIndividual";

interface ConnectedIndividualProps {
  onNext: (companyRegStep: CompanyRegStep) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (name: string) => (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  company: any;
  invalidStep: (companyRegStep: CompanyRegStep, validStatus: boolean) => void;
  inValid: undefined | boolean;
}

export const NonIndividual = (props: ConnectedIndividualProps) => {
  const { company, onChange, onNext, invalidStep } = props;
  const [showConnectedCompanyDialog, setShowConnectedCompanyDialog] =
    useState(false);
  const [selectedIdx, setSelectedIdx] = useState(-1);

  const nextStep = () => {
    onNext(CompanyRegStep.clientAdministratorData)();
    invalidStep(CompanyRegStep.connectedNonIndividuals, false);
  };

  return (
    <div>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" color="secondary">
            Connected Non-Individuals
          </Typography>
        </Box>
        <Button
          id="connectedNonIndividual-next"
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          Next
        </Button>
      </Box>
      <Typography
        style={{ marginTop: "8px", marginBottom: "40px" }}
        variant="subtitle2"
      >
        Add the details of an entity that has 25% or more shareholding in your
        company.
      </Typography>

      {!company.connectedCompanies.length && (
        <Button
          id="connectedNonIndividual-addNew"
          variant="contained"
          color="secondary"
          onClick={() =>
            setShowConnectedCompanyDialog(!showConnectedCompanyDialog)
          }
        >
          <Add />
          New Entity
        </Button>
      )}

      {!!company.connectedCompanies.length && (
        <FETable
          singleSelect
          height={408}
          title="List of Entities"
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (selectedIdx >= 0) {
              controls.push(
                <Tooltip title="Remove" placement="top">
                  <span>
                    <IconButton
                      id="connectedNonIndividualTable-remove-iconButton"
                      size="small"
                      onClick={() =>
                        onChange("connectedCompanies")(
                          company.connectedCompanies.filter(
                            (idx: number) => idx !== selectedIdx,
                          ),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              controls.push(
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconButton
                      id="connectedNonIndividualTable-edit-iconButton"
                      size="small"
                      onClick={() => {
                        setSelectedIdx(selectedIdx);
                        setShowConnectedCompanyDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );
            } else {
              controls.push(
                <Button
                  onClick={() => {
                    setSelectedIdx(-1);
                    setShowConnectedCompanyDialog(true);
                  }}
                  variant="contained"
                  color="secondary"
                  id="connectedNonIndividual-newNonIndividual-button"
                >
                  <Add />
                  New Entity
                </Button>,
              );
            }

            return controls;
          })()}
          onSingleSelectChange={(selectedRowData, idx) => {
            if (selectedRowData) {
              setSelectedIdx(idx);
            } else {
              setSelectedIdx(-1);
            }
          }}
          columns={[
            {
              label: "Name",
              field: "businessName",
              accessor: (data) => (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  children={(data as ConnectedCompany).businessName}
                />
              ),
            },
            {
              label: "Representative Name",
              field: "representativeName",
              accessor: (data) =>
                (data as ConnectedCompany).companyRepresentative.firstName,
            },
            {
              label: "Representative Email",
              field: "representativeEmail",
              accessor: (data) =>
                (data as ConnectedCompany).companyRepresentative.emailAddress,
            },
            {
              label: "Representative Mobile",
              field: "representativeMobile",
              accessor: (data) =>
                (data as ConnectedCompany).companyRepresentative
                  .cellphoneNumber,
            },
          ]}
          data={company.connectedCompanies}
        />
      )}

      {showConnectedCompanyDialog && (
        <NonConnectedIndividualDialog
          entity={
            // if index not negative
            selectedIdx >= 0
              ? // then use selected companies list
                new ConnectedCompany(company.connectedCompanies[selectedIdx])
              : // otherwise provide nothing
                undefined
          }
          onChange={(connectedCompany: ConnectedCompany) => {
            // if index not negative
            if (selectedIdx >= 0) {
              // then update selected companies list
              const updatedList = [...company.connectedCompanies];
              updatedList[selectedIdx] = new ConnectedCompany(connectedCompany);
              onChange("connectedCompanies")(updatedList);
            } else {
              // otherwise add
              onChange("connectedCompanies")([
                ...company.connectedCompanies,
                connectedCompany,
              ]);
            }
          }}
          open={showConnectedCompanyDialog}
          onClose={() => {
            setShowConnectedCompanyDialog(false);
          }}
        />
      )}
    </div>
  );
};
