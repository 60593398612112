import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useClientDetailsViewStore } from "../store";
import { Client } from "james/client";
import { Updater } from "james/client/Updater";
import { Manager } from "james/client/ManagerRead";
import { IDIdentifier } from "james/search/identifier";

export const useSaveClientDetails = () => {
  const store = useClientDetailsViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const saveClientDetails = async (client: Client) => {
    store.setLoading(true);
    try {
      await Updater.UpdateClientUnscoped({
        context: authContext,
        client: store.getClientObject(),
      });
      const response = await Manager.RetrieveClientUnscoped({
        context: authContext,
        identifier: IDIdentifier(client.id),
      });

      store.setCachedClient(response.client);
      store.setStateFrom(response.client);
      store.setLastUpdateBy(response.client.auditEntry.userID);
      store.setLastUpdateDate(response.client.auditEntry.time);

      // Display success message.
      enqueueSnackbar("Client detail saved", {
        variant: "success",
      });
    } catch (e) {
      console.error(`error saving bank account details`, e);
      errorContextDefaultErrorFeedback(e, "error updating client");
    }
    store.setLoading(false);
  };

  return {
    saveClientDetails,
  };
};
