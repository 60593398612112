"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allTimezones = void 0;
exports.timezoneToString = timezoneToString;
exports.stringToTimezone = stringToTimezone;
const timezone_pb_1 = require("./timezone_pb");
// Get all timezones as enum values
exports.allTimezones = Object.values(timezone_pb_1.Timezone).filter((value) => typeof value === "number");
// Define explicit mappings between Timezone enums and custom string representations
const timezoneToStringMapping = {
    [timezone_pb_1.Timezone.UNDEFINED_TIMEZONE]: "-",
    [timezone_pb_1.Timezone.UTC_TIMEZONE]: "UTC",
    [timezone_pb_1.Timezone.SAST_TIMEZONE]: "Africa/Johannesburg",
};
// Reverse mapping from string to Timezone enum
const stringToTimezoneMapping = {};
for (const [key, value] of Object.entries(timezoneToStringMapping)) {
    stringToTimezoneMapping[value] = Number(key);
}
class UnsupportedTimezoneError extends Error {
    constructor(timezone) {
        const message = `Unsupported Timezone: ${timezone}`;
        super(message);
        this.timezone = timezone;
    }
}
/**
 * Converts a Timezone enum instance to a custom string representation.
 * @param {Timezone} timezone - The timezone to convert.
 * @returns {string} The custom string representation of the timezone.
 */
function timezoneToString(timezone) {
    if (timezone in timezoneToStringMapping) {
        return timezoneToStringMapping[timezone];
    }
    else {
        throw new UnsupportedTimezoneError(timezone);
    }
}
class UnsupportedTimezoneStringError extends Error {
    constructor(timezoneStr) {
        const message = `Unsupported timezone string: ${timezoneStr}`;
        super(message);
        this.timezoneStr = timezoneStr;
    }
}
/**
 * Converts a custom string representation to a Timezone enum instance.
 * @param {string} timezoneStr - The custom string representation of the timezone.
 * @returns {Timezone} The corresponding Timezone enum instance.
 */
function stringToTimezone(timezoneStr) {
    if (timezoneStr in stringToTimezoneMapping) {
        return stringToTimezoneMapping[timezoneStr];
    }
    else {
        throw new UnsupportedTimezoneStringError(timezoneStr);
    }
}
