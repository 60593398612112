import React from "react";
import { Box, Typography } from "@mui/material";

export function NonSAKYCCaption() {
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(2, 0, 2, 0),
      })}
    >
      <Typography variant="body1">Please Note</Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          paddingTop: theme.spacing(1),
        })}
        variant="body1"
      >
        We are currently only available to South African citizens. We are
        working to make Mesh available to all users soon so please follow our
        social media for the latest news and updates.
      </Typography>
    </Box>
  );
}
