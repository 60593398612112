import { IdentifierType } from ".";

export interface NumberIdentifierType {
  type: IdentifierType;
  number: string;
}

function NumberIdentifier(no: string): NumberIdentifierType {
  return {
    type: IdentifierType.NumberIdentifierTypeName,
    number: no,
  };
}

export { NumberIdentifier };
