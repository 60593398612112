import { useEffect, useRef } from "react";

// This useEffect does not run on first render, but will only run when there are dependency changes
// after the first render. This is useful when you don't want the useEffect to always
// running even on first render and there are no dependency changes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEffectRunAfterFirstRender = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
