import React from "react";
import { Router } from "routes";
import { Table, Payment } from "./components";
import { Box } from "@mui/material";
import { useApplicationContext } from "context/Application/Application";

export function Payments() {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Payments"]?.ReadUNSCOPED;

  return (
    <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Router
        baseURL={"/builder/payments"}
        redirectPath={"/builder/payments/table"}
        routes={[
          {
            name: "Table",
            id: "table",
            path: "/builder/payments/table",
            component: <Table system={system} />,
          },
          {
            name: "Payment",
            id: "payment",
            path: "/builder/payments/view",
            component: <Payment />,
          },
        ]}
      />
    </Box>
  );
}
