/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/smartInstrumentReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js')

var ledger_token_pb = require('../ledger/token_pb.js')

var financial_smartInstrumentType_pb = require('../financial/smartInstrumentType_pb.js')

var financial_unit_pb = require('../financial/unit_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var i8n_timezone_pb = require('../i8n/timezone_pb.js')

var financial_smartInstrumentLeg_pb = require('../financial/smartInstrumentLeg_pb.js')

var document_document_pb = require('../document/document_pb.js')

var financial_smartInstrumentAttribute_pb = require('../financial/smartInstrumentAttribute_pb.js')

var financial_smartInstrumentReader_meshproto_pb = require('../financial/smartInstrumentReader_meshproto_pb.js')
const proto = {};
proto.financial = require('./smartInstrumentReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneSmartInstrumentRequest,
 *   !proto.financial.ReadOneSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadOneSmartInstrumentUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentReaderUNSCOPED/ReadOneSmartInstrumentUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_smartInstrumentReader_meshproto_pb.ReadOneSmartInstrumentRequest,
  financial_smartInstrumentReader_meshproto_pb.ReadOneSmartInstrumentResponse,
  /**
   * @param {!proto.financial.ReadOneSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_smartInstrumentReader_meshproto_pb.ReadOneSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentReaderUNSCOPEDClient.prototype.readOneSmartInstrumentUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentReaderUNSCOPED/ReadOneSmartInstrumentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadOneSmartInstrumentUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentReaderUNSCOPEDPromiseClient.prototype.readOneSmartInstrumentUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentReaderUNSCOPED/ReadOneSmartInstrumentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadOneSmartInstrumentUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManySmartInstrumentRequest,
 *   !proto.financial.ReadManySmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadManySmartInstrumentUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentReaderUNSCOPED/ReadManySmartInstrumentUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_smartInstrumentReader_meshproto_pb.ReadManySmartInstrumentRequest,
  financial_smartInstrumentReader_meshproto_pb.ReadManySmartInstrumentResponse,
  /**
   * @param {!proto.financial.ReadManySmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_smartInstrumentReader_meshproto_pb.ReadManySmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManySmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManySmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManySmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentReaderUNSCOPEDClient.prototype.readManySmartInstrumentUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentReaderUNSCOPED/ReadManySmartInstrumentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadManySmartInstrumentUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadManySmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManySmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentReaderUNSCOPEDPromiseClient.prototype.readManySmartInstrumentUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentReaderUNSCOPED/ReadManySmartInstrumentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadManySmartInstrumentUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllSmartInstrumentRequest,
 *   !proto.financial.ReadAllSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadAllSmartInstrumentUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentReaderUNSCOPED/ReadAllSmartInstrumentUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_smartInstrumentReader_meshproto_pb.ReadAllSmartInstrumentRequest,
  financial_smartInstrumentReader_meshproto_pb.ReadAllSmartInstrumentResponse,
  /**
   * @param {!proto.financial.ReadAllSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_smartInstrumentReader_meshproto_pb.ReadAllSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentReaderUNSCOPEDClient.prototype.readAllSmartInstrumentUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentReaderUNSCOPED/ReadAllSmartInstrumentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadAllSmartInstrumentUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadAllSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentReaderUNSCOPEDPromiseClient.prototype.readAllSmartInstrumentUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentReaderUNSCOPED/ReadAllSmartInstrumentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReaderUNSCOPED_ReadAllSmartInstrumentUNSCOPED);
};


module.exports = proto.financial;

