import React from "react";
import { ScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfiguration_pb";
import { ScheduleConfigurationType } from "@mesh/common-js/dist/financial/scheduleConfigurationType_pb";

import { Box, MenuItem, Typography } from "@mui/material";
import {
  ScheduleConfigurationWrapper,
  allScheduleConfigurationTypes,
  scheduleConfigurationTypeToString,
} from "@mesh/common-js/dist/financial";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import { PerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationPerpetual_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { NonPerpetualScheduleConfigurationForm } from "./components";
import { useBuilderContext } from "../../Context";
import { ValidationResult } from "common/validation";
import { TextField } from "components/FormFields";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";

export type ScheduleConfigurationFormProps = {
  timezone: Timezone;
  disabled: boolean;
  readOnly: boolean;
  scheduleConfiguration?: ScheduleConfiguration;
  earliestStartDate?: Timestamp;
  onChange: (updatedScheduleConfiguration?: ScheduleConfiguration) => void;
  formDataValidationResult: ValidationResult;
  validationResultFieldPrefix: string;
};

export const ScheduleConfigurationForm = (
  props: ScheduleConfigurationFormProps,
) => {
  const { formData } = useBuilderContext();

  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing(1),
        })}
      >
        <Typography variant="h6">Schedule Configuration:</Typography>
        <TextField
          fullWidth
          disabled={props.disabled}
          readOnly={props.readOnly}
          sx={{ maxWidth: 320 }}
          id={"smartInstrument-scheduleConfiguration-scheduleType-selectField"}
          select
          value={
            new ScheduleConfigurationWrapper(props.scheduleConfiguration)
              .scheduleConfigurationType
          }
          onChange={(e) => {
            // FIXME: if there is already a schedule config, copy the compatible parameters across
            const updatedScheduleConfig = new ScheduleConfiguration();
            switch (Number(e.target.value) as ScheduleConfigurationType) {
              case ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE:
                updatedScheduleConfig.setNonperpetualscheduleconfiguration(
                  new NonPerpetualScheduleConfiguration().setStartdate(
                    formData.smartInstrument.getIssuedate(),
                  ),
                );
                break;

              case ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE:
                updatedScheduleConfig.setPerpetualscheduleconfiguration(
                  new PerpetualScheduleConfiguration().setStartdate(
                    formData.smartInstrument.getIssuedate(),
                  ),
                );
                break;
            }
            props.onChange(updatedScheduleConfig);
          }}
          error={
            !!props.formDataValidationResult.fieldValidations[
              `${props.validationResultFieldPrefix}-scheduleConfiguationType`
            ]
          }
          helperText={
            props.formDataValidationResult.fieldValidations[
              `${props.validationResultFieldPrefix}-scheduleConfiguationType`
            ]
          }
        >
          {allScheduleConfigurationTypes.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {scheduleConfigurationTypeToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      {(() => {
        switch (
          new ScheduleConfigurationWrapper(props.scheduleConfiguration)
            .scheduleConfigurationType
        ) {
          case ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE:
            return "Perpetual not yet supported.";

          case ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE: {
            const conf =
              props.scheduleConfiguration?.getNonperpetualscheduleconfiguration();
            return conf ? (
              <NonPerpetualScheduleConfigurationForm
                disabled={props.disabled}
                readOnly={props.readOnly}
                nonPerpetualScheduleConfiguration={conf}
                onChange={(updatedScheduleConfiguration) =>
                  props.onChange(
                    new ScheduleConfiguration().setNonperpetualscheduleconfiguration(
                      updatedScheduleConfiguration,
                    ),
                  )
                }
                formDataValidationResult={props.formDataValidationResult}
                validationResultFieldPrefix={props.validationResultFieldPrefix}
                timezone={props.timezone}
                smartInstrumentIssueDate={formData.smartInstrument.getIssuedate()}
              />
            ) : (
              "Configuration not set."
            );
          }

          default:
            return null;
        }
      })()}
    </Box>
  );
};
