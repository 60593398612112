import { DataSheetSection } from "./DataSheetSection";

export const AssetPriceHistoryDataSheetSectionTypeName =
  "mesh::marketData/AssetPriceHistory";

export const AssetPriceHistoryDataSheetSheetSectionName = "Price History";

export class AssetPriceHistoryDataSheetSection implements DataSheetSection {
  public ["@type"]: string = AssetPriceHistoryDataSheetSectionTypeName;

  public assetID = "";

  constructor(l?: AssetPriceHistoryDataSheetSection) {
    if (!l) {
      return;
    }

    this.assetID = l.assetID;
  }

  public DataSheetSectionName(): string {
    return AssetPriceHistoryDataSheetSheetSectionName;
  }
}
