import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const PREFIX = "Finish";

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "calc(100% - 16px)",
    margin: 0,
  },

  [`& .${classes.heading}`]: {
    color: theme.palette.secondary.main,
  },
}));

interface FinishProps {
  onFinish: () => void;
}

export const Finish: React.FC<FinishProps> = (props: FinishProps) => {
  return (
    <StyledGrid
      container
      direction="column"
      spacing={1}
      className={classes.root}
    >
      <Grid item>
        <Typography className={classes.heading} variant="h4">
          Thank you for registering on the Mesh platform!
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Select finish to return to sign in.
        </Typography>
      </Grid>
      <Grid item />
      <Grid item>
        <Button
          id="userRegistration-finish"
          color="primary"
          variant="contained"
          onClick={props.onFinish}
        >
          Finish
        </Button>
      </Grid>
    </StyledGrid>
  );
};
