import { MediaImage } from "james/media/Image";

export class MarketingMedia {
  public type: MediaType | "" = "";
  public url = "";
  public thumbnailImage = new MediaImage();

  constructor(m?: MarketingMedia) {
    if (!m) return;

    this.type = m.type;
    this.url = m.url;
    this.thumbnailImage = new MediaImage(m.thumbnailImage);
  }
}

export enum MediaType {
  EmbeddedVideo = "embedded video",
  Video = "video",
  Image = "image",
}
