import React, { useEffect } from "react";
import { Client } from "james/client";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { countries } from "james/country";
import { TextField } from "components/FormFields";
import { AllBankNames } from "james/banking";
import HistoryIcon from "@mui/icons-material/History";
import { getBankAccountTypes } from "james/banking/bankAccount";
import { useBankingDetailsViewStore } from "./store";
import { useSaveBankAccountDetails } from "./hooks/useSaveBankAccountDetails";
import { useCaptureBankAccountDetails } from "./hooks/useCaptureBankAccountDetails";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { CountryOption } from "james/country/countries";

type BankAccountViewProps = {
  Client: Client;
  readOnly?: boolean;
};

export const originatingLocation =
  "mesh-core-ui/src/views/Compliance/components/Dashboard/components/BankAccountDetailsView/BankAccountView.tsx";

export function BankAccountView(props: BankAccountViewProps) {
  const store = useBankingDetailsViewStore();
  const { saveBankAccountDetails } = useSaveBankAccountDetails();
  const { captureClientBankAccountDetails } = useCaptureBankAccountDetails();
  useInitialDataFetch(props.Client);

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <Box
      sx={{
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <Backdrop open={store.loading}>
        <CircularProgress size={100} />
      </Backdrop>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          paddingBottom: (theme) => theme.spacing(2),
          paddingTop: (theme) => theme.spacing(4),
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            {props.Client.name}
          </Typography>
          <HistoryIcon color={"disabled"} />
          <div>
            <Typography variant={"body2"} color="textSecondary">
              {`Last update ${dayjs(store.lastUpdateDate).format(
                "YYYY-MM-DD, HH:mm A",
              )}`}
            </Typography>
            <Typography variant={"caption"} color="textSecondary">
              {store.lastUpdateBy}
            </Typography>
          </div>
        </Box>
        {!props.readOnly && (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={async () => {
                if (store.bankNotFound) {
                  await captureClientBankAccountDetails(props.Client);
                } else {
                  await saveBankAccountDetails(props.Client);
                }
              }}
              variant={"contained"}
              color={"primary"}
              disabled={store.loading}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          paddingTop: (theme) => theme.spacing(4),
          paddingBottom: (theme) => theme.spacing(4),
          display: "flex",
          column: "column",
          width: "100%",
        }}
      >
        <Typography variant={"h4"} fontWeight={"bold"}>
          Bank Account information
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "360px",
        }}
      >
        <Autocomplete
          readOnly={props.readOnly}
          isOptionEqualToValue={(option, value) => option === value}
          id="complianceDashboard-bankingDetail-country-autocomplete"
          sx={{
            marginBottom: (theme) => theme.spacing(1),
          }}
          options={countries}
          disabled={store.loading}
          getOptionLabel={(option) => (option ? option.label : "")}
          onChange={(__: object, country: CountryOption | null) => {
            if (country) {
              store.setCountryCode(country.value);
            }
          }}
          value={(() => {
            const c = countries.find(
              (countryOption) => countryOption.value === store.countryCode,
            );
            return c || null;
          })()}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Country"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Autocomplete
          readOnly={props.readOnly}
          isOptionEqualToValue={(option, value) => option === value}
          id="complianceDashboard-bankingDetail-bankAccountName-autocomplete"
          fullWidth
          disabled={store.loading}
          options={[...AllBankNames]}
          value={store.bankName ? store.bankName : null}
          onChange={(__: object, bankName) => {
            if (bankName) {
              store.setBankName(bankName);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bank"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <Autocomplete
          readOnly={props.readOnly}
          isOptionEqualToValue={(option, value) => option === value}
          id="complianceDashboard-bankingDetail-accountType-autocomplete"
          fullWidth
          disabled={store.loading}
          options={[...getBankAccountTypes(store.bankName)]}
          value={store.accountType ? store.accountType : null}
          onChange={(__: object, bankAccountType) => {
            if (bankAccountType) {
              store.setAccountType(bankAccountType);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Account Type"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <TextField
          readOnly={props.readOnly}
          id="complianceDashboard-bankingDetail-accountNumber-formfield"
          fullWidth
          disabled={store.loading}
          label="Account Number"
          value={store.number}
          onChange={(e) => {
            store.setNumber(e.target.value);
          }}
          variant="outlined"
        />
        <TextField
          readOnly={props.readOnly}
          id="complianceDashboard-bankingDetail-branchCode-autocomplete"
          disabled={store.loading}
          label="Branch Code"
          variant="outlined"
          value={store.branchCode}
          onChange={(e) => {
            store.setBranchCode(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
}
