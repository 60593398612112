import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { countries } from "james/country";
import { IdentificationType } from "james/legal";
import { InviteUserClaims } from "james/security/claims";
import React, { useState } from "react";
import { LuhnAlgorithm } from "utilities/validation";
import { useOpenLatestAUSAgreement } from "james/compliance";
import { CountryOption } from "james/country/countries";
const PREFIX = "PersonalDetails";

const classes = {
  root: `${PREFIX}-root`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  termsAndConditionsLink: `${PREFIX}-termsAndConditionsLink`,
  countrySelectFormField: `${PREFIX}-countrySelectFormField`,
  sectionWithRows2Gap: `${PREFIX}-sectionWithRows2Gap`,
  sectionWith2EqualColumns: `${PREFIX}-sectionWith2EqualColumns`,
  fieldLayout: `${PREFIX}-fieldLayout`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    maxWidth: 385,
    margin: 0,
  },

  [`& .${classes.sectionHeading}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.termsAndConditionsLink}`]: {
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.countrySelectFormField}`]: {
    margin: theme.spacing(1),
    width: 432 + theme.spacing(3),
  },

  [`&.${classes.sectionWithRows2Gap}`]: {
    width: 550,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  [`& .${classes.sectionWith2EqualColumns}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2),
  },

  [`& .${classes.fieldLayout}`]: {
    width: 368,
  },
}));

export interface PersonalDetailsStepFields {
  firstName: string;
  middleNames: string;
  lastName: string;
  nationality: string;
  identificationType: IdentificationType;
  identificationNumber: string;
}

interface PersonalDetailsProps {
  inviteUserClaims: InviteUserClaims;
  onNext: (personalDetails: PersonalDetailsStepFields) => void;
}

export const PersonalDetails: React.FC<PersonalDetailsProps> = (
  props: PersonalDetailsProps,
) => {
  const { openLatestAUSAgreement } = useOpenLatestAUSAgreement();

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [personalDetails, setPersonalDetails] = useState({
    firstName: props.inviteUserClaims.firstName,
    middleNames: "",
    lastName: props.inviteUserClaims.lastName,
    nationality: "ZA",
    identificationType: IdentificationType.SouthAfricanIDIdentificationType,
    identificationNumber: "",
  });
  const [fieldValidation, setFieldValidation] = useState<{
    [key: string]: string | undefined;
  }>({});

  const performValidation: () => { [key: string]: string | undefined } = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    if (personalDetails.firstName === "") {
      newValidationState.firstName = "Cannot Be Blank";
    }

    if (personalDetails.lastName === "") {
      newValidationState.lastName = "Cannot Be Blank";
    }

    if (personalDetails.nationality === "") {
      newValidationState.nationality = "Cannot Be Blank";
    } else {
      if (
        personalDetails.nationality === "ZA" &&
        personalDetails.identificationType !==
          IdentificationType.SouthAfricanIDIdentificationType
      ) {
        newValidationState.identificationType =
          "Must be South African ID for SA Citizen";
      }
      if (
        personalDetails.nationality !== "ZA" &&
        personalDetails.identificationType ===
          IdentificationType.SouthAfricanIDIdentificationType
      ) {
        newValidationState.identificationType =
          "Must be Passport for non-SA Citizen";
      }
    }

    if (personalDetails.identificationNumber === "") {
      newValidationState.identificationNumber =
        "Please provide Identification Number";
    } else if (
      personalDetails.identificationType ===
        IdentificationType.SouthAfricanIDIdentificationType &&
      personalDetails.identificationNumber.length !== 13
    ) {
      newValidationState.identificationNumber =
        "Identification number must be 13 digits";
    } else if (
      personalDetails.identificationType ===
        IdentificationType.SouthAfricanIDIdentificationType &&
      !LuhnAlgorithm(personalDetails.identificationNumber)
    ) {
      newValidationState.identificationNumber = "Invalid";
    }

    if (!acceptedTermsAndConditions) {
      newValidationState.acceptedTermsAndConditions =
        "Accept Terms & Conditions";
    }

    return newValidationState;
  };

  const handleClearValidation = (field: string) => {
    setFieldValidation({
      ...fieldValidation,
      [field]: undefined,
    });
  };

  return (
    <Root className={classes.sectionWithRows2Gap}>
      <Typography variant="h4" color="secondary">
        Hello {props.inviteUserClaims.firstName}, to register as a user on
        behalf of {props.inviteUserClaims.clientName}, enter your details below
        and follow the prompts.
      </Typography>
      <div className={classes.fieldLayout}>
        <Typography className={classes.sectionHeading} variant="h6">
          Personal Details
        </Typography>
        <div className={classes.sectionWith2EqualColumns}>
          <TextField
            id="userRegistration-firstName"
            margin="dense"
            label="First Name"
            variant="outlined"
            fullWidth
            value={personalDetails.firstName}
            onChange={(e) => {
              handleClearValidation("firstName");
              setPersonalDetails({
                ...personalDetails,
                firstName: e.target.value,
              });
            }}
            error={!!fieldValidation.firstName}
            helperText={fieldValidation.firstName}
          />
          <TextField
            id="userRegistration-middleNames"
            margin="dense"
            label="Middle Name(s)"
            fullWidth
            variant="outlined"
            value={personalDetails.middleNames}
            onChange={(e) => {
              handleClearValidation("middleNames");
              setPersonalDetails({
                ...personalDetails,
                middleNames: e.target.value,
              });
            }}
            placeholder="Optional"
            error={!!fieldValidation.middleNames}
            helperText={fieldValidation.middleNames}
          />
          <TextField
            id="userRegistration-lastName"
            margin="dense"
            label="Last Name"
            fullWidth
            variant="outlined"
            value={personalDetails.lastName}
            onChange={(e) => {
              handleClearValidation("lastName");
              setPersonalDetails({
                ...personalDetails,
                lastName: e.target.value,
              });
            }}
            error={!!fieldValidation.lastName}
            helperText={fieldValidation.lastName}
          />
        </div>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          id="userRegistration-nationality"
          options={countries}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Nationality"
              variant="outlined"
              fullWidth
            />
          )}
          onChange={(
            _: React.SyntheticEvent<Element, Event>,
            value: CountryOption | null,
          ) => {
            handleClearValidation("nationality");
            if (value) {
              if (value.value === "ZA") {
                setPersonalDetails({
                  ...personalDetails,
                  nationality: value.value as string,
                  identificationType:
                    IdentificationType.SouthAfricanIDIdentificationType,
                });
              } else {
                setPersonalDetails({
                  ...personalDetails,
                  nationality: value.value as string,
                  identificationType:
                    IdentificationType.PassportIdentificationType,
                });
              }
            }
          }}
        />
        <div className={classes.sectionWith2EqualColumns}>
          <TextField
            id="userRegistration-identificationType"
            margin="dense"
            variant="outlined"
            select
            fullWidth
            label="Identification Type"
            value={personalDetails.identificationType}
            onChange={(e) => {
              handleClearValidation("identificationType");
              setPersonalDetails({
                ...personalDetails,
                identificationType: e.target.value as IdentificationType,
              });
            }}
            error={!!fieldValidation.identificationType}
            helperText={fieldValidation.identificationType}
          >
            <MenuItem
              id={"personalDetails-southAfricanIDIdentificationType-menuItem"}
              key={1}
              value={IdentificationType.SouthAfricanIDIdentificationType}
            >
              South African ID
            </MenuItem>
            <MenuItem
              id={"personalDetails-passportIdentificationType-menuItem"}
              key={2}
              value={IdentificationType.PassportIdentificationType}
            >
              Passport
            </MenuItem>
          </TextField>
          <TextField
            inputProps={{ "data-private": "lipsum" }}
            id="userRegistration-identificationNumber"
            margin="dense"
            fullWidth
            label="Identification Number"
            variant="outlined"
            value={personalDetails.identificationNumber}
            onChange={(e) => {
              handleClearValidation("identificationNumber");
              setPersonalDetails({
                ...personalDetails,
                identificationNumber: e.target.value,
              });
            }}
            error={!!fieldValidation.identificationNumber}
            helperText={fieldValidation.identificationNumber}
          />
        </div>
      </div>
      <Typography variant="body1">
        Last thing. Please click that you have read the &nbsp;
        <Link
          onClick={openLatestAUSAgreement}
          children="Terms and Conditions"
        />
        .
      </Typography>
      <FormControl error={!!fieldValidation.acceptedTermsAndConditions}>
        <FormControlLabel
          control={
            <Checkbox
              id="userRegistration-termsAndConditions"
              color="primary"
              disableRipple
              inputProps={{ "aria-label": "primary checkbox" }}
              value={acceptedTermsAndConditions}
              onChange={() =>
                setAcceptedTermsAndConditions(!acceptedTermsAndConditions)
              }
            />
          }
          label={<Typography variant="body1">Terms and Conditions</Typography>}
        />
        {!!fieldValidation.acceptedTermsAndConditions && (
          <FormHelperText>
            {fieldValidation.acceptedTermsAndConditions}
          </FormHelperText>
        )}
      </FormControl>
      <div>
        <Button
          id="userRegistration-next"
          color="primary"
          variant="contained"
          disabled={!acceptedTermsAndConditions}
          onClick={() => {
            // perform field validations
            const validations = performValidation();
            if (Object.keys(validations).length > 0) {
              // if there are any errors, return
              setFieldValidation(validations);
              return;
            }
            props.onNext(personalDetails);
          }}
        >
          next
        </Button>
      </div>
    </Root>
  );
};
