import React from "react";
import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { formatTextNum } from "utilities/number";
import useMediaQuery from "@mui/material/useMediaQuery";

interface OrdersSubscriptionsCardProps {
  subscriptionOrder: MarketSubscriptionOrderViewModel;
}

export function SubscriptionOrdersCard(props: OrdersSubscriptionsCardProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        minWidth: "217px",
        height: "38px",
        marginTop: "8px",
      })}
    >
      {props.subscriptionOrder && (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: isMobile ? theme.spacing(0, 2) : theme.spacing(0, 3),
          })}
        >
          {!isMobile && (
            <Typography
              variant={"caption"}
              sx={(theme) => ({
                fontSize: "14px",
                color: theme.palette.text.disabled,
                paddingTop: theme.spacing(1),
              })}
              children={`Subscription No. ${props.subscriptionOrder?.number}`}
            />
          )}
          {isMobile && (
            <Typography
              variant={"caption"}
              sx={(theme) => ({
                fontSize: "14px",
                color: theme.palette.text.disabled,
                paddingTop: theme.spacing(1),
              })}
              children={props.subscriptionOrder?.number}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "8px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                sx={(theme) => ({
                  fontSize: "14px",
                  color: theme.palette.text.disabled,
                })}
                children={props.subscriptionOrder.amount.token.code}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  padding: "0px 0px 0px 4px",
                }}
              >
                {formatTextNum(props.subscriptionOrder.amount.value, {
                  addDecimalPadding: true,
                  noDecimalPlaces: 2,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Card>
  );
}
