import { ValidationResult } from "common/validation";
import { ValidateEmail } from "utilities/validation";

export type CompanyRequestFormState = {
  companyRequest: {
    adminName: string;
    adminEmail: string;
    companyName: string;
  };
};

// validate company request state
export const companyRequestValidationFunc = async (
  formState: CompanyRequestFormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!formState.companyRequest.companyName) {
    newValidationState.fieldValidations.companyName =
      "Company name cannot be empty";
    newValidationState.valid = false;
  }

  if (formState.companyRequest.adminEmail === "") {
    newValidationState.fieldValidations.adminEmail =
      "Admin email cannot be empty";
    newValidationState.valid = false;
  } else if (!ValidateEmail(formState.companyRequest.adminEmail)) {
    newValidationState.fieldValidations.adminEmail = "Admin email is invalid";
    newValidationState.valid = false;
  }

  if (!formState.companyRequest.adminName) {
    newValidationState.fieldValidations.adminName =
      "Admin name cannot be empty";
    newValidationState.valid = false;
  }

  return newValidationState;
};

export type CompanyRequestSpecType = {
  companyRequest: (
    value: {
      adminName: string;
      adminEmail: string;
      companyName: string;
    },
    prevState?: CompanyRequestFormState,
  ) => CompanyRequestFormState;
  adminName: (
    value: string,
    prevState?: CompanyRequestFormState,
  ) => CompanyRequestFormState;
  adminEmail: (
    value: string,
    prevState?: CompanyRequestFormState,
  ) => CompanyRequestFormState;
  companyName: (
    value: string,
    prevState?: CompanyRequestFormState,
  ) => CompanyRequestFormState;
};

// update company request state
export const companyRequestFormUpdaterSpecs: CompanyRequestSpecType = {
  companyRequest: (
    value: {
      adminName: string;
      adminEmail: string;
      companyName: string;
    },
    prevState: CompanyRequestFormState = {} as CompanyRequestFormState,
  ): CompanyRequestFormState => ({
    ...prevState,
    companyRequest: value,
  }),
  adminName: (
    value: string,
    prevState: CompanyRequestFormState = {} as CompanyRequestFormState,
  ): CompanyRequestFormState => ({
    ...prevState,
    companyRequest: {
      ...prevState.companyRequest,
      adminName: value,
    },
  }),
  adminEmail: (
    value: string,
    prevState: CompanyRequestFormState = {} as CompanyRequestFormState,
  ): CompanyRequestFormState => ({
    ...prevState,
    companyRequest: {
      ...prevState.companyRequest,
      adminEmail: value,
    },
  }),
  companyName: (
    value: string,
    prevState: CompanyRequestFormState = {} as CompanyRequestFormState,
  ): CompanyRequestFormState => ({
    ...prevState,
    companyRequest: {
      ...prevState.companyRequest,
      companyName: value,
    },
  }),
};

// initialize company request state
const initializeState = (): CompanyRequestFormState => {
  const companyRequest = {
    adminName: "",
    adminEmail: "",
    companyName: "",
  };

  return { companyRequest };
};

export const initialState = initializeState();

// check all submitted fields that there is no validation error
export const completedInFormFields = ({
  touchedFields,
  validationResult,
}: {
  touchedFields: Set<string>;
  validationResult: ValidationResult;
}): { companyRequestFormCompleted: boolean } => {
  const companyRequestFormCompleted = ((): boolean => {
    const fields = ["adminName", "adminEmail", "companyName"];

    const containsAll = fields.every((val) => touchedFields.has(val));

    const filteredValidationKeys = Object.keys(
      validationResult.fieldValidations,
    ).filter((key) => fields.includes(key));
    const noFieldErrs = filteredValidationKeys.every(
      (key) => validationResult.fieldValidations[key] === "" || undefined,
    );

    return containsAll && noFieldErrs;
  })();

  return { companyRequestFormCompleted };
};
