import { Box, DialogContent, Skeleton, useTheme } from "@mui/material";
import React from "react";

export const LoadingDialogContent = () => {
  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr 150px",
        height: {
          sm: 556,
          xs: "unset",
        },
        width: {
          sm: 420,
          xs: "unset",
        },
        padding: "unset !important",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          backgroundColor: theme.palette.custom.cardInner,
          padding: {
            sm: theme.spacing(0, 6),
            xs: theme.spacing(0, 3),
          },
        }}
      >
        <Skeleton width={"100%"} height={30} />
        <Skeleton width={"80%"} height={30} />
        <Box
          sx={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Skeleton width={"100%"} height={80} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr auto 1fr",
          rowGap: "20px",
          width: "100%",
          borderBottom: `1px solid ${theme.palette.divider}`,
          padding: {
            sm: theme.spacing(0, 6),
            xs: theme.spacing(0, 3),
          },
        }}
      >
        <Box
          sx={{
            alignSelf: "end",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "60px",
            width: "100%",
          }}
        >
          <Skeleton width={"100%"} height={30} />
          <Box />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "60px",
            width: "100%",
          }}
        >
          <Skeleton width={"80%"} height={25} />
          <Skeleton width={"80%"} height={25} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
        </Box>
        <Skeleton width={"90%"} height={25} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          padding: {
            sm: theme.spacing(0, 6),
            xs: theme.spacing(0, 3),
          },
        }}
      >
        <Skeleton width={"70%"} height={25} />
        <Skeleton width={"100%"} height={80} />
      </Box>
    </DialogContent>
  );
};
