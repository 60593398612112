import React, { ChangeEvent, useRef } from "react";
import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { Money } from "james/money";
import { StdFormatter } from "james/currency/stdCurrencyFormatter";

interface AddTextFieldProps {
  readOnly?: boolean;
  onChange?: (amount: Money) => void;
  value: Money;
}

export type CurrencyTextFieldProps = Omit<StandardTextFieldProps, "onChange"> &
  AddTextFieldProps;

/**
 * CurrencyTextField is a Material UI TextField wrapper.
 *
 * The TextField will use the predefined formats (james/currency/Format.ts) to format the input money value
 * The most notable difference between this component and IntlCurrencyTextField is that this component behaves the same
 * in all locales. This provides a consistent feel for use of thousand and decimal separators
 *
 */
export function CurrencyTextField(props: CurrencyTextFieldProps) {
  // capture the internal state of the value
  const [amount, setAmount] = React.useState(props.value);

  const { current: formatter } = useRef(new StdFormatter());

  const {
    textValue: initalValue,
    symbolAtStart,
    symbol,
  } = formatter.formatMoney(amount);

  // the value that is derived from the input amount
  const [value, setValue] = React.useState(initalValue);

  // internal state for value
  const [internalValue, setInternalValue] = React.useState(initalValue);

  React.useEffect(() => {
    // check if the amount value actually changed
    if (
      props.value.currencyCode !== amount.currencyCode ||
      props.value.amount !== amount.amount
    ) {
      // format the input again
      const { textValue } = formatter.formatMoney(props.value);

      // record the new state
      setAmount(props.value);

      // check to see which text value to render
      const internalMoney = formatter.parseMoney(
        internalValue,
        props.value.currencyCode,
      );

      if (internalMoney.amount === props.value.amount) {
        // use the custom text
        setValue(internalValue);
      } else {
        setValue(textValue);
        setInternalValue(textValue);
      }
    }
  }, [props.value, amount, formatter, value, internalValue]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rawValue = e.target.value;
    const newValue = formatter.formatMoneyText(rawValue, amount.currencyCode);
    const newAmount = formatter.parseMoney(newValue, amount.currencyCode);

    // update the internal value
    setInternalValue(newValue);

    // check if the amount actually changed
    if (newAmount.amount !== amount.amount) {
      if (props.onChange) {
        props.onChange(newAmount);
      }
    } else if (newValue !== value) {
      // the onChange handler won't be called, so we need to update the text field internally
      setValue(newValue);
    } else if (newValue === value && newValue === "") {
      setValue("0");
    }
  };

  const startAdornment =
    props.InputProps && props.InputProps.startAdornment !== undefined ? (
      props.InputProps.startAdornment
    ) : symbolAtStart ? (
      <InputAdornment position="start">{symbol}</InputAdornment>
    ) : undefined;

  const endAdornment =
    startAdornment !== undefined ? undefined : (
      <InputAdornment position="end">{symbol}</InputAdornment>
    );

  if (props.readOnly) {
    return (
      <TextField
        {...props}
        variant="standard"
        value={value}
        onChange={handleChange}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          startAdornment,
          endAdornment,
        }}
      />
    );
  }
  return (
    <TextField
      {...props}
      value={value}
      placeholder="0"
      onChange={handleChange}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
    />
  );
}
