"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSmartInstrumentStates = void 0;
exports.smartInstrumentStateToString = smartInstrumentStateToString;
exports.stringToSmartInstrumentState = stringToSmartInstrumentState;
const smartInstrument_pb_1 = require("./smartInstrument_pb");
// Get all smart instrument state as enum values
exports.allSmartInstrumentStates = Object.values(smartInstrument_pb_1.SmartInstrumentState).filter((value) => typeof value === "number");
// Define explicit mappings between SmartInstrumentState enums and custom string representations
const smartInstrumentStateToStringMapping = {
    [smartInstrument_pb_1.SmartInstrumentState.UNDEFINED_SMART_INSTRUMENT_STATE]: "-",
    [smartInstrument_pb_1.SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE]: "Draft",
    [smartInstrument_pb_1.SmartInstrumentState.PREISSUING_SMART_INSTRUMENT_STATE]: "Pre-Issuing",
    [smartInstrument_pb_1.SmartInstrumentState.PREISSUANCE_FAILED_SMART_INSTRUMENT_STATE]: "Pre-Issuance Failed",
    [smartInstrument_pb_1.SmartInstrumentState.PREISSUED_SMART_INSTRUMENT_STATE]: "Pre-Issued",
    [smartInstrument_pb_1.SmartInstrumentState.ISSUING_SMART_INSTRUMENT_STATE]: "Issuing",
    [smartInstrument_pb_1.SmartInstrumentState.ISSUANCE_FAILED_SMART_INSTRUMENT_STATE]: "Issuance Failed",
    [smartInstrument_pb_1.SmartInstrumentState.ISSUED_SMART_INSTRUMENT_STATE]: "Issued",
    [smartInstrument_pb_1.SmartInstrumentState.MATURED_SMART_INSTRUMENT_STATE]: "Matured",
    [smartInstrument_pb_1.SmartInstrumentState.CANCELLED_SMART_INSTRUMENT_STATE]: "Cancelled",
};
// Reverse mapping from string to SmartInstrumentState enum
const stringToSmartInstrumentStateMapping = {};
for (const [key, value] of Object.entries(smartInstrumentStateToStringMapping)) {
    stringToSmartInstrumentStateMapping[value] = Number(key);
}
class UnsupportedSmartInstrumentStateError extends Error {
    constructor(smartInstrumentState) {
        const message = `Unsupported SmartInstrumentState: ${smartInstrumentState}`;
        super(message);
        this.smartInstrumentState = smartInstrumentState;
    }
}
/**
 * Converts a SmartInstrumentState enum instance to a custom string representation.
 * @param {SmartInstrumentState} smartInstrumentState - The smart instrument state to convert.
 * @returns {string} The custom string representation of the smart instrument state.
 */
function smartInstrumentStateToString(smartInstrumentState) {
    if (smartInstrumentState in smartInstrumentStateToStringMapping) {
        return smartInstrumentStateToStringMapping[smartInstrumentState];
    }
    else {
        throw new UnsupportedSmartInstrumentStateError(smartInstrumentState);
    }
}
class UnsupportedSmartInstrumentStateStringError extends Error {
    constructor(smartInstrumentStateStr) {
        const message = `Unsupported smart instrument state string: ${smartInstrumentStateStr}`;
        super(message);
        this.smartInstrumentStateStr = smartInstrumentStateStr;
    }
}
/**
 * Converts a custom string representation to a SmartInstrumentState enum instance.
 * @param {string} smartInstrumentStateStr - The custom string representation of the smart instrument state.
 * @returns {SmartInstrumentState} The corresponding SmartInstrumentState enum instance.
 */
function stringToSmartInstrumentState(smartInstrumentStateStr) {
    if (smartInstrumentStateStr in stringToSmartInstrumentStateMapping) {
        return stringToSmartInstrumentStateMapping[smartInstrumentStateStr];
    }
    else {
        throw new UnsupportedSmartInstrumentStateStringError(smartInstrumentStateStr);
    }
}
