import config from "react-global-configuration";
import { Context } from "james/security";
import { Amount, Token } from "james/ledger";
import {
  AccountOverview,
  PortfolioAssetAllocation,
  PortfolioValuation,
} from "james/views/stellarPortfolioView/Models";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export type ViewRequest = {
  context: Context;
  valuationToken: Token;
};

export type ViewResponse = {
  pnlAmount: Amount;
  currentValue: Amount;
  currentAssetAllocation: PortfolioAssetAllocation[];
  portfolioValuationHistory: PortfolioValuation[];
  accountOverviews: AccountOverview[];
  // NotEnoughData is a way to tell the user weather there's sufficient data to show the UI charts
  notEnoughPortfolioValuationHistoryData: boolean;
  notEnoughPortfolioPnlData: boolean;
};

export const Viewer = {
  serviceProvider: "stellarPortfolioView-viewer",
  async View(
    request: ViewRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<ViewResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${Viewer.serviceProvider}.View`,
        request,
      },
      jsonRPCRequestOpts,
    );
    return {
      pnlAmount: new Amount(response.pnlAmount),
      currentValue: new Amount(response.currentValue),
      currentAssetAllocation: response.currentAssetAllocation.map(
        (assetAllocation: PortfolioAssetAllocation) =>
          new PortfolioAssetAllocation(assetAllocation),
      ),
      portfolioValuationHistory: response.portfolioValuationHistory.map(
        (portfolioValuation: PortfolioValuation) =>
          new PortfolioValuation(portfolioValuation),
      ),
      accountOverviews: response.accountOverviews.map(
        (accountOverview: AccountOverview) =>
          new AccountOverview(accountOverview),
      ),
      notEnoughPortfolioValuationHistoryData:
        response.notEnoughPortfolioValuationHistoryData,
      notEnoughPortfolioPnlData: response.notEnoughPortfolioPnlData,
    };
  },
};
